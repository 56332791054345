(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('UnEndingClosingController', UnEndingClosingController);

    UnEndingClosingController.$inject = ['$scope', '$rootScope', '$translate', '$window', 'Principal', 'DateUtils', 'AccountVoucher'];
    
    function UnEndingClosingController($scope, $rootScope, $translate, $window, Principal, DateUtils, AccountVoucher) {
         $scope.filterDTO = {};
         var checkAuth = function(){
            if ($rootScope.HAVENOAUTH('ROLE_DEPARTMENT')){            
                $window.history.back();
                layer.msg($translate.instant('global.forbiddenTeam'));
            }
            $scope.filterDTO.dateFrom = DateUtils.convertLocalDateFromServer(DateUtils.getPreMonth($rootScope.account.accountDate));
        }
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;                
                checkAuth();
            })
        }
        else {
            checkAuth();
        }
        

        $scope.datePickerForEtd = {};
        $scope.datePickerForEtd.status = {
            opened: false
        };
        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };
        
        $scope.isSaving = false;

        var onSuccess = function(result) {
            $rootScope.account.accountDate = DateUtils.getPreMonth($rootScope.account.accountDate);
            $rootScope.OPS_ALERT($translate.instant('autopsApp.accountTitle.antiClosingSuccess'));
        };

        var onError = function(result) {
            $scope.isSaving = false;
        };
        
        $scope.antiTransfer = function () {    
            if($rootScope.ISBETA()){
                layer.msg($translate.instant('global.betaAlert'));
                return;
            }
            var date = DateUtils.convertLocalDateToServer($scope.filterDTO.dateFrom);
            var aDate = date.split("-");
            var oDate1 = aDate[1];
            if (oDate1 == "12") {
                var title = $translate.instant('global.confirm');
                var content = $translate.instant('autopsApp.accountTitle.antiYearClosingAlert', {year: aDate[0]});
                layer.confirm(content, {
                    title: title,
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function() {
                    layer.closeAll();
                    $scope.isSaving = true;
                    $scope.filterDTO.companyId = $rootScope.account.companyId;
                    AccountVoucher.antiAccountClosing($scope.filterDTO, onSuccess, onError);
                    return;
                }, function() {
                    return;
                });
                return;
            }

            $scope.isSaving = true;
            $scope.filterDTO.companyId = $rootScope.account.companyId;
            AccountVoucher.antiAccountClosing($scope.filterDTO, onSuccess, onError);
        }
    }
})();
