(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('EdiDataDetailController', EdiDataDetailController);

    EdiDataDetailController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$uibModal', '$translate', '$timeout',
        'entity', 'Guide', 'OpsCarrier', 'OpsPorts', 'DataUtils', 'EdiData', 'GuideCtns'
    ];

    function EdiDataDetailController($scope, $rootScope, $uibModalInstance, $uibModal, $translate, $timeout,
        entity, Guide, OpsCarrier, OpsPorts, DataUtils, EdiData, GuideCtns) {

        var vm = this;
        $scope.guide = entity;
        $scope.ediData = {};
        var ediDataCopy = {};
        var guideCopy = {};

        $timeout(function() {
            EdiData.getByGuideId({
                guideId: $scope.guide.id
            }, function(result) {
                $scope.ediData = result;
                initEdiDate();
            })
        });

        var initEdiDate = function() {
            if (!$scope.ediData) {
                $scope.ediData = {};
            }
            var strs = [];
            if ($scope.guide.guideShipperBl) {
                strs = $scope.guide.guideShipperBl.split("\n");
                if (!$scope.ediData.shName && strs[0]) {
                    $scope.ediData.shName = strs[0];
                }
                if (!$scope.ediData.shAddress && strs[1]) {
                    $scope.ediData.shAddress = strs[1];
                }
                if (!$scope.ediData.shContact && strs[2]) {
                    $scope.ediData.shContact = strs[2];
                }
            }
            if ($scope.guide.deliveryBl) {
                strs = $scope.guide.deliveryBl.split("\n");
                if (!$scope.ediData.cnName && strs[0]) {
                    $scope.ediData.cnName = strs[0];
                }
                if (!$scope.ediData.cnAddress && strs[1]) {
                    $scope.ediData.cnAddress = strs[1];
                }
                if (!$scope.ediData.cnContact && strs[2]) {
                    $scope.ediData.cnContact = strs[2];
                }
            }
            if ($scope.guide.guideNotifyBl) {
                strs = $scope.guide.guideNotifyBl.split("\n");
                if (!$scope.ediData.npName && strs[0]) {
                    $scope.ediData.npName = strs[0];
                }
                if (!$scope.ediData.npAddress && strs[1]) {
                    $scope.ediData.npAddress = strs[1];
                }
                if (!$scope.ediData.npContact && strs[2]) {
                    $scope.ediData.npContact = strs[2];
                }
            }
            if ($scope.guide.polId && !$scope.ediData.shCountryCode) {
                $scope.ediData.shCountryCode = $scope.guide.polId.substring(0, 2);
            }
            if ($scope.guide.podId && !$scope.ediData.cnCountryCode) {
                $scope.ediData.cnCountryCode = $scope.guide.podId.substring(0, 2);
            }
            if ($scope.guide.podId && !$scope.ediData.npCountryCode) {
                $scope.ediData.npCountryCode = $scope.guide.podId.substring(0, 2);
            }
            ediDataCopy = angular.copy($scope.ediData);
            guideCopy = angular.copy($scope.guide);
        }

        $scope.clear = function() {
            $uibModalInstance.close();
        }
        $scope.updatePkgsAndType = function() {
            if ($scope.guide.pkgNum && $scope.guide.booking.pkgType) {
                $scope.guide.booking.pkgsAndType = $scope.guide.pkgNum.toString() + " " + $scope.guide.booking.pkgType;
                $scope.guide.pkgsAndType = $scope.guide.pkgNum.toString() + " " + $scope.guide.booking.pkgType;
            }
        }
        $scope.updateGwVol = function() {
            var gw = $scope.guide.gw;
            var vol = $scope.guide.vol;
            if (gw == null) {
                gw = 0;
            }
            if (vol == null) {
                vol = 0;
            }
            $scope.guide.gwVol = gw.toFixed(2) + "KGS" + "\n" + vol.toFixed(3) + "CBM";
            $scope.guide.booking.gwVol = gw.toFixed(2) + "KGS" + "\n" + vol.toFixed(3) + "CBM";

            if ($scope.guide.booking.divVolBy) {
                if ($scope.guide.shipmentType == 'FBASEA' || $scope.guide.shipmentType == 'LCL' || $scope.guide.shipmentType == 'LOCAL' || $scope.guide.shipmentType == 'FBARAIL') {
                    $scope.guide.chargeWeight = gw / $scope.guide.booking.divVolBy;
                    if ($scope.guide.chargeWeight < vol) {
                        $scope.guide.chargeWeight = vol;
                    }
                } else {
                    $scope.guide.chargeWeight = vol * 1000000 / $scope.guide.booking.divVolBy;
                    if ($scope.guide.chargeWeight < gw) {
                        $scope.guide.chargeWeight = gw;
                    }
                }
            }
            if ($scope.guide.chargeWeight) {
                $scope.guide.chargeWeight = $scope.guide.chargeWeight.toFixed(2) - 0;
                $scope.guide.awbWeight = $scope.guide.chargeWeight.toFixed(2) - 0;
            }
        };

        $scope.onCarrierSelected = function(carrier) {
            $scope.guide.booking.carrierCode = carrier.code;
            $scope.guide.booking.carrierFullName = carrier.carrierFullName;
        }
        $scope.uppercaseStr = function(obj, fd) {
            obj[fd] = angular.uppercase(obj[fd]);
        }
        $scope.datePickerForEtd = {};
        $scope.datePickerForEtd.status = {
            opened: false
        };
        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };
        $scope.datePickerForEta = {};
        $scope.datePickerForEta.status = {
            opened: false
        };
        $scope.datePickerForEtaOpen = function($event) {
            $scope.datePickerForEta.status.opened = true;
        };
        $scope.loadPorts = function() {
            if ($rootScope.OPSPORTS.airports && $rootScope.OPSPORTS.airports.length > 0) {
                return;
            }
            OpsPorts.getAll(function(result) {
                $rootScope.OPSPORTS.airports = [];
                $rootScope.OPSPORTS.seaports = [];
                angular.forEach(result, function(data) {
                    if (data.type == 1) {
                        $rootScope.OPSPORTS.seaports.push(data);
                    } else {
                        $rootScope.OPSPORTS.airports.push(data);
                    }
                })
                $scope.portList = $rootScope.OPSPORTS.seaports;
            })
        };
        $scope._selected = function($item, which) {
            $scope.guide[which] = $item.code;
        }

        $scope.textPop = function(text, bfd, gfd) {
            $uibModal.open({
                templateUrl: 'app/entities/ops-change-log/ops-change-log-delete-dialog.html',
                controller: 'OpsChangeLogDeleteController',
                controllerAs: 'vm',
                // backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return text;
                    }]
                }
            }).result.then(function(result) {
                if (gfd) {
                    $scope.guide[gfd] = result;
                } else {
                    $scope.guide.booking[bfd] = result;
                }
            }, function() {});
        }
        $scope.addGCtn = function() {
            $scope.guide.guideCtns.push({
                smsSent: false
            });
            $translate.refresh();
        };
        $scope.deleteGCtn = function(ctn) {
            if ($scope.guide.isClosed) {
                return;
            }
            if (ctn.id != null) {
                GuideCtns.delete({
                    id: ctn.id
                });
            };
            var index = $scope.guide.guideCtns.indexOf(ctn);
            if (index > -1) {
                $scope.guide.guideCtns.splice(index, 1);
            };
            $scope.refreshBookingCtns(ctn);
            $scope.guideCtnChange();
        };
        $scope.refreshBookingCtns = function(ctn) {
            if ($scope.guide.shipmentType != "FCL" || !ctn || !ctn.ctnType || ctn.ctnType.length < 4) {
                return;
            }
            var ctnTypes = new Set();
            angular.forEach($scope.guide.guideCtns, function(gctn) {
                ctnTypes.add(gctn.ctnType);
            })

            var size = 0;
            var sizes = {};
            ctnTypes.forEach(function(ctnType) {
                size = 0;
                angular.forEach($scope.guide.guideCtns, function(gctn) {
                    if (gctn.ctnType == ctnType) {
                        size++;
                    }
                })
                if (size) {
                    sizes[ctnType] = size;
                }
            })
            if (!$scope.guide.guideFclCtns) {
                $scope.guide.guideFclCtns = [];
            }
            var matched = false;
            ctnTypes.forEach(function(ctnType) {
                matched = false;
                angular.forEach($scope.guide.guideFclCtns, function(bctn) {
                    if (bctn.ctnType == ctnType) {
                        bctn.num = sizes[ctnType];
                        matched = true;
                    }
                })
                if (!matched) {
                    $scope.guide.guideFclCtns.push({
                        num: sizes[ctnType],
                        ctnType: ctnType
                    });
                }
            })
        }
        $scope.chargeWeightChange = function(chargeWeight) {
            $scope.guide.awbWeight = chargeWeight;
        };

        $scope.guideCtnChange = function() {
            DataUtils.guideCtnChange($scope.guide);
            
            var volTotal = 0;
            var gwTotal = 0;
            var pkgsTotal = 0;

            var ctnTypes = new Set();
            var pkgsCopy = null;
            var isSamePkg = true;
            angular.forEach($scope.guide.guideCtns, function(gctn) {
                ctnTypes.add(gctn.ctnType);
                if (gctn.vol) {
                    volTotal = volTotal + gctn.vol;
                }
                if (gctn.gw) {
                    gwTotal = gwTotal + gctn.gw;
                }
                if (gctn.pkgs) {
                    pkgsTotal = pkgsTotal + gctn.pkgs;
                }
                if (pkgsCopy) {
                    if (pkgsCopy != gctn.pkgType) {
                        isSamePkg = false;
                    }
                } else {
                    pkgsCopy = gctn.pkgType;
                }
            })
            $scope.guide.vol = volTotal.toFixed(3) - 0;
            $scope.guide.gw = gwTotal.toFixed(2) - 0;
            $scope.guide.pkgNum = pkgsTotal;
            if (isSamePkg) {
                $scope.guide.booking.pkgType = pkgsCopy;
            } else {
                $scope.guide.booking.pkgType = "PKGS";
            }
            ctnTypes.forEach(function(ctnType) {
                volTotal = 0;
                gwTotal = 0;
                pkgsTotal = 0;
                pkgsCopy = null;
                isSamePkg = true;
                angular.forEach($scope.guide.guideCtns, function(gctn) {
                    if (gctn.ctnType == ctnType) {
                        if (gctn.vol) {
                            volTotal = volTotal + gctn.vol;
                        }
                        if (gctn.gw) {
                            gwTotal = gwTotal + gctn.gw;
                        }
                        if (gctn.pkgs) {
                            pkgsTotal = pkgsTotal + gctn.pkgs;
                        }
                        if (pkgsCopy) {
                            if (pkgsCopy != gctn.pkgType) {
                                isSamePkg = false;
                            }
                        } else {
                            pkgsCopy = gctn.pkgType;
                        }

                    }
                })
                angular.forEach($scope.guide.guideFclCtns, function(bctn) {
                    if (bctn.ctnType == ctnType) {
                        bctn.vol = volTotal.toFixed(3) - 0;
                        bctn.gw = gwTotal.toFixed(2) - 0;
                        bctn.pkgNum = pkgsTotal;
                        if (isSamePkg) {
                            bctn.pkgType = pkgsCopy;
                        } else {
                            bctn.pkgType = "PKGS";
                        }
                    }
                })
            })
            $scope.updatePkgsAndType();
            $scope.updateGwVol();
        }
        $scope.guideCtnChange = function() {
            DataUtils.guideCtnChange($scope.guide);
            $scope.updatePkgsAndType();
            $scope.updateGwVol();
        }
        $scope.cargoSmartBr = function() {
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if (!$scope.guide.polId) {
                layer.msg('The code of Port of Load is null');
                return;
            }
            if (!$scope.guide.podId) {
                layer.msg('The code of Port of Discharge is null');
                return;
            }
            if (!$scope.guide.destId) {
                layer.msg('The code of Final destination is null');
                return;
            }
            if (!$scope.guide.booking.carrierCode) {
                layer.msg('The code of carrier is null');
                return;
            }
            $scope.isSaving = true;
            var ediDataDTO = {};
            ediDataDTO.guide = $scope.guide;
            ediDataDTO.ediData = $scope.ediData;
            Guide.penavicoTianjinEdiNew(ediDataDTO, onSendSuccess, onSendError);
        }

        var onSendSuccess = function(result) {
            $scope.isSaving = false;
            $rootScope.DOWNLOADTXT(result, $scope.guide.soNum + ".txt");   
        };
        var onSendError = function(result) {
            $scope.isSaving = false;
            if (result.status == 403) {
                $rootScope.OPS_ALERT($translate.instant('global.chineseContainedAlert') + ": " + result.data.filepath);
                // $rootScope.OPS_ALERT(result.filepath);
            } else if (result.status == 406) {
                layer.msg($translate.instant('autopsApp.guide.notAvailableAlert'));
            } else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        };

        $scope.save = function(booking) {
            $scope.isSaving = true;
            $scope.ediData.guideId = $scope.guide.id;
            if (!angular.equals($scope.ediData, ediDataCopy)) {
                if ($scope.ediData.id !== null) {
                    EdiData.update($scope.ediData, onSaveEdiSuccess, onSaveError);
                } else {
                    EdiData.save($scope.ediData, onSaveEdiSuccess, onSaveError);
                }
            } else {
                if (angular.equals($scope.guide, guideCopy)) {
                    $scope.isSaving = false;
                    layer.msg($translate.instant('global.no_amendmend'));
                    return;
                };
                Guide.update($scope.guide, onSaveGuideSuccess, onSaveError);
            }
        };

        var onSaveEdiSuccess = function(result) {
            $scope.ediData = result;
            ediDataCopy = angular.copy($scope.ediData);
            if (angular.equals($scope.guide, guideCopy)) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
                return;
            };
            Guide.update($scope.guide, onSaveGuideSuccess, onSaveError);
        };

        var onSaveGuideSuccess = function(result) {
            $scope.isSaving = false;
            $scope.guide = result;
            guideCopy = angular.copy($scope.guide);
            layer.msg($translate.instant('global.messages.submitted'));
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
            if (result.status == 403) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.lockedAlert'));
            } else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        };


    }
})();