(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsRatesDetailController', OpsRatesDetailController);

    OpsRatesDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'OpsRates', 'OpsTemplate'];
    
    function OpsRatesDetailController($scope, $rootScope, $stateParams, entity, OpsRates, OpsTemplate) {
        $scope.opsRates = entity;
        $scope.load = function (id) {
            OpsRates.get({id: id}, function(result) {
                $scope.opsRates = result;
            });
        };
        var unsubscribe = $rootScope.$on('autopsApp:opsRatesUpdate', function(event, result) {
            $scope.opsRates = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
