
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsPortsDetailController', OpsPortsDetailController);

    OpsPortsDetailController.$inject = ['$timeout', '$scope', '$rootScope', '$translate', '$uibModalInstance', 
    'entity', 'OpsPorts'];

    function OpsPortsDetailController ($timeout, $scope, $rootScope, $translate, $uibModalInstance, 
        entity, OpsPorts) {
        var vm = this;

        vm.opsPorts = {type: "1"};
        vm.clear = clear;
        vm.submitSearch = submitSearch;
        vm.typeChange = typeChange;

        $timeout(function (){
            typeChange(vm.opsPorts.type);
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function submitSearch () {
            vm.isSaving = true;
            OpsPorts.submitSearch(vm.opsPorts, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (results) {
            $uibModalInstance.close(results);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        $scope.lanes = [];
        function typeChange (type) {
            if (!type){
                return;
            }
            if (type == "1"){
                $scope.lanes = $rootScope.sealanes;
            }
            else {
                $scope.lanes = $rootScope.airlanes;
            }
        }

        $scope.uppercaseName = function(str) { 
            vm.opsPorts.name = angular.uppercase(str);
        }
        $scope.uppercaseCode = function(str) { 
            vm.opsPorts.code = angular.uppercase(str);
        }

    }
})();
