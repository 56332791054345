(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BookingController', BookingController);

    BookingController.$inject = ['$scope', '$state', '$rootScope', '$translate', '$uibModal', '$location', '$timeout', '$http',
    'OpsFiles', 'Booking', 'BookingSearch', 'ParseLinks', 'BookingTemplate', 'GridState', 'uiGridConstants', 'Guide',
    'OpsCompany', 'DataUtils'];

    function BookingController($scope, $state, $rootScope, $translate, $uibModal, $location, $timeout, $http,
        OpsFiles, Booking, BookingSearch, ParseLinks, BookingTemplate, GridState, uiGridConstants, Guide,
        OpsCompany, DataUtils) {

        $rootScope.bookingType = "all";
        if ($location.path().indexOf("all") >= 0){
            $rootScope.bookingType = "all";
        }
        else if ($location.path().indexOf("fba") >= 0){
            $rootScope.bookingType = "fba";
        }
        else if ($location.path().indexOf("wms") >= 0){
            $rootScope.bookingType = "wms";
        }
        else if ($location.path().indexOf("fms") >= 0){
            $rootScope.bookingType = "fms";
        }

        $scope.predicate = 'id';
        $scope.direction = 'desc';
        $scope.page = 1;
        $scope.itemsPerPage= 20;

        $scope.searchQuery = null;
        $scope.currentSearch = null;
        $scope.setJobType = function(type) {
            $scope.jobType = type;
        }
        $scope.isShipper = false;

        $scope.jobCount = {};
        $scope.getWorkLoad = function (){
            Booking.getWorkLoad({
                type: $rootScope.bookingType
            }, function(result){
                $scope.jobCount = result;
                $translate.refresh();
            });
        }

        $scope.setIsShipper = function(isShipper) {
            $scope.isShipper = isShipper;
            $scope.loadAll();
        }
        var gridCopy;
        $scope.loadAll = function() {
            if ($scope.searchQuery) {
                if($scope.jobType){
                    Guide.searchByNums({
                        type: $scope.jobType,
                        shipmentType: $rootScope.bookingType,
                        isExport: true,
                        isShipper: $scope.isShipper,
                        companyId: $rootScope.account.companyId
                    }, $scope.searchQuery, onSuccess, onError);
                    return;
                }
                BookingSearch.query({
                    type: $rootScope.bookingType,
                    query: $scope.searchQuery,
                    isShipper: $scope.isShipper,
                    page: $scope.page - 1,
                    size: $scope.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                var filter = getFilter();
                DataUtils.fillGuideFilter(gridCopy, filter);

                Booking.getFilterPage({
                    page: $scope.page - 1,
                    size: $scope.itemsPerPage,
                    sort: sort()
                }, filter, onSuccess, onError);
            }

            function sort() {
                var result = [$scope.predicate + ',' + $scope.direction];
                return result;
            }

            function onSuccess(data, headers) {
                $rootScope.ALERT_RESULT_MSG(headers);
                if(headers('X-Total-Count')){
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                }
                else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                initData();
                useExternalUpdate();
                updateFilterRequired();
                $scope.gridOptions.showColumnFooter = false;
                resize();
                $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
                $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
            }

            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }

        function getFilter() {
            var filter = {};
            filter.fmsType = $rootScope.bookingType;
            filter.isShipper = $scope.isShipper;
            return filter;
        }

        function useExternalUpdate() {
            $scope.gridOptions.useExternalSorting = !$scope.currentSearch;
            $scope.gridOptions.useExternalFiltering = !$scope.currentSearch;
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
        }
        $scope.search = function(searchQuery) {
            if (!searchQuery) {
                return $scope.clear();
            }
            $scope.page = 1;
            $scope.predicate = '_score';
            $scope.direction = 'desc';
            $scope.currentSearch = searchQuery;
            $scope.loadAll();
        }
        $scope.clear = function () {
            $scope.page = 1;
            $scope.predicate = 'id';
            $scope.direction =  'desc';
            $scope.searchQuery = $scope.currentSearch = null;
            $scope.loadAll();
        }
        if($rootScope.HAVEAUTH('SS_FB_CUSTOMER_ROLE')){
            $scope.isShipper = true;
        }
        if($rootScope.HAVEALLAUTH('SS_FB_SHIPPER_ROLE', 'SS_FB_CUSTOMER_ROLE')){
            $state.go('home', null, { reload: true });
        }
        $scope.loadAll();

        window.onresize = function(){
            resize();
        }

        function resize() {
            if(!$("#gridId").offset()){
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 190;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 500){
                tabHeight = 500;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        function initData() {
            angular.forEach($scope.gridOptions.data, function(data){
                DataUtils.addGuideTag(data);
                if(data.jobNum){
                    if(data.jobNum.indexOf("H-") == 0){
                        data.jobNum = data.jobNum.replace("H-", "");
                    }
                    if(data.jobNum.indexOf("M-") == 0){
                        data.jobNum = data.jobNum.replace("M-", "");
                    }
                }
                if(data.status == "rateLocked" || data.status == "rateNotLocked" || data.status == "combined"
                    || data.status == "notCombined" || data.status == "paymentReceived" || data.status == "isClosed"
                    || data.status == "notClosed" || data.status == "payment" || data.status == "collection"
                    || data.status == "approved" || data.status == "notChecked" || data.status == "unapproved"
                    || data.status == "Uncommitted" || data.status == "MblConfirmed" || data.status == "MawbConfirmed"
                    || data.status == "MblNotConfirmed" || data.status == "SaSent" || data.status == "HblHold"
                    || data.status == "MblHold" || data.status == "InvoiceReceived" || data.status == "MblReleased"
                    || data.status == "MawbReleased" || data.status == "FreightReceived" || data.status == "FreightPaid"){
                    if(data.bookingTime){
                        data.status = "Booked";
                    }
                    if(data.soReleaseTime){
                        data.status = data.isExport?"SoReleased":"DoReleased";
                    }
                    if(data.localTimeOne){
                        data.status = "stowageDone";
                    }
                    if(data.truckArrangeTime){
                        data.status = "TruckArranged";
                    }
                    if(data.ctnsLoadedTime){
                        data.status = data.isExport?"CtnStuffed":"CtnUnloaded";
                    }
                    if(data.otherTimeOne){
                        data.status = "wmsIn";
                    }
                    if(data.otherTimeTwo){
                        data.status = "wmsOut";
                    }
                    if(data.customArrangeTime){
                        data.status = "CustomArranged";
                    }
                    if(data.vgmTime){
                        data.status = "vgmSent";
                    }
                    if(data.customsInspectionExp){
                        data.status = "customsInspectionExp";
                    }
                    if(data.customReleaseTime){
                        data.status = "CustomReleased";
                    }
                    if(data.plConfirmTime){
                        data.status = "plConfirmed";
                    }
                    if(data.siSubmitedTime){
                        data.status = "SiSubmitted";
                    }
                    if(data.hblConfirmedTime){
                        data.status = "HblConfirmed";
                    }
                    if(data.mblConfirmedTime){
                        data.status = "MblConfirmed";
                    }
                    if(data.dnSentTime){
                        data.status = "DnSent";
                    }
                    if(data.customsInspection){
                        data.status = "customsInspection";
                    }
                    if(data.customClearTime){
                        data.status = "customCleared";
                    }
                    if(data.ataTime){
                        data.status = "arrivedPod";
                    }
                    if(data.otherTimeThree){
                        data.status = "transferOut";
                    }
                    if(data.deliveryAppointmentTime){
                        data.status = "deliveryAppointed";
                    }
                    if(data.ctnAvailableTime){
                        data.status = "ctnAvailable";
                    }
                    if(data.deliveryTime){
                        data.status = "Deliveried";
                    }
                    if(data.localTimeTwo){
                        data.status = "Cancelled";
                    }
                    if(data.isVoid){
                        data.status = "voided";
                    }
                }
            });

            if($scope.page != 1){
                return;
            }
            angular.forEach($scope.gridOptions.columnDefs, function(col, index){
                if(col.field == "polName"){
                    if($rootScope.bookingType == 'wms'){
                        col.displayName = $translate.instant('autopsApp.booking.polNameE');
                    }
                    else {
                        col.displayName = $translate.instant('autopsApp.booking.polName');
                    }
                }
                else if(col.field == "podName"){
                    if($rootScope.bookingType == 'wms' || $rootScope.bookingType == 'fba'){
                        col.displayName = $translate.instant('autopsApp.booking.podNameE');
                    }
                    else {
                        col.displayName = $translate.instant('autopsApp.booking.podName');
                    }
                }
                else if(col.field == "destName"){
                    if($rootScope.bookingType == 'wms' || $rootScope.bookingType == 'fba'){
                        col.displayName = $translate.instant('autopsApp.booking.destNameE');
                    }
                    else {
                        col.displayName = $translate.instant('autopsApp.booking.destName');
                    }
                }
                else if(col.field == "shipmentType" && col.filter){
                    if($rootScope.bookingType == 'wms'){
                        col.filter.selectOptions = [
                        { value: 'WMS', label: $translate.instant('global.WMS')},
                        { value: 'LOCALKG', label: $translate.instant('global.LOCALKG')},
                        { value: 'LOCAL', label: $translate.instant('global.LOCAL')}];
                    }
                    else if($rootScope.bookingType == 'fba'){
                        col.filter.selectOptions = [
                        { value: 'EXPRESS', label: $translate.instant('global.EXPRESS')},
                        { value: 'FBASEA', label: $translate.instant('global.FBASEA')},
                        { value: 'FBAAIR', label: $translate.instant('global.FBAAIR')},
                        { value: 'FBARAIL', label: $translate.instant('global.FBARAIL')}];
                    }
                    else if($rootScope.bookingType == 'fms'){
                        col.filter.selectOptions = [
                        { value: 'FCL', label: $translate.instant('global.FCL')},
                        { value: 'AIR', label: $translate.instant('global.AIR')},
                        { value: 'LCL', label: $translate.instant('global.LCL')},
                        { value: 'BULK', label: $translate.instant('global.BULK')},
                        { value: 'RAIL', label: $translate.instant('global.RAIL')}];
                    }
                    else {
                        col.filter.selectOptions = $rootScope.FMS_TYPES;
                    }
                }
                else if(col.field == "cargoReadyDate"){
                    if($rootScope.bookingType == 'fba'){
                        col.displayName = $translate.instant('autopsApp.guide.wmsInDate');
                    }
                    else {
                        col.displayName = $translate.instant('autopsApp.booking.cargoReadyDate');
                    }
                }
                else if(col.field == "etd"){
                    if($rootScope.bookingType == 'wms'){
                        col.displayName = $translate.instant('autopsApp.guide.eDate');
                    }
                    else {
                        col.displayName = $translate.instant('autopsApp.booking.etd');
                    }
                }
            })
        }
        var filterTimer;
        var pageLoaded = false;
        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: true,
            useExternalFiltering: true,

            enableGridMenu : $rootScope.account.companyId,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: false,//每列footer

            enableFiltering: true,//高级过滤

            enableHorizontalScrollbar : 1,//表格的水平滚动条
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            exporterMenuCsv : false,
            exporterMenuPdf : false,
            exporterMenuLabel : "Export",
            exporterExcelFilename: $translate.instant('autopsApp.booking.home.title') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],

            enableRowHeaderSelection : true,  //行选中
            enableRowSelection: true, //行选中
            enableSelectAll: true, //全部选中
            enableFullRowSelection : false, //行任意位置选中
            multiSelect: true,//多行选中

            paginationPageSizes: [20, 35, 50, 100, 200],
            paginationPageSize: 20,
            useExternalPagination: true,

            minimumColumnSize: 70,
            columnDefs: [
                { field: 'id',
                    width: 180,
                    displayName: "",
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<div class="btn-group flex-btn-group-container ml-5" ng-click="grid.appScope.setChoosedId(row.entity.id)"><button type="button"ng-click="grid.appScope.viewTracking(row.entity, grid.appScope.gridOptions.data, false)"class="btn btn-info btn-grid"><span class="glyphicon glyphicon-eye-open"></span><span translate="autopsApp.booking.home.shipmentStatus">shipmentStatus</span></button><button type="button"ng-click="grid.appScope.copyBooking(row.entity.id)"class="btn btn-primary btn-grid"><span class="glyphicon glyphicon-duplicate"></span><span translate="entity.action.copy"></span></button><button type="button"ng-click="grid.appScope.viewBooking(row.entity.id)"class="btn btn-success btn-grid"><span class="glyphicon glyphicon-modal-window"></span><span translate="entity.action.view"></span></button></div>'},
                { field: 'soNum',
                    displayName: $translate.instant('autopsApp.booking.soNum'),
                    width:150},
                { field: 'poNum',
                    displayName: $translate.instant('autopsApp.booking.poNum'),
                    width:150},
                { field: 'trackingId',
                    displayName: $translate.instant('autopsApp.guide.trackingId'),
                    minWidth:150},
                { field: 'jobNum',
                    displayName: $translate.instant('autopsApp.booking.bookingNum'),
                    width:150,
                    cellTemplate: '<span class="grid-text" ng-click="grid.appScope.setChoosedId(row.entity.id)" ng-class="row.entity.id==grid.appScope.choosedId?\'choosedColor\':\'\'"><a href=""ng-click="grid.appScope.viewBooking(row.entity.id)">{{row.entity.jobNum}}</a></span>'},
                { field: 'bookingId',
                    displayName: $translate.instant('global.cloudFiles'),
                    width:80,
                    enableSorting: false,
                    cellTemplate: '<div id="btnsId"class="btn-group flex-btn-group-container"ng-if="row.entity.bookingId"><div class="btn-group"uib-dropdown dropdown-append-to-body><button id="btn-append-to-body"type="button"class="btn btn-link btn-grid"uib-dropdown-toggle ng-click="grid.appScope.loadFiles(row.entity)"><span class="glyphicon glyphicon-cloud"></span><span translate="global.cloudFiles">cloudFiles</span><span class="caret"></span></button><ul class="dropdown-menu"uib-dropdown-menu role="menu"aria-labelledby="btn-append-to-body"><li><a href=""ngf-select ngf-change="grid.appScope.uploadFile($file, row.entity)"><span class="glyphicon glyphicon-cloud-upload"></span>&nbsp;<span translate="global.uploadFiles">uploadFiles</span></a></li><li role="separator"class="divider" ng-if="row.entity.files"></li><li ng-repeat="file in row.entity.files"><a href=""ng-click="grid.appScope.PDFPOP(file.fileurl,file.filename)"><span class="glyphicon glyphicon-cloud-download"></span>&nbsp;{{file.filename}}</a></li></ul></div></div>'},
                { field: 'mblNum',
                    displayName: $translate.instant('autopsApp.guide.expressMbl'),
                    width:150,
                    visible: $rootScope.bookingType === 'fba'},
                { field: 'hblNum',
                    displayName: $translate.instant('autopsApp.guide.hblNum'),
                    width:150,
                    visible: $rootScope.bookingType !== 'wms'},
                { field: 'shipmentId',
                    visible: $rootScope.bookingType === 'fba',
                    displayName: "Shipment Id",
                    minWidth:150},
                { field: 'fbaRef',
                    visible: $rootScope.bookingType === 'fba',
                    displayName: "Reference Id",
                    minWidth:150},
                { field: 'refNum',
                    displayName: $translate.instant('autopsApp.guide.refNum') + "(EDI)",
                    minWidth:150},
                { field: 'shipmentType',
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: [
                        { value: 'FCL', label: $translate.instant('global.FCL')}]
                    },
                    displayName: $translate.instant('autopsApp.booking.shipmentType'),
                    width:150,
                    cellTemplate: '<span class="ml-5" translate="global.{{row.entity.shipmentType}}" ng-if="row.entity.shipmentType"></span>'},
                { field: 'status',
                    displayName: $translate.instant('autopsApp.booking.statustitle'),
                    width:100,
                    cellTemplate: '<span class="label label-info ml-5" ng-show="row.entity.status" data-toggle="tooltip" data-placement="top" title="{{row.entity.lastModifiedTime | date:\'mediumDate\'}}">{{grid.appScope.SHOW_STATUS_GD(row.entity.status)}}</span>'},
                { field: 'paymentReceivedTimeTAG',
                    displayName: $translate.instant('autopsApp.expressParcel.status.paid'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.paymentReceivedTimeTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.paymentReceivedTimeTAG?\'stats.stat.paymentPaid\':\'stats.stat.paymentNotPaid\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'pkgNum',
                    type:'number',
                    displayName: $translate.instant('autopsApp.booking.pkgsAndType'),
                    width:100,
                    visible: $rootScope.bookingType === 'fba' || $rootScope.bookingType === 'wms',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    cellTemplate: '<span class="ml-5" ng-if="row.entity.pkgNum">{{row.entity.pkgNum}}PKGS</span>'},
                { field: 'gw',
                    type:'number',
                    visible: $rootScope.bookingType === 'fba' || $rootScope.bookingType === 'wms',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    cellTemplate: '<span class="ml-5" ng-if="row.entity.gw">{{row.entity.gw}}KG</span>',
                    displayName: $translate.instant('autopsApp.booking.gw'),
                    width:100},
                { field: 'vol',
                    type:'number',
                    visible: $rootScope.bookingType === 'fba' || $rootScope.bookingType === 'wms',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.booking.vol'),
                    cellTemplate: '<span class="ml-5" ng-if="row.entity.vol">{{row.entity.vol}}CBM</span>',
                    width:100},
                { field: 'chargeWeight',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.booking.chargeWeight'),
                    minWidth:80},
                { field: 'deliverytoId',
                    visible: $rootScope.bookingType === 'fba' || $rootScope.bookingType === 'wms',
                    displayName: $translate.instant('autopsApp.booking.deliveryTo'),
                    width:100},
                { field: 'polName', //13
                    displayName: $translate.instant('autopsApp.booking.polName'),
                    width:100},
                { field: 'podName',
                    displayName: $translate.instant('autopsApp.booking.podName'),
                    width:100},
                { field: 'destName',
                    displayName: $translate.instant('autopsApp.booking.destName'),
                    width:100},
                { field: 'cargoReadyDate',
                    displayName: $translate.instant('autopsApp.booking.cargoReadyDate'),
                    width:100},
                { field: 'cargoName',
                    displayName: $translate.instant('autopsApp.guide.cargoName'),
                    minWidth:150},
                { field: 'etd',
                    displayName: $translate.instant('autopsApp.booking.etd'),
                    width:100},
                { field: 'eta',
                    displayName: $translate.instant('autopsApp.booking.eta'),
                    visible: $rootScope.bookingType !== 'wms',
                    width:120},
                { field: 'atd',
                    displayName: $translate.instant('autopsApp.guide.atd'),
                    type:'date',
                    visible: false,
                    minWidth:120,
                    cellTemplate: '<span class="grid-text">{{row.entity.atd | date:\'mediumDate\'}}</span>'},
                { field: 'ataTime',
                    displayName: $translate.instant('autopsApp.guide.ataTime'),
                    type:'date',
                    visible: false,
                    minWidth:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.ataTime | date:\'medium\'}}">{{row.entity.ataTime | date:\'mediumDate\'}}</span>'},
                { field: 'deliveryDate',
                    displayName: $translate.instant('autopsApp.booking.deliveryDate'),
                    visible: $rootScope.bookingType !== 'wms',
                    width:120},
                { field: 'lastestTkStatus',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.expressParcel.lastestTkStatus')},
                { field: 'productName',
                    displayName: $translate.instant('autopsApp.guide.productName'),
                    minWidth:150},
                { field: 'deliveryWay',
                    displayName: $translate.instant('autopsApp.booking.deliveryWay'),
                    visible: $rootScope.bookingType === 'fba',
                    cellTemplate: '<span translate="autopsApp.booking.deliveryType.{{row.entity.deliveryWay}}" ng-if="row.entity.deliveryWay" class="label-info label ml-5"></span>',
                    width:120},
                { field: 'carrier',
                    displayName: $translate.instant('autopsApp.guide.carrier'),
                    visible: $rootScope.bookingType === 'fba',
                    width:120},
                { field: 'customRequired',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.booking.customRequired'),
                    cellTemplate: '<span class="ml-5"><span translate="global.yes_answer"ng-if="row.entity.customRequired"class="label-success label"></span><span translate="global.no_answer"ng-hide="row.entity.customRequired"class="label-info label"></span></span>',
                    width:120},
                { field: 'serviceTerm',
                    displayName: $translate.instant('autopsApp.guide.serviceTerm'),
                    visible: $rootScope.bookingType === 'fba',
                    width:120},
                { field: 'truckRequired',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.booking.truckRequired'),
                    cellTemplate: '<span class="ml-5"><span translate="global.yes_answer"ng-if="row.entity.truckRequired"class="label-success label"></span><span translate="global.no_answer"ng-hide="row.entity.truckRequired"class="label-info label"></span></span>',
                    width:120},
                { field: 'customClearTime',
                    visible: $rootScope.bookingType === 'fba' || $rootScope.bookingType === 'wms',
                    displayName: $translate.instant('autopsApp.guide.customClearTime'),
                    minWidth:180,
                    enableFiltering: false,
                    cellTemplate: '<span class="ml-5">{{row.entity.customClearTime | date:\'medium\'}}</span>'},
                { field: 'deliveryAppointmentTime',
                    visible: $rootScope.bookingType === 'fba' || $rootScope.bookingType === 'wms',
                    displayName: $translate.instant('autopsApp.guide.deliveryAppointmentTime'),
                    minWidth:180,
                    enableFiltering: false,
                    cellTemplate: '<span class="ml-5">{{row.entity.deliveryAppointmentTime | date:\'medium\'}}</span>'},
                { field: 'deliveryTime',
                    visible: $rootScope.bookingType === 'fba' || $rootScope.bookingType === 'wms',
                    displayName: $translate.instant('autopsApp.guide.deliveryTime'),
                    minWidth:180,
                    enableFiltering: false,
                    cellTemplate: '<span class="ml-5">{{row.entity.deliveryTime | date:\'medium\'}}</span>'},
                { field: 'destCountryCode',
                    displayName: $translate.instant('autopsApp.guide.destCountryCode'),
                    minWidth:100}

            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = getGridId();
                    layer.msg("Updating...", {
                      shade: 0.3,
                      time: 200000
                    });
                    GridState.update(gridStateObj, function(result){
                        layer.closeAll();
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.closeAll();
                        if(error.status == 404){
                            layer.msg("No sub-system users available!");
                        }
                        else {
                            layer.msg($translate.instant('global.save_fail'));
                        }
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        pageLoaded = false;
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                        pageLoaded = true;
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    pageLoaded = false;
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    pageLoaded = true;
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                    if(!$scope.gridOptions.useExternalSorting){
                        if(sortColumns[sortColumns.length - 1]){
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function(a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    if(sortColumns[sortColumns.length - 1]){
                        $scope.predicate = sortColumns[sortColumns.length - 1].field;
                        $scope.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    }

                    $scope.loadAll();
                });
                gridApi.core.on.filterChanged($scope, function() {
                    gridCopy = this.grid;
                    if(pageLoaded && $scope.gridOptions.useExternalFiltering){
                        clearTimeout(filterTimer);
                        filterTimer = setTimeout(function(){
                            $scope.loadAll();
                        }, 1000);//查询延时时间，单位ms
                    }
                });
                gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                    $scope.page = newPage;
                    $scope.itemsPerPage= pageSize;
                    if(pageLoaded){
                        $scope.loadAll();
                    }
                });
            }
        }
        $scope.choosedId = null;
        $scope.setChoosedId = function(id){
            $scope.choosedId = id;
        }
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            if($rootScope.HAVEALLAUTH('SS_FB_SHIPPER_ROLE', 'SS_FB_CUSTOMER_ROLE')){
                $state.go('home', null, { reload: true });
            }
            if($rootScope.HAVEAUTH('SS_FB_CUSTOMER_ROLE')){
                $scope.isShipper = true;
            }
            $scope.getWorkLoad();
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: getGridId()
            }, function(result){
                gridStateObj = result;
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    pageLoaded = true;
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                pageLoaded = true;
                if(gridCopy){
                    updateFilterRequired();
                    if($scope.filterRequired){
                        layer.msg($translate.instant('global.filterDetectedAlert'));
                    }
                }
            })
            loadHostCompanyId();
        });
         var updateFilterRequired = function(){
            if(!gridCopy){
                return;
            }
            $scope.filterRequired = false;
            angular.forEach(gridCopy.columns, function(column){
                angular.forEach(column.filters, function(cf){
                    if(cf.term || cf.term == false){
                        $scope.filterRequired = true;
                    }
                })
            })
        }

        var loadHostCompanyId = function() {
            $rootScope.HOST_COMPANY_ID = $rootScope.account.companyId;
            if(!$rootScope.HOST_COMPANY_ID && $rootScope.account.id){
                OpsCompany.getCompanyByUserId({userId: $rootScope.account.id}, function(result){
                    $rootScope.HOST_COMPANY_ID = result.companyId;
                })
            }
        }

        function getGridId () {
            if($rootScope.bookingType === "fms"){
                return 67;
            }
            else if($rootScope.bookingType === "fba"){
                return 68;
            }
            else if($rootScope.bookingType === "wms"){
                return 69;
            }
            else if($rootScope.bookingType === "all"){
                return 161;
            }
            else {
                return 117;
            }
        }

        var templateChooseIndex = 0;
        var loadBookingTemplate = function() {
            if ($scope.opsTemplates && $scope.opsTemplates.length>0){
                return;
            }
            BookingTemplate.query(function(result) {
                $scope.opsTemplates = result;
            });
        };

        $scope.onTemplateSelected = function(template) {
            $scope.copyBooking(template.bookingId);
            layer.close(templateChooseIndex);
        }

        $scope.useTemplate = function() {
            loadBookingTemplate();
            templateChooseIndex = layer.open({
                type: 1,
                area: ['380px', '500px'],
                moveOut: false,
                title: $translate.instant("autopsApp.booking.bookingPrice.home.chooseTemplate"),
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: $("#templateChoose")
            });
        }

        $scope.create = function() {
            $rootScope.BOOKINGACTIVETAB = 0;
            $rootScope.id = "new";
            $uibModal.open({
                templateUrl: 'app/entities/booking/booking-detail.html',
                controller: 'BookingDetailController',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return null;
                    }]
                }
            }).result.then(function(result) {
                $scope.loadAll();
            }, function(result) {
            });
        }

        $scope.copyBooking = function(guideId) {
            $rootScope.BOOKINGACTIVETAB = 0;
            $rootScope.id = "copy";
            $uibModal.open({
                templateUrl: 'app/entities/booking/booking-detail.html',
                controller: 'BookingDetailController',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return Guide.getGuideBookingDTO({id : guideId}).$promise;
                    }]
                }
            }).result.then(function(result) {
                $scope.loadAll();
            }, function(result) {
            });
        }

        $scope.loadFiles = function(guide) {
            if(guide.files && guide.files.length > 0){
                return;
            }
            OpsFiles.getBookingFiles({
                bookingId: guide.bookingId
            }, function(data) {
                if (data.length > 0) {
                    guide.files = new Array();
                    for (var i = 0; i < data.length; i++) {
                        var temp = new Object();
                        temp['filepath'] = data[i]['filepath'];
                        temp['filename'] = data[i]['filename'];
                        temp['fileurl'] = data[i]['fileurl'];
                        temp['fileist'] = true;
                        guide.files.push(temp);
                    }
                }
            });
        }


        $scope.uploadFile = function(file, guide){
            if (!file) {
                return;
            }
            var fd = new FormData();
            var filePath = "REPORTS/BOOKING/" + guide.bookingId + "/";
            fd.append('filepath', filePath);
            fd.append('file', file);
            fd.append('companyId', 'null');

            $http.post(
                'api/ops-files/uploadFile', fd, {
                    transformRequest : angular.identity,
                    headers : {
                        'Content-Type' : undefined
                    },
                }).success(
                function(data) {
                    if (angular.isDefined(data.filepath)) {
                        reloadFiles(data, guide);
                        layer.msg($translate.instant('global.uploadSuccess'));
                    }
                }).error(function(error) {
                    layer.msg($translate.instant('global.uploadFail'));
            });
        };
        function reloadFiles (file, guide) {
            if (!angular.isDefined(file.filepath)) {
                return;
            }
            var exist = false;
            if (guide.files.length > 0) {
                for (var i = 0; i < guide.files.length; i++) {
                    if (guide.files[i]['filename'] == file['filename']) {
                        guide.files[i]['filepath'] = file['filepath'];
                        guide.files[i]['filename'] = file['filename'];
                        guide.files[i]['fileurl'] = file['fileurl'];
                        exist = true;
                        break;
                    }
                }
            }
            if (!exist) {
                var temp = new Object();
                temp['filepath'] = file['filepath'];
                temp['filename'] = file['filename'];
                temp['fileurl'] = file['fileurl'];
                temp['fileist'] = true;
                guide.files.push(temp);
            }
        }

        var loadAllUnsubscribe = $rootScope.$on('autopsApp:loadAllRequred', function(event, result) {
            $scope.loadAll();
        });
        $scope.$on('$destroy', loadAllUnsubscribe);

        var unsubscribeImp = $rootScope.$on('autopsApp:guideUpdate', function(event, result) {
            var isNew  = true;
            angular.forEach($scope.gridOptions.data, function(guide){
                if(guide.id == result.id){
                    angular.copy(result, guide);
                    isNew = false;
                }
            })
            if(isNew){
                $scope.loadAll();
            }
        });
        $scope.$on('$destroy', unsubscribeImp);

        $scope.getCustomExcelImportRecord = function() {
            if ($scope.customExcelImportRecords) {
                return;
            }
            GridState.getCustomExcelImportRecord({types: Array.from(arguments)}, function(result) {
                $scope.customExcelImportRecords = result;
            })
        }


    }
})();
