(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('FaqController', FaqController);
    FaqController.$inject = ['$rootScope', '$scope', '$state'];

    function FaqController ($rootScope, $scope, $state) {
    	var vm = this;
		$scope.scroll = function (id) {  
			$("html,body").animate({scrollTop:$('#' + id).offset().top},1000)
		}
        $scope.goIntroduction = function () {  
            $state.go('introduction');
        }
        $scope.goTariff = function () {  
            $state.go('tariff');
        }
        $scope.goFeatures = function () {  
            $state.go('features');
        }
        $scope.goFaq = function () {  
            $state.go('faq');
        }
        $scope.goChangeLog = function () {  
            $state.go('changelog');
        }
        $scope.goCarrier = function () {  
            $state.go('carrier');
        }
        $scope.goFeatureswms = function () {  
            $state.go('features-wms');
        }
        $scope.goProjectCase = function () {  
            $state.go('project-case');
        }
    }
})();
