(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountInvoiceDeleteController', AccountInvoiceDeleteController);

    AccountInvoiceDeleteController.$inject = ['$rootScope', '$scope', '$translate', '$timeout', '$uibModalInstance', '$uibModal',
        'entity', 'OpsFiles', 'AccountInvoice', 'GuideRates', 'OpsReport', 'ExpressTracking', 'ApiErp', 'OpsVender', 'DateUtils'
    ];

    function AccountInvoiceDeleteController($rootScope, $scope, $translate, $timeout, $uibModalInstance, $uibModal,
        entity, OpsFiles, AccountInvoice, GuideRates, OpsReport, ExpressTracking, ApiErp, OpsVender, DateUtils) {

        $scope.invoiceRequest = entity;

        var format = 1;
        $scope.presentId = $scope.invoiceRequest.id;

        $scope.files = [];

        $timeout(function() {
            if($rootScope.HAVENOAUTH("ROLE_ACCOUNT") && $rootScope.HAVENOAUTH("ROLE_CASHIER") && $rootScope.HAVENOAUTH("AU_VOID_DN")
                 && $rootScope.HAVENOAUTH("FUN_ACCOUNT_INVOICE") && $rootScope.HAVENOAUTH("ROLE_BOSS")
                 && $scope.invoiceRequest.createdBy != $rootScope.account.id){
                $rootScope.FORBIDDEN_BY_ALERT("FUN_ACCOUNT_INVOICE");
                $scope.clear();
                return;
            }
            initStr();
            OpsVender.getDTO({
                id: $scope.invoiceRequest.venderId
            }, function(result) {
                $scope.invoiceRequest.invoiceTitle = $scope.invoiceRequest.invoiceTitle + "(" + result.code + ")";
            });
        });
        var initStr = function() {
            if ($scope.invoiceRequest.remarks && $scope.invoiceRequest.remarks.length > 155) {
                $scope.invoiceRequest.remarksShow = $scope.invoiceRequest.remarks.substring(0, 155) + "...";
            } else {
                $scope.invoiceRequest.remarksShow = $scope.invoiceRequest.remarks;
            }

            if ($scope.invoiceRequest.jobNums && $scope.invoiceRequest.jobNums.length > 55) {
                $scope.invoiceRequest.jobNumsShow = $scope.invoiceRequest.jobNums.substring(0, 55) + "...";
            } else {
                $scope.invoiceRequest.jobNumsShow = $scope.invoiceRequest.jobNums;
            }

            if ($scope.invoiceRequest.blNums && $scope.invoiceRequest.blNums.length > 55) {
                $scope.invoiceRequest.blNumsShow = $scope.invoiceRequest.blNums.substring(0, 55) + "...";
            } else {
                $scope.invoiceRequest.blNumsShow = $scope.invoiceRequest.blNums;
            }
        }

        $scope.loadFiles = function() {
            OpsFiles.loadInvoiceFiles({
                invoiceId: $scope.invoiceRequest.id
            }, function(result) {
                $scope.files = [];
                angular.forEach(result, function(file, index) {
                    $scope.files.push(file);
                });
            });
        }

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.previewAccountInvoice = function() {            
            var invoiceRequest = angular.copy($scope.invoiceRequest);
            invoiceRequest.deadLine = DateUtils.convertLocalDateToServer(invoiceRequest.deadLine);
            invoiceRequest.economicDate = DateUtils.convertLocalDateToServer(invoiceRequest.economicDate);
            invoiceRequest.accountDate = DateUtils.convertLocalDateToServer(invoiceRequest.accountDate);

            var paramList = new Array();
            paramList.push(invoiceRequest);
            AccountInvoice.generateReport({
                reportType: 220,
                fileType: 1
            }, paramList, function(result) {
                var file = new Blob([result.data], {
                    type: 'application/pdf'
                });
                var fileURL = URL.createObjectURL(file);
                layer.open({
                    type: 2,
                    area: ['1200px', '800px'],
                    maxmin: true,
                    title: $translate.instant("autopsApp.accountInvoice.viewAccountInvoice"),
                    shade: 0.3,
                    moveType: 0,
                    shift: 0,
                    content: fileURL
                })
            }, function(error) {
                layer.msg($translate.instant("autopsApp.accountInvoice.viewAccountInvocieFail"));
            });
        }
        var onCancelVoucherSuccess = function(result) {
            layer.closeAll();
            $scope.isSaving = false;
            $uibModalInstance.close(result);
            layer.msg($translate.instant('global.messages.submitted'));
        }

        $scope.cancelVoucher = function(voucherId, dnNum) {
            if ($rootScope.HAVENOAUTH('AU_VOID_DN')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_VOID_DN');
                return;
            }
            var title = $translate.instant('autopsApp.accountInvoice.home.hedgingTitle');
            var content = dnNum + $translate.instant('autopsApp.accountInvoice.home.hedgingContent');
            layer.confirm(content, {
                title: title,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                $scope.isSaving = true;
                if ($scope.invoiceRequest.isTtl) {
                    AccountInvoice.dismissCom({
                        isDn: true,
                        id: $scope.invoiceRequest.id
                    }, onCancelVoucherSuccess, $rootScope.ALERT_ERROR);
                } else {
                    layer.load(1, { shade: 0.3 });
                    GuideRates.cancelVoucher({
                        voucherId: voucherId,
                        dnNum: dnNum
                    }, onCancelVoucherSuccess, $rootScope.ALERT_ERROR);
                }
            });
        }

        $scope.previewReport = function(code) {
            var guideReportDTO = angular.copy($scope.invoiceRequest);
            guideReportDTO.deadLine = DateUtils.convertLocalDateToServer(guideReportDTO.deadLine);
            guideReportDTO.economicDate = DateUtils.convertLocalDateToServer(guideReportDTO.economicDate);
            guideReportDTO.accountDate = DateUtils.convertLocalDateToServer(guideReportDTO.accountDate);

            guideReportDTO.isDn = true;
            guideReportDTO.requestId = $scope.invoiceRequest.id;
            guideReportDTO.total = $scope.invoiceRequest.invoiceAmount;
            guideReportDTO.totalDec = $scope.invoiceRequest.invoiceAmount;
            guideReportDTO.reportType = code;
            guideReportDTO.opType = 1;
            guideReportDTO.fileType = 1;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                var file = new Blob([result.data], {
                    type: 'application/pdf'
                });
                if (file.size == 0) {
                    layer.msg($translate.instant('global.loadFileFailed'));
                    return;
                }
                var fileURL = URL.createObjectURL(file);
                var previewDialog = layer.open({
                    type: 2,
                    area: ['1000px', '680px'],
                    maxmin: true,
                    title: $translate.instant("entity.action.preview"),
                    shade: 0.3,
                    moveType: 0,
                    shift: 0,
                    content: fileURL
                });
            }, function(error) {

            });
        }

        $scope.expressRegistration = function() {
            if (!$scope.invoiceRequest.invoiceNum) {
                layer.msg($translate.instant('autopsApp.accountInvoice.invoiceNumNullAlert'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/account-auth/account-auth-detail.html',
                controller: 'AccountAuthDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        return $scope.invoiceRequest;
                    }]
                }
            }).result.then(function() {}, function() {});
        }

        $scope.docTracking = function() {
            $uibModal.open({
                templateUrl: 'app/entities/express-tracking/express-tracking-delete-dialog.html',
                controller: 'ExpressTrackingDeleteController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return ExpressTracking.kd100Tracking({
                            carrier: $scope.invoiceRequest.carrier,
                            carrierCode: $scope.invoiceRequest.carrierCode,
                            trackingNum: $scope.invoiceRequest.truckingNum
                        }).$promise;
                    }]
                }
            }).result.then(function(result) {

            }, function() {});
        }

        var onSaveSuccess = function(result) {
            $scope.invoiceRequest = result;
            $scope.isSaving = false;
            layer.msg($translate.instant('global.save_success'));
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
            if (result.status == 409) {
                $scope.invoiceRequest.alertTag = false;
                layer.msg($translate.instant('global.noUploadedFile'));
                return;
            }
            layer.msg($translate.instant('global.save_fail'));
        };
        $scope.setAlertTag = function() {
            $scope.isSaving = true;
            AccountInvoice.setAlertTag({ id: $scope.invoiceRequest.id }, onSaveSuccess, onSaveError);
        }

        $scope.sendReceiveBill = function(isVoided, fmis) {            
            var invoice = angular.copy($scope.invoiceRequest);
            invoice.isVoided = isVoided;
            if(isVoided){
                layer.prompt({
                    formType: 0,
                    value: "",
                    title: $translate.instant('autopsApp.accountInvoice.invoiceNum')
                }, function(value, index, elem) {
                    layer.close(index);
                    if(value){
                        value = value.replace(/\s+/g, "");
                        invoice.invoiceNum = value;                        
                    }
                    sendReceiveBill(invoice, fmis);
                });
            }
            else {
                sendReceiveBill(invoice, fmis);
            }
        }
        var sendReceiveBill = function(invoice, fmis){
            $scope.isSaving = true;
            ApiErp.sendReceiveBill({
                fmis: fmis
            }, invoice, function(result) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.send_success'));
            }, function(error) {
                $scope.isSaving = false;
                if (error && error.data && error.data.description) {
                    $rootScope.DOWNLOAD_TXT(error.data.description, invoice.dnNum + "_requestBody.txt");
                } else {
                    layer.msg($translate.instant('global.send_fail'));
                }
            });
        }
        

        $scope.getReceiveBillBody = function(fmis) {
            layer.load(1, {shade: 0.3});
            ApiErp.getReceiveBillBody({
                fmis: fmis
            }, $scope.invoiceRequest, function(result){
                layer.closeAll();
                $rootScope.DOWNLOAD_TXT(result.value, $scope.invoiceRequest.dnNum + "_body" + ".txt");                
            }, function(error){
                layer.closeAll();
            });
        }

        $scope.refreshEconomicDate = function() {
            GuideRates.refreshEconomicDate({
                invoiceId: $scope.invoiceRequest.id
            }, onSaveSuccess);
        }

        $scope.refreshGuideRatesByDnNum = function(refreshType) {
            // if(!$rootScope.isYuyue($scope.invoiceRequest.companyId)){
            //     return;
            // }
            layer.load(1, { shade: 0.3 });
            GuideRates.refreshGuideRatesByDnNum({
                companyId: $scope.invoiceRequest.companyId,
                type: refreshType,
                dnNum: $scope.invoiceRequest.dnNum
            }, function(result){
                layer.closeAll();
                if(result.id){
                    angular.copy(result, $scope.invoiceRequest);
                }
                layer.msg($translate.instant('global.update_success'));
            });
        }

    }
})();
