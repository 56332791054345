(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BookingPriceDetailController', BookingPriceDetailController);

    BookingPriceDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'BookingPrice'];
    
    function BookingPriceDetailController($scope, $rootScope, $stateParams, entity, BookingPrice) {
        $scope.bookingPrice = entity;
        $scope.load = function (id) {
            BookingPrice.get({id: id}, function(result) {
                $scope.bookingPrice = result;
            });
        };
        var unsubscribe = $rootScope.$on('autopsApp:bookingPriceUpdate', function(event, result) {
            $scope.bookingPrice = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
