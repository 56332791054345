
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsLogDeleteController', WmsLogDeleteController);

    WmsLogDeleteController.$inject = ['$scope', '$rootScope', '$timeout', '$uibModal', '$translate', '$uibModalInstance',
        'ManageEnterprise', 'WmsIn', 'DataUtils', 'OpsVender', 'OpsCarrier', 'WmsWarehouse', 'DateUtils', 'WmsCargo'
    ];

    function WmsLogDeleteController($scope, $rootScope, $timeout, $uibModal, $translate, $uibModalInstance,
        ManageEnterprise, WmsIn, DataUtils, OpsVender, OpsCarrier, WmsWarehouse, DateUtils, WmsCargo) {
        
        var vm = this;
        vm.addCargo = addCargo;
        vm.delCargo = delCargo;
        vm.returnRegistIn = returnRegistIn;
        vm.updateTtl = updateTtl;
        vm.updateVol = updateVol;
        vm.focusCargo = focusCargo;
        vm._onGoodsSelected = _onGoodsSelected;
        vm.initRapidIn = initRapidIn;
        vm.getAllGoods = getAllGoods;
        vm.getWarehouses = getWarehouses;
        vm.warehouseSelected = warehouseSelected;
        vm._onLocationSelected = _onLocationSelected;
        vm.getLastLocation = getLastLocation;
        vm.onLocationCopyChoosed = onLocationCopyChoosed;
        vm.getLocations = getLocations;
        vm.getBarcode = getBarcode;
        vm.rmaLabels = rmaLabels;
        vm.save = save;
        vm.getCargosByRef = getCargosByRef;
        vm.searchTypeChange = searchTypeChange;
        vm.applyLocation = applyLocation;
        vm.applyClient = applyClient;
        vm.saveWmsCargoList = saveWmsCargoList;
        vm.loadCargoFile = loadCargoFile;

        vm.refNum = "";
        vm.searchType = "returnTracking";

        vm.clear = clear;

        vm.locations = [];
        vm.files = [];   
        vm.wmsIn = {};
        vm.wmsIn.cargoList = [];
        vm.thisCargo = {};
        vm.conveyorMode = false;
        vm.isSaving = false;
        vm.autoShowPic = false;

        function searchTypeChange(searchType) {
            vm.searchType = searchType;
            vm.thisCargo = {};
            vm.refNum = "";
        }

        function fucusInput () {
            $timeout(function() {
                $("#track_number_field").focus();
            });
        }
        function clear () {
            $uibModalInstance.close();                     
        }

        function rmaLabels () {
            WmsIn.rmaLabels({
                timeStr: DateUtils.convertDateTimeToString(new Date()),
                fileType: 1
            }, vm.wmsIn, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        function getBarcode (obj, fd) {
            if(obj.id && obj.allOut){
                return;
            }
            getLocations();
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-dialog.html',
                controller: 'WmsInDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md'
            }).result.then(function(code) {
                if (fd == "code"){ 
                    angular.forEach(vm.locations, function(data){
                        if(data.code == code){
                            _onLocationSelected(data, obj);
                        }
                    });
                }
                else {
                    obj[fd] = code;
                }
                if(fd == "returnTracking"){
                    $("#gw_field").focus();
                }
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }
        vm.locationCopy = null;
        function _onLocationSelected (item, cargo) {
            cargo.location = item.code;
            cargo.locationId = item.locationId;
            cargo.locationType = item.locationType;
            cargo.areaId = item.areaId;
            cargo.warehouseId = item.warehouseId;
            cargo.keeper = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            cargo.keeperId = $rootScope.account.id;
            if(!vm.locationCopy){
                vm.locationCopy = angular.copy(item);
            }            
        }

        function getLastLocation (cargo) {
            if (vm.locationCopy && !cargo.location){
                cargo.location = vm.locationCopy.code;
                cargo.locationId = vm.locationCopy.locationId;
                cargo.locationType = vm.locationCopy.locationType;
                cargo.areaId = vm.locationCopy.areaId;
                cargo.warehouseId = vm.locationCopy.warehouseId;
            }
        }

        function onLocationCopyChoosed(item) {
            vm.locationCopy = angular.copy(item);
            if(vm.locations 
                && vm.locations.length > 0 
                && vm.wmsIn.cargoList
                && vm.wmsIn.cargoList.length > 0){                        
                angular.forEach(vm.wmsIn.cargoList, function(cargo){
                    if(!cargo.location){
                        _onLocationSelected (vm.locationCopy, cargo);
                    }                    
                })
            } 
        }
        function applyLocation(toAll) {
            angular.forEach(vm.wmsIn.cargoList, function(data){
                if(toAll || !data.location){
                    _onLocationSelected (vm.locationCopy, data);
                }
            })
        }
        function applyClient(toAll) {
            if(!vm.wmsIn.venderId){
                return;
            }
            angular.forEach(vm.wmsIn.cargoList, function(data){
                if(!data.wmsInId && (toAll || !data.clientId)){
                    data.clientId = vm.wmsIn.venderId;
                    data.clientName = vm.wmsIn.clientName;
                }
            })
        }
        function getLocations () {
            if(!vm.wmsIn.warehouseId){
                layer.msg($translate.instant('autopsApp.wmsIn.chooseWarehouseAlert'));
                return;
            }
            if(vm.isSaving){
                return;
            }
            if (!vm.locations || vm.locations.length == 0){
                vm.isSaving = true;
                WmsWarehouse.getLocations({warehouseId: vm.wmsIn.warehouseId}, function (data) {
                    vm.isSaving = false;
                    vm.locations = data;
                });
            }
        }

        var newCargoCopy = {
            packageType:"CTNS", 
            pkgs:1, 
            allOut:false, 
            status: "NO",
            action:"preIn", 
            companyId: $rootScope.account.companyId,
            inTime: new Date()
        }

        function initRapidIn() {
            vm.wmsIn = {};
            vm.wmsIn.cargoList = [];
            vm.thisCargo = angular.copy(newCargoCopy);
            vm.wmsIn.cargoList.push(vm.thisCargo);
        }
        initRapidIn();

        function addCargo(cargo) {
            if (!vm.wmsIn.cargoList) {
                vm.wmsIn.cargoList = [];
            }
            if(!cargo){
                vm.thisCargo= angular.copy(newCargoCopy);
                vm.wmsIn.cargoList.push(vm.thisCargo);
                if(vm.locationCopy){
                    _onLocationSelected (vm.locationCopy, vm.thisCargo);
                }                
            }
            else{                
                vm.thisCargo = cargo;
                if(vm.locationCopy && !vm.thisCargo.location){
                    _onLocationSelected (vm.locationCopy, vm.thisCargo);
                } 
                if(!vm.thisCargo.id){
                    vm.wmsIn.cargoList.push(vm.thisCargo);   
                }
                else {
                    var isNew = true;
                    angular.forEach(vm.wmsIn.cargoList, function (data) {
                        if(data.id == vm.thisCargo.id){
                            isNew = false;
                        }
                    })
                    if(isNew){
                        vm.wmsIn.cargoList.push(vm.thisCargo);  
                    }
                }        
                for(var i = vm.wmsIn.cargoList.length - 1; i >= 0; i--){
                    if(angular.equals(vm.wmsIn.cargoList[i], newCargoCopy)){
                        vm.wmsIn.cargoList.splice(i, 1);
                    }
                }   
            }            
            updateTtl();
            fucusInput();
        }

        function focusCargo(cargo) {
            vm.thisCargo = cargo;
        }

        function delCargo(cargo) {
            var index = vm.wmsIn.cargoList.indexOf(cargo);
            if (index > -1) {
                vm.wmsIn.cargoList.splice(index, 1);
            };
        }

        function getWarehouses () {
            if (vm.warehouses && vm.warehouses.length > 0){
                if(!vm.wmsIn.warehouseId){
                    warehouseSelected(vm.warehouses[0]);
                }    
                return;
            }
            WmsWarehouse.getAll({
                includeOwn: true,
                includeVirtual: false,
                includeGroup: false
            }, function (data) {
                vm.warehouses = data;
                if(vm.warehouses.length > 0 && !vm.wmsIn.warehouseId){
                    warehouseSelected(vm.warehouses[0]);                    
                }                
            });
        }
        getWarehouses();

        function warehouseSelected (warehouse) {
            initRapidIn();
            vm.wmsIn.warehouseName = warehouse.name;
            vm.wmsIn.warehouseId = warehouse.id;
            vm.wmsIn.companyId = warehouse.companyId;
            vm.locations = [];            
            getLocations();
        }


        function onSaveSuccess (result) {
            vm.isSaving = false;
            result.deliveryArrange = result.deliveryBySelf? "y" : "n";
            vm.wmsIn = result;            
            $scope.$emit('autopsApp:wmsInUpdate', result);
            layer.msg($translate.instant('global.messages.submitted'));
        }

        function onSaveError (result) {
            vm.isSaving = false;
            $rootScope.ALERT_ERROR(result);
        }

        function save () {
            if (vm.wmsIn.id) {
                $rootScope.LOADFILES(vm.files, '/wmsIn/' + vm.wmsIn.id + '/', vm.wmsIn.companyId);
                return;
            }

            layer.confirm($translate.instant('autopsApp.wmsIn.savePreAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                vm.isSaving = true;                   
                WmsIn.save(vm.wmsIn, onSaveSuccess, onSaveError);
            }, function() {
                return;
            }); 
        }

        $timeout(function() {
            fucusInput();
        });

        function updateTtl () {
            vm.wmsIn.gw = 0;
            vm.wmsIn.vol = 0;
            vm.wmsIn.applicationNum = 0;

            angular.forEach(vm.wmsIn.cargoList, function(data){
                if(!data.pkgs){
                    data.pkgs = 0;
                }
                vm.wmsIn.applicationNum += data.pkgs;
                if(data.gw){
                    vm.wmsIn.gw += data.gw * data.pkgs;
                    data.gwTtl = DataUtils.round(data.gw * data.pkgs, 2);
                }
                if(data.vol){
                    vm.wmsIn.vol += data.vol * data.pkgs;
                    data.volTtl = DataUtils.round(data.vol * data.pkgs, 3);
                }
            })
            vm.wmsIn.gw = DataUtils.round(vm.wmsIn.gw, 2);
            vm.wmsIn.vol = DataUtils.round(vm.wmsIn.vol, 4);
        }
        
        function updateVol (cargo) {
            if (cargo.length && cargo.width && cargo.height){
                cargo.vol = cargo.length * cargo.width * cargo.height/1000000;
                cargo.vol = DataUtils.round(cargo.vol, 4);
                updateTtl();
            }
        }

        $scope._onClientSelected = function(data) {
            vm.wmsIn.venderId = data.id;
            vm.wmsIn.salesId = data.createdBy;
            vm.wmsIn.salesName = data.creater;
            $rootScope.WMSGOODS = [];
        }
        $scope.clearClient = function(data) {
            if(!vm.wmsIn.clientName){
                vm.wmsIn.venderId = null;
                vm.wmsIn.salesId = null;
                vm.wmsIn.salesName = null;
                $rootScope.WMSGOODS = [];
            }            
        }
        function getAllGoods () {
            if(!vm.wmsIn.venderId ){
                return;
            }
            if (!$rootScope.WMSGOODS || $rootScope.WMSGOODS.length == 0){
                ManageEnterprise.getAllGoods({venderId:vm.wmsIn.venderId }, function(result) {
                    $rootScope.WMSGOODS = result;
                })
            }
        }

        function _onGoodsSelected (data, cargo) {
            cargo.code = data.code;
            cargo.sku = data.sku;
            cargo.wmsGoodsId = data.wmsGoodsId;//batchid
            cargo.cargoName = data.cargoName;
            cargo.packageType = data.packageType;
            cargo.gw = data.gw;
            cargo.pkgs = null;
            cargo.palletAmt = null;
            cargo.vol = data.vol;
            cargo.length = data.length;
            cargo.width = data.width;
            cargo.height = data.height;
            cargo.isPallet = data.isPallet;
            cargo.palletCapacity = data.palletCapacity;
            cargo.chargeType = data.chargeType;
            cargo.chargePrice = data.chargePrice;
            cargo.piece = data.piece;
            cargo.pieceUnit = data.pieceUnit;
            cargo.nw = data.nw;
            cargo.capacity = data.capacity;
            cargo.capacityUnit = data.capacityUnit;
            updateVol(cargo);
        }
        $(document).keydown(function(event) {            
            if (event.keyCode == 13) {
                if ("track_number_field" == document.activeElement.id) {
                    $("#gw_field").focus();
                }
                else if ("gw_field" == document.activeElement.id) {
                    $("#field_length").focus();
                }
                else if ("field_length" == document.activeElement.id) {
                    $("#field_width").focus();
                }
                else if ("field_width" == document.activeElement.id) {
                    $("#field_height").focus();
                }
                else if ("field_height" == document.activeElement.id) {
                    $timeout(function (){
                        $("#add_cargo_btn").focus();
                    });    
                }
            } 
            if (event.ctrlKey && (event.keyCode == 13 || event.keyCode == 107)) {
                event.preventDefault();
                addCargo();  
            }  
        });

        $scope.gwTtlChange = function(cargo) {
            if(cargo.pkgs && cargo.gwTtl){
                cargo.gw = DataUtils.round(cargo.gwTtl/cargo.pkgs, 2);
                vm.wmsIn.gw = 0;
                angular.forEach(vm.wmsIn.cargoList, function(data){
                    if(data.gwTtl){
                        vm.wmsIn.gw += data.gwTtl;
                    }
                })
                vm.wmsIn.gw = DataUtils.round(vm.wmsIn.gw, 2);
            }
        };

        $scope.volTtlChange = function(cargo) {
            if(cargo.pkgs && cargo.volTtl){
                cargo.vol = DataUtils.round(cargo.volTtl/cargo.pkgs, 3);
                vm.wmsIn.vol = 0;
                angular.forEach(vm.wmsIn.cargoList, function(data){
                    if(data.volTtl){
                        vm.wmsIn.vol += data.volTtl;
                    }
                })
                vm.wmsIn.vol = DataUtils.round(vm.wmsIn.vol, 3);
            }
        };
        function picsPop(cargos) {
            if(!vm.autoShowPic){
                return;
            }
            angular.forEach(vm.wmsIn.cargoList, function(cargo){
                angular.forEach(cargos, function(data){
                    if(data.id == cargo.id){
                        loadCargoFile(cargo);
                    }
                })
            })
            // i = 0;
            // while(i < 15)
            // {
                setTimeout(function() {
                    var files = [];
                    angular.forEach(vm.wmsIn.cargoList, function(cargo){
                        angular.forEach(cargos, function(data){
                            if(data.id == cargo.id && cargo.files){
                                angular.forEach(cargo.files, function (file) {
                                    if(DataUtils.isPic(file.filename)){
                                        files.push(file);
                                    }                               
                                })
                            }
                        })
                    })
                    if(files.length > 0){
                        $rootScope.PICS_POP(files);
                    }
                }, 1000);
            //     i++;
            // }

        }
        function loadCargoFile(cargo) {
            if(cargo.files){
                return;
            }
            cargo.files=[];
            $rootScope.LOADFILES(cargo.files, '/wmsCargo/' + cargo.id + '/', cargo.companyId);
        }

        function getCargosByRef() {
            if(!vm.refNum){
                return;
            }
            var wmsCargoFilter = {
                forWmsCargoIn: true,
                companyId: $rootScope.account.companyId,
                warehouseId: vm.wmsIn.warehouseId,
                clientId: vm.wmsIn.venderId
            }
            wmsCargoFilter[vm.searchType] = vm.refNum;
            WmsCargo.getCargosByRef(wmsCargoFilter, function(result){
                if(!result || result.length == 0){
                    $rootScope.ALERT_ALARM();
                    layer.msg($translate.instant('global.noRecord'));
                    return;
                }
                if(!vm.wmsIn.cargoList){
                    vm.wmsIn.cargoList = [];
                }
                angular.forEach(result, function (data) {
                    addCargo(data);
                })
                picsPop(result);
            })
        };


        function saveWmsCargoList() {
            if(!vm.wmsIn.cargoList || vm.wmsIn.cargoList.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            vm.isSaving = true;
            if(vm.wmsIn.venderId){
                angular.forEach(vm.wmsIn.cargoList, function (data) {
                    if(!data.clientId){
                        data.clientId = vm.wmsIn.venderId;
                        data.clientName = vm.wmsIn.clientName;
                    }
                })
            }
            WmsCargo.saveWmsCargoList({outDto: false}, vm.wmsIn.cargoList, function(result) {
                vm.wmsIn.cargoList = result;
                vm.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(){
                vm.isSaving = false;
                layer.msg($translate.instant('global.refreshAlert'));
            })
        }

        function returnRegistIn() {
            if(!vm.wmsIn.cargoList || vm.wmsIn.cargoList.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            for(var i = 0; i < vm.wmsIn.cargoList.length; i++){
                if (!vm.wmsIn.cargoList[i].location){
                    var msg = vm.wmsIn.cargoList[i].cargoName + ": " + $translate.instant('global.choose_location_alert');
                    $rootScope.OPS_ALERT(msg);
                    return;
                }     
            }
            vm.isSaving = true;
            layer.confirm($translate.instant('autopsApp.wmsIn.fiAlert'), {
                icon: 3,
                title: "Alert",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index); 
                layer.load(1, { shade: 0.3 });
                vm.isSaving = false;
                WmsCargo.returnRegistIn(vm.wmsIn.cargoList, function(result) {
                    vm.wmsIn.cargoList = result;
                    layer.closeAll();
                    layer.msg($translate.instant('global.messages.submitted'));
                }, function(){
                    layer.closeAll();
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                })
            }, function() {
                vm.isSaving = false;
            });
        }        

    }
})();