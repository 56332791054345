(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CustomDeclarationCtnsDeleteController',CustomDeclarationCtnsDeleteController);

    CustomDeclarationCtnsDeleteController.$inject = ['$uibModalInstance', 'entity', 'CustomDeclarationCtns'];

    function CustomDeclarationCtnsDeleteController($uibModalInstance, entity, CustomDeclarationCtns) {
        var vm = this;

        vm.customDeclarationCtns = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CustomDeclarationCtns.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
