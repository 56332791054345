(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('WriteOffRecords', WriteOffRecords);

    WriteOffRecords.$inject = ['$resource', 'DateUtils'];

    function WriteOffRecords ($resource, DateUtils) {
        var resourceUrl =  'api/write-off-records/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.writeOffDate = DateUtils.convertLocalDateFromServer(data.writeOffDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.writeOffDate = DateUtils.convertLocalDateToServer(data.writeOffDate);
                    return angular.toJson(data);
                }
            },
            'getAllWriteOffRecordsByRefId': {
                method: 'GET',
                url:'api/write-off-records-refid',
                isArray:true
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.writeOffDate = DateUtils.convertLocalDateToServer(data.writeOffDate);
                    return angular.toJson(data);
                }
            }
        });
    }
})();
