
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CommodityDatabaseDetailController', CommodityDatabaseDetailController);

    CommodityDatabaseDetailController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$translate', '$uibModal', 
    'entity', 'CommodityDatabase', 'Dictionary'];

    function CommodityDatabaseDetailController ($timeout, $scope, $stateParams, $uibModalInstance, $translate, $uibModal, 
        entity, CommodityDatabase, Dictionary) {
        var vm = this;

        vm.commodityDatabase = entity;
        vm.clear = clear;
        vm._onCargoSelected = _onCargoSelected;
        vm.findOneByHscode = findOneByHscode;
        vm.saveToDatabase = saveToDatabase;

        $timeout(function (){
            angular.element('#field_commodity').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function _onCargoSelected (item, data) {
            data.hsCode = item.hsCode;
            data.cargoName = item.cargoName;
            data.cargoNameEn = item.cargoNameEn;
            data.customsUnit = item.customsUnit;
            data.invoiceUnit = item.invoiceUnit;
            data.customsSuperviseCondition = item.customsSuperviseCondition;
            data.inspectionCategory = item.inspectionCategory;
            data.commodityInspectionRequired = (item.inspectionCategory && item.inspectionCategory != "无");
            data.declareElement = item.declareElement;
            data.taxRebateRate = item.taxRebateRate;
            data.exportDutyRate = item.exportDutyRate;
            data.otherInfo = item.otherInfo;     
            getCodeInfo();      

            // data.certificateType = null;
            // data.material = null;
            // data.materialEn = null;
            // data.useAge = null;
            // data.useAgeEn = null;
        }
        vm.customsSuperviseCondition = "";
        vm.inspectionCategory = "";
        function getCodeInfo () {
            vm.customsSuperviseCondition = "";
            angular.forEach(Dictionary.customsSuperviseConditions, function(data){
                if(vm.commodityDatabase.customsSuperviseCondition.indexOf(data.code) !== -1){
                    vm.customsSuperviseCondition = vm.customsSuperviseCondition +  "(" + data.code + ")" + data.name + "\n";
                }
            }) 
            vm.inspectionCategory = "";
            angular.forEach(Dictionary.inspectionCategorys, function(data){
                if(vm.commodityDatabase.inspectionCategory.indexOf(data.code) !== -1){
                    vm.inspectionCategory = vm.inspectionCategory +  "(" + data.code + ")" + data.name + "\n";
                }
            })
        }
        function findOneByHscode (item) {
            CommodityDatabase.findOneByHscode({hscode: item.key}, function(result){
                _onCargoSelected(result, vm.commodityDatabase);
                if(!vm.commodityDatabase.hsCode){
                    vm.commodityDatabase.hsCode = item.key;
                    $rootScope.OPS_ALERT(item.key + ": Is Not Exist!");
                }
            });
        }
        function saveToDatabase () {
            $uibModal.open({
                templateUrl: 'app/entities/commodity-database/commodity-database-dialog.html',
                controller: 'CommodityDatabaseDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                resolve: {
                    entity: function () {
                        return angular.copy(vm.commodityDatabase);
                    }
                }
            }).result.then(function() {
            }, function() {
            });
        }

        
    }
})();
