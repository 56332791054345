(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('OpsColoader', OpsColoader);

    OpsColoader.$inject = ['$resource','DateUtils'];
    
    function OpsColoader($resource, DateUtils) {
        return $resource('api/opsColoaders/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    data.lastModifiedTime = DateUtils.convertDateTimeFromServer(data.lastModifiedTime);
                    return data;
                }
            },
            'getContactsList': {
                method:'GET',
                url:'api/ops-coloader/contactList',
                isArray:true
            },
            'getAll': {
                method:'GET',
                url:'api/opsColoadersAll',
                isArray:true
            },
            'update': { method:'PUT' },
        });
    }
})();
