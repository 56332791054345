(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('GuideRatesSearch', GuideRatesSearch);

    GuideRatesSearch.$inject = ['$resource'];
    
    function GuideRatesSearch($resource) {
        return $resource('api/_search/guideRatess/:query', {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
