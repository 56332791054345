(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ExpressVirtualController', ExpressVirtualController);

    ExpressVirtualController.$inject = ['$http', '$scope', '$rootScope', '$state', '$translate', '$uibModal', '$location', 'Principal', 'WinAES',
    'ExpressParcel', 'ExpressParcelSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'OpsTrackStatus', 'DataUtils'];

    function ExpressVirtualController ($http, $scope, $rootScope, $state, $translate, $uibModal, $location, Principal, WinAES,
        ExpressParcel, ExpressParcelSearch, ParseLinks, AlertService, pagingParams, paginationConstants, OpsTrackStatus, DataUtils) {

        var vm = this;
        vm.deleteFromList = deleteFromList;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.searchPop = searchPop;
        vm.isAdvanceSearch = false;
        vm.createNew = createNew;
        vm.bindAccount = bindAccount;

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        }

        if ($location.path().indexOf("tms") >= 0){
            $rootScope.ISTMS = true;
        }
        else{
            $rootScope.ISTMS = false;
        }

        function loadAll () {
            var shipmentType = "EXPRESS";
            if ($rootScope.ISTMS){
                shipmentType = "TMS";
            }

            if (pagingParams.search) {
                ExpressParcelSearch.queryVirtual({
                    type: shipmentType,
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                ExpressParcel.queryVirtual({
                    type: shipmentType,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'createdTime') {
                    result.push('createdTime');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.expressParcels = data;
                vm.page = pagingParams.page;
                initGuidesList();
                vm.isAdvanceSearch = false;
                setTimeout(function() {frzTable("divTable", "showTable", "footPager");}, 100);
            }
            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }
        loadAll();

        function initGuidesList (page) {
            angular.forEach(vm.expressParcels, function(data){
                data.encodeId = WinAES.encode(data.id.toString());
                DataUtils.fillFdLabel(data);
            })
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'createdTime';
            vm.reverse = true;
            vm.currentSearch = null;
            if (vm.isAdvanceSearch){
                vm.itemsPerPage = paginationConstants.itemsPerPage;
                vm.isAdvanceSearch = false;
                loadAll();
            }
            else {
                vm.transition();
            }
        }

        function onSaveSuccess (result) {
            layer.msg($translate.instant('global.messages.submitted'));
        }
        function onSaveError (result) {
            layer.msg($translate.instant('global.messages.submittedfailed'));
        }
        var memoCopy;
        $scope.copyMemo = function(guide) {
            memoCopy = angular.copy(guide.memo);
        }
        $scope.saveMemo = function(guide) {
            if(memoCopy != guide.memo){
                ExpressParcel.upateMemo({
                    memoString: guide.memo,
                    guideId: guide.id
                }, onSaveSuccess, onSaveError);
            }
        }

        function searchPop () {
            var searchParams = {
                submited : true,
                isVirtual : true,
                shipmentType: $rootScope.ISTMS?'TMS':'PARCEL',
                companyId: $rootScope.account.companyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/express-parcel/express-parcel-detail.html',
                controller: 'ExpressParcelDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(results) {
                vm.currentSearch = true;
                vm.expressParcels = results;
                initGuidesList();
                vm.totalItems = vm.expressParcels.length;
                vm.itemsPerPage = vm.expressParcels.length;
                vm.queryCount = vm.totalItems;
                vm.page = 1;
                vm.isAdvanceSearch = true;
                setTimeout(function() {frzTable("divTable", "showTable", "footPager");}, 100);
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }
        function deleteFromList (index) {
            vm.expressParcels.splice(index, 1);
            setTimeout(function() {frzTable("divTable", "showTable", "footPager");}, 100);
        }

        $scope.checkedId = null;
        $scope.setCheckedId = function(id) {
            $scope.checkedId = id;
        }

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        $scope.setStatus = function(guide, status) {
            if(!$rootScope.account.companyId || status == "isClosed"){
                return;
            }
            if (guide.isClosed || (status != "isVoid" && guide.isVoid)){
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if (status == "received" || status == "paid"){
                layer.msg($translate.instant('global.systemMgmt'));
                return;
            }
            ExpressParcel.setStatus({
                expressId: guide.id,
                status: status
            }, function(result) {
                updateStatusTime(guide, result);
                $translate.refresh();
                layer.msg($translate.instant('global.save_success'));
            }, function(result) {
                if (result.status == 403){
                    layer.msg($translate.instant('global.forbiddenBranch'));
                }
                else if (result.status == 409){
                    layer.msg($translate.instant('autopsApp.guide.ratesNotNullAlert'));
                }
                else {
                    layer.msg($translate.instant('global.save_fail'));
                }

            });
        };

        function updateStatusTime (guide, result) {
            guide.submited = result.submited;
            guide.jobNum = result.jobNum;
            guide.status = result.status;
            guide.dnSentTime = result.dnSentTime;
            guide.isClosed = result.isClosed;
            guide.isVoid = result.isVoid;
            guide.dnSentTime = result.dnSentTime;
            guide.problemShipment = result.problemShipment;
            guide.otherTimeOne = result.otherTimeOne;
            guide.otherTimeTwo = result.otherTimeTwo;
            guide.otherTimeThree = result.otherTimeThree;
            guide.customReleaseTime = result.customReleaseTime;
            guide.deliveryTime = result.deliveryTime;
            guide.podTime = result.podTime;
            guide.costInputTime = result.costInputTime;
        }

        $scope.setSubmited = function(guide, status) {
            ExpressParcel.setSubmited({
                id: guide.id
            }, function(result) {
                updateStatusTime(guide, result);
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(result) {
                layer.msg($translate.instant('global.save_fail'));
            });
        };


        var unsubscribe = $rootScope.$on('autopsApp:expressParcelUpdate', function(event, result) {
            if(result.id){
                angular.forEach(vm.expressParcels, function(guide){
                    if(guide.id == result.id){
                        guide.status = result.status;
                        guide.problemShipment = result.problemShipment;
                        guide.isVoid = result.isVoid;
                        guide.isClosed = result.isClosed;
                        guide.lastModifiedTime = result.lastModifiedTime;
                        guide.customArrangeTime = result.customArrangeTime;
                        guide.paymentPaidTime = result.paymentPaidTime;
                        guide.dnSentTime = result.dnSentTime;
                        guide.paymentReceivedTime = result.paymentReceivedTime;
                        guide.otherTimeOne = result.otherTimeOne;
                        guide.otherTimeTwo = result.otherTimeTwo;
                        guide.otherTimeThree = result.otherTimeThree;
                        guide.customReleaseTime = result.customReleaseTime;
                        guide.shipmentType = result.shipmentType;
                        guide.soNum = result.soNum;
                        guide.shipperName = result.shipperName;
                        guide.etd = result.etd;
                        guide.carrier = result.carrier;
                        guide.mblNum = result.mblNum;
                        guide.poNum = result.poNum;
                        guide.salesName = result.salesName;
                        guide.opName = result.opName;
                        guide.deliveryName = result.deliveryName;
                        guide.bookingToName = result.bookingToName;
                        guide.createdTime = result.createdTime;
                        guide.pkgNum = result.pkgNum;
                        guide.pkgType = result.pkgType;
                        guide.gw = result.gw;
                        guide.vol = result.vol;
                        guide.vw = result.vw;
                        guide.sellAmt = result.sellAmt;
                        guide.sellCur = result.sellCur;
                        guide.collect = result.collect;
                        guide.collectCur = result.collectCur;
                        guide.shipfromCode = result.shipfromCode;
                        guide.shiptoCode = result.shiptoCode;
                        guide.cargoType = result.cargoType;
                        guide.goodsDiscription = result.goodsDiscription;
                        guide.tradeTerm = result.tradeTerm;
                        guide.cargoValue = result.cargoValue;
                        guide.warehouseName = result.warehouseName;
                        guide.cargoType = result.cargoType;
                    }
                })
            }
            else {
                loadAll();
            }
        });
        $scope.$on('$destroy', unsubscribe);

        var isReverse = true;
        $scope.sortGuides = function(title) {
            isReverse = !isReverse;
            vm.expressParcels.sort(function(a,b){
                if (isReverse){
                    return a[title]<b[title]?1:-1;
                }
                else {
                    return a[title]>b[title]?1:-1;
                }
            });
            setTimeout(function() {frzTable("divTable", "showTable", "footPager");}, 100);
        }

        var rateUnsubscribe = $rootScope.$on('autopsApp:guideRatesUpdate', function(event, result) {
            if(result && result.id){
                angular.forEach(vm.expressParcels, function(guide){
                    if(guide.id == result.id){
                        guide.etd = result.etd;
                    }
                })
            }
        });
        $scope.$on('$destroy', rateUnsubscribe);

        function createNew (type) {
            $uibModal.open({
                templateUrl: 'app/entities/express-parcel/express-parcel-dialog.html',
                controller: 'ExpressParcelDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: function () {
                        var newBiz = {};
                        newBiz.status = 'new';
                        newBiz.divVolBy = type == 'LTL'?null:6000;
                        newBiz.payParty = 'shipfrom';
                        newBiz.shipmentType = type;
                        newBiz.cargoIsOut = false;
                        newBiz.isVirtual = true;
                        newBiz.expressCargos = [];
                        return newBiz;
                    }
                }
            }).result.then(function() {
                loadAll();
            }, function() {
                loadAll();
            });
        }


        function bindAccount (bindType) {
            $uibModal.open({
                templateUrl: 'app/entities/account-bind/account-bind-detail.html',
                controller: 'AccountBindDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: function () {
                        return bindType;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountBind');
                        return $translate.refresh();
                    }],
                }
            }).result.then(function() {
                loadAll();
            }, function() {
                loadAll();
            });
        }
    }
})();
