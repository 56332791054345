(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('OpsCost', OpsCost);

    OpsCost.$inject = ['$resource', '$location', 'DateUtils'];

    function OpsCost ($resource, $location, DateUtils) {
        var resourceUrl =  'api/ops-costs/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    var result = angular.fromJson(data);
                    if(result.length>0){
                        for(var i = 0; i < result.length; i++){
                            result[i].effectiveDate = DateUtils.convertLocalDateFromServer(result[i].effectiveDate);
                            result[i].expireDate = DateUtils.convertLocalDateFromServer(result[i].expireDate);
                            result[i].createdTime = DateUtils.convertDateTimeFromServer(result[i].createdTime);
                        }
                    }
                    return result;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.effectiveDate = DateUtils.convertLocalDateFromServer(data.effectiveDate);
                        data.expireDate = DateUtils.convertLocalDateFromServer(data.expireDate);
                        data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    }
                    return data;
                }
            },
            'getOneByIdentification': {
                method: 'GET',
                url:'api/ops-costs/getOneByIdentification',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.effectiveDate = DateUtils.convertLocalDateFromServer(data.effectiveDate);
                        data.expireDate = DateUtils.convertLocalDateFromServer(data.expireDate);
                        data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.effectiveDate = DateUtils.convertLocalDateToServer(data.effectiveDate);
                    data.expireDate = DateUtils.convertLocalDateToServer(data.expireDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.effectiveDate = DateUtils.convertLocalDateFromServer(data.effectiveDate);
                        data.expireDate = DateUtils.convertLocalDateFromServer(data.expireDate);
                        data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    }
                    return data;
                }
            },
            'saveAll': {
                method: 'POST',
                url:'api/ops-costs/saveAll',
                isArray:true,
                transformRequest: function (data) {
                    angular.forEach(data, function(cost) {
                        cost.effectiveDate = DateUtils.convertLocalDateToServer(cost.effectiveDate);
                        cost.expireDate = DateUtils.convertLocalDateToServer(cost.expireDate);
                    })
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        angular.forEach(data, function(cost) {
                            cost.effectiveDate = DateUtils.convertLocalDateFromServer(cost.effectiveDate);
                            cost.expireDate = DateUtils.convertLocalDateFromServer(cost.expireDate);
                            cost.createdTime = DateUtils.convertDateTimeFromServer(cost.createdTime);
                        })
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.effectiveDate = DateUtils.convertLocalDateToServer(data.effectiveDate);
                    data.expireDate = DateUtils.convertLocalDateToServer(data.expireDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.effectiveDate = DateUtils.convertLocalDateFromServer(data.effectiveDate);
                        data.expireDate = DateUtils.convertLocalDateFromServer(data.expireDate);
                        data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    }
                    return data;
                }
            },
            'getBookingAgent':{
                method:'PUT',
                url:'api/ops-costs/getBookingAgent',
                isArray:false
            },
            'getProductNames':{
                method:'PUT',
                url:'api/ops-costs/getProductNames',
                isArray:true
            },
            'getWmsAndPols':{
                method:'GET',
                url:'api/ops-costs/getWmsAndPols',
                isArray:true
            },
            'getGuideByOpsCost':{
                method:'GET',
                url:'api/ops-costs/getGuideByOpsCost'
            },
            'getChannels':{
                method:'GET',
                url:'api/ops-costs/getChannels',
                isArray:true
            },
            'getProducts':{
                method:'GET',
                url:'api/ops-costs/getProducts',
                isArray:true
            },
            'jsDebug':{
                method:'PUT',
                url:'api/ops-costs/jsDebug'
            },
            'loadFiles':{
                method:'GET',
                url:'api/ops-costs/loadFiles',
                isArray:true
            },
            'getPorts':{
                method:'GET',
                url:'api/ops-costs/ports',
                isArray:true
            },
            'checkAddress':{
                method:'PUT',
                url:'api/ops-costs/checkAddress'
            },
            'refreshRates':{
                method:'PUT',
                url:'api/ops-costs/refreshRates'
            },
            'guideTryCost':{
                method:'PUT',
                url:'api/ops-costs/guideTryCost',
                isArray:true
            },
            'fmsTryCost':{
                method:'PUT',
                url:'api/ops-costs/fmsTryCost',
                isArray:true
            },
            'findRates':{
                method:'PUT',
                url:$location.host().indexOf("beta.") >= 0?'https://huodai-oa.qdstsy.cn/api/edi/ops-costs/findRates':'https://huodai.qdstsy.cn/api/edi/ops-costs/findRates',
                isArray:true
            },
            'crmEnquiryTrial':{
                method:'PUT',
                url:'api/ops-costs/crmEnquiryTrial',
                isArray:true
            },
            'getTrialCostDTO':{
                method:'PUT',
                url:'api/ops-costs/getTrialCostDTO'
            },
            'deleteAll':{
                method:'PUT',
                url:'api/ops-costs/deleteAll'
            },
            'advanceSearch':{
                method:'PUT',
                url:'api/ops-costs/advanceSearch',
                transformRequest: function (data) {
                    data.effectiveDateFrom = DateUtils.convertLocalDateToServer(data.effectiveDateFrom);
                    data.effectiveDateTo = DateUtils.convertLocalDateToServer(data.effectiveDateTo);
                    data.expireDateFrom = DateUtils.convertLocalDateToServer(data.expireDateFrom);
                    data.expireDateTo = DateUtils.convertLocalDateToServer(data.expireDateTo);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        angular.forEach(data, function(cost) {
                            cost.effectiveDate = DateUtils.convertLocalDateFromServer(cost.effectiveDate);
                            cost.expireDate = DateUtils.convertLocalDateFromServer(cost.expireDate);
                            cost.createdTime = DateUtils.convertDateTimeFromServer(cost.createdTime);
                        })
                    }
                    return data;
                },
                isArray:true
            },
            'getAllPorts':{
                method:'GET',
                url:'api/ops-costs/getAllPorts',
                isArray:false
            },
            'getApiPorts':{
                method:'POST',
                url:$location.host().indexOf("beta.") >= 0?'https://huodai-oa.qdstsy.cn/api/edi/ops-costs/getApiPorts':'https://huodai.qdstsy.cn/api/edi/ops-costs/getApiPorts',
                isArray:false
            },
            'deleteAllByType':{
                method:'DELETE',
                url:'api/ops-costs/deleteAllByType'
            },
            'deleteAllExpired':{
                method:'DELETE',
                url:'api/ops-costs/deleteAllExpired'
            },
            'findRatesCom':{
                method:'PUT',
                url:'api/ops-costs/findRatesCom',
                isArray:true,
                transformResponse: function (data) {
                    if(!data){
                        return;
                    }
                    var result = angular.fromJson(data);
                    if(result.length>0){
                        for(var i = 0; i < result.length; i++){
                            result[i].effectiveDate = DateUtils.convertLocalDateFromServer(result[i].effectiveDate);
                            result[i].expireDate = DateUtils.convertLocalDateFromServer(result[i].expireDate);
                            result[i].createdTime = DateUtils.convertDateTimeFromServer(result[i].createdTime);
                        }
                    }
                    return result;
                }
            },
            'findPrices':{
                method:'GET',
                url:'api/ops-costs/findPrices',
                isArray:true,
                transformResponse: function (data) {
                    if(!data){
                        return;
                    }
                    var result = angular.fromJson(data);
                    if(result.length>0){
                        for(var i = 0; i < result.length; i++){
                            result[i].effectiveDate = DateUtils.convertLocalDateFromServer(result[i].effectiveDate);
                            result[i].expireDate = DateUtils.convertLocalDateFromServer(result[i].expireDate);
                            result[i].createdTime = DateUtils.convertDateTimeFromServer(result[i].createdTime);
                        }
                    }
                    return result;
                }
            },
            'findOneByProduct':{
                method:'GET',
                url:'api/ops-costs/findOneByProduct',
                transformResponse: function (data) {
                    if(!data){
                        return;
                    }
                    var result = angular.fromJson(data);
                    result.effectiveDate = DateUtils.convertLocalDateFromServer(result.effectiveDate);
                    result.expireDate = DateUtils.convertLocalDateFromServer(result.expireDate);
                    result.createdTime = DateUtils.convertDateTimeFromServer(result.createdTime);
                    return result;
                }
            },
            'getCount': {
                method: 'GET',
                url:'api/ops-costs/getCount'
            },
            'getPriceCount': {
                method: 'GET',
                url:'api/ops-costs/getPriceCount'
            },
            'generateSelling': {
                method: 'POST',
                url:'api/ops-costs/generateSelling'
            }

            
        });
    }
})();
