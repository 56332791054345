(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('SplitCtnController', SplitCtnController);

    SplitCtnController.$inject = ['$scope', '$timeout', '$uibModalInstance', 'entity', 'DataUtils'];

    function SplitCtnController($scope, $timeout, $uibModalInstance, entity, DataUtils) {
        var vm = this;
        vm.splitCtn = angular.copy(entity);
        vm.newCtn = angular.copy(entity);
        vm.balanceCtn = {};

        $timeout(function (){
            vm.newCtn.id = null;
            vm.newCtn.booking = null;
            vm.newCtn.guideId = null;
            vm.newCtn.truckerId = null;
            vm.newCtn.companyId = null;
            vm.newCtn.createdBy = null;

            vm.balanceCtn = angular.copy(vm.newCtn);

            vm.newCtn.pkgs = 0;
            vm.newCtn.gw = 0;
            vm.newCtn.vol = 0;

            $scope.numChange();
        })

        $scope.clear = function () {
            $uibModalInstance.dismiss('cancel');            
        }
        $scope.confirm = function () {
            var result = {};
            result.newCtn = vm.newCtn;
            result.balanceCtn = vm.balanceCtn;
            $uibModalInstance.close(result);            
        }
        $scope.numChange = function () {
            vm.balanceCtn.pkgs = DataUtils.round(vm.splitCtn.pkgs - vm.newCtn.pkgs, 0);
            vm.balanceCtn.gw = DataUtils.round(vm.splitCtn.gw - vm.newCtn.gw, 2);
            vm.balanceCtn.vol = DataUtils.round(vm.splitCtn.vol - vm.newCtn.vol, 3);
        }
    }
})();