(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CrmQuotationDetailController', CrmQuotationDetailController);

    CrmQuotationDetailController.$inject = ['$rootScope', '$scope', '$uibModalInstance', '$translate', 'entity', 'OpsVender', 'Account'];

    function CrmQuotationDetailController($rootScope, $scope, $uibModalInstance, $translate, entity, OpsVender, Account) {
        var vm = this;

        vm.contact = entity;
        vm.clear = clear;
        vm.doGetLogin = doGetLogin;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CrmQuotation.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }

        var onGetLoginSuccess = function(result) {
            angular.copy(result, vm.contact);
            $scope.isSaving = false;
            layer.msg($translate.instant('autopsApp.opsClient.openAccountSuccess'));
        };

        var onGetLoginError = function(result) {
            $scope.isSaving = false;
            if (result.status == 403) {
                layer.msg($translate.instant('autopsApp.opsClient.login_inuse'));
            } else if (result.status == 400) {
                layer.msg($translate.instant('user-management.selfEmailAlert'));
            } else if (result.status == 409) {
                layer.msg($translate.instant('autopsApp.opsClient.email_inuse'));
            } else {
                layer.msg($translate.instant('autopsApp.opsClient.openAccountError'));
            }
        };

        function doGetLogin () {
            if ($rootScope.HAVENOAUTH('SUB_CLIENT') && $rootScope.HAVENOAUTH('SUB_AGENT') && $rootScope.HAVENOAUTH('SUB_TRUCKER')) {
                return;
            }
            if (!vm.contact.login || !vm.contact.email) {
                layer.msg($translate.instant('autopsApp.opsClient.emailOrLoginNull'));
                return;
            }            
            var myreg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
            if (!myreg.test(vm.contact.email)) {
                var alertContent = vm.contact.email + ": " + $translate.instant('global.messages.validate.email.invalid');
                layer.msg(alertContent);
                return;
            }

            $scope.isSaving = true;
            OpsVender.getLogin(vm.contact, onGetLoginSuccess, onGetLoginError);
        }


        $scope.setLoginDisable = function() {
            OpsVender.loginDisabled(vm.contact, function(result) {
                layer.msg($translate.instant('global.save_success'));
                vm.contact.loginDisabled = result.loginDisabled;
            }, function(result) {
                layer.msg($translate.instant('global.save_fail'));
            });
        };

        $scope.setIsSuper = function(contact) {
            OpsVender.setIsSuper(vm.contact, function(result) {
                layer.msg($translate.instant('global.save_success'));
            }, function(result) {
                layer.msg($translate.instant('global.save_fail'));
            });
        }

        $scope.initBizBySubSysUser = function() {
            layer.confirm($translate.instant('autopsApp.opsClient.initBizBySubSysUser'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                OpsVender.initBizBySubSysUser({contactListId: vm.contact.id}, function(result) {
                    layer.msg($translate.instant('global.update_success'));
                }, function(result) {
                    layer.msg($translate.instant('global.update_fail'));
                });
            }, function() {
            });
        }

    }
})();
