(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('WmsArea', WmsArea);

    WmsArea.$inject = ['$resource'];

    function WmsArea ($resource) {
        var resourceUrl =  'api/wms-areas/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAllWarehouses': { 
                method: 'GET', 
                url:'api/wms-area-warehouses',
                isArray:true
            },
            'update': { method:'PUT' }
        });
    }
})();
