(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CrmTaskDetailController', CrmTaskDetailController);

    CrmTaskDetailController.$inject = ['$scope', '$rootScope', '$stateParams', '$translate', '$uibModal',
        'previousState', 'entity', 'CrmTask', 'CrmReport'];

    function CrmTaskDetailController($scope, $rootScope, $stateParams, $translate, $uibModal,
        previousState, entity, CrmTask, CrmReport) {
        var vm = this;

        vm.crmTask = entity;
        vm.previousState = previousState.name;
        vm.reportsFilter = reportsFilter;
        vm.saveRating = saveRating;
        vm.files = [];
        vm.edit = edit;

        function saveRating (report) {
            CrmReport.update(report, onSaveSuccess, onSaveError);
        }
        var onSaveSuccess = function (result) {
            layer.msg($translate.instant('global.save_success'));
        };

        var onSaveError = function (result) {
            layer.msg($translate.instant('global.save_fail')); 
        };

        function reportsFilter (report) {
            return report.reportTime != null;
        }
        var unsubscribe = $rootScope.$on('autopsApp:crmTaskUpdate', function(event, result) {
            vm.crmTask = result;
        });
        $scope.$on('$destroy', unsubscribe);

        function edit (reportId) {
            $uibModal.open({
                templateUrl: 'app/entities/crm-task/crm-task-dialog.html',
                controller: 'CrmTaskDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: ['CrmTask', function(CrmTask) {
                        return CrmTask.get({id : reportId}).$promise;
                    }]
                }
            }).result.then(function(result) {
                vm.crmTask = result;
            }, function() {
            });
        }


    }
})();
