(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountTitleDialogController', AccountTitleDialogController);

    AccountTitleDialogController.$inject = ['$scope', '$rootScope', '$compile', '$uibModalInstance', '$translate', '$timeout', 'entity', 'AccountTitle', 'CashFlowSubject','JhiLanguageService'];

    function AccountTitleDialogController($scope, $rootScope, $compile, $uibModalInstance, $translate, $timeout, entity, AccountTitle, CashFlowSubject, JhiLanguageService) {
        var vm = this;        
        vm.addOne = addOne;
        vm.editOne = editOne;
        vm.delOne = delOne;
        vm.getAddCode = getAddCode;
        vm.shareOne = shareOne;
        vm.isFreezedChange = isFreezedChange;
        var filterType = (entity.accountTitle && entity.accountTitle.type) ? entity.accountTitle.type : 'asset';

        $scope.accountTitles=[];        

        $scope.isAdd = false;
        $scope.isEdit = false;
        $scope.fmVoucher = entity.fmVoucher;
        
        var langKey = "";

        var zTreeObj;
        var categorySetting = {
            check : {
                enable : false
            },
            // edit: {
            //     enable: false,
            //     showRemoveBtn: setShowRemove,
            //     showRenameBtn: false,
            //     showAddOneBtn: false,
            //     removeTitle: "删除此节点科目"
            // },
            callback: {
                onClick:onClick
            },
            view: {
                addHoverDom: addHoverDom,
                removeHoverDom: removeHoverDom
            }
        };
        $timeout(function (){
            loadAccountTitles();
            if(entity.accountTitle){     
                if(entity.isAdd == null){
                    $scope.accountTitle = entity.accountTitle;
                }        
                else if(entity.isAdd){
                    doAddOne(entity.accountTitle);
                }
                else {
                    doEditOne(entity.accountTitle);
                }
            }
        });
        var treeNodeCopy = {};
        function addHoverDom(treeId, treeNode){
            treeNodeCopy = treeNode;
            if ($("#diyBtnGroup").length>0) {
                return; 
            }
            var editStr = '<span id="diyBtnGroup">' 
            + '<button class="btn btn-link" ng-click="vm.addOne()" style="margin-left: 5px;"><span translate="entity.action.add">add</span></button>' 
            + '<button class="btn btn-link" ng-click="vm.editOne()"><span translate="entity.action.amendation">amendation</span></button>'
            + '<button class="btn btn-link" ng-click="vm.delOne()"><span translate="entity.action.delete">delete</span></button></span>';
            $("#" + treeNode.tId + "_a").append(editStr);

            $compile($('#diyBtnGroup'))($scope);
        }

        function removeHoverDom(treeId, treeNode){
            $("#diyBtnGroup").unbind().remove();
        }
        
        function getAccountTitleByTreeNode(treeNode){
            for(var i = 0; i < $scope.accountTitles.length; i++){
                if($scope.accountTitles[i].code == treeNode.id){
                    return $scope.accountTitles[i];
                }
            }
        }

        function onClick(event, treeId, treeNode) {
            $scope.isAdd = false;
            $scope.isEdit = false;
            $scope.accountTitle = getAccountTitleByTreeNode(treeNode);
            $translate.refresh();
        }
        function delOne(){
            var accountTitle = getAccountTitleByTreeNode(treeNodeCopy);
            if(!accountTitle){
                return;
            }
            if (accountTitle.level == 1 || !accountTitle.isLast) {
                layer.msg($translate.instant('autopsApp.accountTitle.delLastOnlyAlert'));
                return;
            }
            if (accountTitle.code == "4103" || accountTitle.code == "6401" || accountTitle.code == "6001" || accountTitle.code == "660302" || accountTitle.code == "660301" || accountTitle.code == "660303") {
                layer.msg($translate.instant('autopsApp.accountTitle.sys_used'));
                return;
            }
            var delContent = $translate.instant('autopsApp.accountTitle.delete.question', {
                id: accountTitle.code
            });
            layer.confirm(delContent, {
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                AccountTitle.delete({
                        id: accountTitle.id
                    },
                    function(result) {
                        layer.msg($translate.instant('global.delete_success'));
                        layer.close(index);
                        zTreeObj.removeNode(treeNodeCopy);
                        for(var i = $scope.accountTitles.length -1; i >= 0; i--){
                            if($scope.accountTitles[i].code == treeNodeCopy.id){
                                $scope.accountTitles.splice(i, 1);
                            }
                        }
                    },
                    function(response) {
                        if (response.status == 403) {
                            layer.msg($translate.instant('autopsApp.accountTitle.this_used'));
                        } 
                        else if (response.status == 409) {
                            layer.msg($translate.instant('autopsApp.accountTitle.delLastOnlyAlert'));
                        } else {
                            layer.msg($translate.instant('global.delete_fail'));
                        }
                        layer.close(index);
                        return
                    });

            }, function() {

            });
        }
        
        function addOne () {
            if ($rootScope.HAVENOAUTH('ROLE_DEPARTMENT')) {
                layer.msg($translate.instant('global.forbiddenTeam'));
                return;
            }            
            var accountTitle = getAccountTitleByTreeNode(treeNodeCopy);
            if (!accountTitle) {
                return;
            }
            if (accountTitle.type == "settlement") {
                layer.msg($translate.instant('autopsApp.accountTitle.home.creatSettlementAlert'));
                return;
            }
            if (accountTitle.level > 4) {
                layer.msg($translate.instant('autopsApp.accountTitle.home.sixAlert'));
                return;
            }
            if (accountTitle.code == "4103" || accountTitle.code == "6401" || accountTitle.code == "6001" || accountTitle.code == "660302" || accountTitle.code == "660301" || accountTitle.code == "660303") {
                layer.msg($translate.instant('autopsApp.accountTitle.sys_used'));
                return;
            }
            AccountTitle.get({id: accountTitle.id}, function(result){
                doAddOne(result);  
            });
        };
        function isFreezedChange(accountTitle) {
            if (accountTitle.isFreezed && (accountTitle.code == "4103" || accountTitle.code == "6401" || accountTitle.code == "6001" || accountTitle.code == "660302" || accountTitle.code == "660301" || accountTitle.code == "660303")) {
                layer.msg($translate.instant('autopsApp.accountTitle.sys_used'));
                accountTitle.isFreezed = !accountTitle.isFreezed;
            }
        }      
        var fatherTitle;
        function doAddOne(accountTitle) {
            fatherTitle = angular.copy(accountTitle);
            $scope.isAdd = true;
            $scope.isEdit = true;
            $scope.accountTitle = {
                level: fatherTitle.level + 1,
                code: fatherTitle.code,
                addCode: null,
                cnName: null,
                enName: null,
                type: fatherTitle.type,
                format: fatherTitle.format,
                isFreezed: false,
                abb: null,
                isLast: true,
                currency: fatherTitle.currency,
                isDn: fatherTitle.isDn,
                isForeign: fatherTitle.isForeign,
                isCash: fatherTitle.isCash,
                companyId: $rootScope.account.companyId,
                id: null
            };
            if (fatherTitle.level == 1) {
                $scope.lenLimit = 2;
            } else {
                $scope.lenLimit = 4;
            }
            getAddCode();
            $translate.refresh();
        }

        function getAddCode(){
            var lastCode = null;
            var parentAcount = null;
            angular.forEach($scope.accountTitles, function(data){
                if(data.code.indexOf($scope.accountTitle.code) == 0 && data.level == $scope.accountTitle.level){
                    if(!lastCode || lastCode < data.code){
                        lastCode = data.code;
                    }
                }
                if($scope.accountTitle.code && $scope.accountTitle.code.indexOf(data.code) == 0 && data.level == $scope.accountTitle.level - 1){
                    parentAcount = langKey == 'en' ? data.code+ ' '+ data.enName : data.code+ ' '+data.cnName
                }
            })
            if(!lastCode){
                lastCode = 0;
            }
            else {
                lastCode = lastCode.replace($scope.accountTitle.code, "");
            }
            lastCode++;
            lastCode = lastCode + "";
            var currentLen = lastCode.length;
            
            if(currentLen > $scope.lenLimit){
                $scope.accountTitle.addCode = null;
            }     
            else {
                if (currentLen < $scope.lenLimit) {
                    for (var i = currentLen; i < $scope.lenLimit; i++) {
                        lastCode = "0" + lastCode;
                    }
                }
                $scope.accountTitle.addCode = lastCode;
            }       
            $scope.accountTitle.parentAcount = parentAcount;
        }

        function editOne() {
            if ($rootScope.HAVENOAUTH('ROLE_DEPARTMENT')) {
                layer.msg($translate.instant('global.forbiddenTeam'));
                return;
            }
            var accountTitle = getAccountTitleByTreeNode(treeNodeCopy);
            doEditOne(accountTitle);
        };
        function doEditOne(accountTitle) {
            if (accountTitle.type == "settlement") {
                layer.msg($translate.instant('autopsApp.accountTitle.home.creatSettlementAlert'));
                return;
            }
            AccountTitle.get({id: accountTitle.id}, function(result){
                $scope.accountTitle = result;
                $scope.isAdd = false;
                $scope.isEdit = true;
                $translate.refresh();
            });
        };

        JhiLanguageService.getCurrent().then(function(language) {
            langKey = language;
        });

        var onSaveSuccess = function(result) {
            $scope.$emit('autopsApp:accountTitleUpdate', result);
            $scope.isSaving = false;
            layer.msg($translate.instant('global.save_success'));
            $scope.accountTitle = result;
            $scope.isAdd = false;
            $scope.isEdit = false;
            if(treeNodeCopy && treeNodeCopy.id){
                if(result.code == treeNodeCopy.id){
                    treeNodeCopy.name = langKey == 'en' ? result.code+ ' '+ result.enName : result.code+ ' '+result.cnName;
                    zTreeObj.updateNode(treeNodeCopy);
                    angular.forEach($scope.accountTitles, function(data){
                        if(data.id == result.id){
                            angular.copy(result, data);
                        }
                    })
                }
                else {
                    var newNode = [{
                        name: langKey == 'en' ? result.code+ ' '+ result.enName : result.code+ ' '+result.cnName,
                        open: true,
                        id:result.code
                    }];
                    zTreeObj.addNodes(treeNodeCopy, -1, newNode);
                    $scope.accountTitles.push(result);
                    treeNodeCopy = newNode[0];
                }
            }
            else {
                loadAccountTitles();
            }
            if(entity.saveType == "use" && result.isLast){
                $scope.voucherUse(result);
            }
            else if(entity.saveType == "add" && fatherTitle){
                doAddOne(fatherTitle);
            }
        };

        $scope.voucherUse = function(result) {
            if(!result.isLast){
                return;
            }
            result.showLedger = null;
            var showLedgerArr = [];
            angular.forEach($scope.accountTitles, function(data,index){
                if(result.code && result.code.indexOf(data.code) == 0){
                    showLedgerArr.push(langKey == 'en' ? data.enName : data.cnName);
                }
                else if(result.code == data.code){
                    showLedgerArr.push(langKey == 'en' ? data.enName : data.cnName);
                    return
                }
            })
            result.showLedger = showLedgerArr.join('/ ')
            $uibModalInstance.close(result)
        }
        var onSaveError = function(result) {
            $scope.isSaving = false;
            if (result.status === 409) {
                layer.msg($translate.instant('autopsApp.accountTitle.code_repeat'));
            } else if (result.status === 403) {
                layer.msg($translate.instant('autopsApp.accountTitle.uper_used'));
            } else if (result.status === 404) {
                layer.msg($translate.instant('autopsApp.accountTitle.parentNotExist'));
            } else {
                layer.msg($translate.instant('global.save_fail'));
            }
        };

        var onUpdateError = function(result) {
            $scope.isSaving = false;
            if (result.status === 403) {
                layer.msg($translate.instant('autopsApp.accountTitle.this_used'));
            } else {
                layer.msg($translate.instant('global.save_fail'));
            }
        };       
        $scope.save = function(saveType) {
            entity.saveType = saveType;
            if ($rootScope.HAVENOAUTH('ROLE_DEPARTMENT')) {
                layer.msg($translate.instant('global.forbiddenTeam'));
                return;
            }
            // if ($scope.accountTitle.code == "4103" || $scope.accountTitle.code == "6401" ||
            //     $scope.accountTitle.code == "6001" || $scope.accountTitle.code == "660302" ||
            //     $scope.accountTitle.code == "660301" || $scope.accountTitle.code == "660303") {
            //     layer.msg($translate.instant('autopsApp.accountTitle.sys_used'));
            //     return;
            // }
            if ($scope.accountTitle.isForeign && (!$scope.accountTitle.currency || $scope.accountTitle.currency == $rootScope.account.baseCurrency)) {
                $scope.accountTitle.currency = null;
                layer.msg($translate.instant('autopsApp.accountTitle.chooseCurrencyAlert'));
                return;
            }

            var accountTitle = angular.copy($scope.accountTitle);
            if ($scope.isAdd) {
                var currentLen = accountTitle.addCode.length;
                if (currentLen < $scope.lenLimit) {
                    for (var i = currentLen; i < $scope.lenLimit; i++) {
                        accountTitle.addCode = "0" + accountTitle.addCode;
                    }
                }
                accountTitle.code = accountTitle.code + accountTitle.addCode;
                if (!accountTitle.isForeign) {
                    accountTitle.isForeign = false;
                }
            } 
            else {
                var currentLen = accountTitle.code.length;
                if (currentLen != 4 && currentLen != 6 && currentLen != 10 && currentLen != 14 && currentLen != 18) {
                    layer.msg($translate.instant('autopsApp.accountTitle.codeLengthAlert'));
                    return;
                }
                if (accountTitle.code[0] == "8" && accountTitle.code[1] == "8") {
                    layer.msg($translate.instant('autopsApp.accountTitle.eightAlert'));
                    return;
                }
            }
            $scope.isSaving = true;
            if (accountTitle.id != null) {
                AccountTitle.update(accountTitle, onSaveSuccess, onSaveError);
            }
            else {
                AccountTitle.save(accountTitle, onSaveSuccess, onSaveError);
            }
        };

        $scope.upperAbb = function(abb) {
            $scope.accountTitle.abb = angular.uppercase(abb);
        }

        $scope.formatChange = function() {
            if ($scope.accountTitle.format == "amount") {
                $scope.accountTitle.currency = null;
                $scope.accountTitle.isForeign = false;
            } else {
                $scope.accountTitle.currency = null;
                $scope.accountTitle.isForeign = true;
            }
        }

        $scope.currencyChange = function() {
            if ($scope.accountTitle.currency == "") {
                $scope.accountTitle.format = "amount"
                $scope.accountTitle.isForeign = false;
            } else {
                $scope.accountTitle.format = "currencyAmount"
                $scope.accountTitle.isForeign = true;
            }
        }
        $scope.isForeighChange = function() {
            if(!$scope.isEdit){
                return;
            }
            $scope.accountTitle.isForeign = !$scope.accountTitle.isForeign;
            if (!$scope.accountTitle.isForeign) {
                $scope.accountTitle.format = "amount"
                $scope.accountTitle.currency = null;
            } else {
                $scope.accountTitle.format = "currencyAmount"
                $scope.accountTitle.currency = null;
            }
        }
        $scope.isCashChange = function() {
            if(!$scope.isEdit){
                return;
            }
            $scope.accountTitle.isCash = !$scope.accountTitle.isCash;
            if (!$scope.accountTitle.isForeign) {
                $scope.accountTitle.cashFlowId = null;
                $scope.accountTitle.cashFlowName = null;
            }
        }
        $scope.baseCurrencyFilter = function(item) {
            return item !== $rootScope.account.baseCurrency;
        };


        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.cashFlowSubjects = null;
        $scope.loadCashFlowSubs = function(item) {      
            if(!$scope.accountTitle.cashFlowName){
                $scope.accountTitle.cashFlowName = "";
            }
            if($scope.cashFlowSubjects || $scope.isSaving){
                return;
            }
            $scope.isSaving = true;
            CashFlowSubject.query(function(result) {
                $scope.isSaving = false;
                $scope.cashFlowSubjects = result;
            }, function(error) {    
            });
        };
        $scope.cashFlowSubjectSelected = function(item) { 
            if(item.companyId != $scope.accountTitle.companyId){
                layer.msg($translate.instant('global.refreshAlert'));
            }
            else {
                $scope.accountTitle.cashFlowId = item.id;
            }            
        }      
        $scope.resetSubsidiary = function() { 
            if(!$scope.accountTitle.id){
                return;
            }
            layer.confirm($translate.instant('autopsApp.accountTitle.resetSubsidiaryAlert'), {
                icon: 0,
                title: $scope.accountTitle.code,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);   
                layer.load(1, {shade: 0.3});                   
                AccountTitle.resetSubsidiary({id: $scope.accountTitle.id}, function(result){
                    layer.msg($translate.instant('global.save_success'));
                    layer.closeAll(); 
                });
            }, function() {
                return;
            });
        }   

        $scope.setFilter = function (value) {
            filterType = value;
            loadAccountTitles();   
        }
        $scope.getMyClass = function (value) {
            if(filterType == value){
                return 'Active'
            }
        }
        function loadAccountTitles() {
            AccountTitle.getAll({
                type: filterType
            }, function(result) {
                $scope.accountTitles = result; 
                initTree(); 
                if($scope.isAdd){
                    getAddCode();
                }                
            });
        }

        // zTree 的数据属性，深入使用请参考 API 文档（zTreeNode 节点数据详解）
        var caegoryNodes =[];
        function getNodes(dataList) {
            angular.forEach(dataList, function(data,index){
                if(data.level == 1){
                    var node = {
                        name: langKey == 'en' ? data.code+ ' '+ data.enName : data.code+ ' '+data.cnName,
                        open: true,
                        id:data.code,
                        children:[]
                    };
                    addSubNodes(node, 2, dataList)
                    if(node.children.length == 0){
                        node.children = null;
                    }
                    caegoryNodes.push(node)
                }            
            })            
        };

        function addSubNodes(parentNode, level, dataList) {
            angular.forEach(dataList, function(data){
                if(data.level == level && data.code.indexOf(parentNode.id) == 0 && data.code != parentNode.id){
                    var node = {
                        name: langKey == 'en' ? data.code+ ' '+ data.enName : data.code+ ' '+data.cnName,
                        open: true,
                        id:data.code,
                        children:[]
                    };
                    addSubNodes(node, data.level + 1, dataList);
                    if(node.children.length == 0){
                        node.children = null;
                    }
                    parentNode.children.push(node)
                }
            })
        }        

        function initTree() {
            caegoryNodes=[];
            getNodes($scope.accountTitles); 
            zTreeObj = $.fn.zTree.init($("#accountTitlesTree"), categorySetting, caegoryNodes);
            fuzzySearch('accountTitlesTree','#field_search',null,true); 
            if(entity.accountTitle && entity.accountTitle.code){
                var nodes = zTreeObj.getNodesByParamFuzzy("id", entity.accountTitle.code, null);
                if (nodes.length>0) {
                    zTreeObj.selectNode(nodes[0]);
                    treeNodeCopy = nodes[0];
                }
                entity.accountTitle = null;
            }
        }

        function shareOne(){       
            if(!$scope.accountTitle.id){
                return;
            }     
            layer.confirm($translate.instant('autopsApp.accountTitle.shareAllAlert'), {
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                AccountTitle.shareOne({
                        id: $scope.accountTitle.id
                    },
                    function(result) {
                        layer.close(index);
                        layer.msg($translate.instant('global.messages.submitted'));
                    }, $rootScope.ALERT_ERROR);
            }, function() {
            });
        }


    }
})();