(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsImporterDeleteController',OpsImporterDeleteController);

    OpsImporterDeleteController.$inject = ['$uibModalInstance', 'entity', 'OpsImporter'];

    function OpsImporterDeleteController($uibModalInstance, entity, OpsImporter) {
        var vm = this;

        vm.opsImporter = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            OpsImporter.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
