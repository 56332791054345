(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountBalance', AccountBalance);

    AccountBalance.$inject = ['$scope', '$rootScope', '$translate', '$uibModal', 
    'Principal', 'VoucherContent', 'OpsFiles', 'AccountTitle', 'DataUtils', 'JhiLanguageService'];

    function AccountBalance($scope, $rootScope, $translate, $uibModal, 
        Principal, VoucherContent, OpsFiles, AccountTitle, DataUtils, JhiLanguageService) {

        $scope.format = 1;
        $scope.reportavailalbe = false;
        $scope.fileName = "";
        var params = [];
        $scope.showType = "";
        $scope.classText = $translate.instant('autopsApp.accountTitle.class');
        var lang = "";
        JhiLanguageService.getCurrent().then(function(language) {
            lang = language;
        });
        $scope.showTypeChange = function() {
            initCContent();
        };
        var getLedgers = function(data) {
            AccountTitle.getAll({
                type: "all"
            }, function(result) {
                $scope.ledgers = DataUtils.generateShowLedger(result, false, lang);
            });
        }

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                getLedgers();
            });
        } else {
            getLedgers();
        }


        $scope.datePickerForEtd = {};
        $scope.datePickerForEtd.status = {
            opened: false
        };

        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };

        $scope.datePickerForCargoReadyDate = {};

        $scope.datePickerForCargoReadyDate.status = {
            opened: false
        };

        $scope.datePickerForCargoReadyDateOpen = function($event) {
            $scope.datePickerForCargoReadyDate.status.opened = true;
        };

        $scope.filterDTO = {};
        $scope.isSaving = false;
        $scope.voucherContents = [];
        var dataCopy;
        var initCContent = function() {
            $scope.voucherContents = angular.copy(dataCopy);
            if (!$scope.voucherContents || $scope.voucherContents.length ==0) {
                $scope.voucherContents = [];
                for (var j = 0; j <= 20; j++) {
                    $scope.voucherContents.push({});
                }
            }
            if($scope.showType){
                for(var i = $scope.voucherContents.length - 1; i >= 0; i--){
                    if($scope.voucherContents[i].level && $scope.voucherContents[i].level > $scope.showType){
                        $scope.voucherContents.splice(i, 1);
                    }
                }
            }
        }

        initCContent();
        $scope.getContetns = function() {
            if (!$scope.filterDTO.dateFrom || !$scope.filterDTO.dateTo) {
                layer.msg($translate.instant('autopsApp.accountTitle.nullFilterAlert'));
                return;
            }
            var yearFrom = $scope.filterDTO.dateFrom.getFullYear();         
            var yearTo = $scope.filterDTO.dateTo.getFullYear();     
            if (yearFrom != yearTo){
                layer.msg($translate.instant('global.messages.twoYearPeriodAlert'));
                return;
            }
            
            if ($scope.filterDTO.dateFrom > $scope.filterDTO.dateTo) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                return;
            }
            if ($scope.filterDTO.isAudited == null) {
                $scope.filterDTO.isAudited = false;
            }
            if(!$scope.filterDTO.ledger){
                $scope.filterDTO.ledgerCode = null;
            }
            $scope.filterDTO.companyId = $rootScope.account.companyId;
            $scope.isSaving = true;
            $scope.voucherContents = [];
            VoucherContent.getAccountBalance($scope.filterDTO, function(result) {
                $scope.isSaving = false;
                dataCopy = result;
                if (!dataCopy || dataCopy.length == 0) {
                    layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                    return;
                }
                $scope.reportavailalbe = true;
                initCContent();
            }, function(response) {
                if (response.status == 406){
                    layer.msg($translate.instant('autopsApp.accountTitle.lastYearNotAccountedAlert'));
                }   
                $scope.isSaving = false;
                initCContent();
            });
        }

        $scope.exportAccountBalance = function(format) {
            $scope.format = format;
            $scope.fileName = '';

            $scope.fileName_btn = layer.open({
                type: 1,
                area: ['300px', '180px'],
                btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')],
                title: $translate.instant('global.fileName'),
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: $("#templateReportName"),
                success: function(layero, index) {
                    $("#fileName").focus();
                },
                yes: function(index, layero) {
                    if ($scope.fileName == '') {
                        $scope.fileName = 'AccountBalance';
                    }
                    generateAccountBalance();
                    layer.close($scope.fileName_btn);
                },
                no: function(index, layero) {
                    layer.close(index);
                }
            });
        }

        $("#fileName").keydown(function(event) {
            $scope.keycode = window.event ? event.keyCode : event.which;
            if ($scope.keycode == 13) {
                if ($scope.fileName == '') {
                    $scope.fileName = 'AccountBalance';
                }
                generateAccountBalance();
                layer.close($scope.fileName_btn);
            }
        })

        var generateAccountBalance = function() {
            OpsFiles.generateAccountBalanceReport({
                fileType: $scope.format,
            }, $scope.voucherContents, function(result) {
                exportExcelOrPdf(result);
            }, function(error) {
                layer.msg($translate.instant('autopsApp.accountTitle.viewAccountBalanceFail'));
            });
        }

        var exportExcelOrPdf = function(result) {
            if (angular.isDefined($scope.format) && $scope.format == 2) {
                var file = new Blob([result.data], {
                    type: 'application/vnd.ms-excel'
                });
                var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
                var aTag = document.createElement("a");
                aTag.download = $scope.fileName + ".xlsx";
                aTag.href = blobURL;
                aTag.click();
            } else {
                var file = new Blob([result.data], {
                    type: 'application/pdf'
                });
                var fileURL = URL.createObjectURL(file);
                var previewDialog = layer.open({
                    type: 2,
                    area: ['1200px', '800px'],
                    maxmin: true,
                    title: $translate.instant("autopsApp.accountTitle.viewAccountBalance"),
                    shade: 0.3,
                    moveType: 0,
                    shift: 0,
                    content: fileURL
                })
            }
        }
        $scope.showDetails = function(content) {
            $scope.filterDTO.ledgerId = content.titleId;
            $scope.filterDTO.ledgerCode = content.code;
            $uibModal.open({
                templateUrl: 'app/entities/voucherContent/voucherContent-dialog.html',
                controller: 'VoucherContentDialogController',
                size: 'xxxl',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountVoucher');
                        return $translate.refresh();
                    }],
                    entity: ['VoucherContent', function(VoucherContent) {
                        return VoucherContent.getAllByFiler($scope.filterDTO).$promise;
                    }]
                }
            }).result.then(function(result) {
            }, function() {
            })
        }
        $scope._onLedgerChoosed = function(data) {      
            $scope.filterDTO.ledgerCode = data.code;
        }
        $scope.exelExport = function() {
            if(!$scope.voucherContents){
                return;
            }
            var title = $translate.instant('global.menu.entities.account-balance');
            var fileName = title + ".xlsx";          
            OpsFiles.assistAccountBalance({
                title: title,
                notAssist: true
            }, $scope.voucherContents, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);                
            })
        }
    }
})();