
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsLogDialogController', OpsLogDialogController);

    OpsLogDialogController.$inject = ['$scope', '$rootScope', '$translate', '$uibModalInstance', 
    'entity'];

    function OpsLogDialogController($scope, $rootScope, $translate, $uibModalInstance, 
        entity) {

        $scope.nowTime = (entity == null?new Date():entity);

        $scope.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        $scope.save = function() {
            $uibModalInstance.close($scope.nowTime);
        }

            
    }
})();
