(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('GridState', GridState);

    GridState.$inject = ['$resource'];

    function GridState ($resource) {
        var resourceUrl =  'api/grid-states/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    data.gridId += ""; 
                    return data;
                }
            },
            'getUserGridState': {
                url:'api/grid-states/getUserGridState',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getUserGridStateList': {
                url:'api/grid-states/getUserGridStateList',
                method: 'GET',
                isArray: true
            },
            'update': { method:'PUT' },
            'delete': {method: 'DELETE'},
            'saveCustomRecord': {
                url:'api/grid-states/saveCustomRecord',
                method: 'PUT'
            },
            'getOneCustomExcelImport': {
                url: 'api/grid-states/customExcelImport/:id',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    data.gridId += ""; 
                    return data;
                }
            },
            'getAllCustomExcelImportRecord': {
                url:'api/grid-states/allCustomExcelImportRecord',
                method: 'GET',
                isArray: true
            },
            'getCustomExcelImportRecord': {
                method:'GET',
                url:'api/grid-states/getCustomExcelImportRecord',
                isArray: true
            },
            'getAllCustomExcelExportRecord': {
                url:'api/grid-states/allCustomExcelExportRecord',
                method: 'GET',
                isArray: true
            },
            'getCustomExcelExportRecord': {
                method:'GET',
                url:'api/grid-states/getCustomExcelExportRecord',
                isArray: true
            },
            'updateCustomExcelExport': {
                url:'api/grid-states/updateCustomExcelExport',
                method: 'PUT'
            },
            'customizeExportOrder': {
                method: 'POST',
                url:'api/grid-states/customizeExportOrder',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'getOneSynBranch': {
                url: 'api/synOrder/getOneSynBranch',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    data.gridId += ""; 
                    return data;
                }
            },
            'getSynOrderConfig': {
                method:'PUT',
                url:'api/synOrder/getSynOrderConfig',
                isArray:true
            },
            'getAllSynOrderRecord': {
                url:'api/grid-states/allSynOrderRecord',
                method: 'GET',
                isArray: true
            },
            'getAllCustomEmailTemplateRecord': {
                url:'api/grid-states/allCustomEmailTemplateRecord',
                method: 'GET',
                isArray: true
            },
            'getCustomEmailTemplateByEmailType': {
                url:'api/grid-states/getCustomEmailTemplateByEmailType',
                method: 'GET',
                isArray: true
            },
            'parseEmailTemplate': {
                url:'api/grid-states/parseEmailTemplate',
                method: 'GET'
            }
        });
    }
})();
