(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsNewsController', OpsNewsController);

    OpsNewsController.$inject = ['$scope', '$state', '$rootScope', '$translate', '$timeout', 'OpsNews', 'ParseLinks', 'pagingParams', 'paginationConstants', 'WinAES'
    ];

    function OpsNewsController($scope, $state, $rootScope, $translate, $timeout, OpsNews, ParseLinks, pagingParams, paginationConstants, WinAES) {
        var vm = this;

        vm.opsNewsList;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = pagingParams.page;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.loadAll = loadAll;
        vm.transition = transition;
        vm.search = search;
        vm.clear = clear;
        loadAll();

        function loadAll() {
            if (vm.searchQuery) {
                OpsNews.search({
                    page:  vm.page- 1,
                    query: vm.searchQuery,
                    size: vm.itemsPerPage,
                    sort: "id,desc"
                }, null, onSuccess, onError);
            }else{
                OpsNews.getUpdateNews({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: "id,desc"
                }, null, onSuccess, onError);
            }


            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.opsNewsList = data;
                vm.page = pagingParams.page;
                angular.forEach(vm.opsNewsList, function(data){
                    data.encodeId = WinAES.encode(data.id.toString())
                })
            }

            function onError(error) {
                console.log("onError");
                layer.msg($translate.instant('global.loadFailed'));
            }
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                search: vm.currentSearch
            });
        }

        function search(searchQuery){
            if(!searchQuery){
                return vm.clear();
            }
            vm.page = 1;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear(){
            console.log("执行了clear");
            vm.page = 1;
            vm.currentSearch = null;
            vm.transition();
        }

        $timeout(function() {});

        var unsubscribe = $rootScope.$on('autopsApp:opsNewsUpdate', function() {
            loadAll();
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
