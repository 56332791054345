
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('SiSubmitController', SiSubmitController);

    SiSubmitController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$translate', '$uibModal',
    'entity', 'Guide', 'DataUtils'];

    function SiSubmitController($timeout, $scope, $rootScope, $stateParams, $translate, $uibModal,
        entity, Guide, DataUtils) {
        var vm = this;

        $scope.guide = entity;
        var guideCopy = {};

        $timeout(function (){
            initBooking();
        })
        var initBooking = function() {
            if(!$scope.guide || !$scope.guide.id){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.urlExpired'));
                return;
            }
            if($scope.guide.hblConfirmedTime){
                $scope.guide.booking.isLocked = true;
            }
            guideCopy = angular.copy($scope.guide);
            if ($scope.guide.shipmentType == "FCL" && $scope.guide.booking.id && $scope.guide.booking.status != "new" && $scope.guide.id) {
                if(!$scope.guide.guideCtns){
                    $scope.guide.guideCtns = [];
                }
                if ($scope.guide.guideFclCtns) {
                    angular.forEach($scope.guide.guideFclCtns, function(fclCtn){
                        if (fclCtn.ctnType && fclCtn.num) {
                            var size = 0;
                            angular.forEach($scope.guide.guideCtns, function(gCtn){
                                if (gCtn.ctnType == fclCtn.ctnType) {
                                    size++;
                                }
                            })
                            for (var j = 0; j < fclCtn.num - size; j++) {
                                $scope.guide.guideCtns.push({
                                    ctnType: fclCtn.ctnType
                                });
                            }
                        }
                    })
                }  
            }
            initPort();
            getHblsCount();
        }


        var loadGuide = function() {
            $scope.isSaving = true;
            Guide.getSiGuide({key : $stateParams.id}, function(){
                $scope.isSaving = false;
                $scope.guide = result;
                initBooking();
                layer.msg($translate.instant('entity.action.loadSuccess'));
            });    
        }

        $scope.opsShippers = [];
        $scope.loadOpsShipper = function() {
            if(!$scope.guide.shipperId){
                return;
            }
            if ($scope.opsShippers && $scope.opsShippers.length > 0) {
                return;
            }    
            Guide.getOpsShippers({
                venderId: $scope.guide.shipperId
            }, function(result) {
                $scope.opsShippers = result;
                for (var i = $scope.opsShippers.length -1; i >=0; i--) {
                    if (!$scope.opsShippers[i].companyName){
                        $scope.opsShippers.splice(i, 1);
                    }
                }
            })
        };
        $scope.onShipperSelected = function(data, chooseTarget) {
            if (chooseTarget == "bookingShipper") {
                $scope.guide.booking.shipperBl = data.blShow;
            } else if (chooseTarget == "bookingCnee") {
                $scope.guide.booking.cneeBl = data.blShow;
                $scope.guide.booking.cneeId = data.choosedId;
            } else if (chooseTarget == "bookingNotify") {
                $scope.guide.booking.notifyBl = data.blShow;
            }
        }

        $scope.loadPorts = function(fd) {
            if(!$scope.guide.booking[fd] && fd){
                $scope.guide.booking[fd] = "";
            }
            if ($rootScope.OPSPORTS.airports && $rootScope.OPSPORTS.airports.length > 0){
                return;
            }
            Guide.getOpsPorts({
                companyId: $scope.guide.companyId
            }, function(result){
                $rootScope.OPSPORTS.airports = [];
                $rootScope.OPSPORTS.seaports = [];
                angular.forEach(result, function(data){
                    if(data.type == 1){
                        $rootScope.OPSPORTS.seaports.push(data);
                    }
                    else {
                        $rootScope.OPSPORTS.airports.push(data);
                    }
                })
                initPort();
            })
        };

        var initPort = function() {
            if ($scope.guide.shipmentType == "AIR" || $scope.guide.shipmentType == "EXPRESS" || $scope.guide.shipmentType == "FBAAIR") {
                $scope.portList = $rootScope.OPSPORTS.airports;                
            }
            else {
                $scope.portList =  $rootScope.OPSPORTS.seaports;
            }
        };
        $scope._selected = function($item, which){
            switch(which){
                case "porChoose":                    
                    $scope.guide.booking.porId = $item.code;               
                    break;
                case "polChoose":                    
                    $scope.guide.booking.polId = $item.code;               
                    $scope.guide.polRegion = $item.region;
                    break;
                case "podChoose":
                    $scope.guide.booking.podId = $item.code; 
                    if(!$scope.guide.podRegion) {
                        $scope.guide.podRegion = $item.region;
                    }                     
                    break;
                case "destChoose":
                    $scope.guide.booking.destId = $item.code;   
                    $scope.guide.podRegion = $item.region;
                    $scope.guide.destCountryCode = $item.countryCode;                    
                    break;
                case "deliveryChoose":
                    $scope.guide.booking.deliverytoId = $item.code;             
                    break;
            }
        }
        $scope.addGCtn = function() {
            if(!$scope.guide.guideCtns){
                $scope.guide.guideCtns = [];
            }
            $scope.guide.guideCtns.push({});
        };

        $scope.deleteGCtn = function(ctn) {
            if (ctn.id) {
                return;
            }
            var index = $scope.guide.guideCtns.indexOf(ctn);
            if (index > -1) {
                $scope.guide.guideCtns.splice(index, 1);
            }
        }

        $scope.sendSi = function() {
            if(!$scope.guide || !$scope.guide.id){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.urlExpired'));
                return;
            }
            if (angular.equals($scope.guide, guideCopy)) {
                layer.msg($translate.instant('global.no_amendmend'));
                return;
            }
            if ($scope.guide.booking.isLocked) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            $scope.isSaving = true;
            $scope.guide.booking.status = "SiSubmitted";
            $scope.guide.booking.lastModifiedTime = new Date();
            $scope.guide.booking.siReceivedTime = new Date();
            Guide.sendSiOnline($scope.guide, onSendSiSuccess, onSendError);
        }
        var onSendSiSuccess = function(result) {
            $scope.isSaving = false;
            $scope.guide = result;
            guideCopy = angular.copy($scope.guide);
            layer.msg($translate.instant('global.messages.submitted'));
        }
        var onSendError = function(result) {
            $scope.isSaving = false;
            if (result.status == 409) {
                layer.confirm($translate.instant('autopsApp.guide.home.expiredAlert'), {
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    loadGuide();
                    layer.close(index);
                }, function() {});
            } 
            else if (result.status == 403) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.lockedAlert'));
            } 
            else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        };
        $scope.exportSi = function(format) {
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.opType = 1;
            guideReportDTO.reportType = 190;
            guideReportDTO.fileType = format;
            Guide.guideReport({}, guideReportDTO, function(result) {
                $rootScope.EXPORTDOWNLOAD(result, format, "SI-" + $scope.guide.jobNum);
            });
        }


        $scope.previewSi = function(reportCode) {
            if(!$scope.guide.id){
                return;
            }
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.opType = 1;
            guideReportDTO.reportType = reportCode;
            guideReportDTO.fileType = 1;
            Guide.guideReport({}, guideReportDTO, function(result) {
                $rootScope.PRINTBYTES(result.data);
            });
        }        

        $scope.HBLpreview = function(reportCode) {
            if (!$scope.guide.booking.isLocked) {
                return;
            }
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.opType = 1;
            guideReportDTO.reportType = reportCode;
            guideReportDTO.fileType = 1;
            Guide.guideReport({}, guideReportDTO, function(result) {
                $rootScope.PRINTBYTES(result.data);
            });
        }

        var getHblsCount = function() {
            if(!$scope.guide.id 
                || ($scope.guide.shipmentType != 'FCL' && $scope.guide.shipmentType != 'AIR' && $scope.guide.shipmentType != 'LCL')){
                return;
            }
            Guide.getHblsCount({
                guideId: $scope.guide.id
            }, function(result, headers) {
                $scope.hbls = result.hbls;
            });
        }

        $scope.combineHblIntoMbl = function() {
            if(!$scope.guide.id){
                layer.msg($translate.instant('autopsApp.booking.home.booking_not_sent'));
                return;
            }
            if(!$scope.hbls){return;}
            Guide.getHbls({refGuideId: $scope.guide.id}, function(result){
                var gw = 0,//件毛体合并
                    pkgs = 0,
                    vol = 0,
                    chargeWeight = 0,
                    isSamePkg = true,
                    pkgType;
                angular.forEach(result, function(data) {
                    if (data.pkgNum) {
                        pkgs = pkgs + data.pkgNum;
                    }
                    if (data.gw) {
                        gw = gw + data.gw;
                    }
                    if (data.vol) {
                        vol = vol + data.vol;
                    }
                    if (data.chargeWeight) {
                        chargeWeight = chargeWeight + data.chargeWeight;
                    }
                    if (!pkgType && data.pkgType) {
                        pkgType = data.pkgType;
                    }
                    if (data.pkgType && pkgType != data.pkgType) {
                        isSamePkg = false;
                    }
                })
                $scope.guide.pkgNum = pkgs;
                $scope.guide.chargeWeight = chargeWeight;
                $scope.guide.gw = gw;
                $scope.guide.vol = vol;
                if (isSamePkg) {
                    $scope.guide.booking.pkgType = pkgType;
                } else {
                    $scope.guide.booking.pkgType = "PKGS";
                }
                DataUtils.updateGuideCw($scope.guide);
                $translate.refresh();
                layer.msg($translate.instant('autopsApp.booking.home.combineHblsQuestion'));
            })
        }

        $scope.hblView = function() {       
            if(!$scope.guide.id 
                || ($scope.guide.shipmentType != 'FCL' && $scope.guide.shipmentType != 'AIR' && $scope.guide.shipmentType != 'LCL')){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.typeNotSupportHblAlert'));
                return;
            }
            Guide.getSiGuideForHbls({
                id: $scope.guide.id
            }, function(result) {
                var entity = {};
                entity.guide = result;
                $uibModal.open({
                    templateUrl: 'app/entities/booking/booking-dialog.html',
                    controller: 'BookingDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxl',
                    resolve: {
                        entity: [function() {
                            return entity;
                        }]
                    }
                }).result.then(function(result) {
                    getHblsCount();
                }, function(result) {
                    getHblsCount();
                });
            });
        };
    }
})();