(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CreateCnDialogController', CreateCnDialogController);

    CreateCnDialogController.$inject = ['$scope', '$rootScope', '$translate', '$uibModalInstance', '$timeout',
    'entity', 'AccountPayment', 'OpsVender', 'DataUtils'];

    function CreateCnDialogController($scope, $rootScope, $translate, $uibModalInstance, $timeout,
        entity, AccountPayment, OpsVender, DataUtils) {

        $scope.paymentRequest = entity;
        var ratesCopy = entity.guideRates;

        $timeout(function (){
            if((!$scope.paymentRequest.id || $scope.paymentRequest.manual) && $rootScope.HAVENOAUTH("ROLE_ACCOUNT")){
                $rootScope.FORBIDDEN_BY_ALERT('ROLE_ACCOUNT');
                $scope.clear();
            }
            if(entity.branchCompanyId){
                OpsVender.getBranchVender({
                    companyId: $rootScope.account.companyId,
                    bindedCompanyId: entity.branchCompanyId
                }, function(result){
                    $scope.updateSettlementObj(result);
                });
            }
            $scope.paymentRequest.files = [];
            $rootScope.loadCurrencys();
        });

        var onSaveSuccess = function (result) {
            $uibModalInstance.close(result);
            $scope.isSaving = false;
            layer.msg($translate.instant('global.messages.submitted'));
        };

        $scope.isInvalid = function () {
            if(!$scope.paymentRequest.isTtl && (!$scope.paymentRequest.invoiceType || !$scope.paymentRequest.accountDate)){
                return true;
            }
            return false;
        }

        $scope.save = function () {
            if(!$scope.paymentRequest.isTtl && (!$scope.paymentRequest.invoiceType || !$scope.paymentRequest.accountDate)){
                return;
            }
            $scope.paymentRequest.isUsd = true;
            if($scope.paymentRequest.currency == $rootScope.account.baseCurrency){
                $scope.paymentRequest.isUsd = false;
            }
            if(!$scope.paymentRequest.currency){
                $scope.paymentRequest.currency = $rootScope.account.baseCurrency;
            }        
            if($scope.paymentRequest.invoiceNum){
                $scope.paymentRequest.isIssued = true;
            }       
            $scope.isSaving = true;

            if($scope.paymentRequest.id){
                AccountPayment.update($scope.paymentRequest, onSaveSuccess, $rootScope.ALERT_ERROR);
            }
            else {
                var accountPaymentDTO = {};
                accountPaymentDTO.accountPayment = $scope.paymentRequest;
                accountPaymentDTO.guideRates = ratesCopy;
                AccountPayment.save(accountPaymentDTO, onSaveSuccess, $rootScope.ALERT_ERROR);
            }
        };
        $scope.paymentTypeChange = function(type) {
            if(type == 'withoutInvoice'){
                $scope.paymentRequest.isIssued = true;
                $scope.paymentRequest.noInvoice = true;
            }
            else if (type == 'forUsdOnly'){
                $scope.paymentRequest.isIssued = false;
                $scope.paymentRequest.currency = "USD";
            }
            else{
                $scope.paymentRequest.isIssued = false;
                $scope.paymentRequest.currency = $rootScope.account.baseCurrency;
            }
        }
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.updateSettlementObj = function(obj) {
            $scope.paymentRequest.invoiceTitle = obj.companyName;
            $scope.paymentRequest.venderId = obj.id;
        };

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        $scope.updateSales = function(item) {
            $scope.paymentRequest.salesId = item.id;
        };
        $scope.updateTaxAmt = function() {
            $scope.paymentRequest.invoiceTotalTax = DataUtils.round($scope.paymentRequest.invoiceAmount * ($scope.paymentRequest.vatRate == null?0:$scope.paymentRequest.vatRate)/100, 2);
            $translate.refresh();
            
        };


    }
})();
