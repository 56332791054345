
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WriteOffDetailController', WriteOffDetailController);

    WriteOffDetailController.$inject = ['$scope', '$rootScope', '$translate', '$timeout', '$uibModalInstance', '$uibModal', 
    'DataUtils', 'entity', 'AccountBind', 'AddressBook'];

    function WriteOffDetailController($scope, $rootScope, $translate, $timeout, $uibModalInstance, $uibModal, 
        DataUtils, entity, AccountBind, AddressBook) {
        var vm = this;
        vm.accountBind = entity;

        vm.save = save;
        vm.clientSelected = clientSelected;
        vm.loadAddressBooks = loadAddressBooks;
        vm.onAddressSelected = onAddressSelected;       
        vm.wmsMaping = wmsMaping;
        vm.showtxt = false;

        $timeout(function (){
            if(vm.accountBind.autoChannelJson){
                vm.accountBind.autoChannelJson = angular.fromJson(vm.accountBind.autoChannelJson);
            }
        });

        $scope.generateKey = function(accountBind) {
            if(!accountBind){
                accountBind = {};
            }
            accountBind.appKey = DataUtils.randomWord(false, 32, 32);
            accountBind.appToken = DataUtils.randomWord(false, 255, 255);
            accountBind.type = "Auops";
            save();
        }

        $scope.clearKey = function(accountBind) {
            accountBind.appKey = null;
            accountBind.appToken = null;
            accountBind.type = "Auops";
            save();
        }

        function save () {
            $scope.isSaving = true;
            var accountBind = angular.copy(vm.accountBind);
            if(accountBind.autoChannelJson){
                accountBind.autoChannelJson = angular.toJson(accountBind.autoChannelJson);
            }
            if (accountBind.id !== null) {
                AccountBind.update(accountBind, onSaveSuccess, onSaveError);
            } else {
                AccountBind.save(accountBind, onSaveSuccess, onSaveError);
            }
        }

        var onSaveSuccess = function(result) {
            $scope.isSaving = false;
            angular.copy(result, vm.accountBind);
            $scope.$emit('autopsApp:apiAuthUpdate', result);
            if(vm.accountBind.autoChannelJson){
                vm.accountBind.autoChannelJson = angular.fromJson(vm.accountBind.autoChannelJson);
            }
            $scope.isSaving = false;
            if(result.appKey){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.opsCompany.apiKeyTips'));
            }
            else {
                layer.msg($translate.instant('global.messages.submitted'));
            }
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
        };
        $scope.clear = function () {
            $uibModalInstance.dismiss();            
        }
        
        function clientSelected (client) {
            vm.accountBind.venderId = client.id;
            vm.accountBind.userId = client.userId;
        }

        vm.addressBooks = [];
        function loadAddressBooks () {
            if (vm.addressBooks.length == 0 && !vm.isSaving) {
                $scope.isSaving = true;
                AddressBook.getAll(function(result) {
                    vm.addressBooks = result;
                    $scope.isSaving = false;
                });
            };
        };
        function onAddressSelected (data, accountBind) {
            accountBind.addressId = data.id;
        }
        function wmsMaping () {
            if(!vm.accountBind.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/wms-map/wms-map-dialog.html',
                controller: 'WmsMapDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsMap');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return angular.copy(vm.accountBind);
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }
        $scope.autoChannelEnable = function() {
            vm.accountBind.autoChannel = !vm.accountBind.autoChannel;
            if(!vm.accountBind.autoChannel){
                vm.accountBind.autoChannelJson = [];
            }            
        }
        $scope.bindedAccounts = [];
        $scope.getAllAccounts = function() {
            if($rootScope.account.companyId && $rootScope.HAVENOAUTH('AU_LABEL_ACCOUNT')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_LABEL_ACCOUNT');
                return;            
            }
            if ($scope.bindedAccounts.length == 0) {
                $scope.isSaving = true;
                AddressBook.getAllAccounts({includeChargeChannel: true}, function(result) {
                    $scope.bindedAccounts = result;
                    $scope.isSaving = false;
                });
            };
        };
        $scope.shipServiceSelected = function(item) {
            if(!vm.accountBind.autoChannelJson){
                vm.accountBind.autoChannelJson = [];
            }
            var isNew = true;
            angular.forEach(vm.accountBind.autoChannelJson, function(data){
                if(data.id){
                    if(item.id == data.id){
                        isNew = false;
                    }                    
                }
                else if(item.code == data.code){
                    isNew = false;
                }
            })
            if(isNew){
                var service = {
                    "id": item.id,
                    "code": item.code
                }
                vm.accountBind.autoChannelJson.push(service);
            }            
            vm.accountBind.channelCode="";
        }

        $scope.delService = function(index) {
            vm.accountBind.autoChannelJson.splice(index, 1);
        };

    }
})();
