(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsProductsDialogController', OpsProductsDialogController);

    OpsProductsDialogController.$inject = ['$scope', '$rootScope', '$timeout', '$uibModalInstance', '$translate', '$uibModal', '$location',
        'entity', 'OpsCarrier', 'uiGridConstants', 'WmsWarehouse', 'JsExpression', 'AddressBook'];

    function OpsProductsDialogController ($scope, $rootScope, $timeout, $uibModalInstance, $translate, $uibModal, $location,
                                          entity, OpsCarrier, uiGridConstants, WmsWarehouse, JsExpression, AddressBook ) {
        var vm = this;
        vm.clear = clear;
        vm.getWarehouses = getWarehouses;
        $scope.opsCarrier = {};

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.advanceSearch = function() {
            vm.isSaving = true;
            OpsCarrier.getSearchResult($scope.opsCarrier,function(result) {
                vm.isSaving = false;
                if (!result || result.length == 0){
                    layer.msg($translate.instant('global.messages.resultNull'));
                }
                $uibModalInstance.close(result);
            }, function(response) {
                vm.isSaving = false;
                if (response.status == 400){
                    layer.msg($translate.instant('global.forbidden'));
                }
                else {
                    layer.msg($translate.instant('global.requestFail'));
                }
            });
        };

        vm.warehouses = null;
        function getWarehouses() {
            if (vm.warehouses) {
                return;
            }
            WmsWarehouse.getAll({
                includeOwn: true,
                includeVirtual: false,
                includeGroup: false
            }, function(data) {
                vm.warehouses = data;
            });
        }

        $scope.bindedAccounts = [];
        $scope.getAllAccounts = function() {
            if ($rootScope.account.companyId && $rootScope.HAVENOAUTH('AU_LABEL_ACCOUNT')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_LABEL_ACCOUNT');
                return;
            }
            if ($scope.bindedAccounts.length == 0 && !vm.isSaving) {
                vm.isSaving = true;
                AddressBook.getAllAccounts(function(result) {
                    $scope.bindedAccounts = result;
                    vm.isSaving = false;
                });
            };
        };

    }
})();
