(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsAreaDetailController', WmsAreaDetailController);

    WmsAreaDetailController.$inject = ['$scope', '$state', '$rootScope', '$timeout', '$translate', 
    'WmsIn', 'DataUtils', 'WmsWarehouse', 'WmsCargo'];

    function WmsAreaDetailController ($scope, $state, $rootScope, $timeout, $translate, 
        WmsIn, DataUtils, WmsWarehouse, WmsCargo) {

        $timeout(function() {
            $("#field_oldLocation").focus();
            getLocations();
        });

        var vm = this;
        
        vm.cargo = {};
        vm.getWmsCargos = getWmsCargos;

        vm.ttlPkgs = 0;
        vm.wmsCargos = [];
        vm.location = {};
        vm.oldLocation = {};
        vm.cargoSku = "";
        vm.cargoLoaded = cargoLoaded;
        vm.oldLocationSelected = oldLocationSelected;
        vm._onOldLocationSelected = _onOldLocationSelected;

        function cargoLoaded(){
            return vm.wmsCargos && vm.wmsCargos.length > 0;
        }
        
        function _onOldLocationSelected () {
            angular.forEach(vm.locations, function(item){
                if(item.code == vm.oldLocation.code){
                    vm.oldLocation = item;
                }                
            })
            if(!vm.oldLocation.id){
                vm.oldLocation = {};
                layer.msg("Location is not exist");
                fucusInput("field_oldLocation");
            }
            else {
                layer.msg("Matched");
                fucusInput("field_scan_sku");
            } 
        }

        function getWmsCargos() {
            if(!vm.oldLocation.warehouseId){
                return;
            }
            vm.isSaving = true;
            WmsCargo.getInventoryToCheck({
                sku: vm.cargoSku,
                location: vm.oldLocation.code,
                warehouseId: vm.oldLocation.warehouseId
            }, function (result) {
                vm.isSaving = false;
                vm.wmsCargos = result;          
                updateTtl();            
                fucusInput("field_location");
            }, function(result){
                vm.isSaving = false;
                if(result.status == 404){
                    layer.msg($translate.instant('global.noRecord'));
                }
                else {
                    layer.msg($translate.instant('global.loadFailed'));
                }
            });
        }


        function getLocations () {
            WmsWarehouse.getAllLocations({companyId: $rootScope.account.companyId}, function (data) {
                vm.locations = data;
            });
        }

        vm.ttlObj = {};
        function updateTtl () {
            vm.ttlObj.gw = 0;
            vm.ttlObj.vol = 0;
            vm.ttlObj.pkgs = 0;
            angular.forEach(vm.wmsCargos, function(data){
                if(data.inventoryAmt){
                    vm.ttlObj.pkgs += data.inventoryAmt;
                    if(data.gw){
                        vm.ttlObj.gw += data.gw * data.inventoryAmt;
                    }
                    if(data.vol){
                        vm.ttlObj.vol += data.vol * data.inventoryAmt;
                    }
                }
            })
            vm.ttlObj.gw = DataUtils.round(vm.ttlObj.gw, 2);
            vm.ttlObj.vol = DataUtils.round(vm.ttlObj.vol, 4);
        }
        function fucusInput (fdId) {
            $timeout(function() {
                $("#" + fdId).focus();   
            });
        }
        function oldLocationSelected(item) {
            vm.oldLocation.code = item.code;
            _onOldLocationSelected();
        }
    }
})();
