(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WoBodyDetailController', WoBodyDetailController);

    WoBodyDetailController.$inject = ['$scope', '$rootScope', '$uibModalInstance', 'entity'];

    function WoBodyDetailController($scope, $rootScope, $uibModalInstance, entity) {
        var vm = this;
        vm.files = entity;
        vm.clear = clear;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        $scope.picFilter = function(file){
            return $rootScope.IS_PIC_FILE(file.filename);
        }
    }
})();
