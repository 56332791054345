(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WriteOffDialogController', WriteOffDialogController);

    WriteOffDialogController.$inject = ['$timeout', '$rootScope', '$scope', '$stateParams', '$uibModalInstance', '$translate', 
        'entity', 'WriteOff', 'ApiErp', 'uiGridConstants', 'GridState'];

    function WriteOffDialogController ($timeout, $rootScope, $scope, $stateParams, $uibModalInstance, $translate, 
        entity, WriteOff, ApiErp, uiGridConstants, GridState) {
        var vm = this;

        vm.writeOff = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        $timeout(function (){
            $scope.gridOptions.data = vm.writeOff.writeOffRates;
            updateTtl();
        });

        $scope.ttlStr;
        function updateTtl () {
            var currencies = new Set();
            angular.forEach($scope.gridOptions.data, function(data){
                currencies.add(data.currency);
                if(data.isPay){
                    data.thisAmount = -data.thisAmount;
                    data.totalAmount = -data.totalAmount;
                    data.diffAmount = -data.diffAmount;
                }
            });
            $scope.ttlStr = "";
            currencies.forEach(function(currency){
                var curTtl = 0;
                var dnTtl = 0;
                var cnTtl = 0;
                angular.forEach($scope.gridOptions.data, function(data){
                    if(data.currency == currency){
                        curTtl += data.thisAmount;
                        if(data.isPay){
                            cnTtl += data.thisAmount;
                        }
                        else {
                            dnTtl += data.thisAmount;
                        }
                    }
                });
                $scope.ttlStr += currency + curTtl.toFixed(2);
                if(dnTtl && cnTtl){
                    $scope.ttlStr += "(" + dnTtl.toFixed(2) + cnTtl.toFixed(2)  + ")";
                }
                $scope.ttlStr += " ";                    
            });

        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }


        function onSaveSuccess (result) {
            $scope.$emit('autopsApp:writeOffUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.writeOffDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $scope.WSReceiveBillFacade = function() {
            vm.isSaving = true;
            ApiErp.WSReceiveBillFacade(vm.writeOff, onSaveSuccess, onSaveError);
        }

        $scope.gridOptions = {
            data: [],
            enableSorting: true, //排序
            useExternalSorting: false,

            enableGridMenu: true, //表格 菜单  
            showGridFooter: true, //表格的footer  
            showColumnFooter: false, //每列footer

            enableFiltering: true, //高级过滤

            enableHorizontalScrollbar: 1, //表格的水平滚动条  
            enableVerticalScrollbar: 1, //表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            exporterMenuCsv: false,
            exporterMenuPdf: false,
            exporterMenuLabel: "Export",
            exporterExcelFilename: $translate.instant('autopsApp.writeOff.home.title') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns: ['id'],
            minimumColumnSize: 120,
            gridFooterTemplate:'<div class="ui-grid-footer-info ui-grid-grid-footer"><strong><span translate="global.records" class="text-muted"></span>: <span class="text-primary mr-8 f-13">{{grid.appScope.gridOptions.data.length}}</span><span translate="autopsApp.accountInvoice.home.writeOffAmount" class="text-muted"></span>: <span class="text-primary ml-5 f-13" ng-if="grid.appScope.ttlStr">{{grid.appScope.ttlStr}}</span></strong></div>',
            columnDefs: [{
                    field: 'jobNum',
                    width: 160,
                    cellTemplate: '<div class="ui-grid-cell-contents"><a href="" ng-click="grid.appScope.VIEWGE(row.entity)">{{row.entity.jobNum}}</a></div>',
                    displayName: $translate.instant('autopsApp.writeOff.jobNums')
                },
                {
                    field: 'mblNum',
                    width: 120,
                    displayName: $translate.instant('autopsApp.writeOff.blNums')
                },
                {
                    field: 'dnNum',
                    width: 120,
                    displayName: $translate.instant('autopsApp.writeOff.dnNum')
                },
                {
                    field: 'item',
                    displayName: $translate.instant('autopsApp.writeOff.item')
                },
                {
                    field: 'isPay',
                    width: 50,
                    cellTemplate: '<div class="ui-grid-cell-contents"><span class="label label-success" translate="autopsApp.accountInvoice.payable" ng-if="row.entity.isPay == true">payable</span><span class="label label-primary" translate="autopsApp.accountInvoice.receiveable" ng-if="row.entity.isPay != true">receiveable</span></div>',
                    displayName: $translate.instant('autopsApp.accountInvoice.dnOrCn')
                },
                {
                    field: 'currency',
                    width: 50,
                    displayName: $translate.instant('autopsApp.accountInvoice.currency')
                },
                {
                    field: 'thisAmount',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<div class="ui-grid-cell-contents" ng-class="row.entity.isPay == true?\'text-danger\':\'\'" ng-if="row.entity.thisAmount">{{grid.appScope.FORMAT_NUM(row.entity.thisAmount)}}</div>',
                    displayName: $translate.instant('autopsApp.writeOff.thisAmount')
                },
                {
                    field: 'totalAmount',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<div class="ui-grid-cell-contents" ng-class="row.entity.isPay == true?\'text-danger\':\'\'" ng-if="row.entity.totalAmount">{{grid.appScope.FORMAT_NUM(row.entity.totalAmount)}}</div>',
                    displayName: $translate.instant('autopsApp.writeOff.total')
                },
                {
                    field: 'diffAmount',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<div class="ui-grid-cell-contents" ng-class="row.entity.isPay == true?\'text-danger\':\'\'" ng-if="row.entity.diffAmount">{{grid.appScope.FORMAT_NUM(row.entity.diffAmount)}}</div>',
                    displayName: $translate.instant('autopsApp.writeOff.diffAmount')
                },
                {
                    field: 'salesName',
                    displayName: $translate.instant('autopsApp.writeOff.salesName')
                },
                {
                    field: 'payer',
                    width: 160,
                    displayName: $translate.instant('autopsApp.writeOff.payer')
                },
                {
                    field: 'etd',
                    cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.etd | date:\'mediumDate\'}}</div>',
                    displayName: $translate.instant('autopsApp.accountInvoice.economicDate')
                },
                {
                    field: 'vslVoy',
                    displayName: $translate.instant('autopsApp.writeOff.vslVoy')
                },
                {
                    field: 'actualExchangeRate',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<div class="ui-grid-cell-contents" ng-if="row.entity.actualExchangeRate">{{grid.appScope.FORMAT_NUM(row.entity.actualExchangeRate)}}</div>',
                    displayName: $translate.instant('autopsApp.writeOff.actualExchangeRate')
                }
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 153;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }       
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);    
                },
                order: 2
            }],
            onRegisterApi: function(gridApi) {
                $scope.gridApi = gridApi;
            }
        }

        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 153
            }, function(result){
                gridStateObj = result;
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }   
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
            })
        });
    }
})();
