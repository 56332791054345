(function () {
    'use strict';

    angular.module('autopsApp').controller('CommodityDatabasesSearchController',
        CommodityDatabasesSearchController);

    CommodityDatabasesSearchController.$inject = ['$scope', '$uibModalInstance', '$rootScope', 'entity', 'CommodityDatabase'];

    function CommodityDatabasesSearchController($scope, $uibModalInstance, $rootScope, entity, CommodityDatabase) {

        $scope.searchParams = entity;
        var vm = this;
        vm.isSaving = false;
        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
        $scope.searchRequests = function () {
            vm.isSaving = true;
            CommodityDatabase.advancedSearch($scope.searchParams, function (result, headers) {
                vm.isSaving = false;
                $uibModalInstance.close(result);
            }, $rootScope.ALERT_ERROR);
            vm.isSaving = false;
        };

        $scope.setStatus = function (fd, value) {
            $scope.searchParams[fd] = value;
        }
    }
})();
