
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CrmPriceDetailController', CrmPriceDetailController);

    CrmPriceDetailController.$inject = ['$scope', '$state', '$rootScope', '$uibModalInstance', '$timeout', '$translate',
        'entity', 'JhiSignature'
    ];

    function CrmPriceDetailController($scope, $state, $rootScope, $uibModalInstance, $timeout, $translate,
        entity, JhiSignature) {

        var vm = this;
        vm.save = save;
        vm.clear = clear;

        vm.clearList = clearList;

        var jhiSignature = {}
        vm.isSaving = false;

        vm.storeList = [];
        $timeout(function() {
            vm.isSaving = true;
            JhiSignature.getFbaGuideRemarks({
                guideId: entity.id
            }, function(result) {
                vm.isSaving = false;
                jhiSignature = result;
                if (jhiSignature && jhiSignature.signature) {
                    vm.storeList = angular.fromJson(jhiSignature.signature).storeResult;
                }               
            }, function() {
                vm.isSaving = false;
            });
        });


        function clear() {
            $uibModalInstance.dismiss();
        }
        function clearList() {
            vm.storeList = [];
        }
        $timeout(function() {

        });

        function onSaveSuccess(result) {
            jhiSignature = result;
            vm.isSaving = false;
            layer.msg($translate.instant('global.save_success'));
        };

        function onSaveError(result) {
            vm.isSaving = false;
            layer.msg($translate.instant('global.save_fail'));
        };

        function save() {
            if (!jhiSignature || !jhiSignature.id) {
                jhiSignature.fbaGuideId = entity.id;
            }
            var jasonObj = {};
            jasonObj.storeResult = vm.storeList;
            jhiSignature.signature = angular.toJson(jasonObj);
            vm.isSaving = true;
            JhiSignature.update(jhiSignature, onSaveSuccess, onSaveError);
        };

        
    }
})();