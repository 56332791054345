(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WriteOffRatesController', WriteOffRatesController);

    WriteOffRatesController.$inject = ['$scope', '$rootScope', '$timeout', '$uibModalInstance', 'entity', 'Guide'];

    function WriteOffRatesController ($scope, $rootScope, $timeout, $uibModalInstance, entity, Guide) {
        var vm = this;
        vm.queryResult = entity.result;
        vm.isChangeAppoint = entity.guide.deliveryAppointmentTime;
        // console.log(angular.toJson(entity.result, true));

        // vm.appointAbility = angular.fromJson('{"data":{"dateList":[{"date":"2023-10-23 12:00:00","dateList":[{"date":"2023-10-23 00:00:00","unit":"2","abilityType":"storage","remnantAmount":"4000","appointWaveList":[]},{"date":"2023-10-23 00:00:00","unit":"2","abilityType":"receive","remnantAmount":"0","appointWaveList":[{"remainCapacity":"0","appointWave":"1","isEnough":"true","waveStartTime":"08:00","waveEndTime":"11:30"},{"remainCapacity":"0","appointWave":"2","isEnough":"true","waveStartTime":"13:30","waveEndTime":"17:30"}]}]}]},"success":"true"}')

        vm.clear = clear;
        vm.makeAppoint = makeAppoint;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function makeAppoint(appointWave, appointDate) {
            if(entity.guide.deliveryAppointmentTime){
                Guide.changeAppointDate({appointWave: appointWave, appointDate: appointDate}, entity.guide, function(result) {
                    layer.msg("改约成功，请重新下载入仓单！");
                    $uibModalInstance.close(result);                
                }, $rootScope.ALERT_ERROR);
            }
            else {
                Guide.makeAppoint({appointWave: appointWave, appointDate: appointDate}, entity.guide, function(result) {
                    layer.msg("预约成功，可下载入仓单！");
                    $uibModalInstance.close(result);                
                }, $rootScope.ALERT_ERROR);
            }
        }

        $timeout(function() {
            angular.forEach(vm.queryResult.dateList, function(ability){
                angular.forEach(ability.dateList, function(date){
                    date.unitName = unitNameMap[date.unit];
                    date.abilityName = abilityNameMap[date.abilityType];
                    date.dateShow = date.date.substring(0, 10);                
                });                
            });
        });
        var unitNameMap = {
            1: "立方毫米",
            2: "件",
            3: "单",
            4: "克",
            5: "箱",
            6: "包"
        }
        var abilityNameMap = {
            receive: "收货能力",
            storage: "库容",
            consign: "发货能力"
        }

        


    }
})();
