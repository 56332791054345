
(function() {
    'use strict';

    angular.module('autopsApp').controller('AccountInvoiceDetailController',
            AccountInvoiceDetailController);

    AccountInvoiceDetailController.$inject = ['$scope', '$timeout', '$uibModalInstance', '$rootScope', '$uibModal', '$translate',
    'entity', 'AccountInvoice', 'DataUtils', 'OpsVender'];

    function AccountInvoiceDetailController ($scope, $timeout, $uibModalInstance, $rootScope, $uibModal, $translate,
        entity, AccountInvoice, DataUtils, OpsVender) {
       
        $scope.accountInvoices = [];
        $scope.accountInvoice = {};
        var vm = this;
        vm.isSaving = false;
        vm.clear = clear;
        vm.invoiceOpenRequest = {};

        vm.invoiceTypes = [{
            code: "004",
            name: "专用发票"                                
        },
        {
            code: "007",
            name: "普通发票"
        },
        {
            code: "026",
            name: "电子发票"
        }];

        $scope.loadUnissuedInvoices = function() {
            AccountInvoice.loadUnissuedInvoices(function(results){
                $scope.accountInvoices = results;
                if($scope.accountInvoices.length > 0){
                    $scope.chooseInvoice($scope.accountInvoices[0]);  
                }                                    
            })
        }

        $timeout(function (){
            if($rootScope.HAVENOAUTH('AU_CLOUD_INVOICE')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_CLOUD_INVOICE');
                $uibModalInstance.dismiss('cancel');
                return;
            }
            angular.forEach(entity, function(data){
                if(!data.isVoided){
                    $scope.accountInvoices.push(data);
                }
            })
            initAssistant();
            $translate.refresh();
        });
        var initSuccess = false;
        function initAssistant () {
            layer.msg('初始化开票组件。。', {
                time: 5000
            }); 
            vm.isSaving = true;
            AccountInvoice.register(function(result){
                layer.closeAll();    
                // initSuccess = true;   
                if(!initSuccess){
                    connectedCheck();
                }
            }, function(error){
                initSuccess = false;
                layer.msg('初始化失败，服务未开通', {
                    icon: 0
                }); 
            })
            if($scope.accountInvoices.length > 0){
                $scope.chooseInvoice($scope.accountInvoices[0]);
            }
        }  
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }  
        function connectedCheck () {
            layer.msg('初始化成功，检查设备可用性。。', {
                time: 50000
            }); 
            AccountInvoice.connectedCheck({companyId: $rootScope.account.companyId}, function(result){
                initSuccess = true;
                layer.closeAll();
                vm.isSaving = false;     
                $rootScope.OPS_ALERT("设备已连接。。", {
                    icon: 1
                }); 
            }, function(error){                
                if(initSuccess && error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                } 
                else {
                    layer.msg("设备不可用"); 
                }  
                initSuccess = false;
            })
        }  
        $scope.chooseInvoice = function(invoice) {
            if(invoice.id == $scope.accountInvoice.id){
                return;
            }
            $scope.accountInvoice = invoice;
            vm.isSaving = true;
            AccountInvoice.getInvoice(invoice, function(result){
                vm.invoiceOpenRequest = result;
                vm.invoiceOpenRequest.sellerNamePh = vm.invoiceOpenRequest.sellerName;
                vm.invoiceOpenRequest.sellerName = null;
                $scope.getVenderEmail();
                vm.isSaving = false; 
                      
            })
            var contentDiv = document.getElementById("contentDiv");
            var text = document.createElement("span");
            text.innerHTML = $scope.accountInvoice.remarks;
            text.style.padding="5px";
            contentDiv.appendChild(text);   
        }
        $scope.getVenderEmail = function() {
            if(vm.invoiceOpenRequest.invoiceTypeCode == "026" && !vm.invoiceOpenRequest.invoiceReceipt){
                vm.isSaving = true;
                OpsVender.getEmailById({id: $scope.accountInvoice.venderId}, function(result){
                    vm.invoiceOpenRequest.invoiceReceipt = result.email;
                    vm.isSaving = false;
                })
            }
        }
        $scope.updateGroup = function(invoiceDetail) {
            if(!invoiceDetail.goodsQuantity){
                invoiceDetail.goodsQuantity = 1;
            }            
            if(!invoiceDetail.goodsPrice){
                invoiceDetail.goodsPrice = 0;
            }
            if(!invoiceDetail.goodsTaxRate){    
                invoiceDetail.goodsTaxRate = 0;
            }

            invoiceDetail.goodsQuantity = invoiceDetail.goodsQuantity - 0;
            invoiceDetail.invoiceTotalPriceTax = invoiceDetail.goodsPrice - 0;  
            invoiceDetail.goodsTaxRate = invoiceDetail.goodsTaxRate - 0;

            if(invoiceDetail.goodsQuantity < 0){
                invoiceDetail.goodsQuantity = 1;
            }            
            if(invoiceDetail.goodsPrice < 0){
                invoiceDetail.goodsPrice = 0;
            }
            if(invoiceDetail.goodsTaxRate < 0){
                invoiceDetail.goodsTaxRate = 0;
            }
            invoiceDetail.goodsTotalPrice = invoiceDetail.goodsPrice = DataUtils.round(invoiceDetail.invoiceTotalPriceTax/(1+invoiceDetail.goodsTaxRate), 7);
            invoiceDetail.goodsTotalTax = DataUtils.round(invoiceDetail.goodsTotalPrice * invoiceDetail.goodsTaxRate, 2);
            updateTtl();
        }

        function updateTtl(){
            vm.invoiceOpenRequest.invoiceTotalPrice = 0;
            vm.invoiceOpenRequest.invoiceTotalTax = 0;
            vm.invoiceOpenRequest.invoiceTotalPriceTax = 0;
            angular.forEach(vm.invoiceOpenRequest.invoiceDetailsList, function(data) {
                if(data.goodsTotalPrice){
                    vm.invoiceOpenRequest.invoiceTotalPrice += (data.goodsTotalPrice - 0);
                }
                if(data.goodsTotalTax){
                    vm.invoiceOpenRequest.invoiceTotalTax += (data.goodsTotalTax - 0);
                }
                vm.invoiceOpenRequest.invoiceTotalPriceTax += ((data.goodsTotalPrice - 0) + (data.goodsTotalTax - 0));
            })
            angular.forEach(vm.invoiceOpenRequest.invoiceDetailsList, function(data) {
                data.goodsPrice = (data.goodsPrice - 0).toFixed(7);
                data.goodsTotalPrice = (data.goodsTotalPrice - 0).toFixed(2);
                data.goodsTotalTax = (data.goodsTotalTax - 0).toFixed(2);
                data.goodsTaxRate = (data.goodsTaxRate - 0).toFixed(2);
            })
            vm.invoiceOpenRequest.invoiceTotalPrice = (vm.invoiceOpenRequest.invoiceTotalPrice - 0).toFixed(2);
            vm.invoiceOpenRequest.invoiceTotalTax = (vm.invoiceOpenRequest.invoiceTotalTax - 0).toFixed(2);
            vm.invoiceOpenRequest.invoiceTotalPriceTax = (vm.invoiceOpenRequest.invoiceTotalPriceTax - 0).toFixed(2);
        }

        $scope.addGoods = function() {
            vm.invoiceOpenRequest.invoiceDetailsList.push({
                goodsUnit: "次",
                goodsQuantity: 1
            })
        }

        $scope.delGoods = function(index) {
            vm.invoiceOpenRequest.invoiceDetailsList.splice(index, 1);
            updateTtl();
        };
        $scope.includeIssued = false;
        $scope.issuedFilter = function(invoice) {
            if(!$scope.includeIssued && invoice.isIssued){
                return false;
            }
            return true;
        }

        $scope.createInvoice = function(sendRequired) {
            if(vm.isSaving){
                return;
            }
            if(!initSuccess){
                layer.msg($translate.instant("global.serviceNA"));
                return;
            }
            vm.isSaving = true;
            var loadIndex = layer.load(1, {shade: 0.3});
            AccountInvoice.createInvoice(vm.invoiceOpenRequest, function(result) {
                layer.close(loadIndex);  
                vm.isSaving = false;
                angular.copy(result, $scope.accountInvoice);
                $scope.accountInvoice.isIssued = false;
                if(sendRequired){
                    if(vm.invoiceOpenRequest.invoiceTypeCode == '026'){
                        $scope.sendEInvoice();
                    }
                    else {
                        $scope.printInvoice(false);
                    }
                }
                layer.msg($translate.instant("global.messages.submitted"));                
            }, function(error) {
                layer.close(loadIndex);  
                vm.isSaving = false;
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                } 
                else {
                    layer.msg($translate.instant("global.messages.submittedfailed")); 
                }                 
            });  
        }

        $scope.printInvoice = function(fcPrint) {
            if(vm.isSaving){
                return;
            }
            if(!initSuccess){
                layer.msg($translate.instant("global.serviceNA"));
                return;
            }
            vm.isSaving = true;
            var loadIndex = layer.load(1, {shade: 0.3});            
            vm.invoiceOpenRequest.organizationCode = $scope.accountInvoice.invoiceCode;
            vm.invoiceOpenRequest.originalInvoiceNo = $scope.accountInvoice.invoiceNum;
            AccountInvoice.printInvoice({forcePrint: fcPrint},vm.invoiceOpenRequest, function(result) {
                layer.close(loadIndex);  
                vm.isSaving = false;
                angular.copy(result, $scope.accountInvoice);
                layer.msg($translate.instant("global.messages.submitted"));
            }, function(error) {
                layer.close(loadIndex);  
                vm.isSaving = false;
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                } 
                else {
                    layer.msg($translate.instant("global.messages.submittedfailed")); 
                }  
            });  
        }

        $scope.sendEInvoice = function() {
            if(!initSuccess){
                layer.msg($translate.instant("global.serviceNA"));
                return;
            }
            if(!vm.invoiceOpenRequest.invoiceReceipt){
                layer.msg("请填写电票接收地址");
                return;
            }

            var ccArray = vm.invoiceOpenRequest.invoiceReceipt.split(";");            
            var myreg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
            for (var i = 0; i< ccArray.length; i++){
                 ccArray[i] = ccArray[i].replace(/\s+/g,"");                  
                 if(!myreg.test(ccArray[i]) && ccArray[i] != ""){            
                     var alertContent = ccArray[i] + ": " + $translate.instant('global.messages.validate.email.invalid');
                     layer.msg(alertContent);
                     return;
                 }
            }
            var loadIndex = layer.load(1, {shade: 0.3});

            vm.isSaving = true;
            vm.invoiceOpenRequest.organizationCode = $scope.accountInvoice.invoiceCode;
            vm.invoiceOpenRequest.originalInvoiceNo = $scope.accountInvoice.invoiceNum;
            AccountInvoice.invoiceFile(vm.invoiceOpenRequest, function(result) {
                layer.close(loadIndex);  
                vm.isSaving = false;
                if(result && result.printTime){
                    angular.copy(result, $scope.accountInvoice);
                }                
                layer.msg($translate.instant("global.messages.submitted"));
            }, function(error) {
                layer.close(loadIndex);  
                vm.isSaving = false;
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                } 
                else {
                    layer.msg($translate.instant("global.messages.submittedfailed")); 
                }  
            });  
        }

        $scope.voidInvoice = function() {
            if(!initSuccess){
                layer.msg($translate.instant("global.serviceNA"));
                return;
            }
            if(!$scope.accountInvoice.invoiceNum || $scope.accountInvoice.isTtl){
                return;
            }            
            layer.confirm($translate.instant('autopsApp.accountInvoice.voidInvoiceAlert'), {
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    doVoidInvoice();
                    layer.close(index);
                }, function() {
            });
        }


        var doVoidInvoice = function() {
            vm.isSaving = true;
            AccountInvoice.voidInvoice(vm.invoiceOpenRequest, function(result) {
                vm.isSaving = false;
                angular.copy(result, $scope.accountInvoice);
                layer.msg($translate.instant("global.invalid_success"));
            }, function(error) {
                vm.isSaving = false;
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                } 
                else {
                    layer.msg($translate.instant("global.messages.submittedfailed")); 
                } 
            });
        }

        $scope.monitorData = function() {
            if(!initSuccess){
                layer.msg($translate.instant("global.serviceNA"));
                return;
            }
            vm.isSaving = true;
            AccountInvoice.monitorData(vm.invoiceOpenRequest, function(result) {
                $uibModal.open({
                    templateUrl: 'app/entities/accountant/accountInvoice/invoice-monitor-dialog.html',
                    controller: 'InvoiceMonitorDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: [function() {
                            return result;
                        }]
                    }
                }).result.then(function(result) {
                }, function() {
                });
                vm.isSaving = false;
                layer.msg($translate.instant("entity.action.loadSuccess"));
            }, function(error) {
                vm.isSaving = false;
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                } 
                else {
                    layer.msg($translate.instant("global.loadFailed")); 
                }  
            }); 
        }

        $scope.invoicePurchaseQuery = function() {
            if(!initSuccess){
                layer.msg($translate.instant("global.serviceNA"));
                return;
            }
            vm.isSaving = true;
            AccountInvoice.invoicePurchaseQuery(vm.invoiceOpenRequest, function(result) {
                $uibModal.open({
                    templateUrl: 'app/entities/accountant/accountInvoice/invoice-purchase-dialog.html',
                    controller: 'InvoicePurchaseDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: [function() {
                            return result;
                        }]
                    }
                }).result.then(function(result) {
                }, function() {
                });
                vm.isSaving = false;
                layer.msg($translate.instant("entity.action.loadSuccess"));
            }, function(error) {
                vm.isSaving = false;
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                } 
                else {
                    layer.msg($translate.instant("global.loadFailed")); 
                }  
            }); 
        }


        $scope.invoiceQuery = function() {
            if(!initSuccess){
                layer.msg($translate.instant("global.serviceNA"));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountInvoice/invoice-query-dialog.html',
                controller: 'InvoiceQueryDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return angular.copy(vm.invoiceOpenRequest);
                    }]
                }
            }).result.then(function(result) {
            }, function() {
            });
        }
        vm.companySearch;
        $scope.companySearch = function() {            
            layer.msg($translate.instant("global.developingAlert"));
            return;

            if(!initSuccess){
                layer.msg($translate.instant("global.serviceNA"));
                return;
            }
            vm.isSaving = true;
            AccountInvoice.companySearch({
                companyName: vm.invoiceOpenRequest.buyerName
            }, function(result) {
                vm.companySearch = result;
                vm.isSaving = false;
                layer.msg($translate.instant("entity.action.loadSuccess"));
            }, function(error) {
                vm.isSaving = false;
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                } 
                else {
                    layer.msg($translate.instant("global.messages.submittedfailed")); 
                }  
            }); 
        }
        
        vm.invoiceCheck = null;
        $scope.invoiceCheck = function() {
            layer.msg($translate.instant("global.developingAlert"));
            return;
            if(!initSuccess){
                layer.msg($translate.instant("global.serviceNA"));
                return;
            }
            if(!$scope.accountInvoice.invoiceCode){
                $scope.accountInvoice.invoiceCode = "234";
            }
            if(!$scope.accountInvoice.invoiceNum || !$scope.accountInvoice.invoiceCode){
                return;
            }
            AccountInvoice.findInvoice({
                invoiceCode: $scope.accountInvoice.invoiceCode,
                invoiceNumber: $scope.accountInvoice.invoiceNum,
                taxNo: vm.invoiceOpenRequest.sellerTaxNo
            }, function(result) {
                vm.invoiceCheck = result;
                vm.isSaving = false;
                layer.msg($translate.instant("global.messages.submitted"));
            }, function(error) {
                vm.isSaving = false;
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                } 
                else {
                    layer.msg($translate.instant("global.messages.submittedfailed")); 
                }  
            }); 
        }

        vm.companyIllegal = null;
        $scope.companyIllegal = function() {
            layer.msg($translate.instant("global.developingAlert"));
            return;
            if(!initSuccess){
                layer.msg($translate.instant("global.serviceNA"));
                return;
            }
            AccountInvoice.companyIllegal({
                taxNo: vm.invoiceOpenRequest.buyerTaxNo
            }, function(result) {
                vm.companyIllegal = result;
                vm.isSaving = false;
                layer.msg($translate.instant("global.messages.submitted"));
            }, function(error) {
                vm.isSaving = false;
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                } 
                else {
                    layer.msg($translate.instant("global.messages.submittedfailed")); 
                }  
            }); 
        }

        $scope.onGoodsSelected = function(item, goodsLine) {
            goodsLine.goodsCode = item.goodsCode;
            goodsLine.goodsName = item.goodsName;
            goodsLine.goodsUnit = item.goodsUnit;
            goodsLine.goodsTaxRate = item.goodsTaxRate;
            goodsLine.preferentialMark = item.preferentialMark;
            goodsLine.freeTaxMark = item.freeTaxMark;
            goodsLine.vatSpecialManagement = item.vatSpecialManagement;  
            $scope.updateGroup(goodsLine);
        }

        $scope.goodsList = null;
        $scope.getGoodsCode = function() {
            // if(!initSuccess){
            //     layer.msg($translate.instant("global.serviceNA"));
            //     return;
            // }
            vm.isSaving = true;     
            AccountInvoice.getGoodsCode({companyId: $rootScope.account.companyId}, function(results) {
                $scope.goodsList = results;
                vm.isSaving = false;
            }, function(error) {
                vm.isSaving = false;
            });  
        }
    }
})();

