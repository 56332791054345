(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('GuideFk', GuideFk);

    GuideFk.$inject = ['$resource','DateUtils'];
    
    function GuideFk($resource, DateUtils) {
        function initGuideResponse(data){
            if (!data){return;}    
            data = angular.fromJson(data);            
            if (data.bookingTrucks){
                angular.forEach(data.bookingTrucks, function(item){
                    item.loadTime = DateUtils.convertDateTimeFromServer(item.loadTime);
                })
            }
            if (data.bookingCustoms){
                angular.forEach(data.bookingCustoms, function(item){
                    item.declareDate = DateUtils.convertLocalDateFromServer(item.declareDate);
                })
            }
            return data;
        }

        function initGuideRequest (data) {
            data = angular.copy(data);
            if (data.bookingTrucks){
                angular.forEach(data.bookingTrucks, function(item){
                    item.loadTime = DateUtils.convertDateTimeFromServer(item.loadTime);
                })
            }
            if (data.bookingCustoms){
                angular.forEach(data.bookingCustoms, function(item){
                    item.declareDate = DateUtils.convertLocalDateToServer(item.declareDate);
                })
            }
            return angular.toJson(data);
        }

        return $resource('api/guideFk', {}, {
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'get': {
                method: 'GET',                
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'amassSynCustoms': {
                method: 'GET',           
                url:'api/guideFk/amassSynCustoms'
            }
            
        });
    }
})();
