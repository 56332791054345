(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('opsShipper', {
                parent: 'entity',
                url: '/opsShippers',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_CLIENT'],
                    pageTitle: 'autopsApp.opsShipper.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/opsShipper/opsShippers.html',
                        controller: 'OpsShipperController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsShipper');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('opsShipper.detail', {
                parent: 'entity',
                url: '/opsShipper',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'autopsApp.opsShipper.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/opsShipper/opsShipper-detail.html',
                        controller: 'OpsShipperDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsShipper');
                        return $translate.refresh();
                    }],
                    entity: ['$rootScope', 'OpsShipper', function($rootScope, OpsShipper) {
                        if ($rootScope.id){
                        return OpsShipper.get({id : $rootScope.id});
                    }
                    }]
                }
            })
            .state('opsShipper.delete', {
                parent: 'opsShipper',
                url: '/delete',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$rootScope', '$state', '$uibModal', function($rootScope, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/opsShipper/opsShipper-delete-dialog.html',
                        controller: 'OpsShipperDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['OpsShipper', function(OpsShipper) {
                                if ($rootScope.id){
                                    return {    
                                        companyName: $rootScope.num, 
                                        id: $rootScope.id
                                        };
                                }
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('opsShipper', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }
})();
