(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('opsTemplate', {
                parent: 'entity',
                url: '/opsTemplates',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_CLIENT'],
                    pageTitle: 'autopsApp.opsTemplate.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/opsTemplate/opsTemplates.html',
                        controller: 'OpsTemplateController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('booking');
                        $translatePartialLoader.addPart('opsTemplate');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('opsTemplate.detail', {
                parent: 'opsTemplate',
                url: '/opsTemplate',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_CLIENT'],
                    pageTitle: 'autopsApp.opsTemplate.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/opsTemplate/opsTemplate-detail.html',
                        controller: 'OpsTemplateDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsTemplate');
                        $translatePartialLoader.addPart('booking');
                        return $translate.refresh();
                    }],
                    entity: ['$rootScope', 'OpsTemplate', function($rootScope, OpsTemplate) {
                        if ($rootScope.id){
                        return OpsTemplate.get({id : $rootScope.id});
                    }
                    }]
                }
            })
            .state('opsTemplate.new', {
                parent: 'opsTemplate',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_CLIENT'],
                    pageTitle: 'autopsApp.opsTemplate.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/opsTemplate/opsTemplate-detail.html',
                        controller: 'OpsTemplateDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsTemplate');
                        $translatePartialLoader.addPart('booking');
                        return $translate.refresh();
                    }],
                    entity: ['$rootScope', 'OpsTemplate', function($rootScope, OpsTemplate) {
                            return {
                                    createdBy: $rootScope.account.id,
                                    creater: null,
                                    companyId: null,
                                    code: null,
                                    remarks: null,
                                    createdTime: null,
                                    rates:[],
                                    id: null
                                };
                    }]
                }
            })
            .state('opsTemplate.delete', {
                parent: 'opsTemplate',
                url: '/delete',
                data: {
                    authorities: ['ROLE_USER', 'ROLE_CLIENT'],
                },
                onEnter: ['$rootScope', '$state', '$uibModal', function($rootScope, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/opsTemplate/opsTemplate-delete-dialog.html',
                        controller: 'OpsTemplateDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['OpsTemplate', function(OpsTemplate) {
                                if ($rootScope.id){
                                    return {    
                                        code: $rootScope.num, 
                                        id: $rootScope.id
                                        };
                                }
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('opsTemplate', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }
})();
