(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AutoCheckDetailController', AutoCheckDetailController);

    AutoCheckDetailController.$inject = ['$scope', '$rootScope', '$translate', '$http', '$timeout', '$state', '$uibModal', 'previousState', 'Principal',
    'AutoCheck', 'DateUtils', 'OpsVender', 'entity', 'AutoCheckItem', 'OpsFiles', 'uiGridConstants', 'DataUtils', 'GridState'];

    function AutoCheckDetailController($scope, $rootScope, $translate, $http, $timeout, $state, $uibModal, previousState, Principal,
        AutoCheck, DateUtils, OpsVender, entity, AutoCheckItem, OpsFiles, uiGridConstants, DataUtils, GridState) {

        var vm = this;
        vm.save = save;
        vm.previousState = previousState.name;
        vm.delSystemOne = delSystemOne;
        vm.delImportedOne = delImportedOne;
        vm.getRatesByRefNum = getRatesByRefNum;
        vm.setMcBillNum = setMcBillNum;
        vm.delImportedNotMatched = delImportedNotMatched;
        vm.delSystemNotMatched = delSystemNotMatched;
        vm.ratesCopy = [];

        $scope.files = [];
        $scope.billNumEnabled = false;
        $scope.delOne = function (rate) {
            delImportedOneDo(rate);
            delSystemOneDo(rate)
            $scope.typeChange();
        }

        function delSystemOne (rate) {
            delSystemOneDo(rate)
            $scope.typeChange();
        }

        function delSystemOneDo(rate) {
            if($scope.autoCheck.byTotal){
                for(var i = vm.ratesCopy.length - 1; i >= 0; i--){
                    if(vm.ratesCopy[i][$scope.autoCheck.ref] == rate[$scope.autoCheck.ref] && vm.ratesCopy[i].currency == rate.currency){
                        vm.ratesCopy.splice(i, 1);
                    }
                }
            }
            else {
                for(var i = vm.ratesCopy.length - 1; i >= 0; i--){
                    if(vm.ratesCopy[i].id == rate.id){
                        vm.ratesCopy.splice(i, 1);
                    }
                }
            }
        }
        function delSystemNotMatched () {
            angular.forEach($scope.gridOptions.data, function(rate){
                if(!rate.refNumI){
                    delSystemOneDo(rate);
                }
            });
            $scope.typeChange();
            save();
        }
        function delImportedOne (rate) {
            delImportedOneDo(rate);
            $scope.typeChange();
        }
        function delImportedOneDo(rate) {
            if(!rate){
                return;
            }
            if($scope.autoCheck.byTotal){
                for(var i = $scope.autoCheck.autoCheckItems.length - 1; i >= 0; i--){
                    if($scope.autoCheck.autoCheckItems[i].ref == rate.refNumI){
                        if($scope.autoCheck.autoCheckItems[i].id){
                            AutoCheckItem.delete({id: $scope.autoCheck.autoCheckItems[i].id});
                        }
                        $scope.autoCheck.autoCheckItems.splice(i, 1);
                    }
                }
            }
            else if(rate.billId){
                var index = -1;
                for(var i = 0; i < $scope.autoCheck.autoCheckItems.length; i++){
                    if(rate.billId == $scope.autoCheck.autoCheckItems[i].id){
                        index = i;
                        break;
                    }
                }
                if(index > -1)   {
                    $scope.autoCheck.autoCheckItems.splice(index, 1);
                }
                AutoCheckItem.delete({id: rate.billId});
            }
            else {
                var importedOne = angular.copy(rate);
                var delOne = {};
                if(importedOne.refNumI){
                    delOne.refNumI = importedOne.refNumI.replace(/^\s+|\s+$/gm,'');
                }
                if(importedOne.itemI){
                    delOne.itemI = importedOne.itemI.replace(/^\s+|\s+$/gm,'');
                }
                if(importedOne.currencyI){
                    delOne.currencyI = importedOne.currencyI.replace(/^\s+|\s+$/gm,'');
                }
                delOne.totalI = importedOne.totalI;
                var index = -1;
                for(var i = 0; i < $scope.autoCheck.autoCheckItems.length; i++){
                    if($scope.autoCheck.autoCheckItems[i].ref){
                        $scope.autoCheck.autoCheckItems[i].ref = $scope.autoCheck.autoCheckItems[i].ref.replace(/^\s+|\s+$/gm,'');
                    }
                    if($scope.autoCheck.autoCheckItems[i].item){
                        $scope.autoCheck.autoCheckItems[i].item = $scope.autoCheck.autoCheckItems[i].item.replace(/^\s+|\s+$/gm,'');
                    }
                    if($scope.autoCheck.autoCheckItems[i].currency){
                        $scope.autoCheck.autoCheckItems[i].currency = $scope.autoCheck.autoCheckItems[i].currency.replace(/^\s+|\s+$/gm,'');
                    }
                    if(delOne.refNumI == $scope.autoCheck.autoCheckItems[i].ref
                        && delOne.currencyI == $scope.autoCheck.autoCheckItems[i].currency
                        && delOne.itemI == $scope.autoCheck.autoCheckItems[i].item
                        && delOne.totalI == $scope.autoCheck.autoCheckItems[i].amount){
                        index = i;
                        break;
                    }
                }
                if(index > -1)   {
                    $scope.autoCheck.autoCheckItems.splice(index, 1);
                }
            }
        }


        function delImportedNotMatched () {
            angular.forEach($scope.gridOptions.data, function(rate){
                if(!rate.totalB){
                    delImportedOneDo(rate);
                }
            });
            $scope.typeChange();
        }
        $scope.delNotSelected = function() {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            angular.forEach($scope.gridOptions.data, function(data){
                if(selectedRows.indexOf(data) == -1){
                    delImportedOneDo(data);
                    delSystemOneDo(data);
                }
            })
            $scope.typeChange();
            $scope.gridApi.selection.clearSelectedRows();
        }

        $scope.delSelected = function() {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            angular.forEach(selectedRows, function(data){
                delImportedOneDo(data);
                delSystemOneDo(data);
            })
            $scope.typeChange();
            $scope.gridApi.selection.clearSelectedRows();
        }
        $scope.delSysChoosed = function(cur) {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            angular.forEach(selectedRows, function(data){
                if(data.currency == cur){
                    delSystemOneDo(data);
                }
            })
            $scope.typeChange();
            $scope.gridApi.selection.clearSelectedRows();
        }

        $scope.delImpChoosed = function(cur) {
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            angular.forEach(selectedRows, function(data){
                if(data.currencyI == cur){
                    delImportedOneDo(data);
                }
            })
            $scope.typeChange();
            $scope.gridApi.selection.clearSelectedRows();
        }

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                $rootScope.loadCurrencys();
            });
        }
        else {
            $rootScope.loadCurrencys();
        }
        $scope.autoCheck = entity;
        if(!$scope.autoCheck.ref){
            $scope.autoCheck.ref = "jobNum";
        }
        $scope.cols = 6;
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            initAutoCheck();
            autoCheckGet();
            $rootScope.LOADALLVENDERS();
            if($rootScope.isYuyue($rootScope.account.companyId)){
                gridStateCopy = $scope.gridApi.saveState.save();
                GridState.getUserGridState({
                    userId: $rootScope.account.id,
                    gridId: 160
                }, function(result) {
                    gridStateObj = result;
                    if (!gridStateObj || !gridStateObj.id) {
                        $scope.gridApi.saveState.restore($scope, gridStateCopy);
                        return;
                    }
                    $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                })
            }
        });
        function autoCheckGet () {
            if($scope.autoCheck.id){
                $scope.isSaving = true;
                layer.load(1, {shade: 0.3});
                AutoCheck.autoCheckGet($scope.autoCheck, function(result) {
                    $scope.isSaving = false;
                    layer.closeAll();
                    vm.ratesCopy = angular.copy(result);
                    $scope.typeChange();
                }, function(response) {
                    $scope.isSaving = false;
                    layer.closeAll();
                });
            }
        }

        function initAutoCheck () {
            if($scope.autoCheck.isPay){
                $scope.autoCheck.isCn = true;
                $scope.autoCheck.isDn = false;
            }
            else {
                $scope.autoCheck.isCn = false;
                $scope.autoCheck.isDn = true;
            }
            if($scope.autoCheck.byTotal){
                $scope.cols = 5;
            }
            if($scope.autoCheck.billNum){
                $scope.billNumEnabled = true;
            }
            else {
                $scope.billNumEnabled = false;
            }
            if(!$scope.autoCheck.id && $rootScope.isYuyue($rootScope.account.companyId)){
                $scope.autoCheck.exBilled = true;
            }
        }

        $scope._onClientSelected = function(data) {
            $scope.autoCheck.payerVid = data.id;
        }

        $scope.clearRatesList = function() {
            $scope.autoCheck.idSet = null;
            vm.ratesCopy = [];
            $scope.typeChange();
            save();
        }

        $scope.clearBothList = function() {
            if($scope.autoCheck.id){
                AutoCheck.deleteAutoCheckItems({id: $scope.autoCheck.id});
            }
            $scope.autoCheck.autoCheckItems = [];
            vm.ratesCopy = [];
            $scope.autoCheck.idSet = null;
            $scope.typeChange();
            save();
        }

        $scope.getContents = function() {
            if (!$scope.autoCheck.payerVid || !$scope.autoCheck.dateFrom || !$scope.autoCheck.dateTo) {
                layer.msg($translate.instant('autopsApp.autoCheck.datePayerNullAlert'));
                return;
            }
            if (DateUtils.compareDate($scope.autoCheck.dateFrom, $scope.autoCheck.dateTo) > 0) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                return;
            }
            if (DateUtils.getDaysDiff($scope.autoCheck.dateFrom, $scope.autoCheck.dateTo) > 365) {
                layer.msg($translate.instant('global.messages.etdPeriodAlert'));
                return;
            }

            var loadIndex = layer.load(1, {shade: 0.3});
            $scope.autoCheck.companyId = $rootScope.account.companyId;
            if(!$scope.autoCheck.item){
                $scope.autoCheck.itemId = null;
            }
            AutoCheck.autoheckSearch($scope.autoCheck, function(result) {
                layer.close(loadIndex);
                if (!result || result.length == 0) {
                    layer.msg($translate.instant('global.messages.resultNull'));
                    return;
                }
                if(!vm.ratesCopy){
                    vm.ratesCopy = [];
                }
                var ratesTemp = [];
                angular.forEach(result, function(newRate){
                    var notNew = false;
                    angular.forEach(vm.ratesCopy, function(oldRate){
                        if(newRate.id == oldRate.id){
                            notNew = true;
                        }
                    })
                    if(!notNew){
                        ratesTemp.push(newRate);
                    }
                })
                angular.forEach(ratesTemp, function(newRate){
                    vm.ratesCopy.push(newRate);
                })
                $scope.typeChange();
            }, $rootScope.ALERT_ERROR);
        }

        $scope.datePickerForEtd = {};
        $scope.datePickerForEtd.status = {
            opened: false
        };
        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };
        $scope.datePickerForCargoReadyDate = {};
        $scope.datePickerForCargoReadyDate.status = {
            opened: false
        };
        $scope.datePickerForCargoReadyDateOpen = function($event) {
            $scope.datePickerForCargoReadyDate.status.opened = true;
        };

        $scope.typeChange = function() {
            //币种和收付过滤
            for (var i = vm.ratesCopy.length - 1; i >= 0; i--) {
                if ($scope.autoCheck.currency && $scope.autoCheck.currency != vm.ratesCopy[i].currency){
                    vm.ratesCopy.splice(i, 1);
                }
                if(!$scope.autoCheck.isDiff && $scope.autoCheck.isDn && vm.ratesCopy[i].isPay){
                    vm.ratesCopy.splice(i, 1);
                    continue;
                }
                if(!$scope.autoCheck.isDiff && $scope.autoCheck.isCn && !vm.ratesCopy[i].isPay){
                    vm.ratesCopy.splice(i, 1);
                    continue;
                }
            }
            $scope.gridOptions.data = angular.copy(vm.ratesCopy);

            //合计数
            if ($scope.autoCheck.byTotal){
                $scope.cols = 5;
                var nums = new Set();
                var currencies = new Set();
                var ttlList = [];
                var ttl = 0;
                var guideId = null;
                var expressParcelId = null;
                angular.forEach($scope.gridOptions.data, function(data){
                    nums.add(data[$scope.autoCheck.ref]);
                })
                angular.forEach($scope.gridOptions.data, function(data){
                    currencies.add(data.currency);
                })
                nums.forEach(function(num){
                    currencies.forEach(function(currency){//s.has(100);//返回bool结果，true表示有，false表示无
                        ttl = 0;
                        guideId = null;
                        expressParcelId = null;
                        angular.forEach($scope.gridOptions.data, function(data){
                            if (data.currency == currency && data[$scope.autoCheck.ref] == num && data.totalB){
                                ttl = ttl + data.totalB;
                                if(data.guideId){
                                    guideId = data.guideId;
                                }
                                else if (data.expressParcelId){
                                    expressParcelId = data.expressParcelId;
                                }
                            }
                        })
                        if (ttl){
                            var newRate = {};
                            newRate[$scope.autoCheck.ref] = num;
                            newRate.currency = currency;
                            newRate.item = "Total";
                            newRate.totalB = ttl.toFixed(2) -0;;
                            newRate.guideId = guideId;
                            newRate.expressParcelId = expressParcelId;
                            ttlList.push(newRate);
                        }
                    });
                });
                $scope.gridOptions.data = ttlList;
            }
            else {
                $scope.cols = 6;
            }
            var refChanged = false;
            angular.forEach($scope.gridOptions.columnDefs, function(columnDef){
                if(vm.refTypes.indexOf(columnDef.field) !== -1 && $scope.autoCheck.ref){
                    refChanged = true;
                }
                if(columnDef.field == "etd"){
                    columnDef.visible = !$scope.autoCheck.byTotal;
                }
            })
            if(refChanged){
                $scope.gridOptions.columnDefs.splice(0, 1);
                $scope.gridOptions.columnDefs.splice(0, 0, getRefCol());
            }
            if($rootScope.isYuyue($rootScope.account.companyId)){
                $scope.gridOptions.enableGridMenu = true;                
            }
            else {
                for(var index = $scope.gridOptions.columnDefs.length - 1; index >= 0; index--){
                    var col = $scope.gridOptions.columnDefs[index];
                    if(col.field == "count" || col.field == "price" || col.field == "unit" || col.field == "payer" || col.field == "remarks"){
                        $scope.gridOptions.columnDefs.splice(index, 1);
                    }
                    if(col.field == "countI" || col.field == "priceI" || col.field == "unitI" || col.field == "payerI"){
                        $scope.gridOptions.columnDefs.splice(index, 1);
                    }
                }
            }
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
            autoCheck();
        }
        vm.refTypes = ["dnNum", "mblNum", "soNum", "jobNum", "ctnNumsStr", "hblNum", "poNum", "billNum", "shipmentId", "fbaRef"];
        var getRefCol = function(){
            return { field: $scope.autoCheck.ref,
                    width:180,
                    enableColumnMenu: false,
                    cellTemplate: '<span class="grid-text"><span class=" text-primary" ng-click="grid.appScope.VIEWGE(row.entity)">{{row.entity[grid.appScope.autoCheck.ref]}}</span><span class="text-danger glyphicon glyphicon-remove-circle ml-5" ng-click="grid.appScope.vm.delSystemOne(row.entity)" data-toggle="tooltip" data-placement="top" title="{{\'autopsApp.autoCheck.delSystemOne\' | translate}}" ng-if="row.entity[grid.appScope.autoCheck.ref]"></span></span>',
                    displayName: $translate.instant('autopsApp.autoCheck.' + $scope.autoCheck.ref) + "(S)"};
        }
        var autoCheck = function () {
            var bills = angular.copy($scope.autoCheck.autoCheckItems);
            angular.forEach(bills, function(bill){
                if(bill.ref){
                    bill.ref = bill.ref.replace(/^\s+|\s+$/gm,'');
                }
                if(bill.item){
                    bill.item = bill.item.replace(/^\s+|\s+$/gm,'');
                }
                if(bill.currency){
                    bill.currency = bill.currency.replace(/^\s+|\s+$/gm,'');
                }
                if(!bill.amount){
                    bill.amount = 0;
                }
            })
            if ($scope.autoCheck.byTotal){
                var nums = new Set();
                var currencies = new Set();
                var ttlList = [];
                var ttl = 0;

                angular.forEach(bills, function(data){
                    nums.add(data.ref);
                })
                angular.forEach(bills, function(data){
                    currencies.add(data.currency);
                })
                nums.forEach(function(num){
                    currencies.forEach(function(currency){//s.has(100);//返回bool结果，true表示有，false表示无
                        ttl = 0;
                        angular.forEach(bills, function(data){
                            if (data.currency == currency && data.ref == num && data.amount){
                                ttl = ttl + data.amount;
                            }
                        })
                        if (ttl){
                            var newRate = {};
                            newRate.ref = num;
                            newRate.currency = currency;
                            newRate.item = "Total";
                            newRate.amount = ttl;
                            ttlList.push(newRate);
                        }
                    });
                });
                bills = ttlList;
            }
            angular.forEach($scope.gridOptions.data, function(rate){
                if(rate[$scope.autoCheck.ref]){
                    rate[$scope.autoCheck.ref] = rate[$scope.autoCheck.ref].replace(/^\s+|\s+$/gm,'');
                }
                else {
                    rate[$scope.autoCheck.ref] = "";
                }
                if(rate.item){
                    rate.item = rate.item.replace(/^\s+|\s+$/gm,'');
                }
                if(rate.currency){
                    rate.currency = rate.currency.replace(/^\s+|\s+$/gm,'');
                }
            })
            if(bills && bills.length > 0){
                var rate;
                //参考号 币种 金额 类型 完全匹配
                for (var i = bills.length - 1; i >= 0; i--) {
                    for (var j = 0; j < $scope.gridOptions.data.length; j++) {
                        rate = $scope.gridOptions.data[j];
                        if(!rate.matched && refMatched(rate, bills[i].ref) && bills[i].currency == rate.currency && bills[i].amount == rate.totalB && bills[i].item == rate.item){
                            updateMatchedBill(rate, bills[i]);
                            bills.splice(i, 1);         
                            break;              
                        }
                    }
                }
                //参考号 币种 类型匹配 金额不匹配
                for (var i = bills.length - 1; i >= 0; i--) {
                    for (var j = 0; j < $scope.gridOptions.data.length; j++) {
                        rate = $scope.gridOptions.data[j];
                        if(!rate.matched && refMatched(rate, bills[i].ref) && bills[i].currency == rate.currency && bills[i].item == rate.item){
                            updateMatchedBill(rate, bills[i]);
                            bills.splice(i, 1);
                            break;  
                        }
                    }
                }
                //参考号 币种 金额匹配 类型不匹配
                for (var i = bills.length - 1; i >= 0; i--) {
                    for (var j = 0; j < $scope.gridOptions.data.length; j++) {
                        rate = $scope.gridOptions.data[j];
                        if(!rate.matched && refMatched(rate, bills[i].ref) && bills[i].currency == rate.currency && bills[i].amount == rate.totalB){
                            updateMatchedBill(rate, bills[i]);
                            bills.splice(i, 1);
                            break;  
                        }
                    }
                }
                //参考号 币种匹配 金额 类型不匹配
                for (var i = bills.length - 1; i >= 0; i--) {
                    for (var j = 0; j < $scope.gridOptions.data.length; j++) {
                        rate = $scope.gridOptions.data[j];
                        if(!rate.matched && refMatched(rate, bills[i].ref) && bills[i].currency == rate.currency){
                            updateMatchedBill(rate, bills[i]);
                            bills.splice(i, 1);
                            break;  
                        }
                    }
                }
                //参考号 币种不匹配的情况
                for (var i = bills.length - 1; i >= 0; i--) {
                    var notMatchedBill = {};
                    updateMatchedBill(notMatchedBill, bills[i]);
                    notMatchedBill.matched = null;
                    $scope.gridOptions.data.push(notMatchedBill);

                    
                }
            }
            angular.forEach($scope.gridOptions.data, function(rate){
                if(!rate.matched && rate.totalB){
                    rate.balance = rate.totalB.toFixed(2) -0;
                }
            })
            getTotalList();
        }

        var updateMatchedBill = function(rate, bill) {
            rate.refNumI = bill.ref;
            rate.itemI = bill.item;
            rate.currencyI = bill.currency;
            rate.totalI = bill.amount.toFixed(2) -0;
            if(rate.totalB){
                rate.balance = rate.totalB - bill.amount;
            }
            else {
                rate.balance = 0 - bill.amount;
            }
            
            rate.balance = rate.balance.toFixed(2) -0;
            rate.matched = true;
            rate.billId = bill.id;

            //YY
            rate.countI = bill.count;
            rate.priceI = bill.price;
            rate.unitI = bill.batch;
            rate.payerI = bill.subRef;
        }

        var refMatched = function(rate, billRef) {
            if($scope.autoCheck.ref == "ctnNumsStr"){//包含匹配
                return rate[$scope.autoCheck.ref] && rate[$scope.autoCheck.ref].indexOf(billRef) !== -1 ;
            }
            else {
                return rate[$scope.autoCheck.ref] && rate[$scope.autoCheck.ref] == billRef;
            }
        }

        var getTotalList = function() {
            $scope.totalList = [];
            var currencies = new Set();
            angular.forEach($scope.gridOptions.data, function(data){
                currencies.add(data.currency);
                currencies.add(data.currencyI);
            })
            $scope.colTtl = {
                totalB: "",
                totalI: "",
                balance: "",
            };
            var matched;
            currencies.forEach(function(currency){
                var ttl = 0;
                var ttlI = 0;
                matched = false;
                angular.forEach($scope.gridOptions.data, function(data){
                    if (data.currency == currency && data.totalB){
                        ttl = ttl + data.totalB;
                        matched = true;
                    }
                    if (data.currencyI == currency && data.totalI){
                        ttlI = ttlI + data.totalI;
                        matched = true;
                    }
                })
                if (matched){
                    var newRate = {};
                    newRate.currency = currency;
                    newRate.totalB = ttl.toFixed(2) -0;
                    newRate.totalI = ttlI.toFixed(2) -0;
                    newRate.balance = ttl - ttlI;
                    newRate.balance = newRate.balance.toFixed(2) -0;
                    $scope.totalList.push(newRate);

                    $scope.colTtl.totalB += currency + newRate.totalB + " ";
                    $scope.colTtl.totalI += currency + newRate.totalI + " ";
                    $scope.colTtl.balance += currency + newRate.balance + " ";
                }
            });
            $scope.autoCheck.checkAmt = "";
            angular.forEach($scope.totalList, function(data){
                $scope.autoCheck.checkAmt += data.currency + data.totalB + " - " + data.totalI + " = " + data.balance + "; ";
            })
            resize();
            $translate.refresh();
        }
        var getCheckedTotalList = function() {
            $scope.checkedTotalList = [];
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if(selectedRows.length == 0){
                $translate.refresh();
                return;
            }
            var currencies = new Set();
            angular.forEach(selectedRows, function(data){
                currencies.add(data.currency);
                currencies.add(data.currencyI);
            })
            var matched;
            currencies.forEach(function(currency){
                var ttl = 0;
                var ttlI = 0;
                matched = false;
                angular.forEach(selectedRows, function(data){
                    if (data.currency == currency && data.totalB){
                        ttl = ttl + data.totalB;
                        matched = true;
                    }
                    if (data.currencyI == currency && data.totalI){
                        ttlI = ttlI + data.totalI;
                        matched = true;
                    }
                })
                if (matched){
                    var newRate = {};
                    newRate.currency = currency;
                    newRate.totalB = ttl.toFixed(2) -0;
                    newRate.totalI = ttlI.toFixed(2) -0;
                    newRate.balance = ttl - ttlI;
                    newRate.balance = newRate.balance.toFixed(2) -0;
                    $scope.checkedTotalList.push(newRate);
                }
            });
            $translate.refresh();
        }

        $scope.importBills = function (file) {
            $scope.clearImports();
            if (!file) {return;}
            if(!file.name || angular.lowercase(file.name).indexOf(".xlsx") == -1){
                $rootScope.OPS_ALERT($translate.instant('global.xlsAlert'));
                return;
            }
            $scope.isSaving = true;
            var fd = new FormData();
            fd.append('file', file);
            $http.post(
                'api/auto-checks/importBills', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    $scope.isSaving = false;
                    data = angular.fromJson(data);
                    if (!data || data.length == 0){
                        layer.msg($translate.instant('global.messages.resultNull'));
                        return;
                    }
                    $scope.autoCheck.autoCheckItems = data;
                    autoCheck();
                    layer.msg($translate.instant('autopsApp.autoCheck.doChecking'));
                    setTimeout(function() {$scope.doCheck(false);}, 1000);
                }).error(function(error) {
                    $scope.isSaving = false;
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                });
        };

        $scope.clearImports = function () {
            if($scope.autoCheck.id){
                AutoCheck.deleteAutoCheckItems({id: $scope.autoCheck.id});
            }
            $scope.autoCheck.autoCheckItems = [];
            for (var i = $scope.gridOptions.data.length - 1; i >= 0; i--) {
                if(!$scope.gridOptions.data[i].totalB){
                    $scope.gridOptions.data.splice(i, 1);
                    continue;
                }
                $scope.gridOptions.data[i].refNumI = null;
                $scope.gridOptions.data[i].itemI = null;
                $scope.gridOptions.data[i].currencyI = null;
                $scope.gridOptions.data[i].totalI = null;
                $scope.gridOptions.data[i].balance = null;
                $scope.gridOptions.data[i].billId = null;
                $scope.gridOptions.data[i].matched = false;
            }
            $scope.typeChange();
        }

        $scope.setIsPay = function (isPay) {
            if(vm.ratesCopy && vm.ratesCopy.length > 0){
                layer.msg($translate.instant('autopsApp.autoCheck.clearAllAlert'));
                return;
            }
            $scope.autoCheck.isPay = isPay;
            initAutoCheck();
        }
        function getRateIdSet () {
            var idSet = "";
            angular.forEach(vm.ratesCopy, function(rate){
                if(rate.id && rate.id > 0){
                    idSet = idSet + rate.id + ";";
                }
                else if (rate.dnNum){
                    idSet = idSet + rate.dnNum + ";";
                }
            })
            return idSet;
        }
        function save () {
            if (!$scope.autoCheck.code) {
                layer.msg($translate.instant('autopsApp.autoCheck.nameNullAlert'));
                return;
            }
            if (!$scope.autoCheck.payerVid || !$scope.autoCheck.payer) {
                layer.msg($translate.instant('autopsApp.autoCheck.payerNullAlert'));
                return;
            }
            if ($scope.autoCheck.submited) {
                layer.msg($translate.instant('autopsApp.autoCheck.submitedAlert'));
                return;
            }
            vm.isSaving = true;
            if(!$scope.autoCheck.item){
                $scope.autoCheck.itemId = null;
            }
            $scope.autoCheck.idSet = getRateIdSet();
            $scope.autoCheck.companyId = $rootScope.account.companyId;
            if ($scope.autoCheck.id !== null) {
                AutoCheck.update($scope.autoCheck, onSaveSuccess, onSaveError);
            } else {
                AutoCheck.save($scope.autoCheck, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.autoCheck = result;
            initAutoCheck();
            autoCheckGet();
            layer.msg($translate.instant('global.save_success'));
            vm.isSaving = false;
        }

        function onSaveError () {
            layer.msg($translate.instant('global.save_fail'));
            vm.isSaving = false;
        }

        // function goBack () {
        //     $state.go('auto-check');
        // }

        $scope.searchType = 'Job#';
        $scope.setSearchType = function(type) {
            $scope.searchType = type;
        }
        vm.searchStr = null;
        function getRatesByRefNum (bizType) {
            if($scope.isSaving){return;}
            $scope.isSaving = true;
            if(!vm.searchStr){
                layer.msg($translate.instant('global.search'));
                return;
            }
            var checkCopy = angular.copy($scope.autoCheck);
            checkCopy.companyId = $rootScope.account.companyId;
            if(!checkCopy.item){
                checkCopy.itemId = null;
            }
            AutoCheck.getRatesByRefNum({
                searchType: $scope.searchType,
                bizType: bizType,
                inputStr: vm.searchStr
            }, checkCopy, function(newRates) {
                $scope.isSaving = false;
                if (!newRates || newRates.length == 0){
                    layer.msg($translate.instant('global.messages.resultNull'));
                    return;
                }
                addNewRates(newRates);
            }, function(response) {
                $scope.isSaving = false;
            });
        }

        var addNewRates = function(newRates) {
            if(!newRates || newRates.length == 0){
                return;
            }
            var ratesTemp = [];
            var dateFrom = newRates[0].etd;
            var dateTo = newRates[0].etd;
            angular.forEach(newRates, function(newRate){
                if(newRate.etd < dateFrom){
                    dateFrom = newRate.etd;
                }
                if(newRate.etd > dateTo){
                    dateTo = newRate.etd;
                }
                var notNew = false;
                angular.forEach(vm.ratesCopy, function(oldRate){
                    if(newRate.id == oldRate.id){
                        notNew = true;
                    }
                });
                if(!notNew){
                    ratesTemp.push(newRate);
                }
            })
            angular.forEach(ratesTemp, function(newRate){
                vm.ratesCopy.push(newRate);
            })
            $scope.typeChange();
            if(!$scope.autoCheck.dateFrom){
                $scope.autoCheck.dateFrom = DateUtils.convertLocalDateFromServer(dateFrom);
            }
            if(!$scope.autoCheck.dateTo){
                $scope.autoCheck.dateTo = DateUtils.convertLocalDateFromServer(dateTo);
            }
        }

        $scope.doCheck = function(fuzzyCheck) {
            if ($scope.formInValid()) {
                layer.msg($translate.instant('autopsApp.autoCheck.doCheckAlert'));
                return;
            }
            var checkCopy = angular.copy($scope.autoCheck);
            if(!checkCopy.item){
                checkCopy.itemId = null;
            }
            if(fuzzyCheck){
                checkCopy.payerVid = null;
            }
            checkCopy.companyId = $rootScope.account.companyId;
            $scope.isSaving = true;
            AutoCheck.importMore(checkCopy, function(newRates) {
                $scope.isSaving = false;
                if (!newRates || newRates.length == 0){
                    layer.msg($translate.instant('global.messages.resultNull'));
                    $scope.isSaving = false;
                    return;
                }
                vm.ratesCopy = newRates;
                $scope.typeChange();
            }, function(response) {
                $scope.isSaving = false;
            });
        }

        $scope.formInValid = function() {
            if($scope.autoCheck.ref){
                if($scope.autoCheck.payerVid && $scope.autoCheck.payer){
                    return false;
                }
                if($rootScope.isYuyue($rootScope.account.companyId)){
                    return false;
                }
            }
            return true;
        }


        $scope._onItemSelected = function(data) {
            $scope.autoCheck.itemId = data.id;
        }

        $scope.setFdValue= function(fd, expImp) {
            $scope.autoCheck[fd] = expImp;
        }

        $scope.booleanFdChange= function(fd) {
            if(vm.ratesCopy && vm.ratesCopy.length > 0){
                layer.msg($translate.instant('autopsApp.autoCheck.clearAllAlert'));
                return;
            }
            $scope.autoCheck[fd] = !$scope.autoCheck[fd];
        }


        $scope.searchPopFms = function() {
            if (!$scope.autoCheck.payerVid) {
                layer.msg($translate.instant('autopsApp.autoCheck.payerNullAlert'));
                return;
            }
            if($rootScope.HAVENOAUTH('FMS')){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            var searchParams = {
                autoCheck: $scope.autoCheck,
                companyId: $rootScope.account.companyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/guide/guide-search-dialog.html',
                controller: 'GuideSearchDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(results) {
                addNewRates(results);
            }, function() {
            });
        }

        $scope.searchPopParcel = function(type) {
            if (!$scope.autoCheck.payerVid) {
                layer.msg($translate.instant('autopsApp.autoCheck.payerNullAlert'));
                return;
            }
            if(type == "TMS" && $rootScope.HAVENOAUTH('TMS')){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            if($rootScope.HAVENOAUTH('PARCEL') && type == "PARCEL"){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            var searchParams = {
                submited : true,
                autoCheck: $scope.autoCheck,
                shipmentType: type,
                companyId: $rootScope.account.companyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/express-parcel/express-parcel-detail.html',
                controller: 'ExpressParcelDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('expressParcel');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {
                addNewRates(results);
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        $scope.searchPopWms = function(isout) {
            if (!$scope.autoCheck.payerVid) {
                layer.msg($translate.instant('autopsApp.autoCheck.payerNullAlert'));
                return;
            }
            if($rootScope.HAVENOAUTH('WMS')){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            var searchParams = {
                companyId: $rootScope.account.companyId,
                autoCheck: $scope.autoCheck,
                isOut: isout
            };
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-search.html',
                controller: 'WmsInSearchController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {
                addNewRates(results);
            }, function() {
            });
        }
        $scope.excelExport = function() {
            if(!$scope.gridOptions.data || $scope.gridOptions.data.length == 0){
                layer.msg($translate.instant('autopsApp.autoCheck.listNullAlert'));
                return;
            }
            var autoCheckDTO = angular.copy($scope.autoCheck);
            autoCheckDTO.contents = $scope.gridOptions.data;
            autoCheckDTO.totalList = $scope.totalList;
            OpsFiles.excelAutoCheck({
            }, autoCheckDTO, function(result) {
                $rootScope.DOWNLOADEXCEL(result, $translate.instant('autopsApp.autoCheck.detail.title') + ".xlsx");
            })
        }
        function setMcBillNum (){
            if (!$scope.autoCheck.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            var title;
            if($scope.autoCheck.billNum){
                title = $translate.instant('autopsApp.autoCheck.clearMcNumAlert');
            }
            else {
                title = $translate.instant('autopsApp.autoCheck.mcNumAlert');
            }
            layer.confirm(title, {
                icon: 3,
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                $scope.autoCheck.idSet = getRateIdSet();
                AutoCheck.setMcBillNum($scope.autoCheck, onSaveSuccess, onSaveError);
            }, function() {
                $scope.billNumEnabled = !$scope.billNumEnabled;
            });
        }
        $scope.setByBillDate = function (byBillDate) {
            if(vm.ratesCopy && vm.ratesCopy.length > 0){
                layer.msg($translate.instant('autopsApp.autoCheck.clearAllAlert'));
                return;
            }
            $scope.autoCheck.byBillDate = byBillDate;
        }


        var filterTimer, gridCopy;
        $scope.gridOptions = {
            enableSorting: false,//排序
            useExternalSorting: false,

            enableGridMenu : false,//表格 菜单
            showGridFooter: true,//表格的footer
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤

            enableRowHeaderSelection : true,  //行选中
            enableRowSelection: true, //行选中
            enableSelectAll: true, //全部选中
            enableFullRowSelection : false, //行任意位置选中
            multiSelect: true,//多行选中

            enableHorizontalScrollbar : 1,//表格的水平滚动条
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)
            // minimumColumnSize: 70,
            gridFooterTemplate:'<div class="ml-5 mt-5"><strong><span translate="stats.stat.totalText" class="text-muted"></span><span ng-repeat="total in grid.appScope.totalList track by $index" ng-class="{true: \'label label-success\', false: \'label label-primary\'}[$index%2 ==0]" class="mr-8 f-13">{{total.currency}}{{total.totalB}} - {{total.currency}}{{total.totalI}} = {{total.currency}}{{total.balance}}</span></strong></div>',
            columnDefs: [
                { field: 'jobNum',
                    width:180,
                    enableColumnMenu: false,
                    cellTemplate: '<div class="ui-grid-cell-contents"><span class="text-primary" ng-click="grid.appScope.VIEWGE(row.entity)">{{row.entity[grid.appScope.autoCheck.ref]}}</span><span class="text-danger glyphicon glyphicon-remove-circle ml-5" ng-click="grid.appScope.vm.delSystemOne(row.entity)" data-toggle="tooltip" data-placement="top" title="{{\'autopsApp.autoCheck.delSystemOne\' | translate}}" ng-if="row.entity[grid.appScope.autoCheck.ref]"></span></div>',
                    displayName: $translate.instant('autopsApp.autoCheck.' + $scope.autoCheck.ref) + "(S)"},
                { field: 'etd',
                    type:'date',
                    enableColumnMenu: false,
                    visible: !$scope.autoCheck.byTotal,
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.autoCheck.bizDate') + "(S)",
                    cellTemplate: '<span class="grid-text">{{row.entity.etd | date:\'mediumDate\'}}</span>',
                    width:120},
                { field: 'item',
                    width:120,
                    enableColumnMenu: false,
                    displayName: $translate.instant('autopsApp.autoCheck.item') + "(S)"},
                { field: 'currency',
                    width:80,
                    enableColumnMenu: false,
                    displayName: $translate.instant('autopsApp.autoCheck.currency') + "(S)"},
                { field: 'totalB',
                    type:'number',
                    enableColumnMenu: false,
                    displayName: $translate.instant('autopsApp.autoCheck.total') + "(S)",
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    footerCellTemplate: '<div class="ml-5">{{grid.appScope.colTtl.totalB}}</div>',
                    cellTemplate: '<div class="ui-grid-cell-contents text-right"><strong>{{grid.appScope.FORMAT_NUM(row.entity.totalB)}}</strong></div>',
                    width:150},
                { field: 'price', 
                    type:'number',
                    cellTemplate: '<div class="ui-grid-cell-contents text-right">{{grid.appScope.FORMAT_NUM(row.entity.price)}}</div>',
                    displayName: $translate.instant('stats.stat.price'),
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    width:100},
                { field: 'count', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.guideRates.count'),
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<div class="ui-grid-cell-contents text-right">{{grid.appScope.FORMAT_NUM(row.entity.count)}}</div>',
                    width:100},
                { field: 'unit', 
                    width:100,
                    displayName: $translate.instant('stats.stat.guideRates.unit')},
                { field: 'payer', 
                    width:100,
                    displayName: $translate.instant('autopsApp.autoCheck.payer')},
                { field: 'jobNum', 
                    width:100,
                    displayName: $translate.instant('autopsApp.autoCheck.jobNum')},
               { field: 'porName', 
                    width:100,
                    displayName: $translate.instant('autopsApp.guide.porName')},         
                { field: 'deliveryto', 
                    width:100,
                    displayName: $translate.instant('autopsApp.guide.deliveryTo')},                 
                { field: 'remarks', 
                    width:100,
                    displayName: $translate.instant('stats.stat.remarks')},                    
                { field: 'importedData',
                    width:10,
                    displayName: "",
                    enableFiltering: false,
                    enableColumnMenu: false},
                { field: 'refNumI',
                    width:180,
                    cellTemplate: '<span class="grid-text"><span>{{row.entity.refNumI}}</span><span class="text-danger glyphicon glyphicon-remove-circle ml-5" ng-click="grid.appScope.vm.delImportedOne(row.entity)" ng-if="row.entity.totalI" data-toggle="tooltip" data-placement="top" title="{{\'autopsApp.autoCheck.delImportedOne\' | translate}}"></span></span>',
                    enableColumnMenu: false,
                    displayName: $translate.instant('autopsApp.autoCheck.ref') + "(I)"},
                { field: 'itemI',
                    width:120,
                    enableColumnMenu: false,
                    displayName: $translate.instant('autopsApp.autoCheck.item') + "(I)"},
                { field: 'currencyI',
                    width:80,
                    enableColumnMenu: false,
                    displayName: $translate.instant('autopsApp.autoCheck.currency') + "(I)"},
                { field: 'totalI',
                    type:'number',
                    enableColumnMenu: false,
                    displayName: $translate.instant('autopsApp.autoCheck.total') + "(I)",
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    footerCellTemplate: '<div class="ml-5">{{grid.appScope.colTtl.totalI}}</div>',
                    cellTemplate: '<span class="grid-text"><strong>{{grid.appScope.FORMAT_NUM(row.entity.totalI)}}</strong><span class="glyphicon glyphicon-ok-circle ml-5" ng-if="row.entity.totalI == row.entity.totalB"></span></span>',
                    width:150},
                { field: 'priceI', 
                    type:'number',
                    cellTemplate: '<div class="ui-grid-cell-contents text-right">{{grid.appScope.FORMAT_NUM(row.entity.priceI)}}</div>',
                    displayName: $translate.instant('stats.stat.price') + "(I)",
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    width:100},
                { field: 'countI', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.guideRates.count') + "(I)",
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<div class="ui-grid-cell-contents text-right">{{grid.appScope.FORMAT_NUM(row.entity.countI)}}</div>',
                    width:100},
                { field: 'unitI', 
                    width:100,
                    displayName: $translate.instant('stats.stat.guideRates.unit') + "(I)"},
                { field: 'payerI', 
                    width:100,
                    displayName: $translate.instant('autopsApp.autoCheck.payer') + "(I)"},
                { field: 'balanceData',
                    width:10,
                    displayName: "",
                    enableFiltering: false,
                    enableColumnMenu: false},
                { field: 'balance',
                    type: 'number',
                    width:150,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    enableColumnMenu: false,
                    footerCellTemplate: '<div class="ml-5">{{grid.appScope.colTtl.balance}}</div>',
                    cellTemplate: '<strong class="grid-text">{{grid.appScope.FORMAT_NUM(row.entity.balance)}}</strong>',
                    displayName: $translate.instant('autopsApp.autoCheck.diff') + "(B)"},
                { field: 'delOne',
                    width: 1000,
                    displayName: $translate.instant('entity.action.remove') + "(B)",
                    enableFiltering: false,
                    enableColumnMenu: false,
                    cellTemplate: '<span class="grid-text text-danger" ng-click="grid.appScope.delOne(row.entity)"><span class="glyphicon glyphicon-remove-circle"></span><span translate="entity.action.remove"></span></span>'}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 160;
                    GridState.update(gridStateObj, function(result) {
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error) {
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if (gridStateObj.gridState) {
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }    
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);                   
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    $scope.gridOptions.exporterExcelFilename = getTitleStr();

                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "autoCheck";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);                   
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    $scope.gridOptions.exporterExcelFilename = getTitleStr();
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "autoCheck";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],

            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                gridApi.selection.on.rowSelectionChanged($scope,function(row){
                    getCheckedTotalList();
                });
                gridApi.selection.on.rowSelectionChangedBatch($scope,function(row){
                    getCheckedTotalList();
                });
                gridApi.core.on.filterChanged($scope, function() {
                    gridCopy = this.grid;
                    clearTimeout(filterTimer);
                    filterTimer = setTimeout(function(){
                        getVisibelTtl();
                    }, 1000);//查询延时时间，单位ms
                });
            }
        }
        function getTitleStr() {
            var outStr = $scope.autoCheck.code + " @" + $scope.autoCheck.payer;
            return outStr;
        }
       $scope.delNotFiltered = function() {
            var selectedRows = $scope.gridApi.core.getVisibleRows(gridCopy);
            angular.forEach($scope.gridOptions.data, function(data){
                if(data.entity && selectedRows.indexOf(data.entity) == -1){
                    delImportedOneDo(data.entity);
                    delSystemOneDo(data.entity);
                }
            })
            $scope.typeChange();
            $scope.gridApi.core.clearAllFilters();
        }

        $scope.delFiltered = function() {
            var selectedRows = $scope.gridApi.core.getVisibleRows(gridCopy);
            angular.forEach(selectedRows, function(data){
                if(data.entity){
                    delImportedOneDo(data.entity);
                    delSystemOneDo(data.entity);
                }
            })
            $scope.typeChange();
            $scope.gridApi.core.clearAllFilters();
        }
        $scope.delSysFiltered = function(cur) {
            var selectedRows = $scope.gridApi.core.getVisibleRows(gridCopy);
            angular.forEach(selectedRows, function(data){
                if(data.entity && data.entity.currency == cur){
                    delSystemOneDo(data.entity);
                }
            })
            $scope.typeChange();
            $scope.gridApi.core.clearAllFilters();
        }

        $scope.delImpFiltered = function(cur) {
            var selectedRows = $scope.gridApi.core.getVisibleRows(gridCopy);
            angular.forEach(selectedRows, function(data){
                if(data.entity && data.entity.currencyI == cur){
                    delImportedOneDo(data.entity);
                }
            })
            $scope.typeChange();
            $scope.gridApi.core.clearAllFilters();
        }

        $scope.visibelTtlList = [];
        var getVisibelTtl = function() {
            $scope.visibelTtlList = [];
            if(!DataUtils.getFilterRequired(gridCopy)) {
                $translate.refresh();
                return;
            }
            var visibleRows = $scope.gridApi.core.getVisibleRows(gridCopy);
            if(visibleRows.length == 0){
                $translate.refresh();
                return;
            }
            var currencies = new Set();
            angular.forEach(visibleRows, function(data){
                if(data.entity){
                    currencies.add(data.entity.currency);
                    currencies.add(data.entity.currencyI);
                }
            })
            var matched;
            currencies.forEach(function(currency){
                var ttl = 0;
                var ttlI = 0;
                matched = false;
                angular.forEach(visibleRows, function(data){
                    if (data.entity && data.entity.currency == currency && data.entity.totalB){
                        ttl = ttl + data.entity.totalB;
                        matched = true;
                    }
                    if (data.entity && data.entity.currencyI == currency && data.entity.totalI){
                        ttlI = ttlI + data.entity.totalI;
                        matched = true;
                    }
                })
                if (matched){
                    var newRate = {};
                    newRate.currency = currency;
                    newRate.totalB = ttl.toFixed(2) -0;
                    newRate.totalI = ttlI.toFixed(2) -0;
                    newRate.balance = ttl - ttlI;
                    newRate.balance = newRate.balance.toFixed(2) -0;
                    $scope.visibelTtlList.push(newRate);
                }
            });
            $translate.refresh();
        }


        window.onresize = function(){
            resize();
        }
        function resize() {
            if(!$("#gridId") || !$("#gridId").offset()){
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 200;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 300){
                tabHeight = 300;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        $scope.createOaApplication = function() {
            var autoCheckDTO = angular.copy($scope.autoCheck);
            autoCheckDTO.contents = $scope.gridOptions.data;
            autoCheckDTO.totalList = $scope.totalList;
            $rootScope.CREATE_OAAPPLICATION(autoCheckDTO);
        }
        $scope.amassAutoDiffAdjust = function() {
            if ($rootScope.HAVENOAUTH('AU_FEE_FORCED_MODIFICATION')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_FEE_FORCED_MODIFICATION');
                return;
            }
            if ($scope.autoCheck.byTotal) {
                layer.msg($translate.instant('按合计数对账模式下不支持费用调整!'));
                return;
            }
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            if(!selectedRows || !selectedRows.length){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            layer.confirm("确定要对选中记录，进行费用调整吗？", {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                var autoCheckDTO = angular.copy($scope.autoCheck);
                autoCheckDTO.contents = selectedRows;
                AutoCheck.amassAutoDiffAdjust(autoCheckDTO, function(){
                    layer.msg("费用调整完成，重新加载数据！");
                    $scope.doCheck(true);
                }, $rootScope.ALERT_ERROR);
            }, function() {});
        }
        


    }
})();
