(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountRefDialogController', AccountRefDialogController);

    AccountRefDialogController.$inject = ['$scope', '$translate', '$rootScope', '$uibModalInstance', 'entity', 'AccountRef'];
    
    function AccountRefDialogController($scope, $translate, $rootScope, $uibModalInstance, entity, AccountRef) {

        $scope.accountRef = entity;
        
        var onSaveSuccess = function (result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('global.save_success'));
            $uibModalInstance.close(result);
            // $scope.$emit('autopsApp:accountRefUpdate', result);
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('global.save_fail'));            
        };

        $scope.save = function () {            
            if ($scope.accountRef.id != null) {
                AccountRef.update($scope.accountRef, onSaveSuccess, onSaveError);
            } else {
                AccountRef.save($scope.accountRef, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.upperCode = function(code) {
            $scope.accountRef.code = angular.uppercase(code);
        };

    }
})();