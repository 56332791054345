(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('ops-exchange-rate', {
            parent: 'company',
            url: '/ops-exchange-rate?page&sort&search',
            data: {
                authorities: ['FUN_EXCHANGE_RATES'],
                pageTitle: 'autopsApp.opsExchangeRate.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/ops-exchange-rate/ops-exchange-rates.html',
                    controller: 'OpsExchangeRateController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('opsExchangeRate');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('ops-exchange-rate-detail', {
            parent: 'company',
            url: '/ops-exchange-rate',
            data: {
                authorities: ['FUN_EXCHANGE_RATES'],
                pageTitle: 'autopsApp.opsExchangeRate.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/ops-exchange-rate/ops-exchange-rate-detail.html',
                    controller: 'OpsExchangeRateDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('opsExchangeRate');
                    return $translate.refresh();
                }],
                entity: ['$rootScope', 'OpsExchangeRate', function($rootScope, OpsExchangeRate) {
                    return OpsExchangeRate.get({id : $rootScope.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'ops-exchange-rate',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('ops-exchange-rate-detail.edit', {
            parent: 'ops-exchange-rate-detail',
            url: '/detail/edit',
            data: {
                authorities: ['FUN_EXCHANGE_RATES']
            },
            onEnter: ['$rootScope', '$state', '$uibModal', function($rootScope, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ops-exchange-rate/ops-exchange-rate-dialog.html',
                    controller: 'OpsExchangeRateDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'md',
                    resolve: {
                        entity: ['OpsExchangeRate', function(OpsExchangeRate) {
                            if ($rootScope.id){
                                return OpsExchangeRate.get({id : $rootScope.id}).$promise;
                            }
                            
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('ops-exchange-rate.new', {
            parent: 'ops-exchange-rate',
            url: '/new',
            data: {
                authorities: ['FUN_EXCHANGE_RATES']
            },
            onEnter: ['$rootScope', '$state', '$uibModal', function($rootScope, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ops-exchange-rate/ops-exchange-rate-dialog.html',
                    controller: 'OpsExchangeRateDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'md',
                    resolve: {
                        entity: function () {
                            var now = new Date();
                            now.setDate(now.getDate()+1);  
                            return {
                                companyId: null,
                                effectiveDate: now,
                                exchangeRate: null,
                                currency: null,
                                remarks: null,
                                isLocked: false,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('ops-exchange-rate', null, { reload: 'ops-exchange-rate' });
                }, function() {
                    $state.go('ops-exchange-rate');
                });
            }]
        })
        .state('ops-exchange-rate.edit', {
            parent: 'ops-exchange-rate',
            url: '/edit',
            data: {
                authorities: ['FUN_EXCHANGE_RATES']
            },
            onEnter: ['$rootScope', '$state', '$uibModal', function($rootScope, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ops-exchange-rate/ops-exchange-rate-dialog.html',
                    controller: 'OpsExchangeRateDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'md',
                    resolve: {
                        entity: ['OpsExchangeRate', function(OpsExchangeRate) {
                            if ($rootScope.id){
                                return OpsExchangeRate.get({id : $rootScope.id}).$promise;
                            }                            
                        }]
                    }
                }).result.then(function() {
                    $state.go('ops-exchange-rate', null, { reload: 'ops-exchange-rate' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('ops-exchange-rate.delete', {
            parent: 'ops-exchange-rate',
            url: '/delete',
            data: {
                authorities: ['FUN_EXCHANGE_RATES']
            },
            onEnter: ['$rootScope', '$state', '$uibModal', function($rootScope, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ops-exchange-rate/ops-exchange-rate-delete-dialog.html',
                    controller: 'OpsExchangeRateDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['OpsExchangeRate', function(OpsExchangeRate) {
                            if ($rootScope.id){
                                return OpsExchangeRate.get({id : $rootScope.id}).$promise;
                            }                            
                        }]
                    }
                }).result.then(function() {
                    $state.go('ops-exchange-rate', null, { reload: 'ops-exchange-rate' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
