(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsFactoryDetailController', OpsFactoryDetailController);

    OpsFactoryDetailController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$http', '$translate', 'entity', 'ReportTemplate', 'OpsCompany'];
    
    function OpsFactoryDetailController($scope, $rootScope, $uibModalInstance, $http, $translate, entity, ReportTemplate, OpsCompany) {
        $scope.reportTemplate = entity;
        $scope.isGroup = false;

        $scope.clear = function () {
            $uibModalInstance.close();
        }
        
        $scope.delTemplete = function(){
            ReportTemplate.delTemplate({
                companyId: $scope.reportTemplate.companyId,
                type: $scope.reportTemplate.type
            }, function(result){
                layer.msg($translate.instant('global.delete_success'));
            }, function(result){
                if(result.status == 404){
                    layer.msg($translate.instant('global.noRecord'));
                }
                else {
                    layer.msg($translate.instant('global.delete_fail'));
                }       
            })
        }

        $scope.uploadTemplate = function ($file) {
            var fd = new FormData();
            fd.append('reportTemplate', $file);
            fd.append('companyId', $scope.reportTemplate.companyId);
            fd.append('type', $scope.reportTemplate.type);
            fd.append('companyName', $scope.reportTemplate.companyName);
            fd.append('isGroup', $scope.isGroup);

            $http.post('api/reportTemplates/upload', fd, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined},
            }).success(function(data){
                layer.msg($translate.instant('global.uploadSuccess'));
            }).error(function(error){
                layer.msg($translate.instant('global.uploadFail'));
            });
        }

        $scope.groupCompanys = [];
        $scope.loadCompanys = function() {
            if ($scope.groupCompanys && $scope.groupCompanys.length > 0) {
                return;
            }
            OpsCompany.getBindCompanys(function(result) {
                $scope.groupCompanys = result;
            })
        };

    }
})();
