(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('AccountVoucherSearch', AccountVoucherSearch);

    AccountVoucherSearch.$inject = ['$resource'];
    
    function AccountVoucherSearch($resource) {
        return $resource('api/_search/accountVouchers', {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();    
