(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsChangeLogDeleteController',OpsChangeLogDeleteController);

    OpsChangeLogDeleteController.$inject = ['$uibModalInstance', '$translate', '$rootScope', 'entity'];

    function OpsChangeLogDeleteController($uibModalInstance, $translate, $rootScope, entity) {
        var vm = this;

        vm.content = entity;
        vm.clear = clear;
        vm.confirm = confirm;
        var isCn = false;
        if(entity && entity.isCn){
            isCn = true;
            vm.content = entity.content;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirm () {
            if(isCn || !vm.content){
                $uibModalInstance.close(vm.content);
                return;
            }
            if($rootScope.notBlInfo){
                $uibModalInstance.close(vm.content);
                return;
            }
            var reg = /^[\x00-\xff]/;
            var wideCharStr = "";

            for (var i = 0; i < vm.content.length; i++) {
                var temp = String.valueOf();
                // 判断是全角字符
                if (!reg.test(vm.content[i])) {
                    wideCharStr = wideCharStr + vm.content[i];
                }
            }
            if(wideCharStr.length > 0){
                $rootScope.OPS_ALERT($translate.instant('global.chineseContainedAlert') + ": " + wideCharStr);
            }
            vm.content = angular.uppercase(vm.content);
            $uibModalInstance.close(vm.content);
        }
        // angular.element("#textInputArea").focus();
        
    }
})();
