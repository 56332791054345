(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ContactListController', ContactListController);

    ContactListController.$inject = ['$scope', '$rootScope', '$timeout', '$uibModalInstance', '$translate', 'entity'];
    
    function ContactListController($scope, $rootScope, $timeout, $uibModalInstance, $translate, entity) {

        $scope.contactsList = entity;
        $scope.isSaving = false;
        $scope.venderType = null;

        $timeout(function (){
            if (!$scope.contactsList || $scope.contactsList.length == 0){
                layer.msg($translate.instant('global.messages.resultNull'));
                $uibModalInstance.dismiss('cancel');
            }

            var type = $rootScope.chooseContactVenderType;
            if (type == "customer") {
                $scope.venderType = $translate.instant('autopsApp.guide.customerName')
            } else if (type == "client") {
               $scope.venderType = $translate.instant('autopsApp.guide.home.shipper')
            } else if (type == "coloader") {
                $scope.venderType = $translate.instant('autopsApp.guide.bookingToName')
            } else if (type == "agent") {
                $scope.venderType = $translate.instant('autopsApp.guide.deliveryName')
            } else if (type == "trucker") {
                $scope.venderType = $translate.instant('autopsApp.guide.trucker')
            } else if (type == "broker") {
                $scope.venderType = $translate.instant('autopsApp.guide.broker')
            } else if (type == "warehouse") {
                $scope.venderType = $translate.instant('autopsApp.guide.warehouse')
            } else if (type == "fumigation") {
                $scope.venderType = $translate.instant('autopsApp.guide.fumigation')
            } else if (type == "insurance") {
                $scope.venderType = $translate.instant('autopsApp.guide.insurance')
            } else if (type == "trader") {
                $scope.venderType = $translate.instant('autopsApp.guide.trader')
            } else if (type == "changeContact") {
                $scope.venderType = $translate.instant('autopsApp.guide.trader')
            } else if (type == "clearanceAgent") {
                $scope.venderType = $translate.instant('autopsApp.guide.clearanceAgent')
            }            

        })

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.chooseContact = function (contact){
            $uibModalInstance.close(contact); 
        }




}
})();
