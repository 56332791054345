(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('DataSubscriptionDialogController',DataSubscriptionDialogController);

        DataSubscriptionDialogController.$inject = ['$uibModalInstance', '$rootScope', '$translate', '$timeout', 'entity', 
            'DataSubscription', 'Dictionary'];

    function DataSubscriptionDialogController($uibModalInstance, $rootScope, $translate, $timeout, entity, 
        DataSubscription, Dictionary) {
        var vm = this;

        vm.clear = clear;
        vm.doDataSubscription = doDataSubscription;

        vm.shipCarrierList = Dictionary.shipCarrierList;        
        vm.chinaPorts = Dictionary.chinaPorts;
        vm.usPorts = Dictionary.usPorts;
        vm.shipCarrierList = Dictionary.shipCarrierList;

        vm.setShowIdx = setShowIdx;
        vm.showIdx = null;
        function setShowIdx (showIdx, dataSubscription) {
            if ($rootScope.HAVENOAUTH(dataSubscription.subscriptionType) 
                    && (dataSubscription.subscriptionType != "AU_TRK" || $rootScope.HAVENOAUTH("AU_FREE"))) {
                $rootScope.NO_MODULE_ALERT(dataSubscription.subscriptionType);
                return;
            }
            if (dataSubscription.subscribeSucceeded && !dataSubscription.errorTxt) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.dataSubscription.subscribeSucceededAlert'));
                return;
            }
            vm.showIdx = showIdx;
            dataSubscription.subscribeSucceeded = false;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        };
        
        $timeout(function (){
            getGuideDataSubscription();            
        })

        vm.dataSubscriptions = [];
        function getGuideDataSubscription () {
            DataSubscription.getGuideDataSubscription({}, entity, function(result){
                vm.dataSubscriptions = result;
                $translate.refresh();   
            })          
        }

        function doDataSubscription (dataSubscription) {
            if(dataSubscription.subscribeSucceeded){
                return;
            }
            if (!dataSubscription.dataSource) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.dataSubscription.dataSourceNullAlert'));
                return;
            }
            DataSubscription.doDataSubscription({
            }, dataSubscription, function(result) {
                getGuideDataSubscription();
                if(result.subscribeSucceeded){
                    if(!entity.autoTracking){
                        entity.autoTracking = result.subscribeSucceeded;
                    } 
                    $rootScope.OPS_ALERT($translate.instant('global.subscribeSuccess'));
                }
                else if (result.errorTxt){
                    $rootScope.OPS_ALERT(result.errorTxt);
                }
                else {
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                }                                               
            }, function(error) {
                $rootScope.ALERT_ERROR(error);
            });
        }

    }
})();
