(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('wms-cargo-inventory-query', {
            parent: 'wms',
            url: '/wms-cargo/inventory/query',
            data: {
                authorities: ['FUN_WMS_INVENTORY', 'ROLE_CLIENT'],
                pageTitle: 'autopsApp.wmsCargo.detail.inventory'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wms-cargo/wms-cargo-inventory-new.html',
                    controller: 'WmsCargoInventoryNewController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wmsCargo');
                    $translatePartialLoader.addPart('wmsIn');
                    return $translate.refresh();
                }]
            }
        })
        .state('wms-cargo-fifo', {
            parent: 'wms',
            url: '/wms-cargo/fifo',
            data: {
                authorities: ['FUN_WMS_INVENTORY'],
                pageTitle: 'global.menu.entities.fifoMgmt'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wms-cargo/wms-cargos.html',
                    controller: 'WmsCargoController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wmsCargo');
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('stats');
                    return $translate.refresh();
                }]
            }
        })
        .state('fms-cargo-fifo', {
            parent: 'ops',
            url: '/fms-cargo/fifo',
            data: {
                authorities: ['FUN_WMS_INVENTORY'],
                pageTitle: 'global.menu.entities.fifoMgmt'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wms-cargo/wms-cargos.html',
                    controller: 'WmsCargoController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wmsCargo');
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('stats');                    
                    return $translate.refresh();
                }]
            }
        });
    }

})();
