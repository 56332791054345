(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BookingTruckController', BookingTruckController);

    BookingTruckController.$inject = ['$scope', '$rootScope', '$state', '$translate', '$timeout', '$uibModal',
    'Principal', 'BookingTruck', 'ParseLinks', 'BookingTruckSearch', 'OpsReport', 'Guide'];

    function BookingTruckController($scope, $rootScope, $state, $translate, $timeout, $uibModal,
        Principal, BookingTruck, ParseLinks, BookingTruckSearch, OpsReport, Guide) {

        $scope.isTrucker = true;
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                if ($rootScope.account.authorities.indexOf("ROLE_TRUCKER") === -1){
                    $scope.isTrucker = false;
                }
                else {
                    $scope.isTrucker = true;
                }
            });
        }
        else {
            if ($rootScope.account.authorities.indexOf("ROLE_TRUCKER") === -1){
                $scope.isTrucker = false;
            }
            else {
                $scope.isTrucker = true;
            }
        }

        var filterType = "self";

        $scope.filterSet = function(type) {
            if ($scope.isTrucker){
                layer.msg($translate.instant('autopsApp.bookingTruck.invalidBtn'));
                return;
            }
            filterType = type;
            $scope.loadAll();
        }


        $scope.bookingTrucks = [];
        $scope.predicate = 'id';
        $scope.reverse = false;
        $scope.page = 1;
        $scope.pageSize = 20;
        $scope.currentSearch = false;

        $scope.loadAll = function() {
            $scope.currentSearch = false;
            BookingTruck.query({
                page: $scope.page - 1,
                size: 20,
                sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id'],
                type: filterType
            }, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.bookingTrucks = result;
            });
        };
        $scope.loadAll();
        $scope.reset = function() {
            $scope.currentSearch = false;
            $scope.page = 1;
            $scope.bookingTrucks = [];
            $scope.loadAll();
        };
        $scope.search = function() {
            $scope.currentSearch = true;
            BookingTruckSearch.query({
                query: $scope.searchQuery
            }, function(result) {
                $scope.bookingTrucks = result;
            }, function(response) {
                if (response.status === 404) {
                    $scope.loadAll();
                }
            });
        };

        $scope.previewBookingTruckFile = function(bookingTruck) {
            if (angular.isUndefined(bookingTruck)) {
                console.error('bookingTruck is Undefined!');
                return;
            }
            var guideReportDTO = {};
            guideReportDTO.bookingTruck = bookingTruck;
            guideReportDTO.opType = 1;
            if (bookingTruck.isExport){
                guideReportDTO.reportType = 140;
            }
            else {
                guideReportDTO.reportType = 160;
            }

            guideReportDTO.fileType = 1;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                var file = new Blob([result.data], {
                    type: 'application/pdf'
                });
                var fileURL = URL.createObjectURL(file);
                var previewDialog = layer.open({
                    type: 2,
                    area: ['1000px', '680px'],
                    maxmin: true,
                    title: $translate.instant("autopsApp.bookingTruck.truckFiles"),
                    shade: 0.3,
                    moveType: 0,
                    shift: 0,
                    content: fileURL
                });
            });
        }

        $scope.viewDetail = function(bookingTruck) {
            if ($rootScope.account.authorities.indexOf("ROLE_TRUCKER") !== -1 && (bookingTruck.status =='new' || bookingTruck.status =='Requested')){
                layer.msg($translate.instant('autopsApp.bookingTruck.newAlert'));
                return;
            }
            if ($rootScope.account.authorities.indexOf("ROLE_TRUCKER") !== -1 && bookingTruck.isLocked){
                layer.msg($translate.instant('autopsApp.bookingTruck.lockedAlert'));
                return;
            }
            $rootScope.BKTK = bookingTruck;
            $state.go('bookingTruck.detail');
        }


        $scope.edit = function(id) {
            $rootScope.id = id;
            $state.go('bookingTruck.edit');

        }

        $scope.refresh = function() {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function() {
            $scope.bookingTruck = {
                bookingId: null,
                loadTime: null,
                loadAddress: null,
                attn: null,
                attnMob: null,
                attnTel: null,
                attnFax: null,
                attnEmail: null,
                remarks: null,
                id: null
            };
        };
        $scope.tableHeightStyle = {};
        $timeout(function (){
            var footerHeight = 0;
            if(document.getElementById("footPager")){
                document.getElementById("footPager").style.cssText="margin: 0px;";
                footerHeight = $("#"+"footPager").height();
            }
            var heightPx = $(window).height() - $("#basicTable").offset().top -footerHeight -1;
            $scope.tableHeightStyle = {
              "max-height" : heightPx + "px"
            }
        });

        $scope.truckingMgmt = function(guideId) {
            $uibModal.open({
                templateUrl: 'app/entities/bookingCustom/bookingCustom-delete-dialog.html',
                controller: 'BookingCustomDeleteController',
                size: 'xxl',
                backdrop: false,
                resolve: {
                    entity: [function() {
                        return Guide.getGuideBooking({id : guideId}).$promise;
                    }]
                }
            }).result.then(function(result) {
            }, function() {
            });
        }

    }
})();
