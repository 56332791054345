(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('VoucherContentDialogController', VoucherContentDialogController);

    VoucherContentDialogController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$timeout', '$translate', '$uibModal',
    'entity', 'DataUtils', 'VoucherContent', 'DateUtils', 'OpsFiles'];
    
    function VoucherContentDialogController($scope, $rootScope, $uibModalInstance, $timeout, $translate, $uibModal,
        entity, DataUtils, VoucherContent, DateUtils, OpsFiles) {

        $scope.voucherContents = entity[0].voucherContents;

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $timeout(function (){
            refrestCDC();
        });

        function refrestCDC () {
            var date1, dateArray;
            if ($scope.voucherContents) {
                for (var i = 0; i < $scope.voucherContents.length; i++) {
                    if ($scope.voucherContents[i].makeDate) {
                        date1 = DateUtils.convertLocalDateToServer($scope.voucherContents[i].makeDate);
                        dateArray = date1.split("-");
                        $scope.voucherContents[i].month = dateArray[1];
                        $scope.voucherContents[i].day = dateArray[2];
                    }

                    if ($scope.voucherContents[i].dn || $scope.voucherContents[i].dn == 0) {
                        $scope.voucherContents[i].dn = DataUtils.formatTwoDigits($scope.voucherContents[i].dn);
                    }
                    if ($scope.voucherContents[i].cn || $scope.voucherContents[i].cn == 0) {
                        $scope.voucherContents[i].cn = DataUtils.formatTwoDigits($scope.voucherContents[i].cn);
                    }
                    if ($scope.voucherContents[i].balance || $scope.voucherContents[i].balance == 0) {
                        $scope.voucherContents[i].balance = DataUtils.formatTwoDigits($scope.voucherContents[i].balance);
                    }
                    if ($scope.voucherContents[i].dnCurrencyCount || $scope.voucherContents[i].dnCurrencyCount == 0) {
                        $scope.voucherContents[i].dnCurrencyCount = DataUtils.formatTwoDigits($scope.voucherContents[i].dnCurrencyCount);
                    }
                    if ($scope.voucherContents[i].cnCurrencyCount || $scope.voucherContents[i].cnCurrencyCount == 0) {
                        $scope.voucherContents[i].cnCurrencyCount = DataUtils.formatTwoDigits($scope.voucherContents[i].cnCurrencyCount);
                    }
                    if ($scope.voucherContents[i].currencyBalance || $scope.voucherContents[i].currencyBalance == 0) {
                        $scope.voucherContents[i].currencyBalance = DataUtils.formatTwoDigits($scope.voucherContents[i].currencyBalance);
                        $scope.voucherContents[i].exchangeRate = DataUtils.formatTwoDigits($scope.voucherContents[i].exchangeRate);
                        $scope.voucherContents[i].currencyCount = DataUtils.formatTwoDigits($scope.voucherContents[i].currencyCount);
                    }
                }
            }
        }
        $scope.excelExport = function(vContents) {
            if(!vContents || vContents.length == 0){
                layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                return;
            }
            var title = $translate.instant('global.menu.entities.sub_ledger');
            var fileName = title + ".xlsx";
            title = title + "——" + vContents[0].subLedger;
            var ledgerEntries = [{}];
            ledgerEntries[0].ledgerCode = "";
            ledgerEntries[0].ledgerName = "";
            ledgerEntries[0].voucherContents = angular.copy(vContents);
            OpsFiles.excelVoucherContent({
                singleSheet: true,
                title: title,
                enCn: $translate.instant('global.enCn')
            }, ledgerEntries, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }    
        $scope.getVoucher = function(id) {
            if (null == id) {
                return;
            }
            VoucherContent.getVoucher({
                id: id
            }, function(result) {
                $rootScope.VIEWCONTENTVOUCHER(id, $scope.voucherContents, result);
            });
        };

}
})();
