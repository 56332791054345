(function() {
    'use strict';

    angular
        .module('autopsApp')
        .directive('commonFile', commonFile);

    commonFile.$inject = ['$parse', '$http', 'OpsFiles', '$translate'];

    function commonFile($parse, $http, OpsFiles, $translate) {
        function upload() {

        }
        return {
            restrict: 'AE',
            scope: {
                triggerReload: '&',
                filelist: '='
            },
            link: function(scope, element, attrs, ngModel) {
                scope.hasFile = false;
                var model = $parse(attrs.commonFile);
                var modelSetter = model.assign;
                var confirmLayer = null;
                var fileTemp = null;
                scope.ridFile = function() {
                    angular.forEach(angular.element("input[name='commonFile']"), function(inputElem) {
                        angular.element(inputElem).val(null);
                    });
                }
                scope.upload = function(file) {
                    var fd = new FormData();
                    fd.append('file', fileTemp);
                    $http.post('api/commonFiles/', fd, {
                        transformRequest: angular.identity,
                        headers: { 'Content-Type': undefined },
                    }).success(function(data) {
                        scope.triggerReload();
                    }).error(function(error) {
                        console.log("上传失败!");
                    });
                    layer.close(confirmLayer);
                }
                element.bind('change', function(event) {

                    if (element[0].files[0] == undefined) {
                        return;
                    }
                    scope.$apply(function() {
                        modelSetter(scope, element[0].files[0]);
                    });
                    fileTemp = element[0].files[0];
                    confirmLayer = null;
                    var fileExist = false;
                    if (scope.filelist.length) {
                        for (var i = 0; i < scope.filelist.length; i++) {
                            if (scope.filelist[i].filename == fileTemp.name) {
                                fileExist = true;
                                confirmLayer = layer.confirm(fileTemp.name + ' ' + $translate.instant('home.confirmToCover'), {
                                    btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')]
                                }, function() {
                                    scope.upload(fileTemp);
                                });
                            }
                        }
                        if (!fileExist) {
                            scope.upload(fileTemp);
                        }
                    } else {
                        scope.upload(fileTemp);
                    }
                    scope.ridFile();
                });
            },
        };
    }
})();
(function() {
    'use strict';
    angular.module('autopsApp')
        .directive('numberic', numberic);
    numberic.$inject = ['DataUtils'];

    function numberic(DataUtils) {
        return {
            require: 'ngModel',
            restrict: "EA",
            scope: {
                max: '@',
                maxLength: '@',
                min: '@',
                decimal: '@',
                noFormat: '@'
            },
            link: function(scope, ele, attrs, modelCtrl) {
                //格式化数字
                function formatNum(value) {
                    if(scope.noFormat){
                        return value;
                    }
                    if(null == value || value == undefined){
                        return '';
                    }
                    var num = value + "";
                    if (num == "" || num == "0" || num.indexOf(',') !== -1) {
                        return num;
                    }
                    var decimal = +scope.decimal;
                    if (decimal || decimal == 0) {
                        num = DataUtils.round(num, decimal) + "";
                    }
                    var txt = num.split(".");
                    while (/\d{4}(,|$)/.test(txt[0])) {
                        txt[0] = txt[0].replace(/(\d)(\d{3}(,|$))/, "$1,$2");
                    }
                    return txt[0] + ((txt.length > 1 && txt[1].length > 0)? "." + txt[1] : "");
                }
                //初始化输入框
                modelCtrl.$formatters.push(function(value){
                    var num = formatNum(value);
                    return num;
                });
                //控制输入框，只能输入数字
                modelCtrl.$parsers.push(function(inputValue) {
                    if (inputValue == undefined) {
                        return '';
                    }
                    var max = +scope.max;
                    var maxLength = +scope.maxLength;
                    var min = +scope.min;
                    var transformedInput = inputValue.replace(/[^(\-|\+)?\d+(\.\d+)?$]/g, ''); //
                    if (maxLength && inputValue.length > maxLength) {
                        transformedInput = inputValue.slice(0, maxLength);
                    }
                    if (max && +transformedInput > max) {
                        transformedInput = max + '';
                    }
                    if (min && +transformedInput < min) {
                        transformedInput = min + '';
                    }
                    if (transformedInput != inputValue) {
                        modelCtrl.$setViewValue(transformedInput);
                        modelCtrl.$render();
                    }
                    return transformedInput;
                })
                ele.bind('blur', function(event) {
                    if (ele[0].value.length == 0) {
                        return false;
                    }
                    ele[0].value = formatNum(ele[0].value);
                })
                ele.bind('focus', function(event) {
                    if (ele[0].value.length == 0) {
                        return false;
                    }
                    var num = ele[0].value;
                    var stmp = "";
                    if (num == stmp) {
                        return;
                    }
                    ele[0].value = num.replaceAll(',','');
                })
            }
        }
    }
})();


