(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('ManageEnterpriseSearch', ManageEnterpriseSearch);

    ManageEnterpriseSearch.$inject = ['$resource'];

    function ManageEnterpriseSearch($resource) {
        var resourceUrl =  'api/_search/manage-enterprises/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
