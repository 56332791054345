(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('PredictionDialogController', PredictionDialogController);

    PredictionDialogController.$inject = ['$timeout', '$rootScope', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Prediction', 'OpsVender'];

    function PredictionDialogController ($timeout, $rootScope, $scope, $stateParams, $uibModalInstance, entity, Prediction, OpsVender) {
        var vm = this;

        vm.prediction = entity;
        vm.clear = clear;
        vm.save = save;
        vm._onClientSelected = _onClientSelected;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            if (!vm.prediction.status){
                vm.prediction.status = "requested";
            }   
            if (vm.prediction.id !== null) {
                Prediction.update(vm.prediction, onSaveSuccess, onSaveError);
            } else {
                Prediction.save(vm.prediction, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        $rootScope.LOADALLVENDERS();
        function _onClientSelected (data) {
            vm.prediction.customerId = data.id;
            vm.prediction.customer = data.companyName;
        } 
    }
})();
