(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsCostProductController', OpsCostProductController);

    OpsCostProductController.$inject = ['$scope', '$rootScope', '$timeout', '$translate', '$uibModalInstance', 
    'entity'];

    function OpsCostProductController($scope, $rootScope, $timeout, $translate, $uibModalInstance, 
        entity) {
        var vm = this;

        vm.clear = clear;
        vm.choosePrice = choosePrice;

        function choosePrice (opsCost) {
            opsCost.isMath = true;
            $uibModalInstance.close(opsCost);
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $timeout(function (){
            vm.opsCosts = entity;
            if(vm.opsCosts.length == 0){
                layer.msg($translate.instant('autopsApp.opsCost.noProductRate'));
                clear();
            }
            else if(vm.opsCosts.length == 1){
                layer.msg($translate.instant('autopsApp.opsCost.oneProductRate'));
                vm.opsCosts[0].isMath = false;
                $uibModalInstance.close(vm.opsCosts[0]);
            }
            else{
                layer.msg($translate.instant('autopsApp.opsCost.mutiProductRate'));
            }
        });



    }
})();
