(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsShelveController', WmsShelveController);

    WmsShelveController.$inject = ['$scope', '$rootScope', '$timeout', '$uibModal', '$translate', '$uibModalInstance',
        'WmsIn', 'DataUtils', 'WmsWarehouse'
    ];

    function WmsShelveController($scope, $rootScope, $timeout, $uibModal, $translate, $uibModalInstance, 
        WmsIn, DataUtils,  WmsWarehouse) {
        var vm = this;

        vm._onLocationSelected = _onLocationSelected;
        vm.getBarcode = getBarcode;
        vm.submit = submit;

        vm.clear = clear;

        vm.locations = [];
        vm.location = null;

        vm.wmsIns = [];
        vm.isSaving = false;
        vm.warehousingNumber = null;
        vm.ttl = {};

        function clear () {
            $uibModalInstance.close();                     
        }

        function getBarcode (fd) {
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-dialog.html',
                controller: 'WmsInDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md'
            }).result.then(function(code) {
                if (fd == "location"){ 
                    angular.forEach(vm.locations, function(data){
                        if(data.code == code){
                            _onLocationSelected(data);
                        }
                    });
                }
                else {
                    vm.warehousingNumber = code;
                    submit();
                }
            }, function() {
            });
        }
        function _onLocationSelected (item) {
            vm.location = item;
        }

        function getLocations () {
            if (vm.isSaving || (vm.locations && vm.locations.length > 0)){
                return;
            }
            vm.isSaving = true;
            WmsWarehouse.getAllLocations({companyId: $rootScope.account.companyId}, function (data) {
                vm.locations = data;
                vm.isSaving = false;
            });
        }
        getLocations();

        function submit() {
            if (!vm.warehousingNumber || !vm.location.code) {
                return;
            }
            vm.isSaving = true;
            WmsIn.rapidShelve({
                warehousingNumber: vm.warehousingNumber,
                locationId: vm.location.locationId,
                location:vm.location.code,
                areaId: vm.location.areaId,
                warehouseId: vm.location.warehouseId,
                locationType: vm.location.locationType
            }, function(result) {
                vm.wmsIns.push(result);
                updateTtl();
                vm.warehousingNumber = null;
                $timeout(function() {
                    $("#field_warehousingNumber").focus();
                });
                vm.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(result) {
                vm.isSaving = false;
                if(result.status == 404){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.warehousingNumberNotExist'));
                }
                else if(result.status == 409){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.shelvedAlert'));
                }
                else{
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                }                
            });
        }

        function updateTtl () {
            vm.ttl.pkgs = vm.ttl.gw = vm.ttl.vol = 0;
            angular.forEach(vm.wmsIns, function(data){
                if(data.applicationNum){
                    vm.ttl.pkgs += data.applicationNum;
                }
                if(data.gw){
                    vm.ttl.gw += data.gw;
                }
                if(data.vol){
                    vm.ttl.vol += data.vol;
                }
            })
            vm.ttl.gw = DataUtils.round(vm.ttl.gw, 2);
            vm.ttl.vol = DataUtils.round(vm.ttl.vol, 4);
        }

        
        $(document).keydown(function(event) {            
            if (event.keyCode == 13) {
                if (vm.warehousingNumber && vm.warehousingNumber.length > 3) {
                    submit();
                }
                else {
                    $timeout(function() {
                        $("#field_warehousingNumber").focus();
                    });
                }
            } 
        });




    }
})();