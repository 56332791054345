(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('cost-mgmt', {
            parent: 'entity',
            url: '/cost-management',
            data: {
                authorities: ['FUN_COST_MGMT'],
                pageTitle: 'global.menu.entities.opsCost'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/ops-cost/express-charges.html',
                    controller: 'ExpressChargesController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('opsCost');
                    $translatePartialLoader.addPart('booking');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('selling-mgmt', {
            parent: 'entity',
            url: '/selling-management',
            data: {
                authorities: ['FUN_SELLING_MGMT'],
                pageTitle: 'global.menu.entities.opsSelling'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/ops-cost/express-charges.html',
                    controller: 'ExpressChargesController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('opsCost');
                    $translatePartialLoader.addPart('booking');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('qidian-freight-inquiry', {
            parent: 'account',
            url: '/qidian-freight-inquiry',
            data: {
                authorities: [],
                pageTitle: 'global.menu.entities.opsSelling'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/ops-cost/ops-cost-qidian.html',
                    controller: 'OpsCostQidianController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('opsCost');
                    $translatePartialLoader.addPart('booking');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
