(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CashFlowMatchController', CashFlowMatchController);

    CashFlowMatchController.$inject = ['$scope', '$rootScope', '$translate', '$uibModal', 'JhiLanguageService', 'Principal', 'AccountTitle', 'OpsFiles', 'CashFlowSubject', 'VoucherContent', 'AccountVoucher'];

    function CashFlowMatchController($scope, $rootScope, $translate, $uibModal, JhiLanguageService, Principal, AccountTitle, OpsFiles, CashFlowSubject, VoucherContent, AccountVoucher) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            })
        }

        $scope.isSaving = false;
        $scope.cashFlowSubjects = [];
        var getCashFlowSubjects = function() {
            CashFlowSubject.query(function(result) {
                $scope.cashFlowSubjects = result;
            }, function(error) {
                layer.msg('GET CASH FLOW FAILED!');
            });
        }
        getCashFlowSubjects();

        $scope.voucherContents = [];
        $scope.filterDTO = {};

        $scope.notAllotedOnly = false;
        $scope.cfContentFilter = function(item) {
            if($scope.notAllotedOnly && item.cashFlowId){
                return false;
            }
            return true;
        };

        $scope.getContetns = function() {
            if (!$scope.filterDTO.dateFrom){
                layer.msg($translate.instant('autopsApp.accountTitle.monthNullAlert'));
                return;
            }

            $scope.isSaving = true;
            $scope.filterDTO.companyId = $rootScope.account.companyId;
            var yearFrom = $scope.filterDTO.dateFrom.getFullYear();
            var monthFrom = $scope.filterDTO.dateFrom.getMonth();
            var year = $scope.filterDTO.dateFrom.getFullYear();
            var month = $scope.filterDTO.dateFrom.getMonth();
            if (month == 11) {
                month = month - 12;
                year++;
            }
            $scope.filterDTO.dateFrom = new Date(yearFrom, monthFrom, 1);
            $scope.filterDTO.dateTo = new Date(year, month + 1, 0);
            CashFlowSubject.findAllMatchVou($scope.filterDTO, function(result) {
                $scope.isSaving = false;
                $scope.voucherContents = result;
                if (result.length == 0) {
                    layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                    return;
                }               
                for (var i = 0; i < $scope.voucherContents.length; i++) {
                    $scope.voucherContents[i].choosed = false;
                }                
            }, function(error) {
                $scope.isSaving = false;
                layer.msg('Server Error');
            });
        }
        
        $scope.cfSubjectFilter = function(item) {
            if((item.isDn == true || item.isDn == null) && $scope.choosedVouContent && $scope.choosedVouContent.dn){
                return true;
            }
            if((item.isDn == false || item.isDn == null) && $scope.choosedVouContent && $scope.choosedVouContent.cn){
                return true;
            }
            return false;
        };

        //选中明细科目
        $scope.choosedVouContent = null;
        $scope.choosedCashFlow = null;
        $scope.chooseContent = function(content){
            if (content.choosed){
                content.choosed = false;
                $scope.choosedVouContent = null;
                $scope.choosedCashFlow = null;
                return;
            }
            $scope.choosedVouContent = content; 
            $scope.choosedCashFlow = null;           
            for(var i = 0 ; i < $scope.cashFlowSubjects.length ; i++){
                if(content.cashFlowId && $scope.cashFlowSubjects[i].id == content.cashFlowId){
                    $scope.choosedCashFlow = $scope.cashFlowSubjects[i];
                    $scope.cashFlowSubjects[i].choosed = true;
                }
                else {
                    $scope.cashFlowSubjects[i].choosed = false
                }
            }
            angular.forEach($scope.voucherContents, function(data){
                if(data.id == $scope.choosedVouContent.id){
                    data.choosed = true;
                }
                else {
                    data.choosed = false;
                }
            });
        }
        //选中现金科目
        $scope.chooseCashFlowSuject = function(casheFlow){
            if (!$scope.choosedVouContent){
                layer.msg($translate.instant('autopsApp.accountTitle.chooseContentFirstlyAlert'));
                return;
            }
            if ($scope.choosedCashFlow && casheFlow.id == $scope.choosedCashFlow.id){
                $scope.choosedCashFlow = null;
                casheFlow.choosed = false;
                setCashIdToVoucher();
                return;
            }
            $scope.choosedCashFlow = casheFlow;
            setCashIdToVoucher();
            for(var i =  0 ;  i < $scope.cashFlowSubjects.length; i++){
                if(casheFlow.id == $scope.cashFlowSubjects[i].id){
                    $scope.cashFlowSubjects[i].choosed = true;                      
                }
                else {
                    $scope.cashFlowSubjects[i].choosed = false;  
                }
            }

            $scope.isSaving = true;
            VoucherContent.update($scope.choosedVouContent, function(result) {
                $scope.isSaving = false;
                $scope.choosedVouContent = result;
                layer.msg($translate.instant('global.save_success'));
            }, function(error) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.save_fail'));
            });
        }
        
        //两边选中后,赋值cashFlowId到VoucherContent
        var setCashIdToVoucher = function(){
            if($scope.choosedVouContent){
                if ($scope.choosedCashFlow){
                    if($scope.choosedCashFlow.companyId != $scope.choosedVouContent.companyId){
                        layer.msg($translate.instant('global.refreshAlert'));
                    }
                    else{
                        $scope.choosedVouContent.cashFlowId = $scope.choosedCashFlow.id;
                    }
                }
                else {
                    $scope.choosedVouContent.cashFlowId = 0;
                }
            }
        }        

        $scope.saveResult = function(voucher) {
            $scope.isSaving = true;
            VoucherContent.updateList({}, $scope.voucherContents, function(result) {
                $scope.isSaving = false;
                $scope.voucherContents = result;
                layer.msg($translate.instant('global.save_success'));
            }, function(error) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.save_fail'));
            });
        }

        $scope.dateformat = "yyyy-MM";
        $scope.dateOption = {
            minMode: 'month'
        }
        $scope.datePickerForDateFrom = {};
        $scope.datePickerForDateFrom.status = {
            opened: false
        };
        $scope.datePickerForDateFromOpen = function($event) {
            $scope.datePickerForDateFrom.status.opened = true;
        };

        $scope.getVoucher = function(id) {
            if (null == id) {
                return;
            }
            VoucherContent.getVoucher({
                id: id
            }, function(result) {
                $rootScope.VIEWCONTENTVOUCHER(id, $scope.voucherContents, result);
            });
        };
    }
})();