
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('PredictionDetailController', PredictionDetailController);

    PredictionDetailController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$translate'];
    
    function PredictionDetailController($scope, $rootScope, $uibModalInstance, $translate) {

        $scope.result = {
            warehousingPaper: 360,
            wmsLabel: 690,
            wmsLabelA4: 940
        };

        $rootScope.LOAD_REPORT_TEMPLATES();
        $scope.save = function () {
            $uibModalInstance.close($scope.result);
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();
