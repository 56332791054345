(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ParcelGoodsDetailController', ParcelGoodsDetailController);

    ParcelGoodsDetailController.$inject = ['$scope', '$rootScope', '$uibModalInstance', 'WmsCargo', 'WmsWarehouse', 'entity'];

    function ParcelGoodsDetailController($scope, $rootScope, $uibModalInstance, WmsCargo, WmsWarehouse, entity) {
        var vm = this;
        vm.filter = entity;

        vm.submit = submit;
        vm.getWarehouses = getWarehouses;
        vm.clear = clear;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function submit () {
            WmsCargo.inventoryQuery(vm.filter, function(result){       
                $uibModalInstance.close(result);
            });
        }

        vm.warehouses = [];
        function getWarehouses() {
            if(vm.warehouses && vm.warehouses.length > 0){
                return;
            }
            WmsWarehouse.getAll({
                includeOwn: true,
                includeVirtual: false,
                includeGroup: false
            }, function(data) {
                vm.warehouses = data;
            });
        }
    }
})();
