(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('WriteOff', WriteOff);

    WriteOff.$inject = ['$resource', 'DateUtils'];

    function WriteOff ($resource, DateUtils) {
        var resourceUrl =  'api/write-offs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (!data) {return;}
                    data = angular.fromJson(data);
                    data.writeOffDate = DateUtils.convertLocalDateFromServer(data.writeOffDate);
                    if (data.writeOffRates && data.writeOffRates.length >0) {
                        for(var i=0; i<data.writeOffRates.length; i++){
                            data.writeOffRates[i].etd = DateUtils.convertLocalDateFromServer(data.writeOffRates[i].etd);
                        }   
                    }
                    return data;                    
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.writeOffDate = DateUtils.convertLocalDateToServer(data.writeOffDate);
                    return angular.toJson(data);
                }
            },
            'advanceSearch': {
                method:'PUT',
                url:'api/write-offs/advanceSearch',
                isArray:true,
                transformRequest: function (data) {
                    data.writeOffDateFM = DateUtils.convertLocalDateToServer(data.writeOffDateFM);
                    data.writeOffDateTO = DateUtils.convertLocalDateToServer(data.writeOffDateTO);
                    data.writeOffDate = DateUtils.convertLocalDateToServer(data.writeOffDate);                    
                    return angular.toJson(data);
                },
            },
            'getWriteOffByRef': {
                method:'GET',
                url:'api/write-offs/getWriteOffByRef',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.writeOffDate = DateUtils.convertLocalDateFromServer(data.writeOffDate);
                        if (data.writeOffRates && data.writeOffRates.length >0) {
                            for(var i=0; i<data.writeOffRates.length; i++){
                                data.writeOffRates[i].etd = DateUtils.convertLocalDateFromServer(data.writeOffRates[i].etd);
                            }   
                        }
                    }
                    return data;
                }
            },
            'getWriteOffCode': {
                method: 'GET',
                url:'api/write-offs/getWriteOffCode'
            },
            'setAudited': {
                method: 'GET',
                url:'api/write-offs/setAudited',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.writeOffDate = DateUtils.convertLocalDateFromServer(data.writeOffDate);
                        data.loadTime = DateUtils.convertDateTimeFromServer(data.loadTime);
                        data.auditTime = DateUtils.convertDateTimeFromServer(data.auditTime);
                    }
                    return data;
                }                
            },
            'getByVoucherId': {
                method: 'GET',
                url:'api/write-offs/getByVoucherId'
            },            
            'updateOneWriteOff': {
                method: 'GET',
                url:'api/write-offs/updateOneWriteOff'
            },          
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.writeOffDate = DateUtils.convertLocalDateToServer(data.writeOffDate);
                    return angular.toJson(data);
                }
            },
            'exportWriteOffs': {
                method: 'POST',
                url: 'api/excel/exportWriteOffs',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'getFilterPage': {
                method: 'POST',
                url:'api/write-offs/getFilterPage',
                isArray: true
            }
        });
    }
})();
