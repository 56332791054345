(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsTemplateController', OpsTemplateController);

    OpsTemplateController.$inject = ['$timeout', '$rootScope', '$scope', '$state', '$translate',
    'OpsTemplate', 'Principal', 'OpsTemplateSearch', 'ParseLinks', '$http'];

    function OpsTemplateController($timeout, $rootScope, $scope, $state, $translate,
        OpsTemplate, Principal, OpsTemplateSearch, ParseLinks, $http) {

        $scope.opsTemplates = [];
        $scope.predicate = 'id';
        $scope.reverse = false;
        $scope.page = 1;
        $scope.itemsPerPage = 20;
        Principal.identity().then(function(account) {
            $scope.account = account;
        });

        $scope.loadAll = function() {
            OpsTemplate.query({page: $scope.page-1, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']},function(result,headers) {
            	$scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
            	$scope.opsTemplates = result;
            });
        };
        $scope.loadAll();


        $scope.search = function () {
            OpsTemplateSearch.query({query: $scope.searchQuery}, function(result) {
                $scope.opsTemplates = result;
            }, function(response) {
                if(response.status === 404) {
                    $scope.loadAll();
                }
            });
        };

        $scope.edit = function (id) {
            $rootScope.id = id;
            $state.go('opsTemplate.detail');

        }
        $scope.delete = function (id, num) {
            $rootScope.num = num;
            $rootScope.id = id;
            $state.go('opsTemplate.delete');

        }
        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.opsTemplate = {
                createdBy: null,
                creater: null,
                companyId: null,
                code: null,
                remarks: null,
                createdTime: null,
                id: null
            };
        };
        $scope.excelImport = function(file) {
            if (!file) {return;}
            var fd = new FormData();
            fd.append('file', file);
            $http.post(
                'api/opsTemplates/excelImport', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    layer.msg($translate.instant('global.importSuccess'));
                    $scope.loadAll();
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                });
        };
        $timeout(function (){
            $rootScope.RESIZE();
        });

    }
})();
