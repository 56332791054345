(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GenerateVenderTitleDialogController', GenerateVenderTitleDialogController);

    GenerateVenderTitleDialogController.$inject = ['$scope', '$rootScope', '$translate', '$uibModalInstance', 
    'entity', 'AccountTitle'];

    function GenerateVenderTitleDialogController($scope, $rootScope, $translate, $uibModalInstance, 
        entity, AccountTitle) {

        $scope.accountTitle = entity;
        var onSaveSuccess = function (result) {
            $uibModalInstance.close(result);
            $scope.isSaving = false;
            layer.msg($translate.instant('global.messages.submitted'));
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('global.messages.submittedfailed'));
        };

        $scope.save = function () {
            var isUsd = true;
            if($scope.accountTitle.currency == $rootScope.account.baseCurrency){
                isUsd = false;
            }
            $scope.isSaving = true;
            AccountTitle.generateVenderTitle({
                isUsd: isUsd,
                companyId: $rootScope.account.companyId,
                venderId: $scope.accountTitle.venderId,
                currency: $scope.accountTitle.currency,
            }, onSaveSuccess, onSaveError);
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope._onVenderSelected = function(data) {
            $scope.accountTitle.venderId = data.id;
        }


    }
})();
