(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsConcernLaneDeleteController',OpsConcernLaneDeleteController);

    OpsConcernLaneDeleteController.$inject = ['$rootScope', '$scope', '$uibModalInstance', '$uibModal', '$translate',
    'entity', 'OpsCost', 'OpsTemplate', 'JhiSignature', 'AddressBook', 'DataUtils', 'ExpressParcel', 'OpsCarrier'];

    function OpsConcernLaneDeleteController($rootScope, $scope, $uibModalInstance, $uibModal, $translate,
        entity, OpsCost, OpsTemplate, JhiSignature, AddressBook, DataUtils, ExpressParcel, OpsCarrier) {
        var vm = this;

        vm.clear = clear;

        vm.ratesList = [];
        vm.findRates = findRates;        

        vm.choosedChannel = null;
        vm.chooseService = chooseService;
        vm.updatePkgType = updatePkgType;
        vm.updateGwLbs = updateGwLbs;
        vm.step = 1;
        vm.setStep = setStep;
        vm.sendOrder = sendOrder;
        vm.updateCount = updateCount;
        vm.updateVol = updateVol;
        vm.updateGwTtl = updateGwTtl;
        vm.updateCargoType = updateCargoType;
        $scope.guide = null;
        function updateCargoType(){
            DataUtils.fillFdLabel($scope.guide);
        }
        function initGuide(){
            $scope.guide = {
                shipperCompanyName: $rootScope.account.companyName,
                shipperAddressLineOne: $rootScope.account.companyAddress,
                shipperAttentionName: $rootScope.account.firstName + " " + $rootScope.account.lastName,
                shipperPhoneNumber: $rootScope.account.mob?$rootScope.account.mob:$rootScope.account.tel,
                divVolBy: 6000,
                payParty: "shipfrom",
                chargeType: "prepay",
                shipmentType: entity.shipmentType,
                submited: true,
                status: "Submitted",
                pkgType: "PACKAGES",
                cargoType: "generalCargo",
                expressCargos: [{}],
                createdTime: new Date()
            };
            vm.step = 1;
            updateCargoType();
        }
        initGuide();



        function onCheckError(error){
            if(error && error.data && error.data.description){
                $rootScope.OPS_ALERT(error.data.description);
            }
            else {
                layer.msg($translate.instant('global.requestFail'));
            }
        }
        function setStep(num){
            if(vm.step + num == 2 && !vm.choosedChannel){
                layer.confirm($translate.instant('global.skipStepAlert'), {
                    icon: 3,
                    title: "Msg",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    layer.close(index);
                    vm.step += num;  
                    $translate.refresh();
                }, function() {

                });                   
            }
            else {
                vm.step += num;  
            }                    
        }
        function chooseService (channel) {
            if(vm.choosedChannel && vm.choosedChannel.channelName == channel.channelName){
                vm.choosedChannel = null;
            }
            else {
                vm.choosedChannel = channel;
                vm.step = 2;
                $scope.guide.channel = channel.channelName;
            }
        }

        function findRates () {
            vm.isSaving = true;
            if(!$scope.guide.expressCargos || $scope.guide.expressCargos.length == 0){
                $scope.guide.expressCargos = [{}];
            }            
            $scope.guide.expressCargos[0].pkgs = 1;
            $scope.guide.expressCargos[0].gwTtl = $scope.guide.gw;
            $scope.guide.expressCargos[0].volTtl = $scope.guide.vol;
            $scope.guide.expressCargos[0].gw = $scope.guide.gw;
            $scope.guide.expressCargos[0].vol = $scope.guide.vol;
            
            ExpressParcel.trialCost($scope.guide, function(resultList){
                vm.isSaving = false;
                vm.ratesList = resultList;
                if (!vm.ratesList || vm.ratesList.length == 0){
                    layer.msg($translate.instant('global.noRecord'));
                }
            }, function(data){
                vm.isSaving = false;
                $rootScope.ALERT_ERROR(data);
            })     
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        var isReverse = true;
        $scope.sortRates = function(fd) {
            isReverse = !isReverse;
            vm.ratesList.sort(function(a,b){
                if (isReverse){
                    return a[fd]<b[fd]?1:-1;
                }
                else {
                    return a[fd]>b[fd]?1:-1;
                }                   
            });
        }
        $scope.saveAddress = function(type) {
            var addressBook = {};
            if(type == "shipfrom"){
                if(!$scope.guide.shipfromCode 
                    || !$scope.guide.shipperCompanyName 
                    || !$scope.guide.shipperAddressLineOne 
                    || !$scope.guide.shipperAttentionName
                    || !$scope.guide.shipperPhoneNumber){
                    layer.msg($translate.instant('autopsApp.expressParcel.saveAddressAlert'));
                    return;
                }
                addressBook.code = $scope.guide.shipfromCode;
                addressBook.companyName = $scope.guide.shipperCompanyName;
                addressBook.addressOne = $scope.guide.shipperAddressLineOne;
                addressBook.addressTwo = $scope.guide.shipperAddressLineTwo;
                addressBook.addressThree = $scope.guide.shipperAddressLineThree;
                addressBook.attn = $scope.guide.shipperAttentionName;
                addressBook.postCode = $scope.guide.shipperPostalCode;
                addressBook.countryCode = $scope.guide.shipperCountryCode;
                addressBook.province = $scope.guide.shipperProvinceCode;
                addressBook.city = $scope.guide.shipperCity;
                addressBook.tel = $scope.guide.shipperPhoneNumber;
                addressBook.email = $scope.guide.shipperEmailAddress;
                addressBook.taxId = $scope.guide.shipperTaxId;
            }
            else {
                if(!$scope.guide.shiptoCode 
                    || !$scope.guide.shiptoCompanyName 
                    || !$scope.guide.shiptoAddressLineOne 
                    || !$scope.guide.shiptoAttentionName
                    || !$scope.guide.shiptoPhoneNumber){
                    layer.msg($translate.instant('autopsApp.expressParcel.saveAddressAlert'));
                    return;
                }
                addressBook.code = $scope.guide.shiptoCode;
                addressBook.companyName = $scope.guide.shiptoCompanyName;
                addressBook.addressOne = $scope.guide.shiptoAddressLineOne;
                addressBook.addressTwo = $scope.guide.shiptoAddressLineTwo;
                addressBook.addressThree = $scope.guide.shiptoAddressLineThree;
                addressBook.attn = $scope.guide.shiptoAttentionName;
                addressBook.postCode = $scope.guide.shiptoPostalCode;
                addressBook.countryCode = $scope.guide.shiptoCountryCode;
                addressBook.province = $scope.guide.shiptoProvinceCode;
                addressBook.city = $scope.guide.shiptoCity;
                addressBook.tel = $scope.guide.shiptoPhoneNumber;
            }
            AddressBook.save(addressBook, function(result){
                if(type == "shipfrom"){
                    $scope.guide.shipFromId = result.id;
                }
                else {
                    $scope.guide.shipToId = result.id;
                }
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(er){
                layer.msg($translate.instant('global.messages.submittedfailed'));
            });
        }      

        var showAllShipFrom = false;
        $scope.shipFromFilterChange = function(value) {
            showAllShipFrom = value;
        }
        $scope.shipFromFilter = function(item) {            
            if(item.createrId != $rootScope.account.id && !showAllShipFrom){
                return false;
            }
            return true;
        };
        $scope._onAddressSelected = function(data, type) {
            if(type == "thirdparty"){
                $scope.guide.accountId = data.id;
            }
            else if(type == "shipfrom"){
                $scope.guide.shipFromId = data.id;
                $scope.guide.shipperCompanyName = data.companyName;

                if(data.addressOne){
                    $scope.guide.shipperAddressLineOne = data.addressOne.substring(0, 35);
                    var len = data.addressOne.length;
                    if(len > 70){
                        $scope.guide.shipperAddressLineThree = data.addressOne.substring(70, 105);
                    }
                    if(len > 35){
                        $scope.guide.shipperAddressLineTwo = data.addressOne.substring(35, 70);
                    }
                }
                if(data.addressTwo){
                    $scope.guide.shipperAddressLineTwo = data.addressTwo.substring(0, 35);
                }
                if (data.addressThree){
                    $scope.guide.shipperAddressLineThree = data.addressThree.substring(0, 35);
                }

                $scope.guide.shipperAttentionName = data.attn;
                $scope.guide.shipperPostalCode = data.postCode;
                $scope.guide.shipperCountryCode = data.countryCode;
                $scope.guide.shipperProvinceCode = data.province;
                $scope.guide.shipperCity = data.city;
                $scope.guide.shipperPhoneNumber = data.tel;
                $scope.guide.shipperEmailAddress = data.email;
                $scope.guide.shipperTaxId = data.taxId;
                $scope.guide.labelBindType = data.bindType;
            }
            else if (type == 'shipto'){
                $scope.guide.shipToId = data.id;
                $scope.guide.shiptoCompanyName = data.companyName;

                if(data.addressOne){
                    $scope.guide.shiptoAddressLineOne = data.addressOne.substring(0, 35);
                    var len = data.addressOne.length;
                    if(len > 70){
                        $scope.guide.shiptoAddressLineThree = data.addressOne.substring(70, 105);
                    }
                    if(len > 35){
                        $scope.guide.shiptoAddressLineTwo = data.addressOne.substring(35, 70);
                    }
                }  
                if(data.addressTwo){
                    $scope.guide.shiptoAddressLineTwo = data.addressTwo.substring(0, 35);
                }
                if (data.addressThree){
                    $scope.guide.shiptoAddressLineThree = data.addressThree.substring(0, 35);
                }
                $scope.guide.shiptoAttentionName = data.attn;
                $scope.guide.shiptoPostalCode = data.postCode;
                $scope.guide.shiptoCountryCode = data.countryCode;
                $scope.guide.shiptoProvinceCode = data.province;
                $scope.guide.shiptoCity = data.city;
                $scope.guide.shiptoPhoneNumber = data.tel;
                $scope.guide.shiptoEmailAddress = data.email;
            }
            else {                
                if(type == 'ownImporterId' && data.countryCode != $scope.guide.shiptoCountryCode){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.expressParcel.importerCountryDiffAlert'));
                    return; 
                }
                $scope.guide[type] = data.id;
            }
        }
        $scope.datePickerOpenStatus = {};
        $scope.openCalendar  = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }
        function updateGwTtl (cargo) {
            if(cargo.gw && cargo.pkgs){
                if($scope.guide.inchOz){
                    cargo.lbs = cargo.gw * cargo.pkgs
                    cargo.gwTtl = cargo.lbs / 2.20462262;
                }
                else {
                    cargo.gwTtl = cargo.gw * cargo.pkgs
                    cargo.lbs = cargo.gwTtl * 2.20462262;
                }
            }
            if(!cargo.gwTtl){
                cargo.gwTtl = 0;
            }
            if(!cargo.lbs){
                cargo.lbs = 0;
            }
            
            cargo.gwTtl = cargo.gwTtl.toFixed(2) -0;
            cargo.lbs = cargo.lbs.toFixed(2) -0;
            updateTtl();
        }
        function updateGwLbs () {
            $scope.guide.lbs = $scope.guide.gw * 2.20462262;
            $scope.guide.lbs = $scope.guide.lbs.toFixed(2) - 0;
            $scope.updateGwVol();
        }
        $scope.updateGwVol = function() {
            if($rootScope.ISTMS){return;}
            var vol = $scope.guide.vol;
            if(null == vol){vol = 0;}

            var gw = $scope.guide.gw;
            if(null == gw){gw = 0;}

            if ($scope.guide.divVolBy) {
                $scope.guide.vw = vol * 1000000 / $scope.guide.divVolBy;
            }
            if ($scope.guide.vw < gw) {
                $scope.guide.vw = gw;
            }
            $scope.guide.vw = DataUtils.round($scope.guide.vw, 2);
        };
        $scope.dutyPayPartyChange = function(payParty) {
            if(payParty != "thirdparty"){
                $scope.guide.dutyPayPartyCode = null;
                $scope.guide.dutyPayPartyId = null;
            }
        }

        function sendOrder () {
            if($rootScope.HAVENOAUTH('PARCEL') && $rootScope.HAVENOAUTH('TMS')){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            if($scope.guide.shipperCountryCode && $scope.guide.shipperCountryCode.length > 25){
                $scope.guide.shipperCountryCode = $scope.guide.shipperCountryCode.substring(0,25);
            }
            if($scope.guide.shiptoCountryCode && $scope.guide.shiptoCountryCode.length > 25){
                $scope.guide.shiptoCountryCode = $scope.guide.shiptoCountryCode.substring(0,25);
            }
            if($scope.guide.shipperPostalCode && $scope.guide.shipperPostalCode.length > 10){
                $scope.guide.shipperPostalCode = $scope.guide.shipperPostalCode.substring(0,10);
            }
            if($scope.guide.shipperProvinceCode && $scope.guide.shipperProvinceCode.length > 25){
                $scope.guide.shipperProvinceCode = $scope.guide.shipperProvinceCode.substring(0,25);
            }
            if($scope.guide.shiptoPostalCode && $scope.guide.shiptoPostalCode.length > 10){
                $scope.guide.shiptoPostalCode = $scope.guide.shiptoPostalCode.substring(0,10);
            }
            if($scope.guide.shiptoProvinceCode && $scope.guide.shiptoProvinceCode.length > 25){
                $scope.guide.shiptoProvinceCode = $scope.guide.shiptoProvinceCode.substring(0,25);
            }            
            if($scope.guide.shipperAddressLineOne && $scope.guide.shipperAddressLineOne.length > 35 ){
                var addressOne = $scope.guide.shipperAddressLineOne;
                $scope.guide.shipperAddressLineOne = addressOne.substring(0, 35);
                var len = addressOne.length;
                if(len > 70){
                    $scope.guide.shipperAddressLineThree = addressOne.substring(70, 105);
                }
                if(len > 35){
                    $scope.guide.shipperAddressLineTwo = addressOne.substring(35, 70);
                }
            }
            if($scope.guide.shiptoAddressLineOne && $scope.guide.shiptoAddressLineOne.length > 35 ){
                var addressOne = $scope.guide.shiptoAddressLineOne;
                $scope.guide.shiptoAddressLineOne = addressOne.substring(0, 35);
                var len = addressOne.length;
                if(len > 70){
                    $scope.guide.shiptoAddressLineThree = addressOne.substring(70, 105);
                }
                if(len > 35){
                    $scope.guide.shiptoAddressLineTwo = addressOne.substring(35, 70);
                }
            }
            $scope.isSaving = true;
            ExpressParcel.save($scope.guide, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {         
            $scope.isSaving = false;
            layer.msg($translate.instant('global.messages.submitted'));
            initGuide();
            $scope.$emit('autopsApp:expressParcelUpdate', $scope.guide);
        }

        function onSaveError (result) {
            $scope.isSaving = false;
            $rootScope.ALERT_ERROR(result);
        }
        $scope.pickUpTypeChange = function(type) {
            if(type == "pickup"){
                $scope.guide.pickupAddress = "";
                if($scope.guide.shipperAttentionName){
                    $scope.guide.pickupAddress += $scope.guide.shipperAttentionName + " ";
                }
                if($scope.guide.shipperPhoneNumber){
                    $scope.guide.pickupAddress += $scope.guide.shipperPhoneNumber + "\n";
                }
                if($scope.guide.shipperAddressLineOne){
                    $scope.guide.pickupAddress += $scope.guide.shipperAddressLineOne + " ";
                }
                if($scope.guide.shipperCity){
                    $scope.guide.pickupAddress += $scope.guide.shipperCity + " ";
                }
                if($scope.guide.shipperProvinceCode){
                    $scope.guide.pickupAddress += $scope.guide.shipperProvinceCode + " ";
                }
                if($scope.guide.shipperCountryCode){
                    $scope.guide.pickupAddress += $scope.guide.shipperCountryCode + " ";
                }
                if($scope.guide.shipperPostalCode){
                    $scope.guide.pickupAddress += $scope.guide.shipperPostalCode + " ";
                }
            }
            else {
                $scope.guide.pickupAddress = "";
            }
        }

        $scope.onCarrierSelected = function(carrier) {
            $scope.guide.pickupCarrierCode = carrier.code;
        }

        $scope.addCargo = function  () {
            if (!$scope.guide.expressCargos){
                $scope.guide.expressCargos = [];
            }
            var newCargo = {};
            if($rootScope.ISTMS){
                newCargo.length = 40;
                newCargo.width = 48;
                newCargo.height = 74;
            }
            $scope.guide.expressCargos.push(newCargo);
        }
        function updateCount () {
            $scope.guide.gw = 0;
            $scope.guide.vol = 0;
            $scope.guide.pkgNum = 0;
            $scope.guide.lbs = 0;
            $scope.guide.pallets = 0;
            if (!$scope.guide.expressCargos || $scope.guide.expressCargos.length == 0){
                return;
            }
            angular.forEach($scope.guide.expressCargos, function(data){
                var pallets = data.pallets;
                var pkgs = data.pkgs;
                if(!pallets){pallets = 0;}
                if(!pkgs){pkgs = 0;}                

                if (data.vol){
                    data.volTtl = data.vol * pkgs;
                }
                if(!data.volTtl){
                    data.volTtl = 0;
                }
                data.volTtl = DataUtils.round(data.volTtl, 3);

                if(data.gw){
                    if($scope.guide.inchOz){
                        data.lbs = data.gw * pkgs
                    }
                    else {
                        data.gwTtl = data.gw * pkgs;
                    }                    
                }
                if(!data.gwTtl){
                    data.gwTtl = 0;
                }
                if(!data.lbs){
                    data.lbs = 0;
                }
                
                if(!$rootScope.ISTMS){
                    if($scope.guide.inchOz){
                        data.gwTtl = DataUtils.round(data.lbs/2.20462262, 2); 
                    }
                    else {
                        data.lbs = DataUtils.round(data.gwTtl * 2.20462262, 2); 
                    }                    
                }
                data.gwTtl = DataUtils.round(data.gwTtl, 2); 
                data.lbs = DataUtils.round(data.lbs, 2); 
                
                $scope.guide.gw += data.gwTtl;
                $scope.guide.vol += data.volTtl;
                $scope.guide.lbs += data.lbs;
                $scope.guide.pkgNum += pkgs;
                $scope.guide.pallets += pallets;                
                if($rootScope.ISTMS && data.lbs && data.pallets){
                    var checkAmt = data.lbs/data.pallets;
                    if(checkAmt > 1000){
                        data.lbs = 1000 * data.pallets;
                        layer.msg("MAX 1000/PALLET")
                    }
                }   
            })

            $scope.guide.gw = $scope.guide.gw.toFixed(2) -0;
            $scope.guide.vol = $scope.guide.vol.toFixed(3) -0;
            $scope.guide.lbs = $scope.guide.lbs.toFixed(2) -0;
            $scope.guide.pkgNum = $scope.guide.pkgNum.toFixed(0) -0;
            $scope.guide.pallets = $scope.guide.pallets.toFixed(2) -0;
            if($rootScope.ISTMS){
                updateGwKgs();
            }
            $scope.updateGwVol();
        }
        function updateVol (cargo) {
            if($rootScope.ISTMS || !cargo){return;}
            var l,w,h;
            l = cargo.length;
            w = cargo.width;
            h = cargo.height;
            if(l && w && h){
                if($scope.guide.inchOz){
                    l = l * 2.54;
                    w = w * 2.54;
                    h = h * 2.54;
                }
                cargo.vol = DataUtils.round(l/100 * w/100 * h/100, 6);
            }
            if (cargo.vol && cargo.pkgs){
                cargo.volTtl = DataUtils.round(cargo.vol * cargo.pkgs, 3);
            }
            updateTtl();
        }
        function updateTtl () {
            if(!$scope.guide.sellCur){
                $scope.guide.sellCur = $rootScope.account.baseCurrency;
            }    
            if(!$scope.guide.collectCur){
                $scope.guide.collectCur = $rootScope.account.baseCurrency;
            }         
            $scope.guide.gw = 0;
            $scope.guide.vol = 0;
            $scope.guide.pkgNum = 0;
            $scope.guide.lbs = 0;
            $scope.guide.pallets = 0;

            if (!$scope.guide.expressCargos || $scope.guide.expressCargos.length == 0){
                return;
            }
            angular.forEach($scope.guide.expressCargos, function(data){
                var pallets = data.pallets;
                var pkgs = data.pkgs;
                if(!pallets){pallets = 0;}
                if(!pkgs){pkgs = 0;}
                if(!data.volTtl){
                    data.volTtl = 0;
                }

                data.volTtl = data.volTtl.toFixed(3) -0;
                if(!data.gwTtl){
                    data.gwTtl = 0;
                }       
                if(!data.lbs){
                    data.lbs = 0;
                }           
                if(!$rootScope.ISTMS){
                    if($scope.guide.inchOz){
                        data.gwTtl = data.lbs / 2.20462262;    
                    }
                    else {
                        data.lbs = data.gwTtl * 2.20462262;    
                    }
                }
                data.lbs = data.lbs.toFixed(2) -0;
                data.gwTtl = data.gwTtl.toFixed(2) -0;

                if(pkgs){
                    if($scope.guide.inchOz){
                        data.gw = data.lbs/ pkgs;
                    }
                    else{
                        data.gw = data.gwTtl/ pkgs;
                    }                    
                    data.vol = data.volTtl/ pkgs;
                    data.gw = data.gw.toFixed(2) -0;
                    data.vol = data.vol.toFixed(3) -0;
                }
                $scope.guide.gw += data.gwTtl;
                $scope.guide.vol += data.volTtl;
                $scope.guide.lbs += data.lbs;
                $scope.guide.pkgNum += pkgs;
                $scope.guide.pallets += pallets;                
                if($rootScope.ISTMS && data.lbs && data.pallets){
                    var checkAmt = data.lbs/data.pallets;
                    if(checkAmt > 1000){
                        data.lbs = 1000 * data.pallets;
                        layer.msg("MAX 1000/PALLET")
                    }
                }            
            })

            $scope.guide.gw = $scope.guide.gw.toFixed(2) -0;
            $scope.guide.vol = $scope.guide.vol.toFixed(3) -0;
            $scope.guide.lbs = $scope.guide.lbs.toFixed(2) -0;
            $scope.guide.pkgNum = $scope.guide.pkgNum.toFixed(0) -0;
            $scope.guide.pallets = $scope.guide.pallets.toFixed(2) -0;
            if($rootScope.ISTMS){
                updateGwKgs();
            }
            $scope.updateGwVol();
        }
        function updateGwKgs () {
            $scope.guide.gw = $scope.guide.lbs / 2.20462262;
            $scope.guide.gw = $scope.guide.gw.toFixed(2) - 0;
            $scope.updateGwVol();
        }


        $scope.comCargoName = function(cargoName) {
            if(!$scope.guide.goodsDiscription){
                $scope.guide.goodsDiscription = cargoName;
            }
            else if ($scope.guide.goodsDiscription.indexOf(cargoName) == -1) {
                $scope.guide.goodsDiscription = $scope.guide.goodsDiscription + " " + cargoName;
            }
        };

        $scope.addressBooks = [];
        $scope.loadAddressBooks = function() {
            if ($scope.addressBooks.length == 0 && !$scope.isSaving) {
                $scope.isSaving = true;
                AddressBook.getAll(function(result) {
                    $scope.addressBooks = result;
                    $scope.isSaving = false;
                });
            };
        };
        
        $scope.sizeChange = function() {
            if(!$scope.guide.expressCargos[0].length || !$scope.guide.expressCargos[0].width || !$scope.guide.expressCargos[0].height){
                return;
            }
            $scope.guide.vol = $scope.guide.expressCargos[0].length * $scope.guide.expressCargos[0].width * $scope.guide.expressCargos[0].height;
            $scope.guide.vol = DataUtils.round($scope.guide.vol/1000000, 4);
        }
        function updatePkgType (packageType) {
            $scope.guide.pkgType = packageType;
            angular.forEach($scope.guide.expressCargos, function(cargo){
                if(cargo.packageType && cargo.packageType != packageType){
                    $scope.guide.pkgType = "PACKAGES";
                }
            })
        }

    }
})();
