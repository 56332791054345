(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('AccountGeneralSearch', AccountGeneralSearch);

    AccountGeneralSearch.$inject = ['$resource'];
    
    function AccountGeneralSearch($resource) {
        return $resource('api/_search/accountGenerals/:query', {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
