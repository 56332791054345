(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('ops-log', {
            parent: 'entity',
            url: '/ops-log',
            data: {
                authorities: ['AU_OPS_LOG'],
                pageTitle: 'autopsApp.opsLog.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/ops-log/ops-logs.html',
                    controller: 'OpsLogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('opsLog');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('ops-log-private', {
            parent: 'entity',
            url: '/ops-log-private',
            data: {
                authorities: ['AU_OPS_LOG'],                
                pageTitle: 'autopsApp.opsLog.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/ops-log/ops-logs-private.html',
                    controller: 'OpsLogPrivateController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('opsLog');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('ops-log-detail', {
            parent: 'entity',
            url: '/ops-log/{id}',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'autopsApp.opsLog.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/ops-log/ops-log-detail.html',
                    controller: 'OpsLogDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('opsLog');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'OpsLog', function($stateParams, OpsLog) {
                    return OpsLog.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'ops-log',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('ops-log.delete', {
            parent: 'ops-log',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ops-log/ops-log-delete-dialog.html',
                    controller: 'OpsLogDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['OpsLog', function(OpsLog) {
                            return OpsLog.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('ops-log', null, { reload: 'ops-log' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
