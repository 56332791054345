(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BookingTemplateController', BookingTemplateController);

    BookingTemplateController.$inject = ['$scope', '$translate', '$rootScope', '$state', 'BookingTemplate', 'BookingTemplateSearch'];
    
    function BookingTemplateController($scope, $translate, $rootScope, $state, BookingTemplate, BookingTemplateSearch) {

        $scope.bookingTemplates = [];
        $scope.loadAll = function() {
            BookingTemplate.query(function(result) {
               $scope.bookingTemplates = result;
            });
        };
        $scope.loadAll();


        $scope.search = function () {
            BookingTemplateSearch.query({query: $scope.searchQuery}, function(result) {
                $scope.bookingTemplates = result;
            }, function(response) {
                if(response.status === 404) {
                    $scope.loadAll();
                }
            });
        };


        $scope.viewDetail = function (id) {
            $rootScope.id = id;
            $state.go('bookingTemplate.detail');

        }

        $scope.create = function () {
            layer.msg($translate.instant('autopsApp.bookingTemplate.createTips'));
        }
        
        $scope.edit = function (id) {
            $rootScope.id = id;
            $state.go('bookingTemplate.edit');

        }
        $scope.delete = function (id, num) {
            $rootScope.num = num;
            $rootScope.id = id;
            $state.go('bookingTemplate.delete');

        }

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.bookingTemplate = {
                createdBy: null,
                code: null,
                remarks: null,
                bookingId: null,
                id: null
            };
        };
    }
})();
