(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('VoucherContent', VoucherContent);

    VoucherContent.$inject = ['$resource','DateUtils'];
    
    function VoucherContent($resource, DateUtils) {
        return $resource('api/voucherContents/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getVoucher': {
                method: 'GET',
                url:'api/getVouchersBYContent/:id',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.makeDate = DateUtils.convertLocalDateFromServer(data.makeDate);
                    return data;
                }
            },            
            'getAdvance': {
                method: 'GET',
                url:'api/voucherContents/advance/:companyId',
                isArray:true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    for(var i=0; i<data.length; i++){
                        data[i].makeDate = DateUtils.convertLocalDateFromServer(data[i].makeDate);
                    }          
                    return data;
                }
            },
            'getAllByFiler': {
                method: 'PUT',
                url:'api/voucherContentsByFilter',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    return angular.toJson(data);
                }
            },
            'getSubsidiaryAccountDetails': {
                method: 'PUT',
                url:'api/voucherContents/getSubsidiaryAccountDetails',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    return angular.toJson(data);
                }
            },
            'getAccountBalance': {
                method: 'PUT',
                url:'api/getAccountBalance',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    return angular.toJson(data);
                }
            },
            'getAssistAccountBalance': {
                method: 'PUT',
                url:'api/voucherContents/getAssistAccountBalance',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    return angular.toJson(data);
                }
            },
            'getTrialBalance': {
                method: 'PUT',
                url:'api/trialBalance',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    return angular.toJson(data);
                }
            },
            'getManagementFee': {
                method: 'PUT',
                url:'api/managementFees',
                isArray:false,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    return angular.toJson(data);
                }
            },
            'updateList': {
                method: 'POST',
                url:'api/updateCashFlow/voucherContents',
                isArray:true
            },
            'setIsWritedOff': {
                method: 'PUT',
                url:'api/voucherContents/setIsWritedOff'
            },
            'getPeriodAccountCodes': {
                method: 'PUT',
                url:'api/voucherContents/getPeriodAccountCodes',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.makeDateFM = DateUtils.convertLocalDateToServer(data.makeDateFM);
                    data.makeDateTO = DateUtils.convertLocalDateToServer(data.makeDateTO);
                    return angular.toJson(data);
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
