(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BatchInvoiceReviseController', BatchInvoiceReviseController);
    
    BatchInvoiceReviseController.$inject = ['$rootScope', '$scope', '$state', '$uibModal', '$translate', '$window', 'Principal', 'AccountTitle', 'JhiLanguageService','GuideRates','AccountInvoice', 'AccountPayment'];
    
    function BatchInvoiceReviseController($rootScope, $scope, $state, $uibModal, $translate, $window, Principal, AccountTitle, JhiLanguageService, GuideRates, AccountInvoice, AccountPayment) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        }
   
        $scope.invoiceBatchDTO = {};
        $scope.accountInvoices = [];
        $scope.guideRates = [];

        $scope.paymentTypeChange = function(isPay) {
            $scope.invoiceBatchDTO.isPay = isPay;
            initAllList();
        }

        $scope.registerTypeChange = function(byDn) {
            $scope.invoiceBatchDTO.byDn = byDn;
            initAllList();
        }
        var initAllList = function() {
            $scope.accountInvoices = [];
            $scope.guideRates = [];
        }

        $scope.getInvoicesList = function (item) {
            if (!$scope.invoiceBatchDTO.invoiceNum){
                layer.msg($translate.instant('autopsApp.accountInvoice.invoiceNumNull'));
                return;                
            }
            if ($scope.invoiceBatchDTO.isPay == null){
                layer.msg($translate.instant('autopsApp.accountInvoice.isPayNull'));
                return;                
            }
            if ($scope.invoiceBatchDTO.byDn == null){
                layer.msg($translate.instant('autopsApp.accountInvoice.byDnNull'));
                return;
            }
            if ($scope.invoiceBatchDTO.byDn){
                if ($scope.invoiceBatchDTO.isPay){
                    AccountPayment.getAllByInvoice({
                        companyId: $rootScope.account.companyId,
                        invoiceNum: $scope.invoiceBatchDTO.invoiceNum
                    }, function(result) {                
                        if (result.length == 0){
                            layer.msg($translate.instant('global.messages.resultNull'));
                            return;
                        }
                        $scope.accountInvoices = result;
                        updateTtl();
                    });
                }
                else {
                    AccountInvoice.getAllByInvoice({
                        companyId: $rootScope.account.companyId,
                        invoiceNum: $scope.invoiceBatchDTO.invoiceNum
                    }, function(result) {                
                        if (result.length == 0){
                            layer.msg($translate.instant('global.messages.resultNull'));
                            return;
                        }
                        $scope.accountInvoices = result;
                        updateTtl();
                    });
                }
            }
            else {
                GuideRates.getAllByInvoice({
                    companyId: $rootScope.account.companyId,
                    invoiceNum: $scope.invoiceBatchDTO.invoiceNum
                }, function(result) {                
                    if (result.length == 0){
                        layer.msg($translate.instant('global.messages.resultNull'));
                        return;
                    }
                    $scope.guideRates = result;
                    updateTtl();
                });
            }
        }
        $scope.ttlStr = "";
        var updateTtl = function () {
            var ttlAmt = 0;
            var currency = "";
            if ($scope.invoiceBatchDTO.byDn && $scope.accountInvoices.length > 0){
                currency = $scope.accountInvoices[0].currency;
                angular.forEach($scope.accountInvoices, function(data){
                    if ($scope.invoiceBatchDTO.isPay){
                        if(data.dnNum.indexOf("DN") >= 0){
                            ttlAmt -= data.invoiceAmount;
                        }
                        else {
                            ttlAmt += data.invoiceAmount;
                        }
                    }
                    else {
                        if(data.dnNum.indexOf("DN") < 0){
                            ttlAmt -= data.invoiceAmount;
                        }
                        else {
                            ttlAmt += data.invoiceAmount;
                        }
                    }                    
                })
            }
            else if ($scope.guideRates.length > 0){
                currency = $scope.guideRates[0].payCurrency;
                angular.forEach($scope.guideRates, function(data){
                    if ($scope.invoiceBatchDTO.isPay){
                        if(data.isPay){
                            ttlAmt += data.payAmount;
                        }
                        else {
                            ttlAmt -= data.payAmount;
                        }                        
                    }
                    else {
                        if(!data.isPay){
                            ttlAmt += data.payAmount;
                        }
                        else {
                            ttlAmt -= data.payAmount;
                        }  
                    }
                    
                })
            }
            $scope.ttlStr = currency + ttlAmt.toFixed(2);
        }
        $scope.submitInvoiceNum = function () {
            $scope.isSaving = true;
            if ($scope.invoiceBatchDTO.byDn){
                if ($scope.accountInvoices.length == 0) {
                    layer.msg($translate.instant('autopsApp.accountInvoice.home.noChoosed'));
                    $scope.isSaving = false;
                    return;
                }
                if ($scope.invoiceBatchDTO.isPay){
                    AccountPayment.updateInvoiceNum($scope.accountInvoices, onSaveSuccess, onSaveError);
                }
                else {
                    AccountInvoice.updateInvoiceNum($scope.accountInvoices, onSaveSuccess, onSaveError);
                }
            }
            else {
                if ($scope.guideRates.length == 0) {
                    layer.msg($translate.instant('autopsApp.accountInvoice.home.noChoosed'));
                    $scope.isSaving = false;
                    return;
                }
                GuideRates.udpateWithInvoiceNum($scope.guideRates, onSaveSuccess, onSaveError);
            }
        }

        var onSaveSuccess = function(result) {
            $scope.isSaving = false;
            initAllList();       
            layer.msg($translate.instant('global.messages.submitted'));
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('global.save_fail'));            
        };

        $scope.autoInputDn = function (dn) {
            angular.forEach($scope.accountInvoices, function(data){
                data.invoiceNum = dn.invoiceNum;
            })        
        };
        $scope.autoInputRate = function (rate) {
            angular.forEach($scope.guideRates, function(data){
                data.invoiceNum = rate.invoiceNum;
            })        
        };
    }
})();