(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsBillDeleteController',OpsBillDeleteController);

    OpsBillDeleteController.$inject = ['$uibModalInstance', '$scope', '$rootScope', '$timeout', 'OpsFiles', 'entity'];

    function OpsBillDeleteController($uibModalInstance, $scope, $rootScope, $timeout, OpsFiles, entity) {
        var vm = this;

        vm.notice = entity;
        vm.clear = clear;
        $timeout(function() {
            var contentDiv = document.getElementById("contentDiv");
            var text = document.createElement("span");
            text.innerHTML = vm.notice.content;
            text.style.padding="5px";
            contentDiv.appendChild(text);            
        })        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.files = new Array();   
        function loadFiles (){
            $scope.files = [];
            if (vm.notice.id) {
                var filePath = "/" + "opsNews/" + vm.notice.id + "/";
                OpsFiles.getFilesList({
                    filepath: filePath,
                    companyId: vm.notice.companyId
                },function (data){
                    $scope.files = new Array();
                    if(data.length>0){
                        for(var i = 0 ; i < data.length; i++){
                            var temp  = new Object();
                            temp['filepath'] = data[i]['filepath'];
                            temp['filename'] = data[i]['filename'];
                            temp['fileurl'] = data[i]['fileurl'];
                            temp['fileist'] = true;
                            $scope.files.push(temp);
                        }
                    }
                });
            }
        }
        loadFiles();

    }
})();
