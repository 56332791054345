(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('ApiErp', ApiErp);

    ApiErp.$inject = ['$resource','DateUtils'];

    function ApiErp($resource, DateUtils) {
        return $resource('', {}, {
            'sendConsolList': {
                method:'GET',
                url:'api/apiErp/sendConsolList'
            },
            'sendFile': {
                method:'GET',
                url:'api/apiErp/sendFile'
            },            
            'sendWmsIn': {
                method:'GET',
                url:'api/apiErp/sendWmsIn'
            },
            'sendStatus': {
                method:'POST',
                url:'api/apiErp/sendStatus'
            },      
            'sendShipment': {
                method:'POST',
                url:'api/apiErp/sendShipment'
            },                    
            'sendCharges': {
                method:'POST',
                url:'api/apiErp/sendCharges'
            },       
            'exportCsvBi': {
                method:'POST',
                url:'api/apiErp/exportCsvBi',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },             
            'sendReceiveBill': {
                method: 'POST',
                url:'api/kingDee/sendReceiveBill'
            },
            'getReceiveBillBody': {
                method: 'POST',
                url:'api/kingDee/getReceiveBillBody'
            },
            'getPayBillBody': {
                method: 'POST',
                url:'api/kingDee/getPayBillBody'
            },
            'sendPayBill': {
                method: 'POST',
                url:'api/kingDee/sendPayBill'
            },
            'importCustomer': {
                method: 'POST',
                url:'api/kingDee/importCustomer'
            },
            'importVoucher': {
                method: 'POST',
                url:'api/kingDee/importVoucher'
            },
            'updateCharges': {
                method: 'POST',
                url:'api/apiErp/updateCharges'
            },
            'sendAmassSHErp': {
                method: 'POST',
                url:'api/apiErp/sendAmassSHErp'
            },
            'getStorageCosts': {
                method: 'POST',
                url:'api/apiErp/getStorageCosts'
            }
        });
    }
})();
