(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsInChargesController', WmsInChargesController);

    WmsInChargesController.$inject = ['$scope', '$timeout', '$state', '$rootScope', '$translate', '$uibModalInstance', 
    'entity','WmsIn', 'WmsWarehouse', 'OpsVender', 'DataUtils'];

    function WmsInChargesController($scope, $timeout, $state, $rootScope, $translate, $uibModalInstance, 
        entity, WmsIn, WmsWarehouse, OpsVender, DataUtils) {
        var vm = this;
        vm.guide = entity;
        vm.clear = clear;
        
        vm.etdPeriodDTO = {};
        vm.wmsChargesDTO = {};
        
        vm.getAllWarehouses = getAllWarehouses;
        vm.warehouseSelected = warehouseSelected;

        vm.delRate = delRate;
        vm.getShipmentWmsCharges = getShipmentWmsCharges;
        vm.getAllWmsCharges = getAllWmsCharges;
        vm.saveWmsCharges = saveWmsCharges;

        var typeTemp = entity.typeTemp;
        var wmsCargoIds = [];
        $timeout(function (){
            if(typeTemp == 'guide'){
                vm.etdPeriodDTO.venderId = vm.guide.shipperId;
                vm.etdPeriodDTO.shipperName = vm.guide.shipperName;
                vm.etdPeriodDTO.warehouseId = vm.guide.whId;
                vm.etdPeriodDTO.warehouseName = vm.guide.whName ;  
                vm.etdPeriodDTO.etdEnd = vm.guide.deliveryDate;
                if(!vm.guide.booking.bookingCtns){
                    getWmsCargoIds();
                }
                else {
                    angular.forEach(vm.guide.booking.bookingCtns, function(data){
                        if(data.wmsCargoId){
                            wmsCargoIds.push(data.wmsCargoId);
                        }
                    })
                }
            }
            else if(typeTemp == 'expressParcel'){
                vm.etdPeriodDTO.venderId = vm.guide.shipperId;
                vm.etdPeriodDTO.shipperName = vm.guide.shipperName;
                vm.etdPeriodDTO.warehouseId = vm.guide.warehouseId;
                vm.etdPeriodDTO.warehouseName = vm.guide.warehouseName;
                vm.etdPeriodDTO.etdEnd = vm.guide.etd;
                if(!vm.guide.expressCargos){
                    getWmsCargoIds();
                }
                else {
                    angular.forEach(vm.guide.expressCargos, function(data){
                        if(data.wmsCargoId){
                            wmsCargoIds.push(data.wmsCargoId);
                        }
                    })     
                }       
            }
            else {
                vm.etdPeriodDTO.venderId = vm.guide.venderId;
                vm.etdPeriodDTO.shipperName = vm.guide.clientName;
                vm.etdPeriodDTO.warehouseId = vm.guide.warehouseId;
                vm.etdPeriodDTO.warehouseName = vm.guide.warehouseName;
                vm.etdPeriodDTO.etdEnd = vm.guide.etd;
                if(!vm.guide.cargoList){
                    getWmsCargoIds();
                }
                else {
                    angular.forEach(vm.guide.cargoList, function(data){
                        if(data.id){
                            wmsCargoIds.push(data.id);
                        }
                    })    
                }  
            }
            if(wmsCargoIds.length > 0){
                getShipmentWmsCharges();
            }
        });

        var getWmsCargoIds = function() {
            WmsIn.getWmsCargoIds({
                id: vm.guide.id,
                type: typeTemp
            }, function(result){
                wmsCargoIds = result;
                if(wmsCargoIds.length > 0){
                    getShipmentWmsCharges();
                }
            })
        };

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.datePickerForCargoReadyDate = {};
        $scope.datePickerForCargoReadyDate.status = {
            opened: false
        };
        $scope.datePickerForCargoReadyDateOpen = function($event) {
            $scope.datePickerForCargoReadyDate.status.opened = true;
        };

        function getAllWarehouses () {
            if (!vm.warehouses || vm.warehouses.length == 0){
                WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false
                }, function (data) {
                    vm.warehouses = data;
                });
            }
        }

        function warehouseSelected (warehouse) {
            vm.etdPeriodDTO.warehouseId = warehouse.id;
        }

        $scope._onClientSelected = function(data) {
            vm.etdPeriodDTO.venderId = data.id;
        }

        $scope.isSaving = false;
        function getWmsCharges () {
            $scope.isSaving = true;
            WmsIn.getWmsCharges(vm.etdPeriodDTO, function(result) {
                $scope.isSaving = false;
                vm.wmsChargesDTO = result;
            }, function(response) {
                if (response.status == 409){
                    layer.msg($translate.instant('autopsApp.guide.endDateWrongAlert'));
                }
                else if (response.status == 404){
                    layer.msg($translate.instant('global.messages.resultNull'));
                }
                $scope.isSaving = false;
            });
        }

        function getAllWmsCharges () {
            vm.etdPeriodDTO.wmsCargoIds = null;
            getWmsCharges();
        }

        function getShipmentWmsCharges () {
            vm.etdPeriodDTO.wmsCargoIds = wmsCargoIds;
            getWmsCharges();
        }

        function saveWmsCharges () {
            $scope.isSaving = true;
            vm.wmsChargesDTO.guideId = vm.guide.id;
            vm.wmsChargesDTO.type = typeTemp;
            WmsIn.saveWmsCharges(vm.wmsChargesDTO, function(result) {
                $scope.isSaving = false;
                $uibModalInstance.close(result);
                layer.msg($translate.instant('global.save_success'));                
            }, function(response) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.save_fail'));
            });
        }
        
        function delRate (rate) {
            var cargoToDel = null;
            angular.forEach(vm.wmsChargesDTO.wmsCargos, function(data){
                if (data.id == rate.sharedOn){
                    cargoToDel = data;
                }             
            })
            if (!cargoToDel){return;}
            var index = vm.wmsChargesDTO.wmsCargos.indexOf(cargoToDel);
            if (index > -1) {
                vm.wmsChargesDTO.wmsCargos.splice(index, 1);
            };

            index = vm.wmsChargesDTO.guideRates.indexOf(rate);
            if (index > -1) {
                vm.wmsChargesDTO.guideRates.splice(index, 1);
            };
        }

        $scope.amountChange = function(rate) {
            rate.price = DataUtils.round(rate.price, 4);
            if (rate.price < 0) {
                rate.price = 0;
            }
            if(null == rate.count || rate.count < 0){
                rate.count = 1.00;
            }
            rate.total = rate.count * rate.price;
            if (rate.days) {
                rate.total = rate.total * rate.days;
            }
            rate.payAmount = rate.total = DataUtils.round(rate.total, 2);
        }

        
    }
})();
