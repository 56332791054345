(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsWorkLogDialogController', WmsWorkLogDialogController);

    WmsWorkLogDialogController.$inject = ['$rootScope', '$timeout', '$scope', '$stateParams', '$translate', '$uibModalInstance', 
    'entity', 'JhiLanguageService', 'WmsWorkLog', 'OpsItem', 'WmsIn', 'DataUtils', 'WmsWarehouse', 'OpsFiles'];

    function WmsWorkLogDialogController ($rootScope, $timeout, $scope, $stateParams, $translate, $uibModalInstance, 
        entity, JhiLanguageService, WmsWorkLog, OpsItem, WmsIn, DataUtils, WmsWarehouse, OpsFiles) {
        var vm = this;

        vm.wmsWorkLog = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.isSaving = false;

        vm.opsItems = [];
        vm.loadItems = loadItems;
        vm._onItemSelected = _onItemSelected;

        vm.onPayerSelected = onPayerSelected;

        vm.allWorks = [];
        vm.wmsServiceFilter = wmsServiceFilter;
        vm.saveAndCharge = saveAndCharge;
        vm.refreshCharge = refreshCharge;
        vm.updateTotal = updateTotal;
        vm.updatePrice = updatePrice;
        vm.getAllWmsService = getAllWmsService;
        vm.wmsServiceSelected = wmsServiceSelected;
        vm.getWarehouses = getWarehouses;
        vm.jobNumBindCheck = jobNumBindCheck;
        vm.taskFinishedChange = taskFinishedChange;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
            getFilesList();
        });
        function updateTotal () {
            if(vm.wmsWorkLog.count && vm.wmsWorkLog.price){
                vm.wmsWorkLog.total = DataUtils.round(vm.wmsWorkLog.count * vm.wmsWorkLog.price, 2);
            }   
            else {
                vm.wmsWorkLog.total = null;
            }        
        }
        function updatePrice () {
            if(vm.wmsWorkLog.total && vm.wmsWorkLog.count){
                vm.wmsWorkLog.price = DataUtils.round(vm.wmsWorkLog.total / vm.wmsWorkLog.count, 2);
            }           
        }
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.wmsWorkLog.id !== null) {
                WmsWorkLog.update(vm.wmsWorkLog, onSaveSuccess, onSaveError);
            } else {
                WmsWorkLog.save(vm.wmsWorkLog, onSaveSuccess, onSaveError);
            }
        }
        function onSaveError () {
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submittedfailed'));
        }

        function saveAndCharge () {
            WmsIn.saveAndCharge(vm.wmsWorkLog, onSaveSuccess, $rootScope.ALERT_ERROR);
        }

        function refreshCharge () {
            WmsWorkLog.refreshCharge(vm.wmsWorkLog, function(result){
                DataUtils.updateWmsWorkLog(vm.wmsWorkLog, result.wmsIn, result.cargo, result.opsItem);
                updateTotal();
            }, $rootScope.ALERT_ERROR);
        }

        function onSaveSuccess (result) {
            vm.wmsWorkLog = result;
            vm.wmsWorkLog.files = [];
            $scope.$emit('autopsApp:wmsWorkLogUpdate', result);
            layer.msg($translate.instant('global.save_success'));
            // $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        

        vm.datePickerOpenStatus.workTime = false;
        vm.datePickerOpenStatus.createdTime = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        
        function loadItems () {
            if (vm.isSaving){return;}    
            if (vm.opsItems.length == 0) {
                vm.isSaving = true;
                OpsItem.getAll({
                    companyId: $rootScope.account.companyId
                }, function(result) {
                    vm.isSaving = false;
                    vm.opsItems = result;
                });
            };
        };
        loadItems();

        var lang = "";
        JhiLanguageService.getCurrent().then(function(language) {
            lang = language;
        });
        function _onItemSelected (item) {
            vm.wmsWorkLog.itemId = item.id;
            if (lang == "en") {
                vm.wmsWorkLog.item = item.nameEn;
            } else {
                vm.wmsWorkLog.item = item.nameCn;
            };
            if(item.price){
                vm.wmsWorkLog.price = item.price;
            }
            if(item.unit){
                vm.wmsWorkLog.unit = item.unit;
            }
            if(item.currency){
                vm.wmsWorkLog.currency = item.currency;
            }

            if(vm.wmsWorkLog.count && vm.wmsWorkLog.price){
                vm.wmsWorkLog.total = DataUtils.round(vm.wmsWorkLog.count * vm.wmsWorkLog.price, 2);
                if(!vm.wmsWorkLog.remarks){
                    vm.wmsWorkLog.remarks = "";
                }
                if(item.min && vm.wmsWorkLog.total < item.min){
                    vm.wmsWorkLog.price = vm.wmsWorkLog.total = item.min;
                    vm.wmsWorkLog.unit = "SHIPMENT";
                    vm.wmsWorkLog.count = 1;
                    vm.wmsWorkLog.remarks = vm.wmsWorkLog.remarks + " **Minimum Charge";
                }
                else if (item.max && vm.wmsWorkLog.total > item.max){
                    vm.wmsWorkLog.price = vm.wmsWorkLog.total = item.max;
                    vm.wmsWorkLog.unit = "SHIPMENT";
                    vm.wmsWorkLog.count = 1;
                    vm.wmsWorkLog.remarks = vm.wmsWorkLog.remarks + " **Maximum Charge";
                }
            }
        }
        
        function onPayerSelected (data) {
            vm.wmsWorkLog.venderId = data.id;
            vm.wmsWorkLog.vender = data.companyName;
        }


        function wmsServiceFilter (item) {
            return item.wmsService;
        };

        vm.wmsServices = [];
        function getAllWmsService(){
            if(vm.wmsServices && vm.wmsServices.length > 0){
                return;
            }
            OpsItem.getAllWmsService({
                companyId: $rootScope.account.companyId?$rootScope.account.companyId:vm.wmsWorkLog.companyId
            }, function(result) {
                vm.wmsServices = result;
                if(!vm.wmsServices || vm.wmsServices.length == 0){
                    layer.msg($translate.instant('global.noRecord'));
                }
            }, function(error) {
                layer.msg($translate.instant('global.noRecord'));
            });
        }
        function wmsServiceSelected (opsItem) {
            vm.wmsWorkLog.work = opsItem.wmsService;
            vm.wmsWorkLog.item = opsItem.nameCn;
            vm.wmsWorkLog.itemId = opsItem.id;
            vm.wmsWorkLog.price = opsItem.price;
            vm.wmsWorkLog.currency = opsItem.currency;
            vm.wmsWorkLog.unit = opsItem.unit;
            if(vm.wmsWorkLog.count && vm.wmsWorkLog.price){
                vm.wmsWorkLog.total = DataUtils.round(vm.wmsWorkLog.count * vm.wmsWorkLog.price, 2);
                if(opsItem.min && vm.wmsWorkLog.total < opsItem.min){
                    vm.wmsWorkLog.price = vm.wmsWorkLog.total = opsItem.min;
                    vm.wmsWorkLog.unit = "SHIPMENT";
                    vm.wmsWorkLog.count = 1;
                    vm.wmsWorkLog.remarks = vm.wmsWorkLog.remarks + " **Minimum Charge";
                }
                else if (opsItem.max && vm.wmsWorkLog.total > opsItem.max){
                    vm.wmsWorkLog.price = vm.wmsWorkLog.total = opsItem.max;
                    vm.wmsWorkLog.unit = "SHIPMENT";
                    vm.wmsWorkLog.count = 1;
                    vm.wmsWorkLog.remarks = vm.wmsWorkLog.remarks + " **Maximum Charge";
                }
            }
        }
        vm.warehouses;
        function getWarehouses() {
            if (vm.warehouses && vm.warehouses.length > 0) {
                return;
            }
            WmsWarehouse.getAll({
                includeOwn: true,
                includeVirtual: false,
                includeGroup: true
            }, function(data) {
                vm.warehouses = data;
            });
        }
        function jobNumBindCheck() {
            if(!vm.wmsWorkLog.jobNum || vm.wmsWorkLog.guideId  || vm.wmsWorkLog.wmsInId || vm.wmsWorkLog.expressParcelId ){
                return;
            }
            WmsWorkLog.jobNumBindCheck({}, vm.wmsWorkLog, function(result) {
                if(result.jobNum){
                    vm.wmsWorkLog.guideId = result.guideId;
                    vm.wmsWorkLog.wmsInId = result.wmsInId;
                    vm.wmsWorkLog.expressParcelId = result.expressParcelId;                    
                }
                else {
                    vm.wmsWorkLog.jobNum = null;
                    layer.msg($translate.instant('autopsApp.wmsWorkLog.jobNumNotExist'));                    
                }
            });
        }
        function taskFinishedChange() {
            if(vm.wmsWorkLog.taskFinished || vm.wmsWorkLog.isBilled){
                return;
            }
            if(vm.wmsWorkLog.taskFinished == null){
                vm.wmsWorkLog.taskFinished = false;
            }
            else {
                vm.wmsWorkLog.taskFinished = null;
            }
        }

        function getFilesList() {
            if (vm.wmsWorkLog.id) {
                var filePath = "/" + "wmsWorkLog/" + vm.wmsWorkLog.id + "/";
                OpsFiles.getFilesList({
                    filepath: filePath,
                    companyId: vm.wmsWorkLog.companyId
                }, function(data) {
                    vm.wmsWorkLog.files = data;
                });
            }
        }




    }
})();
