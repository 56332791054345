(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsChangeLogDialogController', OpsChangeLogDialogController);

    OpsChangeLogDialogController.$inject = ['$scope', '$translatePartialLoader', '$uibModalInstance', 'entity', '$translate'];

    function OpsChangeLogDialogController ($scope, $translatePartialLoader, $uibModalInstance, entity, $translate) {
        var vm = this;
        vm.opsChangeLog = entity;
        vm.clear = clear;

        function clear () {
            $uibModalInstance.close();
        }

        $translatePartialLoader.addPart('opsChangeLog');
        $translate.refresh();
    }
})();
