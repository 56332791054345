(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsItemController', OpsItemController);

    OpsItemController.$inject = ['$timeout', '$scope', '$rootScope', '$state', '$translate', '$http', '$uibModal',
    'OpsItem', 'OpsItemSearch', 'ParseLinks', 'Principal', 'OpsFiles'];

    function OpsItemController($timeout, $scope, $rootScope, $state, $translate, $http, $uibModal,
        OpsItem, OpsItemSearch, ParseLinks, Principal, OpsFiles) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})){
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };

        $scope.currentSearch = false;
        $scope.opsItems = [];
        $scope.predicate = 'id';
        $scope.reverse = false;
        $scope.page = 1;
        $scope.itemsPerPage = 20;
        $scope.loadAll = function() {
            $scope.currentSearch = false;
            OpsItem.query({page: $scope.page-1, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']},function(result,headers) {
            	$scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
            	$scope.opsItems = result;
            });
        };
        $scope.loadAll();

        $scope.search = function () {
            $scope.currentSearch = true;
            OpsItemSearch.query({query: $scope.searchQuery}, function(result, headers) {
                $scope.opsItems = result;
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
            }, function(response) {
                if(response.status === 404) {
                    $scope.loadAll();
                }
            });
        };
        $scope.reset = function() {
            $scope.currentSearch = false;
            $scope.page = 0;
            $scope.opsItems = [];
            $scope.loadAll();
        };

        $scope.edit = function (item) {
            if(item.companyId == 0 && $rootScope.account.id != 5){
                return;
            }
            $rootScope.id = item.id;
            $state.go('opsItem.edit');

        }
        $scope.delete = function (opsItem) {
            if (!opsItem.id || opsItem.companyId == 0){
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/opsItem/opsItem-delete-dialog.html',
                controller: 'OpsItemDeleteController',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return {code: opsItem.code, id: opsItem.id};
                    }]
                }
            }).result.then(function(result) {
                for(var i = $scope.opsItems.length - 1; i >= 0; i--){
                    if(opsItem.id == $scope.opsItems[i].id){
                        $scope.opsItems.splice(i, 1);
                    }
                }
            }, function() {
            })
        }

        $timeout(function (){
            $rootScope.RESIZE();
        });


        $scope.importByExcel = function  (file) {
            if (!file) {return;}
            var fd = new FormData();
            fd.append('file', file);
            var url = "api/opsItems/importByExcel";
            layer.msg("Importing...", {
                shade: 0.3,
                time: 200000
            });
            $http.post(url, fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    layer.closeAll();
                    $scope.loadAll();
                    if(!data){
                        layer.msg($translate.instant('global.importSuccess'));
                        return;
                    }
                    data = angular.fromJson(data);
                    $rootScope.OPS_ALERT($translate.instant('global.importExcelAlert', {updated: data.updated, created: data.created, invalid: data.invalid}));
                }).error(function(error) {
                    layer.closeAll();
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                });
        };

        $scope.groupShareOne = function(opsItem){
            if($rootScope.HAVENOAUTH('GROUP_SHARE')){
                $rootScope.FORBIDDEN_BY_ALERT('GROUP_SHARE');
                return;
            }
            layer.confirm($translate.instant('global.groupShareAlert'), {
                icon: 3,
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                layer.close(index);
                layer.load(1, {shade: 0.3});
                OpsItem.groupShareOne(opsItem, function(){
                    layer.closeAll();
                    $rootScope.OPS_ALERT($translate.instant('global.shareSuccess'));
                }, function(){
                    layer.msg($translate.instant('global.save_fail'));
                });
            }, function() {
            });
        }

        $scope.groupShare = function(){
            if($rootScope.HAVENOAUTH('GROUP_SHARE')){
                $rootScope.FORBIDDEN_BY_ALERT('GROUP_SHARE');
                return;
            }
            layer.confirm($translate.instant('global.groupShareAlert'), {
                icon: 3,
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                layer.close(index);
                layer.load(1, {shade: 0.3});
                OpsItem.groupShare(function(){
                    layer.closeAll();
                    $rootScope.OPS_ALERT($translate.instant('global.shareSuccess'));
                }, function(){
                    layer.closeAll();
                    $rootScope.OPS_ALERT($translate.instant('global.save_fail'));
                });
            }, function() {
            });
        }

        $scope.excelExport = function(companyId) {
            layer.load(1, { shade: 0.3 });
            var fileName = companyId == 0?"system_default_items.xlsx":"expense_items.xlsx";
            OpsFiles.exportOpsItems({
                companyId: companyId
            }, function(result) {
                layer.closeAll();
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }

    }
})();
