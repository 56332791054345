(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('FeaturesController', FeaturesController);
    FeaturesController.$inject = ['$rootScope', '$scope', '$state'];

    function FeaturesController ($rootScope, $scope, $state) {
        var vm = this;
        $scope.scroll = function (id) {  
        $("html,body").animate({scrollTop:$('#' + id).offset().top},1000)
        }
        if (!$rootScope.fmsOrWms){
            $rootScope.fmsOrWms = "fms";
        }

        $scope.goIntroduction = function () {  
            $state.go('introduction');
        }
        $scope.goTariff = function () {  
            $state.go('tariff');
        }
        $scope.goFeatures = function () {  
            $state.go('features');
        }
        $scope.goFaq = function () {  
            $state.go('faq');
        }
        $scope.goChangeLog = function () {  
            $state.go('changelog');
        }
        $scope.goCarrier = function () {  
            $state.go('carrier');
        }
        $scope.goFeatureswms = function () {  
            $state.go('features-wms');
        }
        $scope.goProjectCase = function () {  
            $state.go('project-case');
        }
        $scope.dialogIsShow=false;
        // 通过dom操作或者jq操作进行增加一个蒙版元素，并且当关闭的时候需要删除dom元素
        // 看蒙版能否通过jQuery的animation进行打开，让蒙层淡进淡出
        $scope.changeDialog = function() {
            $scope.dialogIsShow=!$scope.dialogIsShow;
            var dialogWarper = document.querySelector('.dialogWarper');
            var body = document.querySelector('body');
            if($scope.dialogIsShow){
                var backgroundDiv = document.createElement("div");
                backgroundDiv.setAttribute("class", "backgroundDiv");
                backgroundDiv.classList.add('addDiv');
                body.appendChild(backgroundDiv);
                dialogWarper.classList.remove('removeDialogWarper');
            }else{
                var backgroundDiv = document.querySelector('.backgroundDiv');
                if(backgroundDiv){
                    backgroundDiv.classList.add('removeDiv');
                    setTimeout(function(){
                        backgroundDiv.remove();
                        dialogWarper.classList.add('removeDialogWarper');
                    },350)
                }
            }

        }
        $scope.stopProp =function ($event) {
            if(!$event){
                return;
            }
            $event.stopPropagation() ?  $event.stopPropagation() :  $event.cancelBubble = true;
        }
    }
})();
