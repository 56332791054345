(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('OpsVenderSearch', OpsVenderSearch);

    OpsVenderSearch.$inject = ['$resource'];

    function OpsVenderSearch($resource) {
        return $resource('api/_search/opsVenders/:query', {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
