(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('OpsExchangeRateSearch', OpsExchangeRateSearch);

    OpsExchangeRateSearch.$inject = ['$resource'];

    function OpsExchangeRateSearch($resource) {
        var resourceUrl =  'api/_search/ops-exchange-rates/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
