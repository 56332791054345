(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('AccountGeneral', AccountGeneral);

    AccountGeneral.$inject = ['$resource','DateUtils'];
    
    function AccountGeneral($resource, DateUtils) {
        return $resource('api/accountGenerals/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.makeDate = DateUtils.convertLocalDateFromServer(data.makeDate);
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.makeDate = DateUtils.convertLocalDateToServer(data.makeDate);
                    return angular.toJson(data);
                }
            },
            'profitStatementPrint':{
                method:'PUT',
                url:'api/accountGenerals/profitStatementPrint',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'profitStatementGet':{
                method:'GET',
                url:'api/accountGenerals/profitStatementGet'
            },
            'fixAssistAccountBal':{
                method:'GET',
                url:'api/accountGenerals/fixAssistAccountBal'
            },
            'getPeriodAccountCodes': {
                method: 'PUT',
                url:'api/accountGenerals/getPeriodAccountCodes',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.makeDateFM = DateUtils.convertLocalDateToServer(data.makeDateFM);
                    data.makeDateTO = DateUtils.convertLocalDateToServer(data.makeDateTO);
                    return angular.toJson(data);
                }
            },
            'getAccountGenerals': {
                method: 'PUT',
                isArray: true,
                url:'api/accountGeneralsFilter',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.makeDate = DateUtils.convertLocalDateToServer(data.makeDate);
                    return angular.toJson(data);
                }
            }
        });
    }
})();
