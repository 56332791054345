(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ReportTemplateChooseController', ReportTemplateChooseController);

    ReportTemplateChooseController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$translate', 'entity'];
    
    function ReportTemplateChooseController($scope, $rootScope, $uibModalInstance, $translate, entity) {

        var reportTemplates = entity.reportTemplates;
        $scope.shipmentType = entity.shipmentType;
        $scope.isMblSa = entity.isMblSa;

        $scope.result = {
            saCode: 120,
            hblCode: 130,
            cnCode: $scope.shipmentType == "AIR"?420:210
        };

        $rootScope.LOAD_REPORT_TEMPLATES();
        $scope.save = function () {
            $uibModalInstance.close($scope.result);
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();
