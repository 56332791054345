(function() {
	'use strict';

	angular
		.module('autopsApp')
		.controller('ChartController', ChartController);

	ChartController.$inject = ['$scope', '$rootScope', '$translate', '$state', '$uibModal', '$timeout', 'Principal', 
	'OpsFiles', 'uiGridConstants', 'uiGridGroupingConstants', 'GridState', 'DateUtils', 'Clerks', 'DataUtils'];

	function ChartController($scope, $rootScope, $translate, $state, $uibModal, $timeout, Principal,
		OpsFiles, uiGridConstants, uiGridGroupingConstants, GridState, DateUtils, Clerks, DataUtils) {		

		$scope.statParams = {};
        $scope.statParams.statType = "month";
        $scope.statParams.month = new Date();
        $scope.statParams.filterRequired = true;
        $scope.contentsList = [];
		var labelTitle="";
        var bgColors = [
            'rgba(255, 99, 132, 0.5)',
            'rgba(54, 162, 235, 0.5)',
            'rgba(255, 206, 86, 0.5)',
            'rgba(75, 192, 192, 0.5)',
            'rgba(153, 102, 255, 0.5)',
            'rgba(255, 159, 64, 0.5)'
        ];
        var bdColors = [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)',
            'rgba(255, 159, 64, 1)'
        ];
		
        $scope.chart = {
            type: "bar",
            x: "salesName",
            y: "profitRMB"
        };
        var chartData = {
            labels: [],
            datasets: []
        };

        $scope.logarithmic = false;
        $scope.stacked = false;
		var options = {
			responsive: true,
			layout: {
                padding: {
                    left: 20,
                    right: 20,
                    top: 10,
                    bottom: 10
                }
            },
            scales: {
				xAxes: [{
					display: true,
					stacked: $scope.stacked
				}],
				yAxes: [{
					display: true,
					type: $scope.logarithmic == true? 'logarithmic' : 'linear',
					stacked: $scope.stacked
				}]
			}
		}
        $timeout(function() {
			initChart();
        });
        var ctx = document.getElementById('myChart').getContext('2d');
        $scope.myChart;
		var initChart = function() {			
			$scope.myChart = new Chart(ctx, {
				type: $scope.chart.type,
				data: chartData,
				options: options
			});
		}


        var contents;
		var getContents = function() {
			DataUtils.updateStatParams($scope.statParams);
			if(!$scope.statParams.etdFM || !$scope.statParams.etdTO){
				layer.msg($translate.instant('stats.stat.dateNullAlert'));				
				return;
			}
			layer.load(1, {shade: 0.3});
			OpsFiles.comStats($scope.statParams, function(result) {
				layer.closeAll();
				if (!result || result.length == 0) {
					layer.msg($translate.instant('global.messages.resultNull'));
                    return;
				}
				$scope.contentsList.push(result);
				addData(result);      
			}, $rootScope.ALERT_ERROR);
		}
        
        $scope.statParamsPop = function() {
            $uibModal.open({
                templateUrl: 'app/entities/stats/statParams-dialog.html',
                controller: 'StatParamsDialogController',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return $scope.statParams;
                    }]
                }
            }).result.then(function(result) {
                $scope.statParams = result;
                getContents();
            }, function() {
            });
        }

        var getBarData = function(contents) {    
            angular.forEach(contents, function(data){
            	if(!data[$scope.chart.x]){
					data[$scope.chart.x] = "null";
                }
                if(chartData.labels.indexOf(data[$scope.chart.x]) == -1){
                    chartData.labels.push(data[$scope.chart.x]);
                }
            })
            var dataSet = {
                label: labelTitle,
                borderWidth: 1,
                data: [],
                backgroundColor: [],
                borderColor: []
            };
            var idx = 0;
            angular.forEach(chartData.labels, function(label){   	
                var ttl = 0;
                angular.forEach(contents, function(data){
                    if(label == data[$scope.chart.x] && data[$scope.chart.y]){
                        ttl+=data[$scope.chart.y];
                    }
                })
                ttl = ttl.toFixed(2) -0; 
                dataSet.data.push(ttl);
                dataSet.backgroundColor.push(bgColors[idx]);
                dataSet.borderColor.push(bdColors[idx]);
                if(++idx > 5){
                    idx = 0;
                }
            })
            chartData.datasets.push(dataSet);   
            $scope.myChart.update();
        }
        var getLineData = function(contents) {
        	var objList = [];
            angular.forEach(contents, function(data){
                if(chartData.labels.indexOf(data.yearWeek) == -1){
                    chartData.labels.push(data.yearWeek);
                }
                if(!data[$scope.chart.x]){
					data[$scope.chart.x] = "null";
                }
            	if(objList.indexOf(data[$scope.chart.x]) == -1){
                    objList.push(data[$scope.chart.x]);
                }
            })            
            chartData.labels.sort(function(a,b){return a.localeCompare(b)});
			var idx = 0;
			angular.forEach(objList, function(objValue){           
	            var dataSet = {
	                label: objValue,
	                fill: false,
	                data: [],
	                backgroundColor: bgColors[idx],
	                borderColor: bdColors[idx]
	            };
	            angular.forEach(chartData.labels, function(label){
	                var ttl = 0;
	                angular.forEach(contents, function(data){
	                    if(label == data.yearWeek && objValue == data[$scope.chart.x] && data[$scope.chart.y]){
	                        ttl+=data[$scope.chart.y];
	                    }
	                })
	                ttl = ttl.toFixed(2) -0; 
	                dataSet.data.push(ttl);  
	            })
	            chartData.datasets.push(dataSet);
	            if(++idx > 5){
                    idx = 0;
                }
            });
            $scope.myChart.update();

        }

        var addData = function(contents) {     
        	if($scope.chart.type == "bar" || $scope.chart.type == "doughnut" || $scope.chart.type == "polarArea" || $scope.chart.type == "radar"){
        		getBarData(contents);
        	}
        	else if($scope.chart.type == "line"){
        		getLineData(contents);
        	}
        }

        $scope.chartTypeChange = function() {   
        	$scope.myChart.destroy();	
            chartData = {
	            labels: [],
	            datasets: []
	        };
	       	if($scope.chart.type == "doughnut" || $scope.chart.type == "polarArea" || $scope.chart.type == "radar"){
				options.legend = {
					position: 'right'
				}
			}
			else {
				options.legend = {
					position: 'top'
				}
			}
			initChart();
			angular.forEach($scope.contentsList, function (contents) {
	        	addData(contents);
	        })
        };
		
        $scope.lineTypeChange = function() {   
    		$scope.myChart.destroy();	
        	chartData = {
	            labels: [],
	            datasets: []
	        };
	        options.scales.yAxes[0].type = $scope.logarithmic == true? 'logarithmic' : 'linear';
	        options.scales.yAxes[0].stacked = $scope.stacked;
	        options.scales.xAxes[0].stacked = $scope.stacked;
			initChart();
        	if($scope.chart.type == "line"){
        		var newDataSet = [];
		        angular.forEach($scope.contentsList, function (contents) {
		        	angular.forEach(contents, function (data) {
		        		if(newDataSet.indexOf(data) == -1){
		        			newDataSet.push(data);
		        		}		        	
			        })
		        })
		        addData(newDataSet);
        	}
        	else {
				angular.forEach($scope.contentsList, function (contents) {
		        	addData(contents);
		        })
        	}
        };
        $scope.combineDataSet = function() {
            var newDataSet = [];
	        angular.forEach($scope.contentsList, function (contents) {
	        	angular.forEach(contents, function (data) {
	        		if(newDataSet.indexOf(data) == -1){
	        			newDataSet.push(data);
	        		}		        	
		        })
	        })
	        $scope.contentsList = [];
	        $scope.contentsList.push(newDataSet);
	        $scope.chartTypeChange();
        };

        $scope.removeDataSet = function() {
			$scope.myChart.data.datasets.shift();
			$scope.myChart.update();
        };

        $scope.toBase64Image = function() {
        	var aTag = document.createElement("a");
            aTag.download = labelTitle;
            aTag.href = $scope.myChart.toBase64Image();
            aTag.click();
        };





	}
})();