(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountSubsidiaryDetailController', AccountSubsidiaryDetailController);

    AccountSubsidiaryDetailController.$inject = ['$scope', '$rootScope', '$timeout', '$uibModal', '$translate', '$location', 
    'JhiLanguageService', 'Principal', 'AccountRef', 'DateUtils', 'AccountVoucher', 'AccountTitle', 
    'VoucherContent', 'OpsFiles', 'User', 'OpsVender', 'DataUtils'];

    function AccountSubsidiaryDetailController($scope, $rootScope, $timeout, $uibModal, $translate, $location, 
        JhiLanguageService, Principal, AccountRef, DateUtils, AccountVoucher, AccountTitle, 
        VoucherContent, OpsFiles, User, OpsVender, DataUtils) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        }
        
        $scope.filterDTO = {subsidiaryType:'vender'};
        var lang = "";
        JhiLanguageService.getCurrent().then(function(language) {
            lang = language;
        });

        $scope.ledgers = [];
        $scope.getLedgers = function() {
            if($scope.ledgers.length > 0){
                return;
            }
            var loadIndex = layer.load(1, {shade: 0.3});
            AccountTitle.getAll({
                type: "all"
            }, function(result) {
                layer.close(loadIndex);  
                $scope.ledgers = DataUtils.generateShowLedger(result, false, lang);
            });
        }      

        $scope.reportavailalbe = false;
        $scope._onLedgerChoosed = function(data) {      
            $scope.filterDTO.ledgerId = data.id;
            $scope.filterDTO.ledgerCode = data.code;
        }

        $scope.datePickerForEtd = {};
        $scope.datePickerForEtd.status = {
            opened: false
        };
        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };

        $scope.datePickerForCargoReadyDate = {};
        $scope.datePickerForCargoReadyDate.status = {
            opened: false
        };
        $scope.datePickerForCargoReadyDateOpen = function($event) {
            $scope.datePickerForCargoReadyDate.status.opened = true;
        };
        
        $scope.isSaving = false;
        $scope.ledgerEntries = [];
        var refrestCDC = function() {
            var date1, dateArray;
            angular.forEach($scope.ledgerEntries, function(ledgerEntry){
                angular.forEach(ledgerEntry.voucherContents, function(content){
                    if (content.dn || content.dn == 0) {
                        content.dn = DataUtils.formatTwoDigits(content.dn);
                    }
                    if (content.cn || content.cn == 0) {
                        content.cn = DataUtils.formatTwoDigits(content.cn);
                    }
                    if (content.balance || content.balance == 0) {
                        content.balance = DataUtils.formatTwoDigits(content.balance);
                    }
                    if (content.dnCurrencyCount || content.dnCurrencyCount == 0) {
                        content.dnCurrencyCount = DataUtils.formatTwoDigits(content.dnCurrencyCount);
                    }
                    if (content.cnCurrencyCount || content.cnCurrencyCount == 0) {
                        content.cnCurrencyCount = DataUtils.formatTwoDigits(content.cnCurrencyCount);
                    }
                    if (content.currencyBalance || content.currencyBalance == 0) {
                        content.currencyBalance = DataUtils.formatTwoDigits(content.currencyBalance);
                        // content.exchangeRate = DataUtils.formatTwoDigits(content.exchangeRate);
                        content.currencyCount = DataUtils.formatTwoDigits(content.currencyCount);
                    }
                })
            })
        }
        var initCContent = function() {
            if (!$scope.ledgerEntries || $scope.ledgerEntries.length == 0 ) {
                $scope.ledgerEntries = [];
                $scope.ledgerEntries.push({});
                $scope.ledgerEntries[0].voucherContents = [];
                for (var j = 0; j <= 20; j++) {
                    $scope.ledgerEntries[0].voucherContents.push({});
                }
            }
            else{
                for(var i = $scope.ledgerEntries.length - 1; i >= 0; i--){
                    if(!$scope.ledgerEntries[i].subsidiaryType){
                        $scope.ledgerEntries.splice(i, 1);
                    }
                }
            }
            refrestCDC();
        }

        initCContent();
        var yearFrom, yearTo;         
        $scope.getContetns = function() {
            if(($scope.filterDTO.subsidiaryType == 'vender' && !$scope.filterDTO.venderId)
                || ($scope.filterDTO.subsidiaryType == 'clerkName' && !$scope.filterDTO.clerkId)
                || ($scope.filterDTO.subsidiaryType == 'team' && !$scope.filterDTO.team)
                || ($scope.filterDTO.subsidiaryType == 'branch' && !$scope.filterDTO.branch)){
                layer.msg($translate.instant('autopsApp.accountTitle.chooseSubsidiaryAlert'));
                return;
            }
            if (!$scope.filterDTO.dateFrom || !$scope.filterDTO.dateTo || !$scope.filterDTO.ledgerId) {
                layer.msg($translate.instant('autopsApp.accountTitle.nullFilterAlert'));
                return;
            }
            var date1, date2, aDate, oDate1, oDate2, iDays;
            date1 = DateUtils.convertLocalDateToServer($scope.filterDTO.dateFrom);
            date2 = DateUtils.convertLocalDateToServer($scope.filterDTO.dateTo);

            if (date1 > date2) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                return;
            }
            $scope.filterDTO.companyId = $rootScope.account.companyId;
            $scope.isSaving = true;            
            if(!$scope.filterDTO.vender){
                $scope.filterDTO.venderId = null;
            }
            if(!$scope.filterDTO.clerkName){
                $scope.filterDTO.clerkId = null;
            }     
            if(!$scope.filterDTO.ledger){
                $scope.filterDTO.ledgerId = null;
                $scope.filterDTO.ledgerCode = null;
            }         

            var filterDTO = angular.copy($scope.filterDTO);
            VoucherContent.getSubsidiaryAccountDetails(filterDTO, onGetSuccess, onGetError);           
        }
        var onGetSuccess = function(result) {
            $scope.isSaving = false;
            if (!result || result.length == 0) {
                layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                return;
            }
            if (result.length > 200) {
                layer.confirm($translate.instant('global.dataSizeAlert', {amt: result.length}), {
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    $scope.excelExport(result);
                    layer.close(index);
                }, function() {
                    addToResultList(result);
                    $scope.reportavailalbe = true;
                    initCContent();
                    $translate.refresh();
                });
            }
            else {
                addToResultList(result);
                $scope.reportavailalbe = true;
                initCContent();
            }             
        }
        var addToResultList = function(newList) {
            angular.forEach(newList, function(newOne) {
                var isNew = true;
                angular.forEach($scope.ledgerEntries, function(oldOne) {
                    if(oldOne.subsidiaryType == newOne.subsidiaryType 
                        && oldOne.subsidiaryName == newOne.subsidiaryName
                        && oldOne.datePeriodStr == newOne.datePeriodStr){
                        isNew = false;
                        angular.copy(newOne, oldOne);
                    } 
                })
                if(isNew){
                    $scope.ledgerEntries.splice(0, 0, newOne);
                }                
            })
        }

        $scope.delLedgerEntry = function(idx) {   
            $scope.ledgerEntries.splice(idx, 1);
            initCContent();
        }
        $scope.clearResults = function(idx) {   
            $scope.ledgerEntries = [];
            initCContent();
        }


        var onGetError = function(response) {
            $scope.isSaving = false;
            if (response.status == 406){
                layer.msg($translate.instant('autopsApp.accountTitle.lastYearNotAccountedAlert'));
            }               
            initCContent();
        }

        $scope.excelExport = function(result) {   
            var ledgerEntries;
            if(result){
                ledgerEntries = angular.copy(result);     
            }
            else {
                ledgerEntries = angular.copy($scope.ledgerEntries);   
            }

            if(!ledgerEntries || ledgerEntries.length == 0){
                layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                return;
            }
            var title = $translate.instant('global.menu.entities.subsidiaryDetail');
            var fileName = title + ".xlsx";
            title = title + "(From " + DateUtils.convertLocalDateToServer($scope.filterDTO.dateFrom) + " To " + DateUtils.convertLocalDateToServer($scope.filterDTO.dateTo) + ")";
            OpsFiles.subsidiaryAccountDetails({
                title: title,
                enCn: $translate.instant('global.enCn')
            }, ledgerEntries, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);                
            })
        }

        $scope.getVoucher = function(id, voucherContents) {
            if (null == id) {
                return;
            }
            VoucherContent.getVoucher({
                id: id
            }, function(result) {
                $rootScope.VIEWCONTENTVOUCHER(id, voucherContents, result);
            });
        };
        $scope.updateSettlementObj = function(item) {
            $scope.filterDTO.venderId = item.id;
        }

        $scope.onClerkSelected = function(item) {
            $scope.filterDTO.clerkId = item.id;
        };

    }
})();