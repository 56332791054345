
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('EdiInsuranceDetailController',EdiInsuranceDetailController);

    EdiInsuranceDetailController.$inject = ['$rootScope', '$scope', '$uibModalInstance', '$uibModal', '$timeout', '$translate',
    'entity', 'OpsCost', 'AddressBook', 'DataUtils', 'DateUtils'];

    function EdiInsuranceDetailController($rootScope, $scope, $uibModalInstance, $uibModal, $timeout, $translate,
        entity, OpsCost, AddressBook, DataUtils, DateUtils) {
        var vm = this;
        vm.isPay = false;
        vm.isFromJob = true;

        vm.freightParams = null;
        vm.clear = clear;
        vm.chooseService = chooseService;
        vm.getRates = getRates;
        vm.getPorts = getPorts;
        vm.trialType = 'iTrial';
        vm.trialTypeChange = trialTypeChange;
        vm.addOne = addOne;
        vm.removeOne = removeOne;
        vm.makeBooking = makeBooking;
        $timeout(function() {
            if(!entity){
                vm.isFromJob = false;
                vm.freightParams = {
                    isPay: vm.isPay,
                    shipmentType: "FBASEA",
                    companyId: $rootScope.account.companyId,
                    plList: [{pkgs: 1}]
                };                
            }
            else {
                OpsCost.getTrialCostDTO({
                    isPay: vm.isPay
                }, entity, function(result){
                    vm.freightParams = result;
                    if(!vm.freightParams.plList || vm.freightParams.plList.length == 0){
                        vm.freightParams.plList = [{pkgs: 1}];
                    }

                });
            }
        })
        function addOne(argument) {
            vm.freightParams.plList.push({pkgs: 1});
        }
        function removeOne(idx) {
            vm.freightParams.plList.splice(idx, 1);
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function chooseService (result) {
            $uibModalInstance.close(result);
        }
        function trialTypeChange (type) {
            vm.trialType = type;
            if(vm.trialType != "eTrial"){
                vm.freightParams.sellingMath = null;
                vm.freightParams.costMath = null;
                vm.freightParams.deliveryChannel = null;
                vm.freightParams.polChannel = null;
                vm.freightParams.productName = null;
            }
        }
        vm.ratesList = [];
        function getRates () {
            if(vm.trialType == 'eTrial' 
                    && (!vm.freightParams.polChannel && !vm.freightParams.deliveryChannel && !vm.freightParams.productName
                        && !vm.freightParams.sellingMath && !vm.freightParams.costMath)){
                layer.msg($translate.instant('autopsApp.opsCost.mathChannelNullAlert'));
                return;
            }            
            // if((vm.trialType != 'eTrial' || vm.freightParams.polChannel) && (!vm.freightParams.shipperCity && (!vm.freightParams.shipperPostalCode || !vm.freightParams.shipperCountryCode))){
            //     layer.msg($translate.instant('autopsApp.expressParcel.shipperNullAlert'));
            //     return;
            // }
            if((vm.trialType != 'eTrial' || vm.freightParams.deliveryChannel) 
                && (!vm.freightParams.shiptoCity && (!vm.freightParams.shiptoPostcode || !vm.freightParams.countryCode))){
                layer.msg($translate.instant('autopsApp.expressParcel.shiptoNullAlert'));
                return;
            }
            vm.isSaving = true;
            var freightParam = angular.copy(vm.freightParams);

            if(!freightParam.plList || freightParam.plList.length == 0){
                freightParam.plList = [{pkgs: 1}];
            }
            angular.forEach(freightParam.plList, function(pl){
                if(pl.pkgs){
                    if(pl.gw){
                        pl.gwTtl = pl.gw * pl.pkgs;
                    }
                    if(pl.gw){
                        pl.volTtl = DataUtils.round(pl.vol * pl.pkgs, 6);
                    }
                }
            })

            OpsCost.fmsTryCost({
                    isPay: vm.isPay
                }, freightParam, function(ratesList){
                vm.isSaving = false;
                vm.ratesList = ratesList;
                if (!vm.ratesList || vm.ratesList.length == 0){
                    layer.msg($translate.instant('global.noRecord'));
                }
                else {
                    getCheapestPrice();
                }
            }, function(error){
                vm.isSaving = false;
                $rootScope.ALERT_ERROR(error);
            })
        }

        function getCheapestPrice(){
            vm.cheapestPrice = null;
            angular.forEach(vm.ratesList, function(rate){
                if(!vm.cheapestPrice || rate.freight < vm.cheapestPrice){
                    vm.cheapestPrice = rate.freight;
                }
            })
            vm.ratesList.sort(function(a,b){
                return Math.abs(a.freight)>Math.abs(b.freight)?1:-1;
            });

        }

        $scope.addressBooks = [];
        $scope.loadAddressBooks = function() {
            if ($scope.addressBooks.length == 0 && !$scope.isSaving) {
                $scope.isSaving = true;
                AddressBook.getAll(function(result) {
                    $scope.addressBooks = result;
                    $scope.isSaving = false;
                });

            };
        };
        $scope._onAddressSelected = function(data) {
            vm.freightParams.shiptoCode = data.code;
            vm.freightParams.shiptoPostcode = data.postCode;
            vm.freightParams.countryCode = data.countryCode;
            vm.freightParams.shiptoCity = data.code;
            vm.freightParams.shiptoId = data.id;
        }
        $scope._onAddressSelected1 = function(data) {
            vm.freightParams.shipFromCode = data.code;
            vm.freightParams.shipperPostalCode = data.postCode;
            vm.freightParams.shipperCountryCode = data.countryCode;
            vm.freightParams.shipperCity = data.code;
            vm.freightParams.shipperId = data.id;
        }
        $scope.sizeChange = function(pl) {
            if(!pl.length || !pl.width || !pl.height){
                return;
            }
            pl.vol = pl.length * pl.width * pl.height;
            pl.vol = DataUtils.round(pl.vol/1000000, 4);
        }
        function getPorts () {
            OpsCost.getAllPorts({
                type: 26,
                companyId: $rootScope.account.companyId
            }, function(result){
                vm.ports = result;
            })
        }

        $scope.opsCosts = [];
        $scope.opsSellings = [];        
        $scope.findPrices = function(isSelling) {
            if(!isSelling && $scope.opsCosts.length > 0){return;}
            if(isSelling && $scope.opsSellings.length > 0){return;}
            var shipmentType;
            if(vm.freightParams.shipmentType == "FCL"){
                shipmentType = "FCL";
            }
            else if(vm.freightParams.shipmentType == "LCL"){
                shipmentType = "LCL";
            }
            else if(vm.freightParams.shipmentType == "AIR"){
                shipmentType = "AIR";
            }
            else{
                shipmentType = "FBA";
            }
            if(!shipmentType){
                return;
            }
            OpsCost.findPrices({
                // etd: DateUtils.convertLocalDateToServer($scope.guide.etd),
                isSelling: isSelling,
                shipmentType: shipmentType}, function(result){
                if(isSelling){
                    $scope.opsSellings = result;
                }
                else {
                    $scope.opsCosts = result;
                }                
            })
        }
        function makeBooking(rate) {
            console.log(angular.toJson(rate, true));
            $rootScope.id = "new";
            $rootScope.bookingType = "fba";
            OpsCost.getGuideByOpsCost({
                opsCostId: rate.opsCostId,
                dest: rate.dest
            }, function(result){
                var plList = angular.copy(vm.freightParams.plList);
                angular.forEach(plList, function(pl){
                    pl.pkgNum = pl.pkgs;
                })
                result.booking.bookingCtns = plList;
                result.shiptoPostcode = vm.freightParams.shiptoPostcode;
                result.destCountryCode = vm.freightParams.countryCode;
                result.booking.bookingToEmail = vm.freightParams.shiptoCity;
                result.shiptoCode = vm.freightParams.shiptoCode;
                result.booking.deliverytoId = vm.freightParams.shiptoCode;
                result.booking.deliveryTo = vm.freightParams.shiptoCode;        
                $uibModal.open({
                    templateUrl: 'app/entities/booking/booking-fba.html',
                    controller: 'BookingDetailController',
                    backdrop: false,
                    size: 'xxl',
                    resolve: {
                        entity: [function() {
                            return result;
                        }]
                    }
                }).result.then(function(result) {
                }, function(result) {
                });
            });


        }
    }
})();

