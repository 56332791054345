(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsColoaderController', OpsColoaderController);

        OpsColoaderController.$inject = ['$scope', '$rootScope', '$state', '$translate', 'Principal', 'OpsFiles', 'OpsColoader', 'OpsColoaderSearch','ParseLinks', 'OpsShipper'];

    function OpsColoaderController($scope, $rootScope, $state, $translate, Principal, OpsFiles, OpsColoader, OpsColoaderSearch,ParseLinks, OpsShipper) {

        $scope.opsColoaders = [];
        $scope.predicate = 'id';
        $scope.reverse = false;
        $scope.page = 1;
        $scope.currentSearch = false;

        $scope.loadAll = function() {
            $scope.currentSearch = false;
            OpsColoader.query({page: $scope.page-1, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']},function(result,headers) {
            	$scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
            	$scope.opsColoaders = result;
                setTimeout(function() {frzTable("divTable", "showTable", "footPager");}, 100);
            });
        };
        $scope.loadAll();

        if (!$rootScope.account || angular.equals($rootScope.account, {})){
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };
        $scope.reset = function() {
            $scope.currentSearch = false;
            $scope.page = 0;
            $scope.opsColoaders = [];
            $scope.loadAll();
        };

        $scope.search = function () {
            $scope.currentSearch = true;
            OpsColoaderSearch.query({query: $scope.searchQuery}, function(result) {
                $scope.opsColoaders = result;
                setTimeout(function() {frzTable("divTable", "showTable", "footPager");}, 100);
            }, function(response) {
                if(response.status === 404) {
                    $scope.loadAll();
                }
            });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        var onDeleteSuccess = function (result) {
            layer.msg($translate.instant('global.delete_success'));
            $scope.loadAll();
        };

        var onDeleteError = function (result) {
            if (result.status === 403){
                layer.msg($translate.instant('global.delete_forbidden'));
            }
            else{
                layer.msg($translate.instant('global.delete_fail'));
            }
        };

        $scope.delete = function (obj) {
            if($rootScope.ISDEMO && $rootScope.HAVENOAUTH('ROLE_BOSS')){
                layer.msg($translate.instant('global.demoAlert'));
                return;
            }
            layer.confirm(obj.companyName + $translate.instant('autopsApp.opsColoader.delete.question'), {
                title: $translate.instant('autopsApp.opsColoader.delete.title'),
                btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')]
            }, function(index) {
                layer.close(index);
                OpsColoader.delete({id: obj.id}, onDeleteSuccess, onDeleteError);
            }, function(index) {
                layer.close(index);
            });
        }


        $scope.clear = function () {
            $scope.opsColoader = {
                companyId: null,
                createdBy: null,
                venderId: null,
                code: null,
                companyName: null,
                address: null,
                attn: null,
                mob: null,
                tel: null,
                fax: null,
                email: null,
                role: null,
                authority: null,
                remarks: null,
                createdTime: null,
                lastModifiedBy: null,
                lastModifiedTime: null,
                id: null
            };
        };
        $scope.upperCode = function(client) {
            client.code = angular.uppercase(client.code);
        };

        $scope.files = new Array();

        $scope.loadFiles = function(id){
            if(id){
            OpsFiles.getBaFile({id:id},function (data){
                $scope.files = new Array();
                if(data.length>0){
                    for(var i = 0 ; i < data.length; i++){
                        var temp  = new Object();
                        temp['filepath'] = data[i]['filepath'];
                        temp['filename'] = data[i]['filename'];
                        temp['fileurl'] = data[i]['fileurl'];
                        temp['fileist'] = true;
                        $scope.files.push(temp);
                    }
                }
            });
            }
        }

        //选择文件
        $scope.chooseColoaderFile = function(){
            $("input[name=baFile]").trigger('click');
        }

        $scope.delColoaderFile = function(index){
            $scope.filepath =  $scope.files[index]['filepath'];
                if($scope.files[index]['filepath']!=undefined){
                    OpsFiles.delBaFile({filepath:$scope.filepath},function(result){
                        $scope.loadFiles($scope.opsColoader.id);
                },function(reject){
                    layer.msg($translate.instant('global.delete_fail'));
                },function(error){
                    console.log(error);
                });
            }
        }


        var editIndex = 0;
        $scope.opsColoader = {};
        var objCopy = {};

        $scope.edit = function (obj) {
            if (!obj){
                $scope.opsColoader = {};
                if (!$scope.opsColoader.contactList){
                    $scope.opsColoader.contactList = [];
                    $scope.opsColoader.contactList.push({});
                }
                objCopy = angular.copy($scope.opsColoader);
                openEditPop();
            }
            else {
                $scope.loadFiles(obj.id);
                OpsColoader.get({id: obj.id}, function(result) {
                    $scope.opsColoader = result;
                    if (!$scope.opsColoader.contactList){
                        $scope.opsColoader.contactList = [];
                        $scope.opsColoader.contactList.push({});
                    }
                    objCopy = angular.copy($scope.opsColoader);
                    openEditPop();
                });
            }
        }

        var openEditPop = function() {
            var title = $translate.instant('autopsApp.opsColoader.home.createLabel');
            if ($scope.opsColoader.companyName){
                title = $scope.opsColoader.companyName;
            }
            editIndex = layer.open({
                type: 1,
                area: ['1100px', '580px'],
                maxmin: true,
                title: title,
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: $("#clientEdit"),
                btn: [$translate.instant('global.save')],
                yes: function(index, layero) {
                    if (angular.equals($scope.opsColoader, objCopy)){
                        layer.close(index);
                        return;
                    }
                    if ($scope.isSaving){
                        return;
                    }
                    saveTrucker();
                },
               end: function() {
                    $scope.opsColoader = {};
                }
            });
        }

        $scope.upperCode = function(client) {
            client.code = angular.uppercase(client.code);
        };

        var onSaveSuccess = function (result) {
            layer.msg($translate.instant('global.save_success'));
            $scope.isSaving = false;
            $scope.loadAll();
            layer.close(editIndex);
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
            if (result.status === 409){
                layer.msg($translate.instant('autopsApp.opsColoader.email_inuse'));
            }
            else {
                layer.msg($translate.instant('global.save_fail'));
            }
        };

        var saveTrucker = function () {
            if($rootScope.ISDEMO && $rootScope.HAVENOAUTH('ROLE_BOSS')){
                layer.msg($translate.instant('global.demoAlert'));
                return;
            }
            if (!$scope.opsColoader.code || !$scope.opsColoader.companyName ||!$scope.opsColoader.attn || !$scope.opsColoader.address ){
                layer.confirm($translate.instant('autopsApp.opsColoader.nullAlert'), {
                    title: $translate.instant('autopsApp.opsColoader.notFull'),
                    btn: [$translate.instant('global.confirm')]
                }, function(index) {
                    layer.close(index);
                });
                return;
            }
            if ($scope.opsColoader.email){
                var myreg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
                $scope.opsColoader.email = $scope.opsColoader.email.replace(/\s+/g, "");
                if(!myreg.test($scope.opsColoader.email)){
                    var alertContent = $scope.opsColoader.email + ": " + $translate.instant('global.messages.validate.email.invalid');
                    layer.msg(alertContent);
                    return;
                }
             }
            if ($scope.opsColoader.cc){
                var ccArray = $scope.opsColoader.cc.split(";");
                var myreg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
                for (var i = 0; i< ccArray.length; i++){
                     ccArray[i] = ccArray[i].replace(/\s+/g,"");
                     if(!myreg.test(ccArray[i]) && ccArray[i] != ""){
                         var alertContent = ccArray[i] + ": " + $translate.instant('global.messages.validate.email.invalid');
                         layer.msg(alertContent);
                         return;
                     }
                 }
            }
            for (var i = 0; i < $scope.opsColoader.contactList.length; i++) {
                if ($scope.opsColoader.contactList[i].email){
                    var myreg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
                    $scope.opsColoader.contactList[i].email = $scope.opsColoader.contactList[i].email.replace(/\s+/g, "");
                    if (!myreg.test($scope.opsColoader.contactList[i].email)) {
                        var alertContent = $scope.opsColoader.contactList[i].email + ": " + $translate.instant('global.messages.validate.email.invalid');
                        layer.msg(alertContent);
                        return;
                    }

                }
            }
            $scope.isSaving = true;
            if ($scope.opsColoader.id != null) {
                OpsColoader.update($scope.opsColoader, onSaveSuccess, onSaveError);
            } else {
                OpsColoader.save($scope.opsColoader, onSaveSuccess, onSaveError);
            }
        };

        $scope.errorList = [];
        $scope.uploadColoaderFile = function(){
            $("#coloaderDataFile").trigger('click');
        }

        $scope.addContact = function() {
            $scope.opsColoader.contactList.push({});
        };

        $scope.delContact = function(contact) {
            if (contact.id != null) {
                OpsShipper.deleteContact({
                    id: contact.id
                });
            };
            var index = $scope.opsColoader.contactList.indexOf(contact);
            if (index > -1) {
                $scope.opsColoader.contactList.splice(index, 1);
            };
        };


        $scope.setAudited = function(audied) {
            if ($rootScope.HAVENOAUTH('ROLE_DEPARTMENT')) {
                layer.msg($translate.instant('global.forbiddenTeam'));
                $scope.opsColoader.audited = !audied;
                return;
            }
            if(audied){
                $scope.opsColoader.auditor = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            }
            else {
                $scope.opsColoader.auditor = null;
            }
        }


    }
})();
