(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsWarehouseDialogController', WmsWarehouseDialogController);

    WmsWarehouseDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'WmsWarehouse'];

    function WmsWarehouseDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, WmsWarehouse) {
        var vm = this;

        vm.wmsWarehouse = entity;
        vm.clear = clear;
        vm.save = save;
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
        }

        function onSaveSuccess (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
