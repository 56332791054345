(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('ManageEnterprise', ManageEnterprise);

    ManageEnterprise.$inject = ['$resource','DateUtils'];

    function ManageEnterprise ($resource,DateUtils) {
        var resourceUrl =  'api/manage-enterprises/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { 
            	method:'PUT',
            	transformRequest: function (data) {
                    data = angular.toJson(data);
                    return data;
                },
            	transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'save':{
            	method:'POST',
            	transformRequest: function (data) {
                    data = angular.toJson(data);
                    return data;
                },
            	transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }	
            },            
            'clientGet':{
                method:'GET',
                url:'api/manage-enterprises/clientGet'
            },
            'loadManageEnterpiseFiles':{
            	method:'GET',
            	url:'api/loadManageEnterpriseFiles',
            	isArray:true
            },
            'getGoodBySku':{
                method:'GET',
                url:'api/manage-enterprises/getGoodBySku',
                isArray:true
            },
            'searchWmsGoods':{
                method:'GET',
                url:'api/manage-enterprises/searchWmsGoods',
                isArray:true
            },
            'getAll':{
                method:'GET',
                url:'api/manage-enterprises/getAll',
                isArray:true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAllGoods':{
                method:'GET',
                url:'api/manage-enterprises/getAllGoods',
                isArray:true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getPubGoods':{
                method:'GET',
                url:'api/manage-enterprises/getPubGoods',
                isArray:true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
