(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ShareClientDetailController', ShareClientDetailController);

    ShareClientDetailController.$inject = ['$timeout', '$rootScope', '$scope', '$uibModalInstance', '$translate', '$uibModal', 
    'WmsCargo', 'uiGridConstants', 'entity', 'ExpressParcel', 'uiGridGroupingConstants'];

    function ShareClientDetailController ($timeout, $rootScope, $scope, $uibModalInstance, $translate, $uibModal,
        WmsCargo, uiGridConstants, entity, ExpressParcel, uiGridGroupingConstants) {
        var vm = this;

        vm.clear = clear;
        vm.accountBind = entity;

        $scope.isSaving = false;
        vm.whCodes = [];
        $timeout(function (){
            inventoryCompare();
            if(vm.accountBind.type == 'logiwa'){
                vm.accountBind.payeeCode = "596;576;578";
            }            
            vm.whCodes = vm.accountBind.payeeCode.split(';');
        });
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }        

        function resize() {
            var tabHeight = $scope.gridOptions.data.length * 30 + 190;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight - 30;
            }
            if(tabHeight < 300){
                tabHeight = 300;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }
        function initData(resultList) {
            angular.forEach(resultList, function(data){
                data[data.platformWhCode] = data.platformSellable;
            })   
            $scope.gridOptions.data = resultList;
            angular.forEach(vm.whCodes, function(whCode){
                $scope.gridOptions.columnDefs.push({
                    field: whCode, 
                    minWidth:80,
                    type:'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.accountBind.plWh') + "(" + whCode + ")"
                })
            })
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
            resize();
            layer.closeAll();
        }
        function inventoryCompare () {
            layer.msg($translate.instant('autopsApp.accountBind.synInventory') + "...", {
              shade: 0.3,
              time: 20000000
            });

            ExpressParcel.inventoryCompare(vm.accountBind, function(resultList){
                initData(resultList);                
            }, function (){
                layer.closeAll();  
            });
        }

        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: false,      

            enableGridMenu : true,//表格 菜单  
            showGridFooter: false,//表格的footer  
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤            

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: $translate.instant('autopsApp.accountBind.synInventory') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],         
            minimumColumnSize: 70,
            columnDefs: [
                { field: 'cargoName', 
                    displayName: "商品名称", 
                    pinnedLeft:true,
                    aggregationType: uiGridConstants.aggregationTypes.count},
                { field: 'localSku', 
                    displayName: $translate.instant('autopsApp.skuBind.sku')},
                { field: 'localSellable', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    type:'number',
                    aggregationType: uiGridConstants.aggregationTypes.sum,  
                    displayName: $translate.instant('autopsApp.accountBind.localInventory')},
                { field: 'platformSku', 
                    displayName: $translate.instant('autopsApp.skuBind.bindSku')}
            ],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
            }
        }


    }
})();
