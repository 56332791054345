(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BookingTemplateDeleteController', BookingTemplateDeleteController);

    BookingTemplateDeleteController.$inject = ['$rootScope', '$scope', '$uibModalInstance', 'entity', 'BookingTemplate'];
    
	function BookingTemplateDeleteController($rootScope, $scope, $uibModalInstance, entity, BookingTemplate) {

        $scope.bookingTemplate = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        setTimeout(function () {   
            if (!$rootScope.id){
                $uibModalInstance.dismiss('cancel');
            }
        }, 500);  
        $scope.confirmDelete = function (id) {
            if (!id){
                
            }
            BookingTemplate.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }
})();
