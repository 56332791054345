(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('profit', {
                parent: 'stats',
                url: '/profit',
                data: {
                    authorities: ['REPORT_PROFIT_STATS'],
                    pageTitle: 'global.menu.admin.profitStats'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/stats/profit/profit.html',
                        controller: 'ProfitController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('stats');
                        $translatePartialLoader.addPart('guide');
                        return $translate.refresh();
                    }]
                }
            }).state('comStats', {
                parent: 'stats',
                url: '/comStats',
                data: {
                    authorities: ['REPORT_PROFIT_STATS'],
                    pageTitle: 'global.menu.admin.profitStats'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/stats/profit/comStats.html',
                        controller: 'ComStatsController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('stats');
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('expressParcel');
                        $translatePartialLoader.addPart('asyncTask');
                        return $translate.refresh();
                    }]
                }
            }).state('concise-stats', {
                parent: 'stats',
                url: '/concise-stats',
                data: {
                    authorities: ['REPORT_PROFIT_STATS'],
                    pageTitle: 'global.menu.admin.concise-stats'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/stats/profit/concise-stats.html',
                        controller: 'ConciseStatsController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('stats');
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('expressParcel');
                        $translatePartialLoader.addPart('asyncTask');
                        return $translate.refresh();
                    }]
                }
            }).state('graphical-analysis', {
                parent: 'stats',
                url: '/graphical-analysis',
                data: {
                    authorities: ['REPORT_PROFIT_STATS'],
                    pageTitle: 'global.menu.admin.graphical-analysis'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/stats/profit/chart.html',
                        controller: 'ChartController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('stats');
                        $translatePartialLoader.addPart('guide');
                        return $translate.refresh();
                    }]
                }
            }).state('shipment-aging', {
                parent: 'stats',
                url: '/shipment-aging',
                data: {
                    authorities: ['REPORT_PROFIT_STATS'],
                    pageTitle: 'global.menu.admin.shipment-aging'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/stats/profit/shipment-aging.html',
                        controller: 'ShipmentAgingController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('stats');
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('asyncTask');
                        return $translate.refresh();
                    }]
                }
            })
    }
})();
