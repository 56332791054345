(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GuideDocController', GuideDocController);

    GuideDocController.$inject = ['$scope', '$state', 'GuideDoc', 'GuideDocSearch'];
    
    function GuideDocController($scope, $state, GuideDoc, GuideDocSearch) {

        $scope.guideDocs = [];
        $scope.loadAll = function() {
            GuideDoc.query(function(result) {
               $scope.guideDocs = result;
            });
        };
        $scope.loadAll();


        $scope.search = function () {
            GuideDocSearch.query({query: $scope.searchQuery}, function(result) {
                $scope.guideDocs = result;
            }, function(response) {
                if(response.status === 404) {
                    $scope.loadAll();
                }
            });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.guideDoc = {
                guideId: null,
                createdByName: null,
                docName: null,
                docNum: null,
                createdTime: null,
                action: null,
                actRemarks: null,
                expressNum: null,
                expressCompany: null,
                id: null
            };
        };
    }
})();
