(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('BookingCtnsSearch', BookingCtnsSearch);

    BookingCtnsSearch.$inject = ['$resource'];
    
    function BookingCtnsSearch($resource) {
        var resourceUrl =  'api/_search/bookingCtnss';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
