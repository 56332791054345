(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('SplitBlController', SplitBlController);

    SplitBlController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$translate', '$timeout', 'entity', 'Guide'];
    
    function SplitBlController($scope, $rootScope, $uibModalInstance, $translate, $timeout, entity, Guide) {

        $scope.splitGuide = entity;       
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $timeout(function (){
            if ($scope.splitGuide.hblNum) {
                $scope.splitGuide.hblNum = $scope.splitGuide.hblNum + "-A";
            }
            if ($scope.splitGuide.shipmentType == "FCL"){
                $scope.splitGuide.shipmentType = "LCL";
            }
        })


        $scope.save = function() {
            $scope.isSaving = true;
            Guide.splitGuide($scope.splitGuide, onSplitSuccess, onSplitError);
        }
        var onSplitSuccess = function(result) {
            $scope.isSaving = false;
            $uibModalInstance.close(result);
            layer.msg($translate.instant('autopsApp.booking.home.split_success'));
        };

        var onSplitError = function(result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('autopsApp.booking.home.split_failed'));
        };

        $scope.onShipperSelected = function(data, chooseTarget) {
            $scope.splitGuide.booking.shipperBl = data.blShow;
        }

}
})();
