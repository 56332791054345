(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ClientAuthoritiesController', ClientAuthoritiesController);

    ClientAuthoritiesController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$timeout', '$translate',
    'entity', 'User', 'OpsCompany', 'Clerks'];

    function ClientAuthoritiesController($scope, $rootScope, $uibModalInstance, $timeout, $translate,
        entity, User, OpsCompany, Clerks) {

        $scope.clerks = [];
        var zTreeObj;
        var userSetting = {
            check : {
                enable : true
            },
            callback: {
                onCheck: userOnCheck
            }
        };
        var authsSet;
        var usersAuthsDTO = {};
        usersAuthsDTO.users = [];
        usersAuthsDTO.authoritys = [];
        var userAuths = [];
        $scope.radioMode = true;

        $scope.authorities = null;
        $scope.getAuthStrs = function() {
            if($scope.authorities){
                return;
            }
            User.getAuthStrs(function(result){
                $scope.authorities = result;
                initAuthorities();
            })
        }


        var initAuthorities = function() {
            angular.forEach($scope.authorities, function(data){
                if(!data.value){
                    data.value = $translate.instant('user-management.' + data.key);
                }
            })
        }
        $scope.radioModeChange = function() {
            zTreeObj.checkAllNodes(false);
        };
        function userOnCheck(event, treeId, treeNode) {
            if($scope.radioMode){
                var checkCopy = treeNode.checked;
                zTreeObj.checkAllNodes(false);
                if(checkCopy){
                    zTreeObj.checkNode(treeNode, true, true);
                }
            }

            var checkedUsers = zTreeObj.getCheckedNodes(true);
            usersAuthsDTO.users = [];
            angular.forEach(checkedUsers, function(user){
                if(user.id > 0){
                    usersAuthsDTO.users.push(user);
                }
            })
            if(usersAuthsDTO.users.length == 1){
                User.getAuths({id:usersAuthsDTO.users[0].id}, function(result){
                    userAuths = result;
                    initAuths();
                })
            }
            else {
                userAuths = [];
                initAuths();
            }
        };

        function authOnCheck(event, treeId, treeNode) {
            authOnCheckDo(event, treeId, treeNode, false);
        };
        function authOnCheckDo(event, treeId, treeNode, isAuthStr) {
            if(usersAuthsDTO.users.length == 0){
                layer.msg($translate.instant('user-management.chooseClerksAlert'));
                return;
            }
            usersAuthsDTO.isAuthStr = isAuthStr;
            usersAuthsDTO.isAdd = treeNode.checked;
            usersAuthsDTO.authoritys = [];
            if(authNodes[treeNode.id]){
                angular.forEach(authNodes[treeNode.id][0].children, function(data){
                    usersAuthsDTO.authoritys.push(data.id);
                })
            }
            else {
                usersAuthsDTO.authoritys.push(treeNode.id);
            }
            if(usersAuthsDTO.users.length > 1){
                layer.confirm($translate.instant('user-management.multiUpdateAlert', {param: usersAuthsDTO.users.length}),
                {   icon: 3,
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                    }, function(index) {
                        updateUsersAuths();
                        layer.close(index);
                    }, function() {
                });
            }
            else {
                updateUsersAuths();
            }
        };
        function updateUsersAuths() {
            User.updateUsersAuths(usersAuthsDTO, function (result) {
                layer.msg($translate.instant('global.messages.submitted'));
            }, function (result) {
                if(result.status == 403){
                    $rootScope.OPS_ALERT($translate.instant('user-management.externalSalesAlert'));
                }
                else {
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                }                
            })
        }

        // zTree 的数据属性，深入使用请参考 API 文档（zTreeNode 节点数据详解）
        var userNodes = [];
        function getNodes(clerks) {
            userNodes = [];
            var branchs = new Set();
            angular.forEach(clerks, function(data){
                branchs.add(data.branch);
            })
            branchs.forEach(function(branch){
                var newBranch = {
                    name: branch,
                    open: true,
                    id: -1,
                    children:[]
                };
                angular.forEach(clerks, function(clerk){
                    if(clerk.branch == branch){
                        var newClert = {
                            name:clerk.name,
                            id: clerk.id
                        };
                        newBranch.children.push(newClert);
                    }
                })
                if(newBranch.children.length > 0){
                    userNodes.push(newBranch);
                }
            })

        };

        $timeout(function (){
            if(entity){
                if(entity.opsVenderId){
                    $scope.getOpsVenderClerks(entity.opsVenderId);
                }
                else if(entity.id){
                    $scope.getOpsVenderClerks(entity.id);
                }                
            }
            initAuths();
        });
        $scope.getOpsVenderClerks = function(venderId) {
            User.getOpsVenderClerks({
                opsVenderId: venderId
            }, function(results){
                addNewUsers(results);
            });
        }

        function addNewUsers(newUsers) {
            var userIdSet = new Set();
            angular.forEach($scope.clerks, function(user){
                userIdSet.add(user.id);
            })
            angular.forEach(newUsers, function(user){
                if(!userIdSet.has(user.id)){
                    $scope.clerks.push(user);
                }
            })
            getNodes($scope.clerks);
            zTreeObj = $.fn.zTree.init($("#userTree"), userSetting, userNodes);
            fuzzySearch('userTree','#field_search', null, true);
        }

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        var authSetting = {
            check : {
                enable : true
            },
            callback: {
                onCheck: authOnCheck
            }
        };


        var hasAuth = function(authority) {
            if(!userAuths){
                return false;
            }
            return userAuths.indexOf(authority) !== -1;
        };
        var authNodes = {};
        function initAuths() {
            /*
            *级别
            */
            authNodes.jobLevel = [{name: $translate.instant('user-management.jobLevel'), open: true, id: "jobLevel", children:[]}];
            authNodes.jobLevel[0].children.push({name:$translate.instant('user-management.subSys.SS_ADMIN'), id: 'SS_ADMIN', checked:hasAuth('SS_ADMIN')});
            $.fn.zTree.init($("#jobLevel"), authSetting, authNodes.jobLevel);

            //功能授权
            authNodes.auth = [{name: $translate.instant('user-management.auth'), open: true, id: "auth", children:[]}];
            authsSet = ["SS_FMS_VIEW_CHANNEL", "SS_WMS_VIEW_CHANNEL", "SS_VIEW_MBL", "SS_VIEW_CTNR"];
            angular.forEach(authsSet, function (auth) {
                authNodes.auth[0].children.push({name:$translate.instant('user-management.subSys.' + auth), id: auth, checked: hasAuth(auth)});
            })
            $.fn.zTree.init($("#authTree"), authSetting, authNodes.auth);

            //禁止类授权
            authNodes.fbAuth = [{name: $translate.instant('user-management.fbAuth'), open: true, id: "fbAuth", children:[]}];
            authsSet = ["SS_FB_SHIPPER_ROLE", "SS_FB_CUSTOMER_ROLE", "SS_FB_CHARGES"];
            angular.forEach(authsSet, function (auth) {
                authNodes.fbAuth[0].children.push({name:$translate.instant('user-management.subSys.' + auth), id: auth, checked: hasAuth(auth)});
            })
            $.fn.zTree.init($("#fbTree"), authSetting, authNodes.fbAuth);


            /*
            *导航栏
            */
            // authNodes.navMenu = [{name: $translate.instant('user-management.navMenu'), 
            //     open: true, id: "navMenu", children:[]}];
            // authsSet = ["SS_FB_RATE_INQ", "SS_FB_FAST_ORDER", "SS_FB_ORDER_MGMT", "SS_FB_OWMS",
            //  "SS_FB_REPORT", "SS_FB_DATUM"];
            // angular.forEach(authsSet, function (auth) {
            //     authNodes.navMenu[0].children.push({
            //         name:$translate.instant('user-management.subSys.' + auth), 
            //         id: auth, checked: hasAuth(auth)});
            // })
            // $.fn.zTree.init($("#navMenu"), authSetting, authNodes.navMenu);

            /*
            *运费查询
            */
            var authTitle;
            authTitle = $translate.instant('global.menu.rateFinder') + "(" + $translate.instant('entity.action.disable') + ")";
            authNodes.firstLevelMenu = [{name: authTitle, open: true, id: "firstLevelMenu", children:[]}];
            authsSet = ["SS_FB_TRIAL_FBA", "SS_FB_TRIAL_PC", "SS_FB_TRIAL_FMS"];
            angular.forEach(authsSet, function (auth) {
                authNodes.firstLevelMenu[0].children.push({
                    name:$translate.instant('user-management.subSys.' + auth), 
                    id: auth, checked: hasAuth(auth)});
            })
            $.fn.zTree.init($("#firstLevelMenu"), authSetting, authNodes.firstLevelMenu);

            /*
            *快速下单
            */
            authTitle = $translate.instant('global.menu.fastOrder') + "(" + $translate.instant('entity.action.disable') + ")";
            authNodes.fastOrder = [{name: authTitle, open: true, id: "fastOrder", children:[]}];
            authsSet = ["SS_FB_FAST_FBA", "SS_FB_GUIDE_FBA", "SS_FB_GUIDE_PC", "SS_FB_GUIDE_KD"];
            angular.forEach(authsSet, function (auth) {
                authNodes.fastOrder[0].children.push({
                    name:$translate.instant('user-management.subSys.' + auth), 
                    id: auth, checked: hasAuth(auth)});
            })
            $.fn.zTree.init($("#fastOrder"), authSetting, authNodes.fastOrder);

            /*
            *订单管理
            */
            authTitle = $translate.instant('global.menu.entities.logOrders') + "(" + $translate.instant('entity.action.disable') + ")";
            authNodes.logOrders = [{name: authTitle, open: true, id: "logOrders", children:[]}];
            authsSet = ["SS_FB_LGOD_ALL", "SS_FB_LGOD_FMS", "SS_FB_LGOD_FBA", "SS_FB_LGOD_WMS", "SS_FB_FIFO", 
                "SS_FB_EMS_ALL", "SS_FB_EMS_KD", "SS_FB_EMS_EP", "SS_FB_EMS_PC", "SS_FB_EMS_RT", "SS_FB_TK"];
            angular.forEach(authsSet, function (auth) {
                authNodes.logOrders[0].children.push({
                    name:$translate.instant('user-management.subSys.' + auth), 
                    id: auth, checked: hasAuth(auth)});
            })
            $.fn.zTree.init($("#logOrders"), authSetting, authNodes.logOrders);

            /*
            *仓库 海外仓
            */
            if($rootScope.HAVEAUTH("WMS_OVERSEAS")){
                authTitle = $translate.instant('global.menu.entities.owms') + "(" + $translate.instant('entity.action.disable') + ")";
            }
            else {
                authTitle = $translate.instant('global.menu.entities.wms') + "(" + $translate.instant('entity.action.disable') + ")";
            }
            authNodes.wms = [{name: authTitle, open: true, id: "wms", children:[]}];
            authsSet = ["SS_FB_FEE_SKU", "SS_FB_WMS_SKUBIND", "SS_FB_WMS_ADDRESS", "SS_FB_WMS_INVENTORY", 
                "SS_FB_WMS_CLAIM", "SS_FB_WMS_IN", "SS_FB_WMS_OT", "SS_FB_WMS_TD", "SS_FB_WMS_LOG"];
            angular.forEach(authsSet, function (auth) {
                authNodes.wms[0].children.push({
                    name:$translate.instant('user-management.subSys.' + auth), 
                    id: auth, checked: hasAuth(auth)});
            })
            $.fn.zTree.init($("#wms"), authSetting, authNodes.wms);

            /*
            *报表
            */
            authTitle = $translate.instant('global.menu.entities.financial_statements') + "(" + $translate.instant('entity.action.disable') + ")";
            authNodes.report = [{name: authTitle, 
                open: true, id: "report", children:[]}];
            authsSet = ["SS_FB_RP_CHECK", "SS_FB_RP_STATS", "SS_FB_RP_SR"];
            angular.forEach(authsSet, function (auth) {
                authNodes.report[0].children.push({
                    name:$translate.instant('user-management.subSys.' + auth), 
                    id: auth, checked: hasAuth(auth)});
            })
            $.fn.zTree.init($("#report"), authSetting, authNodes.report);

            /*
            *资料管理
            */
            authTitle = $translate.instant('global.menu.datumManage') + "(" + $translate.instant('entity.action.disable') + ")";
            authNodes.datumManage = [{name: authTitle, 
                open: true, id: "datumManage", children:[]}];
            authsSet = ["SS_FB_DATUM_ADDR", "SS_FB_DATUM_BTA", "SS_FB_DATUM_EDI", "SS_FB_DATUM_FACT", "SS_FB_DATUM_BL", 
                "SS_FB_DATUM_INV", "SS_FB_DATUM_BT", "SS_FB_DATUM_CT", "SS_FB_DATUM_APR", "SS_FB_DATUM_APRB", "SS_FB_DATUM_API"];
            angular.forEach(authsSet, function (auth) {
                authNodes.datumManage[0].children.push({
                    name:$translate.instant('user-management.subSys.' + auth), 
                    id: auth, checked: hasAuth(auth)});
            })
            $.fn.zTree.init($("#datumManage"), authSetting, authNodes.datumManage);
        };
        $scope.copyType = null;
        $scope.copyName = null;
        $scope.copyAuthPh = null;
        $scope.setCopyType = function(type) {
            $scope.copyType = type;
            if(type == "choosed"){
                $scope.copyAuthPh = $translate.instant('user-management.copyTypeClient.overwrite') + " > " 
                + $translate.instant('user-management.choosedAccounts');
            }
            else if(type == "all"){
                $scope.copyAuthPh = $translate.instant('user-management.copyTypeClient.overwrite') + " > " 
                + $translate.instant('user-management.allSubSysAccounts');
            }
            else if(type == "add_choosed"){
                $scope.copyAuthPh = $translate.instant('user-management.copyTypeClient.add') + " > " 
                + $translate.instant('user-management.choosedAccounts');
            }
            else if(type == "add_all"){
                $scope.copyAuthPh = $translate.instant('user-management.copyTypeClient.add') + " > " 
                + $translate.instant('user-management.allSubSysAccounts');
            }
        };

        $scope.onCopyFmChoosed = function(user) {
            if($scope.copyType == "choosed" || $scope.copyType == "add_choosed"){
                copyAuth(user);                
            }
            else{
                updateAllSubSysUser(user);
            }
        }
        
        function copyAuth(user) {
            if(usersAuthsDTO.users.length == 0){
                layer.msg($translate.instant('user-management.chooseClerksAlert'));
                return;
            }
            User.copyAuth({
                oldId: user.id,
                newId: usersAuthsDTO.users[0].id,
                isAdd: $scope.copyType == "add_choosed"
            }, function (result) {
                userAuths = result;
                initAuths();
                $scope.copyName = null;
                layer.msg($translate.instant('global.messages.submitted'));
            }, function (result) {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            })
        };

        function updateAllSubSysUser(user) {
            if ($rootScope.HAVENOAUTH('ROLE_BOSS')) {
                $rootScope.FORBIDDEN_BY_ALERT('ROLE_BOSS');
                return;
            }
            layer.confirm($translate.instant('user-management.updateAllSubSysUserTips'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                layer.load(1, { shade: 0.3 });
                User.updateAllSubSysUser({
                    isAdd: $scope.copyType == "add_all",
                    oldId: user.id,
                }, function (result) {
                    layer.closeAll();
                    $scope.copyName = null;
                    layer.msg($translate.instant('global.messages.submitted'));
                }, function (result) {
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                })
            }, function() {});
        };

}
})();
