(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('agent', {
                parent: 'ops',
                url: '/agents',
                data: {
                    authorities: ['ROLE_AGENT'],
                    pageTitle: 'autopsApp.guide.home.ititle'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/agent/agent.html',
                        controller: 'AgentController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
