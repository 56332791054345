(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsCostDetailController', OpsCostDetailController);

    OpsCostDetailController.$inject = ['$scope', '$rootScope', '$timeout', '$translate', '$uibModal', '$state', '$uibModalInstance', 
    'entity', 'OpsCost', 'OpsTemplate', 'JhiSignature', 'DataUtils', 'CrmQuotation'];

    function OpsCostDetailController($scope, $rootScope, $timeout, $translate, $uibModal, $state, $uibModalInstance, 
        entity, OpsCost, OpsTemplate, JhiSignature, DataUtils, CrmQuotation) {
        var vm = this;
        vm.opsCost = {};

        var booking = entity;
        vm.isNew = entity.isNew;
        vm.crmClient = entity.crmClient;

        vm.isCostFinder = entity.isCostFinder;
        var isEn = entity.isEn;
        var fromCrmQuotation = entity.fromCrmQuotation;

        vm.crmQuotation = crmQuotation;
        vm.onPolChoosed = onPolChoosed;
        vm.onDestChoosed = onDestChoosed;
        vm.delOneCost = delOneCost;

        vm.activeTab = 0;
        vm.rateType = vm.isCostFinder?27:26;
        vm.params = {};
        
        vm.clear = clear;
        vm.findRate = findRate;
        vm.setRateType = setRateType;
        vm.bookSpace = bookSpace;
        vm.choosePrice = choosePrice;

        vm.ports = {};
        vm.opsCosts = [];
        vm.getLocalCharges = getLocalCharges;
        vm.remarks=null;
        vm.getRemarks = getRemarks;

        function bookSpace (opsCost) {
            var entity = {};
            entity.opsCost = angular.copy(opsCost);
            entity.gw = vm.params.gw;
            entity.vol = vm.params.vol;
            $uibModal.open({
                templateUrl: 'app/entities/bookingCtns/bookingCtns-detail.html',
                controller: 'BookingCtnsDetailController',
                size: 'xl',
                resolve: {
                    entity: function () {
                        return entity;
                    }
                }
            }).result.then(function(result) {
            }, function() {
            })
        }

        function getRemarks(opsCost) {
            if(!opsCost.remarks || !opsCost.companyId){
                return;
            }
            JhiSignature.findRateRemarks({
                companyId: opsCost.companyId,
                code: opsCost.remarks
            }, function(result){
                if(result){
                    vm.remarks=result.signature;
                }  
                else {
                    vm.remarks = opsCost.remarks;
                    // layer.msg($translate.instant('global.noRecord'));   
                }              
            }, function(result){
                vm.remarks = opsCost.remarks;
            })
        }
        function getLocalCharges(opsCost, code) {
            if(!code){
                return;
            }
            OpsTemplate.getLocalCharges({
                code: code,
                companyId: opsCost.companyId,
                isCostFinder: vm.isCostFinder
            }, function(result){
                vm.remarks = null;
                if(!result || !result.rates || result.rates.length == 0){
                    layer.msg($translate.instant('global.noRecord'));  
                    return;
                }                
                $uibModal.open({
                    templateUrl: 'app/entities/opsTemplate/opsTemplate-dialog.html',
                    controller: 'OpsTemplateDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'md',
                    resolve: {
                        entity: [function() {
                            return result;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('opsTemplate');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function(results) {}, function() {});
            })
        }

        var rateTypeCopy;
        function initSearch () {
            if(booking.shipmentType == "FCL"){
                vm.rateType = vm.isCostFinder?1:16;
                vm.activeTab = 1;
            }
            else if (booking.shipmentType == "AIR"){
                vm.rateType = vm.isCostFinder?31:30;
                vm.activeTab = 2;                
            }
            else if (booking.shipmentType == "LCL"){
                vm.rateType = vm.isCostFinder?33:32;
                vm.activeTab = 3;
            }
            else if (booking.shipmentType == "EXPRESS" || booking.shipmentType == "FBAAIR" || booking.shipmentType == "FBASEA"){
                vm.rateType = vm.isCostFinder?27:26;
                vm.activeTab = 0;         
            }
            vm.opsCost.pol = booking.polName?booking.polName:"";
            vm.opsCost.dest = booking.destName?booking.destName:"";
            vm.params.carrier = booking.carrier;
            vm.params.vol = booking.vol;
            vm.params.gw = booking.gw;                

            if (booking.shipmentType == "EXPRESS" || booking.shipmentType == "FBAAIR" || booking.shipmentType == "FBASEA"){          
                if(booking.deliverytoId){
                    vm.opsCost.dest = booking.deliverytoId?booking.deliverytoId:"";
                }
                else if(booking.deliveryTo){
                    vm.opsCost.dest = booking.deliveryTo?booking.deliveryTo:"";
                }
                else {
                    vm.opsCost.dest = booking.dest?booking.dest:"";
                }
            }
            rateTypeCopy = vm.rateType;     
            if (booking.shipmentType == "EXPRESS"){
                rateTypeCopy = vm.isCostFinder?25:24;
            }
            else if (booking.shipmentType == "FBAAIR" || booking.shipmentType == "LOCALKG"){
                rateTypeCopy = vm.isCostFinder?29:28; 
            }       
        }

        function setRateType (type) {
            if(vm.isCostFinder){
                if(type == 16){
                    type = 1;
                }
                else if(type == 30){
                    type = 31;
                }
                else if(type == 32){
                    type = 33;
                }
                else if(type == 26){
                    type = 27;
                }
            }

            if (type != vm.rateType){
                vm.remarks=null;
                vm.rateType = type;
                vm.params = {};
                vm.opsCosts = [];
                getPorts();
            }            
        }

        $timeout(function (){
            initSearch();
            getPorts();
        });

        function getPorts () {
            OpsCost.getAllPorts({
                type: vm.rateType,
                companyId: $rootScope.account.companyId
            }, function(result){
                vm.ports = result;
            })
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
       
        vm.params = {};
        function findRate () {
            if(vm.opsCost.pol && !vm.params.pols){
                vm.params.pols = [];
                vm.params.pols.push(vm.opsCost.pol);
            }
            if(vm.opsCost.dest && !vm.params.dests){
                vm.params.dests = [];
                vm.params.dests.push(vm.opsCost.dest);
            }
            vm.params.productName = vm.opsCost.productName;
            if ((!vm.params.dests || vm.params.dests.length == 0) && !vm.params.productName){
                layer.msg($translate.instant('autopsApp.opsCost.productNameOrPodsRequired'));     
                return;
            }
            vm.params.type = vm.rateType;
            if(vm.crmClient){
                vm.params.venderId = vm.crmClient.id;
            } 
            vm.params.plList=[];
            if(vm.params.gw || vm.params.vol){
                vm.params.plList.push({
                    gw: vm.params.gw,
                    pkgs: 1,
                    vol: vm.params.vol
                });
            }
            
            OpsCost.findRatesCom(vm.params, onGetRatesSuccess, $rootScope.ALERT_ERROR);
        }
        function onGetRatesSuccess (result) {
            vm.opsCosts = result;
            if(!vm.opsCosts || vm.opsCosts.length == 0){
                layer.msg($translate.instant('global.messages.resultNull'));
            }
            $scope.showTtlCol = false;
            angular.forEach(vm.opsCosts, function(data){
                if(data.freight){
                    $scope.showTtlCol = true;
                }
            })
        }       

        function choosePrice (opsCost) {
            $uibModalInstance.close(opsCost);
        }

        var isReverse = true;
        $scope.sortRates = function(fd) {
            isReverse = !isReverse;
            vm.opsCosts.sort(function(a,b){
                if (isReverse){
                    return a[fd]<b[fd]?1:-1;
                }
                else {
                    return a[fd]>b[fd]?1:-1;
                }                   
            });
        }

        function crmQuotation(opsCost) {
            var crmQuotationDTO = {};
            crmQuotationDTO.opsCosts = [];
            if(opsCost){
                crmQuotationDTO.opsCosts.push(opsCost);
            }
            else {
                angular.forEach(vm.opsCosts, function(data){
                    crmQuotationDTO.opsCosts.push(data);
                })
            }
            crmQuotationDTO.gw = vm.params.gw;
            crmQuotationDTO.vol = vm.params.vol;

            crmQuotationDTO.volume = (vm.params.gw? vm.params.gw + "KGS ":"") + (vm.params.vol?vm.params.vol + "CBM":"");
            crmQuotationDTO.opsVender = vm.crmClient;
            crmQuotationDTO.isEn = isEn;

            CrmQuotation.getQuotation(crmQuotationDTO, function(result){
                if(fromCrmQuotation){
                    $uibModalInstance.close(result);
                }
                else {
                    $uibModal.open({
                        templateUrl: 'app/entities/crm-quotation/crm-quotation-dialog.html',
                        controller: 'CrmQuotationDialogController',
                        controllerAs: 'vm',
                        backdrop: false,
                        size: 'xl',
                        resolve: {
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('crmQuotation');
                                $translatePartialLoader.addPart('crmPrice');
                                return $translate.refresh();
                            }],
                            entity: ['CrmQuotation', function(CrmQuotation) {
                                return result;
                            }]
                        }
                    }).result.then(function() {
                    }, function() {
                    });
                }
            });
        }

        $scope.delPol = function(index) {
            vm.params.pols.splice(index, 1);
        }
        $scope.delDest = function(index) {
            vm.params.dests.splice(index, 1);
        }
        var polCopy;
        $scope.polChange = function() {
            polCopy = vm.opsCost.pol;
        }
        var destCopy;
        $scope.destChange = function() {
            destCopy = vm.opsCost.dest;
        }
        
        function onPolChoosed(data) {
            if(!vm.params.pols){
                vm.params.pols = [];
            }
            if(vm.params.pols.indexOf(data.key) == -1){
                vm.params.pols.push(data.key);
            }
            if(polCopy){
                vm.opsCost.pol = polCopy;                
            }
            else {
                vm.opsCost.pol = "";
            }
        }
        function onDestChoosed(data) {
            if(!vm.params.dests){
                vm.params.dests = [];
            }
            if(vm.params.dests.indexOf(data.key) == -1){
                vm.params.dests.push(data.key);
            }
            if(destCopy){
                vm.opsCost.dest = destCopy;            
            }
            else {
                vm.opsCost.dest = "";
            }
        }
        function delOneCost (opsCost) {
            var index = vm.opsCosts.indexOf(opsCost);
            if(index != -1){
                vm.opsCosts.splice(index, 1);
            }            
        }

    }
})();
