(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('opsCrm', {
                parent: 'crm',
                url: '/opsCrm',
                data: {
                    authorities: ['FUN_CLIENT'],
                    pageTitle: 'autopsApp.opsClient.home.crmTitle'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/opsClient/opsCrm.html',
                        controller: 'OpsCrmsController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsClient');
                        $translatePartialLoader.addPart('opsCrm');  
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('opsCrm.quotation', {
                parent: 'opsCrm',
                url: '/quotation',
                data: {
                    authorities: ['FUN_CLIENT'],
                    pageTitle: 'autopsApp.opsClient.detail.quotation'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/opsClient/opsQuotation.html',
                        controller: 'OpsQuotationController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsClient');
                        $translatePartialLoader.addPart('opsCost');                        
                        return $translate.refresh();
                    }],
                    entity: ['$rootScope', 'OpsConcernLane', function($rootScope, OpsConcernLane) {
                        if ($rootScope.id){
                            return OpsConcernLane.get({id : $rootScope.id}, function(result) {
                                return result;
                            })
                        }
                        else {
                            return {
                                type: null,
                                pol: null,
                                pod: null,
                                polId: null,
                                podId: null,
                                id: null
                            };
                        }
                    }]
                }
            });
    }
})();
