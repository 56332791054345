(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('CustomDeclaration', CustomDeclaration);

    CustomDeclaration.$inject = ['$resource', 'DateUtils'];

    function CustomDeclaration ($resource, DateUtils) {
        var resourceUrl =  'api/custom-declarations/:id';

        
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.iEDate = DateUtils.convertLocalDateFromServer(data.iEDate);
                        data.declareDate = DateUtils.convertLocalDateFromServer(data.declareDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                	var param = angular.copy(data);
                	param.iEDate = DateUtils.convertLocalDateToServer(param.iEDate);
                	param.declareDate = DateUtils.convertLocalDateToServer(param.declareDate);
                    return angular.toJson(param);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.iEDate = DateUtils.convertLocalDateFromServer(data.iEDate);
                        data.declareDate = DateUtils.convertLocalDateFromServer(data.declareDate);
                    }
                    return data;
                }
            },
            'setAudited': {
                method: 'PUT',
                url:'api/custom-declarations/setAudited',
                transformRequest: function (data) {
                    var param = angular.copy(data);
                    param.iEDate = DateUtils.convertLocalDateToServer(param.iEDate);
                    param.declareDate = DateUtils.convertLocalDateToServer(param.declareDate);
                    return angular.toJson(param);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.iEDate = DateUtils.convertLocalDateFromServer(data.iEDate);
                        data.declareDate = DateUtils.convertLocalDateFromServer(data.declareDate);
                    }
                    return data;
                }
            },
            'generateWmsIn': {
                method: 'POST',
                url:'api/custom-declarations/generateWmsIn',
                transformRequest: function (data) {
                    var param = angular.copy(data);
                    param.iEDate = DateUtils.convertLocalDateToServer(param.iEDate);
                    param.declareDate = DateUtils.convertLocalDateToServer(param.declareDate);
                    return angular.toJson(param);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.iEDate = DateUtils.convertLocalDateFromServer(data.iEDate);
                        data.declareDate = DateUtils.convertLocalDateFromServer(data.declareDate);
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                url:'api/custom-declarations',
                transformRequest: function (data) {
                	var param = angular.copy(data);
                	param.iEDate = DateUtils.convertLocalDateToServer(param.iEDate);
                	param.declareDate = DateUtils.convertLocalDateToServer(param.declareDate);
                    return angular.toJson(param);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.iEDate = DateUtils.convertLocalDateFromServer(data.iEDate);
                        data.declareDate = DateUtils.convertLocalDateFromServer(data.declareDate);
                    }
                    return data;
                }
            },
            'getAllManualNo': {
                method:'GET',
                url:'api/custom-declarations/getAllManualNo',
                isArray: true
            },     
            'sWindow':{
            	method:'POST',
                url:'api/custom/sWindow',
                transormRequest:function (data) {
                    var customDeclarationDTO = angular.copy(data);
                    customDeclarationDTO.iEDate = DateUtils.convertLocalDateToServer(customDeclarationDTO.iEDate);
                    customDeclarationDTO.declareDate = DateUtils.convertLocalDateToServer(customDeclarationDTO.declareDate);
                    return angular.toJson(param);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.iEDate = DateUtils.convertLocalDateFromServer(data.iEDate);
                        data.declareDate = DateUtils.convertLocalDateFromServer(data.declareDate);
                    }
                    return data;
                }
            }
        });
    }
})();
