
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ExpressPackingDetailController', ExpressPackingDetailController);

    ExpressPackingDetailController.$inject = ['$scope', '$rootScope', '$timeout', '$translate', '$uibModalInstance', '$uibModal', 
        'entity', 'DataUtils', 'ExpressPacking', 'OpsVender', 'ExpressParcel'
    ];

    function ExpressPackingDetailController($scope, $rootScope, $timeout, $translate, $uibModalInstance, $uibModal, 
        entity, DataUtils, ExpressPacking, OpsVender, ExpressParcel) {
        var vm = this;
        vm.expressPacking = entity;
        vm.searchNum = "";
        vm.expressParcels = [];
        vm.unshipedPackings = [];
        vm.datePickerOpenStatus = {};
        vm.wrongNums = [];
        vm.save = save;
        vm.openCalendar = openCalendar;
        vm.getBarcode = getBarcode;
        vm.getPackingByJobNum = getPackingByJobNum;
        vm.getUnshiped = getUnshiped;
        vm.onPackingSelected = onPackingSelected;
        vm.clear = clear;
        vm.createNew = createNew;
        vm.addParcelByJobNum = addParcelByJobNum;
        vm.delFromPacking = delFromPacking;
        vm.updateGwVol = updateGwVol;
        vm.chooseParcel = chooseParcel;
        function clear () {
            $uibModalInstance.close();                     
        }
        function save () {
            vm.isSaving = true;
            ExpressPacking.update(vm.expressPacking, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            layer.msg($translate.instant("global.save_success"));
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
            getUnshiped();     
        });
        function getUnshiped () {
            if(vm.unshipedPackings.length > 0){
                return;
            }
            ExpressPacking.getUnshiped({
                companyId: $rootScope.account.companyId
            }, function(results){
                vm.unshipedPackings = results;
            })
            if(!vm.expressPacking){
                vm.expressPacking = {};
            }
        }

        function getBarcode (fd) {
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-dialog.html',
                controller: 'WmsInDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md'
            }).result.then(function(code) {
                if(fd =="packingJobNum"){
                    vm.expressPacking.jobNum = code;
                    getPackingByJobNum();
                }
                else if (fd =="searchNum"){
                    vm.searchNum = code;
                    addParcelByJobNum();
                }
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }
        function getPackingByJobNum () {
            if(!vm.expressPacking.jobNum){
                return;
            }
            ExpressPacking.getPackingByJobNum({
                companyId: $rootScope.account.companyId,
                jobNum: vm.expressPacking.jobNum
            }, function(result){
                layer.msg($translate.instant("entity.action.loadSuccess"));
                vm.expressPacking = result;
                getExpressParcels();
            }, function(error){
                if(error.status == 404){
                    layer.msg($translate.instant("global.numNotExist"));
                }
                else {
                    layer.msg($translate.instant("global.loadFailed"));
                }
            })
        }
        function onPackingSelected (item) {
            vm.expressPacking = item;
            getExpressParcels();
        }
        
        function getExpressParcels () {
            if(!vm.expressPacking.id){
                return;
            }
            ExpressPacking.getExpressParcels({
                expressPackingId: vm.expressPacking.id
            }, function(results){
                layer.msg($translate.instant("entity.action.loadSuccess"));
                vm.expressParcels = results;
                updateTtl();
            })            
        }

        
        function delFromPacking (expressParcel) {
            if(!vm.expressPacking.id || !expressParcel.id){
                return;
            }
            ExpressPacking.delFromPacking({
                id: expressParcel.id,
                expressPackingId: vm.expressPacking.id
            }, function(result){
                layer.msg($translate.instant("global.delete_success"));
                var idx = vm.expressParcels.indexOf(expressParcel);
                if(idx > -1){
                    vm.expressParcels.splice(idx, 1);
                    updateTtl();
                }
            }, function(error){
                $rootScope.ALERT_ALARM();
                if(error.status == 403){
                    $rootScope.OPS_ALERT($translate.instant("autopsApp.expressPacking.consolidatedAlert"));
                }
                else {
                    $rootScope.OPS_ALERT($translate.instant("global.delete_fail"));
                }
            })
        }

        vm.expressParcel = null;
        vm.updateData = {};

        function chooseParcel (expressParcel) {
            vm.expressParcel = expressParcel;
            vm.updateData = {
                volume: expressParcel.vol,
                weight: expressParcel.gw
            };
        }
        function updateGwVol () {
            if(vm.expressParcel && vm.expressParcel.id && vm.updateData.weight){
                ExpressPacking.updateGwVol({
                    id: vm.expressParcel.id,
                    vol: vm.updateData.volume,
                    gw: vm.updateData.weight
                }, function(result){
                    updateParcel(result);
                    layer.msg($translate.instant("entity.action.loadSuccess"));
                }, function(error){
                    $rootScope.ALERT_ALARM();
                    if(error.status == 404){
                        $rootScope.OPS_ALERT($translate.instant("global.numNotExist"));
                    }
                    else {
                        $rootScope.OPS_ALERT($translate.instant("global.messages.submittedfailed"));
                    }
                })   
            }
        }

        function updateParcel (result) {
            angular.forEach(vm.expressParcels, function(data){
                if(data.id == result.id){
                    angular.copy(result, data);
                }
            })
        }

        function addParcelByJobNum () {
            if(!vm.expressPacking.id || !vm.searchNum){
                return;
            }
            ExpressPacking.addParcelByJobNum({
                companyId: $rootScope.account.companyId,
                jobNum: vm.searchNum,
                pkNum: vm.expressPacking.jobNum,
                expressPackingId: vm.expressPacking.id
            }, function(result){
                vm.expressParcel = result;
                vm.updateData = {};
                layer.msg($translate.instant("entity.action.loadSuccess"));
                vm.expressParcels.splice(0, 0, result);
                updateTtl();
                $timeout(function() {
                    vm.searchNum = "";
                    $("#searchNumField").focus();
                });
            }, function(error){
                $rootScope.ALERT_ALARM();
                if(error.status == 404){
                    $rootScope.OPS_ALERT($translate.instant("global.numNotExist"));
                }
                else if(error.status == 409){
                    $rootScope.OPS_ALERT($translate.instant("autopsApp.expressPacking.packingRepeated"));
                }
                else {
                    $rootScope.OPS_ALERT($translate.instant("global.loadFailed"));
                }
            })            
        }

        function updateTtl () {
            vm.expressPacking.gw = 0; 
            vm.expressPacking.vol = 0; 
            vm.expressPacking.pkgNum = 0; 
            angular.forEach(vm.expressParcels, function(data){
                if(data.gw){
                    vm.expressPacking.gw += data.gw;
                }
                if(data.vol){
                    vm.expressPacking.vol += data.vol;
                }
                if(data.pkgTtl){
                    vm.expressPacking.pkgNum += data.pkgNum;
                }
            })
            vm.expressPacking.gw = DataUtils.round(vm.expressPacking.gw, 2);
            vm.expressPacking.vol = DataUtils.round(vm.expressPacking.vol, 2);
            vm.expressPacking.pkgNum = DataUtils.round(vm.expressPacking.pkgNum, 0);
            if(vm.expressPacking.gw > vm.expressPacking.maxGw){
                $rootScope.ALERT_ALARM();           
                $rootScope.OPS_ALERT($translate.instant("autopsApp.expressPacking.overMax"));
            }
            if(vm.expressPacking.vol > vm.expressPacking.maxVol){
                $rootScope.ALERT_ALARM();
                $rootScope.OPS_ALERT($translate.instant("autopsApp.expressPacking.overMax"));                
            }
        }
        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function createNew(){
            $uibModal.open({
                templateUrl: 'app/entities/express-packing/express-packing-dialog.html',
                controller: 'ExpressPackingDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: function () {
                        return {};
                    }
                }
            }).result.then(function(result) {
                vm.expressPacking = result;
                vm.expressParcels = [];
                updateTtl();
            }, function() {
            });
        }
        $scope._onColoaderSelected = function(data) {
            vm.expressPacking.bookingToId = data.id;
        }

        $scope.printParcelPkLabel = function() {
            ExpressParcel.printParcelPkLabel(vm.expressPacking, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

    }
})();