(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('JhiSignatureDialogController', JhiSignatureDialogController);

    JhiSignatureDialogController.$inject = ['$scope', '$rootScope', '$translate', '$uibModalInstance', 'entity', 'JhiSignature'];
    
    function JhiSignatureDialogController($scope, $rootScope, $translate, $uibModalInstance, entity, JhiSignature) {

        $scope.opsAccounts = entity;

        var onSaveSuccess = function (result) {
            layer.msg($translate.instant('global.messages.submitted'));
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            layer.msg($translate.instant('global.messages.submittedfailed'));
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            JhiSignature.save($scope.opsAccounts, onSaveSuccess, onSaveError);
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.addOne = function () {
            var newAccount = {};
            newAccount.companyId = $rootScope.account.companyId;
            $scope.opsAccounts.push(newAccount);
        };
        $scope.delOne = function (account) {            
            if (account.id != null) {
                JhiSignature.delete({id: account.id});
            };
            var index = $scope.opsAccounts.indexOf(account);
            if (index > -1) {
                $scope.opsAccounts.splice(index, 1);
            };
        };



}
})();
