(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('PrebookingDeleteController',PrebookingDeleteController);

    PrebookingDeleteController.$inject = ['$uibModalInstance', 'entity', 'Prebooking'];

    function PrebookingDeleteController($uibModalInstance, entity, Prebooking) {
        var vm = this;

        vm.prebooking = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Prebooking.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
