(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GuideCtnsController', GuideCtnsController);

    GuideCtnsController.$inject = ['$timeout', '$scope', '$rootScope', '$state', '$translate', 'WinAES', 'GuideCtns', 'GuideCtnsSearch', 'Principal', 'ParseLinks' ];

    function GuideCtnsController($timeout, $scope, $rootScope, $state, $translate, WinAES, GuideCtns, GuideCtnsSearch, Principal, ParseLinks ) {

        if (!$rootScope.account || angular.equals($rootScope.account, {})){
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };
        $scope.currentSearch = false;
        $scope.guideCtnss = [];
        $scope.predicate = 'id';
        $scope.reverse = false;
        $scope.page = 1;
        $scope.itemsPerPage = 20;
        $scope.loadAll = function() {
            $scope.currentSearch = false;
            GuideCtns.query({page: $scope.page-1, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']},function(result,headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.guideCtnss = result;
                angular.forEach($scope.guideCtnss, function(data){
                    data.encodeId = WinAES.encode(data.id.toString());
                })
            });
        };

        $scope.loadAll();

        $scope.search = function () {
            $scope.currentSearch = true;
            GuideCtnsSearch.query({query: $scope.searchQuery}, function(result) {
                $scope.guideCtnss = result;
                angular.forEach($scope.guideCtnss, function(data){
                    data.encodeId = WinAES.encode(data.id.toString());
                })
            }, function(response) {
                if(response.status === 404) {
                    $scope.loadAll();
                }
            });
        };

        $scope.reset = function() {
            $scope.currentSearch = false;
            $scope.page = 0;
            $scope.guideCtnss = [];
            $scope.loadAll();
        };
        $scope.deleteTruckTempalte = function(ctn) {
            if ($rootScope.account.companyId){
                ctn.companyId = null;
            }
            else {
                ctn.createdBy = null;
            }
            GuideCtns.saveAsTemplate({}, ctn, function(result) {
                $scope.loadAll();
                layer.msg($translate.instant('global.delete_success'));
            }, function(error) {
                layer.msg($translate.instant('global.delete_fail'));
            });
        }
        $timeout(function (){
            $rootScope.RESIZE();
        });

    }
})();
