(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountAuthDetailController', AccountAuthDetailController);

    AccountAuthDetailController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$translate', 
    'OpsCarrier', 'entity', 'AccountInvoice'];

    function AccountAuthDetailController($scope, $rootScope, $uibModalInstance, $translate,
        OpsCarrier, entity, AccountInvoice) {
        var vm = this;

        vm.invoiceRequest = entity;
        vm.updateTracking = updateTracking;
        vm.onExpressSelected = onExpressSelected;
        vm.clear = clear;
        vm.express = {};

        vm.isSaving = false;
        function updateTracking () {
            if(!vm.invoiceRequest.carrierCode){
                vm.invoiceRequest.carrier=null;
                return;
            }
            vm.isSaving = true;
            AccountInvoice.updateTracking({
                id: vm.invoiceRequest.id,
                carrier: vm.invoiceRequest.carrier,
                carrierCode: vm.invoiceRequest.carrierCode,
                truckingNum: vm.invoiceRequest.truckingNum
            }, function(result){
                vm.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
                clear();
            })
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onExpressSelected (carrier) {
            vm.invoiceRequest.carrierCode = carrier.code;
        }


    }
})();
