(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CustomDeclarationController', CustomDeclarationController);

    CustomDeclarationController.$inject = ['$scope', '$rootScope', '$translate', '$http', '$state', 'WinAES', 'CustomDeclaration', 'CustomDeclarationSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function CustomDeclarationController ($scope, $rootScope, $translate, $http, $state, WinAES, CustomDeclaration, CustomDeclarationSearch, ParseLinks, AlertService, pagingParams, paginationConstants) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                CustomDeclarationSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                CustomDeclaration.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.customDeclarations = data;
                angular.forEach(vm.customDeclarations, function(data){
                    data.encodeId = WinAES.encode(data.id.toString())
                })
                vm.page = pagingParams.page;
                setTimeout(function() {frzTable("divTable", "showTable", "footPager");}, 100);
            }
            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = true;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        $scope.importByExcel = function(file) {
            if (!file) {return;}
            var fd = new FormData();
            fd.append('file', file);
            fd.append('companyId', $rootScope.account.companyId);
            $http.post(
                'api/custom-declarations/importByExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    if (data.created) {
                        loadAll();
                        $rootScope.OPS_ALERT($translate.instant('global.importSuccessAmt', {param: data.created}));
                    }
                    else if (data.errorStr) {
                        $rootScope.OPS_ALERT(data.errorStr);
                    }
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                });
        };


    }
})();
