(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GuideRatesChargeChooseController', GuideRatesChargeChooseController);

        GuideRatesChargeChooseController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$translate',
        'entity', 'DataUtils'
    ];

    function GuideRatesChargeChooseController($scope, $rootScope, $uibModalInstance, $translate,
        entity, DataUtils) {

        $scope.guideRates = entity.guideRates;

        $scope.deleteRate = function(idx) {
            $scope.guideRates.splice(idx, 1);
            updateTotal();
        }


        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.save = function() {
            if(!$scope.guideRates || $scope.guideRates.length == 0){
                $rootScope.OPS_ALERT($translate.instant('global.messages.notSelected'), {icon:0});
                return;
            }
            $uibModalInstance.close($scope.guideRates);
        }


        $scope.sellingTotalList = [];
        var updateTotal = function() {
            var currencys = [];
            angular.forEach($scope.guideRates, function(data) {
                if (!data.isHidden && data.payCurrency && currencys.indexOf(data.payCurrency) === -1) {
                    currencys.push(data.payCurrency);
                }
            })
            $scope.sellingTotalList = [];
            angular.forEach(currencys, function(currency) {
                var selling = 0;
                angular.forEach($scope.guideRates, function(rate) {
                    if (!rate.isHidden && rate.payCurrency == currency && rate.payAmount) {
                        selling = selling + rate.payAmount;
                    }
                })
                $scope.sellingTotalList.push({
                    currency: currency,
                    total: DataUtils.round(selling, 2)
                })
            })
        }
        updateTotal();
    }
})();