(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WarehousingDataDetailController', WarehousingDataDetailController);

    WarehousingDataDetailController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$timeout', '$translate', 
        'entity', 'DataUtils', 'uiGridConstants', 'uiGridGroupingConstants'];

    function WarehousingDataDetailController($scope, $rootScope, $uibModalInstance, $timeout, $translate, 
        entity, DataUtils, uiGridConstants, uiGridGroupingConstants) {
        var vm = this;
        vm.guide = entity;
        vm.clear = clear;
        vm.setFdValue = setFdValue;

        function clear () {
            $uibModalInstance.dismiss('cancel');            
        }
        vm.fdObj = {
            "boxNoFd": "jobNum",//poNum shipmentId
            "dataBoxNoFd": "barcode"//batchNumber
        }
        $timeout(function() {
            if(!vm.guide.booking.pkgNum){
                vm.guide.booking.pkgNum = 0;
            }            
            initData();
        });
        function setFdValue(fd, value){
            vm.fdObj[fd] = value;
            initData();
        }
        function initData(){
            var boxNoList = [];
            for(var i = 1; i <= vm.guide.booking.pkgNum; i++){
                if(vm.fdObj.boxNoFd == 'shipmentId'){
                    boxNoList.push(vm.guide[vm.fdObj.boxNoFd] + "U" + DataUtils.getBoxNo(i+"", 6));
                }
                else {
                    boxNoList.push(vm.guide[vm.fdObj.boxNoFd] + DataUtils.getBoxNo(i+"", 4));
                }                
            }

            $scope.gridOptions.data = angular.copy(vm.guide.warehousingDatas);            
            angular.forEach($scope.gridOptions.data, function(data){
                var idx = boxNoList.indexOf(data[vm.fdObj.dataBoxNoFd]);
                if(idx === -1){
                    data.status = "notMatch";
                }
                else {
                    data.status = "reved";
                    boxNoList.splice(idx, 1);
                }
            })
            angular.forEach(boxNoList, function(boxNo){
                var newItem = {};
                newItem[vm.fdObj.dataBoxNoFd] = boxNo;
                newItem.status = "notRev";
                newItem.pkgNum = 1;
                $scope.gridOptions.data.push(newItem);
            })
            angular.forEach($scope.gridOptions.columnDefs, function(col){
                if(col.field == "barcode" || col.field == "batchNumber"){
                    col.visible = col.field == vm.fdObj.dataBoxNoFd;
                }
            })
            resize();
        }

        function resize() {
            var tabHeight = $scope.gridOptions.data.length * 30 + 200;
            var winHeight = $(window).height() - $("#gridDialogId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 500){
                tabHeight = 500;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.ALL);
        }
        $scope.revStatus = [
            { value: "reved", label: $translate.instant('autopsApp.wmsIn.revStatus.reved')},
            { value: "notRev", label: $translate.instant('autopsApp.wmsIn.revStatus.notRev')},
            { value: "notMatch", label: $translate.instant('autopsApp.wmsIn.revStatus.notMatch')}
        ];

        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: false,

            enableGridMenu : false,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤
            useExternalFiltering: false,

            enableHorizontalScrollbar : 1,//表格的水平滚动条
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            minimumColumnSize: 30,
            columnDefs: [
                { field: 'pkgNum',
                    type: 'number',
                    // filters: [
                    //     {
                    //       condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    //       placeholder: ' >= '
                    //     },
                    //     {
                    //       condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    //       placeholder: ' <= '
                    //     }
                    // ],      
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsIn.pkgs'),
                    cellTemplate: '<div class="grid-text"><strong>{{row.entity.pkgNum}}</strong></div>',
                    minWidth:80},
                { field: 'batchNumber',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.wmsIn.batch')},
                { field: 'barcode',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.wmsIn.barcode')},
                { field: 'status',
                    minWidth:150,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $scope.revStatus
                    },
                    cellTemplate: '<span class="ml-5"><span class="label" ng-class="row.entity.status == \'reved\'?\'label-info\':(row.entity.status == \'notMatch\'?\'label-warning\':\'label-danger\')" translate="autopsApp.wmsIn.revStatus.{{row.entity.status}}"></span></span>',
                    displayName: $translate.instant('autopsApp.wmsIn.status')}
            ],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
            }
        }
        
    }
})();
