(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GenerateJobNumController', GenerateJobNumController);

    GenerateJobNumController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$translate', '$timeout', 'entity', 'OpsCompany', 'Guide'];
    
    function GenerateJobNumController($scope, $rootScope, $uibModalInstance, $translate, $timeout, entity, OpsCompany, Guide) {
        $scope.guide = entity;        
        $scope.jobPrefixs = [];
        $scope.jobPrefix = "";
        $scope.jobNum = "";
        var jobNumCopy = angular.copy(entity.jobNum);
        $timeout(function (){      
            OpsCompany.get({
                id: $scope.guide.companyId
            }, function(result) {
                $scope.opsCompany = result;
                if($scope.guide.isExport){
                    if(result.jobExpPrefix){
                        $scope.jobPrefixs = result.jobExpPrefix.split(";");
                        $scope.jobPrefixs.splice(0, 0, "");
                    }
                    else {
                        layer.msg($translate.instant('autopsApp.guide.prefixNull'));
                        $uibModalInstance.dismiss('cancel');
                    }                    
                }
                else {
                    if(result.jobImpPrefix){
                        $scope.jobPrefixs = result.jobImpPrefix.split(";");
                        $scope.jobPrefixs.splice(0, 0, "");
                    }
                    else{
                        layer.msg($translate.instant('autopsApp.guide.prefixNull'));
                        $uibModalInstance.dismiss('cancel');
                    }                    
                }
                for (var i = 0; i < jobNumCopy.length; i++){
                    if(!isNaN(jobNumCopy[i])){
                        $scope.jobPrefix = jobNumCopy.substring(0, i);
                        $scope.jobNum = jobNumCopy.substring(i);
                        break;
                    };
                }      
            });
        })

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };      
        
        $scope.save = function() {
            $scope.isSaving = true;
            if($scope.jobPrefix){
                jobNumCopy = $scope.jobPrefix + $scope.jobNum;
            }
            else {
                jobNumCopy = $scope.jobNum;
            }
            $uibModalInstance.close(jobNumCopy); 
        }


}
})();
