(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('DevelopmentController', DevelopmentController);
    DevelopmentController.$inject = ['$scope', '$rootScope'];

    function DevelopmentController ($scope, $rootScope) {
        var vm = this;
    }
})();
