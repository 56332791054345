(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('crm-enquiry', {
            parent: 'crm',
            url: '/crm-enquiry?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'autopsApp.crmEnquiry.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/crm-enquiry/crm-enquiries.html',
                    controller: 'CrmEnquiryController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('crmEnquiry');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('guideRates');        
                    $translatePartialLoader.addPart('crmQuotation');          
                    $translatePartialLoader.addPart('opsCost');                                   
                    return $translate.refresh();
                }]
            }
        })
        .state('crm-quote', {
            parent: 'crm',
            url: '/crm-quote?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'autopsApp.crmEnquiry.quote.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/crm-price/crm-prices.html',
                    controller: 'CrmPriceController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('crmEnquiry');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('crmQuotation');     
                    $translatePartialLoader.addPart('opsCost');       
                    $translatePartialLoader.addPart('expressParcel');                
                    return $translate.refresh();
                }]
            }
        })
        .state('crm-quote.new', {
            parent: 'crm-quote',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/crm-enquiry/crm-enquiry-detail.html',
                    controller: 'CrmEnquiryDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xxxl',
                    resolve: {
                        entity: function () {
                            return {
                                companyId: null,
                                createrId: null,
                                inquirerName: null,
                                inquirerCompany: null,
                                venderId: null,
                                inquirerEmail: null,
                                enquiryTime: null,
                                quotationCount: null,
                                volume: null,
                                volumeJson: null,
                                gw: null,
                                vol: null,
                                pkgs: null,
                                porAddress: null,
                                pol: null,
                                pod: null,
                                podAddress: null,
                                pickupRequired: null,
                                deliveryRequired: null,
                                declarationRequired: null,
                                clearanceRequired: null,
                                exportDocRequired: null,
                                importDocRequired: null,
                                dutyRequired: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('crm-quote', null, { reload: 'crm-quote' });
                }, function() {
                    $state.go('crm-quote');
                });
            }]
        })
        .state('crm-quote.edit', {
            parent: 'crm-quote',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/crm-enquiry/crm-enquiry-detail.html',
                    controller: 'CrmEnquiryDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xxxl',
                    resolve: {
                        entity: ['CrmQuote', 'WinAES', function(CrmQuote, WinAES) {
                            return CrmQuote.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('crm-quote', null, { reload: 'crm-quote' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('crm-quote.copy', {
            parent: 'crm-quote',
            url: '/{id}/copy',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', 'CrmQuote', 'WinAES', function($stateParams, $state, $uibModal, CrmQuote, WinAES) {
                CrmQuote.get({id : WinAES.decode($stateParams.id)}, function(result){
                    result.id = null;
                    result.quoteNum = null;
                    result.quotationTime = null;
                    result.expireDate = null;
                    result.status = 'new';
                    result.jobNum = null;
                    if(result.crmQuotePrices){
                        angular.forEach(result.crmQuotePrices, function(price){
                            price.id = null;
                        })
                    }
                    $uibModal.open({
                        templateUrl: 'app/entities/crm-enquiry/crm-enquiry-detail.html',
                        controller: 'CrmEnquiryDetailController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'xxxl',
                        resolve: {
                            entity: [function() {
                                return result;  
                            }]
                        }
                    }).result.then(function() {
                        $state.go('crm-quote', null, { reload: 'crm-quotation' });
                    }, function() {
                        $state.go('^');

                    });                    
                });                
            }]
        })
        .state('crm-quote.delete', {
            parent: 'crm-quote',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/crm-quotation/crm-quotation-delete-dialog.html',
                    controller: 'CrmQuotationDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CrmQuotation', 'WinAES', function(CrmQuotation, WinAES) {
                            return CrmQuotation.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('crm-quote', null, { reload: 'crm-quote' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('crm-enquiry.new', {
            parent: 'crm-enquiry',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/crm-enquiry/crm-enquiry-dialog.html',
                    controller: 'CrmEnquiryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xl',
                    resolve: {
                        entity: function () {
                            return {
                                companyId: null,
                                createrId: null,
                                inquirerName: null,
                                inquirerCompany: null,
                                venderId: null,
                                inquirerEmail: null,
                                enquiryTime: null,
                                quotationCount: null,
                                volume: null,
                                volumeJson: null,
                                gw: null,
                                vol: null,
                                pkgs: null,
                                porAddress: null,
                                pol: null,
                                pod: null,
                                podAddress: null,
                                pickupRequired: null,
                                deliveryRequired: null,
                                declarationRequired: null,
                                clearanceRequired: null,
                                exportDocRequired: null,
                                importDocRequired: null,
                                dutyRequired: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('crm-enquiry', null, { reload: 'crm-enquiry' });
                }, function() {
                    $state.go('crm-enquiry');
                });
            }]
        })
        .state('crm-enquiry.edit', {
            parent: 'crm-enquiry',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/crm-enquiry/crm-enquiry-dialog.html',
                    controller: 'CrmEnquiryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xl',
                    resolve: {
                        entity: ['CrmEnquiry', 'WinAES', function(CrmEnquiry, WinAES) {
                            return CrmEnquiry.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('crm-enquiry', null, { reload: 'crm-enquiry' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('crm-enquiry.delete', {
            parent: 'crm-enquiry',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/crm-enquiry/crm-enquiry-delete-dialog.html',
                    controller: 'CrmEnquiryDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CrmEnquiry', 'WinAES', function(CrmEnquiry, WinAES) {
                            return CrmEnquiry.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('crm-enquiry', null, { reload: 'crm-enquiry' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
