(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('SubTrackingDialogController', SubTrackingDialogController);

    SubTrackingDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'ExpressParcel', 'entity'];

    function SubTrackingDialogController ($timeout, $scope, $uibModalInstance, ExpressParcel, entity) {
        var vm = this;
        vm.qParam = entity;
        vm.results = [];
        vm.clear = clear;
        vm.isQuering = true;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }        
        $timeout(function (){
            if(entity.results){
                vm.results = entity.results;
                return;
            }
            layer.msg("Quering...", {
                shade: 0.3,
                time: 2000000
            });
            ExpressParcel.remoteCheck({
                country: entity.country,
                postcode: entity.postcode,
                carrier: entity.carrier
            }, function(results){
                layer.closeAll();
                vm.isQuering = false;
                vm.results = results;

                if(vm.results && vm.results.length > 0){
                    if(entity.carrier){
                        $scope.$emit('autopsApp:isRemoteConfirmed', entity);
                    }                    
                }
                else {
                    $scope.$emit('autopsApp:isRemoteConfirmed', null);
                }
            }, function () {
                vm.isQuering = false;
                layer.closeAll();
            });
        });
    }
})();
