(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('InvoiceMonitorDialogController', InvoiceMonitorDialogController);

    InvoiceMonitorDialogController.$inject = ['$uibModalInstance', 'entity'];

    function InvoiceMonitorDialogController ($uibModalInstance, entity) {
        var vm = this;
        vm.clear = clear;
        vm.data = entity;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
