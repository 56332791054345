(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('EdiDataSiDialogController', EdiDataSiDialogController);

    EdiDataSiDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$translate', '$uibModalInstance', '$http',
    'OpsCarrier', 'EdiData', 'OpsPorts', 'entity', 'BookingCtns', 'DataUtils', 
    'Guide', 'GuideCtns'];

    function EdiDataSiDialogController($timeout, $scope, $rootScope, $translate, $uibModalInstance, $http,
        OpsCarrier, EdiData, OpsPorts, entity, BookingCtns, DataUtils, 
       Guide, GuideCtns) {
        var vm = this;
        $scope.isSaving = false;

        $scope.guide = angular.copy(entity.guide);
        $scope.ediData = {};
        var guideCopy;
        var ediDataCopy;
        var refreshRequired = false;

        $timeout(function (){            
            EdiData.getByGuideId({guideId: $scope.guide.id}, function(result){
                $scope.ediData = result;
                initEdiDate();
            })
        });

        var initEdiDate = function() {
            if (!$scope.ediData){
                $scope.ediData = {};
            }
            // if (!$scope.ediData.shCity){
            //     $scope.ediData.shCity = $scope.guide.polName;
            // }
            // if (!$scope.ediData.shCountryCode && $scope.guide.polId){
            //     $scope.ediData.shCountryCode = $scope.guide.polId.substring(0,2);
            // }
            // if (!$scope.ediData.cnCity){
            //     $scope.ediData.cnCity = $scope.guide.destName;
            // }
            // if (!$scope.ediData.cnCountryCode && $scope.guide.destId){
            //     $scope.ediData.cnCountryCode = $scope.guide.destId.substring(0,2);
            // }
            // if (!$scope.ediData.npCity){
            //     $scope.ediData.npCity = $scope.guide.destName;
            // }
            // if (!$scope.ediData.npCountryCode && $scope.guide.destId){
            //     $scope.ediData.npCountryCode = $scope.guide.destId.substring(0,2);
            // }

            if (!$scope.ediData.shName && $scope.guide.guideShipperBl){
                var strs = $scope.guide.guideShipperBl.split("\n");
                $scope.ediData.shName = strs[0];                
                if (strs.length > 1 && !$scope.ediData.shAddress){
                    $scope.ediData.shAddress = "";
                    for (var i = 1; i < strs.length; i++){
                        $scope.ediData.shAddress = $scope.ediData.shAddress + strs[i] + "\n";                        
                    }
                }
            }
            if (!$scope.ediData.cnName && $scope.guide.deliveryBl){
                var strs = $scope.guide.deliveryBl.split("\n");
                $scope.ediData.cnName = strs[0];                
                if (strs.length > 1 && !$scope.ediData.cnAddress){
                    $scope.ediData.cnAddress = "";
                    for (var i = 1; i < strs.length; i++){
                        $scope.ediData.cnAddress = $scope.ediData.cnAddress + strs[i] + "\n";                        
                    }
                }
            }
            if (!$scope.ediData.npName && $scope.guide.guideNotifyBl){
                var strs = $scope.guide.guideNotifyBl.split("\n");
                $scope.ediData.npName = strs[0];                
                if (strs.length > 1 && !$scope.ediData.npAddress){
                    $scope.ediData.npAddress = "";
                    for (var i = 1; i < strs.length; i++){
                        $scope.ediData.npAddress = $scope.ediData.npAddress + strs[i] + "\n";                        
                    }
                }
            }
            if ($scope.guide.booking.hsCode && $scope.guide.booking.hsCode.length > 6){
                $scope.guide.booking.hsCode =$scope.guide.booking.hsCode.replace(/[^\d]/, "");
                $scope.guide.booking.hsCode = $scope.guide.booking.hsCode.substring(0, 6);
            }
            $http.get('i18n/csPortsData.json').success(function(result) {
                $scope.portList = result.ports;
                var pol, pod, por, dest;
                angular.forEach($scope.portList, function(data){
                    if(data.code == $scope.guide.porId){
                        por = true;
                    }
                    if(data.code == $scope.guide.polId){
                        pol = true;
                    }
                    if(data.code == $scope.guide.podId){
                        pod = true;
                    }
                    if(data.code == $scope.guide.destId){
                        dest = true;
                    }
                })
                if(!por){
                    $scope.guide.porId = null;
                    $scope.guide.porName = null;
                }
                if(!pol){
                    $scope.guide.polId = null;
                    $scope.guide.polName = null;
                }
                if(!pod){
                    $scope.guide.podId = null;
                    $scope.guide.podName = null;
                }
                if(!dest){
                    $scope.guide.destId = null;
                    $scope.guide.destName = null;
                }
            });


            guideCopy = angular.copy($scope.guide);
            ediDataCopy = angular.copy($scope.ediData);            
            $scope.updatePkgsAndType();
            $scope.updateGwVol();
            angular.forEach($scope.guide.guideCtns, function(data){
                if (data.ctnType != "20GP" && data.ctnType != "40GP" && data.ctnType != "40HQ" && data.ctnType != "45HQ"){
                    data.ctnType = null;
                }
                if (!data.bookingNum && $scope.guide.soNum){
                    data.bookingNum = $scope.guide.soNum;
                }
            });
            if ($scope.guide.serviceTerm != "CY-CY" && $scope.guide.serviceTerm != "DOOR-CY" 
                && $scope.guide.serviceTerm != "CY-DOOR" && $scope.guide.serviceTerm != "DOOR-DOOR"){
                $scope.guide.serviceTerm = null;
            }
            $scope.uppperCase();
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg("Data updated according EDI criterion, pls check carefully before save.");
            };    
            if (!$scope.ediData.noOfCopies){
                $scope.ediData.noOfCopies = 3;
            }
            if (!$scope.ediData.carrier){
                $scope.ediData.carrier = $scope.guide.carrier;
            }
            if (!$scope.ediData.carrierCode){
                $scope.ediData.carrierCode = $scope.guide.booking.carrierCode;
            }
        }


        $scope.onCarrierSelected = function(carrier) {       
            $scope.ediData.carrierCode = carrier.code;
        }

        $scope.clear = function () {
            $uibModalInstance.close(refreshRequired);
        }
        $scope.cargoSmartSi = function(type) {
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };     
            if (!$scope.guide.porId){
                layer.msg('The code of Place of Receipt is null');
                return;
            }
            if (!$scope.guide.polId){
                layer.msg('The code of Port of Load is null');
                return;
            }
            if (!$scope.guide.podId){
                layer.msg('The code of Port of Discharge is null');
                return;
            }
            if (!$scope.guide.destId){
                layer.msg('The code of Final destination is null');
                return;
            }
            if (!$scope.guide.booking.carrierCode){
                layer.msg('The code of carrier is null');
                return;
            }
            // if ($scope.ediData.npName && (!$scope.ediData.npCity || !$scope.ediData.npCountryCode)){
            //     layer.msg('City of the Notify is null');
            //     return;
            // }
            // if ($scope.ediData.npAddress && !$scope.ediData.npName){
            //     layer.msg('Name of the Notify is null');
            //     return;
            // }
            $scope.isSaving = true;
            $scope.ediData.siActionType = type;
            $scope.ediData.siUpdatedTime = new Date();
            var ediDataDTO = {};
            ediDataDTO.guide = $scope.guide;
            ediDataDTO.ediData = $scope.ediData;       
            EdiData.cargoSmartSi(ediDataDTO, onSendSuccess, onSendError);     
        }

        var onSendSuccess = function(result) {
            $scope.isSaving = false;
            $uibModalInstance.close(refreshRequired);
            layer.msg($translate.instant('global.send_success'));
        };
        var onSendError = function(result) {
            $scope.isSaving = false;
            $scope.ediData.siActionType = ediDataCopy.siActionType;
            if (result.status == 403){
                $rootScope.OPS_ALERT($translate.instant('global.chineseContainedAlert'));
            }
            else if (result.status == 406){
                layer.msg($translate.instant('autopsApp.guide.notAvailableAlert'));
            } else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        };
        $scope.save = function(booking) {
            $scope.isSaving = true;
            $scope.ediData.guideId = $scope.guide.id;
            if (!angular.equals($scope.ediData, ediDataCopy)){
                if ($scope.ediData.id !== null) {
                    EdiData.update($scope.ediData, onSaveEdiSuccess, onSaveError);
                } else {
                    EdiData.save($scope.ediData, onSaveEdiSuccess, onSaveError);
                }
            }
            else {
                if (angular.equals($scope.guide, guideCopy)) {
                    $scope.isSaving = false;
                    layer.msg($translate.instant('global.no_amendmend'));
                    return;
                };
                Guide.update($scope.guide, onSaveGuideSuccess, onSaveError); 
            }
        };

        var onSaveEdiSuccess = function(result) {
            $scope.ediData = result;
            ediDataCopy = angular.copy($scope.ediData); 
            if (angular.equals($scope.guide, guideCopy)) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
                return;
            };
            Guide.update($scope.guide, onSaveGuideSuccess, onSaveError);  
        };

        var onSaveGuideSuccess = function(result) {
            refreshRequired = true;
            $scope.isSaving = false;
            $scope.guide = result;     
            guideCopy = angular.copy($scope.guide);       
            layer.msg($translate.instant('global.messages.submitted'));
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
            if (result.status == 403){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.lockedAlert'));
            }
            else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        };

        $scope.portList = new Array();
        $scope.loadPorts = function() {
            if ($rootScope.OPSPORTS.airports && $rootScope.OPSPORTS.airports.length > 0){
                return;
            }
            OpsPorts.getAll(function(result){
                $rootScope.OPSPORTS.airports = [];
                $rootScope.OPSPORTS.seaports = [];
                angular.forEach(result, function(data){
                    if(data.type == 1){
                        $rootScope.OPSPORTS.seaports.push(data);
                    }
                    else {
                        $rootScope.OPSPORTS.airports.push(data);
                    }
                })
                if ($scope.guide && ($scope.guide.shipmentType == "AIR" || $scope.guide.shipmentType == "EXPRESS")) {
                    $scope.portList = $rootScope.OPSPORTS.airports;         
                } else {
                    $scope.portList = $rootScope.OPSPORTS.seaports;
                }
            })
        };

        $scope._selected = function($item, which) {
            switch (which) {
                case "gporChoose":
                    $scope.guide.porId = $item.code;
                    break;
                case "gpolChoose":
                    $scope.guide.polId = $item.code;
                    break;
                case "gpodChoose":
                    $scope.guide.podId = $item.code;
                    break;
                case "gdestChoose":
                    $scope.guide.destId = $item.code;
                    break;
                case "bpCityChoose":
                    $scope.ediData.bpCountryCode = $item.code.substring(0, 2);
                    break;
                case "shCityChoose":
                    $scope.ediData.shCountryCode = $item.code.substring(0, 2);
                    break;
                case "cnCityChoose":
                    $scope.ediData.cnCountryCode = $item.code.substring(0, 2);
                    break;
                case "npCityChoose":
                    $scope.ediData.npCountryCode = $item.code.substring(0, 2);
                    break;
            }
        }

        $scope.uppperCase = function() {           
            $scope.ediData.shName = angular.uppercase(DataUtils.checkStrLen($scope.ediData.shName, 35));
            $scope.ediData.shAddress = angular.uppercase(DataUtils.checkStrLen($scope.ediData.shAddress, 35));
            $scope.ediData.cnName = angular.uppercase(DataUtils.checkStrLen($scope.ediData.cnName, 35));
            $scope.ediData.cnAddress = angular.uppercase(DataUtils.checkStrLen($scope.ediData.cnAddress, 35));
            $scope.ediData.bpName = angular.uppercase(DataUtils.checkStrLen($scope.ediData.bpName, 35));
            $scope.ediData.bpAddress = angular.uppercase(DataUtils.checkStrLen($scope.ediData.bpAddress, 35));
            $scope.ediData.npName = angular.uppercase(DataUtils.checkStrLen($scope.ediData.npName, 35));
            $scope.ediData.npAddress = angular.uppercase(DataUtils.checkStrLen($scope.ediData.npAddress, 35));
        };

        $scope.uppercaseVsl = function(str) {
            $scope.guide.vsl = angular.uppercase(str);
        }
        $scope.uppercaseVoy = function(str) {
            $scope.guide.voy = angular.uppercase(str);
        }
        $scope.datePickerForEtd = {};
        $scope.datePickerForEtd.status = {
            opened: false
        };
        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };

        $scope.updatePkgsAndType = function() {
            if ($scope.guide.pkgNum && $scope.guide.booking.pkgType) {
                $scope.guide.booking.pkgsAndType = $scope.guide.pkgNum.toString() + " " + $scope.guide.booking.pkgType;
                $scope.guide.pkgsAndType = $scope.guide.pkgNum.toString() + " " + $scope.guide.booking.pkgType;
            }
        }
        $scope.updateGwVol = function() {
            if ($scope.guide.gw && $scope.guide.vol) {
                $scope.guide.gwVol = $scope.guide.gw.toFixed(2) + "KGS" + "\n" + $scope.guide.vol.toFixed(3) + "CBM";
                $scope.guide.booking.gwVol = $scope.guide.gw.toFixed(2) + "KGS" + "\n" + $scope.guide.vol.toFixed(3) + "CBM";
            } else if ($scope.guide.vol) {
                $scope.guide.gwVol = $scope.guide.vol.toFixed(3) + "CBM";
                $scope.guide.booking.gwVol = $scope.guide.vol.toFixed(3) + "CBM";

            } else if ($scope.guide.gw) {
                $scope.guide.gwVol = $scope.guide.gw.toFixed(2) + "KGS";
                $scope.guide.booking.gwVol = $scope.guide.gw.toFixed(2) + "KGS";
            }
            if ($scope.guide.booking.divVolBy){
                $scope.guide.chargeWeight = $scope.guide.vol * 1000000 / $scope.guide.booking.divVolBy;
            }
            if($scope.guide.chargeWeight < $scope.guide.gw){
                $scope.guide.chargeWeight = $scope.guide.gw;
            }
            if ($scope.guide.chargeWeight){
                $scope.guide.chargeWeight = $scope.guide.chargeWeight.toFixed(2) - 0;
                $scope.guide.awbWeight = $scope.guide.chargeWeight.toFixed(2) - 0;
            }   
        };
        $scope.uppercaseCtnNum = function(ctn) {
            ctn.ctnNum = angular.uppercase(ctn.ctnNum);
        }
        $scope.uppercaseCtnSealNum = function(ctn) {
            ctn.sealNum = angular.uppercase(ctn.sealNum);
        }
        $scope.checkCtnRepeat = function(ctn) {
            if(!ctn.ctnNum){
                return;
            }
            angular.forEach($scope.guide.guideCtns, function(data){
                if (data != ctn && data.ctnNum == ctn.ctnNum){
                    $rootScope.OPS_ALERT(data.ctnNum + ": " + $translate.instant('autopsApp.guide.home.ctnRepeatedAlert'));
                }
            })
        }
        $scope.guideCtnPkgsChange = function() {
            if ($scope.guide.guideCtns && $scope.guide.guideCtns.length > 0) {
                var newPkgsTotal = 0;
                for (var i = 0; i < $scope.guide.guideCtns.length; i++) {
                    if ($scope.guide.guideCtns[i].pkgs) {
                        newPkgsTotal = newPkgsTotal + $scope.guide.guideCtns[i].pkgs;
                    }
                }
                $scope.guide.pkgNum = newPkgsTotal;
                $scope.updatePkgsAndType();
            }
            updateBookingCtnsGwVol();
        }
        $scope.guideCtnPkgTypeChange = function(pkgType) {
            var pkgsCopy = null;
            var isSamePkg = true;
            angular.forEach($scope.guide.guideCtns, function (data) {        
                if (data.pkgType && pkgType != data.pkgType){
                    isSamePkg = false;
                }
            });
            if (isSamePkg){
                $scope.guide.booking.pkgType = pkgType;
            }
            else {
                $scope.guide.booking.pkgType = "PKGS";
            } 
            $scope.updatePkgsAndType();
            updateBookingCtnsGwVol();
        }
        $scope.guideCtnGwChange = function() {
            if ($scope.guide.guideCtns && $scope.guide.guideCtns.length > 0) {
                var newGwTotal = 0;
                for (var i = 0; i < $scope.guide.guideCtns.length; i++) {
                    if ($scope.guide.guideCtns[i].gw) {
                        newGwTotal = newGwTotal + $scope.guide.guideCtns[i].gw;
                    }
                }
                $scope.guide.gw = newGwTotal.toFixed(2) - 0;
                $scope.updateGwVol();
            }
            updateBookingCtnsGwVol();
        }
        $scope.guideCtnVolChange = function() {
            if ($scope.guide.guideCtns && $scope.guide.guideCtns.length > 0) {
                var newVolTotal = 0;
                for (var i = 0; i < $scope.guide.guideCtns.length; i++) {
                    if ($scope.guide.guideCtns[i].vol) {
                        newVolTotal = newVolTotal + $scope.guide.guideCtns[i].vol;
                    }
                }
                $scope.guide.vol = newVolTotal.toFixed(3) - 0;
                $scope.updateGwVol();
            }
            updateBookingCtnsGwVol();
        }
        var updateBookingCtnsGwVol = function() {
            if ($scope.guide.guideFclCtns && $scope.guide.guideFclCtns.length > 0) {                
                angular.forEach($scope.guide.guideFclCtns, function(bctn){
                    if (bctn.ctnType) {
                        var gwTtl=0, volTtl=0, pkgTtl=0, pkgType;
                        angular.forEach($scope.guide.guideCtns, function(gctn){
                            if(gctn.ctnType == bctn.ctnType){
                                if(gctn.gw){
                                    gwTtl = gwTtl + gctn.gw;
                                }
                                if(gctn.vol){
                                    volTtl = volTtl + gctn.vol;
                                }      
                                if(gctn.pkgs){
                                    pkgTtl = pkgTtl + gctn.pkgs;
                                }      
                                if(pkgType){
                                    if(gctn.pkgType && pkgType != gctn.pkgType){
                                        pkgType =  "PKGS";      
                                    }
                                }
                                else {
                                    pkgType = gctn.pkgType;
                                }                                              
                            }
                        })
                        bctn.gw = gwTtl.toFixed(2) - 0;
                        bctn.vol = volTtl.toFixed(3) - 0;
                        bctn.pkgNum = pkgTtl.toFixed(2) - 0;
                        bctn.pkgType = pkgType;
                    }
                });
            }
        }

        $scope.addGCtn = function() {
            $scope.guide.guideCtns.push({
                smsSent: false
            });
        };

        
        $scope.deleteGCtn = function(ctn) {
            if (ctn.id != null) {
                GuideCtns.delete({
                    id: ctn.id
                });
            };
            var index = $scope.guide.guideCtns.indexOf(ctn);
            if (index > -1) {
                $scope.guide.guideCtns.splice(index, 1);
            };
            var ctnAmt = 0;
            angular.forEach($scope.guide.guideCtns, function(gctn){
                if(gctn.ctnType == ctn.ctnType){
                    ctnAmt++;
                }                
            })
            angular.forEach($scope.guide.guideFclCtns, function(bctn){
                if(ctnAmt && bctn.ctnType == ctn.ctnType){
                    bctn.num = ctnAmt;
                    ctnAmt = null;
                }         
            })
            updateBookingCtnsGwVol();
            
        };
        $scope.generateMark = function() {
            if ($scope.guide.mark || !$scope.guide.booking.mark) {
                $scope.guide.mark = angular.uppercase($scope.guide.mark);
            }
            else {
                $scope.guide.mark = angular.copy($scope.guide.booking.mark);
            }
            $scope.guide.mark = angular.uppercase(DataUtils.checkStrLen($scope.guide.mark, 35));
        }
        $scope.generateGoodsDiscription = function() {
            if (!$scope.guide.goodsDiscription){
                $scope.guide.goodsDiscription = angular.uppercase($scope.guide.cargoName);
            }
            if (!$scope.guide.booking.goodsDiscription){
                $scope.guide.booking.goodsDiscription = angular.uppercase($scope.guide.cargoName);
            }
            if ($scope.guide.goodsDiscription || !$scope.guide.booking.goodsDiscription) {
                $scope.guide.goodsDiscription = angular.uppercase($scope.guide.goodsDiscription);
            }
            else {
                $scope.guide.goodsDiscription = angular.copy($scope.guide.booking.goodsDiscription);
            }            
            $scope.guide.goodsDiscription = angular.uppercase(DataUtils.checkStrLen($scope.guide.goodsDiscription, 512));
        }

        $scope.checkRemarksLen = function() {       
            $scope.ediData.siRemarks = DataUtils.checkStrLen($scope.ediData.siRemarks, 240);
        }

        $scope.updateSoNum = function(soNum) {
            $scope.guide.soNum = "";
            var doNums = new Set();
            angular.forEach($scope.guide.guideCtns, function(data){
                if (data.bookingNum){
                    data.bookingNum = data.bookingNum.replace(/\s+/g, "");
                }
                if (data.bookingNum){
                    doNums.add(data.bookingNum);
                }                            
            });
            doNums.forEach(function(soNum){
                $scope.guide.soNum = $scope.guide.soNum + soNum + "; ";
            })
            $translate.refresh();
        }

        $scope.releaseWayChange = function(releaseWay) {
            if (releaseWay == "SWB"){
                $scope.ediData.noOfCopies = 1;
            }
            else {
                $scope.ediData.noOfCopies = 3;
            }
        }        
    }
})();
