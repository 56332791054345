(function() {
	'use strict';

	angular
		.module('autopsApp')
		.controller('FastWriteOffController', FastWriteOffController);

	FastWriteOffController.$inject = ['$scope', '$rootScope', '$translate', '$timeout', '$uibModal', '$uibModalInstance',
    'OpsVender', 'GuideRates', 'WriteOff', 'GridState', 'uiGridConstants', 'uiGridGroupingConstants', 'DateUtils', 'DataUtils',
    'JhiLanguageService', 'OpsExchangeRate', 'AccountCashier', 'AccountTitle', 'AutoCheck', 'entity', 'AccountInvoice', 'AccountPayment'];

	function FastWriteOffController($scope, $rootScope, $translate, $timeout, $uibModal, $uibModalInstance,
        OpsVender, GuideRates, WriteOff, GridState, uiGridConstants, uiGridGroupingConstants, DateUtils, DataUtils,
        JhiLanguageService, OpsExchangeRate, AccountCashier, AccountTitle, AutoCheck, entity, AccountInvoice, AccountPayment) {
        
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        }
        $scope.byDnViewChange = function(){
            $scope.byDnView = !$scope.byDnView;
            initGrid();
            updateList();
            $scope.gridApi.grid.refresh();
            $scope.dnGridApi.grid.refresh();
            $translate.refresh();
        }
		$scope.statParams = {
            byItemName: true,
            allPaid: false,
            deductBillIdTAG: false,
            includeHidden: false,
            venderIdTAG: true,
            payCurrencyTAG: true,
            payAmountTAG: true,
            itemTAG: true,
            etdTAG: true
        };
        var lang = "";
        JhiLanguageService.getCurrent().then(function(language) {
            lang = language;
        });

		$scope.showIdx = null;
        $scope.showExIdx = null;
		$scope.dayFormat = "yyyy-MM-dd";
        $scope.byDnView = entity.byDn;

		var getStatParams = function() {
            $scope.statParams.companyId = $rootScope.account.companyId;
			if (!$scope.statParams.payer){
				$scope.statParams.venderId = null;
			}
			if (!$scope.statParams.item){
				$scope.statParams.itemId = null;
			}
			if (!$scope.statParams.agentName){
				$scope.statParams.deliveryId = null;
			}
			if (!$scope.statParams.coloaderName){
				$scope.statParams.bookingToId = null;	
			}
			if (!$scope.statParams.clientName){
				$scope.statParams.shipperId = null;
			}
			if (!$scope.statParams.customerName){
				$scope.statParams.customerId = null;
			}
            $scope.statParams.companyId = $rootScope.account.companyId;
		}
		$scope.isSaving = false;

		$scope.getContents = function() {			
            var diffDays = DateUtils.getDaysDiff($scope.statParams.etdFM, $scope.statParams.etdTO);
            if (diffDays < 0) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                return;
            }
			getStatParams();			
            layer.load(1, {shade: 0.3});
			GuideRates.chargesReport($scope.statParams, addData, $rootScope.ALERT_ERROR);
		}

        var onLoadDataSuccess = function(result){
            $scope.isSaving = false;
            layer.closeAll(); 
            if (!result || result.length == 0) {
                $scope.gridOptions.data = [];   
                initContent();
                layer.msg($translate.instant('stats.stat.noData'));
                return;
            }
            $scope.gridOptions.data = result;                          
            initContent(); 
        }
        var addData = function(result){
            $scope.isSaving = false;
            layer.closeAll(); 
            if (!result || result.length == 0) {
                layer.msg($translate.instant('stats.stat.noData'));
                return;
            }
            addNewData(result);
            initContent(); 
        }
        window.onresize = function(){
            resize();
        }

        function resize() {
            var tabHeight;
            if($scope.byDnView){
                tabHeight = $scope.dnGridOptions.data.length * 30 + 190;
            }
            else {
                tabHeight = $scope.gridOptions.data.length * 30 + 190;
            }
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 380){
                tabHeight = 380;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

		var initContent = function() {
            if(!$scope.accountCashier.originalCurrency){
                if(ledgerCopy){
                    $scope._onLedgerSelected(ledgerCopy);
                }
                else if($rootScope.HAVENOAUTH('FMIS') && $scope.gridOptions.data.length > 0){
                    $scope.accountCashier.originalCurrency = $scope.gridOptions.data[0].payCurrency;
                }
            }
            $scope.amountChange();
            resize();   
            if($scope.byDnView){
                $scope.dnGridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            }
            else {
                $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            }
		}

        $scope.gridOptions = {
            enableSorting: true,//排序
            enableRowHeaderSelection : true,  //行选中
            enableGridMenu : true,//表格 菜单  
            showGridFooter: false,//表格的footer  
            enableFiltering: true,//高级过滤
            showColumnFooter: true,//每列footer

            minRowsToShow: 20,
            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            enableRowSelection: true, //行选中
            enableSelectAll: true, //全部选中
            enableFullRowSelection : false, //行任意位置选中

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: $translate.instant('global.menu.admin.chargesReport') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],     
            columnDefs: [
                { field: 'jobNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.jobNum'),
                    cellTemplate: '<a  class="grid-text" href="" ng-click="grid.appScope.VIEWGE(row.entity)" ng-if="row.entity.id">{{row.entity.jobNum}}</a>'},
                { field: 'item',
                    minWidth:100,
                    displayName: $translate.instant('stats.stat.item'),
                    cellTemplate: '<span class="grid-text"><span>{{row.entity.item}}</span><span class="glyphicon glyphicon-alert ml-4 text-danger" ng-hide="row.entity.dnNum" data-toggle="tooltip" data-placement="top" title="Not Locked/Confirmed"></span><span class="glyphicon glyphicon-remove-circle text-primary ml-4" ng-click="grid.appScope.delOne(row.entity.idStr)"></span></span>'},
                { field: 'payCurrency',
                    minWidth:70, 
                    displayName: $translate.instant('stats.stat.currency')},
                { field: 'payAmount',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100, 
                    cellTemplate: '<span class="pull-right grid-text" ng-class="row.entity.isPay?\'text-danger\':\'text-primary\'"><span ng-if="row.entity.isPay">(</span><span>{{grid.appScope.FORMAT_NUM(row.entity.payAmount)}}</span><span ng-if="row.entity.isPay">)</span></span>',
                    displayName: $translate.instant('autopsApp.accountInvoice.invoiceAmount')},
                { field: 'thisAmount',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ], 
                    minWidth:100, 
                    cellTemplate: '<span class="grid-text pull-right"><strong ng-class="row.entity.isPay?\'text-danger\':\'text-primary\'" ng-hide="grid.appScope.showIdx == row.entity.idStr" ng-click="grid.appScope.showIdx = row.entity.idStr;grid.appScope.selectTxt(\'amt_field_\'+row.entity.idStr)"><span ng-if="row.entity.isPay">(</span><span>{{grid.appScope.FORMAT_NUM(row.entity.thisAmount)}}</span><span ng-if="row.entity.isPay">)</span><small class="ml-1 glyphicon glyphicon-pencil"></small></strong><input type="text" class="input-sm form-control text-right" style="padding-right: 5px;" ng-model="row.entity.thisAmount" ng-show="grid.appScope.showIdx == row.entity.idStr" ng-blur="grid.appScope.amountChange();grid.appScope.clearShowIdx()" numberic id="amt_field_{{row.entity.idStr}}"/></span>',
                    displayName: $translate.instant('autopsApp.accountInvoice.home.writeOffAmount')},               
                { field: 'actualExchangeRate',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:70, 
                    cellTemplate: '<span class="grid-text pull-right"><strong ng-class="row.entity.isPay?\'text-danger\':\'text-primary\'" ng-hide="grid.appScope.showExIdx == row.entity.idStr" ng-click="grid.appScope.setShowIdx(row.entity)"><span>{{row.entity.actualExchangeRate}}</span><small class="ml-1 glyphicon glyphicon-pencil" ng-show="row.entity.payCurrency != grid.appScope.account.baseCurrency"></small></strong><input type="text" class="input-sm form-control text-right" style="padding-right: 5px;" ng-model="row.entity.actualExchangeRate" ng-show="grid.appScope.showExIdx == row.entity.idStr" ng-blur="grid.appScope.amountChange();grid.appScope.clearShowIdx()" numberic id="ex_field_{{row.entity.idStr}}" /></span>',
                    displayName: $translate.instant('stats.stat.actualExchangeRate')},
                { field: 'writeOffBaseAmt',
                    type:'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ], 
                    minWidth:100, 
                    footerCellTemplate: '<div class="text-right grid-text">{{grid.appScope.account.baseCurrency}}{{grid.appScope.FORMAT_NUM(grid.appScope.ratesTtl.baseAmtTtl)}}</div>',
                    cellTemplate: '<span class="pull-right grid-text" ng-class="row.entity.isPay?\'text-danger\':\'text-primary\'"><span ng-if="row.entity.isPay">(</span><span>{{grid.appScope.FORMAT_NUM(row.entity.writeOffBaseAmt)}}</span><span ng-if="row.entity.isPay">)</span></span>',
                    displayName: $translate.instant('stats.stat.guideRates.baseAmt') + "(" + $rootScope.account.baseCurrency + ")"},      
                { field: 'payer',
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.settlementObj')},                
                { field: 'diffAmount',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ], 
                    minWidth:100, 
                    cellTemplate: '<span class="pull-right mr-5" ng-class="row.entity.isPay?\'text-danger\':\'text-primary\'"><span ng-if="row.entity.isPay">(</span><span>{{grid.appScope.FORMAT_NUM(row.entity.diffAmount)}}</span><span ng-if="row.entity.isPay">)</span></span>',
                    displayName: $translate.instant('stats.stat.notPaid')},
                { field: 'isPayTxt',
                    minWidth:80, 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [ 
                        { value: $translate.instant('stats.stat.isPay'), label: $translate.instant('stats.stat.isPay')}, 
                        { value: $translate.instant('stats.stat.isnotpay'), label: $translate.instant('stats.stat.isnotpay')}]
                    },
                    displayName: $translate.instant('stats.stat.guideRates.isPay')},
                { field: 'dnNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.guideRates.dnNum')},
                { field: 'paymentTerm',
                    minWidth:50, 
                    displayName: $translate.instant('stats.stat.guideRates.paymentTerm')},
                { field: 'etd',
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.guideRates.etd')},
                { field: 'accountDate',
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.guideRates.accountDate')},
                { field: 'inputBy',
                    minWidth:100,
                    displayName: $translate.instant('stats.stat.inputBy')},
                { field: 'inputTime',
                    minWidth:120,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.inputTime | date:\'medium\'}}">{{row.entity.inputTime | date:\'mediumDate\'}}</span>',
                    displayName: $translate.instant('stats.stat.feeInput')},
                { field: 'serviceTerm',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.serviceTerm'), 
                    cellTemplate: '<span class="ml-5" translate="global.{{row.entity.serviceTerm}}" ng-if="row.entity.serviceTerm"></span>'},
                { field: 'currency',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.currency')},
                { field: 'unit',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.unit')},
                { field: 'priceDec',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.price')},
                { field: 'countDec',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.count')},     
                { field: 'shouldCount',
                    type:'number',
                    minWidth:100, 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.guideRates.shouldCount')},    
                { field: 'diffCount',
                    minWidth:100, 
                    type: "boolean",
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('stats.stat.guideRates.diffCount'), 
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.diffCount"translate="global.yes_answer" class="label label-danger">yes_answer</span><span ng-hide="row.entity.diffCount"translate="global.no_answer" class="label label-info">no_answer</span></span>'},
                { field: 'totalDec',
                    minWidth:100, 
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('stats.stat.guideRates.total')},
                { field: 'payExchange',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.payExchange')},
                { field: 'pkgNum',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    type:'number',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.pkgs')}, 
                { field: 'gw',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    type:'number',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.gw')},
                { field: 'vol',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    type:'number',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.vol')},
                { field: 'chargeWeight',
                    type:'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.chargeWeight')},
                { field: 'chargeWeightKg', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.chargeWeight') + "(KG)", 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'chargeWeightCbm', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.chargeWeight') + "(CBM)", 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'volumes',
                    type:'number',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.volumes')},
                { field: 'destName',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.destName')},
                { field: 'mblNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.mblNum')},
                { field: 'soNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.soNum')},
                { field: 'clientSoNum', 
                    minWidth:150, 
                    displayName: $translate.instant('autopsApp.guide.clientWmsInNum')},
                { field: 'poNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.poNum')},
                { field: 'hblNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.hblNum')},
                { field: 'ctnMawb',
                    minWidth:150,
                    displayName: $translate.instant('stats.stat.ctnNumsStr')},
                { field: 'billNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.guideRates.billNum')},
                { field: 'mcNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.mcNum')},
                { field: 'billMakeDate', 
                    displayName: $translate.instant('stats.stat.billMakeDate'), 
                    width:120},                
                { field: 'exchangeRate',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.exchangeRate')},
                { field: 'baseAmt',
                    type:'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ], 
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.guideRates.baseAmt')}, 
                { field: 'invoiceNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.guideRates.invoiceNum')},
                { field: 'writeOffCode',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.guideRates.writeOffCode')},
                { field: 'remarks',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.guideRates.remarks')},
                { field: 'salesName',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.filterBySales')},
                { field: 'cusName',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.filterByCs')},                    
                { field: 'opName',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.filterByOperator')},
                { field: 'docName',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.filterByDocument')},
                { field: 'customerName',
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.filterByClient')},
                { field: 'shipperName',
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.filterByShiperCnee')},
                { field: 'bookingToName',
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.filterByColoader')},
                { field: 'deliveryName',
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.filterByAgent')},
                { field: 'clearanceAgent',
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.clearanceAgent')},
                { field: 'itemCode',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.itemCode')},
                { field: 'destCountryCode',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.destCountryCode')}, 
                { field: 'porName', 
                    displayName: $translate.instant('stats.stat.porName'),
                    minWidth:100},
                { field: 'polName', 
                    displayName: $translate.instant('stats.stat.polName'),
                    minWidth:100},
                { field: 'podName', 
                    displayName: $translate.instant('stats.stat.podName'),
                    minWidth:100},
                { field: 'destName', 
                    displayName: $translate.instant('stats.stat.destName'), 
                    minWidth:100},
                { field: 'deliveryTo', 
                    displayName: $translate.instant('stats.stat.deliveryto'), 
                    minWidth:100},
                { field: 'deliveryTime',
                    minWidth:120,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.deliveryTime | date:\'medium\'}}">{{row.entity.deliveryTime | date:\'mediumDate\'}}</span>',
                    displayName: $translate.instant('stats.stat.deliveryTime')},
                { field: 'wmsInTime',
                    minWidth:120,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.wmsInTime | date:\'medium\'}}">{{row.entity.wmsInTime | date:\'mediumDate\'}}</span>',
                    displayName: $translate.instant('stats.stat.wmsInTime')},
                { field: 'ataTime',
                    minWidth:120,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.ataTime | date:\'medium\'}}">{{row.entity.ataTime | date:\'mediumDate\'}}</span>',
                    displayName: $translate.instant('stats.stat.ataTime')},
                { field: 'productName', 
                    displayName: $translate.instant('stats.stat.productName'), 
                    minWidth:120},
                { field: 'mjobNum', 
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.mjobNum')},
                { field: 'carrier',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.bcarrier')},
                { field: 'vsl', 
                    displayName: $translate.instant('stats.stat.vsl'), 
                    minWidth:100},
                { field: 'voy', 
                    displayName: $translate.instant('stats.stat.voy'), 
                    minWidth:100},
                { field: 'team', 
                    displayName: $translate.instant('stats.stat.bteam'), 
                    minWidth:100},
                { field: 'branch',
                    displayName: $translate.instant('stats.stat.bbranch'), 
                    minWidth:100},
                { field: 'handleType',
                    displayName: $translate.instant('stats.stat.handleType'), 
                    minWidth:100},
                { field: 'orderRemarks',
                    displayName: $translate.instant('stats.stat.orderRemarks'), 
                    minWidth:100}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 141;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }     
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);                   
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "chargesReport";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);                   
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "chargesReport";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                gridApi.selection.on.rowSelectionChanged($scope,function(row){
                    selectionChange();
                });
                gridApi.selection.on.rowSelectionChangedBatch($scope,function(row){
                    selectionChange();
                });
            }
        }

        $scope._onClientSelected = function(data) {
        	$scope.statParams.shipperId = data.id;
        }
        $scope._onCustomerSelected = function(data) {
        	$scope.statParams.customerId = data.id;
        }
        $scope._onColoaderSelected = function(data) {
        	$scope.statParams.bookingToId = data.id;
        }
        $scope._onAgentSelected = function(data) {
        	$scope.statParams.deliveryId = data.id;
        }
        $scope._onPayerSelected = function(data) {
        	$scope.statParams.venderId = data.id;
        }
        $scope._onItemSelected = function(data) {
            $scope.statParams.itemId = data.id;
        }
        $scope.checkPayer = function() {
            if(!$scope.statParams.payer){
                $scope.statParams.venderId = null;
            }
        }


        $scope.setFdValue = function(fd, val) {
            if('locked' == fd && $scope.byDnView){
                return;
            }
            $scope.statParams[fd]= val;
        }
        
        $scope.delShipmentType = function(index) {            
            $scope.statParams.shipmentTypes.splice(index, 1);
        };
        $scope.newShipmentType = null;
        $scope.shipmentTypeSelected = function(item) {
            if(!$scope.statParams.shipmentTypes){
                $scope.statParams.shipmentTypes = [];
            }
            $scope.statParams.shipmentTypes.push(item);
        }

        $scope.searchType = 'dnNum';
        $scope.setSearchType = function(type) {
            $scope.searchType = type;
        }
        $scope.searchStr = null;
        $scope.clearAll = function () {
            $scope.gridOptions.data = [];   
            $scope.dnGridOptions.data = [];
            initContent();
            $translate.refresh();
        }

        $scope.noBizType =function(){      
            return $scope.searchType == 'billNum' || $scope.searchType == 'dnNum' 
                    || $scope.searchType == 'statementCode' || $scope.searchType == 'invoiceNum'
                    || $scope.searchType == 'dnNumDnFuzzy' || $scope.searchType == 'dnNumCnFuzzy'
                    || $scope.searchType == 'jobFmsFuzzy';  
        }
        
        $scope.getRatesByRefNum =function(bizType){         
            if(!bizType){
                if($scope.noBizType()){
                    if("jobFmsFuzzy" == $scope.searchType){
                        bizType = "FMS";
                    }
                    else {
                        bizType = $scope.searchType;
                    }                    
                }
                else {
                    return;
                }
            }
            if(!$scope.searchStr){
                layer.msg($translate.instant('stats.stat.numNullAlert'))
                return;
            }
            layer.load(1, {shade: 0.3});
            var paramCopy = angular.copy($scope.statParams);
            paramCopy.searchType = $scope.searchType;
            paramCopy.companyId = $rootScope.account.companyId;
            paramCopy.searchStr = $scope.searchStr;
            paramCopy.bizType = bizType;
            paramCopy.forWriteOff = true;
            GuideRates.getRatesByRefNum(paramCopy, addData, $rootScope.ALERT_ERROR);
        }        

        var addData = function(result){
            layer.closeAll(); 
            if (!result || result.length == 0) {
                layer.msg($translate.instant('stats.stat.noData'));
                return;
            }
            addNewData(result);
            initContent(); 
        }
        var addNewData = function(result){
            if(!$scope.gridOptions.data){
                $scope.gridOptions.data = [];
            }
            var idSet = new Set();
            angular.forEach($scope.gridOptions.data, function(data){
                idSet.add(data.idStr);
            })
            angular.forEach(result, function(data){
                if((!$scope.noBizType() || $scope.searchType == 'jobFmsFuzzy')
                        && $scope.statParams.venderId && $scope.statParams.venderId != data.venderId){

                }
                else if((!$scope.noBizType() || $scope.searchType == 'jobFmsFuzzy')
                        && $scope.statParams.payCurrency && $scope.statParams.payCurrency != data.payCurrency){

                }
                else if(!idSet.has(data.idStr)){
                    idSet.add(data.idStr);
                    $scope.gridOptions.data.splice(0, 0, data);
                }  
            })
        }

        $scope.searchGuidePop = function() {            
            if($rootScope.HAVENOAUTH('FMS') && $rootScope.HAVENOAUTH('FBA') && $rootScope.HAVENOAUTH('WMS')){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }

            $uibModal.open({
                templateUrl: 'app/entities/guide/guide-search-dialog.html',
                controller: 'GuideSearchDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return getEtdPeriod();
                    }]
                }
            }).result.then(function(result) {
                addData(result);
            }, function() {
            });
        }

        function getEtdPeriod(){
            var etdPeriod = {
                forChargesReport: true,
                companyId: $rootScope.account.companyId,
                statParams: angular.copy($scope.statParams)
            };
            etdPeriod.etdFM = $scope.statParams.etdFM;
            etdPeriod.etdTO = $scope.statParams.etdTO;
            etdPeriod.shipperId = $scope.statParams.shipperId;
            etdPeriod.clientNamePH = $scope.statParams.clientName;            
            etdPeriod.customerId = $scope.statParams.customerId;
            etdPeriod.customerNamePH = $scope.statParams.customerName;
            etdPeriod.bookingToId = $scope.statParams.bookingToId;
            etdPeriod.coloaderNamePH = $scope.statParams.coloaderName;
            etdPeriod.deliveryId = $scope.statParams.deliveryId;
            etdPeriod.agentNamePH = $scope.statParams.agentName;
            return etdPeriod;
        }

        $scope.searchParcelsPop = function(shipmentType) {    
            if($rootScope.HAVENOAUTH('TMS') && shipmentType == "TMS"){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            if($rootScope.HAVENOAUTH('PARCEL') && shipmentType == "PARCEL"){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            var etdPeriod = getEtdPeriod();
            etdPeriod.shipmentType = shipmentType;
            $uibModal.open({
                templateUrl: 'app/entities/express-parcel/express-parcel-detail.html',
                controller: 'ExpressParcelDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return etdPeriod;
                    }]
                }
            }).result.then(function(result) {
                addData(result);
            }, function() {
            });
        }

        $scope.searchWmsInPop = function(isOut) {    
            if($rootScope.HAVENOAUTH('WMS')){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
            var searchParams = {
                companyId: $rootScope.account.companyId
            };
            var wmsFilter = getEtdPeriod();
            if(isOut == null){
                wmsFilter.filterType = "TD";
            }
            else {
                wmsFilter.isOut = isOut;
            }
            wmsFilter.venderId = $scope.statParams.shipperId;
            wmsFilter.clientNamePh = $scope.statParams.clientName;
            
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-search.html',
                controller: 'WmsInSearchController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return wmsFilter;
                    }]
                }
            }).result.then(function(result) {
                addData(result);
            }, function() {
            });
        }


        /************************ 费用核销 ***********************/
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            if ($rootScope.account.authorities.indexOf("FUN_WRITE_OFF") === -1) {                
                $uibModalInstance.dismiss('cancel');
                $rootScope.FORBIDDEN_BY_ALERT('FUN_WRITE_OFF');
                return;
            }
            // if ($rootScope.HAVEAUTH('FMIS') && $rootScope.HAVENOAUTH('ROLE_ACCOUNT')) {
            //     $uibModalInstance.dismiss('cancel');
            //     $rootScope.FORBIDDEN_BY_ALERT('ROLE_ACCOUNT');
            //     return;
            // }        
            initGrid();    

            if(entity.autoCheck){
                $scope.isSaving = true;
                AutoCheck.getWriteOffRates(entity.autoCheck, function(result) {
                    $scope.isSaving = false;
                    onLoadDataSuccess(result);
                }, function(response) {
                    $scope.isSaving = false;
                });
            }
            else if(entity.guideRates){
                $scope.isSaving = true;
                GuideRates.getWriteOffRates(entity.guideRates, function(result) {
                    $scope.isSaving = false;
                    onLoadDataSuccess(result);
                }, function(response) {
                    $scope.isSaving = false;
                });
            } else if(entity.dcnRequest){
                $scope.isSaving = true;
                GuideRates.getWriteOffRatesByDnNum({
                    companyId: entity.dcnRequest.companyId,
                    dnNum: entity.dcnRequest.dnNum
                }, function(result) {
                    $scope.isSaving = false;
                    onLoadDataSuccess(result);
                }, function(response) {
                    $scope.isSaving = false;
                });
            }
            if(entity.accountCashier){
                $scope._onTransactionSelected(entity.accountCashier);
            }
            else {
                loadTransactions();
            }
        });

        function initGrid() {
            if($scope.byDnView){
                gridStateCopy = $scope.dnGridApi.saveState.save();
                $scope.statParams.locked = true;
                $translate.refresh();
            }
            else {
                gridStateCopy = $scope.gridApi.saveState.save();
            }
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: $scope.byDnView?142:141
            }, function(result){
                
                gridStateObj = result;
                if(!gridStateObj || !gridStateObj.id){
                    if($scope.byDnView){
                        $scope.dnGridApi.saveState.restore($scope, gridStateCopy);
                    }
                    else {
                        $scope.gridApi.saveState.restore($scope, gridStateCopy);
                        $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.OPTIONS);
                    }                  
                    return;
                }   
                if($scope.byDnView){
                    $scope.dnGridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                }
                else {
                    $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.OPTIONS);
                }            
            })            
        }



        $scope.accountCashier = {};
        $scope.accountCashier.transactionDate = new Date();
        $scope.getWriteOffCode = function () {
            WriteOff.getWriteOffCode({companyId: $rootScope.account.companyId}, function(data){
                $scope.accountCashier.transactionRef = data.value;
            })
            if(!$scope.accountCashier.transactionDate){
                $scope.accountCashier.transactionDate = new Date();
            }
        }
        $scope.originalAmtChange = function() {
            if(!$scope.accountCashier.originalCurrency || $scope.accountCashier.originalAmt == null){
                return;
            }
            updateAccountCashier();
        }

        $scope.ratesTtl = {};
        var holdSelect = false;
        var selectionChange = function() {
            if(holdSelect){
                return;
            }
            var selectedRows = $scope.gridApi.selection.getSelectedRows();
            var currencies = new Set();
            $scope.ratesTtl.selectedBaseAmtTtl = 0;
            $scope.ratesTtl.curSelectedTtlList = [];
            $scope.ratesTtl.curSelectedExpList = [];
            $scope.ratesTtl.curSelectedRevList = [];

            angular.forEach(selectedRows, function(data){
                currencies.add(data.payCurrency);    
                if(data.isPay){
                    $scope.ratesTtl.selectedBaseAmtTtl -= data.writeOffBaseAmt;
                }
                else {
                    $scope.ratesTtl.selectedBaseAmtTtl += data.writeOffBaseAmt;
                }
            });
            currencies.forEach(function(currency){
                var currencyTtl = 0;
                var expTtl = 0;
                var revTtl = 0;
                angular.forEach(selectedRows, function(data){
                    if (data.payCurrency == currency){
                        if(data.isPay){
                            currencyTtl -= data.thisAmount - 0;
                            expTtl += data.thisAmount - 0;
                        }
                        else {
                            currencyTtl += data.thisAmount - 0;
                            revTtl += data.thisAmount - 0;                            
                        }                        
                    }
                })
                $scope.ratesTtl.curSelectedTtlList.push({
                    currency: currency,
                    total: DataUtils.round(currencyTtl, 2)
                });      
                $scope.ratesTtl.curSelectedExpList.push({
                    currency: currency,
                    total: DataUtils.round(expTtl, 2)
                });      
                $scope.ratesTtl.curSelectedRevList.push({
                    currency: currency,
                    total: DataUtils.round(revTtl, 2)
                });      
            });
            $scope.ratesTtl.selectedBaseAmtTtl = DataUtils.round($scope.ratesTtl.selectedBaseAmtTtl, 2);
            $scope.ratesTtl.unselectedBaseAmtTtl = DataUtils.round($scope.ratesTtl.baseAmtTtl - $scope.ratesTtl.selectedBaseAmtTtl, 2);
            $translate.refresh();
        }      

        $scope.writeOffAll = function() {
            initThisAmount();
            if(!$scope.accountCashier.companyId){
                $scope.accountCashier.originalAmt = $scope.ratesTtl.orgAmtTtl - 0;
            }            
            updateAccountCashier();
        }

        $scope.clearDiff = function() {
            updateThisAmount();
        }

        var initThisAmount = function() {
            angular.forEach($scope.gridOptions.data, function(data){
                data.thisAmount = data.diffAmount - 0;
            })
            updateList();
        }
        var updateThisAmountMix = function(writeOffBaseAmt) {
            writeOffBaseAmt = writeOffBaseAmt - 0;
            //付款核销
            if(writeOffBaseAmt < 0){
                //收款正数 付款负数
                angular.forEach($scope.gridOptions.data, function(data){
                    if(!data.isPay && data.writeOffBaseAmt > 0){
                        writeOffBaseAmt -= data.writeOffBaseAmt;
                    }
                    else if(data.isPay && data.writeOffBaseAmt < 0){
                        writeOffBaseAmt += data.writeOffBaseAmt;
                    }
                })
                //收款负数
                angular.forEach($scope.gridOptions.data, function(data){
                    if(!data.isPay && data.writeOffBaseAmt < 0 && data.payCurrency == $scope.accountCashier.originalCurrency){
                        if(Math.abs(data.writeOffBaseAmt) <= Math.abs(writeOffBaseAmt)){
                            writeOffBaseAmt -= data.writeOffBaseAmt;
                        }
                        else{
                            data.writeOffBaseAmt = DataUtils.round(writeOffBaseAmt, 2);
                            data.thisAmount = DataUtils.round(writeOffBaseAmt/data.actualExchangeRate, 2) - 0;
                            writeOffBaseAmt = 0;
                        }
                    }
                })                
                //付款正数
                angular.forEach($scope.gridOptions.data, function(data){
                    if(data.isPay && data.writeOffBaseAmt > 0 && data.payCurrency == $scope.accountCashier.originalCurrency){
                        if(Math.abs(data.writeOffBaseAmt) <= Math.abs(writeOffBaseAmt)){
                            writeOffBaseAmt += data.writeOffBaseAmt;
                        }
                        else{
                            data.writeOffBaseAmt = -DataUtils.round(writeOffBaseAmt, 2);
                            data.thisAmount = -DataUtils.round(writeOffBaseAmt/data.actualExchangeRate, 2) - 0;
                            writeOffBaseAmt = 0;
                        }
                    }
                })
                //收款负数
                angular.forEach($scope.gridOptions.data, function(data){
                    if(!data.isPay && data.writeOffBaseAmt < 0 && data.payCurrency != $scope.accountCashier.originalCurrency){
                        if(Math.abs(data.writeOffBaseAmt) <= Math.abs(writeOffBaseAmt)){
                            writeOffBaseAmt -= data.writeOffBaseAmt;
                        }
                        else{
                            data.writeOffBaseAmt = DataUtils.round(writeOffBaseAmt, 2);
                            data.thisAmount = DataUtils.round(writeOffBaseAmt/data.actualExchangeRate, 2) - 0;
                            writeOffBaseAmt = 0;
                        }
                    }
                })
                //付款正数
                angular.forEach($scope.gridOptions.data, function(data){
                    if(data.isPay && data.writeOffBaseAmt > 0 && data.payCurrency != $scope.accountCashier.originalCurrency){
                        if(Math.abs(data.writeOffBaseAmt) <= Math.abs(writeOffBaseAmt)){
                            writeOffBaseAmt += data.writeOffBaseAmt;
                        }
                        else{
                            data.writeOffBaseAmt = -DataUtils.round(writeOffBaseAmt, 2);
                            data.thisAmount = -DataUtils.round(writeOffBaseAmt/data.actualExchangeRate, 2) - 0;
                            writeOffBaseAmt = 0;
                        }
                    }
                })
            }
            //收款核销
            else {
                //付款正数 收款负数
                angular.forEach($scope.gridOptions.data, function(data){
                    if(data.isPay && data.writeOffBaseAmt > 0){
                        writeOffBaseAmt += data.writeOffBaseAmt;
                    }
                    else if(!data.isPay && data.writeOffBaseAmt < 0){
                        writeOffBaseAmt -= data.writeOffBaseAmt;
                    }
                })
                //付款负数
                angular.forEach($scope.gridOptions.data, function(data){
                    if(data.isPay && data.writeOffBaseAmt < 0 && data.payCurrency == $scope.accountCashier.originalCurrency){
                        if(Math.abs(data.writeOffBaseAmt) <= Math.abs(writeOffBaseAmt)){
                            writeOffBaseAmt += data.writeOffBaseAmt;
                        }
                        else{
                            data.writeOffBaseAmt = -DataUtils.round(writeOffBaseAmt, 2);
                            data.thisAmount = -DataUtils.round(writeOffBaseAmt/data.actualExchangeRate, 2) - 0;
                            writeOffBaseAmt = 0;
                        }
                    }
                })
                //收款正数
                angular.forEach($scope.gridOptions.data, function(data){
                    if(!data.isPay && data.writeOffBaseAmt > 0 && data.payCurrency == $scope.accountCashier.originalCurrency){
                        if(Math.abs(data.writeOffBaseAmt) <= Math.abs(writeOffBaseAmt)){
                            writeOffBaseAmt -= data.writeOffBaseAmt;
                        }
                        else{
                            data.writeOffBaseAmt = DataUtils.round(writeOffBaseAmt, 2);
                            data.thisAmount = DataUtils.round(writeOffBaseAmt/data.actualExchangeRate, 2) - 0;
                            writeOffBaseAmt = 0;
                        }
                    }
                })
                //付款负数
                angular.forEach($scope.gridOptions.data, function(data){
                    if(data.isPay && data.writeOffBaseAmt < 0 && data.payCurrency != $scope.accountCashier.originalCurrency){
                        if(Math.abs(data.writeOffBaseAmt) <= Math.abs(writeOffBaseAmt)){
                            writeOffBaseAmt += data.writeOffBaseAmt;
                        }
                        else{
                            data.writeOffBaseAmt = -DataUtils.round(writeOffBaseAmt, 2);
                            data.thisAmount = -DataUtils.round(writeOffBaseAmt/data.actualExchangeRate, 2) - 0;
                            writeOffBaseAmt = 0;
                        }
                    }
                })
                //收款正数
                angular.forEach($scope.gridOptions.data, function(data){
                    if(!data.isPay && data.writeOffBaseAmt > 0 && data.payCurrency != $scope.accountCashier.originalCurrency){
                        if(Math.abs(data.writeOffBaseAmt) <= Math.abs(writeOffBaseAmt)){
                            writeOffBaseAmt -= data.writeOffBaseAmt;
                        }
                        else{
                            data.writeOffBaseAmt = DataUtils.round(writeOffBaseAmt, 2);
                            data.thisAmount = DataUtils.round(writeOffBaseAmt/data.actualExchangeRate, 2) - 0;
                            writeOffBaseAmt = 0;
                        }
                    }
                })
            }
        }
        var updateThisAmountNotMix = function(writeOffBaseAmt) {
            writeOffBaseAmt = writeOffBaseAmt - 0;
            //付款核销
            if(writeOffBaseAmt < 0){
                //收款正数 付款负数
                angular.forEach($scope.gridOptions.data, function(data){
                    if(!data.isPay && data.thisAmount > 0){
                        writeOffBaseAmt -= (data.thisAmount - 0);
                    }
                    else if(data.isPay && data.thisAmount < 0){
                        writeOffBaseAmt += data.thisAmount - 0;
                    }
                })
                //收款负数
                angular.forEach($scope.gridOptions.data, function(data){
                    if(!data.isPay && data.thisAmount < 0 && data.payCurrency == $scope.accountCashier.originalCurrency){
                        if(Math.abs(data.thisAmount) <= Math.abs(writeOffBaseAmt)){
                            writeOffBaseAmt -= (data.thisAmount - 0);
                        }
                        else{
                            data.thisAmount = DataUtils.round(writeOffBaseAmt, 2);
                            writeOffBaseAmt = 0;
                        }
                    }
                })                
                //付款正数
                angular.forEach($scope.gridOptions.data, function(data){
                    if(data.isPay && data.thisAmount > 0 && data.payCurrency == $scope.accountCashier.originalCurrency){
                        if(Math.abs(data.thisAmount) <= Math.abs(writeOffBaseAmt)){
                            writeOffBaseAmt += (data.thisAmount - 0);
                        }
                        else{
                            data.thisAmount = -DataUtils.round(writeOffBaseAmt, 2);
                            writeOffBaseAmt = 0;
                        }
                    }
                })
                //收款负数
                angular.forEach($scope.gridOptions.data, function(data){
                    if(!data.isPay && data.thisAmount < 0 && data.payCurrency != $scope.accountCashier.originalCurrency){
                        if(Math.abs(data.thisAmount) <= Math.abs(writeOffBaseAmt)){
                            writeOffBaseAmt -= (data.thisAmount - 0);
                        }
                        else{
                            data.thisAmount = DataUtils.round(writeOffBaseAmt, 2);
                            writeOffBaseAmt = 0;
                        }
                    }
                })
                //付款正数
                angular.forEach($scope.gridOptions.data, function(data){
                    if(data.isPay && data.thisAmount > 0 && data.payCurrency != $scope.accountCashier.originalCurrency){
                        if(Math.abs(data.thisAmount) <= Math.abs(writeOffBaseAmt)){
                            writeOffBaseAmt += (data.thisAmount - 0);
                        }
                        else{
                            data.thisAmount = -DataUtils.round(writeOffBaseAmt, 2);
                            writeOffBaseAmt = 0;
                        }
                    }
                })
            }
            //收款核销
            else {
                //付款正数 收款负数
                angular.forEach($scope.gridOptions.data, function(data){
                    if(data.isPay && data.thisAmount > 0){
                        writeOffBaseAmt += data.thisAmount - 0;
                    }
                    else if(!data.isPay && data.thisAmount < 0){
                        writeOffBaseAmt -= data.thisAmount - 0;
                    }
                })
                //付款负数
                angular.forEach($scope.gridOptions.data, function(data){
                    if(data.isPay && data.thisAmount < 0 && data.payCurrency == $scope.accountCashier.originalCurrency){
                        if(Math.abs(data.thisAmount) <= Math.abs(writeOffBaseAmt)){
                            writeOffBaseAmt += data.thisAmount - 0;
                        }
                        else{
                            data.thisAmount = -DataUtils.round(writeOffBaseAmt, 2);
                            writeOffBaseAmt = 0;
                        }
                    }
                })
                //收款正数
                angular.forEach($scope.gridOptions.data, function(data){
                    if(!data.isPay && data.thisAmount > 0 && data.payCurrency == $scope.accountCashier.originalCurrency){
                        if(Math.abs(data.thisAmount) <= Math.abs(writeOffBaseAmt)){
                            writeOffBaseAmt -= data.thisAmount - 0;
                        }
                        else{
                            data.thisAmount = DataUtils.round(writeOffBaseAmt, 2);
                            writeOffBaseAmt = 0;
                        }
                    }
                })
                //付款负数
                angular.forEach($scope.gridOptions.data, function(data){
                    if(data.isPay && data.thisAmount < 0 && data.payCurrency != $scope.accountCashier.originalCurrency){
                        if(Math.abs(data.thisAmount) <= Math.abs(writeOffBaseAmt)){
                            writeOffBaseAmt += data.thisAmount - 0;
                        }
                        else{
                            data.thisAmount = -DataUtils.round(writeOffBaseAmt, 2);
                            writeOffBaseAmt = 0;
                        }
                    }
                })
                //收款正数
                angular.forEach($scope.gridOptions.data, function(data){
                    if(!data.isPay && data.thisAmount > 0 && data.payCurrency != $scope.accountCashier.originalCurrency){
                        if(Math.abs(data.thisAmount) <= Math.abs(writeOffBaseAmt)){
                            writeOffBaseAmt -= data.thisAmount - 0;
                        }
                        else{
                            data.thisAmount = DataUtils.round(writeOffBaseAmt, 2);
                            writeOffBaseAmt = 0;
                        }
                    }
                })
            }
        }


        var updateThisAmount = function() {
            if(!$scope.accountCashier.exchangeRate){
                $scope.accountCashier.exchangeRate = exchangeRates[$scope.accountCashier.originalCurrency];   
            }
            initThisAmount();   
            var writeOffBaseAmt = DataUtils.round($scope.accountCashier.originalAmt * $scope.accountCashier.exchangeRate, 2);
            $scope.accountCashier.baseAmt = writeOffBaseAmt - 0;

            if(Math.abs($scope.ratesTtl.baseAmtTtl) < Math.abs(writeOffBaseAmt)){
                return;
            }

            if(isMix || !isSameEx){
                var amt = writeOffBaseAmt - 1;
                updateThisAmountMix(amt);
            }
            else {
                var amt = $scope.accountCashier.originalAmt - 0;
                updateThisAmountNotMix(amt);
            }
            updateList();
        }
        var updateAccountCashier = function() {
            if(!$scope.accountCashier.exchangeRate){
                $scope.accountCashier.exchangeRate = exchangeRates[$scope.accountCashier.originalCurrency];   
            }
            $scope.accountCashier.baseAmt = DataUtils.round($scope.accountCashier.originalAmt * $scope.accountCashier.exchangeRate);            
            $scope.accountCashier.invoiceAmt = $scope.ratesTtl.baseAmtTtl - 0;
            if($scope.accountCashier.originalAmt == $scope.ratesTtl.orgAmtTtl){
                $scope.accountCashier.diffAmt = 0;
            }
            else {
                $scope.accountCashier.diffAmt = DataUtils.round($scope.accountCashier.baseAmt - $scope.ratesTtl.baseAmtTtl);
            }
            //原币核销检查
            $scope.originalBalance = 0;
            if($scope.accountCashier && $scope.accountCashier.originalCurrency && !isMix){
                angular.forEach($scope.gridOptions.data, function(data){
                    if (data.isPay){
                        $scope.originalBalance -= data.thisAmount - 0;
                    }
                    else {
                        $scope.originalBalance += data.thisAmount - 0;
                    }
                })  
                $scope.originalBalance = (!$scope.accountCashier.originalAmt?0:$scope.accountCashier.originalAmt) - $scope.originalBalance;
                $scope.originalBalance = DataUtils.round($scope.originalBalance, 2);

                if($scope.originalBalance == 0 && isSameEx){
                    $scope.accountCashier.diffAmt = 0;            
                }
            }
        }

        var isSameEx = true;
        var isMix = false;
        var updateList = function(resetWriteOffExchangeRate) {
            if(!$scope.accountCashier.exchangeRate && $scope.accountCashier.originalCurrency){
                $scope.accountCashier.exchangeRate = exchangeRates[$scope.accountCashier.originalCurrency];   
            }
            isSameEx = true;
            isMix = false;
            var currencies = new Set();
            $scope.ratesTtl.baseAmtTtl = 0;

            $scope.ratesTtl.curTtlList = [];
            $scope.ratesTtl.curRevList = [];
            $scope.ratesTtl.curExpList = [];
            angular.forEach($scope.gridOptions.data, function(data){
                currencies.add(data.payCurrency);
                if (!data.actual){
                    data.actual = 0;
                }                
                if(!data.diffAmount){
                    data.diffAmount = DataUtils.round(data.payAmount - data.actual, 2) - 0;
                }
                if(!data.thisAmount && data.thisAmount != 0){
                    data.thisAmount = data.diffAmount;
                }                
                if(data.diffAmount > 0 && (data.thisAmount < 0 || data.thisAmount > data.diffAmount)){
                    data.thisAmount = data.diffAmount;
                    layer.msg($translate.instant('autopsApp.opsClient.invalidInput'));
                }
                if(data.diffAmount < 0 && (data.thisAmount > 0 || data.thisAmount < data.diffAmount)){
                    data.thisAmount = data.diffAmount;
                    layer.msg($translate.instant('autopsApp.opsClient.invalidInput'));
                } 
                if(!data.actualExchangeRate || resetWriteOffExchangeRate){
                    if(data.payCurrency == $scope.accountCashier.originalCurrency){
                        data.actualExchangeRate = $scope.accountCashier.exchangeRate;
                    }
                    else {
                        if(data.exchangeRate){
                            data.actualExchangeRate = data.exchangeRate;
                        }
                        else {
                            data.actualExchangeRate = exchangeRates[data.payCurrency];
                        }
                    }
                }
                data.writeOffBaseAmt = DataUtils.round(data.actualExchangeRate * data.thisAmount, 2);       
                if(data.isPay){
                    $scope.ratesTtl.baseAmtTtl -= data.writeOffBaseAmt;
                    data.isPayTxt = $translate.instant('stats.stat.isPay');
                }
                else {
                    $scope.ratesTtl.baseAmtTtl += data.writeOffBaseAmt;
                    data.isPayTxt = $translate.instant('stats.stat.isnotpay');
                }
                if(data.actualExchangeRate != $scope.accountCashier.exchangeRate){
                    isSameEx = false;
                }
            });
            if($scope.accountCashier.exchangeRate){
                $scope.ratesTtl.orgAmtTtl = DataUtils.round($scope.ratesTtl.baseAmtTtl/$scope.accountCashier.exchangeRate);
            }
            $scope.ratesTtl.baseAmtTtl = DataUtils.round($scope.ratesTtl.baseAmtTtl, 2)
            if(currencies.size > 1){
                isMix = true;
            }          
            else if(currencies.size == 1 && !currencies.has($scope.accountCashier.originalCurrency)){
                isMix = true;
            }

            currencies.forEach(function(currency){
                var currencyTtl = 0;
                var expTtl = 0;
                var revTtl = 0;
                angular.forEach($scope.gridOptions.data, function(data){
                    if (data.payCurrency == currency){
                        if(data.isPay){
                            currencyTtl -= data.thisAmount - 0;
                            expTtl += data.thisAmount - 0;
                        }
                        else {
                            currencyTtl += data.thisAmount - 0;
                            revTtl += data.thisAmount - 0;
                        }
                        
                    }
                })
                $scope.ratesTtl.curTtlList.push({
                    currency: currency,
                    total: DataUtils.round(currencyTtl, 2)
                });    
                $scope.ratesTtl.curExpList.push({
                    currency: currency,
                    total: DataUtils.round(expTtl, 2)
                });  
                $scope.ratesTtl.curRevList.push({
                    currency: currency,
                    total: DataUtils.round(revTtl, 2)
                });   
                if(!isMix){
                    $scope.ratesTtl.orgAmtTtl = DataUtils.round(currencyTtl);
                }
            });

            if($scope.byDnView){
                initByDnData();
                selectionDnChange();
            }
            else {
                clearCurDn();
                selectionChange();
            }        
            updateAccountCashier();            
            $timeout(function (){
                resize();
                $translate.refresh();
            })            
        }


        $scope.currencyChange = function() { 
            if(!$scope.accountCashier.companyId){
                $scope.accountCashier.exchangeRate = null;
            }
            updateList(true);
            if($scope.accountCashier.originalAmt == null){
                $scope.accountCashier.originalAmt = $scope.ratesTtl.orgAmtTtl;                
            }
            updateAccountCashier();                 
        }

        $scope.datePickerForEtd = {};
        $scope.datePickerForEtd.status = {
            opened: false
        };
        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };
        $scope.datePickerForEta = {};
        $scope.datePickerForEta.status = {
            opened: false
        };
        $scope.datePickerForEtaOpen = function($event) {
            $scope.datePickerForEta.status.opened = true;
        };
        $scope.ledgers = [];
        $scope.cashLedgers = [];
        function generateShowLedger(type) {
            for (var i = 0; i < $scope.ledgers.length; i++) {
                var titleTemp = "";
                if ($scope.ledgers[i].level > 1) {
                    var subLen = 0;
                    for (var j = 1; j < $scope.ledgers[i].level; j++) {
                        if (j == 2) {
                            subLen = subLen + 2;
                        } else {
                            subLen = subLen + 4;
                        }
                        var ledger = $scope.ledgers[i].code.substr(0, subLen);

                        for (var ii = 0; ii < $scope.ledgers.length; ii++) {
                            if ($scope.ledgers[ii].code == ledger) {
                                if (lang == "en") {
                                    titleTemp = titleTemp + $scope.ledgers[ii].enName + "/ ";
                                } else {
                                    titleTemp = titleTemp + $scope.ledgers[ii].cnName + "/ ";
                                }
                            }
                        }
                    }
                }
                if (lang == "en") {
                    $scope.ledgers[i].showLedger = titleTemp + $scope.ledgers[i].enName;
                } else {
                    $scope.ledgers[i].showLedger = titleTemp + $scope.ledgers[i].cnName;
                }
            }
            for (var i = $scope.ledgers.length - 1; i >= 0; i--) {
                if (!$scope.ledgers[i].isLast) {
                    $scope.ledgers.splice(i, 1);
                }
            }
            angular.forEach($scope.ledgers, function(data) {
                if (type == "all"){
                    $scope.cashLedgers.push(data);
                }
                else if (data.code.substring(0,4) == "1001" || data.code.substring(0,4) == "1002"){ 
                    $scope.cashLedgers.push(data);              
                }                
            })
            var nullOpposite = {
                id: null,
                code: '0000',
                showLedger: $translate.instant('autopsApp.accountInvoice.partWriteOff')
            }
            $scope.ledgers.splice(0, 0, nullOpposite);  
        }


        $scope.loadAllLedgers = function(loadType) {
            if(loadType != "all" && $scope.ledgers && $scope.ledgers.length > 0){
                return;
            }
            AccountTitle.getAll({
                type: loadType
            }, function(result) {
                $scope.ledgers = result;
                $scope.cashLedgers = [];
                generateShowLedger("all");
            });
        }
        var ledgerCopy;
        $scope._onLedgerSelected = function(ledger) {
            ledgerCopy = angular.copy(ledger);
            $scope.accountCashier.accountId = ledger.id;
            $scope.accountCashier.accountCode = ledger.code;
            $scope.accountCashier.accountName = ledger.showLedger;
            
            if (ledger.currency){
                $scope.accountCashier.originalCurrency = ledger.currency;            
            }
            else {
                $scope.accountCashier.originalCurrency = $rootScope.account.baseCurrency;
            }
            $scope.currencyChange();
        }

        $scope.onOppositeLedgerChoosed = function(data) {
            if((data.code.substring(0,4) == "2205" && $scope.accountCashier.diffAmt < 0)  
                || (data.code.substring(0,4) == "1123" && $scope.accountCashier.diffAmt > 0)){
                layer.msg($translate.instant('autopsApp.accountInvoice.advanceAccountAmtAlert'));
                $scope.accountCashier.oppositeName = null;
                return;
            }
            $scope.accountCashier.oppositeId = data.id;
            $scope.accountCashier.oppositeName = data.showLedger;
            $scope.accountCashier.oppositeCode = data.code;
        }
        
        $scope.clearOposite =  function () {            
            if(!$scope.accountCashier.oppositeName && $scope.accountCashier.oppositeCode){
                $scope.accountCashier.oppositeId = null;
                $scope.accountCashier.oppositeName = null;
                $scope.accountCashier.oppositeCode = null;
            }
        }

        $scope.allTransactions = [];
        var exchangeRates = [];
        var loadTransactions = function() {
            if(!exchangeRates || exchangeRates.length == 0){
                $scope.accountingDateChange();
            }
            if ($rootScope.HAVENOAUTH('FMIS')){
                $rootScope.loadCurrencys();
                return;
            }
            AccountCashier.getAllTransaction({
                companyId: $rootScope.account.companyId
            }, function(result) {
                $scope.allTransactions = result;
                angular.forEach($scope.allTransactions, function(data){
                    data.originalAmt = data.originalAmt.toString();
                })
            });
            if($scope.ledgers && $scope.ledgers.length > 0){
                return;
            }
            AccountTitle.getAll({
                type: "part"
            }, function(result) {
                $scope.ledgers = result;
                generateShowLedger();
            });
        }


        $scope._onTransactionSelected = function(transaction) {
            $scope.accountCashier = angular.copy(transaction);
            $scope.accountCashier.originalAmt = $scope.accountCashier.originalAmt - 0;
            $scope.accountCashier.writeOffRemarks = transaction.remarks;
            $scope.accountingDateChange();
        }
        $scope.clearTransaction = function() {
            $scope.accountCashier= {};
            updateList(true);  
        }

        $scope.clearShowIdx = function(){
            $scope.showIdx = null;
            $scope.showExIdx = null;
        }

        $scope.inputFocus = function(fd, id){
            $timeout(function (){
                $("#" + fd + id).focus();
            })            
        }

        $scope.amountChange = function(){
            updateList();
            if(!$scope.accountCashier.originalAmt && $scope.ratesTtl.orgAmtTtl && !$scope.accountCashier.companyId){
                $scope.accountCashier.originalAmt = $scope.ratesTtl.orgAmtTtl;
            }
            updateAccountCashier();
        }

        $scope.delChoosed = function(cur, type) {          
            var selectedRows = $scope.gridApi.selection.getSelectedRows();  
            var delIds = new Set();
            angular.forEach(selectedRows, function(data){
                if(!type){
                    delIds.add(data.idStr);
                }
                else if(cur == data.payCurrency){
                    if(type == "all"){
                        delIds.add(data.idStr);
                    }
                    else if(type == "rev"){
                        if(!data.isPay){
                            delIds.add(data.idStr);
                        }                        
                    }
                    else if(type == "exp"){
                        if(data.isPay){
                            delIds.add(data.idStr);
                        }                        
                    }
                }                
            })
            for(var i = $scope.gridOptions.data.length - 1; i >= 0; i--){
                if(delIds.has($scope.gridOptions.data[i].idStr)){
                    $scope.gridOptions.data.splice(i, 1);
                }
            }
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            $timeout(function (){
                $scope.amountChange();
            })            
        }

        $scope.delNotSelected = function(cur, type) {          
            var selectedRows = $scope.gridApi.selection.getSelectedRows();  
            var delIds = new Set();
            angular.forEach(selectedRows, function(data){
                delIds.add(data.idStr);               
            })
            for(var i = $scope.gridOptions.data.length - 1; i >= 0; i--){
                if(!delIds.has($scope.gridOptions.data[i].idStr)){
                    $scope.gridOptions.data.splice(i, 1);
                }
            }
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            $timeout(function (){
                $scope.amountChange();
            })
        }
        $scope.delOne = function(idStr) {          
            for(var i = $scope.gridOptions.data.length - 1; i >= 0; i--){
                if(idStr == $scope.gridOptions.data[i].idStr){
                    $scope.gridOptions.data.splice(i, 1);
                }
            }
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            $timeout(function (){
                $scope.amountChange();
            })            
        }

        $scope.writOffNumCheck = function() {
            if (!$scope.accountCashier.transactionRef) {
                return;
            } else {
                GuideRates.checkWritOffNum({
                    companyId: $rootScope.account.companyId,
                    writeOffCode: $scope.accountCashier.transactionRef
                }, function(result) {
                    if (result.isRepeated) {
                        layer.msg($translate.instant('autopsApp.accountInvoice.writeOffCodeRepeated', {param: $scope.accountCashier.transactionRef}));
                    }
                });
            }
        };
        $scope.accountingDateChange = function() {
            OpsExchangeRate.getExchangeRatesMap({
                date: DateUtils.convertLocalDateToServer($scope.accountCashier.transactionDate)
            }, function(result) {
                exchangeRates = result;
                $scope.currencyChange();
            })
        }
        $scope.setShowIdx = function(rate) {
            if(rate.payCurrency != $rootScope.account.baseCurrency){
                 $scope.showExIdx = rate.idStr;
                 $rootScope.selectTxt('ex_field_'+rate.idStr);
            }
        }
        $scope.isSaving = false;
        $scope.submitWriteOff = function () {            
            $scope.isSaving = true;
            if (!$scope.gridOptions.data || $scope.gridOptions.data.length == 0) {
                layer.msg($translate.instant('autopsApp.accountInvoice.home.nothingChoosed'));
                $scope.isSaving = false;
                return;
            }
            if($scope.accountCashier.invoiceAmt > 0 && $scope.accountCashier.originalAmt < 0 && !$scope.accountCashier.oppositeId){
                layer.msg($translate.instant('autopsApp.accountInvoice.dnCnWrong'));
                $scope.isSaving = false;
                return;
            }
            if($scope.accountCashier.invoiceAmt < 0 && $scope.accountCashier.originalAmt > 0 && !$scope.accountCashier.oppositeId){
                layer.msg($translate.instant('autopsApp.accountInvoice.dnCnWrong'));
                $scope.isSaving = false;
                return;
            }

            if (!$scope.accountCashier.accountName && $rootScope.HAVEAUTH('FMIS')) {
                layer.msg($translate.instant('autopsApp.accountInvoice.home.noAccountChoosed'));
                $scope.isSaving = false;
                return;
            }
            if(!isMix){
                var notOk = false;
                angular.forEach($scope.gridOptions.data, function(data){  
                    if(data.payCurrency != $scope.accountCashier.originalCurrency){
                        notOk = true;
                    }
                });
                if(notOk){
                    layer.msg($translate.instant('autopsApp.accountInvoice.mixCurrenyAlert'));
                    $scope.isSaving = false;
                    return;
                }
            }
            if (!$scope.accountCashier.transactionRef) {
                layer.msg($translate.instant('autopsApp.accountInvoice.noWriteOffCodeAlert'));
                $scope.isSaving = false;
                return;
            }
            if(!$scope.accountCashier.transactionDate && $rootScope.HAVEAUTH('FMIS')){
                layer.msg($translate.instant('autopsApp.accountInvoice.accountingDateNullAlert'));
                $scope.isSaving = false;
                return;
            }
            if (null == $scope.accountCashier.originalAmt) {
                layer.msg($translate.instant('autopsApp.accountInvoice.home.noPayAmount'));
                $scope.isSaving = false;
                return;
            }
            //账单付款合计与发票金额对比
            if ($scope.accountCashier.diffAmt){
                if ($rootScope.HAVEAUTH('FMIS')){
                    if (null == $scope.accountCashier.oppositeName){
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.accountInvoice.diffAccountNullAlert', {ref: $scope.accountCashier.transactionRef}));
                        $scope.isSaving = false;
                        return;
                    }                    
                    if (($scope.accountCashier.baseAmt > 0 && $scope.accountCashier.diffAmt > 0)
                        || ($scope.accountCashier.baseAmt < 0 && $scope.accountCashier.diffAmt < 0)){
                        if (!$scope.accountCashier.oppositeId){
                            $scope.accountCashier.oppositeName = null;
                            $rootScope.OPS_ALERT($translate.instant('autopsApp.accountInvoice.diffAccountNullAlert', {ref: $scope.accountCashier.transactionRef}));
                            $scope.isSaving = false;
                            return;
                        }
                    }
                }
                else {
                    if (($scope.accountCashier.baseAmt > 0 && $scope.accountCashier.diffAmt > 0)
                        || ($scope.accountCashier.baseAmt < 0 && $scope.accountCashier.diffAmt < 0)){
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.accountInvoice.bankAmtBiger'));
                        $scope.isSaving = false;
                        return;
                    }   
                }        
                if(!$scope.accountCashier.oppositeId){
                    updateThisAmount();
                }
            }
            else {
                $scope.accountCashier.oppositeId = null;
                $scope.accountCashier.oppositeName = null;
                $scope.accountCashier.oppositeCode = null;
            }

            if (!$scope.accountCashier.id && !$scope.accountCashier.payerName){
                $scope.accountCashier.payerName = $scope.gridOptions.data[0].invoiceTitle;
                $scope.accountCashier.payerVid = $scope.gridOptions.data[0].venderId;
            }     
            var writeOffDTO = getWriteOffDTO();
            var alertTitle;
            if(writeOffDTO.hasUnconfirmedRate){
                alertTitle = $translate.instant('stats.stat.hasUnconfirmedRateAlert');
            }
            else{
                var amtParam = $scope.accountCashier.originalCurrency + $scope.accountCashier.originalAmt;
                if($rootScope.account.baseCurrency != $scope.accountCashier.originalCurrency){
                    amtParam += " (" + $rootScope.account.baseCurrency + $scope.ratesTtl.baseAmtTtl + ")";
                }
                alertTitle = $translate.instant('stats.stat.writeOffAlert', {param: amtParam});
            }
            layer.confirm(alertTitle, {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                writeOffDo(writeOffDTO);   
            }, function() {
                $scope.isSaving = false;
                $translate.refresh();
                return;
            });
        }

        function getWriteOffDTO(){
            var writeOffDTO = {};
            writeOffDTO.accountCashier = $scope.accountCashier;
            writeOffDTO.companyId = $rootScope.account.companyId;
            writeOffDTO.writeOffRemarks = $scope.accountCashier.writeOffRemarks;
            writeOffDTO.hasUnconfirmedRate = false;

            writeOffDTO.writeOffRates = [];            
            angular.forEach($scope.gridOptions.data, function(data){
                if((data.thisAmount || (data.thisAmount == 0 && data.payAmount == 0)) && data.actualExchangeRate){
                    var writeOff = {};
                    writeOff.refId = data.id;
                    writeOff.jobNum = data.jobNum;
                    writeOff.mblNum = data.mblNum;
                    writeOff.dnNum = data.dnNum;
                    if(!writeOff.dnNum || writeOff.dnNum.length < 8){
                        writeOffDTO.hasUnconfirmedRate = true;
                    }
                    writeOff.vslVoy = (data.vsl?data.vsl:"") + ((data.voy || data.vsl)?"/":"") + (data.voy?data.voy:"");

                    writeOff.payer = data.payer;                    
                    writeOff.payerVid = data.venderId;
                    if(!writeOffDTO.accountCashier.payerName && !writeOffDTO.accountCashier.id){
                        writeOffDTO.accountCashier.payerName = data.payer;
                        writeOffDTO.accountCashier.payerVid = data.venderId;
                    }

                    writeOff.etd = data.etd;
                    writeOff.item = data.item;
                    writeOff.thisAmount = data.thisAmount;
                    writeOff.currency = data.payCurrency;
                    writeOff.totalAmount = data.payAmount;
                    writeOff.actualExchangeRate = data.actualExchangeRate;                    
                    writeOff.diffAmount = data.diffAmount;
                    writeOff.isPay = data.isPay;
                    writeOff.guideId = data.guideId;
                    writeOff.expressParcelId = data.expressParcelId;
                    writeOff.wmsInId = data.wmsInId;                    
                    writeOff.isExport = data.isExport;
                    writeOff.salesName = data.salesName;
                    writeOffDTO.writeOffRates.push(writeOff);
                }                
            });
            return writeOffDTO;
        }

        function writeOffDo(writeOffDTO){
            layer.load(1, {shade: 0.3});
            GuideRates.fastWriteOff(writeOffDTO, onSaveSuccess, onSaveError);   
        }

        var onSaveSuccess = function(result) {            
            $scope.isSaving = false;
            layer.closeAll(); 
            if(result && result.id){
                if($scope.bankSlips.length > 0){
                    layer.load(1, { shade: 0.3 });
                    layer.msg($translate.instant('autopsApp.autoCheck.slipUploading'));    
                    angular.forEach($scope.bankSlips, function(file) {
                        WriteOff.getByVoucherId({
                            voucherId: result.id, 
                            companyId: result.companyId
                        }, function(writeOffResult){                                            
                            $rootScope.UPLOADFILE(file, '/writeOff/' + writeOffResult.id + '/', [], writeOffResult.companyId);
                            layer.closeAll();
                        })
                    })
                }

                if($rootScope.HAVEAUTH('ROLE_ACCOUNT')){
                    $rootScope.VIEWVOUCHER(null, null, result);
                }
            }                  
            if (entity.autoCheck || entity.guideRates || entity.dcnRequest){
                $scope.clear();
                return;
            }
            $scope.gridOptions.data = [];
            $scope.accountCashier = {};
            $scope.ratesTtl = {};
            updateList();
            $scope.accountCashier.transactionDate = new Date();            
            loadTransactions();
            $scope.getWriteOffCode();
            layer.msg($translate.instant('global.messages.submitted'));
        };

        var onSaveError = function(result) {
            $scope.isSaving = false; 
            layer.closeAll(); 
            $rootScope.ALERT_ERROR(result);
        };
        
        $scope.refreshDnGuideRates = function () {
            layer.confirm($translate.instant('autopsApp.accountInvoice.refreshDnGuideRatesAlert'), {
                icon: 3,
                title: "Alert",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                layer.load(1, {shade: 0.3});
                GuideRates.refreshDnGuideRates({companyId: $rootScope.account.companyId}, getWriteOffDTO(), 
                    onSaveSuccess, onSaveError);
            }, function() {});
        }

        $scope.dnGridOptions = {
            enableSorting: true,//排序
            enableRowHeaderSelection : true,  //行选中
            enableGridMenu : true,//表格 菜单  
            showGridFooter: false,//表格的footer  
            enableFiltering: true,//高级过滤
            showColumnFooter: true,//每列footer

            minRowsToShow: 20,
            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            enableRowSelection: true, //行选中
            enableSelectAll: true, //全部选中
            enableFullRowSelection : false, //行任意位置选中

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: $translate.instant('global.menu.admin.chargesReport') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],     
            columnDefs: [
                { field: 'dnNum',
                    minWidth:150, 
                    cellTemplate: '<span class="grid-text"><span ng-click="grid.appScope.amendDnAmount(row.entity.dnNum)">{{row.entity.dnNum}}</span><span class="glyphicon glyphicon-alert ml-4 text-danger" ng-hide="row.entity.locked" data-toggle="tooltip" data-placement="top" title="Not Locked/Confirmed"></span><span class="glyphicon glyphicon-remove-circle text-primary ml-4" ng-click="grid.appScope.delDnOne(row.entity)"></span></span>',
                    displayName: $translate.instant('stats.stat.guideRates.dnNum')},
                { field: 'payCurrency',
                    minWidth:70, 
                    displayName: $translate.instant('stats.stat.currency')},
                { field: 'payAmount',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100, 
                    cellTemplate: '<span class="pull-right grid-text" ng-class="row.entity.isPay?\'text-danger\':\'text-primary\'"><span ng-if="row.entity.isPay">(</span><span>{{grid.appScope.FORMAT_NUM(row.entity.payAmount)}}</span><span ng-if="row.entity.isPay">)</span></span>',
                    displayName: $translate.instant('autopsApp.accountInvoice.invoiceAmount')},
                { field: 'thisAmount',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ], 
                    minWidth:100, 
                    cellTemplate: '<span class="grid-text pull-right"><strong ng-class="row.entity.isPay?\'text-danger\':\'text-primary\'" ng-hide="grid.appScope.showIdx == row.entity.idStr"><span ng-if="row.entity.isPay">(</span><span>{{grid.appScope.FORMAT_NUM(row.entity.thisAmount)}}</span><span ng-if="row.entity.isPay">)</span><small class="ml-1 glyphicon glyphicon-pencil" ng-click="grid.appScope.showIdx = row.entity.idStr;grid.appScope.selectTxt(\'amt_field_\'+row.entity.idStr)"></small><small class="ml-4 glyphicon glyphicon-modal-window" ng-click="grid.appScope.amendDnAmount(row.entity.dnNum)"></small></strong><input type="text" class="input-sm form-control text-right" style="padding-right: 5px;" ng-model="row.entity.thisAmount" ng-show="grid.appScope.showIdx == row.entity.idStr" ng-blur="grid.appScope.dnAmountChange(row.entity);grid.appScope.clearShowIdx()" numberic id="amt_field_{{row.entity.idStr}}" /></span>',
                    displayName: $translate.instant('autopsApp.accountInvoice.home.writeOffAmount')},               
                { field: 'actualExchangeRate',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:70, 
                    cellTemplate: '<span class="grid-text pull-right"><strong ng-class="row.entity.isPay?\'text-danger\':\'text-primary\'" ng-hide="grid.appScope.showExIdx == row.entity.idStr" ng-click="grid.appScope.setShowIdx(row.entity)"><span>{{row.entity.actualExchangeRate}}</span><small class="ml-1 glyphicon glyphicon-pencil" ng-show="row.entity.payCurrency != grid.appScope.account.baseCurrency"></small></strong><input type="text" class="input-sm form-control text-right" style="padding-right: 5px;" ng-model="row.entity.actualExchangeRate" ng-show="grid.appScope.showExIdx == row.entity.idStr" ng-blur="grid.appScope.dnAmountChange(row.entity);grid.appScope.clearShowIdx()" numberic id="ex_field_{{row.entity.idStr}}" /></span>',
                    displayName: $translate.instant('stats.stat.actualExchangeRate')},
                { field: 'writeOffBaseAmt',
                    type:'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ], 
                    minWidth:100, 
                    footerCellTemplate: '<div class="text-right grid-text">{{grid.appScope.account.baseCurrency}}{{grid.appScope.FORMAT_NUM(grid.appScope.ratesTtl.baseAmtTtl)}}</div>',
                    cellTemplate: '<span class="pull-right grid-text" ng-class="row.entity.isPay?\'text-danger\':\'text-primary\'"><span ng-if="row.entity.isPay">(</span><span>{{grid.appScope.FORMAT_NUM(row.entity.writeOffBaseAmt)}}</span><span ng-if="row.entity.isPay">)</span></span>',
                    displayName: $translate.instant('stats.stat.guideRates.baseAmt') + "(" + $rootScope.account.baseCurrency + ")"},      
                { field: 'payer',
                    minWidth:200, 
                    displayName: $translate.instant('stats.stat.settlementObj')},                
                { field: 'diffAmount',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ], 
                    minWidth:100, 
                    cellTemplate: '<span class="pull-right mr-5" ng-class="row.entity.isPay?\'text-danger\':\'text-primary\'"><span ng-if="row.entity.isPay">(</span><span>{{grid.appScope.FORMAT_NUM(row.entity.diffAmount)}}</span><span ng-if="row.entity.isPay">)</span></span>',
                    displayName: $translate.instant('stats.stat.notPaid')},
                { field: 'isPayTxt',
                    minWidth:80, 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [ 
                        { value: "DN", label: "DN"}, 
                        { value: "CN", label: "CN"}]
                    },
                    displayName: $translate.instant('stats.stat.guideRates.isPay')},
                { field: 'exchangeRate',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.exchangeRate')},
                { field: 'etd',
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.guideRates.etd')},
                { field: 'accountDate',
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.guideRates.accountDate')},
                { field: 'invoiceNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.guideRates.invoiceNum')},
                { field: 'mblNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.mblNum')},
                { field: 'salesName',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.filterBySales')},
                { field: 'jobNum',
                    minWidth:150, 
                    displayName: $translate.instant('stats.stat.jobNum')}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.dnGridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 142;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.dnGridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }     
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.dnGridApi.saveState.restore($scope, gridStateCopy);                   
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.dnGridApi.core.getVisibleRows($scope.dnGridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.dnGridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "dnReport";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);                   
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.dnGridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "dnReport";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function( gridApi ) {
                $scope.dnGridApi = gridApi;
                $scope.dnGridApi.selection.on.rowSelectionChanged($scope,function(row){
                    selectionDnChange();
                });
                $scope.dnGridApi.selection.on.rowSelectionChangedBatch($scope,function(row){
                    selectionDnChange();
                });
            }
        }

        function updateDnLine(newLine, data){            
            if(!newLine.actualExchangeRate && data.actualExchangeRate){
                newLine.actualExchangeRate = data.actualExchangeRate;
            }
            if(!newLine.payCurrency && data.payCurrency){
                newLine.payCurrency = data.payCurrency;
            }             
            if(!newLine.payer && data.payer){
                newLine.payer = data.payer;
            }  
            if(!newLine.etd && data.etd){
                newLine.etd = data.etd;
            }  
            if(!newLine.exchangeRate && data.exchangeRate){
                newLine.exchangeRate = data.exchangeRate;
            }  
            if(!newLine.accountDate && data.accountDate){
                newLine.accountDate = data.accountDate;
            }  
            if(data.salesName && newLine.salesName.indexOf(data.salesName) == -1){
                newLine.salesName = newLine.salesName + data.salesName + " ";
            } 
            if(data.mblNum && newLine.mblNum.indexOf(data.mblNum) == -1){
                newLine.mblNum = newLine.mblNum + data.mblNum + " ";
            }
            if(data.jobNum && newLine.jobNum.indexOf(data.jobNum) == -1){
                newLine.jobNum = newLine.jobNum + data.jobNum + " ";
            }
            if(data.invoiceNum && newLine.invoiceNum.indexOf(data.invoiceNum) == -1){
                newLine.invoiceNum = newLine.invoiceNum + data.invoiceNum + " ";
            }
            if(newLine.isPay == data.isPay){
                if(data.thisAmount){
                    newLine.thisAmount += data.thisAmount - 0;
                }
                if(data.payAmount){
                    newLine.payAmount += data.payAmount - 0;
                }                        
                if(data.writeOffBaseAmt){
                    newLine.writeOffBaseAmt += data.writeOffBaseAmt - 0;
                }
                if(data.diffAmount){
                    newLine.diffAmount += data.diffAmount - 0;
                }
            }
            else {
                if(data.thisAmount){
                    newLine.thisAmount -= data.thisAmount - 0;
                }
                if(data.payAmount){
                    newLine.payAmount -= data.payAmount - 0;
                }                        
                if(data.writeOffBaseAmt){
                    newLine.writeOffBaseAmt -= data.writeOffBaseAmt - 0;
                }
                if(data.diffAmount){
                    newLine.diffAmount -= data.diffAmount - 0;
                }
            }    

        }
        var clearCurDn = function() {
            angular.forEach($scope.gridOptions.data, function(data){
                if(data.dnNum && data.dnNum.length < 8){
                    data.dnNum = null;
                }  
            })
        }

        var initByDnData = function() {
            var dnNums = [];
            angular.forEach($scope.gridOptions.data, function(data){
                if(!data.dnNum){
                    data.dnNum = data.payCurrency;
                }
                if(dnNums.indexOf(data.dnNum) == -1){
                    dnNums.push(data.dnNum);
                }     
            })
            dnNums.sort();
            dnNums.reverse();

            $scope.dnGridOptions.data = [];
            dnNums.forEach(function(dnNum){
                var newLine = {dnNum: dnNum};
                newLine.thisAmount = 0;
                newLine.payAmount = 0;
                newLine.actualExchangeRate = 0;
                newLine.writeOffBaseAmt = 0;
                newLine.diffAmount = 0;

                if(dnNum.indexOf("CN") == 0 && dnNum.length > 8){
                    newLine.isPay = true;
                    newLine.isPayTxt = "CN";
                }
                else {
                    newLine.isPay = false;
                    newLine.isPayTxt = "DN";
                }
                newLine.locked = dnNum.length > 8;
                newLine.jobNum = "";
                newLine.salesName = "";
                newLine.mblNum = "";
                newLine.invoiceNum = "";
                angular.forEach($scope.gridOptions.data, function(data){
                    if(data.dnNum == dnNum){
                        updateDnLine(newLine, data);
                    }                                  
                })
                newLine.thisAmount = DataUtils.round(newLine.thisAmount);
                newLine.payAmount = DataUtils.round(newLine.payAmount);
                newLine.writeOffBaseAmt = DataUtils.round(newLine.writeOffBaseAmt);
                newLine.diffAmount = DataUtils.round(newLine.diffAmount);
                newLine.idStr = newLine.dnNum;
                $scope.dnGridOptions.data.push(newLine);
            })
            updateDnCnAmt();
        }
        

        var selectionDnChange = function() {
            holdSelect = true;
            var selectedRows = $scope.dnGridApi.selection.getSelectedRows();
            $scope.gridApi.selection.clearSelectedRows();
            angular.forEach(selectedRows, function(selectDnRow){
                angular.forEach($scope.gridOptions.data, function(data){
                    if(data.dnNum == selectDnRow.dnNum){
                        $scope.gridApi.selection.selectRow(data);
                    }                    
                })
            })
            holdSelect = false;
            selectionChange();
        }

        $scope.dnAmountChange = function(dnRow) {
            if(!dnRow.thisAmount && dnRow.thisAmount != 0){
                dnRow.thisAmount = dnRow.diffAmount - 0;
            }                
            if(dnRow.diffAmount > 0 && (dnRow.thisAmount < 0 || dnRow.thisAmount > dnRow.diffAmount)){
                dnRow.thisAmount = dnRow.diffAmount - 0;
                layer.msg($translate.instant('autopsApp.opsClient.invalidInput'));
            }
            if(dnRow.diffAmount < 0 && (dnRow.thisAmount > 0 || dnRow.thisAmount < dnRow.diffAmount)){
                dnRow.thisAmount = dnRow.diffAmount - 0;
                layer.msg($translate.instant('autopsApp.opsClient.invalidInput'));
            } 
            if (!dnRow.thisAmount){
                dnRow.thisAmount = 0;
            }
            // 汇率
            if(!dnRow.actualExchangeRate){
                if(dnRow.payCurrency == $scope.accountCashier.originalCurrency){
                    dnRow.actualExchangeRate = $scope.accountCashier.exchangeRate;
                }
                else {
                    if(dnRow.exchangeRate){
                        dnRow.actualExchangeRate = dnRow.exchangeRate;
                    }
                    else {
                        dnRow.actualExchangeRate = exchangeRates[dnRow.payCurrency];
                    }
                }
            }
            var thisAmount = DataUtils.round(dnRow.thisAmount);
            //清空记录
            angular.forEach($scope.gridOptions.data, function(data){
                if(data.dnNum == dnRow.dnNum){
                    data.thisAmount = 0;
                    data.actualExchangeRate = dnRow.actualExchangeRate - 0;
                }                    
            })

            if(dnRow.isPay){
                paymentAutoInput(dnRow, thisAmount);
            }
            else {
                invoiceAutoInput(dnRow, thisAmount);
            }
            $scope.amountChange();
        }

        function invoiceAutoInput(dnRow, thisAmount) {
            //更新费用列表
            angular.forEach($scope.gridOptions.data, function(data){
                if(data.dnNum == dnRow.dnNum){
                    if (!data.isPay && data.payAmount < 0) {
                        data.thisAmount = DataUtils.round(data.diffAmount) - 0;
                        thisAmount = thisAmount - data.thisAmount;
                    }
                    else if (data.isPay && data.payAmount > 0) {
                        data.thisAmount = DataUtils.round(data.diffAmount) - 0;
                        thisAmount = thisAmount + data.thisAmount;
                    }
                }
            });
            angular.forEach($scope.gridOptions.data, function(data){
                if(data.dnNum == dnRow.dnNum){
                    if (!data.isPay && data.payAmount >= 0) {
                        if (thisAmount >= data.diffAmount) {
                            data.thisAmount = DataUtils.round(data.diffAmount) - 0;
                            thisAmount = thisAmount - data.diffAmount;
                        } 
                        else {
                            data.thisAmount = DataUtils.round(thisAmount) - 0;
                            thisAmount = 0;
                        }
                    }
                    else if (data.isPay && data.payAmount <= 0) {
                        if (thisAmount >= -data.diffAmount) {
                            data.thisAmount = DataUtils.round(data.diffAmount) - 0;
                            thisAmount = thisAmount + data.diffAmount;
                        }
                        else {
                            data.thisAmount = -(DataUtils.round(thisAmount) - 0);
                            thisAmount = 0;
                        }
                    }  
                }
            });
        }
        function paymentAutoInput(dnRow, thisAmount) {
            //更新费用列表
            angular.forEach($scope.gridOptions.data, function(data){
                if(data.dnNum == dnRow.dnNum){
                    if (!data.isPay && data.payAmount > 0) {
                        data.thisAmount = DataUtils.round(data.diffAmount) - 0;
                        thisAmount = thisAmount + data.thisAmount;
                    }
                    else if (data.isPay && data.payAmount < 0) {
                        data.thisAmount = DataUtils.round(data.diffAmount) - 0;
                        thisAmount = thisAmount - data.thisAmount;
                    }
                }
            });
            angular.forEach($scope.gridOptions.data, function(data){
                if(data.dnNum == dnRow.dnNum){
                    if (!data.isPay && data.payAmount <= 0) {
                        if (thisAmount >= -data.diffAmount) {
                            data.thisAmount = DataUtils.round(data.diffAmount) - 0;
                            thisAmount = thisAmount + data.diffAmount;
                        }
                        else {
                            data.thisAmount = -(DataUtils.round(thisAmount)) - 0;
                            thisAmount = 0;
                        }
                    }
                    else if (data.isPay && data.payAmount >= 0) {
                        if (thisAmount >= data.diffAmount) {
                            data.thisAmount = DataUtils.round(data.diffAmount) - 0;
                            thisAmount = thisAmount - data.diffAmount;
                        }
                        else {
                            data.thisAmount = DataUtils.round(thisAmount) - 0;
                            thisAmount = 0;
                        }
                    }
                }
            });
        }

        $scope.delDnOne = function(row) {          
            for(var i = $scope.gridOptions.data.length - 1; i >= 0; i--){
                if($scope.gridOptions.data[i].dnNum == row.dnNum){
                    $scope.gridOptions.data.splice(i, 1);
                }
            }
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            $timeout(function (){
                $scope.amountChange();
            })
        }

        function getSearchParams(){
            var searchParams = {
                isPaid: false,
                isVoided: false,
                isTtl: false,
                forChargesReport: true,
                companyId: $rootScope.account.companyId
            };
            if($scope.statParams.payCurrency){
                searchParams.currency = $scope.statParams.payCurrency;
            }
            return searchParams;
        }

        $scope.searchDnCn = function(dnCn) {
            var templateUrl = dnCn == "DN"?'app/entities/accountant/accountInvoice/accountInvoice-dialog.html':'app/entities/accountant/accountPayment/accountPayment-dialog.html';
            var controller = dnCn == "DN"?'AccountInvoiceDialogController':'AccountPaymentDialogController';
            $uibModal.open({
                templateUrl: templateUrl,
                controller: controller,
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return getSearchParams();
                    }]
                }
            }).result.then(function(results) {
                addData(results);
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        $scope.dnpayer = "";
        $scope.cnpayer = "";
        $scope.etdEndDate = null;
        $scope._ondnpayerselected = function(data, dncn) {
            if (dncn == 'dn'){
                AccountInvoice.getByDnNumOrVender({
                    companyId: $rootScope.account.companyId,
                    etdEndDate: DateUtils.convertLocalDateToServer($scope.etdEndDate),
                    venderId: data.id,
                    forWriteOff: true
                }, function(result) {
                    addData(result);
                });
            }
            else {
                AccountPayment.getByDnNumOrVender({
                    companyId: $rootScope.account.companyId,
                    etdEndDate: DateUtils.convertLocalDateToServer($scope.etdEndDate),
                    venderId: data.id,
                    forWriteOff: true
                }, function(result) {     
                    addData(result);
                });
            }
        }

        $scope.amendDnAmount = function(dnNum) {
            var dnEntity = {};
            dnEntity.dnNum = dnNum;
            dnEntity.dataList = $scope.gridOptions.data;
            $uibModal.open({
                templateUrl: 'app/entities/wo-body/wo-body-delete-dialog.html',
                controller: 'WoBodyDeleteController',
                controllerAs: 'vm',
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return dnEntity;
                    }]
                }
            }).result.then(function() {
                updateList();
            }, function() {
                updateList();
            });
        }

        function updateDnCnAmt() {
            GuideRates.getDnCnMap({
                companyId: $rootScope.account.companyId
            }, $scope.dnGridOptions.data, function(result){
                angular.forEach($scope.dnGridOptions.data, function(data) {
                    if(data.dnNum && result[data.dnNum]){
                        data.payAmount = result[data.dnNum].invoiceAmount;
                        data.dnPaid = result[data.dnNum].paidAmount;
                        if(!data.dnPaid){
                            data.dnPaid = 0;
                        }
                        data.diffAmount = DataUtils.round(data.payAmount - data.dnPaid);
                    }
                })
                $scope.dnGridApi.grid.refresh();
            })
        }
        $scope.bankSlips = [];
        $scope.filesChoosed = function (files) {
            angular.forEach(files, function(file) {
                $scope.bankSlips.push(file);
            })                  
            $translate.refresh();
        }
        $scope.delOneSlip = function (file) {
            var idx = $scope.bankSlips.indexOf(file);
            if(idx > -1){
                $scope.bankSlips.splice(idx, 1);
            }              
            $translate.refresh();
        }

        
	}
})();