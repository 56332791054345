
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GuideFclCtnDeleteController', GuideFclCtnDeleteController);

    GuideFclCtnDeleteController.$inject = ['$scope', '$rootScope', '$timeout', '$uibModal', '$translate', '$http',
        'Guide', 'ExpressTracking', 'OpsTrackStatus'
    ];

    function GuideFclCtnDeleteController($scope, $rootScope, $timeout, $uibModal, $translate, $http,
        Guide, ExpressTracking, OpsTrackStatus) {
        var vm = this;

        vm.deliverySign = deliverySign;
        vm.viewTracking = viewTracking;
        vm.uploadFile = uploadFile;

        vm.onstatusSelected = onstatusSelected;
        vm.clearData = clearData;

        vm.guide = {};
        vm.type = "Deliveried";
        vm.moveList = [];
        vm.move = {};


        function clearData() {
            vm.code = "";
            vm.guide = null;
            vm.moveList = [];
        }
        
        function getDliveriedMove() {
            angular.forEach($rootScope.TRACKSTATUS, function(move){
                if(move.delivered){
                    vm.move = angular.copy(move);
                }
            })
        }

        if (!$rootScope.TRACKSTATUS || $rootScope.TRACKSTATUS.length == 0){
            OpsTrackStatus.getAll(function(result) {
                $rootScope.TRACKSTATUS = result;
                getDliveriedMove();
            });
        }
        else {
            getDliveriedMove();
        }
        
        function onstatusSelected (data) {
            vm.move = data;        
        }     

        function deliverySign() {
            if (!vm.code || !vm.move.id) {
                return;
            }
            vm.guide = {};
            Guide.deliverySign({
                soNum: vm.code
            }, vm.move, function(result) {
                vm.guide = result;
                viewTracking(vm.code);
                $translate.refresh();
            }, $rootScope.ALERT_ERROR);
        }

        $(document).keydown(function(event) {
            if (event.keyCode == 13) {
                if (vm.scanedCode && vm.scanedCode.length > 3) {
                    deliverySign(vm.scanedCode);
                    vm.code = vm.scanedCode;
                    vm.scanedCode = null;
                }
                $timeout(function() {
                    $("#field_manualBarCode").focus();
                });
            }
        });
        $timeout(function() {
            $("#field_manualBarCode").focus();
        });

        function viewTracking(code) {
            code = code.replace(/[ ]/g, "");
            ExpressTracking.pubTracking({
                soNum: code,
                companyId: $rootScope.account.companyId
            }, function(result) {
                vm.moveList = result;
                if (!vm.moveList || vm.moveList.length == 0) {
                    layer.msg($translate.instant('activate.notFound'));
                }
                $translate.refresh();
            }, function(result) {
                if (result.status == 404) {
                    layer.msg($translate.instant('activate.notFound'));
                }
            })
        }

        function uploadFile (file) {
            if (!file || !vm.guide.id) {
                return;
            }
            var fileName = null;
            var filePath;
            if(vm.guide.booking){
                filePath = "REPORTS/GUIDE/" + vm.guide.id + "/";
            }
            else {
                filePath = "/expressParcel/" + vm.guide.id + "/";
            }
            if(vm.move.delivered){
                fileName = "receipt";
                filePath +="RC/";
            }
            $rootScope.UPLOAD_FILE_WITH_NAME(file, filePath, [], $rootScope.account.companyId, fileName);
        };

    }
})();