(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('JobPalletSearch', JobPalletSearch);

    JobPalletSearch.$inject = ['$resource'];

    function JobPalletSearch($resource) {
        var resourceUrl =  'api/_search/job-pallets/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
