(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsOperationRequirmentDialogController', WmsOperationRequirmentDialogController);

    WmsOperationRequirmentDialogController.$inject = ['$scope', '$uibModalInstance', '$rootScope', '$translate', '$uibModal', '$timeout', 
    'entity', 'WmsWorkLog', 'OpsVender'];

    function WmsOperationRequirmentDialogController ($scope, $uibModalInstance, $rootScope, $translate, $uibModal, $timeout, 
        entity, WmsWorkLog, OpsVender) {
               
        var vm = this;
        
        vm.clear = clear;
        vm.deleteOne = deleteOne;
        vm.editWorkLog = editWorkLog;

        vm.isSaving = false;

        $timeout(function() {
            WmsWorkLog.getOpRequirment({
                type: entity.type,
                id: entity.id
            }, function(result){
                vm.wmsWorkLogs = result;
            });
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function editWorkLog(workLog) {
            if(null == workLog){
                workLog = {};
                workLog.companyId = entity.companyId;
                workLog.isBilled = false;
                if(entity.type == 'wms'){
                    workLog.wmsInId = entity.id;
                    workLog.wmsInNum = entity.warehousingNumber;
                    workLog.jobNum = entity.warehousingNumber;
                    workLog.count = entity.applicationNum;
                    workLog.vender = entity.clientName;
                    workLog.venderId = entity.venderId;
                    workLog.warehouseId = entity.warehouseId;
                    workLog.warehouse = entity.warehouseName;
                }
                else if(entity.type == 'guide'){
                    workLog.guideId = entity.id;
                    workLog.wmsInNum = entity.soNum;
                    workLog.jobNum = entity.jobNum;
                    workLog.count = entity.pkgNum;
                    workLog.vender = entity.customerName;
                    workLog.venderId = entity.customerId;
                    workLog.warehouseId = entity.whId;
                    workLog.warehouse = entity.whName;
                }
                else {
                    workLog.expressParcelId = entity.id;
                    workLog.wmsInNum = entity.soNum;
                    workLog.jobNum = entity.jobNum;
                    workLog.count = entity.pkgNum;
                    workLog.vender = entity.shipperName;
                    workLog.venderId = entity.shipperId;
                    workLog.warehouseId = entity.warehouseId;
                    workLog.warehouse = entity.warehouseName;
                }
            }
            $uibModal.open({
                templateUrl: 'app/entities/wms-work-log/wms-work-log-dialog.html',
                controller: 'WmsWorkLogDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: ['WmsWorkLog', function(WmsWorkLog) {
                        if(workLog.id){
                            return WmsWorkLog.get({id : workLog.id}).$promise;
                        }
                        else {
                            return workLog;
                        }                        
                    }]
                }
            }).result.then(function(result) {
                if(!workLog.id){
                    vm.wmsWorkLogs.push(result);
                    $translate.refresh();
                }
                else {
                    angular.forEach(vm.wmsWorkLogs, function(data){
                        if(data.id == result.id){
                            angular.copy(result, data);
                        }
                    })                    
                }
            }, function() {
            });
        }

        function deleteOne (workLog) {
            if(workLog.id){
                WmsWorkLog.delete({id: workLog.id}, function () {
                    var index = vm.wmsWorkLogs.indexOf(workLog);
                    if (index > -1) {
                        vm.wmsWorkLogs.splice(index, 1);
                    };
                });
            }
        }


    }
})();
