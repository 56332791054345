(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('CommodityDatabaseSearch', CommodityDatabaseSearch);

    CommodityDatabaseSearch.$inject = ['$resource'];

    function CommodityDatabaseSearch($resource) {
        var resourceUrl =  'api/_search/commodity-databases/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
