(function() {
	'use strict';

	angular
		.module('autopsApp')
		.controller('StatTruckerController', StatTruckerController);

	StatTruckerController.$inject = ['$scope', '$rootScope', '$translate', '$state', 'Principal', 'Auth', 'JhiLanguageService', 'Clerks', 'DateUtils', 'OpsFiles','$filter'];

	function StatTruckerController($scope, $rootScope, $translate, $state, Principal, Auth, JhiLanguageService, Clerks, DateUtils, OpsFiles,$filter) {
		if (!$rootScope.account || angular.equals($rootScope.account, {})) {
			Principal.identity().then(function(account) {
				$rootScope.account = account;
			});
		};
		
		$scope.datePickerOpenStatus = {};

		$scope.yearFormat = "yyyy";
		$scope.monthFormat = "yyyy-MM";
		$scope.dayFormat = "yyyy-MM-dd";
		$scope.yearDatepickerOptions = {
			minMode: 'year'
		}
		$scope.monthDatepickerOptions = {
			minMode: 'month'
		}
		$scope.dayDatepickerOptions = {
			minMode: 'day'
		}
		$scope.datePickerOpenStatus.yearpicker = false;
		$scope.datePickerOpenStatus.monthpicker = false;
		$scope.datePickerOpenStatus.daypicker = false;
		$scope.datePickerOpenStatus.dateFromPicker = false;
		$scope.datePickerOpenStatus.dateToPicker = false;

		$scope.openCalendar = function(date) {
			$scope.datePickerOpenStatus[date] = true;
		}

		$scope.statParams = {};
		$scope.isSaving = false;
		$scope.getStatParams = function() {
			$scope.statParams.statBy = "jobNum";
			$scope.statParams.dateFrom = "";
			$scope.statParams.dateTo = "";
			$scope.statParams.fileName = "";
			if ($scope.statParams.statType == "irregular") {
				$scope.statParams.dateFrom = $scope.statParams.dateFrom_;
				$scope.statParams.dateTo = $scope.statParams.dateTo_;
			}
			if ($scope.statParams.statType == "year") {
				var year = $scope.statParams.year.getFullYear(); //取当前的年份          
				$scope.statParams.dateFrom = new Date(year, 0, 1);
				$scope.statParams.dateTo = new Date(year, 11, 31);
			}
			if ($scope.statParams.statType == "month") {
				var year = $scope.statParams.month.getFullYear(); //取当前的年份          
				var month = $scope.statParams.month.getMonth();
				if (month == 11) {
					month = month -12;
					year++;
				}
				$scope.statParams.dateFrom = new Date(year, month, 1);
				$scope.statParams.dateTo = new Date(year, month+1, 0);
			}
			if ($scope.statParams.statType == "day") {
				$scope.statParams.dateFrom = $scope.statParams.day;
				$scope.statParams.dateTo = $scope.statParams.day;
			}


			if ("filterByClient" == $scope.statParams.filterBy) {
				if ($scope.clients.length > 0) {
					for (var i = 0; i < $scope.clients.length; i++) {
						if ($scope.clients[i].id == $scope.statParams.scope) {
							$scope.statParams.filterByContent = $scope.clients[i].companyName;
						}
					}
				}
			} else if ("filterByAgent" == $scope.statParams.filterBy) {
				if ($scope.agents.length > 0) {
					for (var i = 0; i < $scope.agents.length; i++) {
						if ($scope.agents[i].id = $scope.statParams.scope) {
							$scope.statParams.filterByContent = $scope.agents[i].companyName;
						}
					}
				}
			}
			$scope.statParams.timezoneOffset = new Date().getTimezoneOffset()/60;
		}
		$scope.reportavailalbe = false;
		$scope.isJobNumStats = true;
		$scope.statsResult = {};
		$scope.getContents = function() {
			$scope.getStatParams();
			if ($scope.statParams.statBy == "jobNum"){
				$scope.isJobNumStats = true;
				OpsFiles.statsBookingTruckByJobNum($scope.statParams, function(result) {
					$scope.isSaving = false;
					$scope.statsResult = result;
					if (!$scope.statsResult.profitStatByJobNumDTOs || $scope.statsResult.profitStatByJobNumDTOs.length == 0) {
						initContent();
						layer.msg($translate.instant('stats.stat.noData'));
						return;
					}
					initNums();
					$scope.reportavailalbe = true;
				}, function(response) {
					$scope.isSaving = false;
					$scope.reportavailalbe = false;
					initContent();
				});
			}
			else {
				$scope.isJobNumStats = false;
				OpsFiles.statsBookingTruckByForwarder($scope.statParams, function(result) {
					$scope.isSaving = false;
					$scope.statsResult = result;
					if (!$scope.statsResult.profitStatByUserDTOs || $scope.statsResult.profitStatByUserDTOs.length == 0) {
						initContent();
						layer.msg($translate.instant('stats.stat.noData'));
						return;
					}
					initNums();
					$scope.reportavailalbe = true;
				}, function(response) {
					$scope.isSaving = false;
					$scope.reportavailalbe = false;
					initContent();
				});
			}

		}
		var initContent = function() {
			$scope.statsResult.profitStatByJobNumDTOs = [];
			for (var j = 0; j <= 20; j++) {
				$scope.statsResult.profitStatByJobNumDTOs.push({});
			}

			$scope.statsResult.profitStatByUserDTOs = [];
			for (var j = 0; j <= 20; j++) {
				$scope.statsResult.profitStatByUserDTOs.push({});
			}


		}
		initContent();
		$scope.exportReport = function(format) {
			$scope.statParams.fileType = format;
			$scope.statParams.fileName = "";
			$scope.fileName_btn = layer.open({
				type: 1,
				area: ['300px', '180px'],
				btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')],
				title: $translate.instant('global.fileName'),
				shade: 0.3,
				moveType: 0,
				shift: 0,
				content: $("#templateReportStatName"),
				success: function(layero, index) {
					$("#statFileName").focus();
				},
				yes: function(index, layero) {
					if ($scope.statParams.fileName == '' || angular.isUndefined($scope.statParams.fileName)) {
						$scope.statParams.fileName = 'StatBookingTruck';
					}
					$scope.getStatFile();
					layer.close($scope.fileName_btn);
				},
				no: function(index, layero) {
					layer.close(index);
				}
			});
		}

		$("#statFileName").keydown(function(event) {
			$scope.keycode = window.event ? event.keyCode : event.which;
			if ($scope.keycode == 13) {
				if ($scope.statParams.fileName == '' || angular.isUndefined($scope.statParams.fileName)) {
					$scope.statParams.fileName = 'StatBookingTruck';
				}
				$scope.getStatFile();
				layer.close($scope.fileName_btn);
			}
		})
		$scope.getStatFile = function() {
			$scope.statParams.opType = false;
			$scope.params = {};
			if($scope.statParams.statBy == "jobNum"){
				$scope.params = $scope.statsResult.profitStatByJobNumDTOs;
			}else{
				$scope.params = $scope.statsResult.profitStatByUserDTOs;
			}
			OpsFiles.statsBookingTruckFile({
					dateFrom: $filter('date')($scope.statParams.dateFrom, 'yyyy-MM-dd'),
					dateTo: $filter('date')($scope.statParams.dateTo, 'yyyy-MM-dd'),
					statBy:$scope.statParams.statBy,
					fileType:$scope.statParams.fileType
			},$scope.statsResult, function(result) {
				$scope.exportExcelOrPdf(result);
			}, function(error) {
				layer.msg($translate.instant('stats.stat.noData'));
			});
		}

		$scope.exportExcelOrPdf = function(result) {
			if (angular.isDefined($scope.statParams.fileType) && $scope.statParams.fileType == 2) {
				var file = new Blob([result.data], {
					type: 'application/vnd.ms-excel'
				});
				var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
				var aTag = document.createElement("a");
				aTag.download = $scope.statParams.fileName + ".xlsx";
				aTag.href = blobURL;
				aTag.click();
			} else {
	                var file = new Blob([result.data], {
	                    type: 'application/pdf'
	                });
	                var fileURL = URL.createObjectURL(file);
	                var previewDialog = layer.open({
	                    type: 2,
	                    area: ['1200px', '800px'],
	                    maxmin: true,
	                    title: $translate.instant("stats.stat.viewBookingTruckStatementFile"),
	                    shade: 0.3,
	                    moveType: 0,
	                    shift: 0,
	                    content: fileURL
	                })
	            }
		}
		
		$scope.statByName = ": "
		$scope.statByChange = function(statBY) {
			$scope.statByName = ": "
			$scope.statsResult = {};
			initContent();
			if (statBY=="jobNum"){
				$scope.isJobNumStats = true;
				$scope.statByName = $scope.statByName + $translate.instant('stats.stat.jobNum');
			}else if (statBY=="truckForwarder"){
				$scope.isJobNumStats = false;
				$scope.statByName = $scope.statByName + $translate.instant('stats.stat.truckForwarder');
			}
		};
        var isReverse = false;
        $scope.sortByTitle = function(title) {
        	isReverse = !isReverse;
    		$scope.statsResult.profitStatByJobNumDTOs.sort(function(a,b){
    			if (isReverse){
    				return a[title]<b[title]?1:-1;
    			}
    			else {
    				return a[title]>b[title]?1:-1;
    			}        			
    		});
        }
        $scope.sortNameByTitle = function(title) {
        	isReverse = !isReverse;
    		$scope.statsResult.profitStatByUserDTOs.sort(function(a,b){
    			if (isReverse){
    				return a[title]<b[title]?1:-1;
    			}
    			else {
    				return a[title]>b[title]?1:-1;
    			}        			
    		});
        }
		var initNums = function() {
			$scope.statsResult.gw = $scope.statsResult.gw.toFixed(2) -0;
			$scope.statsResult.vol = $scope.statsResult.vol.toFixed(3) -0;
			$scope.statsResult.chargeWeight = $scope.statsResult.chargeWeight.toFixed(2) -0;
			angular.forEach($scope.statsResult.profitStatByJobNumDTOs, function(data){
				data.gw = data.gw.toFixed(2) -0;
				data.vol = data.vol.toFixed(3) -0;
				data.chargeWeight = data.chargeWeight.toFixed(2) -0;
			})
			angular.forEach($scope.statsResult.profitStatByUserDTOs, function(data){
				data.gw = data.gw.toFixed(2) -0;
				data.vol = data.vol.toFixed(3) -0;
				data.chargeWeight = data.chargeWeight.toFixed(2) -0;
			})
		}
	}
})();