(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsFactoryController', OpsFactoryController);

    OpsFactoryController.$inject = ['$scope', '$rootScope', '$state', 'OpsFactory', 'OpsFactorySearch', 'ParseLinks'];
    
    function OpsFactoryController($scope, $rootScope, $state, OpsFactory, OpsFactorySearch,ParseLinks) {

        $scope.opsFactorys = [];
        $scope.predicate = 'id';
        $scope.reverse = false;
        $scope.page = 1;
        $scope.loadAll = function() {
            OpsFactory.query({page: $scope.page-1, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']},function(result,headers) {
            	$scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
            	$scope.opsFactorys = result;
            });
        };
        $scope.loadAll();


        $scope.search = function () {
            OpsFactorySearch.query({query: $scope.searchQuery}, function(result) {
                $scope.opsFactorys = result;
            }, function(response) {
                if(response.status === 404) {
                    $scope.loadAll();
                }
            });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };
        $scope.edit = function (id) {
            $rootScope.id = id;
            $state.go('opsFactory.edit');

        }
        $scope.delete = function (id, num) {
            $rootScope.num = num;
            $rootScope.id = id;
            $state.go('opsFactory.delete');

        }
        $scope.clear = function () {
            $scope.opsFactory = {
                companyId: null,
                createdBy: null,
                venderId: null,
                code: null,
                companyName: null,
                address: null,
                attn: null,
                mob: null,
                tel: null,
                fax: null,
                email: null,
                authority: null,
                remarks: null,
                createdTime: null,
                lastModifiedBy: null,
                lastModifiedTime: null,
                id: null
            };
        };
    }
})();
