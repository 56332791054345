(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsWorkLogSearchController', WmsWorkLogSearchController);

    WmsWorkLogSearchController.$inject = ['$scope', '$uibModalInstance', '$http', 'entity', 'WmsWorkLog'];

    function WmsWorkLogSearchController ($scope, $uibModalInstance, $http, entity, WmsWorkLog) {
       
        $scope.searchParams = entity;

        var vm = this;
        vm.isSaving = false;
        vm.clear = clear;

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.datePickerOpenStatus.workTime = false;
        vm.datePickerOpenStatus.createdTime = false;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }  
        
        $scope._onClientSelected = function(data) {
            $scope.searchParams.venderId = data.id;
        }

        $scope._onClerkSelected = function(clerk, data) {
            $scope.searchParams[clerk] = data.id;
        }
        $scope.getWmsWorkLogs = function() {
            vm.isSaving = true;
            WmsWorkLog.getWmsWorkLogs($scope.searchParams, function(result) {
                vm.isSaving = false;
                $uibModalInstance.close(result);
            }, function(response) {
                vm.isSaving = false;
            });  
        };

    }
})();
