(function () {
    'use strict';

    angular
        .module('autopsApp')
        .controller('PalletizeController', PalletizeController);

    PalletizeController.$inject = ['$scope', '$state', '$rootScope', '$timeout', '$translate',
        'WmsIn', 'DataUtils', 'WmsWarehouse', 'Palletize'];

    function PalletizeController($scope, $state, $rootScope, $timeout, $translate,
        WmsIn, DataUtils, WmsWarehouse, Palletize) {

        $timeout(function () {
            $("#field_warehousingNumber").focus();
        });

        var vm = this;
        vm.location = {};
        vm.cargo = {};
        vm.getInboundPaper = getInboundPaper;
        vm.save = save;
        vm.generatePalletNum = generatePalletNum;
        vm.clearOrder = clearOrder;
        vm.addPalletNum = addPalletNum;
        vm.addBox = addBox;
        vm.deleteBox = deleteBox;
        vm.deletePallet = deletePallet;
        vm.checkBox = checkBox;
        vm.scanBox = scanBox;
        vm.updatePalletVol = updatePalletVol;

        vm._onLocationSelected = _onLocationSelected;
        vm.getLocations = getLocations;
        function _onLocationSelected(item, cargo) {
            cargo.location = item.code;
            cargo.locationId = item.locationId;
            cargo.locationType = item.locationType;
            cargo.areaId = item.areaId;
            cargo.warehouseId = item.warehouseId;
        }
        vm.locations = [];

        function getLocations() {
            if (!vm.locations || vm.locations.length == 0) {
                WmsWarehouse.getLocations({ warehouseId: vm.wmsIn.warehouseId }, function(data) {
                    vm.locations = data;
                });
            }
        }
        
        // 需要与cargo里的属性名相同
        vm.BOX_NUM_TYPE = ["mark", "sku", "poNum", "batchOne"]
        vm.BOX_NUM_TYPE_OPTIONS = [
            {
                value: "mark",
                label: $translate.instant('autopsApp.wmsIn.mark')
            },
            {
                value: "sku",
                label: "SKU"
            },
            {
                value: "poNum",
                label: "PO#"
            },
            {
                value: "batchOne",
                label: "ShipmentId"
            },
        ]
        vm.DEFAULT_BOX_NUM_TYPE = "sku";
        var tdCargoList;

        function getInboundPaper() {
            if (!vm.warehousingBarcode) {
                return;
            }
            vm.isSaving = true;
            Palletize.getWmsInByWarehousingNumber({
                warehousingNumber: vm.warehousingBarcode
            }, function (result) {
                vm.isSaving = false;
                vm.wmsIn = result.pltOrder;
                tdCargoList = result.tdOrder.cargoList;
                parsePalletizes();
                fucusInput("field_palletNum");
            }, function (result) {
                vm.isSaving = false;
                if (result.status == 404) {
                    layer.msg($translate.instant('global.noRecord'));
                }
                else {
                    layer.msg($translate.instant('global.loadFailed'));
                }
            });
        }

        function generatePalletNum() {
            var newPalletNum;
            angular.forEach(vm.palletNums, function (palletNum) {
                if (palletNum != null && palletNum.indexOf(vm.warehousingBarcode.substring(2)) != -1) {
                    var lastNumIndex = palletNum.lastIndexOf("-");
                    if (lastNumIndex != -1) {
                        var lastNum = parseInt(palletNum.substring(lastNumIndex + 1));
                        if (!newPalletNum || lastNum > newPalletNum) {
                            newPalletNum = lastNum;
                        }
                    }
                }
            })
            if (newPalletNum) {
                vm.palletNum = "TP-" + vm.warehousingBarcode.substring(2) + "-" + (newPalletNum + 1);
            } else {
                vm.palletNum = "TP-" + vm.warehousingBarcode.substring(2) + "-1";
            }
            addPalletNum();
        }

        function clearOrder() {
            vm.wmsIn = {};
            vm.location = {};
            vm.cargo = {};
            vm.palletNum = "";
            vm.palletNums = [];
            vm.warehousingBarcode = "";
        }

        function parsePalletizes() {
            vm.palletNums = [];
            angular.forEach(vm.wmsIn.cargoList, function (item) {
                vm.palletNums.push(item.palletizedAt);
            })
        }

        function addPalletNum() {
            if (vm.palletNums.indexOf(vm.palletNum) != -1) {
                layer.msg($translate.instant('autopsApp.palletize.palletNumberExist'))
                return;
            }
            if (!vm.wmsIn.cargoList) {
                vm.wmsIn.cargoList = [];
            }
            var palletizes = [];
            palletizes.push({
                companyId: $rootScope.account.companyId,
                boxNum: "",
                boxNumType: vm.DEFAULT_BOX_NUM_TYPE,
                orderId: vm.wmsIn.id,
                palletNum: vm.palletNum,
                quantity: 0
            });
            vm.wmsIn.cargoList.unshift({
                palletizedAt: vm.palletNum,
                palletizes: palletizes,
                length: 122,
                width: 102,
                height: 188,
                gw: 0,
                vol: 2.34,
                show: false,
            });
            vm.palletNums.push(vm.palletNum);
            fucusInput(vm.palletNum + "field_boxNum0");
            vm.palletNum = "";
        }

        function addBox(pallet) {
            pallet.palletizes.push({
                companyId: $rootScope.account.companyId,
                boxNum: "",
                boxNumType: vm.DEFAULT_BOX_NUM_TYPE,
                orderId: vm.wmsIn.id,
                palletNum: vm.palletNum,
                quantity: 0
            })
            fucusInput(pallet.palletizedAt + "field_boxNum" + (pallet.palletizes.length - 1));
        }

        function deleteBox(palletizes, palletize) {
            if (palletize.id) {
                Palletize.deleteBox({ id: palletize.id }, function () {
                    palletizes.splice(palletizes.indexOf(palletize), 1);
                })
            } else {
                palletizes.splice(palletizes.indexOf(palletize), 1);
            }
        }

        function deletePallet(pallet) {
            if (pallet.id) {
                Palletize.deletePallet({ id: pallet.id }, null, function () {
                    vm.wmsIn.cargoList.splice(vm.wmsIn.cargoList.indexOf(pallet), 1);
                })
            } else {
                vm.wmsIn.cargoList.splice(vm.wmsIn.cargoList.indexOf(pallet), 1);
            }
            vm.palletNums.splice(vm.palletNums.indexOf(pallet.palletizedAt), 1);
        }

        function scanBox(palletize) {
            if (!palletize.boxNum) {
                return;
            }
            // 获取当前箱对应的Cargo对象及相同箱号货物数量
            var existCargo = false;
            angular.forEach(tdCargoList, function (cargo) {
                if (!existCargo && cgPltMatch(palletize, cargo)) {
                    existCargo = true;
                    palletize.quantity++;
                }
            })
            // 输入的箱号与现有的货物匹配不上
            if (!existCargo) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.palletize.boxNumNoExist') + ":(" + palletize.boxNumType + ")" + palletize.boxNum);
                palletize.boxNum = "";
                return;
            }            
            checkBox(palletize);
        }

        function checkBox(palletize) {
            var count = 0;
            angular.forEach(tdCargoList, function (cargo) {
                if (cargo[palletize.boxNumType] 
                    && (cargo[palletize.boxNumType] == palletize.boxNum 
                        || palletize.boxNum.startsWith(cargo[palletize.boxNumType]))) {
                    count += cargo.pkgs;
                }
            })
            // 检查数量是否超过业务单中的数据
            var currentCount = 0;
            var plt;
            angular.forEach(vm.wmsIn.cargoList, function (pallet) {
                angular.forEach(pallet.palletizes, function (box) {
                    if (box.boxNumType == palletize.boxNumType && box.boxNum == palletize.boxNum) {
                        currentCount += box.quantity;
                        plt = pallet;
                    }
                    // else {
                    //     var exist = false;
                    //     angular.forEach(tdCargoList, function(cargo) {
                    //         if(box.boxNum.startsWith(cargo[box.boxNumType]) && palletize.boxNum.startsWith(cargo[palletize.boxNumType])) {
                    //             exist = true;
                    //         }
                    //     })
                    //     if (exist) {
                    //         currentCount += box.quantity;
                    //     }     
                    // }
                })
            })
            if (currentCount > count) {
                var allowedQuantity = count - (currentCount - palletize.quantity);
                layer.msg("(" + palletize.boxNumType + ")" + palletize.boxNum + " " + $translate.instant('autopsApp.palletize.quantityExceeded') + ":" + count);
                if (allowedQuantity != 0) {
                    palletize.quantity = allowedQuantity;
                } else {
                    palletize.quantity = null;
                }
            }
            if(plt){
                updatePalletGw(plt);
            }            
        }

        // 更新托盘重量
        function updatePalletGw(pallet) {
            var gw = 0;
            angular.forEach(pallet.palletizes, function (palletize) {
                var existCargo = null;
                angular.forEach(tdCargoList, function (cargo) {
                    if (cgPltMatch(palletize, cargo)) {
                        existCargo = cargo;
                    }
                })
                if (existCargo && existCargo.gw && palletize.quantity) {
                    gw += existCargo.gw * palletize.quantity;
                }
            })
            pallet.gw = DataUtils.round(gw);
        }
        function cgPltMatch(palletize, cargo) {
            if (cargo[palletize.boxNumType] && (cargo[palletize.boxNumType] == palletize.boxNum || cargo[palletize.boxNumType].startsWith(palletize.boxNum))) {
                return true;
            }
            return false;
        }

        // 更新托盘体积
        function updatePalletVol(pallet) {
            if (pallet && pallet.length && pallet.width && pallet.height) {
                pallet.vol = (pallet.length * pallet.width * pallet.height) / 1000000;
            } else {
                pallet.vol = 0;
            }
        }

        function updatePalletInfo() {
            angular.forEach(vm.BOX_NUM_TYPE, function (item) {
                angular.forEach(vm.wmsIn.cargoList, function (pallet) {
                    pallet[item] = "";
                })
            })
            angular.forEach(vm.wmsIn.cargoList, function (pallet) {
                pallet.piece = 0;
                angular.forEach(pallet.palletizes, function (palletize) {
                    if (palletize.boxNum && pallet[palletize.boxNumType] != null && pallet[palletize.boxNumType].indexOf(palletize.boxNum) == -1) {
                        pallet[palletize.boxNumType] += palletize.boxNum + " ";
                    }
                    if(palletize.quantity){
                        pallet.piece += palletize.quantity;
                    }
                })
            })
        }

        function fucusInput(fdId) {
            $timeout(function () {
                $("#" + fdId).focus();
            });
            // document.activeElement.blur();
        }
        function save() {
            vm.isSaving = true;
            for (var i = 0; i < vm.wmsIn.cargoList.length; i++) {
                var pallet = vm.wmsIn.cargoList[i];
                if(!pallet.pkgs){
                    pallet.pkgs = 1;
                    pallet.packageType = "PALLETS";                    
                }
                if(!pallet.mark){
                    pallet.mark = vm.wmsIn.mark;
                    alert(pallet.mark)
                }
                for (var j = 0; j < pallet.palletizes.length; j++) {
                    var palletize = pallet.palletizes[j];
                    if (!palletize.boxNum){
                        layer.msg($translate.instant('autopsApp.palletize.boxNumEmpty'));
                        pallet.show = false;
                        fucusInput(pallet.palletizedAt + "field_boxNum" + j);
                        vm.isSaving = false;
                        return;
                    }
                    if (!palletize.quantity) {
                        layer.msg($translate.instant('autopsApp.palletize.boxQuantityEmpty'));
                        pallet.show = false;
                        fucusInput(pallet.palletizedAt + "field_quantity" + j);
                        vm.isSaving = false;
                        return;
                    }
                }
            }
            updatePalletInfo();
            WmsIn.registPltIn(vm.wmsIn, onSaveSuccess, onSaveError);
        }
        function onSaveSuccess(result) {
            vm.isSaving = false;
            getInboundPaper();
            layer.msg($translate.instant('global.messages.submitted'));
        }

        function onSaveError(result) {
            vm.isSaving = false;
            if (result.status == 409) {
                layer.msg($translate.instant('global.refreshAlert'));
            }
        }



    }
})();
