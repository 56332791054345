(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CrmEnquiryDialogController', CrmEnquiryDialogController);

    CrmEnquiryDialogController.$inject = ['$timeout', '$rootScope', '$scope', '$translate', '$uibModalInstance', '$uibModal', '$location', 
    'entity', 'CrmEnquiry', 'Dictionary', 'DataUtils', 'AddressBook', 'OpsVender', 'CrmQuote'];

    function CrmEnquiryDialogController($timeout, $rootScope, $scope, $translate, $uibModalInstance, $uibModal, $location,
        entity, CrmEnquiry, Dictionary, DataUtils, AddressBook, OpsVender, CrmQuote) {
        var vm = this;

        vm.crmEnquiry = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.addVolume = addVolume;
        vm.delVolume = delVolume;
        vm.volumeChange = volumeChange;
        vm.volumeUnits = Dictionary.volumeUnits;

        vm.getContactList = getContactList;
        vm.updateContact = updateContact;
        vm.enquiryLocked = enquiryLocked;
        vm.viewQuotation = viewQuotation;
        vm.sendEnquiry = sendEnquiry;
        vm.quoterChoosed = quoterChoosed;
        vm.files = [];

        function enquiryLocked() {
            return vm.crmEnquiry.status && vm.crmEnquiry.status != "new";
        }
        function viewQuotation(crmQuoteId) {
            $uibModal.open({
                templateUrl: 'app/entities/crm-enquiry/crm-enquiry-detail.html',
                controller: 'CrmEnquiryDetailController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xxxl',
                resolve: {
                    entity: ['CrmQuote', function(CrmQuote) {
                        return CrmQuote.get({id : crmQuoteId}).$promise;
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }
        // vm.setShowIdx = setShowIdx;
        // vm.showIdx;
        // function setShowIdx(idx) {
        //     vm.showIdx = idx;
        //     $timeout(function() {
        //         $("#" + idx + "_field").focus();
        //         $("#" + idx + "_field").select();
        //     });
        // }
        $timeout(function() {
            if(vm.crmEnquiry.createrId && vm.crmEnquiry.createrId != $rootScope.account.id && $rootScope.HAVENOAUTH('AU_VIEW_ALL_ENQUIRY')){
                $uibModalInstance.dismiss();
            }
            // angular.element('.form-group:eq(1)>input').focus();
            initJasonResp();
        });
        function initJasonResp(argument) {
            if (vm.crmEnquiry.volumeJson) {
                vm.crmEnquiry.volumeJsonObj = angular.fromJson(vm.crmEnquiry.volumeJson);
            }
            if (!vm.crmEnquiry.volumeJsonObj) {
                vm.crmEnquiry.volumeJsonObj = [
                    { amt: null, unit: "KG" },
                    { amt: null, unit: "CBM" },
                    { amt: null, unit: "PKGS" }
                ];
            }
            if(vm.crmEnquiry.pickupRequired == null){
                vm.crmEnquiry.pickupRequired = true;
            }
            if(vm.crmEnquiry.deliveryRequired == null){
                vm.crmEnquiry.deliveryRequired = true;
            }
            if(vm.crmEnquiry.dutyRequired == null){
                vm.crmEnquiry.dutyRequired = true;
            }
            if(vm.crmEnquiry.insuranceRequired == null){
                vm.crmEnquiry.insuranceRequired = true;
            }
            volumeChange();
        }

        function volumeChange() {
            vm.crmEnquiry.gw = 0;
            vm.crmEnquiry.vol = 0;
            vm.crmEnquiry.pkgs = 0;
            var units = new Set();
            angular.forEach(vm.crmEnquiry.volumeJsonObj, function(data) {
                if (data.unit == "KG") {
                    if (data.amt) {
                        vm.crmEnquiry.gw += data.amt;
                    }
                } else if (data.unit == "CBM") {
                    if (data.amt) {
                        vm.crmEnquiry.vol += data.amt;
                    }
                } else if (data.unit == "PKGS") {
                    if (data.amt) {
                        vm.crmEnquiry.pkgs += data.amt;
                    }
                } else if (data.unit) {
                    units.add(data.unit);
                }
            })
            vm.crmEnquiry.gw = DataUtils.round(vm.crmEnquiry.gw, 4);
            vm.crmEnquiry.vol = DataUtils.round(vm.crmEnquiry.vol, 4);
            vm.crmEnquiry.pkgs = DataUtils.round(vm.crmEnquiry.pkgs, 0);

            vm.crmEnquiry.volume = "";
            if (vm.crmEnquiry.pkgs) {
                vm.crmEnquiry.volume += vm.crmEnquiry.pkgs + "PKGS ";
            }
            if (vm.crmEnquiry.gw) {
                vm.crmEnquiry.volume += vm.crmEnquiry.gw + "KG ";
            }
            if (vm.crmEnquiry.vol) {
                vm.crmEnquiry.volume += vm.crmEnquiry.vol + "CBM ";
            }

            units.forEach(function(unit) {
                var ttAmt = 0;
                angular.forEach(vm.crmEnquiry.volumeJsonObj, function(data) {
                    if (data.unit == unit && data.amt) {
                        ttAmt += data.amt;
                    }
                })
                ttAmt = DataUtils.round(ttAmt, 0);
                if (ttAmt) {
                    vm.crmEnquiry.volume += ttAmt + "x" + unit + " ";
                }
            })

        }

        function addVolume() {
            vm.crmEnquiry.volumeJsonObj.push({});
        }

        function delVolume(volume) {
            var index = vm.crmEnquiry.volumeJsonObj.indexOf(volume);
            if (index > -1) {
                vm.crmEnquiry.volumeJsonObj.splice(index, 1);
            };
        }

        function clear() {
            $uibModalInstance.dismiss(vm.crmEnquiry);
        }

        function save() {
            vm.isSaving = true;
            vm.crmEnquiry.volumeJson = angular.toJson(vm.crmEnquiry.volumeJsonObj, true);
            if (vm.crmEnquiry.id !== null) {
                CrmEnquiry.update(vm.crmEnquiry, onSaveSuccess, onSaveError);
            } else {
                CrmEnquiry.save(vm.crmEnquiry, onSaveSuccess, onSaveError);
            }
        }

        var path = $location.path();
        function onSaveSuccess(result) {
            vm.isSaving = false;
            $scope.$emit('autopsApp:crmEnquiryUpdate', result);
            vm.crmEnquiry = result;
            initJasonResp();
            layer.msg($translate.instant('global.save_success'));
            if (path.indexOf("crm-quote") >= 0) {
                $uibModalInstance.close(result);
            }            
            $translate.refresh();
        }

        function onSaveError() {
            vm.isSaving = false;
            layer.msg($translate.instant('global.save_fail'));
        }

        vm.datePickerOpenStatus.enquiryTime = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $scope._selected = function($item, which) {
            switch (which) {
                case "pol":
                    AddressBook.getFbaAddress({ code: $item.code, countryCode: $item.countryCode}, function(result) {
                        vm.crmEnquiry.porAddress = result.address;
                        vm.crmEnquiry.shipperCity = result.city;
                        vm.crmEnquiry.shipperPostalCode = result.postCode;
                        vm.crmEnquiry.shipperCountryCode = result.countryCode;
                    });
                    break;
                case "pod":
                    AddressBook.getFbaAddress({ code: $item.code, countryCode: $item.countryCode}, function(result) {
                        vm.crmEnquiry.podAddress = result.address;
                        vm.crmEnquiry.shiptoCity = result.city;
                        vm.crmEnquiry.shiptoPostalCode = result.postCode;
                        vm.crmEnquiry.shiptoCountryCode = result.countryCode;
                    });
                    break;
            }
        }

        $scope._onVenderSelected = function(data, guide) {
            OpsVender.getDTO({
                id: data.id
            }, function(result) {
                vm.crmEnquiry.venderId = result.venderId;
                vm.crmEnquiry.inquirerCompany = result.companyName;
                vm.contactList = result.contactList  
                if (result.contactList.length == 1) {
                    updateContact(result.contactList[0]);
                } else {
                    choosedContact(result.contactList);
                }     
            })
        }

        vm.contactList = [];
        function getContactList() {
            if(!vm.crmEnquiry.venderId || vm.contactList.length > 0){
                return;
            }
            OpsVender.getDTO({
                id: vm.crmEnquiry.venderId
            }, function(result) {
                vm.contactList = result.contactList  
            })
        }

        
        var choosedContact = function(contactList) {
            $uibModal.open({
                templateUrl: 'app/entities/guide/contact-list.html',
                controller: 'ContactListController',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return contactList;
                    }]
                }
            }).result.then(function(contact) {
                updateContact(contact);
                $translate.refresh();
            }, function() {});
        }

        function updateContact (contact) {
            vm.crmEnquiry.inquirerId = contact.userId;
            vm.crmEnquiry.inquirerName = contact.attn;
            vm.crmEnquiry.inquirerEmail = contact.email;
        }

        vm.quoter = null;
        function quoterChoosed (clerk) {
            vm.quoter = clerk;
        }

        function sendEnquiry() {
            if(!vm.quoter.id){
                return;
            }
            if(!vm.crmEnquiry.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            CrmQuote.sendEnquiry({
                crmEnquiryId: vm.crmEnquiry.id,
                userId: vm.quoter.id
            }, function(result) {
                layer.msg($translate.instant('global.send_success'));
                CrmEnquiry.get({id : vm.crmEnquiry.id}, function(result){
                    vm.crmEnquiry = result;
                    initJasonResp();
                });
            }, $rootScope.ALERT_ERROR)
        }


    }
})();
