(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ClientTrackingController', ClientTrackingController);

    ClientTrackingController.$inject = ['$scope', '$rootScope', '$translate', 'Principal', 'Guide', 'DateUtils', 'OpsFiles'];

    function ClientTrackingController($scope, $rootScope, $translate, Principal, Guide, DateUtils, OpsFiles) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };

        $scope.filterDTO = {isShipper: false};

        $scope.setIsShipper = function(isShipper) {
            $scope.filterDTO.isShipper = isShipper;
        };

        $scope.datePickerForEtd = {};
        $scope.datePickerForEtd.status = {
            opened: false
        };
        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };

        $scope.datePickerForCargoReadyDate = {};

        $scope.datePickerForCargoReadyDate.status = {
            opened: false
        };

        $scope.datePickerForCargoReadyDateOpen = function($event) {
            $scope.datePickerForCargoReadyDate.status.opened = true;
        };

        $scope.opsClient = [];
        $scope.contents = [];
        $scope.getContetns = function() {
            if (!$scope.filterDTO.etdFrom || !$scope.filterDTO.etdEnd) {
                layer.msg($translate.instant('stats.stat.nullFilterAlert'));
                return;
            }
            if (DateUtils.compareDate($scope.filterDTO.etdFrom, $scope.filterDTO.etdEnd) > 0) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                return;            
            }
            if (DateUtils.getDaysDiff($scope.filterDTO.etdFrom, $scope.filterDTO.etdEnd) > 365) {
                layer.msg($translate.instant('global.messages.etdPeriodAlert'));
                return;            
            }

            $scope.isSaving = true;
            Guide.getTrackingReport($scope.filterDTO, function(result) {
                $scope.isSaving = false;
                if (!result || result.length == 0) {
                    initContent();
                    layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                    return;
                }
                $scope.contents = [];
                $scope.contents = result;
                $scope.reportavailalbe = true;
            }, function(response) {
                $scope.isSaving = false;
                $scope.reportavailalbe = false;

            });
        }

        var initContent = function() {
            if (!$scope.contents || $scope.contents.length == 0) {
                $scope.contents = [];
                for (var j = 0; j <= 20; j++) {
                    $scope.contents.push({});
                }
            }
        }
        initContent();

        $scope.reportavailalbe = false;

        $scope.exportTracking = function(format) {
            OpsFiles.generateClientTrackingReport({
                dateFrom: $("#field_etd").val(),
                dateTo: $("#field_cargoReadyDate").val(),
                fileType: format
            }, $scope.contents, function(result) {
                if (format == 'excel') {
                    var file = new Blob([result.data], {
                        type: 'application/vnd.ms-excel'
                    });
                    var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
                    var aTag = document.createElement("a");
                    aTag.download = 'Tracking' + ".xlsx";
                    aTag.href = blobURL;
                    aTag.click();
                } else {
                    var file = new Blob([result.data], {
                        type: 'application/pdf'
                    });
                    var fileURL = URL.createObjectURL(file);
                    var previewDialog = layer.open({
                        type: 2,
                        area: ['1200px', '800px'],
                        maxmin: true,
                        title: $translate.instant("autopsApp.accountTitle.viewReport"),
                        shade: 0.3,
                        moveType: 0,
                        shift: 0,
                        content: fileURL
                    })
                }
            }, function(error) {
                layer.msg($translate.instant('stat.stat.generateTrackingReportFailed'));
            });
        }


    }
})();