(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('OpsUser', OpsUser);

    OpsUser.$inject = ['$resource','DateUtils'];

    function OpsUser($resource, DateUtils) {
        return $resource('api/opsUsers/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
