(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ParcelGoodsDeleteController',ParcelGoodsDeleteController);

    ParcelGoodsDeleteController.$inject = ['$uibModalInstance', 'entity', 'ParcelGoods'];

    function ParcelGoodsDeleteController($uibModalInstance, entity, ParcelGoods) {
        var vm = this;

        vm.parcelGoods = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ParcelGoods.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
