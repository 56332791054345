(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('ImChatService', ImChatService);

    ImChatService.$inject = ['$q', 'ImChatFactory', '$rootScope'];
    
    function ImChatService($q, ImChatFactory, $rootScope) {
        var recentContact = $q.defer();
        var unReadMsg = $q.defer();
        var newMsg = $q.defer();
        var chatInfo = $q.defer();
        
        function dealWithTimeOut(){
            WKIT.Conn.sdk.Base.logout({
                success:function(data){
//                  console.log(data);
                },
                error:function(error){
                    if(error.code == 1002){//timeout
                        dealWithTimeOut();
                    }
                    if(error.code == 1001){//not login
                        
                    }
                    console.log('liveChat登出失败:'+error.code);
                }
                
            });
            WKIT.destroy();
        }
        
        return {
            //######################## 获取最近联系人 #############################
            dealWithRecentContact: function() {
                var recentResponsed = new Array();
                WKIT.Conn.sdk.Base.getRecentContact({
                    count: 99,
                    success: function(recentRes) {
                        if (recentRes.code == '1000') {
                            var recentResult = recentRes.data.cnts;
                            if (recentResult.length > 0) {
                                var requestParamImUsers = "";
                                for (var i = 0; i < recentResult.length; i++) {
                                    var uid = recentResult[i]['uid'].substring(8, recentResult[i]['uid'].length);
                                    requestParamImUsers = requestParamImUsers + "," + uid;
                                }

                                requestParamImUsers = requestParamImUsers.substring(1, requestParamImUsers.length);

                                ImChatFactory.getRecentContancts({
                                    userids: requestParamImUsers
                                }, function(data) {

                                    if (data.length > 0) {
                                        for (var j = 0; j < data.length; j++) {
                                            var responedObj = new Object();
                                            responedObj['userid'] = data[j]['userid'];
                                            responedObj['nick'] = data[j]['nick'];
                                            responedObj['unRmsgNum'] = 0;
                                            recentResponsed.push(responedObj);
                                        }
                                    }
                                    recentContact.notify(recentResponsed);
                                });
                            } else {
                                console.log('No Recent Contact');
                            }
                        } else {
                            recentContact.notify(recentResponsed);
                            console.log('No Recent Contact');
                        }
                    },
                    error: function(error) {
                        recentContact.notify(recentResponsed);
                        console.log('GET Recent Contact Failed');
                    }
                });
            },
            getRecentContact: function() {
                return recentContact.promise;
            }, //######################## 获取最近联系人 #############################
            dealWithUnReadMsg: function() {
                var unReadResponsed = new Array();
                WKIT.Conn.sdk.Base.getUnreadMsgCount({
                    count: 10,
                    success: function(unReadRes) {

                        if (unReadRes.code == '1000') {
                            var unReadContactList = unReadRes.data;
                            if (unReadContactList.length > 0) {
                                for (var i = 0; i < unReadContactList.length; i++) {
                                    var responsedObj = new Object();
                                    var userid = unReadContactList[i]['contact'].substring(8, unReadContactList[i]['contact'].length);
                                    responsedObj['userid'] = userid;
                                    responsedObj['unRmsgNum'] = unReadContactList[i]['msgCount'];
                                    unReadResponsed.push(responsedObj);
                                }
                                unReadMsg.notify(unReadResponsed);
                            }
                        } else {
                            unReadMsg.notify(unReadResponsed);
                            console.log('No Un Read Msg');
                        }
                    },
                });
            },
            getUnReadMsg: function() {
                return unReadMsg.promise;
            }, //######################## unRead Msg #############################
            //######################## listen Msg #############################
            dealWithNewMsg: function() {
                WKIT.Conn.sdk.Event.on('START_RECEIVE_ALL_MSG', function(eventRes) {
                    if (eventRes.code == '1000') {
                        var result = new Object();
                        var resultMsgs = eventRes.data['msgs'];
                        result['userid'] = resultMsgs[0]['from'];
                        result['msg'] = resultMsgs[0]['msg'];
                        result['userid'] = result['userid'].substring(8, result['userid'].length);
                        newMsg.notify(result);
                    }
                });
            },
            startListenMsg: function() {
                return newMsg.promise;
            },
            switchTouid: function(touid) {
                if (touid == $rootScope.touid) {
                    return;
                }

                WKIT.switchTouid({
                    touid: touid,
                    avatar: 'https://hforwarder.oss-cn-shenzhen.aliyuncs.com/HeadShot/default.jpg',
                    toAvatar: 'https://hforwarder.oss-cn-shenzhen.aliyuncs.com/HeadShot/default.jpg',
                });


                $rootScope.touid = touid;
                //set old chat user had read
                setTimeout(function() {
                    WKIT.Conn.sdk.Chat.setReadState({
                        touid: touid,
                        timestamp: Math.floor((+new Date()) / 1000),
                        success: function(data) {
                            //                          console.log('set read state success', data);
                        },
                        error: function(error) {
                            //                          console.log('set read state fail', error);
                        }
                    });
                }, 1000);
                //set new chat user had read
                setTimeout(function() {
                    WKIT.Conn.sdk.Chat.setReadState({
                        touid: touid,
                        timestamp: Math.floor((+new Date()) / 1000),
                        success: function(data) {
                            //                          console.log('set read state success', data);
                        },
                        error: function(error) {
                            //                          console.log('set read state fail', error);
                        }
                    });
                }, 1000);
            },
            sendMsg: function(touid, msg) {
                WKIT.sendMsg({
                    msgType: 0,
                    msg: msg
                });
            },
            logout: function() {
                if(!angular.isDefined(WKIT.Conn.sdk)){
                    return;
                }
                dealWithTimeOut();
            },
            listenOff: function() {
                WKIT.Conn.sdk.Event.off();
            }
        }


    }
})();