(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('PublishRate', PublishRate);

    PublishRate.$inject = ['$resource', 'DateUtils'];

    function PublishRate ($resource, DateUtils) {
        var resourceUrl =  'api/publish-rates/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.effectiveDate = DateUtils.convertLocalDateFromServer(data.effectiveDate);
                        data.expireDate = DateUtils.convertLocalDateFromServer(data.expireDate);
                        data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.effectiveDate = DateUtils.convertLocalDateToServer(data.effectiveDate);
                    data.expireDate = DateUtils.convertLocalDateToServer(data.expireDate);
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.effectiveDate = DateUtils.convertLocalDateToServer(data.effectiveDate);
                    data.expireDate = DateUtils.convertLocalDateToServer(data.expireDate);
                    return angular.toJson(data);
                }
            },
            'loadPublishRateList': {
                method: 'GET',
                url:'api/publish-rates/loadPublishRateList',
                isArray: true,
                transformResponse: function (dataList) {
                    if (dataList) {
                        dataList = angular.fromJson(dataList);
                        angular.forEach(dataList, function(data){
                            data.effectiveDate = DateUtils.convertLocalDateFromServer(data.effectiveDate);
                            data.expireDate = DateUtils.convertLocalDateFromServer(data.expireDate);
                            data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                        })      
                    }
                                  
                    return dataList;
                }
            }

        });
    }
})();
