(function() {
    'use strict';
    /* globals SockJS, Stomp */

    angular
        .module('autopsApp')
        .factory('MsgTrackerService', MsgTrackerService);

    MsgTrackerService.$inject = ['$rootScope', '$window', '$cookies', '$http', '$q'];

    function MsgTrackerService($rootScope, $window, $cookies, $http, $q) {
        var stompClient = null;
        var subscriber = null;
        var listener = $q.defer();
        var connected = $q.defer();
        var alreadyConnectedOnce = false;

        var subscriberUrl = '/topic/tracker/msg/';
        var service = {
            connect: connect,
            disconnect: disconnect,
            receive: receive,
            sendActivity: sendActivity,
            subscribe: subscribe,
            unsubscribe: unsubscribe
        };

        return service;

        function connect(userId) {
            //building absolute path so that websocket doesnt fail when deploying with a context path
            var loc = $window.location;
            var url = '//' + loc.host + loc.pathname + 'websocket/tracker';
            subscriberUrl = '/topic/tracker/msg/' + userId;           
            var socket = new SockJS(url);
            stompClient = Stomp.over(socket);
            var stateChangeStart;
            var headers = {};
            headers['X-CSRF-TOKEN'] = $cookies[$http.defaults.xsrfCookieName];
            stompClient.connect(headers, function() {
                connected.resolve('success');
                subscribe();
                sendActivity();
                if (!alreadyConnectedOnce) {
                    stateChangeStart = $rootScope.$on('$stateChangeStart', function() {
                        sendActivity();
                    });
                    alreadyConnectedOnce = true;
                }
            });
            $rootScope.$on('$destroy', function() {
                if (angular.isDefined(stateChangeStart) && stateChangeStart !== null) {
                    stateChangeStart();
                }
            });
        }

        function disconnect() {
            if (stompClient !== null) {
                unsubscribe();
                stompClient.disconnect();
                stompClient = null;
            }
        }

        function receive() {
            return listener.promise;
        }

        function sendActivity() {
            if (stompClient !== null && stompClient.connected) {
                stompClient
                    .send('/topic/activity/msg', {},
                        angular.toJson({
                            'page': $rootScope.toState.name,
                            'url': subscriberUrl
                        }));
            }
        }

        function subscribe() {
            connected.promise.then(function() {
                subscriber = stompClient.subscribe(subscriberUrl, function(data) {
                    listener.notify(angular.fromJson(data.body));
                });
            }, null, null);
        }

        function unsubscribe() {
            if (subscriber !== null) {
                subscriber.unsubscribe();
            }
            listener = $q.defer();
        }
    }
})();