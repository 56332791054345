
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('JhiSignatureController', JhiSignatureController);

    JhiSignatureController.$inject = ['$scope', '$rootScope', '$translate', '$uibModalInstance', '$timeout', '$uibModal',
    'entity', 'JhiSignature', 'DateUtils'];

    function JhiSignatureController($scope, $rootScope, $translate, $uibModalInstance, $timeout, $uibModal,
        entity, JhiSignature, DateUtils) {

        $scope.portRemark = {};
        $scope.portCode = entity.code;

        $timeout(function (){
            JhiSignature.getPortRemarks({
                companyId: entity.companyId,
                code: entity.code
            }, function(result){
                $scope.portRemark = result;
            }, function(result){
                $rootScope.OPS_ALERT($translate.instant('global.codeInvalid', {code: entity.code}));
            })
        });

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.newPractice = "";
        $scope.addPractice = function () {
            if(!$scope.newPractice){return}
            if($scope.portRemark.signature == null){
                $scope.portRemark.signature = "【" + $rootScope.account.firstName + " " + $rootScope.account.lastName + " - " + DateUtils.convertLocalDateToServer(new Date()) + "】\n" + $scope.newPractice;
            }
            else {
                $scope.portRemark.signature= $scope.portRemark.signature + "\n\n【" + $rootScope.account.firstName + " " + $rootScope.account.lastName + " - " + DateUtils.convertLocalDateToServer(new Date()) + "】\n" + $scope.newPractice;
            }
            JhiSignature.update($scope.portRemark, onSaveSuccess, onSaveError);
        }

        $scope.textPop = function(text) {
            $rootScope.notBlInfo = true;//不是提单信息 不执行提单信息的强制大写
            $uibModal.open({
                templateUrl: 'app/entities/ops-change-log/ops-change-log-delete-dialog.html',
                controller: 'OpsChangeLogDeleteController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return text;
                    }]
                }
            }).result.then(function(result) {
                $rootScope.notBlInfo = null;
                if (text != result) {
                    $scope.portRemark.signature = result;
                    JhiSignature.update($scope.portRemark, onSaveSuccess, onSaveError);
                }
            }, function() {});
        }

        var onSaveSuccess = function (result) {
            layer.msg($translate.instant('global.messages.submitted'));
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            layer.msg($translate.instant('global.messages.submittedfailed'));
            $scope.isSaving = false;
        };
}
})();

