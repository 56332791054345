(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsExchangeRateDialogController', OpsExchangeRateDialogController);

    OpsExchangeRateDialogController.$inject = ['$location', '$translate', '$timeout', '$scope', '$stateParams', '$uibModalInstance', '$rootScope', 'Principal', 'entity', 'OpsExchangeRate', 'DateUtils'];

    function OpsExchangeRateDialogController ($location, $translate, $timeout, $scope, $stateParams, $uibModalInstance, $rootScope, Principal, entity, OpsExchangeRate, DateUtils) {
        
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };

        setTimeout(function () {   
            var path = $location.path();
            if (!$rootScope.id && path.indexOf("new") <= 0){
                $uibModalInstance.dismiss('cancel');
            }
        }, 500);  

        var vm = this;

        vm.opsExchangeRate = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.baseCurrencyFilter = baseCurrencyFilter;
        vm.setRateType = setRateType;
        vm.remarksDisabled = remarksDisabled;

        vm.allCurrencys = ["RMB", "USD", "EUR", "HKD", "JPY", "INR", "GBP", "AUD", "THB", "DHS", "SGD", "TWD", "MYR", "NOK", 
            "DKK", "CAD", "NZD", "SAR", "IDR", "NGN", "TZS", "KWD", "CHF", "LKR", "VND", "BRL", "SEK", "KRW", "ZAR", "MMK", "OMR", 
            "RUB","TND", "AED", "MOP", "MAD", "BDT", "KHR", "PHP", "GYD", "MXN", "MZN"];

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function baseCurrencyFilter (item) {
            return item != $rootScope.account.baseCurrency;
        }

        function setRateType (isPay) {
            if(vm.opsExchangeRate.id){
                return;
            }
            vm.opsExchangeRate.isPay = isPay;
        }
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.opsExchangeRate.companyId = $rootScope.account.companyId;
            vm.opsExchangeRate.createdTime = new Date();
            if (vm.opsExchangeRate.id !== null) {
                OpsExchangeRate.update(vm.opsExchangeRate, onSaveSuccess, onSaveError);
            } else {
                OpsExchangeRate.save(vm.opsExchangeRate, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('autopsApp:opsExchangeRateUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError (result) {
            if (result.status == 409){
                layer.msg($translate.instant('autopsApp.opsExchangeRate.effectiveDateRepeat'));
            }
            else if (result.status == 403){
                layer.msg($translate.instant('autopsApp.opsExchangeRate.notSupportedCurrency'));
            }
            vm.isSaving = false;         
        }

        vm.datePickerOpenStatus.effectiveDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        function remarksDisabled () {
            return vm.opsExchangeRate && vm.opsExchangeRate.id 
                && vm.opsExchangeRate.remarks && vm.opsExchangeRate.remarks.indexOf('->') !== -1;
        }
    }
})();
