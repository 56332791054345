(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsDistributionController', WmsDistributionController);

    WmsDistributionController.$inject = ['$scope', '$state', '$rootScope', '$uibModal', '$translate', '$http', '$location', '$timeout', 'DataUtils', 'Guide',
    'WinAES', 'WmsIn', 'WmsInSearch', 'uiGridConstants', 'AlertService', 'Principal', 'OpsFiles', 'GridState', 'WmsWarehouse', 'DateUtils'];

    function WmsDistributionController ($scope, $state, $rootScope, $uibModal, $translate, $http, $location, $timeout, DataUtils, Guide,
        WinAES, WmsIn, WmsInSearch, uiGridConstants, AlertService, Principal, OpsFiles, GridState, WmsWarehouse, DateUtils) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                loadAll();
            });
        };
        var path = $location.path();
        var vm = this;
        vm.exportExcel = exportExcel;

        vm.predicate = 'id';
        vm.direction = 'desc';
        vm.page = 1;
        vm.itemsPerPage = 20;

        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = null;
        vm.currentSearch = null;

        $scope.isAdvanceSearch = false;

        vm.getWarehouses = getWarehouses;
        vm.warehouseSelected = warehouseSelected;
        var type = "inPaper";
        vm.warehouses = null;
        vm.choosedWarehouse = {};

        function warehouseSelected (warehouse) {
            vm.choosedWarehouse = warehouse;
            loadAll();
        }

        function getWarehouses () {
            if (vm.warehouses && vm.warehouses.length > 0){
                return;
            }
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false
                }, function (data) {
                vm.warehouses = data;
            });
        }

        var gridCopy;
        var pageLoaded = false;
        var filterTimer;
        function loadAll() {
            if(!$rootScope.account.id){
                return;
            }
            type = "TD";
            if (vm.searchQuery) {
                WmsInSearch.query({
                    type: type,
                    warehouseId: vm.choosedWarehouse.id,
                    query: vm.searchQuery,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                var filter = {};
                filter.filterType = type;
                filter.warehouseId = vm.choosedWarehouse.id;
                if(gridCopy){
                    angular.forEach(gridCopy.columns, function(column){
                        angular.forEach(column.filters, function(cf){
                            if(cf.term != null && angular.isDefined(cf.term)){
                                if(cf.condition){
                                    var fd = column.field;
                                    if(fd.endWith("Str")){
                                        fd = fd.replace("Str", "");
                                    }
                                    if(cf.condition == 128 || cf.condition == 256){ //" < ",
                                        if(column.field == "ata" || column.field == "etd"){
                                            filter[fd + "TO"] = DateUtils.convertLocalDateToServer(cf.term);
                                        }
                                        else {
                                            filter[fd + "TO"] = cf.term;
                                        }
                                    }
                                    else if(cf.condition == 32 || cf.condition == 64){ //" > ",
                                        if(column.field == "ata" || column.field == "etd"){
                                            filter[fd + "FM"] = DateUtils.convertLocalDateToServer(cf.term);
                                        }
                                        else{
                                            filter[fd + "FM"] = cf.term;
                                        }
                                    }
                                }
                                else {
                                    filter[column.field] = cf.term;
                                }
                            }
                        })
                    })
                }

                WmsIn.getFilterPage({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, filter, onSuccess, onError);
            }

            function sort() {
                var result = [(vm.predicate.endWith('TAG')?vm.predicate.replace('TAG', ''):vm.predicate) + ',' + vm.direction];
                return result;
            }

            function onSuccess(data, headers) {
                if(headers('X-Total-Count')){
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                }
                else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                $scope.isAdvanceSearch = false;
                initData();
            }

            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }
        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.page = 1;
            vm.predicate = '_score';
            vm.direction = 'desc';
            vm.currentSearch = searchQuery;
            loadAll();
        }
        function clear() {
            vm.page = 1;
            vm.predicate = 'id';
            vm.direction =  'desc';
            vm.searchQuery = vm.currentSearch = null;
            $scope.isAdvanceSearch = false;
            loadAll();
        }
        loadAll();

        window.onresize = function(){
            resize();
        }

        function resize() {
            var tabHeight = $scope.gridOptions.data.length * 30 + 190;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 300){
                tabHeight = 300;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        var gridInited = false;
        function initData() {
            $scope.gridOptions.useExternalSorting = !$scope.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.useExternalFiltering = !$scope.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.showColumnFooter = $scope.isAdvanceSearch;
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS);

            angular.forEach($scope.gridOptions.data, function(data){
                if(data.id){
                    data.codeId = WinAES.encode(data.id.toString());
                }
                if (data.guideId){
                    data.encodeId = WinAES.encode(data.guideId.toString());
                }
                if(data.gw){
                    data.gw = data.gw.toFixed(2) - 0;
                }
                if(data.vol){
                    data.vol = data.vol.toFixed(2) - 0;
                }
                if($rootScope.HAVENOAUTH('FUN_VIEW_CLIENT')){
                    data.clientName = "******";
                }
                if($rootScope.HAVENOAUTH('FUN_VIEW_SHIPPER')){
                    data.shipperName = "******";
                }
            })
            $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.OPTIONS);
            resize();
            if(gridInited){
                return;
            }
            gridInited = true;
            if(!$rootScope.account.companyId){
                for(var index = $scope.gridOptions.columnDefs.length - 1; index >= 0; index--){
                    var col = $scope.gridOptions.columnDefs[index];
                    if(col.field == "clientName" || col.field == "poVender"){
                        $scope.gridOptions.columnDefs.splice(index, 1);
                    }
                }
            }
            $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
        }

        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: true,

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: false,//每列footer

            enableFiltering: true,//高级过滤
            useExternalFiltering: true,

            enableHorizontalScrollbar : 1,//表格的水平滚动条
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            exporterMenuCsv : false,
            exporterMenuPdf : false,
            exporterMenuLabel : "Export",
            exporterExcelFilename: 'tdSheet.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],

            paginationPageSizes: [20, 35, 50, 100, 200],
            paginationPageSize: 20,
            useExternalPagination: true,

            minimumColumnSize: 70,
            columnDefs: [
                { field: 'id',
                    width: 150,
                    displayName: "",
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<div class="btn-group flex-btn-group-container"ng-click="grid.appScope.setChoosedId(row.entity.id)"><button type="button"ui-sref="wms-distribution.edit({id:row.entity.codeId})"class="btn btn-info btn-grid"><span class="glyphicon glyphicon-file"></span><span class="hidden-xs hidden-sm"translate="autopsApp.wmsIn.inPaper"></span></button><button type="button"ng-if="grid.appScope.account.companyId"ng-disabled="!row.entity.warehousingNumber||(!row.entity.id && !row.entity.guideId)"ng-click="grid.appScope.RATESPOP(row.entity, \'wms\', grid.appScope.gridOptions.data)"class="btn btn-danger btn-grid"><span class="glyphicon glyphicon-usd"></span><span translate="entity.action.chargesPop"></span></button><button ng-if="grid.appScope.isAdvanceSearch"ng-click="grid.appScope.deleteOne(row.entity)"class="btn btn-default btn-grid"><span class="glyphicon glyphicon-remove"></span></button></div>'},
                { field: 'warehousingNumber',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.wmsIn.warehousingNumber'),
                    cellTemplate: '<span class="grid-text" ng-click="grid.appScope.setChoosedId(row.entity.id)" ng-class="(row.entity.id==grid.appScope.choosedId && row.entity.id)?\'choosedColor\':\'\'"><a href=""ui-sref="wms-distribution.edit({id:row.entity.codeId})">{{row.entity.warehousingNumber}}</a></span>'},
                { field: 'cloudFiles',
                    displayName: $translate.instant('global.cloudFiles'),
                    width:80,
                    enableSorting: false,
                    cellTemplate: '<div id="btnsId"class="btn-group flex-btn-group-container ml-4"ng-if="row.entity.id"><div class="btn-group"uib-dropdown dropdown-append-to-body><button id="btn-append-to-body"type="button"class="btn btn-link btn-grid"uib-dropdown-toggle ng-click="grid.appScope.loadFiles(row.entity)"><span class="glyphicon glyphicon-cloud"></span><span translate="global.cloudFiles">cloudFiles</span><span class="caret"></span></button><ul class="dropdown-menu"uib-dropdown-menu role="menu"aria-labelledby="btn-append-to-body"><li><a href=""ngf-select ngf-change="grid.appScope.UPLOADFILE($file, \'/wmsIn/\' + row.entity.id + \'/\', row.entity.files, row.entity.companyId)"><span class="glyphicon glyphicon-cloud-upload"></span>&nbsp;<span translate="global.uploadFiles">uploadFiles</span></a></li><li role="separator"class="divider" ng-if="row.entity.files"></li><li ng-repeat="file in row.entity.files"><a href=""ng-click="grid.appScope.PDFPOP(file.fileurl,file.filename)"><span class="glyphicon glyphicon-cloud-download"></span>&nbsp;{{file.filename}}</a></li></ul></div></div>'},
                { field: 'clientName',
                    displayName: $translate.instant('autopsApp.wmsIn.clientName'),
                    minWidth:200},
                { field: 'jobNum',
                    displayName: $translate.instant('autopsApp.wmsIn.jobNum'),
                    minWidth:150},
                { field: 'audited',
                    displayName: $translate.instant('autopsApp.wmsIn.audit'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span class="grid-text"><span class="label label-danger"translate="global.yes_answer"ng-if="row.entity.audited"style="cursor: pointer;"ng-click="grid.appScope.setAudited(row.entity, false)"data-toggle="tooltip"data-placement="top"title="{{row.entity.auditor}}"></span><span class="label label-info"translate="global.no_answer"ng-hide="row.entity.audited"style="cursor: pointer;"ng-click="grid.appScope.setAudited(row.entity, true)"></span></span>'},
                { field: 'status',
                    displayName: $translate.instant('autopsApp.wmsIn.status'),
                    minWidth:100,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [
                        { value: 'new', label: $translate.instant('autopsApp.wmsIn.inStatus.new')},
                        { value: 'preIn', label: $translate.instant('autopsApp.wmsIn.inStatus.preIn')},
                        { value: 'Requested', label: $translate.instant('autopsApp.wmsIn.inStatus.Requested')},
                        { value: 'Confirmed', label: $translate.instant('autopsApp.wmsIn.inStatus.Confirmed')},
                        { value: 'allIn', label: $translate.instant('autopsApp.wmsIn.inStatus.allIn')},
                        { value: 'partIn', label: $translate.instant('autopsApp.wmsIn.inStatus.partIn')},
                        { value: 'Amended', label: $translate.instant('autopsApp.wmsIn.inStatus.Amended')},
                        { value: 'Declined', label: $translate.instant('autopsApp.wmsIn.inStatus.Declined')},
                        { value: 'Cancelled', label: $translate.instant('autopsApp.wmsIn.inStatus.Cancelled')}]
                    },
                    cellTemplate: '<span class="grid-text"><span class="label label-success"translate="autopsApp.wmsIn.inStatus.allIn"ng-if="row.entity.status == \'allIn\'"data-toggle="tooltip"data-placement="top"title="{{row.entity.inTime | date:\'medium\'}}">status</span><span class="label label-info"translate="autopsApp.wmsIn.inStatus.partIn"ng-if="row.entity.status == \'partIn\'"data-toggle="tooltip"data-placement="top"title="{{row.entity.actualNum}}">status</span><span class="label label-primary"translate="autopsApp.wmsIn.inStatus.{{row.entity.status}}"ng-if="row.entity.status && row.entity.status != \'allIn\' && row.entity.status != \'partIn\'">status</span></span>'},
                { field: 'expectedWarehousingTime',
                    displayName: $translate.instant('autopsApp.wmsIn.expectedWarehousingTime'),
                    minWidth:160,
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<span class="grid-text">{{row.entity.expectedWarehousingTime | date:\'medium\'}}</span>'},
                { field: 'etd',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.wmsIn.eta'),
                    minWidth:120,
                    cellTemplate: '<span class="grid-text">{{row.entity.etd | date:\'mediumDate\'}}</span>'},
                { field: 'ata',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.wmsIn.ata'),
                    minWidth:120,
                    cellTemplate: '<span class="grid-text">{{row.entity.ata | date:\'mediumDate\'}}</span>'},
                { field: 'customClearTime',
                    displayName: $translate.instant('autopsApp.wmsIn.customClearTime'),
                    minWidth:160,
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<span class="grid-text">{{row.entity.customClearTime | date:\'medium\'}}</span>'},
                { field: 'pickupTime',
                    displayName: $translate.instant('autopsApp.wmsIn.pickupTime'),
                    minWidth:160,
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<span class="grid-text">{{row.entity.pickupTime | date:\'medium\'}}</span>'},
                { field: 'inTime',
                    displayName: $translate.instant('autopsApp.wmsIn.actualInTime'),
                    minWidth:160,
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<span class="grid-text">{{row.entity.inTime | date:\'medium\'}}</span>'},
                { field: 'applicationNum',
                    displayName: $translate.instant('autopsApp.wmsIn.inNum'),
                    minWidth:100,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    cellTemplate: '<span class="grid-text"><strong ng-if="row.entity.actualNum">{{row.entity.actualNum}}</strong><strong ng-if="!row.entity.actualNum">0</strong>/<strong ng-if="row.entity.applicationNum">{{row.entity.applicationNum}}</strong><strong ng-if="!row.entity.applicationNum">0</strong></span>'},
                { field: 'gw',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsIn.gwTtl'),
                    minWidth:100},
                { field: 'vol',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsIn.volTtl'),
                    minWidth:100},
                { field: 'pallets',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsIn.palletAmt'),
                    minWidth:100},
                { field: 'warehouseName',
                    displayName: $translate.instant('autopsApp.wmsIn.warehouseName'),
                    minWidth:150},
                { field: 'opName',
                    displayName: $translate.instant('autopsApp.wmsIn.opName'),
                    minWidth:100},
                { field: 'type',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [
                        { value: 'FCL', label: $translate.instant('global.FCL')},
                        { value: 'AIR', label: $translate.instant('global.AIR')},
                        { value: 'LCL', label: $translate.instant('global.LCL')},
                        { value: 'WMS', label: $translate.instant('global.WMS')},
                        { value: 'LOCAL', label: $translate.instant('global.LOCAL')},
                        { value: 'LOCALKG', label: $translate.instant('global.LOCALKG')},
                        { value: 'EXPRESS', label: $translate.instant('global.EXPRESS')},
                        { value: 'FBASEA', label: $translate.instant('global.FBASEA')},
                        { value: 'FBAAIR', label: $translate.instant('global.FBAAIR')},
                        { value: 'BULK', label: $translate.instant('global.BULK')},
                        { value: 'RAIL', label: $translate.instant('global.RAIL')}]
                    },
                    displayName: $translate.instant('autopsApp.wmsIn.type'),
                    minWidth:120,
                    cellTemplate: '<span class="grid-text" translate="global.{{row.entity.type}}" ng-if="row.entity.type"></span>'},
                { field: 'cargoDescription',
                    displayName: $translate.instant('autopsApp.wmsIn.cargoDescription'),
                    minWidth:100},
                { field: 'poNum',
                    displayName: $translate.instant('autopsApp.wmsIn.poNum'),
                    minWidth:120},
                { field: 'ctnNum',
                    displayName: $translate.instant('autopsApp.wmsIn.ctnNum'),
                    minWidth:100},
                { field: 'ctnType',
                    displayName: $translate.instant('autopsApp.wmsIn.ctnType'),
                    minWidth:100},
                { field: 'returnChannel',
                    displayName: $translate.instant('autopsApp.wmsIn.carrier'),
                    minWidth:100},
                { field: 'poContract',
                    displayName: $translate.instant('autopsApp.wmsIn.blNum'),
                    minWidth:100},
                { field: 'poVender',
                    displayName: $translate.instant('autopsApp.wmsIn.truckCompany'),
                    minWidth:100},
                { field: 'truckNum',
                    displayName: $translate.instant('autopsApp.wmsIn.truckNum'),
                    minWidth:100},
                { field: 'customNum',
                    displayName: $translate.instant('autopsApp.wmsIn.customNum'),
                    minWidth:100},
                { field: 'podName',
                    displayName: $translate.instant('autopsApp.wmsIn.podName'),
                    minWidth:100},
                { field: 'freeDays',
                    displayName: $translate.instant('autopsApp.wmsIn.freeDays'),
                    minWidth:100},
                { field: 'createdTime',
                    displayName: $translate.instant('autopsApp.wmsIn.createdTime'),
                    minWidth:160,
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<span class="grid-text">{{row.entity.createdTime | date:\'medium\'}}</span>'},
                { field: 'updateTime',
                    displayName: $translate.instant('autopsApp.wmsIn.updateTime'),
                    minWidth:160,
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<span class="grid-text">{{row.entity.updateTime | date:\'medium\'}}</span>'},
                { field: 'remarks',
                    minWidth:300,
                    displayName: $translate.instant('autopsApp.wmsIn.remarks')},
                { field: 'creater',
                    minWidth:120,
                    displayName: $translate.instant('autopsApp.wmsIn.creater')}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 114;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                    if(!$scope.gridOptions.useExternalSorting){
                        if(sortColumns[sortColumns.length - 1]){
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function(a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    if(sortColumns[sortColumns.length - 1]){
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    }
                    loadAll();
                });
                gridApi.core.on.filterChanged($scope, function() {
                    gridCopy = this.grid;
                    if(pageLoaded && $scope.gridOptions.useExternalFiltering){
                        clearTimeout(filterTimer);
                        filterTimer = setTimeout(function(){
                            loadAll();
                        }, 1000);//查询延时时间，单位ms
                    }
                });
                gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                    vm.page = newPage;
                    vm.itemsPerPage = pageSize;
                    if(pageLoaded){
                        loadAll();
                    }
                });
            }
        }
        $scope.choosedId = null;
        $scope.setChoosedId = function(id){
            $scope.choosedId = id;
        }
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 114
            }, function(result){
                gridStateObj = result;
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    pageLoaded = true;
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                pageLoaded = true;
                if(gridCopy){
                    var filterRequired = false;
                    angular.forEach(gridCopy.columns, function(column){
                        angular.forEach(column.filters, function(cf){
                            if(cf.term || cf.term == false){
                                filterRequired = true;
                            }
                        })
                    })
                    if(filterRequired){
                        layer.msg($translate.instant('global.filterDetectedAlert'));
                        loadAll();
                    }
                }
            })
        });

        $scope.registerIn = function(wmsIn) {
            if ($rootScope.account.wmsMustAudit && !wmsIn.audited) {
                $rootScope.OPS_ALERT($translate.instant('global.notAuditedAlert'));
                return;
            }
            $state.go('wms-ins-register.dialog', {id: wmsIn.codeId});
        }

        $scope.registerout = function(wmsIn) {
            if ($rootScope.account.wmsMustAudit && !wmsIn.audited) {
                $rootScope.OPS_ALERT($translate.instant('global.notAuditedAlert'));
                return;
            }
            $state.go('wms-outs-register.dialog', {id: wmsIn.codeId});
        }

        $scope.deleteOne = function(row) {
            var index = $scope.gridOptions.data.indexOf(row);
            $scope.gridOptions.data.splice(index, 1);
        }

        $scope.isSaving = false;
        $scope.setAudited = function(wmsIn, isAudited) {
            if(!wmsIn.id){
                return;
            }
            if (!$rootScope.account.authorities || $rootScope.account.authorities.indexOf("AU_WMS_AUDIT") == -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_WMS_AUDIT');
                return;
            }
            if (wmsIn.status == 'allIn' && wmsIn.auditor){
                layer.msg($translate.instant('autopsApp.wmsIn.confirmStored'));
                return;
            }
            WmsIn.setAudit({
                id: wmsIn.id,
                userName: $rootScope.account.firstName + " " + $rootScope.account.lastName,
                audited: isAudited
            }, function(result) {
                $scope.isSaving = false;
                wmsIn.auditor = result.auditor;
                wmsIn.audited = result.audited;
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(result) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submittedfailed'));
            });
        };

        $scope.auditAll = function(isAudited) {
            if (!$rootScope.account.authorities || $rootScope.account.authorities.indexOf("AU_WMS_AUDIT") == -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_WMS_AUDIT');
                return;
            }
            var wmsInList = $scope.gridApi.selection.getSelectedRows();
            if(!wmsInList || wmsInList.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            angular.forEach(wmsInList, function(data){
                if (data.status != 'allIn' && data.status != 'partIn'){
                    if(isAudited){
                        data.auditor = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                        data.audited = true;
                    }
                    else {
                        data.auditor = null;
                        data.audited = false;
                    }
                }
            })
            $scope.isSaving = true;
            WmsIn.updateList(wmsInList, function(){
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(){
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submittedfailed'));
            });
        }

        $scope.audit = function(wmsIn) {
            if (!$rootScope.account.authorities || $rootScope.account.authorities.indexOf("AU_WMS_AUDIT") == -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_WMS_AUDIT');
                return;
            }
            var wmsInList = $scope.gridApi.selection.getSelectedRows();
            if(!wmsInList || wmsInList.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            var venders = [];
            if(wmsIn){
                venders.push({
                    vender: wmsIn.clientName,
                    venderId: wmsIn.venderId
                })
            }
            else {
                var vender = {};
                angular.forEach(wmsInList, function(data){
                    if(!vender[data.venderId] && data.clientName){
                        venders.push({vender: data.clientName, venderId: data.venderId});
                        vender[data.venderId] = true;
                    }
                })
            }
            var entity = {};
            entity.venders = venders;
            $rootScope.VENDER_AUDIT_POP(entity);
        }

        function exportExcel (showSku) {
            var wmsInList = $scope.gridApi.selection.getSelectedRows();
            if(!wmsInList || wmsInList.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            var fileName = "wmsDistributions.xlsx";
            OpsFiles.excelWmsIn({
                timezoneOffset: new Date().getTimezoneOffset()/60,
                showSku: showSku,
                enCn: $translate.instant('global.enCn')
            }, wmsInList, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }

        $scope.importTdByExcel = function  (file) {
            if (!file) {return;}
            var fd = new FormData();
            fd.append('file', file);
            $http.post(
                'api/wms-ins/importTdByExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    if(data.clientNotExist){
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.clientNotExist', {param: data.clientNotExist, successCount: data.successCount}));
                    }
                    else if(data.poExist){
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.poExist', {param: data.poExist, successCount: data.successCount}));
                    }
                    else if(data.failedStr){
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.importFailed', {param: data.failedStr}));
                    }
                    else {
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.importSuccess', {successCount: data.successCount}));
                    }
                    loadAll();
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                });
        };

        $scope.preIn = function() {
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-pre-in.html',
                controller: 'WmsPreInController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return false;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('guide');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {
                loadAll();
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }
        $scope.rapidIn = function() {
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-shelve.html',
                controller: 'WmsShelveController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        var unsubscribe = $rootScope.$on('autopsApp:wmsInUpdate', function(event, result) {
            var isNew = true;
            angular.forEach($scope.gridOptions.data, function(data){
                if(data.id && data.id == result.id){
                    angular.copy(result, data);
                    data.codeId = WinAES.encode(data.id.toString());
                    isNew = false;
                }
            })
            if(isNew){
                loadAll();
            }
        });
        $scope.$on('$destroy', unsubscribe);


        $scope.searchRequestPop = function() {
            var searchParams = {
                companyId: $rootScope.account.companyId,
                filterType: "TD"
            };
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-search.html',
                controller: 'WmsInSearchController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(results) {
                $scope.gridOptions.data = results;
                $scope.isAdvanceSearch = vm.currentSearch = true;
                initData();
                $scope.gridOptions.totalItems = results.length;
            }, function() {
            });
        }
        $scope.loadFiles = function(wmsIn) {
            if(wmsIn.files && wmsIn.files.length > 0){
                return;
            }
            wmsIn.files = [];
            $rootScope.LOADFILES(wmsIn.files, '/wmsIn/' + wmsIn.id + '/', wmsIn.companyId)
        }

    }
})();
