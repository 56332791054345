(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BatchRatesSubmitController', BatchRatesSubmitController);

    BatchRatesSubmitController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$translate', '$timeout', 
    'entity', 'GuideRates', 'DataUtils', 'DateUtils'];
    
    function BatchRatesSubmitController($scope, $rootScope, $uibModalInstance, $translate, $timeout, 
        entity, GuideRates, DataUtils, DateUtils) {

        $scope.guide = entity;
        
        $scope.isSaving = false;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };      
        var autoCheckName = "";
        $timeout(function (){
            if(entity.isGuide || entity.isFin){
                filterRates();    
            }
            else if(entity.isAutoCheck){
                GuideRates.getUnlockedRatesByAutoCheck($scope.guide, function(result) {
                    $scope.guide.guideRates = result;
                    filterRates();
                });
                autoCheckName = entity.code;
                if(entity.billNum){
                    autoCheckName = autoCheckName + "/ " + entity.billNum;
                }
            }
            else if(entity.isOpsBill){
                GuideRates.getUnlockedRatesByOpsBill($scope.guide, function(result) {
                    $scope.guide.guideRates = result;
                    filterRates();
                });
            }
            else if(entity.guideRateDtos){
                var guideRateIds = [];
                angular.forEach(entity.guideRateDtos, function(data){
                    if(!data.dnNum && data.id){
                        guideRateIds.push(data.id);
                    }                
                });                
                GuideRates.getGuideRatesByIds({}, guideRateIds, function(result) {
                    $scope.guide.guideRates = result;
                    filterRates();
                });
            }     
        });

        $scope.payerList = [];
        $scope.curList = [];
        
        var getTtlList = function() {
            $scope.payerList = [];
            $scope.curList = [];
            var currencys = [];
            var payerIds = [];
            angular.forEach($scope.guide.guideRates, function(data) {
                if(entity.isGuide){
                    data.jobNum = entity.jobNum;
                    data.polName = entity.polName;
                    data.podName = entity.podName;
                }
                data.choosed = true;
                if (data.payCurrency && currencys.indexOf(data.payCurrency) === -1) {
                    currencys.push(data.payCurrency);

                    var curMap = {};
                    curMap.choosed = true;
                    curMap.currency = data.payCurrency;
                    $scope.curList.push(curMap);                    
                }
                if (data.venderId && payerIds.indexOf(data.venderId) === -1) {
                    payerIds.push(data.venderId);

                    var payerMap = {};
                    payerMap.choosed = true;
                    payerMap.venderId = data.venderId;
                    payerMap.payer = data.payer;
                    $scope.payerList.push(payerMap);    
                }
            })
            uddateCurTtl();
        }

        var filterRates = function() {
            for(var i = $scope.guide.guideRates.length -1; i >= 0; i--){
                if($scope.guide.guideRates[i].isHidden 
                    || !$scope.guide.guideRates[i].venderId 
                    || $scope.guide.guideRates[i].payAmount == null
                    || !$scope.guide.guideRates[i].payCurrency 
                    || !$scope.guide.guideRates[i].payer 
                    || $scope.guide.guideRates[i].dnNum){
                    $scope.guide.guideRates.splice(i, 1);
                    continue;
                }
                // if ($rootScope.account.authorities.indexOf("FUN_VIEWCOST") === -1) {
                //     if($scope.guide.guideRates[i].isPay){
                //         $scope.guide.guideRates.splice(i, 1);
                //         continue;
                //     }
                // }
                // if ($rootScope.account.authorities.indexOf("FUN_VIEWSELLING") === -1) {
                //     if(!$scope.guide.guideRates[i].isPay){
                //         $scope.guide.guideRates.splice(i, 1);
                //         continue;
                //     }
                // }
                // if ($rootScope.account.authorities.indexOf("FUN_VIEW_ONE_HIDDEN") === -1) {
                //     if($scope.guide.guideRates[i].hiddenRate){
                //         $scope.guide.guideRates.splice(i, 1);
                //         continue;
                //     }
                // }
                if ((entity.isPay == true || entity.isPay == false) && entity.isPay != $scope.guide.guideRates[i].isPay) {
                    $scope.guide.guideRates.splice(i, 1);
                    continue;
                }
            }
            if($scope.guide.guideRates.length == 0){
                $scope.clear();
                layer.msg($translate.instant('autopsApp.autoCheck.noUnlockedCharges'));
                return;
            }
            getTtlList();
            if(entity.isFin){
                return;
            }
            $scope.guide.guideRates.sort(function(a,b){
                return a.isPay > b.isPay?1:-1;                 
            });

        };      

        $scope.curAmtList = [];
        $scope.updateList = function() {
            angular.forEach($scope.guide.guideRates, function(rate) {
                angular.forEach($scope.payerList, function(payer) {
                    angular.forEach($scope.curList, function(cur) {
                        if(payer.venderId == rate.venderId && cur.currency == rate.payCurrency){
                            rate.choosed = (payer.choosed && cur.choosed);
                        }
                    })
                })
            })
            uddateCurTtl();
        }
        
        function uddateCurTtl () {
            $scope.curAmtList = [];
            angular.forEach($scope.curList, function(curMap) {
                var ttlAmt = 0;
                angular.forEach($scope.guide.guideRates, function(rate) {
                    if(rate.payCurrency == curMap.currency && rate.choosed){
                        if(rate.isPay){
                            ttlAmt -= rate.payAmount;
                        }
                        else {
                            ttlAmt += rate.payAmount;
                        }
                    }
                });
                var curAmtMap = {};
                curAmtMap.currency = curMap.currency;
                curAmtMap.total = DataUtils.round(ttlAmt, 2);;
                $scope.curAmtList.push(curAmtMap);
            })
        }

        $scope.delOne = function(rateId) {
            for(var i = $scope.guide.guideRates.length - 1; i>=0; i--){
                if($scope.guide.guideRates[i].id == rateId){
                    $scope.guide.guideRates.splice(i, 1);
                }
            }            
        };      
        $scope.deadlineDate = null;
        $scope.accountingDate = null;
        $scope.autoReminder = false;
        $scope.isBalance = false;
        $scope.advancedFunds = false;
        $scope.perShipment = false;

        $scope.ratesSubmit = function() {
            var ratesCopy = angular.copy($scope.guide.guideRates);
            for(var i = ratesCopy.length -1; i >= 0; i--){
                if(!ratesCopy[i].choosed){
                    ratesCopy.splice(i, 1);
                }
            }
            if(!ratesCopy || ratesCopy.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            layer.load(1, {shade: 0.3});
            GuideRates.ratesSubmit({
                isDiff: $scope.isBalance,
                accountingDate: DateUtils.convertLocalDateToServer($scope.accountingDate),
                deadlineDate: DateUtils.convertLocalDateToServer($scope.deadlineDate),
                autoCheckName: autoCheckName,
                autoReminder: $scope.autoReminder,
                advancedFunds: $scope.advancedFunds,
                perShipment: $scope.perShipment
            }, ratesCopy, function(data){
                layer.closeAll(); 
                layer.msg($translate.instant('global.messages.submitted'));
                $uibModalInstance.close();
            }, $rootScope.ALERT_ERROR);
        };      
        $scope.unchooseAllPayer = function() {
            angular.forEach($scope.payerList, function(data){
                data.choosed = false;
            })
            $scope.updateList();
        }
        $scope.unchooseAllCur = function() {
            angular.forEach($scope.curList, function(data){
                data.choosed = false;
            })
            $scope.updateList();
        }



    }
})();
