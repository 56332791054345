(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsConcernLaneDialogController', OpsConcernLaneDialogController);

    OpsConcernLaneDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Guide'];

    function OpsConcernLaneDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Guide) {
        var vm = this;

        vm.request = entity;
        vm.clear = clear;
        vm.save = save;
        vm.oldMblNums = vm.request.blNums.split(" ");

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function save () {
            vm.isSaving = true;
            Guide.amendMblNum({
                companyId: vm.request.companyId,
                dnNum: vm.request.dnNum,
                oldMblNum: vm.request.oldMblNum,
                newMblNum: vm.request.newMblNum
            }, onSaveSuccess, onSaveError);

        }

        function onSaveSuccess (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
