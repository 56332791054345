(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ExpressTrackingDeleteController',ExpressTrackingDeleteController);

    ExpressTrackingDeleteController.$inject = ['$uibModalInstance', '$translatePartialLoader', '$translate', 'entity'];

    function ExpressTrackingDeleteController($uibModalInstance, $translatePartialLoader, $translate, entity) {
        var vm = this;

        vm.result = entity;
        vm.clear = clear;
        $translatePartialLoader.addPart('expressTracking');
        $translate.refresh();

        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }

})();
