
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OrderNodeDialogController', OrderNodeDialogController);

    OrderNodeDialogController.$inject = ['$scope', '$rootScope', '$translate', '$uibModalInstance', '$timeout',
    'entity', 'OrderNode'];

    function OrderNodeDialogController($scope, $rootScope, $translate, $uibModalInstance, $timeout,
        entity, OrderNode) {
        
        $scope.orderNode = entity;
        

        $scope.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }


        $timeout(function() {
            if($scope.orderNode.nodeTime){
                $scope.orderNode.nodeTime = null;
                $scope.save();

            }
            else{
                $scope.orderNode.nodeTime = new Date();
            }
        });


        $scope.isSaving = false;
        $scope.save = function() {
            layer.msg("Node(s) Updating...", {
              shade: 0.3,
              time: 200000
            });
            OrderNode.setOrderNode({}, $scope.orderNode, function(result) {
                layer.closeAll();
                $uibModalInstance.close(result);
            }, $rootScope.ALERT_ERROR);
        }


    }
})();
