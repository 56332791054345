(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('AsyncTask', AsyncTask);

    AsyncTask.$inject = ['$resource', 'DateUtils'];

    function AsyncTask ($resource, DateUtils) {
        var resourceUrl =  'api/async-tasks/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createTime = DateUtils.convertDateTimeFromServer(data.createTime);
                        data.completeTime = DateUtils.convertDateTimeFromServer(data.completeTime);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'submitAsyncTask': {
                method: 'POST',
                url: 'api/async-tasks/submitAsyncTask',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    if(data.statParams){
                        data.statParams.dateFrom = DateUtils.convertLocalDateToServer(data.statParams.dateFrom);
                        data.statParams.dateTo = DateUtils.convertLocalDateToServer(data.statParams.dateTo);
                        data.statParams.etdFM = DateUtils.convertLocalDateToServer(data.statParams.etdFM);
                        data.statParams.etdTO = DateUtils.convertLocalDateToServer(data.statParams.etdTO);
                    }
                    return angular.toJson(data);
                }
            }
        });
    }
})();
