(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('InvoicePurchaseDialogController', InvoicePurchaseDialogController);

    InvoicePurchaseDialogController.$inject = ['$uibModalInstance', 'entity'];

    function InvoicePurchaseDialogController ($uibModalInstance, entity) {
        var vm = this;
        vm.clear = clear;
        vm.data = entity;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
