(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('ReportTemplate', ReportTemplate);

    ReportTemplate.$inject = ['$resource','DateUtils'];
    
    function ReportTemplate($resource, DateUtils) {
        return $resource('api/reportTemplates/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getAll': {
                method:'GET',
                url:'api/reportTemplates/getAll',
                isArray: true
            },
            'getBob':{
                method:'GET',
                url:'api/reportTemplates/getBob',
                responseType: 'arraybuffer',
                isArray:false,
                transformResponse: function (data) {
                    var result = new Object();                    
                    result['data'] = data;
                    return result;
                }
            },
            'delTemplate': {
                method:'GET',
                url:'api/reportTemplates/delTemplate'
            },
            'update': { method:'PUT' }
        });
    }
})();
