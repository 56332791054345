(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('VenderAuditDialogController', VenderAuditDialogController);

    VenderAuditDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$uibModalInstance', '$translate',  
    'entity', 'GuideRates', 'Guide', 'OpsVender', 'OpsExchangeRate', 'DateUtils', 'DataUtils', 'uiGridConstants', 'uiGridGroupingConstants',
    'OpsFiles', 'GridState'];

    function VenderAuditDialogController ($timeout, $scope, $rootScope, $uibModalInstance, $translate,  
        entity, GuideRates, Guide, OpsVender, OpsExchangeRate, DateUtils, DataUtils, uiGridConstants, uiGridGroupingConstants,
        OpsFiles, GridState) {
        var vm = this;
        vm.clear = clear;
        vm.getUnpaid = getUnpaid;
        vm.getSubShipments = getSubShipments;
        vm.viewCient = viewCient;
        vm.unpaidTypeChange = unpaidTypeChange;
        vm.changeVender = changeVender;
        vm.venderId = null;
        vm.shipperName = null;
        $scope.venders = entity.venders;
        var exchangeRates = entity.exchangeRates;
        vm.getSalesGuarantee = getSalesGuarantee;
        vm.getSonShipments = getSonShipments;
        vm.setValue = setValue;


        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            if($scope.venders && $scope.venders.length){
                vm.venderId = $scope.venders[0].venderId;
                vm.shipperName = $scope.venders[0].vender;
                viewCient();
            }
            if(entity.guide){
                auditGuideInit();
            }
        });

        function auditGuideInit () {
            if ($rootScope.HAVENOAUTH('FUN_VIEW_PAYER')) {
                $rootScope.FORBIDDEN_BY_ALERT('FUN_VIEW_PAYER');   
                clear();
                return;
            }
            if ($rootScope.HAVENOAUTH('FUN_VIEW_PAYEE')) {
                $rootScope.FORBIDDEN_BY_ALERT('FUN_VIEW_PAYEE');   
                clear();
                return;
            }
            if ($rootScope.HAVENOAUTH('FUN_VIEWCOST') && vm.guide.hidenCost) {
                $rootScope.FORBIDDEN_BY_ALERT('FUN_VIEWCOST');   
                clear();
                return;
            }
            if ($rootScope.HAVENOAUTH('FUN_VIEWSELLING') && vm.guide.hidenSelling) {
                $rootScope.FORBIDDEN_BY_ALERT('FUN_VIEWSELLING'); 
                clear();  
                return;
            }
            if ($rootScope.HAVENOAUTH('FUN_VIEW_ONE_HIDDEN')) {
                $rootScope.FORBIDDEN_BY_ALERT('FUN_VIEW_ONE_HIDDEN');   
                clear();
                return;
            }
            if ($rootScope.HAVENOAUTH('AU_VIEW_REV')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_VIEW_REV');   
                clear();
                return;
            }
            if ($rootScope.HAVENOAUTH('AU_VIEW_EXP')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_VIEW_EXP');   
                clear();
                return;
            }
            $scope.files = new Array();
            if(entity.guide.id){
                Guide.getGuideReview({
                    id: entity.guide.id
                }, function(result) {
                    vm.guide = result;
                    vm.guide.isFin = entity.isFin;
                    $scope.guideRates = result.guideRates;
                    getPayers();
                });
            }
            else{
                Guide.getGuideAndRatesByJobNum({
                    companyId: entity.guide.companyId,
                    jobNum: entity.guide.jobNum
                }, function(result) {
                    vm.guide = result;
                    vm.guide.isFin = entity.isFin;
                    $scope.guideRates = result.guideRates;
                    getPayers();
                });
            }
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 152
            }, function(result){
                gridStateObj = result;
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }   
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
            })
        }
        var getExchangeRatesMap = function() {
            if(exchangeRates){
                updateTotal();
                return;
            }
            OpsExchangeRate.getExchangeRatesMap({
                date: DateUtils.convertLocalDateToServer(vm.guide.etd)
            },function(result) {
                exchangeRates = result;
                updateTotal();
            })
        }
        $scope.profit = {};
        $scope.costTotalList = [];
        $scope.sellingTotalList = [];
        $scope.profitList = [];
        var updateTotal = function() {
            $scope.costTotalList = [];
            $scope.sellingTotalList = [];
            $scope.profitList = [];
            var currencys = [];
            angular.forEach($scope.guideRates, function(data) {
                if (data.payCurrency && currencys.indexOf(data.payCurrency) === -1) {
                    currencys.push(data.payCurrency);
                }
            })
            var cost = 0;
            var selling = 0;
            var profit = 0;
            var profitTtl = 0;
            angular.forEach(currencys, function(currency) {
                cost = 0;
                selling = 0;
                profit = 0;
                angular.forEach($scope.guideRates, function(rate) {
                    if (rate.payCurrency == currency && rate.payAmount) {
                        if (rate.isPay) {
                            cost = cost + rate.payAmount;
                            if(rate.exchangeRate){
                                profitTtl = profitTtl - rate.payAmount * rate.exchangeRate;
                            }
                            else {
                                profitTtl = profitTtl - rate.payAmount * exchangeRates[currency];
                            }
                        } else {
                            selling = selling + rate.payAmount;
                            if(rate.exchangeRate){
                                profitTtl = profitTtl + rate.payAmount * rate.exchangeRate;
                            }
                            else {
                                profitTtl = profitTtl + rate.payAmount * exchangeRates[currency];
                            }
                        }
                        profitTtl = DataUtils.round(profitTtl, 2);
                    }
                })
                $scope.costTotalList.push({
                    currency: currency,
                    total: DataUtils.round(cost, 2)
                });
                $scope.sellingTotalList.push({
                    currency: currency,
                    total: DataUtils.round(selling, 2)
                });
                profit = selling - cost;
                $scope.profitList.push({
                    currency: currency,
                    total: DataUtils.round(profit, 2)
                });
            })
            $scope.profit.ammount = DataUtils.round(profitTtl, 2);
            if (!$scope.profit.currency) {
                $scope.profit.currency = $rootScope.account.baseCurrency;
            }
        }
        function getPayers(){
            $scope.venders = [];
            getExchangeRatesMap();
            var payerVids = new Set();
            if(vm.guide.shipperId){
                vm.venderId = vm.guide.shipperId;
                payerVids.add(vm.guide.shipperId);
                var payer = {};
                payer.venderId = vm.guide.shipperId;
                payer.vender = vm.guide.shipperName;
                $scope.venders.push(payer);
            }
            if(vm.guide.customerId && !payerVids.has(vm.guide.customerId)){
                payerVids.add(vm.guide.customerId);
                var payer = {};
                payer.venderId = vm.guide.customerId;
                payer.vender = vm.guide.customerName;
                $scope.venders.push(payer);
            }  
            if(vm.guide.venderId && !payerVids.has(vm.guide.venderId)){
                payerVids.add(vm.guide.venderId);
                var payer = {};
                payer.venderId = vm.guide.venderId;
                payer.vender = vm.guide.clientName;
                $scope.venders.push(payer);
            }           
            angular.forEach($scope.guideRates, function(rate){
                if(rate.venderId && !payerVids.has(rate.venderId) && !rate.isPay){
                    payerVids.add(rate.venderId);
                    var payer = {};
                    payer.venderId = rate.venderId;
                    payer.vender = rate.payer;
                    $scope.venders.push(payer);
                }                    
            })
            $translate.refresh();
            if($scope.venders.length){
                vm.venderId = $scope.venders[0].venderId;
                vm.shipperName = $scope.venders[0].vender;
            }
            viewCient();
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.viewUnpaidType = "byDn";
        function unpaidTypeChange (unpaidType) {
            if(vm.result && vm.result.ratesList){
                if(vm.viewUnpaidType == unpaidType){
                    return;
                }
                if((vm.viewUnpaidType == "byCharge" && unpaidType == "byOrder") || (vm.viewUnpaidType == "byOrder" && unpaidType == "byCharge")){
                    vm.viewUnpaidType = unpaidType;
                    byOrderUpdate();
                    return;
                }
            }
            vm.viewUnpaidType = unpaidType;
            vm.result = null;
            getUnpaid();
        }
        var ratesListCopy;
        function getUnpaid () {
            if(vm.result || !vm.venderId){
                return;
            }
            layer.load(1, { shade: 0.3 });
            GuideRates.getUnpaidList({
                enCn: $translate.instant('global.enCn'),
                venderId: vm.venderId,
                viewUnpaidType: vm.viewUnpaidType
            }, function(result){
                layer.closeAll();
                vm.result = result;
                if(!vm.result.ratesList.length || vm.result.ratesList.length == 0){
                    layer.msg($translate.instant('global.messages.resultNull'));
                }
                vm.result.ratesList.sort(function(a, b) {
                    if(a.dnNum){
                        return -1;
                    }
                    if(b.dnNum){
                        return 1;
                    }
                   return 0;
                });
                ratesListCopy = angular.copy(vm.result.ratesList);
                if(vm.viewUnpaidType == "byOrder"){
                    byOrderUpdate();
                } 
            })
        }
        
        function byOrderUpdate (vender) {            
            vm.result.ratesList = [];
            if(vm.viewUnpaidType == "byCharge"){
                angular.forEach(ratesListCopy, function(data){
                    vm.result.ratesList.push(data);
                })
                return;
            }
            var jobNumSet = new Set();
            angular.forEach(ratesListCopy, function(data){
                jobNumSet.add(data.jobNum);
            })
            
            jobNumSet.forEach(function(ref){
                var currencies = new Set();
                angular.forEach(ratesListCopy, function(data){
                    currencies.add(data.currency);
                })  
                currencies.forEach(function(cur){
                    var curTtl = 0;
                    var job = null;
                    angular.forEach(ratesListCopy, function(data){
                        if(ref == data.jobNum && cur == data.currency){
                            if(data.totalB){
                                curTtl += data.totalB;
                                if(!job){
                                    job = angular.copy(data);
                                }
                            }
                        }
                    })
                    if(curTtl){
                        job.totalB = curTtl;
                        vm.result.ratesList.push(job);
                    }
                })    
            })
            $translate.refresh();
        }
        function changeVender (vender, type) {
            if(vender && vender.venderId){
                if(vm.venderId == vender.venderId){
                    return;
                }
                vm.venderId = vender.venderId;
                vm.shipperName = vender.vender;
                vm.guides = null;
                vm.result = null;
                $scope.opsClient = null;
                if(type == 'getSubShipments'){
                    getSubShipments();
                }
                else if(type == 'getUnpaid'){
                    getUnpaid();
                }   
                else if(type == 'viewCient'){
                    viewCient();
                }              
            }
        }

        function getSubShipments () {
            if(vm.guides || !vm.venderId){
                return;
            }
            layer.load(1, { shade: 0.3 });
            Guide.onGoingShipments({
                companyId: $rootScope.account.companyId,
                shipperId: vm.venderId
            }, function(result){
                layer.closeAll();
                vm.guides = result;
                if(!vm.guides.length || vm.guides.length == 0){
                    layer.msg($translate.instant('global.messages.resultNull'));
                }
                if(vm.guides.length == 50){
                    layer.msg($translate.instant('global.maxAmtAlert', {param: vm.guides.length}));
                }
            })
        }

        function viewCient (vender) {
            if(!vm.venderId || $scope.opsClient){
                return;
            }            
            OpsVender.get({
                id: vm.venderId
            }, function(result) {
                $scope.opsClient = result;
            }, function(result) {
                
            });
        }
        var onSaveError = function(result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('global.messages.submittedfailed'));
        };
        $scope.mblRelease = function() {
            var guideDTO = {};
            guideDTO.id = vm.guide.id;
            guideDTO.mblOnly = true;
            if (vm.guide.mblReleasedTime) {
                guideDTO.mblReleasedTime = null;
                guideDTO.status = "MblHold";
            } else {
                guideDTO.mblReleasedTime = new Date();                
                guideDTO.status = "MblReleased";
            }
            $scope.isSaving = true;
            Guide.setRelease(guideDTO, function(result) {
                vm.guide.status = result.status;
                vm.guide.mblReleasedTime = result.mblReleasedTime;
                vm.guide.lastModifiedTime = result.lastModifiedTime;
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
            }, onSaveError);
        }
        $scope.blRelease = function() {
            var guideDTO = {};
            guideDTO.id = vm.guide.id;
            guideDTO.mblOnly = false;
            if (vm.guide.hblReleasedTime) {
                guideDTO.hblReleasedTime = null;
                guideDTO.status = "HblHold";
            } else {
                guideDTO.hblReleasedTime = new Date();                
                guideDTO.status = "HblReleased";
            }
            $scope.isSaving = true;
            Guide.setRelease(guideDTO, function(result) {
                vm.guide.status = result.status;
                vm.guide.hblReleasedTime = result.hblReleasedTime;
                vm.guide.lastModifiedTime = result.lastModifiedTime;
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
            }, onSaveError);
        }
        
        var statsPageSize = 5000;
        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: false,   

            enableGridMenu : true,//表格 菜单  
            showGridFooter: true,//表格的footer  
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤            

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            enableRowHeaderSelection : true,  //行选中
            enableRowSelection: true, //行选中
            enableSelectAll: true, //全部选中
            enableFullRowSelection : false, //行任意位置选中
            multiSelect: true,//多行选中

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: $translate.instant('global.menu.admin.profitStats') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'], 
            minimumColumnSize: 70,
            columnDefs: [
                { field: 'jobNum', 
                    width: 150,
                    displayName: $translate.instant('stats.stat.jobNum'),
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<span class="ml-5"><a href=""ng-click="grid.appScope.VIEWGE(row.entity)">{{row.entity.jobNum}}&nbsp;</a><span class="glyphicon glyphicon-ok-circle"ng-if="row.entity.tkState == \'3\'"></span></span>'},
                { field: 'clientName', 
                    displayName: $translate.instant('stats.stat.filterByClient'), 
                    minWidth:180},
                { field: 'etd', 
                    displayName: $translate.instant('stats.stat.etd'), 
                    minWidth:120},
                { field: 'mblReleasedTime', 
                    displayName: $translate.instant('autopsApp.guide.mblReleasedTime'),
                    minWidth:60, 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.mblReleasedTime != null?\'label-primary\':\' label-danger\'"translate="{{row.entity.mblReleasedTime != null?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.guideId"></span>'},
                { field: 'hblReleasedTime', 
                    displayName: $translate.instant('autopsApp.guide.hblReleasedTime'),
                    minWidth:60, 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span ng-class="row.entity.hblReleasedTime != null?\'label-primary\':\' label-danger\'"translate="{{row.entity.hblReleasedTime != null?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.guideId"></span>'},
                { field: 'totalRMB', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.totalRMB', {currency: $rootScope.account.baseCurrency}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'uncollectedRMB', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.uncollectedRMB', {currency: $rootScope.account.baseCurrency}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},       
                { field: 'profitRMB', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.profitRMB', {currency: $rootScope.account.baseCurrency}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'gp', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.gp'), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.AVG,
                    treeAggregationLabel: "Avg: ",
                    aggregationType: uiGridConstants.aggregationTypes.avg,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100}, 
                { field: 'latestPaymentDate',
                    minWidth:120, 
                    displayName: $translate.instant('stats.stat.latestPaymentDate')},     
                { field: 'actAdvanced', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.actAdvanced', {currency: $rootScope.account.baseCurrency}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'costRmbTtl', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.costRmbTtl', {currency: $rootScope.account.baseCurrency}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'actRev', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.actRev', {currency: $rootScope.account.baseCurrency}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'actExp', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.actExp', {currency: $rootScope.account.baseCurrency}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},

                { field: 'unPaidRMB', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.unPaidRMB', {currency: $rootScope.account.baseCurrency}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},

                { field: 'clientRole',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.clientRole')},       
                { field: 'creditRating',
                    minWidth:100, 
                    displayName: $translate.instant('stats.stat.creditRating')},
                { field: 'isExport', 
                    displayName: $translate.instant('stats.stat.expImp'), 
                    minWidth:120,
                    type: "boolean",
                    cellTemplate: '<span class="ml-5" ng-show="row.entity.isExport != null" translate="{{row.entity.isExport?\'stats.stat.isExport\':\'stats.stat.isImport\'}}"></span>'},
                { field: 'salesName', 
                    displayName: $translate.instant('stats.stat.filterBySales'), 
                    minWidth:120},
                { field: 'branch', 
                    displayName: $translate.instant('stats.stat.bbranch'), 
                    minWidth:120},
                { field: 'department', 
                    displayName: $translate.instant('stats.stat.bteam'), 
                    minWidth:120},
                { field: 'opName', 
                    displayName: $translate.instant('stats.stat.filterByOperator'), 
                    minWidth:120},
                { field: 'cusName', 
                    displayName: $translate.instant('stats.stat.filterByCs'), 
                    minWidth:120},
                { field: 'docName', 
                    displayName: $translate.instant('stats.stat.filterByDocument'), 
                    minWidth:120},
                { field: 'finName', 
                    displayName: $translate.instant('stats.stat.filterByAccount'), 
                    minWidth:120},
                { field: 'overseasCs', 
                    displayName: $translate.instant('stats.stat.filterByOverSeasCs'), 
                    minWidth:120},
                { field: 'checkByName', 
                    displayName: $translate.instant('stats.stat.filterByMarket'), 
                    minWidth:120},
                { field: 'bookingBy', 
                    displayName: $translate.instant('stats.stat.bookingBy'), 
                    minWidth:120},
                { field: 'truckBy', 
                    displayName: $translate.instant('stats.stat.truckBy'), 
                    minWidth:120},
                { field: 'customBy', 
                    displayName: $translate.instant('stats.stat.customBy'), 
                    minWidth:120},
                { field: 'bizType', 
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.bizType')},
                { field: 'internalType', 
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.internalType')},                    
                { field: 'shipmentType', 
                    displayName: $translate.instant('stats.stat.shipmentType'), 
                    minWidth:120},
                { field: 'porName', 
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.porName')},
                { field: 'polName', 
                    displayName: $translate.instant('stats.stat.polName'), 
                    minWidth:120},
                { field: 'podName', 
                    displayName: $translate.instant('stats.stat.podName'), 
                    minWidth:120},
                { field: 'destName',
                    displayName: $translate.instant('stats.stat.destName'), 
                    minWidth:120},       
                { field: 'clearanceAgent',
                    displayName: $translate.instant('stats.stat.clearanceAgent'), 
                    minWidth:120},
                { field: 'deliveryToCode',
                    displayName: $translate.instant('stats.stat.deliveryto') + "(Code)", 
                    minWidth:120},
                { field: 'deliveryToName',
                    displayName: $translate.instant('stats.stat.deliveryto'), 
                    minWidth:120},
                { field: 'destCountryCode',
                    displayName: $translate.instant('stats.stat.destCountryCode'), 
                    minWidth:120},                    
                { field: 'atd', 
                    displayName: $translate.instant('stats.stat.atd'), 
                    minWidth:120},
                { field: 'eta', 
                    displayName: $translate.instant('stats.stat.eta'), 
                    minWidth:120},
                { field: 'ata', 
                    displayName: $translate.instant('stats.stat.ata'), 
                    minWidth:120},
                { field: 'vsl', 
                    displayName: $translate.instant('stats.stat.vsl'), 
                    minWidth:120},
                { field: 'voy', 
                    displayName: $translate.instant('stats.stat.voy'), 
                    minWidth:120},
                { field: 'carrier', 
                    displayName: $translate.instant('stats.stat.bcarrier'), 
                    minWidth:120},
                { field: 'deliveryChannel', 
                    displayName: $translate.instant('stats.stat.deliveryChannel'), 
                    minWidth:120},
                { field: 'polRegion', 
                    displayName: $translate.instant('stats.stat.polRegion'), 
                    minWidth:120},
                { field: 'podRegion', 
                    displayName: $translate.instant('stats.stat.podRegion'), 
                    minWidth:120},
                { field: 'lane', 
                    displayName: $translate.instant('stats.stat.lane'), 
                    minWidth:120},
                { field: 'contractNum', 
                    displayName: $translate.instant('stats.stat.contractNum'), 
                    minWidth:120},
                { field: 'cargoName', 
                    displayName: $translate.instant('stats.stat.cargoName'), 
                    minWidth:120},
                { field: 'cargoType', 
                    displayName: $translate.instant('stats.stat.cargoType'), 
                    minWidth:120},
                { field: 'tradeTerm', 
                    displayName: $translate.instant('stats.stat.tradeTerm'), 
                    minWidth:120},                
                { field: 'remarks', 
                    displayName: $translate.instant('stats.stat.remarks'), 
                    minWidth:120},
                { field: 'shipperName', 
                    displayName: $translate.instant('stats.stat.blShipper'), 
                    minWidth:120},
                { field: 'deliveryWay', 
                    displayName: $translate.instant('stats.stat.deliveryWay'), 
                    minWidth:120},
                { field: 'cargoReadyDate', 
                    displayName: $translate.instant('stats.stat.cargoReadyDate'), 
                    minWidth:120},
                { field: 'wmsInTime', 
                    displayName: $translate.instant('stats.stat.wmsInTime'), 
                    minWidth:120},                   
                { field: 'wmsOutTime', 
                    displayName: $translate.instant('stats.stat.wmsOutTime'), 
                    minWidth:120},        
                { field: 'deliveryDate', 
                    displayName: $translate.instant('stats.stat.deliveryDate'), 
                    minWidth:120},  
                { field: 'deliveryTime', 
                    displayName: $translate.instant('stats.stat.deliveryTime'), 
                    minWidth:120},
                { field: 'paymentRcvDate', 
                    displayName: $translate.instant('stats.stat.paymentRcvDate'), 
                    minWidth:120},
                { field: 'paymentPaidDate', 
                    displayName: $translate.instant('stats.stat.paymentPaidDate'), 
                    minWidth:120},
                { field: 'productName', 
                    displayName: $translate.instant('stats.stat.productName'), 
                    minWidth:120},
                { field: 'mjobNum', 
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.mjobNum')},
                { field: 'whName', 
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.whName')},
                { field: 'companyAbb', 
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.companyAbb')}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 152;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if($scope.choosedOne.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson($scope.choosedOne.gridState).gridState);
                    }     
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);                   
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    $scope.gridOptions.exporterExcelFilename = getTitleStr();
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "comStats";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);                   
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    $scope.gridOptions.exporterExcelFilename = getTitleStr();
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "comStats";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }, {
                title: $translate.instant('entity.action.exportSelExcel'),
                action: function($event) {
                    var jsonObj = {};
                    $scope.gridOptions.exporterExcelFilename = getTitleStr();
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = $scope.gridApi.selection.getSelectedRows();
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "comStats";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 5
            }, {
                title: $translate.instant('entity.action.delSelected'),
                action: function($event) {
                    for(var i = $scope.gridOptions.data.length - 1; i >= 0; i--){
                        var row = $scope.gridApi.grid.getRow($scope.gridOptions.data[i]);
                        if(row.isSelected){
                            $scope.gridOptions.data.splice(i, 1);
                        }
                    }
                    $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );          
                },
                order: 6
            }, {
                title: $translate.instant('entity.action.delNotSelected'),
                action: function($event) {
                    for(var i = $scope.gridOptions.data.length - 1; i >= 0; i--){
                        var row = $scope.gridApi.grid.getRow($scope.gridOptions.data[i]);
                        if(!row.isSelected){
                            $scope.gridOptions.data.splice(i, 1);
                        }
                    }
                    $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );                    
                },
                order: 7
            }],

            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi; 
            }
        }


        vm.statParams = {
            paymentReceivedTimeTAG: false,
            hblReleasedTimeTAG: true,
            mblReleasedTimeTAG: null,
            revDeadLineTO: new Date()
        };
        $scope.salesGuarantee;
        function getSalesGuarantee(refreshData) {
            if($scope.salesGuarantee && $scope.salesGuarantee.statsJobList && !refreshData){
                return;
            }
            vm.statParams.companyId = vm.guide.companyId;
            vm.statParams.salesId = vm.guide.salesId;
            layer.load(1, { shade: 0.3 });
            OpsFiles.getSalesGuarantee({}, vm.statParams, function(result) {
                layer.closeAll();
                $scope.salesGuarantee = result;
                $scope.gridOptions.data = result.statsJobList;
                if($scope.gridOptions.data.length == 0){
                    $rootScope.OPS_ALERT($translate.instant('stats.stat.noOverDueOders'));
                }
                $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.ALL);
            }, function(result) {
                layer.closeAll();
            });
        }
        function getSonShipments () {
            if(vm.sonGuides && vm.sonGuides.length){
                return;
            }
            Guide.getSonShipments({
                id: vm.guide.id
            }, function(result){
                vm.sonGuides = result;
                if(!vm.sonGuides || vm.sonGuides.length == 0){
                    layer.msg($translate.instant('global.messages.resultNull'));
                }
            })
        }
        function setValue(fd, value){
            vm.statParams[fd] = value;
        }

    }
})();
