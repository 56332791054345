(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WorkTableListController', WorkTableListController);

    WorkTableListController.$inject = ['$rootScope', '$scope', '$uibModalInstance', 'entity'];
    
    function WorkTableListController($rootScope, $scope, $uibModalInstance, entity) {
  
        $scope.workLoadList = entity; 
        $scope.clear = function  () {
            $uibModalInstance.dismiss('cancel');
        }
        var isReverse = true;
        $scope.sortList = function(title) {
            isReverse = !isReverse;
            $scope.workLoadList.sort(function(a,b){
                if (isReverse){
                    return a[title]<b[title]?1:-1;
                }
                else {
                    return a[title]>b[title]?1:-1;
                }                   
            });
        }
        
    }

})();