(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('LoginController', LoginController);

    LoginController.$inject = ['$rootScope','$scope', '$state', '$location', '$timeout', '$uibModalInstance','$cookieStore','$translate','$interval',
    'Auth', 'OpsFiles', 'Principal', 'ValidateCode', 'WoHead', 'OpsCompany'];

    function LoginController ($rootScope,$scope, $state, $location, $timeout, $uibModalInstance,$cookieStore,$translate,$interval,
        Auth, OpsFiles, Principal, ValidateCode, WoHead, OpsCompany) {
        var vm = this;

        vm.authenticationError = false;
        vm.cancel = cancel;
        vm.credentials = {};
        vm.login = login;
        vm.password = null;
        vm.trialApply = trialApply;
        vm.rememberMe = false;
        vm.requestResetPassword = requestResetPassword;
        vm.username = null;

        $scope.loginErrorTimeCount = 0;
        if($cookieStore.get("lgCt")){
            $scope.loginErrorTimeCount = $cookieStore.get("lgCt");
        }
        
        $scope.langKey = $translate.use();
        $scope.validateCodeShow = false;
        $scope.waitSec = false;
        $scope.tempCount = 0;
        $scope.second = 60;
        var timePromise = undefined; 
        vm.verCode = '';
        // $scope.rememberMe = true;
        
        $timeout(function (){
            angular.element('#username').focus();
            if ($location.host().indexOf("anmei") !== -1){
                $scope.systemName = "Anmei Cloud Intelligence";
                document.title = "Anmei Cloud Intelligence";
            }
            else if ($location.host().indexOf("thunder") !== -1){
                $scope.systemName = "Thunder Cloud Intelligence";
                document.title = "Thunder Cloud Intelligence";
            }
            else {
                $scope.systemName = "云智能操作系统";
                document.title = "云智能操作系统";                
            }
        });

        function cancel () {
            vm.credentials = {
                username: null,
                password: null,
                rememberMe: true
            };
            vm.authenticationError = false;
            $uibModalInstance.dismiss('cancel');
        }

        function login (event) {
            if($rootScope.ISDEMO){
                $uibModalInstance.dismiss('cancel');
                $rootScope.OPS_ALERT("Demo系统试用账号登录已禁用！"); 
                return;
            }
            event.preventDefault();
            
            if($scope.validateCodeShow){
                if(!vm.verCode){
                    layer.msg($translate.instant('login.messages.error.verCodeValidate')); 
                    return;
                }            
                if(!vm.username){
                    layer.msg($translate.instant('login.messages.error.usernameValidate'));
                    return;
                }                
                ValidateCode.validateCode({login:vm.username,verCode:vm.verCode},function(result){ //成功                    
                    $scope.authLogin();
                },function(error){
                    layer.msg($translate.instant('login.messages.error.verCodeValidateFail'));
                    return;
                });
            }else{
                 $scope.authLogin();
            }
            
            
//            Auth.login({
//                username: vm.username,
//                password: vm.password,
//                rememberMe: vm.rememberMe
//            }).then(function () {
//                vm.authenticationError = false;
//                $uibModalInstance.close();
//                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
//                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
//                    $state.go('home');
//                }
//
//                $rootScope.$broadcast('authenticationSuccess');
//
//                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
//                // since login is succesful, go to stored previousState and clear previousState
//                if (Auth.getPreviousState()) {
//                    var previousState = Auth.getPreviousState();
//                    Auth.resetPreviousState();
//                    $state.go(previousState.name, previousState.params);
//                }
//            }).catch(function () {
//                vm.authenticationError = true;
//            });
        }
        
        $scope.authLogin = function(){
        	$rootScope.ISLOGIN = true;
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {        
                $rootScope.ISLOGIN = false;    	
            	$scope.loginErrorTimeCount = 0;
                $scope.waitSec = false;
                $cookieStore.put("second",0);
                $cookieStore.put("lgCt",$scope.loginErrorTimeCount);
                $scope.validateCodeShow = false;
                $scope.authenticationError = false;   
                Principal.identity().then(function(account) {
                    if(!account.authorities){
                        layer.msg($translate.instant('global.loginFailed'));
                        $rootScope.account = {};
                        setTimeout(function(){
                            Auth.logout();
                        }, 1000);
                        return;
                    }
                    $rootScope.account = account;
                    try{
                        if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
                            $state.go('pda-home', null, { reload: false });
                            $rootScope.IS_MOB = true;
                        }
                    }catch(e){}
                    WoHead.workLoad();
                    $rootScope.LOAD_CONFIGURATION();
                    $rootScope.LOAD_CUSTOM_NODE_FIELD();

                    $rootScope.HOST_COMPANY_ID = $rootScope.account.companyId;
                    if(!$rootScope.HOST_COMPANY_ID && $rootScope.account.id){
                        OpsCompany.getCompanyByUserId({userId: $rootScope.account.id}, function(result){
                            $rootScope.HOST_COMPANY_ID = result.companyId;
                            if($rootScope.isPGS(result.companyId)){
                                $state.go('booking-all', null, { reload: false });                             
                            }
                        })
                    }
                });
                $rootScope.initLiveChat();
                $rootScope.OPSCURRENCYS = null;
                $rootScope.OPSPORTS = {};
                $rootScope.OPSVENDERS = [];
                $rootScope.dnTemplates = null;
                $rootScope.cnTemplates = null;
                $rootScope.OPSCLIENTS = null;
                $rootScope.WMSGOODS = null;
                $rootScope.choosedRole = null;  
                $rootScope.CLIENT_DEPOSITE = null;
                $rootScope.CARGOTYPES = null;
                $rootScope.OATYPES = null;
                
            	vm.authenticationError = false;
                $uibModalInstance.close();
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('home');
                }
                if ($rootScope.PUBTKSHOWLOGIN) {
                    $state.go('home');
                    $rootScope.PUBTKSHOWLOGIN = false;
                }

                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is succesful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }

                if(!$rootScope.ISCHROME) {
                    layer.confirm($translate.instant('home.logged.chromeWarn'), {
                        title: $translate.instant('home.logged.alert_title'),
                        btn: [$translate.instant('global.confirm')]
                    }, function(index) {
                        layer.close(index);
                    });
                }    

            }).catch(function () {
                $scope.tempCount = $cookieStore.get("lgCt");
                if($scope.tempCount>300){
                    // show验证码
                    $scope.validateCodeShow = true;
                }else{
                    $scope.loginErrorTimeCount++;
                    $cookieStore.put("lgCt",$scope.loginErrorTimeCount);
                }
                vm.authenticationError = true;
            });
        }
        
        

        function trialApply () {
            $uibModalInstance.dismiss('cancel');
            $state.go('application');
        }

        function requestResetPassword () {
            $uibModalInstance.dismiss('cancel');
            $state.go('requestReset');
        }
        
        if($scope.loginErrorTimeCount>30){
            $scope.validateCodeShow = true;
        }
        
        $scope.codeBtnText = 1;
        
        if(angular.isDefined($cookieStore.get("second"))){
            $scope.secondCount = $cookieStore.get("second");
        }
        
        $scope.countDown = function(){
            timePromise = $interval(function(){  
               if($scope.second<=0){  
                 $interval.cancel(timePromise);  
                 timePromise = undefined;
                 $cookieStore.put("second",$scope.second);
                 $scope.second = 60;
                 $scope.codeBtnText = 2;
                 $scope.waitSec = false;
               }else{
                 $scope.codeBtnText = 3;
                 $scope.second--;  
                 $scope.waitSec = true;
                 $cookieStore.put("second",$scope.second);
               }  
             },1000,100); 
        }
        
        
        if($scope.secondCount>0){
            $scope.waitSec = false;
            $scope.second = $scope.secondCount-1;
            $scope.codeBtnText = 3;
            $scope.countDown();
        }

        $scope.sendVCode = function(){
            event.preventDefault();
            if(!vm.username){
                layer.msg($translate.instant('login.messages.error.usernameValidate'));
                return;
            }
            ValidateCode.sendVerCode({login:vm.username},function(result){
                $scope.countDown();
                layer.msg($translate.instant('login.messages.error.verCodeSendSuccess'));
            },function(error){
                if(error.status == 403){
                    layer.msg($translate.instant('login.messages.error.verCodeLimit'));
                }else if(error.status == 404){
                    layer.msg($translate.instant('login.messages.error.unUseableTel'));
                }else{
                    layer.msg($translate.instant('login.messages.error.verCodeSendFail'));
                }                
            });
        }
        
        
    }
})();
