(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsCompanyDeleteController', OpsCompanyDeleteController);

    OpsCompanyDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'OpsCompany'];
    
	function OpsCompanyDeleteController($scope, $uibModalInstance, entity, OpsCompany) {

        $scope.opsCompany = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            OpsCompany.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }
})();
