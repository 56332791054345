(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('ExpressTracking', ExpressTracking);

    ExpressTracking.$inject = ['$resource', 'DateUtils'];

    function ExpressTracking ($resource, DateUtils) {
        var resourceUrl =  'api/express-trackings/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getConsolShipmentDTOs':{
                method:'POST',
                isArray:true,
                url:'api/express-trackings/getConsolShipmentDTOs'
            }, 
            'updateService':{
                method:'POST',
                url:'api/express-trackings/updateService',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.etd = DateUtils.convertLocalDateToServer(data.etd);
                    data.eta = DateUtils.convertLocalDateToServer(data.eta);
                    return angular.toJson(data);
                }
            },
            'getTrackingContext':{
                method:'PUT',
                url:'api/express-trackings/getTrackingContext'
            },
            'delTracking':{
                method:'PUT',
                url:'api/express-trackings/delTracking'
            },
            'changeSubsysHide':{
                method:'GET',
                url:'api/express-trackings/changeSubsysHide'
            },
            'getRelatedCount':{
                method:'PUT',
                url:'api/express-trackings/getRelatedCount'
            },
            'sendTrackingEmail':{
                method:'POST',
                url:'api/express-trackings/sendTrackingEmail'
            },            
            'getHeadTracking':{
                method:'GET',
                url:'api/express-trackings/getHeadTracking'
            },
            'airTracking':{
                method:'GET',
                url:'api/express-trackings/airTracking'
            },
            'getSubTrackings':{
                method:'GET',
                url:'api/express-trackings/getSubTrackings'
            },
            'getOneTracking':{
                method:'GET',
                url:'api/express-trackings/getOneTracking'
            },
            'kd100Tracking':{
                method:'GET',
                url:'api/express-trackings/kd100Tracking'
            },            
            'pubTracking':{
                method:'GET',
                isArray:true,
                url:'edi/pubTracking'
            },
            'getPodFile':{
                method:'GET',
                isArray:true,
                url:'edi/web-services/getPodFile'
            },
            'subTracking':{
                method:'GET',
                url:'edi/pubTracking/subTracking'
            },
            'pubSubTracking':{
                method:'GET',
                url:'edi/pubTracking/pubSubTracking'
            },
            'update': { method:'PUT' },
            'getTrackingResult': {
                method: 'GET',
                url: 'api/express-trackings/getTrackingResult'
            },
            'getCoscoTrackingToken':{
                method:'GET',
                url:'api/express-trackings/getCoscoTrackingToken'
            }
        });
    }
})();
