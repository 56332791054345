(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('TrackingController', TrackingController);

    TrackingController.$inject = ['$scope', '$rootScope', '$translate', '$uibModal', 'Principal', 'Guide', 'DateUtils', 'OpsFiles', '$http', 'Email'];

    function TrackingController($scope, $rootScope, $translate, $uibModal, Principal, Guide, DateUtils, OpsFiles, $http, Email) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };

        $scope.filterDTO = {};
        $scope.datePickerForEtd = {};

        $scope.datePickerForEtd.status = {
            opened: false
        };

        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };

        $scope.datePickerForCargoReadyDate = {};

        $scope.datePickerForCargoReadyDate.status = {
            opened: false
        };

        $scope.datePickerForCargoReadyDateOpen = function($event) {
            $scope.datePickerForCargoReadyDate.status.opened = true;
        };


        $scope._onClientSelected = function(data) {
            $scope.filterDTO.shipperId = data.id;
            $scope.filterDTO.clientName = data.companyName;
        }


        $scope.contents = [];
        $scope.getContetns = function() {
            if (!$scope.filterDTO.shipperId || !$scope.filterDTO.etdFrom || !$scope.filterDTO.etdEnd) {
                layer.msg($translate.instant('stats.stat.nullFilterAlert'));
                return;
            }
            if (DateUtils.compareDate($scope.filterDTO.etdFrom, $scope.filterDTO.etdEnd) > 0) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                return;            
            }
            if (DateUtils.getDaysDiff($scope.filterDTO.etdFrom, $scope.filterDTO.etdEnd) > 365) {
                layer.msg($translate.instant('global.messages.etdPeriodAlert'));
                return;            
            }

            $scope.filterDTO.companyId = $rootScope.account.companyId;
            $scope.isSaving = true;


            Guide.getTrackingReport($scope.filterDTO, function(result) {
                $scope.isSaving = false;
                if (!result || result.length == 0) {
                    initContent();
                    layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                    return;
                }
                $scope.contents = [];
                $scope.contents = result;
                $scope.reportavailalbe = true;

            }, function(response) {
                $scope.isSaving = false;
                $scope.reportavailalbe = false;

            });
        }

        var initContent = function() {
            if (!$scope.contents || $scope.contents.length == 0) {
                $scope.contents = [];
                for (var j = 0; j <= 20; j++) {
                    $scope.contents.push({});
                }
            }
        }
        initContent();

        $scope.format = "excel";
        $scope.reportavailalbe = false;
        $scope.fileName = "";

        $scope.exportTracking = function(format) {
            $scope.format = format;
            $scope.fileName = '';
            $scope.fileName_btn = layer.open({
                type: 1,
                area: ['300px', '180px'],
                btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')],
                title: $translate.instant('global.fileName'),
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: $("#templateReportName"),
                success: function(layero, index) {
                    $("#fileName").focus();
                },
                yes: function(index, layero) {
                    if ($scope.fileName == '') {
                        $scope.fileName = 'Tracking';
                    }
                    generateReport();
                    layer.close($scope.fileName_btn);
                },
                no: function(index, layero) {
                    layer.close(index);
                }
            });
        }

        $("#fileName").keydown(function(event) {
            $scope.keycode = window.event ? event.keyCode : event.which;
            if ($scope.keycode == 13) {
                if ($scope.fileName == '') {
                    $scope.fileName = 'Tracking';
                }
                generateReport();
                layer.close($scope.fileName_btn);
            }
        })

        var generateReport = function() {
            OpsFiles.generateClientTrackingReport({
                client: $scope.filterDTO.clientName,
                dateFrom: $("#field_etd").val(),
                dateTo: $("#field_cargoReadyDate").val(),
                fileType: $scope.format
            }, $scope.contents, function(result) {
                exportExcelOrPdf(result);
            }, function(error) {
                layer.msg($translate.instant('stat.stat.generateTrackingReportFailed'));
            });
        }

        var exportExcelOrPdf = function(result) {
            if (angular.isDefined($scope.format) && $scope.format == 'excel') {
                var file = new Blob([result.data], {
                    type: 'application/vnd.ms-excel'
                });
                var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
                var aTag = document.createElement("a");
                aTag.download = $scope.fileName + ".xlsx";
                aTag.href = blobURL;
                aTag.click();
            } else {
                var file = new Blob([result.data], {
                    type: 'application/pdf'
                });
                var fileURL = URL.createObjectURL(file);
                var previewDialog = layer.open({
                    type: 2,
                    area: ['1200px', '800px'],
                    maxmin: true,
                    title: $translate.instant("autopsApp.accountTitle.viewReport"),
                    shade: 0.3,
                    moveType: 0,
                    shift: 0,
                    content: fileURL
                })
            }
        }

        $scope.emailPopUp = function() {
            var email = {};
            email.attachments = [];
            email.head = $scope.filterDTO.clientName;
            email.subject = $translate.instant('entity.email.trackingSubject');
            email.content = $translate.instant('entity.email.trackingContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }

            var entity = {};
            entity.email = email;
            entity.venderId = $scope.filterDTO.shipperId;
            OpsFiles.generateTrackingAttachment({
                companyId: $rootScope.account.companyId,
                client: $scope.filterDTO.clientName,
                dateFrom: $("#field_etd").val(),
                dateTo: $("#field_cargoReadyDate").val()
            }, $scope.contents, function(result) {
                if (result.file) {
                    entity.fileResult = result.file;
                    sendEmailPop(entity);
                }
            }); 
        }

        var sendEmailPop = function(entity) {
            $uibModal.open({
                templateUrl: 'app/entities/email/email-dialog.html',
                controller: 'EmailDialogController',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
            }, function(result) {});
        }

    }
})();