(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountBindDeleteController',AccountBindDeleteController);

    AccountBindDeleteController.$inject = ['$uibModalInstance', '$translate', 'entity', 'AccountBind'];

    function AccountBindDeleteController($uibModalInstance, $translate, entity, AccountBind) {
        var vm = this;

        vm.accountBind = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AccountBind.delete({id: id},
                function () {
                    layer.msg($translate.instant('global.delete_success'));
                    $uibModalInstance.close(true);
                });
        }
    }
})();
