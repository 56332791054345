(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ProfitStatementController', ProfitStatementController);

    ProfitStatementController.$inject = ['$scope', '$rootScope', '$window', '$translate', 
    'Principal', 'OpsFiles', 'DateUtils', 'DataUtils', 'AccountGeneral'];

    function ProfitStatementController($scope, $rootScope, $window, $translate, 
        Principal, OpsFiles, DateUtils, DataUtils, AccountGeneral) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            })
        }
        $scope.opsData = "ops";
        $scope.isSaving = false;
        $scope.profitSheet = {};
        $scope.profitSheet.incomeStatementsList = [];
        $scope.filterDTO = {};

        $scope.yearFormat = "yyyy";
        $scope.datePickerOpenStatus = {};
        $scope.yearDatepickerOptions = {
            minMode: 'year'
        }
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }
        $scope.getContetns = function() {
            $scope.isSaving = true;
            layer.load(1, {shade: 0.3});
            AccountGeneral.profitStatementGet({
                companyId: $rootScope.account.companyId,
                dataSource: $scope.opsData,
                isEn: $translate.instant('global.enCn') == "en",
                localDate: DateUtils.convertLocalDateToServer($scope.filterDTO.year)
            }, function(result) {
                layer.closeAll(); 
                $scope.isSaving = false;
                $scope.profitSheet = result;
                if (!$scope.profitSheet || $scope.profitSheet.incomeStatementsList.length == 0) {
                    layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                    return;
                }
            }, function(error) {
                layer.closeAll(); 
                $scope.isSaving = false;
                layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
            });
        }

        $scope.year = "";
        $scope.getYear = function() {
            $scope.year = $scope.filterDTO.year.getFullYear(); //取当前的年份   
        }

        $scope.exportYearReport = function(fileType) {
            AccountGeneral.profitStatementPrint({
                companyId: $rootScope.account.companyId,
                reportCode: 990,
                fileType: fileType,
                isEn: $translate.instant('global.enCn') == "en",
                localDate: DateUtils.convertLocalDateToServer($scope.filterDTO.year)
            }, $scope.profitSheet, function(result) {
                if (fileType == 2) {
                    $rootScope.EXPORTDOWNLOAD(result, 2, "Profit Statement");
                } else {
                    $rootScope.PRINTBYTES(result.data);
                }
            }, function(error) {
                layer.msg($translate.instant('autopsApp.accountTitle.viewAccountTitleFail'));
            });
        }


    }
})();