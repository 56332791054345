(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('custom-declaration', {
            parent: 'wms',
            url: '/custom-declaration?page&sort&search',
            data: {
                authorities: ['ROLE_BROKER'],
                pageTitle: 'autopsApp.customDeclaration.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/custom-declaration/custom-declarations.html',
                    controller: 'CustomDeclarationController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('customDeclaration');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('custom-declaration.copy', {
            parent: 'custom-declaration',
            url: '/copy/{id}',
            data: {
                authorities: ['ROLE_BROKER'],
                pageTitle: 'autopsApp.customDeclaration.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/custom-declaration/custom-declaration-detail.html',
                    controller: 'CustomDeclarationDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('customDeclaration');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'CustomDeclaration', 'WinAES', function($stateParams, CustomDeclaration, WinAES) {
                    return CustomDeclaration.get({id : WinAES.decode($stateParams.id)}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'custom-declaration',
                        isCopy:true,
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('custom-declaration.edit', {
            parent: 'custom-declaration',
            url: '/edit/{id}',
            data: {
                authorities: ['ROLE_BROKER'],
                pageTitle: 'autopsApp.customDeclaration.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/custom-declaration/custom-declaration-detail.html',
                    controller: 'CustomDeclarationDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('customDeclaration');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'CustomDeclaration', 'WinAES', function($stateParams, CustomDeclaration, WinAES) {
                    return CustomDeclaration.get({id : WinAES.decode($stateParams.id)}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'custom-declaration',
                        isCopy:false,
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('custom-declaration.new', {
            parent: 'custom-declaration',
            url: '/new',
            data: {
                authorities: ['ROLE_BROKER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/custom-declaration/custom-declaration-detail.html',
                    controller: 'CustomDeclarationDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('customDeclaration');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: function () {
                    return {
                        id: null,
                        tradeName:null,
                        iEPort:null,
                        iEDate:null,
                        declareDate:null,
                        ownerName:null,
                        conveyCode:null,
                        trafMode:null,
                        manualNo:null,
                        agentName:null,
                        wrapType:null,
                        grossWt:null,
                        netWt:null,
                        marks:null,
                    };
                },
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'custom-declaration',
                        isCopy:false,
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('custom-declaration.delete', {
            parent: 'custom-declaration',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_BROKER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/custom-declaration/custom-declaration-delete-dialog.html',
                    controller: 'CustomDeclarationDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CustomDeclaration', 'WinAES', function(CustomDeclaration, WinAES) {
                            return CustomDeclaration.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('custom-declaration', null, { reload: 'custom-declaration' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
