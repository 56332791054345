(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ManagementFeesController', ManagementFeesController);

    ManagementFeesController.$inject = ['$scope', '$rootScope', '$translate', '$uibModal', '$timeout',
    'JhiLanguageService', 'Principal', 'AccountRef', 'OpsFiles', 'DateUtils', 'AccountVoucher', 'AccountTitle', 'VoucherContent', 'DataUtils'];

    function ManagementFeesController($scope, $rootScope, $translate, $uibModal, $timeout,
        JhiLanguageService, Principal, AccountRef, OpsFiles, DateUtils, AccountVoucher, AccountTitle, VoucherContent, DataUtils) {
        $scope.filterDTO = {};
        var ledgers = [];
        var showLedger = "";
        $scope.managementFeeDTO = {};

        var lang = "";
        JhiLanguageService.getCurrent().then(function(language) {
            lang = language;
        });
        var zTreeObj;
        var categorySetting = {
            check: {
                enable: false
            },
            callback: {
                onClick: onClick
            },
        };
        function onClick(event, treeId, treeNode) {
            var data = treeNode.obj;
            $scope.filterDTO.ledgerId = data.id;
            $scope.filterDTO.ledgerCode = data.code;
            var showLedgerArr = [];
            angular.forEach(ledgers, function(result, index) {
                if (data.code && data.code.indexOf(result.code) > (-1)) {
                    showLedgerArr.push(lang == 'en' ? result.enName : result.cnName);
                } else if (data.code == result.code) {
                    showLedgerArr.push(lang == 'en' ? result.enName : result.cnName);
                    return
                }
            })
            showLedger = showLedgerArr.join('/ ');
            $scope.filterDTO.ledger = showLedgerArr.join('/ ');
            $scope.reportavailalbe = false;
            if (data.isForeign) {
                $scope.isForeign = true;
                $scope.currencyName = data.currency;
            } else {
                $scope.isForeign = false;
                $scope.currencyName = null;
            }
            initCContent();
            // $scope.filterDTO.level = "";
            $scope.getContetns();
            $translate.refresh();
        }
        $scope._onLedgerChoosed = function(data) {
            $scope.filterDTO.ledgerId = data.id;
            $scope.filterDTO.ledger = data.showLedger;
            $scope.filterDTO.ledgerCode = data.code;
            $scope.reportavailalbe = false;
            if (data.isForeign && data.isLast) {
                $scope.isForeign = true;
                $scope.currencyName = data.currency;
            } else {
                $scope.isForeign = false;
                $scope.currencyName = null;
            }
            $scope.managementFeeDTO.managementFeeContentDTOs = [];
            initCContent();
            $translate.refresh();
        }
        var getLedgers = function(data) {
            AccountTitle.getAll({
                type: "all"
            }, function(result) {
                ledgers = result;
                $scope.ledgers = DataUtils.generateShowLedger(ledgers, false, lang);
                for(var i = $scope.ledgers.length - 1; i >= 0; i--){
                    if($scope.ledgers[i].isLast){
                        $scope.ledgers.splice(i, 1);
                    }
                }
            });
        }
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                getLedgers();
            });
        } else {
            getLedgers();
        }

        $scope.datePickerForEtd = {};
        $scope.datePickerForEtd.status = {
            opened: false
        };
        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };

        $scope.datePickerForCargoReadyDate = {};
        $scope.datePickerForCargoReadyDate.status = {
            opened: false
        };
        $scope.datePickerForCargoReadyDateOpen = function($event) {
            $scope.datePickerForCargoReadyDate.status.opened = true;
        };


        $scope.isSaving = false;
        $scope.contentYear = "2016";
        $scope.dnColNum = 6;
        $scope.total = {};

        var refrestCDC = function() {
            var dnTotal = 0,
                cnTotal = 0,
                balanceTotal = 0,
                date1, dateArray;
            if ($scope.managementFeeDTO.managementFeeContentDTOs) {
                angular.forEach($scope.managementFeeDTO.managementFeeContentDTOs, function (data) {
                    if (data.makeDate) {
                        date1 = DateUtils.convertLocalDateToServer(data.makeDate);
                        dateArray = date1.split("-");
                        data.month = dateArray[1];
                        data.day = dateArray[2];
                    }

                    if (data.dn) {
                        dnTotal = dnTotal + data.dn;
                    }
                    if (data.cn) {
                        cnTotal = cnTotal + data.cn;
                    }
                    if (data.balance) {
                        balanceTotal = data.balance;
                    }
                })
            }
            $scope.total.dn = dnTotal;
            $scope.total.cn = cnTotal;
            $scope.total.balance = balanceTotal;
        }

        var initCContent = function() {
            if (!$scope.managementFeeDTO.managementFeeContentDTOs || $scope.managementFeeDTO.managementFeeContentDTOs.length == 0) {
                $scope.managementFeeDTO.managementFeeContentDTOs = [];
                for (var j = 0; j <= 10; j++) {
                    $scope.managementFeeDTO.managementFeeContentDTOs.push({});
                }
                $scope.dnColNum = 6;
                $scope.total = {};
                $scope.managementFeeDTO.subLedgerNames = [];
                $scope.managementFeeDTO.subLedgerTotal = [];
                for (var i = 0; i < 6; i++) {
                    $scope.managementFeeDTO.subLedgerNames.push("");
                    $scope.managementFeeDTO.subLedgerTotal.push("");
                }
            } else {
                refrestCDC();
            }            
        }

        initCContent();
        $scope.titles = [{
            code: "6602",
            name: $translate.instant('autopsApp.accountTitle.management_fees')
        }, {
            code: "6601",
            name: $translate.instant('autopsApp.accountTitle.marketing_cost')
        }, {
            code: "6603",
            name: $translate.instant('autopsApp.accountTitle.financial_cost')
        }];
        $scope.selectedLedgerChange = function(code) {
            if (code == "6602") {
                $scope.filterDTO.ledgerName = $translate.instant('autopsApp.accountTitle.management_fees');

            } else if (code == "6601") {
                $scope.filterDTO.ledgerName = $translate.instant('autopsApp.accountTitle.marketing_cost');

            } else if (code == "6603") {
                $scope.filterDTO.ledgerName = $translate.instant('autopsApp.accountTitle.financial_cost');

            }
        }

        $scope.reportavailalbe = false;
        $scope.getContetns = function() {
            if (!$scope.filterDTO.ledgerId) {
                layer.msg($translate.instant('autopsApp.accountTitle.nullFilterAlert'));
                return;
            }
            var filterDTO = angular.copy($scope.filterDTO);
            // if(loadAll) {
            //     if (!filterResults || filterResults.length == 0) {
            //         layer.msg($translate.instant('autopsApp.accountTitle.nullFilterAlert'));
            //         return;
            //     }
            //     filterDTO.subLedgers = filterResults;
            // }
            // $scope.filterDTO.ledgerCode = "6602";
            if (!filterDTO.ledgerCode || !filterDTO.dateFrom || !filterDTO.dateTo) {
                layer.msg($translate.instant('autopsApp.accountTitle.nullFilterAlert'));
                return;
            }
            if (DateUtils.compareDate(filterDTO.dateFrom, filterDTO.dateTo) > 0) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                return;            
            }
            if (DateUtils.getDaysDiff(filterDTO.dateFrom, filterDTO.dateTo) > 365) {
                layer.msg($translate.instant('global.messages.etdPeriodAlert'));
                return;            
            }

            if (filterDTO.isAudited == null) {
                filterDTO.isAudited = false;
            }
            filterDTO.companyId = $rootScope.account.companyId;
            $scope.isSaving = true;
            $scope.subLedgerNames = [];
            VoucherContent.getManagementFee(filterDTO, function(result) {
                $scope.isSaving = false;
                $scope.managementFeeDTO = result;
                $scope.contentYear = $scope.filterDTO.dateTo.getFullYear();
                $scope.dnColNum = result.subLedgerNames.length + 1;
                if (!$scope.managementFeeDTO.managementFeeContentDTOs || $scope.managementFeeDTO.managementFeeContentDTOs.length == 0) {
                    layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                    initCContent();
                    $scope.reportavailalbe = false;
                    return;
                }
                $scope.reportavailalbe = true;
                initCContent();
            }, function(response) {
                $scope.isSaving = false;
                $scope.reportavailalbe = false;
                initCContent();
            });
        }

        $scope.exportManagementFee = function() {
            OpsFiles.exportManagementFee({
                enCn: $translate.instant('global.enCn')
            }, $scope.managementFeeDTO, function(result) {
                $rootScope.DOWNLOADEXCEL(result, "Multicolumn_Statement.xlsx");                
            })
        }


        // $scope.exportManagementFee = function(format) {
        //     $scope.getReport();
        //     $scope.fileName = '';
        //     $scope.format = format;
        //     $scope.fileName_btn = layer.open({
        //         type: 1,
        //         area: ['300px', '180px'],
        //         btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')],
        //         title: $translate.instant('global.fileName'),
        //         shade: 0.3,
        //         moveType: 0,
        //         shift: 0,
        //         content: $("#templateReportName"),
        //         success: function(layero, index) {
        //             $("#fileName").focus();
        //         },
        //         yes: function(index, layero) {
        //             if ($scope.fileName == '') {
        //                 $scope.fileName = 'Multicolumn_Statement';
        //             }
        //             $scope.generateManagementFeeReport();
        //             layer.close($scope.fileName_btn);
        //         },
        //         no: function(index, layero) {
        //             layer.close(index);
        //         }
        //     });
        // }

        // $("#fileName").keydown(function(event) {
        //     $scope.keycode = window.event ? event.keyCode : event.which;
        //     if ($scope.keycode == 13) {
        //         if ($scope.fileName == '') {
        //             $scope.fileName = 'Multicolumn_Statement';
        //         }
        //         $scope.generateManagementFeeReport();
        //         layer.close($scope.fileName_btn);
        //     }
        // });

        // $scope.generateManagementFeeReport = function() {
        //     if(!$scope.filterDTO.ledgerName){
        //         $scope.filterDTO.ledgerName = $translate.instant('global.menu.entities.management_fees');
        //     }
        //     OpsFiles.generateManagementFeeContent({
        //         ledgerName: $scope.filterDTO.ledgerName,
        //         contentYear: $scope.contentYear,
        //         fileType: $scope.format
        //     }, managementFeeDTO, function(result) {
        //         $scope.exportExcelOrPdf(result);
        //     }, function(error) {
        //         layer.msg($translate.instant("autopsApp.accountTitle.viewManagementFeeFail"));
        //     });
        // }

        // $scope.exportExcelOrPdf = function(result) {
        //     if (angular.isDefined($scope.format) && $scope.format == 'excel') {
        //         var file = new Blob([result.data], {
        //             type: 'application/vnd.ms-excel'
        //         });
        //         var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
        //         var aTag = document.createElement("a");
        //         aTag.download = $scope.fileName + ".xlsx";
        //         aTag.href = blobURL;
        //         aTag.click();
        //     } else {
        //         var file = new Blob([result.data], {
        //             type: 'application/pdf'
        //         });
        //         var fileURL = URL.createObjectURL(file);
        //         var previewDialog = layer.open({
        //             type: 2,
        //             area: ['1000px', '680px'],
        //             maxmin: true,
        //             title: $translate.instant("autopsApp.accountTitle.viewManagementFee"),
        //             shade: 0.3,
        //             moveType: 0,
        //             shift: 0,
        //             content: fileURL
        //         })
        //     }
        // }
        $scope.getVoucher = function(id) {
            if (null == id) {
                return;
            }
            VoucherContent.getVoucher({
                id: id
            }, function(result) {
                $rootScope.VIEWCONTENTVOUCHER(id, $scope.managementFeeDTO.managementFeeContentDTOs, result);
            });
        };
        $scope.firstShow = true;
        $scope.isOpTree = true;
        $scope.isOpenTree = function() {
            $scope.firstShow = false;
            $scope.isOpTree = !$scope.isOpTree;
        }
        $scope.TreeDivHeight = null;
        $scope.isAcitve = false;
        $timeout(function() {
            getTableHeight()
        });
        window.addEventListener('resize', getTableHeight);

        function getTableHeight() {
            var footerHeight = 0;
            if (document.getElementById("footPager")) {
                document.getElementById("footPager").style.cssText = "margin: 0px;";
                footerHeight = $("#" + "footPager").height();
            }
            var heightPx = $(window).height() - $("#showTable").offset().top - footerHeight - 1;
            $scope.TableHeight = {
                "max-height": heightPx + "px"
            }
            $scope.TreeDivHeight = {
                "max-height": heightPx - 10 + "px"
            }
            $scope.TreeHeightStyle = {
                "max-height": heightPx - 150 + "px"
            }
            $translate.refresh();
        }

        $scope.getPeriodAccountCodes = function() {
            if (!$scope.filterDTO.dateTo || !$scope.filterDTO.dateFrom) {
                return;
            }
            var filterLedger = {};
            filterLedger.companyId = $rootScope.account.companyId;
            filterLedger.makeDateTO = $scope.filterDTO.dateTo;
            filterLedger.makeDateFM = $scope.filterDTO.dateFrom;
            filterLedger.includeBalaceNotZero = $scope.filterDTO.isShowAmount; //无发生额且余额为0不显示
            layer.load(1, { shade: 0.3 });
            VoucherContent.getPeriodAccountCodes(filterLedger, function(result) {
                layer.closeAll();
                ledgers = result;
                $scope.filterChange();
            }, function() {
                layer.closeAll();
                layer.msg($translate.instant('global.loadFailed'));
            });
        }
        var filterResults;
        $scope.filterChange = function() {
            filterResults = getFilterResult(ledgers);
            caegoryNodes = [];
            getNodes(filterResults);
            zTreeObj = $.fn.zTree.init($("#accountTitlesTree"), categorySetting, caegoryNodes);
            fuzzySearch('accountTitlesTree', '#field_search', null, true);
        }
        function getFilterResult() {
            if (!ledgers || ledgers.length == 0) {
                return;
            }
            var results = angular.copy(ledgers);
            var newResult = angular.copy(results);
            for (var i = results.length - 1; i >= 0; i--) {
                if (codeCompare($scope.filterDTO.ledgerCodeFrom, results[i].code) == 1 ) {
                    newResult.splice(i, 1);
                }
                if (codeCompare($scope.filterDTO.ledgerCodeTo, results[i].code) == -1 ) {
                    newResult.splice(i, 1);
                }
                if ($scope.filterDTO.levelFrom && parseInt($scope.filterDTO.levelFrom) > results[i].level) {
                    newResult.splice(i, 1);
                }
                if ($scope.filterDTO.levelTo && parseInt($scope.filterDTO.levelTo) < results[i].level) {
                    newResult.splice(i, 1);
                }
            }
            return newResult
        }
        function codeCompare(code1, code2) {
            if(!code1 || !code2){
                return 0;
            }
            if(code2.length > code1.length){
                code2 = code2.substring(0, code1.length);
            }
            if(code1 > code2){
                return 1;
            }
            if(code1 < code2){
                return -1;
            }
            return 0;
        }
        // zTree 的数据属性，深入使用请参考 API 文档（zTreeNode 节点数据详解）
        var caegoryNodes = [];
        function getNodes(dataList) {
            var num = 1;
            if ($scope.filterDTO.levelFrom) {
                num = $scope.filterDTO.levelFrom - 0;
            }
            angular.forEach(dataList, function(data) {
                if (data.level == num) {
                    var node = {
                        name: lang == 'en' ? data.code + ' ' + data.enName : data.code + ' ' + data.cnName,
                        open: true,
                        id: data.code,
                        obj: data,
                        children: []
                    };
                    addSubNodes(node, data.level + 1, dataList)
                    if (node.children.length == 0) {
                        node.children = null;
                    }
                    caegoryNodes.push(node)
                }
            })
        };
        function addSubNodes(parentNode, level, dataList) {
            angular.forEach(dataList, function(data) {
                if (data.level == level && data.code.indexOf(parentNode.id) == 0 && data.code != parentNode.id) {
                    var node = {
                        name: lang == 'en' ? data.code + ' ' + data.enName : data.code + ' ' + data.cnName,
                        open: true,
                        id: data.code,
                        obj: data,
                        children: []
                    };
                    addSubNodes(node, data.level + 1, dataList);
                    if (node.children.length == 0) {
                        node.children = null;
                    }
                    parentNode.children.push(node)
                }
            })
        }
    }
})();