
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsGoodsBatchDialogController', WmsGoodsBatchDialogController);

    WmsGoodsBatchDialogController.$inject = ['$scope', '$state', '$rootScope', '$timeout', '$translate', 
    'WmsIn', 'DataUtils', 'WmsWarehouse'];

    function WmsGoodsBatchDialogController ($scope, $state, $rootScope, $timeout, $translate, 
        WmsIn, DataUtils, WmsWarehouse) {

        $timeout(function() {
            $("#field_warehousingNumber").focus();
        });

        var vm = this;
        vm.location = {};
        vm.cargo = {};
        vm.getInboundPaper = getInboundPaper;
        vm._onLocationSelected = _onLocationSelected;
        vm.onSkuSelected = onSkuSelected;
        vm.locationSelected = locationSelected;
        vm.save = save;

        function getInboundPaper() {
            if(!vm.wmsIn.warehousingNumber){
                return;
            }
            vm.isSaving = true;
            WmsIn.getRapidOutBywarehousingNumber({
                warehousingNumber: vm.wmsIn.warehousingNumber,
                companyId: $rootScope.account.companyId
            }, function (result) {
                vm.isSaving = false;
                vm.wmsIn = result;          
                updateTtl();      
                getLocations();
                // layer.msg($translate.instant('entity.action.loadSuccess'));                
            }, function(result){
                vm.isSaving = false;
                if(result.status == 404){
                    layer.msg($translate.instant('global.noRecord'));
                }
                else {
                    layer.msg($translate.instant('global.loadFailed'));
                }
            });
        }
        function getLocations () {
            if(!vm.wmsIn.warehouseId){
                return;
            }
            vm.isSaving = true;
            WmsWarehouse.getLocations({warehouseId: vm.wmsIn.warehouseId}, function (data) {
                vm.locations = data;
                for (var i = vm.locations.length - 1; i >= 0; i --){
                    if (vm.locations[i].isVirtual == "isNot"){
                        vm.locations.splice(i, 1);
                    }
                }
                vm.isSaving = false;
                fucusInput("field_location");
            });
        }
        function _onLocationSelected () {
            angular.forEach(vm.locations, function(item){
                if(item.code == vm.location.code){
                    vm.location = item;
                }                
            })
            if(!vm.location.id){
                vm.location = {};
                layer.msg("Equipment is not exist");
                fucusInput("field_location");
            }
            else {
                fucusInput("field_scan_sku");
            } 
        }
        function onSkuSelected () {            
            angular.forEach(vm.wmsIn.cargoList, function(item){
                if(!item.outTime && vm.cargo.sku == item.sku){
                    vm.cargo = item;
                    vm.cargo.pkgsCount = vm.cargo.pkgs;
                }                
            })
            if(!vm.cargo.id){
                layer.msg("No matched SKU");
                fucusInput("field_scan_sku");                
            }
            else {
                fucusInput("field_pkgs");
            } 
        } 

        function updateTtl () {
            vm.wmsIn.gw = 0;
            vm.wmsIn.vol = 0;
            vm.wmsIn.applicationNum = 0;
            vm.wmsIn.actualNum = 0;  
            angular.forEach(vm.wmsIn.cargoList, function(data){
                if(!data.pkgs){
                    data.pkgs = 0;
                }
                vm.wmsIn.applicationNum += data.pkgs;
                if(data.gw){
                    vm.wmsIn.gw += data.gw * data.pkgs;
                    data.gwTtl = DataUtils.round(data.gw * data.pkgs, 2);
                }
                if(data.vol){
                    vm.wmsIn.vol += data.vol * data.pkgs;
                    data.volTtl = DataUtils.round(data.vol * data.pkgs, 3);
                }
                if (data.outTime || data.allOut){
                    vm.wmsIn.actualNum += data.pkgs;
                }
            })
            vm.wmsIn.gw = DataUtils.round(vm.wmsIn.gw, 2);
            vm.wmsIn.vol = DataUtils.round(vm.wmsIn.vol, 4);
            if(vm.wmsIn.actualNum >= vm.wmsIn.applicationNum){
                layer.msg("All products/SKU registed!");
            }
        }
        function fucusInput (fdId) {
            $timeout(function() {
                $("#" + fdId).focus();   
            });
            // document.activeElement.blur();
        }
        function save() {
            if(vm.isSaving){
                return;
            }
            vm.isSaving = true;
            if(!vm.cargo.pkgsCount || vm.cargo.pkgsCount > vm.cargo.pkgs){
                $rootScope.OPS_ALERT(vm.cargo.sku + ": " + $translate.instant('autopsApp.wmsIn.notNumAlert'));
                vm.isSaving = false;
                return;
            }
            vm.wmsIn.actualNum = 0;
            angular.forEach(vm.wmsIn.cargoList, function (data) {
                if (vm.cargo.id == data.id){
                    data.pkgs = vm.cargo.pkgsCount;
                    data.location = vm.location.code;
                    data.locationId = vm.location.locationId;
                    data.areaId = vm.location.areaId;
                    data.warehouseId = vm.location.warehouseId;

                    data.keeper = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                    data.keeperId = $rootScope.account.id;
                    data.allOut = true;
                }         

                if(data.allOut){
                    vm.wmsIn.actualNum += data.pkgs;
                }
            })
            if (vm.wmsIn.actualNum){
                if(vm.wmsIn.actualNum >= vm.wmsIn.applicationNum){
                    vm.wmsIn.status = "allIn";
                    vm.wmsIn.allOut = true;
                    if(!vm.wmsIn.outTime){
                        vm.wmsIn.outTime = new Date();
                    }   
                }
                else {
                    vm.wmsIn.status = "partIn";
                }
            }
            else {
                vm.wmsIn.status = "Confirmed";
            }
            vm.wmsIn.registerBy = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            vm.wmsIn.registerId = $rootScope.account.id;

            WmsIn.saveRegistOut(vm.wmsIn, onSaveSuccess, onSaveError);          
        }
        function onSaveSuccess (result) {
            vm.isSaving = false;
            vm.wmsIn = result;
            updateTtl();
            vm.cargo = {};
            fucusInput("field_scan_sku");            
            layer.msg($translate.instant('global.messages.submitted'));
        }

        function onSaveError (result) {
            vm.isSaving = false;
            if (result.status == 409) {
                layer.msg($translate.instant('global.refreshAlert'));
            } 
        }

        function locationSelected(item) {
            vm.location.code = item.code;
            _onLocationSelected();
        }
    }
})();
