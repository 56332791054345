(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('Prediction', Prediction);

    Prediction.$inject = ['$resource'];

    function Prediction ($resource) {
        var resourceUrl =  'api/predictions/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                },
                isArray: true
            },
            'generateGuide': {
                method: 'PUT',
                url:'api/predictions/generateGuide',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'refreshStatus': {
                method: 'GET',
                url:'api/predictions/refreshStatus',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                },
                isArray: true
            },
            'update': { method:'PUT' }
        });
    }
})();
