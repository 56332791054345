(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('guide-fcl-ctn', {
            parent: 'entity',
            url: '/guide-fcl-ctn?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'autopsApp.guideFclCtn.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/guide-fcl-ctn/guide-fcl-ctns.html',
                    controller: 'GuideFclCtnController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('guideFclCtn');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('guide-fcl-ctn-detail', {
            parent: 'entity',
            url: '/guide-fcl-ctn/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'autopsApp.guideFclCtn.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/guide-fcl-ctn/guide-fcl-ctn-detail.html',
                    controller: 'GuideFclCtnDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('guideFclCtn');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'GuideFclCtn', function($stateParams, GuideFclCtn) {
                    return GuideFclCtn.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'guide-fcl-ctn',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('guide-fcl-ctn-detail.edit', {
            parent: 'guide-fcl-ctn-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/guide-fcl-ctn/guide-fcl-ctn-dialog.html',
                    controller: 'GuideFclCtnDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['GuideFclCtn', function(GuideFclCtn) {
                            return GuideFclCtn.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('guide-fcl-ctn.new', {
            parent: 'guide-fcl-ctn',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/guide-fcl-ctn/guide-fcl-ctn-dialog.html',
                    controller: 'GuideFclCtnDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                num: null,
                                ctnType: null,
                                gw: null,
                                vol: null,
                                pkgNum: null,
                                pkgType: null,
                                soc: null,
                                guideId: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('guide-fcl-ctn', null, { reload: 'guide-fcl-ctn' });
                }, function() {
                    $state.go('guide-fcl-ctn');
                });
            }]
        })
        .state('guide-fcl-ctn.edit', {
            parent: 'guide-fcl-ctn',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/guide-fcl-ctn/guide-fcl-ctn-dialog.html',
                    controller: 'GuideFclCtnDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['GuideFclCtn', function(GuideFclCtn) {
                            return GuideFclCtn.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('guide-fcl-ctn', null, { reload: 'guide-fcl-ctn' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('pda-express-operation', {
            parent: 'pda-home',
            url: '/express-operation',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'autopsApp.wmsIn.expressOperation'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/guide-fcl-ctn/guide-fcl-ctn-delete-dialog.html',
                    controller: 'GuideFclCtnDeleteController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('expressParcel');                    
                    return $translate.refresh();
                }]
            }
        });

    }

})();
