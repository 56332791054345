(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('VirtualWarehouseDialogController', VirtualWarehouseDialogController);

    VirtualWarehouseDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', '$uibModal', '$translate', 
    'entity', 'VirtualWarehouse', 'OpsCompany'];

    function VirtualWarehouseDialogController ($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, $uibModal, $translate, 
        entity, VirtualWarehouse, OpsCompany) {
        var vm = this;

        var wmsWarehouse = entity;
        vm.clear = clear;
        vm.save = save;
        vm.addOne = addOne;
        vm.deleteOne = deleteOne;
        vm.getCompanyByEmail = getCompanyByEmail;

        vm.virtualWarehouses = [];

        $timeout(function (){
            loadList();
        });
        function loadList () {
            VirtualWarehouse.getByWarehouseId({warehouseId : wmsWarehouse.id}, function(result){
                vm.virtualWarehouses = result;
            });
        }

        function getCompanyByEmail (virtualWarehouse) {    
            if(!virtualWarehouse.emailAddress){
                return;
            }
            OpsCompany.getVenderIdCompanyIdByEmail({
                companyId: wmsWarehouse.companyId,
                email: virtualWarehouse.emailAddress
            }, function(result){
                if(!result.venderId){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.virtualWarehouse.notBindedAlert', {param: result.companyName}));
                }
                else {
                    virtualWarehouse.toCompanyName= result.companyName;
                    virtualWarehouse.toCompanyId = result.companyId;
                    virtualWarehouse.toVenderId = result.venderId;
                }
            })
        };

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save (virtualWarehouse) {
            if(!virtualWarehouse){return;}
            vm.isSaving = true;
            if (virtualWarehouse.id !== null) {
                VirtualWarehouse.update(virtualWarehouse, onSaveSuccess, onSaveError);
            } else {
                VirtualWarehouse.save(virtualWarehouse, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            // $scope.$emit('autopsApp:virtualWarehouseUpdate', result);
            // $uibModalInstance.close(result);
            layer.msg($translate.instant('global.save_success'));
            loadList();
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function addOne () {
            if(!vm.virtualWarehouses){
                vm.virtualWarehouses = [];
            }
            vm.virtualWarehouses.push({
                locked: false,
                warehouseId: wmsWarehouse.id,
                warehouseName: wmsWarehouse.name,
                virtualWarehouseName: wmsWarehouse.name,
                virtualWarehouseAddress: wmsWarehouse.address,
                fromCompanyId: wmsWarehouse.companyId,
                fromCompanyName: $rootScope.account.companyName
            });
        }
        function deleteOne (virtualWarehouse, index) {
            if(!virtualWarehouse.id){
                vm.virtualWarehouses.splice(index, 1);    
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/virtual-warehouse/virtual-warehouse-delete-dialog.html',
                controller: 'VirtualWarehouseDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return virtualWarehouse;
                    }]
                }
            }).result.then(function() {
                vm.virtualWarehouses.splice(index, 1);                
            }, function() {
            });
        }


    }
})();
