(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('PredictionDeleteController',PredictionDeleteController);

    PredictionDeleteController.$inject = ['$uibModalInstance', '$translate', 'entity', 'Prediction'];

    function PredictionDeleteController($uibModalInstance, $translate, entity, Prediction) {
        var vm = this;

        vm.prediction = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete () {
            vm.prediction.status = "released";
            Prediction.update(vm.prediction, function(result) {
                $uibModalInstance.close(result);
                layer.msg($translate.instant('global.save_success'));
            }, function(result) {
                layer.msg($translate.instant('global.save_fail'));
            });
        }

    }
})();
