(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('tariff', {
                parent: 'account',
                url: '/function-list',
                data: {
                    authorities: [],
                    pageTitle: 'home.tariff'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/tariff/tariff.html',
                        controller: 'TariffController',
                    controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        return $translate.refresh();
                    }]
                }
            });
    }
})();