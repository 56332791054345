(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('accountTitle', {
                parent: 'finSettlement',
                url: '/accountTitles',
                data: {
                    authorities: ['ROLE_ACCOUNT'],
                    pageTitle: 'autopsApp.accountTitle.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountTitle/accountTitles.html',
                        controller: 'AccountTitleController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('accountTitle-detail', {
                parent: 'finSettlement',
                url: '/finance/account-details',
                data: {
                    authorities: ['REPORT_LEDGER_ENTRIES'],
                    pageTitle: 'global.menu.entities.sub_ledger'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountTitle/accountTitle-detail.html',
                        controller: 'AccountTitleDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        $translatePartialLoader.addPart('accountVoucher');
                        return $translate.refresh();
                    }]
                }
            })
            .state('accountTitle-subsidiary-detail', {
                parent: 'finSettlement',
                url: '/finance/account-subsidiary-details',
                data: {
                    authorities: ['REPORT_LEDGER_ENTRIES'],
                    pageTitle: 'global.menu.entities.subsidiaryDetail'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountTitle/accountTitle-subsidiary-detail.html',
                        controller: 'AccountSubsidiaryDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        $translatePartialLoader.addPart('accountVoucher');
                        return $translate.refresh();
                    }]
                }
            })
            .state('advance-mgmt', {
                parent: 'finSettlement',
                url: '/financialsettlement/advance-mgmt',
                data: {
                    authorities: ['ROLE_ACCOUNT'],
                    pageTitle: 'global.menu.entities.advance-mgmt'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountTitle/advance-mgmt.html',
                        controller: 'AdvanceMgmtController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        return $translate.refresh();
                    }]
                }
            })
            .state('accountTitle-managementFees', {
                parent: 'finSettlement',
                url: '/financialsettlement/managementFees',
                data: {
                    authorities: ['REPORT_LEDGER_ENTRIES'],
                    pageTitle: 'global.menu.entities.management_fees'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountTitle/management-fees.html',
                        controller: 'ManagementFeesController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        return $translate.refresh();
                    }]
                }
            })

            .state('charges-syn', {
                parent: 'finSettlement',
                url: '/financialsettlement/charges-syn',
                data: {
                    authorities: ['ROLE_ACCOUNT'],
                    pageTitle: 'global.menu.entities.charges-syn'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountTitle/charges-syn.html',
                        controller: 'ChargesSynController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        $translatePartialLoader.addPart('stats');
                        return $translate.refresh();
                    }]
                }
            })
            .state('accountTitle-trialbalance', {
                parent: 'finSettlement',
                url: '/financialsettlement/trialbalance',
                data: {
                    authorities: ['REPORT_TRIAL_BALANCE'],
                    pageTitle: 'global.menu.entities.trialbalance'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountTitle/trial-balance.html',
                        controller: 'TrialBalanceController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        return $translate.refresh();
                    }]
                }
            })
            .state('accountTitle-accounting', {
                parent: 'finSettlement',
                url: '/financialsettlement/accounting',
                data: {
                    authorities: ['ROLE_ACCOUNT'],
                    pageTitle: 'global.menu.entities.accounting'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountTitle/accounting.html',
                        controller: 'AccountingController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        return $translate.refresh();
                    }]
                }
            })
            .state('accountTitle-endingTransfer', {
                parent: 'finSettlement',
                url: '/financialsettlement/endingTransfer',
                data: {
                    authorities: ['ROLE_ACCOUNT'],
                    pageTitle: 'global.menu.entities.endingTransfer'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountTitle/ending-transfer.html',
                        controller: 'EndingTransferController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        return $translate.refresh();
                    }]
                }
            })
            .state('accountTitle-endingClosing', {
                parent: 'finSettlement',
                url: '/financialsettlement/endingClosing',
                data: {
                    authorities: ['ROLE_ACCOUNT'],
                    pageTitle: 'global.menu.entities.endingClosing'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountTitle/ending-closing.html',
                        controller: 'EndingClosingController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        return $translate.refresh();
                    }]
                }
            })
            .state('accountTitle-unaccounting', {
                parent: 'finSettlement',
                url: '/financialsettlement/antiBookkeeping',
                data: {
                    authorities: ['ROLE_ACCOUNT'],
                    pageTitle: 'global.menu.entities.antiBookkeeping'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountTitle/unaccounting.html',
                        controller: 'UnAccountingController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        return $translate.refresh();
                    }]
                }
            })
            .state('accountTitle-unendingTransfer', {
                parent: 'finSettlement',
                url: '/financialsettlement/antiTransfer',
                data: {
                    authorities: ['ROLE_ACCOUNT'],
                    pageTitle: 'global.menu.entities.antiTransfer'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountTitle/unending-transfer.html',
                        controller: 'UnEndingTransferController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        return $translate.refresh();
                    }]
                }
            })
            .state('accountTitle-unendingClosing', {
                parent: 'finSettlement',
                url: '/financialsettlement/antiSettlement',
                data: {
                    authorities: ['ROLE_ACCOUNT'],
                    pageTitle: 'global.menu.entities.antiSettlement'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountTitle/unending-closing.html',
                        controller: 'UnEndingClosingController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        return $translate.refresh();
                    }]
                }
            })
            .state('accountTitle.delete', {
                parent: 'accountTitle',
                url: '/delete',
                data: {
                    authorities: ['ROLE_ACCOUNT'],
                },
                onEnter: ['$rootScope', '$state', '$uibModal', function($rootScope, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/accountant/accountTitle/accountTitle-delete-dialog.html',
                        controller: 'AccountTitleDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['AccountTitle', function(AccountTitle) {
                                if ($rootScope.id){
                                   return {    
                                        code: $rootScope.num, 
                                        id: $rootScope.id
                                        };
                                }
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('accountTitle', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('vouchersSyn', {
                parent: 'finSettlement',
                url: '/vouchersSyn',
                data: {
                    authorities: ['ROLE_ACCOUNT'],
                    pageTitle: 'global.menu.entities.vouchersSyn'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountTitle/vouchers-syn.html',
                        controller: 'VoucherSynController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        return $translate.refresh();
                    }]
                }
            })            
            .state('account-balance', {
                parent: 'finSettlement',
                url: '/account-balance',
                data: {
                    authorities: ['REPORT_ACCOUNT_BALANCE'],
                    pageTitle: 'global.menu.entities.account-balance'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountTitle/account-balance.html',
                        controller: 'AccountBalance'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        return $translate.refresh();
                    }]
                }
            })
            .state('assist-account-balance', {
                parent: 'finSettlement',
                url: '/assist-account-balance',
                data: {
                    authorities: ['REPORT_ACCOUNT_BALANCE'],
                    pageTitle: 'global.menu.entities.assistAccountingBalance'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountTitle/account-assist-balance.html',
                        controller: 'AccountAssistBalanceController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        $translatePartialLoader.addPart('accountVoucher');                        
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
