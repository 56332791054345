(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ExpressTrackingSeaController', ExpressTrackingSeaController);

    ExpressTrackingSeaController.$inject = ['$scope', '$rootScope', '$translate', '$timeout', '$uibModalInstance', '$uibModal',
    'entity', 'ExpressTracking'];

    function ExpressTrackingSeaController ($scope, $rootScope, $translate, $timeout, $uibModalInstance, $uibModal, 
        entity, ExpressTracking) {
        var vm = this;
        vm.moveList = [];
        vm.clear = clear;
        vm.ctnTrackingPop = ctnTrackingPop;

        vm.trackingResult = {};        
        var headNodes = ["ECPU", "GIPOL", "LOAD", "DPOL", "APOD", "DIPOD", "GOPOD", "ECRT"];
        var portNodes = ["DIPOD", "FPAID", "CDPOD", "CTNHD", "RFP", "GOPOD", "LSTFD", "PORTFEE"];
        var customsNodes = ["DIPOD", "BLMATCH", "ISFMATCH", "CUSTOMSRLS", "EXAM", "EXAMTYPE"];
        $timeout(function (){
            vm.trackingResult.headList = [];
            angular.forEach(headNodes, function(node){
                vm.trackingResult.headList.push({node: node, context: "wait"});
            })

            vm.trackingResult.portTrackingList = [];
            angular.forEach(portNodes, function(node){
                vm.trackingResult.portTrackingList.push({node: node, context: "N/A"});
            })

            vm.trackingResult.customsTrackingList = [];
            angular.forEach(customsNodes, function(node){
                vm.trackingResult.customsTrackingList.push({node: node, context: "N/A"});
            })

            if(entity.guideId){
                ExpressTracking.getTrackingResult({
                    guideId: entity.guideId
                }, function(result){
                    vm.trackingResult = result;
                }, $rootScope.ALERT_ERROR);
            }            
        })


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function ctnTrackingPop (result) {
            $uibModal.open({
                templateUrl: 'app/entities/express-tracking/express-tracking-ctn.html',
                controller: 'ExpressTrackingCtnController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return result;
                    }]
                }
            }).result.then(function(result) {
            }, function() {
            });
        }
    }
})();
