(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsGroupDetailController',OpsGroupDetailController);

    OpsGroupDetailController.$inject = ['$uibModalInstance', '$translate', '$rootScope', 'entity'];

    function OpsGroupDetailController($uibModalInstance, $translate, $rootScope, entity) {
        var vm = this;
        vm.entity = entity;
        vm.content = null;
        vm.clear = clear;
        vm.confirm = confirm;
        var isCn = false;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirm () {
            $uibModalInstance.close(vm.content);
        }
        
    }
})();
