(function () {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CustomizeExportDialogController', CustomizeExportDialogController);

    CustomizeExportDialogController.$inject = ['$rootScope', '$scope', '$uibModalInstance', 'entity', 'GridState', '$timeout', '$translate',
        'OpsFiles', 'DataUtils', 'OrderNode'];

    function CustomizeExportDialogController($rootScope, $scope, $uibModalInstance, entity, GridState, $timeout, $translate,
        OpsFiles, DataUtils, OrderNode) {
        var vm = this;

        $scope.gridState = entity;
        vm.clear = clear;
        vm.save = save;
        vm.addHead = addHead;
        vm.delHead = delHead;
        vm.addBody = addBody;
        vm.delBody = delBody;
        vm.changeType = changeType;
        vm.reloadGridState = reloadGridState;
        vm.changeBodyType = changeBodyType;
        vm.showBodyType = showBodyType;
        vm.headFields = [];
        vm.bodyFields = [];
        $scope.files = [];

        function initGridState() {
            if ($scope.gridState.gridState) {
                vm.data = angular.fromJson($scope.gridState.gridState);
            }
            if (!vm.data) {
                vm.data = {};
                vm.data.heads = [];
                vm.data.bodys = [];
            }
            changeType();
            changeBodyType(true);
        }

        $timeout(function () {
            initGridState();
        })

        function reloadGridState(gridStateId) {
            if (!gridStateId) return;
            GridState.getOneCustomExcelImport({ id: gridStateId }, function (result) {
                $scope.gridState = result;
                initGridState();
            }, $rootScope.ALERT_ERROR)
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
        function save() {
            $scope.gridState.gridState = angular.toJson(vm.data);
            if (!$scope.gridState.venderName) {
                $scope.gridState.venderId = null;
            }
            GridState.updateCustomExcelExport($scope.gridState, function (result) {
                layer.msg($translate.instant('global.save_success'));
                reloadGridState(result.id);
            }, $rootScope.ALERT_ERROR)
        }
        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function (date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        function addHead() {
            vm.data.heads.push({
                field: null,
                location: ""
            })
        }

        function delHead(head) {
            var index = vm.data.heads.indexOf(head);
            if (index > -1) {
                vm.data.heads.splice(index, 1);
            };
        }

        function addBody() {
            vm.data.bodys.push({
                field: null,
                location: ""
            })
        }

        function delBody(body) {
            var index = vm.data.bodys.indexOf(body);
            if (index > -1) {
                vm.data.bodys.splice(index, 1);
            };
        }

        // 需要自定义名称的字段。名称填写在i18n autopsApp.gridState.allField 下
        var guideOtherField = ["vgm", "hblRlsWay", "voy", "BKdivVolBy", "BKvw", "carrier", "polName", "podName", "destName", "ataTime", "ctnAvailableTime", "valueCur"];
        var expressParcelsOtherField = ["shipperName", "bookingToName", "mblNum", "soNum", "labelBindType", "updateTask",
            "sku", "length", "width", "height", "fba", "quantity", "usdValuePerUnit", "usdValueTtl", "packageType", "shipfromCode", "shiptoCode",
            "shiptoCompanyName", "shiptoAttentionName", "shiptoPhoneNumber", "shiptoCity", "shiptoPostalCode", "shiptoProvinceCode", "shiptoCountryCode", "shiptoAddressLineOne", "shiptoAddressLineTwo",
            "shiptoAddressLineThree", "shiptoEmailAddress", "cargoValueUsd", "shipperExt", "shiptoExt", "collectCur", "sellCur", "poNumber", "shiptoDoorplate", "ownImporterCode",
            "accountCode", "dutyPayPartyCode", "updateTime", "otherTimeOne", "otherTimeTwo", "offShelfTime", "otherTimeThree", "goodsDiscription", "ctnNum", "location"];
            var expressParcelsOtherField2 = ["salesName", "opName", "cusName", "dispatcher", "storekeeper"];
        var wmsInOtherField = ["handleType", "packageType", "sku", "warehouseName", "palletCapacity", "warehousingNumber", "type", "audited", "pallets", "applicationNum", "gw", "vol", "expectedWarehousingTime"];
        var wmsInIsOutOtherField = ["handleType", "packageType", "sku", "warehouseName", "palletCapacity", "warehousingNumber", "type", "audited", "pallets", "applicationNum", "gw", "vol", "expectedWarehousingTime"];
        // FMS订单有些字段需要区分MBL和HBL
        var FMSMblField = ["porName", "polName", "podName", "destName", "deliveryTo", "serviceTerm", "releaseWay", "freightTerm"];
        var FMSHblField = ["BKporName", "BKpolName", "BKpodName", "BKdestName", "BKdeliveryTo", "BKserviceTerm", "hblRlsWay", "BKfreightTerm"];
        function changeType() {
            vm.headFields = [];
            if ($scope.gridState.gridId == "21" || $scope.gridState.gridId == "22") {
                // FBA、FMS
                vm.headFieldValue = ["jobNum", "shipmentType", "poNum", "fbaRef", "shipmentId", "soNum", "BKsoNum", "hblNum", "mblNum", "etd", "cargoReadyDate", "eta", "vsl", "voy",
                    "carrier", "productName", "deliveryWay", "deliveryChannel", "BKcarrierFullName", "tkState", "cargoType", "pkgNum", "BKpkgType", "gw", "vol", "chargeWeight", "BKdivVolBy",
                    "vw", "BKvolumeShare", "BKpkgNum", "BKgw", "BKvol", "volumes", "whName", "chargeByCbm", "salesName", "customsType", "remarks", "BKremarks", "bizType", "internalType", "customerName",
                    "shipperName", "bookingToName", "deliveryName", "clearanceAgent", "cargoName", "BKhsCode", "pieces", "BKtradeTerm", "cargoValue", "cls", "billDate", "BKetd",
                    "deliveryDate", "freightTerm", "serviceTerm", "refNum", "thirdpartyPlatformRef", "sayCtns", "BKporName", "BKpolName", "BKpodName", "BKdestName", "BKdeliveryTo",
                    "BKimporterName", "BKimporterTaxId", "BKimporterEori", "BKimporterAddress", "BKshipperBl", "BKcneeBl", "BKnotifyBl", "BKdeliveryBl", "BKmark", "BKgoodsDiscription",
                    "deliveryBl", "shipfromCode", "serviceDescription", "shiptoCode", "BKbookingToAttn", "BKhblNum", "destCountryCode", "shiptoPostcode", "BKbookingToEmail", "BKbookingToFax",
                    "BKbookingToAddress", "BKbookingToMob", "BKbookingToTel", "templateName", "customRequired", "createdTime", "status", "checkByName", "checkStatus", "cusName", "overseasCs",
                    "opName", "finName", "bookingBy", "truckBy", "customBy", "lastModifiedTime", "bookingTime", "soReleaseTime", "truckArrangeTime", "customArrangeTime", "siSubmitedTime",
                    "hblConfirmedTime", "mblConfirmedTime", "dnSentTime", "saSentTime", "hblReleasedTime", "mblReleasedTime", "invoiceIssuedTime", "invoiceReceivedTime", "paymentPaidTime",
                    "paymentReceivedTime", "siCutTime", "localTimeTwo", "otherTimeOne", "otherTimeTwo", "otherTimeThree", "vgmTime", "ctnsLoadedTime", "customReleaseTime",
                    "deliveryTime", "customClearTime", "ataTime", "deliveryAppointmentTime", "soExpectedTime", "deliveryGuideTime", "closeTime",
                    "ctnAvailableTime", "ctnReturnTime", "consolidated", "bookingMemo", "checkRemarks", "wmsActAmt", "wmsGw", "wmsVol", "wmsCw", "atd"];
                if ($scope.gridState.gridId == "21") {
                    vm.headFieldValue.push("valueCur");
                }
                if ($scope.gridState.gridId == "22") {
                    vm.headFieldValue.push("lane", "soExpectedTime", "BKwmsNum", "dock", "polRegion", "podRegion", "vgm", "BKserviceTerm", "hblRlsWay", "BKfreightTerm", "awbCharges",
                        "porName", "polName", "podName", "destName", "deliveryTo", "releaseWay");
                }

                angular.forEach(vm.headFieldValue, function (item) {
                    var data = {};
                    data.name = item;
                    if (item == 'templateName') {
                        data.langName = $translate.instant('autopsApp.gridState.allField.templateName');
                    }
                    else if (item == 'chargeByCbm') {
                        data.langName = $translate.instant('autopsApp.guide.chargeByCbm');
                    }
                    else if (guideOtherField.indexOf(data.name) != -1) {
                        data.langName = $translate.instant('autopsApp.gridState.allField.guide.' + data.name);
                    } else {
                        DataUtils.getLangNameFba(data);
                    }
                    if ($scope.gridState.gridId == "22") {
                        if (FMSMblField.indexOf(data.name) != -1) {
                            data.langName = data.langName + "(MBL)";
                        } else if (FMSHblField.indexOf(data.name) != -1) {
                            data.langName = data.langName + "(HBL)";
                        }
                    }
                    vm.headFields.push(data);
                })
                OrderNode.getAllOrderNodes(function(result){
                    angular.forEach(result, function (item) {
                        var data = {
                            name: item,
                            langName: item
                        };
                        vm.headFields.push(data);
                    })
                })
            }
            else if ($scope.gridState.gridId == "23") {
                // 快递小包
                vm.headFieldValue = ["shipmentType", "shipperName", "bookingToName", "jobNum", "innerRemarks", "carrier", "remarks", "salesName", "opName", "cusName", "mblNum", "soNum", "poNum",
                    "goodsDiscription", "etd", "gw", "vol", "cargoType", "hsCode", "pkgNum", "pkgType", "divVolBy", "tradeTerm", "ctnNum", "createdTime", "updateTime",
                    "shiptoCompanyName", "shiptoAttentionName", "shiptoPhoneNumber", "shiptoCity", "shiptoPostalCode", "shiptoProvinceCode", "shiptoCountryCode", "shiptoAddressLineOne", "shiptoAddressLineTwo",
                    "shiptoAddressLineThree", "serviceType", "warehouseName", "payParty", "shipfromCode", "shiptoCode", "shiptoEmailAddress", "channel", "cargoValueUsd", "eta",
                    "dispatcher", "storekeeper", "pickingNum", "dcType", "platform", "shipperExt", "shiptoExt", "inchOz", "collect", "collectCur", "sellAmt", "sellCur", "saleFreight",
                    "platformFee", "thirdpartyPlatform", "isRemote", "onHold", "otherFee", "irregularPacking", "isResidential", "isSuperRemote", "addressModify", "poNumber",
                    "bookingToCode", "insuranceRequired", "shipperBl", "cneeBl", "importerTaxId", "shiptoDoorplate", "bizType", "costChannel", "remotePostalCode", "dutyPayParty", "ownImporterCode", "ownImporter",
                    "accountCode", "dutyPayPartyCode", "otherTimeOne", "otherTimeTwo", "offShelfTime", "otherTimeThree", "customReleaseTime", "deliveryTime"];
                angular.forEach(vm.headFieldValue, function (item) {
                    var data = {};
                    data.name = item;
                    if (expressParcelsOtherField.indexOf(data.name) != -1) {
                        data.langName = $translate.instant('autopsApp.gridState.allField.expressParcel.' + data.name);
                    } else if (expressParcelsOtherField2.indexOf(data.name) != -1) {
                        data.langName = $translate.instant('autopsApp.gridState.allField.expressParcel2.' + data.name);
                    }else {
                        data.langName = $translate.instant('autopsApp.expressParcel.' + data.name);
                    }
                    if (data.langName.startsWith("autopsApp")) {
                        data.langName = $translate.instant('autopsApp.guide.' + data.name);
                    }
                    if (data.langName.startsWith("autopsApp")) {
                        data.langName = $translate.instant('autopsApp.booking.' + data.name);
                    }
                    if (data.langName.startsWith("autopsApp")) {
                        data.langName = $translate.instant('autopsApp.booking.bookingCtns.' + data.name);
                    }
                    vm.headFields.push(data);
                })
            }
            else if ($scope.gridState.gridId == "24" || $scope.gridState.gridId == "25") {
                // 入仓单、出仓单
                vm.headFieldValue = ["warehousingNumber", "clientName", "mark", "cargoDescription", "gw", "vol", "remarks", "pallets", "salesName", "createdTime", "updateTime", "creater",
                    "warehouseName", "podName", "type", "customNum", "poNum", "expectedWarehousingTime", "ctnNum", "truckNum", "handleType", "ctnType", "freeDays", "deliveryBySelf",
                    "status", "audited", "applicationNum"];
                angular.forEach(vm.headFieldValue, function (item) {
                    var data = {};
                    data.name = item;
                    if ($scope.gridState.gridId == "24" && wmsInOtherField.indexOf(data.name) != -1) {
                        data.langName = $translate.instant('autopsApp.gridState.allField.wmsIn.' + data.name);
                    } else if ($scope.gridState.gridId == "25" && wmsInIsOutOtherField.indexOf(data.name) != -1) {
                        data.langName = $translate.instant('autopsApp.gridState.allField.wmsInIsOut.' + data.name);
                    } else {
                        data.langName = $translate.instant('autopsApp.wmsIn.' + data.name);
                    }
                    vm.headFields.push(data);
                })
            }
        }

        function getLangNameBookingCtns(data) {
            var otherField = ["length", "width", "height", "shipmentId", "materialEn", "useAgeEn", "modelEn", "brandEn", "hsCode", "fbaRef", "sku", "idCode"];
            if (otherField.indexOf(data.name) != -1) {
                data.langName = $translate.instant("autopsApp.gridState.allField.bookingCtns." + data.name);
            } else {
                data.langName = $translate.instant("autopsApp.booking.bookingCtns." + data.name);
            }
        }

        var guideRatesOtherFields = ["payer", "isPay"];
        var guideCtnsOtherFields = ["ctnNum", "gw", "vol", "vgm"];
        function changeBodyType(init) {
            if (!init) {
                vm.data.bodys = [];
            }
            vm.bodyFields = [];
            if (vm.data.bodyType == 'bookingCtns') {
                vm.bodyFieldValue = ["ctnType", "gw", "vol", "pkgNum", "pkgType", "length", "width", "height", "cargoName", "model",
                    "hsCode", "fbaRef", "material", "useAge", "pieces", "unitPrice", "totalPrice", "sku",
                    "pallets", "brand", "remarks", "nw", "cargoNameEn", "mark", "dutyRate", "dutyAudited", "shipmentId", "materialEn",
                    "useAgeEn", "modelEn", "brandEn", "importCountryCode", "gwTtl", "volTtl", "productUrl", "idCode", "electric", "magnetic", 
                    "cargoPic", "taxRefund"];
                angular.forEach(vm.bodyFieldValue, function (item) {
                    var data = {};
                    data.name = item;
                    getLangNameBookingCtns(data);
                    vm.bodyFields.push(data);
                })
            }
            else if (vm.data.bodyType == 'guideRates') {
                vm.bodyFieldValue = ["item", "currency", "price", "unit", "count", "total", "actual", "paymentTerm", "inputTime", "inputBy", "invoiceNum", "dnNum", "remarks",
                    "lastModifiedBy", "payer", "writeOffCode", "payCurrency", "payExchange", "payAmount", "days", "billNum",
                    "exchangeRate", "incomeTax", "vat", "taxInPrice", "actualExchangeRate", "hiddenRate", "paidTime", "mcNum", "isPay"];
                angular.forEach(vm.bodyFieldValue, function (item) {
                    var data = {};
                    data.name = item;
                    if (guideRatesOtherFields.indexOf(data.name) != -1) {
                        data.langName = $translate.instant('autopsApp.gridState.allField.guideRates.' + data.name);
                    } else {
                        data.langName = $translate.instant("autopsApp.booking.bookingPrice." + data.name);
                    }
                    vm.bodyFields.push(data);
                })
            }
            else if (vm.data.bodyType == 'warehousingDatas') {
                vm.bodyFieldValue = ["cargoName", "barcode", "remarks", "location", "gw", "vol", "vw", "cw", "length", "width", "height", "gwTtl", "volTtl", "pallets",
                    "pkgNum", "registrationTime", "pkgType", "batchNumber","girth"];
                angular.forEach(vm.bodyFieldValue, function (item) {
                    var data = {};
                    data.name = item;
                    if (data.name === 'girth'){
                        data.langName = $translate.instant("autopsApp.wmsIn." + data.name);
                    }else {
                        data.langName = $translate.instant("autopsApp.warehousingData." + data.name);
                    }
                    vm.bodyFields.push(data);
                })
            }
            else if (vm.data.bodyType == 'guideCtns') {
                vm.bodyFieldValue = ["ctnNum", "ctnType", "sealNum", "pkgs", "pkgType", "gw", "vol", "remarks", "pickAddress", "returnAddress", "truckNum", "driverName", "driverMob", "returnTime", "weighWeight", "vgm"];
                angular.forEach(vm.bodyFieldValue, function (item) {
                    var data = {};
                    data.name = item;
                    if (guideCtnsOtherFields.indexOf(data.name) != -1) {
                        data.langName = $translate.instant('autopsApp.gridState.allField.guideCtns.' + data.name);
                    } else {
                        data.langName = $translate.instant("autopsApp.booking.bookingCtns." + data.name);
                    }
                    vm.bodyFields.push(data);
                })
            }
            else if (vm.data.bodyType == 'expressCargos') {
                vm.bodyFieldValue = ["sku", "cargoName", "packageType", "pkgs", "gw", "vol", "length", "width", "height", "shipmentId",
                    "fba", "pallets", "gwTtl", "volTtl", "remarks", "hsCode", "quantity", "usdValuePerUnit", "usdValueTtl", "originalCountry", "brand", "model", "material", "useAge",
                    "cargoNameEn", "productUrl", "location"];
                angular.forEach(vm.bodyFieldValue, function (item) {
                    var data = {};
                    data.name = item;
                    if (expressParcelsOtherField.indexOf(data.name) != -1) {
                        data.langName = $translate.instant('autopsApp.gridState.allField.expressParcel.' + data.name);
                    } else {
                        data.langName = $translate.instant('autopsApp.expressParcel.' + data.name);
                    }
                    if (data.langName.startsWith("autopsApp")) {
                        data.langName = $translate.instant('autopsApp.guide.' + data.name);
                    }
                    if (data.langName.startsWith("autopsApp")) {
                        data.langName = $translate.instant('autopsApp.booking.' + data.name);
                    }
                    if (data.langName.startsWith("autopsApp")) {
                        data.langName = $translate.instant('autopsApp.booking.bookingCtns.' + data.name);
                    }
                    vm.bodyFields.push(data);
                })
            }
            else if (vm.data.bodyType == 'cargoList') {
                vm.bodyFieldValue = ["gwTtl", "volTtl", "code", "cargoName", "packageType", "gw", "vol", "length", "width", "height", "pkgs",
                    "location", "palletizedAt", "palletCapacity", "remarks", "chargeType", "chargePrice",
                    "batchOne", "batchTwo", "batchThree", "batchFour", "sku", "piece", "pieceUnit", "nw", "capacity", "capacityUnit", "mark", "cargoNameEn"];

                var isPalletField = ["pkgs", "palletCapacity"];
                var pkgMoreField = ["piece", "pieceUnit", "pieceTtl", "capacity", "capacityUnit", "capacityTtl"];
                var batchMoreField = ["batchOne", "batchTwo", "batchThree", "batchFour"];
                angular.forEach(vm.bodyFieldValue, function (item) {
                    var data = {};
                    data.name = item;
                    if (wmsInOtherField.indexOf(data.name) != -1) {
                        data.langName = $translate.instant('autopsApp.gridState.allField.wmsIn.' + data.name);
                    } else {
                        data.langName = $translate.instant('autopsApp.wmsIn.' + data.name);
                    }
                    if (isPalletField.indexOf(data.name) != -1) {
                        data.langName = data.langName + '- 托盘/大包装';
                    } else if (pkgMoreField.indexOf(data.name) != -1) {
                        data.langName = data.langName + '- 小包装';
                    } else if (batchMoreField.indexOf(data.name) != -1) {
                        data.langName = data.langName + '- 批次';
                    }
                    vm.bodyFields.push(data);
                })
            }
        }

        // 设定哪些类型的单有哪些类型的Body数据
        var guideBodyType = ["bookingCtns", "guideRates", "warehousingDatas", "guideCtns"];
        var expressParcelsBodyType = ["expressCargos", "guideRates"];
        var wmsInBodyType = ["cargoList", "guideRates"];
        var wmsInOutBodyType = ["cargoList", "guideRates"];
        function showBodyType(bodyType) {
            if ($scope.gridState.gridId == "21" || $scope.gridState.gridId == "22") {
                if (guideBodyType.indexOf(bodyType) != -1) {
                    return true;
                }
                return false;
            } else if ($scope.gridState.gridId == "23") {
                if (expressParcelsBodyType.indexOf(bodyType) != -1) {
                    return true;
                }
                return false;
            } else if ($scope.gridState.gridId == "24") {
                if (wmsInBodyType.indexOf(bodyType) != -1) {
                    return true;
                }
                return false;
            } else if ($scope.gridState.gridId == "25") {
                if (wmsInOutBodyType.indexOf(bodyType) != -1) {
                    return true;
                }
                return false;
            }
            return false;
        }
    }
})();
