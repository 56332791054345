(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('InvoiceInfoDialogController', InvoiceInfoDialogController);

    InvoiceInfoDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$translatePartialLoader', 'entity', 'InvoiceInfo'];

    function InvoiceInfoDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $translatePartialLoader, entity, InvoiceInfo) {
        var vm = this;
$translatePartialLoader.addPart('guide');
        vm.invoiceInfos = entity;
        vm.clear = clear;
        vm.choosed = choosed;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function choosed (result) {
            $uibModalInstance.close(result);
        }



    }
})();
