(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ClerkManagementDeleteController', ClerkManagementDeleteController);

    ClerkManagementDeleteController.$inject = ['$rootScope', '$scope', '$translate', '$uibModalInstance', 'entity', 'User'];
    
	function ClerkManagementDeleteController($rootScope, $scope, $translate, $uibModalInstance, entity, User) {

        $scope.user = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (login) {
            User.delete({login: login},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }
})();
