(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('pda-in-register', {
            parent: 'pda-home',
            url: '/wms-in-register',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'autopsApp.wmsIn.detail.register'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wms-goods-batch/wms-goods-batches.html',
                    controller: 'WmsGoodsBatchController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wmsIn');
                    return $translate.refresh();
                }]
            }
        })
        .state('pda-out-register', {
            parent: 'pda-home',
            url: '/wms-out-register',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'autopsApp.wmsIn.detail.registerOut'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wms-goods-batch/wms-goods-batch-dialog.html',
                    controller: 'WmsGoodsBatchDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wmsIn');
                    return $translate.refresh();
                }]
            }
        })
        .state('pda-shelve', {
            parent: 'pda-home',
            url: '/shelve',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'autopsApp.wmsIn.moveShelf'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wms-goods-batch/wms-goods-batch-delete-dialog.html',
                    controller: 'WmsGoodsBatchDeleteController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wmsIn');
                    return $translate.refresh();
                }]
            }
        })
        .state('pda-inventory-check', {
            parent: 'pda-home',
            url: '/inventory-check',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'autopsApp.wmsIn.inventoryCheck'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wms-area/wms-area-detail.html',
                    controller: 'WmsAreaDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wmsIn');
                    return $translate.refresh();
                }]
            }
        })
        .state('pda-in-create', {
            parent: 'pda-home',
            url: '/wms-in-create',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'autopsApp.wmsIn.detail.create'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wms-goods-batch/wms-goods-batches-create.html',
                    controller: 'WmsGoodsBatchCreateController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wmsIn');
                    return $translate.refresh();
                }]
            }
        });
    }

})();
