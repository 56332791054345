(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AutoCheckController', AutoCheckController);

    AutoCheckController.$inject = ['$timeout', '$rootScope', '$scope', '$state', '$location', '$translate', '$uibModal', '$http',
    'AutoCheck', 'AutoCheckSearch', 'ParseLinks', 'WinAES', 'AlertService', 'pagingParams', 'paginationConstants', 'OpsFiles'];

    function AutoCheckController ($timeout, $rootScope, $scope, $state, $location, $translate, $uibModal, $http,
        AutoCheck, AutoCheckSearch, ParseLinks, WinAES, AlertService, pagingParams, paginationConstants, OpsFiles) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.deleteCheck = deleteCheck;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.isAdvanceSearch = false;

        if($rootScope.exClosed != true && $rootScope.exClosed != false){
            $rootScope.exClosed = true;
        }

        var path = $location.path();
        vm.isAuto = true;
        vm.parcelCheck = false;
        if (path.indexOf("parcel") >= 0){
            vm.parcelCheck = true;
        }

        if (path.indexOf("monthly") >= 0){
            vm.isAuto = false;
        }
        else if (path.indexOf("receipt") >= 0){
            vm.isAuto = null;
        }
        loadAll();
        function loadAll () {
            if (pagingParams.search) {
                AutoCheckSearch.query({
                    type: vm.isAuto,
                    parcelCheck: vm.parcelCheck,
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                AutoCheck.query({
                    type: vm.isAuto,
                    parcelCheck: vm.parcelCheck,
                    exClosed: $rootScope.exClosed,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.autoChecks = data;
                vm.page = pagingParams.page;
                angular.forEach(vm.autoChecks, function(data){
                    data.encodeId = WinAES.encode(data.id.toString())
                })

            }
            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = true;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {

            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.isAdvanceSearch = false;
            vm.transition();
        }

        $scope.exClosedChange = function(exClosed) {
            $rootScope.exClosed = exClosed;
            loadAll();
        }

        $scope.createDN = function(autoCheck) {
            if(autoCheck.groupCheck){
                layer.msg($translate.instant('autopsApp.autoCheck.notSupportAlert'));
                return;
            }
            AutoCheck.get({id : autoCheck.id}, function(result){
                $rootScope.AUTOCHECK = result;
                $state.go('monthly-payment');
            });
        }

        $scope.fastWriteOff = function(autoCheck, byDn) {
            if(autoCheck.groupCheck){
                layer.msg($translate.instant('autopsApp.autoCheck.notSupportAlert'));
                return;
            }
            var entty = {byDn: byDn};
            AutoCheck.get({id : autoCheck.id}, function(result){
                entty.autoCheck = result;
                $rootScope.WRITE_OFF_POP(entty);
            });
        }
        $scope.writeOff = function(autoCheck) {
            if(autoCheck.groupCheck){
                layer.msg($translate.instant('autopsApp.autoCheck.notSupportAlert'));
                return;
            }
            AutoCheck.get({id : autoCheck.id}, function(result){
                var entty = {};
                entty.autoCheck = result;
                $uibModal.open({
                    templateUrl: 'app/entities/accountant/accountant/com-write-off.html',
                    controller: 'ComWriteOffController',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: [function() {
                            return entty;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('accountInvoice');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function(results) {}, function() {});
            });
        }


        $scope.dnCnCombine = function(autoCheck) {
            if(autoCheck.groupCheck){
                layer.msg($translate.instant('autopsApp.autoCheck.notSupportAlert'));
                return;
            }
            // AutoCheck.get({id : autoCheck.id}, function(result){
                $rootScope.AUTOCHECK = autoCheck;
                $state.go('dn-cn-combine');
            // });
        }

        $scope.batchInvoice = function(autoCheck) {
            if(autoCheck.groupCheck){
                layer.msg($translate.instant('autopsApp.autoCheck.notSupportAlert'));
                return;
            }
            // AutoCheck.get({id : autoCheck.id}, function(result){
                $rootScope.AUTOCHECK = autoCheck;
                $state.go('batch-invoice');
            // });
        }

        $timeout(function (){
            $rootScope.RESIZE();
        });

        $scope.ratesSubmit = function(autoCheck) {
            var entity = angular.copy(autoCheck);
            entity.isPay = null;
            entity.isAutoCheck = true;
            $uibModal.open({
                templateUrl: 'app/entities/guideRates/batchRatesSubmit.html',
                controller: 'BatchRatesSubmitController',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
            }, function(result) {
            });
        }

        function onSaveSuccess (result) {
            layer.msg($translate.instant('global.messages.submitted'));
        }
        function onSaveError (result) {
            layer.msg($translate.instant('global.messages.submittedfailed'));
        }
        var memoCopy;
        $scope.copyMemo = function(guide) {
            memoCopy = angular.copy(guide.memo);
        }
        $scope.saveMemo = function(autoCheck) {
            if(memoCopy != autoCheck.memo){
                AutoCheck.upateMemo({
                    memoString: autoCheck.memo,
                    id: autoCheck.id
                }, onSaveSuccess, onSaveError);
            }
        }

        $scope.files = new Array();
        $scope.loadFiles = function (autoCheck){
            if(autoCheck.files){
                return;
            }
            if (autoCheck.id) {
                var filePath = "/" + "autoCheck/" + autoCheck.id + "/";
                OpsFiles.getFilesList({
                    filepath: filePath,
                    companyId: $rootScope.account.companyId
                },function (data){
                    autoCheck.files = new Array();
                    if(data.length>0){
                        for(var i = 0 ; i < data.length; i++){
                            var temp  = new Object();
                            temp['filepath'] = data[i]['filepath'];
                            temp['filename'] = data[i]['filename'];
                            temp['fileurl'] = data[i]['fileurl'];
                            temp['fileist'] = true;
                            autoCheck.files.push(temp);
                        }
                    }
                });
            }
        }

        var reloadFiles = function(autoCheck, file) {
            if (!angular.isDefined(file.filepath)) {
                return;
            }
            var exist = false;
            if(!autoCheck.files){
                autoCheck.files = [];
            }
            if (autoCheck.files.length > 0) {
                for (var i = 0; i < autoCheck.files.length; i++) {
                    if (autoCheck.files[i]['filename'] == file['filename']) {
                        autoCheck.files[i]['filepath'] = file['filepath'];
                        autoCheck.files[i]['filename'] = file['filename'];
                        autoCheck.files[i]['fileurl'] = file['fileurl'];
                        exist = true;
                        break;
                    }
                }
            }
            if (!exist) {
                var temp = new Object();
                temp['filepath'] = file['filepath'];
                temp['filename'] = file['filename'];
                temp['fileurl'] = file['fileurl'];
                temp['fileist'] = true;
                autoCheck.files.push(temp);
            }
        }


        $scope.uploadFile = function(file, autoCheck, isSlip) {
            if(!autoCheck.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if (!file) {
                return;
            }
            var fd = new FormData();
            var filePath = "/" + "autoCheck/" + autoCheck.id+ "/";
            fd.append('filepath', filePath);
            fd.append('file', file);
            fd.append('companyId', $rootScope.account.companyId);
            $http.post(
                'api/ops-files/uploadFile', fd, {
                    transformRequest : angular.identity,
                    headers : {
                        'Content-Type' : undefined
                    },
                }).success(
                function(data) {
                    if (angular.isDefined(data.filepath)) {
                        reloadFiles(autoCheck, data);
                        layer.msg($translate.instant('global.uploadSuccess'));
                    }
                    if(isSlip){
                        $scope.updateStatus(autoCheck, 'slipUploaded', true);
                    }
                }).error(function(error) {
                    layer.msg($translate.instant('global.uploadFail'));
            });
        };

        $scope.updateStatus = function(autoCheck, fd, value) {
            if((fd == "writedOff" || fd == "jobDone")
                && $rootScope.account.authorities.indexOf("ROLE_ACCOUNT") == -1
                && $rootScope.account.authorities.indexOf("ROLE_CASHIER") == -1){
                layer.msg($translate.instant('global.forbiddenAccount'));
                return;
            }
            var entity = angular.copy(autoCheck);
            entity[fd] = value;
            AutoCheck.updateStatus(entity, function(result){
                autoCheck.jobDone = result.jobDone;
                autoCheck.submited = result.submited;
                autoCheck.slipUploaded = result.slipUploaded;
                autoCheck.writedOff = result.writedOff;
            }, onSaveError);
        }

        $scope.currentAccount = function(autoCheck) {
            if ($rootScope.HAVENOAUTH('ROLE_ACCOUNT')) {
                $rootScope.FORBIDDEN_BY_ALERT('ROLE_ACCOUNT');
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/auto-check/auto-check-current-dialog.html',
                controller: 'AutoCheckCurrentDialogController',
                backdrop: false,
                controllerAs: 'vm',
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return angular.copy(autoCheck);
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('guide');
                        $translatePartialLoader.addPart('booking');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
            }, function(result) {
            });
        }

        function deleteCheck (autoCheck) {
            if (autoCheck.submited && $rootScope.HAVENOAUTH('ROLE_ACCOUNT')) {
                $rootScope.FORBIDDEN_BY_ALERT('ROLE_ACCOUNT');
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/auto-check/auto-check-delete-dialog.html',
                controller: 'AutoCheckDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return autoCheck;
                    }]
                }
            }).result.then(function() {
                loadAll();
            }, function() {
            });
        }
        $scope.confirmChecking = function(choosedCheck) {
            $scope.isSaving = true;
            AutoCheck.confirmChecking(choosedCheck, function(result) {
                $scope.isSaving = false;
                layer.msg($translate.instant('autopsApp.autoCheck.confirmedAlert'));
                choosedCheck.clientChecked = result.clientChecked;
            }, function(response) {
                $scope.isSaving = false;
            });
        }

        $scope.chargeOrRefund = function(autoCheck, isCharge) {
            if(isCharge && $rootScope.HAVENOAUTH('AU_DEDUCT_FEE')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_DEDUCT_FEE');
                return;
            }
            if(!isCharge && $rootScope.HAVENOAUTH('AU_REFUND_FEE')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_REFUND_FEE');
                return;
            }
            var content = $translate.instant('autopsApp.guide.chargeRefundAlert');
            if(isCharge){
                content = $translate.instant('autopsApp.guide.chargeDeductionAlert');
            }
            layer.confirm(content, {
                title: "Msg",
                icon:3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                $scope.isSaving = true;
                AutoCheck.chargeOrRefund({
                    isCharge: isCharge
                }, autoCheck, function(result){
                    $scope.isSaving = false;
                    if(result){
                        $rootScope.OPS_ALERT($translate.instant('global.messages.depositeBal', {currency: $rootScope.account.baseCurrency, balance: result.deposite}), {icon:0});
                    }
                    else{
                        layer.msg($translate.instant('global.messages.submitted'));
                    }
                }, function(result){
                    $scope.isSaving = false;
                    if (result.status == 403) {
                        $rootScope.OPS_ALERT($translate.instant('global.messages.noDeposite'), {icon:0});
                    } else {
                        layer.msg($translate.instant('global.messages.submittedfailed'));
                    }
                })
                layer.close(index);
            }, function() {});
        }
        $scope.searchRequestPop = function(){
            var searchParams = {};
            $uibModal.open({
                templateUrl: 'app/entities/auto-check/auto-checks-search.html',
                controller: 'AutoChecksSearchController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function () {
                        return searchParams;
                    }]
                }
            }).result.then(function (results) {
                vm.autoChecks = results;
                vm.isAdvanceSearch = vm.currentSearch = true;
                angular.forEach(vm.autoChecks, function(data){
                    data.encodeId = WinAES.encode(data.id.toString())
                })
                $scope.totalItems = vm.autoChecks.length;
            }, function () {
            });
        }
    }
})();
