(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('DataSubscription', DataSubscription);

    DataSubscription.$inject = ['$resource', 'DateUtils'];

    function DataSubscription ($resource, DateUtils) {
        var resourceUrl =  'api/data-subscriptions/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.subscriptionTime = DateUtils.convertDateTimeFromServer(data.subscriptionTime);
                    }
                    return data;
                }
            },
            'getGuideDataSubscription':{                
                url:'api/data-subscriptions/getGuideDataSubscription',
                method:'POST',
                isArray:true
            }, 
            'doDataSubscription':{                
                url:'api/data-subscriptions/doDataSubscription',
                method:'POST'
            },          
            'changeHold':{                
                url:'api/data-subscriptions/changeHold',
                method:'GET'
            },
            'update': { method:'PUT' }
        });
    }
})();
