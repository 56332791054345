(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GridStateDetailController', GridStateDetailController);

    GridStateDetailController.$inject = ['$scope', '$uibModalInstance', 'entity', 'DateUtils'];

    function GridStateDetailController ($scope, $uibModalInstance, entity, DateUtils) {
        var vm = this;

        vm.guide = entity;
        vm.guide.cls = DateUtils.convertDateTimeFromServer(vm.guide.cls);
        vm.guide.eta = DateUtils.convertLocalDateFromServer(vm.guide.eta);
        vm.guide.etd = DateUtils.convertLocalDateFromServer(vm.guide.etd);
        vm.guide.siCutTime = DateUtils.convertDateTimeFromServer(vm.guide.siCutTime);
        vm.guide.vgm = DateUtils.convertDateTimeFromServer(vm.guide.vgm);
        vm.guide.feederEtd = DateUtils.convertLocalDateFromServer(vm.guide.feederEtd);


        vm.clear = clear;
        vm.save = save;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function save () {
            $uibModalInstance.close(vm.guide);
        }
        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }
    }
})();