
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OaApplicationDetailController', OaApplicationDetailController);

    OaApplicationDetailController.$inject = ['$timeout', '$rootScope', '$scope', '$stateParams', '$uibModalInstance', '$uibModal', '$translate', '$http', 
    'entity', 'OaApplication', 'AccountInvoice', 'AccountPayment', 'Guide'];

    function OaApplicationDetailController ($timeout, $rootScope, $scope, $stateParams, $uibModalInstance, $uibModal, $translate, $http, 
        entity, OaApplication, AccountInvoice, AccountPayment, Guide) {
        var vm = this;

        vm.oaApplications = [];
        vm.clear = clear;
        vm.save = save;
        vm.addOne = addOne;
        vm.refreshShipmentOnHold = refreshShipmentOnHold;
        vm.shipment = {};
        vm.oaApplication = {};
        vm.instruction = "";
        $scope.files = [];

        $timeout(function (){
            loadList();
            if (entity.guide.id){
                vm.shipment = entity.guide;
            }
            else if(entity.parcel.id){
                vm.shipment = entity.parcel;
            }
            refreshShipmentOnHold();
        });
        function loadList () {
            OaApplication.getExceptions({
                parcelId: entity.parcel.id,
                guideId: entity.guide.id
            }, function(results){
                vm.oaApplications = results;
            })
        }

        vm.exceptionTypes =["欠费","偏远","带电池","仿牌","渠道未确认","少件数","外箱包装原木", "清关超时"]

        $scope.idFilter = function(item) {
            if(item.id){
                return true;
            }
            return false;
        }
        $scope.chooseOne = function(item) {
            vm.oaApplication = item;
        }
        $scope.setApproved = function(value) {
            vm.oaApplication.approved = value;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function addOne () {
            vm.oaApplication = {
                parcelId: entity.parcel.id,
                guideId: entity.guide.id,
                approved: false,
                refNum: vm.shipment.jobNum
            };
            vm.oaApplications.push(vm.oaApplication);
        }

        function save () {
            vm.isSaving = true;
            vm.oaApplication.instruction = vm.instruction;
            if(!vm.oaApplication.superior){
                vm.oaApplication.superiorId = null;
            }
            OaApplication.saveException(vm.oaApplication, onSaveSuccess, onSaveError);
        }

        function refreshShipmentOnHold () {
            vm.isSaving = true;
            var oaApplication = {
                parcelId: entity.parcel.id,
                guideId: entity.guide.id
            };
            OaApplication.refreshShipmentOnHold(oaApplication, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            loadList();
            vm.oaApplication = result;
            vm.instruction = "";
            layer.msg($translate.instant('global.messages.submitted'));
            vm.isSaving = false;
            if(result.guideId){
                Guide.getGuideDTO({id: result.guideId}, function(result){
                    $scope.$emit('autopsApp:guideUpdate', result);
                })
            }
        }

        function onSaveError (error) {
            vm.isSaving = false;
            if(error.status == 409){
                layer.msg($translate.instant('global.refreshAlert'));
            }
            else{
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }            
        }

        $scope._onDataSelected = function(data, fd) {
            vm.oaApplication[fd] = data.id;
        }


    }
})();
