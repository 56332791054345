(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsMapController', WmsMapController);

    WmsMapController.$inject = ['$timeout', '$scope', '$rootScope', '$http', '$uibModalInstance', '$translate', '$uibModal', 
    'entity', 'WmsWarehouse'];

    function WmsMapController ($timeout, $scope, $rootScope, $http, $uibModalInstance, $translate, $uibModal, 
        entity, WmsWarehouse) {
        var vm = this;
        vm.clear = clear;
        vm.clientSelected = clientSelected;
        vm.onAddressSelected = onAddressSelected;
        vm.getWarehouses = getWarehouses;
        vm.warehouseSelected = warehouseSelected;
        vm.accountBind = entity;
        $timeout(function (){            
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function clientSelected (client) {
            vm.accountBind.venderId = client.id;
            vm.accountBind.userId = client.userId;
        }
        function onAddressSelected (data, fd) {
            vm.accountBind[fd] = data.id;
        }

        vm.wmswarehouses = [];
        function getWarehouses () {
            if(vm.wmswarehouses.length > 0){
                return;
            }
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false
                }, function (data) {
                vm.wmswarehouses = data;
            });
        }

        function warehouseSelected (data) {
            vm.accountBind.warehouseId = data.id;
        }


        $scope.importOneFbaOrder = function (file) {
            if (!file) {return;}    
            layer.load(1, {shade: 0.3});
            var url = "api/guide-import/importOneFbaOrder";
            var fd = new FormData();
            fd.append('file', file);
            if(vm.accountBind.type){
                fd.append('shipmentType', vm.accountBind.type);
            }
            if(vm.accountBind.venderId){
                fd.append('customerId', vm.accountBind.venderId);
            }
            if(vm.accountBind.addressId){
                fd.append('shiperId', vm.accountBind.addressId);
            }
            if(vm.accountBind.payeeId){
                fd.append('coloaderId', vm.accountBind.payeeId);
            }
            if(vm.accountBind.thirdPartId){
                fd.append('agentId', vm.accountBind.thirdPartId);
            }
            if(vm.accountBind.warehouseId){
                fd.append('warehouseId', vm.accountBind.warehouseId);
            }
            if(vm.accountBind.warehouseName){
                fd.append('warehouseName', vm.accountBind.warehouseName);
            }
            if(vm.accountBind.thirdPartIdFd){
                fd.append('clearanceAgentId', vm.accountBind.thirdPartIdFd);
            }
            
            $http.post(url, fd, {
                    transformRequest: angular.identity,
                    // responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {   
                    layer.closeAll();
                    $scope.$emit('autopsApp:loadAllRequred');  
                    clear();
                }).error(function(error) {
                    layer.closeAll();
                    if(error && error.description){
                        $rootScope.OPS_ALERT(error.description);
                    }
                    else {
                        $rootScope.OPS_ALERT($translate.instant('global.importFailed'));     
                    }                                          
                });
        };



    }
})();
