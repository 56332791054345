(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AsyncTaskDeleteController',AsyncTaskDeleteController);

    AsyncTaskDeleteController.$inject = ['$uibModalInstance', 'entity', 'AsyncTask', '$rootScope', '$translate'];

    function AsyncTaskDeleteController($uibModalInstance, entity, AsyncTask, $rootScope, $translate) {
        var vm = this;

        vm.asyncTask = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AsyncTask.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                    layer.msg($translate.instant('global.delete_success'));
                }, $rootScope.ALERT_ERROR);
        }
    }
})();
