(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('InvoiceRegistDialogController', InvoiceRegistDialogController);

    InvoiceRegistDialogController.$inject = ['$uibModalInstance', '$uibModal', '$translate', '$scope', '$timeout', 'entity', 'AccountInvoice'];

    function InvoiceRegistDialogController ($uibModalInstance, $uibModal, $translate, $scope, $timeout, entity, AccountInvoice) {
        var vm = this;    
        vm.registerRequest = entity;    
        vm.registerRequest.tenantName = vm.registerRequest.companyName;
        vm.registerRequest.keyPwd = vm.registerRequest.taxDiskKey;
        vm.registerRequest.taxDisxPwd = vm.registerRequest.taxDiskPassword;
        vm.registerRequest.organizationTax = vm.registerRequest.taxId;

        // vm.registerRequest.tenantName = "深圳市盐田区比尔网络科技开发服务中心";
        // vm.registerRequest.organizationType = "1";//1纳税主体 2非纳税主体
        // vm.registerRequest.organizationTax = "92440300MA5FGDJF66";
        // vm.registerRequest.taxQualification = "2";//1一般纳税人 2 小规模纳税人；纳税主体机构必填
        // vm.registerRequest.legalRepresentative = "吴燕燕";
        // vm.registerRequest.contactInformation = "13691933392";
        // vm.registerRequest.registeredAddress = "深圳市盐田区盐田街道泊郡雅苑B栋11楼E室";
        // vm.registerRequest.openingBank = "中国工商银行盐田港区支行";
        // vm.registerRequest.accountNo = "6232604000002512674";
        // vm.registerRequest.subordinateProvince = "广东省";
        // vm.registerRequest.taxDiscNo = "661900108445";
        // vm.registerRequest.taxDisxPwd = "12345678";
        // vm.registerRequest.keyPwd = "88888888";
        // vm.registerRequest.terminalSign = "KP19";

        vm.isSaving = false;

        vm.clear = clear;
        vm.submit = submit;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function submit () {
            if(!initSuccess){
                layer.msg('未初始化', {
                    icon: 0
                }); 
                return;
            }
            vm.isSaving = true;
            AccountInvoice.registNewCompany({
                drawer: vm.registerRequest.drawer,
                checker: vm.registerRequest.checker,
                payee: vm.registerRequest.payee,
                companyId: entity.id,
                login: entity.createdBy
            }, vm.registerRequest, function(result){
                vm.isSaving = false;
                layer.msg($translate.instant("global.messages.submitted")); 
                $uibModalInstance.dismiss('cancel');
            }, function(error){
                vm.isSaving = false;
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                } 
                else {
                    layer.msg($translate.instant("global.messages.submittedfailed")); 
                }                  
            })
        }

        $timeout(function (){
            initAssistant();
        });
        var initSuccess = false;
        function initAssistant () {
            layer.msg('初始化开票组件。。', {
                time: 5000
            }); 
            vm.isSaving = true;
            AccountInvoice.register(function(result){
                initSuccess = true;
                vm.isSaving = false;
                layer.closeAll();
            }, function(error){
                initSuccess = false;
                vm.isSaving = false;
                layer.msg('初始化失败，服务未开通', {
                    icon: 0
                }); 

            })
        }  

    }
})();
