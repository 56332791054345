(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsClientDetailController', OpsClientDetailController);

    OpsClientDetailController.$inject = ['$http', '$timeout', '$scope', '$rootScope', '$state', '$location', '$uibModal', '$translate', '$uibModalInstance', 
    'OpsPorts', 'Principal', 'entity', 'AccountInvoice', 'OpsVender','OpsFiles','OpsFactory', 'OpsConcernLane', 'OpsCrm', 'Clerks', 'OpsShipper'];
    
    function OpsClientDetailController($http, $timeout, $scope, $rootScope, $state, $location, $uibModal, $translate, $uibModalInstance, 
        OpsPorts, Principal, entity, AccountInvoice, OpsVender, OpsFiles, OpsFactory, OpsConcernLane, OpsCrm, Clerks, OpsShipper) {
        
        if (!$rootScope.account || $rootScope.account == {}){
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };

        $scope.opsClient = entity;   
        $scope.files = [];   
        var clientCopy = {};       

        $timeout(function (){
            if (!$scope.opsClient.contactList || $scope.opsClient.contactList.length == 0) {
                $scope.opsClient.contactList = [{}];
            }          
            $scope.addCrm();
            clientCopy = angular.copy($scope.opsClient);
        });

        $scope.shipmentsReport = function () {
            if ($rootScope.account.companyId !=  $scope.opsClient.companyId && $scope.opsClient.companyId){
                layer.msg($translate.instant('autopsApp.opsClient.groupClientAlert'));
                return;
            }   
            if (!angular.equals($scope.opsClient, clientCopy)) {
                layer.confirm($translate.instant('global.notSavedAlert'), {
                    btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
                }, function(index) {
                    $rootScope.id = $scope.opsClient;
                    $state.go('profit');
                    layer.close(index);
                    $uibModalInstance.dismiss('cancel');                    
                }, function() {});
                return;
            }
            $rootScope.id = $scope.opsClient;
            $state.go('profit');
            $uibModalInstance.dismiss('cancel');
        }

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function (date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        $scope.getAllFirstLetter = function () {    
            if (!$scope.opsClient.companyName || $scope.opsClient.code){
                return;
            }
            OpsVender.getAllFirstLetter({
                inputStr: $scope.opsClient.companyName
            }, function(result){
                $scope.opsClient.code = result.reultStr;
            })
        }
        $scope.addConcernLane = function () {
            $scope.opsClient.opsConcernLanes.push({});
        }
        $scope.delConcernLane = function(lane) {
            if ($rootScope.account.companyId !=  $scope.opsClient.companyId && $scope.opsClient.companyId){
                layer.msg($translate.instant('autopsApp.opsClient.groupClientAlert'));
                return;
            }   
            if (lane.id) {
                OpsConcernLane.delete({
                    id: lane.id
                });
            };
            var index = $scope.opsClient.opsConcernLanes.indexOf(lane);
            if (index > -1) {
                $scope.opsClient.opsConcernLanes.splice(index, 1);
            };
        };

        $scope.addCrm = function () {
            var isFirst = true;
            angular.forEach($scope.opsClient.opsCrms, function(data){
                if(!data.id){
                    isFirst = false;
                }
            })
            if(isFirst){
                $scope.opsClient.opsCrms.push({nextRequired:true});
            }            
        }

        $scope.delCrm = function(crm) {
            if ($rootScope.account.companyId !=  $scope.opsClient.companyId && $scope.opsClient.companyId){
                layer.msg($translate.instant('autopsApp.opsClient.groupClientAlert'));
                return;
            }   
            if (crm.id) {
                OpsCrm.delete({
                    id: crm.id
                });
            };
            var index = $scope.opsClient.opsCrms.indexOf(crm);
            if (index > -1) {
                $scope.opsClient.opsCrms.splice(index, 1);
            };
        };


        $scope.setBizVol = function (vol) {
            $scope.opsClient.bizVol = vol;
        }

        $scope.setSaleSchedule = function (saleSchedule) {
            $scope.opsClient.saleSchedule = saleSchedule;
        }
        var loadPorts = function(type) {
            if ($rootScope.OPSPORTS.airports && $rootScope.OPSPORTS.airports.length > 0){
                return;
            }
            OpsPorts.getAll(function(result){
                $rootScope.OPSPORTS.airports = [];
                $rootScope.OPSPORTS.seaports = [];
                angular.forEach(result, function(data){
                    if(data.type == 1){
                        $rootScope.OPSPORTS.seaports.push(data);
                    }
                    else {
                        $rootScope.OPSPORTS.airports.push(data);
                    }
                })
                $scope.shipmentTypeChange(type);
            })
        };

        $scope.shipmentTypeChange = function (type){
            if ($rootScope.OPSPORTS.airports && $rootScope.OPSPORTS.airports.length > 0){
                if(type == 3 || type == 4){
                    $scope.portList = $rootScope.OPSPORTS.airports;
                }
                else{
                    $scope.portList = $rootScope.OPSPORTS.seaports;
                }
            }
            else {
                loadPorts(type);
            }
        }

        $scope._selected = function ($item, which, lane){
            if(which == 'pol'){
                lane.polId = $item.code;
            }
            if(which == 'pod'){
                lane.podId = $item.code;
            }
        }

        var path = $location.path();

        if (path.indexOf("new") !== -1){
            $scope.showDemail = true;
        }

        $scope.upperCode = function(code) {
            $scope.opsClient.code = angular.uppercase(code);
        };

        $scope.setNextRequired = function(opsCrm) {
            opsCrm.nextRequired = true;
            opsCrm.cleared = false;
        };

        $scope.setCleared = function(opsCrm) {
            opsCrm.nextRequired = false;
            opsCrm.cleared = true;
        };
        var onSaveSuccess = function (result) {
            $scope.isSaving = false;
            $scope.opsClient = result;   
            clientCopy = angular.copy($scope.opsClient);
            layer.msg($translate.instant('global.save_success'));
            $scope.$emit('autopsApp:opsVenderUpdate', clientCopy);
        };

        $scope.save = function () {
            if (angular.equals($scope.opsClient, clientCopy)){
                layer.msg($translate.instant('global.no_amendmend'));
                return;
            }
            var myreg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
            for (var i = 0; i < $scope.opsClient.contactList.length; i++) {
                if(!$scope.opsClient.contactList[i].attn){
                    layer.msg($translate.instant('autopsApp.opsClient.attnNullAlert'));
                    return;
                }
                if ($scope.opsClient.contactList[i].email) {
                    $scope.opsClient.contactList[i].email = $scope.opsClient.contactList[i].email.replace(/\s+/g, "");
                    if (!myreg.test($scope.opsClient.contactList[i].email)) {
                        var alertContent = $scope.opsClient.contactList[i].email + ": " + $translate.instant('global.messages.validate.email.invalid');
                        $rootScope.OPS_ALERT(alertContent);
                        return;
                    }
                }
                if ($scope.opsClient.contactList[i].cc) {
                    var ccArray = $scope.opsClient.contactList[i].cc.split(";");
                    for (var i = 0; i < ccArray.length; i++) {
                        ccArray[i] = ccArray[i].replace(/\s+/g, "");
                        if (!myreg.test(ccArray[i]) && ccArray[i] != "") {
                            var alertContent = ccArray[i] + ": " + $translate.instant('global.messages.validate.email.invalid');
                            $rootScope.OPS_ALERT(alertContent);
                            return;
                        }
                    }
                }
            }
            var i = -1;
            if($scope.opsClient.opsCrms && $scope.opsClient.opsCrms.length > 0){
                i = $scope.opsClient.opsCrms.length - 1;
            }            
            if (i >= 0 && !$scope.opsClient.opsCrms[i].action && !$scope.opsClient.opsCrms[i].id){
                $scope.opsClient.opsCrms.splice(i, 1);                
            }         
            else if (i >= 0){
                if (!$scope.opsClient.opsCrms[i].nextRequired && !$scope.opsClient.opsCrms[i].cleared){
                    layer.msg($translate.instant('autopsApp.opsClient.chooseNextTimeMsg'));
                    return;
                }
                if ($scope.opsClient.opsCrms[i].nextRequired && !$scope.opsClient.opsCrms[i].nextTime ){
                    layer.msg($translate.instant('autopsApp.opsClient.chooseNextTimeMsg'));
                    return;
                }
                $scope.opsClient.opsCrms[i].companyId = $scope.opsClient.companyId;

                if (!$scope.opsClient.creater){
                    if($scope.opsClient.createdBy == $rootScope.account.id){
                        layer.msg($translate.instant('autopsApp.opsClient.getBackForbid'));
                        return;
                    }
                    $scope.opsClient.createdBy = $rootScope.account.id; 
                    $scope.opsClient.branch = $rootScope.account.branch; 
                    $scope.opsClient.team = $rootScope.account.team; 
                    $scope.opsClient.creater = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                    $scope.opsClient.checkDate = new Date();
                }

                $scope.opsClient.opsCrms[i].actionTime = new Date();
                if ($scope.opsClient.opsCrms[i].cleared){
                    $scope.opsClient.nextTime = null;
                    $scope.opsClient.nextAction = "THE END";
                    $scope.opsClient.creater = null;
                }
                else {
                    $scope.opsClient.nextTime = $scope.opsClient.opsCrms[i].nextTime;
                    $scope.opsClient.nextAction = $scope.opsClient.opsCrms[i].nextAction;
                }
            } 

            for (var i = $scope.opsClient.opsConcernLanes.length - 1; i>=0 ; i--){
                $scope.opsClient.opsConcernLanes[i].companyId = $rootScope.account.companyId; 
            }
            if ($scope.opsClient.opsCrms.length == 0){
                $scope.opsClient.opsCrms.push({});
                layer.msg($translate.instant('autopsApp.opsClient.noCrmAlert'));
                return;
            }
            if ($scope.opsClient.saleSchedule == "100"){
                $scope.opsClient.cooperated = true;
            }
            $scope.isSaving = true;
            if ($scope.opsClient.id != null) {
                OpsVender.update($scope.opsClient, onSaveSuccess, onSaveError);
            } else {
                $scope.opsClient.createdBy = $rootScope.account.id; 
                OpsVender.save($scope.opsClient, onSaveSuccess, onSaveError);
            }
        };
        var onSaveError = function (error) {
            $scope.isSaving = false;
            $rootScope.ALERT_ERROR(error);            
        };

        $scope.codeUpper = function(){
            $scope.opsClient.code = angular.uppercase($scope.opsClient.code);
        }
        

        $scope.goBack = function() {
            if (!angular.equals($scope.opsClient, clientCopy)) {
                layer.confirm($translate.instant('global.notSavedAlert'), {
                    btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
                }, function(index) {
                    $uibModalInstance.dismiss('cancel');
                    layer.close(index);
                }, function() {});
                return;
            }
            $uibModalInstance.dismiss('cancel');
        }
        $scope.shipmentsTypes = [{
            id: 1,
            name: "海运整柜"
        }, {
            id: 2,
            name: "海运拼箱"
        }, {
            id: 3,
            name: "航空货运"
        }, {
            id: 4,
            name: "FBA业务"
        }];

        $scope.addContact = function() {
            if(!$scope.opsClient.contactList){
                $scope.opsClient.contactList = [];
            }
            $scope.opsClient.contactList.push({});
        };
        $scope.contactMore = function(contact) {
            contact.contactMore = !contact.contactMore;
        }

        $scope.delContact = function(contact) {
            if($scope.opsClient.contactList.length == 1){
                layer.msg($translate.instant('autopsApp.opsClient.contactNullAlert'));
                return;
            }
            if ($rootScope.account.companyId !=  $scope.opsClient.companyId && $scope.opsClient.companyId){
                layer.msg($translate.instant('autopsApp.opsClient.groupClientAlert'));
                return;
            }   
            if(contact.userId){
                layer.msg($translate.instant('autopsApp.opsClient.delContactForbidden'));
                return;
            }
            if (contact.id != null) {
                OpsShipper.deleteContact({
                    id: contact.id
                });
            };
            var index = $scope.opsClient.contactList.indexOf(contact);
            if (index > -1) {
                $scope.opsClient.contactList.splice(index, 1);
            };
        };
        $scope.locations = [];
        $scope.getAllLocation= function() {
            OpsVender.getAllLocation(function(result) {
                $scope.locations = result;
            });
        };

        $scope.shareClient = function() {
            $uibModal.open({
                templateUrl: 'app/entities/share-client/share-client-dialog.html',
                controller: 'ShareClientDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return $scope.opsClient;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('shareClient');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {

            }, function() {

            });
        }
        $scope.textPop = function(crm) {
            if(crm.id){return;}
            $uibModal.open({
                templateUrl: 'app/entities/ops-change-log/ops-change-log-delete-dialog.html',
                controller: 'OpsChangeLogDeleteController',
                controllerAs: 'vm',
                // backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return crm.action;
                    }]
                }
            }).result.then(function(result) {
                crm.action = result;
            }, function() {
            });
        }
        $scope.setAudited = function(audied) {
            if ($rootScope.account.companyId !=  $scope.opsClient.companyId && $scope.opsClient.companyId){
                layer.msg($translate.instant('autopsApp.opsClient.groupClientAlert'));
                return;
            }   
            if ($rootScope.HAVENOAUTH('AU_OBJ_AUDIT')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_OBJ_AUDIT');   
                $scope.opsClient.audited = !audied;
                return;
            }
            if(audied){
                $scope.opsClient.auditor = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            }
            else {
                $scope.opsClient.auditor = null;
            }
        }

        $scope.shiftSales = function() {
            if ($rootScope.account.companyId !=  $scope.opsClient.companyId && $scope.opsClient.companyId){
                layer.msg($translate.instant('autopsApp.opsClient.groupClientAlert'));
                return;
            }   
            $uibModal.open({
                templateUrl: 'app/entities/opsVender/change-sales.html',
                controller: 'ChangeSalesController',
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return $scope.opsClient;
                    }]
                }
            }).result.then(function(result) {
                $scope.opsClient.creater = result.creater;
                $scope.opsClient.createdBy = result.createdBy;
            }, function() {
            });
        }

        $scope.notifySales = function () {
            layer.confirm($translate.instant('autopsApp.opsClient.notifySalesTips'), {
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    OpsVender.notifySales($scope.opsClient, function(){
                        layer.msg($translate.instant('global.msgSent')); 
                    }, function(error){
                        layer.msg($translate.instant('global.send_fail')); 
                    });
                    layer.close(index);
                }, function() {
            });
        }


        $scope.crmQuotation = function(lane) {
            var booking = {};
            if(lane.type == 1){
                booking.shipmentType = "FCL";
            }
            else if (lane.type == 2){
                booking.shipmentType = "LCL";
            }
            else if (lane.type == 3){
                booking.shipmentType = "AIR";
            }
            else if (lane.type == 4){
                booking.shipmentType = "EXPRESS";
            }            
            booking.polName = lane.pol;
            booking.destName = lane.pod;            
            booking.crmClient = angular.copy($scope.opsClient);
            booking.isNew = "crmQuotation";
            $uibModal.open({
                templateUrl: 'app/entities/ops-cost/ops-cost-detail.html',
                controller: 'OpsCostDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return booking;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsCost');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
            }, function() {
            });
        }

        $scope.setDiscount = function(discount) {
            $scope.opsClient.discount = discount;
        };

        $scope.defaultPicChange = function(contact) {
            if(contact.defaultPic){
                angular.forEach($scope.opsClient.contactList, function(data){
                    if(data != contact){
                        data.defaultPic = false;
                    }
                })
            }
        }
        $scope.shipperShipments = [];
        $scope.clientShipments = [];
        $scope.getRecentShipments = function(isShipper) {
            if(!$scope.opsClient.id){
                return;
            }
            if(isShipper && $scope.shipperShipments.length > 0){
                return;
            }
            if(!isShipper && $scope.clientShipments.length > 0){
                return;
            }
            var statParams = {};
            statParams.getRecentShipments = true;
            if(isShipper){
                statParams.shipperId = $scope.opsClient.id;
            }
            else {
                statParams.customerId = $scope.opsClient.id;
            }
            statParams.filterRequired = true;
            layer.load(1, {shade: 0.3});
            OpsFiles.comStats(statParams, function(result) {
                layer.closeAll(); 
                if (!result || result.length == 0) {
                    layer.msg($translate.instant('global.messages.resultNull'));
                    return;
                }
                if(isShipper){
                    $scope.shipperShipments = result;
                }
                else {
                    $scope.clientShipments = result;
                }
            }, $rootScope.ALERT_ERROR);
        }
        $scope.unpaidList = [];
        $scope.getUnpaidList = function(contact) {
            if(!$scope.opsClient.id || $scope.unpaidList.length > 0){
                return;
            }
            layer.load(1, {shade: 0.3});
            AccountInvoice.getVenderUnpaidList({
                venderId: $scope.opsClient.id
            }, function(result) {
                layer.closeAll(); 
                if (!result || result.length == 0) {
                    layer.msg($translate.instant('global.messages.resultNull'));
                    return;
                }
                $scope.unpaidList = result;
                updateUnpaidttl();
            }, function(response) {
                layer.closeAll(); 
                if(response.status == 404){
                    layer.msg($translate.instant('stats.stat.noData'));
                }
                else {
                    layer.msg($translate.instant('global.requestFail'));
                }
            });
        }
        $scope.totalList = [];
        function updateUnpaidttl(){            
            var currencies = new Set();
            angular.forEach($scope.unpaidList, function(data){
                if(!data.paidAmount){
                    data.paidAmount = 0;
                }
                if(!data.invoiceAmount){
                    data.invoiceAmount = 0;
                }
                data.balance = data.invoiceAmount - data.paidAmount;
                currencies.add(data.currency);
            })
            $scope.totalList = [];
            currencies.forEach(function(currency){
                var ttl = {
                    currency: currency,
                    invoiceAmount: 0,
                    paidAmount: 0,
                    balance: 0
                };
                angular.forEach($scope.unpaidList, function(data){
                    if(data.currency == currency){
                        ttl.invoiceAmount += data.invoiceAmount;
                        ttl.paidAmount += data.paidAmount;
                        ttl.balance += data.balance;
                    }
                })
                ttl.invoiceAmount = ttl.invoiceAmount.toFixed(2) -0; ;
                ttl.paidAmount = ttl.paidAmount.toFixed(2) -0; ;
                ttl.balance = ttl.balance.toFixed(2) -0; ;
                $scope.totalList.push(ttl);
            });
        }


    }
})();
