(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountTitleDetailController', AccountTitleDetailController);

    AccountTitleDetailController.$inject = ['$scope', '$rootScope', '$timeout', '$uibModal', '$translate', '$location',
        'JhiLanguageService', 'Principal', 'AccountRef', 'DateUtils', 'AccountVoucher', 'AccountTitle',
        'VoucherContent', 'OpsFiles', 'User', 'OpsVender', 'DataUtils'
    ];

    function AccountTitleDetailController($scope, $rootScope, $timeout, $uibModal, $translate, $location,
        JhiLanguageService, Principal, AccountRef, DateUtils, AccountVoucher, AccountTitle,
        VoucherContent, OpsFiles, User, OpsVender, DataUtils) {

        $scope.isOpTree = true;
        $scope.firstShow = true;
        $scope.isShowMore = false;
        $scope.isAcitve = false;
        $scope.filterDTO = {};
        $scope.filterDTO.isShowAmount = false;
        var ledgers = [];
        var type = "";
        var showLedger = "";
        $scope.TableHeight = null;
        $scope.TreeDivHeight = null;
        $scope.TreeHeight = null;
        $scope.TreeHeightStyle = null;

        $scope.classText = $translate.instant('autopsApp.accountTitle.class');

        $scope.viewType = null;
        var path = $location.path();
        if (path.indexOf("account") >= 0) {
            $scope.viewType = "sub_ledger";
            type = "all";
        } else if (path.indexOf("cash") >= 0) {
            $scope.viewType = "cashJournal";
            type = "1001";
        } else if (path.indexOf("deposit") >= 0) {
            $scope.viewType = "bankDeposits";
            type = "1002";
        }

        var lang = "";
        JhiLanguageService.getCurrent().then(function(language) {
            lang = language;
        });

        var zTreeObj;
        var categorySetting = {
            check: {
                enable: false
            },
            // edit: {
            //     enable: false,
            //     showRemoveBtn: setShowRemove,
            //     showRenameBtn: false,
            //     showAddOneBtn: false,
            //     removeTitle: "删除此节点科目"
            // },
            callback: {
                onClick: onClick
            },
            // view: {
            //     addHoverDom: addHoverDom,
            //     removeHoverDom: removeHoverDom
            // }
        };

        function onClick(event, treeId, treeNode) {
            var data = treeNode.obj;
            $scope.filterDTO.ledgerId = data.id;
            $scope.filterDTO.ledgerCode = data.code;
            // $scope.filterDTO.ledger = data.showLedger;
            // showLedger = data.showLedger;
            var showLedgerArr = [];
            angular.forEach(ledgers, function(result, index) {
                if (data.code && data.code.indexOf(result.code) > (-1)) {
                    showLedgerArr.push(lang == 'en' ? result.enName : result.cnName);
                } else if (data.code == result.code) {
                    showLedgerArr.push(lang == 'en' ? result.enName : result.cnName);
                    return
                }
            })
            showLedger = showLedgerArr.join('/ ');
            $scope.filterDTO.ledger = showLedgerArr.join('/ ');
            $scope.reportavailalbe = false;
            if (data.isForeign) {
                $scope.isForeign = true;
                $scope.currencyName = data.currency;
            } else {
                $scope.isForeign = false;
                $scope.currencyName = null;
            }
            initContent();
            // $scope.filterDTO.level = "";
            $scope.getContetns(false);
            $translate.refresh();
        }

        $scope.getLedgers = function() {
            if ($scope.allLedgers && $scope.allLedgers.length > 0) {
                return;
            }
            AccountTitle.getAll({
                type: "all"
            }, function(result) {
                $scope.allLedgers = DataUtils.generateShowLedger(result, false, lang);
            });
        }

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        }

        // $scope.isForeign;
        $scope.reportavailalbe = false;
        $scope.filterDTO.subLedgers = [];

        var showLedger = "";
        $scope._onLedgerChoosed = function(data) {
            $scope.filterDTO.ledgerId = data.id;
            $scope.filterDTO.ledgerCode = data.code;
            showLedger = data.showLedger;
            $scope.reportavailalbe = false;
            if (data.isForeign) {
                $scope.isForeign = true;
                $scope.currencyName = data.currency;
            } else {
                $scope.isForeign = false;
                $scope.currencyName = null;
            }
            initContent();
            $translate.refresh();
            $scope.getContetns(false);
        }

        $scope.chooseSubLedger = function(data) {
            $scope.filterDTO.ledgerId = data.id;
            $scope.filterDTO.ledgerCode = data.code;
            $scope.filterDTO.ledger = data.showLedger;
            showLedger = data.showLedger;
            $scope.reportavailalbe = false;
            if (data.isForeign) {
                $scope.isForeign = true;
                $scope.currencyName = data.currency;
            } else {
                $scope.isForeign = false;
                $scope.currencyName = null;
            }
            initContent();
            $scope.filterDTO.level = "";
            $scope.getContetns(false);
        }

        $scope.datePickerForEtd = {};
        $scope.datePickerForEtd.status = {
            opened: false
        };
        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };

        $scope.datePickerForCargoReadyDate = {};
        $scope.datePickerForCargoReadyDate.status = {
            opened: false
        };
        $scope.datePickerForCargoReadyDateOpen = function($event) {
            $scope.datePickerForCargoReadyDate.status.opened = true;
        };

        $scope.isSaving = false;
        $scope.ledgerEntries = [];
        var refrestCDC = function() {
            var date1, dateArray;
            angular.forEach($scope.ledgerEntries, function(ledgerEntry) {
                angular.forEach(ledgerEntry.voucherContents, function(content) {
                    // if (content.makeDate) {
                    //     date1 = DateUtils.convertLocalDateToServer(content.makeDate);
                    //     dateArray = date1.split("-");
                    //     content.month = dateArray[1];
                    //     content.day = dateArray[2];
                    // }

                    if (content.dn || content.dn == 0) {
                        content.dn = DataUtils.formatTwoDigits(content.dn);
                    }
                    if (content.cn || content.cn == 0) {
                        content.cn = DataUtils.formatTwoDigits(content.cn);
                    }
                    if (content.balance || content.balance == 0) {
                        content.balance = DataUtils.formatTwoDigits(content.balance);
                    }
                    if (content.dnCurrencyCount || content.dnCurrencyCount == 0) {
                        content.dnCurrencyCount = DataUtils.formatTwoDigits(content.dnCurrencyCount);
                    }
                    if (content.cnCurrencyCount || content.cnCurrencyCount == 0) {
                        content.cnCurrencyCount = DataUtils.formatTwoDigits(content.cnCurrencyCount);
                    }
                    if (content.currencyBalance || content.currencyBalance == 0) {
                        content.currencyBalance = DataUtils.formatTwoDigits(content.currencyBalance);
                        // content.exchangeRate = DataUtils.formatTwoDigits(content.exchangeRate);
                        content.currencyCount = DataUtils.formatTwoDigits(content.currencyCount);
                    }
                })
            })
        }
        var initContent = function() {
            if ($scope.ledgerEntries && $scope.ledgerEntries.length > 0) {
                for (var i = $scope.ledgerEntries.length - 1; i >= 0; i--) {
                    if (!$scope.ledgerEntries[i].ledgerCode) {
                        $scope.ledgerEntries.splice(i, 1);
                    }
                }
            }
            if (!$scope.ledgerEntries || $scope.ledgerEntries.length == 0) {
                $scope.ledgerEntries = [];
                $scope.ledgerEntries.push({});
                $scope.ledgerEntries[0].voucherContents = [];
                for (var j = 0; j <= 16; j++) {
                    $scope.ledgerEntries[0].voucherContents.push({});
                }
            }
            refrestCDC();
        }

        initContent();
        // var yearFrom, yearTo;
        $scope.getContetns = function(loadAll) {
            if (!$scope.filterDTO.dateFrom || !$scope.filterDTO.dateTo) {
                layer.msg($translate.instant('autopsApp.accountTitle.nullFilterAlert'));
                return;
            }
            if (!loadAll && !$scope.filterDTO.ledgerId) {
                layer.msg($translate.instant('autopsApp.accountTitle.nullFilterAlert'));
                return;
            }
            var filterDTO = angular.copy($scope.filterDTO);
            if(loadAll) {
                if (!filterResults || filterResults.length == 0) {
                    layer.msg($translate.instant('autopsApp.accountTitle.nullFilterAlert'));
                    return;
                }
                $scope.isShowMore = true;
                filterDTO.subLedgers = filterResults;
            }

            // yearFrom = filterDTO.dateFrom.getFullYear();
            // yearTo = filterDTO.dateTo.getFullYear();
            // if (yearFrom != yearTo) {
            //     layer.msg($translate.instant('global.messages.twoYearPeriodAlert'));
            //     return;
            // }

            if (filterDTO.dateFrom > filterDTO.dateTo) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                return;
            }
            if (DateUtils.getDaysDiff(filterDTO.dateFrom, filterDTO.dateTo) > 365) {
                layer.msg($translate.instant('global.messages.etdPeriodAlert'));
                return;
            }

            if (filterDTO.isAudited == null) {
                filterDTO.isAudited = false;
            }
            filterDTO.companyId = $rootScope.account.companyId;
            $scope.isSaving = true;
            if (!filterDTO.vender) {
                filterDTO.venderId = null;
            }
            if (!filterDTO.clerkName) {
                filterDTO.clerkId = null;
            }
            layer.load(1, { shade: 0.3 });
            VoucherContent.getAllByFiler(filterDTO, onGetSuccess, onGetError);
        }
        var onGetSuccess = function(result) {
            $scope.isSaving = false;
            layer.closeAll();
            if (!result || result.length == 0) {
                layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                return;
            }
            var ttlAmt = 0;
            angular.forEach(result, function(data){
                if(data && data.voucherContents){
                    ttlAmt += data.voucherContents.length;
                }
            })
            if (ttlAmt > 2000) {
                layer.confirm($translate.instant('global.dataSizeAlert', { amt: ttlAmt }), {
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    $scope.excelExport(result, true);
                    layer.close(index);
                }, function() {
                    loadResults(result);
                });
            } else {
                loadResults(result);
            }
        }
        var loadResults = function(result) {
            // $scope.contentYear = yearFrom;
            if ($scope.isShowMore) {
                addToResultList(result);
            } else {
                $scope.ledgerEntries = result
            }
            $scope.reportavailalbe = true;
            initContent();
            $translate.refresh();
        }
        var addToResultList = function(newList) {
            angular.forEach(newList, function(newOne) {
                var isNew = true;
                angular.forEach($scope.ledgerEntries, function(oldOne) {
                    if (oldOne.ledgerCode == newOne.ledgerCode) {
                        isNew = false;
                        angular.copy(newOne, oldOne);
                    }
                })
                if (isNew) {
                    $scope.ledgerEntries.splice(0, 0, newOne);
                }
            })
        }

        $scope.delLedgerEntry = function(idx) {
            $scope.ledgerEntries.splice(idx, 1);
            initContent();
        }
        $scope.clearResults = function(idx) {
            $scope.reportavailalbe = false;
            $scope.ledgerEntries = [];
            initContent();
        }

        var onGetError = function(response) {
            $scope.isSaving = false;
            layer.closeAll();
            if (response.status == 406) {
                layer.msg($translate.instant('autopsApp.accountTitle.lastYearNotAccountedAlert'));
            }
            initContent();
        }

        $scope.excelExport = function(result, singleSheet) {
            var ledgerEntries;
            if (result) {
                ledgerEntries = angular.copy(result);
            } else {
                ledgerEntries = angular.copy($scope.ledgerEntries);
            }

            if (!ledgerEntries || ledgerEntries.length == 0) {
                layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                return;
            }
            var title = $translate.instant('global.menu.entities.sub_ledger');            
            title = title + "(From " + DateUtils.convertLocalDateToServer($scope.filterDTO.dateFrom) + " To " + DateUtils.convertLocalDateToServer($scope.filterDTO.dateTo) + ")";
            var fileName = title + ".xlsx";
            OpsFiles.excelVoucherContent({
                singleSheet: singleSheet,
                title: title,
                enCn: $translate.instant('global.enCn')
            }, ledgerEntries, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }
        $scope.getVoucher = function(id, voucherContents) {
            if (null == id) {
                return;
            }
            VoucherContent.getVoucher({
                id: id
            }, function(result) {
                $rootScope.VIEWCONTENTVOUCHER(id, voucherContents, result);
            });
        };
        $scope.updateSettlementObj = function(item) {
            $scope.filterDTO.venderId = item.id;
        }

        $scope.onClerkSelected = function(item) {
            $scope.filterDTO.clerkId = item.id;
        };
        $scope.isForeignChange = function() {
            $translate.refresh();
        };

        // zTree 的数据属性，深入使用请参考 API 文档（zTreeNode 节点数据详解）
        var caegoryNodes = [];
        function getNodes(dataList) {
            var num = 1;
            if ($scope.filterDTO.levelFrom) {
                num = $scope.filterDTO.levelFrom - 0;
            }
            angular.forEach(dataList, function(data) {
                if (data.level == num) {
                    var node = {
                        name: lang == 'en' ? data.code + ' ' + data.enName : data.code + ' ' + data.cnName,
                        open: true,
                        id: data.code,
                        obj: data,
                        children: []
                    };
                    addSubNodes(node, data.level + 1, dataList)
                    if (node.children.length == 0) {
                        node.children = null;
                    }
                    caegoryNodes.push(node)
                }
            })
        };

        function addSubNodes(parentNode, level, dataList) {
            angular.forEach(dataList, function(data) {
                if (data.level == level && data.code.indexOf(parentNode.id) == 0 && data.code != parentNode.id) {
                    var node = {
                        name: lang == 'en' ? data.code + ' ' + data.enName : data.code + ' ' + data.cnName,
                        open: true,
                        id: data.code,
                        obj: data,
                        children: []
                    };
                    addSubNodes(node, data.level + 1, dataList);
                    if (node.children.length == 0) {
                        node.children = null;
                    }
                    parentNode.children.push(node)
                }
            })
        }
        $timeout(function() {
            getTableHeight()
        });
        window.addEventListener('resize', getTableHeight);

        function getTableHeight() {
            var footerHeight = 0;
            if (document.getElementById("footPager")) {
                document.getElementById("footPager").style.cssText = "margin: 0px;";
                footerHeight = $("#" + "footPager").height();
            }
            var heightPx = $(window).height() - $("#switchTable").offset().top - footerHeight - 1;
            $scope.TableHeight = {
                "max-height": heightPx + "px"
            }
            $scope.TreeDivHeight = {
                "max-height": heightPx - 10 + "px"
            }
            $scope.TreeHeight = heightPx - 140;
            $scope.TreeHeightStyle = {
                "max-height": $scope.TreeHeight + "px"
            }
            $translate.refresh();
        }
        $scope.isOpenTree = function() {
            $scope.firstShow = false;
            $scope.isOpTree = !$scope.isOpTree;
        }
        $scope.getPeriodAccountCodes = function() {
            if (!$scope.filterDTO.dateTo || !$scope.filterDTO.dateFrom) {
                return;
            }
            var filterLedger = {};
            filterLedger.companyId = $rootScope.account.companyId;
            filterLedger.makeDateTO = $scope.filterDTO.dateTo;
            filterLedger.makeDateFM = $scope.filterDTO.dateFrom;
            filterLedger.includeBalaceNotZero = $scope.filterDTO.isShowAmount; //无发生额且余额为0不显示
            layer.load(1, { shade: 0.3 });
            VoucherContent.getPeriodAccountCodes(filterLedger, function(result) {
                layer.closeAll();
                ledgers = result;
                $scope.filterChange();
            }, function() {
                layer.closeAll();
                layer.msg($translate.instant('global.loadFailed'));
            });
        }

        $scope.IsShowMoreDo = function() {
            var ledgerEntries = angular.copy($scope.ledgerEntries);
            if (!$scope.isShowMore) {
                $scope.ledgerEntries = [ledgerEntries[0]]
            }
        }

        function getFilterResult() {
            if (!ledgers || ledgers.length == 0) {
                return;
            }
            var results = angular.copy(ledgers);
            for (var i = results.length - 1; i >= 0; i--) {
                if (codeCompare($scope.filterDTO.ledgerCodeFrom, results[i].code) == 1 ) {
                    results.splice(i, 1);
                }
                if (codeCompare($scope.filterDTO.ledgerCodeTo, results[i].code) == -1 ) {
                    results.splice(i, 1);
                }
                if ($scope.filterDTO.levelFrom && parseInt($scope.filterDTO.levelFrom) > results[i].level) {
                    results.splice(i, 1);
                }
                if ($scope.filterDTO.levelTo && parseInt($scope.filterDTO.levelTo) < results[i].level) {
                    results.splice(i, 1);
                }
            }
            return results
        }
        function codeCompare(code1, code2) {
            if(!code1 || !code2){
                return 0;
            }
            if(code2.length > code1.length){
                code2 = code2.substring(0, code1.length);
            }
            if(code1 > code2){
                return 1;
            }
            if(code1 < code2){
                return -1;
            }
            return 0;

        }

        var filterResults;
        $scope.filterChange = function() {
            filterResults = getFilterResult(ledgers);
            caegoryNodes = [];
            getNodes(filterResults);
            zTreeObj = $.fn.zTree.init($("#accountTitlesTree"), categorySetting, caegoryNodes);
            fuzzySearch('accountTitlesTree', '#field_search', null, true);
        }
    }
})();
