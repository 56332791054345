(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['$scope', 'Principal', 'Auth', 'JhiLanguageService', '$translate', 'JhiSignature'];

    function SettingsController ($scope, Principal, Auth, JhiLanguageService, $translate, JhiSignature) {
        var vm = this;

        vm.error = null;
        vm.save = save;
        vm.settingsAccount = null;
        vm.success = null;
        vm.setPort = setPort;

        function setPort (port) {
            vm.settingsAccount.port = port;
        }

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login,
                mob: account.mob,
                tel: account.tel,
                fax: account.fax,
                auth: account.auth, 
                companyName: account.companyName, 
                companyAddress: account.companyAddress, 
                signatures: account.signatures,
                host: account.host, 
                emailPassword: account.emailPassword, 
                userName: account.userName,
                emailAddress: account.emailAddress, 
                isSuper: account.isSuper,
                qq: account.qq,
                port: account.port 
            };
        };

        Principal.identity().then(function(account) {
            vm.settingsAccount = copyAccount(account);
            if(!vm.settingsAccount){
                vm.settingsAccount.port = 465;
            }
        });

        function save () {
            Auth.updateAccount(vm.settingsAccount).then(function() {
                vm.error = null;
                vm.success = 'OK';
                Principal.identity(true).then(function(account) {
                    vm.settingsAccount = copyAccount(account);
                });
                JhiLanguageService.getCurrent().then(function(current) {
                    if (vm.settingsAccount.langKey !== current) {
                        $translate.use(vm.settingsAccount.langKey);
                    }
                });
            }).catch(function() {
                vm.success = null;
                vm.error = 'ERROR';
            });
        }
        $scope.sign = {};
        var isEdit = false;
        $scope.addSignature = function () {
            $scope.sign = {};
            $scope.sign.signature = vm.settingsAccount.firstName + " " + vm.settingsAccount.lastName
            + "/ " + vm.settingsAccount.mob + "\n"
            + vm.settingsAccount.companyName + "\n" + vm.settingsAccount.companyAddress + "\n"
            + "TEL: " + vm.settingsAccount.tel + "\n" + "FAX: " + vm.settingsAccount.fax ;
            isEdit = false;
            layer.open({
                type: 1,
                area: ['720px', '410px'],
                moveOut: true,
                maxmin: true,
                title: $translate.instant('entity.email.signTitle'),
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: $("#signatureView")
            }); 
        }

        $scope.editSign = function (sign) {
            $scope.sign = sign;
            isEdit = true;
            layer.open({
                type: 1,
                area: ['720px', '410px'],
                moveOut: true,
                maxmin: true,
                title: $translate.instant('entity.email.signTitle'),
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: $("#signatureView")
            }); 
        }

        $scope.clear = function () {
            $scope.sign = {};
            layer.closeAll();
        }

        $scope.saveSign = function () {
            if(isEdit){
                layer.closeAll();
                return;
            };
            vm.settingsAccount.signatures.push($scope.sign);
            layer.closeAll();
        }

        $scope.delSign = function (signature) {
            if (signature.id != null) {
                JhiSignature.delete({
                    id: signature.id
                });
            };
            var index = vm.settingsAccount.signatures.indexOf(signature);
            if (index > -1) {
                vm.settingsAccount.signatures.splice(index, 1);
            };
        }


    }
})();
