(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('InvoiceRequestController', InvoiceRequestController);

    InvoiceRequestController.$inject = ['$scope', '$rootScope', '$uibModal', '$uibModalInstance', '$translate', '$timeout',
    'entity', 'Guide', 'OpsExchangeRate', 'OpsVender', 'GuideRates', 'DataUtils', 'DateUtils'];
    
    function InvoiceRequestController($scope, $rootScope, $uibModal, $uibModalInstance, $translate, $timeout,
        entity, Guide, OpsExchangeRate, OpsVender, GuideRates, DataUtils, DateUtils) {

        $scope.guide = entity;
        
        $scope.isSaving = false;  
        $scope.invoiceRequest = {};

        $scope.choosedRates = [];
        var allChoosedRates = [];
        $scope.settlementObjs = [];
        $scope.showRatesList = false;

        $scope.accountingDate = new Date();
        $scope.deadlineDate = null;
        $scope.currencys = [];

        $timeout(function (){
            if ($rootScope.account.accountByEtd){
                $scope.accountingDate = DateUtils.getAccountDate($rootScope.account.companyId, $scope.guide.etd, $scope.guide.podRegion,  $scope.guide.shipmentType);
            }
            if(entity.invoiceRequest){
                $scope.invoiceRequest = entity.invoiceRequest;
            }
            else {
                $scope.invoiceRequest.jobNums = $scope.guide.jobNum;
                if($scope.guide.warehousingNumber){
                    $scope.invoiceRequest.jobNums = $scope.guide.warehousingNumber;
                }
                $scope.invoiceRequest.blNums = $scope.guide.mblNum;
                $scope.invoiceRequest.sales = $scope.guide.salesName;
                $scope.invoiceRequest.salesId = $scope.guide.salesId;
            }

            for(var i = $scope.guide.guideRates.length -1; i >= 0; i--){
                if($scope.guide.guideRates[i].isHidden 
                    || !$scope.guide.guideRates[i].id 
                    || !$scope.guide.guideRates[i].venderId 
                    || !$scope.guide.guideRates[i].payAmount 
                    || !$scope.guide.guideRates[i].payCurrency 
                    || !$scope.guide.guideRates[i].payer 
                    || $scope.guide.guideRates[i].locked 
                    || $scope.guide.guideRates[i].internalControl == 'mustCom' 
                    || $scope.guide.guideRates[i].dnNum){
                    $scope.guide.guideRates.splice(i, 1);
                }
                else if($rootScope.HAVEAUTH('FB_VIEW_OTH_RATE')){
                    var usrName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                    if(usrName != $scope.guide.guideRates[i].inputBy){
                        $scope.guide.guideRates.splice(i, 1);
                    }
                }
            }
            
            var guideRates = angular.copy($scope.guide.guideRates);
            for (var i = 0; i < guideRates.length; i++) {
                guideRates[i].jobNum = $scope.guide.jobNum;
                if(!guideRates[i].locked && guideRates[i].payCurrency && $scope.currencys.indexOf(guideRates[i].payCurrency) === -1){
                    $scope.currencys.push(guideRates[i].payCurrency);
                }    
            }

            loadDefultPayer();
            addMoreRates(guideRates); 

            for (var i = 0; i < guideRates.length; i++) {
                if(!guideRates[i].locked && !guideRates[i].isPay){
                    $scope.updateSettlementObj(guideRates[i].venderId);
                    if($rootScope.account.baseCurrency != "RMB"){
                        $scope.invoiceRequest.invoiceType = "noInvoice";
                        $scope.invoiceRequest.currency = guideRates[i].payCurrency;
                    }
                    else if(guideRates[i].payCurrency == $rootScope.account.baseCurrency){
                        $scope.invoiceRequest.invoiceType = "rmbOnly";  
                    }
                    else if (guideRates[i].payCurrency == "USD"){
                        $scope.invoiceRequest.invoiceType = "usdOnly";
                    }
                    else {
                        $scope.invoiceRequest.invoiceType = "noInvoice";
                        $scope.invoiceRequest.currency = guideRates[i].payCurrency;
                    }
                    break;
                }    
            }

            $scope.invoiceTypeChange();
        })
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };            

        $scope.datePickerForDeadLine = {};
        $scope.datePickerForDeadLine.status = {
            opened: false
        };
        $scope.datePickerForDeadLineOpen = function($event) {
            $scope.datePickerForDeadLine.status.opened = true;
        };

        $scope.datePickerForAccountingDate = {};
        $scope.datePickerForAccountingDate.status = {
            opened: false
        };
        $scope.datePickerForAccountingDateOpen = function($event) {
            $scope.datePickerForAccountingDate.status.opened = true;
        };

        var loadDefultPayer = function() {
            $scope.settlementObjs = [];
            var isNew;
            for (var i = $scope.guide.guideRates.length-1; i >=0; i--) {
                if (!$scope.guide.guideRates[i].locked && $scope.guide.guideRates[i].payer) {
                    isNew = true;
                    for (var j = 0; j < $scope.settlementObjs.length; j++) {
                        if ($scope.settlementObjs[j].choosedId == $scope.guide.guideRates[i].venderId) {
                            isNew = false;
                            break;
                        }
                    }
                    if (isNew) {
                        $scope.settlementObjs.push({
                            "Company_name": $scope.guide.guideRates[i].payer,
                            "choosedId": $scope.guide.guideRates[i].venderId
                        });
                    }
                }
            }
        };
        //开票申请
        var addMoreRates = function(rates) {
            for (var i = 0; i < rates.length; i++) {
                if (!rates[i].locked) {
                    if (!rates[i].jobNum) {
                        rates[i].jobNum = $scope.guide.jobNum;
                    };
                    allChoosedRates.push(rates[i]);
                }
            }
            updateInvoiceRates();
        };
        var venderCopy = {};
        $scope.updateSettlementObj = function(vId) {
            $scope.invoiceRequest.venderId = vId;
            OpsVender.getInvoiceVenderDTO({id: vId}, function(result) {
                venderCopy = result;
                $scope.invoiceRequest.payer = result.companyName;
                $scope.invoiceRequest.invoiceTitle = result.companyName;
                $scope.invoiceRequest.mailAddress = result.address;
                $scope.invoiceRequest.mailMob = result.mob;
                $scope.invoiceRequest.mailAttn = result.attn;
                $scope.invoiceRequest.addressTel = result.tel;
                $scope.invoiceRequest.bankInfo = null;
                $scope.invoiceRequest.taxNum = null;

                $scope.invoiceRequest.dnOnly = (!result.paymentTerm || result.paymentTerm == "DP" || result.paymentTerm == "beforeETA")?false:true;

                if(result.invoiceInfos && result.invoiceInfos.length > 0 && result.invoiceInfos[0].invoiceTitle){
                    $scope.invoiceRequest.invoiceTitle = result.invoiceInfos[0].invoiceTitle;
                    $scope.invoiceRequest.taxNum = result.invoiceInfos[0].taxNum;
                    if($scope.invoiceRequest.invoiceType == 'rmbOnly'){
                        $scope.invoiceRequest.bankInfo = result.invoiceInfos[0].bankInfo;
                    }
                    else {
                        $scope.invoiceRequest.bankInfo = result.invoiceInfos[0].bankInfoUsd;
                    }                        
                    $scope.invoiceRequest.addressTel = result.invoiceInfos[0].addressTel;
                    $scope.invoiceRequest.mailAddress = result.invoiceInfos[0].mailAddress;
                    $scope.invoiceRequest.mailMob = result.invoiceInfos[0].mailMob;
                    $scope.invoiceRequest.mailAttn = result.invoiceInfos[0].mailAttn;
                    $scope.invoiceRequest.eInvoiceEmail = result.invoiceInfos[0].einvoiceEmail;
                }
            }, function(result){
                $scope.invoiceRequest.venderId = null;
                layer.msg($translate.instant('autopsApp.guide.venderDeletedAlert'));
            });
            Guide.getRevDeadLine({opsVenderId: vId}, $scope.guide, function(result) {
                $scope.deadlineDate = result.revDeadLine;
            })
            $scope.choosedRates = [];
            updateInvoiceRates();
        };

        var updateInvoiceRates = function() {
            if (!$scope.invoiceRequest.venderId || !$scope.invoiceRequest.invoiceType) {
                $scope.choosedRates = [];
                return;
            }
            $scope.choosedRates = [];

            for (var i = 0; i < allChoosedRates.length; i++) {
                if (allChoosedRates[i].payAmount && allChoosedRates[i].venderId == $scope.invoiceRequest.venderId) {
                    $scope.choosedRates.push(allChoosedRates[i]);
                }
            }

            if (!$scope.invoiceRequest.isBalance) {
                for (var i = $scope.choosedRates.length - 1; i >= 0; i--) {
                    if ($scope.choosedRates[i] && $scope.choosedRates[i].isPay) {
                        $scope.choosedRates.splice(i, 1);
                    }
                }
            }

            if ($scope.invoiceRequest.invoiceType == "rmbOnly") {
                $scope.invoiceRequest.isIssued = false;
                $scope.invoiceRequest.currency = $rootScope.account.baseCurrency;
                for (var i = $scope.choosedRates.length - 1; i >= 0; i--) {
                    if ($scope.choosedRates[i].payCurrency != $rootScope.account.baseCurrency) {
                        $scope.choosedRates.splice(i, 1);
                    }
                }
                $scope.invoiceRequest.isUsd = false;
            } 
            else if ($scope.invoiceRequest.invoiceType == "usdOnly") {
                $scope.invoiceRequest.isIssued = false;
                $scope.invoiceRequest.currency = "USD";
                for (var i = $scope.choosedRates.length - 1; i >= 0; i--) {
                    if ($scope.choosedRates[i].payCurrency != "USD") {
                        $scope.choosedRates.splice(i, 1);
                    }
                }
                $scope.invoiceRequest.isUsd = true;
            } 
            else if ($scope.invoiceRequest.invoiceType == "noInvoice") {
                $scope.invoiceRequest.electronicInvoice = false;
                $scope.invoiceRequest.isIssued = true;
                $scope.invoiceRequest.mailAddress = null;
                $scope.invoiceRequest.mailMob = null;
                $scope.invoiceRequest.mailAttn = null;
                for (var i = $scope.choosedRates.length - 1; i >= 0; i--) {
                    if ($scope.choosedRates[i].payCurrency != $scope.invoiceRequest.currency) {
                        $scope.choosedRates.splice(i, 1);
                    }
                    if ($scope.invoiceRequest.currency == $rootScope.account.baseCurrency) {
                        $scope.invoiceRequest.isUsd = false;
                    } 
                    else {
                        $scope.invoiceRequest.isUsd = true;
                    }
                }
            }
            getInvoiceAmount($scope.choosedRates);
        }

        var revContent = {}; 
        var expContent = {}; 
        var revVatContent = {}; 
        var revWhtContent = {}; 
        var expVatContent = {}; 
        var expWhtContent = {}; 

        var getInvoiceAmount = function(rates) {
            if (!rates || rates.length == 0) {
                $scope.invoiceRequest.invoiceAmount = 0;
                return;
            }
            $scope.invoiceRequest.invoiceAmount = 0;

            var balance = 0;
            var revAmt = 0;
            var expAmt = 0;
            var expVatTtl = 0;
            var expWhtTtl = 0;
            var revVatTtl = 0;
            var revWhtTtl = 0;
            for (var i = 0; i < rates.length; i++) {
                if (rates[i].isPay) {
                    balance = balance - rates[i].payAmount;
                    expAmt = expAmt + rates[i].payAmount;
                    if(rates[i].vatAmt){
                        expVatTtl = expVatTtl + rates[i].vatAmt;
                        expAmt = expAmt - rates[i].vatAmt;
                    }
                    if(rates[i].incomeTaxAmt){
                        expWhtTtl = expWhtTtl + rates[i].incomeTaxAmt;
                        expAmt = expAmt + rates[i].incomeTaxAmt;
                    }
                } else {
                    balance = balance + rates[i].payAmount;  
                    revAmt = revAmt + rates[i].payAmount;    
                    if(rates[i].vatAmt){
                        revVatTtl = revVatTtl + rates[i].vatAmt;
                        revAmt = revAmt - rates[i].vatAmt;
                    }   
                    if(rates[i].incomeTaxAmt){
                        revWhtTtl = revWhtTtl + rates[i].incomeTaxAmt;
                        revAmt = revAmt + rates[i].incomeTaxAmt;
                    }        
                }
            }

            $scope.invoiceRequest.invoiceAmount = DataUtils.round(balance, 2);
            revContent.cn = DataUtils.round(revAmt, 2);
            expContent.dn = DataUtils.round(expAmt, 2);
            revVatContent.cn = DataUtils.round(revVatTtl, 2);
            revWhtContent.dn = DataUtils.round(revWhtTtl, 2);
            expVatContent.dn = DataUtils.round(expVatTtl, 2);
            expWhtContent.cn = DataUtils.round(expWhtTtl, 2);
        };

        $scope.invoiceTypeChange = function() {
            updateInvoiceRates();
            if(venderCopy.invoiceInfos && venderCopy.invoiceInfos.length > 0 && venderCopy.invoiceInfos[0].invoiceTitle){
                if($scope.invoiceRequest.invoiceType == 'rmbOnly'){
                    $scope.invoiceRequest.bankInfo = venderCopy.invoiceInfos[0].bankInfo;
                }
                else if($scope.invoiceRequest.invoiceType == 'usdOnly'){
                    $scope.invoiceRequest.bankInfo = venderCopy.invoiceInfos[0].bankInfoUsd;
                }
            }
        }

        $scope.isBalanceChange = function(type) {
            updateInvoiceRates();
        }

        $scope.deleteOneInvoiceRate = function(rate) {
            var index = allChoosedRates.indexOf(rate);
            if (index > -1) {
                allChoosedRates.splice(index, 1);
            };
            updateInvoiceRates();
        }

        var onVencherSuccess = function(result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('global.messages.submitted'));
            $uibModalInstance.close(result);            
        }

        var onVencherError = function(result) {
            $scope.isSaving = false;
            if (result.status === 404) {
                layer.msg($translate.instant('autopsApp.guide.venderDeletedAlert'));
            } else if (result.status === 409) {
                layer.msg($translate.instant('autopsApp.guide.endClosedAlert'));
            } else if (result.status === 403) {
                layer.msg($translate.instant('autopsApp.guide.titleNotSetAlert'));
            } else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        }
        var getBillNumStr = function() {
            var result = [], hash = {}, billNumStr = "";
            for (var i = 0, elem; i < $scope.choosedRates.length && (elem = $scope.choosedRates[i].billNum) != null; i++) {
                if (!hash[elem]) {
                    result.push(elem);
                    hash[elem] = true;
                }
            }
            for (var i = 0; i < result.length; i++){
                billNumStr = billNumStr + result[i] + " ";
            }
            return billNumStr;
        }
        $scope.invoiceRequestSubmit = function() {
            $scope.isSaving = true;
            for (var i = $scope.choosedRates.length-1; i >=0; i --){
                if (!$scope.choosedRates[i].id){
                    $scope.choosedRates.splice(i, 1);
                }
            }

            if ($scope.choosedRates.length == 0) {
                layer.msg($translate.instant('autopsApp.guide.notRatesForVencher'));
                $scope.isSaving = false;
                return;
            }

            if ($scope.invoiceRequest.invoiceAmount < 0) {
                if(!$scope.invoiceRequest.dnOnly){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.zeroAlert'));
                    $scope.isSaving = false;
                    return;
                }
                if($rootScope.HAVENOAUTH("NEGATIVE_DN_OK")){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.negativeDnForbidden'));
                    $scope.isSaving = false;
                    return;
                }
            }
            
            if ($scope.invoiceRequest.invoiceType == "noInvoice") {
                $scope.invoiceRequest.electronicInvoice = false;
            }
            $scope.invoiceRequest.billNum = getBillNumStr();
            $scope.invoiceRequest.companyId = $rootScope.account.companyId;
            $scope.invoiceRequest.createdTime = new Date();
            $scope.invoiceRequest.deadLine = $scope.deadlineDate;
            $scope.invoiceRequest.creater = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            $scope.invoiceRequest.createdBy = $rootScope.account.id;
            $scope.invoiceRequest.isPaid = false;
            $scope.invoiceRequest.isIssued = false;
            $scope.invoiceRequest.isVoided = false;            
            $scope.invoiceRequest.economicDate = $scope.guide.etd;

            var accountInvoiceDTO = {};
            accountInvoiceDTO.guideRates = $scope.choosedRates;
            accountInvoiceDTO.accountInvoice = $scope.invoiceRequest;

            accountInvoiceDTO.revContent = revContent;
            accountInvoiceDTO.expContent = expContent;
            accountInvoiceDTO.revVatContent = revVatContent;
            accountInvoiceDTO.revWhtContent = revWhtContent;
            accountInvoiceDTO.expVatContent = expVatContent;
            accountInvoiceDTO.expWhtContent = expWhtContent;

            accountInvoiceDTO.accountingDate = $scope.accountingDate;
            GuideRates.newInvoice({
                guideId: $scope.guide.id
            }, accountInvoiceDTO, onVencherSuccess, onVencherError);
        }

        $scope.viewRates = function() {
            if (!$scope.invoiceRequest.venderId || !$scope.invoiceRequest.invoiceType) {
                layer.msg($translate.instant('autopsApp.guide.invoiceTypeVoidAlert'));
                return;
            }
            $scope.showRatesList = !$scope.showRatesList;
        };

        $scope.changeInvoiceTitle = function() {
            if(!venderCopy || !venderCopy.invoiceInfos || venderCopy.invoiceInfos.length == 0){
                layer.msg($translate.instant('global.messages.resultNull'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/invoice-info/invoice-info-dialog.html',
                controller: 'InvoiceInfoDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return venderCopy.invoiceInfos;
                    }]
               }
           }).result.then(function(result) {
                $scope.invoiceRequest.invoiceTitle = result.invoiceTitle;
                $scope.invoiceRequest.taxNum = result.taxNum;
                if($scope.invoiceRequest.invoiceType == 'rmbOnly'){
                    $scope.invoiceRequest.bankInfo = result.bankInfo;
                }
                else {
                    $scope.invoiceRequest.bankInfo = result.bankInfoUsd;
                }                        
                $scope.invoiceRequest.addressTel = result.addressTel;
                $scope.invoiceRequest.mailAddress = result.mailAddress;
                $scope.invoiceRequest.mailMob = result.mailMob;
                $scope.invoiceRequest.mailAttn = result.mailAttn;
                $scope.invoiceRequest.eInvoiceEmail = result.einvoiceEmail;
           }, function() {

           });   
        };


}
})();
