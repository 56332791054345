(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('checking-receipt', {
            parent: 'finance',
            url: '/checking-receipt?page&sort&search',
            data: {
                authorities: ['ROLE_ACCOUNT', 'ROLE_BOSS', 'ROLE_CASHIER'],
                pageTitle: 'global.menu.admin.checking-receipt'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/auto-check/auto-checks.html',
                    controller: 'AutoCheckController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('autoCheck');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('stats');
                    return $translate.refresh();
                }]
            }
        })
        .state('auto-check', {
            parent: 'finance',
            url: '/auto-check?page&sort&search',
            data: {
                authorities: ['ROLE_ACCOUNT', 'ROLE_BOSS', 'ROLE_CASHIER', 'FUN_MONTHLY_PAYMENT'],
                pageTitle: 'autopsApp.autoCheck.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/auto-check/auto-checks.html',
                    controller: 'AutoCheckController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('autoCheck');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('stats');
                    return $translate.refresh();
                }]
            }
        })
        .state('parcel-check', {
            parent: 'finance',
            url: '/parcel-check?page&sort&search',
            data: {
                authorities: ['ROLE_ACCOUNT', 'ROLE_BOSS', 'ROLE_CASHIER', 'FUN_MONTHLY_PAYMENT'],
                pageTitle: 'autopsApp.autoCheck.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/auto-check/auto-checks.html',
                    controller: 'AutoCheckController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('autoCheck');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('stats');
                    return $translate.refresh();
                }]
            }
        })
        .state('monthly-check', {
            parent: 'finance',
            url: '/monthly-check?page&sort&search',
            data: {
                authorities: ['FUN_MONTHLY_PAYMENT'],
                pageTitle: 'global.menu.admin.monthly-statement'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/auto-check/auto-checks.html',
                    controller: 'AutoCheckController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('autoCheck');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('stats');
                    return $translate.refresh();
                }]
            }
        })
        .state('auto-check-detail', {
            parent: 'finance',
            url: '/auto-check-detail/{id}',
            data: {
                authorities: ['ROLE_ACCOUNT', 'ROLE_BOSS', 'ROLE_CASHIER', 'FUN_MONTHLY_PAYMENT'],
                pageTitle: 'autopsApp.autoCheck.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/auto-check/auto-check-detail.html',
                    controller: 'AutoCheckDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('autoCheck'); 
                    $translatePartialLoader.addPart('stats');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'AutoCheck', 'WinAES', function($stateParams, AutoCheck, WinAES) {
                    if($stateParams.id){
                        return AutoCheck.get({id : WinAES.decode($stateParams.id)}).$promise;
                    }
                    else {
                        return {
                            parcelCheck: false,
                            isAuto: true,
                            isPay: true
                        };
                    } 
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'auto-check',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('monthly-check-detail', {
            parent: 'finance',
            url: '/monthly-check-detail/{id}',
            data: {
                authorities: ['FUN_MONTHLY_PAYMENT'],
                pageTitle: 'autopsApp.autoCheck.detail.titleMohthly'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/auto-check/auto-check-dialog.html',
                    controller: 'AutoCheckDialogController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('autoCheck');
                    $translatePartialLoader.addPart('stats');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'AutoCheck', 'WinAES', function($stateParams, AutoCheck, WinAES) {
                    if($stateParams.id){
                        return AutoCheck.get({id : WinAES.decode($stateParams.id)}).$promise;
                    }
                    else {
                        return {
                            parcelCheck: false,
                            isAuto: false,
                            isPay: null
                        };
                    } 
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'monthly-check',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('parcel-check-detail', {
            parent: 'finance',
            url: '/parcel-check-detail/{id}',
            data: {
                authorities: ['FUN_MONTHLY_PAYMENT'],
                pageTitle: 'global.menu.entities.parcelCheck'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/auto-check/auto-check-parcel.html',
                    controller: 'AutoCheckParcelController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('autoCheck');
                    $translatePartialLoader.addPart('stats');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'AutoCheck', 'WinAES', function($stateParams, AutoCheck, WinAES) {
                    if($stateParams.id){
                        return AutoCheck.get({id : WinAES.decode($stateParams.id)}).$promise;
                    }
                    else {
                        return {
                            parcelCheck: true,
                            ref: 'jobNum',
                            byTotal: true,
                            isPay: true,
                            isCn: true,
                            isDn: false,
                            isDiff: false
                        };
                    } 
                }]
            }
        });
    }

})();
