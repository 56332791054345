(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('EdiDataDeleteController', EdiDataDeleteController);

    EdiDataDeleteController.$inject = ['$timeout', '$scope', '$rootScope', '$translate', '$uibModalInstance', '$http', '$uibModal',
    'OpsCarrier', 'EdiData', 'OpsPorts', 'entity', 'GuideFclCtn', 'DataUtils',
    'Guide', 'GuideCtns', 'OpsReport', 'ReportTemplate'];

    function EdiDataDeleteController($timeout, $scope, $rootScope, $translate, $uibModalInstance, $http, $uibModal,
        OpsCarrier, EdiData, OpsPorts, entity, GuideFclCtn, DataUtils,
       Guide, GuideCtns, OpsReport, ReportTemplate) {
        var vm = this;
        $scope.isSaving = false;

        $scope.guide = angular.copy(entity.guide);
        $scope.ediData = {};
        $scope.isSi = entity.isSi;

        var guideCopy;
        var ediDataCopy;
        var refreshRequired = false;
//        var bpCode = "CN900329";

        $timeout(function (){
            EdiData.getByGuideId({guideId: $scope.guide.id}, function(result){
                $scope.ediData = result;
                if(!$scope.ediData.id){
                    $scope.ediData.bpCity = $scope.ediData.bpName;
                    $scope.ediData.bpAddress = $scope.ediData.bpAddress;
                    $scope.ediData.bpName = null;
                }
                initEdiDate();
            })
        });

        var initEdiDate = function() {
            if($rootScope.IS_HAITIE()){
                $scope.ediData.bpName = "CN900329";
                $scope.ediData.bpAddress = "SHENZHEN T-OCEAN LOGISTICS CO.,LTD";
            }
            else if($scope.guide.companyId == 388){
                $scope.ediData.bpName = "CN252301";
                $scope.pilSales = [{
                    id: 'CN029',
                    name: "Flying Wang"
                },{
                    id: 'CN040',
                    name: "Wison Zhang"
                },{
                    id: 'CN028',
                    name: "Kent Zhou"
                }];
            }
            if (!$scope.ediData){
                $scope.ediData = {};
            }
            if (!$scope.ediData.cnCountryCode && $scope.guide.destId){
                $scope.ediData.cnCountryCode = $scope.guide.destId.substring(0,2);
            }
            if (!$scope.guide.contractCargoName && $scope.guide.goodsDiscription){
                $scope.guide.contractCargoName = $scope.guide.goodsDiscription.substring(0,60);
            }
            if (!$scope.guide.contractCargoName && $scope.guide.cargoName){
                $scope.guide.contractCargoName = $scope.guide.cargoName.substring(0,60);
            }
//            if(!$scope.ediData.shName){
//                $scope.ediData.shName = bpCode;
//            }
            if ($scope.guide.booking.hsCode && $scope.guide.booking.hsCode.length > 6){
                $scope.guide.booking.hsCode =$scope.guide.booking.hsCode.replace(/[^0-9]*/g,"");
                $scope.guide.booking.hsCode = $scope.guide.booking.hsCode.substring(0, 6);
            }
            $http.get('i18n/pilPortsData.json').success(function(result) {
                $scope.portList = result.ports;
                var pol, pod, por, dest;
                angular.forEach($scope.portList, function(data){
                    if(data.code == $scope.guide.porId){
                        por = true;
                    }
                    if(data.code == $scope.guide.polId){
                        pol = true;
                    }
                    if(data.code == $scope.guide.podId){
                        pod = true;
                    }
                    if(data.code == $scope.guide.destId){
                        dest = true;
                    }
                })
                if(!por){
                    $scope.guide.porId = null;
                    $scope.guide.porName = null;
                }
                if(!pol){
                    $scope.guide.polId = null;
                    $scope.guide.polName = null;
                }
                if(!pod){
                    $scope.guide.podId = null;
                    $scope.guide.podName = null;
                }
                if(!dest){
                    $scope.guide.destId = null;
                    $scope.guide.destName = null;
                }
            });
            guideCopy = angular.copy($scope.guide);
            ediDataCopy = angular.copy($scope.ediData);

            $scope.updatePkgsAndType();
            $scope.updateGwVol();
            angular.forEach($scope.guide.guideFclCtns, function(data){
                if (data.ctnType != "20GP" && data.ctnType != "40GP" && data.ctnType != "40HQ" && data.ctnType != "45HQ"){
                    data.ctnType = null;
                }
            });
            initByGuide();
            
            if (!$scope.ediData.noOfCopies){
                $scope.ediData.noOfCopies = 3;
            }
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg("Data updated according EDI criterion, pls check carefully before save.");
            };

        }
        function initByGuide() {
            if(!$scope.ediData.shCity){
                $scope.ediData.shCity = getStr($scope.guide.guideShipperBl, 1);
                $scope.ediData.shAddress = getStr($scope.guide.guideShipperBl, null);
            }
            if(!$scope.ediData.cnCity){
                $scope.ediData.cnCity = getStr($scope.guide.deliveryBl, 1);
                $scope.ediData.cnAddress = getStr($scope.guide.deliveryBl, null);
            }
            if(!$scope.ediData.npCity){
                $scope.ediData.npCity = getStr($scope.guide.guideNotifyBl, 1);
                $scope.ediData.npAddress = getStr($scope.guide.guideNotifyBl, null);
            }
            $scope.uppperCase();
        }

        function getStr(inStr, line) {
            if (!inStr) {
                return null;
            }
            var strs = inStr.split("\n");
            if(strs.length == 0 || !strs[0]){
                return null;
            }
            if(line == 1){
                return strs[0];
            }
            else {
                return inStr.replace(strs[0], "");
            }
        }


        $scope.clear = function () {
            $uibModalInstance.close(refreshRequired);
        }

        $scope.cargoSmartBr = function() {
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if (!$scope.guide.polId){
                layer.msg('The code of Port of Load is null');
                return;
            }
            if (!$scope.guide.podId){
                layer.msg('The code of Port of Discharge is null');
                return;
            }
            if (!$scope.guide.destId){
                layer.msg('The code of Final destination is null');
                return;
            }
            if (!$scope.guide.booking.carrierCode){
                layer.msg('The code of carrier is null');
                return;
            }
            if (!$scope.ediData.cnName && $scope.guide.freightTerm == "CCT"){
                layer.msg('The cnee code is null');
                return;
            }
            if (!$scope.ediData.shName && $scope.guide.freightTerm == "PPD"){
                layer.msg('The shipper code is null');
                return;
            }

            if ($scope.guide.podId.substring(0, 2) == "US" || $scope.guide.podId.substring(0, 2) == "CA"){
                if (!$scope.guide.amsNum && $scope.isSi){
                    layer.msg('The AMS/ACI code is required for US/CA Shipments');
                    return;
                }
                if (!$scope.guide.contractNum){
                    layer.msg('The Contact # is required for US/CA Shipments');
                    return;
                }
            }
            if ($scope.guide.podId.substring(0, 2) == "DE" || $scope.guide.podId.substring(0, 2) == "AR" || $scope.guide.podId.substring(0, 2) == "UY"){
                if (!$scope.guide.booking.hsCode){
                    layer.msg("The HS code is required for " + $scope.guide.podId.substring(0, 2) +" Shipments");
                    return;
                }
            }
            if(getStrRow($scope.ediData.shAddress) > 4){
                layer.msg("Shipper address can't over 4 rows.");
                return;
            }
            if(getStrRow($scope.ediData.cnAddress) > 4){
                layer.msg("Conginee address can't over 4 rows.");
                return;
            }
            if(getStrRow($scope.ediData.npAddress) > 4){
                layer.msg("Notify address can't over 4 rows.");
                return;
            }
            // if(getStrRow($scope.guide.goodsDiscription) > 8){
            //     layer.msg("Discription can't over 8 rows.");
            //     return;
            // }
            if(getStrRow($scope.guide.mark) > 12){
                layer.msg("Discription can't over 12 rows.");
                return;
            }

            if(!$scope.isSi){
                $scope.ediData.brStatus = "BR SENT";
                $scope.ediData.brUpdatedTime = new Date();
            }
            else {
                $scope.ediData.siStatus = "SI SENT";
                $scope.ediData.siUpdatedTime = new Date();
            }
            $scope.isSaving = true;

            var ediDataDTO = {};
            ediDataDTO.guide = angular.copy($scope.guide);
            ediDataDTO.ediData = angular.copy($scope.ediData);
            Guide.pilEdi({isSi: $scope.isSi}, ediDataDTO, onSendSuccess, onSendError);
        }

        var onSendSuccess = function(result) {
            result.soNum = $scope.guide.soNum;
            result.podName = $scope.guide.podName;
            result.vsl = $scope.guide.vsl;
            result.voy = $scope.guide.voy;
            result.releaseWay = $scope.guide.releaseWay;
            $scope.isSaving = false;
            $uibModalInstance.close(result);
        };
        var onSendError = function(result) {
            $scope.isSaving = false;
            if (result.status == 403){
                $rootScope.OPS_ALERT($translate.instant('global.chineseContainedAlert') + ": " + result.data.filepath);
                // $rootScope.OPS_ALERT(result.filepath);
            }
            else if (result.status == 406){
                layer.msg($translate.instant('autopsApp.guide.notAvailableAlert'));
            } else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        };
        $scope.save = function(booking) {
            $scope.isSaving = true;
            $scope.ediData.guideId = $scope.guide.id;
            if (!angular.equals($scope.ediData, ediDataCopy)){
                if ($scope.ediData.id !== null) {
                    EdiData.update($scope.ediData, onSaveEdiSuccess, onSaveError);
                } else {
                    EdiData.save($scope.ediData, onSaveEdiSuccess, onSaveError);
                }
            }
            else {
                if (angular.equals($scope.guide, guideCopy)) {
                    $scope.isSaving = false;
                    layer.msg($translate.instant('global.no_amendmend'));
                    return;
                };
                Guide.update($scope.guide, onSaveGuideSuccess, onSaveError);
            }
        };

        var onSaveEdiSuccess = function(result) {
            $scope.ediData = result;
            ediDataCopy = angular.copy($scope.ediData);
            if (angular.equals($scope.guide, guideCopy)) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
                return;
            };
            Guide.update($scope.guide, onSaveGuideSuccess, onSaveError);
        };

        var onSaveGuideSuccess = function(result) {
            refreshRequired = true;
            $scope.isSaving = false;
            $scope.guide = result;
            guideCopy = angular.copy($scope.guide);
            layer.msg($translate.instant('global.messages.submitted'));
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;        
            if (result.status == 409) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.expiredAlert'));
            } 
            else if (result.status == 403) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.lockedAlert'));
            } 
            else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        };

        $scope._selected = function($item, which) {
            switch (which) {
                case "gporChoose":
                    $scope.guide.porId = $item.code;
                    break;
                case "gpolChoose":
                    $scope.guide.polId = $item.code;
                    break;
                case "gpodChoose":
                    $scope.guide.podId = $item.code;
                    break;
                case "gdestChoose":
                    $scope.guide.destId = $item.code;
                    break;
            }
        }

        var getStrRow = function(inStr) {
            if (!inStr) {
                return 0;
            }
            var strs = inStr.split("\n");
            return strs.length;
        }

        $scope.uppperCase = function() {
            $scope.ediData.bpName = angular.uppercase(DataUtils.maxLen($scope.ediData.bpName, 10));
            $scope.ediData.bpCity = angular.uppercase(DataUtils.maxLen($scope.ediData.bpCity, 70));
            $scope.ediData.bpAddress = angular.uppercase(DataUtils.checkStrLen($scope.ediData.bpAddress, 35));

            $scope.ediData.shName = angular.uppercase(DataUtils.maxLen($scope.ediData.shName, 10));
            $scope.ediData.shCity = angular.uppercase(DataUtils.maxLen($scope.ediData.shCity, 70));
            $scope.ediData.shAddress = angular.uppercase(DataUtils.checkStrLen($scope.ediData.shAddress, 35));

            $scope.ediData.cnName = angular.uppercase(DataUtils.maxLen($scope.ediData.cnName, 10));
            $scope.ediData.cnCity = angular.uppercase(DataUtils.maxLen($scope.ediData.cnCity, 70));
            $scope.ediData.cnAddress = angular.uppercase(DataUtils.checkStrLen($scope.ediData.cnAddress, 35));
            
            $scope.ediData.npName = angular.uppercase(DataUtils.maxLen($scope.ediData.npName, 10));
            $scope.ediData.npCity = angular.uppercase(DataUtils.maxLen($scope.ediData.npCity, 70));
            $scope.ediData.npAddress = angular.uppercase(DataUtils.checkStrLen($scope.ediData.npAddress, 35));

            $scope.guide.goodsDiscription = angular.uppercase(DataUtils.checkStrLen($scope.guide.goodsDiscription, 30));
            $scope.guide.mark = angular.uppercase(DataUtils.checkStrLen($scope.guide.mark, 20));
        };

        $scope.uppercaseStr = function(obj, fd) {
            obj[fd] = angular.uppercase(obj[fd]);
        }
        $scope.datePickerForEtd = {};
        $scope.datePickerForEtd.status = {
            opened: false
        };
        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };

        $scope.addCtn = function() {
            $scope.guide.guideFclCtns.push({});
        };
        $scope.updatePkgsAndType = function() {
            if ($scope.guide.pkgNum && $scope.guide.booking.pkgType) {
                $scope.guide.booking.pkgsAndType = $scope.guide.pkgNum.toString() + " " + $scope.guide.booking.pkgType;
                $scope.guide.pkgsAndType = $scope.guide.pkgNum.toString() + " " + $scope.guide.booking.pkgType;
            }
        };

        $scope.deleteCtn = function(bookingCtn) {
            if($scope.guide.isClosed){return;}
            if (bookingCtn.id != null) {
                GuideFclCtn.delete({
                    id: bookingCtn.id
                }, function(){
                    var index = $scope.guide.guideFclCtns.indexOf(bookingCtn);
                    if (index > -1) {
                        $scope.guide.guideFclCtns.splice(index, 1);
                    };
                }, function(result){
                    if(result.status == 409){
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.cargoIsOutAlert'), {icon:0});
                    }
                    else {
                        layer.msg($translate.instant('global.delete_fail'));
                    }
                });
            }
            else {
                var index = $scope.guide.guideFclCtns.indexOf(bookingCtn);
                if (index > -1) {
                    $scope.guide.guideFclCtns.splice(index, 1);
                };
            }
        };

        $scope.updateGwVol = function() {
            if ($scope.guide.gw && $scope.guide.vol) {
                $scope.guide.gwVol = $scope.guide.gw.toFixed(2) + "KGS" + "\n" + $scope.guide.vol.toFixed(3) + "CBM";
                $scope.guide.booking.gwVol = $scope.guide.gw.toFixed(2) + "KGS" + "\n" + $scope.guide.vol.toFixed(3) + "CBM";
            } else if ($scope.guide.vol) {
                $scope.guide.gwVol = $scope.guide.vol.toFixed(3) + "CBM";
                $scope.guide.booking.gwVol = $scope.guide.vol.toFixed(3) + "CBM";

            } else if ($scope.guide.gw) {
                $scope.guide.gwVol = $scope.guide.gw.toFixed(2) + "KGS";
                $scope.guide.booking.gwVol = $scope.guide.gw.toFixed(2) + "KGS";
            }
        };

        $scope.checkRemarksLen = function() {
            $scope.ediData.siRemarks = DataUtils.checkStrLen($scope.ediData.siRemarks, 240);
        }
        $scope.guideCtnGwChange = function() {
            if ($scope.guide.guideCtns && $scope.guide.guideCtns.length > 0) {
                var newGwTotal = 0;
                for (var i = 0; i < $scope.guide.guideCtns.length; i++) {
                    if ($scope.guide.guideCtns[i].gw) {
                        newGwTotal = newGwTotal + $scope.guide.guideCtns[i].gw;
                    }
                }
                $scope.guide.gw = newGwTotal.toFixed(2) - 0;
                $scope.updateGwVol();
            }
            updateBookingCtnsGwVol();
        }
        $scope.guideCtnVolChange = function() {
            if ($scope.guide.guideCtns && $scope.guide.guideCtns.length > 0) {
                var newVolTotal = 0;
                for (var i = 0; i < $scope.guide.guideCtns.length; i++) {
                    if ($scope.guide.guideCtns[i].vol) {
                        newVolTotal = newVolTotal + $scope.guide.guideCtns[i].vol;
                    }
                }
                $scope.guide.vol = newVolTotal.toFixed(3) - 0;
                $scope.updateGwVol();
            }
            updateBookingCtnsGwVol();
        }
        $scope.guideCtnPkgsChange = function() {
            if ($scope.guide.guideCtns && $scope.guide.guideCtns.length > 0) {
                var newPkgsTotal = 0;
                for (var i = 0; i < $scope.guide.guideCtns.length; i++) {
                    if ($scope.guide.guideCtns[i].pkgs) {
                        newPkgsTotal = newPkgsTotal + $scope.guide.guideCtns[i].pkgs;
                    }
                }
                $scope.guide.pkgNum = newPkgsTotal;
                $scope.updatePkgsAndType();
            }
            updateBookingCtnsGwVol();
        }
        var updateBookingCtnsGwVol = function() {
            if ($scope.guide.guideFclCtns && $scope.guide.guideFclCtns.length > 0) {
                angular.forEach($scope.guide.guideFclCtns, function(bctn){
                    if (bctn.ctnType) {
                        var gwTtl=0, volTtl=0, pkgTtl=0, pkgType;
                        angular.forEach($scope.guide.guideCtns, function(gctn){
                            if(gctn.ctnType == bctn.ctnType){
                                if(gctn.gw){
                                    gwTtl = gwTtl + gctn.gw;
                                }
                                if(gctn.vol){
                                    volTtl = volTtl + gctn.vol;
                                }
                                if(gctn.pkgs){
                                    pkgTtl = pkgTtl + gctn.pkgs;
                                }
                                if(pkgType){
                                    if(gctn.pkgType && pkgType != gctn.pkgType){
                                        pkgType =  "PKGS";
                                    }
                                }
                                else {
                                    pkgType = gctn.pkgType;
                                }
                            }
                        })
                        bctn.gw = gwTtl.toFixed(2) - 0;
                        bctn.vol = volTtl.toFixed(3) - 0;
                        bctn.pkgNum = pkgTtl.toFixed(2) - 0;
                        bctn.pkgType = pkgType;
                    }
                });
            }
        }
        $scope.bookingCtnGwChange = function() {
            if ($scope.guide.guideFclCtns && $scope.guide.guideFclCtns.length > 0) {
                var newGwTotal = 0;
                angular.forEach($scope.guide.guideFclCtns, function(data){
                    if (data.gw) {
                        newGwTotal = newGwTotal + data.gw;
                    }
                });
                $scope.guide.gw = newGwTotal.toFixed(2) - 0;
                $scope.updateGwVol();
            }
        }

        $scope.bookingCtnVolChange = function() {
            if ($scope.guide.guideFclCtns && $scope.guide.guideFclCtns.length > 0) {
                var newVolTotal = 0;
                angular.forEach($scope.guide.guideFclCtns, function(data){
                    if (data.vol) {
                        newVolTotal = newVolTotal + data.vol;
                    }
                });
                $scope.guide.vol = newVolTotal.toFixed(3) - 0;
                $scope.updateGwVol();
            }
        }

        $scope.generateMark = function() {
            if ($scope.guide.mark || !$scope.guide.booking.mark) {
                $scope.guide.mark = angular.uppercase($scope.guide.mark);
            }
            else {
                $scope.guide.mark = angular.copy($scope.guide.booking.mark);
            }
            $scope.guide.mark = angular.uppercase(DataUtils.checkStrLen($scope.guide.mark, 20));
        }
        $scope.generateGoodsDiscription = function() {
            if (!$scope.guide.goodsDiscription){
                $scope.guide.goodsDiscription = angular.uppercase($scope.guide.cargoName);
            }
            if (!$scope.guide.booking.goodsDiscription){
                $scope.guide.booking.goodsDiscription = angular.uppercase($scope.guide.cargoName);
            }
            if ($scope.guide.goodsDiscription || !$scope.guide.booking.goodsDiscription) {
                $scope.guide.goodsDiscription = angular.uppercase($scope.guide.goodsDiscription);
            }
            else {
                $scope.guide.goodsDiscription = angular.copy($scope.guide.booking.goodsDiscription);
            }
            $scope.guide.goodsDiscription = angular.uppercase(DataUtils.checkStrLen($scope.guide.goodsDiscription, 30));
        }

        $scope.addGCtn = function() {
            $scope.guide.guideCtns.push({
                smsSent: false
            });
        };

        $scope.deleteGCtn = function(ctn) {
            if (ctn.id != null) {
                GuideCtns.delete({
                    id: ctn.id
                });
            };
            var index = $scope.guide.guideCtns.indexOf(ctn);
            if (index > -1) {
                $scope.guide.guideCtns.splice(index, 1);
            };
            var ctnAmt = 0;
            angular.forEach($scope.guide.guideCtns, function(gctn){
                if(gctn.ctnType == ctn.ctnType){
                    ctnAmt++;
                }
            })
            angular.forEach($scope.guide.guideFclCtns, function(bctn){
                if(ctnAmt && bctn.ctnType == ctn.ctnType){
                    bctn.num = ctnAmt;
                    ctnAmt = null;
                }
            })
            updateBookingCtnsGwVol();
        };

        $scope.checkCtnRepeat = function(ctn) {
            if(!ctn.ctnNum){
                return;
            }
            angular.forEach($scope.guide.guideCtns, function(data){
                if (data != ctn && data.ctnNum == ctn.ctnNum){
                    $rootScope.OPS_ALERT(data.ctnNum + ": " + $translate.instant('autopsApp.guide.home.ctnRepeatedAlert'));
                }
            })
        }
        $scope.guideCtnPkgTypeChange = function(pkgType) {
            var pkgsCopy = null;
            var isSamePkg = true;
            angular.forEach($scope.guide.guideCtns, function (data) {
                if (data.pkgType && pkgType != data.pkgType){
                    isSamePkg = false;
                }
            });
            if (isSamePkg){
                $scope.guide.booking.pkgType = pkgType;
            }
            else {
                $scope.guide.booking.pkgType = "PKGS";
            }
            $scope.updatePkgsAndType();
            updateBookingCtnsGwVol();
        }
        $scope.releaseWayChange = function(releaseWay) {
            if (releaseWay == "SWB"){
                $scope.ediData.noOfCopies = 1;
            }
            else {
                $scope.ediData.noOfCopies = 3;
            }
        }
        $scope.datePickerForEta = {};
        $scope.datePickerForEta.status = {
            opened: false
        };
        $scope.datePickerForEtaOpen = function($event) {
            $scope.datePickerForEta.status.opened = true;
        };

        $scope.pilSales = [{
            id: "CN001",
            name: "MORGAN LI"
        }, {
            id: 'CN002',
            name: "SONIA SONG"
        },{
            id: 'CN003',
            name: "HEATON ZHANG"
        },{
            id: 'CN004',
            name: "WINNY WEN"
        },{
            id: 'CN005',
            name: "JORDAN CHENG"
        },{
            id: 'CN006',
            name: "DENNY LIU"
        },{
            id: 'CN007',
            name: "VICTOR ZHANG"
        },{
            id: 'CN008',
            name: "CANDY CHEN"
        },{
            id: 'CN059',
            name: "TIM TAO"
        }];

        $scope.pilSales = [{
            id: "CN001",
            name: "MORGAN LI"
        }, {
            id: 'CN002',
            name: "SONIA SONG"
        },{
            id: 'CN003',
            name: "HEATON ZHANG"
        },{
            id: 'CN004',
            name: "WINNY WEN"
        },{
            id: 'CN005',
            name: "JORDAN CHENG"
        },{
            id: 'CN006',
            name: "DENNY LIU"
        },{
            id: 'CN007',
            name: "VICTOR ZHANG"
        },{
            id: 'CN008',
            name: "CANDY CHEN"
        },{
            id: 'CN059',
            name: "TIM TAO"
        }];

        $scope.paymentTerms = [{
            id: "POC-PPSZ",
            name: "海运费和LOCAL 都付PIL深圳账户"
        }, {
            id: 'POC-PPHK',
            name: "海运费和LOCAL 都付PIL香港账户"
        },{
            id: 'POC-CCSZ',
            name: "海运费到付 LOCAL预付深圳"
        },{
            id: 'POC-CCHK',
            name: "海运费到付 LOCAL预付香港"
        }];


        $scope.siPreview = function() {
            if(getStrRow($scope.ediData.shAddress) > 4){
                layer.msg("Shipper address can't over 4 rows.");
                return;
            }
            if(getStrRow($scope.ediData.cnAddress) > 4){
                layer.msg("Conginee address can't over 4 rows.");
                return;
            }
            if(getStrRow($scope.ediData.npAddress) > 4){
                layer.msg("Notify address can't over 4 rows.");
                return;
            }
            // if(getStrRow($scope.guide.goodsDiscription) > 8){
            //     layer.msg("Discription can't over 8 rows.");
            //     return;
            // }
            if(getStrRow($scope.guide.mark) > 12){
                layer.msg("Discription can't over 12 rows.");
                return;
            }

            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.reportType = 630;
            guideReportDTO.booking.noOfCopies = $scope.ediData.noOfCopies;

            guideReportDTO.guideShipperBl = $scope.ediData.shCity + "\n" + $scope.ediData.shAddress;
            guideReportDTO.deliveryBl = $scope.ediData.cnCity + "\n" + $scope.ediData.cnAddress;
            guideReportDTO.guideNotifyBl = $scope.ediData.npCity + "\n" + $scope.ediData.npAddress;

            guideReportDTO.opType = 1;
            guideReportDTO.fileType = 1;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                var file = new Blob([result.data], {
                    type: 'application/pdf'
                });
                if (file.size == 0) {
                    layer.msg($translate.instant('global.loadFileFailed'));
                    return;
                }
                var fileURL = URL.createObjectURL(file);
                var previewDialog = layer.open({
                    type: 2,
                    area: ['1000px', '680px'],
                    maxmin: true,
                    title: $translate.instant("entity.action.preview"),
                    shade: 0.3,
                    moveType: 0,
                    shift: 0,
                    content: fileURL
                });
            }, function(error) {

            });
        }
        $scope.textPop = function(text, efd, gfd) {
            $uibModal.open({
                templateUrl: 'app/entities/ops-change-log/ops-change-log-delete-dialog.html',
                controller: 'OpsChangeLogDeleteController',
                controllerAs: 'vm',
                // backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return text;
                    }]
                }
            }).result.then(function(result) {
                if(efd){
                    $scope.ediData[efd] = result;
                }
                else{
                    $scope.guide[gfd] = result;
                }
            }, function() {});
        }

    }
})();
