(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsCostQidianController',OpsCostQidianController);

    OpsCostQidianController.$inject = ['$rootScope', '$scope', '$timeout', '$translate', '$location', 
    'OpsCost', 'AddressBook', 'DataUtils', 'DateUtils'];

    function OpsCostQidianController($rootScope, $scope, $timeout, $translate, $location, 
        OpsCost, AddressBook, DataUtils, DateUtils) {
        var vm = this;
        vm.freightParams = {            
            requestType: true,
            shipmentType: "AIR",
            plList: [{pkgs: 1}]
        };
        vm.getRates = getRates;
        vm.getPorts = getPorts;
        vm.addOne = addOne;
        vm.removeOne = removeOne;
        vm.isOutList = isOutList;
        vm.setShipmentType = setShipmentType;
        $rootScope.HIDE_NAV = true;
        var qidianParams = {};
        $timeout(function() {
            qidianParams.csrf_token = $location.search().csrf_token;
            qidianParams.erpName = $location.search().erpName;

            var slo = $location.path().indexOf("127.") >= 0?'https://huodai-oa.qdstsy.cn/api/edi/ops-costs/findRates':'https://huodai.qdstsy.cn/api/edi/ops-costs/findRates';
            console.log($location.host())
        })
        function addOne(argument) {
            vm.freightParams.plList.push({pkgs: 1});
        }
        function removeOne(idx) {
            vm.freightParams.plList.splice(idx, 1);
        }
        function setShipmentType(shipmentType) {
            vm.freightParams.shipmentType = shipmentType;
            vm.ports=null;
            vm.ratesList = null;
            vm.freightParams.shiptoPostcode = null;
            vm.freightParams.countryCode = null;
            vm.freightParams.shipperPostalCode = null;
            vm.freightParams.shipperCountryCode = null;
            vm.freightParams.shipperCity = null;
            vm.freightParams.shiptoCity = null;
        }

        function isOutList () {
            if(!vm.freightParams.plList[0].gw && !vm.freightParams.plList[0].vol){
                vm.freightParams.outList = true;
            }
            else if(vm.freightParams.shipmentType == "FCL" || vm.freightParams.shipmentType == "LCLTRUCK" || vm.freightParams.shipmentType == "FCLTRUCK"){
                vm.freightParams.outList = true;
            } 
            else {
                vm.freightParams.outList = false;
            }
            return vm.freightParams.outList;
        }

        function getFreightParams () {
            isOutList();
            var freightParam = angular.copy(vm.freightParams);
            if(vm.freightParams.shipmentType != 'FBA'){
                freightParam.shiptoPostcode = null;
                freightParam.countryCode = null;
                freightParam.shipperPostalCode = null;
                freightParam.shipperCountryCode = null;
            }
            angular.forEach(freightParam.plList, function(pl){
                if(pl.pkgs){
                    if(pl.gw){
                        pl.gwTtl = pl.gw * pl.pkgs;
                    }
                    if(pl.gw){
                        pl.volTtl = DataUtils.round(pl.vol * pl.pkgs, 6);
                    }
                }
            })
            return freightParam;
        }

        vm.ratesList = [];
        function getRates () {
            vm.isSaving = true;
            OpsCost.findRates(qidianParams, getFreightParams(), function(ratesList){
                vm.isSaving = false;
                vm.ratesList = ratesList;
                if (!vm.ratesList || vm.ratesList.length == 0){
                    layer.msg($translate.instant('global.noRecord'));
                }
                else {
                    getCheapestPrice();
                }
            }, function(error){
                vm.isSaving = false;
                $rootScope.ALERT_ERROR(error);
            })
        }

        function getCheapestPrice(){
            vm.cheapestPrice = null;
            angular.forEach(vm.ratesList, function(rate){
                if(!vm.cheapestPrice || rate.freight < vm.cheapestPrice){
                    vm.cheapestPrice = rate.freight;
                }
            })
            vm.ratesList.sort(function(a,b){
                return Math.abs(a.freight)>Math.abs(b.freight)?1:-1;
            });
        }

        $scope.addressBooks = [];
        $scope.loadAddressBooks = function() {
            if ($scope.addressBooks.length == 0) {
                AddressBook.getApiAddressBook(qidianParams, function(result) {
                    $scope.addressBooks = result;
                });
            };
        };
        $scope._onAddressSelected = function(data) {
            vm.freightParams.shiptoPostcode = data.postCode;
            vm.freightParams.countryCode = data.countryCode;
            vm.freightParams.shiptoCity = data.code;
        }
        $scope._onAddressSelected1 = function(data) {
            vm.freightParams.shipperPostalCode = data.postCode;
            vm.freightParams.shipperCountryCode = data.countryCode;
            vm.freightParams.shipperCity = data.code;

        }
        $scope.sizeChange = function(pl) {
            if(!pl.length || !pl.width || !pl.height){
                return;
            }
            pl.vol = pl.length * pl.width * pl.height;
            pl.vol = DataUtils.round(pl.vol/1000000, 4);
        }

        function getPorts () {
            if(vm.ports){
                return;
            }
            OpsCost.getApiPorts(qidianParams, getFreightParams(), function(result){
                vm.ports = result;
            })
        }

    }
})();

