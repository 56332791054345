(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AwbChargesDialogController', AwbChargesDialogController);

    AwbChargesDialogController.$inject = ['$scope', '$timeout', '$rootScope', '$uibModalInstance', '$translate', 'entity', 'DataUtils'];
    
    function AwbChargesDialogController($scope, $timeout, $rootScope, $uibModalInstance, $translate, entity, DataUtils) {

        var chargesTxt = entity.str;
        var amt = entity.amt;
        var oldAmt = entity.oldAmt;
        var guide = entity.guide;
        $scope.ratesList = [];
        $scope.ttlAmt = 0;

        $timeout(function() {
            var rateStrs = [];
            if(chargesTxt){
                rateStrs = chargesTxt.split("\t");
                angular.forEach(rateStrs, function(rateStr) {
                    if(rateStr){
                        var item = rateStr.split(": ");
                        if(item.length == 2){
                            var rate = {};
                            rate.item = item[0];
                            if(oldAmt && oldAmt != amt){
                                rate.payAmount = item[1] - 0;
                                rate.price = DataUtils.round(rate.payAmount/oldAmt, 2);
                                rate.count = amt;
                                rate.payAmount = DataUtils.round(rate.count * rate.price, 2);
                            }
                            else {
                                rate.payAmount = item[1] - 0;
                                rate.count = amt;
                                rate.price = DataUtils.round(rate.payAmount/rate.count, 2);
                            }
                            $scope.ratesList.push(rate);
                        }
                    }                    
                })
                $scope.updateTotal();
            }
            if($scope.ratesList.length < 10){
                for(var i=$scope.ratesList.length; i<10; i++){
                    $scope.ratesList.push({});
                }
            }
        })

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.save = function() {
            chargesTxt = "";
            angular.forEach($scope.ratesList, function(data) {
                if(data.item && data.payAmount){
                    chargesTxt += data.item + ": " + data.payAmount.toFixed(2) + "\t";
                }
            })
            var result = {};
            result.ttlAmt = $scope.ttlAmt.toFixed(2) - 0;
            result.chargesTxt = chargesTxt;
            $uibModalInstance.close(result);       
        }

        $scope.deleteRate = function(rate) {
            var index = $scope.ratesList.indexOf(rate);
            if (index > -1) {
                $scope.ratesList.splice(index, 1);
                $scope.ratesList.push({});
            };
            $scope.updateTotal();
        };

        $scope.amountChange = function(rate) {
            if(rate.price && rate.count){
                rate.payAmount = rate.price * rate.count;
            }
            rate.payAmount = DataUtils.round(rate.payAmount, 2);
            $scope.updateTotal();
        }
        $scope.updateTotal = function() {
            $scope.ttlAmt = 0;
            angular.forEach($scope.ratesList, function(data) {
                if (data.payAmount) {
                    $scope.ttlAmt += data.payAmount;
                }
            })
            $scope.ttlAmt = DataUtils.round($scope.ttlAmt, 2);
        };

        $scope._onItemSelected = function(rate, data) {
            if(data.price){
                rate.price = data.price;
            }
            if (data.unit) {
                rate.unit = data.unit;
            }
            rate.count = getUnitCount(rate);
            if(!rate.count){
                rate.count = amt;
            }
            if(rate.price && rate.count){
                rate.payAmount = rate.price * rate.count;
                if(data.max && rate.payAmount > data.max){
                    rate.count = 1;
                    rate.price = rate.payAmount = data.max;
                }
                if(data.min && rate.payAmount < data.min){
                    rate.count = 1;
                    rate.price = rate.payAmount = data.min;
                }
            }
            // rate.count = amt;
            // if(rate.price && rate.count){
            //     rate.payAmount = rate.price * rate.count;
            // }  
            rate.payAmount = DataUtils.round(rate.payAmount, 2);
            $scope.updateTotal();
        };

        var getUnitCount = function(rate) {
            var outNum;
            var gw = guide.gw;
            var vol = guide.vol;
            var pkgNum = guide.pkgNum;
            var chargeWeight = guide.chargeWeight;
            var vw = guide.vw; 

            if (rate.unit == "KG") {
                outNum = gw;
            } else if (rate.unit == "TON") {
                outNum = gw / 1000;
            } else if (rate.unit == "CBM") {
                outNum = vol;
            } else if (rate.unit == "VW") {
                outNum = vw;
            } else if (rate.unit == "CW") {
                outNum = chargeWeight;
            } else if (rate.unit == "PKGS") {
                outNum = pkgNum;
            } else if (rate.unit == "MT" && gw && vol) {
                outNum = guide.gw / 1000;
                if (outNum < vol) {
                    outNum = vol;
                }
            } else if (rate.unit == "RT" && gw && vol) {
                outNum = gw / 363;
                if (outNum < vol) {
                    outNum = vol;
                }
            }
            return outNum;            
        }


}
})();
