(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CheckingInvoiceController', CheckingInvoiceController);

    CheckingInvoiceController.$inject = ['$scope', '$rootScope', '$translate', '$timeout', '$http',
    'Principal', 'GuideRates', 'DateUtils', 'OpsExchangeRate', 'DataUtils', 'AutoCheck'];

    function CheckingInvoiceController($scope, $rootScope, $translate, $timeout, $http,
        Principal, GuideRates, DateUtils, OpsExchangeRate, DataUtils, AutoCheck) {
        
        var guideRates = [];
        $scope.showrates = [];
        var contentCopy;
        
        $timeout(function (){
            initContent();
            getClientChecking();
        });

        $scope.autoChecks = [];
        var getClientChecking = function() {     
            AutoCheck.clientChecking(function(result) {
                $scope.autoChecks = result;                    
            }, function(response) {
            });
        }

        $scope.choosedCheck;
        $scope.selectChecking = function(check) {
            $scope.choosedCheck = check;
            $scope.isSaving = true;
            GuideRates.clientCheckingByAutoCheck(check, function(result) {
                $scope.isSaving = false;
                contentCopy = angular.copy(result);
                if (!result || !result.ratesList || result.ratesList.length == 0) {
                    layer.msg($translate.instant('stats.stat.resultNullAlert'));
                }
                else {
                    initContent();
                }
            }, function(response) {
                $scope.isSaving = false;
            });
        }

        $scope.setChecked = function(content, checked) {
            if(content.clientChecked){
                layer.msg($translate.instant('autopsApp.autoCheck.confirmedAlert'));
                return;
            }
            $scope.isSaving = true;
            GuideRates.setChecked({
                id: content.id,
                clientChecked: checked
            }, function(result) {
                $scope.isSaving = false;
                content.clientChecked = result.clientChecked;     
                layer.msg($translate.instant('autopsApp.autoCheck.confirmedAlert'));           
            }, function(response) {
                $scope.isSaving = false;
            });
        }

        $scope.invoicePopShow = function() {
            layer.msg($translate.instant('global.moduleNotLoad'));
        }

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
        		if (!$rootScope.OPSCURRENCYS){
            		OpsExchangeRate.getAllCurrencys(function(result) {
                		$rootScope.OPSCURRENCYS = result;
            		})
        		}		
            });
        }
        else {
        	if (!$rootScope.OPSCURRENCYS){
            	OpsExchangeRate.getAllCurrencys(function(result) {
               	 	$rootScope.OPSCURRENCYS = result;
            	})
        	}
        }

        $scope.requestDTO = {};
        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }
        $scope._onItemSelected = function(data) {
            $scope.requestDTO.itemId = data.id;
            $scope.typeChange();
        }

        $scope.getContents = function() {
            if (!$scope.requestDTO.etdFrom || !$scope.requestDTO.etdEnd) {
                layer.msg($translate.instant('stats.stat.nullFilterAlert'));
                return;
            }
            if ($scope.requestDTO.etdFrom > $scope.requestDTO.etdEnd) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                return;
            }
            $scope.isSaving = true;
            $scope.requestDTO.enRequried = $translate.instant('global.enCn') == "en";
            GuideRates.clientCheckingInvoice($scope.requestDTO, function(result) {
                $scope.isSaving = false;
                contentCopy = angular.copy(result);
                if (!result || !result.ratesList || result.ratesList.length == 0) {
                    layer.msg($translate.instant('stats.stat.resultNullAlert'));
                }
                else {
                    initContent();
                }
            }, function(response) {
                $scope.isSaving = false;
            });
        }

        $scope.totalList = [];
        var initContent = function(skipCopy) {    
            if(!skipCopy && contentCopy){
                $scope.showrates = angular.copy(contentCopy.ratesList);
                guideRates = angular.copy(contentCopy.guideRatesList);
            }
            if (!$scope.showrates || $scope.showrates.length == 0){
                $scope.showrates = [];
                for (var i = 0; i < 10; i ++){
                    $scope.showrates.push({});
                }
            }
            
            for(var i = $scope.showrates.length - 1; i >= 0; i--){
                if($scope.requestDTO.currency && $scope.requestDTO.currency != $scope.showrates[i].currency){
                    $scope.showrates.splice(i, 1);
                }
                else if($scope.requestDTO.item && $scope.requestDTO.item != $scope.showrates[i].item){
                    $scope.showrates.splice(i, 1);
                }
            }

            angular.forEach($scope.showrates, function(data){
                if(data.jobNum){
                    data.jobNum = data.jobNum.replace("H-", "");
                    data.jobNum = data.jobNum.replace("M-", "");
                }                
            })

            $scope.totalList = [];

            var currencies = new Set();
            angular.forEach($scope.showrates, function(data){
                currencies.add(data.currency);
            })
            currencies.forEach(function(currency){
                var ttl = 0;
                var ttlI = 0;
                angular.forEach($scope.showrates, function(data){
                    if (data.currency == currency && data.totalB){
                        ttl = ttl + data.totalB;
                    }
                })
                if (ttl){
                    var newRate = {};
                    newRate.currency = currency;
                    newRate.total = ttl.toFixed(2) -0;
                    $scope.totalList.push(newRate);
                }
            });
        }

        $scope.typeChange = function() {
            initContent();
        }

        $scope.delete = function(id) {
            for (var i = $scope.showrates.length - 1; i>=0; i--){
                if ($scope.showrates[i].id == id){
                    $scope.showrates.splice(i, 1);
                    break;
                }
            }
            for (var i = guideRates.length - 1; i>=0; i--){
                if (guideRates[i].id == id){
                    guideRates.splice(i, 1);
                    break;
                }
            }
            initContent(true);
        }
        function onSaveError (result) {
            layer.msg($translate.instant('global.messages.submittedfailed'));
        }

        var updateStatus = function(autoCheck, fd, value) {
            var entity = angular.copy(autoCheck);
            entity[fd] = value;
            AutoCheck.updateStatus(entity, function(result){
                autoCheck.jobDone = result.jobDone;
                autoCheck.submited = result.submited;
                autoCheck.slipUploaded = result.slipUploaded;
                autoCheck.writedOff = result.writedOff;
            }, onSaveError);
        }

        $scope.uploadFile = function(file, autoCheck, isSlip) {
            if(!autoCheck.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if (!file) {
                return;
            }
            var fd = new FormData();            
            var filePath = "/" + "autoCheck/" + autoCheck.id+ "/";
            fd.append('filepath', filePath);
            fd.append('file', file);
            fd.append('companyId', $rootScope.account.companyId);
            $http.post(
                'api/ops-files/uploadFile', fd, {
                    transformRequest : angular.identity,
                    headers : {
                        'Content-Type' : undefined
                    },
                }).success(
                function(data) {
                    if (angular.isDefined(data.filepath)) {
                        layer.msg($translate.instant('global.uploadSuccess'));
                    }
                    if(isSlip){
                        updateStatus(autoCheck, 'slipUploaded', true);
                    }
                }).error(function(error) {
                    layer.msg($translate.instant('global.uploadFail'));
            });
        };
        $scope.confirmChecking = function() {
            if($scope.choosedCheck.clientChecked){
                layer.msg($translate.instant('autopsApp.autoCheck.confirmedAlert'));
                return;
            }
            $scope.isSaving = true;
            AutoCheck.confirmChecking($scope.choosedCheck, function(result) {
                $scope.isSaving = false;
                layer.msg($translate.instant('autopsApp.autoCheck.confirmedAlert'));
                $scope.choosedCheck.clientChecked = result.clientChecked;   
                angular.forEach($scope.showrates, function(data){
                    data.clientChecked = result.clientChecked;  
                })                 
            }, function(response) {
                $scope.isSaving = false;
            });
        }





    }
})();