(function () {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GeneratePalletNoController', GeneratePalletNoController);

    GeneratePalletNoController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'JobPallet', 'WmsWarehouse', '$rootScope',
        '$translate'];

    function GeneratePalletNoController($timeout, $scope, $stateParams, $uibModalInstance, entity, JobPallet, WmsWarehouse, $rootScope,
        $translate) {
        var vm = this;

        vm.source = entity;
        vm.clear = clear;
        vm.general = general;


        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function general() {
            JobPallet.jobPalletBarcode(vm.source, {}, function (result) {
                $rootScope.PRINTBYTES(result.data);
            }, function (error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            })
        }
    }
})();
