(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('ImChatFactory', ImChatFactory);

    ImChatFactory.$inject = ['$resource','$q','$http'];
    
    function ImChatFactory($resource,$q,$http) {
    	
    	return $resource('', {}, {
            'getMe': {
            	method:'GET',
    		    url:'api/liveChat/getMe'
            },
            'getRecentContancts': {
            	method:'GET',
    		    url:'api/liveChat/getRecentContancts',
    		    isArray:true
            },
            'getUserInfo':{
            	method:'GET',
            	param:{'userid':'@userid'},
            	url:'api/liveChat/getUserInfo'
            }
    	});
}
})();