(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsNewsDetailController', OpsNewsDetailController);

    OpsNewsDetailController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'OpsNews'];

    function OpsNewsDetailController($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, entity, OpsNews) {
        var vm = this;

        vm.opsNews = entity;
        vm.clear = clear;

        $scope.files = [];
        $timeout(function(){
            if(vm.opsNews.id != null){
                $rootScope.LOADFILES($scope.files, '/opsNews/' + vm.opsNews.id + '/', 54);
            }
        })

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
