(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsCargoFbaController', WmsCargoFbaController);

    WmsCargoFbaController.$inject = ['$scope', '$state', '$rootScope', '$uibModalInstance', '$timeout', '$translate',
        'entity', 'JhiSignature'
    ];

    function WmsCargoFbaController($scope, $state, $rootScope, $uibModalInstance, $timeout, $translate,
        entity, JhiSignature) {

        var vm = this;
        vm.save = save;
        vm.clear = clear;

        vm.ttlPkgs = entity.applicationNum;
        vm.saveFbaList = saveFbaList;
        vm.clearList = clearList;

        
        vm.scanedCode = '';
        vm.correctCount = 0;
        vm.errorCount = 0;
        var jhiSignature = {}
        vm.isSaving = false;

        vm.comparisonList = [];

        var wmsCargoList = [];//wmscargo list
        var fbaList = [];//scaned fba list
        var scanedFbaList = [];

        $timeout(function() {
            vm.isSaving = true;
            JhiSignature.getFbaGuideRemarks({
                guideId: entity.guideId
            }, function(result) {
                vm.isSaving = false;
                jhiSignature = result;
                if (jhiSignature && jhiSignature.signature) {
                    fbaList = angular.fromJson(jhiSignature.signature).scanResult;
                    scanedFbaList = angular.copy(fbaList);
                    doComparison();
                }               
            }, function() {
                vm.isSaving = false;
            });
            initWmsCargoList();
        });

        function initWmsCargoList() {
            var serial = 1;
            var fbaIdSet = new Set();
            angular.forEach(entity.cargoList, function(data) {
                if (data.batchOne && data.pkgs) {
                    fbaIdSet.add(data.batchOne);
                }
            })

            wmsCargoList = [];
            var ttlPkgs = 0;
            fbaIdSet.forEach(function(fbaId){
                ttlPkgs = 0;
                angular.forEach(entity.cargoList, function(data) {
                    if (data.batchOne && data.pkgs && fbaId == data.batchOne) {
                        ttlPkgs += data.pkgs;
                    }
                })
                var newCargo = {};
                newCargo.shipmentId = fbaId;
                newCargo.serial = serial++;
                newCargo.ttlAmt = ttlPkgs;
                wmsCargoList.push(newCargo);
            })

            vm.comparisonList = angular.copy(wmsCargoList);
        }

        function addScaned() {
            if (!vm.scanedCode) {
                return;
            }
            if (!fbaList) {
                fbaList = [];
            }
            if (!scanedFbaList) {
                scanedFbaList = [];
            }
            fbaList.push({shipmentIdSerial: vm.scanedCode});
            scanedFbaList.push({shipmentIdSerial: vm.scanedCode});
            vm.scanedCode = "";
            $translate.refresh();
            $("#field_manualBarCode").focus();
            doComparison();
        }

        vm.correctCount = 0;
        vm.errorCount = 0;
        var scanedSet = new Set();
        function doComparison() {            
            for(var j = vm.comparisonList.length -1; j >= 0; j--){     
                for(var i = scanedFbaList.length -1; i >= 0; i--){
                    //长度不够 无效输入
                    if(scanedFbaList[i].shipmentIdSerial.length < 12){
                        scanedFbaList[i].error = "无效输入(小于12位)";
                        continue;
                    }    
                    if(scanedSet.has(scanedFbaList[i].shipmentIdSerial)){
                        scanedFbaList[i].error = "唯一识别码重复";

                        continue;
                    }
                    scanedSet.add(scanedFbaList[i].shipmentIdSerial);
                  
                    //匹配
                    if(scanedFbaList[i].shipmentIdSerial.substring(0,12) == vm.comparisonList[j].shipmentId){
                        if(!vm.comparisonList[j].count){
                            vm.comparisonList[j].count = 0;
                        }
                        if(vm.comparisonList[j].count < vm.comparisonList[j].ttlAmt){
                            if(!vm.comparisonList[j].shipmentIdSerial){
                                vm.comparisonList[j].shipmentIdSerial = "";
                            }
                            vm.comparisonList[j].shipmentIdSerial = vm.comparisonList[j].shipmentIdSerial + scanedFbaList[i].shipmentIdSerial.substring(12) + " ";
                            vm.comparisonList[j].count++;
                            scanedFbaList.splice(i, 1);
                            vm.correctCount++;                                                   
                        }
                        else {
                            scanedFbaList[i].error = "数量与入仓单不匹配";
                        }
                        continue;                        
                    }
                    scanedFbaList[i].error = "识别码与入仓单不匹配";
                }
            }
            
            angular.forEach(scanedFbaList, function(fba){
                fba.shipmentId = fba.shipmentIdSerial.substring(0,12);
                if(fba.shipmentIdSerial.length >12){
                    fba.shipmentIdSerial = fba.shipmentIdSerial.substring(12);    
                }                            
                fba.count = 1;
                vm.comparisonList.push(fba);
                vm.errorCount++;
            })
            scanedFbaList=[];

            vm.comparisonList.sort(function(a, b) {
                if (b.error) {
                    return 1;
                } else {
                    return -1;
                }
            });
        }

        function clearList() {
            fbaList = [];
            initWmsCargoList();
        }

        function save() {
            // saveFbaList();
            $uibModalInstance.close(vm.correctCount);
        }

        function clear() {
            $uibModalInstance.dismiss();
        }

        $(document).keydown(function(event) {
            // alert(event.keyCode);
            if (event.keyCode == 13) {
                addScaned();
            }
        });

        $timeout(function() {
            $("#field_manualBarCode").focus();
        });

        function onSaveSuccess(result) {
            jhiSignature = result;
            vm.isSaving = false;
            layer.msg($translate.instant('global.save_success'));
        };

        function onSaveError(result) {
            vm.isSaving = false;
            layer.msg($translate.instant('global.save_fail'));
        };

        function saveFbaList() {
            if (!jhiSignature || !jhiSignature.id) {
                jhiSignature.fbaGuideId = entity.guideId;
            }
            var jasonObj = {};
            jasonObj.scanResult = fbaList;
            jhiSignature.signature = angular.toJson(jasonObj);
            vm.isSaving = true;
            JhiSignature.update(jhiSignature, onSaveSuccess, onSaveError);
        };

    }
})();