(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('MixedPlDeleteController',MixedPlDeleteController);

    MixedPlDeleteController.$inject = ['$uibModalInstance', 'entity', 'MixedPl'];

    function MixedPlDeleteController($uibModalInstance, entity, MixedPl) {
        var vm = this;

        vm.mixedPl = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            MixedPl.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
