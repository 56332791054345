
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsCrmDeleteController',OpsCrmDeleteController);

    OpsCrmDeleteController.$inject = ['$uibModalInstance', '$rootScope', '$scope', '$translate', 'entity', 'OpsVender'];

    function OpsCrmDeleteController($uibModalInstance, $rootScope, $scope, $translate, entity, OpsVender) {
        var vm = this;

        vm.batchEntity = entity;
        vm.clear = clear;
        vm.confirm = confirm;
        $scope.isSaving = false;

        $rootScope.loadClerks();
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        $scope.newId = 0;
        function confirm (id) {
            $scope.isSaving = true;
            layer.load(1, {shade: 0.3});
            OpsVender.batchUpdate({
                newId: $scope.newId,
                type: vm.batchEntity.type
            }, vm.batchEntity.opsVenders, function(result){
                $scope.isSaving = false;
                layer.closeAll();
                layer.msg($translate.instant('global.save_success'));
                $uibModalInstance.close(result);
            }, function(error){
                $scope.isSaving = false;
                layer.closeAll();
                layer.msg($translate.instant('global.save_fail'));   
            });
        }

    }
})();
