(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('TrialBalanceController', TrialBalanceController);

    TrialBalanceController.$inject = ['$scope', '$rootScope', '$translate', 
    'JhiLanguageService', 'Principal', 'AccountRef', 'DateUtils', 'AccountVoucher','AccountTitle','VoucherContent', 'OpsFiles', 'DataUtils'];

    function TrialBalanceController($scope, $rootScope, $translate, 
        JhiLanguageService, Principal, AccountRef, DateUtils, AccountVoucher, AccountTitle, VoucherContent, OpsFiles, DataUtils) {

        $scope.ledgerTitle = $translate.instant('autopsApp.accountTitle.trialBalance');

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        }

        $scope.format = "pdf";
        $scope.reportavailalbe = false;
        $scope.fileName = "";
        
        $scope.datePickerForEtd = {};

        $scope.datePickerForEtd.status = {
            opened: false
        };

        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };

        $scope.datePickerForCargoReadyDate = {};

        $scope.datePickerForCargoReadyDate.status = {
            opened: false
        };

        $scope.datePickerForCargoReadyDateOpen = function($event) {
            $scope.datePickerForCargoReadyDate.status.opened = true;
        };

        $scope.filterDTO = {};
        $scope.total = {};
        $scope.isSaving = false;
        $scope.voucherContents = [];
        var refrestCDC = function() {
            if ($scope.voucherContents) {
                $scope.total.openingDnT = 0;
                $scope.total.openingCnT = 0;
                $scope.total.currentDnT = 0;
                $scope.total.currentCnT = 0;
                $scope.total.endingDnT = 0;
                $scope.total.endingCnT = 0;

                for (var i = 0; i < $scope.voucherContents.length; i++) {
                    if ($scope.voucherContents[i].openingDn){
                        $scope.total.openingDnT = $scope.total.openingDnT + $scope.voucherContents[i].openingDn;
                    }
                    if ($scope.voucherContents[i].openingCn){
                        $scope.total.openingCnT = $scope.total.openingCnT + $scope.voucherContents[i].openingCn;
                    }    
                    if ($scope.voucherContents[i].currentDn){
                        $scope.total.currentDnT = $scope.total.currentDnT + $scope.voucherContents[i].currentDn;
                    }
                    if ($scope.voucherContents[i].currentCn){
                        $scope.total.currentCnT = $scope.total.currentCnT + $scope.voucherContents[i].currentCn;
                    }
                    if ($scope.voucherContents[i].endingDn){
                        $scope.total.endingDnT = $scope.total.endingDnT + $scope.voucherContents[i].endingDn;
                    }
                    if ($scope.voucherContents[i].endingCn){
                        $scope.total.endingCnT = $scope.total.endingCnT + $scope.voucherContents[i].endingCn;
                    }
                }
                $scope.total.openingDnT = DataUtils.formatTwoDigits($scope.total.openingDnT);
                $scope.total.openingCnT = DataUtils.formatTwoDigits($scope.total.openingCnT);
                $scope.total.currentDnT = DataUtils.formatTwoDigits($scope.total.currentDnT);
                $scope.total.currentCnT = DataUtils.formatTwoDigits($scope.total.currentCnT);
                $scope.total.endingDnT = DataUtils.formatTwoDigits($scope.total.endingDnT);
                $scope.total.endingCnT = DataUtils.formatTwoDigits($scope.total.endingCnT);

                for (var i = 0; i < $scope.voucherContents.length; i++) {
                    $scope.voucherContents[i].openingDn = DataUtils.formatTwoDigits($scope.voucherContents[i].openingDn);
                    $scope.voucherContents[i].openingCn = DataUtils.formatTwoDigits($scope.voucherContents[i].openingCn);
                    $scope.voucherContents[i].currentDn = DataUtils.formatTwoDigits($scope.voucherContents[i].currentDn);
                    $scope.voucherContents[i].currentCn = DataUtils.formatTwoDigits($scope.voucherContents[i].currentCn);
                    $scope.voucherContents[i].endingDn = DataUtils.formatTwoDigits($scope.voucherContents[i].endingDn);
                    $scope.voucherContents[i].endingCn = DataUtils.formatTwoDigits($scope.voucherContents[i].endingCn);
                }
            }
        }

        var initCContent = function() {       
            if ($scope.voucherContents) {
                for (var j = $scope.voucherContents.length; j <= 10; j++) {
                    $scope.voucherContents.push({});
                }

            } else {
                $scope.voucherContents = [];
                for (var j = 0; j <= 10; j++) {
                    $scope.voucherContents.push({});
                }
            }            
        }

        initCContent();
        $scope.getContetns = function () {
            if (!$scope.filterDTO.dateFrom || !$scope.filterDTO.dateTo){
                layer.msg($translate.instant('autopsApp.accountTitle.nullFilterAlert'));                
                return;
            }
            if (DateUtils.compareDate($scope.filterDTO.dateFrom, $scope.filterDTO.dateTo) > 0) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                return;            
            }
            if (DateUtils.getDaysDiff($scope.filterDTO.dateFrom, $scope.filterDTO.dateTo) > 365) {
                layer.msg($translate.instant('global.messages.etdPeriodAlert'));
                return;            
            }

            if ($scope.filterDTO.isAudited == null){
                $scope.filterDTO.isAudited = false;
            }
            $scope.filterDTO.companyId = $rootScope.account.companyId;
            $scope.isSaving = true;
            $scope.voucherContents = [];
            var filterDTOParam = angular.copy($scope.filterDTO);
            VoucherContent.getTrialBalance(filterDTOParam, function(result) {
                $scope.isSaving = false;
                $scope.voucherContents = result;
                if (!$scope.voucherContents || $scope.voucherContents.length == 0) {
                    layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                    return;
                }
                $scope.reportavailalbe = true;
                refrestCDC();
            }, function(response) {
                $scope.isSaving = false;
                initCContent();
           });
        }
       
        $scope.exportTrialBalance = function(format){
          $scope.format = format;
          $scope.fileName = '';
          $scope.params = {
                 trialBalanceList:$scope.voucherContents,
                 openingDnT:$scope.total.openingDnT,
                 openingCnT:$scope.total.openingCnT,
                 currentDnT:$scope.total.currentDnT,
                 currentCnT:$scope.total.currentCnT,
                 endingDnT:$scope.total.endingDnT,
                 endingCnT:$scope.total.endingCnT
          };
          
          $scope.fileName_btn = layer.open({
              type: 1,
              area: ['300px', '180px'],
              btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')],
              title: $translate.instant('global.fileName'),
              shade: 0.3,
              moveType: 0,
              shift: 0,
              content: $("#templateReportName"),
              success: function(layero, index) {
                  $("#fileName").focus();
              },
              yes: function(index, layero) {
                  if ($scope.fileName == '') {
                      $scope.fileName = 'TrialBalanace';
                  }
                  $scope.generateTrialBalance();
                  layer.close($scope.fileName_btn);
              },
              no: function(index, layero) {
                  layer.close(index);
              }
          });
        }
        
        $("#fileName").keydown(function(event) {
            $scope.keycode = window.event ? event.keyCode : event.which;
            if ($scope.keycode == 13) {
                if ($scope.fileName == '') {
                    $scope.fileName = 'TrialBalanace';
                }
                $scope.generateTrialBalance();
                layer.close($scope.fileName_btn);
            }
        })
        

        $scope.generateTrialBalance = function(){
            var filterDTOParam = angular.copy($scope.filterDTO);
            OpsFiles.previewTrialBalanceReport({dateFrom:filterDTOParam.dateFrom, dateTo:filterDTOParam.dateTo,fileType:$scope.format}, $scope.params, function(result){
              $scope.exportExcelOrPdf(result);
            },function(error){
              layer.msg($translate.instant('autopsApp.accountTitle.viewTrialBalanceFail'));
            });
        }
        
        $scope.exportExcelOrPdf = function(result) {
            if (angular.isDefined($scope.format) && $scope.format == 'excel') {
                var file = new Blob([result.data], {
                    type: 'application/vnd.ms-excel'
                });
                var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
                var aTag = document.createElement("a");
                aTag.download = $scope.fileName + ".xlsx";
                aTag.href = blobURL;
                aTag.click();
            } else {
                var file = new Blob([result.data], {
                    type: 'application/pdf'
                });
                var fileURL = URL.createObjectURL(file);
                var previewDialog = layer.open({
                    type: 2,
                    area: ['1200px', '800px'],
                    maxmin: true,
                    title: $translate.instant("autopsApp.accountTitle.viewTrialBalance"),
                    shade: 0.3,
                    moveType: 0,
                    shift: 0,
                    content: fileURL
                })
            }
        }
       
        
    }
})();
