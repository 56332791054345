(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsCompanyController', OpsCompanyController);

    OpsCompanyController.$inject = ['$scope', '$rootScope', '$state', '$uibModal', '$http', '$translate', 'OpsCarrier', 'WoHead',
    'OpsCompany', 'OpsCompanySearch', 'ReportTemplate', 'ParseLinks', 'Email', 'Account', 'OpsPorts', 'OpsItem', 'AddressBook'];

    function OpsCompanyController($scope, $rootScope, $state, $uibModal, $http,$translate, OpsCarrier, WoHead,
        OpsCompany, OpsCompanySearch, ReportTemplate, ParseLinks, Email, Account, OpsPorts, OpsItem, AddressBook) {

        $scope.opsCompanys = [];
        $scope.predicate = 'id';
        $scope.reverse = false;
        $scope.page = 1;
        $scope.loadAll = function() {
            OpsCompany.query({page: $scope.page-1, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']},function(result,headers) {
               $scope.links = ParseLinks.parse(headers('link'));
               $scope.totalItems = headers('X-Total-Count');
               $scope.opsCompanys = result;
            });
        };
        $scope.loadAll();


        $scope.search = function () {
            OpsCompanySearch.query({query: $scope.searchQuery}, function(result) {
                $scope.opsCompanys = result;
            }, function(response) {
                if(response.status === 404) {
                    $scope.loadAll();
                }
            });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };
        $scope.edit = function (id) {
            $rootScope.id = id;
            $state.go('opsCompany.edit');

        }
        $scope.delete = function (id) {
            $rootScope.id = id;
            $state.go('opsCompany.delete');

        }
        $scope.clear = function () {
            $scope.opsCompany = {
                createdBy: null,
                companyName: null,
                address: null,
                tel: null,
                fax: null,
                email: null,
                website: null,
                createdTime: null,
                companyLogo: null,
                companyAbb: null,
                deposit: null,
                hfSalesId: null,
                blPrefix: null,
                jobPrefix: null,
                id: null
            };
        };
        $scope.viewCompany = function (id) {
            $rootScope.id = id;
            $state.go('opsCompany.detail');
        };
        $scope.opsCompanyEdit = function (id) {
            $rootScope.id = id;
            $state.go('opsCompany.edit');
        };


        $scope.sendChangeLogEmail = function() {
            $scope.email = {};
            $scope.email.subject = "";
            $scope.email.content = "";
            layer.open({
                type: 1,
                area: ['1160px', '690px'],
                maxmin: true,
                title: $translate.instant('entity.email.title'),
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: $("#emailView")
            });
        };
        var getArray = function(address) {
            var addressArray = new Array();
            if (angular.isUndefined(address) || null == address) {
                return addressArray;
            }
            addressArray = address.split(";");
            angular.forEach(addressArray, function(to, index) {
                if (to.trim() == "" || null == to) {
                    addressArray.splice(index, 1);
                }
            });
            return addressArray;
        }
        $scope.sendEmail = function() {
            $scope.isSaving = true;
            var bccArray = getArray($scope.email.bcc);

            Email.sendChangeLogEmail({
                to: new Array(),
                cc: new Array(),
                bcc: bccArray,
                subject: $scope.email.subject,
                content: $scope.email.content,
                attachments: new Array(),
            }, function(result) {
                layer.closeAll();
                $scope.isSaving = false;
                layer.msg($translate.instant('global.email_send_success'));
            }, function(error) {
                $scope.isSaving = false;
                alert(error.status);
            });
        }


        $scope.roleReset = function() {
            layer.prompt({
                formType: 1,
                value: '',
                title: "Init Role"
            }, function(value, index, elem) {
                layer.close(index);
                if (value == "641313"){
                    Email.roleReset(function(result) {
                        $rootScope.OPS_ALERT("Init Role success");
                    }, function(error) {
                        alert(error.status);
                    });
                }
            });
        }

        $scope.delOneCompanyData = function() {
            layer.prompt({
                formType: 1,
                value: '',
                title: "Del One Company Data"
            }, function(value, index, elem) {
                layer.close(index);
                Email.delOneCompanyData({
                    companyId: value
                }, function(result) {
                    $rootScope.OPS_ALERT("Del success");
                }, function(error) {
                    alert(error.status);
                });
            });
        }




        $scope.initSearch = function() {
            layer.prompt({
                formType: 1,
                value: '',
                title: "Init Search"
            }, function(value, index, elem) {
                layer.close(index);
                if (value == "641313"){
                    Email.initSearch(function(result) {
                        $rootScope.OPS_ALERT("Init Search success");
                    }, function(error) {
                        alert(error.status);
                    });
                }
            });

        }
        $scope.clearAccounting = function() {
            layer.prompt({
                formType: 1,
                value: '',
                title: "Cear Accounting"
            }, function(value, index, elem) {
                layer.close(index);
                    Email.clearAccounting({companyId: value}, function(result) {
                        $rootScope.OPS_ALERT("Cear Accounting success");
                    }, function(error) {
                        alert(error.status);
                    });
            });
        }

        $scope.resetBalance = function() {
            layer.prompt({
                formType: 1,
                value: '',
                title: "Reset Balance"
            }, function(value, index, elem) {
                layer.close(index);
                    Email.resetBalance({companyId: value}, function(result) {
                        $rootScope.OPS_ALERT("Reset Balance success");;
                    }, function(error) {
                        alert(error.status);
                    });
            });
        }

        $scope.recharge = function(companyId, vasFee) {
            var hfBill = {};
            hfBill.companyId = companyId;
            hfBill.time = new Date();
            hfBill.type = "RECHARGE";
            hfBill.vasFee = vasFee;
            hfBill.currency = "RMB";
            $uibModal.open({
                templateUrl: 'app/entities/hf-bill/hf-bill-dialog.html',
                controller: 'HfBillDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        return hfBill;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('hfBill');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {

            }, function() {

            });
        }

        $scope.registNewCompany = function (opsCompany) {
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountInvoice/invoice-regist-dialog.html',
                controller: 'InvoiceRegistDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return opsCompany;
                    }]
                }
            }).result.then(function(results) {

            }, function() {

            });

        }

        $scope.deleteAllPorts = function(id) {
            layer.prompt({
                formType: 1,
                value: '',
                title: "Init Role"
            }, function(value, index, elem) {
                layer.close(index);
                if (value == "641313"){
                    OpsPorts.deleteAllPorts({id: id}, function(argument) {
                        layer.msg($translate.instant('global.delete_success'));
                    })
                }
            });
        }

        $scope.deleteAllItems = function(id) {
            layer.prompt({
                formType: 1,
                value: '',
                title: "Init Role"
            }, function(value, index, elem) {
                layer.close(index);
                if (value == "641313"){
                    OpsItem.deleteAllItems({id: id}, function(argument) {
                        layer.msg($translate.instant('global.delete_success'));
                    })
                }
            });
        }

        $scope.deleteAllCarrers = function(id) {
            layer.prompt({
                formType: 1,
                value: '',
                title: "Init Role"
            }, function(value, index, elem) {
                layer.close(index);
                if (value == "641313"){
                    OpsCarrier.deleteAllCarrers({id: id}, function(argument) {
                        layer.msg($translate.instant('global.delete_success'));
                    })
                }
            });
        }
        $scope.delAddressBooks = function(id) {
            layer.prompt({
                formType: 1,
                value: '',
                title: "Init Role"
            }, function(value, index, elem) {
                layer.close(index);
                if (value == "641313"){
                    AddressBook.delAddressBooks({id: id}, function(argument) {
                        layer.msg($translate.instant('global.delete_success'));
                    })
                }
            });
        }

        $scope.goLiveCheck = function(id){
            OpsCompany.goLiveCheck({companyId: id}, function(result){
                $rootScope.DOWNLOAD_TXT(angular.toJson(result, true), "goLiveCheckResult.txt");
            }, function(){
                $rootScope.OPS_ALERT($translate.instant('global.save_fail'));
            });
        }

        $scope.kpiExport = function(){
            WoHead.kpiExport(function(result){
                $rootScope.DOWNLOAD_TXT(angular.toJson(result, true), "kpiResult.txt");
            }, function(){
                $rootScope.OPS_ALERT($translate.instant('global.save_fail'));
            });
        }

        $scope.changePassword = function(opsCompany) {
            if($rootScope.absUrl.indexOf("8080") !== -1){
                $rootScope.CHANGE_PASSWORD(null, opsCompany.createdBy);
                return;
            }
            layer.prompt({
                formType: 1,
                value: '',
                title: "Change Password"
            }, function(value, index, elem) {
                layer.close(index);
                if (value == "641313"){
                    $rootScope.CHANGE_PASSWORD(null, opsCompany.createdBy);
                }
            });
        }





    }
})();
