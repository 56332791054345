(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('OpsChangeLog', OpsChangeLog);

    OpsChangeLog.$inject = ['$resource'];

    function OpsChangeLog ($resource) {
        var resourceUrl =  'api/ops-change-logs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getByTypeId': { 
                method: 'GET', 
                url:'api/ops-change-logs/getByTypeId'
            },    
            'update': { method:'PUT' }
        });
    }
})();
