
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsGoodsBatchDetailController', WmsGoodsBatchDetailController);

    WmsGoodsBatchDetailController.$inject = ['$http', '$scope', '$rootScope', '$uibModalInstance', '$translate', 
    'entity'];

    function WmsGoodsBatchDetailController ($http, $scope, $rootScope, $uibModalInstance, $translate, 
        entity) {
        var vm = this;
        $scope.entity = entity;

        vm.clear = clear;
        vm.uploadFile = uploadFile;
        vm.updateTrackingNum = updateTrackingNum;
        $scope.isSaving = false;

        function uploadFile (file) {            
            if (!file) {return;}            
            $scope.isSaving = true;
            var fd = new FormData();
            fd.append('file', file);
            fd.append('poNums', $scope.entity.poNums);
            fd.append('trackingNums', $scope.entity.trackingNums);
            if($rootScope.account.companyId){
                fd.append('companyId', $rootScope.account.companyId);
            }            
            var loadIndex = layer.load(1, {shade: 0.3});
            $http.post(
                'api/express-parcels/uploadLabelFiles', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) { 
                    layer.close(loadIndex);   
                    $scope.isSaving = false;
                    if(data){
                        data = angular.fromJson(data);
                        if(!data.failedStr){
                            $rootScope.OPS_ALERT("全部更新成功！", {icon: 1});  
                        }
                        else {
                            $rootScope.OPS_ALERT("部分/全部未更新成功，请检查: " + data.failedStr);  
                        }
                    }  
                }).error(function(error) {
                    layer.close(loadIndex); 
                    $scope.isSaving = false;
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));                           
                });
        }
        function updateTrackingNum () {
            if(!$scope.entity.poNums || !$scope.entity.trackingNums){
                $rootScope.OPS_ALERT("PO#和Tracking#不能为空，并需要相互对应关系！");
                return;
            }      
            $scope.isSaving = true;
            var fd = new FormData();
            var url = null;
            if($scope.entity.type == "updateFbaTrackingNum"){
                fd.append('refNums', $scope.entity.poNums);
                fd.append('trackingNums', $scope.entity.trackingNums);
                fd.append('companyId', $rootScope.account.companyId);
                url = 'api/guides/updateMblNum';
            }
            else {
                fd.append('poNums', $scope.entity.poNums);
                fd.append('trackingNums', $scope.entity.trackingNums);
                fd.append('companyId', $rootScope.account.companyId);
                url = 'api/express-parcels/updateMblNumByPoNum';
            }
            var loadIndex = layer.load(1, {shade: 0.3});
            $http.post(url, fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {    
                    $scope.isSaving = false;
                    layer.close(loadIndex); 
                    if(data){
                        data = angular.fromJson(data);
                        $scope.entity.poNums = data.notUpdatedPO;
                        $scope.entity.trackingNums = data.notUpdatedMbl;
                        if(!$scope.entity.poNums && !$scope.entity.trackingNums){
                            $rootScope.OPS_ALERT("全部更新成功！", {icon: 1});  
                        }
                        else {
                            $rootScope.OPS_ALERT("部分/全部未更新成功，请检查！");  
                        }
                    }  
                }).error(function(error) {
                    layer.close(loadIndex); 
                    $scope.isSaving = false;
                    $rootScope.OPS_ALERT("更新失败，请检查PO#和Tracking#的对应关系！");                    
                });
        }

        function clear () {
            $uibModalInstance.close();
        }


    }
})();
