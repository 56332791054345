(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BatchStatusController', BatchStatusController);

    BatchStatusController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$translate', 'entity', 'Guide', 'OpsTrackStatus', 'DateUtils'];
    
    function BatchStatusController($scope, $rootScope, $uibModalInstance, $translate, entity, Guide, OpsTrackStatus, DateUtils) {

        var vm = this;    
        var guides = entity;

        vm.statusId = null;
        vm.status = "";
        vm.statusTime = new Date();
        
        if (!$rootScope.TRACKSTATUS || $rootScope.TRACKSTATUS.length == 0){
            OpsTrackStatus.getAll(function(result) {
                $rootScope.TRACKSTATUS = result;
            });
        }

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.isSaving = false;
        $scope.save = function() {
            $scope.isSaving = true;
            Guide.updateStatusBatch({
                statusTime: vm.statusTime,
                statusTxt: vm.status,
                statusId: vm.statusId
            }, guides, function(result){
                $scope.isSaving = false;
                $uibModalInstance.close();
                layer.msg($translate.instant('global.save_success'));
            }, function(result){
                layer.msg($translate.instant('global.save_fail'));
            })        
        }

        $scope.onstatusSelected = function(data) {
            vm.statusId = data.id;
            vm.status = data.status;
        }  

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }


}
})();
