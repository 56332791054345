(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('InvoiceQueryResultDialogController', InvoiceQueryResultDialogController);

    InvoiceQueryResultDialogController.$inject = ['$uibModalInstance', '$uibModal', 'entity', 'AccountInvoice'];

    function InvoiceQueryResultDialogController ($uibModalInstance, $uibModal, entity, AccountInvoice) {
        var vm = this;        
        vm.queryResult = entity;

        vm.clear = clear;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }



    }
})();
