(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('CrmQuote', CrmQuote);

    CrmQuote.$inject = ['$resource', 'DateUtils'];

    function CrmQuote ($resource, DateUtils) {
        var resourceUrl =  'api/crm-quote/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.quotationTime = DateUtils.convertDateTimeFromServer(data.quotationTime);
                        data.expireDate = DateUtils.convertLocalDateFromServer(data.expireDate);
                    }
                    return data;
                }
            },
            'printQuotation':{
                method:'POST',
                responseType: 'arraybuffer',
                url:'api/crm-quote/printQuotation',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'getAttachment':{
                method:'POST',
                url:'api/crm-quote/getAttachment',
                isArray:true
            },
            'getFilterPage':{
                method:'POST',
                url:'api/crm-quote/getFilterPage',
                isArray: true
            },
            'generateGuide':{
                method:'POST',
                url:'api/crm-quote/generateGuide'
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.expireDate = DateUtils.convertLocalDateToServer(data.expireDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.quotationTime = DateUtils.convertDateTimeFromServer(data.quotationTime);
                        data.expireDate = DateUtils.convertLocalDateFromServer(data.expireDate);
                    }
                    return data;
                }
            },
            'searchQuote':{
                method:'GET',
                url:'api/_search/crm-quote', 
                isArray: true
            },
            'sendEnquiry':{
                method:'GET',
                url:'api/crm-quote/sendEnquiry'
            }
        });
    }
})();
