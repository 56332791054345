(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountingController', AccountingController);

    AccountingController.$inject = ['$scope', '$rootScope', '$translate', '$window', '$uibModal',
    'JhiLanguageService', 'Principal', 'AccountRef', 'DateUtils','AccountVoucher','AccountTitle','VoucherContent', 
    'OpsCompany', 'OpsFiles'];

    function AccountingController($scope, $rootScope, $translate, $window, $uibModal,
        JhiLanguageService, Principal, AccountRef, DateUtils, AccountVoucher, AccountTitle, VoucherContent, 
        OpsCompany, OpsFiles) {
        $scope.filterDTO = {};
        var checkAuth = function(){
            if ($rootScope.HAVENOAUTH('ROLE_DEPARTMENT')){            
                $window.history.back();
                layer.msg($translate.instant('global.forbiddenTeam'));
            }
            $scope.filterDTO.dateFrom = DateUtils.convertLocalDateFromServer($rootScope.account.accountDate);
        }

        Principal.identity().then(function(account) {
            $rootScope.account = account;
            checkAuth();
            OpsCompany.getAccountLocked({companyId: $rootScope.account.companyId}, function(result){
                $scope.filterDTO.accountLocked = result.accountLocked;
                $scope.filterDTO.isAccounted = result.isAccounted;
            })
        })

        $scope.setAccountLocked = function () {
            OpsCompany.setAccountLocked({
                companyId: $rootScope.account.companyId,
                accountLocked: $scope.filterDTO.accountLocked,
            }, function(result){
                $scope.filterDTO.accountLocked = result.accountLocked;
                if($scope.filterDTO.accountLocked){
                    layer.msg($translate.instant('entity.action.locked'));
                }
                else {
                    layer.msg($translate.instant('entity.action.unlocked'));
                }
            })            
        }

        $scope.isSaving = false;
        $scope.isChecked = false;
        $scope.showCheckResult = false;

        $scope.checkBeforeAccounting = function () {
            $scope.isSaving = true;
            $scope.voucherReult = {};
            $scope.filterDTO.companyId = $rootScope.account.companyId;
            layer.load(1, {shade: 0.3});
            AccountVoucher.getAccountingResult($scope.filterDTO, function(result) {
                layer.closeAll(); 
                $scope.voucherReult = result;
                $scope.isSaving = false;
                $scope.showCheckResult = true;
                $scope.isChecked = true;
                $translate.refresh();
            }, function(response) {
                layer.closeAll(); 
                $scope.isSaving = false;
           });
        }

        var onAccountingSuccess = function(result) {
            layer.closeAll(); 
            $scope.isSaving = false;
            $scope.voucherReult = {};
            $scope.isChecked = false;
            $scope.filterDTO.accountLocked = true;
            $scope.filterDTO.isAccounted = true;
            $rootScope.OPS_ALERT($translate.instant('autopsApp.accountTitle.onAccountingSuccess'));
        };

        var onAccountingError = function(result) {
            layer.closeAll(); 
            $scope.isSaving = false;
            if(result.status == 409){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.accountTitle.isAccountingAlert'));
            }
            else {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.accountTitle.onAccountingError'));
            }            
        };
        
        $scope.doAccounting = function () {   
            if($rootScope.ISBETA()){
                layer.msg($translate.instant('global.betaAlert'));
                return;
            }
            if($scope.isSaving){
                return;
            }
            $scope.isSaving = true; 
            if ($scope.voucherReult.notAvailableVouchers && $scope.voucherReult.notAvailableVouchers.length >0){
                layer.msg($translate.instant('autopsApp.accountTitle.handleInvalidAlert'));
                $scope.isSaving = false; 
                return;
            } 
            layer.load(1, {shade: 0.3});
            AccountVoucher.doAccounting($scope.voucherReult.availableVouchers, onAccountingSuccess, onAccountingError);
        }

        $scope.getVoucher = function(voucher, isInvalid) {
            if (!voucher || !voucher.id) {
                return;
            }
            var accountVouchers = $scope.voucherReult.availableVouchers;
            if(isInvalid){
                accountVouchers = $scope.voucherReult.notAvailableVouchers;
            }
            $rootScope.VIEWVOUCHER(voucher.id, accountVouchers);
        };

        
        $scope.getAdjustVoucher = function () {    
            var dateEntity = {};
            dateEntity.title = $translate.instant('autopsApp.accountTitle.chooseAdjustExchangeRateDate');
            dateEntity.date = new Date();

            $uibModal.open({
                templateUrl: 'app/entities/auto-check-item/auto-check-item-detail.html',
                controller: 'AutoCheckItemDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return dateEntity;
                    }]
                }
            }).result.then(function(result) {
                getAdjustVoucher(result.date);
            }, function() {
            });
        }

        var getAdjustVoucher = function (cutDate) {    
            $scope.filterDTO.companyId = $rootScope.account.companyId;
            $scope.filterDTO.cutDate = cutDate;
            $scope.isSaving = true;
            AccountVoucher.getAdjustVoucher($scope.filterDTO, function(result){
                $scope.isSaving = false;
                $rootScope.VIEWVOUCHER(null, null, result);
            }, function(result){
                $scope.isSaving = false;
            });

        }
        $scope.amassChargesExcel = function () {    
            layer.load(1, {shade: 0.3});
            OpsFiles.amassChargesExcel({
                accountDate: DateUtils.convertLocalDateToServer($scope.filterDTO.dateFrom),
                companyId: $rootScope.account.companyId
            }, function(result) {
                layer.closeAll(); 
                $rootScope.DOWNLOADEXCEL(result, $translate.instant('autopsApp.hfBill.bills') + ".xlsx");
            }, function(){
                layer.closeAll(); 
            })
        }    

    }
})();
