(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ChargesSynController', ChargesSynController);

    ChargesSynController.$inject = ['$scope', '$rootScope', '$translate', '$filter', '$uibModal', 
    'JhiLanguageService', 'Principal', 'DateUtils', 'GuideRates', 'OpsFiles'];

    function ChargesSynController($scope, $rootScope, $translate, $filter,  $uibModal, 
        JhiLanguageService, Principal, DateUtils, GuideRates, OpsFiles) {

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        }

        $scope.datePickerForEtd = {};
        $scope.datePickerForEtd.status = {
            opened: false
        };
        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };

        $scope.datePickerForCargoReadyDate = {};
        $scope.datePickerForCargoReadyDate.status = {
            opened: false
        };
        $scope.datePickerForCargoReadyDateOpen = function($event) {
            $scope.datePickerForCargoReadyDate.status.opened = true;
        };

        $scope.filterDTO = {};
        $scope.filterDTO.byBillDate = false;
        $scope.filterDTO.byAcccountDate = false;
        $scope.synResult = null;
        $scope.getContetns = function() {
            if (!$scope.filterDTO.dateFrom || !$scope.filterDTO.dateTo) {
                layer.msg($translate.instant('autopsApp.accountTitle.nullFilterAlert'));
                return;
            }

            var diffDays = DateUtils.getDaysDiff($scope.filterDTO.dateFrom, $scope.filterDTO.dateTo);
            if (diffDays < 0) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                return;
            }
            if (diffDays > 92) {
                layer.msg($translate.instant('global.messages.etdPeriodQuarterAlert'));
                return;
            }

            if ($scope.filterDTO.isAudited == null) {
                $scope.filterDTO.isAudited = false;
            }

            $scope.filterDTO.isEn = false;
            if($translate.instant('global.enCn') == "en"){
                $scope.filterDTO.isEn = true;
            }
            
            $scope.filterDTO.companyId = $rootScope.account.companyId;
            $scope.filterDTO.baseCurrency = $rootScope.account.baseCurrency;
            $scope.isSaving = true;
            layer.load(1, {shade: 0.3});
            GuideRates.getChargesSyn($scope.filterDTO, function(result) {
                layer.closeAll(); 
                $scope.isSaving = false;
                $scope.synResult = result;
                if (!$scope.synResult) {
                    layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                    return;
                }
                initContent();
            }, function(response) {
                layer.closeAll(); 
                $scope.isSaving = false;
                $scope.synResult = null;
                initContent();
            });
        }
        var allUnaccountedRates = [];
        $scope.unchargedLen = null;
        $scope.unmatchedDnLen = null;
        $scope.unmatchedCnLen = null;
        var initContent = function() {
            if (!$scope.synResult) {
                $scope.synResult = {};
                $scope.synResult.synList = [];
                for (var j = 0; j <= 4; j++) {
                    $scope.synResult.synList.push({});
                }
            }
            else {
                allUnaccountedRates = [];
                angular.forEach($scope.synResult.synList, function(syn){
                    angular.forEach(syn.unaccountedDnRates, function(rate){
                        allUnaccountedRates.push(rate);
                    })
                    angular.forEach(syn.unaccountedCnRates, function(rate){
                        allUnaccountedRates.push(rate);
                    })
                })
                $scope.unchargedLen = allUnaccountedRates.length;
                $scope.unmatchedDnLen = $scope.synResult.unMatchDnContents.length;
                $scope.unmatchedCnLen = $scope.synResult.unMatchCnContents.length;
            }
        }
        initContent();
        $scope.synPop = function(ratesList) {
            $uibModal.open({
                templateUrl: 'app/entities/pop-list/syn-pop.html',
                controller: 'SynPopController',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return ratesList;
                    }]
                }
            }).result.then(function(result) {

            }, function(result) {

            });     
        }

        $scope.ratesPop = function(ratesList) {
            $uibModal.open({
                templateUrl: 'app/entities/pop-list/rates-pop.html',
                controller: 'RatesPopController',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        if(ratesList){
                            return ratesList;
                        }
                        else {
                            return allUnaccountedRates;
                        }
                    }]
                }
            }).result.then(function(result) {
            }, function(result) {
            });     
        }

        $scope.batchSubmit = function() {
            if(allUnaccountedRates.length == 0){                            
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            var result = {};
            result.guideRateDtos = allUnaccountedRates;
            $uibModal.open({
                templateUrl: 'app/entities/guideRates/batchRatesSubmit.html',
                controller: 'BatchRatesSubmitController',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return result;
                    }]
                }
            }).result.then(function(result) {}, function(result) {});
        }


        $scope.unmatchedEntryPop = function(contents) {
            $uibModal.open({
                templateUrl: 'app/entities/pop-list/contents-pop.html',
                controller: 'ContentsPopController',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return contents;
                    }]
                }
            }).result.then(function(result) {

            }, function(result) {

            });     
        }

    }
})();