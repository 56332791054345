(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsBillDetailController', OpsBillDetailController);

    OpsBillDetailController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$translate', 'OpsFiles', 'entity'];

    function OpsBillDetailController($scope, $rootScope, $uibModalInstance, $translate, OpsFiles, entity) {
        var vm = this;
        vm.timeParam = {};
        vm.clear = clear;
        vm.exportExcel = exportExcel;
        vm.exportHfBillCharges = exportHfBillCharges;
        vm.exportCheckBills = exportCheckBills;

        function exportHfBillCharges() {  
            layer.load(1, {shade: 0.3});
            OpsFiles.exportHfBillCharges({
                timeFrom: vm.timeParam.timeFrom,
                timeTo: vm.timeParam.timeTo,
                venderId: entity.venderId,
                currency: entity.currency,
                companyId: entity.venderId?null:entity.companyId
            }, function(result) {
                layer.closeAll(); 
                $rootScope.DOWNLOADEXCEL(result, $translate.instant('autopsApp.hfBill.bills') + ".xlsx");
                clear();
            })
        }    

        function exportExcel() {  
            layer.load(1, {shade: 0.3});
            OpsFiles.exportHfBill({
                timeFrom: vm.timeParam.timeFrom,
                timeTo: vm.timeParam.timeTo,
                venderId: entity.venderId,
                currency: entity.currency,
                companyId: entity.venderId?null:entity.companyId
            }, function(result) {
                layer.closeAll(); 
                $rootScope.DOWNLOADEXCEL(result, $translate.instant('autopsApp.hfBill.bills') + ".xlsx");
                clear();
            })
        }    

        function exportCheckBills() {  
            layer.load(1, {shade: 0.3});
            OpsFiles.exportCheckBills({
                timeFrom: vm.timeParam.timeFrom,
                timeTo: vm.timeParam.timeTo,
                venderId: entity.venderId,
                currency: entity.currency,
                companyId: entity.venderId?null:entity.companyId
            }, function(result) {
                layer.closeAll(); 
                $rootScope.DOWNLOADEXCEL(result, $translate.instant('autopsApp.hfBill.bills') + ".xlsx");
                clear();
            })
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }        
        
    }
})();
