(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('JhiSignatureDeleteController', JhiSignatureDeleteController);

    JhiSignatureDeleteController.$inject = ['$scope', '$uibModalInstance', '$translate', 'entity', 'JhiSignature'];
    
	function JhiSignatureDeleteController($scope, $uibModalInstance, $translate, entity, JhiSignature) {

        $scope.jhiSignature = entity;
        $scope.isSaving = false;

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        var onSaveSuccess = function (result) {
            layer.msg($translate.instant('global.messages.submitted'));
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            layer.msg($translate.instant('global.messages.submittedfailed'));
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            JhiSignature.update($scope.jhiSignature, onSaveSuccess, onSaveError);
        };


    }
})();
