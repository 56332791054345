(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('Po', Po);

    Po.$inject = ['$resource', 'DateUtils'];

    function Po ($resource, DateUtils) {
        var resourceUrl =  'api/po/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.poDate = DateUtils.convertLocalDateFromServer(data.poDate);
                    }
                    return data;
                }
            },
            'save': { 
                method:'POST',
                transformRequest: function (data) {
                    data.poDate = DateUtils.convertLocalDateToServer(data.poDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.poDate = DateUtils.convertLocalDateFromServer(data.poDate);
                    }
                    return data;
                }
            },
            'generateWmsIn': { 
                method:'GET',
                url:'api/po/generateWmsIn',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.poDate = DateUtils.convertLocalDateFromServer(data.poDate);
                    }
                    return data;
                }
            },
            'getAllPoVenders': { 
                method:'GET',
                url:'api/po/getAllPoVenders',
                isArray: true
            }
        });
    }
})();
