(function () {
    'use strict';

    angular
        .module('autopsApp')
        .controller('PltEdiDataController', PltEdiDataController);

    PltEdiDataController.$inject = ['$scope', '$state', '$location','$rootScope',
        'AmsEdiData', 'AmsEdiDataSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'WinAES', '$uibModal'];

    function PltEdiDataController($scope, $state, $location,$rootScope,
                                  AmsEdiData, AmsEdiDataSearch, ParseLinks, AlertService, pagingParams, paginationConstants, WinAES, $uibModal) {
        var path = $location.path();
        $scope.manifestType = "PldHd";
        var vm = this;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.deleteIt = deleteIt;
        vm.updateIt = updateIt;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        $scope.elineShowStatusList = ["CCAM_NEW_WAITING", "CCAM_NEW_SUCCESS", "CCAM_NEW_FAILURE", "CCAM_UPDATE_WAITING", "CCAM_UPDATE_SUCCESS", "CCAM_UPDATE_FAILURE", "CCAM_DELETE_WAITING", "CCAM_DELETE_SUCCESS", "CCAM_DELETE_FAILURE"];

        loadAll();

        function loadAll() {
            if (vm.searchQuery) {
                AmsEdiDataSearch.query({
                    manifestType: $scope.manifestType,
                    query: vm.searchQuery,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                AmsEdiData.query({
                    manifestType: $scope.manifestType,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.amsEdiData = data;
                vm.page = pagingParams.page;
                angular.forEach(vm.amsEdiData, function (data) {
                    if (data.stsJson) {
                        data.stsObj = angular.fromJson(data.stsJson);
                        if (Array.isArray(data.stsObj) && data.stsObj.length > 0) {
                            data.stsObj = data.stsObj[data.stsObj.length - 1].status;
                        }
                    }
                    data.encodeId = WinAES.encode(data.id.toString());
                })
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function deleteIt(encodeId) {
            $uibModal.open({
                templateUrl: 'app/entities/ams-edi-data/ams-edi-data-delete-dialog.html',
                controller: 'AmsEdiDataDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: ['AmsEdiData', 'WinAES', function (AmsEdiData, WinAES) {
                        return AmsEdiData.get({id: WinAES.decode(encodeId)}).$promise;
                    }]
                }
            }).result.then(function () {
                loadAll();
            }, function () {
            });
        }

        function updateIt(guideId) {
            // if($rootScope.HAVENOAUTH('AU_ASSIST_CHARGES_CONFIRM')){
            //     $rootScope.FORBIDDEN_BY_ALERT('AU_ASSIST_CHARGES_CONFIRM');
            //     return;
            // }
            AmsEdiData.updatePltOrderStatus({
                guideId: guideId
            }, function(result) {
                layer.msg($translate.instant('global.messages.submitted'));
            }, $rootScope.ALERT_ERROR);
        }

    }
})();
