(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('TemplateGuideController', TemplateGuideController);

    TemplateGuideController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$translate', 'entity', 'GuideTemplate'];
    
    function TemplateGuideController($scope, $rootScope, $uibModalInstance, $translate, entity, GuideTemplate) {


        $scope.isSaving = false; 
        $scope.guideTemplate = entity;

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.save = function() {
            if (!$scope.guideTemplate.code || !$scope.guideTemplate.remarks) {
                layer.msg($translate.instant('global.messages.fillBasicInfo'));
                return;
            }
            $scope.isSaving = true;
            if ($scope.guideTemplate.id != null) {
                GuideTemplate.update($scope.guideTemplate, onTempalteSaveSuccess, onTempalteSaveError);
            } else {
                GuideTemplate.save($scope.guideTemplate, onTempalteSaveSuccess, onTempalteSaveError);
            }
        }

        var onTempalteSaveSuccess = function(result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('global.save_success'));
            $uibModalInstance.close(result);
        }
        var onTempalteSaveError = function(result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('global.save_fail'));
        }
       
        $scope.uppergTemplateCode = function(code) {
            $scope.guideTemplate.code = angular.uppercase(code);
        };



}
})();
