(function () {
    'use strict';

    angular.module('autopsApp').controller('OpsExchangeSearchController',
        OpsExchangeSearchController);

    OpsExchangeSearchController.$inject = ['$scope', '$uibModalInstance', '$rootScope', 'entity', 'OpsExchangeRate'];

    function OpsExchangeSearchController($scope, $uibModalInstance, $rootScope, entity, OpsExchangeRate) {

        $scope.searchParams = entity;
        var vm = this;
        vm.isSaving = false;
        vm.clear = clear;

        vm.allCurrencys = ["", "RMB", "USD", "EUR", "HKD", "JPY", "INR", "GBP", "AUD", "THB", "DHS", "SGD", "TWD", "MYR", "NOK",
            "DKK", "CAD", "NZD", "SAR", "IDR", "NGN", "TZS", "KWD", "CHF", "LKR", "VND", "BRL", "SEK", "KRW", "ZAR", "MMK"];

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.salesFilter = function (item) {
            return item.authorities.indexOf("ROLE_SALES") !== -1;
        };

        $scope.searchRequests = function () {
            vm.isSaving = true;
            OpsExchangeRate.advancedSearch($scope.searchParams, function (result) {
                vm.isSaving = false;
                $uibModalInstance.close(result);
            }, $rootScope.ALERT_ERROR);
            vm.isSaving = false;
        };

        $scope.setStatus = function (fd, value) {
            $scope.searchParams[fd] = value;
        }
    }
})();
