(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('EndingTransferController', EndingTransferController);

    EndingTransferController.$inject = ['$scope', '$rootScope', '$translate', '$window', '$uibModal', 
    'JhiLanguageService', 'Principal', 'AccountRef', 'DateUtils','AccountVoucher','AccountTitle','VoucherContent'];

    function EndingTransferController($scope, $rootScope, $translate, $window, $uibModal, 
        JhiLanguageService, Principal, AccountRef, DateUtils, AccountVoucher, AccountTitle, VoucherContent) {
        $scope.filterDTO = {};
        var checkAuth = function(){
            if ($rootScope.HAVENOAUTH('ROLE_DEPARTMENT')){            
                $window.history.back();
                layer.msg($translate.instant('global.forbiddenTeam'));
            }
            $scope.filterDTO.dateFrom = DateUtils.convertLocalDateFromServer($rootScope.account.accountDate);
        }
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                checkAuth();
            });
        }
        else {
            checkAuth();
        }



        $scope.isSaving = false;
        $scope.showCheckResult = false;
        $scope.showVoucher = false;
        $scope.checkedOk = false;
        $scope.voucherOk = false;
        $scope.voucherReult = {};

        $scope.checkBeforeTransfer = function () {
            if (!$scope.filterDTO.dateFrom) {
                layer.msg($translate.instant('autopsApp.accountTitle.monthNullAlert'));
                return;
            }
            $scope.isSaving = true;
            $scope.voucherReult = {};
            $scope.filterDTO.companyId = $rootScope.account.companyId;
            layer.load(1, { shade: 0.3 });
            AccountVoucher.getAccountTransfer($scope.filterDTO, function(result) {
                layer.closeAll();
                $scope.voucherReult = result;
                $scope.isSaving = false;
                $scope.showCheckResult = true;
                $scope.showVoucher = false;

                if ($scope.voucherReult.cnInValidList.length ==0 && $scope.voucherReult.dnInValidList.length ==0){
                    $scope.checkedOk = true;
                    $scope.voucherOk = false;
                }
                else {
                    $scope.checkedOk = false;
                    $scope.voucherOk = false;
                }       
                $translate.refresh();
            }, function(response) {
                $scope.isSaving = false;
                $rootScope.ALERT_ERROR(response);
           });
        }


        var onPrevewSuccess = function(result) {
            $scope.accountVoucher = result;
            initContent();
            $scope.voucherOk = true;
            $scope.checkedOk = false;
            $scope.showCheckResult = false;
            $scope.showVoucher = true;
            $scope.isSaving = false;
        };

        var onPreviewError = function(result) {
            $scope.voucherOk = false;
            $scope.checkedOk = true;
            $scope.showCheckResult = true;
            $scope.showVoucher = false;
            $scope.isSaving = false;
        };
        
        $scope.previewTransfer = function () {    
            if ($scope.voucherReult.notAvailableVouchers && $scope.voucherReult.notAvailableVouchers.length >0){
                layer.msg($translate.instant('autopsApp.accountTitle.handleInvalidAlert'));
                return;
            } 

            $scope.filterDTO.companyId = $rootScope.account.companyId;

            $scope.isSaving = true;
            AccountVoucher.accountTransferPrevew($scope.filterDTO, onPrevewSuccess, onPreviewError);
        }

        var onTransferSuccess = function(result) {
            $scope.voucherOk = false;
            $scope.checkedOk = false;
            $scope.showVoucher = false;
            $rootScope.OPS_ALERT($translate.instant('autopsApp.accountTitle.onTransferSuccess'));
        };

        var onTransferError = function(result) {
            $scope.voucherOk = false;
            $scope.checkedOk = false;
            $scope.showVoucher = false;
        };

        $scope.doTransfer = function () {    
            if($rootScope.ISBETA()){
                layer.msg($translate.instant('global.betaAlert'));
                return;
            }
            if ($scope.accountVoucher.dnTotal != $scope.accountVoucher.cnTotal && $scope.accountVoucher.dnTotal !=0) {
                layer.msg($translate.instant('autopsApp.accountTitle.invalidVoucher'));
                return;
            }      

            for (var i = $scope.accountVoucher.voucherContents.length - 1; i >= 0; i--) {
                if (!$scope.accountVoucher.voucherContents[i].dn && !$scope.accountVoucher.voucherContents[i].cn) {
                    $scope.accountVoucher.voucherContents.splice(i, 1);
                }
                else {
                    $scope.accountVoucher.voucherContents[i].isAudited = true;
                }
            }
            
            $scope.accountVoucher.isAudited = true;

            $scope.isSaving = true;
            AccountVoucher.doAccountTransfer($scope.accountVoucher, onTransferSuccess, onTransferError);
        }

        var initContent = function() {
            if ($scope.accountVoucher.voucherContents) {
                for (var j = $scope.accountVoucher.voucherContents.length; j <= 5; j++) {
                    $scope.accountVoucher.voucherContents.push({});
                }

            } else {
                $scope.accountVoucher.voucherContents = [];
                for (var j = 0; j <= 5; j++) {
                    $scope.accountVoucher.voucherContents.push({});
                }
            }
            refrestDtCt();
            refrestDC();
        }
        // 刷新借贷总额
        var returnString = function(inString) {
            var outString = inString.toString();
            var stringArray = outString.split(".");
            outString = "";
            if (stringArray.length > 1) {
                var leng = stringArray[1].length;
                if (leng >= 2) {
                    outString = stringArray[1][0] + stringArray[1][1]
                } else if (leng == 1) {
                    outString = stringArray[1][0] + "0";
                } else if (leng == 0) {
                    outString = "00";
                }
                outString = stringArray[0] + outString;
            } else {
                outString = stringArray[0] + "00";
            }

            if (outString[0] == "0") {
                outString = outString.substr(1);
            }

            if (outString[0] == "0") {
                outString = outString.substr(1);
            }

            return outString;
        }

        var refrestDtCt = function() {
            var dt = 0;
            var ct = 0;
            if ($scope.accountVoucher.voucherContents) {
                for (var i = 0; i < $scope.accountVoucher.voucherContents.length; i++) {
                    if ($scope.accountVoucher.voucherContents[i].dn) {
                        dt = dt + $scope.accountVoucher.voucherContents[i].dn;
                    }
                    if ($scope.accountVoucher.voucherContents[i].cn) {
                        ct = ct + $scope.accountVoucher.voucherContents[i].cn;
                    }
                }
            }
            $scope.accountVoucher.dnTotal = dt.toFixed(2);
            $scope.accountVoucher.cnTotal = ct.toFixed(2);

            $scope.accountVoucher.dt = returnString($scope.accountVoucher.dnTotal.toString());
            $scope.dtLen = $scope.accountVoucher.dt.length;

            $scope.accountVoucher.ct = returnString($scope.accountVoucher.cnTotal.toString());
            // $scope.accountVoucher.ct = "¥" + $scope.accountVoucher.ct;
            $scope.ctLen = $scope.accountVoucher.ct.length;
        }
            // 刷新借贷明细
        var refrestDC = function() {
            if ($scope.accountVoucher.voucherContents) {
                for (var i = 0; i < $scope.accountVoucher.voucherContents.length; i++) {
                    if ($scope.accountVoucher.voucherContents[i].dn) {
                        $scope.accountVoucher.voucherContents[i].d = returnString($scope.accountVoucher.voucherContents[i].dn.toString());
                        $scope.accountVoucher.voucherContents[i].dLen = $scope.accountVoucher.voucherContents[i].d.length;
                    }
                    if ($scope.accountVoucher.voucherContents[i].cn) {
                        $scope.accountVoucher.voucherContents[i].c = returnString($scope.accountVoucher.voucherContents[i].cn.toString());
                        $scope.accountVoucher.voucherContents[i].cLen = $scope.accountVoucher.voucherContents[i].c.length;
                    }
                }
            }
        }
        $scope.getVoucher = function(content, tag) {
            if (null == content) {
                return;
            }
            VoucherContent.getVoucher({
                id: content.id
            }, function(result) {
                var voucherContents = $scope.voucherReult.dnValidList;
                if(tag == "dnInvalid"){
                    voucherContents = $scope.voucherReult.dnInValidList;
                }
                else if(tag == "cnValid"){
                    voucherContents = $scope.voucherReult.cnValidList;
                }
                else if(tag == "cnInvalid"){
                    voucherContents = $scope.voucherReult.cnInValidList;
                }
                $rootScope.VIEWCONTENTVOUCHER(content.id, voucherContents, result);
            });
        };

    }
})();
    
