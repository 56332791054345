(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('BookingTruck', BookingTruck);

    BookingTruck.$inject = ['$resource','DateUtils'];
    
    function BookingTruck($resource, DateUtils) {
        return $resource('api/bookingTrucks/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    for(var i=0; i<data.length; i++){
                        data[i].loadTime = DateUtils.convertDateTimeFromServer(data[i].loadTime);
                        data[i].orderTime = DateUtils.convertDateTimeFromServer(data[i].orderTime);
                    }                    
                    return data;
                }
            },
            'updateOne': { 
                method:'PUT', 
                url:'api/bookingTruck',
                isArray: false,
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    data.loadTime = DateUtils.convertDateTimeFromServer(data.loadTime);    
                    data.orderTime = DateUtils.convertDateTimeFromServer(data.orderTime);            
                    return data;
                } 
            },
            'getOne': {
                method:'GET',
                url:'api/bookingTruck/:id',
                params:{id:'@id'},
                isArray:false,
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    data.loadTime = DateUtils.convertDateTimeFromServer(data.loadTime);    
                    data.orderTime = DateUtils.convertDateTimeFromServer(data.orderTime);            
                    return data;
                }
            },
            'save': { 
                method:'POST', 
                isArray: false ,
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    data.loadTime = DateUtils.convertDateTimeFromServer(data.loadTime);    
                    data.orderTime = DateUtils.convertDateTimeFromServer(data.orderTime);                              
                    return data;
                } 
            },
            'update': { method:'PUT', isArray: true }
        });
    }
})();
