(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('SubTrackingController', SubTrackingController);
    SubTrackingController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'entity'];

    function SubTrackingController ($timeout, $scope, $uibModalInstance, entity) {
        var vm = this;
        vm.clear = clear;
        vm.autoCheckDto = entity;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }        
        vm.ttlLine = {};
        $timeout(function() {
            vm.ttlLine.freightAmt = 0;
            angular.forEach(vm.autoCheckDto.autoCheckItems, function(data){
                if(data.amount){
                    vm.ttlLine.freightAmt += data.amount;
                    vm.ttlLine.currency = data.currency;
                }
            })
            vm.ttlLine.freightAmt = vm.ttlLine.freightAmt.toFixed(2) - 0;
        })
    }
})();
