(function() {
    'use strict';

    angular
        .module('autopsApp')
        .directive('logoUpload', logoUpload);
    
    logoUpload.$inject = ['$parse', '$http', '$translate'];
    
function logoUpload($parse,$http,$translate) {
	
	function contains(file) {
		file = angular.lowercase(file);
	    var regx  = new Array('jpg','png','jpeg','bmp','tiff');
	    for(var m = 0 ; m < regx.length; m++){
	    	if(file == regx[m]){
	    		return true;
	    	}
	    }
	    return false;
	}
	
  return {
    restrict: 'AE',
    scope:{
    	logo:'=',
        setLogo:'&',
        hasLogo:'=',
        show:'&'
    },
    link: function(scope, element, attrs, ngModel) {
    	
      scope.hasFile = false;
      var model = $parse(attrs.logoUpload);
      var modelSetter = model.assign;
      
      scope.$watch('hasLogo',function(uploaded){
    	  if(uploaded){
    		  angular.forEach(angular.element("input[name='logo']"),function (inputElem) {
    			  angular.element(inputElem).val(null);
    			  scope.hasLogo = false;
        	  });
    	  }
      });
      element.bind('change', function(event){
    	  
	    	if(element[0].files[0]==undefined){
	    		return;
	    	}
	    	
	    	var file = element[0].files[0];
	    	var fileSplit = element[0].files[0].name.split(".");
	     	if(!contains(fileSplit[fileSplit.length-1])){
	     		layer.msg($translate.instant('global.logo_warn1'));
	     		return;
	     	}
	     	if(element[0].files[0].size>204096){
	     		layer.msg($translate.instant('global.logo_warn2'));
	     		return;
	     	}
	     	
	        scope.$apply(function(){
	          modelSetter(scope, element[0].files[0]);
	        });
	        
	        scope.show({logo:file});
	        scope.logo = file;
      });      
    },
    template:'<input type="button" id="setLogoBtn" ng-click="setLogo({logo:logo})" style="display:none"/>',
  };
}
})();