(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('statBooking', {
                parent: 'stats',
                url: '/statBooking',
                data: {
                    authorities: ['ROLE_CLIENT'],
                    pageTitle: 'global.menu.admin.statBooking'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/stats/booking/statBooking.html',
                        controller: 'StatBookingController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('stats');
                        return $translate.refresh();
                    }]
                }
            }).state('statBookingNew', {
                parent: 'stats',
                url: '/orders-statistics',
                data: {
                    authorities: ['ROLE_CLIENT', 'FUN_COMPANY_SETUP'],
                    pageTitle: 'global.menu.admin.statBooking'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/stats/booking/statBookingNew.html',
                        controller: 'StatBookingNewController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('stats');
                        return $translate.refresh();
                    }]
                }
            }).state('shipment-tracking', {
                parent: 'stats',
                url: '/shipment-tracking',
                data: {
                    authorities: ['AU_TRACKING_REPORT'],
                    pageTitle: 'global.menu.admin.statBooking'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/stats/booking/statBookingNew.html',
                        controller: 'StatBookingNewController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('stats');
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
