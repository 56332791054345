(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('WmsOut', WmsOut);

    WmsOut.$inject = ['$resource', 'DateUtils'];

    function WmsOut ($resource, DateUtils) {
        var resourceUrl =  'api/wms-outs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'send': {
                method: 'PUT',
                url:'api/wms-outs/send',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'generateGuide': {
                method: 'PUT',
                url:'api/wms-outs/generateGuide',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'pickingOrders':{
                method:'POST',
                responseType: 'arraybuffer',
                url:'api/wms-outs/pickingOrders',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },            
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.updateTime = DateUtils.convertDateTimeFromServer(data.updateTime);
                        data.inTime = DateUtils.convertDateTimeFromServer(data.inTime);
                        data.createTime = DateUtils.convertDateTimeFromServer(data.createTime);
                    }
                    return data;
                }
            },            
            'update': { method:'PUT' }
        });
    }
})();
