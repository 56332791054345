(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ProformaInvoiceDialogController', ProformaInvoiceDialogController);

    ProformaInvoiceDialogController.$inject = ['$rootScope', '$scope', '$timeout', '$translate', '$uibModalInstance', 
    'entity', 'DataUtils', 'OpsReport', 'JhiSignature'];
    
    function ProformaInvoiceDialogController($rootScope, $scope, $timeout, $translate, $uibModalInstance, 
        entity, DataUtils, OpsReport, JhiSignature) {

        var guide = entity;
        $scope.dnLists = [];
        $scope.opsAccounts = [];
        $timeout(function (){
            var dnNums = new Set();
            angular.forEach(guide.guideRates, function(rate){
                if(rate.dnNum && rate.dnNum.indexOf("DN") == 0){
                    dnNums.add(rate.dnNum);
                }
            })
            dnNums.forEach(function(num){
                var dn = {};
                dn.dnNum = num;
                angular.forEach(guide.guideRates, function(rate){
                    if(rate.dnNum == num && rate.payer){
                        dn.payer = rate.payer;
                    }
                })
                $scope.dnLists.push(dn);
            });
            getAccounts();
        });
        $scope.userBankInfo = null;
        var getAccounts = function() {
            JhiSignature.query({companyId: $rootScope.account.companyId}, function(result) {
                $scope.opsAccounts = result; 
                if($scope.opsAccounts && $scope.opsAccounts.length > 0){
                    $scope.userBankInfo = $scope.opsAccounts[0].signature;
                }           
            });
        }      
        $scope.guideRates = [];
        $scope.payAmountTtl = 0;
        $scope.choosedDnNum = null;
        $scope.isEnNote = false;
        $scope.currency = false;
        $scope.onDnChoosed = function(dnNum) {
            $scope.guideRates = [];
            $scope.payAmountTtl = 0;
            $scope.choosedDnNum = dnNum;
            angular.forEach(guide.guideRates, function(rate){
                if(rate.dnNum == dnNum){
                    $scope.guideRates.push(rate);
                    $scope.payAmountTtl = $scope.payAmountTtl + rate.payAmount;
                    $scope.currency = rate.payCurrency;
                }
            })
            $scope.payAmountTtl = DataUtils.round($scope.payAmountTtl, 2);
        };
        $scope.clear = function() {
            $uibModalInstance.dismiss("dismiss");
        };
        var getGuideDTO = function(fileType, reportCode) {
            var guideReportDTO = angular.copy(guide);
            guideReportDTO.guideRates = $scope.guideRates;
            guideReportDTO.isEn = $scope.isEnNote;
            guideReportDTO.fileName = $scope.choosedDnNum + "-Invoice";
            guideReportDTO.reportType = reportCode;
            guideReportDTO.fileType = fileType;            
            guideReportDTO.dnNum = $scope.choosedDnNum;
            guideReportDTO.currency = $scope.currency;
            guideReportDTO.totalDec = $scope.payAmountTtl;
            guideReportDTO.total = $scope.payAmountTtl;
            guideReportDTO.userBankInfo = $scope.userBankInfo;
            return guideReportDTO;
        }

        $scope.generateReport = function(fileType, reportCode) {
            var guideReportDTO = getGuideDTO(fileType, reportCode);
            guideReportDTO.opType = 1;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                if (guideReportDTO.fileType == 2) {
                    var file = new Blob([result.data], {
                        type: 'application/vnd.ms-excel'
                    });
                    if (file.size == 0) {
                        layer.msg($translate.instant('global.loadFileFailed'));
                        return;
                    }
                    var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
                    var aTag = document.createElement("a");
                    aTag.download = guideReportDTO.fileName + ".xlsx";
                    aTag.href = blobURL;
                    aTag.click();
                } else {
                    var file = new Blob([result.data], {
                        type: 'application/pdf'
                    });
                    if (file.size == 0) {
                        layer.msg($translate.instant('global.loadFileFailed'));
                        return;
                    }
                    var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
                    var aTag = document.createElement("a");
                    aTag.download = guideReportDTO.fileName + ".PDF";
                    aTag.href = blobURL;
                    aTag.click();
                }
                $scope.isSaving = false;
            });
        }

        $scope.previewReport = function(reportCode) {
            var guideReportDTO = getGuideDTO(1, reportCode);
            guideReportDTO.opType = 1;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                var file = new Blob([result.data], {
                    type: 'application/pdf'
                });
                if (file.size == 0) {
                    layer.msg($translate.instant('global.loadFileFailed'));
                    return;
                }

                var fileURL = URL.createObjectURL(file);
                var previewDialog = layer.open({
                    type: 2,
                    area: ['1000px', '680px'],
                    maxmin: true,
                    title: $translate.instant("entity.action.preview"),
                    shade: 0.3,
                    moveType: 0,
                    shift: 0,
                    content: fileURL
                });
            });
        }
        $rootScope.LOAD_REPORT_TEMPLATES();
        $scope.invoiceSend = function(emailType, reportCode) {
            var guideReportDTO  = getGuideDTO(1, reportCode);
            guideReportDTO.opType = 3;
 
            OpsReport.getMultiAttachments({
                reportTypes: guideReportDTO.reportType
            }, guideReportDTO, function(result) {
                var resultMap = {};
                resultMap.emailType = emailType;                    
                resultMap.venderId = $scope.guideRates[0].venderId;
                resultMap.report = result;
                resultMap.dnNum = $scope.choosedDnNum;                
                $uibModalInstance.close(resultMap);
                $scope.isSaving = false;
            });
        }

        $scope.onAccountSelected = function(data, opsBill) {
            $scope.userBankInfo = data.signature;
        }

    }
})();
