(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsGoodsDialogController', WmsGoodsDialogController);

    WmsGoodsDialogController.$inject = ['$timeout', '$rootScope', '$scope', '$http', '$translate', '$uibModal', '$uibModalInstance', 
    'Principal', 'entity', 'WmsGoods', 'ManageEnterprise', 'WmsWarehouse', 'WmsGoodsBatch', 'OpsFiles', 'OpsExchangeRate', 'DataUtils'];

    function WmsGoodsDialogController ($timeout, $rootScope, $scope, $http, $translate, $uibModal, $uibModalInstance, 
        Principal, entity, WmsGoods, ManageEnterprise, WmsWarehouse, WmsGoodsBatch, OpsFiles, OpsExchangeRate, DataUtils) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };

        var vm = this;
        vm.wmsGoods = entity;
        vm.clear = clear;
        vm.save = save;
        vm.uploadGoodsFile = uploadGoodsFile;
        vm.loadFiles = loadFiles;
        vm.reloadFiles = reloadFiles;
        vm.updateVol = updateVol;
        vm.upperCaseCode = upperCaseCode;    
        vm.units = []; 
        vm.tradeCurrs = []; 
        vm._currencySelected = _currencySelected;
        vm._onLocationSelected = _onLocationSelected;
        vm.getAllLocations = getAllLocations;
        vm._unit1Selected = _unit1Selected;
        vm._unit2Selected = _unit2Selected;
        vm._unit3Selected = _unit3Selected;
        vm._customsModeSelected = _customsModeSelected;
        vm._countriesSelected = _countriesSelected;
        vm.importExcel = importExcel;
        vm.addBatch = addBatch;
        vm.delBatch = delBatch;     
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.checkSku = checkSku;
        vm.refreshInventory = refreshInventory;
        vm.imperialChange = imperialChange;
        vm.imperialVolTtlChange = imperialVolTtlChange;
        vm.imperialUnitChange = imperialUnitChange;
        vm.orderHandleFeeJson = orderHandleFeeJson;

        function orderHandleFeeJson(){
            var entity = angular.fromJson(vm.wmsGoods.orderHandleFeeJson);
            $uibModal.open({
                templateUrl: 'app/entities/wms-goods/wms-goods-delete-dialog.html',
                controller: 'WmsGoodsDeleteController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                vm.wmsGoods.orderHandleFeeJson = angular.toJson(result, true)
            }, function() {
            });
        }

        function checkSku(){
            if(!vm.wmsGoods.sku || !vm.wmsGoods.manageEnterpriseId){
                return;
            }
            // vm.wmsGoods.sku = angular.uppercase(vm.wmsGoods.sku);
            WmsGoods.checkSku(vm.wmsGoods, function(result){                
            },function(error){
                vm.wmsGoods.sku = null;
                if(error.status == 406){
                    layer.msg($translate.instant('autopsApp.manageEnterprise.skuInvalid'));
                }
                else {
                    layer.msg($translate.instant('autopsApp.manageEnterprise.skuRepeated'));
                }                
            });
        }

        function exportExcelOrPdf(result) {
            var file = new Blob([result.data], {
                type: 'application/pdf'
            });
            var fileURL = URL.createObjectURL(file);
            var previewDialog = layer.open({
                type: 2,
                area: ['1200px', '800px'],
                maxmin: true,
                title: "SKU Barcode",
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: fileURL
            });
        }

        $http.get('i18n/customsData.json').success(function(result) {
            vm.units = result.units;
            vm.tradeCurrs = result.tradeCurrs;
            vm.dutyModes = result.dutyModes;
            vm.countries = result.countries;
        });

        function _customsModeSelected(data, goods) {
            goods.dutyModeName = data.N;
            goods.dutyMode = data.C;
        }

        function _countriesSelected(data, goods) {
            goods.originCountryName = data.N;
            goods.originCountry = data.C;
        }

        function _currencySelected(data, goods) {
            goods.currency = data.N;
            goods.tradeCurr = data.C;
        }

        function _unit1Selected(data, goods) {
            goods.unit1 = data.N;
            goods.unit1Code = data.C;
        }
        function _unit2Selected(data, goods) {
            goods.unit2 = data.N;
            goods.unit2Code = data.C;
        }
        function _unit3Selected(data, goods) {
            goods.unit3 = data.N;
            goods.unit3Code = data.C;
        }

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
            getCargpPic();     
            checkSku();
            if(!$rootScope.OPSCURRENCYS || $rootScope.OPSCURRENCYS.length == 0){
                OpsExchangeRate.getAllCurrencys(function(result) {
                     $rootScope.OPSCURRENCYS= result;
                })
            }
            vm.imperialUnit = $rootScope.HAVEAUTH('IMPERIAL_UNIT');
            imperialUnitInit();
        });


        vm.imperialUnit = false;
        function imperialUnitChange () {
            if(vm.imperialUnit){
                imperialUnitInit();
            }
        }
        function imperialUnitInit () {
            if(vm.wmsGoods.gw){
                vm.wmsGoods.gwLb = DataUtils.round(vm.wmsGoods.gw * 2.20462262, 2);
            }
            if(vm.wmsGoods.nw){
                vm.wmsGoods.nwLb = DataUtils.round(vm.wmsGoods.nw * 2.20462262, 2);
            }
            if(vm.wmsGoods.length){
                vm.wmsGoods.lengthInch = DataUtils.round(vm.wmsGoods.length * 0.393700788, 2);
            }
            if(vm.wmsGoods.width){
                vm.wmsGoods.widthInch = DataUtils.round(vm.wmsGoods.width * 0.393700788, 2);
            }
            if(vm.wmsGoods.height){
                vm.wmsGoods.heightInch = DataUtils.round(vm.wmsGoods.height * 0.393700788, 2);
            }
            if(vm.wmsGoods.vol){
                vm.wmsGoods.volInch = DataUtils.round(vm.wmsGoods.vol * 35.3147, 4);
            }
        }


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save(){
            if(!vm.wmsGoods.goodsBatchList || vm.wmsGoods.goodsBatchList.length == 0){                
                layer.msg($translate.instant('autopsApp.manageEnterprise.batchNullAlert'));
                return;
            }
            vm.isSaving = true;
            WmsGoods.update(vm.wmsGoods, onSaveSuccess, onSaveError);
        }
        
        function onSaveSuccess (result) {
            vm.wmsGoods = result;
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submitted'));
            imperialUnitInit();
            // clear();
        }
        function onSaveError () {
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submittedfailed'));
        }

        vm.files = new Array();        
        function loadFiles (){
            if (vm.wmsGoods.id) {
                ManageEnterprise.loadManageEnterpiseFiles({manageEnterId:vm.wmsGoods.manageEnterpriseId, goodsId:vm.wmsGoods.id},function (data){
                    vm.files = new Array();
                    if(data.length>0){
                        for(var i = 0 ; i < data.length; i++){
                            var temp  = new Object();
                            temp['filepath'] = data[i]['filepath'];
                            temp['filename'] = data[i]['filename'];
                            temp['fileurl'] = data[i]['fileurl'];
                            temp['fileist'] = true;
                            vm.files.push(temp);
                        }
                    }
                });
            }
        }
        loadFiles();        
        function reloadFiles (file) {
            if (!angular.isDefined(file.filepath)) {
                return;
            }
            var exist = false;
            if (vm.files.length > 0) {
                for (var i = 0; i < vm.files.length; i++) {
                    if (vm.files[i]['filename'] == file['filename']) {
                        vm.files[i]['filepath'] = file['filepath'];
                        vm.files[i]['filename'] = file['filename'];
                        vm.files[i]['fileurl'] = file['fileurl'];
                        exist = true;
                        break;
                    }
                }
            }
            if (!exist) {
                var temp = new Object();
                temp['filepath'] = file['filepath'];
                temp['filename'] = file['filename'];
                temp['fileurl'] = file['fileurl'];
                temp['fileist'] = true;
                vm.files.push(temp);
            }
        }
        
        function uploadGoodsFile(){
            $("input[name=wmsGoodsFile]").trigger('click');
        }

        function updateVol (goods) {            
            if (goods.length && goods.width && goods.height){
                goods.vol = goods.length/100 * goods.width/100 * goods.height/100;
                goods.vol = goods.vol.toFixed(4) -0;
            }
        }
        function imperialChange () {
            if(vm.wmsGoods.gwLb){
                vm.wmsGoods.gw = DataUtils.round(vm.wmsGoods.gwLb / 2.20462262, 2);
            }
            if(vm.wmsGoods.nwLb){
                vm.wmsGoods.nw = DataUtils.round(vm.wmsGoods.nwLb / 2.20462262, 2);
            }
            if(vm.wmsGoods.lengthInch){
                vm.wmsGoods.length = DataUtils.round(vm.wmsGoods.lengthInch / 0.393700788, 2);
            }
            if(vm.wmsGoods.widthInch){
                vm.wmsGoods.width = DataUtils.round(vm.wmsGoods.widthInch / 0.393700788, 2);
            }
            if(vm.wmsGoods.heightInch){
                vm.wmsGoods.height = DataUtils.round(vm.wmsGoods.heightInch / 0.393700788, 2);
            }
            if (vm.wmsGoods.lengthInch && vm.wmsGoods.widthInch && vm.wmsGoods.heightInch){
                vm.wmsGoods.volInch = vm.wmsGoods.lengthInch * vm.wmsGoods.widthInch * vm.wmsGoods.heightInch/1728;
                vm.wmsGoods.volInch = vm.wmsGoods.volInch.toFixed(4) -0;
                vm.wmsGoods.vol = DataUtils.round(vm.wmsGoods.volInch/35.3147, 4);
            }
        }
        function imperialVolTtlChange () {
            if (vm.wmsGoods.volInch){
                vm.wmsGoods.vol = DataUtils.round(vm.wmsGoods.volInch/35.3147, 4);
            }
        }

        function upperCaseCode (obj) {
            obj.code = angular.uppercase(obj.code);
        }

        vm.locations = [];
        function getAllLocations () {
            if (vm.locations && vm.locations.length > 0){
                return;
            }
            WmsWarehouse.getAllLocations({companyId: $rootScope.account.companyId}, function (data) {
                vm.locations = data;
            });
        }
        function _onLocationSelected (item) {
            vm.wmsGoods.location = item.code;
            vm.wmsGoods.locationId = item.locationId;
        }
                
        function addBatch () {
            vm.wmsGoods.goodsBatchList.push({});
            $translate.refresh();
        }
        
        function delBatch(batch){
            if(batch.id){
                layer.confirm($translate.instant('global.confirmToDelete'), {
                    title: $translate.instant('global.messageInfo'),
                    zIndex: 1100,
                    btn: [$translate.instant('global.confirm'),$translate.instant('global.cancel')]
                }, function(index) {                    
                    WmsGoodsBatch.del({id:batch.id});
                    var indexToDel= vm.wmsGoods.goodsBatchList.indexOf(batch);
                    if (indexToDel > -1) {
                        vm.wmsGoods.goodsBatchList.splice(indexToDel, 1);
                    };
                    layer.close(index);
                    $translate.refresh();
                },function(index){
                    layer.close(index);
                });      
            }     
            else {
                var indexToDel= vm.wmsGoods.goodsBatchList.indexOf(batch);
                if (indexToDel > -1) {
                    vm.wmsGoods.goodsBatchList.splice(indexToDel, 1);
                };
            } 
        }
        function importExcel (file) {
            if (!file) {return;}
            var fd = new FormData();
            fd.append('file', file);
            $http.post(
                'api/wms-goods-batches/importByExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {    
                    data = angular.fromJson(data);
                    if (!data || data.length == 0){
                        layer.msg($translate.instant('global.messages.resultNull'));  
                        return;
                    }
                    angular.forEach(data, function (item) {
                        vm.wmsGoods.goodsBatchList.push(item);
                    })           
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.manageEnterprise.uploadResult', {newCount: data.length}));
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));                           
                });
        };

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $scope.cargoPic = null;
        $scope.downLoadPic = function() {
            var aTag = document.createElement("a");
            aTag.href = $scope.cargoPic.fileurl;
            aTag.click();
        }
        $scope.deleOssFile = function() {
            OpsFiles.delOssFile({
                filepath: $scope.cargoPic.filepath
            }, function(result) {
                $scope.cargoPic = null;
                layer.msg($translate.instant('global.delete_success'));
            }, function(reject) {
                layer.msg($translate.instant('global.delete_fail'));
            }, function(error) {
                console.log(error);
            });
        }
        function getCargpPic() {
            if(!vm.wmsGoods.id){
                return;
            }
            var filePath = "/" + "wmsGoods/" + vm.wmsGoods.id + "/cargoPic/";
            OpsFiles.getFilesList({
                filepath: filePath,
                companyId: $rootScope.account.companyId
            },function (data){
                if(data && data.length > 0){
                    $scope.cargoPic = data[0];
                }
            });
        }        
        $scope.uploadPic = function(file) {
            if(!vm.wmsGoods.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if (!file) {
                return;
            } 
            if(!DataUtils.isPic(file.name)){
                layer.msg($translate.instant('global.logo_warn1'));
                return;
            }
            if(file.size>2040960 && $rootScope.account.companyId != 273){
                layer.msg($translate.instant('global.logo_warn2m'));
                return;
            }
            var fd = new FormData();            
            var filePath = "/" + "wmsGoods/" + vm.wmsGoods.id + "/cargoPic/";
            fd.append('filepath', filePath);
            fd.append('file', file);
            fd.append('companyId', $rootScope.account.companyId);
            $http.post(
                'api/ops-files/uploadFile', fd, {
                    transformRequest : angular.identity,
                    headers : {
                        'Content-Type' : undefined
                    },
                }).success(function(data) {
                    $scope.cargoPic = data;
                    layer.msg($translate.instant('global.uploadSuccess'));
                }).error(function(error) {
                    layer.msg($translate.instant('global.uploadFail'));
            });
        };

        function refreshInventory(){
            if(!vm.wmsGoods.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            layer.confirm($translate.instant('autopsApp.manageEnterprise.wmsGoods.refreshInventoryAlert'), {
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    vm.isSaving = true;
                    WmsGoods.refreshInventory({
                        wmsGoodsId: vm.wmsGoods.id
                    }, function (result) {
                        vm.isSaving = false;
                        layer.msg($translate.instant('global.messages.submitted'));
                    }, function(result){
                        vm.isSaving = false;
                        layer.msg($translate.instant('global.messages.submittedfailed'));
                    });
                    layer.close(index);
                }, function() {
            });
        }

    }
})();
