(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('WmsLog', WmsLog);

    WmsLog.$inject = ['$resource', 'DateUtils'];

    function WmsLog ($resource, DateUtils) {
        var resourceUrl =  'api/wms-logs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.actTime = DateUtils.convertDateTimeFromServer(data.actTime);
                    }
                    return data;
                }
            },
            'correctInventory': {
                method: 'GET',
                url:'api/wms-logs/correctInventory'
            }, 
            'briefWmsInOutLogs': {
                method: 'GET',
                url:'api/wms-logs/briefWmsInOutLogs',                
                isArray: true
            },             
            'getWmsLogs': {
                method: 'POST',
                url:'api/wms-logs/getWmsLogs',                
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        // angular.forEach(data, function(cargo){
                        //     cargo.actTime = DateUtils.convertDateTimeFromServer(cargo.actTime);
                        // })
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
