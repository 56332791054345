(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ShareListDialogController', ShareListDialogController);

    ShareListDialogController.$inject = ['$rootScope', '$uibModalInstance', '$translate', '$timeout', 
    'entity', 'ExpressParcel', 'Guide'];

    function ShareListDialogController ($rootScope, $uibModalInstance, $translate, $timeout, 
        entity, ExpressParcel, Guide) {
               
        var vm = this;
        vm.rateCopy = entity;
        vm.clear = clear;
        vm.delRate = delRate;  
        vm.delAllShareCost = delAllShareCost;      
        vm.isSaving = false;

        vm.shareRates = [];
        vm.isMaster = false;
        vm.mRate = {};
        vm.type = "teu";

        var refresh = false;

        $timeout(function (){
            if (vm.rateCopy.dnNum.indexOf("CSV") === 0){
                vm.type = "vol";
            }
            else if (vm.rateCopy.dnNum.indexOf("CSW") === 0){
                vm.type = "gw";
            }
            else if (vm.rateCopy.dnNum.indexOf("CSP") === 0){
                vm.type = "plt";
            }
            else if (vm.rateCopy.dnNum.indexOf("CSB") === 0){
                vm.type = "bl";
            }
            else if (vm.rateCopy.dnNum.indexOf("CSC") === 0){
                vm.type = "vw";
            }

            getShareRates();
        })
        
        function getShareRates () {
            vm.shareRates = [];
            Guide.getShareRates({
                sharedOn : vm.rateCopy.sharedOn,
                id: vm.rateCopy.id
            }, function(result){
                if(result.length == 0){
                    refresh = true;
                    $uibModalInstance.close(refresh);
                    return;
                }
                angular.forEach(result, function(data){
                    if(vm.rateCopy.dnNum.indexOf(data.jobNum) != -1){
                        vm.mRate = data;
                    }
                })
                if(vm.rateCopy.id == vm.mRate.id){
                    vm.isMaster = true;
                }
                angular.forEach(result, function(data){
                    if (data.id != vm.mRate.id){
                        vm.shareRates.push(data);                        
                    }
                })
            })
        }

        function clear () {
            $uibModalInstance.close(refresh);
        }
        function delRate (rate) {
            if ($rootScope.HAVENOAUTH('AU_CHARGES_SHARE')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_CHARGES_SHARE');
                return;
            }
            if (!vm.isMaster && vm.mRate.payAmount){
                layer.msg($translate.instant('autopsApp.guide.delFromMasterAlert'));
                return;
            }
            vm.isSaving = true;
            if (vm.shareRates.length == 1 || !vm.mRate.payAmount){
                delAllShareCost();
            }
            else {
                ExpressParcel.reShareCharges(rate, function(result){
                    vm.isSaving = false;                    
                    layer.msg($translate.instant('global.messages.submitted'));
                    getShareRates();
                }, function(){
                    vm.isSaving = false;
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                })
            }
        }
        function delAllShareCost () {
            if ($rootScope.HAVENOAUTH('AU_CHARGES_SHARE')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_CHARGES_SHARE');
                return;
            }
            if (!vm.isMaster && vm.mRate.payAmount){
                layer.msg($translate.instant('autopsApp.guide.delFromMasterAlert'));
                return;
            }

            ExpressParcel.delAllShareCharges({sharedOn:vm.rateCopy.sharedOn}, function(){
                vm.isSaving = false;
                refresh = true;
                clear();
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(result){
                vm.isSaving = false;
                if(result.status == 403){
                    layer.msg($translate.instant('global.lockedAlert'));
                }
                else {
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                }
                
            })
        }


    }
})();
