(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountRefDeleteController', AccountRefDeleteController);

    AccountRefDeleteController.$inject = ['$scope', '$uibModalInstance', '$translate', '$rootScope', 'entity', 'AccountRef'];
	function AccountRefDeleteController($scope, $uibModalInstance, $translate, $rootScope, entity, AccountRef) {

        $scope.accountRef = entity;

        var onSaveSuccess = function (result) {
            $uibModalInstance.close(true);
            layer.msg($translate.instant('global.delete_success'));
        };

        var onSaveError = function (result) {            
            if (result.status === 403) {
                $uibModalInstance.close(true);
                $rootScope.OPS_ALERT($translate.instant('global.forbidden'), {
                    icon: 0, 
                    title :$translate.instant('global.alert_title'),
                    btn: [$translate.instant('global.ok_answer')]
                })
            }     
            else {
                layer.msg($translate.instant('global.delete_fail')); 
            }       
        };
              
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function () {
            AccountRef.delete({id: $scope.accountRef.id}, onSaveSuccess, onSaveError);
        };


    }
})();
