(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('AccountPaymentSearch', AccountPaymentSearch);

    AccountPaymentSearch.$inject = ['$resource'];
    
    function AccountPaymentSearch($resource) {
        return $resource('api/_search/accountPayments/:query', {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
