(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BookingCtnsController', BookingCtnsController);

    BookingCtnsController.$inject = ['$rootScope', "$translate", '$scope', '$state', '$uibModal',
        'Dictionary', 'BookingCtns', 'Guide', 'BookingCtnsSearch', 'DataUtils'];

    function BookingCtnsController($rootScope, $translate, $scope, $state, $uibModal,
        Dictionary, BookingCtns, Guide, BookingCtnsSearch, DataUtils) {

        $scope.bookingCtnss = [];
        $scope.filter = {
            pageSize: 100,
            dutyAudited: false
        };
        $scope.batchAuditOptions = {};
        $scope.batchAuditOn = function() {
            return $scope.batchAuditOptions.byCargoName || $scope.batchAuditOptions.byCargoNameAndMaterial || $scope.batchAuditOptions.byCargoNameAndMaterialAndUnitPrice;
        }
        $scope.loadAll = function() {
            if ($scope.search) {
                BookingCtnsSearch.query({
                    query: $scope.search,
                    page: $scope.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                layer.load(1, { shade: 0.3 });
                BookingCtns.getFilterPage({
                    page: 1 - 1,
                    size: $scope.filter.pageSize,
                    sort: sort()
                }, $scope.filter, onSuccess, onError);
            }

            function sort() {
                return ['id,desc'];
            }

            function onSuccess(data, headers) {
                layer.closeAll();
                $scope.totalItems = headers('X-Total-Count');
                $scope.bookingCtnss = data;
                $scope.batchAuditChange();
            }

            function onError(error) {
                layer.closeAll();
                AlertService.error(error.data.message);
            }
        }


        $scope.searchPopFms = function() {
            var searchParams = {
                forDutyAudit: true,
                companyId: $rootScope.account.companyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/guide/guide-search-dialog.html',
                controller: 'GuideSearchDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(results) {
                $scope.bookingCtnss = results;
                getCodeInfo($scope.bookingCtnss);
                $scope.batchAuditChange();
            }, function() {
            });
        }
        function getCodeInfo(bookingCtns) {
            angular.forEach(bookingCtns, function(item) {
                item.customsSuperviseConditionTxt = "";
                item.inspectionCategoryTxt = "";
                angular.forEach(Dictionary.customsSuperviseConditions, function(data) {
                    if (item.commodityData && item.commodityData.customsSuperviseCondition &&
                        item.commodityData.customsSuperviseCondition.indexOf(data.code) !== -1) {
                        item.customsSuperviseConditionTxt = item.customsSuperviseConditionTxt + "(" + data.code + ")" + data.name + "\n";
                    }
                })
                angular.forEach(Dictionary.inspectionCategorys, function(data) {
                    if (item.commodityData && item.commodityData.inspectionCategory &&
                        item.commodityData.inspectionCategory.indexOf(data.code) !== -1) {
                        item.inspectionCategoryTxt = item.inspectionCategoryTxt + "(" + data.code + ")" + data.name + "\n";
                    }
                })
            })
        }
        $scope.saveToDatabase = function(btn) {
            var commodityDatabase = angular.copy(btn);
            commodityDatabase.id = null;
            commodityDatabase.commodity = btn.cargoName;
            commodityDatabase.companyId = btn.companyId;
            commodityDatabase.venderId = btn.venderId;
            commodityDatabase.venderName = btn.customerName;
            commodityDatabase.commodityCountrys = [{
                importCountryCode: btn.importCountryCode,
                hsCode: btn.hsCode,
                dutyRate: btn.dutyRate,
                country: $rootScope.getCountryName(btn.importCountryCode)
            }];
            $uibModal.open({
                templateUrl: 'app/entities/commodity-database/commodity-database-dialog.html',
                controller: 'CommodityDatabaseDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                resolve: {
                    entity: function() {
                        return commodityDatabase;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('commodityDatabase');
                        $translatePartialLoader.addPart('commodityCountry');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                if(result.id){
                    btn.commodityData = result;
                    $scope.auditOneWithCommDatabase(btn);
                }
            }, function() {
            });
        }

        function autoMatchCommDatabase(btn) {
            BookingCtns.autoMatchCommDatabase(btn, function(result) {
                angular.copy(result, btn);
                getCodeInfo([btn]);
                $translate.refresh();
            })
        }


        $scope.commDatabasePop = function(btn) {
            $uibModal.open({
                templateUrl: 'app/entities/commodity-database/commodity-database-dialog.html',
                controller: 'CommodityDatabaseDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                resolve: {
                    entity: function() {
                        return btn.commodityData;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('commodityDatabase');
                        $translatePartialLoader.addPart('commodityCountry');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {
                autoMatchCommDatabase(btn);
            }, function() {
                autoMatchCommDatabase(btn);
            });
        }
        $scope.auditOneWithCommDatabase = function(btn) {
            if ($rootScope.HAVENOAUTH('AUDI_COMM_DATABASE')) {
                $rootScope.FORBIDDEN_BY_ALERT('AUDI_COMM_DATABASE');
                return;
            }
            BookingCtns.auditOneWithCommDatabase(angular.copy(btn), function(result) {
                layer.msg($translate.instant('global.messages.submitted'));
                angular.copy(result, btn);
                getCodeInfo([btn]);
                $translate.refresh();
            }, $rootScope.ALERT_ERROR);
        }

        $scope.plPop = function(btn) {
            if (!btn.bookingId) {
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/bookingCtns/bookingCtns-dialog.html',
                controller: 'BookingCtnsDialogController',
                size: 'xxxl',
                backdrop: false,
                resolve: {
                    entity: [function() {
                        return Guide.getByBookingId({booingId: btn.bookingId}).$promise;
                    }]
                }
            }).result.then(function(result) {}, function() {})
        }

        $scope._onCommSelected = function(item, btn) {
            if ($rootScope.HAVENOAUTH('AUDI_COMM_DATABASE')) {
                $rootScope.FORBIDDEN_BY_ALERT('AUDI_COMM_DATABASE');
                return;
            }
            var entity = angular.copy(btn);
            entity.commodityData = item;
            BookingCtns.auditWithCommDatabase(entity, function(result) {
                layer.msg($translate.instant('global.messages.submitted'));
                angular.copy(result, btn);
                getCodeInfo([btn]);
                $translate.refresh();
            }, $rootScope.ALERT_ERROR);
        }

        $scope.batchAuditPlByFilter = function(pl, fd, type) {
            if(!pl[fd]){
                layer.msg("SKU is required");
                return;
            }
            if(!pl.commodityData.id){
                layer.msg("Commodity is required");
                return;
            }
            var filter = {
                commodityDatabaseId: pl.commodityData.id
            };
            filter[fd] = pl[fd];
            if(type == "job"){
                filter.bookingId = pl.bookingId;
            }
            else if(type == "vender"){
                filter.venderId = pl.venderId;
            }
            layer.confirm($translate.instant('autopsApp.commodityDatabase.auditBySkuAlert', {param: pl[fd]}), {
                title: "Msg",
                icon: 3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                layer.load(1, { shade: 0.3 });
                BookingCtns.batchAuditPlByFilter({}, filter, function(resultList){
                    layer.closeAll();
                    angular.forEach(resultList, function(data){
                        angular.forEach($scope.bookingCtnss, function(btn){
                            if(data.mixedPlId && btn.mixedPlId == data.mixedPlId){
                                angular.copy(data, btn);
                                getCodeInfo([btn]);
                            }
                            else if (data.id && btn.id == data.id){
                                angular.copy(data, btn);
                                getCodeInfo([btn]);
                            }
                        })
                    })
                }, function(){
                    layer.closeAll();
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                });
            }, function() {
                return;
            });
        }
        var fillBatchAuditData = function(dutyAudited){
            var cargoNames = new Set();
            var materials = new Set();
            var importCountryCodes = new Set();
            var unitPrices = new Set();

            angular.forEach($scope.bookingCtnss, function(btn){
                if(null == btn.dutyAudited){
                    btn.dutyAudited = false;
                }
                if(btn.dutyAudited == dutyAudited){
                    cargoNames.add(btn.cargoName);
                    materials.add(btn.material);
                    importCountryCodes.add(btn.importCountryCode);
                    unitPrices.add(btn.unitPrice);
                }
            })
            if($scope.batchAuditOptions.byCargoName){
                importCountryCodes.forEach(function(importCountryCode){
                    cargoNames.forEach(function(cargoName){
                        var matchedPls = [];
                        angular.forEach($scope.bookingCtnss, function(btn){
                            if(dutyAudited == btn.dutyAudited && btn.cargoName == cargoName && btn.importCountryCode == importCountryCode){
                                matchedPls.push(btn);
                            }
                        })
                        if(matchedPls.length){
                            $scope.batchAuditData.push({
                                dutyAudited: dutyAudited,
                                importCountryCode: importCountryCode,
                                cargoName: cargoName,
                                matchedPls: matchedPls,
                                count: matchedPls.length
                            });
                        }
                    })
                })
            }
            else if($scope.batchAuditOptions.byCargoNameAndMaterial){
                importCountryCodes.forEach(function(importCountryCode){
                    cargoNames.forEach(function(cargoName){
                        materials.forEach(function(material){
                            var matchedPls = [];
                            angular.forEach($scope.bookingCtnss, function(btn){
                                if(dutyAudited == btn.dutyAudited && btn.cargoName == cargoName && btn.importCountryCode == importCountryCode && btn.material == material){
                                    matchedPls.push(btn);
                                }
                            })
                            if(matchedPls.length){
                                $scope.batchAuditData.push({
                                    dutyAudited: dutyAudited,
                                    importCountryCode: importCountryCode,
                                    cargoName: cargoName,
                                    material: material,
                                    matchedPls: matchedPls,
                                    count: matchedPls.length
                                });
                            }
                        })
                    })
                })
            }
            else if($scope.batchAuditOptions.byCargoNameAndMaterialAndUnitPrice){
                importCountryCodes.forEach(function(importCountryCode){
                    cargoNames.forEach(function(cargoName){
                        materials.forEach(function(material){
                            unitPrices.forEach(function(unitPrice){
                                var matchedPls = [];
                                angular.forEach($scope.bookingCtnss, function(btn){
                                    if(dutyAudited == btn.dutyAudited && btn.cargoName == cargoName && btn.importCountryCode == importCountryCode && btn.material == material && btn.unitPrice == unitPrice){
                                        matchedPls.push(btn);
                                    }
                                })
                                if(matchedPls.length){
                                    $scope.batchAuditData.push({
                                        dutyAudited: dutyAudited,
                                        importCountryCode: importCountryCode,
                                        cargoName: cargoName,
                                        material: material,
                                        unitPrice: unitPrice,
                                        matchedPls: matchedPls,
                                        count: matchedPls.length
                                    });
                                }
                            })
                        })
                    })
                })
            }
            updateBatchAuditData();
        }

        var updateBatchAuditData = function(){
            var updateFds = ["auditor", "hsCode", "dutyRate", "materialEn", "cargoNameEn", "unitPrice", "material", "useAge",
                "useAgeEn", "sku", "brand", "model", "mark"];
            angular.forEach(updateFds, function(fd){
                angular.forEach($scope.batchAuditData, function(data){
                    if(!data[fd]){
                        var fdValue = "";
                        angular.forEach(data.matchedPls, function(pl){
                            if(pl[fd] && fdValue.indexOf(pl[fd]) == -1){
                                fdValue += pl[fd] + "; "
                            }
                        })
                        if(fdValue){
                            data[fd] = fdValue.substring(0, fdValue.length - 2);
                        }
                    }
                    angular.forEach(data.matchedPls, function(pl){
                        if(pl.commodityData && pl.commodityData.id){
                            if(!data.commodityData){
                                data.commodityData = pl.commodityData;
                            }
                        }
                    })
                })
            });
        }

        $scope.batchAuditChange = function(){
            $scope.batchAuditData = [];
            if(!$scope.batchAuditOn()){
                return;
            }
            fillBatchAuditData(false);
            fillBatchAuditData(true);
            $translate.refresh();
        }

        $scope.batchAuditWithCommDatabase = function(commodityData, matchedPls){
            var bookingCtnDTOS = [];
            angular.forEach(matchedPls, function(matchedPl){
                var entity = angular.copy(matchedPl);
                entity.commodityData = commodityData;
                bookingCtnDTOS.push(entity);
            });
            BookingCtns.batchAuditWithCommDatabase(bookingCtnDTOS, function(result) {
                layer.msg($translate.instant('global.messages.submitted'));
                angular.forEach($scope.bookingCtnss, function(btn){
                    angular.forEach(result, function(rst){
                        if(btn.id == rst.id && btn.mixedPlId == rst.mixedPlId){
                            angular.copy(rst, btn);
                        }
                    })
                })
                $scope.batchAuditChange();
                $translate.refresh();
            }, $rootScope.ALERT_ERROR);
        }

    }
})();
