(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AutoCheckItemDetailController', AutoCheckItemDetailController);

    AutoCheckItemDetailController.$inject = ['$scope', '$rootScope', '$uibModalInstance', 'entity', 'AutoCheckItem'];

    function AutoCheckItemDetailController($scope, $rootScope, $uibModalInstance, entity, AutoCheckItem) {
        var vm = this;
        vm.dateEntity = entity;

        $scope.datePickerForEtdAmend = {};
        $scope.datePickerForEtdAmend.status = {
            opened: false
        };
        $scope.datePickerForEtdAmendOpen = function($event) {
            $scope.datePickerForEtdAmend.status.opened = true;
        };
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.save = function() {
            $uibModalInstance.close(vm.dateEntity);   
        };
        

    }
})();
