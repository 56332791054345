(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('PalletizeDetailController', PalletizeDetailController);

    PalletizeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Palletize'];

    function PalletizeDetailController($scope, $rootScope, $stateParams, previousState, entity, Palletize) {
        var vm = this;

        vm.palletize = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('autopsApp:palletizeUpdate', function(event, result) {
            vm.palletize = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
