(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsVirtualController', WmsVirtualController);

    WmsVirtualController.$inject = ['$scope', '$state', '$rootScope', '$uibModal', '$translate', 'DataUtils', '$timeout',
    'WinAES', 'WmsIn', 'WmsInSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Principal', 'OpsFiles'];

    function WmsVirtualController ($scope, $state, $rootScope, $uibModal, $translate, DataUtils, $timeout,
        WinAES, WmsIn, WmsInSearch, ParseLinks, AlertService, pagingParams, paginationConstants, Principal, OpsFiles) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };

        $timeout(function (){
            if($rootScope.HAVENOAUTH('WMS_VIRTUAL')){
                $state.go('home');
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
        })

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.exportExcel = exportExcel;
        vm.editWms = editWms;
        vm.creatNew = creatNew;

        vm.isAdvanceSearch = false;

        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                WmsInSearch.queryVirtual({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                WmsIn.getWmsVirtual({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                // alert(data.length);
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.wmsIns = data;
                vm.isAdvanceSearch = false;
                angular.forEach(vm.wmsIns, function(data){
                    data.codeId = WinAES.encode(data.id.toString());
                    if (data.guideId){
                        data.encodeId = WinAES.encode(data.guideId.toString());
                    }
                    if($rootScope.HAVENOAUTH('FUN_VIEW_CLIENT')){
                        data.clientName = "******";
                    }
                    if($rootScope.HAVENOAUTH('FUN_VIEW_SHIPPER')){
                        data.shipperName = "******";
                    }
                })
                vm.page = pagingParams.page;
                setTimeout(function() {frzTable("divTable", "showTable", "footPager");}, 100);
            }
            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
           if (!searchQuery){
               return vm.clear();
           }
           vm.links = null;
           vm.page = 1;
           vm.predicate = '_score';
           vm.reverse = true;
           vm.currentSearch = searchQuery;
           vm.transition();
            // OpsFiles.tests({id:1});
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            if (vm.isAdvanceSearch){
                vm.itemsPerPage = paginationConstants.itemsPerPage;
                vm.isAdvanceSearch = false;
                loadAll();
            }
            else {
                vm.transition();
            }
            vm.totalLine = {};
        }


        $scope.searchRequestPop = function() {
            var searchParams = {
                companyId: $rootScope.account.companyId,
                isVirtual: true,
                isOut: false
            };
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-search.html',
                controller: 'WmsInSearchController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(results) {
                vm.wmsIns = results;
                vm.currentSearch = true;
                vm.totalItems = results.length;
                vm.itemsPerPage = results.length;
                vm.queryCount = vm.totalItems;
                vm.page = 1;
                vm.isAdvanceSearch = true;

                angular.forEach(vm.wmsIns, function(data){
                    data.codeId = WinAES.encode(data.id.toString());
                    if (data.guideId){
                        data.encodeId = WinAES.encode(data.guideId.toString());
                    }
                })
                getTtlCount();

                setTimeout(function() {frzTable("divTable", "showTable", "footPager");}, 100);
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }


        vm.totalLine = {};
        var getTtlCount = function() {
            var ttlPkgs = 0, ttlGw = 0, ttlVol = 0, ttlPaper = vm.wmsIns.length;
            angular.forEach(vm.wmsIns, function(data){
                if(data.applicationNum){
                    ttlPkgs += data.applicationNum;
                }
                if(data.gw){
                    ttlGw += data.gw;
                }
                if(data.vol){
                    ttlVol += data.vol;
                }
            })
            vm.totalLine = {
                pkgs: DataUtils.round(ttlPkgs, 0),
                gwTtl: DataUtils.round(ttlGw, 2),
                volTtl: DataUtils.round(ttlVol, 3),
                paperCount: ttlPaper
            };
        }

        $scope.deleteOne = function(index) {
            vm.wmsIns.splice(index, 1);
            setTimeout(function() {frzTable("divTable", "showTable", "footPager");}, 100);
        }

        $scope.isSaving = false;
        $scope.setAudited = function(wmsIn, isAudited) {
            if (!$rootScope.account.authorities || $rootScope.account.authorities.indexOf("AU_WMS_AUDIT") == -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_WMS_AUDIT');
                return;
            }
            if (wmsIn.status == 'allIn'){
                layer.msg($translate.instant('autopsApp.wmsIn.confirmStored'));
                return;
            }
            WmsIn.setAudit({
                id: wmsIn.id,
                userName: $rootScope.account.firstName + " " + $rootScope.account.lastName,
                audited: isAudited
            }, function(result) {
                $scope.isSaving = false;
                wmsIn.auditor = result.auditor;
                wmsIn.audited = result.audited;
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(result) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submittedfailed'));
            });
        };

        var isReverse = true;
        $scope.sortWmsins = function(title) {
            isReverse = !isReverse;
            vm.wmsIns.sort(function(a,b){
                if (isReverse){
                    return a[title]<b[title]?1:-1;
                }
                else {
                    return a[title]>b[title]?1:-1;
                }
            });
            setTimeout(function() {frzTable("divTable", "showTable", "footPager");}, 100);
        }
        $scope.audit = function(wmsIn) {
            if (!$rootScope.account.authorities || $rootScope.account.authorities.indexOf("AU_WMS_AUDIT") == -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_WMS_AUDIT');
                return;
            }

            var venders = [];
            if(wmsIn){
                venders.push({
                    vender: wmsIn.clientName,
                    venderId: wmsIn.venderId
                })
            }
            else {
                var vender = {};
                angular.forEach(vm.wmsIns, function(data){
                    if(!vender[data.venderId] && data.clientName){
                        venders.push({vender: data.clientName, venderId: data.venderId});
                        vender[data.venderId] = true;
                    }
                })
            }
            var entity = {};
            entity.venders = venders;
            $rootScope.VENDER_AUDIT_POP(entity);
        }

        $scope.checkedId = null;
        $scope.setCheckedId = function(id) {
            $scope.checkedId = id;
        }
        function exportExcel (showSku) {
            if(!vm.wmsIns || vm.wmsIns.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var fileName = "wmsInBoundPapers.xlsx";
            OpsFiles.excelWmsIn({
                timezoneOffset: new Date().getTimezoneOffset()/60,
                showSku: showSku,
                enCn: $translate.instant('global.enCn')
            }, vm.wmsIns, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }

        function editWms (wmsId) {
            WmsIn.get({
                id: wmsId
            }, function(wmsIn) {
                var htmlPath = 'app/entities/wms-in/wms-in-detail.html';
                var controllerName = 'WmsInDetailController';
                if (wmsIn.isOut){
                    var htmlPath = "app/entities/wms-in/wms-in-out-detail.html";
                    var controllerName = 'WmsInOutDetailController';
                }
                $uibModal.open({
                    templateUrl: htmlPath,
                    controller: controllerName,
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: [function() {
                            return wmsIn;
                        }]
                    }
                }).result.then(function(result) {
                    loadAll();
                }, function(result) {
                    loadAll();
                });
            })
        }

        function creatNew (isOut) {
            var wmsIn = {
                cargoList: [{allOut:false, action:"appIn"}],
                type: "WMS",
                status: "new",
                isOut: isOut,
                isVirtual: true,
                medCompanyId: $rootScope.account.companyId,
                allOut: false
            }
            if(isOut){
                wmsIn.cargoList = [];
            }
            var htmlPath = 'app/entities/wms-in/wms-in-detail.html';
            var controllerName = 'WmsInDetailController';
            if (wmsIn.isOut){
                var htmlPath = "app/entities/wms-in/wms-in-out-detail.html";
                var controllerName = 'WmsInOutDetailController';
            }
            $uibModal.open({
                templateUrl: htmlPath,
                controller: controllerName,
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return wmsIn;
                    }]
                }
            }).result.then(function(result) {
                loadAll();
            }, function(result) {
                loadAll();
            });
        }



    }
})();
