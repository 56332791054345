(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsGoodsBatchDeleteController', WmsGoodsBatchDeleteController);

    WmsGoodsBatchDeleteController.$inject = ['$scope', '$state', '$rootScope', '$timeout', '$translate', 
    'WmsIn', 'DataUtils', 'WmsWarehouse', 'WmsCargo'];

    function WmsGoodsBatchDeleteController ($scope, $state, $rootScope, $timeout, $translate, 
        WmsIn, DataUtils, WmsWarehouse, WmsCargo) {

        $timeout(function() {
            $("#field_oldLocation").focus();
            getLocations();
        });

        var vm = this;
        
        vm.cargo = {};
        vm.getWmsCargos = getWmsCargos;
        vm._onLocationSelected = _onLocationSelected;
        vm.moveAmtChange = moveAmtChange;
        vm.oldLocationSelected = oldLocationSelected;
        vm.locationSelected = locationSelected;
        vm.save = save;

        vm.wmsCargos = [];
        vm.location = {};
        vm.oldLocation = {};
        vm.cargoSku = "";
        vm.cargoLoaded = cargoLoaded;
        function cargoLoaded(){
            return vm.wmsCargos && vm.wmsCargos.length > 0;
        }

        vm._onOldLocationSelected = _onOldLocationSelected;
        function _onOldLocationSelected () {
            angular.forEach(vm.locations, function(item){
                if(item.code == vm.oldLocation.code){
                    vm.oldLocation = item;
                }                
            })
            if(!vm.oldLocation.id){
                vm.oldLocation = {};
                layer.msg("Location is not exist");
                fucusInput("field_oldLocation");
            }
            else {
                layer.msg("Matched");
                fucusInput("field_scan_sku");
            } 
        }

        function getWmsCargos() {
            if(!vm.oldLocation.warehouseId){
                return;
            }
            vm.isSaving = true;
            WmsCargo.getInventoryToMove({
                sku: vm.cargoSku,
                location: vm.oldLocation.code,
                warehouseId: vm.oldLocation.warehouseId
            }, function (result) {
                vm.isSaving = false;
                vm.wmsCargos = result;          
                updateTtl();            
                fucusInput("field_location");
            }, function(result){
                vm.isSaving = false;
                if(result.status == 404){
                    layer.msg($translate.instant('global.noRecord'));
                }
                else {
                    layer.msg($translate.instant('global.loadFailed'));
                }
            });
        }


        function getLocations () {
            WmsWarehouse.getAllLocations({companyId: $rootScope.account.companyId}, function (data) {
                vm.locations = data;
            });
        }

        function _onLocationSelected () {
            angular.forEach(vm.locations, function(item){
                if(item.code == vm.location.code){
                    vm.location = item;
                }                
            })
            if(!vm.location.id){
                vm.location = {};
                layer.msg("Location is not exist");
                fucusInput("field_location");
            }
            else {
                layer.msg("Matched");
            }
        }

        vm.ttlObj = {};
        function updateTtl () {
            vm.ttlObj.gw = 0;
            vm.ttlObj.vol = 0;
            vm.ttlObj.pkgs = 0;
            angular.forEach(vm.wmsCargos, function(data){
                if(data.availableAmt){
                    vm.ttlObj.pkgs += data.availableAmt;
                    if(data.gw){
                        vm.ttlObj.gw += data.gw * data.availableAmt;
                    }
                    if(data.vol){
                        vm.ttlObj.vol += data.vol * data.availableAmt;
                    }
                }
            })
            vm.ttlObj.gw = DataUtils.round(vm.ttlObj.gw, 2);
            vm.ttlObj.vol = DataUtils.round(vm.ttlObj.vol, 4);
        }
        function fucusInput (fdId) {
            $timeout(function() {
                $("#" + fdId).focus();   
            });
        }

        function moveAmtChange (cargo) {
            if(cargo.moveAmt > cargo.availableAmt){
                cargo.moveAmt = cargo.availableAmt;
                layer.msg($translate.instant('global.overMaxAlert'));
            }
        }

        function save() {
            if(vm.isSaving){
                return;
            }
            vm.isSaving = true;
            angular.forEach(vm.wmsCargos, function (data) {
                if (data.moveAmt){
                    data.locationNew = vm.location.code;
                    data.locationIdNew = vm.location.locationId;
                    data.areaIdNew = vm.location.areaId;
                    data.warehouseIdNew = vm.location.warehouseId;
                    data.locationTypeNew = vm.location.locationType;
                }
            })
            WmsCargo.movement(vm.wmsCargos, onSaveSuccess, onSaveError);  
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            vm.wmsCargos = [];
            updateTtl();
            fucusInput("field_scan_sku");            
            layer.msg($translate.instant('global.messages.submitted'));
        }

        function onSaveError (result) {
            vm.isSaving = false;
            if (result.status == 409) {
                layer.msg($translate.instant('global.refreshAlert'));
            } 
        }

        function oldLocationSelected(item) {
            vm.oldLocation.code = item.code;
            _onOldLocationSelected();
        }

        function locationSelected(item) {
            vm.location.code = item.code;
            _onLocationSelected();
        }
    }
})();
