(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AsyncTaskDetailController', AsyncTaskDetailController);

    AsyncTaskDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'AsyncTask'];

    function AsyncTaskDetailController($scope, $rootScope, $stateParams, previousState, entity, AsyncTask) {
        var vm = this;

        vm.asyncTask = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('autopsApp:asyncTaskUpdate', function(event, result) {
            vm.asyncTask = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
