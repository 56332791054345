(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsGoodsController', WmsGoodsController);

    WmsGoodsController.$inject = ['$scope', '$rootScope','$stateParams', '$timeout', '$translate', '$state', '$http', '$uibModal',
    'WmsGoods', 'WmsGoodsSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'ManageEnterprise', 'DataUtils', 'OpsFiles', 'AccountBind'];

    function WmsGoodsController ($scope, $rootScope, $stateParams, $timeout, $translate, $state, $http, $uibModal,
        WmsGoods, WmsGoodsSearch, ParseLinks, AlertService, pagingParams, paginationConstants, ManageEnterprise, DataUtils, OpsFiles, AccountBind) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.searchGoods = '';

        vm.editGoods = editGoods;
        vm.goBack = goBack;
        vm.importExcel = importExcel;
        vm.loadFiles = loadFiles;
        vm.reloadFiles = reloadFiles;
        vm.delGoods = delGoods;
        vm.copyGoods = copyGoods;
        vm.uploadManageEnterFile = uploadManageEnterFile;
        vm.saveAll = saveAll;
        vm.getAccountBinds = getAccountBinds;
        vm.sendProductApi = sendProductApi;
        vm.delAll = delAll;
        vm.getProductApiRequestBody = getProductApiRequestBody;

        vm.accountBinds = [];
        function getAccountBinds (){
            if(!$rootScope.account.companyId || vm.accountBinds.length > 0){
                return;
            }
            AccountBind.getEdiAccounts({
                companyId: $rootScope.account.companyId,
                type: "product"
            }, function(result){
                vm.accountBinds = result;
            })
        }
        function sendProductApi (accountBind){
            var pushOrdersDTO = {};
            pushOrdersDTO.accountBind = accountBind;
            pushOrdersDTO.wmsGoods = vm.wmsGoods;
            WmsGoods.sendProductApi(pushOrdersDTO, function(result){
                layer.msg($translate.instant('global.send_success'));
            }, function(error){
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                }
                else {
                    layer.msg($translate.instant('global.send_fail'));
                }
            })
        }
        function getProductApiRequestBody (accountBind){
            var pushOrdersDTO = {};
            pushOrdersDTO.accountBind = accountBind;
            pushOrdersDTO.wmsGoods = vm.wmsGoods;
            layer.load(1, {shade: 0.3});
            WmsGoods.getProductApiRequestBody(pushOrdersDTO, function(result){
                layer.closeAll();
                $rootScope.DOWNLOAD_TXT(result.value, "send_products_request_body.txt");
            }, function(error){
                layer.closeAll();
            });
        }

        $timeout(function (){
            if($rootScope.manageEnterprise && $rootScope.manageEnterprise.id){
                loadAll();
            }
            else {
                goBack();
            }
        });
        vm.isSaving = true;
        function saveAll (){
            vm.isSaving = true;
            if(!$rootScope.manageEnterprise.companyId || !$rootScope.manageEnterprise.id){
                return;
            }
            angular.forEach(vm.wmsGoods, function(data){
                data.companyId = $rootScope.manageEnterprise.companyId;
                data.manageEnterpriseId = $rootScope.manageEnterprise.id;
                if(!data.goodsBatchList){
                    data.goodsBatchList = [{code:"01"}];
                }
            })
            WmsGoods.saveAll(vm.wmsGoods, function(){
                vm.isSaving = true;
                loadAll();
                layer.msg($translate.instant('global.save_success'));
            }, function(){
                vm.isSaving = false;
                layer.msg($translate.instant('global.save_fail'));
            })
        }

        vm.files = new Array();
        function loadFiles (){
            if ($rootScope.manageEnterprise.id) {
                ManageEnterprise.loadManageEnterpiseFiles({
                    manageEnterId:$rootScope.manageEnterprise.id,
                    goodsId:null}, function (data){
                    vm.files = new Array();
                    if(data.length>0){
                        for(var i = 0 ; i < data.length; i++){
                            var temp  = new Object();
                            temp['filepath'] = data[i]['filepath'];
                            temp['filename'] = data[i]['filename'];
                            temp['fileurl'] = data[i]['fileurl'];
                            temp['fileist'] = true;
                            vm.files.push(temp);
                        }
                    }
                });
            }
        }

        function reloadFiles (file) {
            if (!angular.isDefined(file.filepath)) {
                return;
            }

            var exist = false;
            if (vm.files.length > 0) {
                for (var i = 0; i < vm.files.length; i++) {
                    if (vm.files[i]['filename'] == file['filename']) {
                        vm.files[i]['filepath'] = file['filepath'];
                        vm.files[i]['filename'] = file['filename'];
                        vm.files[i]['fileurl'] = file['fileurl'];
                        exist = true;
                        break;
                    }
                }
            }
            if (!exist) {
                var temp = new Object();
                temp['filepath'] = file['filepath'];
                temp['filename'] = file['filename'];
                temp['fileurl'] = file['fileurl'];
                temp['fileist'] = true;
                vm.files.push(temp);
            }
        }

        function uploadManageEnterFile(){
            $("input[name=manageEnterFile]").trigger('click');
        }

        $("#manageEnterFile").change(function(){
            var file = $('#manageEnterFile').get(0).files[0];
            if (!file) {
                return;
            }
            var fd = new FormData();
            fd.append('file', file);
            $http.post(
                    'api/uploadManageEnterFile/' + $rootScope.manageEnterprise.id, fd, {
                        transformRequest : angular.identity,
                        headers : {
                            'Content-Type' : undefined
                        },
                    }).success(
                    function(data) {
                        if (angular.isDefined(data.filepath)) {
                            reloadFiles(data);
                            $('#manageEnterFile').val(null);
                            layer.msg($translate.instant('global.uploadSuccess'));
                        }
                    }).error(function(error) {
                        $('#manageEnterFile').val(null);
                        layer.msg($translate.instant('global.uploadFail'));
            });
        });
        function delGoods(goods){
            if(goods.id){
                layer.confirm($translate.instant('global.confirmToDelete'), {
                    title: $translate.instant('global.messageInfo'),
                    btn: [$translate.instant('global.confirm'),$translate.instant('global.cancel')]
                }, function(index) {
                    WmsGoods.del({
                        id:goods.id
                    }, function(result){
                        var indexToDel= vm.wmsGoods.indexOf(goods);
                        if (indexToDel > -1) {
                            vm.wmsGoods.splice(indexToDel, 1);
                        };
                        layer.close(index);
                        $translate.refresh();
                        layer.msg($translate.instant('global.delete_success'));
                    }, function(result){
                        if(result.status == 403){
                            $rootScope.OPS_ALERT($translate.instant('autopsApp.manageEnterprise.wmsGoods.forbiddenByInventory'));
                        }
                        else {
                            layer.msg($translate.instant('global.delete_fail'));
                        }

                    });

                },function(index){
                    layer.close(index);
                });
            }
            else {
                var indexToDel= vm.wmsGoods.indexOf(goods);
                if (indexToDel > -1) {
                    vm.wmsGoods.splice(indexToDel, 1);
                };
            }
        }

        function copyGoods (wmsGoods) {
            var newGoods = angular.copy(wmsGoods);
            newGoods.sku = DataUtils.getNextCode(wmsGoods.sku);
            newGoods.code = DataUtils.getNextCode(wmsGoods.code);
            newGoods.id = null;
            newGoods.manageEnterpriseId = $rootScope.manageEnterprise.id;
            newGoods.goodsBatchList = [{code:"01"}];
            newGoods.warehouseIn = null;
            newGoods.warehouseOut = null;
            newGoods.warehouseBalance = null;
            newGoods.customsIn = null;
            newGoods.customsOut = null;
            newGoods.customsBalance = null;
            newGoods.id = null;
            $uibModal.open({
                templateUrl: 'app/entities/wms-goods/wms-goods-dialog.html',
                controller: 'WmsGoodsDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return newGoods;
                    }]
                }
            }).result.then(function(result) {
                loadAll();
            }, function(result) {
                loadAll();
            });
        }

        var isReverse = true;
        function sortGoods (title) {
            isReverse = !isReverse;
            vm.wmsGoods.sort(function(a,b){
                if (isReverse){
                    return a[title]<b[title]?1:-1;
                }
                else {
                    return a[title]>b[title]?1:-1;
                }
            });
        }

        function editGoods (wmsGood) {
            $uibModal.open({
                templateUrl: 'app/entities/wms-goods/wms-goods-dialog.html',
                controller: 'WmsGoodsDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        if(wmsGood && wmsGood.id){
                            return WmsGoods.get({id : wmsGood.id}).$promise;
                        }
                        else {
                            return {
                                companyId: $rootScope.manageEnterprise.companyId,
                                packageType: "CTNS",
                                manageEnterpriseId: $rootScope.manageEnterprise.id,
                                goodsBatchList: [{code:"01"}]
                            };
                        }
                    }]
                }
            }).result.then(function(result) {
                loadAll();
            }, function(result) {
                loadAll();
            });
        }


        function importExcel (file) {
            if (!file) {return;}
            layer.msg("Parsing...", {
                shade: 0.3,
                time: 2000000
            });
            var fd = new FormData();
            fd.append('file', file);
            fd.append('companyId', $rootScope.manageEnterprise.companyId);
            fd.append('manageEnterpriseId', $rootScope.manageEnterprise.id);
            $http.post(
                'api/wms-goods/importByExcel', fd, {
                    transformRequest: angular.identity,
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    layer.closeAll();
                    var result = new Object();
                    result['data'] = data;
                    $rootScope.DOWNLOADTXT(result, "importResult.txt");
                    loadAll ()
                }).error(function(error) {
                    layer.closeAll();
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                });
        };

        $scope.jobType = null;

        $scope.setJobType = function(type) {
            $scope.jobType = type;
        }

        $scope.setPageSize = function(pageSize) {
            paginationConstants.itemsPerPage = vm.itemsPerPage = pageSize;
            pagingParams.page = vm.page = 1;
            vm.currentSearch = vm.searchQuery = null;
            loadAll();
        }


        function loadAll () {
            if (vm.currentSearch) {
                if($scope.jobType){
                    WmsGoods.searchByNums({
                        type: $scope.jobType,
                        numStr: vm.currentSearch,
                        manageEnterpriseId: $rootScope.manageEnterprise.id
                    }, onSuccess, onError);
                    return;
                }
                WmsGoodsSearch.query({
                    manageEnterpriseId: $rootScope.manageEnterprise.id,
                    query: vm.currentSearch,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                WmsGoods.query({
                    manageEnterpriseId: $rootScope.manageEnterprise.id,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                if(headers('link')){
                    vm.links = ParseLinks.parse(headers('link'));
                    vm.totalItems = headers('X-Total-Count');
                    vm.queryCount = vm.totalItems;
                }
                vm.wmsGoods = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = true;
            vm.currentSearch = searchQuery;
            loadAll();
        }

        function clear () {
            vm.links = null;
            pagingParams.page = vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = vm.searchQuery = null;
            loadAll();
        }
        function goBack () {
            if($rootScope.account.companyId){
                $state.go('manage-enterprise', null, {
                    reload: false
                });
            }
            else {
                $state.go('home', null, {
                    reload: false
                });
            }
        }

        function delAll () {
            if($rootScope.HAVENOAUTH('ROLE_BOSS')){
                $rootScope.FORBIDDEN_BY_ALERT('ROLE_BOSS');
                return;
            }
            if(!vm.wmsGoods || vm.wmsGoods.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            layer.confirm($translate.instant('entity.action.delAll'), {
                icon: 3,
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function() {
                layer.load(1, {shade: 0.3});
                WmsGoods.delAll(vm.wmsGoods, function(){
                    layer.closeAll();
                    loadAll();
                }, function(){
                    layer.closeAll();
                })
            }, function() {
            });
        }

        $scope.excelExport = function(includePic) {
            var fileName = $translate.instant('global.menu.entities.manageEnterprise') + ".xlsx";
            OpsFiles.exportWmsGoods({
                includePic: includePic,
                id: $rootScope.manageEnterprise.id,
                title: $rootScope.manageEnterprise.name + " - " + $translate.instant('global.menu.entities.manageEnterprise'),
                enCn: $translate.instant('global.enCn')
            }, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }

        $scope.refreshInventoryBySku = function() {
            if ($rootScope.HAVENOAUTH('ROLE_BOSS')){
                layer.msg($translate.instant('global.forbiddenBoss'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/ops-group/ops-group-detail.html',
                controller: 'OpsGroupDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        return {
                            ph: $translate.instant('global.excelCopyPh'),
                            title: $translate.instant('autopsApp.manageEnterprise.refreshInventoryBySku')
                        };
                    }]
                }
            }).result.then(function(result) {
                if(result){
                    layer.load(1, {shade: 0.3});
                    WmsGoods.refreshInventoryBySku({
                        id: $rootScope.manageEnterprise.id,
                        numStr: result
                    }, function (result) {
                        layer.closeAll();
                        layer.msg($translate.instant('global.messages.submitted'));
                    }, function(result){
                        layer.closeAll();
                        layer.msg($translate.instant('global.messages.submittedfailed'));
                    });
                }
            }, function() {});
        }


    }
})();
