(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsClaimCargoDialogController', WmsClaimCargoDialogController);

    WmsClaimCargoDialogController.$inject = ['$timeout', '$rootScope', '$scope', '$uibModalInstance', '$translate', 
    'WmsCargo', 'entity'];

    function WmsClaimCargoDialogController ($timeout, $rootScope, $scope, $uibModalInstance, $translate, 
        WmsCargo, entity) {

        var vm = this;
        vm.wmsCargo = entity;
        vm.clear = clear;
        vm.save = save;
        $scope.isSaving = false;
        vm.newTracking = "";

        $timeout(function (){
            if($rootScope.account.companyId){
                $rootScope.LOADALLVENDERS();
            }
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        $scope._onClientSelected = function(data) {
            vm.wmsCargo.clientId = data.id;
        }        

        function save () {
            if ($scope.isSaving){
                return;
            }
            $scope.isSaving = true;     
            var wmsCargo = angular.copy(vm.wmsCargo);
            if(!$rootScope.account.companyId){
                wmsCargo.returnTracking = vm.newTracking;
            }       
            WmsCargo.claimCargo(wmsCargo, function (result) {
                $scope.isSaving = false;
                $uibModalInstance.close(result);
                layer.msg($translate.instant('global.save_success'));
            }, function (result){
                $scope.isSaving = false;
                if(result.status == 403){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsCargo.lockedAlert'));   
                }
                else if(result.status == 406){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsCargo.trackingWrongAlert'));   
                }
                else {
                    layer.msg($translate.instant('global.save_fail'));   
                }                                 
            });
        }

    }
})();
