(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('BookingCtns', BookingCtns);

    BookingCtns.$inject = ['$resource','DateUtils'];
    
    function BookingCtns($resource, DateUtils) {
        return $resource('api/bookingCtnss/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getFilterPage': {
                method: 'POST',
                url:'api/bookingCtnss/getFilterPage',
                isArray: true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.etdFM = DateUtils.convertLocalDateToServer(data.etdFM);
                    data.etdTO = DateUtils.convertLocalDateToServer(data.etdTO);
                    if(!data.venderName){
                        data.venderId = null;
                    }
                    return angular.toJson(data);
                }
            },      
            'splitCargoCom': { 
                method:'PUT',
                url:'api/bookingCtnss/splitCargoCom'
            }, 
            'auditOneWithCommDatabase': { 
                method:'POST',
                url:'api/bookingCtnss/auditOneWithCommDatabase'
            },
            'batchAuditWithCommDatabase': { 
                method:'POST',
                isArray: true,
                url:'api/bookingCtnss/batchAuditWithCommDatabase'
            },
            'auditWithCommDatabase': { 
                method:'POST',
                url:'api/bookingCtnss/auditWithCommDatabase'
            },
            'deletePlByGuideId': { 
                method:'DELETE',
                url:'api/bookingCtnss/deletePlByGuideId'
            },   
            'autoMatchCommDatabase': { 
                method:'POST',
                url:'api/bookingCtnss/autoMatchCommDatabase'
            },
            'batchAuditPlByFilter': { 
                method:'POST',
                isArray: true,
                url:'api/bookingCtnss/batchAuditPlByFilter'
            },
            'update': { method:'PUT', isArray: true}
        });
    }
})();
