(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('MonthlyPaymentController', MonthlyPaymentController);

    MonthlyPaymentController.$inject = ['$scope', '$rootScope', '$translate', '$timeout', '$uibModal', 
    'Principal', 'GuideRates', 'DateUtils', 'OpsVender', 'OpsFiles', '$http', 'Email', 'OpsExchangeRate', 'DataUtils', 'AutoCheck'];

    function MonthlyPaymentController($scope, $rootScope, $translate, $timeout, $uibModal, 
        Principal, GuideRates, DateUtils, OpsVender, OpsFiles, $http, Email, OpsExchangeRate, DataUtils, AutoCheck) {
        
        var guideRates = [];
        $scope.showrates = [];
        $scope.showTotal = 0;
        $scope.teusTotal = 0;
        var autoCheck = {};
        $timeout(function (){
            if($rootScope.AUTOCHECK && $rootScope.AUTOCHECK.idSet){
                autoCheck = angular.copy($rootScope.AUTOCHECK);
                OpsVender.getInvoiceVenderDTO({id: autoCheck.payerVid}, function(result) {
                    $scope.requestDTO.dnOnly = result.paymentTerm == "credit"?true:false;
                    
                    $scope.requestDTO.venderId = result.id;                 
                    $scope.requestDTO.payerName = result.companyName;   
                    $scope.requestDTO.mailAddress = result.address;
                    $scope.requestDTO.mailMob = result.mob;
                    $scope.requestDTO.mailAttn = result.attn;
                    if(result.invoiceInfos && result.invoiceInfos.length > 0){
                        $scope.requestDTO.invoiceTitle = result.invoiceInfos[0].invoiceTitle;
                        $scope.requestDTO.taxNum = result.invoiceInfos[0].taxNum;
                        if(autoCheck.currency == $rootScope.account.baseCurrency){
                            $scope.requestDTO.bankInfo = result.invoiceInfos[0].bankInfo;
                        }
                        else {
                            $scope.requestDTO.bankInfo = result.invoiceInfos[0].bankInfoUsd;
                        }                        
                        $scope.requestDTO.addressTel = result.invoiceInfos[0].addressTel;
                        $scope.requestDTO.mailAddress = result.invoiceInfos[0].mailAddress;
                        $scope.requestDTO.mailMob = result.invoiceInfos[0].mailMob;
                        $scope.requestDTO.mailAttn = result.invoiceInfos[0].mailAttn;
                        $scope.requestDTO.eInvoiceEmail = result.invoiceInfos[0].einvoiceEmail;
                    }
                });
                $scope.requestDTO.etdFrom = autoCheck.dateFrom;
                $scope.requestDTO.etdEnd = autoCheck.dateTo;

                if(autoCheck.isDiff){
                    $scope.requestDTO.bizType = "balance";
                }
                else if(autoCheck.isPay){
                    $scope.requestDTO.bizType = "payable";
                }
                else {
                    $scope.requestDTO.bizType = "receivable";
                }

                if(autoCheck.currency){
                    $scope.getListByCurrency(autoCheck.currency);
                }
                else {
                    $scope.isSaving = true;
                    AutoCheck.getAllPayCurrencys(autoCheck, function(result) {
                        $scope.isSaving = false;
                        if(!result || result.length == 0){
                            return;
                        }
                        $scope.getListByCurrency(result[0].key);
                        $scope.currencyMaps = result;                       
                    }, function(response) {
                        $scope.isSaving = false;
                    });
                }

                $rootScope.AUTOCHECK=null;           
            }
        });

        $scope._onItemSelected = function(data) {
            $scope.requestDTO.itemId = data.id;
        }

        $scope.getListByCurrency = function(currency) {
            autoCheck.currency = currency;
            $scope.requestDTO.currency = currency;
            $scope.requestDTO.autoCheck = autoCheck;

            if (!DateUtils.isSameMonth($scope.requestDTO.etdFrom, $scope.requestDTO.etdEnd)) {
                layer.msg($translate.instant('global.messages.etdDiffMonthAlert'));
                return;
            }

            $scope.isSaving = true;
            GuideRates.getMonthlyPayment($scope.requestDTO, function(result) {
                $scope.isSaving = false;
                if (!result || !result.ratesList || result.ratesList.length == 0) {
                    $scope.reportavailalbe = false;
                    layer.msg($translate.instant('stats.stat.resultNullAlert'));
                }
                else {
                    $scope.reportavailalbe = true;
                    $scope.showrates = result.ratesList;
                    guideRates = result.guideRatesList;
                    $scope.teusTotal = result.teus;
                    initContent();
                    if(!$scope.requestDTO.currency){
                        layer.msg($translate.instant('stats.stat.currencyNullAlert'));   
                    }
                }
            }, function(response) {
                $scope.isSaving = false;
            });     
        }


        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
        		if (!$rootScope.OPSCURRENCYS){
            		OpsExchangeRate.getAllCurrencys(function(result) {
                		$rootScope.OPSCURRENCYS = result;
            		})
        		}		
            });
        }
        else {
        	if (!$rootScope.OPSCURRENCYS){
            	OpsExchangeRate.getAllCurrencys(function(result) {
               	 	$rootScope.OPSCURRENCYS = result;
            	})
        	}
        }
        $scope.requestDTO = {};
        $scope.datePickerForEtd = {};
        $scope.datePickerForEtd.status = {
            opened: false
        };
        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };

        $scope.datePickerForCargoReadyDate = {};
        $scope.datePickerForCargoReadyDate.status = {
            opened: false
        };
        $scope.datePickerForCargoReadyDateOpen = function($event) {
            $scope.datePickerForCargoReadyDate.status.opened = true;
        };

        $rootScope.LOADALLVENDERS();

        $scope._onClientSelected = function(data) {
            $scope.requestDTO.venderId = data.id;
            $scope.typeChange();
        }
        
        var ratesTotal = 0;
        var showTotal =0;   
        $scope.getContents = function() {
            if (!$scope.requestDTO.venderId || !$scope.requestDTO.etdFrom || !$scope.requestDTO.etdEnd || !$scope.requestDTO.currency) {
                layer.msg($translate.instant('stats.stat.nullFilterAlert'));
                return;
            }
            if (DateUtils.compareDate($scope.requestDTO.etdFrom, $scope.requestDTO.etdEnd) > 0) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                return;            
            }
            if (!DateUtils.isSameMonth($scope.requestDTO.etdFrom, $scope.requestDTO.etdEnd)) {
                layer.msg($translate.instant('global.messages.etdDiffMonthAlert'));
                return;
            }

            $scope.requestDTO.companyId = $rootScope.account.companyId;
            $scope.isSaving = true;
            GuideRates.getMonthlyPayment($scope.requestDTO, function(result) {
                $scope.isSaving = false;
                if (!result || !result.ratesList || result.ratesList.length == 0) {
                    $scope.reportavailalbe = false;
                    layer.msg($translate.instant('stats.stat.resultNullAlert'));
                }
                else {
                    $scope.reportavailalbe = true;
                    $scope.showrates = result.ratesList;
                    guideRates = result.guideRatesList;
                    $scope.teusTotal = result.teus;
                    initContent();
                }

            }, function(response) {
                $scope.isSaving = false;
            });
        }
        
        var initContent = function() {    
            if (!$scope.showrates){
                $scope.showrates = [];
                for (var i = 0; i < 10; i ++){
                    $scope.showrates.push({});
                }
                $scope.teusTotal = null;
            }            
            showTotal = 0;
            ratesTotal = 0;

            //统计total            
            for (var i = 0; i < $scope.showrates.length; i++){
                if ($scope.showrates[i].totalB){
                    showTotal = showTotal + $scope.showrates[i].totalB;  
                }                                 
            }             
            if (!guideRates) {
                guideRates = [];
            }
            //数据核对            
            for (var i = 0; i < guideRates.length; i++){
                if (guideRates[i].isPay){
                    if (guideRates[i].payAmount){
                        ratesTotal = ratesTotal - guideRates[i].payAmount;  
                    }
                }
                else {
                    if (guideRates[i].payAmount){
                        ratesTotal = ratesTotal + guideRates[i].payAmount;  
                    }                    
                }                              
            }
            ratesTotal = DataUtils.round(ratesTotal, 2);
            showTotal = DataUtils.round(showTotal, 2);
            if (showTotal != ratesTotal){
                $scope.typeChange();
                alert("加载出错！");
            }
            else {
                for (var i = 0; i < $scope.showrates.length; i++){
                    if ($scope.showrates[i].totalB){
                        $scope.showrates[i].totalB = DataUtils.round($scope.showrates[i].totalB, 2);
                    }                    
                }
                $scope.showTotal = DataUtils.round(showTotal, 2);
            }
            $translate.refresh();
        }

        initContent();
        $scope.typeChange = function() {
            $scope.showrates = [];
            guideRates = [];
            $scope.showTotal = 0;   
            $scope.teusTotal = null;   
            ratesTotal = 0;
            showTotal =0;   
            initContent();
        }

        $scope.delete = function(id) {
            for (var i = $scope.showrates.length - 1; i>=0; i--){
                if ($scope.showrates[i].id == id){
                    $scope.showrates.splice(i, 1);
                    break;
                }
            }
            for (var i = guideRates.length - 1; i>=0; i--){
                if (guideRates[i].id == id){
                    guideRates.splice(i, 1);
                    break;
                }
            }
            initContent();
        }
        var getJobNumStr = function() {
            var result = [], hash = {}, outStr = "";
            for (var i = 0, elem; i < $scope.showrates.length; i++) {
                var elem = $scope.showrates[i].jobNum;
                if (elem && !hash[elem]) {
                    result.push(elem);
                    hash[elem] = true;
                }
            }
            for (var i = 0; i < result.length; i++){
                outStr = outStr + result[i] + " ";
            }
            return outStr;
        }
        var getBillNumStr = function() {
            var result = [], hash = {}, outStr = "";
            for (var i = 0, elem; i < $scope.showrates.length; i++) {
                var elem = $scope.showrates[i].billNum;
                if (elem && !hash[elem]) {
                    result.push(elem);
                    hash[elem] = true;
                }
            }
            for (var i = 0; i < result.length; i++){
                outStr = outStr + result[i] + " ";
            }
            if(outStr.length > 255){
                outStr = outStr.substring(0, 255);
            }
            return outStr;
        }
        var getBlNumStr = function() {
            var result = [], hash = {}, outStr = "";
            for (var i = 0, elem; i < $scope.showrates.length; i++) {
                var elem = $scope.showrates[i].mblNum;
                if (elem && !hash[elem]) {
                    result.push(elem);
                    hash[elem] = true;
                }
            }
            for (var i = 0; i < result.length; i++){
                outStr = outStr + result[i] + " ";
            }
            return outStr;
        }
        var getSalesStr = function() {
            var result = [], hash = {}, outStr = "";
            for (var i = 0, elem; i < $scope.showrates.length; i++) {
                var elem = $scope.showrates[i].salesName;
                if (elem && !hash[elem]) {
                    result.push(elem);
                    hash[elem] = true;
                }
            }
            for (var i = 0; i < result.length; i++){
                outStr = outStr + result[i] + " ";
            }
            if(outStr.length > 155){
                outStr = outStr.substring(0, 155);
            }
            return outStr;
        }
        $scope.invoicePopShow = function() { 
            if ($scope.requestDTO.bizType == "balance"){
                $scope.requestDTO.isBalance = true;                
            } else{
                $scope.requestDTO.isBalance = false;   
            } 
            $scope.requestDTO.jobNums = getJobNumStr();
            $scope.requestDTO.billNum = getBillNumStr();
            $scope.requestDTO.blNums = getBlNumStr();
            $scope.requestDTO.sales = getSalesStr();

            $scope.requestDTO.mark = autoCheck.code;
            var guide = {};
            guide.id = 0;
            guide.invoiceRequest = $scope.requestDTO;
            guide.guideRates = guideRates;
            guide.booking = {};
            guide.etd = $scope.requestDTO.etdEnd;
            guide.etd = $scope.requestDTO.etdEnd;

            $uibModal.open({
                templateUrl: 'app/entities/guide/invocie-request.html',
                controller: 'InvoiceRequestController',
                size: 'lg',
                backdrop: false,
                resolve: {
                    entity: [function() {
                        return guide;
                    }]
                }
            }).result.then(function(result) {
                $scope.typeChange();  
            }, function() {});
        };

        $scope.paymentPopShow = function(invalid) {
            if ($rootScope.account.accountByEtd){
                $scope.requestDTO.accountingDate = $scope.requestDTO.etdEnd;
            }
            if ($scope.requestDTO.bizType == "balance"){
                $scope.requestDTO.isBalance = true;                
            } else{
                $scope.requestDTO.isBalance = false;   
            } 
            $scope.requestDTO.mark = autoCheck.code;

            $scope.requestDTO.jobNums = getJobNumStr();
            $scope.requestDTO.billNum = getBillNumStr();
            $scope.requestDTO.blNums = getBlNumStr();
            $scope.requestDTO.sales = getSalesStr();
            
            var guide = {};
            guide.id = 0;
            guide.paymentRequest = $scope.requestDTO;
            guide.guideRates = guideRates;
            guide.booking = {};
            guide.etd = $scope.requestDTO.etdEnd;
            guide.etd = $scope.requestDTO.etdEnd;
            
            $uibModal.open({
                templateUrl: 'app/entities/guide/payment-request.html',
                controller: 'PaymentRequestController',
                size: 'lg',
                backdrop: false,
                resolve: {
                    entity: [function() {
                        return guide;
                    }]
                }
            }).result.then(function(result) {
                $scope.typeChange(); 
            }, function() {});
        }

        $scope.showTotalTemp = 0;
        var getShowTotalTemp = function() {               
            if (!$scope.tempContents.ratesList){
                return;
            }
            $scope.showTotalTemp = 0; 
            angular.forEach($scope.tempContents.ratesList, function(data){
                if (data.totalB){
                    $scope.showTotalTemp = $scope.showTotalTemp + data.totalB;  
                }   
            })
            $scope.showTotalTemp = $scope.showTotalTemp.toFixed(2);  
            $translate.refresh();
        }

        $scope.tempContents = {}
        $scope.getRatesByMblNum = function() {
            if($scope.isSaving){return;}
            $scope.isSaving = true;
            if(!$scope.requestDTO.mblNum){
                return;
            }
            if (!$scope.requestDTO.venderId || !$scope.requestDTO.etdFrom || !$scope.requestDTO.etdEnd || !$scope.requestDTO.currency) {
                layer.msg($translate.instant('stats.stat.nullFilterAlert'));
                $scope.isSaving = false;
                return;
            }
            if (DateUtils.compareDate($scope.requestDTO.etdFrom, $scope.requestDTO.etdEnd) > 0) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                return;            
            }
            if (!DateUtils.isSameMonth($scope.requestDTO.etdFrom, $scope.requestDTO.etdEnd)) {
                layer.msg($translate.instant('global.messages.etdDiffMonthAlert'));
                return;
            }
            $scope.requestDTO.companyId = $rootScope.account.companyId;

            GuideRates.getRatesByMblNum($scope.requestDTO, function(result) {
                if (!result || !result.ratesList || result.ratesList.length == 0){
                    layer.msg($translate.instant('stats.stat.resultNullAlert'));
                    $scope.isSaving = false;
                    return;
                }
                else {
                    $scope.tempContents = result;
                    getShowTotalTemp();
                    $scope.isSaving = false;
                    layer.open({
                        type: 1,
                        area: ['1060px', '520px'],
                        moveOut: false,
                        title: $scope.requestDTO.mblNum,
                        shade: 0.3,
                        moveType: 0,
                        maxmin: true,
                        shift: 0,
                        content: $("#tempContentsPop"),
                        btn: [$translate.instant('entity.action.confirm')],
                        yes: function(index, layero) {
                            addAllFromTempContents();
                            layer.close(index);
                        }
                    });
                }
            }, function(response) {
                $scope.isSaving = false;
            });
        }

        $scope.deleteFromTempContents = function(id) {
            for (var i = $scope.tempContents.ratesList.length - 1; i>=0; i--){
                if ($scope.tempContents.ratesList[i].id == id){
                    $scope.tempContents.ratesList.splice(i, 1);
                    break;
                }
            }
            for (var i = $scope.tempContents.guideRatesList.length - 1; i>=0; i--){
                if ($scope.tempContents.guideRatesList[i].id == id){
                    $scope.tempContents.guideRatesList.splice(i, 1);
                    break;
                }
            }
            getShowTotalTemp();
        }

        var addAllFromTempContents = function() {
            var isExist = false;
            if (!$scope.showrates){
                $scope.showrates = [];
            }
            if (!guideRates){
                guideRates = [];
            }
            for (var i = $scope.tempContents.ratesList.length - 1; i>=0; i--){
                isExist = false;
                angular.forEach($scope.showrates, function(data){
                    if (data.id == $scope.tempContents.ratesList[i].id){
                        isExist = true;
                    }
                })
                if (!isExist){
                    $scope.showrates.push($scope.tempContents.ratesList[i]);
                }
            }
            for (var i = $scope.tempContents.guideRatesList.length - 1; i>=0; i--){
                isExist = false;
                angular.forEach(guideRates, function(data){
                    if (data.id == $scope.tempContents.guideRatesList[i].id){
                        isExist = true;
                    }
                })
                if (!isExist){
                    guideRates.push($scope.tempContents.guideRatesList[i]);
                }
            }
            $scope.tempContents = [];
            initContent();
        }


        $scope.format = 2;
        $scope.reportavailalbe = false;
        $scope.fileName = "";

        $scope.exportMonthlyStatement = function(format) {
            $scope.format = format;
            $scope.fileName = '';
            $scope.fileName_btn = layer.open({
                type: 1,
                area: ['300px', '180px'],
                btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')],
                title: $translate.instant('global.fileName'),
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: $("#templateReportName"),
                success: function(layero, index) {
                    $("#fileName").focus();
                },
                yes: function(index, layero) {
                    if ($scope.fileName == '') {
                        $scope.fileName = 'ChargesList';
                    }
                    generateReport();
                    layer.close($scope.fileName_btn);
                },
                no: function(index, layero) {
                    layer.close(index);
                }
            });
        }

        $("#fileName").keydown(function(event) {
            $scope.keycode = window.event ? event.keyCode : event.which;
            if ($scope.keycode == 13) {
                if ($scope.fileName == '') {
                    $scope.fileName = 'ChargesList';
                }
                generateReport();
                layer.close($scope.fileName_btn);
            }
        })


        var generateReport = function() {            
            var totalList = [];
            var currencyTotal = {
                currency:$scope.requestDTO.currency,
                total:$scope.showTotal
            };
            totalList.push(currencyTotal);

            var reportDataDTO = {};
            reportDataDTO.totalList = totalList;
            reportDataDTO.ratesList = $scope.showrates;

            OpsFiles.generateMonthlyPaymentReport({
                fileType: $scope.format
            }, reportDataDTO, function(result) {
                exportExcelOrPdf(result);
            }, function(error) {
                layer.msg($translate.instant('stats.stat.generateTrackingReportFailed'));
            });
        }

        var exportExcelOrPdf = function(result) {
            if (angular.isDefined($scope.format) && $scope.format == 2) {
                var file = new Blob([result.data], {
                    type: 'application/vnd.ms-excel'
                });
                var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
                var aTag = document.createElement("a");
                aTag.download = $scope.fileName + ".xlsx";
                aTag.href = blobURL;
                aTag.click();
            } else {
                var file = new Blob([result.data], {
                    type: 'application/pdf'
                });
                var fileURL = URL.createObjectURL(file);
                var previewDialog = layer.open({
                    type: 2,
                    area: ['900px', '680px'],
                    maxmin: true,
                    title: $translate.instant("stats.stat.viewMonthlyStatement"),
                    shade: 0.3,
                    moveType: 0,
                    shift: 0,
                    content: fileURL
                })
            }
        }


    }
})();