(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('GuideDoc', GuideDoc);

    GuideDoc.$inject = ['$resource', 'DateUtils'];
    
    function GuideDoc($resource, DateUtils) {
        return $resource('api/guideDocs/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if(data){
                        data = angular.fromJson(data);
                        angular.forEach(data, function(doc){
                            doc.createdTime = DateUtils.convertDateTimeFromServer(doc.createdTime);
                        })
                    }
                    return data;
                }
            },
            'update': { method:'PUT', isArray: true},
            'save': { method:'POST', isArray: false}
        });
    }
})();
