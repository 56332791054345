(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CombineCostDialogController', CombineCostDialogController);

    CombineCostDialogController.$inject = ['$rootScope', '$uibModalInstance', '$translate', '$timeout', 
    'entity', 'WinAES', 'Guide', 'ExpressParcel'];

    function CombineCostDialogController ($rootScope, $uibModalInstance, $translate, $timeout, 
        entity, WinAES, Guide, ExpressParcel) {
               
        var vm = this;
        vm.rateCopy = entity;
        vm.clear = clear;
        vm.delRate = delRate;  
        vm.delAllShareCost = delAllShareCost;      
        vm.isSaving = false;
        vm.isMaster = false;

        vm.shareRates = [];
        vm.mRate = {};

        var refresh = false;
        $timeout(function (){
            getCombineRates();
        });

        function getCombineRates () {
            vm.shareRates = [];
            Guide.getCombineRates({sharedOn : vm.rateCopy.sharedOn}, function(result){
                angular.forEach(result, function(data){
                    if(vm.rateCopy.dnNum.indexOf(data.jobNum) != -1){
                        vm.mRate = data;
                    }
                })
                if(vm.rateCopy.id == vm.mRate.id){
                    vm.isMaster = true;
                }
                angular.forEach(result, function(data){
                    if (data.id != vm.mRate.id){
                        vm.shareRates.push(data);                        
                    }
                })
            })
        }
        
        function clear () {
            $uibModalInstance.close(refresh);
        }

        function delRate (rate) {
            if ($rootScope.HAVENOAUTH('AU_CHARGES_SHARE')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_CHARGES_SHARE');
                return;
            }
            var allRates = angular.copy(vm.shareRates);
            for(var i = allRates.length -1; i >= 0; i-- ){
                if (allRates[i].id == rate.id){
                    allRates.splice(i, 1);
                }
            }
            vm.isSaving = true;
            if (allRates.length == 0){
                delAllShareCost();
            }
            else {
                ExpressParcel.reCombineCost(rate, function(result){
                    vm.isSaving = false;
                    refresh = true;
                    getCombineRates();
                    layer.msg($translate.instant('global.messages.submitted'));
                }, function(){
                    vm.isSaving = false;
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                })
            }
        }
        function delAllShareCost () {
            if ($rootScope.HAVENOAUTH('AU_CHARGES_SHARE')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_CHARGES_SHARE');
                return;
            }
            if (!vm.isMaster && vm.mRate.payAmount){
                layer.msg($translate.instant('autopsApp.guide.delFromMasterAlert'));
                return;
            }
            ExpressParcel.delAllCombineCost({sharedOn:vm.rateCopy.sharedOn}, function(){
                vm.isSaving = false;
                refresh = true;
                clear();
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(){
                vm.isSaving = false;
                layer.msg($translate.instant('global.messages.submittedfailed'));
            })
        }


    }
})();
