(function () {
    'use strict';
    angular
        .module('autopsApp')
        .factory('OpsNews', OpsNews);

    OpsNews.$inject = ['$resource', 'DateUtils'];

    function OpsNews($resource, DateUtils) {
        var resourceUrl = 'api/ops-news/:id';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                url: '/api/opsNews/getOneNews'
            },
            'save': {
                method: 'POST',
                url: '/api/ops-news/saveUpdateNews'
            },
            'getUpdateNews': {
                method: 'GET',
                url: '/api/ops-news/getUpdateNews',
                isArray: true
            },
            'search': {
                method: 'GET',
                url: '/api/ops-news/search',
                isArray: true
            },
            'delete': {
                method: 'GET',
                url: '/api/deleteNews'
            }
        });
    }
})();
