(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ClerkManagementDialogController', ClerkManagementDialogController);

    ClerkManagementDialogController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$translate', '$location', '$state',
    'entity', 'User', 'JhiLanguageService', 'Principal', 'OpsCompany'];

    function ClerkManagementDialogController($scope, $rootScope, $uibModalInstance, $translate, $location, $state,
        entity, User, JhiLanguageService, Principal, OpsCompany) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                OpsCompany.get({id : $rootScope.account.companyId}, function(result){
                    $rootScope.account.loginPrefix = result.loginPrefix;
                });
            });
        }else {
            OpsCompany.get({id : $rootScope.account.companyId}, function(result){
                $rootScope.account.loginPrefix = result.loginPrefix;
            });
        }

        $scope.user = entity;

        JhiLanguageService.getAll().then(function(languages) {
            $scope.languages = languages;
        });

        var onSaveSuccess = function(result) {
            $scope.isSaving = false;
            $uibModalInstance.close(result);
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
            $rootScope.ALERT_ERROR(result);
        };

        setTimeout(function() {
            var path = $location.path();
            if (!$rootScope.num && path.indexOf("new") <= 0) {
                $uibModalInstance.dismiss('cancel');
            }
        }, 500);

        $scope.save = function() {
            $scope.isSaving = true;
            if ($scope.user.id != null) {
                User.update($scope.user, onSaveSuccess, onSaveError);
            } else {
                initAuthorities();
                User.save($scope.user, onSaveSuccess, onSaveError);
            }
        };
        var initAuthorities = function(){
            $scope.user.authorities = ["AU_CHARGE_DATA", "AU_VIEW_INNER_REMARKS", "AU_VOID_ORDER", "AU_VIEW_ASSIST_CHARGE", "CREATE_STMT_OBJ", "SYN_EDI_DATA", "AU_AMEND_CHARGES", "AU_AMEND_OWN_BILL", "AU_BILL_CLOSE", "AU_RATE_LOCK", "AU_AMEND_SHIPMENT", "AU_VIEW_SUBTOTAL", "AU_EDIT_PAYAMT", "FUN_VIEW_PAYEE", 'AU_VIEW_BL', 'AU_CONSOLIDATE_UNLOCK', "AU_VIEW_REV",  "AU_VIEW_EXP", "FUN_VIEW_SHIPPER", "AU_VIEW_PROFIT", "AU_CHANGE_SALES", "FUN_ADDRESS_BOOK", "AU_VOID_DN", "AU_VOID_CN", "ROLE_USER", "ROLE_CLERK", "FUN_VIEWSELLING", "FUN_VIEW_CLIENT", "FUN_VIEW_COLOADER", "FUN_VIEW_AGENT", "FUN_VIEW_PAYER", "AU_VIEW_CHARGES", "AU_AMEND_EXCHANGE"];
            if ($scope.user.auth == 1) {
                $scope.user.authorities.push("ROLE_BOSS");
                $scope.user.authorities.push("ROLE_BRANCH");
                $scope.user.authorities.push("ROLE_DEPARTMENT");
                $scope.user.authorities.push("FUN_COLOADER");
                $scope.user.authorities.push("FUN_AGENT");
                $scope.user.authorities.push("FUN_VENDER");
                $scope.user.authorities.push("FUN_CLIENT");
                $scope.user.authorities.push("FUN_VIEWCOST");
                $scope.user.authorities.push("FUN_VIEWSELLING");
                $scope.user.authorities.push("REPORT_PROFIT_STATS");
                $scope.user.authorities.push("REPORT_BALANCE_STATEMENT");
                $scope.user.authorities.push("REPORT_PROFIT_STATEMENT");
                $scope.user.authorities.push("REPORT_CASHFLOW_STATEMENT");
                $scope.user.authorities.push("REPORT_BIZ_ANALYSIS");
                $scope.user.authorities.push("REPORT_PERFORMANCE");
                $scope.user.authorities.push("REPORT_AGING");
                $scope.user.authorities.push("REPORT_CHARGES_STATS");
                $scope.user.authorities.push("REPORT_CHARGES_COMPARE");
                $scope.user.authorities.push("REPORT_RECEIPT_PAYMENT");
                $scope.user.authorities.push("REPORT_INCOME_LIST");
                $scope.user.authorities.push("FUN_VIEW_TEAM_BIZ");
                $scope.user.authorities.push("FUN_VIEW_BRANCH_BIZ");
                $scope.user.authorities.push("AU_STAT_BRANCH");
                $scope.user.authorities.push("AU_STAT_TEAM");
                $scope.user.authorities.push("AU_STAT_ALL"); 
                $scope.user.authorities.push("AU_DEL_FILE"); 
                $scope.user.authorities.push("AU_ALLOT_SPACE");
                $scope.user.authorities.push("AU_ALL_APPLICATOIN");                                
            } else if ($scope.user.auth == 2) {
                $scope.user.authorities.push("ROLE_BRANCH");
                $scope.user.authorities.push("ROLE_DEPARTMENT");
                $scope.user.authorities.push("FUN_COLOADER");
                $scope.user.authorities.push("FUN_AGENT");
                $scope.user.authorities.push("FUN_VENDER");
                $scope.user.authorities.push("FUN_CLIENT");
                $scope.user.authorities.push("FUN_VIEW_BRANCH_BIZ");
                $scope.user.authorities.push("FUN_VIEW_TEAM_BIZ");
                $scope.user.authorities.push("AU_STAT_BRANCH");
                $scope.user.authorities.push("AU_STAT_TEAM");
                $scope.user.authorities.push("AU_DEL_FILE"); 
            } else if ($scope.user.auth == 3) {
                $scope.user.authorities.push("ROLE_DEPARTMENT");
                $scope.user.authorities.push("FUN_VIEW_TEAM_BIZ");
                $scope.user.authorities.push("AU_STAT_TEAM");
                $scope.user.authorities.push("AU_DEL_FILE"); 
            }
            
            if ($scope.user.role == "account") {
                $scope.user.authorities.push("ROLE_ACCOUNT");
                $scope.user.authorities.push("AU_STAT_FIN");
                $scope.user.authorities.push("REPORT_PROFIT_STATS");
                $scope.user.authorities.remove("ROLE_CLERK");
                $scope.user.authorities.remove("AU_AMEND_SHIPMENT");
            } else if ($scope.user.role == "cashier") {
                $scope.user.authorities.push("ROLE_CASHIER");
                $scope.user.authorities.push("REPORT_PROFIT_STATS");
                $scope.user.authorities.remove("ROLE_CLERK");
            } else if ($scope.user.role == "operator") {
                $scope.user.authorities.push("ROLE_OPERATOR");
                $scope.user.authorities.push("AU_STAT_OP");
            } else if ($scope.user.role == "document") {
                $scope.user.authorities.push("ROLE_DOCUMENT");
                $scope.user.authorities.push("AU_STAT_DOC");
            } else if ($scope.user.role == "market") {
                $scope.user.authorities.push("ROLE_MARKET");
                $scope.user.authorities.push("AU_STAT_MARKET");
            } else if ($scope.user.role == "sales") {
                $scope.user.authorities.push("ROLE_SALES");
                $scope.user.authorities.push("REPORT_PROFIT_STATS");
                $scope.user.authorities.push("FUN_CLIENT");
                $scope.user.authorities.push("REPORT_AGING");
                $scope.user.authorities.push("REPORT_PERFORMANCE");
                $scope.user.authorities.push("AU_STAT_SALES");
            } else if ($scope.user.role == "externalSales") {
                $scope.user.authorities = ["ROLE_USER", "ROLE_CLERK"];
                $scope.user.authorities.push("ROLE_SALES");
            } else if ($scope.user.role == "cs") {
                $scope.user.authorities.push("ROLE_CS");
                $scope.user.authorities.push("REPORT_PROFIT_STATS");
                $scope.user.authorities.push("FUN_CLIENT");
                $scope.user.authorities.push("AU_STAT_CS");
            } else if ($scope.user.role == "wms") {
                $scope.user.authorities.remove("ROLE_CLERK");
                $scope.user.authorities.push("ROLE_WAREHOUSE");
            } else if ($scope.user.role == "broker") {
                $scope.user.authorities.remove("ROLE_CLERK");
                $scope.user.authorities.push("ROLE_BROKER");
            } else if ($scope.user.role == "boss") {
                $scope.user.auth = 1;
                $scope.user.authorities.push("ROLE_BOSS");
                $scope.user.authorities.push("ROLE_BRANCH");
                $scope.user.authorities.push("ROLE_DEPARTMENT");
            } else if ($scope.user.role == "courier") {
                $scope.user.auth = 4;
                $scope.user.authorities.remove("ROLE_CLERK");
                $scope.user.authorities.remove("ROLE_USER");
                $scope.user.authorities.remove("FUN_VIEWSELLING");
                $scope.user.authorities.push("ROLE_COURIER");
            }
        }

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.authorities = [{
            id: 1,
            name: $translate.instant('user-management.master')
        }, {
            id: 2,
            name: $translate.instant('user-management.branchManager')
        }, {
            id: 3,
            name: $translate.instant('user-management.depManager')
        }, {
            id: 4,
            name: $translate.instant('user-management.clerk')
        }];

        $scope.authoritiesPop = function () {
            $state.go('clerk-management.authorities');
        }

        $scope.roleChange = function (role) {
            if(role == "externalSales"){
                $rootScope.OPS_ALERT($translate.instant('user-management.externalSalesAlert'));
            }
        }

    }
})();