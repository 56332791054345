(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsPreInController', WmsPreInController);

    WmsPreInController.$inject = ['$scope', '$rootScope', '$timeout', '$uibModal', '$translate', '$uibModalInstance', 'entity',
        'ManageEnterprise', 'WmsIn', 'DataUtils', 'OpsVender', 'OpsCarrier', 'WmsWarehouse', 'DateUtils', 'ExpressParcel'
    ];

    function WmsPreInController($scope, $rootScope, $timeout, $uibModal, $translate, $uibModalInstance, entity,
        ManageEnterprise, WmsIn, DataUtils, OpsVender, OpsCarrier, WmsWarehouse, DateUtils, ExpressParcel) {
        
        var vm = this;
        vm.isWmsIn = entity;
        vm.getCargo = getCargo;
        vm.addCargo = addCargo;
        vm.delCargo = delCargo;
        vm.submitIn = submitIn;
        vm.updateTtl = updateTtl;
        vm.updateVol = updateVol;
        vm.focusCargo = focusCargo;
        vm._onGoodsSelected = _onGoodsSelected;
        vm.initRapidIn = initRapidIn;
        vm.getAllGoods = getAllGoods;
        vm.getWarehouses = getWarehouses;
        vm.warehouseSelected = warehouseSelected;
        vm._onLocationSelected = _onLocationSelected;
        vm.getLastLocation = getLastLocation;
        vm.getLocations = getLocations;
        vm.getBarcode = getBarcode;
        vm.rmaLabels = rmaLabels;
        vm.getShipperByTrackingNum = getShipperByTrackingNum;
        vm.getShipment = getShipment;
        vm.save = save;

        vm.clear = clear;

        vm.locations = [];
        vm.files = [];   
        vm.wmsIn = {};
        vm.wmsIn.cargoList = [];
        vm.thisCargo = {};
        vm.conveyorMode = false;
        vm.isSaving = false;

        function getShipment() {
            if(!vm.wmsIn.warehousingNumber){
                return;
            }
            vm.isSaving = true;
            WmsIn.getRapidInBywarehousingNumber({
                warehousingNumber: vm.wmsIn.warehousingNumber,
                companyId: $rootScope.account.companyId
            }, function (result) {
                vm.isSaving = false;
                vm.wmsIn = result;          
                updateTtl();      
                layer.msg($translate.instant('entity.action.loadSuccess'));
                fucusInput();
            }, function(result){
                vm.isSaving = false;
                if(result.status == 404){
                    layer.msg($translate.instant('global.noRecord'));
                }
                else {
                    layer.msg($translate.instant('global.loadFailed'));
                }
            });
        }
        function fucusInput () {
            $timeout(function() {
                if(vm.isWmsIn){
                    $("#gw_field").focus();                    
                }
                else{
                    $("#track_number_field").focus();
                }
            });
        }
        function clear () {
            $uibModalInstance.close();                     
        }

        function getShipperByTrackingNum(mblNum) {
            if (!mblNum) {
                return;
            }
            ExpressParcel.getShipperByTrackingNum({
                companyId: $rootScope.account.companyId,
                mblNum: mblNum
            }, function(result) {
                vm.wmsIn.venderId = result.id;
                vm.wmsIn.clientName = result.companyName;
                vm.wmsIn.salesId = result.createdBy;
                vm.wmsIn.salesName = result.creater;
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        function rmaLabels () {
            WmsIn.rmaLabels({
                timeStr: DateUtils.convertDateTimeToString(new Date()),
                fileType: 1
            }, vm.wmsIn, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        function getBarcode (obj, fd) {
            if(obj.id && obj.allOut){
                return;
            }
            getLocations();
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-dialog.html',
                controller: 'WmsInDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md'
            }).result.then(function(code) {
                if (fd == "code"){ 
                    angular.forEach(vm.locations, function(data){
                        if(data.code == code){
                            _onLocationSelected(data, obj);
                        }
                    });
                }
                else {
                    obj[fd] = code;
                    if(fd == "warehousingNumber"){
                        getShipment();
                    }
                }
                if(fd == "returnTracking"){
                    $("#gw_field").focus();
                }
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }
        var locationCopy = null;
        function _onLocationSelected (item, cargo) {
            cargo.location = item.code;
            cargo.locationId = item.locationId;
            cargo.locationType = item.locationType;
            cargo.areaId = item.areaId;
            cargo.warehouseId = item.warehouseId;
            cargo.keeper = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            cargo.keeperId = $rootScope.account.id;

            locationCopy = angular.copy(item);
        }
        function getLastLocation (cargo) {
            if (locationCopy && !cargo.location){
                cargo.location = locationCopy.code;
                cargo.locationId = locationCopy.locationId;
                cargo.locationType = locationCopy.locationType;
                cargo.areaId = locationCopy.areaId;
                cargo.warehouseId = locationCopy.warehouseId;
            }
        }
        function getLocations () {
            if(!vm.wmsIn.warehouseId){
                layer.msg($translate.instant('autopsApp.wmsIn.chooseWarehouseAlert'));
                return;
            }
            if(vm.isSaving){
                return;
            }
            if (!vm.locations || vm.locations.length == 0){
                vm.isSaving = true;
                WmsWarehouse.getLocations({warehouseId: vm.wmsIn.warehouseId}, function (data) {
                    vm.isSaving = false;
                    vm.locations = data;
                    chooseLocation();
                });
            }
        }

        var chooseLocation = function(){
            if(vm.locations 
                && vm.locations.length > 0 
                && vm.wmsIn.cargoList
                && vm.wmsIn.cargoList.length > 0){                        
                angular.forEach(vm.wmsIn.cargoList, function(cargo){
                    _onLocationSelected (vm.locations[0], cargo);
                })
            }    
        }
        function getNewCargo() {
            return {
                packageType:"CTNS", 
                pkgs:1, 
                allOut:false, 
                status: vm.isWmsIn? "OK" : "NO",
                action:"preIn", 
                inTime: new Date()
            }
        }
        function initRapidIn() {
            vm.wmsIn = {
                registerId: $rootScope.account.id,
                registerBy: $rootScope.account.firstName + " " + $rootScope.account.lastName,
                companyId: $rootScope.account.companyId,
                isOut: vm.isWmsIn?false: null,
                applicationNum: 1,
                type: "WMS",
                status: "preIn",
                deliveryBySelf: true,
                handleType: vm.isWmsIn?"transportService":null,
                updateTime: new Date(),
                inTime: new Date(),
                expectedWarehousingTime: new Date(),
                allOut: false
            }
            vm.wmsIn.cargoList = [];
            vm.thisCargo= getNewCargo();
            if(locationCopy){
                getLastLocation(vm.thisCargo);
            }
            else if(vm.locations 
                && vm.locations.length > 0){                        
                _onLocationSelected (vm.locations[0], vm.thisCargo);
            }   
            vm.wmsIn.cargoList.push(vm.thisCargo);

        }
        initRapidIn();


        function addCargo() {
            if (!vm.wmsIn.cargoList) {
                vm.wmsIn.cargoList = [];
            }
            vm.thisCargo= getNewCargo();
            vm.wmsIn.cargoList.push(vm.thisCargo);
            _onLocationSelected (vm.locations[0], vm.thisCargo);
            fucusInput();
        }

        function focusCargo(cargo) {
            vm.thisCargo = cargo;
        }

        function delCargo(cargo) {
            if(cargo.id){
                return;
            }
            var index = vm.wmsIn.cargoList.indexOf(cargo);
            if (index > -1) {
                vm.wmsIn.cargoList.splice(index, 1);
            };
        }

        function getCargo(code) {
            if (!code) {
                return;
            }
            ManageEnterprise.getGoodBySku({
                companyId: $rootScope.account.companyId,
                sku: code
            }, function(result) {
                vm.goodsList = result;
                angular.forEach(result, function(data){
                    if(data.venderId == vm.wmsIn.venderId){
                        vm.thisCargo = {};
                        _onGoodsSelected(data, vm.thisCargo);
                        vm.wmsIn.cargoList.push(vm.thisCargo);
                    }
                })
            }, function(result) {
                if (result.status == 404) {
                    layer.msg($translate.instant('global.messages.resultNull'));
                } else {
                    layer.msg($translate.instant('global.requestFail'));
                }
            });
        }

        function getWarehouses () {
            if (vm.warehouses && vm.warehouses.length > 0){
                if(!vm.wmsIn.warehouseId){
                    warehouseSelected(vm.warehouses[0]);
                }    
                return;
            }
            WmsWarehouse.getAll({
                includeOwn: true,
                includeVirtual: false,
                includeGroup: false
            }, function (data) {
                vm.warehouses = data;
                if(vm.warehouses.length > 0 && !vm.wmsIn.warehouseId){
                    warehouseSelected(vm.warehouses[0]);                    
                }                
            });
        }
        getWarehouses();

        function warehouseSelected (warehouse) {
            vm.wmsIn.warehouseName = warehouse.name;
            vm.wmsIn.warehouseId = warehouse.id;
            vm.wmsIn.companyId = warehouse.companyId;
            vm.locations = [];
            getLocations();
        }

        function submitIn() {
            if (vm.wmsIn.cargoList.length == 0) {
                layer.msg($translate.instant('autopsApp.wmsIn.rapidSubmitAlert'));
                return;
            }
            for(var i = 0; i < vm.wmsIn.cargoList.length; i++){
                if (!vm.wmsIn.cargoList[i].location && !vm.wmsIn.cargoList[i].id){
                    var msg = vm.wmsIn.cargoList[i].cargoName + ": " + $translate.instant('global.choose_location_alert');
                    $rootScope.OPS_ALERT(msg);
                    return;
                }     
            }
            if(!vm.wmsIn.clientName){
                if(vm.isWmsIn){
                    layer.msg($translate.instant('autopsApp.wmsIn.clientNullAlert'));                    
                    return;
                }
                vm.wmsIn.venderId = null;
                vm.wmsIn.salesId = null;
                vm.wmsIn.salesName = null;
            }
            vm.isSaving = true;
            WmsIn.rapidIn(vm.wmsIn, function(result) {
                $scope.$emit('autopsApp:wmsInUpdate', result);
                initRapidIn();
                vm.wmsIn.warehouseName = result.warehouseName;
                vm.wmsIn.warehouseId = result.warehouseId;
                vm.wmsIn.companyId = result.companyId;
                vm.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
            }, onSaveError);
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            result.deliveryArrange = result.deliveryBySelf? "y" : "n";
            vm.wmsIn = result;            
            $scope.$emit('autopsApp:wmsInUpdate', result);
            layer.msg($translate.instant('global.messages.submitted'));
        }

        function onSaveError (result) {
            vm.isSaving = false;
            $rootScope.ALERT_ERROR(result);
        }

        function save () {
            if (vm.wmsIn.id) {
                $rootScope.LOADFILES(vm.files, '/wmsIn/' + vm.wmsIn.id + '/', vm.wmsIn.companyId);
                return;
            }

            layer.confirm($translate.instant('autopsApp.wmsIn.savePreAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                vm.isSaving = true;                   
                WmsIn.save(vm.wmsIn, onSaveSuccess, onSaveError);
            }, function() {
                return;
            }); 
        }

        $timeout(function() {
            if (!vm.wmsIn.cargoList || vm.wmsIn.cargoList.length == 0) {
                vm.wmsIn.cargoList = [];
                vm.thisCargo = getNewCargo();
                vm.wmsIn.cargoList.push(vm.thisCargo);
            }
            fucusInput();
        });

        function updateTtl () {
            vm.wmsIn.gw = 0;
            vm.wmsIn.vol = 0;
            vm.wmsIn.applicationNum = 0;

            angular.forEach(vm.wmsIn.cargoList, function(data){
                if(!data.pkgs){
                    data.pkgs = 0;
                }
                vm.wmsIn.applicationNum += data.pkgs;
                if(data.gw){
                    vm.wmsIn.gw += data.gw * data.pkgs;
                    data.gwTtl = DataUtils.round(data.gw * data.pkgs, 2);
                }
                if(data.vol){
                    vm.wmsIn.vol += data.vol * data.pkgs;
                    data.volTtl = DataUtils.round(data.vol * data.pkgs, 3);
                }
            })
            vm.wmsIn.gw = DataUtils.round(vm.wmsIn.gw, 2);
            vm.wmsIn.vol = DataUtils.round(vm.wmsIn.vol, 4);
        }
        
        function updateVol (cargo) {
            if (cargo.length && cargo.width && cargo.height){
                cargo.vol = cargo.length * cargo.width * cargo.height/1000000;
                cargo.vol = DataUtils.round(cargo.vol, 4);
                updateTtl();
            }
        }

        $scope._onClientSelected = function(data) {
            vm.wmsIn.venderId = data.id;
            vm.wmsIn.salesId = data.createdBy;
            vm.wmsIn.salesName = data.creater;
            $rootScope.WMSGOODS = [];
        }

        $scope.onCarrierSelected = function(carrier) {
            vm.wmsIn.returnChannel = carrier.code;
        }

        function getAllGoods () {
            if(!vm.wmsIn.venderId ){
                return;
            }
            if (!$rootScope.WMSGOODS || $rootScope.WMSGOODS.length == 0){
                ManageEnterprise.getAllGoods({venderId:vm.wmsIn.venderId }, function(result) {
                    $rootScope.WMSGOODS = result;
                })
            }
        }

        function _onGoodsSelected (data, cargo) {
            cargo.code = data.code;
            cargo.sku = data.sku;
            cargo.wmsGoodsId = data.wmsGoodsId;//batchid
            cargo.cargoName = data.cargoName;
            cargo.packageType = data.packageType;
            cargo.gw = data.gw;
            cargo.pkgs = null;
            cargo.palletAmt = null;
            cargo.vol = data.vol;
            cargo.length = data.length;
            cargo.width = data.width;
            cargo.height = data.height;
            cargo.isPallet = data.isPallet;
            cargo.palletCapacity = data.palletCapacity;
            cargo.chargeType = data.chargeType;
            cargo.chargePrice = data.chargePrice;
            cargo.piece = data.piece;
            cargo.pieceUnit = data.pieceUnit;
            cargo.nw = data.nw;
            cargo.capacity = data.capacity;
            cargo.capacityUnit = data.capacityUnit;
            updateVol(cargo);
        }
        $(document).keydown(function(event) {            
            if (event.keyCode == 13) {
                if ("track_number_field" == document.activeElement.id) {
                    $("#gw_field").focus();
                }
                else if ("gw_field" == document.activeElement.id) {
                    $("#field_length").focus();
                }
                else if ("field_length" == document.activeElement.id) {
                    $("#field_width").focus();
                }
                else if ("field_width" == document.activeElement.id) {
                    $("#field_height").focus();
                }
                else if ("field_height" == document.activeElement.id) {
                    $timeout(function (){
                        $("#add_cargo_btn").focus();
                    });    
                }
            } 
            if (event.keyCode == 83 && event.ctrlKey) {
                event.preventDefault();
                submitIn();              
            } 
            else if (event.ctrlKey && (event.keyCode == 13 || event.keyCode == 107)) {
                event.preventDefault();
                addCargo();  
            }  
        });

        $scope.wmsLabels = function(reportType) {
            var wmsInCopy = angular.copy(vm.wmsIn);
            WmsIn.wmsLabels({
                reportType: reportType,
                fileType: 1
            }, wmsInCopy, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        $scope.gwTtlChange = function(cargo) {
            if(cargo.pkgs && cargo.gwTtl){
                cargo.gw = DataUtils.round(cargo.gwTtl/cargo.pkgs, 2);
                vm.wmsIn.gw = 0;
                angular.forEach(vm.wmsIn.cargoList, function(data){
                    if(data.gwTtl){
                        vm.wmsIn.gw += data.gwTtl;
                    }
                })
                vm.wmsIn.gw = DataUtils.round(vm.wmsIn.gw, 2);
            }
        };

        $scope.volTtlChange = function(cargo) {
            if(cargo.pkgs && cargo.volTtl){
                cargo.vol = DataUtils.round(cargo.volTtl/cargo.pkgs, 3);
                vm.wmsIn.vol = 0;
                angular.forEach(vm.wmsIn.cargoList, function(data){
                    if(data.volTtl){
                        vm.wmsIn.vol += data.volTtl;
                    }
                })
                vm.wmsIn.vol = DataUtils.round(vm.wmsIn.vol, 3);
            }
        };
    }
})();