(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('purchase-order', {
            parent: 'wms',
            url: '/purchase-order',
            data: {
                authorities: ['ROLE_USER', 'ROLE_CLIENT'],
                pageTitle: 'autopsApp.wmsIn.poMgmt'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/po/pos.html',
                    controller: 'PoController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })      
        .state('purchase-order.edit', {
            parent: 'purchase-order',
            url: '/edit/{id}',
            data: {
                authorities: ['ROLE_USER', 'ROLE_CLIENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/po/po-dialog.html',
                    controller: 'PoDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['WmsIn', 'WinAES', function(WmsIn, WinAES) {
                            return WmsIn.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('wmsIn');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('purchase-order.copy', {
            parent: 'purchase-order',
            url: '/copy/{id}',
            data: {
                authorities: ['ROLE_USER', 'ROLE_CLIENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/po/po-dialog.html',
                    controller: 'PoDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['WmsIn', 'WinAES', function(WmsIn, WinAES) {
                            return WmsIn.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('wmsIn');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('purchase-order.new', {
            parent: 'purchase-order',
            url: '/new',
            data: {
                authorities: ['ROLE_USER', 'ROLE_CLIENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/po/po-dialog.html',
                    controller: 'PoDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: [function() {
                            return {};
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('wmsIn');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
