(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CrmTaskDialogController', CrmTaskDialogController);

    CrmTaskDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$translate', '$stateParams', '$uibModalInstance', 'entity', 'CrmTask', 'Principal', 'User', 'CrmReport'];

    function CrmTaskDialogController ($timeout, $scope, $rootScope, $translate, $stateParams, $uibModalInstance, entity, CrmTask, Principal, User, CrmReport) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})){
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                angular.forEach(vm.subordinates, function(data){
                    if ($rootScope.account.id == data.createrId){
                        alert('s');

                        var index = vm.subordinates.indexOf(data);
                        if (index > -1) {
                            vm.subordinates.splice(index, 1);
                        };
                        $translate.refresh();
                    }
                });
            });
        };

        var vm = this;

        vm.crmTask = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.subordinates = [];
        vm.receivers = [];
        vm.addToList = addToList;
        vm.delFromList = delFromList;
        vm.addAll = addAll;
        vm.removeAll = removeAll;
        vm.files = [];

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.crmTask.crmReports){
                vm.crmTask.receiverNum = vm.crmTask.crmReports.length;
            }        
            else {
                vm.crmTask.receiverNum = 0;
            }    
            if (vm.crmTask.id !== null) {
                CrmTask.update(vm.crmTask, onSaveSuccess, onSaveError);
            } else {
                vm.crmTask.completedNum = 0;
                CrmTask.save(vm.crmTask, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('autopsApp:crmTaskUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.deadline = false;
        vm.datePickerOpenStatus.createdTime = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        User.getSubordinates(function(result){
            var isRepeat = false;
            if (result && result.length >0){
                angular.forEach(result, function(data){
                    isRepeat = false;
                    if ($rootScope.account.id == data.id){
                        isRepeat = true;
                    }
                    if (vm.crmTask.crmReports && vm.crmTask.crmReports.length > 0){                        
                        angular.forEach(vm.crmTask.crmReports, function(data1){
                            if (data.id == data1.reporterId){                                
                                isRepeat = true;
                            }
                        });
                    }
                    if (!isRepeat){
                        var subordinate = {};
                        subordinate.reporter = data.firstName + " " +data.lastName;
                        subordinate.reporterId = data.id;
                        vm.subordinates.push(subordinate);
                    }
                    $translate.refresh();
                });
            }
        });

        function addToList (reporter) {
            if (!vm.crmTask.crmReports){
                vm.crmTask.crmReports = [];
            }
            vm.crmTask.crmReports.push(reporter);
            var index = vm.subordinates.indexOf(reporter);
            if (index > -1) {
                vm.subordinates.splice(index, 1);
            };            
        }
        function delFromList (reporter) {
            vm.subordinates.push(reporter);
            if (reporter.id != null) {
                CrmReport.delete({
                    id: reporter.id
                });
            };
            var index = vm.crmTask.crmReports.indexOf(reporter);
            if (index > -1) {
                vm.crmTask.crmReports.splice(index, 1);
            };
        }

        function addAll () {
            if (!vm.crmTask.crmReports){
                vm.crmTask.crmReports = [];
            }
            angular.forEach(vm.subordinates, function(data){
                 vm.crmTask.crmReports.push(data);
            });
            vm.subordinates = [];
        }
        function removeAll () {
            angular.forEach(vm.crmTask.crmReports, function(data){
                vm.subordinates.push(data);
                if (data.id != null) {
                    CrmReport.delete({
                        id: data.id
                    });
                };
            });
            vm.crmTask.crmReports = [];
        }
    }
})();
