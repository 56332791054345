(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsMapDialogController', WmsMapDialogController);

    WmsMapDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', '$translate', '$uibModal', 
    'entity', 'WmsMap', 'WmsWarehouse', 'AddressBook', 'OpsCarrier', 'Dictionary'];

    function WmsMapDialogController ($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, $translate, $uibModal, 
        entity, WmsMap, WmsWarehouse, AddressBook, OpsCarrier, Dictionary) {
        var vm = this;

        vm.accountBind = entity;
        vm.wmsMaps = [];
        vm.clear = clear;
        vm.save = save;
        vm.getWarehouses = getWarehouses;
        vm.loadAddressBooks = loadAddressBooks;
        vm.onBindSelected = onBindSelected;

        $timeout(function (){
            loadAll();
            $rootScope.PARCEL_SERVICES  = null;
        });

        function loadAll () {
            WmsMap.getAllByAccountBindId({accountBindId: vm.accountBind.id}, function(results){
                vm.wmsMaps = results;
            })
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save (wmsMap) {
            if(null == wmsMap.bindName){
                wmsMap.bindName = "";
            }
            if(null == wmsMap.targetCode){
                wmsMap.targetCode = "";
            }
            vm.isSaving = true;
            WmsMap.update(wmsMap, function(result){
                loadAll();
                vm.isSaving = false;
                layer.msg($translate.instant('global.save_success'));
            }, onSaveError);
        }

        function onSaveError () {
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submittedfailed'));
        }

        $scope.addOne = function() {
            if(!vm.wmsMaps){
                vm.wmsMaps = [];
            }
            vm.wmsMaps.splice(0, 0, {accountBindId:vm.accountBind.id});
        }

        $scope.delOne = function (index) {
            if(!vm.wmsMaps[index].id){
                vm.wmsMaps.splice(index, 1);
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/wms-map/wms-map-delete-dialog.html',
                controller: 'WmsMapDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: ['WmsMap', function(WmsMap) {
                        return vm.wmsMaps[index];
                    }]
                }
            }).result.then(function() {
                loadAll();
            }, function() {
            });
        }

        vm.wmswarehouses = [];
        function getWarehouses () {
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false
                }, function (data) {
                vm.wmswarehouses = data;
            });
        }
        vm.addressBooks = [];
        function loadAddressBooks () {
            if($rootScope.account.companyId && $rootScope.HAVENOAUTH('AU_LABEL_ACCOUNT')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_LABEL_ACCOUNT');
                return;            
            }
            if (vm.addressBooks.length == 0 && !vm.isSaving) {
                vm.isSaving = true;
                AddressBook.getAllAccounts(function(result) {
                    vm.addressBooks = result;
                    vm.isSaving = false;
                });
            };
        };

        function onBindSelected (wmsMap, data) {
            wmsMap.bindId = data.id;
        }

        function onAddressSelected (wmsMap, data) {
            wmsMap.bindId = data.id;
        }

        $scope.bindTypeChange = function(wmsMap) {            
            wmsMap.zipCode = null;
            wmsMap.targetCode = null;
            wmsMap.bindId = null;
            wmsMap.bindName = null;
            wmsMap.carrierCode = null;
            wmsMap.priority = null;
            $translate.refresh();
        }

        $scope.bindTargetChange = function(wmsMap) {
            $rootScope.PARCEL_SERVICES  = null;
            var guide = {labelBindType: angular.uppercase(wmsMap.carrierCode)};
            $rootScope.GET_PARCEL_SERVICE(guide);
            wmsMap.bindName = "";
            wmsMap.zipCode = null;
            $translate.refresh();
        }

        $scope.onShipServiceSelected = function(data, wmsMap) {
            wmsMap.zipCode = data.type;
        }

        $scope.onCarrierSelected = function(wmsMap) {
            if(!wmsMap.zipCode){
                return;
            }
            var carrierName = angular.lowercase(wmsMap.zipCode);
            if(carrierName.indexOf("ups") != -1){
                wmsMap.bindName = "UPS";
            }
            else if(arrierName.indexOf("fedex") != -1){
                wmsMap.bindName = "FedEx";
            }
            else if(arrierName.indexOf("usps") != -1){
                wmsMap.bindName = "USPS";
            }
            else if(arrierName.indexOf("ups") != -1){
                wmsMap.bindName = "UPS";
            }
            else if(arrierName.indexOf("dhl") != -1){
                wmsMap.bindName = "DHL";
            }
            else if(arrierName.indexOf("dpd") != -1){
                wmsMap.bindName = "DPD";
            }
        }

    }
})();
