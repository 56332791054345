(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CrmReportDetailController', CrmReportDetailController);

    CrmReportDetailController.$inject = ['$scope', '$rootScope', '$stateParams', '$uibModal', 
        'previousState', 'entity', 'CrmReport'];

    function CrmReportDetailController($scope, $rootScope, $stateParams, $uibModal, 
        previousState, entity, CrmReport) {
        var vm = this;

        vm.crmReport = entity;
        vm.previousState = previousState.name;
        vm.edit = edit;
        vm.files = [];

        var unsubscribe = $rootScope.$on('autopsApp:crmReportUpdate', function(event, result) {
            vm.crmReport = result;
        });
        $scope.$on('$destroy', unsubscribe);

        function edit (reportId) {
            $uibModal.open({
                templateUrl: 'app/entities/crm-report/crm-report-dialog.html',
                controller: 'CrmReportDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: ['CrmReport', function(CrmReport) {
                        return CrmReport.get({id : reportId}).$promise;
                    }]
                }
            }).result.then(function(result) {
                vm.crmReport = result;
            }, function() {
            });
        }

    }
})();
