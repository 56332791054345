(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsTrackStatusDialogController', OpsTrackStatusDialogController);

    OpsTrackStatusDialogController.$inject = ['$rootScope', '$timeout', '$scope', '$stateParams', '$translate', '$uibModalInstance', 'entity', 'OpsTrackStatus', 'CustomNode'];

    function OpsTrackStatusDialogController($rootScope, $timeout, $scope, $stateParams, $translate, $uibModalInstance, entity, OpsTrackStatus, CustomNode) {
        var vm = this;

        vm.opsTrackStatus = entity;
        vm.clear = clear;
        vm.save = save;
        vm.insertAtCursor = insertAtCursor;
        vm.bindingChange = bindingChange;
        vm.bindNodes = [];
        vm.shipmentTypeChange = shipmentTypeChange;

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
            loadNodeTypesByShipmentType(vm.opsTrackStatus.shipmentType);
        });
        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.opsTrackStatus.id !== null) {
                OpsTrackStatus.update(vm.opsTrackStatus, onSaveSuccess, onSaveError);
            } else {
                OpsTrackStatus.save(vm.opsTrackStatus, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('autopsApp:opsTrackStatusUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
        function bindingChange(binding) {
            if(binding == "Deliveried"){
                vm.opsTrackStatus.delivered = true;
            }
            else {
                vm.opsTrackStatus.delivered = false;
            }
        }
        function insertAtCursor(myValue) {
            var myField = document.getElementById('field_status');
            //IE support 
            if (document.selection) {
                myField.focus();
                sel = document.selection.createRange();
                sel.text = myValue;
                sel.select();
            }
            //MOZILLA/NETSCAPE support 
            else if (myField.selectionStart || myField.selectionStart == '0') {
                var startPos = myField.selectionStart;
                var endPos = myField.selectionEnd;
                // save scrollTop before insert 
                var restoreTop = myField.scrollTop;
                myField.value = myField.value.substring(0, startPos) + myValue + myField.value.substring(endPos, myField.value.length);
                if (restoreTop > 0) {
                    // restore previous scrollTop 
                    myField.scrollTop = restoreTop;
                }
                myField.focus();
                myField.selectionStart = startPos + myValue.length;
                myField.selectionEnd = startPos + myValue.length;
            } else {
                myField.value += myValue;
                myField.focus();
            }
            vm.opsTrackStatus.status = document.getElementById("field_status").value;
            // vm.opsTrackStatus.status = vm.opsTrackStatus.status + "ss";
        }

        function shipmentTypeChange(shipmentType) {
            vm.opsTrackStatus.binding = null;
            loadNodeTypesByShipmentType(shipmentType);
        }
        function loadNodeTypesByShipmentType(shipmentType) {
            if(parcel_node_types.indexOf(shipmentType) !== -1){
                vm.bindNodes = angular.copy(parcelNodes);
            }
            else if(tms_node_types.indexOf(shipmentType) !== -1){
                vm.bindNodes = angular.copy(tmsNodes);
            }
            else {
                vm.bindNodes = angular.copy(standardNodes);
                CustomNode.getAfCustomNodes({
                    companyId: $rootScope.account.companyId,
                    shipmentType: shipmentType
                }, function(result){                    
                    angular.forEach(result, function(customNode){
                        vm.bindNodes.push({
                            key: customNode,
                            value: customNode
                        });                    
                    });
                    $translate.refresh();
                })
            }
            $translate.refresh();
        }

        var parcel_node_types = ["PARCELALL", "PARCEL", "EP", "LINE", "RETURN"];
        var tms_node_types = ["TMSALL", "TMS", "FTL", "LTL", "EXD", "CPU", "EPWMS"];

        var tmsNodes = [
            { value: 'confirm', key: $translate.instant('autopsApp.expressParcel.status.confirm')},
            { value: 'sendDn', key: $translate.instant('autopsApp.expressParcel.status.sendDn')},
            { value: 'pickup', key: $translate.instant('autopsApp.expressParcel.status.pickup')},
            { value: 'wmsIn', key: $translate.instant('autopsApp.expressParcel.status.wmsIn')},
            { value: 'delivery', key: $translate.instant('autopsApp.expressParcel.status.delivery')},
            { value: 'wmsOut', key: $translate.instant('autopsApp.expressParcel.status.wmsOut')},
            { value: 'costInput', key: $translate.instant('autopsApp.expressParcel.status.costInput')},
            { value: 'podConfirm', key: $translate.instant('autopsApp.expressParcel.status.podConfirm')}];

        var parcelNodes = [
            { value: 'checkIn', key: $translate.instant('autopsApp.expressParcel.status.checkIn')},
            { value: 'docment', key: $translate.instant('autopsApp.expressParcel.status.docment')},
            { value: 'checkOut', key: $translate.instant('autopsApp.expressParcel.status.checkOut')},
            { value: 'deliveried', key: $translate.instant('autopsApp.expressParcel.status.deliveried')},
            { value: 'dnDone', key: $translate.instant('autopsApp.expressParcel.status.dnDone')}];



        //delGuideTrackingByNode delGuideTrackingByNode guide.outNodeTime
        var standardNodes = [
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.deliveryGuide"), 'value': 'deliveryGuide'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.Booked"), 'value': 'Booked'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.SoReleased"), 'value': 'SoReleased'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.TruckArranged"), 'value': 'TruckArranged'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.wmsIn"), 'value': 'wmsIn'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.plConfirmed"), 'value': 'plConfirmed'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.wmsOut"), 'value': 'wmsOut'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.transferOut"), 'value': 'transferOut'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.CtnStuffed"), 'value': 'CtnStuffed'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.CustomArranged"), 'value': 'CustomArranged'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.customsInspectionExp"), 'value': 'customsInspectionExp'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.CustomReleased"), 'value': 'CustomReleased'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.vgmSent"), 'value': 'vgmSent'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.atd"), 'value': 'atd'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.arrivedPod"), 'value': 'arrivedPod'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.customsInspection"), 'value': 'customsInspection'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.customCleared"), 'value': 'customCleared'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.ctnAvailable"), 'value': 'ctnAvailable'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.deliveryAppointed"), 'value': 'deliveryAppointed'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.Delivering"), 'value': 'Delivering'},   
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.Deliveried"), 'value': 'Deliveried'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.ctnReturned"), 'value': 'ctnReturned'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.etd"), 'value': 'etd'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.eta"), 'value': 'eta'}, 
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.transferDate"), 'value': 'transferDate'},           
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.deliveryDate"), 'value': 'deliveryDate'},
            {'key': $translate.instant("autopsApp.opsTrackStatus.sts.Cancelled"), 'value': 'Cancelled'}    
        ]


    }
})();