(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('OpsShipper', OpsShipper);

    OpsShipper.$inject = ['$resource','DateUtils'];

    function OpsShipper($resource, DateUtils) {
        return $resource('api/opsShippers/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    return data;
                }
            },
            'getAll': {
                method:'GET',
                url:'api/opsShippersAll',
                isArray:true
            },
            'deleteContact': {
                method:'DELETE',
                url:'api/contactList/:id'
            },
            'getAllByClientOrAgentId': {
                method:'PUT',
                url:'api/opsShippersAllByClientOrAgentId',
                isArray:true
            },
            'update': { method:'PUT' }
        });
    }
})();
