(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CustomDeclarationDeleteController',CustomDeclarationDeleteController);

    CustomDeclarationDeleteController.$inject = ['$uibModalInstance', '$translate', 'entity', 'CustomDeclaration'];

    function CustomDeclarationDeleteController($uibModalInstance, $translate, entity, CustomDeclaration) {
        var vm = this;

        vm.customDeclaration = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CustomDeclaration.delete({id: id},function () {
                $uibModalInstance.close(true);
            }, function () {
                layer.msg($translate.instant('global.delete_forbidden'));
            });
        }
    }
})();
