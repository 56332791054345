(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('IncomeStatementController', IncomeStatementController);

    IncomeStatementController.$inject = ['$scope', '$rootScope', '$window', '$translate', 
    'Principal', 'OpsFiles', 'DateUtils', 'DataUtils', 'AccountGeneral'];

    function IncomeStatementController($scope, $rootScope, $window, $translate, 
        Principal, OpsFiles, DateUtils, DataUtils, AccountGeneral) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            })
        }


        $scope.isSaving = false;
        $scope.profitSheet = {};
        $scope.profitSheet.incomeStatementsList = [];
        $scope.filterDTO = {};


        var getDates = function() {
            var year = $scope.filterDTO.month.getFullYear(); //取当前的年份          
            var month = $scope.filterDTO.month.getMonth();
            if (month > 11) {
                month -= 12;
                year++;
            }
            $scope.filterDTO.dateFrom = new Date(year, month, 1);
            $scope.filterDTO.dateTo = new Date(year, month + 1, 0);            
        }

        $scope.getContetns = function() {
            $scope.isSaving = true;
            getDates();
            OpsFiles.getIncomeStatements($scope.filterDTO, function(result) {
                $scope.isSaving = false;
                $scope.profitSheet = result;
                if (!$scope.profitSheet || $scope.profitSheet.incomeStatementsList.length == 0) {
                    layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                    return;
                }
                for (var i = 0; i < $scope.profitSheet.incomeStatementsList.length; i++) {
                    $scope.profitSheet.incomeStatementsList[i].currentBalance = DataUtils.formatTwoDigits($scope.profitSheet.incomeStatementsList[i].currentBalance);
                    $scope.profitSheet.incomeStatementsList[i].previousBalance = DataUtils.formatTwoDigits($scope.profitSheet.incomeStatementsList[i].previousBalance);
                }
                $scope.filterDTO.makeDate = new Date();
            }, function(error) {
                $scope.isSaving = false;
                layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
            });
        }

        var initContent = function() {
            for (var j = 0; j <= 20; j++) {
                $scope.profitSheet.incomeStatementsList.push({});
            }
        }

        initContent();
        $scope.exportReport = function(format) {
            OpsFiles.generateReportIncomeStatements({
                cnName: $scope.profitSheet.companyName,
                fileType: format,
                makeDate: $scope.profitSheet.makeDate,
                dateFrom: DateUtils.convertLocalDateToServer($scope.filterDTO.dateFrom),
                dateTo: DateUtils.convertLocalDateToServer($scope.filterDTO.dateTo)
            }, $scope.profitSheet.incomeStatementsList, function(result) {
                if (format == 2) {
                    $rootScope.EXPORTDOWNLOAD(result, 2, "Profit Statement");
                } else {
                    $rootScope.PRINTBYTES(result.data);
                }
            }, function(error) {
                layer.msg($translate.instant('autopsApp.accountTitle.viewAccountTitleFail'));
            });
        }

        $scope.exportYearReport = function(format, isEn) {
            getDates();
            AccountGeneral.incomeStatement({
                companyId: $rootScope.account.companyId,
                reportCode: 990,
                isEn: isEn,
                localDate: DateUtils.convertLocalDateToServer($scope.filterDTO.dateFrom)
            }, function(result) {
                if (format == 'excel') {
                    $rootScope.EXPORTDOWNLOAD(result, 2, "Profit Statement");
                } else {
                    $rootScope.PRINTBYTES(result.data);
                }
            }, function(error) {
                layer.msg($translate.instant('autopsApp.accountTitle.viewAccountTitleFail'));
            });
        }
        $scope.monthFormat = "yyyy-MM";
        $scope.datepickerOptions = {
           minMode:'month'
        }
        $scope.datePickerForEtd = {};
        $scope.datePickerForEtd.status = {
            opened: false
        };

        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };
    }
})();