(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountPaymentDeleteController', AccountPaymentDeleteController);

    AccountPaymentDeleteController.$inject = ['$rootScope', '$scope', '$uibModal', '$translate', '$timeout', '$uibModalInstance', 
    'Principal', 'OpsFiles', 'entity', 'AccountPayment', 'GuideRates', 'OpsReport', 'AccountInvoice', 'ApiErp', 'OpsVender', 'DateUtils'];

    function AccountPaymentDeleteController($rootScope, $scope, $uibModal, $translate, $timeout, $uibModalInstance, 
        Principal, OpsFiles, entity, AccountPayment, GuideRates, OpsReport, AccountInvoice, ApiErp, OpsVender, DateUtils) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        }
        $scope.paymentRequest = entity;
        $scope.presentId = $scope.paymentRequest.id;
        $scope.files = [];
        $timeout(function() {
            if($rootScope.HAVENOAUTH("ROLE_ACCOUNT") && $rootScope.HAVENOAUTH("ROLE_CASHIER") && $rootScope.HAVENOAUTH("AU_VOID_CN")
                 && $rootScope.HAVENOAUTH("FUN_ACCOUNT_PAYMENT") && $rootScope.HAVENOAUTH("ROLE_BOSS")
                 && $scope.paymentRequest.createdBy != $rootScope.account.id){
                $rootScope.FORBIDDEN_BY_ALERT("FUN_ACCOUNT_PAYMENT");
                $scope.clear();
                return;
            }
            initRequest();
            OpsVender.getDTO({
                id: $scope.paymentRequest.venderId
            }, function(result) {
                $scope.paymentRequest.invoiceTitle = $scope.paymentRequest.invoiceTitle + "(" + result.code + ")";
            });
        });
        function initRequest (){
            if($scope.paymentRequest.auditId){
                $scope.paymentRequest.isAudited = true;
            }
            else {
                $scope.paymentRequest.isAudited = false;
            }
            if($scope.paymentRequest.remarks && $scope.paymentRequest.remarks.length > 155){
                $scope.paymentRequest.remarksShow = $scope.paymentRequest.remarks.substring(0, 155) + "...";
            }
            else{
                $scope.paymentRequest.remarksShow = $scope.paymentRequest.remarks;
            }

            if($scope.paymentRequest.jobNums && $scope.paymentRequest.jobNums.length > 55){
                $scope.paymentRequest.jobNumsShow = $scope.paymentRequest.jobNums.substring(0, 55) + "...";
            }
            else{
                $scope.paymentRequest.jobNumsShow = $scope.paymentRequest.jobNums;
            }

            if($scope.paymentRequest.blNums && $scope.paymentRequest.blNums.length > 55){
                $scope.paymentRequest.blNumsShow = $scope.paymentRequest.blNums.substring(0, 55) + "...";
            }
            else{
                $scope.paymentRequest.blNumsShow = $scope.paymentRequest.blNums;
            }
        }
        $scope.loadFiles = function() {
            OpsFiles.loadPaymentFiles({
                paymentId: $scope.paymentRequest.id
            }, function(result) {
                $scope.files = [];
                angular.forEach(result, function(file, index) {
                    $scope.files.push(file);
                });
            });
        }
        
        $scope.refreshEconomicDate = function() {
            GuideRates.refreshEconomicDate({
                paymentId: $scope.paymentRequest.id
            }, onSaveSuccess);
        }

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        var onCancelVoucherSuccess = function(result) {
            layer.closeAll();
            $scope.isSaving = false;
            $uibModalInstance.close(result);
            layer.msg($translate.instant('global.messages.submitted'));
        }

        $scope.cancelVoucher = function(voucherId, dnNum) {
            if ($rootScope.HAVENOAUTH('AU_VOID_CN')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_VOID_CN');
                return;
            }
            if($scope.paymentRequest.isApproved || $scope.paymentRequest.auditId){
                layer.msg($translate.instant('autopsApp.accountPayment.auditedAlert'));
                return;
            }
            var title = $translate.instant('autopsApp.accountPayment.home.hedgingTitle');
            var content = dnNum + $translate.instant('autopsApp.accountPayment.home.hedgingContent');
            layer.confirm(content, {
                title: title,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                $scope.isSaving = true;
                if($scope.paymentRequest.isTtl){
                    if($scope.paymentRequest.isApproved || $scope.paymentRequest.auditId){
                        layer.msg($translate.instant('autopsApp.accountPayment.auditedAlert'));
                        return;
                    }
                    AccountInvoice.dismissCom({
                        isDn: false,
                        id: $scope.paymentRequest.id
                    }, onCancelVoucherSuccess, $rootScope.ALERT_ERROR);
                }
                else {
                    layer.load(1, { shade: 0.3 });
                    GuideRates.cancelVoucher({
                        voucherId: voucherId,
                        dnNum: dnNum
                    }, onCancelVoucherSuccess, $rootScope.ALERT_ERROR);
                }
            });
        }
        $scope.previewPayment = function() {
            var paramList = new Array();
            var paymentRequest = angular.copy($scope.paymentRequest);    
            paymentRequest.deadLine = DateUtils.convertLocalDateToServer(paymentRequest.deadLine);
            paymentRequest.economicDate = DateUtils.convertLocalDateToServer(paymentRequest.economicDate);
            paymentRequest.accountDate = DateUtils.convertLocalDateToServer(paymentRequest.accountDate);
            paramList.push(paymentRequest);
            OpsFiles.previewAccountPaymentReport({
                fileType: "pdf"
            }, paramList, function(result) {
                var file = new Blob([result.data], {
                    type: 'application/pdf'
                });
                var fileURL = URL.createObjectURL(file);
                layer.open({
                    type: 2,
                    area: ['1200px', '800px'],
                    maxmin: true,
                    title: $translate.instant("autopsApp.accountPayment.viewAccountPayment"),
                    shade: 0.3,
                    moveType: 0,
                    shift: 0,
                    content: fileURL
                })
            }, function(error) {
                layer.msg($translate.instant("autopsApp.accountPayment.viewAccountPaymentFail "));
            });
        }

        $scope.previewReport = function(code) {
            var guideReportDTO = angular.copy($scope.paymentRequest);           
            guideReportDTO.deadLine = DateUtils.convertLocalDateToServer(guideReportDTO.deadLine);
            guideReportDTO.economicDate = DateUtils.convertLocalDateToServer(guideReportDTO.economicDate);
            guideReportDTO.accountDate = DateUtils.convertLocalDateToServer(guideReportDTO.accountDate);

            guideReportDTO.isDn = false;
            guideReportDTO.requestId = $scope.paymentRequest.id;
            guideReportDTO.payer = $scope.paymentRequest.invoiceTitle;
            guideReportDTO.total = $scope.paymentRequest.invoiceAmount;
            guideReportDTO.totalDec = $scope.paymentRequest.invoiceAmount;            
            guideReportDTO.reportType = code;
            guideReportDTO.opType = 1;
            guideReportDTO.fileType = 1;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                var file = new Blob([result.data], {
                    type: 'application/pdf'
                });
                if (file.size == 0) {
                    layer.msg($translate.instant('global.loadFileFailed'));
                    return;
                }
                var fileURL = URL.createObjectURL(file);
                var previewDialog = layer.open({
                    type: 2,
                    area: ['1000px', '680px'],
                    maxmin: true,
                    title: $translate.instant("entity.action.preview"),
                    shade: 0.3,
                    moveType: 0,
                    shift: 0,
                    content: fileURL
                });
            }, function(error) {

            });
        }


        var onSaveSuccess = function(result) {
            $scope.paymentRequest = result;
            initRequest();
            $scope.isSaving = false;
            layer.msg($translate.instant('global.save_success'));
            // $uibModalInstance.close(result);
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
            if(result.status == 409){
                $scope.paymentRequest.alertTag = false;
                layer.msg($translate.instant('global.noUploadedFile'));
                return;
            }
            layer.msg($translate.instant('global.save_fail'));
        };
        $scope.setAlertTag = function() {
            $scope.isSaving = true;
            AccountPayment.setAlertTag({id: $scope.paymentRequest.id}, onSaveSuccess, onSaveError);
        }
        
        $scope.writeOffSubmit = function() {
            layer.close(writeOffPopIndex);
        }

        $scope.getGuides = function(payment) {
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountPayment/accountPayment-detail.html',
                controller: 'AccountPaymentDetailController',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return AccountPayment.getGuides(payment).$promise;
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }
        $scope.sendPayBill = function(isVoided) {            
            var paymentRequest = angular.copy($scope.paymentRequest);
            paymentRequest.isVoided = isVoided;
            if(isVoided){
                layer.prompt({
                    formType: 0,
                    value: "",
                    title: $translate.instant('autopsApp.accountInvoice.invoiceNum')
                }, function(value, index, elem) {
                    layer.close(index);
                    if(value){
                        value = value.replace(/\s+/g, "");
                        paymentRequest.invoiceNum = value;                        
                    }
                    sendPayBill(paymentRequest);
                });
            }
            else {
                sendPayBill(paymentRequest);
            }
        }

        var sendPayBill = function(paymentRequest){
            $scope.isSaving = true;
            ApiErp.sendPayBill(paymentRequest, onSaveSuccess, function(error){
                $scope.isSaving = false;
                if(error && error.data && error.data.description){
                    $rootScope.DOWNLOAD_TXT(error.data.description, $scope.paymentRequest.dnNum + "_requestBody.txt");
                }
                else {
                    layer.msg($translate.instant('global.send_fail'));
                }
            });
        }
        $scope.setAudit = function() {
            if($scope.paymentRequest.approvalTime && $scope.paymentRequest.auditTime){
                initRequest();
                $rootScope.OPS_ALERT($translate.instant('autopsApp.accountPayment.approvedAlert'));
                return;
            }   
            if($scope.paymentRequest.paidAmount && $scope.paymentRequest.auditTime){
                initRequest();
                $rootScope.OPS_ALERT($translate.instant('autopsApp.accountPayment.paidAlert'));
                return;
            }       
            $scope.isSaving = true;
            AccountPayment.setAudited({id: $scope.paymentRequest.id}, onSaveSuccess, onSaveError);
        }
        
        $scope.setApprove = function() {  
            if(($scope.paymentRequest.dnOnly && $scope.paymentRequest.isApproved) || $scope.paymentRequest.isVoided){
                $scope.paymentRequest.isApproved = !$scope.paymentRequest.isApproved;
                $rootScope.OPS_ALERT($translate.instant('autopsApp.accountPayment.dnOnlyAlert'));
                return;
            }
            if($scope.paymentRequest.paidAmount && !$scope.paymentRequest.isApproved){
                $scope.paymentRequest.isApproved = !$scope.paymentRequest.isApproved;
                $rootScope.OPS_ALERT($translate.instant('autopsApp.accountPayment.paidAlert'));
                return;
            } 
            $scope.isSaving = true;    
            AccountPayment.setApprove({id: $scope.paymentRequest.id}, onSaveSuccess, onSaveError);
        };
        
        $scope.refreshGuideRatesByDnNum = function(refreshType) {
            // if(!$rootScope.isYuyue($scope.paymentRequest.companyId)){
            //     return;
            // }
            layer.load(1, { shade: 0.3 });
            GuideRates.refreshGuideRatesByDnNum({
                companyId: $scope.paymentRequest.companyId,
                type: refreshType,
                dnNum: $scope.paymentRequest.dnNum
            }, function(result){
                layer.closeAll();
                if(result.id){
                    angular.copy(result, $scope.paymentRequest);
                }
                layer.msg($translate.instant('global.update_success'));
            });
        }
        $scope.getPayBillBody = function() {
            layer.load(1, {shade: 0.3});
            ApiErp.getPayBillBody($scope.paymentRequest, function(result){
                layer.closeAll();
                $rootScope.DOWNLOAD_TXT(result.value, $scope.paymentRequest.dnNum + "_body" + ".txt");                
            }, function(error){
                layer.closeAll();
            });
        }
    }
})();