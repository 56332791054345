(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsShipperController', OpsShipperController);

    OpsShipperController.$inject = ['$scope', '$rootScope', '$state', '$translate', 'Principal', 'OpsShipper', 'OpsShipperSearch', 'ParseLinks'];

    function OpsShipperController($scope, $rootScope, $state, $translate, Principal, OpsShipper, OpsShipperSearch,ParseLinks) {

        $scope.opsShippers = [];
        $scope.predicate = 'id';
        $scope.reverse = false;
        $scope.page = 1;
        $scope.loadAll = function() {
            OpsShipper.query({page: $scope.page-1, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']},function(result,headers) {
            	$scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
            	$scope.opsShippers = result;
            });
        };
        $scope.loadAll();

        if (!$rootScope.account || angular.equals($rootScope.account, {})){
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };
        $scope.search = function () {
            OpsShipperSearch.query({query: $scope.searchQuery}, function(result) {
                $scope.opsShippers = result;
            }, function(response) {
                if(response.status === 404) {
                    $scope.loadAll();
                }
            });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.delete = function (id, num) {

        }
        $scope.clear = function () {
            $scope.opsShipper = {
                companyId: null,
                createdBy: null,
                venderId: null,
                code: null,
                companyName: null,
                address: null,
                attn: null,
                mob: null,
                tel: null,
                fax: null,
                email: null,
                einNum: null,
                bondNum: null,
                authority: null,
                blShow: null,
                createdTime: null,
                lastModifiedBy: null,
                lastModifiedTime: null,
                remarks: null,
                id: null
            };
        };

        var clientEditIndex = 0;
        $scope.opsShipper = {};
        var objCopy = {};

        $scope.edit = function (shipper) {
            if (shipper && shipper.createdBy != $rootScope.account.id){
                layer.msg($translate.instant('global.forbidden'));
                return;
            }

            if (!shipper){
                $scope.opsShipper = {};
                objCopy = angular.copy($scope.opsShipper);
                openClientEditPop();
            }
            else {
                OpsShipper.get({id: shipper.id}, function(result) {
                    $scope.opsShipper = result;
                    objCopy = angular.copy($scope.opsShipper);
                    openClientEditPop();
                });
            }
        }

        var openClientEditPop = function() {
            var title = $translate.instant('autopsApp.opsShipper.home.createLabel');
            if ($scope.opsShipper.companyName){
                title = $scope.opsShipper.companyName;
            }
            clientEditIndex = layer.open({
                type: 1,
                area: ['880px', '350px'],
                maxmin: true,
                title: title,
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: $("#clientEdit"),
                btn: [$translate.instant('global.save')],
                yes: function(index, layero) {
                    if (angular.equals($scope.opsShipper, objCopy)){
                        layer.close(index);
                        return;
                    }
                    if ($scope.isSaving){
                        return;
                    }
                    saveClient();
                },
               end: function() {
                    $scope.opsShipper = {};
                }
            });
        }

        $scope.upperCode = function(client) {
            client.code = angular.uppercase(client.code);
        };
        $scope.uppercaseBlShow = function (obj){
            obj.blShow=angular.uppercase(obj.blShow);
        }
        var onSaveSuccess = function (result) {
            layer.msg($translate.instant('global.save_success'));
            $scope.isSaving = false;
            $scope.loadAll();
            layer.close(clientEditIndex);
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('global.save_fail'));
        };

        var saveClient = function () {
            if ($scope.opsShipper.id && $scope.opsShipper.createdBy != $rootScope.account.id){
                layer.msg($translate.instant('global.forbidden'));
                return;
            }

            if (!$scope.opsShipper.code || !$scope.opsShipper.companyName || !$scope.opsShipper.blShow){
                layer.confirm($translate.instant('autopsApp.opsShipper.nullAlert'), {
                    title: $translate.instant('autopsApp.opsShipper.notFull'),
                    btn: [$translate.instant('global.confirm')]
                }, function(index) {
                    layer.close(index);
                });
                return;
            }

            $scope.isSaving = true;
            if ($scope.opsShipper.id != null) {
                OpsShipper.update($scope.opsShipper, onSaveSuccess, onSaveError);
            } else {
                $scope.opsShipper.createdBy = $rootScope.account.id;
                $scope.opsShipper.createdTime = new Date();
                OpsShipper.save($scope.opsShipper, onSaveSuccess, onSaveError);
            }
        };


        var onDeleteSuccess = function (result) {
            layer.msg($translate.instant('global.delete_success'));
            $scope.loadAll();
        };

        var onDeleteError = function (result) {
            layer.msg($translate.instant('global.delete_fail'));
        };

        $scope.delete = function (obj) {
            if (obj.createdBy != $rootScope.account.id){
                layer.msg($translate.instant('global.forbidden'));
                return;
            }
            layer.confirm(obj.companyName + $translate.instant('autopsApp.opsShipper.delete.question'), {
                title: $translate.instant('autopsApp.opsShipper.delete.title'),
                btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')]
            }, function(index) {
                layer.close(index);
                OpsShipper.delete({id: obj.id}, onDeleteSuccess, onDeleteError);
            }, function(index) {
                layer.close(index);
            });
        }





    }
})();
