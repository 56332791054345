(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ShareSellingDialogController', ShareSellingDialogController);

    ShareSellingDialogController.$inject = ['$rootScope', '$uibModalInstance', '$translate', '$timeout', '$uibModal', 
    'entity', 'Booking', 'GuideRates', 'Guide', 'DataUtils', 'ExpressParcel'];

    function ShareSellingDialogController ($rootScope, $uibModalInstance, $translate, $timeout, $uibModal, 
        entity, Booking, GuideRates, Guide, DataUtils, ExpressParcel) {
               
        var vm = this;
        vm.guide = entity;
        vm.hbls = [];
        vm.ratesCopy = [];
        vm.type;
        vm.vwTtl = {};

        vm.clear = clear;
        vm.refreshRatesHbls = refreshRatesHbls;
        vm.delRate = delRate;
        vm.delGRate = delGRate;
        vm.submitShare = submitShare;
        vm.setShareType = setShareType;
        vm.updateVWtotal = updateVWtotal;

        vm.delHbl= delHbl;

        var hblsCopy;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function setShareType (type) {
            vm.type = type;
        }
        var isSplitHbls = true;
        $timeout(function (){
            if(vm.guide.masterId == 0 || vm.guide.isMaster || vm.guide.parcelMaster){
                isSplitHbls = false;
            }
            hblsCopy = [];
            if(isSplitHbls){
                ExpressParcel.getSplitShipments({refGuideId: vm.guide.id}, function(result){
                    if(!result || result.length == 0){
                        layer.msg($translate.instant('autopsApp.guide.hblsNullAlert'));
                        $uibModalInstance.dismiss('cancel');
                        return;
                    }
                    else {
                        angular.forEach(result, function(data){
                            if(!data.isClosed && !data.isVoid && !data.ratesLocked){
                                hblsCopy.push(data);
                            }
                        })
                        refreshRatesHbls();
                    }                
                })
            }
            else {                
                ExpressParcel.getSubShipments({
                    companyId: $rootScope.account.companyId,
                    isFms: angular.isDefined(vm.guide.booking),
                    masterId: vm.guide.id
                }, function(result){
                    if(!result || result.length == 0){
                        layer.msg($translate.instant('autopsApp.guide.hblsNullAlert'));
                        $uibModalInstance.dismiss('cancel');
                        return;
                    }
                    else {
                        angular.forEach(result, function(data){
                            if(!data.isClosed && !data.isVoid && !data.ratesLocked){
                                hblsCopy.push(data);
                            }
                        })
                        refreshRatesHbls();
                    }  
                });
            }            
        })
        function refreshRatesHbls (rate) {
            vm.ratesCopy = [];
            angular.forEach(vm.guide.guideRates, function(rate){
                if(!rate.isPay && !rate.dnNum && rate.id && !rate.isHidden){
                    vm.ratesCopy.push(rate);
                }
            })
            vm.hbls = angular.copy(hblsCopy);
            updateVWtotal();
            updateRatesTotal();
        }

        function delRate (rate) {
            var index = vm.ratesCopy.indexOf(rate);
            if (index > -1) {
                vm.ratesCopy.splice(index, 1);
            };
            updateRatesTotal();
        }
        function delGRate (rate) {
            var index = vm.guide.guideRates.indexOf(rate);
            if (index > -1) {
                vm.guide.guideRates.splice(index, 1);
            };
            updateRatesTotal();
        }
        function delHbl (hbl) {
            var index = vm.hbls.indexOf(hbl);
            if (index > -1) {
                vm.hbls.splice(index, 1);
            };
            updateVWtotal();
        }

        vm.costTotalList = [];
        vm.sellTotalList = [];
        function updateRatesTotal () {
            vm.costTotalList = [];
            var currencys = [];
            angular.forEach(vm.guide.guideRates, function(data){
                if (!data.isPay && data.payCurrency && currencys.indexOf(data.payCurrency) === -1){
                    currencys.push(data.payCurrency);
                }
            })

            var cost = 0;
            angular.forEach(currencys, function(currency){
                cost = 0;
                angular.forEach(vm.guide.guideRates, function(rate){
                    if (!rate.isPay && rate.payCurrency == currency && rate.payAmount){
                        if (!rate.isPay) {
                            cost = cost + rate.payAmount;
                        }
                    }
                })
                vm.costTotalList.push({currency: currency, total: DataUtils.round(cost, 2)});
            })

            vm.sellTotalList = [];
            currencys = [];
            angular.forEach(vm.ratesCopy, function(data){
                if (!data.isPay && data.payCurrency && currencys.indexOf(data.payCurrency) === -1){
                    currencys.push(data.payCurrency);
                }
            })
            angular.forEach(currencys, function(currency){
                cost = 0;
                angular.forEach(vm.ratesCopy, function(rate){
                    if (!rate.isPay && rate.payCurrency == currency && rate.payAmount){
                        if (!rate.isPay) {
                            cost = cost + rate.payAmount;
                        }
                    }
                })
                vm.sellTotalList.push({currency: currency, total: DataUtils.round(cost, 2)});
            })
        }

        function updateVWtotal () {
            var gw=0,vol=0,vw=0,plt=0;
            angular.forEach(vm.hbls, function(data){
                if(data.gw){
                    gw = gw + data.gw;
                }
                if(data.vol){
                    vol = vol + data.vol;
                }
                if(data.cw){
                    vw = vw + data.cw;
                }
                if(data.pallets){
                    plt = plt + data.pallets;
                }
            })
            vm.vwTtl.gw = DataUtils.round(gw, 2);
            vm.vwTtl.vol = DataUtils.round(vol, 3);
            vm.vwTtl.vw = DataUtils.round(vw, 2);
            vm.vwTtl.pallets = DataUtils.round(plt, 2);            
        }
        
        function submitShare () {
            if(!vm.type || vm.ratesCopy.length == 0 || vm.hbls.length == 0){
                return;
            }
            var shareSellingDTO = {};
            shareSellingDTO.guideRates = vm.ratesCopy;
            shareSellingDTO.consolShipmentDTOs = vm.hbls;
            shareSellingDTO.type = vm.type;
            shareSellingDTO.isSplitHbls = isSplitHbls;

            ExpressParcel.shareSelling(shareSellingDTO, function(result){
                angular.forEach(vm.ratesCopy, function(rate){
                    var index = vm.guide.guideRates.indexOf(rate);
                    if (index > -1) {
                        vm.guide.guideRates.splice(index, 1);
                    };
                });
                if(isSplitHbls){
                    angular.forEach(result, function(rate){
                        vm.guide.guideRates.push(rate);      
                    });
                }
                vm.ratesCopy = [];
                updateRatesTotal();
                layer.msg($translate.instant('global.messages.submitted')); 
            }, function(){
                layer.msg($translate.instant('global.messages.submittedfailed'));
            })
        }

    }
})();
