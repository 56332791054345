(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('pub-tracking-demo', {
                parent: 'account',
                url: '/pub-tracking/demo',
                data: {
                    authorities: [],
                    pageTitle: 'activate.tracking'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/pub-tracking/pub-tracking.html',
                        controller: 'PubTrackingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('activate');
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('opsTrackStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('public-tracking', {
                parent: 'account',
                url: '/public-tracking',
                data: {
                    authorities: [],
                    pageTitle: 'activate.tracking'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/pub-tracking/pub-tracking.html',
                        controller: 'PubTrackingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('activate');
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('opsTrackStatus');
                        return $translate.refresh();
                    }]
                }
            })

            .state('pub-tracking-elt', {
                parent: 'account',
                url: '/pub-tracking/elt',
                data: {
                    authorities: [],
                    pageTitle: 'activate.tracking'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/pub-tracking/pub-tracking.html',
                        controller: 'PubTrackingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('activate');                        
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('opsTrackStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('pub-tracking-ontask', {
                parent: 'account',
                url: '/pub-tracking/ontask',
                data: {
                    authorities: [],
                    pageTitle: 'activate.tracking'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/pub-tracking/pub-tracking.html',
                        controller: 'PubTrackingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('activate');
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('opsTrackStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('pub-tracking-sharkso', {
                parent: 'account',
                url: '/pub-tracking/sharkso',
                data: {
                    authorities: [],
                    pageTitle: 'activate.tracking'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/pub-tracking/pub-tracking.html',
                        controller: 'PubTrackingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('activate');
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('opsTrackStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('pub-tracking-blue', {
                parent: 'account',
                url: '/pub-tracking/blue',
                data: {
                    authorities: [],
                    pageTitle: 'activate.tracking'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/pub-tracking/pub-tracking.html',
                        controller: 'PubTrackingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('activate');
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('opsTrackStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('pub-tracking-yuyue', {
                parent: 'account',
                url: '/pub-tracking/yuyue',
                data: {
                    authorities: [],
                    pageTitle: 'activate.tracking'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/pub-tracking/pub-tracking.html',
                        controller: 'PubTrackingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('activate');
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('opsTrackStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('pub-tracking-globalflyinghorse', {
                parent: 'account',
                url: '/pub-tracking/globalflyinghorse',
                data: {
                    authorities: [],
                    pageTitle: 'activate.tracking'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/pub-tracking/pub-tracking.html',
                        controller: 'PubTrackingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('activate');
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('opsTrackStatus');
                        return $translate.refresh();
                    }]
                }
            })            
            .state('pub-tracking-ansung', {
                parent: 'account',
                url: '/pub-tracking/ansung',
                data: {
                    authorities: [],
                    pageTitle: 'activate.tracking'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/pub-tracking/pub-tracking-old.html',
                        controller: 'PubTrackingOldController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('activate');
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('opsTrackStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('pub-tracking-lylogistics', {
                parent: 'account',
                url: '/pub-tracking/lylogistics',
                data: {
                    authorities: [],
                    pageTitle: 'activate.tracking'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/pub-tracking/pub-tracking.html',
                        controller: 'PubTrackingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('activate');
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('opsTrackStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('pub-tracking-zjscjs', {
                parent: 'account',
                url: '/pub-tracking/zjscjs',
                data: {
                    authorities: [],
                    pageTitle: 'activate.tracking'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/pub-tracking/pub-tracking.html',
                        controller: 'PubTrackingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('activate');
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('opsTrackStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('pub-tracking-szmr', {
                parent: 'account',
                url: '/pub-tracking/szmr',
                data: {
                    authorities: [],
                    pageTitle: 'activate.tracking'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/pub-tracking/pub-tracking.html',
                        controller: 'PubTrackingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('activate');
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('opsTrackStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('pub-tracking-btskd', {
                parent: 'account',
                url: '/pub-tracking/btskd',
                data: {
                    authorities: [],
                    pageTitle: 'activate.tracking'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/pub-tracking/pub-tracking.html',
                        controller: 'PubTrackingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('activate');
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('opsTrackStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('pub-tracking-hingka', {
                parent: 'account',
                url: '/pub-tracking/hingka',
                data: {
                    authorities: [],
                    pageTitle: 'activate.tracking'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/pub-tracking/pub-tracking.html',
                        controller: 'PubTrackingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('activate');
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('opsTrackStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('pub-tracking-yk', {
                parent: 'account',
                url: '/pub-tracking/yk',
                data: {
                    authorities: [],
                    pageTitle: 'activate.tracking'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/pub-tracking/pub-tracking.html',
                        controller: 'PubTrackingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('activate');
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('opsTrackStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('pub-tracking-ngs', {
                parent: 'account',
                url: '/pub-tracking/ngs',
                data: {
                    authorities: [],
                    pageTitle: 'activate.tracking'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/pub-tracking/pub-tracking.html',
                        controller: 'PubTrackingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('activate');
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('opsTrackStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('pub-tracking-kraken', {
                parent: 'account',
                url: '/pub-tracking/kraken',
                data: {
                    authorities: [],
                    pageTitle: 'activate.tracking'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/pub-tracking/pub-tracking.html',
                        controller: 'PubTrackingController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('activate');
                        $translatePartialLoader.addPart('expressTracking');
                        $translatePartialLoader.addPart('opsTrackStatus');
                        return $translate.refresh();
                    }]
                }
            });            
    }
})();
