(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('VgmHeadDetailController', VgmHeadDetailController);

    VgmHeadDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'VgmHead', 'WinAES'];

    function VgmHeadDetailController($scope, $rootScope, $stateParams, previousState, entity, VgmHead, WinAES) {
        var vm = this;

        vm.vgmHead = entity;
        vm.vgmHead.encodeId = WinAES.encode(vm.vgmHead.id.toString());
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('autopsApp:vgmHeadUpdate', function(event, result) {
            vm.vgmHead = result;
            vm.vgmHead.encodeId = WinAES.encode(vm.vgmHead.id.toString());
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
