(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ExpressTrackingDetailController', ExpressTrackingDetailController);

    ExpressTrackingDetailController.$inject = ['$scope', '$rootScope', '$timeout', '$uibModal', '$translate', '$http',
        'previousState', 'Guide', 'ExpressTracking', 'OpsFiles', 'OpsTrackStatus'
    ];

    function ExpressTrackingDetailController($scope, $rootScope, $timeout, $uibModal, $translate, $http,
        previousState, Guide, ExpressTracking, OpsFiles, OpsTrackStatus) {
        var vm = this;

        vm.previousState = previousState.name;
        vm.deliverySign = deliverySign;
        vm.scanCode = scanCode;
        vm.viewTracking = viewTracking;
        vm.uploadFile = uploadFile;

        vm.onstatusSelected = onstatusSelected;
        vm.choosedMoveChange = choosedMoveChange;


        vm.guide = {};
        vm.scanedList = [];
        vm.moveList = [];
        vm.move = {};

        vm.conveyorMode = false;

        function scanCode() {
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-dialog.html',
                controller: 'WmsInDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md'
            }).result.then(function(code) {
                deliverySign(code);
            }, function() {});
        }
        function choosedMoveChange() {
            vm.guide = null;
            vm.scanedList = [];
            vm.moveList = [];
        }
        
        function getDliveriedMove() {
            angular.forEach($rootScope.TRACKSTATUS, function(move){
                if(move.delivered){
                    vm.move = angular.copy(move);
                }
            })
        }

        if (!$rootScope.TRACKSTATUS || $rootScope.TRACKSTATUS.length == 0){
            OpsTrackStatus.getAll(function(result) {
                $rootScope.TRACKSTATUS = result;
                getDliveriedMove();
            });
        }
        else {
            getDliveriedMove();
        }
        
        function onstatusSelected (data) {
            vm.move = data;        
        }     

        function deliverySign(code) {
            if (!code || !vm.move.id) {
                return;
            }
            vm.guide = {};
            Guide.deliverySign({
                soNum: code
            }, vm.move, function(result) {
                vm.guide = result;
                if (vm.scanedList.indexOf(code) == -1) {
                    vm.scanedList.splice(0, 0, code);
                }
                if (vm.scanedList.length > 5) {
                    for (var i = vm.scanedList.length - 1; i > 5; i--) {
                        vm.scanedList.splice(i, 1);
                    }
                }
                $translate.refresh();
            }, $rootScope.ALERT_ERROR);
        }

        $(document).keydown(function(event) {
            if (event.keyCode == 13) {
                if (vm.scanedCode && vm.scanedCode.length > 3) {
                    deliverySign(vm.scanedCode);
                    vm.code = vm.scanedCode;
                    vm.scanedCode = null;
                }
                $timeout(function() {
                    $("#field_manualBarCode").focus();
                });
            }
        });
        $timeout(function() {
            $("#field_manualBarCode").focus();
        });

        function viewTracking(code) {
            code = code.replace(/[ ]/g, "");
            ExpressTracking.pubTracking({
                soNum: code,
                companyId: $rootScope.account.companyId
            }, function(result) {
                vm.moveList = result;
                if (!vm.moveList || vm.moveList.length == 0) {
                    layer.msg($translate.instant('activate.notFound'));
                }
            }, function(result) {
                if (result.status == 404) {
                    layer.msg($translate.instant('activate.notFound'));
                }
            })
        }

        function uploadFile (file) {
            if (!file || !vm.guide.id) {
                return;
            }
            var fileName = null;            
            var filePath;
            if(vm.guide.booking){
                filePath = "REPORTS/GUIDE/" + vm.guide.id + "/";
            }
            else {
                filePath = "/expressParcel/" + vm.guide.id + "/";
            }
            if(vm.move.delivered){
                fileName = "receipt";
                filePath +="RC/";
            }
            $rootScope.UPLOAD_FILE_WITH_NAME(file, filePath, $scope.files, $rootScope.account.companyId, fileName);
        };

        $scope.files = new Array();        
        $scope.loadFiles = function (){
            if ($scope.files && $scope.files.length > 0){
                return;
            }            
            if (vm.guide.id) {
                OpsFiles.getReceiptFile({
                    id: vm.guide.id
                },function (data){
                    $scope.files = new Array();
                    if(data.length>0){
                        for(var i = 0 ; i < data.length; i++){
                            var temp  = new Object();
                            temp['filepath'] = data[i]['filepath'];
                            temp['filename'] = data[i]['filename'];
                            temp['fileurl'] = data[i]['fileurl'];
                            temp['fileist'] = true;
                            $scope.files.push(temp);
                        }
                    }
                });
            }
        }

    }
})();