(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsVenderDialogController', OpsVenderDialogController);

    OpsVenderDialogController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$translate', 'OpsVender'];
    
    function OpsVenderDialogController($scope, $rootScope, $uibModalInstance, $translate, OpsVender) {
        $scope.fromV = {};
        $scope.toV = {};
        var onSaveSuccess = function (result) {
            $rootScope.OPSVENDERS = null;
            layer.closeAll();   
            layer.msg($translate.instant('global.messages.submitted'));
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };
        var onSaveError = function (result) {
            layer.closeAll();   
            $scope.isSaving = false;
            $rootScope.ALERT_ERROR(result);
        }

        $scope.save = function () {
            if($rootScope.ISDEMO && $rootScope.HAVENOAUTH('ROLE_BOSS')){
                layer.msg($translate.instant('global.demoAlert'));
                return;
            }   
            $scope.isSaving = true;
            layer.msg("Combining...", {
                shade: 0.3,
                time: 200000
            });
            OpsVender.combineObj({
                fromId: $scope.fromV.id,
                toId: $scope.toV.id
            }, onSaveSuccess, onSaveError);
        }
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $rootScope.LOADALLVENDERS();  

        $scope.onFromChoosed = function(data) {
            if($scope.toV && $scope.toV.id == data.id){
                $scope.fromV = {};
            }
            else {
                $scope.fromV = data;
            } 
        }
        $scope.onToChoosed = function(data) {
            if($scope.fromV && $scope.fromV.id == data.id){
                $scope.toV = {};
            }
            else {
                $scope.toV = data;
            }
        }
    }
})();
