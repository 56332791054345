(function () {
    'use strict';

    angular.module('autopsApp').controller('WarehousingDataSearchController',
        WarehousingDataSearchController);

    WarehousingDataSearchController.$inject = ['$scope', '$uibModalInstance', '$rootScope', 'entity', 'WmsIn', 'WmsWarehouse', 'OpsPorts', 'WarehousingData', 'OpsVender'];

    function WarehousingDataSearchController($scope, $uibModalInstance, $rootScope, entity, WmsIn, WmsWarehouse, OpsPorts, WarehousingData, OpsVender) {

        $scope.searchParams = entity;
        var vm = this;
        vm.isSaving = false;
        vm.clear = clear;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope._onClientSelected = function (data) {
            $scope.searchParams.guide.customerId = data.id;
        }
        $scope.salesFilter = function (item) {
            return item.authorities.indexOf("ROLE_SALES") !== -1;
        };
        $scope._onClerkSelected = function (clerk, data) {
            $scope.searchParams[clerk] = data.id;
        }
        $scope.searchRequests = function () {
            vm.isSaving = true;
            WarehousingData.advancedSearch($scope.searchParams, function (result, headers) {
                vm.isSaving = false;
                $uibModalInstance.close(result);
            }, $rootScope.ALERT_ERROR);
            vm.isSaving = false;
        };

        $scope.warehouseSelected = function (warehouse) {
            $scope.searchParams.guide.whId = warehouse.id;
        }

        $scope.warehouses = null;
        $scope.getWarehouses = function (fd, value) {
            if ($scope.warehouses && $scope.warehouses.length > 0) {
                return;
            }
            WmsWarehouse.getAll({
                includeOwn: true,
                includeVirtual: false,
                includeGroup: false
            }, function (data) {
                $scope.warehouses = data;
            });
        }

        $scope.opsPorts = [];
        $scope.loadPorts = function () {
            if ($scope.opsPorts && $scope.opsPorts.length > 0) {
                return;
            }
            OpsPorts.getAll(function (result) {
                $scope.opsPorts = result;
            })
        };

        $scope.setStatus = function (fd, value) {
            $scope.searchParams.guide[fd] = value;
        }

        $scope.warehouses = null;
        $scope.getWarehouses= function(fd, value) {
            if ($scope.warehouses && $scope.warehouses.length > 0){
                return;
            }
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false
                }, function (data) {
                $scope.warehouses = data;
            });
        }
    }
})();
