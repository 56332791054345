(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsCargoMovementController',WmsCargoMovementController);

    WmsCargoMovementController.$inject = ['$rootScope', '$uibModalInstance', '$translate', '$timeout', 'entity', 'WmsCargo', 'WmsWarehouse', 'WmsIn'];

    function WmsCargoMovementController($rootScope, $uibModalInstance, $translate, $timeout, entity, WmsCargo, WmsWarehouse, WmsIn) {
        var vm = this;

        vm.wmsCargos = entity.wmsCargos;
        vm.locations = entity.locations;
        vm.clear = clear;
        vm.submitMovment = submitMovment;
        vm.getAllLocations = getAllLocations;
        vm._onLocationSelected = _onLocationSelected;
        vm.moveAmtChange = moveAmtChange;
        vm.loadWmsInInventoryCargo = loadWmsInInventoryCargo;
        vm.autoFillLocation = autoFillLocation;
        vm.isSaving = false;
        $timeout(function (){
            angular.forEach(vm.wmsCargos, function(data){
                data.moveAmt = data.availableAmt;
            })
        });
        vm.searchParam = "";
        function loadWmsInInventoryCargo () {     
            if(!vm.searchParam){
                return;
            }
            WmsIn.loadWmsInInventoryCargo({
                companyId: $rootScope.account.companyId,
                searchNum: vm.searchParam
            }, function(result) {
                vm.wmsCargos = result;
            }, function(error) {
                layer.msg($translate.instant('global.loadFailed'));
            });
        }

        function moveAmtChange (cargo) {
            if(cargo.moveAmt < 0){
                cargo.moveAmt = 0;
                return;
            }
            if(cargo.moveAmt > cargo.availableAmt){
                cargo.moveAmt = cargo.availableAmt;
                layer.msg($translate.instant('global.overMaxAlert'));
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function _onLocationSelected (location, cargo) {
            cargo.locationNew = location.code;
            cargo.locationIdNew = location.locationId;
            cargo.areaIdNew = location.areaId;
            cargo.warehouseIdNew = location.warehouseId;
            cargo.locationTypeNew = location.locationType;
        }
        function getAllLocations () {
            if (vm.locations && vm.locations.length > 0){
                return;
            }
            WmsWarehouse.getAllLocations({companyId: $rootScope.account.companyId}, function (data) {
                vm.locations = data;
            });
        }

        function submitMovment () {
            vm.isSaving = true;
            WmsCargo.movement(vm.wmsCargos, function(result) {
                vm.isSaving = false;
                $uibModalInstance.close(result);
                layer.msg($translate.instant('global.messages.submitted'));
                $translate.refresh();  
            }); 
        }
        function autoFillLocation (cargo) {
            if(!cargo.locationNew){
                return;
            }
            angular.forEach(vm.wmsCargos, function (data) {
                if(!data.locationNew){
                    data.locationNew = cargo.locationNew;
                    data.locationIdNew = cargo.locationIdNew;
                    data.areaIdNew = cargo.areaIdNew;    
                    data.warehouseIdNew = cargo.warehouseIdNew;
                    data.locationTypeNew = cargo.locationTypeNew;
                }
            })
        }

    }
})();
