(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('address-book', {
            parent: 'entity',
            url: '/address-book?page&sort&search',
            data: {
                authorities: ['FUN_ADDRESS_BOOK', 'ROLE_CLIENT'],
                pageTitle: 'autopsApp.addressBook.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/address-book/address-books.html',
                    controller: 'AddressBookController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('addressBook');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('jsExpression');
                    $translatePartialLoader.addPart('commodityDatabase');
                    return $translate.refresh();
                }]
            }
        })
        .state('label-edi', {
            parent: 'entity',
            url: '/label-edi?page&sort&search',
            data: {
                authorities: ['FUN_LABEL_EDI'],
                pageTitle: 'global.menu.entities.labelEdi'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/address-book/address-books.html',
                    controller: 'AddressBookController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('addressBook');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('jsExpression');      
                    $translatePartialLoader.addPart('commodityDatabase');
                    return $translate.refresh();
                }]
            }
        })
        .state('label-edi.edit', {
            parent: 'label-edi',
            url: '/{id}/edit',
            data: {
                authorities: ['FUN_LABEL_EDI']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/address-book/address-book-dialog.html',
                    controller: 'AddressBookDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: ['AddressBook', 'WinAES', function(AddressBook, WinAES) {
                            return AddressBook.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('label-edi', null, { reload: 'label-edi' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('label-edi.copy', {
            parent: 'label-edi',
            url: '/{id}/copy',
            data: {
                authorities: ['FUN_LABEL_EDI']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/address-book/address-book-dialog.html',
                    controller: 'AddressBookDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: ['AddressBook', 'WinAES', function(AddressBook, WinAES) {
                            return AddressBook.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('label-edi', null, { reload: 'label-edi' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('label-edi.new', {
            parent: 'label-edi',
            url: '/new',
            data: {
                authorities: ['FUN_LABEL_EDI']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/address-book/address-book-dialog.html',
                    controller: 'AddressBookDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                binded: true
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('label-edi', null, { reload: 'label-edi' });
                }, function() {
                    $state.go('label-edi');
                });
            }]
        })
        .state('address-book.new', {
            parent: 'address-book',
            url: '/new',
            data: {
                authorities: ['FUN_ADDRESS_BOOK', 'ROLE_CLIENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/address-book/address-book-dialog.html',
                    controller: 'AddressBookDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {binded: false};
                        }
                    }
                }).result.then(function() {
                    $state.go('address-book', null, { reload: 'address-book' });
                }, function() {
                    $state.go('address-book');
                });
            }]
        })
        .state('address-book.edit', {
            parent: 'address-book',
            url: '/{id}/edit',
            data: {
                authorities: ['FUN_ADDRESS_BOOK', 'ROLE_CLIENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/address-book/address-book-dialog.html',
                    controller: 'AddressBookDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: ['AddressBook', 'WinAES', function(AddressBook, WinAES) {
                            return AddressBook.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('address-book', null, { reload: 'address-book' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('address-book.delete', {
            parent: 'address-book',
            url: '/{id}/delete',
            data: {
                authorities: ['FUN_ADDRESS_BOOK', 'ROLE_CLIENT']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/address-book/address-book-delete-dialog.html',
                    controller: 'AddressBookDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['AddressBook', 'WinAES', function(AddressBook, WinAES) {
                            return AddressBook.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('address-book', null, { reload: 'address-book' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('label-edi.ups-auth', {
            parent: 'label-edi',
            url: '/ups-auth',
            data: {
                authorities: ['FUN_LABEL_EDI']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/address-book/address-book-dialog.html',
                    controller: 'AddressBookDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: [function () {
                            return {};
                        }]
                    }
                }).result.then(function() {
                    $state.go('label-edi', null, { reload: 'label-edi' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('label-edi.sp-api-auth', {
            parent: 'label-edi',
            url: '/sp-api-auth',
            data: {
                authorities: ['FUN_LABEL_EDI']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/address-book/address-book-dialog.html',
                    controller: 'AddressBookDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: [function () {
                            return {};
                        }]
                    }
                }).result.then(function() {
                    $state.go('label-edi', null, { reload: 'label-edi' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
