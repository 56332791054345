(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountAuthController', AccountAuthController);

    AccountAuthController.$inject = ['$scope', '$uibModalInstance', '$timeout', '$translate', 'entity', 'DataUtils'];

    function AccountAuthController($scope, $uibModalInstance, $timeout, $translate, entity, DataUtils) {
        var vm = this;

        vm.ratesCopy = entity;

        vm.clear = clear;
        vm.save = save;
        vm.onSelected = onSelected;
        vm.delOne = delOne;
        vm.ratesList = [];
        vm.totalList = [];

        vm.filter = {};

        vm.shipmentTypes = [];
        vm.productNames = [];
        vm.items = [];
        vm.opNames = [];
        vm.docNames = [];
        vm.finNames = [];

        vm.shptTypes = [];
        vm.prodNames = [];
        vm.itms = [];
        vm.opNms = [];
        vm.docNms = [];
        vm.finNms = [];

        $timeout(function() {
            getFilters();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            $uibModalInstance.close(vm.ratesList);
        }

        function getTotalList() {
            vm.totalList = [];
            var currencies = new Set();
            angular.forEach(vm.ratesList, function(data) {
                currencies.add(data.currency);
            })
            currencies.forEach(function(currency) {
                var ttl = 0;
                angular.forEach(vm.ratesList, function(data) {
                    if (data.id >= 0 && data.currency == currency && data.totalB) {
                        ttl += data.totalB;
                    }
                })
                if (ttl) {
                    var newRate = {};
                    newRate.currency = currency;
                    newRate.totalB = DataUtils.round(ttl, 2);
                    newRate.total = DataUtils.round(ttl, 2);
                    vm.totalList.push(newRate);
                }
            });
            $translate.refresh();
        }
        
        function updateList() {
            angular.copy(vm.ratesCopy, vm.ratesList);
            for (var i = vm.ratesList.length - 1; i >= 0; i--) {
                if (vm.shipmentTypes.length && (isNew(vm.shipmentTypes, vm.ratesList[i].shipmentType) || !vm.ratesList[i].shipmentType)) {
                    vm.ratesList.splice(i, 1);
                    continue;
                }
                if (vm.productNames.length && (isNew(vm.productNames, vm.ratesList[i].productName) || !vm.ratesList[i].productName)) {
                    vm.ratesList.splice(i, 1);
                    continue;
                }
                if (vm.items.length && (isNew(vm.items, vm.ratesList[i].item) || !vm.ratesList[i].item)) {
                    vm.ratesList.splice(i, 1);
                    continue;
                }
                if (vm.opNames.length && (isNew(vm.opNames, vm.ratesList[i].opName) || !vm.ratesList[i].opName)) {
                    vm.ratesList.splice(i, 1);
                    continue;
                }
                if (vm.docNames.length && (isNew(vm.docNames, vm.ratesList[i].docName) || !vm.ratesList[i].docName)) {
                    vm.ratesList.splice(i, 1);
                    continue;
                }
                if (vm.finNames.length && (isNew(vm.finNames, vm.ratesList[i].finName) || !vm.ratesList[i].finName)) {
                    vm.ratesList.splice(i, 1);
                    continue;
                }
            }
            getTotalList();
        }
        function delOne(dataList, idx) {
            dataList.splice(idx, 1);
            updateList();
        }

        function onSelected(data, fd) {
            if (vm[fd].indexOf(data) == -1) {
                vm[fd].push(data);
            }
            vm.filter[fd] = "";
            updateList();
        }

        function isNew(dataList, id) {
            if (!id) {
                return false;
            }
            if (!dataList || dataList.length == 0) {
                return true;
            }
            for (var i = 0; i < dataList.length; i++) {
                if (dataList[i].id == id) {
                    return false;
                }
            }
            return true;
        }



        function getFilters() {
            angular.forEach(vm.ratesCopy, function(data) {
                if (isNew(vm.shptTypes, data.shipmentType)) {
                    vm.shptTypes.push({
                        id: data.shipmentType,
                        name: $translate.instant('global.' + data.shipmentType)
                    });
                }
                if (isNew(vm.prodNames, data.productName)) {
                    vm.prodNames.push({
                        id: data.productName,
                        name: data.productName
                    });
                }
                if (isNew(vm.itms, data.item)) {
                    vm.itms.push({
                        id: data.item,
                        name: data.item
                    });
                }
                if (isNew(vm.opNms, data.opName)) {
                    vm.opNms.push({
                        id: data.opName,
                        name: data.opName
                    });
                }
                if (isNew(vm.docNms, data.docName)) {
                    vm.docNms.push({
                        id: data.docName,
                        name: data.docName
                    });
                }
                if (isNew(vm.finNms, data.finName)) {
                    vm.finNms.push({
                        id: data.finName,
                        name: data.finName
                    });
                }
            })
        }





    }
})();