(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WoHeadDeleteController',WoHeadDeleteController);

    WoHeadDeleteController.$inject = ['$rootScope', '$uibModalInstance', '$translate', 'entity', 'WoHead'];

    function WoHeadDeleteController($rootScope, $uibModalInstance, $translate, entity, WoHead) {
        var vm = this;

        vm.woHead = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            if(vm.woHead.knowledgeBase || vm.woHead.technicalSupport 
                || vm.woHead.serviceRating || vm.woHead.status != "new"){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.woHead.knowledgeBaseDelAlert'));
                return;
            }
            WoHead.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
