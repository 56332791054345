(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsFactoryDeleteController', OpsFactoryDeleteController);

    OpsFactoryDeleteController.$inject = ['$rootScope', '$scope', '$uibModalInstance', '$translate', 
    'entity', 'Dictionary'];
    
	function OpsFactoryDeleteController($rootScope, $scope, $uibModalInstance, $translate, 
        entity, Dictionary) {

        $scope.autoCharges = entity;
        $scope.priceUnits = Dictionary.autoPriceUnits;
        $rootScope.loadCurrencys();

        if(!$scope.autoCharges){
            $scope.autoCharges = [];
        }
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope._onItemSelected = function(data, autoCharge) {
            autoCharge.itemId = data.id;
        }


        $scope.confirm = function () {
            $uibModalInstance.close($scope.autoCharges);
        };
        $scope.addOne = function () {
            $scope.autoCharges.push({});
        };
        $scope.delOne = function (index) {
            $scope.autoCharges.splice(index, 1);
        };
        $scope.copyOne = function (autoCharge) {            
            $scope.autoCharges.push(angular.copy(autoCharge));
        };
        $scope._onShipmentTypeSelected = function (shipmentType, autoCharge) {           
            if(!autoCharge.shipmentTypes){
                autoCharge.shipmentTypes = [];
            }
            autoCharge.shipmentType = "";
            var repeated = false;
            angular.forEach(autoCharge.shipmentTypes, function(data) {
                if (data.id == shipmentType.id) {
                    repeated = true;
                }
            })
            if(repeated){
                layer.msg($translate.instant('global.messages.repeated'));
                return;
            }
            autoCharge.shipmentTypes.push(shipmentType);
            autoCharge.shipmentType = "";
        };
        $scope.delShipmentType = function (index, autoCharge) {            
            autoCharge.shipmentTypes.splice(index, 1);
        };

        $scope.shipmentTypes = [{
            id: 'FCL',
            name: $translate.instant('global.FCL')
        }, {
            id: 'AIR',
            name: $translate.instant('global.AIR')
        }, {
            id: 'LCL',
            name: $translate.instant('global.LCL')
        }, {
            id: 'WMS',
            name: $translate.instant('global.WMS')
        }, {
            id: 'LOCAL',
            name: $translate.instant('global.LOCAL')
        }, {
            id: 'LOCALKG',
            name: $translate.instant('global.LOCALKG')
        }, {
            id: 'EXPRESS',
            name: $translate.instant('global.EXPRESS')
        }, {
            id: 'FBAAIR',
            name: $translate.instant('global.FBAAIR')
        }, {
            id: 'FBASEA',
            name: $translate.instant('global.FBASEA')
        }, {
            id: 'RAIL',
            name: $translate.instant('global.RAIL')
        }, {
            id: 'BULK',
            name: $translate.instant('global.BULK')
        }, {
            id: 'ITCT',
            name: $translate.instant('global.ITCT')
        }, {
            id: 'PARCEL',
            name: $translate.instant('global.PARCEL')
        }, {
            id: 'TMS',
            name: $translate.instant('global.TMS')
        }, {
            id: 'LINE',
            name: $translate.instant('global.LINE')
        }, {
            id: 'LINE',
            name: $translate.instant('global.LINE')
        }, {
            id: 'EXD',
            name: $translate.instant('global.EXD')
        }, {
            id: 'FTL',
            name: $translate.instant('global.FTL')
        }, {
            id: 'LTL',
            name: $translate.instant('global.LTL')
        }, {
            id: 'TMS',
            name: $translate.instant('global.TMS')
        }, {
            id: 'EXD',
            name: $translate.instant('global.EXD')
        }, {
            id: 'CPU',
            name: $translate.instant('global.CPU')
        }, {
            id: 'WMS',
            name: $translate.instant('global.WMS')
        }];
    }
})();
