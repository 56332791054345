(function () {
    'use strict';

    angular.module('autopsApp').controller('AutoChecksSearchController',
        AutoChecksSearchController);

    AutoChecksSearchController.$inject = ['$scope', '$uibModalInstance', '$rootScope', 'entity', 'AutoCheck', 'OpsVender'];

    function AutoChecksSearchController($scope, $uibModalInstance, $rootScope, entity, AutoCheck, OpsVender) {

        $scope.searchParams = entity;
        var vm = this;
        vm.isSaving = false;
        vm.clear = clear;
        $rootScope.loadCurrencys();

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function (date) {
            $scope.datePickerOpenStatus[date] = true;
        }
        $scope._onClientSelected = function (data) {
            $scope.searchParams.payerVid = data.id;
        }

        $scope.searchRequests = function () {
            vm.isSaving = true;
            AutoCheck.advancedSearch($scope.searchParams, function (result, headers) {
                vm.isSaving = false;
                $uibModalInstance.close(result);
            }, $rootScope.ALERT_ERROR);
            vm.isSaving = false;
        };

        $scope.setStatus = function (fd, value) {
            if (fd == "isDn") {
                $scope.searchParams.isCn = null;
                $scope.searchParams.isDiff = null;
            }
            if (fd == "isCn") {
                $scope.searchParams.isDn = null;
                $scope.searchParams.isDiff = null;
            }
            if (fd == "isDiff") {
                $scope.searchParams.isCn = null;
                $scope.searchParams.isDn = null;
            }
            $scope.searchParams[fd] = value;
        }

    }
})();
