
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsChangeLogDetailController', OpsChangeLogDetailController);

    OpsChangeLogDetailController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$timeout', '$uibModal', '$translate', 
    'entity', 'OpsVender', 'DateUtils'];

    function OpsChangeLogDetailController ($scope, $rootScope, $uibModalInstance, $timeout, $uibModal, $translate,
        entity, OpsVender, DateUtils) {

        var vm = this;
        vm.contactInfo = entity;
        var venderId = entity.venderId;
        var contactId = entity.contactId;
        vm.remarks = null;
        vm.newPractice = null;
        vm.clear = clear;
        vm.submit = submit;
        vm.confirm = confirm;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function confirm () {
            $uibModalInstance.close(vm.contactInfo);
        }
        $timeout(function (){
            if(venderId){
                OpsVender.getDTO({id: venderId}, function(result){
                    vm.venderDTO = result;
                    vm.remarks = result.remarks;
                    if(contactId){
                        angular.forEach(result.contactList, function(contact){
                            if(contact.id == contactId){
                                vm.contactInfo = contact;
                            }
                        })
                    }
                    if(!vm.contactInfo.attn && result.contactList && result.contactList.length > 0){
                        vm.contactInfo = result.contactList[0];
                    }
                })
            }
        });

        function submit () {            
            if(!vm.newPractice){return}
            if(vm.remarks == null){
                vm.remarks = "【" + $rootScope.account.firstName + " " + $rootScope.account.lastName + " - " + DateUtils.convertLocalDateToServer(new Date()) + "】\n" + vm.newPractice;
            }
            else {
                vm.remarks = vm.remarks + "\n\n【" + $rootScope.account.firstName + " " + $rootScope.account.lastName + " - " + DateUtils.convertLocalDateToServer(new Date()) + "】\n" + vm.newPractice;
            }            
            OpsVender.saveRemarks({
                venderId: venderId,
                remarks: vm.remarks
            }, function(result){     
                $uibModalInstance.close(vm.contactInfo);           
            })
        }

        $scope.showContactList = function() {
            $uibModal.open({
                templateUrl: 'app/entities/guide/contact-list.html',
                controller: 'ContactListController',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return OpsVender.getContactsList({id: venderId}).$promise;
                    }]
                }
            }).result.then(function(contact) {
                vm.contactInfo = contact;
                $translate.refresh();
            }, function() {});
        }

        $scope.editVender = function () { 
            $rootScope.activeTab = 0;
            $uibModal.open({
                templateUrl: 'app/entities/opsVender/opsVender-detail.html',
                controller: 'OpsVenderDetailController',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return OpsVender.get({id: venderId}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsClient');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                vm.venderDTO = result;
            }, function() {
            });
        }

        $scope.textPop = function(text) {
            $rootScope.notBlInfo = true;
            $uibModal.open({
                templateUrl: 'app/entities/ops-change-log/ops-change-log-delete-dialog.html',
                controller: 'OpsChangeLogDeleteController',
                controllerAs: 'vm',
                // backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return text;
                    }]
                }
            }).result.then(function(result) {
                $rootScope.notBlInfo = null;
                if (text != result) {
                    vm.remarks = result;
                    OpsVender.saveRemarks({
                        venderId: venderId,
                        remarks: result
                    }, function(result){     
                        layer.msg($translate.instant('global.save_success'));
                    })
                }
            }, function() {});
        }

    }
})();
