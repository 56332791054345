(function () {
    'use strict';

    angular
        .module('autopsApp')
        .factory('CustomNodeSearch', CustomNodeSearch);

    CustomNodeSearch.$inject = ['$resource'];

    function CustomNodeSearch($resource) {
        var resourceUrl = 'api/_search/custom-nodes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true }
        });
    }
})();
