(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsRatesDeleteController', OpsRatesDeleteController);

    OpsRatesDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'OpsRates'];
    
	function OpsRatesDeleteController($scope, $uibModalInstance, entity, OpsRates) {

        $scope.opsRates = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            OpsRates.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }
})();
