(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('SkuBindDialogController', SkuBindDialogController);

    SkuBindDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', 
    'entity', 'SkuBind', 'AccountBind', 'WmsWarehouse'];

    function SkuBindDialogController ($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, 
        entity, SkuBind, AccountBind, WmsWarehouse) {
        var vm = this;

        vm.skuBind = entity;
        vm.clear = clear;
        vm.save = save;
        vm.clientSelected = clientSelected;
        vm.platFormSelected = platFormSelected;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.skuBind.id !== null) {
                SkuBind.update(vm.skuBind, onSaveSuccess, onSaveError);
            } else {
                SkuBind.save(vm.skuBind, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('autopsApp:skuBindUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function clientSelected (client) {
            vm.skuBind.venderId = client.id;
        }

        vm.accountBinds = [];
        function getAllAccountBind () {
            AccountBind.getAll(function(resultList){
                vm.accountBinds = resultList;
                vm.accountBinds.push({id:0,name:""});
            });
        }
        getAllAccountBind();

        vm.wmswarehouses = [];
        function getWarehouses () {
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false
                }, function (data) {
                vm.wmswarehouses = data;
            });
        }
        getWarehouses();

        function platFormSelected (id) {            
            vm.skuBind.platform = null;        
            vm.skuBind.bindName = null;           
            vm.skuBind.warehouseId = null;  
            vm.skuBind.venderCode = null;  
            vm.skuBind.warehouseCode = null;  
            vm.skuBind.autoUpdate = false;  
            angular.forEach(vm.accountBinds, function(data) {
                if (data.id == id){
                    vm.skuBind.platform = data.type;     
                    vm.skuBind.bindName = data.name;        
                }
            });
        }

    }
})();
