(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GuideRatesController', GuideRatesController);

    GuideRatesController.$inject = ['$scope', '$state', 'GuideRates', 'GuideRatesSearch'];
    
    function GuideRatesController($scope, $state, GuideRates, GuideRatesSearch) {

        $scope.guideRatess = [];
        $scope.loadAll = function() {
            GuideRates.query(function(result) {
               $scope.guideRatess = result;
            });
        };
        $scope.loadAll();


        $scope.search = function () {
            GuideRatesSearch.query({query: $scope.searchQuery}, function(result) {
                $scope.guideRatess = result;
            }, function(response) {
                if(response.status === 404) {
                    $scope.loadAll();
                }
            });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.guideRates = {
                guideId: null,
                item: null,
                currency: null,
                price: null,
                unit: null,
                count: null,
                venderId: null,
                total: null,
                actual: null,
                paymentTerm: null,
                inputTime: null,
                inputBy: null,
                invoiceNum: null,
                dnNum: null,
                remarks: null,
                locked: null,
                lastModifiedBy: null,
                lastModifiedTime: null,
                id: null
            };
        };
    }
})();
