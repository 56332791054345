(function() {
	'use strict';

	angular
		.module('autopsApp')
		.controller('ProfitController', ProfitController);

	ProfitController.$inject = ['$scope', '$rootScope', '$translate', '$state', '$uibModal', 'Principal', 'OpsPorts', 
	'Guide', 'DateUtils', 'OpsFiles', 'OpsVender', 'OpsCompany', 'OpsCarrier', 'User'];

	function ProfitController($scope, $rootScope, $translate, $state, $uibModal, Principal, OpsPorts, 
		Guide, DateUtils, OpsFiles, OpsVender, OpsCompany, OpsCarrier, User) {
		
		$scope.companys = [];
		$scope.statParams = {};
		$scope.statParams.statType = "month";
		$scope.statParams.statBy = "sales";
		$scope.statParams.month = new Date();

		$scope.loadCompanys = function () {
			if($scope.companys && $scope.companys.length > 0){
                return;
            }
			if($rootScope.account.isSuper && $rootScope.HAVEAUTH('FUN_VIEW_GROUP_BIZ')){
				OpsCompany.getGroupCompanys(function(result){
					$scope.companys = result;
				})
			}
		}

        $scope.setStatus= function(fd, value) {
            $scope.statParams[fd] = value;
        }     

		if (!$rootScope.account || angular.equals($rootScope.account, {})) {
			Principal.identity().then(function(account) {
				$rootScope.account = account;
			});
		}

        $scope.portList = [];
        var initPorts = function() {
            $scope.portList = $rootScope.OPSPORTS.seaports;
            angular.forEach($rootScope.OPSPORTS.airports, function(data){
                $scope.portList.push(data);
            });
        }
        $scope.loadPorts = function() {
            if ($rootScope.OPSPORTS.airports && $rootScope.OPSPORTS.airports.length > 0){
                return;
            }
            OpsPorts.getAll(function(result){
                $rootScope.OPSPORTS.airports = [];
                $rootScope.OPSPORTS.seaports = [];
                angular.forEach(result, function(data){
                    if(data.type == 1){
                        $rootScope.OPSPORTS.seaports.push(data);
                    }
                    else {
                        $rootScope.OPSPORTS.airports.push(data);
                    }
                })
                initPorts();
            })
        };

		//----------统计报表

		$scope.datePickerOpenStatus = {};

		$scope.yearFormat = "yyyy";
		$scope.monthFormat = "yyyy-MM";
		$scope.dayFormat = "yyyy-MM-dd";
		$scope.yearDatepickerOptions = {
			minMode: 'year'
		}
		$scope.monthDatepickerOptions = {
			minMode: 'month'
		}
		$scope.dayDatepickerOptions = {
			minMode: 'day'
		}
		$scope.datePickerOpenStatus.yearpicker = false;
		$scope.datePickerOpenStatus.monthpicker = false;
		$scope.datePickerOpenStatus.daypicker = false;
		$scope.datePickerOpenStatus.dateFromPicker = false;
		$scope.datePickerOpenStatus.dateToPicker = false;

		$scope.openCalendar = function(date) {
			$scope.datePickerOpenStatus[date] = true;
		}

		
		$scope.isSaving = false;
		var getStatParams = function() {
			$scope.statParams.etdFM = "";
			$scope.statParams.etdTO = "";
			$scope.statParams.fileName = "";
			if ($scope.statParams.statType == "irregular") {
				$scope.statParams.etdFM = $scope.statParams.etdFM_;
				$scope.statParams.etdTO = $scope.statParams.etdTO_;
				$scope.statParams.fileName = $scope.statParams.etdFM_ + "_" + $scope.statParams.etdTO_ + "利润统计";
			}
			if ($scope.statParams.statType == "year") {
				var year = $scope.statParams.year.getFullYear(); //取当前的年份          
				$scope.statParams.etdFM = new Date(year, 0, 1);
				$scope.statParams.etdTO = new Date(year, 11, 31);
				$scope.statParams.fileName = $scope.statParams.year + "年利润统计";
			}
			if ($scope.statParams.statType == "month") {
				var year = $scope.statParams.month.getFullYear(); //取当前的年份          
				var month = $scope.statParams.month.getMonth();
				if (month == 11) {
					month = month -12;
					year++;
				}
				$scope.statParams.etdFM = new Date(year, month, 1);
				$scope.statParams.etdTO = new Date(year, month+1, 0);
				$scope.statParams.fileName = $scope.statParams.month + "利润统计";
			}
			if ($scope.statParams.statType == "day") {
				$scope.statParams.etdFM = $scope.statParams.day;
				$scope.statParams.etdTO = $scope.statParams.day;
				$scope.statParams.fileName = $scope.statParams.day + "利润统计";
			}
			if (!$scope.statParams.agentName){
				$scope.statParams.deliveryId = null;
			}
			if (!$scope.statParams.coloaderName){
				$scope.statParams.bookingToId = null;	
			}
			if (!$scope.statParams.clientName){
				$scope.statParams.shipperId = null;
			}
			if (!$scope.statParams.customerName){
				$scope.statParams.customerId = null;
			}
			if (!$scope.statParams.trucker){
				$scope.statParams.truckerId = null;
			}
			if (!$scope.statParams.broker){
				$scope.statParams.brokerId = null;
			}
			if (!$scope.statParams.payer){
				$scope.statParams.payerVid = null;
			}
			if($scope.statParams.marketList && $scope.statParams.marketList.length > 0){
				$scope.statParams.marketIds = [];
				angular.forEach($scope.statParams.marketList, function(data){
					$scope.statParams.marketIds.push(data.id);
				})
			}
			else {
				$scope.statParams.marketIds = null;
			}
			
			if($scope.statParams.finList && $scope.statParams.finList.length > 0){
				$scope.statParams.finIds = [];
				angular.forEach($scope.statParams.finList, function(data){
					$scope.statParams.finIds.push(data.id);
				})
			}
			else {
				$scope.statParams.finIds = null;
			}

			if($scope.statParams.docList && $scope.statParams.docList.length > 0){
				$scope.statParams.docIds = [];
				angular.forEach($scope.statParams.docList, function(data){
					$scope.statParams.docIds.push(data.id);
				})
			}
			else {
				$scope.statParams.docIds = null;
			}

			if($scope.statParams.opList && $scope.statParams.opList.length > 0){
				$scope.statParams.opIds = [];
				angular.forEach($scope.statParams.opList, function(data){
					$scope.statParams.opIds.push(data.id);
				})
			}
			else {
				$scope.statParams.opIds = null;
			}

			if($scope.statParams.csList && $scope.statParams.csList.length > 0){
				$scope.statParams.cusIds = [];
				angular.forEach($scope.statParams.csList, function(data){
					$scope.statParams.cusIds.push(data.id);
				})
			}
			else {
				$scope.statParams.cusIds = null;
			}

			if($scope.statParams.salesList && $scope.statParams.salesList.length > 0){
				$scope.statParams.salesIds = [];
				angular.forEach($scope.statParams.salesList, function(data){
					$scope.statParams.salesIds.push(data.id);
				})
			}
			else {
				$scope.statParams.salesIds = null;
			}

			if($scope.companyList && $scope.companyList.length > 0){
				$scope.statParams.companyIds = [];
				angular.forEach($scope.companyList, function(data){
					if($scope.statParams.companyIds.indexOf(data.id) == -1){
						$scope.statParams.companyIds.push(data.id);
					}					
				})
			}
			else {
				$scope.statParams.companyIds = null;
			}

			if($scope.statParams.polAreas && $scope.statParams.polAreas.length > 0){
                $scope.statParams.polRegions = [];
                angular.forEach($scope.statParams.polAreas, function(data){
                    $scope.statParams.polRegions.push(data.id);
                })
            }
            else {
                $scope.statParams.podRegions = null;
            }
            
            if($scope.statParams.podAreas && $scope.statParams.podAreas.length > 0){
                $scope.statParams.podRegions = [];
                angular.forEach($scope.statParams.podAreas, function(data){
                    $scope.statParams.podRegions.push(data.id);
                })
            }
            else {
                $scope.statParams.podRegions = null;
            }
		}
		$scope.reportavailalbe = false;
		$scope.isJobNumStats = false;
		$scope.statsResult = {};
		$scope.getContents = function() {
			$scope.isSaving = true;
			var loadIndex = layer.load(1, {shade: 0.3});  
			getStatParams();
			if ($scope.statParams.statBy == "jobNum"){
				$scope.reportavailalbe = false;
				$scope.isJobNumStats = true;
				OpsFiles.statsByJobNum($scope.statParams, function(result) {
					$scope.isSaving = false;					
					layer.close(loadIndex);  
					if (!result.guides || result.guides.length == 0) {
						initContent();
						layer.msg($translate.instant('stats.stat.noData'));
						return;
					}
	                if (result.guides.length > 500) {
	                    layer.confirm($translate.instant('global.dataSizeAlert', {amt: result.guides.length}), {
	                        btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
	                    }, function(index) {
	                        $scope.excelExport(result);
	                        layer.close(index);
	                    }, function() {
	                        $scope.statsResult = result;
							initNums();
							$scope.reportavailalbe = true;
	                    });
	                }
	                else {
                        $scope.statsResult = result;
						initNums();
						$scope.reportavailalbe = true;
	                } 
				}, function(response) {
					$scope.isSaving = false;
					$rootScope.ALERT_ERROR(response);
				});
			}
			else {
				$scope.isJobNumStats = false;
				OpsFiles.statsPerformance($scope.statParams, function(result) {
					$scope.isSaving = false;
					layer.close(loadIndex);  
					$scope.statsResult = result;
					if (!$scope.statsResult.profitStatByUserDTOs || $scope.statsResult.profitStatByUserDTOs.length == 0) {
						initContent();
						layer.msg($translate.instant('stats.stat.noData'));
						return;
					}
					initNums();
					$scope.reportavailalbe = true;
				}, function(response) {
					$scope.isSaving = false;
					$rootScope.ALERT_ERROR(response);
				});
			}

		}

		var initContent = function() {
			$scope.statsResult.guides = [];
			for (var j = 0; j <= 20; j++) {
				$scope.statsResult.guides.push({});
			}

			$scope.statsResult.profitStatByUserDTOs = [];
			for (var j = 0; j <= 20; j++) {
				$scope.statsResult.profitStatByUserDTOs.push({});
			}
			// setTimeout(function() {frzTable("divTable", "showTable", null);}, 100);   
		}
		initContent();
		$scope.currencyTtl = 0;
		var initNums = function() {
			$scope.currencyTtl = $scope.statsResult.currencys.length;
			$scope.currencys = $scope.statsResult.currencys;
			$scope.statsResult.gw = $scope.statsResult.gw.toFixed(2) -0;
			$scope.statsResult.vol = $scope.statsResult.vol.toFixed(3) -0;
			$scope.statsResult.chargeWeightKg = $scope.statsResult.chargeWeightKg.toFixed(2) -0;
			$scope.statsResult.chargeWeightCbm = $scope.statsResult.chargeWeightCbm.toFixed(2) -0;
			angular.forEach($scope.statsResult.profitStatByJobNumDTOs, function(data){
				data.gw = data.gw.toFixed(2) -0;
				data.vol = data.vol.toFixed(3) -0;
				data.chargeWeightKg = data.chargeWeightKg.toFixed(2) -0;			
			})
			angular.forEach($scope.statsResult.guides, function(data){
                if(data.polRegion){
                    data.polRegion = $translate.instant('global.lanes.' + data.polRegion);
                }
                if(data.podRegion){
                    data.podRegion = $translate.instant('global.lanes.' + data.podRegion);
                }   				
			})
			angular.forEach($scope.statsResult.profitStatByUserDTOs, function(data){
				data.gw = data.gw.toFixed(2) -0;
				data.vol = data.vol.toFixed(3) -0;
				data.chargeWeightKg = data.chargeWeightKg.toFixed(2) -0;
				data.chargeWeightCbm = data.chargeWeightCbm.toFixed(2) -0;
			})
			$translate.refresh();

			// setTimeout(function() {frzTable("divTable", "showTable", null);}, 100);   
		}
		$scope.exportReport = function(format) {
			// layer.msg($translate.instant('global.developingAlert'));
			// return;
			$scope.statParams.fileType = format;
			$scope.statParams.fileName = "";
			$scope.fileName_btn = layer.open({
				type: 1,
				area: ['300px', '180px'],
				btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')],
				title: $translate.instant('global.fileName'),
				shade: 0.3,
				moveType: 0,
				shift: 0,
				content: $("#templateReportStatName"),
				success: function(layero, index) {
					$("#statFileName").focus();
				},
				yes: function(index, layero) {
					if ($scope.statParams.fileName == '' || angular.isUndefined($scope.statParams.fileName)) {
						$scope.statParams.fileName = 'Stat';
					}
					$scope.getStatFile();
					layer.close($scope.fileName_btn);
				},
				no: function(index, layero) {
					layer.close(index);
				}
			});


		}

		$("#statFileName").keydown(function(event) {
			$scope.keycode = window.event ? event.keyCode : event.which;
			if ($scope.keycode == 13) {
				if ($scope.statParams.fileName == '' || angular.isUndefined($scope.statParams.fileName)) {
					$scope.statParams.fileName = 'Stat';
				}
				$scope.getStatFile();
				layer.close($scope.fileName_btn);
			}
		})
		$scope.getStatFile = function() {
			$scope.statParams.opType = false;
			var params = $scope.statsResult;
			if($scope.statParams.statBy == "jobNum"){
				params.profitStatByJobNumDTOs = $scope.statsResult.guides;
			}
			var dateStr = "";
			if ($scope.statParams.statType == 'year'){
				var dateTemp = DateUtils.convertLocalDateToServer($scope.statParams.year);
				dateStr = dateTemp.substring(0, 4) 

			}
			else if ($scope.statParams.statType == 'month'){
				var dateTemp = DateUtils.convertLocalDateToServer($scope.statParams.month);
				dateStr = dateTemp.substring(0, 7) 
			}
			else if ($scope.statParams.statType == 'day'){
				dateStr = DateUtils.convertLocalDateToServer($scope.statParams.day);
			}
			else if ($scope.statParams.statType == 'irregular'){
				dateStr = DateUtils.convertLocalDateToServer($scope.statParams.etdFM_);
				dateStr = dateStr + " - " + DateUtils.convertLocalDateToServer($scope.statParams.etdTO_);
			}

			OpsFiles.statProfitFile({
					year: dateStr,
					statBy:$scope.statParams.statBy,
					statByName:$scope.statByName,
					fileType:$scope.statParams.fileType
			}, params, function(result) {
				$scope.exportExcelOrPdf(result);
			}, function(error) {
				layer.msg($translate.instant('stats.stat.noData'));
			});
		}

		$scope.incomeList = function() {
			$scope.isSaving = true;
			getStatParams();
			OpsFiles.incomeList($scope.statParams, 
			function(result) {
				$scope.isSaving = false;
				$scope.statParams.fileType = 2;
				$scope.statParams.fileName = "incomeList";
				$scope.exportExcelOrPdf(result);
			}, function(response) {
				$scope.isSaving = false;
			});
		}

		$scope.exportExcelOrPdf = function(result) {
			if (angular.isDefined($scope.statParams.fileType) && $scope.statParams.fileType == 2) {
				var file = new Blob([result.data], {
					type: 'application/vnd.ms-excel'
				});
				var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
				var aTag = document.createElement("a");
				aTag.download = $scope.statParams.fileName + ".xlsx";
				aTag.href = blobURL;
				aTag.click();
			} else {
                $rootScope.PRINTBYTES(result.data);
            }
		}

		$scope.statParams.filterRequired = false;
		$scope.filterCheckBoxControl = function() {
			if (!$scope.statParams.filterRequired) {
				$scope.statParams.filterRequired = false;
				var paramCopy = angular.copy($scope.statParams);
	            $scope.statParams = {};
	            $scope.statParams.statType = paramCopy.statType;
	            $scope.statParams.month = paramCopy.month;
	            $scope.statParams.dateType = paramCopy.dateType;
	            $scope.statParams.statBy = paramCopy.statBy;
	            $scope.statParams.year = paramCopy.year;
	            $scope.statParams.day = paramCopy.day;
	            $scope.statParams.etdFM_ = paramCopy.etdFM_;
	            $scope.statParams.etdTO_ = paramCopy.etdTO_;
	            $scope.statParams.company = paramCopy.company;
			}
		}

		$scope.statParams.showBasic = true;
		$scope.statParams.showProfit = true;
		$scope.statParams.showVolume = true;
		$scope.statParams.showRoute = true;
		$scope.showChange = function() {
			// setTimeout(function() {frzTable("divTable", "showTable", null);}, 100); 
		}
        $scope.opFilter = function(item) {
            if (item.authorities.indexOf("ROLE_OPERATOR") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_ALL") !== -1) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_OPERATOR") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_BRANCH") !== -1
                && item.branch == $rootScope.account.branch) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_OPERATOR") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_TEAM") !== -1
                && item.team == $rootScope.account.team) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_OPERATOR") !== -1 
                && item.id == $rootScope.account.id) {
                return true;
            }
            return false;
        };

        $scope.docFilter = function(item) {
            if (item.authorities.indexOf("ROLE_DOCUMENT") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_ALL") !== -1) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_DOCUMENT") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_BRANCH") !== -1
                && item.branch == $rootScope.account.branch) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_DOCUMENT") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_TEAM") !== -1
                && item.team == $rootScope.account.team) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_DOCUMENT") !== -1 
                && item.id == $rootScope.account.id) {
                return true;
            }
            return false;
        };

        $scope.salesFilter = function(item) {
            if (item.authorities.indexOf("ROLE_SALES") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_ALL") !== -1) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_SALES") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_BRANCH") !== -1
                && item.branch == $rootScope.account.branch) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_SALES") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_TEAM") !== -1
                && item.team == $rootScope.account.team) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_SALES") !== -1 
                && item.id == $rootScope.account.id) {
                return true;
            }
            return false;
        };

        $scope.csFilter = function(item) {
            if (item.authorities.indexOf("ROLE_CS") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_ALL") !== -1) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_CS") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_BRANCH") !== -1
                && item.branch == $rootScope.account.branch) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_CS") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_TEAM") !== -1
                && item.team == $rootScope.account.team) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_CS") !== -1 
                && item.id == $rootScope.account.id) {
                return true;
            }
            return false;
        };

        $scope.marketFilter = function(item) {      
            if (item.authorities.indexOf("ROLE_MARKET") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_ALL") !== -1) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_MARKET") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_BRANCH") !== -1
                && item.branch == $rootScope.account.branch) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_MARKET") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_TEAM") !== -1
                && item.team == $rootScope.account.team) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_MARKET") !== -1 
                && item.id == $rootScope.account.id) {
                return true;
            }
            return false;
        };

        $scope.finFilter = function(item) {
            if (item.authorities.indexOf("ROLE_ACCOUNT") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_ALL") !== -1) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_ACCOUNT") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_BRANCH") !== -1
                && item.branch == $rootScope.account.branch) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_ACCOUNT") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_TEAM") !== -1
                && item.team == $rootScope.account.team) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_ACCOUNT") !== -1 
                && item.id == $rootScope.account.id) {
                return true;
            }
            return false;
        };


    	$scope.contracts = [];
		$scope.getallcontract= function() {
			if ($scope.contracts && $scope.contracts.length > 0) {
				return;
			};
            Guide.getallcontract(function(result) {
				$scope.contracts = result;
			});
		};
        $scope.lanes = []; 
        $scope.shipmentTypeChange = function(type) {
            $scope.lanes = []; 
            if(!type){
                $scope.lanes = angular.copy($rootScope.sealanes);
                angular.forEach($rootScope.airlanes, function(data){
                    if(data.id.indexOf("Zone") == -1){
                        $scope.lanes.push(data);
                    }                    
                })
            }
            else if (type == "AIR") {
                $scope.lanes = angular.copy($rootScope.airlanes);
            } else {
                $scope.lanes = angular.copy($rootScope.sealanes);
            }
        };
        $scope.shipmentTypeChange($scope.statParams.shipmentType);

        $scope.polRegionSelect = function(type) {
            if(!$scope.statParams.polAreas){
                $scope.statParams.polAreas = [];
            }
            angular.forEach($scope.lanes, function(data){
                if(data.id == type){
                    $scope.statParams.polAreas.push(data);
                }
            })        
            $scope.statParams.polRegion = "";    
        }
        $scope.podRegionSelect = function(type) {
            if(!$scope.statParams.podAreas){
                $scope.statParams.podAreas = [];
            }
            angular.forEach($scope.lanes, function(data){
                if(data.id == type){
                    $scope.statParams.podAreas.push(data);
                }
            })     
            $scope.statParams.podRegion = "";
        }
        $scope.delPolArea = function(index) {
            $scope.statParams.polAreas.splice(index, 1);
        }
        $scope.delPodArea = function(index) {
            $scope.statParams.podAreas.splice(index, 1);
        }
        $scope.delMblServiceTerm = function(index) {
            $scope.statParams.mblServiceTerms.splice(index, 1);
        }
        $scope.delHblServiceTerm = function(index) {
            $scope.statParams.hblServiceTerms.splice(index, 1);
        }
        $scope.mblServiceTermChange = function(data) {
            if(!$scope.statParams.mblServiceTerms){
                $scope.statParams.mblServiceTerms = [];
            }
            $scope.statParams.mblServiceTerms.push(data); 
            $scope.statParams.mblServiceTerm = "";
        }



        $scope.hblServiceTermChange = function(data) {
            if(!$scope.statParams.hblServiceTerms){
                $scope.statParams.hblServiceTerms = [];
            }
            $scope.statParams.hblServiceTerms.push(data);
            $scope.statParams.hblServiceTerm = "";
        }

		$scope.statByName = "jobNum"
		$scope.statByChange = function(statBY) {
			$scope.statByName = ": "
			$scope.statsResult = {};
			initContent();
			if (statBY=="jobNum"){
				$scope.isJobNumStats = true;
				$scope.statByName = $scope.statByName + $translate.instant('stats.stat.jobNum');
			}
			else if (statBY=="client"){
				$scope.isJobNumStats = false;
				$scope.statByName = $scope.statByName + $translate.instant('stats.stat.filterByClient');
			}
			else if (statBY=="agent"){
				$scope.isJobNumStats = false;
				$scope.statByName = $scope.statByName + $translate.instant('stats.stat.bagent');
			}
			else if (statBY=="sales"){
				$scope.isJobNumStats = false;
				$scope.statByName = $scope.statByName + $translate.instant('stats.stat.bsales');
			}
			else if (statBY=="operator"){
				$scope.isJobNumStats = false;
				$scope.statByName = $scope.statByName + $translate.instant('stats.stat.boperator');
			}
			else if (statBY=="document"){
				$scope.isJobNumStats = false;
				$scope.statByName = $scope.statByName + $translate.instant('stats.stat.bdocument');
			}
			else if (statBY=="account"){
				$scope.isJobNumStats = false;
				$scope.statByName = $scope.statByName + $translate.instant('stats.stat.baccount');
			}
			else if (statBY=="market"){
				$scope.isJobNumStats = false;
				$scope.statByName = $scope.statByName + $translate.instant('stats.stat.bmarket');
			}
		};
		if ($rootScope.id && $rootScope.id.opsCrms){
			$scope.statParams.statBy = "jobNum";
			$scope.statParams.month = new Date();
			$scope.statParams.filterBy = "filterByClient";
			$scope.statParams.clientName = $rootScope.id.companyName;
			$scope.statParams.shipperId = $rootScope.id.id;
			$scope.statParams.statType = "month";
			$scope.statParams.filterRequired = true;
			$translate.refresh();
		}

        $scope._onClientSelected = function(data) {
        	$scope.statParams.shipperId = data.id;
        }
        $scope._onCustomerSelected = function(data) {
        	$scope.statParams.customerId = data.id;
        }
        $scope._onColoaderSelected = function(data) {
        	$scope.statParams.bookingToId = data.id;
        }
        $scope._onAgentSelected = function(data) {
        	$scope.statParams.deliveryId = data.id;
        }
        $scope._onTruckerSelected = function(data) {
        	$scope.statParams.truckerId = data.id;
        }
        $scope._onBrokerSelected = function(data) {
        	$scope.statParams.brokerId = data.id;
        }
        $scope._onPayerSelected = function(data) {
        	$scope.statParams.payerVid = data.id;
        }
        var isReverse = false;
        $scope.sortByTitle = function(title) {
        	isReverse = !isReverse;
    		$scope.statsResult.guides.sort(function(a,b){
    			if (isReverse){
    				return a[title]<b[title]?1:-1;
    			}
    			else {
    				return a[title]>b[title]?1:-1;
    			}        			
    		});
    		// setTimeout(function() {frzTable("divTable", "showTable", null);}, 100);  
        }

        $scope.sortNameByTitle = function(title) {
        	isReverse = !isReverse;
    		$scope.statsResult.profitStatByUserDTOs.sort(function(a,b){
    			if (isReverse){
    				return a[title]<b[title]?1:-1;
    			}
    			else {
    				return a[title]>b[title]?1:-1;
    			}        			
    		});
    		// setTimeout(function() {frzTable("divTable", "showTable", null);}, 100);  
        }

        $scope.showAllGuides = function(content) {
            if (content.guides.length > 500) {
                layer.confirm($translate.instant('global.dataSizeAlert', {amt: content.guides.length}), {
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    $scope.excelExport(content);
                    layer.close(index);
                }, function() {
                    guidesViewPop(content);
                });
            }
            else {
                guidesViewPop(content);
            } 
        }
        function guidesViewPop (content) {
        	var entity = {};
        	entity.content = content;
        	entity.statParams = $scope.statParams;
            $uibModal.open({
                templateUrl: 'app/entities/stats/profit/job-list-dialog.html',
                controller: 'JobListDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }

        $scope.excelExport = function(userResult) {
            if(!userResult || !userResult.guides || userResult.guides.length == 0){
                layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                return;
            }
            
            var excelDTO = angular.copy(userResult);
            var title = $translate.instant('global.menu.admin.profitStats');
            var fileName = title + ".xlsx";
            title = title + "(From " + DateUtils.convertLocalDateToServer($scope.statParams.etdFM) + " To " + DateUtils.convertLocalDateToServer($scope.statParams.etdTO) + ")";
            OpsFiles.excelProfitStatByUserDTO({
                title: title,
                enCn: $translate.instant('global.enCn')
            }, excelDTO, function(result) {
            	$rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }    

        $scope.statParams.salesList = [];
        $scope.statParams.marketList = [];
        $scope.statParams.finList = [];
        $scope.statParams.docList = [];
        $scope.statParams.opList = [];
        $scope.statParams.csList = [];
        
        $scope._onClerkSelected = function(fd, item) {
            if(fd == "salesId"){
            	$scope.statParams.salesList.push(item);
            }
            else if(fd == "marketId"){
            	$scope.statParams.marketList.push(item);
            }
            else if(fd == "finId"){
            	$scope.statParams.finList.push(item);
            }
            else if(fd == "docId"){
            	$scope.statParams.docList.push(item);
            }
            else if(fd == "opId"){
            	$scope.statParams.opList.push(item);
            }
            else if(fd == "cusId"){
            	$scope.statParams.csList.push(item);
            }
            $scope.statParams[fd] = "";
        };
        $scope.delSales = function(index) {
	        $scope.statParams.salesList.splice(index, 1);
        };
        $scope.delMarket = function(index) {
	        $scope.statParams.marketList.splice(index, 1);
        };
        $scope.delFin = function(index) {
	        $scope.statParams.finList.splice(index, 1);
        };
        $scope.delDoc = function(index) {
	        $scope.statParams.docList.splice(index, 1);
        };
        $scope.delOp = function(index) {
	        $scope.statParams.opList.splice(index, 1);
        };
        $scope.delCs = function(index) {
	        $scope.statParams.csList.splice(index, 1);
        };

        $scope.companyList = [];        
        $scope._onCompanySelected = function(company) {
        	if(company.id == 0){
        		$scope.companyList = [];
        		angular.forEach($scope.companys, function(data){
        			if(data.id != 0){
        				$scope.companyList.push(data); 
        			}	        		
	        	})  
	        	return;
        	}
        	angular.forEach($scope.companys, function(data){
        		if(data.id == company.id){
        			var isNew = true;
        			angular.forEach($scope.companyList, function(data1){
        				if(data1.id == data.id){
        					isNew = false;
        				}
        			})
        			if(isNew){
        				$scope.companyList.push(data);
        			}        			
        		}
        	})       
        	$scope.statParams.company = "";     
        };
        $scope.delCompany = function(index) {
	        $scope.companyList.splice(index, 1);
        };



	}
})();