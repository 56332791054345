(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', '$rootScope', '$location', '$uibModal', '$state', '$http', '$compile', '$stateParams',
        'Principal', 'LoginService', 'OpsFiles', '$translate', 'VERSION', 'WinAES',
        'translationHandler', 'OpsVender', 'AccountBind', 'JhiLanguageService', 'ParseLinks'];

    function HomeController($scope, $rootScope, $location, $uibModal, $state, $http, $compile, $stateParams,
        Principal, LoginService, OpsFiles, $translate, VERSION, WinAES,
        translationHandler, OpsVender, AccountBind, JhiLanguageService, ParseLinks) {

        $scope.systemName = "";
        var url = $location.absUrl();
        var chartParams = [];
        // $scope.DashBoardArr=[];
        if ($rootScope.VERSION.indexOf("Demo") != -1 ||
            url.indexOf("https://au-ops.com") != -1 ||
            url.indexOf("icargo.vip") != -1 ||
            url.indexOf("www.i-ops.vip") != -1 ||
            // url.indexOf("127.0.0.1") != -1 ||
            url.indexOf("//i-ops.vip") != -1 ||
            url.indexOf("iops.vip") != -1 ||
            url.indexOf("cargosyn.com") != -1 ||
            url.indexOf("https://www.au-ops.com") != -1) {
            // $rootScope.ISDEMO = true;
            $scope.systemName = "乐代";
        } else {
            $rootScope.ISDEMO = false;
            $scope.systemName = "";
        }
        try {
            if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
                $rootScope.IS_MOB = true;
            }
        } catch (e) {}

        if (url.indexOf("spapi_oauth_code") !== -1) {
            layer.load(1, { shade: 0.3 });
            var idx = url.indexOf("?");
            var urlParam = url.substring(idx);
            if (urlParam.indexOf("#/") == (urlParam.length - 2)) {
                var newUrl = url.substring(0, idx) + "#/" + urlParam.substring(0, urlParam.length - 2);
                window.open(newUrl, "_self");
            } else {
                var stateStr = $location.search().state;
                idx = stateStr.indexOf("-");
                var protocol = stateStr.substring(0, idx);
                stateStr = stateStr.substring(idx + 1);
                idx = stateStr.indexOf("_");
                if (stateStr.indexOf("127.0.0.1") !== -1) {
                    if (stateStr.indexOf("amazonshipping") !== -1) {
                        var newUrl = protocol + "://" + stateStr.substring(0, idx) + ":8080/#/label-edi/sp-api-auth" + urlParam;
                        window.open(newUrl, "_self");
                    } else {
                        var newUrl = protocol + "://" + stateStr.substring(0, idx) + ":8080/#/account-bind/sp-api-auth" + urlParam;
                        window.open(newUrl, "_self");
                    }
                } else {
                    if (stateStr.indexOf("amazonshipping") !== -1) {
                        var newUrl = protocol + "://" + stateStr.substring(0, idx) + "/#/label-edi/sp-api-auth" + urlParam;
                        window.open(newUrl, "_self");
                    } else {
                        var newUrl = protocol + "://" + stateStr.substring(0, idx) + "/#/account-bind/sp-api-auth" + urlParam;
                        window.open(newUrl, "_self");
                    }
                }
            }
        } else if (url.indexOf("accessToken") !== -1) {
            layer.load(1, { shade: 0.3 });
            var idx = url.indexOf("?");
            var urlParam = url.substring(idx);
            if (urlParam.indexOf("#/") == (urlParam.length - 2)) {
                var newUrl = url.substring(0, idx) + "#/account-bind/mb-wms-auth" + urlParam.substring(0, urlParam.length - 2);
                window.open(newUrl, "_self");
            }
        } else if (url.indexOf("TikTokShopAuth") !== -1) {
            layer.load(1, { shade: 0.3 });
            var idx = url.indexOf("?");
            var urlParam = url.substring(idx);
            if (urlParam.indexOf("#/") == (urlParam.length - 2)) {
                var newUrl = url.substring(0, idx) + "#/" + urlParam.substring(0, urlParam.length - 2);
                window.open(newUrl, "_self");
            } else {
                var stateStr = $location.search().state;
                idx = stateStr.indexOf("-");
                var protocol = stateStr.substring(0, idx);
                stateStr = stateStr.substring(idx + 1);
                idx = stateStr.indexOf("_");
                if (stateStr.indexOf("127.0.0.1") !== -1) {
                    var newUrl = protocol + "://" + stateStr.substring(0, idx) + ":8080/#/account-bind/tiktok-shop-auth" + urlParam;
                    window.open(newUrl, "_self");
                } else {
                    var newUrl = protocol + "://" + stateStr.substring(0, idx) + "/#/account-bind/tiktok-shop-auth" + urlParam;
                    window.open(newUrl, "_self");
                }
            }
        } else if (url.indexOf("UPSAuth") !== -1) {
            layer.load(1, { shade: 0.3 });
            var idx = url.indexOf("?");
            var urlParam = url.substring(idx);
            if (urlParam.indexOf("#/") == (urlParam.length - 2)) {
                var newUrl = url.substring(0, idx) + "#/" + urlParam.substring(0, urlParam.length - 2);
                window.open(newUrl, "_self");
            } else {
                var stateStr = $location.search().state;
                idx = stateStr.indexOf("-");
                var protocol = stateStr.substring(0, idx);
                stateStr = stateStr.substring(idx + 1);
                idx = stateStr.indexOf("_");
                if (stateStr.indexOf("127.0.0.1") !== -1) {
                    var newUrl = protocol + "://" + stateStr.substring(0, idx) + ":8080/#/label-edi/ups-auth" + urlParam;
                    window.open(newUrl, "_self");
                } else {
                    var newUrl = protocol + "://" + stateStr.substring(0, idx) + "/#/label-edi/ups-auth" + urlParam;
                    window.open(newUrl, "_self");
                }
            }
        } else if (url.indexOf("redirectLink") !== -1) {
            // var redirectLink = $location.search().redirectLink;
            var redirectLink = "http://127.0.0.1:8080/#/clients?ticket=xxx";
            console.log("redirectLink = " + redirectLink);
            // redirectLink = decodeURIComponent(redirectLink);
            var idx = redirectLink.indexOf("ticket=");
            var ticket = redirectLink.substring(idx + 7);
            console.log("ticket = " + ticket);
            AccountBind.tokenInfoPush({
                platform: "TEMUCW", 
                ticket: ticket
            }, function(){
                window.open(redirectLink, "_self");
            }, $rootScope.ALERT_ERROR);
            return;
        }

        if ($rootScope.account && !$rootScope.account.companyAbb && !$rootScope.account.companyLogo) {
            $rootScope.LOAD_PIC_LOGO(false);
        }
        var vm = this;
        vm.loadPage = loadPage;
        vm.transition = transition;
        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        vm.addNews = addNews;
        vm.tempNews = null;
        vm.deleteNotice = deleteNotice;
        vm.deleteAlert = deleteAlert;
        vm.clearAllAlerts = clearAllAlerts;
        vm.editNews = editNews; //edit New event
        vm.commonFiles = []; //list
        vm.loadFiles = loadFiles;
        vm.clearFiles = clearFiles;
        vm.uploadCommonFile = uploadCommonFile;
        vm.deleteCommonFile = deleteCommonFile;
        vm.goGuide = goGuide;
        vm.shareFile = shareFile;
        vm.getNews = getNews;
        vm.clearNews = clearNews;
        vm.stateGo = stateGo;
        vm.getChartTips = getChartTips;

        vm.getNotices = getNotices;
        vm.clearNotices = clearNotices;
        vm.viewNotice = viewNotice;

        var lang = "";
        function getAccount() {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if ($rootScope.account) {
                    if ($rootScope.account.id && $rootScope.account.authorities.length > 0) {
                        JhiLanguageService.getCurrent().then(function(language) {
                            lang = language;
                            getCharts("bar", 12, 'volumeTtl', false, null);
                            getCharts("line", 12, 'DCN', false, null);
                            getCharts(null, null, 'TASK', null, null);
                        })
                    };
                    getNews();
                    getNotices();
                    loadFiles();
                }
            });
        }

        function clearNews() {
            vm.newsList = [];
        }

        function clearFiles() {
            vm.commonFiles = [];
        }

        function clearNotices() {
            vm.noticesList = [];
        }

        getAccount();
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        function getLogo(id) {
            OpsFiles.getCompanyLogo({
                companyId: id
            }, function(result) {
                if (!$rootScope.account) {
                    $rootScope.account = {};
                }
                if (result.logo) {
                    $rootScope.account.companyLogo = result.logo;
                }
            })
        }

        // vm.newsCount = {};

        // function getNewsCount() {
        //     OpsFiles.getNewsCount(function(result) {
        //         vm.newsCount = result;
        //     })
        // }

        vm.noticesList = [];
        vm.noticesPage = 1;
        vm.noticesTotalItems;
        vm.itemsPerPage = 10;
        function getNotices() {
            OpsFiles.getPageNotices({
                page: vm.noticesPage - 1,
                size: vm.itemsPerPage,
                sort: ['announcementTime' + ',' + 'desc']
            },function(result, headers) {
                vm.noticeslinks = ParseLinks.parse(headers('link'));
                vm.noticesTotalItems = headers('X-Total-Count');
                $translate.refresh();
                vm.noticesList = result;
            })
        }
        function loadPage (page) {
            vm.noticesPage = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.noticesPage,
                sort: ['announcementTime' + ',' + 'desc']
            });
        }
        vm.newsList = [];
        vm.newsPage = 1;
        vm.newsTotalItems;
        function getNews() {
            OpsFiles.getPageNews({
                page:vm.newsPage - 1,
                size: vm.itemsPerPage,
                sort: ['announcementTime' + ',' + 'desc']
            },function(result, headers) {
                vm.newslinks = ParseLinks.parse(headers('link'));
                vm.newsTotalItems = headers('X-Total-Count');
                $translate.refresh();
                vm.newsList = result;
            })
        }

        function deleteAlert(id) {
            OpsFiles.deleteNews({ id: id }, function(result) {
                layer.msg($translate.instant('global.delete_success'));
                getNews()
            }, function(error) {
                layer.msg($translate.instant('global.delete_fail'));
            });
        }

        function clearAllAlerts(id) {
            var alerts = angular.copy(vm.newsList);
            for (var i = alerts.length - 1; i >= 0; i--) {
                if (alerts[i].type == 1) {
                    alerts.splice(i, 1);
                }
            }
            if (null == alerts || alerts.length == 0) {
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            OpsFiles.clearAllAlerts({}, alerts, function(result) {
                layer.msg($translate.instant('global.delete_success'));
                getNews()
            }, function(error) {
                layer.msg($translate.instant('global.delete_fail'));
            });
        }


        function deleteNotice(id) {
            if ($rootScope.HAVENOAUTH('FUN_ANNOUNCEMENT')) {
                $rootScope.FORBIDDEN_BY_ALERT('FUN_ANNOUNCEMENT');
                return;
            }
            if ($rootScope.ISDEMO && $rootScope.HAVENOAUTH('ROLE_BOSS')) {
                layer.msg($translate.instant('global.demoAlert'));
                return;
            }
            layer.confirm($translate.instant('global.confirmToDelete'), {
                btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')]
            }, function() {
                OpsFiles.deleteNews({ id: id }, function(result) {
                    layer.msg($translate.instant('global.delete_success'));
                    getNotices()
                }, function(error) {
                    layer.msg($translate.instant('global.delete_fail'));
                });
            });

        }

        function loadFiles() {
            OpsFiles.getCommonFiles(function(result) {
                vm.commonFiles = result;
            }, function(error) {
                layer.msg($translate.instant('home.getFileFailed'));
            });
        }

        function deleteCommonFile(filepath) {
            if ($rootScope.HAVENOAUTH('ROLE_BRANCH')) {
                layer.msg($translate.instant('global.forbiddenTeam'));
                return;
            }
            if ($rootScope.ISDEMO && $rootScope.HAVENOAUTH('ROLE_BOSS')) {
                layer.msg($translate.instant('global.demoAlert'));
                return;
            }
            layer.confirm($translate.instant('global.confirmToDelete'), {
                btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')]
            }, function() {
                OpsFiles.deleteCommonFiles({ filepath: filepath }, function(result) {
                    if (result) {
                        loadFiles();
                        layer.msg($translate.instant('global.delete_success'));
                    }
                }, function(error) {
                    layer.msg($translate.instant('global.delete_fail'));

                });
            });
        }

        function shareFile(filepath) {
            if ($rootScope.HAVENOAUTH('ROLE_BRANCH')) {
                layer.msg($translate.instant('global.forbiddenBranch'));
                return;
            }

            layer.confirm($translate.instant('global.shareFileAlert'), {
                btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')]
            }, function() {
                if (!$rootScope.account.isSuper) {
                    layer.msg($translate.instant('global.forbidden'));
                    return;
                }
                OpsFiles.shareFile({ filepath: filepath }, function(result) {
                    if (result) {
                        layer.msg($translate.instant('global.save_success'));
                    }
                }, function(error) {
                    layer.msg($translate.instant('global.save_fail'));
                });
            });

        }

        function uploadCommonFile() {
            if ($rootScope.HAVENOAUTH('ROLE_DEPARTMENT')) {
                layer.msg($translate.instant('global.forbiddenTeam'));
                return;
            }
            $("#commonFile").trigger('click');
        }


        function goGuide(type, id) {
            $rootScope.id = id;
            if (type == 2) {
                $rootScope.BIZPOP(id);
            } else if (type == 3) {
                $uibModal.open({
                    templateUrl: 'app/entities/opsClient/opsClient-detail.html',
                    controller: 'OpsClientDetailController',
                    backdrop: false,
                    size: 'xxl',
                    resolve: {
                        entity: [function() {
                            return OpsVender.getCrm({ id: id }).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('opsClient');
                            $translatePartialLoader.addPart('opsCrm');
                            $translatePartialLoader.addPart('stats');
                            $translatePartialLoader.addPart('accountInvoice');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function(result) {}, function() {});
            } else if (type == 9) {
                $rootScope.activeTab = 0;
                $uibModal.open({
                    templateUrl: 'app/entities/opsVender/opsVender-detail.html',
                    controller: 'OpsVenderDetailController',
                    backdrop: false,
                    size: 'xxl',
                    resolve: {
                        entity: [function() {
                            return OpsVender.get({ id: id }).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('opsClient');
                            $translatePartialLoader.addPart('opsCrm');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function(result) {}, function() {});
            } else if (type == 7) {
                $uibModal.open({
                    templateUrl: 'app/entities/prebooking/prebooking-dialog.html',
                    controller: 'PrebookingDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxl',
                    resolve: {
                        entity: ['Prebooking', function(Prebooking) {
                            return Prebooking.get({ id: id }).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('prebooking');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {}, function() {});
            } else if (type == 4 || type == 8) {
                $uibModal.open({
                    templateUrl: 'app/entities/write-off-rates/write-off-rates-dialog.html',
                    controller: 'WriteOffRatesDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: ['GuideRates', function(GuideRates) {
                            return GuideRates.getWrieteOffRatesByDnNum({
                                companyId: $rootScope.account.companyId,
                                invoiceId: type == 4 ? id : null,
                                paymentId: type == 8 ? id : null,
                            }).$promise;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('accountInvoice');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function(result) {
                }, function(result) {
                });
            } else if (type == 10) {
                $rootScope.id = id;
                $state.go('crm-report.edit');
            }
        }

        var i = 1;
        function getCharts(chartType, length, statModule, isChange, index) {
            var result;
            chartParams = [];
            var chartParam = {};
            chartParam.chartType = chartType;
            chartParam.statObj = "bl";
            chartParam.statStepUnit = "week";
            chartParam.length = length;
            chartParam.endDate = new Date();
            chartParam.endTime = new Date();
            if (statModule == "volumeTtl") {
                var moduleChart = angular.copy(chartParam);
                moduleChart.statModule = "CRM";
                moduleChart.chartType = "line";
                chartParams.push(moduleChart);

                if ($rootScope.account.authorities.indexOf("FMS") != -1) {
                    var moduleChart = angular.copy(chartParam);
                    moduleChart.statModule = "FMS";
                    chartParams.push(moduleChart);
                }
                if ($rootScope.account.authorities.indexOf("FBA") != -1) {
                    var moduleChart = angular.copy(chartParam);
                    moduleChart.statModule = "FBA";
                    chartParams.push(moduleChart);
                }
                if ($rootScope.account.authorities.indexOf("WMS") != -1) {
                    var moduleChart = angular.copy(chartParam);
                    moduleChart.statModule = "WMS";
                    chartParams.push(moduleChart);
                }
                if ($rootScope.account.authorities.indexOf("PARCEL") != -1) {
                    var moduleChart = angular.copy(chartParam);
                    moduleChart.statModule = "PARCEL";
                    chartParams.push(moduleChart);
                }
                if ($rootScope.account.authorities.indexOf("TMS") != -1) {
                    var moduleChart = angular.copy(chartParam);
                    moduleChart.statModule = "TMS";
                    chartParams.push(moduleChart);
                }
            } else {
                var moduleChart = angular.copy(chartParam);
                moduleChart.statModule = statModule;
                chartParams.push(moduleChart);
            }

            OpsFiles.getCharts({
                langEn: lang == 'en'
            }, chartParams, function(results) {
                if (!results || results.length == 0) {
                    return;
                }
                if (isChange) {
                    var chartFdId = 'chart_' + statModule;
                    angular.element(document.getElementById(chartFdId)).empty();
                    angular.element(document.getElementById(chartFdId)).append(lastWeeksOptions[length]);

                    if (statModule == "DCN") {
                        initDcnChart(results, true);
                    } else if (statModule == "volumeTtl") {
                        initVolumeTtlChart(results, true);
                    } else {
                        initLineChart(results, true, index);
                    }
                } else {
                    if (statModule == "DCN") {
                        initDcnChart(results, false);
                    }
                    else if (statModule == "TASK") {
                        vm.tasks = results;
                    }
                    else {
                        initLineChart(results, false, index);
                        initVolumeTtlChart(results, false);
                    }
                }
            })
        }
        $scope.changeWeeks = function(chartType, weeks, statModule, index) {
            getCharts(chartType, weeks, statModule, true, index)
        }
        var lastWeeksOptions = {
            12: $translate.instant('home.chart.last12'),
            24: $translate.instant('home.chart.last24'),
            36: $translate.instant('home.chart.last36'),
        }
        var lineChart = {};

        function initLineChart(charts, isChange, index) {
            var lineChartDiv = document.getElementById("lineChartDiv");
            if (!lineChartDiv) {
                return;
            }
            lineChart = !isChange ? {} : lineChart;
            angular.forEach(charts, function(chartData) {
                chartData.options['layout'] = {
                    padding: {
                        left: 10,
                        right: 10,
                        top: 10,
                        bottom: 3
                    }
                }
                chartData.options.tooltips = {
                    enabled: false,
                    mode: 'index',
                    intersect: false,
                    custom: function(tooltipModel) {
                        tooltipsCustom(tooltipModel, this)
                    }
                }
                chartData.options['maintainAspectRatio'] = false;
                chartData.options.scales.xAxes[0].display = false;
                chartData.options.scales.yAxes[0].display = false;
                chartData.options.scales.xAxes[0].stacked = false;
                chartData.options.scales.yAxes[0].stacked = false;

                chartData.options.plugins = {
                    legend: false
                };
                angular.forEach(chartData.data.datasets, function(val) {
                    val['pointRadius'] = 2;
                    val['borderWidth'] = 1;
                })
                if (!isChange) {
                    var lineColDiv = document.createElement("div")
                    lineColDiv.setAttribute("id", "DashBoardDiv" + i);
                    lineColDiv.setAttribute("ng-class","vm.winWidth <= 1400 ? 'col-md-4' : '' ");
                    var linePanelDiv = document.createElement("div")
                    linePanelDiv.setAttribute("class", "panel panel-default PanelDefault setOutSideStyle");
                    var lineHeadingDiv = document.createElement("div")
                    lineHeadingDiv.setAttribute("class", "panel-heading");
                    var totalShipmentsTitleDiv = document.createElement("div");
                    totalShipmentsTitleDiv.setAttribute("class", "row");

                    var lineTotalShipmentsTitle = document.createElement("strong");
                    lineTotalShipmentsTitle.setAttribute("class", "dashBoardTitle text-left col-xs-5");
                    lineTotalShipmentsTitle.innerHTML = chartData.chartTitle;
                    totalShipmentsTitleDiv.appendChild(lineTotalShipmentsTitle)
                    var totalShipmentsSelect = document.createElement("div");
                    totalShipmentsSelect.setAttribute("class", "text-right col-xs-7");
                    totalShipmentsSelect.setAttribute("id", "dropdownParents" + i);
                    totalShipmentsTitleDiv.appendChild(totalShipmentsSelect);
                    lineHeadingDiv.appendChild(totalShipmentsTitleDiv);
                    var totalShipmentsRow = document.createElement("div");
                    totalShipmentsRow.setAttribute("class", "totalShipments");
                    var totalShipmentCol8 = document.createElement("span");
                    totalShipmentCol8.setAttribute("class", "totalShipmentCol8");
                    var totalShipmentCol8Strong = document.createElement("strong");
                    totalShipmentCol8Strong.innerHTML = $rootScope.FORMAT_NUM(chartData.chartTitleNum);
                    totalShipmentCol8.appendChild(totalShipmentCol8Strong);
                    totalShipmentsRow.appendChild(totalShipmentCol8);
                    var totalShipmentCol4 = document.createElement("span");
                    totalShipmentCol4.setAttribute("class", "text-center totalShipmentCol4");
                    var totalShipmentCol4Span2 = document.createElement("strong");
                    if (chartData.isUp) {
                        totalShipmentCol4Span2.setAttribute("class", "f-14 text-green mr-5 ml-4");
                        totalShipmentCol4Span2.innerHTML = chartData.chartTitlePercent + '<svg t="1666082045534" class="icon ml-5 mb--3" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9942" width="20" height="20"><path d="M929.408 202.752l-205.269333 24.661333a8.234667 8.234667 0 0 0-4.864 13.994667l61.141333 60.928-232.96 232.32-104.874667-104.533333a16.469333 16.469333 0 0 0-23.296 0l-331.52 330.752a8.234667 8.234667 0 0 0 0 11.605333l46.336 46.464a8.277333 8.277333 0 0 0 11.648 0l285.184-284.288 104.789334 104.533333c6.485333 6.357333 16.896 6.357333 23.296 0l279.722666-278.784 61.098667 60.970667c4.821333 4.821333 13.226667 1.962667 14.037333-4.821333l24.746667-204.8a8.106667 8.106667 0 0 0-9.216-9.002667z" p-id="9943" fill="#2fb344"></path></svg>';
                    } else {
                        totalShipmentCol4Span2.setAttribute("class", "f-14 text-red mr-5 ml-4");
                        totalShipmentCol4Span2.innerHTML = chartData.chartTitlePercent + '<svg t="1666082616155" class="icon ml-5 mb--3" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11048" width="20" height="20"><path d="M152.436364 287.371636l2.792727 2.490182 244.898909 243.293091 185.6-184.343273 211.316364 209.896728c2.141091 2.117818 3.956364 4.421818 5.445818 6.912l51.223273-50.920728L930.909091 744.727273l-231.563636-76.683637 53.201454-52.829091a36.282182 36.282182 0 0 1-4.189091-2.90909l-2.792727-2.490182-159.837091-158.789818-185.576727 184.343272-296.401455-294.4a35.979636 35.979636 0 0 1 0-51.106909 36.584727 36.584727 0 0 1 48.686546-2.490182z" p-id="11049" fill="#d63939"></path></svg>';
                    }
                    totalShipmentCol4.appendChild(totalShipmentCol4Span2);
                    totalShipmentsRow.appendChild(totalShipmentCol4);
                    lineHeadingDiv.appendChild(totalShipmentsRow);

                    linePanelDiv.appendChild(lineHeadingDiv);
                    var lineBodyDiv = document.createElement("div")
                    lineBodyDiv.setAttribute("class", "panel-body body-container");
                    var lineChartContainerDiv = document.createElement("div")
                    lineChartContainerDiv.setAttribute("class", "setLineHeight");
                    var lineCanvas = document.createElement("canvas")
                    lineCanvas.setAttribute("id", i + "lineCanvas");
                    var myLineChart = new Chart(lineCanvas, {
                        type: chartData.type,
                        data: chartData.data,
                        options: chartData.options
                    });
                    lineChart[i] = myLineChart;
                    lineChartContainerDiv.appendChild(lineCanvas);
                    lineBodyDiv.appendChild(lineChartContainerDiv);
                    linePanelDiv.appendChild(lineBodyDiv);
                    lineColDiv.appendChild(linePanelDiv);
                    lineChartDiv.appendChild(lineColDiv);
                    $compile($('#DashBoardDiv' + i))($scope);
                    var dropdownMenuList = '<div id="dropdownDiv' + i + '" class="dropdown pointer dropdownDiv " uib-dropdown><a  id="chart_' + chartData.statModule + '"  class="dropdown-toggle dropDownStyle" uib-dropdown-toggle>' + lastWeeksOptions[12] + '</a><ul class="dropdown-menu l-auto" uib-dropdown-menu>';
                    for (var k in lastWeeksOptions) {
                        dropdownMenuList += '<li ui-sref-active="active"><a href="" ng-click="changeWeeks(\'bar\', ' + k + ',\'' + chartData.statModule + '\',' + i + ')">' + lastWeeksOptions[k] + '</a></li>'
                    }
                    dropdownMenuList += '</ul></div>'
                    $('#dropdownParents' + i).append(dropdownMenuList);
                    $compile($('#dropdownDiv' + i))($scope);
                } else {
                    var updateChart = lineChart[index];
                    updateChart.data.labels = chartData.data.labels;
                    updateChart.data.datasets = chartData.data.datasets;
                    updateChart.update();
                }
                i++;
            })
        }
        var volTtlChart = null;

        function initVolumeTtlChart(charts, isChange) {
            var volumeTtlDiv = document.getElementById("volumeTtlDiv");
            if (!volumeTtlDiv || !charts || charts.length < 1) {
                return;
            }
            var barData = {
                "chartTitle": "货量统计",
                "type": "bar",
                "data": {
                    "labels": charts[0].data.labels,
                    "datasets": []
                },
                "options": {
                    "responsive": true,
                    "title": {
                        "display": false,
                        "text": ""
                    },
                    "tooltips": {
                        enabled: true,
                        mode: 'index',
                        intersect: false,
                        // custom:function(tooltipModel){
                        //     tooltipsCustom(tooltipModel, this)
                        // }
                    },
                    "layout": {
                        "padding": {
                            left: 10,
                            right: 10,
                            top: 0,
                            bottom: 3
                        }
                    },
                    "hover": {
                        "intersect": true,
                        "mode": "nearest"
                    },
                    "scales": {
                        "xAxes": [{
                            "display": true,
                            "scaleLabel": {
                                "display": false,
                                "labelString": null
                            },
                            stacked: true
                        }],
                        "yAxes": [{
                            "display": true,
                            "scaleLabel": {
                                "display": false,
                                "labelString": null
                            },
                            stacked: true
                        }]
                    }
                }
            };
            barData.chartTitle = lang == "en" ? "Cargo Statistics" : "货量统计";
            var datasets = [];
            if (charts.length > 1) {
                angular.forEach(charts, function(data, index) {
                    if ((index > 0 || !$rootScope.account.companyId) && data.data.datasets && data.data.datasets.length > 0){
                        var chartDataSets = data.data.datasets;
                        var dataset = {
                            backgroundColor: '',
                            data: chartDataSets[chartDataSets.length - 1].data,
                            fill: chartDataSets[chartDataSets.length - 1].fill,
                            label: data.chartTitle,
                        };
                        if (data.statModule == "FMS") {
                            dataset.backgroundColor = "rgb(255, 99, 132)"
                        } else if (data.statModule == "FBA") {
                            dataset.backgroundColor = "rgb(255, 159, 64)"
                        } else if (data.statModule == "WMS") {
                            dataset.backgroundColor = "rgb(255, 205, 86)"
                        } else if (data.statModule == "PARCEL") {
                            dataset.backgroundColor = "rgb(75, 192, 192)"
                        } else if (data.statModule == "TMS") {
                            dataset.backgroundColor = "rgb(54, 162, 235)"
                        }
                        datasets.push(dataset);
                    }
                })
                barData.data.datasets = datasets;
            }
            if (!isChange) {
                var barColDiv = document.createElement("div");
                // barColDiv.setAttribute("class","col-md-6");
                var barPanelDiv = document.createElement("div")
                barPanelDiv.setAttribute("class", "panel panel-default PanelDefault setOutSideStyle");
                var barHeadingDiv = document.createElement("div")
                barHeadingDiv.setAttribute("class", "panel-heading");
                var barTotalShipmentsTitle = document.createElement("strong");
                barTotalShipmentsTitle.setAttribute("class", " text-left CardTitle col-xs-5");
                barTotalShipmentsTitle.innerHTML = barData.chartTitle;
                barHeadingDiv.appendChild(barTotalShipmentsTitle);
                var bartotalShipmentsSelect = document.createElement("div");
                bartotalShipmentsSelect.setAttribute("class", "text-right col-xs-7");
                bartotalShipmentsSelect.setAttribute("id", "bardropdownParents");
                barHeadingDiv.appendChild(bartotalShipmentsSelect);

                barPanelDiv.appendChild(barHeadingDiv);
                var barBodyDiv = document.createElement("div")
                barBodyDiv.setAttribute("class", "panel-body BodyContainer");
                var barChartContainerDiv = document.createElement("div")
                // barChartContainerDiv.setAttribute("class","setbarHeight");
                var barCanvas = document.createElement("canvas")
                barCanvas.setAttribute("id", "barCanvas");
                barChartContainerDiv.appendChild(barCanvas);
                barBodyDiv.appendChild(barChartContainerDiv);
                barPanelDiv.appendChild(barBodyDiv);
                barColDiv.appendChild(barPanelDiv);
                volumeTtlDiv.appendChild(barColDiv);

                volTtlChart = new Chart(barCanvas, {
                    type: barData.type,
                    data: barData.data,
                    options: barData.options
                });

                var bardropdownMenuList = '<div id="bardropdownDiv" class="dropdown pointer dropdownDiv " uib-dropdown><a  id="chart_volumeTtl" class="dropdown-toggle dropDownStyle" uib-dropdown-toggle>' + lastWeeksOptions[12] + '</a><ul class="dropdown-menu l-auto" uib-dropdown-menu>';
                for (var k in lastWeeksOptions) {
                    bardropdownMenuList += '<li ui-sref-active="active"><a href="" ng-click="changeWeeks(\'bar\', ' + k + ', \'volumeTtl\', ' + null + ')">' + lastWeeksOptions[k] + '</a></li>'
                }
                bardropdownMenuList += '</ul></div>'
                $('#bardropdownParents').append(bardropdownMenuList);
                $compile($('#bardropdownDiv'))($scope);
            } else {
                volTtlChart.data.labels = charts && charts.length > 1 ? charts[1].data.labels : [];
                volTtlChart.data.datasets = datasets;
                volTtlChart.update();
            }
        }
        var lineAreaChart = null;

        function initDcnChart(charts, isChange) {
            var dcnChartDiv = document.getElementById("dcnChartDiv");
            if (!dcnChartDiv || charts.length != 1) {
                return;
            }
            var lineAreaData = charts[0];
            lineAreaData.options.layout = {
                padding: {
                    left: 10,
                    right: 10,
                    top: 0,
                    bottom: 3
                }
            }
            lineAreaData.options['legend'] =  {
                align: "end"
            }
            angular.forEach(lineAreaData.data.datasets, function(val) {
                val.pointRadius = 3;
                val.borderWidth = 2;
                val.tension = 0.2;
            })
            if (!isChange) {
                var lineAreaColDiv = document.createElement("div")
                // lineAreaColDiv.setAttribute("class","col-md-6");
                var lineAreaPanelDiv = document.createElement("div")
                lineAreaPanelDiv.setAttribute("class", "panel panel-default PanelDefault setOutSideStyle lineAreaStyle");
                var lineAreaHeadingDiv = document.createElement("div")
                lineAreaHeadingDiv.setAttribute("class", "panel-heading");
                var lineAreaTotalShipmentsTitle = document.createElement("strong");
                lineAreaTotalShipmentsTitle.setAttribute("class", " text-left CardTitle col-xs-5");
                lineAreaTotalShipmentsTitle.innerHTML = lineAreaData.chartTitle;
                lineAreaHeadingDiv.appendChild(lineAreaTotalShipmentsTitle);
                var lineAreatotalShipmentsSelect = document.createElement("div");
                lineAreatotalShipmentsSelect.setAttribute("class", "text-right col-xs-7 z-1000");
                lineAreatotalShipmentsSelect.setAttribute("id", "lineAreadropdownParents");
                lineAreaHeadingDiv.appendChild(lineAreatotalShipmentsSelect);

                lineAreaPanelDiv.appendChild(lineAreaHeadingDiv);
                var lineAreaBodyDiv = document.createElement("div")
                lineAreaBodyDiv.setAttribute("class", "panel-body BodyContainer lineAreaBodyStyle");
                var lineAreaChartInnerDiv = document.createElement("div");
                lineAreaChartInnerDiv.setAttribute("class", "setInnerChartDiv")
                var InnerContentDIvOne = document.createElement("div");
                InnerContentDIvOne.setAttribute("class", "InnerContentDIvOne");
                if($rootScope.account.companyId){
                    InnerContentDIvOne.innerHTML = "Income: " + $rootScope.FORMAT_NUM(lineAreaData.chartTitleNum);
                }
                else {
                    InnerContentDIvOne.innerHTML = "Total: " + $rootScope.FORMAT_NUM(lineAreaData.chartTitleNum);
                }
                lineAreaChartInnerDiv.appendChild(InnerContentDIvOne);
                var InnerContentDIvTwo = document.createElement("div");
                InnerContentDIvTwo.setAttribute("class", "InnerContentDIvTwo text-muted");
                if (lineAreaData.isUp) {
                    InnerContentDIvTwo.innerHTML = '<svg t="1666082045534" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9942" width="20" height="20"><path d="M929.408 202.752l-205.269333 24.661333a8.234667 8.234667 0 0 0-4.864 13.994667l61.141333 60.928-232.96 232.32-104.874667-104.533333a16.469333 16.469333 0 0 0-23.296 0l-331.52 330.752a8.234667 8.234667 0 0 0 0 11.605333l46.336 46.464a8.277333 8.277333 0 0 0 11.648 0l285.184-284.288 104.789334 104.533333c6.485333 6.357333 16.896 6.357333 23.296 0l279.722666-278.784 61.098667 60.970667c4.821333 4.821333 13.226667 1.962667 14.037333-4.821333l24.746667-204.8a8.106667 8.106667 0 0 0-9.216-9.002667z" p-id="9943" fill="#2fb344"></path></svg>' + lineAreaData.chartTitlePercent + '% than Last Week';
                }
                else {
                    InnerContentDIvTwo.innerHTML = '<svg t="1666082616155" class="icon ml-5 mb--3" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11048" width="20" height="20"><path d="M152.436364 287.371636l2.792727 2.490182 244.898909 243.293091 185.6-184.343273 211.316364 209.896728c2.141091 2.117818 3.956364 4.421818 5.445818 6.912l51.223273-50.920728L930.909091 744.727273l-231.563636-76.683637 53.201454-52.829091a36.282182 36.282182 0 0 1-4.189091-2.90909l-2.792727-2.490182-159.837091-158.789818-185.576727 184.343272-296.401455-294.4a35.979636 35.979636 0 0 1 0-51.106909 36.584727 36.584727 0 0 1 48.686546-2.490182z" p-id="11049" fill="#d63939"></path></svg>' + lineAreaData.chartTitlePercent + '% than Last Week';
                }
                lineAreaChartInnerDiv.appendChild(InnerContentDIvTwo);
                var lineAreaChartContainerDiv = document.createElement("div")
                lineAreaChartContainerDiv.setAttribute("class", "setbarHeight");

                var lineAreaCanvas = document.createElement("canvas")
                lineAreaCanvas.setAttribute("id", "lineAreaCanvas");
                lineAreaChartContainerDiv.appendChild(lineAreaCanvas);
                lineAreaBodyDiv.appendChild(lineAreaChartInnerDiv);
                lineAreaBodyDiv.appendChild(lineAreaChartContainerDiv);
                lineAreaPanelDiv.appendChild(lineAreaBodyDiv);
                lineAreaColDiv.appendChild(lineAreaPanelDiv);
                dcnChartDiv.appendChild(lineAreaColDiv);
                lineAreaChart = new Chart(lineAreaCanvas, {
                    type: lineAreaData.type,
                    data: lineAreaData.data,
                    options: lineAreaData.options
                });
                var lineAreadropdownMenuList = '<div id="lineAreadropdownDiv" class="dropdown pointer dropdownDiv " uib-dropdown><a  id="chart_DCN"  class="dropdown-toggle dropDownStyle" uib-dropdown-toggle>' + lastWeeksOptions[12] + '</a><ul class="dropdown-menu l-auto" uib-dropdown-menu>';
                for (var k in lastWeeksOptions) {
                    lineAreadropdownMenuList += '<li ui-sref-active="active"><a href="" ng-click="changeWeeks(\'line\', ' + k + ', \'DCN\' , null)">' + lastWeeksOptions[k] + '</a></li>';
                }
                lineAreadropdownMenuList += '</ul></div>'
                $('#lineAreadropdownParents').append(lineAreadropdownMenuList);
                $compile($('#lineAreadropdownDiv'))($scope);
            } else {
                lineAreaChart.data.labels = charts[0].data.labels;
                lineAreaChart.data.datasets = charts[0].data.datasets;
                lineAreaChart.update();
            }
        }

        function tooltipsCustom(tooltipModel, that) {
            var tooltipEl = document.getElementById('chartjs-tooltip');
            if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table ></table>';
                document.body.appendChild(tooltipEl);
            }
            if (tooltipModel.opacity === 0) {
                // tooltipEl.style.animation = 'OpacityZreo 0.5s';
                // tooltipEl.style.animationFillMode = 'forwards';
                tooltipEl.style.opacity = 0;
                return;
            }
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
                tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem, that) {
                return bodyItem.lines;
            }

            function getLabelColors(colorItem, that) {
                return colorItem.backgroundColor;
            }
            if (tooltipModel.body) {
                var titleLines = tooltipModel.title || [];
                var bodyLines = tooltipModel.body.map(getBody);
                var labelColors = tooltipModel.labelColors.map(getLabelColors);

                var innerHtml = '<thead style="background: #ECEFF1;border-bottom:1px solid #ddd;">';

                titleLines.forEach(function(title) {
                    innerHtml += '<tr><th style="color:#333;padding:5px 8px;">' + title + '</th><th></th></tr>';
                });
                innerHtml += '</thead><tbody style="background: rgba(255, 255, 255, 0.96);">';

                bodyLines.forEach(function(body, i) {
                    innerHtml += '<tr><td><p style="background-color:' + labelColors[i] + ';width:10px;height:10px;border-radius:50%;padding:3px 0px;margin:0 auto;"></p></td><td><p style="color:#333;padding:3px 10px 3px 0px;margin:0px;">' + body + '</p></td></tr>';
                });
                innerHtml += '</tbody>';

                var tableRoot = tooltipEl.querySelector('table');
                tableRoot.innerHTML = innerHtml;
            }
            var position = that._chart.canvas.getBoundingClientRect();
            var scroll = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
            tooltipEl.style.opacity = 1;
            // tooltipEl.style.animation = 'OpacityOne 0.5s';
            // tooltipEl.style.animationFillMode = 'forwards';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + tooltipModel.caretY + scroll + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
        }

        function editNews(news) {
            if ($rootScope.HAVENOAUTH('FUN_ANNOUNCEMENT')) {
                $rootScope.FORBIDDEN_BY_ALERT('FUN_ANNOUNCEMENT');
                return;
            }
            noticeEditPop(angular.copy(news));
        }

        function addNews() {
            if ($rootScope.HAVENOAUTH('FUN_ANNOUNCEMENT')) {
                $rootScope.FORBIDDEN_BY_ALERT('FUN_ANNOUNCEMENT');
                return;
            }
            vm.tempNews = {};
            noticeEditPop({});
        }

        function noticeEditPop(notice) {
            $uibModal.open({
                templateUrl: 'app/entities/ops-bill/ops-bill-dialog.html',
                controller: 'OpsBillDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: ['OpsFiles', function(OpsFiles) {
                        if (notice && notice.id) {
                            return OpsFiles.getOneNews({ id: notice.id }).$promise;
                        }
                        return {};
                    }]
                }
            }).result.then(function() {}, function() {});
        }

        function viewNotice(notice) {
            var entity = angular.copy(notice);
            entity.content = entity.content.replace(/\n/g, '<br>');
            $uibModal.open({
                templateUrl: 'app/entities/ops-bill/ops-bill-delete-dialog.html',
                controller: 'OpsBillDeleteController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function() {}, function() {});
        }

        function stateGo(task) {
            if(task.workType){
                $state.go(task.statModule, {customNode: task});
            }
            else {
                window.open($state.href(task.statModule),"_blank");
            }
        }

        window.onresize = function(){
            resize();
        }
        resize();
        vm.winWidth;
        function resize() {
            vm.winWidth = $(window).width()+17;
            $translate.refresh();
        }

        vm.chartTips = null;
        function getChartTips(i18n) {
            if(i18n){
                vm.chartTips = $translate.instant(i18n);
            }
            else {
                vm.chartTips = null;
            }          
            $translate.refresh();  
        }
    }
})();
