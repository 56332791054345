(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GridStateDialogController', GridStateDialogController);

    GridStateDialogController.$inject = ['$scope', '$uibModalInstance', 'entity'];

    function GridStateDialogController ($scope, $uibModalInstance, entity) {
        var vm = this;

        vm.timeStamp = entity;
        vm.clear = clear;
        vm.save = save;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function save () {
            $uibModalInstance.close(vm.timeStamp);
        }
        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }
    }
})();
