(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('AmsEdiData', AmsEdiData);

    AmsEdiData.$inject = ['$resource', 'DateUtils'];

    function AmsEdiData ($resource, DateUtils) {
        function initGuideResponse(data){
            if (!data){return;}    
            data = angular.fromJson(data);            
            data.guide.cls = DateUtils.convertDateTimeFromServer(data.guide.cls);
            data.guide.siCutTime = DateUtils.convertDateTimeFromServer(data.guide.siCutTime);
            data.guide.cargoReadyDate = DateUtils.convertLocalDateFromServer(data.guide.cargoReadyDate);
            data.guide.eta = DateUtils.convertLocalDateFromServer(data.guide.eta);
            data.guide.billDate = DateUtils.convertLocalDateFromServer(data.guide.billDate);
            data.guide.opDate = DateUtils.convertLocalDateFromServer(data.guide.opDate);
            data.guide.etd = DateUtils.convertLocalDateFromServer(data.guide.etd);

            data.amsEdiData.lastForeignPortEtd = DateUtils.convertLocalDateFromServer(data.amsEdiData.lastForeignPortEtd);

            data.guide.atd = DateUtils.convertLocalDateFromServer(data.guide.atd);
            data.guide.siCutTime = DateUtils.convertDateTimeFromServer(data.guide.siCutTime);
            data.guide.soExpectedTime = DateUtils.convertDateTimeFromServer(data.guide.soExpectedTime);            
            data.guide.vgm = DateUtils.convertDateTimeFromServer(data.guide.vgm);
            data.guide.feederEtd = DateUtils.convertLocalDateFromServer(data.guide.feederEtd);
            data.guide.lastModifiedTime = DateUtils.convertDateTimeFromServer(data.guide.lastModifiedTime);
            
            data.guide.deliveryDate = DateUtils.convertLocalDateFromServer(data.guide.deliveryDate);
            if (data.guide.booking){
                data.guide.booking.issueDate = DateUtils.convertLocalDateFromServer(data.guide.booking.issueDate);
                data.guide.booking.etd = DateUtils.convertLocalDateFromServer(data.guide.booking.etd);
            }
            angular.forEach(data.guide.guideCtns, function(ctn){
                ctn.returnTime = DateUtils.convertDateTimeFromServer(ctn.returnTime);
            })
            return data;
        }

        function initResponse(data){
            if (!data){return;}    
            data = angular.fromJson(data);            
            data.lastForeignPortEtd = DateUtils.convertLocalDateFromServer(data.lastForeignPortEtd);
            return data;
        }

        function initRequest (data) {
            data = angular.copy(data);
            data.lastForeignPortEtd = DateUtils.convertLocalDateToServer(data.lastForeignPortEtd);
            if(data.guide){
                data.guide.etd = DateUtils.convertLocalDateToServer(data.guide.etd);
                data.guide.eta = DateUtils.convertLocalDateToServer(data.guide.eta); 
            }
            if(data.amsEdiData){
                data.amsEdiData.lastForeignPortEtd = DateUtils.convertLocalDateToServer(data.amsEdiData.lastForeignPortEtd);
            }
            return angular.toJson(data);
        }

        var resourceUrl =  'api/ams-edi-data/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'getByGuideId': {
                method: 'GET', 
                url:'api/ams-edi-data/guideId',
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'getByJobNum': {
                method: 'GET', 
                url:'api/ams-edi-data/jobNum',
                transformResponse: function (data) {
                    return initGuideResponse(data);
                }
            },
            'getVesselInfo': {
                method: 'PUT', 
                url:'api/ams-edi-data/getVesselInfo',
                isArray: true
            },
            'getAmsStatus': {
                method: 'PUT', 
                url:'api/ams-edi-data/getAmsStatus'
            },
            'fillZipCodeAndState': {
                method: 'PUT', 
                url:'api/ams-edi-data/fillZipCodeAndState'
            },
            'sendAms': {
                method: 'POST', 
                url:'api/ams-edi-data/sendAms',          
                transformRequest: function (data) {
                    return initRequest(data);
                }
            },
            'update': { 
                method:'PUT',
                transformResponse: function (data) {
                    return initResponse(data);
                },          
                transformRequest: function (data) {
                    return initRequest(data);
                }
            },
            'save': { 
                method:'POST',
                transformResponse: function (data) {
                    return initResponse(data);
                },
                transformRequest: function (data) {
                    return initRequest(data);
                }
            },
            'getPltPltToken': {
                method: 'GET', 
                url:'api/ams-edi-data/getPltPltToken'
            },
            'getPltPltAMSData': {
                method: 'GET',
                url:'api/ams-edi-data/getPltPltAMSData'
            },
            'sendOperationOrder':{
                method:'POST',
                url:'api/ams-edi-data/sendOperationOrder'
            },
            'updatePltOrderStatus':{
                method:'GET',
                url:'api/ams-edi-data/updatePltOrderStatus'
            }
        });
    }
})();
