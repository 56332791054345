(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('GuideDocSearch', GuideDocSearch);

    GuideDocSearch.$inject = ['$resource'];
    
    function GuideDocSearch($resource) {
        return $resource('api/_search/guideDocs/:query', {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
