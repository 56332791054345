(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsNewsDialogController', OpsNewsDialogController);

    OpsNewsDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$translate', '$uibModalInstance', 'entity', 'OpsNews'];

    function OpsNewsDialogController($timeout, $scope, $rootScope, $translate, $uibModalInstance, entity, OpsNews) {
        var vm = this;

        vm.opsNews = entity;
        vm.clear = clear;
        vm.save = save;

        var opsNewsCopy;
        $scope.files = [];

        $timeout(function() {
            opsNewsCopy = angular.copy(vm.opsNews);
            if(vm.opsNews.id != null){
                $rootScope.LOADFILES($scope.files, '/opsNews/' + vm.opsNews.id + '/', 54);
            }
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            OpsNews.save(vm.opsNews, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.opsNews = result;
            opsNewsCopy = angular.copy(vm.opsNews);
            $scope.$emit('autopsApp:opsNewsUpdate');
            // $uibModalInstance.close(result);
            vm.isSaving = false;
            layer.msg($translate.instant('global.save_success'));
        }

        function onSaveError(result) {
            vm.isSaving = false;
            $rootScope.ALERT_ERROR(result);
        }
    }
})();
