(function() {
	'use strict';

	angular.module('autopsApp').controller('AccountInvoiceDialogController',
			AccountInvoiceDialogController);

    AccountInvoiceDialogController.$inject = ['$scope', '$uibModalInstance', '$rootScope', 'entity', 'AccountInvoice', 'OpsVender'];

    function AccountInvoiceDialogController ($scope, $uibModalInstance, $rootScope, entity, AccountInvoice, OpsVender) {
       
        $scope.searchParams = entity;
        var vm = this;
         vm.isSaving = false;
        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        $scope._onClientSelected = function(data) {
            $scope.searchParams.venderId = data.id;
        }
        $scope._onClerkSelected = function(clerk, data) {
            $scope.searchParams[clerk] = data.id;
        }

        $scope.searchRequests = function() {
            vm.isSaving = true;
            AccountInvoice.advanceSearchRequests($scope.searchParams, function(result) {
                vm.isSaving = false;
                $uibModalInstance.close(result);
            }, function(response) {
                vm.isSaving = false;
            });
        };

        $scope.setStatus= function(fd, value) {
            $scope.searchParams[fd] = value;
        }        

    }
})();
