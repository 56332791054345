
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('JhiSignatureDetailController', JhiSignatureDetailController);

    JhiSignatureDetailController.$inject = ['$scope', '$rootScope', '$translate', '$uibModalInstance', '$uibModal', 'entity', 'JhiSignature'];
    
    function JhiSignatureDetailController($scope, $rootScope, $translate, $uibModalInstance, $uibModal, entity, JhiSignature) {

        $scope.opsAccounts = entity;

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.delOne = function (account) {            
            if (account.id != null) {
                JhiSignature.delete({id: account.id});
            };
            var index = $scope.opsAccounts.indexOf(account);
            if (index > -1) {
                $scope.opsAccounts.splice(index, 1);
            };
        };
        $scope.edit = function(rateRemark) {
            if(!rateRemark){
                rateRemark = {};
                rateRemark.ratesRemarkCompanyId = $rootScope.account.companyId;
            }
            $uibModal.open({
                templateUrl: 'app/entities/jhiSignature/jhiSignature-delete-dialog.html',
                controller: 'JhiSignatureDeleteController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return rateRemark;
                    }]
                }
            }).result.then(function(result) {
                if(rateRemark.id){
                    rateRemark.id = result.id;
                    rateRemark.name = result.name;
                    rateRemark.signature = result.signature;
                }
                else {
                    $scope.opsAccounts.push(result);
                }
            }, function() {

            });

        };



}
})();
