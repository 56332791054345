(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WoBodyDialogController', WoBodyDialogController);

    WoBodyDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$uibModalInstance', '$translate', 'entity', 'WoHead'];

    function WoBodyDialogController ($timeout, $scope, $rootScope, $uibModalInstance, $translate, entity, WoHead) {
        var vm = this;

        vm.woHead = entity;
        vm.clear = clear;
        vm.save = save;
        vm.woBody = {solved: true, serviceRating: 5};

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
            if(!vm.woBody.files){
                vm.woBody.files = [];
            }
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            var content = "问题解决: " + (vm.woBody.solved?"已解决":"未解决");
            // if(vm.woBody.serviceRating){
            //     content += "\n服务评级: " + vm.woBody.serviceRating + "星";
            // }
            if(vm.woBody.comments){
                content += "\n评价内容: " + vm.woBody.comments;
            }
            vm.isSaving = true;
            vm.woBody.content = content;
            vm.woBody.headId = vm.woHead.id;
            vm.woBody.userName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            vm.woBody.time = new Date();
            vm.woBody.cusReply = $rootScope.account.companyId == 1;
            WoHead.closeWo({serviceRating: vm.woBody.serviceRating}, vm.woBody, onSaveSuccess, onSaveError);
        }
        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);            
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function saveAlert () {
            layer.msg($translate.instant('global.saveAlert'));
        };

    }
})();
