(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsTemplateDeleteController', OpsTemplateDeleteController);

    OpsTemplateDeleteController.$inject = ['$rootScope', '$scope', '$uibModalInstance', 'entity', 'OpsTemplate'];
    
	function OpsTemplateDeleteController($rootScope, $scope, $uibModalInstance, entity, OpsTemplate) {

        $scope.opsTemplate = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        setTimeout(function () {   
            if (!$rootScope.id){
                $uibModalInstance.dismiss('cancel');
            }
        }, 500);  
        $scope.confirmDelete = function (id) {
            OpsTemplate.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }
})();
