
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ExpressParcelDetailController', ExpressParcelDetailController);

    ExpressParcelDetailController.$inject = ['$scope', '$rootScope', '$timeout', '$translate', '$uibModalInstance', '$http',
    'entity', 'ExpressParcel', 'OpsVender', 'OpsCarrier', 'AddressBook', 'AccountBind', 'WmsWarehouse'];

    function ExpressParcelDetailController ($scope, $rootScope, $timeout, $translate, $uibModalInstance, $http,
        entity, ExpressParcel, OpsVender, OpsCarrier, AddressBook, AccountBind, WmsWarehouse) {
       
        $scope.searchParams = entity;
        var vm = this;
        vm.getWarehouses = getWarehouses;
        vm.warehouseSelected = warehouseSelected;

        $rootScope.ISTMS = $scope.searchParams.shipmentType == "TMS";

        vm.isSaving = false;
        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.datePickerOpenStatus = {};
        $scope.dayFormat = "yyyy-MM-dd";
        $scope.dayDatepickerOptions = {
            minMode: 'day'
        }
        $scope.datePickerOpenStatus.dateFromPicker = false;
        $scope.datePickerOpenStatus.dateToPicker = false;

        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }        
        
        $scope._onVenderSelected = function(fd, data) {
            $scope.searchParams[fd] = data.id;
        }

        $scope._onClerkSelected = function(clerk, data) {
            $scope.searchParams[clerk] = data.id;
        }

        $scope.platforms = ["Offline", "Amazon", "Ebay", "Walmart", "Shopify", "MfyCang", "AmazonSftp", "wayfair", "Overstock", "Aliexpress", "Wish", "HomeDepot", "Lazada", "Joom", "1688", "B2C", "Bigcommerce", "Cdiscount", "Linio", "Oberlo", "PriceMinister", "PPCart", "Shopee", "Tophatter", "Mall", "Yandex", "Pearlgo", "Vova", "Jumia", "JD", "Alabom", "OpenCart", "Houzz", "Sears", "Tanga"];


        $scope.searchGuides = function() {
            var searchParams = angular.copy($scope.searchParams);
            if (searchParams.allBilled){
                searchParams.allBilled = false;
            }
            else {
                searchParams.allBilled = null;
            }
            if(searchParams.multiPieces){
                searchParams.pkgNumFM = 2;
            }
            layer.load(1, {shade: 0.3});
            ExpressParcel.advancedSearch(searchParams, function(result) {
                layer.closeAll(); 
                $uibModalInstance.close(result);
            }, $rootScope.ALERT_ERROR);  
        };
        $scope.excelImport = function(file, type) {
            if (!file) {return;}            
            var fd = new FormData();
            fd.append('file', file);
            fd.append('type', type);
            fd.append('shipmentType', $scope.searchParams.shipmentType);
            $http.post(
                'api/express-parcels/updateStatusByExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {    
                    data = angular.fromJson(data);
                    if (data.result.length == 0){
                        layer.msg($translate.instant('global.messages.resultNull'));  
                        return;
                    }
                    $uibModalInstance.close(data.result);
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));                           
                });
        };
        $scope.addressBooks = [];
        $scope.loadAddressBooks = function() {
            if ($scope.addressBooks.length == 0 && !vm.isSaving) {
                vm.isSaving = true;
                AddressBook.getAll(function(result) {
                    $scope.addressBooks = result;
                    vm.isSaving = false;
                });

            };
        };

        $scope.setStatus= function(fd, value) {
            if($scope.searchParams[fd]  == value){
                $scope.searchParams[fd] = null;
            }
            else {
                $scope.searchParams[fd] = value;
            }            
        }     

        $scope.accountBinds = [];
        $scope.getAllAccountBind = function() {
            if($scope.accountBinds.length > 0){
                return;
            }
            AccountBind.getAll(function(resultList){
                $scope.accountBinds = resultList;
            });
        }

        $scope._onAccountBindSelected = function(data, fd) {
            $scope.searchParams[fd] = data.id;
        }

        vm.wmswarehouses = [];
        function getWarehouses () {
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false
                }, function (data) {
                vm.wmswarehouses = data;
            });
        }
        function warehouseSelected (data) {
            $scope.searchParams.warehouseId = data.id;
        }
        $scope.bindedAccounts = [];
        $scope.getAllAccounts = function() {
            if($rootScope.account.companyId && $rootScope.HAVENOAUTH('AU_LABEL_ACCOUNT')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_LABEL_ACCOUNT');
                return;            
            }
            if ($scope.bindedAccounts.length == 0 && !$scope.isSaving) {
                $scope.isSaving = true;
                AddressBook.getAllAccounts(function(result) {
                    $scope.bindedAccounts = result;
                    $scope.isSaving = false;
                }, function(result) {
                    $scope.isSaving = false;
                });
            };
        };
        $scope._onAddressSelected = function(data) {
            ExpressParcel.getServicesList({addressId: data.id}, function(results){
                $rootScope.PARCEL_SERVICES = results;
            })
        }
        $scope.searchParams.serviceCodes = [];
        $scope.shipServiceSelected = function(data) {
           if($scope.searchParams.serviceCodes.indexOf(data.discription) == -1){
                $scope.searchParams.serviceCodes.push(data.discription);
                $scope.searchParams.discription = null;
                $translate.refresh();
            }
        }
        $scope.delService = function(index) {
            $scope.searchParams.serviceCodes.splice(index, 1);
        };

    }
})();

