(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('WoHeadSearch', WoHeadSearch);

    WoHeadSearch.$inject = ['$resource'];

    function WoHeadSearch($resource) {
        var resourceUrl =  'api/_search/wo-heads/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
