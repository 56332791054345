(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('InvoiceInfo', InvoiceInfo);

    InvoiceInfo.$inject = ['$resource'];

    function InvoiceInfo ($resource) {
        var resourceUrl =  'api/invoice-infos/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getVenderInvoiceInfos': {
                method: 'GET',
                url:'api/invoice-infos/vender',
                isArray: true
            },
            'update': { method:'PUT' }
        });
    }
})();
