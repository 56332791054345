(function () {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('custom-node', {
                parent: 'entity',
                url: '/custom-node?type',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'autopsApp.customNode.home.generalTitle'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/custom-node/custom-nodes.html',
                        controller: 'CustomNodeController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,desc',
                        squash: true
                    },
                    search: null,
                    type: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            type: $stateParams.type,
                            search: $stateParams.search
                        };
                    }],
                    entity : ['$stateParams',function ($stateParams){
                        return {
                            type: $stateParams.type
                        }
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('customNode');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('expressParcel');
                        $translatePartialLoader.addPart('booking');
                        $translatePartialLoader.addPart('guide');
                        return $translate.refresh();
                    }]
                }
            })
            .state('custom-node-detail', {
                parent: 'entity',
                url: '/custom-node/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'autopsApp.customNode.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/custom-node/custom-node-detail.html',
                        controller: 'CustomNodeDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('customNode');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'CustomNode', function ($stateParams, CustomNode) {
                        return CustomNode.get({ id: $stateParams.id }).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'custom-node',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('custom-node-detail.edit', {
                parent: 'custom-node-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/custom-node/custom-node-dialog.html',
                        controller: 'CustomNodeDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['CustomNode', function (CustomNode) {
                                return CustomNode.get({ id: $stateParams.id }).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('customNode');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('customCondition');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('^', {}, { reload: false });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('custom-node.new', {
                parent: 'custom-node',
                url: '/{type}/new',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/custom-node/custom-node-dialog.html',
                        controller: 'CustomNodeDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    companyId: null,
                                    customConditions: [{conditionType: 'condition'}],
                                    conditionsCombine: false,
                                    disabled: false,
                                    logicalOperator: "and",
                                    viewScope: "self",
                                    id: null,
                                    type: $stateParams.type
                                };
                            },
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('customNode');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('customCondition');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('custom-node', null, { reload: 'custom-node' });
                    }, function () {
                        $state.go('custom-node');
                    });
                }]
            })
            .state('custom-node.edit', {
                parent: 'custom-node',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/custom-node/custom-node-dialog.html',
                        controller: 'CustomNodeDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['CustomNode', 'WinAES', function (CustomNode, WinAES) {
                                return CustomNode.get({ id: WinAES.decode($stateParams.id) }).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('customNode');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('customCondition');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('custom-node', null, { reload: 'custom-node' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('custom-node.delete', {
                parent: 'custom-node',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/custom-node/custom-node-delete-dialog.html',
                        controller: 'CustomNodeDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['CustomNode', 'WinAES', function (CustomNode, WinAES) {
                                return CustomNode.get({ id: WinAES.decode($stateParams.id) }).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('custom-node', null, { reload: 'custom-node' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('custom-node-dialog-field', {
                parent: 'custom-node',
                url: '/{id}/field/edit',
                data: {
                    authorities: ['AU_COMPANY_CONFIGURATION']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/custom-node/custom-node-dialog-field.html',
                        controller: 'CustomNodeDialogFieldController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['CustomNode', 'WinAES', function (CustomNode, WinAES) {
                                return CustomNode.get({ id: WinAES.decode($stateParams.id) }, function(result){
                                    // 接口响应的“目标值”总是字符串，如果“目标值”字符串中是数字，就转换为数字类型，这样在界面才能显示出来
                                    if(result && result.customConditions){
                                        angular.forEach(result.customConditions, function(customCondition){
                                            if(customCondition.targetValue && !isNaN(customCondition.targetValue)){
                                                customCondition.targetValue = Number(customCondition.targetValue);
                                            }
                                        })
                                    }
                                    return result;
                                }).$promise;
                            }],
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('customNode');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('customCondition');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('custom-node', null, { reload: 'custom-node' });
                    }, function () {
                        $state.go('^');
                    });
                }]
            })
            .state('custom-node-field-new', {
                parent: 'custom-node',
                url: '/{type}/field/new',
                data: {
                    authorities: ['AU_COMPANY_CONFIGURATION']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/custom-node/custom-node-dialog-field.html',
                        controller: 'CustomNodeDialogFieldController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    companyId: null,
                                    customConditions: [],
                                    conditionsCombine: false,
                                    disabled: false,
                                    logicalOperator: "and",
                                    viewScope: "self",
                                    id: null,
                                    type: $stateParams.type
                                };
                            },
                            translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                                $translatePartialLoader.addPart('customNode');
                                $translatePartialLoader.addPart('global');
                                $translatePartialLoader.addPart('customCondition');
                                return $translate.refresh();
                            }]
                        }
                    }).result.then(function () {
                        $state.go('custom-node', null, { reload: 'custom-node' });
                    }, function () {
                        $state.go('custom-node');
                    });
                }]
            })
    }

})();
