(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('OpsRates', OpsRates);

    OpsRates.$inject = ['$resource','DateUtils'];

    function OpsRates($resource, DateUtils) {
        return $resource('api/opsRatess/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getByCode': {
                url:'api/opsRatess/getByCode',                
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT', isArray: true}
        });
    }
})();
