(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('WmsWarehouse', WmsWarehouse);

    WmsWarehouse.$inject = ['$resource'];

    function WmsWarehouse ($resource) {
        var resourceUrl =  'api/wms-warehouses/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getTypes': {
                method: 'GET',
                url:'api/wms-warehouses/types',
                isArray: true
            },
            'getAll': {
                method: 'GET',
                url:'api/wms-warehouses/all',
                isArray: true
            },
            'setDisabled': {
                method: 'GET',
                url:'api/wms-warehouses/setDisabled'
            },
            'refreshInventoryLocationType': {
                method: 'GET',
                url:'api/wms-warehouses/refreshInventoryLocationType'
            },
            'getLocations': {
                method: 'GET',
                url:'api/wms-warehouses/getLocations',
                isArray: true
            },
            'getLocationByArea': {
                method: 'GET',
                url:'api/wms-warehouses/getLocationByArea',
                isArray: true
            },
            'saveExpressZone': {
                method: 'POST',
                url:'api/wms-warehouses/saveExpressZone'
            },
            'refreshNameOfOrders': {
                method: 'POST',
                url:'api/wms-warehouses/refreshNameOfOrders'
            },
            'refreshEnNameOfOrders': {
                method: 'POST',
                url:'api/wms-warehouses/refreshEnNameOfOrders'
            },            
            'getAllLocations': {
                method: 'GET',
                url:'api/wms-warehouses/alllocations',
                isArray: true
            },
            'getAllAreas': {
                method: 'GET',
                url:'api/wms-warehouses/allAreas',
                isArray: true
            },
            'update': { method:'PUT' },
            'checkWareHouse':{
                method:'GET',
                url:'api/wms-warehouses/check/:id'
            }
        });
    }
})();
