(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('sku-bind', {
            parent: 'wms',
            url: '/sku-bind?page&sort&search',
            data: {
                authorities: ['ROLE_CLIENT', 'FUN_WMS_GOODS'],
                pageTitle: 'autopsApp.skuBind.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/sku-bind/sku-binds.html',
                    controller: 'SkuBindController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('skuBind');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('sku-bind.new', {
            parent: 'sku-bind',
            url: '/new',
            data: {
                authorities: ['ROLE_CLIENT', 'FUN_WMS_GOODS']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sku-bind/sku-bind-dialog.html',
                    controller: 'SkuBindDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'md',
                    resolve: {
                        entity: function () {
                            return {
                                companyId: null,
                                venderId: null,
                                sku: null,
                                bindSku: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('sku-bind', null, { reload: 'sku-bind' });
                }, function() {
                    $state.go('sku-bind');
                });
            }]
        })
        .state('sku-bind.edit', {
            parent: 'sku-bind',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_CLIENT', 'FUN_WMS_GOODS']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sku-bind/sku-bind-dialog.html',
                    controller: 'SkuBindDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'md',
                    resolve: {
                        entity: ['SkuBind', 'WinAES', function(SkuBind, WinAES) {
                            return SkuBind.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('sku-bind', null, { reload: 'sku-bind' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('sku-bind.delete', {
            parent: 'sku-bind',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_CLIENT', 'FUN_WMS_GOODS']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sku-bind/sku-bind-delete-dialog.html',
                    controller: 'SkuBindDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['SkuBind', 'WinAES', function(SkuBind, WinAES) {
                            return SkuBind.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('sku-bind', null, { reload: 'sku-bind' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
