(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('vgm-head', {
            parent: 'ops',
            url: '/vgm-head?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'autopsApp.vgmHead.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/vgm-head/vgm-heads.html',
                    controller: 'VgmHeadController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('vgmHead');
                    $translatePartialLoader.addPart('vgmCtn');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('vgm-head-detail', {
            parent: 'ops',
            url: '/vgm-head/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'autopsApp.vgmHead.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/vgm-head/vgm-head-detail.html',
                    controller: 'VgmHeadDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('vgmHead');
                    $translatePartialLoader.addPart('vgmCtn');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'VgmHead', 'WinAES', function($stateParams, VgmHead, WinAES) {
                    return VgmHead.get({id : WinAES.decode($stateParams.id)}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'vgm-head',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('vgm-head-detail.edit', {
            parent: 'vgm-head-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/vgm-head/vgm-head-dialog.html',
                    controller: 'VgmHeadDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xl',
                    resolve: {
                        entity: ['VgmHead', 'WinAES', function(VgmHead, WinAES) {
                            return VgmHead.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('vgm-head.new', {
            parent: 'vgm-head',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/vgm-head/vgm-head-dialog.html',
                    controller: 'VgmHeadDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xl',
                    resolve: {
                        entity: function () {
                            return {
                                companyId: null,
                                ctnId: null,
                                polId: null,
                                polName: null,
                                mblNum: null,
                                clientId: null,
                                clientName: null,
                                carrierCode: null,
                                carrierName: null,
                                submitter: null,
                                submitterId: null,
                                ctnVerified: null,
                                lastModifiedTime: null,
                                id: null,
                                status: null,
                                voy: null,
                                pierCode: null,
                                pierName: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('vgm-head', null, { reload: 'vgm-head' });
                }, function() {
                    $state.go('vgm-head');
                });
            }]
        })
        .state('vgm-head.edit', {
            parent: 'vgm-head',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/vgm-head/vgm-head-dialog.html',
                    controller: 'VgmHeadDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xl',
                    resolve: {
                        entity: ['VgmHead', 'WinAES', function(VgmHead, WinAES) {
                            return VgmHead.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('vgm-head', null, { reload: 'vgm-head' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('vgm-head.delete', {
            parent: 'vgm-head',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/vgm-head/vgm-head-delete-dialog.html',
                    controller: 'VgmHeadDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['VgmHead', 'WinAES', function(VgmHead, WinAES) {
                            return VgmHead.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('vgm-head', null, { reload: 'vgm-head' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
