(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('bookingPrice', {
                parent: 'entity',
                url: '/bookingPrices',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'autopsApp.bookingPrice.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/bookingPrice/bookingPrices.html',
                        controller: 'BookingPriceController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bookingPrice');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('bookingPrice.detail', {
                parent: 'entity',
                url: '/bookingPrice/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'autopsApp.bookingPrice.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/bookingPrice/bookingPrice-detail.html',
                        controller: 'BookingPriceDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bookingPrice');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'BookingPrice', function($stateParams, BookingPrice) {
                        return BookingPrice.get({id : $stateParams.id});
                    }]
                }
            })
            .state('bookingPrice.new', {
                parent: 'bookingPrice',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/bookingPrice/bookingPrice-dialog.html',
                        controller: 'BookingPriceDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    bookingId: null,
                                    currency: null,
                                    price: null,
                                    item: null,
                                    count: null,
                                    payer: null,
                                    paymentTerm: null,
                                    remarks: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('bookingPrice', null, { reload: true });
                    }, function() {
                        $state.go('bookingPrice');
                    })
                }]
            })
            .state('bookingPrice.edit', {
                parent: 'bookingPrice',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/bookingPrice/bookingPrice-dialog.html',
                        controller: 'BookingPriceDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['BookingPrice', function(BookingPrice) {
                                return BookingPrice.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('bookingPrice', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('bookingPrice.delete', {
                parent: 'bookingPrice',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/bookingPrice/bookingPrice-delete-dialog.html',
                        controller: 'BookingPriceDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['BookingPrice', function(BookingPrice) {
                                return BookingPrice.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('bookingPrice', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }
})();
