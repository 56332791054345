(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('WmsCargo', WmsCargo);

    WmsCargo.$inject = ['$resource', 'DateUtils'];

    function WmsCargo ($resource, DateUtils) {
        var resourceUrl =  'api/wms-cargos/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'getAll': { 
                method: 'GET', 
                url:'api/wms-cargos/all',            
                isArray: true
            },
            'unclaimedCargo': { 
                method: 'GET', 
                url:'api/wms-cargos/unclaimedCargo',
                isArray: true
            },
            'getWmsCargoInventory': { 
                method: 'POST', 
                url:'api/wms-cargos/getWmsCargoInventory',
                isArray: true
            },            
            'claimCargo': { 
                method: 'PUT', 
                url:'api/wms-cargos/claimCargo',
                isArray: false
            },
            'changeReturnHandled': { 
                method: 'PUT', 
                url:'api/wms-cargos/changeReturnHandled'
            },            
            'antiIn': { 
                method: 'GET', 
                url:'api/wms-cargos/antiIn'
            },      
            'batchAntiIn': { 
                method: 'PUT', 
                url:'api/wms-cargos/batchAntiIn',
                isArray: true
            },  
            'getByBookingCtnid': { 
                method: 'GET', 
                url:'api/wms-cargos/getByBookingCtnid'
            },
            'tdPltForceOut': { 
                method: 'GET', 
                url:'api/wms-cargos/tdPltForceOut'
            },
            'delFieldValue': { 
                method: 'GET', 
                url:'api/wms-cargos/delFieldValue'
            },
            'excelPickingCargo': { 
                method: 'POST', 
                url:'api/wms-cargos/excelPickingCargo'
            },               
            'getInventory': { 
                method: 'PUT', 
                url:'api/wms-cargos/inventory',            
                isArray: true
            },
            'inventoryForceOut': { 
                method: 'POST', 
                url:'api/wms-cargos/inventoryForceOut',            
                isArray: true
            },
            'delAll': { 
                method: 'POST', 
                url:'api/wms-cargos/delAll'
            },
            'correctNegativeInventory': { 
                method: 'POST', 
                url:'api/wms-cargos/correctNegativeInventory'
            },            
            'createWmsIn': {
                method: 'POST',
                url:'api/wms-cargos/createWmsIn',
                isArray: true
            },     
            'fifoRegistIn': { 
                method: 'POST', 
                url:'api/wms-cargos/fifoRegistIn',            
                isArray: true
            },
            'returnRegistIn': { 
                method: 'POST', 
                url:'api/wms-cargos/returnRegistIn',            
                isArray: true
            },
            'saveWmsCargoList': { 
                method: 'POST', 
                url:'api/wms-cargos/saveWmsCargoList',            
                isArray: true
            },
            'getInventoryToMove': { 
                method: 'GET', 
                url:'api/wms-cargos/getInventoryToMove',            
                isArray: true
            },
            'getInventoryToCheck': { 
                method: 'GET', 
                url:'api/wms-cargos/getInventoryToCheck',            
                isArray: true
            },
            'getCargosByRef': { 
                method: 'POST', 
                url:'api/wms-cargos/getCargosByRef',            
                isArray: true
            },
            'inventoryQuery': { 
                method: 'PUT', 
                url:'api/wms-cargos/inventoryQuery',            
                isArray: true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.etdFM = DateUtils.convertLocalDateToServer(data.etdFM);
                    data.etdTO = DateUtils.convertLocalDateToServer(data.etdTO);
                    return angular.toJson(data);
                },
                transformResponse: function (resultList) {
                    if (resultList) {
                        resultList = angular.fromJson(resultList);
                        angular.forEach(resultList, function(data){
                            data.gateInTime = DateUtils.convertDateTimeFromServer(data.gateInTime);
                        })                                            
                    }
                    return resultList;    
                }
            },
            'setHandled': { 
                method: 'GET', 
                url:'api/wms-cargos/setHandled',            
                isArray: false
            },
            'updateInCargoCost': { 
                method: 'PUT', 
                url:'api/wms-cargos/updateInCargoCost'
            },       
            'forcedAdjustment': {
                method: 'GET',
                url:'api/wms-cargos/forcedAdjustment'
            },
            'lockWmsInCargos': { 
                method: 'PUT', 
                url:'api/wms-cargos/lockWmsInCargos',    
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.expectedWarehousingTime = DateUtils.convertDateTimeFromServer(data.expectedWarehousingTime);
                    }
                    return data;
                }
            },
            'lockOutCargos': { 
                method: 'PUT', 
                url:'api/wms-cargos/lockOutCargos'
            },
            'lockFmsOutCargos': { 
                method: 'PUT', 
                url:'api/wms-cargos/lockFmsOutCargos'
            },
            'markOutCargoStatus': { 
                method: 'GET', 
                url:'api/wms-cargos/markOutCargoStatus'
            },            
            'delFromOut': { 
                method:'PUT',
                url:'api/wms-cargos-delfrom-out',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.etd = DateUtils.convertLocalDateFromServer(data.etd);
                        data.expectedWarehousingTime = DateUtils.convertDateTimeFromServer(data.expectedWarehousingTime);
                        angular.forEach(data.cargoList, function(cargo){
                            cargo.gateInTime = DateUtils.convertDateTimeFromServer(cargo.gateInTime);
                            cargo.outTime = DateUtils.convertDateTimeFromServer(cargo.outTime);
                        })
                    }
                    return data;
                }
            },       
            'splitCargoCom': { 
                method:'PUT',
                url:'api/wms-cargos/splitCargoCom'
            }, 
            'movement': { 
                method:'PUT',
                url:'api/wms-cargos/movement',            
                isArray: true
            },     
            'unpacking': { 
                method:'PUT',
                url:'api/wms-cargos/unpacking',            
                isArray: true
            },
            'correctInventory': {
                method:'POST',
                url:'api/wms-cargos/correctInventory'
            },
            'getWmsCargoMovementDTO': {
                method:'GET',
                url:'api/wms-cargos/getWmsCargoMovementDTO'
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.gateInTime = DateUtils.convertDateTimeFromServer(data.gateInTime);
                    }
                    return data;
                }

            },
            'delete': {
                method: 'DELETE',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.etd = DateUtils.convertLocalDateFromServer(data.etd);
                        data.expectedWarehousingTime = DateUtils.convertDateTimeFromServer(data.expectedWarehousingTime);
                    }
                    return data;
                }
            },
            'update': { 
                method:'PUT',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.billDate = DateUtils.convertLocalDateToServer(data.billDate);
                    return angular.toJson(data);
                } 
            }
        });
    }
})();
