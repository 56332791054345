(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('HfBillSearchDialogController', HfBillSearchDialogController);

    HfBillSearchDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'HfBill'];

    function HfBillSearchDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, HfBill) {
        var vm = this;

        vm.searchParams = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.doSearch = doSearch;
        vm.payerSelected = payerSelected;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function doSearch () {
            vm.isSaving = true;
            HfBill.advancedSearch(vm.searchParams, onSaveSuccess, onSaveError);            
        }

        function onSaveSuccess (results) {
            // $scope.$emit('autopsApp:hfBillUpdate', result);
            $uibModalInstance.close(results);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
        vm.datePickerOpenStatus.time = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function payerSelected (data) {
            vm.searchParams.venderId = data.id;
        }


    }
})();
