(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('releaseManagement', {
            parent: 'finance',
            url: '/releaseManagement',
            data: {
                authorities: ['FUN_BL_RELEASE'],
                pageTitle: 'global.menu.entities.releaseManagement'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/accountant/accountant/accountants.html',
                    controller: 'AccountantController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('guide');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('booking');
                    return $translate.refresh();
                }]
            }
        })
        .state('shipment-release', {
            parent: 'finance',
            url: '/shipment-release',
            data: {
                authorities: ['FUN_BL_RELEASE'],
                pageTitle: 'global.menu.entities.releaseManagement'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/accountant/accountant/bl-rls.html',
                    controller: 'BlRlsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('guide');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('expressParcel');
                    return $translate.refresh();
                }]
            }
        })
        .state('batch-invoice', {
            parent: 'finance',
            url: '/batch-invoice',
            data: {
                authorities: ['FUN_INVOICE'],
                pageTitle: 'global.menu.entities.batch-invoice'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/accountant/accountant/batch-invoice.html',
                    controller: 'BatchInvoiceController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('accountInvoice');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('batch-invoice-revise', {
            parent: 'finance',
            url: '/batch-invoice-revise',
            data: {
                authorities: ['FUN_INVOICE'],
                pageTitle: 'global.menu.entities.batch-invoice-revise'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/accountant/accountant/batch-invoice-revise.html',
                    controller: 'BatchInvoiceReviseController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('accountInvoice');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('dn-cn-combine', {
            parent: 'finance',
            url: '/dn-cn-combine',
            data: {
                authorities: ['AU_DCN_COMBINE'],
                pageTitle: 'global.menu.entities.dn-cn-combine'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/accountant/accountant/dn-cn-combine.html',
                    controller: 'DnCnCombineController'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('accountInvoice');
                    $translatePartialLoader.addPart('accountPayment');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }

})();