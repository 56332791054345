
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsLogPrivateController', OpsLogPrivateController);

        OpsLogPrivateController.$inject = ['$scope', '$state', '$timeout', '$translate', '$rootScope', '$uibModal',
        'OpsLog', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'WinAES', 'uiGridConstants',
        'GridState', 'OpsLogSearch'];

    function OpsLogPrivateController($scope, $state, $timeout, $translate, $rootScope, $uibModal,
        OpsLog, ParseLinks, AlertService, pagingParams, paginationConstants, WinAES, uiGridConstants,
        GridState, OpsLogSearch) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = 'id';
        vm.direction = 'desc';
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.page = 1;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.isAdvanceSearch = false;
        var gridCopy;
        var pageLoaded = true;
        var filterTimer;
        loadAll();

        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function () {
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 134
            }, function (result) {
                gridStateObj = result;
                if (!gridStateObj || !gridStateObj.id) {
                    pageLoaded = true;
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                pageLoaded = true;
                if (gridCopy) {
                    var filterRequired = false;
                    angular.forEach(gridCopy.columns, function (column) {
                        angular.forEach(column.filters, function (cf) {
                            if (cf.term) {
                                filterRequired = true;
                            }
                        })
                    })
                    if (filterRequired) {
                        layer.msg($translate.instant('global.filterDetectedAlert'));
                        loadAll();
                    }
                }
            })
        });

        $scope.linghangDataMap = { comCodeList: [], transportIdList: [], applicantCertificateTypeList: [], goodsTypeList: [], bigPackList: [], smallPackList: [], jzxList: [], currencyList: [], startPortSmallList: [] };

        function loadAll() {
            if (vm.searchQuery) {
                OpsLogSearch.query({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    query: vm.searchQuery
                }, onSuccess, onError);
            } else {
                var filter = {};
                if (gridCopy) {
                    angular.forEach(gridCopy.columns, function (column) {
                        angular.forEach(column.filters, function (cf) {
                            if (cf.term != null && angular.isDefined(cf.term)) {
                                var fd = column.field;
                                if (cf.condition) {
                                    if (cf.condition == 128 || cf.condition == 256) {
                                        filter[fd + "TO"] = cf.term;
                                    } else if (cf.condition == 32 || cf.condition == 64) {
                                        filter[fd + "FM"] = cf.term;
                                    }
                                }else {
                                    filter[column.field] = cf.term;
                                }
                            }
                        })
                    })
                }
                filter.getAll = true;
                OpsLog.getFilterPage({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, filter, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + vm.direction];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                if (headers('X-Total-Count')) {
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                } else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                angular.forEach($scope.gridOptions.data, function (data) {
                    data.encodeId = WinAES.encode(data.id.toString());
                    data.planInfoList = angular.fromJson(data.planInfoList);
                })
                vm.isAdvanceSearch = false;
                initData();
                resize();
                $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.OPTIONS);
                $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        window.onresize = function () {
            resize();
        }

        function resize() {
            if (!$("#gridId") || !$("#gridId").offset()) {
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 180;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if (tabHeight > winHeight) {
                tabHeight = winHeight;
            }
            if (tabHeight < 600) {
                tabHeight = 600;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }
        function initData() {
            $scope.gridOptions.useExternalSorting = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.useExternalFiltering = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.showColumnFooter = vm.isAdvanceSearch;
        }

        $scope.gridOptions = {
            enableSorting: true, //排序
            useExternalSorting: true,

            enableGridMenu: true, //表格 菜单
            showGridFooter: false, //表格的footer
            showColumnFooter: false, //每列footer

            enableFiltering: true, //高级过滤
            useExternalFiltering: true,

            enableHorizontalScrollbar: 1, //表格的水平滚动条
            enableVerticalScrollbar: 1, //表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            exporterMenuCsv: false,
            exporterMenuPdf: false,
            exporterMenuExcel: false,

            paginationPageSizes: [20, 35, 50, 500, 1000],
            paginationPageSize: 20,
            useExternalPagination: true,

            // minimumColumnSize: 70,
            rowHeight: 100,
            exporterExcelFilename: "Log",
            columnDefs: [{
                field: 'timeStamp',
                width: 220,
                type:'date',
                filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                filters: [{
                    condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                    placeholder: ' >= '
                }, {
                    condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                    placeholder: ' <= '
                }],
                displayName: $translate.instant('autopsApp.opsLog.timeStamp'),
                cellTemplate: "<div class='ui-grid-cell-contents'>{{row.entity.timeStamp | date:'medium'}}</div>"
            },
            {
                field: 'actionType',
                width: 200,
                displayName: $translate.instant('autopsApp.opsLog.actionType')
            },
            {
                field: 'sessiongId',
                width: 200,
                displayName: $translate.instant('autopsApp.opsLog.sessiongId')
            },
            {
                field: 'success',
                width: 80,
                filter: {
                  type: uiGridConstants.filter.SELECT,
                  selectOptions: $rootScope.YES_NO_OPTIONS
                },
                type: "boolean",
                cellTemplate: '<span class="ml-5"><span ng-if="row.entity.success"class="label label-success"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.success"class="label label-info"><span translate="global.no_answer">no_answer</span></span></span>',
                displayName: $translate.instant('autopsApp.opsLog.success')
            },
            {
                field: 'body',
                minWidth: 1000,
                displayName: $translate.instant('autopsApp.opsLog.body'),
                cellTemplate: "<div class='p5' ng-dblclick='grid.appScope.COPYALL(row.entity.body)' data-toggle='tooltip' data-placement='top' title='{{\"entity.action.dbcToCopy\" | translate}}'>{{row.entity.body}}</div>"
            }],
            // {
            //     field: 'userName',
            //     minWidth: 80,
            //     displayName: $translate.instant('autopsApp.opsLog.userName')
            // }
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function ($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 134;
                    GridState.update(gridStateObj, function (result) {
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function (error) {
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function ($event) {
                    if (gridStateObj.gridState) {
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function ($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function ($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "ediInsurance";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function ($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "ediInsurance";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function (gridApi) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function (grid, sortColumns) {
                    if (!$scope.gridOptions.useExternalSorting) {
                        if (sortColumns[sortColumns.length - 1]) {
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function (a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    if (sortColumns[sortColumns.length - 1]) {
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    }
                    loadAll();
                });
                gridApi.core.on.filterChanged($scope, function () {
                    gridCopy = this.grid;
                    if (pageLoaded && $scope.gridOptions.useExternalFiltering) {
                        clearTimeout(filterTimer);
                        filterTimer = setTimeout(function () {
                            loadAll();
                        }, 1000); //查询延时时间，单位ms
                    }
                });
                gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                    vm.page = newPage;
                    vm.itemsPerPage = pageSize;
                    if (pageLoaded) {
                        loadAll();
                    }
                });
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + vm.direction,
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.direction = 'desc';
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.direction = 'desc';
            vm.currentSearch = null;
            vm.transition();
        }
        $scope.amassExportOpsLogs = function () {
            if ($scope.gridOptions.data == null || $scope.gridOptions.data.length == 0) {
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            layer.load(1, { shade: 0.3 });
            OpsLog.amassExportOpsLogs($scope.gridOptions.data, function (result) {
                layer.closeAll();
                var filename = "ops_logs.xlsx";
                $rootScope.DOWNLOADEXCEL(result, filename);
            }, function () {
                layer.msg($translate.instant('global.loadFileFailed'));
                layer.closeAll();
            })
        }

    }

})();
