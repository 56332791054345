(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('FmsWmsController', FmsWmsController);

    FmsWmsController.$inject = ['$rootScope', '$scope', '$state', '$translate', '$http', '$uibModal', '$timeout', '$interval', '$location',
    'WinAES', 'Principal', 'Guide', 'GuideTemplate', 'DataUtils', 'DateUtils',
    'GuideSearch','Booking','OpsFiles', 'uiGridConstants', 'GridState', 'WmsWarehouse'];

    function FmsWmsController($rootScope, $scope, $state, $translate, $http, $uibModal, $timeout, $interval, $location,
        WinAES, Principal, Guide, GuideTemplate, DataUtils, DateUtils,
        GuideSearch, Booking, OpsFiles, uiGridConstants, GridState, WmsWarehouse) {

        var vm = this;

        var pageLoaded = false;
        var filterTimer;

        $rootScope.absUrl = $location.absUrl();
        $scope.shipmentType;


        $scope.isWmsOut = false;
        if($rootScope.absUrl.indexOf('fms-inbound') !== -1){
            $scope.shipmentType = 'fmsin';
        }
        else if($rootScope.absUrl.indexOf('fms-outbound') !== -1){
            $scope.shipmentType = 'fmsout';
            $scope.isWmsOut = true;
        }
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                loadAll();
            });
        }

        vm.getWarehouses = getWarehouses;
        vm.warehouseSelected = warehouseSelected;
        vm.warehouses = null;
        vm.choosedWarehouse = {};
        vm.scanRegist = scanRegist;
        function warehouseSelected (warehouse) {
            vm.choosedWarehouse = warehouse;
            loadAll();
        }
        function getWarehouses () {
            if (vm.warehouses && vm.warehouses.length > 0){
                return;
            }
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false
                }, function (data) {
                vm.warehouses = data;
            });
        }
        function registWithCode (code) {
            if($scope.isWmsOut){
                $uibModal.open({
                    templateUrl: 'app/entities/wms-in/wms-out-register.html',
                    controller: 'WmsOutRegisterController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['WmsIn', function(WmsIn) {
                            return WmsIn.getWmsInByJobNum({
                                companyId: $rootScope.account.companyId,
                                jobNum: code,
                                isOut: $scope.isWmsOut
                            }).$promise;
                        }]
                    }
                }).result.then(function() {
                }, function() {
                });
            }
            else {
                $uibModal.open({
                    templateUrl: 'app/entities/wms-in/wms-in-register.html',
                    controller: 'WmsInRegisterController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: ['WmsIn', function(WmsIn) {
                            return WmsIn.getWmsInByJobNum({
                                companyId: $rootScope.account.companyId,
                                jobNum: code,
                                isOut: $scope.isWmsOut
                            }).$promise;
                        }]
                    }
                }).result.then(function() {
                }, function() {
                });
            }
        }

        $scope.saveTempRegistOut = function(wmsIn) {
            var guide = {};
            guide.id = wmsIn.id;
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-out-register.html',
                controller: 'WmsOutRegisterController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: ['WmsIn', function(WmsIn) {
                        return WmsIn.getWmsInByGuide({isOut: true}, guide).$promise;
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }


        $scope.saveTempRegistIn = function(wmsIn) {
            var guide = {};
            guide.id = wmsIn.id;

            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-register.html',
                controller: 'WmsInRegisterController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: ['WmsIn', function(WmsIn) {
                        return WmsIn.getWmsInByGuide({isOut: false}, guide).$promise;
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }

        vm.predicate = 'id';
        vm.direction = 'desc';
        vm.page = 1;
        vm.itemsPerPage = 20;
        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = null;
        vm.currentSearch = null;
        $scope.isAdvanceSearch = false;
        $scope.jobType = null;

        $scope.setJobType = function(type) {
            $scope.jobType = type;
        }
        var gridCopy;
        function getFilter(){
            var filter = {};
            filter.fmsType = angular.uppercase($scope.shipmentType);
            filter.exClosed = true;
            filter.isExport = null;
            filter.submited = true;
            filter.companyId = $rootScope.account.companyId;
            if($scope.shipmentType == "fmsin"){
                filter.asWmsIn = true;
                filter.isVoid = false;
                if(vm.choosedWarehouse && vm.choosedWarehouse.id){
                    filter.whId = vm.choosedWarehouse.id;
                }
            }
            else if($scope.shipmentType == "fmsout"){
                filter.asWmsOut = true;
                filter.isVoid = false;
                if(vm.choosedWarehouse && vm.choosedWarehouse.id){
                    filter.whId = vm.choosedWarehouse.id;
                }
            }
            return filter;
        }

        function loadAll() {
            if(!vm.choosedWarehouse.id){
                layer.msg($translate.instant('autopsApp.wmsIn.chooseWarehouseAlert'));
                return;
            }
            if (vm.searchQuery) {
                // if($scope.jobType){
                //     Guide.searchByNums({
                //         type: $scope.jobType,
                //         shipmentType: $scope.shipmentType,
                //         companyId: $rootScope.account.companyId
                //     }, vm.searchQuery, onSuccess, onError);
                //     return;
                // }
                GuideSearch.query({
                    query: vm.searchQuery,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    warehouseId: vm.choosedWarehouse.id,
                    shipmentType: $scope.shipmentType,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                var filter = getFilter();
                DataUtils.fillGuideFilter(gridCopy, filter);
                Guide.getFilterPage({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, filter, onSuccess, onError);

            }

            function sort() {
                var result = [(vm.predicate.endWith('TAG')?vm.predicate.replace('TAG', ''):vm.predicate) + ',' + vm.direction];
                return result;
            }

            function onSuccess(data, headers) {
                if(headers('X-Total-Count')){
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                }
                else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                initData();
                resize();
                if($scope.jobType){
                    $scope.isAdvanceSearch = true;
                }
                else {
                    $scope.isAdvanceSearch = false;
                }
                updateByIsAdvanceSearch();
            }

            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }
        function updateByIsAdvanceSearch(searchQuery) {
            $scope.gridOptions.useExternalSorting = !$scope.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.useExternalFiltering = !$scope.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.showColumnFooter = $scope.isAdvanceSearch;
            $scope.gridOptions.enableSelectAll = $scope.isAdvanceSearch;
            $scope.gridOptions.multiSelect = $scope.isAdvanceSearch;

            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
        }
        loadAll();
        function search(searchQuery) {
            if (!searchQuery) {
                return clear();
            }
            vm.page = 1;
            vm.predicate = '_score';
            vm.direction = 'desc';
            vm.currentSearch = searchQuery;
            loadAll();
        }
        function clear() {
            if(vm.predicate == "_score"){
                vm.page = 1;
                vm.predicate = 'id';
                vm.direction =  'desc';
            }
            vm.searchQuery = vm.currentSearch = null;
            $scope.jobType = null;
            loadAll();
        }

        window.onresize = function(){
            resize();
        }

        function resize() {
            if(!$("#gridId") || !$("#gridId").offset()){
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 200;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 300){
                tabHeight = 300;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        $scope.searchGuidePop = function() {
            if(!vm.choosedWarehouse.id){
                layer.msg($translate.instant('autopsApp.wmsIn.chooseWarehouseAlert'));
                return;
            }
            var searchParams = getFilter();
            $uibModal.open({
                templateUrl: 'app/entities/guide/guide-search-dialog.html',
                controller: 'GuideSearchDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(results) {
                $scope.gridOptions.data = results;
                initData();
                $scope.isAdvanceSearch = vm.currentSearch = true;
                $scope.gridOptions.totalItems = results.length;
                updateByIsAdvanceSearch();
                resize();
            }, function() {
            });
        }

        $scope.volumesTotal = null;
        function initData() {
            var vol = 0, gw = 0, pkg = 0;
            angular.forEach($scope.gridOptions.data, function(data){
                if(!data.isVoid && !data.localTimeTwo){
                    if(data.pkgNum){
                        pkg += data.pkgNum;
                    }
                    if(data.gw){
                        gw += data.gw;
                    }
                    if(data.vol){
                        vol += data.vol;
                    }
                }
                DataUtils.addGuideTag(data);
            })
            $scope.volumesTotal = gw.toFixed(2) + "KGS " + vol.toFixed(3) + "CBM " + pkg + "PKGS";
            if(vm.page != 1){
                return;
            }
            angular.forEach($scope.gridOptions.columnDefs, function(col, index){
                // if(index == 3){
                //     if($rootScope.workType != null){
                //         col.visible = true;
                //     }
                //     else {
                //         col.visible = false;
                //     }
                // }
            })
        }
        $scope.choosedId = null;
        $scope.setChoosedId = function(id){
            $scope.choosedId = id;
        }
        var memoCopy;
        $scope.copyMemo = function(guide) {
            memoCopy = angular.copy(guide.bookingMemo);
        }
        $scope.saveMemo = function(guide) {
            if(memoCopy != guide.bookingMemo){
                Guide.upateMemo({
                    memoString: guide.bookingMemo,
                    type: "TABLE_BOOKING",
                    companyId: guide.companyId,
                    guideId: guide.id
                }, function(){
                    layer.msg($translate.instant('global.save_success'));
                }, function(){
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                });
            }
        }
        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: true,

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: false,//每列footer

            enableFiltering: true,//高级过滤
            useExternalFiltering: true,

            enableHorizontalScrollbar : 1,//表格的水平滚动条
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            enableRowHeaderSelection : true,  //行选中
            enableRowSelection: true, //行选中
            enableSelectAll: true, //全部选中
            enableFullRowSelection : false, //行任意位置选中
            multiSelect: true,//多行选中

            exporterMenuCsv : false,
            exporterMenuPdf : false,
            exporterMenuLabel : "Export",
            exporterExcelFilename: $translate.instant('autopsApp.guide.home.title') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],

            paginationPageSizes: [20, 35, 50, 100, 200],
            paginationPageSize: 20,
            useExternalPagination: true,

            minimumColumnSize: 70,
            columnDefs: [
                { field: 'id',
                    width: 100,
                    displayName: "",
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<div class="btn-group flex-btn-group-container"ng-click="grid.appScope.setChoosedId(row.entity.id)"><button type="button"ng-if="grid.appScope.isWmsOut"ng-disabled="!row.entity.jobNum"ng-click="grid.appScope.saveTempRegistOut(row.entity)"class="btn btn-primary btn-grid"><span class="glyphicon glyphicon-log-out"></span><span class="hidden-xs hidden-sm"translate="autopsApp.wmsIn.detail.registerOut"></span></button><button type="button"ng-if="!grid.appScope.isWmsOut"ng-disabled="!row.entity.jobNum" ng-click="grid.appScope.saveTempRegistIn(row.entity)"class="btn btn-primary btn-grid"><span class="glyphicon glyphicon-log-in"></span><span class="hidden-xs hidden-sm"translate="autopsApp.wmsIn.detail.register"></span></button></div>'},
                { field: 'jobNum',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.guide.jobNum'),
                    cellTemplate: '<span class="grid-text"ng-class="row.entity.id==grid.appScope.choosedId?\'choosedColor\':\'\'"ng-click="grid.appScope.setChoosedId(row.entity.id)"><a href=""ng-click="grid.appScope.saveTempRegistOut(row.entity)"ng-if="grid.appScope.isWmsOut">{{row.entity.jobNum}}</a><a href=""ng-click="grid.appScope.saveTempRegistIn(row.entity)"ng-if="!grid.appScope.isWmsOut">{{row.entity.jobNum}}</a></span>'},
                { field: 'bookingMemo',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.guide.table.memo'),
                    cellTemplate: '<input type="text" class="grid-input" ng-model="row.entity.bookingMemo" ng-focus="grid.appScope.copyMemo(row.entity)" ng-blur="grid.appScope.saveMemo(row.entity)" maxlength="255"/>'},
                { field: 'lastModifiedTime',
                    displayName: $translate.instant('autopsApp.guide.status'),
                    minWidth:100,
                    enableFiltering: false,
                    cellTemplate: '<span class="ml-5 label label-info">{{grid.appScope.SHOW_STATUS_GD(row.entity.status)}}</span>'},
                { field: 'volumes',
                    displayName: $translate.instant('autopsApp.guide.volumes'),
                    minWidth:200,
                    footerCellTemplate: '<div class="ml-5">{{grid.appScope.volumesTotal}}</div>'},
                { field: 'onHold',
                    displayName: $translate.instant('autopsApp.guide.onHold'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span class="ml-5" ng-click="grid.appScope.problemShipmentHandle(row.entity, null)"><span ng-if="row.entity.onHold"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.onHold"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'customerName',
                    displayName: $translate.instant('autopsApp.guide.bookingPartyName'),
                    minWidth:150},
                { field: 'shipperName',
                    displayName: $translate.instant('autopsApp.guide.shipperName'),
                    minWidth:150},
                { field: 'shipmentType',
                    displayName: $translate.instant('autopsApp.guide.shipmentType'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.FMS_TYPES
                    },
                    minWidth:150,
                    cellTemplate: '<span class="ml-5"><span translate="global.{{row.entity.shipmentType}}"ng-if="row.entity.shipmentType"></span></span>'},
                { field: 'soNum',
                    displayName: $translate.instant('autopsApp.guide.soWmsInNum'),
                    minWidth:150},
                { field: 'poNum',
                    displayName: $translate.instant('autopsApp.booking.poNum'),
                    minWidth:100},
                { field: 'polName',
                    displayName: $translate.instant('autopsApp.booking.polNameE'),
                    minWidth:100},
                { field: 'podName',
                    displayName: $translate.instant('autopsApp.booking.podNameE'),
                    minWidth:100},
                { field: 'destName',
                    displayName: $translate.instant('autopsApp.booking.destNameE'),
                    minWidth:100},
                { field: 'destCountryCode',
                    displayName: $translate.instant('autopsApp.guide.destCountryCode'),
                    minWidth:100},
                { field: 'shiptoPostcode',
                    displayName: $translate.instant('autopsApp.guide.destPostCode'),
                    minWidth:100},
                { field: 'deliverytoId',
                    displayName: $translate.instant('autopsApp.booking.deliverytoId'),
                    minWidth:100},
                { field: 'deliveryTo',
                    displayName: $translate.instant('autopsApp.booking.deliveryTo'),
                    minWidth:100},
                { field: 'etd',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.guide.etd'),
                    minWidth:120,
                    cellTemplate: '<span class="grid-text">{{row.entity.etd | date:\'mediumDate\'}}</span>'},
                { field: 'otherTimeOne',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.guide.wmsInTime'),
                    minWidth:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.otherTimeOne | date:\'medium\'}}">{{row.entity.otherTimeOne | date:\'mediumDate\'}}</span>'},
                { field: 'otherTimeTwo',
                    displayName: $translate.instant('autopsApp.guide.wmsOutTime'),
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.otherTimeTwo | date:\'medium\'}}">{{row.entity.otherTimeTwo | date:\'mediumDate\'}}</span>'},
                { field: 'carrier',
                    displayName: $translate.instant('autopsApp.guide.carrier'),
                    minWidth:100},
                { field: 'mblNum',
                    displayName: $translate.instant('autopsApp.guide.expressMbl'),
                    minWidth:150},
                { field: 'salesName',
                    displayName: $translate.instant('autopsApp.guide.salesName'),
                    minWidth:100},
                { field: 'cusName',
                    displayName: $translate.instant('autopsApp.guide.cusName'),
                    minWidth:100},
                { field: 'opName',
                    displayName: $translate.instant('autopsApp.guide.opName'),
                    minWidth:100},
                { field: 'vsl',
                    displayName: $translate.instant('autopsApp.guide.vsl'),
                    minWidth:100},
                { field: 'voy',
                    displayName: $translate.instant('autopsApp.guide.voy'),
                    minWidth:100},
                { field: 'createdTime',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.guide.home.createdTime'),
                    minWidth:160,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.createdTime | date:\'medium\'}}">{{row.entity.createdTime | date:\'mediumDate\'}}</span>'},
                { field: 'pkgNum',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.guide.pkgNum'),
                    minWidth:80},
                { field: 'gw',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.guide.gw'),
                    minWidth:80},
                { field: 'vol',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.guide.vol'),
                    minWidth:80},
                { field: 'palletNum',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.booking.palletNum'),
                    minWidth:80},
                { field: 'cargoType',
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CARGOTYPES
                    },
                    displayName: $translate.instant('autopsApp.guide.cargoType'),
                    cellTemplate: '<span class="label label-info ml-5">{{row.entity.cargoTypeLabel}}</span>'},
                { field: 'plConfirmed',
                    displayName: $translate.instant('autopsApp.booking.plConfirmed'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.plConfirmed"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.plConfirmed"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'hblNum',
                    displayName: $translate.instant('autopsApp.guide.hblNum'),
                    minWidth:150},
                { field: 'cargoReadyDate',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.guide.wmsInDate'),
                    minWidth:120,
                    cellTemplate: '<span class="grid-text">{{row.entity.cargoReadyDate | date:\'mediumDate\'}}</span>'},
                { field: 'bookingBy',
                    displayName: $translate.instant('autopsApp.guide.bookingBy'),
                    minWidth:150},
                { field: 'productName',
                    displayName: $translate.instant('autopsApp.guide.productName'),
                    minWidth:150},
                { field: 'porName',
                    displayName: $translate.instant('autopsApp.guide.porName'),
                    minWidth:150},
                { field: 'customsType',
                    displayName: $translate.instant('autopsApp.guide.customsType'),
                    minWidth:150},
                { field: 'cargoName',
                    displayName: $translate.instant('autopsApp.guide.cargoName'),
                    minWidth:150},
                { field: 'sayCtns',
                    displayName: "CTN/MAWB#",
                    minWidth:150},
                { field: 'whName',
                    displayName: $translate.instant('autopsApp.guide.warehouse'),
                    minWidth:130},
                { field: 'otherTimeOneTAG',
                    displayName: $translate.instant('autopsApp.guide.wmsInTime'),
                    minWidth:100,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span ng-class="row.entity.otherTimeOneTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.otherTimeOneTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'otherTimeTwoTAG',
                    displayName: $translate.instant('autopsApp.guide.wmsOutTime'),
                    minWidth:100,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span ng-class="row.entity.otherTimeTwoTAG?\'label-primary\':\' label-danger\'"translate="{{row.entity.otherTimeTwoTAG?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = getGridId();
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                    if(!$scope.gridOptions.useExternalSorting){
                        if(sortColumns[sortColumns.length - 1]){
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function(a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    if(sortColumns[sortColumns.length - 1]){
                        var fd = sortColumns[sortColumns.length - 1].field;
                        if(fd.length > 3 && fd.indexOf("Str") == fd.length - 3){
                            fd = fd.substring(0, fd.length - 3);
                        }
                        vm.predicate = fd;
                        vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    }
                    loadAll();
                });
                gridApi.core.on.filterChanged($scope, function() {
                    gridCopy = this.grid;
                    if(pageLoaded && $scope.gridOptions.useExternalFiltering){
                        clearTimeout(filterTimer);
                        filterTimer = setTimeout(function(){
                            loadAll();
                        }, 1000);//查询延时时间，单位ms
                    }
                });
                gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                    vm.page = newPage;
                    vm.itemsPerPage = pageSize;
                    if(pageLoaded){
                        loadAll();
                    }
                });
            }
        }
        function getGridId(){
            if ($scope.shipmentType == "fmsin"){
                return 104;
            }
            else if ($scope.shipmentType == "fmsout"){
                return 105;
            }
        }

        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: getGridId()
            }, function(result){
                gridStateObj = result;
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    pageLoaded = true;
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                pageLoaded = true;
                if(gridCopy){
                    var filterRequired = false;
                    angular.forEach(gridCopy.columns, function(column){
                        angular.forEach(column.filters, function(cf){
                            if(cf.term || cf.term == false){
                                filterRequired = true;
                            }
                        })
                    })
                    if(filterRequired){
                        layer.msg($translate.instant('global.filterDetectedAlert'));
                        loadAll();
                    }
                }
            })
        });

        $scope.deleteFromList = function (row) {
            var index = $scope.gridOptions.data.indexOf(row);
            $scope.gridOptions.data.splice(index, 1);
        }

        vm.scanCode = null;
        $(document).keydown(function(event){
            if(vm.scanCode && vm.scanCode.length > 3 && event.keyCode == 13){
                registWithCode(vm.scanCode);
                vm.scanCode = null;
                $timeout(function (){
                    $("#field_manualBarCode").focus();
                });
            }
        });
        vm.scannerScan = false;
        $scope.scannerScan = function (val) {
            vm.scannerScan = val;
            if(val){
                $timeout(function (){
                    $("#field_manualBarCode").focus();
                });
            }
        }

        function scanRegist () {
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-dialog.html',
                controller: 'WmsInDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md'
            }).result.then(function(code) {
                registWithCode(code);
            }, function() {
            });
        }


    }

})();
