(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('WriteOffRatesSearch', WriteOffRatesSearch);

    WriteOffRatesSearch.$inject = ['$resource'];

    function WriteOffRatesSearch($resource) {
        var resourceUrl =  'api/_search/write-off-rates/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
