(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('OpsFiles', OpsFiles);

    OpsFiles.$inject = ['$resource','DateUtils'];

    function OpsFiles($resource, DateUtils) {
        return $resource('', {}, {
            'getCharts': {
                method:'PUT',
                url:'api/chart/getCharts',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.endDate = DateUtils.convertLocalDateToServer(data.endDate);
                    return angular.toJson(data);
                }
            },
            'getBaFile': {
                method:'GET',
                url:'api/opsBAFile/:id',
                params:{id:'@id'},
                isArray:true
            },
            'delBaFile':{
                method:'POST',
                url:'api/opsBAFile/delBaFile',
            },
            'getClientFile': {
                method:'GET',
                url:'api/opsClientFile/:id',
                params:{id:'@id'},
                isArray:true
            },
            'delClientFile':{
                method:'POST',
                url:'api/opsClientFile/delClientFile',
            },
            'getAgentFile': {
                method:'GET',
                url:'api/opsAgentFile/:id',
                params:{id:'@id'},
                isArray:true
            },
            'delAgentFile':{
                method:'POST',
                url:'api/opsAgentFile/delAgentFile',
            },
            'getTruckerFile': {
                method:'GET',
                url:'api/opsTruckerFile/:id',
                params:{id:'@id'},
                isArray:true
            },
            'delTruckerFile':{
                method:'POST',
                url:'api/opsTruckerFile/delTruckerFile',
            },
            'getBookingFiles':{
                method:'GET',
                url:'api/getBookingFiles',
                params:{bookingId:'@bookingId'},
                isArray:true,
            },
            'getClientFileUrl':{
                method:'GET',
                url:'api/ops-files/getClientFileUrl'
            },
            'viewDeliveryGuideFile':{
                method:'POST',
                url:'api/guide/viewDevliery',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'viewBookinSendedFile':{
                method:'GET',
                url:'api/booking/viewfirstSendedBK',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'previewPdf':{
                method:'GET',
                url:'edi/ops-files/previewPdf',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },            
            'delOssFile':{
                method:'POST',
                url:'api/delOssFile'
            },
            'getSalesGuarantee':{
                method:'PUT',
                url:'api/stats/getSalesGuarantee',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    data.etdFM = DateUtils.convertLocalDateToServer(data.etdFM);
                    data.etdTO = DateUtils.convertLocalDateToServer(data.etdTO);
                    data.revDeadLineFM = DateUtils.convertLocalDateToServer(data.revDeadLineFM);
                    data.revDeadLineTO = DateUtils.convertLocalDateToServer(data.revDeadLineTO);
                    return angular.toJson(data);
                }
            },
            'shareFile':{
                method:'GET',
                url:'api/commonFiles/shareFile'
            },            
            'addToIm':{
                method:'GET',
                url:'api/liveChat/addToIm'
            },
            'sendMsg':{
                method:'GET',
                url:'api/sendMsg'
            },
            'comStats':{
                method:'POST',
                url:'api/stats/comStats',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    data.etdFM = DateUtils.convertLocalDateToServer(data.etdFM);
                    data.etdTO = DateUtils.convertLocalDateToServer(data.etdTO);
                    return angular.toJson(data);
                }
            }, 
            'comStatsByNums':{
                method:'POST',
                url:'api/stats/comStatsByNums',
                isArray:true
            },        
            'statsByJobNum':{
                method:'POST',
                url:'api/stats/byJobNum',
                isArray:false,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    data.etdFM = DateUtils.convertLocalDateToServer(data.etdFM);
                    data.etdTO = DateUtils.convertLocalDateToServer(data.etdTO);
                    return angular.toJson(data);
                }
            }, 
            'incomeList':{
                method:'POST',
                url:'api/stats/incomeList',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'statsPerformance':{
                method:'POST',
                url:'api/stats/performance',
                isArray:false,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    data.etdFM = DateUtils.convertLocalDateToServer(data.etdFM);
                    data.etdTO = DateUtils.convertLocalDateToServer(data.etdTO);
                    return angular.toJson(data);
                }
            },   
            'receiptPayment':{
                method:'POST',
                url:'api/stats/receiptPayment',
                isArray:false,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.etdFM = DateUtils.convertLocalDateToServer(data.etdFM);
                    data.etdTO = DateUtils.convertLocalDateToServer(data.etdTO);
                    return angular.toJson(data);
                }
            },     
            'statsBizState':{
                method:'POST',
                url:'api/stats/bizState',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    return angular.toJson(data);
                }
            },     
            'copyFile':{
                method:'GET',
                url:'api/ops-files/copyFile'
            },
            'copyToWms':{
                method:'GET',
                url:'api/ops-files/copyToWms'
            },
            'previewAccountPaymentReport':{
                method:'POST',
                url:'api/accountPayments/generateReport/:fileType',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'previewAccountVoucherReport':{
                method:'POST',
                url:'api/accountVoucher/generateReport/:fileType',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    data= angular.copy(data);
                    angular.forEach(data, function(voucher){
                        voucher.makeDate = DateUtils.convertLocalDateToServer(voucher.makeDate);
                    })                    
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'previewTrialBalanceReport':{
                method:'POST',
                url:'api/trialBalance/generateReport/:fileType/:dateFrom/:dateTo',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'previewAccountCashReport':{
                method:'POST',
                url:'api/accountCash/generateReport',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'previewAccountCashForexReport':{
                method:'POST',
                url:'api/accountCashForex/generateReport',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'generateReportBalanceSheet':{
                method:'PUT',
                url:'api/balanceSheet/generateReport',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'generateReportIncomeStatements':{
                method:'PUT',
                url:'api/incomeStatements/generateReport',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'generateReportCashFlowsStatement':{
                method:'PUT',
                url:'api/cashFlowsStatement/generateReport',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'getBalanceSheet':{
                method:'GET',
                url:'api/balanceSheet',
                isArray:false
            },
            'getIncomeStatements':{
                method:'PUT',
                url:'api/incomeStatements',
                transformRequest: function (data) {
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    return angular.toJson(data);
                }
            },
            'projectProfitStatement':{
                method:'PUT',
                url:'api/projectProfitStatement',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.makeDateFM = DateUtils.convertLocalDateToServer(data.makeDateFM);
                    data.makeDateTO = DateUtils.convertLocalDateToServer(data.makeDateTO);
                    return angular.toJson(data);
                }
            },
            'excelProjectStatement':{
                method:'POST',
                url:'api/excel/excelProjectStatement',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'exportShippingReport': {
                method: 'POST',
                url:'api/excel/exportShippingReport',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'exportTdPlts':{
                method:'GET',
                url:'api/excel/exportTdPlts',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'excelExpenseDetails':{
                method:'POST',
                url:'api/excel/excelExpenseDetails',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'expenseDetails':{
                method:'PUT',
                url:'api/expenseDetails',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.makeDateFM = DateUtils.convertLocalDateToServer(data.makeDateFM);
                    data.makeDateTO = DateUtils.convertLocalDateToServer(data.makeDateTO);
                    return angular.toJson(data);
                }
            },
            'getCashFlowsStatement':{
                method:'PUT',
                url:'api/cashFlowsStatement',
                isArray:false,
                transformRequest: function (data) {
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    return angular.toJson(data);
                }
            },
            'generateManagementFeeContent':{
                method:'POST',
                url:'api/managementFeeContent/generateReport',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'exportManagementFee':{
                method:'POST',
                url:'api/excel/exportManagementFee',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },            
            'generateAccountGeneralReport':{
                method:'POST',
                url:'api/accountGenerals/generateReport',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'loadInvoiceFiles':{
                method:'GET',
                url:'api/accountInvoice/getFiles',
                isArray:true
            },
            'loadPaymentFiles':{
                method:'GET',
                url:'api/accountPayments/getFiles',
                isArray:true
            },
            'generateClientTrackingReport':{
                method:'PUT',
                url:'api/tracking/generateReport',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'generateTrackingAttachment':{
                method:'PUT',
                url:'api/tracking/generateAttachment'
            },
            'loadTrackingFiles':{
                method:'GET',
                url:'api/tracking/loadFiles',
                isArray:true
            },
            'generateMonthlyStatementReport':{
                method:'POST',
                url:'api/monthlystatement/generateReport',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    if(data.ratesList){
                        angular.forEach(data.ratesList, function(rate){
                            rate.etd = DateUtils.convertLocalDateToServer(rate.etd);
                            rate.eta = DateUtils.convertLocalDateToServer(rate.eta);
                            rate.deadline = DateUtils.convertLocalDateToServer(rate.deadline);
                            rate.deliveryDate = DateUtils.convertLocalDateToServer(rate.deliveryDate);
                            rate.billMakeDate = DateUtils.convertLocalDateToServer(rate.billMakeDate);        
                            rate.createDate = DateUtils.convertLocalDateToServer(rate.createDate);
                            rate.billDate = DateUtils.convertLocalDateToServer(rate.billDate);
                            rate.cargoReadyDate = DateUtils.convertLocalDateToServer(rate.cargoReadyDate);    
                        })
                    }
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'bxAccountStatement':{
                method:'POST',
                url:'api/monthlystatement/bxAccountStatement',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    if(data.ratesList){
                        angular.forEach(data.ratesList, function(rate){
                            rate.etd = DateUtils.convertLocalDateToServer(rate.etd);
                            rate.eta = DateUtils.convertLocalDateToServer(rate.eta);
                            rate.deadline = DateUtils.convertLocalDateToServer(rate.deadline);
                            rate.deliveryDate = DateUtils.convertLocalDateToServer(rate.deliveryDate);
                            rate.billMakeDate = DateUtils.convertLocalDateToServer(rate.billMakeDate);     
                        })
                    }
                    if(data.autoCheck){
                        data.autoCheck.dateFrom = DateUtils.convertLocalDateToServer(data.autoCheck.dateFrom);
                        data.autoCheck.dateTo = DateUtils.convertLocalDateToServer(data.autoCheck.dateTo);     
                    }
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'generateMonthlyPaymentReport':{
                method:'POST',
                url:'api/guideRates/generateMonthlypaymentReport',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'generateMonthlyStatementAttachment':{
                method:'POST',
                url:'api/monthlystatement/getAttachment',
            },
            'statProfitFile':{
                method:'POST',
                url:'api/stat/generateReport',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    if(null!=data.profitStatByJobNumDTOs){
                        data.profitStatByJobNumDTOs.eta= DateUtils.convertLocalDateToServer(data.profitStatByJobNumDTOs.eta);
                        data.profitStatByJobNumDTOs.etd = DateUtils.convertLocalDateToServer(data.profitStatByJobNumDTOs.etd);
                    }
                    return angular.toJson(data);
                 },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'generateSummaryReport':{
                method:'POST',
                url:'api/stat/generateSummaryReport',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'bizAnalysisFile':{
                method:'POST',
                url:'api/bizAnalysis/generateReport/:dateFrom/:dateTo/:fileType',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'statsBookingByJobNum':{
                method:'POST',
                url:'api/stats/booking/byJobNum',
                isArray:false,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    return angular.toJson(data);
                }
            },
            'statsBookingByBookingAgent':{
                method:'POST',
                url:'api/stats/booking/byBookingAgent',
                isArray:false,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    return angular.toJson(data);
                }
            },
            'statsBookingTruckByJobNum':{
                method:'POST',
                url:'api/stats/truck/byJobNum',
                isArray:false,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    return angular.toJson(data);
                }
            },
            'statsBookingTruckByForwarder':{
                method:'POST',
                url:'api/stats/truck/byForwarder',
                isArray:false,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    return angular.toJson(data);
                }
            },
            'statsBookingFile':{
                method:'POST',
                url:'api/stats/booking/generateReport/:dateFrom/:dateTo/:statBy/:fileType',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'plSummary':{
                method:'POST',
                url:'api/stat/plSummary',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'statsBookingTruckFile':{
                method:'POST',
                url:'api/stats/bookingTruck/generateReport/:dateFrom/:dateTo/:statBy/:fileType',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'opPlan':{
                method:'POST',
                url:'api/report/opPlan',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'goGetTrackingInfo':{
                method:'GET',
                isArray:false,
                url:'api/trackingCtnNum'
            },
            'getNews':{
                method:'GET',
                isArray:true,
                url:'api/getNews',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    for(var i = 0; i < data.length; i ++){
                        data[i].announcementTime = DateUtils.convertLocalDateFromServer(data[i].announcementTime);
                    }
                    return data;
                }   
            },
            'getPageNews':{
                method:'GET',
                isArray:true,
                url:'api/ops-news/getPageNews'
                // ,
                // transformResponse: function (data) {
                //     data = angular.fromJson(data);
                //     for(var i = 0; i < data.length; i ++){
                //         data[i].announcementTime = DateUtils.convertLocalDateFromServer(data[i].announcementTime);
                //     }
                //     return data;
                // }   
            },      
            'getNotices':{
                method:'GET',
                isArray:true,
                url:'api/getNotices',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    for(var i = 0; i < data.length; i ++){
                        data[i].announcementTime = DateUtils.convertLocalDateFromServer(data[i].announcementTime);
                    }
                    return data;
                }   
            }, 
            'getPageNotices':{
                method:'GET',
                isArray:true,
                url:'api/ops-news/getPageNotices'
                // ,
                // transformResponse: function (data) {
                //     data = angular.fromJson(data);
                //     for(var i = 0; i < data.length; i ++){
                //         data[i].announcementTime = DateUtils.convertLocalDateFromServer(data[i].announcementTime);
                //     }
                //     return data;
                // }   
            },         
            'getNewsCount':{
                method:'GET',
                url:'api/getNewsCount'
            },
            'saveNews':{
                method:'POST',
                url:'api/saveNews',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.announcementTime = DateUtils.convertLocalDateToServer(data.announcementTime);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.announcementTime = DateUtils.convertLocalDateFromServer(data.announcementTime);
                    return data;
                }    
            },
            'getOneNews':{
                method:'GET',
                url:'api/opsNews/getOneNews',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.announcementTime = DateUtils.convertLocalDateFromServer(data.announcementTime);
                    return data;
                }   
            },
            'deleteNews':{
                method:'GET',
                url:'api/deleteNews'
            },
            'clearAllAlerts':{
                method:'POST',
                url:'api/deleteAlerts'
            },
            'getCommonFiles':{
                method:'GET',
                isArray:true,
                url:'api/commonFiles'
            },
            'scheduleTest':{
                method:'GET',
                url:'api/scheduleTestApi'
            },
            'deleteCommonFiles':{
                method:'DELETE',
                url:'api/commonFiles'
            },
            'getVouchersSyn':{
                method:'POST',
                isArray:true,
                url:'api/stat/vouchersSyn'
            },
            'generateVouchersSyn':{
                method:'POST',
                responseType: 'arraybuffer',
                url:'api/generateReport/vouchersSyn/:dateFrom/:dateTo/:fileType',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'loadItemTemplate':{
                method:'GET',
                url:'api/ops-item/loadTempalte',
                isArray:false
            },
            'generateWmsInPaper':{
                method:'POST',
                responseType: 'arraybuffer',
                url:'api/wmsInPaper/generateReport',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'generateWmsInPaperBatch':{
                method:'POST',
                responseType: 'arraybuffer',
                url:'api/wmsInPaper/generateWmsInPaperBatch',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'receivingNote':{
                method:'POST',
                responseType: 'arraybuffer',
                url:'api/wmsInPaper/receivingNote',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'receivingNoteGuide':{
                method:'POST',
                responseType: 'arraybuffer',
                url:'api/wmsInPaper/receivingNoteGuide',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'generateAccountBalanceReport':{
                method:'POST',
                url:'api/accountBalance/generateReport',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;    
                }   
            },
            'getWmsGoodsFiles':{
                method:'GET',
                url:'api/getWmsGoodsFiles',
                isArray:true,
            },
            'generatePerformanceReport':{
                method:'POST',
                url:'api/performance/generateReport',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;    
                }   
            },
            'getFilesList':{
                method:'GET',
                url:'api/ops-files/getFilesList',
                isArray:true
            },
            'getFilesListWithFullPath':{
                method:'GET',
                url:'api/ops-files/getFilesListWithFullPath',
                isArray:true
            },
            'getReceiptFile':{
                method:'GET',
                url:'api/ops-files/getReceiptFile',
                isArray:true
            },
            'getCustomDeclarationFiles':{
                method:'GET',
                url:'api/getCustomDeclarationFiles',
                isArray:true,
            },
            'shipmentsAging': {
                method: 'POST',
                url:'api/excel/shipmentsAging',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'jdxtBlReleaseReport': {
                method: 'POST',
                url:'api/excel/jdxtBlReleaseReport',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'blReleaseReport': {
                method: 'POST',
                url:'api/excel/blReleaseReport',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'clientCreditRating': {
                method: 'POST',
                url:'api/excel/clientCreditRating',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },            
            'customizedChargesReport': {
                method: 'POST',
                url:'api/excel/customizedChargesReport',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'amassChargesExcel': {
                method: 'GET',
                url:'api/excel/amassChargesExcel',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'dadianWmsInventory': {
                method: 'POST',
                url:'api/excel/dadianWmsInventory',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'pgsBalanceSheet': {
                method: 'GET',
                url:'api/excel/pgsBalanceSheet',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'assistAccountBalance': {
                method: 'POST',
                url:'api/excel/assistAccountBalance',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'balanceReport': {
                method: 'GET',
                url:'api/excel/balanceReport',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'zipFiles': {
                method: 'POST',
                url:'api/ops-files/zipFiles',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },            
            'zipGuideOssFiles': {
                method: 'GET',
                url:'api/ops-files/zipGuideOssFiles',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'zipWarehousingDataFiles': {
                method: 'GET',
                url:'api/ops-files/zipWarehousingDataFiles',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'zipGuideSubOssFiles': {
                method: 'GET',
                url:'api/ops-files/zipGuideSubOssFiles',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'zipGuidesFilesByType': {
                method: 'POST',
                url:'api/ops-files/zipGuidesFilesByType',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'shipmentAging':{
                method:'POST',
                url:'api/stats/shipmentAging',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    data.etdFM = DateUtils.convertLocalDateToServer(data.etdFM);
                    data.etdTO = DateUtils.convertLocalDateToServer(data.etdTO);
                    return angular.toJson(data);
                }
            },
            'subsidiaryAccountDetails': {
                method: 'POST',
                url:'api/excel/subsidiaryAccountDetails',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'excelVoucherContent': {
                method: 'POST',
                url:'api/excelVoucherContent',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'exportVouchers': {
                method: 'POST',
                url:'api/dbf/exportVouchers',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'exportCnVouchers': {
                method: 'POST',
                url:'api/dbf/exportCnVouchers',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'exportDnVouchers': {
                method: 'POST',
                url:'api/dbf/exportDnVouchers',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'excelOpsVender': {
                method: 'POST',
                url:'api/excelOpsVender',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'exportOpsPorts': {
                method: 'GET',
                url:'api/excel/exportOpsPorts',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'exportOpsItems': {
                method: 'GET',
                url:'api/excel/exportOpsItems',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            }, 
            'excelExpressParcel': {
                method: 'POST',
                url:'api/excelExpressParcel',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'exportExpressParcel': {
                method: 'POST',
                url:'api/excel/exportExpressParcel',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'parcelSubTrackings': {
                method: 'POST',
                url:'api/excel/parcelSubTrackings',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'excelWmsIn': {
                method: 'POST',
                url:'api/excelWmsIn',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'excelWmsInventory': {
                method: 'POST',
                url:'api/excelWmsInventory',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },            
            'excelProfitStatByUserDTO': {
                method: 'POST',
                url:'api/excelProfitStatByUserDTO',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'exportGridData': {
                method: 'POST',
                url:'api/excel/exportGridData',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'uifStatsSheet': {
                method: 'POST',
                url:'api/excel/uifStatsSheet',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'statsCustomReport': {
                method: 'POST',
                url:'api/excel/statsCustomReport',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'agingCustomReport': {
                method: 'POST',
                url:'api/excel/agingCustomReport',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'haiYuanGpSheet': {
                method: 'POST',
                url:'api/excel/haiYuanGpSheet',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },          
            'exportGpSheet': {
                method: 'POST',
                url:'api/excel/exportGpSheet',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'yyComStatsReport': {
                method: 'POST',
                url:'api/excel/yyComStatsReport',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'yyProfitStatement': {
                method: 'POST',
                url:'api/excel/yyProfitStatement',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'customizedReport': {
                method: 'POST',
                url:'api/stats/customizedReport',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'exportRechargeReport': {
                method: 'POST',
                url:'api/excel/exportRechargeReport',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },          
            'shipmentsReport': {
                method: 'POST',
                url:'api/excel/shipmentsReport',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'plDetailedSummary': {
                method: 'POST',
                url:'api/excel/plDetailedSummary',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'getCompanyLogo': {
                method: 'GET',
                url:'api/account/getCompanyLogo',
                isArray:false
            },
            'getCompanyByUrl': {
                method: 'GET',
                url:'api/account/getCompanyByUrl',
                isArray:false
            },            
            'excelAutoCheck': {
                method: 'POST',
                url:'api/excel/autoCheck',
                isArray:false,
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    angular.forEach(data.contents, function(rate){
                        rate.etd = DateUtils.convertLocalDateToServer(rate.etd);
                        rate.etd = DateUtils.convertLocalDateToServer(rate.etd);
                        rate.eta = DateUtils.convertLocalDateToServer(rate.eta);
                        rate.deadline = DateUtils.convertLocalDateToServer(rate.deadline);
                        rate.deliveryDate = DateUtils.convertLocalDateToServer(rate.deliveryDate);
                        rate.billMakeDate = DateUtils.convertLocalDateToServer(rate.billMakeDate);
                    })               
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'excelMonthlyStatement': {
                method: 'POST',
                url:'api/excelMonthlyStatement',
                isArray:false,
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    angular.forEach(data.contents, function(rate){
                        rate.etd = DateUtils.convertLocalDateToServer(rate.etd);
                        rate.eta = DateUtils.convertLocalDateToServer(rate.eta);
                        rate.deadline = DateUtils.convertLocalDateToServer(rate.deadline);
                        rate.deliveryDate = DateUtils.convertLocalDateToServer(rate.deliveryDate);
                        rate.billMakeDate = DateUtils.convertLocalDateToServer(rate.billMakeDate);
                    })               
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'dldStatement': {
                method: 'POST',
                url:'api/excel/dldStatement',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },  
            'scAccountStatement': {
                method: 'POST',
                url:'api/excel/scAccountStatement',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'excelMonthlyStatementCol': {
                method: 'POST',
                url:'api/excelMonthlyStatementCol',
                isArray:false,
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    angular.forEach(data.contents, function(rate){
                        rate.etd = DateUtils.convertLocalDateToServer(rate.etd);
                        rate.etd = DateUtils.convertLocalDateToServer(rate.etd);
                        rate.eta = DateUtils.convertLocalDateToServer(rate.eta);
                        rate.deadline = DateUtils.convertLocalDateToServer(rate.deadline);
                        rate.deliveryDate = DateUtils.convertLocalDateToServer(rate.deliveryDate);
                        rate.billMakeDate = DateUtils.convertLocalDateToServer(rate.billMakeDate);
                    })               
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'excelAccountInvoices': {
                method: 'POST',
                url:'api/excelAccountInvoices',
                isArray:false,
                responseType: 'arraybuffer',                
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'excelAccountVoucher': {
                method: 'POST',
                url:'api/excelAccountVoucher',
                isArray:false,
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    angular.forEach(data, function(rate){
                        rate.makeDate = DateUtils.convertLocalDateToServer(rate.makeDate);
                    })               
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'scVoucerExport': {
                method: 'POST',
                url:'api/excel/scVoucerExport',
                isArray:false,
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    angular.forEach(data, function(rate){
                        rate.makeDate = DateUtils.convertLocalDateToServer(rate.makeDate);
                    })               
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'excelInvoicesInfo': {
                method: 'POST',
                url:'api/excelInvoicesInfo',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'excelAccountPayment': {
                method: 'POST',
                url:'api/excelAccountPayment',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'excelGuide': {
                method: 'POST',
                url:'api/excelGuide',
                isArray:false,
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    angular.forEach(data, function(line){
                        line.eta = DateUtils.convertLocalDateToServer(line.eta);
                        line.etd = DateUtils.convertLocalDateToServer(line.etd);
                        line.deliveryDate = DateUtils.convertLocalDateToServer(line.deliveryDate);
                        line.opDate = DateUtils.convertLocalDateToServer(line.opDate);
                        line.atd = DateUtils.convertLocalDateToServer(line.atd);
                        line.billDate = DateUtils.convertLocalDateToServer(line.billDate);
                        line.cargoReadyDate = DateUtils.convertLocalDateToServer(line.cargoReadyDate);
                        line.feederEtd = DateUtils.convertLocalDateToServer(line.feederEtd);
                        line.bookingEtd = DateUtils.convertLocalDateToServer(line.bookingEtd);
                        line.transferDate = DateUtils.convertLocalDateToServer(line.transferDate);
                        line.revDeadLine = DateUtils.convertLocalDateToServer(line.revDeadLine);
                        line.lfd = DateUtils.convertLocalDateToServer(line.lfd);
                    })               
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'shipmentManifest': {
                method: 'POST',
                url:'api/excel/shipmentManifest',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'amassRateShareSheet': {
                method: 'POST',
                url:'api/excel/amassRateShareSheet',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'examReport': {
                method: 'POST',
                url:'api/excelGuide/examReport',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'agingReport': {
                method: 'POST',
                url:'api/excelGuide/agingReport',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'excelAccountTitle': {
                method: 'GET',
                url:'api/excel/accountTitle',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'excelGuideRatesDTO': {
                method: 'POST',
                url:'api/excelGuideRatesDTO',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'agingAnalysisByDnReport': {
                method: 'POST',
                url:'api/excel/agingAnalysisByDnReport',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'agingAnalysisByDnSupSubComReport': {
                method: 'POST',
                url:'api/excel/agingAnalysisByDnSupSubComReport',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'excelSynGuideRatesDTO': {
                method: 'POST',
                url:'api/excelSynGuideRatesDTO',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'exportPackingList': {
                method: 'POST',
                url:'api/excel/exportPackingList',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'exportConsolMasterPackingList': {
                method: 'GET',
                url:'api/excel/exportConsolMasterPackingList',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'exportPackingListInvoice': {
                method: 'GET',
                url:'api/excel/exportPackingListInvoice',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'exportConsolPackingList': {
                method: 'POST',
                url:'api/excel/exportConsolPackingList',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'exportWmsGoods': {
                method: 'GET',
                url:'api/excel/exportWmsGoods',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'consolWarehousingPlan': {
                method: 'GET',
                url:'api/excel/consolWarehousingPlan',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'consolList': {
                method: 'GET',
                url:'api/excel/consolList',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'exportHfBill': {
                method: 'GET',
                url:'api/excel/exportHfBill',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'exportHfBillCharges': {
                method: 'GET',
                url:'api/excel/exportHfBillCharges',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'exportCheckBills': {
                method: 'GET',
                url:'api/excel/exportCheckBills',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'excelBizs': {
                method: 'GET',
                url:'api/excelBizs',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'packingParcel': {
                method: 'POST',
                url:'api/excel/packingParcel',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'packingParcelT86': {
                method: 'POST',
                url:'api/excel/packingParcelT86',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'excelOntaskBizs': {
                method: 'GET',
                url:'api/excelOntaskBizs',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'huanhaiUnpaid': {
                method: 'POST',
                url:'api/excel/huanhaiUnpaid',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'linkGoExcelMonthlyStatementCol': {
                method: 'POST',
                url:'api/linkGoExcelMonthlyStatementCol',
                isArray:false,
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    angular.forEach(data.contents, function(rate){
                        rate.etd = DateUtils.convertLocalDateToServer(rate.etd);
                        rate.etd = DateUtils.convertLocalDateToServer(rate.etd);
                        rate.eta = DateUtils.convertLocalDateToServer(rate.eta);
                        rate.deadline = DateUtils.convertLocalDateToServer(rate.deadline);
                        rate.deliveryDate = DateUtils.convertLocalDateToServer(rate.deliveryDate);
                        rate.billMakeDate = DateUtils.convertLocalDateToServer(rate.billMakeDate);
                    })               
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'exportFullDigitizationInvoicesInfo': {
                method: 'POST',
                url:'api/exportFullDigitizationInvoicesInfo',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'haoBoExcelMonthlyStatementCol': {
                method: 'POST',
                url:'api/haoBoExcelMonthlyStatementCol',
                isArray:false,
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
                    data.dateTo = DateUtils.convertLocalDateToServer(data.dateTo);
                    angular.forEach(data.contents, function(rate){
                        rate.etd = DateUtils.convertLocalDateToServer(rate.etd);
                        rate.etd = DateUtils.convertLocalDateToServer(rate.etd);
                        rate.eta = DateUtils.convertLocalDateToServer(rate.eta);
                        rate.deadline = DateUtils.convertLocalDateToServer(rate.deadline);
                        rate.deliveryDate = DateUtils.convertLocalDateToServer(rate.deliveryDate);
                        rate.billMakeDate = DateUtils.convertLocalDateToServer(rate.billMakeDate);
                    })               
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            }

        });
    }
})();
