(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('opsCompany', {
                parent: 'company',
                url: '/opsCompanys',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'autopsApp.opsCompany.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/company/opsCompany/opsCompanys.html',
                        controller: 'OpsCompanyController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsCompany');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('opsCompany.detail', {
                parent: 'company',
                url: '/opsCompany',
                data: {
                    authorities: ['ROLE_BOSS','ROLE_ADMIN','FUN_COMPANY_SETUP'],
                    pageTitle: 'autopsApp.opsCompany.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/company/opsCompany/opsCompany-detail.html',
                        controller: 'OpsCompanyDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsCompany');
                        return $translate.refresh();
                    }]
                }
            })
            .state('opsCompany.new', {
                parent: 'opsCompany',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN'],
                },
                onEnter: ['$rootScope', '$state', '$uibModal', function($rootScope, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/company/opsCompany/opsCompany-dialog.html',
                        controller: 'OpsCompanyDialogController',
                        size: 'lg',
                        backdrop: false,
                        resolve: {
                            entity: function () {
                                return {
                                    createdBy: null,
                                    companyName: null,
                                    address: null,
                                    tel: null,
                                    fax: null,
                                    email: null,
                                    website: null,
                                    createdTime: null,
                                    companyLogo: null,
                                    companyAbb: null,
                                    deposit: null,
                                    hfSalesId: null,
                                    blPrefix: null,
                                    jobPrefix: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('opsCompany', null, { reload: true });
                    }, function() {
                        $state.go('opsCompany');
                    })
                }]
            })
            .state('opsCompany.edit', {
                parent: 'opsCompany',
                url: '/edit',
                data: {
                    authorities: ['ROLE_ADMIN',],
                },
                onEnter: ['$rootScope', '$state', '$uibModal', function($rootScope, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/company/opsCompany/opsCompany-dialog.html',
                        controller: 'OpsCompanyDialogController',
                        size: 'lg',
                        backdrop: false,
                        resolve: {
                            entity: ['OpsCompany', function(OpsCompany) {
                                return OpsCompany.get({id : $rootScope.id});
                            }]
                        }
                    }).result.then(function(result) {
                        // $state.go('opsCompany', null, { reload: true });
                        $state.go('^');
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('opsCompany.delete', {
                parent: 'opsCompany',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/company/opsCompany/opsCompany-delete-dialog.html',
                        controller: 'OpsCompanyDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['OpsCompany', function(OpsCompany) {
                                return OpsCompany.get({id : $stateParams.id/82678665});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('opsCompany', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }
})();
