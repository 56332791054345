(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GuideRatesDTODialogController', GuideRatesDTODialogController);

    GuideRatesDTODialogController.$inject = ['$scope', '$translate', '$uibModalInstance', '$uibModal', '$timeout', 'entity'];
    
    function GuideRatesDTODialogController($scope, $translate, $uibModalInstance, $uibModal, $timeout, entity) {

        $scope.receiptPaymentContentDTO = entity;

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        var isReverse = false;
        $scope.sortByTitle = function(title) {
            isReverse = !isReverse;
            $scope.receiptPaymentContentDTO.venderGuideRates.sort(function(a,b){
                if (isReverse){
                    return a[title]<b[title]?1:-1;
                }
                else {
                    return a[title]>b[title]?1:-1;
                }                   
            });
        }
        $timeout(function() {
            if($scope.receiptPaymentContentDTO.venderGuideRates){
                angular.forEach($scope.receiptPaymentContentDTO.venderGuideRates, function(data){
                    if(data.invoiceNum){
                        data.invoiceNum += " ";
                    }
                })
            }
            
        });

    }
})();
