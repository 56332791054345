(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AgentDetailController', AgentDetailController);

    AgentDetailController.$inject = ['$timeout', '$stateParams','$scope', '$rootScope', '$http', '$state', '$location', '$uibModal', '$translate', '$uibModalInstance', 'Principal', 'Guide',
        'Booking', 'Clerks', 'DateUtils', 'BookingTruck', 'BookingCtns', 'OpsExchangeRate', 'entity', 
        'OpsVender', 'GuideDoc', 'GuideCtns', 'OpsRates', 'OpsCompany', 'OpsReport', 'OpsPorts', 'OpsCarrier',
        'OpsShipper', 'GuideRates', 'OpsTemplate', 'OpsFiles', 'WinAES', 'DataUtils',
        'Email', 'GuideTemplate', 'AccountPayment', 'AccountInvoice', 'OpsCost'
    ];

    function AgentDetailController($timeout, $stateParams, $scope, $rootScope, $http, $state, $location, $uibModal, $translate, $uibModalInstance, Principal, Guide,
        Booking, Clerks, DateUtils, BookingTruck, BookingCtns, OpsExchangeRate, entity, 
        OpsVender, GuideDoc, GuideCtns, OpsRates, OpsCompany, OpsReport, OpsPorts, OpsCarrier,
        OpsShipper, GuideRates, OpsTemplate, OpsFiles, WinAES, DataUtils,
        Email, GuideTemplate, AccountPayment, AccountInvoice, OpsCost) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                initGuide();
            });
        };

        $scope.guide = entity;
        $scope.portList = new Array();
        var initGuide = function() {
            if ($scope.guide.booking && ($scope.guide.shipmentType == "AIR" || $scope.guide.shipmentType == "EXPRESS")) {
                $scope.lanes = $rootScope.airlanes;
                $scope.portList = $rootScope.OPSPORTS.airports;
        
            } else {
                $scope.lanes = $rootScope.sealanes;
                $scope.portList = $rootScope.OPSPORTS.seaports;
            }

            if (!$scope.guide.booking) {
                $scope.guide.booking = {};
            }
            if (!$scope.guide.booking.bookingCtns) {
                $scope.guide.booking.bookingCtns = [];
            }
            if (!$scope.guide.booking.bookingTrucks) {
                $scope.guide.booking.bookingTrucks = [];
            }
            if (!$scope.guide.guideRates) {
                $scope.guide.guideRates = [];
            }
            if (!$scope.guide.guideCtns) {
                $scope.guide.guideCtns = [];
            }    
            if (!$scope.guide.guideFclCtns) {
                $scope.guide.guideFclCtns = [];
            }  

            if($scope.guide.gw){
                $scope.guide.gw = $scope.guide.gw.toFixed(2)- 0;
            }
            if($scope.guide.vol){
                $scope.guide.vol = $scope.guide.vol.toFixed(3)- 0;
            }
            if($scope.guide.chargeWeight){
                $scope.guide.chargeWeight = $scope.guide.chargeWeight.toFixed(2)- 0;
            }
            document.title = $scope.guide.jobNum;     
        }
        initGuide();
        var exchangeRates = null;
        $scope.getExchangeRatesMap = function() {
            $rootScope.loadCurrencys();
            if (exchangeRates){
                return;
            }
            OpsExchangeRate.getExchangeRatesMap({
                    date: DateUtils.convertLocalDateToServer($scope.guide.etd)
                }, function(result) {
                exchangeRates = result;
                updateTotal();
            })
        }
        $scope.getHblsCount = function() {
            Booking.getHblsCount({
                guideId: $scope.guide.id
            }, function(result, headers) {
                $scope.hbls = result.hbls;
            });
        }

        $scope.exchangeRate = function() {
            OpsExchangeRate.getEffectiveExchangeRates({date: DateUtils.convertLocalDateToServer($scope.guide.etd)}, function(result){
                $uibModal.open({
                    templateUrl: 'app/entities/ops-exchange-rate/ops-exchange-rates-dialog.html',
                    controller: 'OpsExchangeRatesDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'md',
                    resolve: {
                        entity: [function() {
                            return result;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('opsExchangeRate');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function(results) {

                }, function() {

                });
            })
        }

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }
        
        $scope.payerData = {
            value: []
        };

        var payerChooseOneForEmailIndex = 0;
        $scope.lanes = []; 
        $scope.docTypes = [{
            C: "mbl",
            N: $translate.instant('autopsApp.guide.docType.mbl')
        }, {
            C: 'hbl',
            N: $translate.instant('autopsApp.guide.docType.hbl')
        }, {
            C: 'invoice',
            N: $translate.instant('autopsApp.guide.docType.invoice')
        }, {
            C: 'custom',
            N: $translate.instant('autopsApp.guide.docType.custom')
        }, {
            C: 'insurance',
            N: $translate.instant('autopsApp.guide.docType.insurance')
        }, {
            C: 'fumigation',
            N: $translate.instant('autopsApp.guide.docType.fumigation')
        }, {
            C: 'co',
            N: $translate.instant('autopsApp.guide.docType.co')
        }, {
            C: 'ciq',
            N: $translate.instant('autopsApp.guide.docType.ciq')
        }];

        $scope.profit = {};
        var profitCopy = 0;
        $scope.costTotalList = [];
        $scope.sellingTotalList = [];
        $scope.profitList = [];
        var updateTotal = function() {
            $scope.costTotalList = [];
            $scope.sellingTotalList = [];
            $scope.profitList = [];
            var currencys = [];
            angular.forEach($scope.guide.guideRates, function(data) {
                if (data.payCurrency && currencys.indexOf(data.payCurrency) === -1) {
                    currencys.push(data.payCurrency);
                }
            })
            var cost = 0;
            var selling = 0;
            var profit = 0;
            var profitTtl = 0;
            angular.forEach(currencys, function(currency) {
                cost = 0;
                selling = 0;
                profit = 0;
                angular.forEach($scope.guide.guideRates, function(rate) {
                    if (rate.payCurrency == currency && rate.payAmount) {
                        if (rate.isPay) {
                            cost = cost + rate.payAmount;
                            if(rate.exchangeRate){
                                profitTtl = profitTtl - rate.payAmount * rate.exchangeRate;
                            }
                            else {
                                profitTtl = profitTtl - rate.payAmount * exchangeRates[currency];
                            }
                        } else {
                            selling = selling + rate.payAmount;
                            if(rate.exchangeRate){
                                profitTtl = profitTtl + rate.payAmount * rate.exchangeRate;
                            }
                            else {
                                profitTtl = profitTtl + rate.payAmount * exchangeRates[currency];
                            }
                        }
                        profitTtl = DataUtils.round(profitTtl, 2);
                    }
                })
                $scope.costTotalList.push({
                    currency: currency,
                    total: DataUtils.round(cost, 2)
                });
                $scope.sellingTotalList.push({
                    currency: currency,
                    total: DataUtils.round(selling, 2)
                });
                profit = selling - cost;
                $scope.profitList.push({
                    currency: currency,
                    total: DataUtils.round(profit, 2)
                });
            })
            profitCopy = DataUtils.round(profitTtl, 2);
            $scope.profit.ammount = DataUtils.round(profitTtl, 2);
            if (!$scope.profit.currency) {
                $scope.profit.currency = $rootScope.account.baseCurrency;
            }
        }

        $scope.updateProfit = function(currency) {
            $scope.profit.ammount = profitCopy / exchangeRates[currency];
            $scope.profit.ammount = $scope.profit.ammount.toFixed(2) -0;
        };

        $scope.files = new Array();

        $scope.goBack = function() {
            $uibModalInstance.close();
        }

        $scope.loadPorts = function() {
            if ($rootScope.OPSPORTS.airports && $rootScope.OPSPORTS.airports.length > 0){
                return;
            }
            OpsPorts.getAll(function(result){
                $rootScope.OPSPORTS.airports = [];
                $rootScope.OPSPORTS.seaports = [];
                angular.forEach(result, function(data){
                    if(data.type == 1){
                        $rootScope.OPSPORTS.seaports.push(data);
                    }
                    else {
                        $rootScope.OPSPORTS.airports.push(data);
                    }
                })
                initGuide();
            })
        };

        $scope._selected = function($item, which) {
            switch (which) {
                case "gporChoose":
                    $scope.guide.porId = $item.code;
                    break;
                case "gpolChoose":
                    $scope.guide.polId = $item.code;
                    $scope.guide.polRegion = $item.region;
                    break;
                case "gpodChoose":
                    $scope.guide.podId = $item.code;
                    if(!$scope.guide.podRegion) {
                        $scope.guide.podRegion = $item.region;
                    }    
                    break;
                case "gdestChoose":
                    $scope.guide.destId = $item.code;
                    $scope.guide.podRegion = $item.region;
                    $scope.guide.destCountryCode = $item.countryCode;    
                    break;
                case "gdeliverytoChoose":
                    $scope.guide.deliverytoId = $item.code;
                    break;    
                case "podChoose":
                    $scope.guide.booking.podId = $item.code;
                    break;
                case "porChoose":
                    $scope.guide.booking.porId = $item.code;
                    break;
                case "destChoose":
                    $scope.guide.booking.destId = $item.code;
                    break;
                case "deliverytoChoose":
                    $scope.guide.booking.deliverytoId = $item.code;
                    break;                    
                case "polChoose":
                    $scope.guide.booking.polId = $item.code;
                    break;
            }
        }

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        $scope.updateFreightTerm = function(term) {
            $scope.guide.freightTerm = term;
            if(term == "PPD"){
                $scope.guide.payableAt = $scope.guide.booking.polName;
            }
            else {
                $scope.guide.payableAt = "DESTINATION";
            }
            
            $translate.refresh();
        }

        $scope.addCtn = function() {
            $scope.guide.booking.bookingCtns.push({});
        };

        $scope.isWood = false;
        $scope.woodenTxt = function() {
            if ($scope.guide.isWood) {
                $scope.guide.woodenStatement = "THIS SHIPMENT HAS BEEN FUMIGATED AND HEATED.";
            } else {
                $scope.guide.woodenStatement = "THIS SHIPMENT CONTAINS NO SOLID WOODEN PACKING MATERIALS.";
            }

        }

        $scope.generateSayCtns = function() {
            if ($scope.guide.shipmentType == "FCL"){
                if (!$scope.guide.guideFclCtns || $scope.guide.guideFclCtns.length == 0) {
                    layer.msg($translate.instant('global.messages.sayCtnsFailed'));
                    return;
                }
                var sayCtns = "SAY ";
                var isFirst = true;

                var ctnTypeSet = new Set();
                angular.forEach($scope.guide.guideFclCtns, function(data){
                    if(data.ctnType && !ctnTypeSet.has(data.ctnType)){
                        ctnTypeSet.add(data.ctnType);
                    }
                }) 

                var ctnCount = 0;
                ctnTypeSet.forEach(function(ctnType){
                    ctnCount = 0;
                    angular.forEach($scope.guide.guideFclCtns, function(data) {
                        if(data.ctnType == ctnType && data.num){
                            ctnCount += data.num;
                        }
                    })
                    if (ctnCount) {
                        if (!isFirst) {
                            sayCtns = sayCtns + " AND ";
                        }
                        sayCtns = sayCtns + DataUtils.translate(ctnCount.toString()) + " (" + ctnCount + ") " + ctnType;
                        isFirst = false;
                    }
                })

                $scope.guide.sayCtns = sayCtns + " CONTAINER(S) ONLY.";
            }
            else {
                if (!$scope.guide.pkgNum) {
                    layer.msg($translate.instant('global.messages.sayCtnsFailed'));
                    return;
                }
                $scope.guide.sayCtns = "SAY " + DataUtils.translate($scope.guide.pkgNum.toString()) + " (" + $scope.guide.pkgNum + ") " + $scope.guide.booking.pkgType + " ONLY.";
            }            
        }

        $scope.generateGoodsDiscription = function() {
            if (!$scope.guide.goodsDiscription){
                $scope.guide.goodsDiscription = angular.uppercase($scope.guide.cargoName);
            }
            if (!$scope.guide.booking.goodsDiscription){
                $scope.guide.booking.goodsDiscription = angular.uppercase($scope.guide.cargoName);
            }
            if ($scope.guide.goodsDiscription || !$scope.guide.booking.goodsDiscription) {
                $scope.guide.goodsDiscription = angular.uppercase($scope.guide.goodsDiscription);
                return;
            }
            $scope.guide.goodsDiscription = angular.copy($scope.guide.booking.goodsDiscription);
        }
        $scope.generateMark = function() {
            if ($scope.guide.mark || !$scope.guide.booking.mark) {
                $scope.guide.mark = angular.uppercase($scope.guide.mark);
                return;
            }
            $scope.guide.mark = angular.copy($scope.guide.booking.mark);
        }

        $scope.ggeneratePkgsAndType = function() {
            if (!$scope.guide.pkgNum || !$scope.guide.booking.pkgType || $scope.guide.pkgsAndType) {
                $scope.guide.pkgsAndType = angular.uppercase($scope.guide.pkgsAndType);
                return;
            }
            $scope.guide.pkgsAndType = $scope.guide.pkgNum.toString() + " " + $scope.guide.booking.pkgType;
        }

        $scope.generatePkgsAndType = function() {
            if (!$scope.guide.pkgNum || !$scope.guide.booking.pkgType || $scope.guide.booking.pkgsAndType) {
                $scope.guide.booking.pkgsAndType = angular.uppercase($scope.guide.booking.pkgsAndType);
                return;
            }
            $scope.guide.booking.pkgsAndType = $scope.guide.pkgNum.toString() + " " + $scope.guide.booking.pkgType;
        }

        $scope.updatePkgsAndType = function() {
            if ($scope.guide.pkgNum && $scope.guide.booking.pkgType) {
                $scope.guide.booking.pkgsAndType = $scope.guide.pkgNum.toString() + " " + $scope.guide.booking.pkgType;
                $scope.guide.pkgsAndType = $scope.guide.pkgNum.toString() + " " + $scope.guide.booking.pkgType;
            }
        }

        $scope.generateGwVol = function() {
            if ($scope.guide.booking.gwVol) {
                $scope.guide.booking.gwVol = angular.uppercase($scope.guide.booking.gwVol);
                return;
            }
            if ($scope.guide.gw && $scope.guide.vol) {
                $scope.guide.booking.gwVol = $scope.guide.gw.toFixed(2) + " KGS" + "\n" + $scope.guide.vol.toFixed(3)  + " CBM";
            } else if ($scope.guide.vol) {
                $scope.guide.booking.gwVol = $scope.guide.vol.toFixed(3)  + " CBM";

            } else if ($scope.guide.gw) {
                $scope.guide.volVol = $scope.guide.gw.toFixed(2)  + " KGS";
            };
        };

        $scope.ggenerateGwVol = function() {
            if ($scope.guide.gwVol) {
                $scope.guide.gwVol = angular.uppercase($scope.guide.gwVol);
                return;
            }
            if ($scope.guide.gw && $scope.guide.vol) {
                $scope.guide.gwVol = $scope.guide.gw.toFixed(2) + " KGS" + "\n" + $scope.guide.vol.toFixed(3)  + " CBM";
            } else if ($scope.guide.vol) {
                $scope.guide.gwVol = $scope.guide.vol.toFixed(3) + " CBM";

            } else if ($scope.guide.gw) {
                $scope.guide.gwVol = $scope.guide.gw.toFixed(2) + " KGS";
            };
        };

        $scope.uppercaseVsl = function(str) {
            $scope.guide.vsl = angular.uppercase(str);
        }
        $scope.uppercaseVoy = function(str) {
            $scope.guide.voy = angular.uppercase(str);
        }
        $scope.uppercaseFeederVsl = function(str) {
            $scope.guide.feederVsl = angular.uppercase(str);
        }
        $scope.uppercaseFeederVoy = function(str) {
            $scope.guide.feederVoy = angular.uppercase(str);
        }
        $scope.uppercaseLane = function(str) {
            $scope.guide.lane = angular.uppercase(str);
        }

        $scope.uppercaseIssuePlace = function(str) {
            $scope.guide.issuePlace = angular.uppercase(str);
        }
        $scope.uppercasePayableAt = function(str) {
            $scope.guide.payableAt = angular.uppercase(str);
        }

        $scope.uppercasePor = function(str) {
            $scope.guide.booking.porName = angular.uppercase(str);
        }

        $scope.uppercasePol = function(str) {
            $scope.guide.booking.polName = angular.uppercase(str);
        }
        $scope.uppercasePod = function(str) {
            $scope.guide.booking.podName = angular.uppercase(str);
        }
        $scope.uppercaseDest = function(str) {
            $scope.guide.booking.destName = angular.uppercase(str);
        }
        $scope.uppercaseDeliveryTo = function(str) {
            $scope.guide.booking.deliveryTo = angular.uppercase(str);
        }

        $scope.uppercaseShipper = function(str) {
            $scope.guide.booking.shipperBl = angular.uppercase(str);
        }
        $scope.uppercaseCnee = function(str) {
            $scope.guide.booking.cneeBl = angular.uppercase(str);
        }
        $scope.uppercaseNotify = function(str) {
            $scope.guide.booking.notifyBl = angular.uppercase(str);
        }
        $scope.uppercaseMark = function(str) {
            $scope.guide.booking.mark = angular.uppercase(str);
        }
        $scope.uppercaseDescription = function(str) {
            if (!$scope.guide.booking.goodsDiscription){
                $scope.guide.booking.goodsDiscription = angular.uppercase($scope.guide.cargoName);
            }
            else {
                $scope.guide.booking.goodsDiscription = angular.uppercase(str);
            }            
        }

        $scope.uppercaseCtnNum = function(ctn) {
            ctn.ctnNum = angular.uppercase(ctn.ctnNum);
        }
        $scope.uppercaseCtnSealNum = function(ctn) {
            ctn.sealNum = angular.uppercase(ctn.sealNum);
        }
        $scope.uppercaseGPor = function(str) {
            $scope.guide.porName = angular.uppercase(str);
        }
        $scope.guppercasePol = function(str) {
            $scope.guide.polName = angular.uppercase(str);
        }
        $scope.guppercasePod = function(str) {
            $scope.guide.podName = angular.uppercase(str);
        }
        $scope.guppercaseDest = function(str) {
            $scope.guide.destName = angular.uppercase(str);
        }        
        $scope.uppercaseGDeliveryTo = function(str) {
            $scope.guide.deliveryTo = angular.uppercase(str);
        }
        $scope.guppercaseShipper = function(str) {
            $scope.guide.guideShipperBl = angular.uppercase(str);
        }
        $scope.guppercaseCnee = function(str) {
            $scope.guide.deliveryBl = angular.uppercase(str);
        }
        $scope.guppercaseNotify = function(str) {
            $scope.guide.guideNotifyBl = angular.uppercase(str);
        }
        $scope.uppercaseAlsoNotify = function(str) {
            $scope.guide.booking.alsoNotify = angular.uppercase(str);
        }
        $scope.uppercaseBlAttachment = function(str) {
            $scope.guide.booking.blAttachment = angular.uppercase(str);
        }
        $scope.ggenerateBlAttachment = function() {
            if ($scope.guide.blAttachment) {
                $scope.guide.blAttachment = angular.uppercase($scope.guide.blAttachment);
                return;
            }
            if ($scope.guide.booking.blAttachment) {
                $scope.guide.blAttachment = angular.copy($scope.guide.booking.blAttachment);
            }
        }

        $scope.blSend = function(type, code) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.shipperName;
            var subjectNum = $scope.guide.booking.bookingNum;
            if (!subjectNum){
                if ($scope.guide.hblNum){
                    subjectNum = $scope.guide.hblNum;
                }
                else {
                    subjectNum = $scope.guide.jobNum;
                }                
            }
            email.subject = subjectNum + "--" + $translate.instant('entity.email.blSendSubject');
            email.content = $translate.instant('entity.email.blSendContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = code;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.shipperId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }

        var sendEmailPop = function(entity) {
            $uibModal.open({
                templateUrl: 'app/entities/email/email-dialog.html',
                controller: 'EmailDialogController',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                $scope.guide = result;
            }, function(result) {});
        }

        var fileName_index = 0;
        $scope.exportExcelPdf = function(format, code, fileName, idCopy) {
            var guideReportDTO = angular.copy($scope.guide);
            if(code == 140 || code == 160){
                for (var i = 0; i < $scope.guide.booking.bookingTrucks.length; i++) {
                    if ($scope.guide.booking.bookingTrucks[i].id == idCopy) {
                        guideReportDTO.bookingTruck = angular.copy($scope.guide.booking.bookingTrucks[i]);
                    }
                }      
                if (guideReportDTO.bookingTruck.useImport) {
                    guideReportDTO.reportType = 140;
                }
                else {
                    guideReportDTO.reportType = 160;
                }    
            }           
            else {
                guideReportDTO.reportType = code;
            }
            guideReportDTO.opType = 1;            
            guideReportDTO.fileType = format;
            guideReportDTO.fileName = fileName;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                if (format == 2) {
                    var file = new Blob([result.data], {
                        type: 'application/vnd.ms-excel'
                    });
                    if (file.size == 0) {
                        layer.msg($translate.instant('global.loadFileFailed'));
                        return;
                    }
                    var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
                    var aTag = document.createElement("a");
                    aTag.download = fileName + ".xlsx";
                    aTag.href = blobURL;
                    aTag.click();
                } else {
                    var file = new Blob([result.data], {
                        type: 'application/pdf'
                    });
                    if (file.size == 0) {
                        layer.msg($translate.instant('global.loadFileFailed'));
                        return;
                    }
                    var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
                    var aTag = document.createElement("a");
                    aTag.download = fileName + ".pdf";
                    aTag.href = blobURL;
                    aTag.click();
                }
            });
        }



        $scope.amountChange = function(rate) {
            if (rate.price < 0) {
                rate.price = 0;
            }
            if(null == rate.count || rate.count < 0){
                rate.count = 1.00;
            }
            rate.total = rate.count * rate.price;
            if (rate.days) {
                rate.total = rate.total * rate.days;
            }
            rate.total = DataUtils.round(rate.total, 2);

            if(!rate.payCurrency){
                rate.payCurrency = rate.currency;
                rate.payExchange = 1.00;
            }            
            if(null == rate.payExchange){
                rate.payExchange = 1.00;
            }            
            rate.payAmount = rate.payExchange * rate.count * rate.price;
            if (rate.days) {
                rate.payAmount = rate.payAmount * rate.days;
            }
            rate.payAmount = DataUtils.round(rate.payAmount, 2);

            if(rate.payAmount != null && rate.vat != null){
                rate.vatAmt = DataUtils.round(rate.payAmount*rate.vat/100, 3)
            }
            if(rate.payAmount != null && rate.incomeTax != null){
                rate.incomeTaxAmt = DataUtils.round(rate.payAmount*rate.incomeTax/100, 3)
            }

            updateTotal();
        }

        $scope._onItemSelected = function(data, rate) {
            rate.itemId = data.id;
            $scope.amountChange();
        }


        /* ctns view */
        $scope.addGCtn = function() {
            $scope.guide.guideCtns.push({
                smsSent: false
            });
            $translate.refresh();
        };

        $scope.updateGwVol = function() {
            if ($scope.guide.gw && $scope.guide.vol) {
                $scope.guide.gwVol = $scope.guide.gw.toFixed(2) + "KGS" + "\n" + $scope.guide.vol.toFixed(3) + "CBM";
                $scope.guide.booking.gwVol = $scope.guide.gw.toFixed(2) + "KGS" + "\n" + $scope.guide.vol.toFixed(3) + "CBM";
            } else if ($scope.guide.vol) {
                $scope.guide.gwVol = $scope.guide.vol.toFixed(3) + "CBM";
                $scope.guide.booking.gwVol = $scope.guide.vol.toFixed(3) + "CBM";

            } else if ($scope.guide.gw) {
                $scope.guide.gwVol = $scope.guide.gw.toFixed(2) + "KGS";
                $scope.guide.booking.gwVol = $scope.guide.gw.toFixed(2) + "KGS";
            }
            if ($scope.guide.booking.divVolBy){
                $scope.guide.chargeWeight = $scope.guide.vol * 1000000 / $scope.guide.booking.divVolBy;
            }
            if($scope.guide.chargeWeight < $scope.guide.gw){
                $scope.guide.chargeWeight = $scope.guide.gw;
            }
            if ($scope.guide.chargeWeight){
                $scope.guide.chargeWeight = $scope.guide.chargeWeight.toFixed(2) - 0;
                $scope.guide.awbWeight = $scope.guide.chargeWeight.toFixed(2) - 0;
            }   
        };


        $scope.chargeWeightChange = function(chargeWeight) {
            $scope.guide.awbWeight = chargeWeight;
        };
        var updateBookingCtnsGwVol = function() {
            if ($scope.guide.guideFclCtns && $scope.guide.guideFclCtns.length > 0) {                
                angular.forEach($scope.guide.guideFclCtns, function(bctn){
                    if (bctn.ctnType) {
                        var gwTtl=0, volTtl=0, pkgTtl=0, pkgType;
                        angular.forEach($scope.guide.guideCtns, function(gctn){
                            if(gctn.ctnType == bctn.ctnType){
                                if(gctn.gw){
                                    gwTtl = gwTtl + gctn.gw;
                                }
                                if(gctn.vol){
                                    volTtl = volTtl + gctn.vol;
                                }      
                                if(gctn.pkgs){
                                    pkgTtl = pkgTtl + gctn.pkgs;
                                }      
                                if(pkgType){
                                    if(gctn.pkgType && pkgType != gctn.pkgType){
                                        pkgType =  "PKGS";      
                                    }
                                }
                                else {
                                    pkgType = gctn.pkgType;
                                }                                              
                            }
                        })
                        bctn.gw = gwTtl.toFixed(2) - 0;
                        bctn.vol = volTtl.toFixed(3) - 0;
                        bctn.pkgNum = pkgTtl.toFixed(2) - 0;
                        bctn.pkgType = pkgType;
                    }
                });
            }
        }
        $scope.guideCtnGwChange = function() {
            if ($scope.guide.guideCtns && $scope.guide.guideCtns.length > 0) {
                var newGwTotal = 0;
                for (var i = 0; i < $scope.guide.guideCtns.length; i++) {
                    if ($scope.guide.guideCtns[i].gw) {
                        newGwTotal = newGwTotal + $scope.guide.guideCtns[i].gw;
                    }
                }
                $scope.guide.gw = newGwTotal.toFixed(2) - 0;
                $scope.updateGwVol();
            }
            updateBookingCtnsGwVol();
        }
        $scope.guideCtnVolChange = function() {
            if ($scope.guide.guideCtns && $scope.guide.guideCtns.length > 0) {
                var newVolTotal = 0;
                for (var i = 0; i < $scope.guide.guideCtns.length; i++) {
                    if ($scope.guide.guideCtns[i].vol) {
                        newVolTotal = newVolTotal + $scope.guide.guideCtns[i].vol;
                    }
                }
                $scope.guide.vol = newVolTotal.toFixed(3) - 0;
                $scope.updateGwVol();
            }
            updateBookingCtnsGwVol();
        }
        $scope.guideCtnPkgsChange = function() {
            if ($scope.guide.guideCtns && $scope.guide.guideCtns.length > 0) {
                var newPkgsTotal = 0;
                for (var i = 0; i < $scope.guide.guideCtns.length; i++) {
                    if ($scope.guide.guideCtns[i].pkgs) {
                        newPkgsTotal = newPkgsTotal + $scope.guide.guideCtns[i].pkgs;
                    }
                }
                $scope.guide.pkgNum = newPkgsTotal;
                $scope.updatePkgsAndType();
            }
            updateBookingCtnsGwVol();
        }
        $scope.guideCtnPkgTypeChange = function(pkgType) {
            var pkgsCopy = null;
            var isSamePkg = true;
            angular.forEach($scope.guide.guideCtns, function (data) {        
                if (data.pkgType && pkgType != data.pkgType){
                    isSamePkg = false;
                }
            });
            if (isSamePkg){
                $scope.guide.booking.pkgType = pkgType;
            }
            else {
                $scope.guide.booking.pkgType = "PKGS";
            } 
            $scope.updatePkgsAndType();
            updateBookingCtnsGwVol();
        }

        $scope.templates=[];
        $scope.loadTempaltes = function() {  
            if (!$scope.templates || $scope.templates.length == 0){
                GuideCtns.getTempaltes({}, function(result) {
                    $scope.templates = result;
                }, function(error) {
                    // layer.msg($translate.instant('global.messages.submittedfailed'));
                });
            }
        }
        $scope._ctnselected = function ($item, ctn){
            ctn.truckNum = $item.truckNum;
            ctn.driverMob = $item.driverMob;
            ctn.driverName = $item.driverName;    
        }

        $scope.showContactList = function(id, type) {
            if (!id || !type) {
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/guide/contact-list.html',
                controller: 'ContactListController',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return OpsVender.getContactsList({id: id}).$promise;
                    }]
                }
            }).result.then(function(contact) {
                $translate.refresh();
            }, function() {});
        }



        $scope.rateUnitChange = function(rate) {
            if (rate.unit == "BL") {
                rate.count = 1;
            } else if (rate.unit == "KG") {
                if ($scope.guide.gw) {
                    rate.count = $scope.guide.gw.toFixed(2) - 0;
                }
            } else if (rate.unit == "PKGS") {
                if ($scope.guide.pkgNum) {
                    rate.count = $scope.guide.pkgNum.toFixed(2) - 0;
                }
            } else if (rate.unit == "CBM") {
                if ($scope.guide.vol) {
                    rate.count = $scope.guide.vol.toFixed(3) - 0;
                }
            } else if (rate.unit == "TON") {
                if ($scope.guide.gw) {
                    rate.count = $scope.guide.gw / 1000;
                    rate.count = rate.count.toFixed(2) - 0;
                }
            } else if (rate.unit == "MT") {
                if ($scope.guide.gw && $scope.guide.vol) {
                    rate.count = $scope.guide.gw / 1000;
                    rate.count = rate.count.toFixed(2) - 0;
                    if (rate.count < $scope.guide.vol) {
                        rate.count = $scope.guide.vol.toFixed(3) - 0;
                    }
                }
            } else if (rate.unit == "VW") {
                if ($scope.guide.chargeWeight) {
                    rate.count = $scope.guide.chargeWeight.toFixed(2) - 0;
                }
            } else if (rate.unit == "RT") {
                if ($scope.guide.gw && $scope.guide.vol) {
                    rate.count = $scope.guide.gw / 363;
                    rate.count = rate.count.toFixed(2) - 0;
                    if (rate.count < $scope.guide.vol) {
                        rate.count = $scope.guide.vol.toFixed(3) - 0;
                    }
                }
            } else {
                angular.forEach($scope.guide.guideFclCtns, function(data) {
                    if (data.ctnType == rate.unit) {
                        rate.count = data.num;
                    }
                })
            }
            $scope.amountChange(rate);
        }
        $scope.showDetailedContactInfo = false;
        $scope.showDetailedSet = function (rate){
            $scope.showDetailedContactInfo = !$scope.showDetailedContactInfo;
        }


        $scope.updateVolGw = function() {
            var newGwTotal = 0;
            var newVolTotal = 0;
            var newPkgsTotal = 0;
            var isSamePkg = true;
            var pkgsCopy = null;
            angular.forEach($scope.guide.booking.bookingCtns, function (data) {
                if (data.length && data.width && data.height){
                    data.width = data.width.toFixed(4) - 0;
                    data.length = data.length.toFixed(4) - 0;
                    data.height = data.height.toFixed(4) - 0;
                    data.vol = data.length * data.width * data.height/1000000;
                }
                if (data.vol && data.pkgNum){
                    newVolTotal = newVolTotal + data.vol * data.pkgNum;
                }
                if (data.gw && data.pkgNum){
                    newGwTotal = newGwTotal + data.gw * data.pkgNum;
                }
                if (data.pkgNum){
                    newPkgsTotal = newPkgsTotal + data.pkgNum;
                }
                if (data.vol){
                    data.vol = data.vol.toFixed(4) - 0;
                }
                if (data.pkgType){
                    if(pkgsCopy){
                        if (pkgsCopy != data.pkgType){
                            isSamePkg = false;
                        }
                    }
                    else {
                        pkgsCopy = data.pkgType;
                    }
                }              
            })
            if ($scope.guide.booking.divVolBy){
                $scope.guide.chargeWeight = newVolTotal * 1000000 / $scope.guide.booking.divVolBy;
            }
            if($scope.guide.chargeWeight < $scope.guide.gw){
                $scope.guide.chargeWeight = $scope.guide.gw;
            }
            if ($scope.guide.chargeWeight){
                $scope.guide.chargeWeight = $scope.guide.chargeWeight.toFixed(2) - 0;
            }   
            $scope.guide.gw = newGwTotal.toFixed(2) - 0;
            $scope.guide.vol = newVolTotal.toFixed(3) - 0;
            $scope.guide.pkgNum = newPkgsTotal.toFixed(0) - 0;
            if (!isSamePkg){
                $scope.guide.booking.pkgType = "PKGS";
            }
            else {
                $scope.guide.booking.pkgType = pkgsCopy;
            }
            $scope.updateGwVol();
            $scope.updatePkgsAndType();
        }

        $scope.previewReport = function(code, truckId) {
            var guideReportDTO = angular.copy($scope.guide);
            if (truckId){
                for (var i = 0; i < $scope.guide.booking.bookingTrucks.length; i++) {
                    if ($scope.guide.booking.bookingTrucks[i].id == truckId) {
                        guideReportDTO.bookingTruck = angular.copy($scope.guide.booking.bookingTrucks[i]);
                    }
                }
                if (guideReportDTO.bookingTruck.useImport) {
                    guideReportDTO.reportType = 140;
                }
                else {
                    guideReportDTO.reportType = 160;
                }    
            }   
            else {
                guideReportDTO.reportType = code;
            }
            guideReportDTO.opType = 1;            
            guideReportDTO.fileType = 1;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                var file = new Blob([result.data], {
                    type: 'application/pdf'
                });
                if (file.size == 0) {
                    layer.msg($translate.instant('global.loadFileFailed'));
                    return;
                }
                var fileURL = URL.createObjectURL(file);
                var previewDialog = layer.open({
                    type: 2,
                    area: ['1000px', '680px'],
                    maxmin: true,
                    title: $translate.instant("entity.action.preview"),
                    shade: 0.3,
                    moveType: 0,
                    shift: 0,
                    content: fileURL
                });
            }, function(error) {

            });
        }


        $scope.getLoadRemark = function() {
            if(!$scope.guide.booking.loadRemark){
                var add = "预计货到堆场时间：" + DateUtils.convertLocalDateToServer($scope.guide.cargoReadyDate) + "\n\n";
                add = add + "装箱要求：" + "\n";
                add = add + "报箱号时间：" + "\n";
                add = add + "费用：" + "\n";
                $scope.guide.booking.loadRemark = add;    
            }
        }

        $scope.checkCtnRepeat = function(ctn) {
            if(!ctn.ctnNum){
                return;
            }
            angular.forEach($scope.guide.guideCtns, function(data){
                if (data != ctn && data.ctnNum == ctn.ctnNum){
                    $rootScope.OPS_ALERT(data.ctnNum + ": " + $translate.instant('autopsApp.guide.home.ctnRepeatedAlert'));
                }
            })
        }

        $scope.declPorts = [];
        $scope.getDeclPorts = function(mGuideId) {
            if ($scope.declPorts.length > 0){
                return;
            }
            $http.get('i18n/customsData.json').success(function(result) {
                $scope.declPorts = result.customCodes;
            });
        }
        $scope.datePickerForDeclareDate = {};
        $scope.datePickerForDeclareDate.status = {
            opened: false
        };
        $scope.datePickerForDeclareDateOpen = function($event) {
            $scope.datePickerForDeclareDate.status.opened = true;
        };
        $scope.blAttRemark = function(hm) {
            if (hm == "H"){
                $scope.guide.booking.goodsDiscription = $scope.guide.booking.goodsDiscription + "\n\n" + "MARKS AND DESCRIPTION AS PER ATTACHED LIST";
            }
            else {
                $scope.guide.goodsDiscription = $scope.guide.goodsDiscription + "\n\n" + "MARKS AND DESCRIPTION AS PER ATTACHED LIST";
            }
        }

        $scope.sendAn = function(type) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.shipperName;
            email.subject = $scope.guide.hblNum + "--" + $translate.instant('entity.email.anSubject');
            email.content = $translate.instant('entity.email.anContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = 180;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.shipperId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }
        $scope.sendDevanning = function(type) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.shipperName;
            email.subject = $scope.guide.hblNum + "--" + $translate.instant('entity.email.dvSubject');
            email.content = $translate.instant('entity.email.dvContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = 490;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.shipperId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }
        $scope.sendGuarantee = function(type) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.shipperName;
            email.subject = $scope.guide.hblNum + "--" + $translate.instant('entity.email.grtSubject');
            email.content = $translate.instant('entity.email.grtContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = 500;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.shipperId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }

        $scope.sendDO = function(type) {
            var email = {};
            email.attachments = [];
            email.head = $scope.guide.shipperName;
            email.subject = $scope.guide.hblNum + "--" + $translate.instant('entity.email.doSubject');
            email.content = $translate.instant('entity.email.doContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = $scope.guide;
            entity.emailType = type;
            entity.code = null;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = $scope.guide.shipperId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity);
        }

        $scope.synCtns = function() {           
            Guide.synCtns($scope.guide, function(result) {
                if (result && result.length > 0){
                    angular.forEach(result, function(data){
                        $scope.guide.guideCtns.push(data);
                    })
                    layer.msg($translate.instant('autopsApp.guide.synSuccess'));
                }
                else {
                    layer.msg($translate.instant('global.messages.resultNull'));
                }
            }, function(result){
                layer.msg($translate.instant('autopsApp.guide.synFailed'));
            });
        };



        $scope.textPop = function(text, gfd, bfd) {
            $uibModal.open({
                templateUrl: 'app/entities/ops-change-log/ops-change-log-delete-dialog.html',
                controller: 'OpsChangeLogDeleteController',
                controllerAs: 'vm',
                // backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return text;
                    }]
                }
            }).result.then(function(result) {
                if(gfd){
                    $scope.guide[gfd] = result;
                }
                else {
                    $scope.guide.booking[bfd] = result;
                }
            }, function() {
            });
        }

        $scope.payCurrencyChange = function(rate) {
            if (rate.currency == rate.payCurrency) {
                rate.payExchange = 1.00;
                $scope.amountChange(rate);
                return;
            }
            OpsExchangeRate.getGuidingRate({
                baseCurrency: $rootScope.account.baseCurrency,
                originalCurrency: rate.currency,
                targetCurrency: rate.payCurrency,
                companyId: $rootScope.account.companyId,
                isPay: rate.isPay
            }, function(result) {
                rate.payExchange = result.rate;
                $scope.amountChange(rate);
            })
        }


        var isReverse = true;
        $scope.sortRates = function(fd) {
            isReverse = !isReverse;
            $scope.guide.guideRates.sort(function(a,b){
                if (isReverse){
                    return a[fd]<b[fd]?1:-1;
                }
                else {
                    return a[fd]>b[fd]?1:-1;
                }                   
            });
        }

        $scope.generateCtnTypeNum = function(index, isDb) {
            if (isDb) {
                $scope.guide.booking.bookingTrucks[index].ctnNumType = "";
                for (var i = 0; i < $scope.guide.guideFclCtns.length; i++) {
                    $scope.guide.booking.bookingTrucks[index].ctnNumType = $scope.guide.booking.bookingTrucks[index].ctnNumType + $scope.guide.guideFclCtns[i].num + " X " + $scope.guide.guideFclCtns[i].ctnType + "; ";
                }
            } else {
                if (!$scope.guide.booking.bookingTrucks[index].ctnNumType) {
                    $scope.guide.booking.bookingTrucks[index].ctnNumType = "";
                    for (var i = 0; i < $scope.guide.guideFclCtns.length; i++) {
                        $scope.guide.booking.bookingTrucks[index].ctnNumType = $scope.guide.booking.bookingTrucks[index].ctnNumType + $scope.guide.guideFclCtns[i].num + " X " + $scope.guide.guideFclCtns[i].ctnType + "; ";
                    }
                }
            }

        }
        $scope.generateSo = function(index, isDb) {
            if (isDb) {                
                if(null == $scope.guide.soNum){
                    $scope.guide.booking.bookingTrucks[index].soNum = $scope.guide.mblNum;
                }
                else {
                    $scope.guide.booking.bookingTrucks[index].soNum = $scope.guide.soNum;
                }
            } else {
                if (!$scope.guide.booking.bookingTrucks[index].soNum) {
                    if(null == $scope.guide.soNum){
                        $scope.guide.booking.bookingTrucks[index].soNum = $scope.guide.mblNum;
                    }
                    else {
                        $scope.guide.booking.bookingTrucks[index].soNum = $scope.guide.soNum;
                    }
                }
            }
        }
        $scope.addTruckOrder = function() {
            $scope.guide.booking.bookingTrucks.push({
                status: "new",
                companyId: $rootScope.account.companyId,
                createdBy: $rootScope.account.id,
                isExport: true
            });
        }
        //----------------------------------------------- 拖车安排 --------------------------------------------------------------------------
        $scope.exportTruck = function(truckId, format) {
            if (!truckId) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            $scope.exportExcelPdf(format, 140, $scope.guide.jobNum, truckId);
        }

        $scope.viewBookingTruckFile = function(truckId) {
            if (!truckId) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            $scope.previewReport(140, truckId)
        }
        $rootScope.LOAD_REPORT_TEMPLATES();

    }
})();