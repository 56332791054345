(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('wms-warehouse', {
            parent: 'wms',
            url: '/wms-warehouse?page&sort&search',
            data: {
                authorities: ['FUN_WMS_WAREHOUSE'],
                pageTitle: 'autopsApp.wmsWarehouse.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wms-warehouse/wms-warehouses.html',
                    controller: 'WmsWarehouseController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wmsWarehouse');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('wms-warehouse.detail', {
            parent: 'wms-warehouse',
            url: '/detail',
            data: {
                authorities: ['FUN_WMS_WAREHOUSE'],
                pageTitle: 'autopsApp.wmsWarehouse.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wms-warehouse/wms-warehouse-detail.html',
                    controller: 'WmsWarehouseDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wmsWarehouse');
                    return $translate.refresh();
                }],
                entity: ['$rootScope', 'WmsWarehouse', function($rootScope, WmsWarehouse) {
                    if ($rootScope.id){
                        return WmsWarehouse.get({id : $rootScope.id}).$promise;
                    }                    
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'wms-warehouse',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('wms-warehouse.new', {
            parent: 'wms-warehouse',
            url: '/new',
            data: {
                authorities: ['FUN_WMS_WAREHOUSE'],
                pageTitle: 'autopsApp.wmsWarehouse.home.createLabel'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/wms-warehouse/wms-warehouse-detail.html',
                    controller: 'WmsWarehouseDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('wmsWarehouse');
                    return $translate.refresh();
                }],
                entity: function () {
                    return {};                 
                },
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'wms-warehouse',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('wms-warehouse.inventory', {
            parent: 'wms-warehouse',
            url: '/inventory',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$rootScope', '$state', '$uibModal', function($rootScope, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/wms-warehouse/wms-warehouse-dialog.html',
                    controller: 'WmsWarehouseDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: ['WmsWarehouse', function(WmsWarehouse) {
                            return WmsWarehouse.checkWareHouse({id : $rootScope.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('wms-warehouse', null, { reload: 'wms-warehouse' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
