(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsOutDetailController', WmsOutDetailController);

    WmsOutDetailController.$inject = ['$scope', '$rootScope', '$stateParams', '$location', '$state', '$translate', 'WinAES', 'Principal', 'previousState', 'WmsOut', 'WmsWarehouse', 'WmsIn', 'OpsPorts'];

    function WmsOutDetailController($scope, $rootScope, $stateParams, $location, $state, $translate, WinAES, Principal, previousState, WmsOut, WmsWarehouse, WmsIn, OpsPorts) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };

        var vm = this;

        vm.previousState = previousState.name;
        vm.wmsOut = {};
        vm.previousState = previousState.name;
        vm.save = save;
 
        vm.addAll = addAll;
        vm.addToList = addToList; 
        vm.delFromList = delFromList;     
        vm.delFromChoosed = delFromChoosed;                
        vm.warehouses = [];
        vm.storageList = [];
        vm.total = {};
        vm.warehouseSelected = warehouseSelected;
        vm.amendation = amendation;
        vm.isEdit = false;
        vm.submitOut = submitOut;

        vm.portList = [];
        vm.typeChange = typeChange;
        vm._portSelected = _portSelected;
        vm.portType = true;

        vm.podList = [];
        vm._onPodChoosed = _onPodChoosed;
        vm.storageListCopy = [];
        vm.clerks = [];
        vm._onClerkChoosed = _onClerkChoosed;
        vm.isSaving = false;
        vm.getWarehouses = getWarehouses;

        vm.pickingOrders = pickingOrders;

        $scope.loadPorts = function() {
            if ($rootScope.OPSPORTS.airports && $rootScope.OPSPORTS.airports.length > 0){
                typeChange();
                return;
            }
            OpsPorts.getAll(function(result){
                $rootScope.OPSPORTS.airports = [];
                $rootScope.OPSPORTS.seaports = [];
                angular.forEach(result, function(data){
                    if(data.type == 1){
                        $rootScope.OPSPORTS.seaports.push(data);
                    }
                    else {
                        $rootScope.OPSPORTS.airports.push(data);
                    }
                })
                typeChange();
            })
        };
        function typeChange () {
            if (vm.wmsOut.type == 'AIR'){
                vm.portList = $rootScope.OPSPORTS.airports;
                vm.portType = true;
            }
            else {
                vm.portList = $rootScope.OPSPORTS.seaports;
                vm.portType = false;
            }
        }
        function _portSelected (data) {
            vm.wmsOut.podId = data.code;
            vm.wmsOut.podName = data.name;
        }
        
       
        var wmsOutCopy = {};

        var path = $location.path();
        if (!$rootScope.id && path.indexOf("new") < 0) {
            $state.go('wms-out', null, {
                reload: true
            });
        }
        
        if ($rootScope.id && path.indexOf("detail") >= 0) {
            WmsOut.get({
                id: $rootScope.id
            }, function(result) {                
                vm.wmsOut = result;
                if (!vm.wmsOut.wmsIns){
                    vm.wmsOut.wmsIns = [];
                }    
                angular.forEach(vm.wmsOut.wmsIns, function(data){
                    if (data.guideId){
                        data.encodeId = WinAES.encode(data.guideId.toString());
                    }                    
                })
                angular.copy(vm.wmsOut, wmsOutCopy);
                updateList();            
            });
        } else {
            vm.wmsOut = {
                wmsIns: [],
                status: "new",
                allOut: false
            }
            vm.isEdit = true;
            angular.copy(vm.wmsOut, wmsOutCopy);
        }

        function getWarehouses () {
            if (vm.warehouses && vm.warehouses.length > 0){
                return;
            }
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false
                }, function (data) {
                vm.warehouses = data;
            });
        }

        function warehouseSelected (warehouse) {
            vm.wmsOut.warehouseName = warehouse.name;
            vm.wmsOut.warehouseId = warehouse.id;
            loadWmsIns(vm.wmsOut.warehouseId);
        }

        function loadWmsIns (warehouseId) {
            WmsIn.getAllForOut({warehouseId:warehouseId}, function(result) {
                vm.storageList = result;                
                angular.forEach(vm.storageList, function(data){
                    if (data.guideId){
                        data.encodeId = WinAES.encode(data.guideId.toString());
                    }                    
                })
                angular.copy(vm.storageList, vm.storageListCopy);
                getPodList();
                updateList();
            })
        }

        var unsubscribe = $rootScope.$on('autopsApp:wmsOutUpdate', function(event, result) {
            vm.wmsOut = result;
        });
        $scope.$on('$destroy', unsubscribe);

        function pickingOrders(){
            WmsOut.pickingOrders({},vm.wmsOut,function(result){
                exportExcelOrPdf(result);
            },function(error){
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        function exportExcelOrPdf(result) {
            var file = new Blob([result.data], {
                type: 'application/pdf'
            });
            var fileURL = URL.createObjectURL(file);
            var previewDialog = layer.open({
                type: 2,
                area: ['1200px', '800px'],
                maxmin: true,
                title: $translate.instant("autopsApp.wmsOut.pickingOrders"),
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: fileURL
            });
        }


        function amendation () {
            vm.isEdit = true;
            if (vm.wmsOut.warehouseId){
                loadWmsIns(vm.wmsOut.warehouseId);
            }
        }

        function save () {
            vm.isSaving = true;
            if (angular.equals(vm.wmsOut, wmsOutCopy)){
                layer.msg($translate.instant('global.no_amendmend'));
                vm.isSaving = false;
                return;
            }     
            vm.wmsOut.updateTime = new Date();
            vm.wmsOut.createdBy = $rootScope.account.id;
            vm.wmsOut.companyId = $rootScope.account.companyId; 

            if (vm.wmsOut.id) {
                WmsOut.update(vm.wmsOut, onSaveSuccess, onSaveError);
            } else {
                WmsOut.save(vm.wmsOut, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.wmsOut = result;
            angular.forEach(vm.wmsOut.wmsIns, function(data){
                if (data.guideId){
                    data.encodeId = WinAES.encode(data.guideId.toString());
                }                    
            })
            updateList();
            angular.copy(vm.wmsOut, wmsOutCopy);
            layer.msg($translate.instant('global.save_success'));
            vm.isSaving = false;
            vm.isEdit = false;
        }

        function onSaveError () {
            layer.msg($translate.instant('global.save_fail'));
            vm.isSaving = false;
        }

        function addToList (cargo) {
            var isRepeated = false;
            for(var j = vm.wmsOut.wmsIns.length -1; j >=0; j--){
                if (cargo.id == vm.wmsOut.wmsIns[j].id){
                    isRepeated = true;
                    break;
                }
            }
            if (isRepeated){
                layer.msg($translate.instant('global.messages.repeated'));
            }
            else {
                vm.wmsOut.wmsIns.push(cargo);
            }

            var index = vm.storageList.indexOf(cargo);
            if (index > -1) {
                vm.storageList.splice(index, 1);
                updateList();
            };            
        }

        function delFromList (cargo) {
            var index = vm.storageList.indexOf(cargo);
            if (index > -1) {
                vm.storageList.splice(index, 1);
                updateList();
            };            
        }

        function delFromChoosed (cargo) {
            vm.storageList.push(cargo);
            var index = vm.wmsOut.wmsIns.indexOf(cargo);
            if (index > -1) {
                vm.wmsOut.wmsIns.splice(index, 1);
                updateList();
            };
        }

        function addAll() {
            for(var i = vm.storageList.length -1; i >=0; i--){      
                addToList(vm.storageList[i]);
            }
        }

        function updateList () {
            var choosedGW = 0;
            var choosedVol = 0;
            var choosedPkgs = 0;
            var unChoosedGW = 0;
            var unChoosedVol = 0;
            var unChoosedPkgs = 0;
            angular.forEach(vm.wmsOut.wmsIns, function(data) {
                if (data.applicationNum){
                    choosedPkgs = choosedPkgs + data.applicationNum;
                }
                if (data.gw){
                    choosedGW = choosedGW + data.gw;
                }
                if (data.vol){
                    choosedVol = choosedVol + data.vol;
                }
            });

            angular.forEach(vm.storageList, function(data) {
                if (data.applicationNum){
                    unChoosedPkgs = unChoosedPkgs + data.applicationNum;
                }
                if (data.gw){
                    unChoosedGW = unChoosedGW + data.gw;
                }
                if (data.vol){
                    unChoosedVol = unChoosedVol + data.vol;
                }
            });

            vm.total.unChoosedPkgs = unChoosedPkgs.toFixed(2) + "PKGS";
            vm.total.unChoosedGW = unChoosedGW.toFixed(2) + "KGS";
            vm.total.unChoosedVol = unChoosedVol.toFixed(2) + "CBM";
            vm.total.choosedPkgs = choosedPkgs.toFixed(2) + "PKGS";
            vm.total.choosedGW = choosedGW.toFixed(2) + "KGS";
            vm.total.choosedVol = choosedVol.toFixed(2) + "CBM";  

            vm.wmsOut.gw = choosedGW.toFixed(2) - 0;
            vm.wmsOut.vol = choosedVol.toFixed(2) - 0;
            vm.wmsOut.applicationNum = choosedPkgs.toFixed(2) - 0;
        }

        function getPodList () {
            vm.podList = [];
            angular.forEach(vm.storageListCopy, function(data){
                if(data.podName && vm.podList.indexOf(data.podName) == -1){
                    vm.podList.push(data.podName);
                }
            });
            vm.clerks = [];
            angular.forEach(vm.storageListCopy, function(data){
                if(data.opName && vm.clerks.indexOf(data.opName) == -1){                    
                    vm.clerks.push(data.opName);
                }
            });
        }

        function _onClerkChoosed (item) {
            vm.storageList = [];
            angular.forEach(vm.storageListCopy, function(data){
                if(data.opName == item){
                    vm.storageList.push(data);
                }
            });
        }

        function _onPodChoosed (item) {
            vm.storageList = [];
            angular.forEach(vm.storageListCopy, function(data){
                if(data.podName == item){
                    vm.storageList.push(data);
                }
            });
        }

        function submitOut () {
            if (vm.isSaving){
                return;
            }
            if (!angular.equals(vm.wmsOut, wmsOutCopy)){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }    
            if (!vm.wmsOut.id){
                layer.msg($translate.instant('autopsApp.wmsOut.notSaveAlert')); 
                return;
            }
            if (!vm.wmsOut.type){
                layer.msg($translate.instant('autopsApp.wmsOut.shipmentTypeNull')); 
                return;
            }
            vm.isSaving = true;
            WmsOut.generateGuide(vm.wmsOut, function (result) {
                vm.wmsOut = result;
                angular.forEach(vm.wmsOut.wmsIns, function(data){
                    if (data.guideId){
                        data.encodeId = WinAES.encode(data.guideId.toString());
                    }                    
                })                
                vm.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));                    
            });            
        }       

    }
})();
