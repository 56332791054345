(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('WmsGoods', WmsGoods);

    WmsGoods.$inject = ['$resource','DateUtils'];

    function WmsGoods ($resource, DateUtils) {
        var resourceUrl =  'api/wms-goods/:id';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        if(data.goodsBatchList){
                        	for(var i = 0 ; i < data.goodsBatchList.length; i++){
                            	data.goodsBatchList[i].expirationDate = DateUtils.convertLocalDateFromServer(data.goodsBatchList[i].expirationDate);
                                data.goodsBatchList[i].productionDate = DateUtils.convertLocalDateFromServer(data.goodsBatchList[i].productionDate);
                            }
                        }
                    }
                    return data;
                }
            },
            'update': { 
            	method:'PUT',
            	transformRequest: function (data) {
                    if (data) {
                    	if(data.goodsBatchList){
	                        for(var i = 0 ; i < data.goodsBatchList.length; i++){
	                        	data.goodsBatchList[i].expirationDate = DateUtils.convertLocalDateToServer(data.goodsBatchList[i].expirationDate);
	                        	data.goodsBatchList[i].productionDate = DateUtils.convertLocalDateToServer(data.goodsBatchList[i].productionDate);
	                        }
                    	}
                    }
                    data = angular.toJson(data);
                    return data;
                },
            	transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        if(data.goodsBatchList){
	                        for(var i = 0 ; i < data.goodsBatchList.length; i++){
	                        	data.goodsBatchList[i].expirationDate = DateUtils.convertLocalDateFromServer(data.goodsBatchList[i].expirationDate);
	                            data.goodsBatchList[i].productionDate = DateUtils.convertLocalDateFromServer(data.goodsBatchList[i].productionDate);
	                        }
                        }
                    }
                    return data;
                }
            },
            'del':{
            	method:'DELETE'
            },
            'skuBarcode':{
                url:'api/wms-goods/skuBarcode',
                method:'POST',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'checkSku':{
                url:'api/wms-goods/checkSku',
                method:'POST'
            },
            'sendProductApi':{
                url:'api/api-wms/sendProductApi',
                method:'POST'
            },
            'saveAll':{
                url:'api/wms-goods/saveAll',
                method:'POST'
            },
            'delAll':{
                url:'api/wms-goods/delAll',
                method:'PUT'
            },
            'getSkuByCode':{
                url:'api/wms-goods/getSkuByCode',
                method:'GET'
            },            
            'refreshInventory':{
                url:'api/wms-goods/refreshInventory',
                method:'GET'
            },            
            'refreshInventoryBySku':{
                url:'api/wms-goods/refreshInventoryBySku',
                method:'GET'
            }, 
            'searchByNums':{
                url:'api/wms-goods/searchByNums',
                method:'GET',
                isArray: true
            },             
            'getProductApiRequestBody':{
                method: 'POST',
                url:'api/api-wms/getProductApiRequestBody'
            },
            'goodsTypeHead':{
            	url:'api/wms-goods/typeHead/:wmsGoodsHsCode',
            	method:'GET',
            	isArray: true,
            	transformRequest: function (data) {
            		data = angular.toJson(data);
                    return data;
            	}
            }
        });
    }
})();
