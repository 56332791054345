(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountAuthDialogController', AccountAuthDialogController);

    AccountAuthDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$uibModal', '$translate',
    'entity', 'AccountAuth', 'OpsVender', 'ExpressParcel'];

    function AccountAuthDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $uibModal, $translate,
        entity, AccountAuth, OpsVender, ExpressParcel) {
        var vm = this;

        vm.requestEntity = entity;
        vm.clear = clear;
        vm.save = save;
        vm.addOne = addOne;
        vm.deleteOne = deleteOne;
        vm.getServiceTypes = getServiceTypes;

        vm.clientSelected = clientSelected;
        vm.getSubSysClients = getSubSysClients;

        vm.accountAuths = [];
        vm.clients = [];
        function clientSelected (accountAuth, client) {
            accountAuth.venderId = client.id;
            accountAuth.venderName = client.companyName;
        }

        function getSubSysClients () {
            OpsVender.subSysClients(function(result) {
                vm.clients = result;
            })
        }

        $timeout(function (){
            loadList();
        });


        vm.addressBindTag = true;
        function loadList () {
            if(entity.id){
                AccountAuth.getByAddressBookId({addressBookId : entity.id}, function(result){
                    vm.accountAuths = result;
                    initName();
                });
            }
            else if(entity.chargeChannelId){
                vm.addressBindTag = false;
                AccountAuth.getByChargeChannelId({chargeChannelId : entity.chargeChannelId}, function(result){
                    vm.accountAuths = result;
                    initName();
                });
            }
        }
        function initName () {
            angular.forEach(vm.accountAuths, function(data) {
                if(data.chargeChannelId){
                    data.chargeChannel = entity.chargeChannel;
                }
                else if(data.addressBookId){
                    data.addressCode = entity.code;
                    data.accountCode = entity.accountNum;
                }
                if(data && data.servicesJson){
                    data.servicesJson = angular.fromJson(data.servicesJson, true);
                }
            })
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function addOne () {
            if(!vm.accountAuths){
                vm.accountAuths = [];
            }
            if(entity.id){
                vm.accountAuths.push({
                    addressBookId: entity.id,
                    addressCode: entity.code,
                    accountCode: entity.accountNum
                });
            }
            else {
                vm.accountAuths.push({
                    chargeChannelId: entity.chargeChannelId,
                    chargeChannel: entity.chargeChannel
                });
            }
        }

        function deleteOne (accountAuth, index) {
            if(!accountAuth.id){
                vm.accountAuths.splice(index, 1);
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/account-auth/account-auth-delete-dialog.html',
                controller: 'AccountAuthDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return accountAuth;
                    }]
                }
            }).result.then(function() {
                vm.accountAuths.splice(index, 1);
            }, function() {
            });
        }

        function save (accountAuthOrg) {
            vm.isSaving = true;
            var accountAuth = angular.copy(accountAuthOrg);
            if(accountAuth.servicesJson){
                accountAuth.servicesJson = angular.toJson(accountAuth.servicesJson);
            }
            if (accountAuth.id !== null) {
                AccountAuth.update(accountAuth, onSaveSuccess, onSaveError);
            } else {
                AccountAuth.save(accountAuth, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            // $scope.$emit('autopsApp:accountAuthUpdate', result);
            // $uibModalInstance.close(result);
            layer.msg($translate.instant('global.save_success'));
            loadList();
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        $scope.shipServiceSelected = function(accountAuth, item) {
            if(!accountAuth.servicesJson){
                accountAuth.servicesJson = [];
            }
            var isNew = true;
            angular.forEach(accountAuth.servicesJson, function(data){
                if(item.type == data.type && item.discription == data.discription){
                    isNew = false;
                }
            })
            if(isNew){
                var service = {
                    "type": item.type,
                    "discription": item.discription
                }
                accountAuth.servicesJson.push(service);
            }
            accountAuth.serviceDescription="";
        }

        vm.serviceTypes = [];
        function getServiceTypes() {
            if(vm.serviceTypes && vm.serviceTypes.length > 0){
                return;
            }
            ExpressParcel.getServicesList({
                getAll: true,
                addressId: vm.requestEntity.id
            }, function(results){
                vm.serviceTypes = results;
            })
        }

        $scope.delService = function(accountAuth, index) {
            accountAuth.servicesJson.splice(index, 1);
        };
    }
})();
