(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsWorkLogListController', WmsWorkLogListController);

    WmsWorkLogListController.$inject = ['$scope', '$uibModalInstance', '$rootScope', '$translate', '$uibModal', 'entity', 'WmsWorkLog', 'OpsVender'];

    function WmsWorkLogListController ($scope, $uibModalInstance, $rootScope, $translate, $uibModal, entity, WmsWorkLog, OpsVender) {
       
        
        var vm = this;
        vm.guide = entity;
        var typeTemp = entity.typeTemp;
        
        vm.clear = clear;
        vm.deleteOne = deleteOne;
        vm.getWmsWorkLogs = getWmsWorkLogs;
        vm.saveBilledWorkLogs = saveBilledWorkLogs;

        vm.isSaving = false;
        vm.wmsWorkLogs = [];
        vm.searchParams = {};

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.workTime = false;
        vm.datePickerOpenStatus.createdTime = false;

        vm.searchParams.companyId = vm.guide.companyId;

        vm.searchParams.venderId = vm.guide.shipperId;
        vm.searchParams.shipperName = vm.guide.shipperName;
        if(typeTemp == 'wms'){
            vm.searchParams.venderId = vm.guide.venderId;
            vm.searchParams.shipperName = vm.guide.clientName;
        }

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }  

        $scope._onClientSelected = function(data) {
            vm.searchParams.venderId = data.id;
        }

        function getWmsWorkLogs() {
            vm.isSaving = true;

            WmsWorkLog.getWmsWorkLogs(vm.searchParams, function(result) {
                vm.isSaving = false;
                vm.wmsWorkLogs = result;
                if (vm.wmsWorkLogs.length == 0){
                    layer.msg($translate.instant('global.messages.resultNull'));
                }
                
            }, function(response) {
                vm.isSaving = false;
            });  
        };

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function deleteOne (workLog) {
            var index = vm.wmsWorkLogs.indexOf(workLog);
            if (index > -1) {
                vm.wmsWorkLogs.splice(index, 1);
            };
        }

        function saveBilledWorkLogs () {
            vm.isSaving = true;
            var num = vm.guide.jobNum;
            if(typeTemp == 'wms'){
                num = vm.guide.warehousingNumber;
            }
            WmsWorkLog.saveBilledWorkLogs({
               type: typeTemp,
               jobNum: num,
               guideId: vm.guide.id
            }, vm.wmsWorkLogs, function(result) {
                vm.isSaving = false;
                $uibModalInstance.close(result);
            }, function(response) {
                vm.isSaving = false;
            });  
        };

    }
})();
