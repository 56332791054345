
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('SkuBindDetailController', SkuBindDetailController);

    SkuBindDetailController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', 
    'entity', 'SkuBind', 'AccountBind', 'WmsWarehouse'];

    function SkuBindDetailController ($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, 
        entity, SkuBind, AccountBind, WmsWarehouse) {
        var vm = this;

        vm.skuBind = entity;
        vm.clear = clear;
        vm.save = save;
        vm.clientSelected = clientSelected;
        vm.platFormSelected = platFormSelected;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            SkuBind.advanceSearch(vm.skuBind, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function clientSelected (client) {
            vm.skuBind.venderId = client.id;
        }

        vm.accountBinds = [];
        function getAllAccountBind () {
            AccountBind.getAll(function(resultList){
                vm.accountBinds = resultList;
                vm.accountBinds.push({id:0,name:""});
            });
        }
        getAllAccountBind();

        vm.wmswarehouses = [];
        function getWarehouses () {
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false
                }, function (data) {
                vm.wmswarehouses = data;
            });
        }
        getWarehouses();

        function platFormSelected (id) {
            angular.forEach(vm.accountBinds, function(data) {
                if (data.id == id){
                    vm.skuBind.platform = data.type;                        
                }
            });
        }

    }
})();
