(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('VirtualWarehouseDeleteController',VirtualWarehouseDeleteController);

    VirtualWarehouseDeleteController.$inject = ['$uibModalInstance', 'entity', 'VirtualWarehouse'];

    function VirtualWarehouseDeleteController($uibModalInstance, entity, VirtualWarehouse) {
        var vm = this;

        vm.virtualWarehouse = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            VirtualWarehouse.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
