(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('JhiSignatureSearch', JhiSignatureSearch);

    JhiSignatureSearch.$inject = ['$resource'];
    
    function JhiSignatureSearch($resource) {
        return $resource('api/_search/jhiSignatures/:query', {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
