(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CashFlowSubjectDialogController', CashFlowSubjectDialogController);

    CashFlowSubjectDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$translate', '$uibModalInstance', 'entity', 'CashFlowSubject'];

    function CashFlowSubjectDialogController ($timeout, $scope, $rootScope, $translate, $uibModalInstance, entity, CashFlowSubject) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;

        $scope.accountVoucher = entity;
        $scope.cashFlowSubjects = [];
        $timeout(function (){
            CashFlowSubject.query(function(result){
                $scope.cashFlowSubjects = result;
                angular.forEach($scope.accountVoucher.voucherContents, function(data){
                    if(data.cashFlowId != null){
                        data.choosed = false;
                        $scope.chooseContent(data);
                    }
                })
            },function(error){
                layer.msg('GET CASH FLOW FAILED!');
            });
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function save () {
            $uibModalInstance.close();
        }

        $scope.cfContentFilter = function(item) {
            return item.cashFlowId != null;
        };

        $scope.cfSubjectFilter = function(item) {
            if((item.isDn == true || item.isDn == null) && $scope.choosedVouContent && $scope.choosedVouContent.dn){
                return true;
            }
            if((item.isDn == false || item.isDn == null) && $scope.choosedVouContent && $scope.choosedVouContent.cn){
                return true;
            }
            return false;
        };

        $scope.choosedVouContent = null;
        $scope.choosedCashFlow = null;
        $scope.chooseContent = function(content){
            if (content.choosed){
                content.choosed = false;
                $scope.choosedVouContent = null;
                $scope.choosedCashFlow = null;
                return;
            }
            $scope.choosedVouContent = content; 
            $scope.choosedCashFlow = null;           
            for(var i = 0 ; i < $scope.cashFlowSubjects.length ; i++){
                if(content.cashFlowId && $scope.cashFlowSubjects[i].id == content.cashFlowId){
                    $scope.choosedCashFlow = $scope.cashFlowSubjects[i];
                    $scope.cashFlowSubjects[i].choosed = true;
                }
                else {
                    $scope.cashFlowSubjects[i].choosed = false
                }
            }
            angular.forEach($scope.accountVoucher.voucherContents, function(data){
                if(data.id == $scope.choosedVouContent.id){
                    data.choosed = true;
                }
                else {
                    data.choosed = false;
                }
            });
        }
        $scope.chooseCashFlowSuject = function(casheFlow){
            if (!$scope.choosedVouContent){
                layer.msg($translate.instant('autopsApp.accountVoucher.chooseContentFirstlyAlert'));
                return;
            }
            if ($scope.choosedCashFlow && casheFlow.id == $scope.choosedCashFlow.id){
                $scope.choosedCashFlow = null;
                casheFlow.choosed = false;
                setCashIdToVoucher();
                return;
            }
            $scope.choosedCashFlow = casheFlow;
            setCashIdToVoucher();
            for(var i =  0 ;  i < $scope.cashFlowSubjects.length; i++){
                if(casheFlow.id == $scope.cashFlowSubjects[i].id){
                    $scope.cashFlowSubjects[i].choosed = true;                      
                }
                else {
                    $scope.cashFlowSubjects[i].choosed = false;  
                }
            }
        }
        var setCashIdToVoucher = function(){
            if($scope.choosedVouContent){
                if ($scope.choosedCashFlow){
                    if($scope.choosedCashFlow.companyId != $scope.choosedVouContent.companyId){
                        layer.msg($translate.instant('global.refreshAlert'));
                    }
                    else {
                        $scope.choosedVouContent.cashFlowId = $scope.choosedCashFlow.id;
                    }                    
                }
                else {
                    $scope.choosedVouContent.cashFlowId = 0;

                }
            }
        }        
   
    }
})();
