(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('OpsLog', OpsLog);

    OpsLog.$inject = ['$resource', 'DateUtils'];

    function OpsLog ($resource, DateUtils) {
        var resourceUrl =  'api/ops-logs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.timeStamp = DateUtils.convertDateTimeFromServer(data.timeStamp);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getFilterPage': {
                method: 'POST',
                url: "api/ops-logs/getFilterPage",
                isArray: true
            },
            'amassExportOpsLogs': {
                method: 'POST',
                url:'api/excel/amassExportOpsLogs',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            }
        });
    }
})();
