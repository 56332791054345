(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('InvoiceQueryDialogController', InvoiceQueryDialogController);

    InvoiceQueryDialogController.$inject = ['$uibModalInstance', '$uibModal', '$scope', 'entity', 'AccountInvoice'];

    function InvoiceQueryDialogController ($uibModalInstance, $uibModal, $scope, entity, AccountInvoice) {
        var vm = this;    
        vm.queryRequest = {};    
        vm.queryRequest.sellerTaxNo = entity.sellerTaxNo;
        vm.queryRequest.returnType = "1";
        vm.queryRequest.size = "100";
        vm.queryRequest.invoiceTerminalCode = entity.invoiceTerminalCode;
        vm.queryRequest.deviceType = "1";
        
        vm.isSaving = false;

        vm.clear = clear;
        vm.submit = submit;

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }       

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function submit () {
            vm.isSaving = true;
            AccountInvoice.invoiceQuery(vm.queryRequest, function(result){
                vm.isSaving = false;
                $uibModal.open({
                    templateUrl: 'app/entities/accountant/accountInvoice/invoice-query-result-dialog.html',
                    controller: 'InvoiceQueryResultDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: [function() {
                            return result;
                        }]
                    }
                }).result.then(function(result) {
                }, function() {
                });
            }, function(error){
                vm.isSaving = false;
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                } 
                else {
                    layer.msg($translate.instant("global.messages.submittedfailed")); 
                }  
            })
        }
    }
})();
