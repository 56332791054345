(function () {
    'use strict';
    angular
        .module('autopsApp')
        .controller('ExpressParcelScanCodeController', ExpressParcelScanCodeController);

    ExpressParcelScanCodeController.$inject = ['$rootScope', '$scope', '$state', '$translate', '$uibModal', '$timeout', '$location',
        'Principal', 'DataUtils', 'Guide', 'GuideSearch', 'OpsFiles', 'WmsWarehouse', 'uiGridConstants', 'GridState', 'ExpressParcel',
        'ExpressPacking', 'DateUtils', 'WmsIn'];

    function ExpressParcelScanCodeController($rootScope, $scope, $state, $translate, $uibModal, $timeout, $location,
                                             Principal, DataUtils, Guide, GuideSearch, OpsFiles, WmsWarehouse, uiGridConstants, GridState, ExpressParcel,
                                             ExpressPacking, DateUtils, WmsIn) {
        var vm = this;

        vm.searchParcels = searchParcels;
        vm.printLabel = printLabel;

        vm.autoPrint = true;
        vm.labelCount = 1;
        vm.boxNoLen = 0;
        vm.searchStr = '';
        vm.formResponse = [];

        vm.seachField = "poNum";
        vm.changeType = "expressLabel";
        vm.bindEvent = "checkOut";

        function searchParcels() {
            var searchNo = vm.searchStr;
            if(vm.boxNoLen && searchNo.length > vm.boxNoLen){
                searchNo = searchNo.substring(0, searchNo.length - vm.boxNoLen);
            }
            ExpressParcel.searchByNoPrefix({
                seachField: vm.seachField,
                isFms: false
            }, searchNo, function (result) {
                result.boxNo = vm.searchStr;
                appendScanCode(result);
            }, function (error) {
                var notFoundObj = {};
                notFoundObj.boxNo = vm.searchStr;
                vm.formResponse.unshift(notFoundObj);
                $rootScope.ALERT_ERROR(error);
            });
        }
        function getIdx(result) {
            for(var i = 0; i < vm.formResponse.length; i++){
                if(vm.formResponse[i].id == result.id){
                    return i;
                }
            }
            return -1;

        }

        function appendScanCode(result) {      
            result.nodeField = DataUtils.getTmsPcNodeField(result, vm.bindEvent);            
            result.nodeTime = result[result.nodeField];      

            if(result && result.id){
                var findIndex = getIdx(result);
                if (findIndex !== -1) {
                    vm.formResponse.splice(findIndex, 1)
                }
                vm.formResponse.unshift(result);
            }

            if (vm.autoPrint && !result.nodeTime) {
                printLabel(result);                
            }
            if (vm.formResponse.length > 15) {
                vm.formResponse.pop();
            }
        }

        function printLabel(result) {
            if(result.isVoid){
                layer.msg($translate.instant('autopsApp.guide.home.voidedAlert'));
                return;
            }

            if(vm.changeType == 'expressLabel'){
                if(!result.labelUrl){
                    layer.msg($translate.instant('autopsApp.expressParcel.noLabelAlert')); 
                    return;
                }
                else {
                    printExpressLabel(result.id);
                }                    
            }
            else if(vm.changeType == 'printOwnLabels'){
                printOwnLabels(result, 710);
            }
            else if (vm.changeType == 'boxLabel'){
                printWmsLabels(result, 690);
            }

            if(!result.nodeTime){
                doSetStatus(result);
            }            
        }

        function doSetStatus(shipment) {
            if(shipment[shipment.nodeField]){
                return;
            }
            ExpressParcel.setStatus({
                expressId: shipment.id,
                status: vm.bindEvent,
                timeStr: new Date()
            }, function(result) {
                var findIndex = getIdx(result);
                if(findIndex !== -1){
                    vm.formResponse[findIndex].nodeTime = result[shipment.nodeField];
                    vm.formResponse[findIndex][shipment.nodeField] = result[shipment.nodeField];
                }
                layer.msg($translate.instant('global.update_success'));
            }, function(result) {
                if (result.status == 404){
                    layer.msg($translate.instant('global.refreshAlert'));
                }
                else if (result.status == 409){
                    layer.msg($translate.instant('autopsApp.guide.ratesNotNullAlert'));
                }
                else {
                    layer.msg($translate.instant('global.save_fail'));
                }
            });
        }

        function printExpressLabel(parcelId, pageIdx) {
            ExpressParcel.autoPrintLabel({
                id: parcelId,
                page: pageIdx
            }, function (result) {
                for (var i = 0; i < vm.labelCount; i++) {
                    $rootScope.PRINT_BYTES_DIR(result.data);
                }
            }, function (error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }
        function printOwnLabels(shipment, reportType) {
            ExpressParcel.printLabel({
                reportType: 710
            }, shipment, function(result) {
                for (var i = 0; i < vm.labelCount; i++) {
                    $rootScope.PRINT_BYTES_DIR(result.data);
                }
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }
        function printWmsLabels(shipment, reportType) {
            if(!shipment.shipperId){
                layer.msg($translate.instant('autopsApp.guide.clientNotChoosedAlert'));
                return;
            }
            var wmsIn = {};
            wmsIn.parcelId = shipment.id;
            wmsIn.companyId = shipment.companyId;
            WmsIn.wmsLabels({
                reportType: reportType,
                fileType: 1
            }, wmsIn, function(result) {
                for (var i = 0; i < vm.labelCount; i++) {
                    $rootScope.PRINT_BYTES_DIR(result.data);
                }
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

    }


})();
