(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('Palletize', Palletize);

    Palletize.$inject = ['$resource', 'DateUtils'];

    function Palletize ($resource, DateUtils) {
        var resourceUrl =  'api/palletize/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.expirationDate = DateUtils.convertLocalDateToServer(data.expirationDate);
                    data.productionDate = DateUtils.convertLocalDateToServer(data.productionDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.expirationDate = DateUtils.convertLocalDateFromServer(data.expirationDate);
                        data.productionDate = DateUtils.convertLocalDateFromServer(data.productionDate);
                    }
                    return data;
                }
            },
            'deleteBox': {
                method:'DELETE'
            },
            'deletePallet': {
                method: 'POST',
                url: 'api/palletize/deletePallet'
            },
            'getWmsInByWarehousingNumber':{
                method: 'GET',
                url:'api/palletize/getWmsInByWarehousingNumber',
            }
        });
    }
})();
