(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ShareChargesDialogController', ShareChargesDialogController);

    ShareChargesDialogController.$inject = ['$rootScope', '$uibModalInstance', '$scope', '$translate', '$timeout', '$uibModal', 
    'entity', 'ExpressParcel', 'Guide', 'DataUtils'];

    function ShareChargesDialogController ($rootScope, $uibModalInstance, $scope, $translate, $timeout, $uibModal, 
        entity, ExpressParcel, Guide, DataUtils) {
               
        var vm = this;
        var isPay = entity.isPay;
        vm.shareMCostDTO = entity;
        vm.clear = clear;
        vm.delHGuide = delHGuide;
        vm.delRate = delRate;
        vm.submitShare = submitShare;
        vm.setShareType = setShareType;
        vm.isSaving = false;
        vm.exSharedRates = true;
        vm.exSharedRatesChange = exSharedRatesChange;
        vm.cargoType = "";
        vm.cargoTypeChange = cargoTypeChange;
        vm.filterChange = filterChange;
        vm.updateVWtotal = updateVWtotal;

        var consolShipmentsCopy = [];
        vm.filterStr = "";       

        $timeout(function (){
            vm.shareMCostDTO.consolShipmentDTOs = [];
            vm.shareMCostDTO.shareRates = [];
            vm.shareMCostDTO.type = "vol";
            angular.forEach(vm.shareMCostDTO.masterGuide.guideRates, function(data){
                if (!data.isHidden 
                    && data.payAmount 
                    && data.item
                    && (!data.dnNum || data.dnNum.indexOf("CS") == -1)){
                    if(isPay == data.isPay){
                        vm.shareMCostDTO.shareRates.push(data);
                    }                    
                }
            })
            if(vm.shareMCostDTO.shareRates.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                clear();
                return;
            }
            ExpressParcel.getSubShipments({
                companyId: $rootScope.account.companyId,
                isFms: vm.shareMCostDTO.isFms,
                masterId: vm.shareMCostDTO.masterId,
                forShare: true,
            }, function(result){
                vm.shareMCostDTO.consolShipmentDTOs = result;
                angular.forEach(vm.shareMCostDTO.consolShipmentDTOs, function(data) {
                    DataUtils.fillFdLabel(data);
                })
                consolShipmentsCopy = angular.copy(vm.shareMCostDTO.consolShipmentDTOs);
                updateVWtotal();
            })
            filterByExSharedRates();
        })
        
        function cargoTypeChange () {
            vm.shareMCostDTO.consolShipmentDTOs = [];
            angular.forEach(consolShipmentsCopy, function(data){
                if (!vm.cargoType || data.cargoType == vm.cargoType){
                    vm.shareMCostDTO.consolShipmentDTOs.push(data);
                }
            })
            updateVWtotal();
        }

        function filterChange () {
            if (!vm.filterStr){
                vm.shareMCostDTO.consolShipmentDTOs = consolShipmentsCopy;
                return;
            }
            vm.shareMCostDTO.consolShipmentDTOs = [];
            angular.forEach(consolShipmentsCopy, function(data){
                if (data.mblNum && data.mblNum.indexOf(vm.filterStr) !== -1){
                    vm.shareMCostDTO.consolShipmentDTOs.push(data);
                }
                else if (data.soNum && data.soNum.indexOf(vm.filterStr) !== -1){
                    vm.shareMCostDTO.consolShipmentDTOs.push(data);
                }
                else if (data.jobNum && data.jobNum.indexOf(vm.filterStr) !== -1){
                    vm.shareMCostDTO.consolShipmentDTOs.push(data);
                }
            })
            updateVWtotal();
        }

        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function setShareType (type) {
            vm.shareMCostDTO.type = type;
        }
        function delHGuide (guide) {
            var index = vm.shareMCostDTO.consolShipmentDTOs.indexOf(guide);
            if (index > -1) {
                vm.shareMCostDTO.consolShipmentDTOs.splice(index, 1);
            };
            updateVWtotal();
        }
        function delRate (rate) {
            var index = vm.shareMCostDTO.shareRates.indexOf(rate);
            if (index > -1) {
                vm.shareMCostDTO.shareRates.splice(index, 1);
            };
            updateTotal();
        }
        function exSharedRatesChange () {
            vm.exSharedRates = !vm.exSharedRates;
            filterByExSharedRates();
        }
        function filterByExSharedRates () {
            vm.shareMCostDTO.shareRates = [];
            angular.forEach(vm.shareMCostDTO.masterGuide.guideRates, function(data){
                if (!data.isHidden 
                    && data.item
                    && data.isPay == isPay 
                    && data.payAmount && (!data.dnNum || data.dnNum.indexOf("CS") == -1)){
                    if (vm.exSharedRates){
                        if (!data.sharedOn){
                            vm.shareMCostDTO.shareRates.push(data);
                        }                        
                    }
                    else {
                        vm.shareMCostDTO.shareRates.push(data);
                    }                    
                }
            })
            updateTotal();
        }
        vm.costTotalList = [];
        function updateTotal () {
            vm.costTotalList = [];
            var currencys = [];
            angular.forEach(vm.shareMCostDTO.shareRates, function(data){
                if (data.payCurrency && currencys.indexOf(data.payCurrency) === -1){
                    currencys.push(data.payCurrency);
                }
            })

            var cost = 0;
            angular.forEach(currencys, function(currency){
                cost = 0;
                angular.forEach(vm.shareMCostDTO.shareRates, function(rate){
                    if (rate.payCurrency == currency && rate.payAmount){
                        if (rate.isPay == isPay) {
                            cost = cost + rate.payAmount;
                        }
                    }
                })
                vm.costTotalList.push({currency: currency, total: cost.toFixed(2) -0});
            })
        }
        vm.vwTtl = {};
        function updateVWtotal () {
            var gw=0,vol=0,cw=0,pallets=0,teus=0,pkgNum=0;
            angular.forEach(vm.shareMCostDTO.consolShipmentDTOs, function(data){
                if(data.gw){
                    gw = gw + data.gw;
                }
                if(data.vol){
                    vol = vol + data.vol;
                }
                if(data.cw){
                    cw = cw + data.cw;
                }
                if(data.pallets){
                    pallets = pallets + data.pallets;
                }
                if(data.teus){
                    teus = teus + data.teus;
                }
                if(data.pkgNum){
                    pkgNum = pkgNum + data.pkgNum;
                }
            })
            vm.vwTtl.gw = gw.toFixed(2) -0;
            vm.vwTtl.vol = vol.toFixed(3) -0;
            vm.vwTtl.cw = cw.toFixed(2) -0;
            vm.vwTtl.pallets = pallets.toFixed(2) -0;
            vm.vwTtl.teus = teus.toFixed(2) -0;
            vm.vwTtl.pkgNum = pkgNum.toFixed(2) -0;
        }
        var onShareError = function(result) {
            layer.closeAll();
            vm.isSaving = false;
            if (result.status == 409){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.expiredAlert'));
            }
            else if (result.status == 403){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.shareCostError'));
            }
            else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        };
        
        var onShareSuccess = function(result) {
            layer.closeAll();
            vm.isSaving = false;            
            $uibModalInstance.close(result);
        };

        function submitShare() {  
            if (!vm.shareMCostDTO.type){
                layer.msg($translate.instant('global.invalidRequest'));
                return;
            }
            if (vm.shareMCostDTO.consolShipmentDTOs.length == 0){
                layer.msg($translate.instant('autopsApp.guide.noShareShipments'));
                return;
            }
            if (vm.shareMCostDTO.shareRates.length == 0){
                layer.msg($translate.instant('autopsApp.guide.noShareCharges'));
                return;
            }
            if (vm.shareMCostDTO.type == "teu"){
                var isAllFcl = true;
                angular.forEach(vm.shareMCostDTO.consolShipmentDTOs, function(data){
                    if (data.shipmentType != 'FCL'){
                        isAllFcl = false;
                    }
                })
                if (!isAllFcl){
                    layer.msg($translate.instant('autopsApp.guide.byTeuAlert'));
                    return;
                }
            }
            vm.isSaving = true;
            var entity = angular.copy(vm.shareMCostDTO);
            layer.load(1, { shade: 0.3 });
            ExpressParcel.shareCharges(entity, onShareSuccess, onShareError);
        }

        $scope.searchStr = "";
        $scope.virtualWms = false;
        var loadFmsBiz = vm.shareMCostDTO.isFms;
        $scope.searchType = 'Job#';

        $scope.setSearchType = function(type) {
            $scope.searchType = type;
        }

        $scope.searchGuide = function(value) {
            if (!$scope.searchStr){
                return;
            }
            loadFmsBiz = value;
            if(loadFmsBiz){
                searchGuides();                
            }
            else {
                searchParcels();
            }
        };

        function searchGuides() {        
            Guide.searchGuide({
                isFms: loadFmsBiz,
                searchType: $scope.searchType,
                jobType: "shareCharges",
                virtualWms: $scope.virtualWms,
                companyId: $rootScope.account.companyId,
                inputStr: $scope.searchStr
            }, function(result) {
                addAllToGuides(result);
                $scope.searchStr = null;
            }, function(response) {
            });  
        }

        function searchParcels() {
            ExpressParcel.searchByNum({
                isFms: loadFmsBiz,
                type: "shareCharges",
                searchType: $scope.searchType,
                companyId: $rootScope.account.companyId,
                inputStr: $scope.searchStr,
                isTms: $scope.isTms
            }, function(result) {
                addAllToGuides(result);
                $scope.searchStr = null;
            }, function(response) {
            });  
        }

        function searchParcelsPop(type) {
            var searchParams = {
                isShareCharges: true,
                shipmentType: type,
                companyId: $rootScope.account.companyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/express-parcel/express-parcel-detail.html',
                controller: 'ExpressParcelDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(result) {
                addAllToGuides(result);
            }, function() {
            });
        }

        function searchGuidesPop() {
            var searchParams = {
                isExport: vm.shareMCostDTO.masterGuide.isExport,
                isShareCharges: true,
                searchType: "all",
                companyId: $rootScope.account.companyId
            };
            if($scope.virtualWms){
                searchParams.companyId = $scope.choosedWarehouse.companyId;
            }

            $uibModal.open({
                templateUrl: 'app/entities/guide/guide-search-dialog.html',
                controller: 'GuideSearchDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(result) {
                addAllToGuides(result);
            }, function() {
            });
        }


        $scope.searchGuidePop = function(loadFms, parcelType) {
            if($scope.virtualWms && (!$scope.choosedWarehouse || !$scope.choosedWarehouse.companyId)){
                layer.msg($translate.instant('autopsApp.guide.warehouseNullAlert'));
                return;
            }
            loadFmsBiz = value;
            if(loadFmsBiz){
                searchGuidesPop();                
            }
            else {
                searchParcelsPop(parcelType);
            }
        }
        function addAllToGuides(results){
            if (!results || results.length == 0){
                layer.msg($translate.instant('global.messages.resultNull'));
            }
            else {
                angular.forEach(results, function(data){
                    if(data.otherTimeTwo){
                        layer.msg($translate.instant('autopsApp.guide.sts.wmsOut'));                           
                    }
                    else if(data.isClosed){
                        layer.msg($translate.instant('autopsApp.guide.sts.isClosed'));                           
                    }
                    else if ($scope.virtualWms && data.medCompanyId != $rootScope.account.companyId){

                    }
                    else {
                        addToList(data);
                    }   
                })
            }
        }


        function addToList (guide) {
            var isRepeated = false;
            for(var j = vm.shareMCostDTO.consolShipmentDTOs.length -1; j >=0; j--){
                if (guide.parcelId && guide.parcelId == vm.shareMCostDTO.consolShipmentDTOs[j].parcelId){
                    isRepeated = true;
                    break;
                }
                if (guide.guideId && guide.guideId == vm.shareMCostDTO.consolShipmentDTOs[j].guideId){
                    isRepeated = true;
                    break;
                }
            }
            if (isRepeated){
                layer.msg($translate.instant('global.messages.repeated')); 
            }
            else {
                vm.shareMCostDTO.consolShipmentDTOs.push(guide)
            }
            updateVWtotal();
        }




    }
})();
