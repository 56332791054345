(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WoHeadController', WoHeadController);

    WoHeadController.$inject = ['$scope', '$state', '$rootScope', '$uibModal', '$translate', '$timeout',
    'WoHead', 'WoHeadSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'WinAES', 'GridState', 'uiGridConstants', 'uiGridGroupingConstants'];

    function WoHeadController ($scope, $state, $rootScope, $uibModal, $translate, $timeout,
        WoHead, WoHeadSearch, ParseLinks, AlertService, pagingParams, paginationConstants, WinAES, GridState, uiGridConstants, uiGridGroupingConstants) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.isAdvanceSearch = false;

        vm.viewAll = false;
        vm.exClosed = true;
        vm.getWorkload = getWorkload;

        $scope.setWoType = function(type) {
            $rootScope.woType = type;
        }

        $scope.setWoStatus = function(status) {
            $rootScope.woStatus = status;
            loadAll();
        }

        function getWorkload (){
            WoHead.getWorkload({viewAll: vm.viewAll}, function(result){
                vm.workLoad = result;
            });
        }

        $scope.viewAllChange = function() {
            if ($rootScope.account.authorities.indexOf("ROLE_BOSS") == -1
                && $rootScope.account.companyId != 1){
                vm.viewAll = false;
                $rootScope.FORBIDDEN_BY_MSG("ROLE_BOSS");
                return;
            }
            getWorkload();
            loadAll();
        }

        $scope.exClosedChange = function() {
            loadAll();
        }

        loadAll();
        var gridCopy;
        var pageLoaded = false;
        var filterTimer;
        function loadAll () {
            if (pagingParams.search) {
                WoHeadSearch.query({
                    query: pagingParams.search,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                var filter = {};
                filter.viewAll = vm.viewAll;
                filter.exClosed = vm.exClosed;
                filter.type = $rootScope.woType;
                filter.status = $rootScope.woStatus;
                if(gridCopy){
                    angular.forEach(gridCopy.columns, function(column){
                        angular.forEach(column.filters, function(cf){
                            if(cf.term != null && angular.isDefined(cf.term)){
                                if(cf.condition){
                                    var fd = column.field;
                                    if(fd.endWith("Str")){
                                        fd = fd.replace("Str", "");
                                    }
                                    if(cf.condition == 128 || cf.condition == 256){
                                        filter[fd + "TO"] = cf.term;
                                    }
                                    else if(cf.condition == 32 || cf.condition == 64){
                                        filter[fd + "FM"] = cf.term;
                                    }
                                }
                                else {
                                    filter[column.field] = cf.term;
                                }
                            }
                        })
                    })
                }
                // alert(vm.page);
                WoHead.getFilterPage({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, filter, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                if(headers('X-Total-Count')){
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                }
                else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                initData();
                vm.isAdvanceSearch = false;
                resize();
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        function initData() {
            angular.forEach($scope.gridOptions.data, function(data){
                data.encodeId = WinAES.encode(data.id.toString());
            });
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
        }

        window.onresize = function(){
            resize();
        }

        function resize() {
            if(!$("#gridId") || !$("#gridId").offset()){
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 180;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 600){
                tabHeight = 600;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }
        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = true;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
        var unsubscribe = $rootScope.$on('autopsApp:woHeadUpdate', function(event, result) {
            var isNew = true;
            angular.forEach($scope.gridOptions.data, function(data){
                if(data.id && data.id == result.id){
                    angular.copy(result, data);
                    data.encodeId = WinAES.encode(data.id.toString());
                    isNew = false;
                }
            })
            if(isNew){
                result.encodeId = WinAES.encode(result.id.toString());
                $scope.gridOptions.data.splice(0, 0, result);
            }
        });
        $scope.$on('$destroy', unsubscribe);

        $scope.closeWo = function(woHead) {
            if(woHead.endTime){
                $rootScope.OPS_ALERT("工单已关闭");
                return;
            }
            if($rootScope.account.companyId == 1){
                WoHead.closeWoByCus({
                    id: woHead.id
                }, onUpdateSuccess);
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/wo-body/wo-body-dialog.html',
                controller: 'WoBodyDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return woHead;
                    }]
                }
            }).result.then(onUpdateSuccess);
        }

        var onUpdateSuccess = function(result){
            angular.forEach($scope.gridOptions.data, function(data){
                if(data.id && data.id == result.id){
                    angular.copy(result, data);
                    data.encodeId = WinAES.encode(data.id.toString());
                    $translate.refresh();
                }
            })
            layer.msg($translate.instant('global.messages.submitted'));
        }

        var woTypes = [
            { value: 'bug', label: $translate.instant('autopsApp.woHead.woType.bug')},
            { value: 'advice', label: $translate.instant('autopsApp.woHead.woType.advice')},
            { value: 'customized', label: $translate.instant('autopsApp.woHead.woType.customized')}];
        var woStatus = [
            { value: 'new', label: $translate.instant('autopsApp.woHead.sts.new')},
            { value: 'replied', label: $translate.instant('autopsApp.woHead.sts.replied')},
            { value: 'added', label: $translate.instant('autopsApp.woHead.sts.added')},
            { value: 'closed', label: $translate.instant('autopsApp.woHead.sts.closed')},
            { value: 'newAdded', label: $translate.instant('autopsApp.woHead.sts.newAdded')}];

        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: true,

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: $rootScope.account.id == 5 || $rootScope.account.id == 148,//每列footer

            enableFiltering: true,//高级过滤
            useExternalFiltering: true,

            enableHorizontalScrollbar : 1,//表格的水平滚动条
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)
            // enableRowHeaderSelection : true,  //行选中
            // enableRowSelection: true, //行选中
            // enableSelectAll: true, //全部选中
            // enableFullRowSelection : false, //行任意位置选中
            // multiSelect: true,//多行选中
            exporterMenuCsv : false,
            exporterMenuPdf : false,
            exporterMenuLabel : "Export",
            exporterExcelFilename: $translate.instant('autopsApp.woHead.home.title') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],

            paginationPageSizes: [20, 35, 50, 100, 200],
            paginationPageSize: 20,
            useExternalPagination: true,

            minimumColumnSize: 70,
            columnDefs: [
                { field: 'id',
                    width: 182,
                    displayName: "",
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<div class="btn-group flex-btn-group-container"><button type="button" ng-click="grid.appScope.woHeadPop(row.entity.id)" class="btn btn-info btn-grid"><span class="glyphicon glyphicon-eye-open"></span><span class="hidden-xs hidden-sm" translate="entity.action.view"></span></button><button type="button" ng-click="grid.appScope.closeWo(row.entity)" class="btn btn-primary btn-grid"><span class="glyphicon glyphicon-off"></span><span class="hidden-xs hidden-sm" translate="entity.action.close">close</span></button><button type="button" ui-sref="wo-head.delete({id:row.entity.encodeId})" class="btn btn-danger btn-grid"><span class="glyphicon glyphicon-remove-circle"></span><span class="hidden-xs hidden-sm" translate="entity.action.delete"></span></button></div>'},
                { field: 'woNo',
                    displayName: $translate.instant('autopsApp.woHead.woNo'),
                    minWidth:120,
                    cellTemplate: '<a class="ml-5" href="" ng-click="grid.appScope.woHeadPop(row.entity.id)">{{row.entity.woNo}}</a>'},
                { field: 'woTitle',
                    displayName: $translate.instant('autopsApp.woHead.woTitle'),
                    minWidth:150},
                { field: 'urgentTag',
                    displayName: $translate.instant('autopsApp.woHead.urgentTag'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span ng-class="row.entity.urgentTag?\'label-primary\':\' label-danger\'"translate="{{row.entity.urgentTag?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'type',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: woTypes
                    },
                    displayName: $translate.instant('autopsApp.woHead.type'),
                    minWidth:100,
                    cellTemplate: '<span class="ml-5 label" ng-class="row.entity.type == \'bug\'?\'label-danger\':(row.entity.type == \'advice\'?\'label-info\':\'label-warning\')" translate="autopsApp.woHead.woType.{{row.entity.type}}" ng-if="row.entity.type"></span>'},
                { field: 'status',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: woStatus
                    },
                    displayName: $translate.instant('autopsApp.woHead.status'),
                    minWidth:100,
                    cellTemplate: '<span class="ml-5 label" ng-class="row.entity.status == \'closed\'?\'label-success\':\'label-info\'" translate="autopsApp.woHead.sts.{{row.entity.status}}" ng-if="row.entity.status"></span>'},
                { field: 'auditRequired',
                    displayName: $translate.instant('autopsApp.woHead.auditRequired'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span ng-class="row.entity.auditRequired?\'label-primary\':\' label-danger\'"translate="{{row.entity.auditRequired?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'technicalSupport',
                    displayName: $translate.instant('autopsApp.woHead.technicalSupport'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span ng-class="row.entity.technicalSupport?\'label-primary\':\' label-danger\'"translate="{{row.entity.technicalSupport?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'ldSubmitter',
                    displayName: $translate.instant('autopsApp.woHead.ldSubmitter'),
                    minWidth:80},
                { field: 'submitter',
                    displayName: $translate.instant('autopsApp.woHead.submitter'),
                    minWidth:150},
                { field: 'submitterRating',
                    displayName: $translate.instant('autopsApp.woHead.submitterRating'),
                    minWidth:200,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    type:'number',
                    cellTemplate: '<div class="grid-text"><uib-rating ng-model="row.entity.submitterRating" max="10" state-on="\'glyphicon glyphicon-star\'" state-off="\'glyphicon glyphicon-star-empty\'" read-only="row.entity.endTime" ng-change="grid.appScope.setSubmitterRating(row.entity.id, row.entity.submitterRating)"></uib-rating></div>'},
                { field: 'company',
                    displayName: $translate.instant('autopsApp.woHead.company'),
                    minWidth:150},
                { field: 'cus',
                    displayName: $translate.instant('autopsApp.woHead.cus'),
                    minWidth:150},
                { field: 'rdName',
                    displayName: $translate.instant('autopsApp.woHead.rdName'),
                    minWidth:150},
                { field: 'completionDate',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName:  "工单完成",
                    minWidth:120,
                    cellTemplate: '<span class="grid-text">{{row.entity.completionDate | date:\'mediumDate\'}}</span>'},
                { field: 'workingHours',
                    displayName: "工时",
                    type:'number',
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    minWidth:150},
                { field: 'knowledgeBase',
                    displayName: $translate.instant('autopsApp.woHead.knowledgeBase'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span ng-class="row.entity.knowledgeBase?\'label-primary\':\' label-danger\'"translate="{{row.entity.knowledgeBase?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'submitTime',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:200,
                    displayName: $translate.instant('autopsApp.woHead.submitTime'),
                    cellTemplate: '<span class="grid-text">{{row.entity.submitTime | date:\'medium\'}}</span>'},
                { field: 'endTime',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:200,
                    displayName: $translate.instant('autopsApp.woHead.endTime'),
                    cellTemplate: '<span class="grid-text">{{row.entity.endTime | date:\'medium\'}}</span>'},
                { field: 'updateTime',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:200,
                    displayName: $translate.instant('autopsApp.woHead.updateTime'),
                    cellTemplate: '<span class="grid-text">{{row.entity.updateTime | date:\'medium\'}}</span>'},
                { field: 'serviceRating',
                    displayName: $translate.instant('autopsApp.woHead.serviceRating'),
                    minWidth:200,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    type:'number',
                    cellTemplate: '<div class="grid-text"><uib-rating ng-model="row.entity.serviceRating" max="10" state-on="\'glyphicon glyphicon-star\'" state-off="\'glyphicon glyphicon-star-empty\'" read-only="true"></uib-rating></div>'}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 111;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                    if(!$scope.gridOptions.useExternalSorting){
                        if(sortColumns[sortColumns.length - 1]){
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function(a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    if(sortColumns[sortColumns.length - 1]){
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.reverse = 'asc' == sortColumns[sortColumns.length - 1].sort.direction;
                    }
                    if(pageLoaded){
                        loadAll();
                    }

                });
                gridApi.core.on.filterChanged($scope, function() {
                    gridCopy = this.grid;
                    if(pageLoaded && $scope.gridOptions.useExternalFiltering){
                        clearTimeout(filterTimer);
                        filterTimer = setTimeout(function(){
                            loadAll();
                        }, 1000);//查询延时时间，单位ms
                    }
                });
                gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                    vm.page = newPage;
                    vm.itemsPerPage = pageSize;
                    if(pageLoaded){
                        loadAll();
                    }
                });
            }
        }

        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            getWorkload();
            for(var i = $scope.gridOptions.columnDefs.length - 1; i >= 0; i--){
                var col = $scope.gridOptions.columnDefs[i];
                if($rootScope.account.companyId != 1){
                    if(col == 'company' || col == 'knowledgeBase' || col.field == "cus"
                        || col.field == "ldSubmitter" || col.field == "auditRequired"
                        || col.field == "technicalSupport" || col.field == "rdName" 
                        || col.field == "completionDate"){
                        $scope.gridOptions.columnDefs.splice(i, 1);
                    }
                }
                else if($rootScope.account.id != 5 && $rootScope.account.id != 148 && $rootScope.account.id != 19943){
                    if(col.field == "workingHours"){
                        $scope.gridOptions.columnDefs.splice(i, 1);
                    }
                }
            }

            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 111
            }, function(result){
                gridStateObj = result;
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    pageLoaded = true;
                    return;
                }
                if(gridStateObj.gridState){
                    $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                }
                pageLoaded = true;
                if(gridCopy){
                    var filterRequired = false;
                    angular.forEach(gridCopy.columns, function(column){
                        angular.forEach(column.filters, function(cf){
                            if(cf.term || cf.term == false){
                                filterRequired = true;
                            }
                        })
                    })
                    if(filterRequired){
                        layer.msg($translate.instant('global.filterDetectedAlert'));
                        loadAll();
                    }
                }
            })
        });

        $scope.woHeadPop= function(id) {
            $uibModal.open({
                templateUrl: 'app/entities/wo-head/wo-head-detail.html',
                controller: 'WoHeadDetailController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'xxxl',
                resolve: {
                    entity: ['WoHead', function(WoHead) {
                        return WoHead.get({id : id}).$promise;
                    }]
                }
            }).result.then(function() {
                loadAll();
            }, function() {
                loadAll();
            });
        }
        $scope.setSubmitterRating= function(id, submitterRating) {
            if(!id){
                return;
            }
            WoHead.setSubmitterRating({
                id: id,
                submitterRating: submitterRating
            }, function(){
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(){
                layer.msg($translate.instant('global.messages.submittedfailed'));
            });
        }

    }
})();
