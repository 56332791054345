(function () {
    'use strict';

    angular
        .module('autopsApp')
        .controller('JsExpressionGuiDialogController', JsExpressionGuiDialogController);

    JsExpressionGuiDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', '$translate', '$uibModal',
        'entity'];

    function JsExpressionGuiDialogController($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, $translate, $uibModal,
        entity) {
        var vm = this;

        vm.clear = clear;
        vm.generate = generate;
        vm.addStep = addStep;
        vm.deleteItem = deleteItem;
        vm.logicSymbolChange = logicSymbolChange;
        vm.addBranchs = addBranchs;
        vm.changeType = changeType;
        vm.addOperation = addOperation;
        vm.deleteOperation = deleteOperation;
        vm.formulaOptionChange = formulaOptionChange;
        vm.changedFormulaValue = changedFormulaValue;
        vm.branchConditionVariablChange = branchConditionVariablChange;

        vm.alertFd = "variablesTips";

        $timeout(function () {
            if (entity.jsJson) {
                vm.jsJson = JSON.parse(entity.jsJson);
            }
            if (!vm.jsJson) {
                vm.jsJson = [];
                vm.jsJson.push({ type: "operation", operationResult: "", formulas: [{value: "" , valueName: "", option: ""}]});                
                // var jsJsonFirst = {};
                // jsJsonFirst.type = "branch";
                // jsJsonFirst.brachs = [{ branchConditionVariableName: "", operator: "", logicSymbol: "" }];
                // jsJsonFirst.operations = [{operationResult: "", formulas: [{value: "", valueName: "", option: ""}]}];
                // vm.jsJson.push(jsJsonFirst);
            }
            // 所有变量
            vm.allVariables = [
                { name: "结果(result)", value: "result" },
                { name: "长度CM(length)", value: "length" },
                { name: "宽度/圆柱直径CM(width)", value: "width" },
                { name: "高度CM(height)", value: "height" },
                { name: "重量KG(gw)", value: "gw" },
                { name: "计费重(cw)", value: "cw" },
                { name: "货值USD(cargoValue)", value: "cargoValue" },
                { name: "公布价(carriage)", value: "carriage" },
                { name: "收件人邮编(postCode)", value: "postCode" },
                { name: "件数PKG(pkg)", value: "pkg" },
                { name: "托盘数量(pallets)", value: "pallets" },
                { name: "计费数量(amt)", value: "amt" },
                { name: "库存天数(ttlDays)", value: "ttlDays" },
                { name: "计费天数(days)", value: "days" },
                { name: "仓库代码(whCode)", value: "whCode" },
                { name: "体积CBM(vol)", value: "vol" },
                { name: "库区代码(wmsArea)", value: "wmsArea" },
                { name: "柜型(ctnType)", value: "ctnType" },
                { name: "库存类型(jobType)", value: "jobType" },
                { name: "出库类型(outType)", value: "outType" },
                { name: "收件人国家代码(countryCode)", value: "countryCode" },
                { name: "收件人分区(destZone)", value: "destZone" },
                { name: "特殊包装(irregularPacking)", value: "irregularPacking" },
                { name: "住宅派送(isResidential)", value: "isResidential" },
                { name: "偏远(isRemote)", value: "isRemote" },
                { name: "超偏远(isSuperRemote)", value: "isSuperRemote" },
                { name: "远程邮编(isRemotePostalCode)", value: "isRemotePostalCode" },
                { name: "签收服务(signature)", value: "signature" },
                { name: "成人签收服务(adultSignature)", value: "adultSignature" },
                { name: "地址修正(addressModify)", value: "addressModify" },
                { name: "需要保险(insuranceRequired)", value: "insuranceRequired" },
                { name: "业务类型字段 (bizType)", value: "bizType" }
            ];
            // 布尔类型的变量
            vm.booleanVariables = ["irregularPacking", "isResidential", "isRemote", "isSuperRemote", "isRemotePostalCode", "signature", "adultSignature", "addressModify", "insuranceRequired"];
            // 数值类型的变量
            vm.numberVariables = ["result", "length", "width", "height", "gw", "cw", "value", "carriage", "cargoValue", "pkg", "amt", "ttlDays", "days", "vol", "pallets"];
            // 不能比较大小的变量。条件判断时，不能用于判断大于、小于
            vm.stringVariables = ["whCode", "wmsArea", "ctnType", "jobType", "outType", "countryCode", "destZone", "bizType"];
            // 不同类型的业务拥有不同变量
            var hasVariables = [];
            if(entity.jsType == 'freightJs'){
                hasVariables = ["result", "cw"];
            }else if (entity.jsType == 'weightJs') {
                hasVariables = ["result", "gw", "vol", "length", "width", "height"];
            }else if (entity.jsType == 'dutyJs') {
                hasVariables = ["result", "value"];
            }else if (entity.jsType == 'customsJs') {
                hasVariables = ["result", "gw"];
            }else if (entity.jsType == 'dgJs') {
                hasVariables = ["result", "pkg"];
            }else if (entity.jsType == 'maxSize') {
                hasVariables = ["result", "length", "width", "height"];
            }else if (entity.jsType == 'wmsChargeJs') {
                hasVariables = ["result", "amt", "days", "ttlDays", "whCode", "pkg", "pallets", "gw", "vol", "length", "width", "height", "wmsArea", "ctnType", "jobType", "outType", "bizType"];
            }else if (entity.jsType == 'surchargeJs') {
                hasVariables = ["result", "length", "carriage", "width", "height", "pkg", "gw", "vol", "cw", "cargoValue", "postCode", "countryCode", "destZone", "bizType"].concat(vm.booleanVariables);
            } 

            // 存放分支条件变量，包含了不可赋值的布尔变量
            vm.brachVariables = [];
            // 存放可赋值的变量
            vm.variables = [];
            angular.forEach(vm.allVariables, function (item) {
                if (item && hasVariables.indexOf(item.value) != -1) {
                    vm.brachVariables.push(item);
                    if (vm.numberVariables.indexOf(item.value) != -1) {
                        vm.variables.push(item);
                    }
                }
            })
        });
        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        // 可视化操作后，生成类似如下的Json字符，从中解析为Js表达式：
        // [
        //     {
        //         "type": "branch",
        //         "brachs": [
        //             {
        //                 "branchConditionVariableName": "length",
        //                 "operator": "greater",
        //                 "logicSymbol": "",
        //                 "branchConditionVariableValue": "4"
        //             }
        //         ],
        //         "operations": [
        //             {
        //                 "operationResult": "width",
        //                 "formulas": [
        //                     {
        //                         "value": "width",
        //                         "valueName": "宽度/圆柱直径CM(width)",
        //                         "option": "plus"
        //                     },
        //                     {
        //                         "value": "",
        //                         "valueName": "2",
        //                         "option": ""
        //                     }
        //                 ]
        //             },
        //             {
        //                 "operationResult": "result",
        //                 "formulas": [
        //                     {
        //                         "value": "gw",
        //                         "valueName": "重量KG(gw)",
        //                         "option": "multiply"
        //                     },
        //                     {
        //                         "value": "",
        //                         "valueName": "33",
        //                         "option": ""
        //                     }
        //                 ]
        //             }
        //         ]
        //     },
        //     {
        //         "type": "operation",
        //         "operationResult": "result",
        //         "formulas": [
        //             {
        //                 "value": "length",
        //                 "valueName": "长度CM(length)",
        //                 "option": "multiply"
        //             },
        //             {
        //                 "value": "",
        //                 "valueName": "23",
        //                 "option": ""
        //             }
        //         ]
        //     }
        // ]
        function generate(jsJson) {
            var branchStr = "var result = 0;";
            angular.forEach(jsJson, function (item) {
                if (item.type == 'branch') {
                    branchStr += "if(";
                    angular.forEach(item.brachs, function (brach) {
                        if (brach.operator == 'isNull' || brach.operator == 'isFalse') {
                            branchStr += '!' + brach.branchConditionVariableName;
                        }
                        if (brach.operator == 'notNull' || brach.operator == 'isTrue') {
                            branchStr += brach.branchConditionVariableName;
                        }
                        if (brach.operator == 'equal') {
                            if (isNaN(brach.branchConditionVariableValue)) {
                                branchStr += brach.branchConditionVariableName + "==\"" + brach.branchConditionVariableValue + "\"";
                            } else {
                                branchStr += brach.branchConditionVariableName + "==" + brach.branchConditionVariableValue;
                            }
                        }
                        if (brach.operator == 'greater') {
                            if (isNaN(brach.branchConditionVariableValue)) {
                                branchStr += brach.branchConditionVariableName + ">\"" + brach.branchConditionVariableValue + "\"";
                            } else {
                                branchStr += brach.branchConditionVariableName + ">" + brach.branchConditionVariableValue;
                            }
                        }
                        if (brach.operator == 'greaterEqual') {
                            if (isNaN(brach.branchConditionVariableValue)) {
                                branchStr += brach.branchConditionVariableName + ">=\"" + brach.branchConditionVariableValue + "\"";
                            } else {
                                branchStr += brach.branchConditionVariableName + ">=" + brach.branchConditionVariableValue;
                            }
                        }
                        if (brach.operator == 'less') {
                            if (isNaN(brach.branchConditionVariableValue)) {
                                branchStr += brach.branchConditionVariableName + "<\"" + brach.branchConditionVariableValue + "\"";
                            } else {
                                branchStr += brach.branchConditionVariableName + "<" + brach.branchConditionVariableValue;
                            }
                        }
                        if (brach.operator == 'lessEqual') {
                            if (isNaN(brach.branchConditionVariableValue)) {
                                branchStr += brach.branchConditionVariableName + "<=\"" + brach.branchConditionVariableValue + "\"";
                            } else {
                                branchStr += brach.branchConditionVariableName + "<=" + brach.branchConditionVariableValue;
                            }
                        }
                        if (brach.logicSymbol == "and") {
                            branchStr += "&&";
                        } else if (brach.logicSymbol == "or") {
                            branchStr += "||";
                        }
                    })
                    branchStr += "){";
                    angular.forEach(item.operations, function(operation){
                        branchStr += operation.operationResult + '=';
                        angular.forEach(operation.formulas, function(formula){
                            branchStr += (formula.value ? formula.value : formula.valueName) + getOptionSymbol(formula.option);
                        })
                        branchStr += ";";
                    })
                    branchStr += "};";
                } else if (item.type == 'operation') {
                    branchStr += item.operationResult + '=';
                    angular.forEach(item.formulas, function(formula){
                        branchStr += (formula.value ? formula.value : formula.valueName) + getOptionSymbol(formula.option);
                    })
                    branchStr += ";";
                }
            })

            var result = {};
            result.jsGuiJson = JSON.stringify(jsJson);

            branchStr += "result = result;";
            result.jsStr = js_beautify(branchStr);

            $uibModalInstance.close(result);
        }

        function addStep(index) {
            vm.jsJson.splice(index + 1, 0, { type: "operation", operationResult: "", formulas: [{value: "" , valueName: "", option: ""}]});
        }

        function deleteItem(index) {
            if(index == 0){
                return;
            }
            vm.jsJson.splice(index, 1);
        }

        function logicSymbolChange(jsJsonItem) {
            angular.forEach(jsJsonItem.brachs, function (item, index) {
                if (!item.logicSymbol && jsJsonItem.brachs.length - 1 > index) {
                    jsJsonItem.brachs.splice(index + 1, jsJsonItem.brachs.length - index + 1);
                } else if ((item.logicSymbol == 'and' || item.logicSymbol == 'or') && index == jsJsonItem.brachs.length - 1) {
                    jsJsonItem.brachs.push({ branchConditionVariableName: "", operator: "", logicSymbol: "" });
                }
            })
        }

        function addBranchs(jsJsonItem) {
            jsJsonItem.brachs.push({ branchConditionVariableName: "", operator: "", logicSymbol: "" });
        }
        function changeType(jsJsonItem) {
            if (jsJsonItem.type == 'branch') {
                delete jsJsonItem.operationResult;
                delete jsJsonItem.formulas;
                jsJsonItem.brachs = [];
                jsJsonItem.brachs.push({ branchConditionVariableName: "", operator: "", logicSymbol: "" });
                jsJsonItem.operations = [];
                jsJsonItem.operations.push({operationResult: "", formulas: [{value: "" , valueName: "", option: ""}]})
            }else if(jsJsonItem.type == 'operation'){
                delete jsJsonItem.brachs;
                delete jsJsonItem.operations;
                jsJsonItem.operationResult = "";
                jsJsonItem.formulas = [];
                jsJsonItem.formulas.push({value: "" , valueName: "", option: ""});
            }
        }

        function addOperation(operations, index) {
            operations.splice(index + 1, 0, {operationResult: "", formulas: [{value: "" , valueName: "", option: ""}]});
        }

        function deleteOperation(operations, index) {
            operations.splice(index, 1);
        }

        $scope.selectedFormulaValue = function($item, formula) {
            formula.value = $item.value;
            formula.valueName = $item.name;
        }

        function formulaOptionChange(operation) {
            angular.forEach(operation.formulas, function (item, index) {
                if (!item.option && operation.formulas.length - 1 > index) {
                    operation.formulas.splice(index + 1, operation.formulas.length - index + 1);
                } else if (item.option && index == operation.formulas.length - 1) {
                    operation.formulas.push({value: "" , valueName: "", option: ""});
                }
            })
        }

        function getOptionSymbol(option){
            if(!option){
                return '';
            }
            if(option == 'plus'){
                return "+";
            }
            if(option == 'minus'){
                return "-";
            }
            if(option == 'multiply'){
                return "*";
            }
            if(option == 'divide'){
                return "/";
            }
        }

        function changedFormulaValue(formula){
            formula.value = '';
        }

        function branchConditionVariablChange(brach){
            delete brach.operator;
            delete brach.branchConditionVariableValue;
        }
    }
})();
