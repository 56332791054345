(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('AutoCheckItemSearch', AutoCheckItemSearch);

    AutoCheckItemSearch.$inject = ['$resource'];

    function AutoCheckItemSearch($resource) {
        var resourceUrl =  'api/_search/auto-check-items/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
