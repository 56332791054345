(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AdvanceMgmtController', AdvanceMgmtController);

    AdvanceMgmtController.$inject = ['$scope', '$rootScope', '$translate', '$uibModal', 
    'JhiLanguageService', 'Principal', 'AccountRef', 'DateUtils', 'AccountVoucher', 
    'AccountTitle', 'VoucherContent', 'OpsFiles', 'DataUtils'];

    function AdvanceMgmtController($scope, $rootScope, $translate, $uibModal, 
        JhiLanguageService, Principal, AccountRef, DateUtils, AccountVoucher, 
        AccountTitle, VoucherContent, OpsFiles, DataUtils) {
        var ledgers = [];
        $scope.isForeign = false;
        $scope.isPay = false;
        $scope.format = "pdf";
        $scope.reportavailalbe = false;
        $scope.fileName = "";
        var params = [];

        var lang = "";
        JhiLanguageService.getCurrent().then(function(language) {
            lang = language;
        });
        
        var getLedgers = function(data) {
            AccountTitle.getAllByPrefix({
                code: "2205"
            }, function(result) {
                ledgers = result;
                AccountTitle.getAllByPrefix({
                    code: "1123"
                }, function(result) {
                    angular.forEach(result, function(data){
                        ledgers.push(data);
                    })
                    $scope.ledgers = DataUtils.generateShowLedger(ledgers, true, lang);
                })                
            });
        }

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account; 
                getLedgers();
            });
        } else {
            getLedgers();
        }

        $scope._onLedgerChoosed = function(data) {
            $scope.filterDTO.ledgerId = data.id;
            $scope.filterDTO.ledger = data.showLedger;
            $scope.filterDTO.ledgerCode = data.code;
            $scope.reportavailalbe = false;
            if (data.isForeign && data.isLast) {
                $scope.isForeign = true;
                $scope.currencyName = data.currency;
            } else {
                $scope.isForeign = false;
                $scope.currencyName = null;
            }

            if ($scope.filterDTO.ledgerCode.substring(0,4) == "2205"){
                $scope.isPay = false;
            }
            else{
                $scope.isPay = true;                
            }

            $scope.voucherContents = [];
            initCContent();
            $translate.refresh();
        }


        $scope.datePickerForEtd = {};

        $scope.datePickerForEtd.status = {
            opened: false
        };

        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };

        $scope.datePickerForCargoReadyDate = {};

        $scope.datePickerForCargoReadyDate.status = {
            opened: false
        };

        $scope.datePickerForCargoReadyDateOpen = function($event) {
            $scope.datePickerForCargoReadyDate.status.opened = true;
        };


        $scope.load = function(id) {
            AccountTitle.get({
                id: id
            }, function(result) {
                $scope.accountTitle = result;
            });
        };

        $scope.filterDTO = {};
        $scope.isSaving = false;

        $scope.voucherContents = [];
        $scope.contentYear = null;
        var refrestCDC = function() {
            var date1, dateArray;
            if ($scope.voucherContents) {
                for (var i = 0; i < $scope.voucherContents.length; i++) {
                    if ($scope.voucherContents[i].makeDate) {
                        date1 = DateUtils.convertLocalDateToServer($scope.voucherContents[i].makeDate);
                        dateArray = date1.split("-");
                        $scope.voucherContents[i].month = dateArray[1];
                        $scope.voucherContents[i].day = dateArray[2];
                    }
                    if ($scope.voucherContents[i].dn || $scope.voucherContents[i].dn == 0) {
                        $scope.voucherContents[i].dn = DataUtils.formatTwoDigits($scope.voucherContents[i].dn);
                    }
                    if ($scope.voucherContents[i].cn || $scope.voucherContents[i].cn == 0) {
                        $scope.voucherContents[i].cn = DataUtils.formatTwoDigits($scope.voucherContents[i].cn);
                    }
                    if ($scope.voucherContents[i].balance || $scope.voucherContents[i].balance == 0) {
                        $scope.voucherContents[i].balance = DataUtils.formatTwoDigits($scope.voucherContents[i].balance);
                    }
                    if ($scope.voucherContents[i].currencyBalance || $scope.voucherContents[i].currencyBalance == 0) {
                        $scope.voucherContents[i].currencyBalance = DataUtils.formatTwoDigits($scope.voucherContents[i].currencyBalance);
                        $scope.voucherContents[i].exchangeRate = DataUtils.formatTwoDigits($scope.voucherContents[i].exchangeRate);
                        $scope.voucherContents[i].currencyCount = DataUtils.formatTwoDigits($scope.voucherContents[i].currencyCount);
                    }
                }
            }
        }
        var initCContent = function() {
            if ($scope.voucherContents) {
                for (var j = $scope.voucherContents.length; j <= 20; j++) {
                    $scope.voucherContents.push({});
                }

            } else {
                $scope.voucherContents = [];
                for (var j = 0; j <= 20; j++) {
                    $scope.voucherContents.push({});
                }
            }
            refrestCDC();
        }

        initCContent();
        var contentsCopy = [];
        $scope.getContents = function() {
            if (!$scope.filterDTO.ledgerId || !$scope.filterDTO.dateFrom || !$scope.filterDTO.dateTo) {
                layer.msg($translate.instant('autopsApp.accountTitle.nullFilterAlert'));
                return;
            }
            if (DateUtils.compareDate($scope.filterDTO.dateFrom, $scope.filterDTO.dateTo) > 0) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                return;            
            }
            if (DateUtils.getDaysDiff($scope.filterDTO.dateFrom, $scope.filterDTO.dateTo) > 365) {
                layer.msg($translate.instant('global.messages.etdPeriodAlert'));
                return;            
            }
            $scope.filterDTO.isAudited = true;
            $scope.filterDTO.companyId = $rootScope.account.companyId;
            $scope.isSaving = true;
            $scope.voucherContents = [];
            VoucherContent.getAllByFiler($scope.filterDTO, function(result) {
                $scope.isSaving = false;
                $scope.contentYear = $scope.filterDTO.dateTo.getFullYear();    
                $scope.voucherContents = result[0].voucherContents;                
                for (var i = $scope.voucherContents.length - 1; i >= 0; i--) {
                    if (!$scope.voucherContents[i].id){
                        $scope.voucherContents.splice(i, 1);
                    }
                    else if (!$scope.isPay && null == $scope.voucherContents[i].cn){
                        $scope.voucherContents.splice(i, 1);
                    }
                    else if ($scope.isPay && null == $scope.voucherContents[i].dn){
                        $scope.voucherContents.splice(i, 1);
                    }           
                }
                contentsCopy = angular.copy($scope.voucherContents);
                $scope.showAllChange();         
                if (!$scope.voucherContents || $scope.voucherContents.length == 0) {
                    layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                    return;
                }
                $scope.reportavailalbe = true;
                initCContent();
            }, function(response) {
                if (response.status == 406){
                    layer.msg($translate.instant('autopsApp.accountTitle.lastYearNotAccountedAlert'));
                }   
                $scope.isSaving = false;
                initCContent();
            });
        }

        $scope.exportAccountTitle = function(format) {
            $scope.format = format;
            $scope.fileName = '';
            params = [];
            for (var i = 0; i < $scope.voucherContents.length; i++) {
                if ($scope.voucherContents[i].b || $scope.voucherContents[i].d || $scope.voucherContents[i].c) {
                    $scope.param = angular.copy($scope.voucherContents[i]);
                    if ($scope.param.isDn) {
                        $scope.param.dnFlag = true;
                    } else {
                        $scope.param.dnFlag = false;
                    }
                    params.push($scope.param);
                }
            }

            $scope.fileName_btn = layer.open({
                type: 1,
                area: ['300px', '180px'],
                btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')],
                title: $translate.instant('global.fileName'),
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: $("#templateReportName"),
                success: function(layero, index) {
                    $("#fileName").focus();
                },
                yes: function(index, layero) {
                    if ($scope.fileName == '') {
                        $scope.fileName = 'AccountTitle';
                    }
                    $scope.generateAccountTitle();
                    layer.close($scope.fileName_btn);
                },
                no: function(index, layero) {
                    layer.close(index);
                }
            });
        }

        $("#fileName").keydown(function(event) {
            $scope.keycode = window.event ? event.keyCode : event.which;
            if ($scope.keycode == 13) {
                if ($scope.fileName == '') {
                    $scope.fileName = 'AccountTitle';
                }
                $scope.generateAccountTitle();
                layer.close($scope.fileName_btn);
            }
        })

        $scope.generateAccountTitle = function() {
            if ($scope.isForeign) {
                if (null == $scope.currencyName) {
                    $scope.currencyName = "";
                }
                OpsFiles.previewAccountTitleForexReport({
                    contentYear: $scope.contentYear,
                    ledgerName: $scope.filterDTO.ledger,
                    fileType: $scope.format,
                    currency: $scope.currencyName
                }, params, function(result) {
                    $scope.exportExcelOrPdf(result);
                }, function(error) {
                    layer.msg($translate.instant('autopsApp.accountTitle.viewAccountTitleFail'));
                });
            } else {
                OpsFiles.previewAccountTitleReport({
                    contentYear: $scope.contentYear,
                    ledgerName: $scope.filterDTO.ledger,
                    fileType: $scope.format
                }, params, function(result) {
                    $scope.exportExcelOrPdf(result);
                }, function(error) {
                    layer.msg($translate.instant('autopsApp.accountTitle.viewAccountTitleFail'));
                });
            }


        }

        $scope.exportExcelOrPdf = function(result) {
            if (angular.isDefined($scope.format) && $scope.format == 'excel') {
                var file = new Blob([result.data], {
                    type: 'application/vnd.ms-excel'
                });
                var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
                var aTag = document.createElement("a");
                aTag.download = $scope.fileName + ".xlsx";
                aTag.href = blobURL;
                aTag.click();
            } else {
                var file = new Blob([result.data], {
                    type: 'application/pdf'
                });
                var fileURL = URL.createObjectURL(file);
                var previewDialog = layer.open({
                    type: 2,
                    area: ['1200px', '800px'],
                    maxmin: true,
                    title: $translate.instant("autopsApp.accountTitle.viewAccountTitle"),
                    shade: 0.3,
                    moveType: 0,
                    shift: 0,
                    content: fileURL
                })
            }
        }
        $scope.showAll = true;
        $scope.showAllChange = function(result) {
            $scope.voucherContents = angular.copy(contentsCopy);
            for (var i = $scope.voucherContents.length - 1; i >= 0; i--) {
                if (!$scope.showAll && $scope.voucherContents[i].isWritedOff){
                    $scope.voucherContents.splice(i, 1);
                }
            }
            initCContent();
        }
        $scope.getVoucher = function(id) {
            if (null == id) {
                return;
            }
            VoucherContent.getVoucher({
                id: id
            }, function(result) {
                $rootScope.VIEWCONTENTVOUCHER(id, $scope.voucherContents, result);
            });
        };
        $scope.setIsWritedOff = function(content) {
            if (!content.id) {
                return;
            }
            VoucherContent.setIsWritedOff({}, content, function(result) {
                content.isWritedOff = result.isWritedOff;
                layer.msg($translate.instant('global.messages.submitted'));
            });
        };





    }
})();