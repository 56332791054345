(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('CashFlowSubjectSearch', CashFlowSubjectSearch);

    CashFlowSubjectSearch.$inject = ['$resource'];

    function CashFlowSubjectSearch($resource) {
        var resourceUrl =  'api/_search/cash-flow-subjects/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
