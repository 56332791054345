(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('DataSubscriptionController', DataSubscriptionController);

    DataSubscriptionController.$inject = ['$scope', '$state', '$rootScope', '$uibModal', '$translate',
        'DataSubscription', 'DataSubscriptionSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'Guide'];

    function DataSubscriptionController ($scope, $state, $rootScope, $uibModal, $translate,
        DataSubscription, DataSubscriptionSearch, ParseLinks, AlertService, paginationConstants, pagingParams, Guide) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.searchType = "self";
        vm.viewDataSubscription = viewDataSubscription;
        vm.editDataSubscription = editDataSubscription;
        vm.deleteDataSubscription = deleteDataSubscription;
        vm.createDataSubscription = createDataSubscription;
        vm.changeHold = changeHold;
        loadAll();
        function loadAll () {
            if (pagingParams.search) {
                DataSubscriptionSearch.query({
                    query: pagingParams.search,
                    type: vm.searchType,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                DataSubscription.query({
                    type: vm.searchType,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.dataSubscriptions = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        $scope.viewAllChange = function() {
            if ($rootScope.HAVENOAUTH('AU_VIEW_ALL_DS')) {
                vm.viewAll = !vm.viewAll;
                $rootScope.FORBIDDEN_BY_ALERT('AU_VIEW_ALL_DS');
                return;
            }
            if(vm.viewAll){
                vm.searchType = "all";
            }
            else {
                vm.searchType = "self";
            }
            loadAll();
        }

        function viewDataSubscription(dataSubscription) {
            if(!dataSubscription.guideId){
                return;
            }
            $rootScope.SEA_TRACKING_POP(dataSubscription.guideId);
        }

        function editDataSubscription(dataSubscription) {
            if(!dataSubscription.guideId){
                return;
            }
            layer.load(1, { shade: 0.3 });
            Guide.get({
                id: dataSubscription.guideId
            }, function(result) {
                $rootScope.AUTO_TRACKING(result);
                layer.closeAll();
            });
        }

        function deleteDataSubscription(dataSubscription) {
            $uibModal.open({
                templateUrl: 'app/entities/data-subscription/data-subscription-delete-dialog.html',
                controller: 'DataSubscriptionDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: ['DataSubscription', function(DataSubscription) {
                        return DataSubscription.get({id : dataSubscription.id}).$promise;
                    }]
                }
            }).result.then(function() {
                loadAll();
            }, function() {
            });
        }

        function createDataSubscription() {
            $rootScope.OPS_ALERT($translate.instant('autopsApp.dataSubscription.createAlert'));
        }
        function changeHold(dataSubscription) {
            if(!dataSubscription.id){
                return;
            }
            DataSubscription.changeHold({
                id: dataSubscription.id
            }, function(result) {
                dataSubscription.hold = result.hold;
            });
        }



    }
})();
