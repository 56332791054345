(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('OpsTemplateSearch', OpsTemplateSearch);

    OpsTemplateSearch.$inject = ['$resource'];

    function OpsTemplateSearch($resource) {
        return $resource('api/_search/opsTemplates/:query', {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
