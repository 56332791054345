(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountCashierDeleteController',AccountCashierDeleteController);

    AccountCashierDeleteController.$inject = ['$uibModalInstance', '$translate', 'entity', 'AccountCashier'];

    function AccountCashierDeleteController($uibModalInstance, $translate, entity, AccountCashier) {
        var vm = this;

        vm.accountCashier = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AccountCashier.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                }, function(error){
                    if(error.status == 409){
                        layer.msg($translate.instant('autopsApp.accountCashier.afterVoucherIdNotNull'));
                    }
                    else if (error.status == 403){
                        layer.msg($translate.instant('autopsApp.accountCashier.voucherIdNotNull'));
                    }
                    else {
                        layer.msg($translate.instant('global.delete_fail'));
                    }
                });
        }
    }
})();
