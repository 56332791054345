(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountantController', AccountantController);

    AccountantController.$inject = ['$rootScope', '$scope', '$state', '$translate', '$window', '$uibModal',
    'WinAES', 'DataUtils', 'Principal', 'OpsVender', 'OpsItem', 'Guide', 'GuideRates', 'GuideSearch', 'OpsFiles',
    'ParseLinks', 'GuideDoc'];

    function AccountantController($rootScope, $scope, $state, $translate, $window, $uibModal,
        WinAES, DataUtils, Principal, OpsVender, OpsItem, Guide, GuideRates, GuideSearch, OpsFiles,
        ParseLinks, GuideDoc) {

        $scope.filterType = "hblNotRls";
        $scope.getWorkload = function  (){
            Guide.getBillsWorkload(function(result){
                $rootScope.WORKLOADBILLS = result;
                $translate.refresh();
            });
        }
        $scope.getWorkload();
        $scope.jobType = null;
        $scope.setJobType = function(type) {
            $scope.jobType = type;
        }
        $scope.guides = [];
        $scope.predicate = 'id';
        $scope.reverse = false;
        $scope.page = 1;
        $scope.currentSearch = false;
        $scope.isAdvancedSearch = false;
        $scope.itemsPerPage = 20;

        $scope.loadAll = function() {
            $scope.currentSearch = false;
            Guide.getBills({
                type: $scope.filterType,
                page: $scope.page - 1,
                size: 20,
                sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']
            }, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.guides = result;
                angular.forEach($scope.guides, function(data) {
                    data.encodeId = WinAES.encode(data.id.toString());
                    DataUtils.fillFdLabel(data);
                })
                setTimeout(function() {frzTable("divTable", "showTable", "footPager");}, 100);
            });
        };

        $scope.searchGuidePop = function() {
            var searchParams = {
                // isApplied: true,
                shipmentType: 'fba',
                companyId: $rootScope.account.companyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/guide/guide-search-dialog.html',
                controller: 'GuideSearchDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(results) {
                $scope.currentSearch = true;
                $scope.isAdvancedSearch = true;
                $scope.guides = results;
                angular.forEach($scope.guides, function(data){
                    data.encodeId = WinAES.encode(data.id.toString())
                })
                setTimeout(function() {frzTable("divTable", "showTable", "footPager");}, 100);
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        }
        $scope.reset = function() {
            $scope.currentSearch = false;
            $scope.isAdvancedSearch = false;
            $scope.page = 0;
            $scope.guides = [];
            $scope.loadAll();
        };

        $scope.filterTypeSet = function(type) {
            $scope.filterType = type;
            $scope.guides = [];
            $scope.predicate = 'id';
            $scope.reverse = false;
            $scope.page = 1;
            $scope.currentSearch = false;
            $scope.loadAll();
        }
        $scope.loadAll();
        $scope.search = function() {
            $scope.currentSearch = true;
            if($scope.jobType){
                $scope.isAdvancedSearch = true;
                Guide.searchByNums({
                    type: $scope.jobType,
                    shipmentType: "all",
                    isExport: true,
                    companyId: $rootScope.account.companyId
                }, $scope.searchQuery, onSuccess, onSaveError);
                return;
            }
            GuideSearch.billsQuery({
                query: $scope.searchQuery
            }, onSuccess, function(response) {
                if (response.status === 404) {
                    $scope.loadAll();
                }
            });
        };

        var onSuccess = function(result, headers) {
            $rootScope.ALERT_RESULT_MSG(headers);
            $scope.guides = result;
            angular.forEach($scope.guides, function(data) {
                data.encodeId = WinAES.encode(data.id.toString())
            })
            setTimeout(function() {frzTable("divTable", "showTable", "footPager");}, 100);
        };


        var onSaveError = function(result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('global.messages.submittedfailed'));
        };

        $scope.datePickerForEtd = {};

        $scope.datePickerForEtd.status = {
            opened: false
        };
        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };

        $scope.datePickerForEta = {};
        $scope.datePickerForEta.status = {
            opened: false
        };
        $scope.datePickerForEtaOpen = function($event) {
            $scope.datePickerForEta.status.opened = true;
        };

        var onReleaseAllSuccess = function(result) {
            var msgToShow = "";
            if (result && result.length > 0) {
                for (var i = 0; i < result.length; i++) {
                    for (var j = 0; j < $scope.guides.length; j++) {
                        if ($scope.guides[j].id == result[i].id) {
                            angular.copy(result[i], $scope.guides[j]);
                        }
                    }
                }
                msgToShow = result.length.toString();
                angular.forEach($scope.guides, function(data) {
                    data.encodeId = WinAES.encode(data.id.toString())
                })
            } else {
                msgToShow = "0";
            }
            msgToShow = msgToShow + $translate.instant('autopsApp.guide.home.updatedNum');

            $scope.isSaving = false;
            layer.msg(msgToShow);
        };

        $scope.releaseAll = function(receivedOnly) {
            var guidesToRelase = angular.copy($scope.guides);

            for (var i = guidesToRelase.length - 1; i >= 0; i--) {
                if (guidesToRelase[i].hblReleasedTime) {
                    guidesToRelase.splice(i, 1);
                }
            }
            if (guidesToRelase.length == 0) {
                var msgToShow = "0" + $translate.instant('autopsApp.guide.home.updatedNum');
                layer.msg(msgToShow);
                return;
            }
            $scope.isSaving = true;

            Guide.releaseGuides({
                receivedOnly: receivedOnly
            }, guidesToRelase, onReleaseAllSuccess, onSaveError);
        };

        $scope.deleteOneGuide = function(index) {
            $scope.guides.splice(index, 1);
        }

        $scope.lockRatesAll = function(targetValue) {
            if (targetValue && $rootScope.account.authorities.indexOf("AU_RATE_LOCK") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_RATE_LOCK');
                return;
            }
            if (!targetValue && $rootScope.account.authorities.indexOf("AU_RATE_UNLOCK") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_RATE_UNLOCK');
                return;
            }
            var guidesToRelase = angular.copy($scope.guides);

            for (var i = guidesToRelase.length - 1; i >= 0; i--) {
                if (targetValue && guidesToRelase[i].ratesLocked) {
                    guidesToRelase.splice(i, 1);
                }
                else if (!targetValue && !guidesToRelase[i].ratesLocked) {
                    guidesToRelase.splice(i, 1);
                }
            }
            if (guidesToRelase.length == 0) {
                var msgToShow = "0" + $translate.instant('autopsApp.guide.home.updatedNum');
                layer.msg(msgToShow);
                return;
            }
            $scope.isSaving = true;
            Guide.lockRatesAll({targetValue: targetValue}, guidesToRelase, onReleaseAllSuccess, onSaveError);
        };

        $scope.lockGuidesAll = function(targetValue) {
            if (targetValue && $rootScope.account.authorities.indexOf("AU_BILL_CLOSE") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_CLOSE');
                return;
            }
            if (!targetValue && $rootScope.account.authorities.indexOf("AU_BILL_OPEN") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_OPEN');
                return;
            }
            var guidesToRelase = angular.copy($scope.guides);

            for (var i = guidesToRelase.length - 1; i >= 0; i--) {
                if (targetValue && guidesToRelase[i].isClosed) {
                    guidesToRelase.splice(i, 1);
                }
                else if (!targetValue && !guidesToRelase[i].isClosed) {
                    guidesToRelase.splice(i, 1);
                }
            }
            if (guidesToRelase.length == 0) {
                var msgToShow = "0" + $translate.instant('autopsApp.guide.home.updatedNum');
                layer.msg(msgToShow);
                return;
            }
            $scope.isSaving = true;
            Guide.lockGuidesAll({targetValue: targetValue}, guidesToRelase, onReleaseAllSuccess, onSaveError);
        };

        $scope.lockGuideOne = function(guide) {
            if (!guide.isClosed && $rootScope.account.authorities.indexOf("AU_BILL_CLOSE") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_CLOSE');
                return;
            }
            if (guide.isClosed && $rootScope.account.authorities.indexOf("AU_BILL_OPEN") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_OPEN');
                return;
            }
            if (!guide.etd) {
                layer.msg($translate.instant('autopsApp.guide.etdNullAlert'));
                return;
            };

            $scope.isSaving = true;
            Guide.setIsClosed({id: guide.id}, function(result) {
                $scope.isSaving = false;
                guide.isClosed = result.isClosed;
                guide.status = result.status;
                layer.msg($translate.instant('global.messages.submitted'));
                if(result.masterId == 0){
                    layer.confirm($translate.instant('autopsApp.guide.lockSubGuidesAllTips'), {
                        btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                        }, function(index) {
                            layer.msg($translate.instant('global.isSavingMsg'), {
                              shade: 0.3,
                              time: 200000
                            });
                            Guide.lockSubGuidesAll({
                                masterId: result.id,
                                bValue: result.isClosed,
                                type: "guide"
                            }, function(result) {
                                layer.closeAll();
                                layer.msg($translate.instant('global.save_success'));
                            })
                            layer.close(index);
                        }, function() {
                    });
                }
            }, onSaveError);
        }


        $scope.lockRateOne = function(guide, rateLock) {
            if (rateLock && $rootScope.account.authorities.indexOf("AU_RATE_LOCK") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_RATE_LOCK');
                return;
            }
            if (!rateLock && $rootScope.account.authorities.indexOf("AU_RATE_UNLOCK") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_RATE_UNLOCK');
                return;
            }
            $scope.isSaving = true;
            Guide.lockRateOne({
                guideId: guide.id,
                ratesLocked: rateLock
            }, function(result) {
                $scope.isSaving = false;
                guide.ratesLocked = rateLock;
                layer.msg($translate.instant('global.messages.submitted'));
                if(result.masterId == 0){
                    layer.confirm($translate.instant('autopsApp.guide.lockSubGuidesAllTips'), {
                        btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                        }, function(index) {
                            layer.msg($translate.instant('global.isSavingMsg'), {
                              shade: 0.3,
                              time: 200000
                            });
                            Guide.lockSubGuidesAll({
                                masterId: result.id,
                                bValue: result.ratesLocked,
                                type: "rate"
                            }, function(result) {
                                layer.closeAll();
                                layer.msg($translate.instant('global.save_success'));
                            })
                            layer.close(index);
                        }, function() {
                    });
                }
            }, onSaveError);
        };

        $scope.finFilter = function(item) {
            return item.authorities.indexOf("ROLE_ACCOUNT") !== -1;
        };

        $scope.updateFin = function(guide, item) {
            if(guide.finId == item.id){
                return;
            }
            $scope.isSaving = true;
            Guide.updateFin({
                guideId: guide.id,
                userId: item.id,
                userName: item.name
            }, function() {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
            }, onSaveError);
        };

        $scope.auditGuide = function(guide) {
            var entity = angular.copy(guide);
            entity.isFin = true;
            $uibModal.open({
                templateUrl: 'app/entities/custom-declaration-docs/custom-declaration-docs-dialog.html',
                controller: 'CustomDeclarationDocsDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('stats');
                        $translatePartialLoader.addPart('opsClient');
                        return $translate.refresh();
                    }],
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }

        $scope.mblRelease = function(guide) {
            var guideDTO = {};
            guideDTO.id = guide.id;
            guideDTO.mblOnly = true;
            if (guide.mblReleasedTime) {
                guideDTO.mblReleasedTime = null;
                guideDTO.status = "MblHold";
            } else {
                guideDTO.mblReleasedTime = new Date();
                guideDTO.status = "MblReleased";
            }
            $scope.isSaving = true;
            Guide.setRelease(guideDTO, function(result) {
                guide.status = result.status;
                guide.mblReleasedTime = result.mblReleasedTime;
                guide.lastModifiedTime = result.lastModifiedTime;
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
            }, onSaveError);
        }
        $scope.blRelease = function(guide) {
            var guideDTO = {};
            guideDTO.id = guide.id;
            guideDTO.mblOnly = false;
            if (guide.hblReleasedTime) {
                guideDTO.hblReleasedTime = null;
                guideDTO.status = "HblHold";
            } else {
                guideDTO.hblReleasedTime = new Date();
                guideDTO.status = "HblReleased";
            }
            $scope.isSaving = true;
            Guide.setRelease(guideDTO, function(result) {
                guide.status = result.status;
                guide.hblReleasedTime = result.hblReleasedTime;
                guide.lastModifiedTime = result.lastModifiedTime;
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
            }, onSaveError);
        }

        var memoCopy;
        $scope.copyMemo = function(guide) {
            memoCopy = angular.copy(guide.finMemo);
        }

        $scope.saveMemo = function(guide) {
            if(memoCopy != guide.finMemo){
                Guide.upateMemo({
                    memoString: guide.finMemo,
                    type: "TABLE_FIN",
                    companyId: guide.companyId,
                    guideId: guide.id
                }, function(){
                    layer.msg($translate.instant('global.save_success'));
                }, function(){
                    layer.msg($translate.instant('global.save_fail'));
                });
            }
        }

        $scope.guidePop = function(guide) {
            $rootScope.GUIDEACTIVETAB = 1;
            $rootScope.id = "view";
            if(guide.isExport){
                var htmlPath = 'app/entities/guide/sea-dialog.html';
                var controllerName = 'SeaDialogController';
                if(guide.shipmentType == "AIR"){
                    var htmlPath = 'app/entities/guide/air-dialog.html';
                    var controllerName = 'AirDialogController';
                }
                else if (guide.shipmentType == "EXPRESS" || guide.shipmentType == "FBAAIR" || guide.shipmentType == "FBASEA" || guide.shipmentType == "FBARAIL"
                    || guide.shipmentType == "WMS" || guide.shipmentType == "LOCAL" || guide.shipmentType == "LOCALKG"){
                    var htmlPath = ($rootScope.HAVEAUTH && $rootScope.HAVEAUTH("FB_WF_VIEW"))?'app/entities/guide/fba-role-dialog.html':'app/entities/guide/fba-dialog.html';
                    var controllerName = 'FbaDialogController';
                }
                $uibModal.open({
                    templateUrl: htmlPath,
                    controller: controllerName,
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: [function() {
                            return Guide.get({id : guide.id}).$promise;
                        }]
                    }
                }).result.then(function(result) {
                    guide.status = result.status;
                    guide.mblOnly = result.mblOnly;
                    guide.lastModifiedTime = result.lastModifiedTime;
                }, function(result) {
                });
            }
            else {
                $uibModal.open({
                    templateUrl: 'app/entities/import/import-dialog.html',
                    controller: 'ImportDialogController',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: [function() {
                            return Guide.get({id : guide.id}).$promise;
                        }]
                    }
                }).result.then(function(result) {
                    guide.status = result.status;
                    guide.mblOnly = result.mblOnly;
                    guide.lastModifiedTime = result.lastModifiedTime;
                }, function(result) {
                });
            }
        }

        $scope.blReleaseReport = function(apiName) {
            if(!$scope.guides || $scope.guides.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var loadIndex = layer.load(1, {shade: 0.3});
            if(apiName == "jdxtBlReleaseReport"){
                OpsFiles.jdxtBlReleaseReport($scope.guides, function(result) {
                    layer.close(loadIndex);
                    $rootScope.DOWNLOADEXCEL(result, "shipments_report.xlsx");
                })
            }
            else {
                OpsFiles.blReleaseReport($scope.guides, function(result) {
                    layer.close(loadIndex);
                    $rootScope.DOWNLOADEXCEL(result, "shipments_report.xlsx");
                })
            }
        }


    }
})();
