(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('AsyncTaskSearch', AsyncTaskSearch);

    AsyncTaskSearch.$inject = ['$resource'];

    function AsyncTaskSearch($resource) {
        var resourceUrl =  'api/_search/async-tasks/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
