(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('ExpressPacking', ExpressPacking);

    ExpressPacking.$inject = ['$resource', 'DateUtils'];

    function ExpressPacking ($resource, DateUtils) {
        var resourceUrl =  'api/express-packings/:id';
        function initResponse(data){
            if (data) {
                data = angular.fromJson(data);
                data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                data.etd = DateUtils.convertLocalDateFromServer(data.etd);
            }
            return data;
        }
        function initRequest (data) {
            data = angular.copy(data);
            data.etd = DateUtils.convertLocalDateToServer(data.etd);
            return angular.toJson(data);
        }

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'getUnshiped': {
                url:'api/express-packings/getUnshiped',
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        angular.forEach(data, function(item){
                            item.etd = DateUtils.convertLocalDateFromServer(item.etd);
                        }) 
                    }
                    return data;
                }
            },      
            'getPackingByJobNum': {
                url:'api/express-packings/getPackingByJobNum',
                method: 'GET'
            },      
            'delFromPacking': {
                url:'api/express-packings/delFromPacking',
                method: 'GET'
            },              
            'addParcelByJobNum': {
                url:'api/express-packings/addParcelByJobNum',
                method: 'GET'
            }, 
            'updateGwVol': {
                url:'api/express-packings/updateGwVol',
                method: 'GET'
            },              
            'getExpressParcels': {
                url:'api/express-packings/getExpressParcels',
                method: 'GET',
                isArray: true
            },     
            'searchByNums': {
                url:'api/express-packings/searchByNums',
                method: 'GET',
                isArray: true
            },                      
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    return initRequest(data);
                },
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    return initRequest(data);
                },
                transformResponse: function (data) {
                    return initResponse(data);
                }
            }
        });
    }
})();
