(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('WmsMap', WmsMap);

    WmsMap.$inject = ['$resource'];

    function WmsMap ($resource) {
        var resourceUrl =  'api/wms-maps/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAllByAccountBindId': {
                method: 'GET',
                url:'api/wms-maps/getAllByAccountBindId',
                isArray: true
            },
            'saveList': {
                method: 'PUT',
                url:'api/wms-maps/saveList',
                isArray: true
            }, 
            'update': { method:'PUT' }
        });
    }
})();
