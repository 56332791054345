(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsCompanyDialogController', OpsCompanyDialogController);

    OpsCompanyDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'OpsCompany'];

    function OpsCompanyDialogController($scope, $stateParams, $uibModalInstance, entity, OpsCompany) {

        $scope.opsCompany = entity;
        $scope.load = function(id) {
            OpsCompany.get({id : id}, function(result) {
                $scope.opsCompany = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('autopsApp:opsCompanyUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.opsCompany.id != null) {
                OpsCompany.update($scope.opsCompany, onSaveSuccess, onSaveError);
            } else {
                OpsCompany.save($scope.opsCompany, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.datePickerForCreatedTime = {};

        $scope.datePickerForCreatedTime.status = {
            opened: false
        };

        $scope.datePickerForCreatedTimeOpen = function($event) {
            $scope.datePickerForCreatedTime.status.opened = true;
        };

        $scope.datePickerOpenStatus = {};
        $scope.datePickerOpenStatus.dateFrom = false;
        $scope.openCalendar =  function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        $scope.zoneIds = [];
        $scope.getZoneIds = function() {
            if($scope.zoneIds.length > 0){
                return;
            }
            OpsCompany.getZoneIds(function(result){
                $scope.zoneIds = result;
            })
        };

}
})();
