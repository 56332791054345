(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsCargoUnpackingController',WmsCargoUnpackingController);

    WmsCargoUnpackingController.$inject = ['$rootScope', '$uibModalInstance', '$translate', '$timeout', 'entity', 
        'WmsCargo', 'WmsWarehouse', 'WmsIn'];

    function WmsCargoUnpackingController($rootScope, $uibModalInstance, $translate, $timeout, entity, 
        WmsCargo, WmsWarehouse, WmsIn) {
        var vm = this;

        vm.unpackingDto = entity.cargo;
        vm.locations = entity.locations;
        vm.clear = clear;
        vm.submitMovment = submitMovment;
        vm.getAllLocations = getAllLocations;
        vm._onLocationSelected = _onLocationSelected;
        vm.moveAmtChange = moveAmtChange;
        vm._onGoodsSelected = _onGoodsSelected;
        vm.clearWmsGoods = clearWmsGoods;

        vm.addOne = addOne;
        vm.delOne = delOne;
        vm.updateVol = updateVol;

        vm.isSaving = false;

        $timeout(function (){
            if(!entity.cargo || !entity.cargo.id){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.inventoryAmtAlert', {param: vm.unpackingDto.availableAmt}))
                clear();
            }
            getWmsCargoMovementDTO();
        });
        function getWmsCargoMovementDTO () {
            WmsCargo.getWmsCargoMovementDTO({wmsCargoId: entity.cargo.id}, function(result) {
                vm.unpackingDto = result;
                if(!result || !result.availableAmt){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.inventoryAmtAlert', {param: result.availableAmt}))
                    clear();
                }
                if(!vm.unpackingDto.unpackingToList || !vm.unpackingDto.unpackingToList.length){
                    addOne();
                }
                else if(vm.unpackingDto.availableAmt) {
                    angular.forEach(vm.unpackingDto.unpackingToList, function(data){
                        if(data.pkgs){
                            data.pkgsCopy = data.pkgs;
                            data.pkgs = data.pkgs * vm.unpackingDto.availableAmt;
                        }
                    })
                }    
            });
        }

        function clearWmsGoods(cargo) {
            if (!cargo.wmsGoodsId) { return; };
            cargo.code = null;
            cargo.sku = null;
            cargo.wmsGoodsId = null;
            cargo.cargoName = null;
            cargo.cargoNameEn = null;
            cargo.packageType = null;
            cargo.gw = null;
            cargo.vol = null;
            cargo.length = null;
            cargo.width = null;
            cargo.height = null;
            cargo.isPallet = null;
            cargo.palletCapacity = null;
            cargo.palletAmt = null;
        }

        function addOne () {
            if(!vm.unpackingDto.unpackingToList){
                vm.unpackingDto.unpackingToList = [];
            }
            vm.unpackingDto.unpackingToList.push(getNewCargo());
        }
        function delOne (idx) {
            vm.unpackingDto.unpackingToList.splice(idx, 1);
        }
        function moveAmtChange (cargo) {
            if(vm.unpackingDto.moveAmt < 0){
                vm.unpackingDto.moveAmt = 0;
                return;
            }
            if(vm.unpackingDto.moveAmt > vm.unpackingDto.availableAmt){
                vm.unpackingDto.moveAmt = vm.unpackingDto.availableAmt;
                layer.msg($translate.instant('global.overMaxAlert'));
                return;
            }
            angular.forEach(vm.unpackingDto.unpackingToList, function(data){
                if(data.pkgsCopy){
                    data.pkgs = data.pkgsCopy * vm.unpackingDto.moveAmt;
                }
            })
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function _onLocationSelected (location, cargo) {
            cargo.location = location.code;
            cargo.locationId = location.locationId;
            cargo.areaId = location.areaId;
            cargo.warehouseId = location.warehouseId;
            cargo.locationType = location.locationType;
        }
        function getNewCargo () {
            var newCargo = angular.copy(entity.cargo);
            newCargo.action = "unpackingIn";
            newCargo.keeper = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            newCargo.keeperId = $rootScope.account.id;
            newCargo.isLocked = true;

            newCargo.id = null;
            newCargo.pkgs = null;
            newCargo.wmsGoodsId = null;            
            newCargo.isPallet = false;
            newCargo.palletCapacity = null;
            newCargo.palletAmt = null;
            newCargo.tempTime = null;
            newCargo.piece = null;
            newCargo.pieceUnit = null;
            newCargo.capacity = null;
            newCargo.capacityUnit = null;
            newCargo.tempTag = null;
            newCargo.wmsOutNum = null;
            newCargo.pkgMore = false;
            newCargo.goodsValue = null;
            newCargo.shareCost = null;
            newCargo.dutyRate = null;
            newCargo.declareValue = null;
            return newCargo;
        }
        function updateVol(cargo) {
            if (cargo.length && cargo.width && cargo.height) {
                cargo.vol = cargo.length / 100 * cargo.width / 100 * cargo.height / 100;
                cargo.vol = cargo.vol.toFixed(4) - 0;
            }            
        }
        function getAllLocations () {
            if (vm.locations && vm.locations.length > 0){
                return;
            }
            WmsWarehouse.getAllLocations({companyId: $rootScope.account.companyId}, function (data) {
                vm.locations = data;
            });
        }

        function submitMovment () {            
            if ($rootScope.HAVENOAUTH("FUN_WMS_MOVING")) {
                $rootScope.FORBIDDEN_BY_ALERT("FUN_WMS_MOVING");
                return;
            }
            vm.isSaving = true;
            WmsCargo.unpacking(vm.unpackingDto, function(result) {
                vm.isSaving = false;
                getWmsCargoMovementDTO();
                layer.msg($translate.instant('global.messages.submitted'));
                $translate.refresh();  
            }, $rootScope.ALERT_ERROR); 
        }
        function _onGoodsSelected(data, cargo) {
            cargo.wmsGoodsId = data.wmsGoodsId; //batchid
            goodsSelected(data, cargo);
        }
        function goodsSelected(data, cargo) {
            cargo.code = data.code;
            cargo.sku = data.sku;
            cargo.cargoName = data.cargoName;
            cargo.cargoNameEn = data.cargoNameEn;
            cargo.packageType = data.packageType;
            cargo.gw = data.gw;
            cargo.palletAmt = null;
            cargo.vol = data.vol;
            cargo.length = data.length;
            cargo.width = data.width;
            cargo.height = data.height;
            cargo.isPallet = data.isPallet;
            cargo.palletCapacity = data.palletCapacity;
            cargo.chargeType = data.chargeType;
            cargo.chargePrice = data.chargePrice;
            cargo.piece = data.piece;
            cargo.pieceUnit = data.pieceUnit;
            cargo.nw = data.nw;
            cargo.capacity = data.capacity;
            cargo.capacityUnit = data.capacityUnit;
            cargo.goodsValue = data.goodsValue;
            cargo.dutyRate = data.dutyRate;
            cargo.declareValue = data.declareValue;
        }
    }
})();
