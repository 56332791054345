(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('HfBillDeleteController', HfBillDeleteController);

    HfBillDeleteController.$inject = ['$rootScope', '$uibModalInstance', '$timeout', '$translate', '$state', '$uibModal', '$translatePartialLoader',
    'entity', 'Principal', 'DateUtils', 'DataUtils', 'GuideRates', 'OpsVender', 'HfBill', 'ParseLinks', 'OpsCompany'];

    function HfBillDeleteController($rootScope, $uibModalInstance, $timeout, $translate, $state, $uibModal, $translatePartialLoader,
        entity, Principal, DateUtils, DataUtils, GuideRates, OpsVender, HfBill, ParseLinks, OpsCompany) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                // initList();
            });
        }
        var vm = this;
        vm.opsVenderDeposite = {};
        vm.clear = clear;
        vm.exportBills = exportBills;
        vm.isCompany = entity.opsCompany != null;

        vm.isVasDeposite = false;
        if(vm.isCompany && entity.serviceType == "VAS"){
            vm.isVasDeposite = true;
        }
        vm.cfBills = [];
        vm.curAmtList = [];
        vm.choosedCurAmt = choosedCurAmt;
        vm.choosedCur = $rootScope.account.baseCurrency;
        vm.hfBills = [];

        vm.itemsPerPage = 20;
        vm.clear = clear;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.reDeductCharges = reDeductCharges;
        vm.delHedgingBills = delHedgingBills;
        
        function loadAll (loadPage) {
            HfBill.getCompanyBill({
                page: loadPage - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                companyId: entity.opsCompany?entity.opsCompany.id:null,
                serviceType: entity.opsCompany?entity.serviceType:null,
                currency: entity.vender?vm.choosedCur:null,
                venderId: entity.vender?entity.vender.id:null
            }, onSuccess, onError);

            function sort() {
                return ['id,desc'];
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.hfBills = data;
                vm.page = loadPage;
                // initList();    
            }
            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }

        function loadPage () {
            loadAll(vm.page);
        }

        function choosedCurAmt (curAmt) {
            vm.choosedCur = curAmt.cur;
            loadAll(1);
        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        loadAll(1);

        vm.groupCompanys = [];
        $timeout(function (){
            if(entity.vender){
                OpsVender.getDeposteAmt({id: entity.vender.id}, function(result){
                    vm.opsVenderDeposite = result;
                    vm.curAmtList = angular.fromJson(result.depositeJson);                    
                })
            }
            else if(entity.opsCompany){
                OpsCompany.getBindCompanys(function(result) {
                    vm.groupCompanys = result;
                    angular.forEach(vm.groupCompanys, function(data){
                        data.deposit = DataUtils.round(data.deposit, 2);
                        data.vasDeposite = DataUtils.round(data.vasDeposite, 2);
                        if(!data.deposit){
                            data.deposit = 0.00;
                        }
                        if(!data.vasDeposite){
                            data.vasDeposite = 0.00;
                        }
                    })
                })
            }
        });
        
        // vm.customizedFormat = false;
        // function initList () {
        //     if (!$rootScope.account || angular.equals($rootScope.account, {})) {
        //         return;
        //     }
        //     if($rootScope.IS_JINLING()){
        //         vm.customizedFormat = true;
        //         for(var i = vm.hfBills.length - 1; i >= 0; i--){
        //             var data = vm.hfBills[i];
        //             if(!data.total){
        //                 continue;
        //             }
        //             var newBill = {
        //                 remarks: data.remarks
        //             };

        //             if(data.total > 0){
        //                 newBill.total = data.total;
        //                 newBill.sign = "-";
        //                 newBill.remarks = data.remarks + " - " + DateUtils.convertLocalDateToServer(data.time);
        //                 vm.cfBills.push({});
        //                 vm.cfBills.push(newBill);
        //                 vm.cfBills.push({});

        //                 var balanceBill = {};
        //                 balanceBill.sign = "=";
        //                 balanceBill.total = data.balance;

        //                 balanceBill.remarks = "Final Balance";// + (vm.cfBills.length > 3?vm.cfBills[vm.cfBills.length-4].remarks:"")
        //                 vm.cfBills.push(balanceBill);
        //                 vm.cfBills.push({});
        //             }
        //             else{
        //                 newBill.total = -data.total;
        //                 newBill.sign = "+";
        //                 vm.cfBills.push(newBill);
        //             }                    
        //         }
        //         if(vm.cfBills[vm.cfBills.length - 1].sign != "="){
        //             var balanceBill = {
        //                 sign: "=",
        //                 total: vm.hfBills[0].balance,
        //                 remarks: "Final  balance"
        //                 // remarks: vm.hfBills[0].balance >= 0?"Advance balance":"Balance need to pay"
        //             };
        //             vm.cfBills.push({});
        //             vm.cfBills.push(balanceBill);
        //         }
        //     }
        // }

        function exportBills () {
            if(!vm.hfBills || vm.hfBills.length == 0){
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/ops-bill/ops-bill-detail.html',
                controller: 'OpsBillDetailController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return vm.hfBills[0];
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }

        function reDeductCharges () {
            if ($rootScope.HAVENOAUTH('ROLE_BOSS')){
                layer.msg($translate.instant('global.forbiddenBoss'));
                return;
            }
            layer.confirm("确认要初始化，重新扣费吗？", {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                layer.load(1, {shade: 0.3});
                OpsVender.reDeductCharges({id: entity.vender.id}, function(){
                    layer.closeAll(); 
                    clear();
                });
            }, function() {
                return;
            });
        }
        function delHedgingBills () {
            // if ($rootScope.HAVENOAUTH('ROLE_BOSS')){
            //     layer.msg($translate.instant('global.forbiddenBoss'));
            //     return;
            // }
            // layer.confirm("确认要删除所有对冲费用记录吗？", {
            //     icon: 3,
            //     title: "Msg",
            //     btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            // }, function(index) {
            //     layer.close(index);
            //     layer.load(1, {shade: 0.3});
            //     OpsVender.delHedgingBills({id: entity.vender.id}, function(){
            //         layer.closeAll(); 
            //         loadAll();
            //     });
            // }, function() {
            //     return;
            // });
        }
    }
})();
