(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsExchangeRatesDialogController', OpsExchangeRatesDialogController);

    OpsExchangeRatesDialogController.$inject = ['$uibModalInstance', 'entity'];

    function OpsExchangeRatesDialogController ($uibModalInstance, entity) {
        var vm = this;
        vm.opsExchangeRates = entity;
        vm.clear = clear;
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
