
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AssistChargeDetailController', AssistChargeDetailController);

    AssistChargeDetailController.$inject = ['$timeout', '$rootScope', '$scope', '$uibModalInstance', '$translate', 
    'entity', 'GuideRates', 'DataUtils'];

    function AssistChargeDetailController ($timeout, $rootScope, $scope, $uibModalInstance, $translate, 
        entity, GuideRates, DataUtils) {
        var vm = this;        
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
           getUnpaidSubshipmentCharges();           
        });
        function getUnpaidSubshipmentCharges () {
            GuideRates.getUnpaidSubshipmentCharges({
                isFms : entity.isFms,
                masterId: entity.masterId,
                companyId: entity.companyId
            }, function(result){
                $scope.guideRates = result;
                updateTotal();
            });            
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;            
            GuideRates.hedgingUnpaidCharges({
                isFms : entity.isFms,
                masterId: entity.masterId
            }, $scope.guideRates, onSaveSuccess, onSaveError);
        }


        function onSaveSuccess () {
            vm.isSaving = false;
            $uibModalInstance.close();
            layer.msg($translate.instant('global.messages.submitted'));
        }

        function onSaveError () {
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submittedfailed'));
        }

        var updateTotal = function() {            
            if(!$scope.guideRates || $scope.guideRates.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                $uibModalInstance.dismiss('cancel');
                return;
            }
            $scope.totalList = [];
            var currencys = [];
            angular.forEach($scope.guideRates, function(data) {
                if (data.payCurrency && currencys.indexOf(data.payCurrency) === -1) {
                    currencys.push(data.payCurrency);
                }
            })
            var ttAmt = 0;
            angular.forEach(currencys, function(currency) {
                ttAmt = 0;
                angular.forEach($scope.guideRates, function(rate) {
                    if (rate.payCurrency == currency && rate.payAmount) {
                        ttAmt = ttAmt + rate.payAmount;                       
                    }
                })
                $scope.totalList.push({
                    currency: currency,
                    total: DataUtils.round(ttAmt, 2)
                });
            })
        }
        $scope.deleteRate = function(rate) {
            var index = $scope.guideRates.indexOf(rate);
            if (index > -1) {
                $scope.guideRates.splice(index, 1);
            };
            updateTotal();
            $translate.refresh();
        }
        var isReverse = true;
        $scope.sortRates = function(fd) {
            isReverse = !isReverse;
            $scope.guideRates.sort(function(a, b) {
                if (isReverse) {
                    if(!a[fd]){
                        return 1;
                    }
                    return a[fd] < b[fd] ? 1 : -1;
                } else {
                    if(!b[fd]){
                        return 1;
                    }
                    return a[fd] > b[fd] ? 1 : -1;
                }
            });
        }
    }
})();
