(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('PrebookingDetailController', PrebookingDetailController);

    PrebookingDetailController.$inject = ['$timeout', '$scope', '$rootScope', '$translate', '$uibModalInstance', '$uibModal',
    'Booking', 'OpsPorts', 'entity', 'DateUtils', 'OpsVender', 'DataUtils', 
    'Guide', 'JhiSignature', 'OpsReport'];

    function PrebookingDetailController($timeout, $scope, $rootScope, $translate, $uibModalInstance, $uibModal,
        Booking, OpsPorts, entity, DateUtils, OpsVender, DataUtils,
       Guide, JhiSignature, OpsReport) {
        var vm = this;
        vm.save = save;
        vm.preBookingConfirm = preBookingConfirm;
        $scope.preBookingList = [];

        var guide = entity;
        $scope.customerName = entity.customerName;        

        $scope.isSaving = false;

        var bookingCopy, indexCopy, jhiSignature, jasonObj;

        $timeout(function (){      
            guide.guideRates = [];
            $scope.addRate(guide);
            $scope.isSaving = true;
            JhiSignature.getFbaGuideRemarks({
                guideId: guide.id
            }, function(result) {
                $scope.isSaving = false;
                jhiSignature = result;
                if (jhiSignature) {
                    jasonObj = angular.fromJson(jhiSignature.signature);
                    if(jasonObj.preBookings){
                        angular.forEach(jasonObj.preBookings, function(data){                            
                            if (data.booking){          
                                data.etd = DateUtils.convertLocalDateFromServer(data.etd);                      
                                data.cargoReadyDate = DateUtils.convertLocalDateFromServer(data.cargoReadyDate);
                            }
                        })
                        $scope.preBookingList = jasonObj.preBookings;
                    }     
                    else {
                        $scope.preBookingList.push(guide);
                    }               
                }
                else {
                    $scope.preBookingList.push(guide);
                }
            }, function() {
                $scope.preBookingList.push(guide);
                $scope.isSaving = false;
            });
            vm.activeTab = 0;
            indexCopy = 0;
        });
        function onSaveSuccess(result) {
            jhiSignature = result;
            $scope.isSaving = false;
            bookingCopy = angular.copy($scope.preBookingList[indexCopy]);
            layer.msg($translate.instant('global.save_success'));
        };
        $scope._onPortSelected = function(data, obj, fd) {
            obj[fd] = data.code;
        }
        function onSaveError(result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('global.save_fail'));
        };

        function save() {
            if(!jasonObj){
                jasonObj = {};
            }
            jasonObj.preBookings = angular.copy($scope.preBookingList);
            angular.forEach(jasonObj.preBookings, function(data){
                if (data.booking){
                    data.etd = DateUtils.convertLocalDateToServer(data.etd);
                    data.cargoReadyDate = DateUtils.convertLocalDateToServer(data.cargoReadyDate);
                }
            })

            if (!jhiSignature || !jhiSignature.id) {
                jhiSignature = {};
                jhiSignature.fbaGuideId = guide.id;
            }
            jhiSignature.signature = angular.toJson(jasonObj);
            $scope.isSaving = true;
            JhiSignature.update(jhiSignature, onSaveSuccess, onSaveError);
        };

        $scope.setActiveTab = function ($index, booking) {
            if (bookingCopy && !angular.equals($scope.preBookingList[indexCopy], bookingCopy)) {
                layer.msg($translate.instant('global.saveAlert'));
                vm.activeTab = indexCopy;
                return;
            };  
            vm.activeTab = $index;
            indexCopy = $index;
            bookingCopy = angular.copy($scope.preBookingList[indexCopy]);
            $scope.opsShippers = [];
        }

        $scope.addBooking = function () {
            var booking = angular.copy(guide);
            $scope.preBookingList.push(booking);
        }

        $scope.delBooking = function(booking) {
            var index = $scope.preBookingList.indexOf(booking);
            if (index > -1) {
                $scope.preBookingList.splice(index, 1);
            };
            bookingCopy = null;
        };

        $scope.clear = function () {
            $uibModalInstance.dismiss();            
        }

        $rootScope.LOAD_REPORT_TEMPLATES();

        function getVolumes(guide){
            var volumes = "";
            angular.forEach(guide.guideFclCtns, function(data){
                if(data.num && data.ctnType){
                    volumes += data.num + " X " + data.ctnType + " ";
                }
            })
            return volumes.trim();
        }

        $scope.guideSendBooking = function(type, code, guide) {
            if(!guide.bookingToName){
                layer.msg($translate.instant('autopsApp.booking.home.choose_booking_agent'));
                return;
            }
            initGuide(guide);    
            var email = {};
            email.attachments = [];
            email.head = guide.bookingToName;
            email.subject = guide.polName + " - " + guide.destName + "(" + getVolumes(guide) + " By " + guide.carrier + "; ETD: "+ DateUtils.convertLocalDateToServer(guide.etd) + ")--" + $translate.instant('entity.email.bookingSubject');
            email.content = $translate.instant('entity.email.bookingContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = guide;
            entity.emailType = type;
            entity.code = code;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            entity.venderId = guide.bookingToId;
            entity.contactId = guide.bookingToContactId;
            entity.saVenderId = null;
            entity.fileResult = null;
            sendEmailPop(entity, guide);
        }

        var sendEmailPop = function(entity, guide) {
            $uibModal.open({
                templateUrl: 'app/entities/email/email-dialog.html',
                controller: 'EmailDialogController',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                guide = result;
            }, function(result) {});
        }
        var initGuide = function(guide) {
            angular.forEach(guide.guideRates, function(data){
                data.payer = guide.bookingToName;
                data.venderId = guide.bookingToId;
                data.isPay = true;
            })
        }

        $scope.exportExcelPdf = function(format, code, fileName, guide) {
            if(!guide.bookingToName){
                layer.msg($translate.instant('autopsApp.booking.home.choose_booking_agent'));
                return;
            }
            initGuide(guide);            
            var guideReportDTO = angular.copy(guide);
            guideReportDTO.packingList = guideReportDTO.booking.bookingCtns;
            guideReportDTO.reportType = code;
            guideReportDTO.opType = 1;
            guideReportDTO.fileType = format;
            guideReportDTO.fileName = fileName;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                $rootScope.EXPORTDOWNLOAD(result, format, fileName);
            });
        }

        $scope.previewReport = function(code, guide) {
            if(!guide.bookingToName){
                layer.msg($translate.instant('autopsApp.booking.home.choose_booking_agent'));
                // return;
            }
            initGuide(guide);    
            var guideReportDTO = angular.copy(guide);
            guideReportDTO.packingList = guideReportDTO.booking.bookingCtns;
            guideReportDTO.reportType = code;
            guideReportDTO.opType = 1;
            guideReportDTO.fileType = 1;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {

            });
        }

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }
        
        $scope.portList = new Array();
        $scope.loadPorts = function() {
            if ($rootScope.OPSPORTS.airports && $rootScope.OPSPORTS.airports.length > 0){
                return;
            }
            OpsPorts.getAll(function(result){
                $rootScope.OPSPORTS.airports = [];
                $rootScope.OPSPORTS.seaports = [];
                angular.forEach(result, function(data){
                    if(data.type == 1){
                        $rootScope.OPSPORTS.seaports.push(data);
                    }
                    else {
                        $rootScope.OPSPORTS.airports.push(data);
                    }
                })
                if (guide && (guide.shipmentType == "AIR" || guide.shipmentType == "EXPRESS")) {
                    $scope.portList = $rootScope.OPSPORTS.airports;         
                } else {
                    $scope.portList = $rootScope.OPSPORTS.seaports;
                }
            })
        };
        $scope.ggenerateGwVol = function(guide) {
            if (guide.gwVol) {
                guide.gwVol = angular.uppercase(guide.gwVol);
                return;
            }
            if (guide.gw && guide.vol) {
                guide.gwVol = guide.gw.toFixed(3) + " KGS" + "\n" + guide.vol.toFixed(3) + " CBM";
            } else if (guide.vol) {
                guide.gwVol = guide.vol.toFixed(3) + " CBM";

            } else if (guide.gw) {
                guide.gwVol = guide.gw.toFixed(3) + " KGS";
            };
        };
        $scope.generateMark = function(guide) {
            if (!guide.mark && guide.booking.mark && !guide.mblOnly) {
                guide.mark = angular.copy(guide.booking.mark);               
            }
            guide.mark = angular.uppercase(guide.mark);
        }
        $scope.generateGoodsDiscription = function(guide) {
            if (!guide.goodsDiscription) {
                if (guide.booking.goodsDiscription && !guide.mblOnly) {
                    guide.goodsDiscription = angular.copy(guide.booking.goodsDiscription);
                }
                else if (guide.cargoName) {
                    guide.goodsDiscription = angular.uppercase(guide.cargoName);
                }
            }
            guide.goodsDiscription = angular.uppercase(guide.goodsDiscription);
        }
        $scope.ggeneratePkgsAndType = function(guide) {
            if (!guide.pkgNum || !guide.booking.pkgType || guide.pkgsAndType) {
                guide.pkgsAndType = angular.uppercase(guide.pkgsAndType);
                return;
            }
            guide.pkgsAndType = guide.pkgNum.toString() + " " + guide.booking.pkgType;
        }

        $scope.generateSayCtns = function(guide) {
            if (!guide.guideFclCtns || guide.guideFclCtns.length == 0) {
                layer.msg($translate.instant('global.messages.sayCtnsFailed'));
                return;
            }
            var sayCtns = "SAY ";
            var isFirst = true;

            var ctnTypeSet = new Set();
            angular.forEach(guide.guideFclCtns, function(data){
                if(data.ctnType && !ctnTypeSet.has(data.ctnType)){
                    ctnTypeSet.add(data.ctnType);
                }
            }) 

            var ctnCount = 0;
            ctnTypeSet.forEach(function(ctnType){
                ctnCount = 0;
                angular.forEach(guide.guideFclCtns, function(data) {
                    if(data.ctnType == ctnType && data.num){
                        ctnCount += data.num;
                    }
                })
                if (ctnCount) {
                    if (!isFirst) {
                        sayCtns = sayCtns + " AND ";
                    }
                    sayCtns = sayCtns + DataUtils.translate(ctnCount.toString()) + " (" + ctnCount + ") " + ctnType;
                    isFirst = false;
                }
            })
            guide.sayCtns = sayCtns + " CONTAINER(S) ONLY.";         
        }

        var isWood = true;
        $scope.woodenTxt = function(guide) {
            if (guide.isWood) {
                if(isWood){
                    guide.woodenStatement = "THIS SHIPMENT HAS BEEN FUMIGATED AND HEATED.";
                }
                else {
                    guide.woodenStatement = "THIS SHIPMENT CONTAINS SOLID WOOD PACKING MATERIALS, BUT HAS BEEN FUMIGATED.";
                }
                isWood = !isWood;                
            } else {
                guide.woodenStatement = "THIS SHIPMENT CONTAINS NO SOLID WOODEN PACKING MATERIALS.";
            }
        }
        $scope.addCtn = function(guide) {
            if(!guide.guideFclCtns){
                guide.guideFclCtns = [];
            }
            guide.guideFclCtns.push({});
        };

        $scope.deleteCtn = function(bookingCtn, guide) {
            var index = guide.guideFclCtns.indexOf(bookingCtn);
            if (index > -1) {
                guide.guideFclCtns.splice(index, 1);
            };
        };

        $scope.updateVolGw = function(guide) {
            var newGwTotal = 0;
            var newVolTotal = 0;
            var newPkgsTotal = 0;
            var isSamePkg = true;
            var pkgsCopy = null;
            angular.forEach(guide.guideFclCtns, function(data) {
                if (data.gw) {
                    newGwTotal = newGwTotal + data.gw;
                }
                if (data.vol) {
                    newVolTotal = newVolTotal + data.vol;
                }
                if (data.pkgNum) {
                    newPkgsTotal = newPkgsTotal + data.pkgNum;
                }
                if (data.pkgType) {
                    if (pkgsCopy) {
                        if (pkgsCopy != data.pkgType) {
                            isSamePkg = false;
                        }
                    } else {
                        pkgsCopy = data.pkgType;
                    }
                }
            });
            guide.gw = newGwTotal.toFixed(3) - 0;
            guide.vol = newVolTotal.toFixed(3) - 0;
            guide.pkgNum = newPkgsTotal.toFixed(0) - 0;
            if (!isSamePkg) {
                guide.booking.pkgType = "PKGS";
            } else {
                guide.booking.pkgType = pkgsCopy;
            }
            updateGwVol(guide);
            updatePkgsAndType(guide);
        }

        var updatePkgsAndType = function(guide) {
            guide.booking.pkgsAndType = guide.pkgNum + " " + guide.booking.pkgType;
            guide.pkgsAndType = guide.pkgNum + " " + guide.booking.pkgType;     
        }

        var updateGwVol = function(guide) {
            var gw = guide.gw;
            var vol = guide.vol;
            if(gw == null){gw = 0;}
            if(vol == null){vol = 0;}
            guide.gwVol = gw.toFixed(3) + "KGS" + "\n" + vol.toFixed(3) + "CBM";
            guide.booking.gwVol = gw.toFixed(3) + "KGS" + "\n" + vol.toFixed(3) + "CBM";
        };

        $scope.addRate = function(guide) {
            if(!guide.guideRates){
                guide.guideRates = [];
            }
            guide.guideRates.push({
                itemId: 1, 
                item: "海运费",
                payer: guide.bookingToName,
                venderId: guide.bookingToId,
                isHidden: false,
                locked: false,
                isPay: true
            });
        };
        $scope.deleteRate = function(rate, guide) {
            var index = guide.guideRates.indexOf(rate);
            if (index > -1) {
                guide.guideRates.splice(index, 1);
            };
        };

        $scope.rateUnitChange = function(rate, guide) {
            if(rate.unit == "CTNTYPE VAR" && guide.guideFclCtns){
                ctnTypeVarInput(rate, null);
            }
            else {
                rate.count = DataUtils.round(DataUtils.getUnitCount(rate, 'guide', guide), 4);
                $scope.amountChange(rate);
            }
        }
        
        var ctnTypeVarInput = function(rate, item, guide) {
            var ctnTypeSet = new Set();
            angular.forEach(guide.guideFclCtns, function(data) {
                if (data.ctnType) {
                    ctnTypeSet.add(data.ctnType);
                }
            })
            var isFirst = true;
            ctnTypeSet.forEach(function(ctnType){ 
                if(isFirst){
                    rate.unit = ctnType;
                    rate.count = DataUtils.round(DataUtils.getUnitCount(rate, 'guide', guide), 4);
                    $scope.amountChange(rate);
                    minMaxCheck(rate, item);
                    isFirst = false;
                }
                else {
                    var typeExist = false;
                    angular.forEach(guide.guideRates, function(data) {
                        if (data.isPay == rate.isPay 
                                && data.unit == ctnType 
                                && data.itemId == rate.itemId 
                                && data.venderId == rate.venderId) {
                            typeExist = true;
                        }
                    })
                    if(!typeExist){
                        var tempRate = angular.copy(rate);
                        tempRate.unit = ctnType;
                        tempRate.count = DataUtils.round(DataUtils.getUnitCount(tempRate, 'guide', guide), 4);
                        $scope.amountChange(tempRate);
                        guide.guideRates.push(tempRate);
                    }
                }                    
            })
        }
        $scope.amountChange = function(rate) {
            rate.price = DataUtils.round(rate.price, 4);
            if (rate.price < 0) {
                rate.price = 0;
            }
            if (null == rate.count || rate.count < 0) {
                rate.count = 1.00;
            }
            rate.total = rate.count * rate.price;
            rate.total = rate.payAmount = DataUtils.round(rate.total, 2);
            rate.payCurrency = rate.currency;
            rate.payExchange = 1.00;
        }

        function preBookingConfirm(guide) {
            $scope.isSaving = true;
            Guide.preBookingConfirm(guide, function(result){
                $scope.isSaving = false;
                layer.msg($translate.instant('global.save_success'));
                $uibModalInstance.close(result);  
            }, function(){
                $scope.isSaving = false;
                layer.msg($translate.instant('global.save_fail'));
            })
        }
    }
})();