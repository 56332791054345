(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CrmReportDialogController', CrmReportDialogController);

    CrmReportDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'CrmReport'];

    function CrmReportDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, CrmReport) {
        var vm = this;

        vm.crmReport = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.files = [];

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.crmReport.reportTime = new Date();
            if (vm.crmReport.id !== null) {
                CrmReport.update(vm.crmReport, onSaveSuccess, onSaveError);
            } else {
                CrmReport.save(vm.crmReport, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('autopsApp:crmReportUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.reportTime = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
