(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BookingPriceDialogController', BookingPriceDialogController);

    BookingPriceDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'BookingPrice'];
    
    function BookingPriceDialogController($scope, $stateParams, $uibModalInstance, entity, BookingPrice) {

        $scope.bookingPrice = entity;
        $scope.load = function(id) {
            BookingPrice.get({id : id}, function(result) {
                $scope.bookingPrice = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('autopsApp:bookingPriceUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.bookingPrice.id != null) {
                BookingPrice.update($scope.bookingPrice, onSaveSuccess, onSaveError);
            } else {
                BookingPrice.save($scope.bookingPrice, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
}
})();
