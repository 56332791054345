(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('ops-news', {
            parent: 'account',
            url: '/ops-news?page&size',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'autopsApp.opsNews.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/ops-news/ops-news.html',
                    controller: 'OpsNewsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('opsNews');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('ops-news-detail', {
            parent: 'ops-news',
            url: '/{id}/detail',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ops-news/ops-news-detail.html',
                    controller: 'OpsNewsDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xl',
                    resolve: {
                        entity: ['OpsNews', 'WinAES', function(OpsNews, WinAES) {
                            return OpsNews.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('ops-news', null, { reload: 'ops-news' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('ops-news.new', {
            parent: 'ops-news',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ops-news/ops-news-dialog.html',
                    controller: 'OpsNewsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xl',
                    resolve: {
                        entity: function () {
                            return {
                                id: null,
                                companyId: null,
                                content: null,
                                subject: null,
                                announcementTime: null,
                                type: null,
                                refId: null,
                                timingRelease: null,
                                groupNews: null,
                                toSubsys: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('ops-news', null, { reload: 'ops-news' });
                }, function() {
                    $state.go('ops-news');
                });
            }]
        })
        .state('ops-news.edit', {
            parent: 'ops-news',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ops-news/ops-news-dialog.html',
                    controller: 'OpsNewsDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xl',
                    resolve: {
                        entity: ['OpsNews', 'WinAES', function(OpsNews, WinAES) {
                            return OpsNews.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('ops-news', null, { reload: 'ops-news' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('ops-news.delete', {
            parent: 'ops-news',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/ops-news/ops-news-delete-dialog.html',
                    controller: 'OpsNewsDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['OpsNews', 'WinAES', function(OpsNews, WinAES) {
                            return OpsNews.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('ops-news', null, { reload: 'ops-news' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
