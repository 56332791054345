(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BookingCustomDialogController', BookingCustomDialogController);

    BookingCustomDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', '$translate', '$timeout', 
        'entity', 'ExpressParcel', 'Dictionary'];
    
    function BookingCustomDialogController($scope, $stateParams, $uibModalInstance, $translate, $timeout, 
        entity, ExpressParcel, Dictionary) {

        $scope.param = entity;

        var onSaveSuccess = function (result) {
            layer.closeAll(); 
            layer.msg($translate.instant('global.syn_success')); 
            $scope.$emit('autopsApp:refreshHblsEtd', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var refFds = [];
        $timeout(function() {
            if($scope.param.isFms){
                refFds = Dictionary.refs;
            }
            else {
                refFds = Dictionary.refsPc;
            }
        })
        $scope.refContains = function (fd) {
            return refFds.indexOf(fd) !== -1;
        }

        var onSaveError = function (result) {
            layer.closeAll(); 
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            layer.load(1, {shade: 0.3});
            ExpressParcel.refreshHblsEtd($scope.param, onSaveSuccess, onSaveError);
        };
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
}
})();
