(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CustomConditionDetailController', CustomConditionDetailController);

    CustomConditionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CustomCondition'];

    function CustomConditionDetailController($scope, $rootScope, $stateParams, previousState, entity, CustomCondition) {
        var vm = this;

        vm.customCondition = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('autopsApp:customConditionUpdate', function(event, result) {
            vm.customCondition = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
