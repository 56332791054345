(function() {
    'use strict';

    angular
        .module('autopsApp')
        .directive('coloaderData', coloaderData);
    
    coloaderData.$inject = ['$parse','$http','$translate'];
    
function coloaderData($parse,$http,$translate) {
	
  return {
    restrict: 'AE',
    scope:{
    	   triggerReload: '&',
    	   errorList:'='
    },
    link: function(scope, element, attrs, ngModel) {
    	
      scope.hasFile = false;
      var model = $parse(attrs.coloaderData);
      var modelSetter = model.assign;
      
      scope.ridFile = function (){
    	  angular.forEach(angular.element("input[name='coloaderDataFile']"),function (inputElem) {
			  angular.element(inputElem).val(null);
    	  });
      }
      
      element.bind('change', function(event){
    	  
    	if(element[0].files[0]==undefined){
    		return;
    	}
        scope.$apply(function(){
          modelSetter(scope, element[0].files[0]);
        });
        
        var fd = new FormData();
        fd.append('coloaderDataFile', element[0].files[0]);
        $http.post('api/opsColoaderDataFile/', fd, {
        	transformRequest: angular.identity,
            headers: {'Content-Type': undefined},
        }).success(function(data){
        	  if(!data.success){
        		 $rootScope.OPS_ALERT(data.msg);
	      	  }
        	  scope.ridFile();
        	  scope.triggerReload();
        }).error(function(error){
        	if(error.msg == 'hasError'){
        		scope.errorList = error.errorList;
        		var height_ = '218px';
        		if(error.errorList.length > 3){
                    if (error.errorList.length <11){
                        height_ =   (150 +  38 * error.errorList.length) + 'px';
                    }
                    else {
                        height_ =   (150 +  38 * 10) + 'px';
                    }     
        		}
        		layer.open({
                    type: 1,
                    area: ['800px', height_],
                    moveOut: false,
                    title: $translate.instant('global.messageInfo'),
                    shade: 0.3,
                    moveType: 0,
                    shift: 0,
                    content: $("#errorListBox"),
                    btn: [$translate.instant('global.confirm')],
                    yes: function(index, layero) {
                        layer.close(index);
                    }
                });
        	}else{
        		$rootScope.OPS_ALERT(error.msg);
        	}
        	scope.ridFile();
        });
        
      });
    },
  };
}
})();