(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('EdiDataController',EdiDataController);

    EdiDataController.$inject = ['$rootScope', '$scope', '$uibModalInstance', '$timeout', '$translate', 
    'entity', 'ExpressParcel', 'AddressBook', 'DataUtils'];

    function EdiDataController($rootScope, $scope, $uibModalInstance, $timeout, $translate, 
        entity, ExpressParcel, AddressBook, DataUtils) {
        var vm = this;

        vm.expressParcel = entity;

        vm.clear = clear;
        vm.chooseService = chooseService;
        vm.getRates = getRates;
        vm.addOne = addOne;
        vm.removeOne = removeOne;

        vm.byChannel = false;
        function clear () {
            $uibModalInstance.dismiss('cancel');            
        }
        function chooseService (result) {
            $uibModalInstance.close(result);            
        }
        function addOne(argument) {
            vm.expressParcel.expressCargos.push({pkgs: 1});
        }
        function removeOne(idx) {
            vm.expressParcel.expressCargos.splice(idx, 1);
        }
        vm.ratesList = [];
        function getRates () {
            vm.isSaving = true;
            var ep = angular.copy(vm.expressParcel);
            if(!ep.expressCargos || ep.expressCargos.length == 0){
                ep.expressCargos = [{}];
            }            
            if(!vm.byChannel){
                ep.channel = null;
            }
            
            ExpressParcel.trialCost(ep, function(resultList){
                vm.isSaving = false;
                vm.ratesList = resultList;
                if (!vm.ratesList || vm.ratesList.length == 0){
                    layer.msg($translate.instant('global.noRecord'));
                }
                else {
                    getCheapestPrice();
                }
            }, function(data){
                vm.isSaving = false;
                $rootScope.ALERT_ERROR(data);
            })     
        }

        function getCheapestPrice(){
            vm.cheapestPrice = null;
            angular.forEach(vm.ratesList, function(rate){  
                if(!vm.cheapestPrice || rate.freight < vm.cheapestPrice){                    
                    vm.cheapestPrice = rate.freight;
                }
            })          
            vm.ratesList.sort(function(a,b){
                return Math.abs(a.freight)>Math.abs(b.freight)?1:-1;                  
            });

        }
        vm.isFromJob = true;
        $timeout(function() {
            if(entity && entity.booking){
                vm.expressParcel.shiptoCode = entity.booking.deliveryTo;
                vm.expressParcel.shiptoPostalCode = entity.shiptoPostcode;
                vm.expressParcel.shiptoCountryCode = entity.destCountryCode;
            }
            if(!vm.expressParcel){
                vm.expressParcel = {};
                vm.isFromJob = false;
            }
            else {
                if(vm.expressParcel.channel){
                    vm.byChannel = true;
                }
            }
            if(vm.expressParcel.expressCargos && vm.expressParcel.expressCargos.length > 0){
                // vm.expressParcel.expressCargos = [vm.expressParcel.expressCargos[0]];
            }
            else {
                vm.expressParcel.expressCargos = [{pkgs: 1}];
            }
        })
        $scope.addressBooks = [];
        $scope.loadAddressBooks = function() {
            if ($scope.addressBooks.length == 0 && !$scope.isSaving) {
                $scope.isSaving = true;
                AddressBook.getAll(function(result) {
                    $scope.addressBooks = result;
                    $scope.isSaving = false;
                });

            };
        };
        $scope._onAddressSelected = function(data) {
            vm.expressParcel.shiptoPostalCode = data.postCode;
            vm.expressParcel.shiptoCountryCode = data.countryCode;
            vm.expressParcel.shiptoProvinceCode = data.province;
            vm.expressParcel.shiptoCity = data.city;
        }
        $scope._onAddressSelected1 = function(data) {
            vm.expressParcel.shipperPostalCode = data.postCode;
            vm.expressParcel.shipperCountryCode = data.countryCode;
            vm.expressParcel.shipperProvinceCode = data.province;
            vm.expressParcel.shipperCity = data.city;
        }
        $scope.sizeChange = function(pl) {
            if(!pl.length || !pl.width || !pl.height){
                return;
            }
            pl.vol = pl.length * pl.width * pl.height;
            pl.vol = DataUtils.round(pl.vol/1000000, 4);
        }
    }
})();
