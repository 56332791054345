(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('ExpressCargo', ExpressCargo);

    ExpressCargo.$inject = ['$resource'];

    function ExpressCargo ($resource) {
        var resourceUrl =  'api/express-cargos/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'splitCargoCom': { 
                method:'PUT',
                url:'api/express-cargos/splitCargoCom'
            }, 
            'update': { method:'PUT' }
        });
    }
})();
