(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('StatParamsDialogController', StatParamsDialogController);

    StatParamsDialogController.$inject = ['$scope', '$rootScope', '$translate', '$uibModalInstance', '$timeout', 'OpsPorts',
    'OpsVender', 'OpsCompany', 'OpsCarrier', 'User', 'Guide', 'entity', 'OpsFiles', 'DateUtils', 'Principal', 'OpsCost', 'WmsWarehouse'];

    function StatParamsDialogController($scope, $rootScope, $translate, $uibModalInstance, $timeout, OpsPorts,
        OpsVender, OpsCompany, OpsCarrier, User, Guide, entity, OpsFiles, DateUtils, Principal, OpsCost, WmsWarehouse) {

        $scope.statParams = entity;
        $timeout(function() {
            if(entity.isDateObj){
                return;
            }
            if ($scope.statParams.statType == "irregular") {
                $scope.statParams.etdFM = DateUtils.convertLocalDateFromServer($scope.statParams.etdFM);
                $scope.statParams.etdTO= DateUtils.convertLocalDateFromServer($scope.statParams.etdTO);
            }
            if ($scope.statParams.statType == "year") {
                $scope.statParams.year= DateUtils.convertLocalDateFromServer($scope.statParams.year);
            }
            if ($scope.statParams.statType == "month") {
                $scope.statParams.month= DateUtils.convertLocalDateFromServer($scope.statParams.month);
            }
            if ($scope.statParams.statType == "day") {
                $scope.statParams.day= DateUtils.convertLocalDateFromServer($scope.statParams.day);
            }
            if(!$scope.statParams.salesList){
                $scope.statParams.salesList = [];
            }
            if(!$scope.statParams.docList){
                $scope.statParams.docList = [];
            }
            if(!$scope.statParams.finList){
                $scope.statParams.finList = [];
            }
            if(!$scope.statParams.marketList){
                $scope.statParams.marketList = [];
            }
            if(!$scope.statParams.overseasCsList){
                $scope.statParams.overseasCsList = [];
            }
            if(!$scope.statParams.bookingByList){
                $scope.statParams.bookingByList = [];
            }
            if(!$scope.statParams.truckByList){
                $scope.statParams.truckByList = [];
            }
            if(!$scope.statParams.customByList){
                $scope.statParams.customByList = [];
            }
            if(!$scope.statParams.csList){
                $scope.statParams.csList = [];
            }
            if(!$scope.statParams.opList){
                $scope.statParams.opList = [];
            }
        });
        $scope.initStatParams  = function() {
            $scope.statParams = {};
            $scope.statParams.statType = "month";
            $scope.statParams.month = new Date();
            $scope.statParams.filterRequired = true;
            initParamList();
        }
        function initParamList(){
            $scope.statParams.salesList = [];
            $scope.statParams.csList = [];
            $scope.statParams.opList = [];
            $scope.statParams.docList = [];
            $scope.statParams.finList = [];
            $scope.statParams.marketList = [];
            $scope.statParams.overseasCsList = [];
            $scope.statParams.bookingByList = [];
            $scope.statParams.truckByList = [];
            $scope.statParams.customByList = [];
        }
        $scope.confirm  = function() {
            if($scope.statParams.filterRequired != false){
                $scope.statParams.filterRequired = true;
            }
            if($scope.statParams.companyList && $scope.statParams.companyList.length > 0){
                $scope.statParams.companyIds = [];
                angular.forEach($scope.statParams.companyList, function(data){
                    if($scope.statParams.companyIds.indexOf(data.id) == -1){
                        $scope.statParams.companyIds.push(data.id);
                    }
                })
            }
            else {
                $scope.statParams.companyIds = null;
            }
            $scope.statParams.channel = $scope.statParams.deliveryChannel;
            $uibModalInstance.close($scope.statParams);
        };

        $scope.clear= function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.datePickerOpenStatus = {};
        $scope.yearFormat = "yyyy";
        $scope.monthFormat = "yyyy-MM";
        $scope.dayFormat = "yyyy-MM-dd";
        $scope.yearDatepickerOptions = {
            minMode: 'year'
        }
        $scope.monthDatepickerOptions = {
            minMode: 'month'
        }
        $scope.dayDatepickerOptions = {
            minMode: 'day'
        }

        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        $scope.companys = [];
        $scope.loadCompanys = function () {
            if($scope.companys && $scope.companys.length > 0){
                return;
            }
            if($rootScope.account.isSuper && $rootScope.HAVEAUTH('FUN_VIEW_GROUP_BIZ')){
                OpsCompany.getGroupCompanys(function(result){
                    $scope.companys = result;
                })
            }
        }
        $scope.setStatus= function(fd, value) {
            if($scope.statParams[fd] == value){
                $scope.statParams[fd] = null;
            }
            else {
                $scope.statParams[fd] = value;
            }
        }

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        }

        $scope.portList = [];
        var initPorts = function() {
            if($scope.portList.length > 0){
                return;
            }
            $scope.portList = $rootScope.OPSPORTS.seaports;
            angular.forEach($rootScope.OPSPORTS.airports, function(data){
                $scope.portList.push(data);
            });
        }
        $scope.loadPorts = function() {
            if ($rootScope.OPSPORTS.airports && $rootScope.OPSPORTS.airports.length > 0){
                initPorts();
                return;
            }
            OpsPorts.getAll(function(result){
                $rootScope.OPSPORTS.airports = [];
                $rootScope.OPSPORTS.seaports = [];
                angular.forEach(result, function(data){
                    if(data.type == 1){
                        $rootScope.OPSPORTS.seaports.push(data);
                    }
                    else {
                        $rootScope.OPSPORTS.airports.push(data);
                    }
                })
                initPorts();
            })
        };
        var clerkFilter = function(item, role) {
            if (item.authorities.indexOf(role) !== -1) {
                return true;
            }
            // if (item.authorities.indexOf(role) !== -1
            //     && $rootScope.account.authorities.indexOf("AU_STAT_ALL") !== -1) {
            //     return true;
            // }
            // if (item.authorities.indexOf(role) !== -1
            //     && $rootScope.account.authorities.indexOf("AU_STAT_BRANCH") !== -1
            //     && item.branch == $rootScope.account.branch) {
            //     return true;
            // }
            // if (item.authorities.indexOf(role) !== -1
            //     && $rootScope.account.authorities.indexOf("AU_STAT_TEAM") !== -1
            //     && item.team == $rootScope.account.team) {
            //     return true;
            // }
            if (item.authorities.indexOf(role) !== -1
                && item.id == $rootScope.account.id) {
                return true;
            }
            return false;
        };

        $scope.opFilter = function(item) {
            return clerkFilter(item, "ROLE_OPERATOR");
        };

        $scope.docFilter = function(item) {
            return clerkFilter(item, "ROLE_DOCUMENT");
        };
        $scope.salesFilter = function(item) {
            return clerkFilter(item, "ROLE_SALES");
        };
        $scope.csFilter = function(item) {
            return clerkFilter(item, "ROLE_CS");
        };
        $scope.overseasCusFilter = function(item) {
            return clerkFilter(item, "ROLE_OVERSEAS_CS");
        };
        $scope.bookingByFilter = function(item) {
            return clerkFilter(item, "BOOKING_BY") || clerkFilter(item, "ROLE_OPERATOR");
        };
        $scope.truckByFilter = function(item) {
            return clerkFilter(item, "TRUCK_BY") || clerkFilter(item, "ROLE_OPERATOR");
        };
        $scope.customByFilter = function(item) {
            return clerkFilter(item, "CUSTOM_BY") || clerkFilter(item, "ROLE_OPERATOR");
        };


        $scope.marketFilter = function(item) {
            return clerkFilter(item, "ROLE_MARKET");
        };
        $scope.finFilter = function(item) {
            return clerkFilter(item, "ROLE_ACCOUNT");
        };

        $scope.contracts = [];
        $scope.getallcontract= function() {
            if ($scope.contracts && $scope.contracts.length > 0) {
                return;
            };
            Guide.getallcontract(function(result) {
                $scope.contracts = result;
                $scope.statParams.contract = "";
            });
        };

        $scope.lanesList = [];
        $scope.getAllLanes= function() {
            if ($scope.lanesList && $scope.lanesList.length > 0) {
                return;
            };
            Guide.getAllLanes(function(result) {
                $scope.lanesList = result;
                $scope.statParams.lane = "";
            });
        };

        $scope.lanes = [];
        $scope.shipmentTypeChange = function(type) {
            $scope.lanes = [];
            if(!type){
                $scope.lanes = angular.copy($rootScope.sealanes);
                angular.forEach($rootScope.airlanes, function(data){
                    if(data.id.indexOf("Zone") == -1){
                        $scope.lanes.push(data);
                    }
                })
                return;
            }
            else if (type == "AIR") {
                $scope.lanes = angular.copy($rootScope.airlanes);
            } else {
                $scope.lanes = angular.copy($rootScope.sealanes);
            }

            if(!$scope.statParams.shipmentTypes){
                $scope.statParams.shipmentTypes = [];
            }
            if($scope.statParams.shipmentTypes.indexOf(type) == -1){
                $scope.statParams.shipmentTypes.push(type);
            }
            $scope.statParams.shipmentType = null;
        };
        $scope.shipmentTypeChange($scope.statParams.shipmentType);

        $scope.polRegionSelect = function(type) {
            if(!$scope.statParams.polAreas){
                $scope.statParams.polAreas = [];
            }
            angular.forEach($scope.lanes, function(data){
                if(data.id == type){
                    $scope.statParams.polAreas.push(data);
                }
            })
            $scope.statParams.polRegion = "";
        }
        $scope.podRegionSelect = function(type) {
            if(!$scope.statParams.podAreas){
                $scope.statParams.podAreas = [];
            }
            angular.forEach($scope.lanes, function(data){
                if(data.id == type){
                    $scope.statParams.podAreas.push(data);
                }
            })
            $scope.statParams.podRegion = "";
        }
        $scope.delFromList = function(dataList, index) {
            dataList.splice(index, 1);
        }
        $scope.mblServiceTermChange = function(data) {
            if(!$scope.statParams.mblServiceTerms){
                $scope.statParams.mblServiceTerms = [];
            }
            $scope.statParams.mblServiceTerms.push(data);
            $scope.statParams.mblServiceTerm = "";
        }
        $scope.hblServiceTermChange = function(data) {
            if(!$scope.statParams.hblServiceTerms){
                $scope.statParams.hblServiceTerms = [];
            }
            $scope.statParams.hblServiceTerms.push(data);
            $scope.statParams.hblServiceTerm = "";
        }
        $scope._onObjSelected = function(fd, data) {
            $scope.statParams[fd] = data.id;
        }
        $scope.productNames = [];
        $scope.getProductNames = function() {
            if ($scope.productNames.length > 0){
                return;
            }
            var companyIds = [];
            companyIds.push($rootScope.account.companyId);
            if($scope.statParams.companyList && $scope.statParams.companyList.length > 0){
                angular.forEach($scope.statParams.companyList, function(data){
                    if(companyIds.indexOf(data.id) == -1){
                        companyIds.push(data.id);
                    }
                })
            }
            OpsCost.getProductNames(companyIds, function (result) {
                $scope.productNames = result;
            });
        }

        $scope._onClerkSelected = function(fd, item, clerkList) {
            if(!clerkList){
                clerkList = [];
            }
            clerkList.push(item);
            $translate.refresh();
            $scope.statParams[fd] = "";
        };

        $scope._onSelected = function(fd, item) {
            if(!$scope.statParams[fd+"LST"]){
                $scope.statParams[fd+"LST"] = [];
            }
            $scope.statParams[fd+"LST"].push(item);
            $translate.refresh();
            $scope.statParams[fd] = "";
        };

        $scope.delClerk = function(clerks, index) {
            clerks.splice(index, 1);
        };

        $scope._onCompanySelected = function(company) {
            if(company.id == 0){
                $scope.statParams.companyList = [];
                angular.forEach($scope.companys, function(data){
                    if(data.id != 0){
                        $scope.statParams.companyList.push(data);
                    }
                })
                return;
            }
            if(!$scope.statParams.companyList){
                $scope.statParams.companyList = [];
            }
            angular.forEach($scope.companys, function(data){
                if(data.id == company.id){
                    var isNew = true;
                    angular.forEach($scope.statParams.companyList, function(data1){
                        if(data1.id == data.id){
                            isNew = false;
                        }
                    })
                    if(isNew){
                        $scope.statParams.companyList.push(data);
                    }
                }
            })
            $scope.statParams.company = "";
        };
        $scope.delCompany = function(index) {
            $scope.statParams.companyList.splice(index, 1);
        };

        $scope.warehouses = null;
        $scope.getAllWarehouses = function() {
            if ($scope.warehouses && $scope.warehouses.length > 0){
                return;
            }
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: true,
                    includeGroup: true
                }, function (result) {
                if(!result || result.length == 0){
                    layer.msg($translate.instant('global.messages.resultNull'));
                }
                $scope.warehouses = result;
            });
        }

    }
})();
