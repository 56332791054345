(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('si-submit', {
                parent: 'account',
                url: '/si-submit/{id}',
                data: {
                    authorities: [],
                    pageTitle: 'home.si-submit'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/account/si-submit/si-submit.html',
                        controller: 'SiSubmitController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    mainTranslatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('home');
                        $translatePartialLoader.addPart('guide');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Guide', function($stateParams, Guide) {
                        return Guide.getSiGuide({key : $stateParams.id}).$promise;
                    }]
                }
            });


    }
})();