(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ErrorController', ErrorController);

    ErrorController.$inject = ['$rootScope'];

    function ErrorController($rootScope) {
        $rootScope.LOAD_PIC_LOGO(false);
    }
})();
