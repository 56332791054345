(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CrmTaskController', CrmTaskController);

    CrmTaskController.$inject = ['$scope', '$state', '$rootScope', 'CrmTask', 'CrmTaskSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Principal'];

    function CrmTaskController ($scope, $state, $rootScope, CrmTask, CrmTaskSearch, ParseLinks, AlertService, pagingParams, paginationConstants, Principal) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})){
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.editTask = editTask;
        vm.viewTask = viewTask;
        vm.deleteTask = deleteTask;

        function editTask (task) {
            $rootScope.id = task.id;
            $state.go('crm-task.edit');
        }

        function viewTask (task) {
            $rootScope.id = task.id;
            $state.go('crm-task-detail');
        }
        function deleteTask (task) {
            $rootScope.id = task.id;
            $state.go('crm-task.delete');
        }

        loadAll();


        function loadAll () {
            if (pagingParams.search) {
                CrmTaskSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                CrmTask.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.crmTasks = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = true;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
    }
})();
