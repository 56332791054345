(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsCrmDialogController', OpsCrmDialogController);

    OpsCrmDialogController.$inject = ['$timeout', '$rootScope', '$scope', '$uibModalInstance', '$translate', 
    'entity', 'OpsVender', 'User', 'DataUtils'];

    function OpsCrmDialogController ($timeout, $rootScope, $scope, $uibModalInstance, $translate, 
        entity, OpsVender, User, DataUtils) {
        var vm = this;
        vm.clear = clear;
        $scope.opsClient = entity; 

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.setValue = function(fd, value) {
            $scope.opsClient[fd] = value;           
        }

        $scope.advanceSearch = function() {            
            vm.isSaving = true;
            OpsVender.getSearchResult({}, $scope.opsClient, function(result) {
                vm.isSaving = false;
                if (!result || result.length == 0){
                    layer.msg($translate.instant('global.messages.resultNull'));
                }
                $uibModalInstance.close(result);
            }, function(response) {
                vm.isSaving = false;
                if (response.status == 400){
                    layer.msg($translate.instant('global.forbidden'));
                }
                else {
                    layer.msg($translate.instant('global.requestFail'));
                }
            });
        };

        $scope._onClerkSelected = function(clerk, data) {
            $scope.opsClient[clerk] = data.id;
        }

        $scope.locations = [];
        $scope.getAllLocation= function() {
            OpsVender.getAllLocation(function(result) {
                $scope.locations = result;
            });
        };

    }
})();
