(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CustomDeclarationDialogController', CustomDeclarationDialogController);

    CustomDeclarationDialogController.$inject = ['$scope', '$rootScope', '$timeout', '$translate', '$uibModalInstance',
        'entity', 'DataUtils'
    ];

    function CustomDeclarationDialogController($scope, $rootScope, $timeout, $translate, $uibModalInstance, 
        entity, DataUtils) {
        var vm = this;

        vm.bCtn = entity;
        vm.clear = clear;
        vm.confirm = confirm;

        vm.chooseCountry = chooseCountry;
        vm.updateDuty = updateDuty;

        function chooseCountry (code) {
            vm.bCtn.importCountryCode = code;  
            vm.bCtn = DataUtils.getDuty(vm.bCtn);
        }

        function clear () {
            $uibModalInstance.dismiss('dismiss');                     
        }

        function confirm () {
            $uibModalInstance.close(vm.bCtn);                     
        }

        $timeout(function (){
            if($rootScope.account.authorities.indexOf('FBA') === -1){
                layer.msg($translate.instant('global.forbiddenFBA'));
                clear();
            }
            if(!vm.bCtn){
                vm.bCtn = {
                    importCountryCode: 'US',
                    vw: 1
                };
            }
            vm.bCtn = DataUtils.getDuty(vm.bCtn);
        });

        function updateDuty () {
            vm.bCtn = DataUtils.getDuty(vm.bCtn);
        }

        

    }
})();