(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('OpsFactorySearch', OpsFactorySearch);

    OpsFactorySearch.$inject = ['$resource'];
    
    function OpsFactorySearch($resource) {
        return $resource('api/_search/opsFactorys/:query', {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
