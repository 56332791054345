
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ExpressCargoDetailController', ExpressCargoDetailController);

    ExpressCargoDetailController.$inject = ['$scope', '$http', '$rootScope', '$uibModalInstance', '$timeout', '$translate', 
    'entity', 'DataUtils', 'OpsFiles'];
    
    function ExpressCargoDetailController($scope, $http, $rootScope, $uibModalInstance, $timeout, $translate, 
        entity, DataUtils, OpsFiles) {

        $scope.expressCargo = entity;
        $timeout(function() {
            $scope.updateVolGw();       
            getCargpPic();     
        });

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        $scope.updatePrice = function() {
            if($scope.expressCargo.quantity &&  $scope.expressCargo.usdValuePerUnit){
                $scope.expressCargo.usdValueTtl = DataUtils.round($scope.expressCargo.quantity * $scope.expressCargo.usdValuePerUnit, 2);
            }
        };
        $scope.updateVolGw = function() {
            if($scope.expressCargo.pkgs){
                if($scope.expressCargo.gw){
                    $scope.expressCargo.gwTtl = DataUtils.round($scope.expressCargo.pkgs * $scope.expressCargo.gw, 2);
                }
                if($scope.expressCargo.vol){
                    $scope.expressCargo.volTtl = DataUtils.round($scope.expressCargo.pkgs * $scope.expressCargo.vol, 4);
                }                
            }
        };
        $scope.updateVol = function() {
            if ($scope.expressCargo.length && $scope.expressCargo.width && $scope.expressCargo.height) {
                $scope.expressCargo.width = $scope.expressCargo.width.toFixed(4) - 0;
                $scope.expressCargo.length = $scope.expressCargo.length.toFixed(4) - 0;
                $scope.expressCargo.height = $scope.expressCargo.height.toFixed(4) - 0;
                $scope.expressCargo.vol = $scope.expressCargo.length * $scope.expressCargo.width * $scope.expressCargo.height / 1000000;
                $scope.expressCargo.vol = DataUtils.round($scope.expressCargo.vol, 4);
                if($scope.expressCargo.pkgs){
                    $scope.expressCargo.volTtl = DataUtils.round($scope.expressCargo.pkgs * $scope.expressCargo.vol, 4);
                }                
            }
        };
        function contains(file) {
            file = angular.lowercase(file);
            var regx  = new Array('jpg','png','jpeg','bmp','tiff');
            for(var m = 0 ; m < regx.length; m++){
                if(file == regx[m]){
                    return true;
                }
            }
            return false;
        }

        function getCargpPic() {
            var filePath = "/" + "expressCargo/" + $scope.expressCargo.id + "/cargoPic/";
            OpsFiles.getFilesList({
                filepath: filePath,
                companyId: $rootScope.account.companyId
            },function (data){
                if(data && data.length > 0){
                    $scope.expressCargo.cargoPics = data;
                }
            });
        }
        $scope.downLoadPic = function(cargoPic) {
            var aTag = document.createElement("a");
            aTag.href = cargoPic.fileurl;
            aTag.click();
        }
        $scope.deleOssFile = function(cargoPic, idx) {
            OpsFiles.delOssFile({
                filepath: cargoPic.filepath
            }, function(result) {
                $scope.expressCargo.cargoPics.splice(idx, 1);
                layer.msg($translate.instant('global.delete_success'));
            }, function(reject) {
                layer.msg($translate.instant('global.delete_fail'));
            }, function(error) {
                console.log(error);
            });
        }
        $scope.uploadPic = function(file) {
            if (!file) {
                return;
            } 
            var fileSplit = file.name.split(".");
            if(!contains(fileSplit[fileSplit.length-1])){
                layer.msg($translate.instant('global.logo_warn1'));
                return;
            }
            if(file.size>2040960){
                layer.msg($translate.instant('global.logo_warn2m'));
                return;
            }
            var fd = new FormData();            
            var filePath = "/" + "expressCargo/" + $scope.expressCargo.id + "/cargoPic/";
            fd.append('filepath', filePath);
            fd.append('file', file);
            fd.append('companyId', $rootScope.account.companyId);
            $http.post(
                'api/ops-files/uploadFile', fd, {
                    transformRequest : angular.identity,
                    headers : {
                        'Content-Type' : undefined
                    },
                }).success(function(data) {
                    if(!$scope.expressCargo.cargoPics){
                        $scope.expressCargo.cargoPics = [];
                    }
                    $scope.expressCargo.cargoPics.push(data);
                    $scope.cargoPic = data;
                    layer.msg($translate.instant('global.uploadSuccess'));
                }).error(function(error) {
                    layer.msg($translate.instant('global.uploadFail'));
            });
        };
}
})();
