(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('TmsRateInquiryController', TmsRateInquiryController);

    TmsRateInquiryController.$inject = ['$scope', '$rootScope', 'ExpressParcel', 'AddressBook', 'DataUtils', 'DateUtils'];

    function TmsRateInquiryController($scope, $rootScope, ExpressParcel, AddressBook, DataUtils, DateUtils) {
        var vm = this;
        vm.addOne = addOne;
        vm.removeOne = removeOne;
        vm.getLtlRates = getLtlRates;
        vm.updateTtl = updateTtl;
        vm.ratesResult = {};
        vm.getCityState = getCityState;

        vm.expressParcel = {
            etd: DateUtils.getNewDay(new Date(), 1),
            shipperCountryCode: "US",
            shiptoCountryCode: "US",
            pickupServices: [],
            deliveryServices: [],
            expressCargos: [{
                pkgs: 1,
                packageType: "PALLETS"
            }]
        };
        $scope.addressBooks = [];

        function addOne(argument) {
            vm.expressParcel.expressCargos.push({pkgs: 1, packageType: "PALLETS"});
        }
        function removeOne(fd, idx) {
            vm.expressParcel[fd].splice(idx, 1);
        }
        function updateTtl() {
            vm.expressParcel.gw = 0;
            vm.expressParcel.pkgs = 0;
            vm.expressParcel.vol = 0;
            angular.forEach(vm.expressParcel.expressCargos, function(data){
                if(data.pkgs){
                    vm.expressParcel.pkgs += data.pkgs;
                    if(data.gw){
                        data.gwTtl = DataUtils.round(data.gw * data.pkgs, 2);
                        vm.expressParcel.gw += data.gwTtl;
                    }
                    if(data.vol){
                        data.volTtl = DataUtils.round(data.vol * data.pkgs, 4);
                        vm.expressParcel.vol += data.volTtl;
                    }
                }
            })
            vm.expressParcel.gw = DataUtils.round(vm.expressParcel.gw, 2);
            vm.expressParcel.vol = DataUtils.round(vm.expressParcel.vol, 4);
        }
        $scope.isSaving = false;
        $scope.loadAddressBooks = function() {
            if ((!$scope.addressBooks || $scope.addressBooks.length == 0) && !$scope.isSaving) {
                $scope.isSaving = true;
                AddressBook.getAll(function(result) {
                    $scope.addressBooks = result;
                    $scope.isSaving = false;
                });
            };
        };
        $scope._onAddressSelected = function(data) {
            vm.expressParcel.shiptoPostalCode = data.postCode;
            vm.expressParcel.shiptoCountryCode = data.countryCode;
            vm.expressParcel.shiptoProvinceCode = data.province;
            vm.expressParcel.shiptoCity = data.city;
        }
        $scope._onAddressSelected1 = function(data) {
            vm.expressParcel.shipperPostalCode = data.postCode;
            vm.expressParcel.shipperCountryCode = data.countryCode;
            vm.expressParcel.shipperProvinceCode = data.province;
            vm.expressParcel.shipperCity = data.city;
        }
        vm.accessorialServices = [
            {value: 'SORTPU', pickup: true},
            {value: 'INPU', pickup: true},
            {value: 'CONPU', pickup: true},
            {value: 'CNVPU', pickup: true},
            {value: 'ELS_11', pickup: true},
            {value: 'ELS_13', pickup: true},
            {value: 'ELS_15', pickup: true},
            {value: 'ELS_17', pickup: true},
            {value: 'ELS_18', pickup: true},
            {value: 'ELS_19', pickup: true},
            {value: 'ELS_21', pickup: true},
            {value: 'ELS_22', pickup: true},
            {value: 'ELS_23', pickup: true},
            {value: 'ELS_24', pickup: true},
            {value: 'ELS_25', pickup: true},
            {value: 'ELS_26', pickup: true},
            {value: 'ELS_27', pickup: true},
            {value: 'ELS_28', pickup: true},
            {value: 'ELS_29', pickup: true},
            {value: 'ELS_30', pickup: true},
            {value: 'ELS_9', pickup: true},
            {value: 'AIRPU', pickup: true},
            {value: 'CFSPU', pickup: true},
            {value: 'CAMPPU', pickup: true},
            {value: 'CHRCPU', pickup: true},
            {value: 'CLUBPU', pickup: true},
            {value: 'EDUPU', pickup: true},
            {value: 'FARMPU', pickup: true},
            {value: 'GOVPU', pickup: true},
            {value: 'HOSPU', pickup: true},
            {value: 'HOTLPU', pickup: true},
            {value: 'PRISPU', pickup: true},
            {value: 'UTLPU', pickup: true},
            {value: 'PIERPU', pickup: true},
            {value: 'MILPU', pickup: true},
            {value: 'GROPU', pickup: true},
            {value: 'GROPU', pickup: true},
            {value: 'DOCKPU', pickup: true},
            {value: 'PFZ', pickup: false},
            {value: 'ELS_20', pickup: false},
            {value: 'ELS_16', pickup: false},
            {value: 'ELS_14', pickup: false},
            {value: 'ELS_12', pickup: false},
            {value: 'ELS_10', pickup: false},
            {value: 'ELS_8', pickup: false},
            {value: 'CNVDEL', pickup: false},
            {value: 'INDEL', pickup: false},
            {value: 'CONDEL', pickup: false},
            {value: 'SORTDEL', pickup: false},
            {value: 'CFSDEL', pickup: false},
            {value: 'FARMDEL', pickup: false},
            {value: 'AIRDEL', pickup: false},
            {value: 'CAMPDEL', pickup: false},
            {value: 'CHRCDEL', pickup: false},
            {value: 'CLUBDEL', pickup: false},
            {value: 'EDUDEL', pickup: false},
            {value: 'GOVDEL', pickup: false},
            {value: 'HOSDEL', pickup: false},
            {value: 'HOTLDEL', pickup: false},
            {value: 'PRISDEL', pickup: false},
            {value: 'UTLDEL', pickup: false},
            {value: 'PIERDEL', pickup: false},
            {value: 'MILDEL', pickup: false},
            {value: 'GRODEL', pickup: false},
            {value: 'RDNS', pickup: false},
            {value: 'RDTH', pickup: false},
            {value: 'RDRC', pickup: false},
            {value: 'RDWG', pickup: false},
            {value: 'MINEDEL', pickup: false}];



        $scope.serviceChoosed = function(fd, targetValue) {
            var serviceChoosed = false;
            angular.forEach(vm.expressParcel[fd], function(data){
                if(data.value == targetValue){
                    serviceChoosed = true;
                }                
            });
            return serviceChoosed;
        }
        $scope.addToServices = function(fd, service) {
            var serviceChoosed = false;
            angular.forEach(vm.expressParcel[fd], function(data){
                if(data.value == service.value){
                    serviceChoosed = true;
                }                
            });
            if(serviceChoosed){

            }
            else {
                vm.expressParcel[fd].push(service);
            }
        }


        vm.classesSet = [50, 55, 60, 65, 70, 77.5, 85, 92.5, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500];
        $scope.sizeChange = function(pl) {
            if(!pl.length || !pl.width || !pl.height){
                return;
            }
            pl.vol = pl.length * pl.width * pl.height;
            pl.vol = DataUtils.round(pl.vol/1000000, 4);
        }
        $scope.fillSuggestedClass = function(pl) {
            if(!pl.length || !pl.width || !pl.height || !pl.pkgs || !pl.gw){
                return;
            }
            ExpressParcel.fillSuggestedClass(pl, function(result){
                pl.suggestedClass = result.suggestedClass - 0;
                $translate.refresh();
            })
        }
        // vm.ratesResult = {
        //     quoteNo: "Q2405020503254",
        //     expressRates: [{
        //         totalFreight: "USD1433.4700",
        //         serviceLevel: "STANDARD",
        //         carrierName: "ABF Freight",
        //         carrierCode: "ABFS",
        //         serviceLevel: "MR",
        //         serviceLevelDescription: "Market Rate",
        //         laneType: "DIRECT",
        //         alertMsg: "R&L will not deliver Meijer DCs. NO VAPE PRODUCTS NO WAL-MART DCS in USA...Will handle WALMART DC's in Canada NO AMAZON DC's",
        //         mode: "LTL",
        //         effectiveDate: new Date(),
        //         expirationDate: new Date(),
        //         expirationDate: new Date(),
        //         deliveryDate: new Date(),
        //         transitDays: 1
        //     },{
        //         totalFreight: "USD3255",
        //         serviceLevel: "STANDARD",
        //         carrierName: "ABF Freight",
        //         carrierCode: "ABFS",
        //         serviceLevel: "MR",
        //         serviceLevelDescription: "Market Rate",
        //         laneType: "DIRECT",
        //         alertMsg: "NO COMMENTS",
        //         mode: "LTL",
        //         effectiveDate: new Date(),
        //         expirationDate: new Date(),
        //         expirationDate: new Date(),
        //         deliveryDate: new Date(),
        //         transitDays: 1
        //     }]
        // };
        function getLtlRates() {
            layer.load(1, { shade: 0.3 });
            ExpressParcel.getLtlRates(vm.expressParcel, function(result){
                layer.closeAll();
                vm.ratesResult = result;
            }, $rootScope.ALERT_ERROR);
        }
        function getCityState(zipCodeFd) {
            var entity = {
                shiptoPostalCode: vm.expressParcel[zipCodeFd]
            }
            if(!entity.shiptoPostalCode || entity.shiptoPostalCode.length < 5){
                return;
            }
            if(zipCodeFd == "shiptoPostalCode" && angular.uppercase(vm.expressParcel.shiptoCountryCode) != "US"){
                return;
            }
            if(zipCodeFd == "shipperPostalCode" && angular.uppercase(vm.expressParcel.shipperCountryCode) != "US"){
                return;
            }
            ExpressParcel.getCityState(entity, function(result){
                if(zipCodeFd == "shipperPostalCode"){
                    if(result.shiptoCity){
                        vm.expressParcel.shipperCity = result.shiptoCity;
                    }
                    if(result.shiptoProvinceCode){
                        vm.expressParcel.shipperProvinceCode = result.shiptoProvinceCode;
                    }
                }
                else if(zipCodeFd == "shiptoPostalCode"){
                    if(result.shiptoCity){
                        vm.expressParcel.shiptoCity = result.shiptoCity;
                    }
                    if(result.shiptoProvinceCode){
                        vm.expressParcel.shiptoProvinceCode = result.shiptoProvinceCode;
                    }
                }
            }, $rootScope.ALERT_ERROR);
        }


    }
})();
