(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('PrebookingDialogController', PrebookingDialogController);

    PrebookingDialogController.$inject = ['$rootScope', '$uibModal', '$location', '$translate', 
    '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'WinAES','OpsPorts', 'OpsCarrier',
    'Principal', 'entity', 'Prebooking', 'Prediction', 'OpsVender'];

    function PrebookingDialogController ($rootScope, $uibModal, $location, $translate, 
        $timeout, $scope, $stateParams, $uibModalInstance, WinAES, OpsPorts, OpsCarrier,
        Principal, entity, Prebooking, Prediction, OpsVender) {
        if (!$rootScope.account || $rootScope.account == {}){
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };

        var vm = this;

        vm.prebooking = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm._onColoaderSelected = _onColoaderSelected;
        vm.portList = [];
        vm.editPrediction = editPrediction;
        vm.delPrediction = delPrediction;
        vm.allotTeus = allotTeus;
        vm.generateGuide = generateGuide;
        vm.refreshStatus = refreshStatus;
        vm.predictions = [];
        vm.updateTtl = updateTtl;

        function refreshStatus () {
            if (vm.isSaving){
                return;
            }
            vm.isSaving = true;
            Prediction.refreshStatus({companyId: $rootScope.account.companyId, prebookingId: vm.prebooking.id}, function (result){
                vm.predictions = result;
                angular.forEach(vm.predictions, function(data){
                    if (data.guideId){
                        data.encodeId = WinAES.encode(data.guideId.toString());
                    }                    
                })
                updateTtl();
                vm.isSaving = false;
                layer.msg($translate.instant('autopsApp.prebooking.refreshed'));                    
            });
        }

        function allotTeus(prediction) {
            if($rootScope.account.id != vm.prebooking.marketId && $rootScope.HAVENOAUTH('AU_ALLOT_SPACE')){
                return;
            }
            if(prediction.status == "Voided" || prediction.status == "Cancelled"){
                return;
            }
            var predictionCopy = angular.copy(prediction);
            if(!predictionCopy.soNum){
                predictionCopy.soNum = vm.prebooking.bookingNum;
            }            
            $uibModal.open({
                templateUrl: 'app/entities/prediction/prediction-delete-dialog.html',
                controller: 'PredictionDeleteController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: ['Prediction', function(Prediction) {
                        return predictionCopy;
                    }]
                }
            }).result.then(function(result) {
                angular.copy(result, prediction);
                updateTtl();
            }, function() {
            });
        }

        function editPrediction(prediction) {
            if(!vm.prebooking.id){
                layer.msg($translate.instant('autopsApp.prebooking.notSaveAlert'));
                return;
            }
            if (!prediction){
                prediction = {
                    prebookingId: vm.prebooking.id,
                    createrId: $rootScope.account.id,
                    creater: $rootScope.account.firstName + " " + $rootScope.account.lastName
                };
            }
            $uibModal.open({
                templateUrl: 'app/entities/prediction/prediction-dialog.html',
                controller: 'PredictionDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return prediction;
                    }]
                }
            }).result.then(function(result) {
                var isNew = true;
                angular.forEach(vm.predictions, function(data){
                    if (data.id == prediction.id){
                        data = prediction;
                        isNew = false;
                    }   
                })
                if(isNew){
                    vm.predictions.push(result);
                }
                updateTtl();
            }, function(result) {

            });
        }

        function delPrediction (prediction) {
            if (prediction.id != null) {
                Prediction.delete({
                    id: prediction.id
                });
            };
            var index = vm.predictions.indexOf(prediction);
            if (index > -1) {
                vm.predictions.splice(index, 1);
            };
            updateTtl();
        };

        function updateTtl () {
            if (!vm.predictions || vm.predictions.length == 0){
                return;
            }
            if (vm.prebooking.getTeus){
                var getTeusTtl = 0;
                angular.forEach(vm.predictions, function(data){
                    if (data.getTeus && data.status != "Voided" && data.status != "Cancelled"){
                        getTeusTtl = getTeusTtl + data.getTeus;
                    }   
                })
                vm.prebooking.availableTeus = vm.prebooking.getTeus - getTeusTtl;
            }
        };


        $scope.loadPorts = function() {
            if ($rootScope.OPSPORTS.airports && $rootScope.OPSPORTS.airports.length > 0){
                vm.portList = $rootScope.OPSPORTS.seaports;
                return;
            }
            OpsPorts.getAll(function(result){
                $rootScope.OPSPORTS.airports = [];
                $rootScope.OPSPORTS.seaports = [];
                angular.forEach(result, function(data){
                    if(data.type == 1){
                        $rootScope.OPSPORTS.seaports.push(data);
                    }
                    else {
                        $rootScope.OPSPORTS.airports.push(data);
                    }
                })
                vm.portList = $rootScope.OPSPORTS.seaports;
            })
        };

        var prebookingCopy = {};
        $timeout(function (){
            var path = $location.path();
            if (path.indexOf("copy") >= 0) {
                vm.prebooking.id = null;
                vm.prebooking.market = null;
                vm.prebooking.marketId = null;
                vm.prebooking.companyId = null;
                vm.prebooking.bookingNum = null;
                vm.prebooking.vsl = null;
                vm.prebooking.voy = null;
                vm.prebooking.etd = null;
                vm.prebooking.getTeus = null;
                vm.prebooking.availableTeus = null; 
                prebookingCopy = angular.copy(vm.prebooking);
            }
            else if (vm.prebooking.id){
                Prediction.get({id : vm.prebooking.id}, function(result){
                    vm.predictions = result;
                    angular.forEach(vm.predictions, function(data){
                        if (data.guideId){
                            data.encodeId = WinAES.encode(data.guideId.toString());
                        }      
                    })
                    updateTtl();
                })
                prebookingCopy = angular.copy(vm.prebooking);
            }
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if(angular.equals(vm.prebooking, prebookingCopy)){
                $uibModalInstance.close(vm.prebooking);
                return;
            }
            if (vm.prebooking.id !== null) {
                Prebooking.update(vm.prebooking, onSaveSuccess, onSaveError);
            } else {
                Prebooking.save(vm.prebooking, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('autopsApp:prebookingUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.etd = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function _onColoaderSelected (data) {
            vm.prebooking.bookingAgentId = data.id;
            vm.prebooking.bookingAgentName = data.companyName;
        }
        
        function generateGuide (prediction) {
            if (vm.isSaving){
                return;
            }
            vm.isSaving = true;
            Prediction.generateGuide(prediction, function (result) {
                prediction.guideId = result.guideId;
                prediction.encodeId = WinAES.encode(result.guideId.toString());
                prediction.jobNum = result.jobNum;
                vm.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));                    
            });            
        }        

    }
})();
