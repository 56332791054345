(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ExpressTrackingController', ExpressTrackingController);

    ExpressTrackingController.$inject = ['$scope', '$rootScope', '$translate', '$uibModal', '$http', '$timeout', '$uibModalInstance', 
    'OpsTrackStatus', 'DateUtils', 'entity', 'ExpressTracking', 'ExpressParcel', 'OpsFiles', 'SubTracking'];

    function ExpressTrackingController ($scope, $rootScope, $translate, $uibModal, $http, $timeout, $uibModalInstance, 
        OpsTrackStatus, DateUtils, entity, ExpressTracking, ExpressParcel, OpsFiles, SubTracking) {
        var vm = this;
        vm.guide = entity.guide;
        vm.isExpress =  entity.isExpress;  
        vm.moveList = [];
        vm.tkResult = {};
        vm.clear = clear;
        vm.loadTracking = loadTracking;
        vm.isOp = isOp;
        function isOp () {
            var authorities = ["ROLE_BOSS", "ROLE_OPERATOR", "ROLE_DOCUMENT", "ROLE_OVERSEAS_CS", "ROLE_CS"];
            if (!$rootScope.account || !$rootScope.account.companyId || angular.equals($rootScope.account, {})) {
                return false;
            }
            for (var i = 0; i < authorities.length; i++) {
                if ($rootScope.account.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }
            return false;
        };

        $timeout(function (){
            if(vm.guide.agentGuideId){
                vm.guide.id = vm.guide.agentGuideId;
            }         
            loadBothTracking();
        })

        function isMasterShipment () {
            if(vm.guide.masterId == 0 || vm.guide.isMaster || vm.guide.parcelMaster){
                return true;
            }
            return false;
        }

        $scope.isLoading= false;
        vm.subTrackings = null;
        function getHeadTracking () {
            $scope.isLoading= true;
            ExpressTracking.getHeadTracking({
                guideId: vm.isExpress?null:vm.guide.id,
                expressId: vm.isExpress?vm.guide.id:null
            }, function (result) {
                $scope.isLoading= false;
                vm.tkResult = result;
                vm.headNodes = result.headNodes;
                vm.moveList = result.trackings;
                // vm.moveList.sort(function(a,b){
                //     return DateUtils.compareDate(a.time, b.time);            
                // });
                loadTrackingFiles();
            }, function (result) {
                $scope.isLoading= false;
            })
        }
        
        $scope.showSubtracking = false;
        function getSubTrackings () {
            if(!vm.guide.mblNum 
                || (!$rootScope.ISFBAPARCEL(vm.guide.shipmentType) && vm.guide.shipmentType != 'LOCALKG')){
                return;
            }
            $scope.showSubtracking = true;
            var loadIndex = layer.load(1, {shade: 0.3});
            $scope.isLoading= true;
            var carrierCode;
            var trackingNum = vm.guide.mblNum;
            if(vm.isExpress){
                if(vm.guide.labelBindType == "RPDL"){
                    carrierCode = "RPDL";
                }
                else {
                    carrierCode = vm.guide.carrier;
                }                
            }
            else {
                if($rootScope.IS_ZJSC()){
                    if(vm.guide.deliveryChannel && vm.guide.deliveryChannel.toLowerCase().indexOf("imile") != -1){
                        carrierCode = "IMILE";
                    }
                    else if(vm.guide.booking && vm.guide.booking.carrierFullName && vm.guide.booking.carrierFullName.toLowerCase().indexOf("imile") != -1){
                        carrierCode = "IMILE";
                    }                    
                }
                else if (vm.guide.booking && vm.guide.booking.labelBindType  == "RPDL"){
                    carrierCode = "RPDL";
                }
                else {
                    carrierCode = vm.guide.deliveryChannel;
                }
            }
            ExpressTracking.getSubTrackings({
                carrierCode: carrierCode,
                trackingNum: trackingNum,
                guideId: vm.isExpress?null:vm.guide.id,
                parcelId: vm.isExpress?vm.guide.id:null,
                companyId: $rootScope.account.companyId,
                isMaster: isMasterShipment(),
                isMps: vm.guide.pkgNum && vm.guide.pkgNum > 1
            }, function (result) {
                $scope.isLoading= false;
                if(result.state){
                    vm.tkResult = result;
                }                
                vm.subTrackings = result.subTrackings;                
                layer.close(loadIndex);  
                loadAllTrackingNums();
            }, function (result) {
                $scope.isLoading= false;
                layer.close(loadIndex);
            })
        }

        function loadAllTrackingNums () {    
            if(!vm.guide.id){
                return;
            }
            SubTracking.getTrackingSubList({
                guideId: vm.isExpress?null:vm.guide.id,
                parcelId: vm.isExpress?vm.guide.id:null,
                isMaster: isMasterShipment()
            }, function(results){
                if(!results){
                    return;
                }
                results.push({tracking: vm.guide.mblNum});

                if(!vm.subTrackings){
                    vm.subTrackings = [];
                }
                angular.forEach(results, function(data){
                    var isNew = true;
                    angular.forEach(vm.subTrackings, function(tracking){
                        if(data.tracking == tracking.trackingNum){
                            isNew = false;
                        }
                    })
                    if(isNew){
                        var newSubTracking = {
                            trackingNum: data.tracking,
                            context: data.deliveried?"Deliveryed":null,
                            state: data.deliveried?'3':null
                        };
                        vm.subTrackings.push(newSubTracking);
                    }
                })
                loadAllTracking();
            });
        };

        function loadAllTracking () {   
            angular.forEach(vm.subTrackings, function(tracking){
                if(!tracking.context){
                    ExpressTracking.kd100Tracking({
                        carrier : vm.isExpress?vm.guide.carrier:vm.guide.deliveryChannel, 
                        carrierCode : vm.guide.booking?vm.guide.booking.carrierCode:vm.guide.carrierCode, 
                        trackingNum : tracking.trackingNum
                    }, function(result){
                        tracking.trackings = result.trackings;
                        tracking.state = result.state;
                        if(result.trackings && result.trackings.length > 0){
                            tracking.context = result.trackings[result.trackings.length - 1].context;
                            tracking.time = result.trackings[result.trackings.length - 1].time;
                        }
                    })
                }
            })
        }

        function loadBothTracking () {
            getHeadTracking();
            getSubTrackings();
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onTrackingFailed(result){
            $scope.isLoading= false;
            if (result.status == 409){
                layer.msg($translate.instant('autopsApp.expressTracking.notExpressAlert'));                    
            }
            else if(result.status == 403){
                layer.msg($translate.instant('autopsApp.expressTracking.noCarrierAlert'));  
            }
            else {
                layer.msg($translate.instant('autopsApp.expressTracking.trackingFailed'));  
            }
        }
        function loadTracking () {
            if(!vm.guide.mblNum){
                return;
            }
            ExpressTracking.kd100Tracking({
                carrier : vm.isExpress?vm.guide.carrier:vm.guide.deliveryChannel, 
                carrierCode : vm.guide.booking?vm.guide.booking.carrierCode:vm.guide.carrierCode, 
                trackingNum : vm.guide.mblNum
            }, function(result){
                fullTrackingPop(result);
            }, onTrackingFailed)
        }

        $scope.getOneTracking = function(subTracking) {
            if(subTracking.trackings && subTracking.trackings.length > 0){
                subTracking.trackings = subTracking.trackings;
                subTracking.nu = subTracking.trackingNum;
                fullTrackingPop(subTracking);
                return;
            }
            if(!subTracking.trackingNum){
                return;
            }
            ExpressTracking.kd100Tracking({
                carrier : vm.isExpress?vm.guide.carrier:vm.guide.deliveryChannel, 
                carrierCode : vm.guide.booking?vm.guide.booking.carrierCode:vm.guide.carrierCode, 
                trackingNum : subTracking.trackingNum
            }, function(result){
                fullTrackingPop(result);
            }, onTrackingFailed)
        }

        // $scope.getAirTracking = function() {
        //     if($rootScope.HAVENOAUTH('TRACKING')){
        //         $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
        //         return;
        //     }
        //     if(!vm.guide.mblNum){
        //         layer.msg($translate.instant('autopsApp.guide.home.mawbInvalid'));
        //         return;
        //     }
        //     var mawb = vm.guide.mblNum;
        //     mawb = mawb.replace("/\s+/g", "");
        //     mawb = mawb.replace("-", "");
        //     if(mawb.length != 11){
        //         layer.msg($translate.instant('autopsApp.guide.home.mawbInvalid'));
        //         return;
        //     }
        //     ExpressTracking.airTracking({
        //         awb : vm.guide.mblNum
        //     }, function(result){
        //         fullTrackingPop(result);
        //     }, onTrackingFailed)
        // }

        function fullTrackingPop (result) {
            $uibModal.open({
                templateUrl: 'app/entities/express-tracking/express-tracking-delete-dialog.html',
                controller: 'ExpressTrackingDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return result;
                    }]
                }
            }).result.then(function(result) {
            }, function() {
            });
        }

        $scope.go17track = function(trackNum) {
            if(!trackNum){
                return;
            }
            var absUrl = "https://t.17track.net/zh-cn?v=2#nums=" + trackNum;
            window.open(absUrl);       
        };
        $scope.showSub = function(trackNum) {
            if(!trackNum){
                return;
            }
            var absUrl = "https://t.17track.net/zh-cn?v=2#nums=" + trackNum;
            window.open(absUrl);       
        };

        function loadTrackingFiles() {
            if(!vm.moveList || vm.moveList.length == 0 || !vm.guide.companyId){
                return;
            }
            angular.forEach(vm.moveList, function(tracking){
                if(tracking.id){
                    tracking.files = [];
                    $rootScope.LOADFILES(tracking.files, '/tracking/' + (tracking.refId?tracking.refId:tracking.id) + '/', vm.guide.companyId)
                    $translate.refresh();
                }
            })
        };

    }
})();
