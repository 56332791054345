(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('PoController', PoController);

    PoController.$inject = ['$scope', '$state', '$rootScope', '$uibModal', '$translate', '$http', '$location', '$timeout', 'DataUtils', 'Guide',
    'WinAES', 'Po', 'PoSearch', 'uiGridConstants', 'AlertService', 'Principal', 'OpsFiles', 'GridState', 'WmsWarehouse'];

    function PoController ($scope, $state, $rootScope, $uibModal, $translate, $http, $location, $timeout, DataUtils, Guide,
        WinAES, Po, PoSearch, uiGridConstants, AlertService, Principal, OpsFiles, GridState, WmsWarehouse) {

        var vm = this;

        vm.predicate = 'id';
        vm.direction = 'desc';
        vm.page = 1;
        vm.itemsPerPage = 20;

        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = null;
        vm.currentSearch = null;

        function loadAll() {
            if (vm.searchQuery) {
                PoSearch.query({
                    query: vm.searchQuery,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Po.query({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                var result = [(vm.predicate.endWith('TAG')?vm.predicate.replace('TAG', ''):vm.predicate) + ',' + vm.direction];
                return result;
            }

            function onSuccess(data, headers) {
                if(headers('X-Total-Count')){
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                }
                else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                initData();
                $scope.gridOptions.useExternalSorting = true;
                $scope.gridOptions.showColumnFooter = false;
                resize();
                $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
                $scope.isAdvanceSearch = false;
            }

            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }
        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.page = 1;
            vm.predicate = '_score';
            vm.direction = 'desc';
            vm.currentSearch = searchQuery;
            loadAll();
        }
        function clear() {
            vm.page = 1;
            vm.predicate = 'id';
            vm.direction =  'desc';
            vm.searchQuery = vm.currentSearch = null;
            $scope.isAdvanceSearch = false;
            loadAll();
        }
        loadAll();

        window.onresize = function(){
            resize();
        }

        function resize() {
            var tabHeight = $scope.gridOptions.data.length * 30 + 190;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 300){
                tabHeight = 300;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        function initData() {
            angular.forEach($scope.gridOptions.data, function(data){
                if(data.id){
                    data.codeId = WinAES.encode(data.id.toString());
                }
                if(data.gw){
                    data.gw = data.gw.toFixed(2) - 0;
                }
                if(data.vol){
                    data.vol = data.vol.toFixed(2) - 0;
                }
            })
            if(vm.page != 1){
                return;
            }
            angular.forEach($scope.gridOptions.columnDefs, function(col, index){
                if(index == 2 || index == 3 || index == 4){
                    if($rootScope.account.companyId){
                        col.visible = true;
                    }
                    else {
                        col.visible = false;
                    }
                }
                if(index == 24){
                    if($scope.isFms){
                        col.visible = true;
                    }
                    else {
                        col.visible = false;
                    }
                }
            })
        }

        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: !$scope.isFms,

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: false,//每列footer

            enableFiltering: true,//高级过滤

            enableHorizontalScrollbar : 1,//表格的水平滚动条
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            exporterMenuCsv : false,
            exporterMenuPdf : false,
            exporterMenuLabel : "Export",
            exporterExcelFilename: 'poSheet.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],

            paginationPageSizes: [20, 35, 50],
            paginationPageSize: 20,
            useExternalPagination: true,

            minimumColumnSize: 70,
            columnDefs: [
                { field: 'id',
                    width: 130,
                    displayName: "",
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<div class="btn-group flex-btn-group-container"ng-click="grid.appScope.setChoosedId(row.entity.id)"><button type="button"ui-sref="purchase-order.copy({id:row.entity.codeId})"class="btn btn-info btn-grid"><span class="glyphicon glyphicon-duplicate"></span><span class="hidden-xs hidden-sm ml-2"translate="entity.action.copy"></span></button><button type="button"ui-sref="purchase-order.edit({id:row.entity.codeId})"class="btn btn-primary btn-grid"><span class="glyphicon glyphicon-edit"></span><span class="hidden-xs hidden-sm ml-2"translate="autopsApp.wmsIn.poOrder"></span></button></div>'},
                { field: 'poNum',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.wmsIn.poNum'),
                    cellTemplate: '<span class="grid-text"ng-click="grid.appScope.setChoosedId(row.entity.id)"ng-class="(row.entity.id==grid.appScope.choosedId && row.entity.id)?\'choosedColor\':\'\'"><a href=""ui-sref="purchase-order.edit({id:row.entity.codeId})">{{row.entity.poNum}}</a></span>'},
                { field: 'poVender',
                    displayName: $translate.instant('autopsApp.wmsIn.poVender'),
                    minWidth:200},
                { field: 'poDate',
                    displayName: $translate.instant('autopsApp.wmsIn.poDate'),
                    minWidth:120},
                { field: 'applicationNum',
                    displayName: $translate.instant('autopsApp.wmsIn.purchaseAmount'),
                    minWidth:100,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'poValue',
                    displayName: $translate.instant('autopsApp.wmsIn.poValue'),
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    minWidth:100,
                    cellTemplate: '<strong class="grid-text text-primary" ng-if="row.entity.poValue">{{grid.appScope.account.baseCurrency}}{{row.entity.poValue}}</strong>'},
                { field: 'gw',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsIn.gwTtl'),
                    minWidth:100},
                { field: 'vol',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsIn.volTtl'),
                    minWidth:100},
                { field: 'cargoDescription',
                    displayName: $translate.instant('autopsApp.wmsIn.cargoDescription'),
                    minWidth:100},
                { field: 'mark',
                    displayName: $translate.instant('autopsApp.wmsIn.mark'),
                    minWidth:100},
                { field: 'poUser',
                    displayName: $translate.instant('autopsApp.wmsIn.poUser'),
                    minWidth:100},
                { field: 'warehousingNumber',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.wmsIn.warehousingNumber')},
                { field: 'updateTime',
                    displayName: $translate.instant('autopsApp.wmsIn.updateTime'),
                    minWidth:120,
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.updateTime | date:\'medium\'}}">{{row.entity.updateTime | date:\'mediumDate\'}}</span>'}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 94;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                    if(!$scope.gridOptions.useExternalSorting){
                        if(sortColumns[sortColumns.length - 1]){
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function(a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    if(sortColumns[sortColumns.length - 1]){
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    }
                    loadAll();
                });
                gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                    vm.page = newPage;
                    vm.itemsPerPage = pageSize;
                    loadAll();
                });
            }
        }
        $scope.choosedId = null;
        $scope.setChoosedId = function(id){
            $scope.choosedId = id;
        }
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 94
            }, function(result){
                gridStateObj = result;
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
            })
        });

        var unsubscribe = $rootScope.$on('autopsApp:poUpdate', function(event, result) {
            var isNew = true;
            angular.forEach($scope.gridOptions.data, function(data){
                if(data.id && data.id == result.id){
                    angular.copy(result, data);
                    data.codeId = WinAES.encode(data.id.toString());
                    isNew = false;
                }
            })
            if(isNew){
                loadAll();
            }
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
