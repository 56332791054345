(function() {
    'use strict';

    angular
        .module('autopsApp')
        .directive('ctnvalidator', ctnvalidator);

    ctnvalidator.$inject = ['$parse', '$translate', '$rootScope'];

    function ctnvalidator($parse, $translate, $rootScope) {

        var letters = {
            A: '10',
            B: '12',
            C: '13',
            D: '14',
            E: '15',
            F: '16',
            G: '17',
            H: '18',
            I: '19',
            J: '20',
            K: '21',
            L: '23',
            M: '24',
            N: '25',
            O: '26',
            P: '27',
            Q: '28',
            R: '29',
            S: '30',
            T: '31',
            U: '32',
            V: '34',
            W: '35',
            X: '36',
            Y: '37',
            Z: '38'
        }

        var multiplyNums = {
            1: 1,
            2: 2,
            3: 4,
            4: 8,
            5: 16,
            6: 32,
            7: 64,
            8: 128,
            9: 256,
            10: 512
        }

        var reg = /(^[A-Z]{4})([0-9]{7})/;


        return {
            restrict: 'AE',
            scope: {
                validatedResult: '='
            },
            link: function(scope, element, attrs, ngModel) {
                scope.validatedResult = false;
                var model = $parse(attrs.ctnValidator);
                var modelSetter = model.assign;

                element.bind('blur', function(event) {

                    if (element[0].value.length == 0) {
                        return false;
                    }


                    if (element[0].value.length == 11) {
                        try {
                            var ctnNum = element[0].value;

                            ctnNum = ctnNum.replace(/\s/g, "");

                            if (reg.test(ctnNum) === false) {
                                $rootScope.OPS_ALERT($translate.instant('entity.action.ctnsNumError'));
                                scope.validatedResult = false;
                                return false;
                            }

                            var a_letter = 0;
                            var b_letter = 0;
                            var c_letter = 0;
                            var toValidateNum = parseInt(ctnNum[10]);

                            //字母转成数字后加上后6位数字的数组
                            var tobeCalucateNums = new Array();

                            var ctnLetter = ctnNum.substring(0, 4);

                            tobeCalucateNums[0] = letters[ctnLetter[0]];
                            tobeCalucateNums[1] = letters[ctnLetter[1]];
                            tobeCalucateNums[2] = letters[ctnLetter[2]];
                            tobeCalucateNums[3] = letters[ctnLetter[3]];

                            var ctnNums = ctnNum.substring(4, 10);

                            tobeCalucateNums[4] = ctnNums[0];
                            tobeCalucateNums[5] = ctnNums[1];
                            tobeCalucateNums[6] = ctnNums[2];
                            tobeCalucateNums[7] = ctnNums[3];
                            tobeCalucateNums[8] = ctnNums[4];
                            tobeCalucateNums[9] = ctnNums[5];


                            for (var i = 0; i < tobeCalucateNums.length; i++) {
                                a_letter = a_letter + (tobeCalucateNums[i] * multiplyNums[i + 1]);
                            }

                            a_letter = a_letter / 11;


                            var a_letter_temp = "0" + String(a_letter).substring(String(a_letter).indexOf('.', 0), a_letter.length);


                            b_letter = parseFloat(a_letter_temp) * 11;

                            c_letter = Math.round(b_letter);

                            if (c_letter >= 10) {
                                c_letter = c_letter - 10;
                            }

                            if (toValidateNum === c_letter) {
                                scope.validatedResult = true;
                                return true;
                            } else {
                                scope.validatedResult = false;
                                $rootScope.OPS_ALERT($translate.instant('entity.action.ctnsNumError'));
                                return false;
                            }

                        } catch (error) {
                            scope.validatedResult = false;
                            $rootScope.OPS_ALERT($translate.instant('entity.action.ctnsNumError'));
                            return false;
                        }
                    } else {
                        scope.validatedResult = false;
                        $rootScope.OPS_ALERT($translate.instant('entity.action.ctnsNumError'));
                        return false;
                    }
                });
            },
        };
    }
})();
