(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsInOutDetailController', WmsInOutDetailController);

    WmsInOutDetailController.$inject = ['$scope', '$state', '$rootScope', '$http', '$translate', '$timeout', '$location', '$uibModalInstance', 'OpsPorts', 'entity',
    '$stateParams', '$uibModal', 'WinAES', 'WmsIn', 'WmsCargo', 'Principal', 'OpsItem', 'WmsWorkLog', 
    'OpsFiles', 'WmsWarehouse', 'DateUtils', 'DataUtils'];

    function WmsInOutDetailController($scope, $state, $rootScope, $http, $translate, $timeout, $location, $uibModalInstance, OpsPorts, entity,
        $stateParams, $uibModal, WinAES, WmsIn, WmsCargo, Principal, OpsItem, WmsWorkLog, 
        OpsFiles, WmsWarehouse, DateUtils, DataUtils) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };

        var vm = this;

        vm.wmsIn = entity;
        vm.save = save;          
        vm.send = send;  
        vm.resend = resend;  
        vm.accept = accept; 
        vm.decline = decline;   
        vm.clients = [];   
        vm.clientSelected = clientSelected;
        vm.warehouseSelected = warehouseSelected;
        vm.delFromChoosed = delFromChoosed;
        vm.generateGuide = generateGuide;
        vm.amendation = amendation;
        vm.isEdit = false;
        vm.printOut = printOut;
        vm.printPl = printPl;
        vm.warehouses = [];
        vm.total = {};

        vm.portList = [];
        vm.typeChange = typeChange;
        vm._portSelected = _portSelected;
        vm.portType = true;

        vm.generateCustoms = generateCustoms;

        vm.getWarehouses = getWarehouses;
        vm.setAudited = setAudited;
        vm.getCargo = getCargo;
        vm.antiOut = antiOut;
    
        vm.deleteOne = deleteOne;
        var isGetCargo = false;

        vm.getAllWmsService = getAllWmsService;
        vm.opsItems = [];
        vm.wmsWorkLogs = [];   

        vm.files = [];   

        vm.page = 1;
        vm.itemsPerPage = 20;
        vm.totalItems = 0;
        $scope.filter = null;
        $scope.pageFiler = function(cargo, idx){
            if($scope.filter){
                return true;
            }
            return idx >= (vm.itemsPerPage * (vm.page - 1)) && idx < (vm.page * vm.itemsPerPage) ;
        }

        function getAllWmsService(cargo){
            if(cargo && !cargo.wmsService){
                cargo.wmsService = "";
            }
            if(vm.opsItems && vm.opsItems.length > 0){
                return;
            }
            OpsItem.getAllWmsService({
                whId: vm.wmsIn.warehouseId,
                companyId: $rootScope.account.companyId
            }, function(result) {
                vm.opsItems = result;
                if(!vm.opsItems || vm.opsItems.length == 0){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.wmsServiceTips'));
                }
            }, function(error) {
                layer.msg($translate.instant('global.noRecord'));
            });
        }

        function antiOut(cargoId){
            if(!$rootScope.account.companyId){return;}
            if($rootScope.HAVENOAUTH('AU_WMS_ANTI')){
                $rootScope.FORBIDDEN_BY_ALERT('AU_WMS_ANTI');    
                return;
            }
            layer.confirm($translate.instant('autopsApp.wmsIn.antiOutAlert'), {
                icon: 0,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                if(cargoId){
                    doAntiOutOne(cargoId);
                }
                else {
                    doAntiOut();
                }
            }, function() {
                return;
            }); 
        }

        function doAntiOut () { 
            vm.isSaving = true;
            WmsIn.antiOut({
                id: vm.wmsIn.id
            }, function(result){
                vm.wmsIn = result;
                vm.isSaving = false;
                vm.totalItems = vm.wmsIn.cargoList.length;
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(error){
                vm.isSaving = false;
                layer.msg($translate.instant('global.messages.submittedfailed'));
            })
        }

        function doAntiOutOne(cargoId) { 
            vm.isSaving = true;
            WmsIn.antiOutOne({
                id: cargoId
            }, function(result){
                vm.wmsIn = result;
                vm.isSaving = false;
                vm.totalItems = vm.wmsIn.cargoList.length;
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(error){
                vm.isSaving = false;
                layer.msg($translate.instant('global.messages.submittedfailed'));
            })
        }
        


        function getCargo (){
            if (!vm.wmsIn.id || !angular.equals(vm.wmsIn, wmsInCopy)){
                if(!$rootScope.account.companyId){
                    vm.wmsIn.status = "Requested";
                }
                isGetCargo = true;
                save();
            }         
            else{
                getCargoPop();
            }
        }

        function getCargoPop () {
            var entt = {};
            entt.wmsIn = angular.copy(vm.wmsIn);
            $uibModal.open({
                templateUrl: 'app/entities/express-cargo/express-cargo-dialog.html',
                controller: 'ExpressCargoDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entt;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('wmsCargo');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                if(result && result.id){
                    vm.wmsIn = result;
                    angular.copy(vm.wmsIn, wmsInCopy);
                    vm.total.choosedPkgs = vm.wmsIn.applicationNum + "PKG";
                    vm.total.choosedGW = vm.wmsIn.gw + "KGS";
                    vm.total.choosedVol = vm.wmsIn.vol + "CBM";      
                }
            }, function() {
            });                 
        }


        $scope.clear = function () {
            $uibModalInstance.dismiss();                       
        }
        var path = $location.path();
        var wmsInCopy = {};
        $timeout(function() {
            if (path.indexOf("wms-app-out/new") >= 0){
                vm.wmsIn = {
                    cargoList: [],
                    type: "WMS",
                    status: "new",
                    allOut: false
                }
                vm.isEdit = true;                
            }
            else {
                if (vm.wmsIn.status == "new"){
                    vm.isEdit = true;
                }                
                if (vm.wmsIn.guideId){
                    vm.wmsIn.encodeId = WinAES.encode(vm.wmsIn.guideId.toString());                    
                }     
                if (vm.wmsIn.customDeclarationId){
                    vm.wmsIn.encodeIdCus = WinAES.encode(vm.wmsIn.customDeclarationId.toString());
                }
                angular.copy(vm.wmsIn, wmsInCopy);
                updateList();
                if(vm.wmsIn.id){
                    WmsWorkLog.getOpRequirment({
                        type: "wms",
                        id: vm.wmsIn.id
                    }, function(result){
                        vm.wmsWorkLogs = result;
                    });
                }
            }
        });
        var rateUnsubscribe = $rootScope.$on('autopsApp:wmsWorkLogUpdate', function(event, result) {
            if(result && result.id){
                var isNew = true;
                angular.forEach(vm.wmsWorkLogs, function(data){
                    if(data.id == result.id){
                        data.count = result.count;
                        data.work = result.work;
                        isNew = false;
                    }
                })
                if(isNew){
                    vm.wmsWorkLogs.push(result)
                }
            }
        });
        $scope.$on('$destroy', rateUnsubscribe);
        function deleteOne (wmsIn) {
            if(vm.wmsIn.cargoList.length > 0){
                layer.msg($translate.instant('autopsApp.wmsIn.releaseLockAlert'));
                return;
            }
            if ($rootScope.HAVENOAUTH('AU_DEL_BIZ')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_DEL_BIZ');
                return;
            }      
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-delete-dialog.html',
                controller: 'WmsInDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return wmsIn;
                    }]
                }
            }).result.then(function() {
                $uibModalInstance.close(vm.wmsIn);  
            }, function() {
            });            
        }

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }
        $scope.loadPorts = function() {
            if ($rootScope.OPSPORTS.airports && $rootScope.OPSPORTS.airports.length > 0){
                typeChange();
                return;
            }
            OpsPorts.getAll(function(result){
                $rootScope.OPSPORTS.airports = [];
                $rootScope.OPSPORTS.seaports = [];
                angular.forEach(result, function(data){
                    if(data.type == 1){
                        $rootScope.OPSPORTS.seaports.push(data);
                    }
                    else {
                        $rootScope.OPSPORTS.airports.push(data);
                    }
                })
                typeChange();
            })
        };

        function typeChange () {
            if (vm.wmsIn.type == 'AIR' || vm.wmsIn.type == 'FBAAIR' || vm.wmsIn.type == 'EXPRESS'){
                vm.portList = $rootScope.OPSPORTS.airports;
                vm.portType = true;
            }
            else {
                vm.portList = $rootScope.OPSPORTS.seaports;
                vm.portType = false;
            }
            // getAllPodNames();
        }

        $scope.updateSales = function(item) {
            vm.wmsIn.salesId = item.id;
        };
        $scope.updateCus = function(item) {
            $scope.wmsIn.cusId = item.cusId;
        };
        $scope.salesFilter = function(item) {
            return item.authorities.indexOf("ROLE_SALES") !== -1;
        };
        $scope.cusFilter = function(item) {
            return item.authorities.indexOf("ROLE_CS") !== -1;
        };
        function _portSelected (data) {
            vm.wmsIn.podId = data.code;
            vm.wmsIn.podName = data.name;
        }

        function save () {
            vm.isSaving = true;
            if (angular.equals(vm.wmsIn, wmsInCopy)){
                layer.msg($translate.instant('global.no_amendmend'));
                vm.isSaving = false;
                return;
            }          
            if (vm.wmsIn.isClosed) {
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }
            if ($rootScope.account.companyId){
                if(!vm.wmsIn.companyId){
                    vm.wmsIn.companyId = $rootScope.account.companyId; 
                } 
                vm.wmsIn.registerId = $rootScope.account.id;
                vm.wmsIn.registerBy = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                if (vm.wmsIn.status == "new" || vm.wmsIn.status == "Requested" || vm.wmsIn.status == "Amended"){
                    vm.wmsIn.status = "Confirmed";
                }                
                // if (!vm.wmsIn.warehousingNumber){
                //     vm.wmsIn.warehousingNumber = new Date().getTime();
                // }                
            }   

            if (vm.wmsIn.id) {                  
                WmsIn.update(vm.wmsIn, onSaveSuccess, onSaveError);
            } else {      
                vm.wmsIn.isOut = true;
                vm.wmsIn.createdBy = $rootScope.account.id;                                          
                WmsIn.save(vm.wmsIn, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.wmsIn = result;
            $scope.$emit('autopsApp:wmsInUpdate', result);
            if (!vm.wmsIn.cargoList){
                vm.wmsIn.cargoList = [];
            }
            angular.copy(vm.wmsIn, wmsInCopy);
            vm.totalItems = vm.wmsIn.cargoList.length;
            
            vm.isSaving = false;
            if(vm.wmsIn.cargoList.length > 0){
                vm.isEdit = false;
            }
            else {
                vm.isEdit = true;
            }            
            if(isGetCargo){
                getCargoPop();
                isGetCargo = false;
            }
            else {
                layer.msg($translate.instant('global.save_success'));
            }
        }

        function onSaveError (result) {
            vm.isSaving = false;
            $rootScope.ALERT_ERROR(result);
        }
        function resend () {
            vm.isSaving = true;
            if (angular.equals(vm.wmsIn, wmsInCopy)){
                layer.msg($translate.instant('global.no_amendmend'));
                vm.isSaving = false;
                return;
            }     
            // vm.wmsIn.warehousingNumber = null;   
            vm.wmsIn.status = "Amended";
            WmsIn.update(vm.wmsIn, onSendSuccess, onSendError);
        }

        function send () {
            vm.isSaving = true;       
            if(!vm.wmsIn.id){
                vm.wmsIn.isOut = true;
            }            
            vm.wmsIn.status = "Requested";
            // vm.wmsIn.warehousingNumber = null;
            WmsIn.send(vm.wmsIn, onSendSuccess, onSendError);
        }

        function onSendSuccess (result) {
            vm.wmsIn = result;
            if (!vm.wmsIn.cargoList){
                vm.wmsIn.cargoList = [];
            }
            vm.totalItems = vm.wmsIn.cargoList.length;
            angular.copy(vm.wmsIn, wmsInCopy);
            layer.msg($translate.instant('global.send_success'));
            vm.isSaving = false;
            vm.isEdit = false;
            $state.go('wms-in-out', null, {
                reload: true
            });
        }

        function onSendError (error) {
            angular.copy(wmsInCopy, vm.wmsIn);
            vm.isSaving = false;
            $rootScope.ALERT_ERROR(error);
        }

        function accept () {
            vm.isSaving = true;    
            vm.wmsIn.status = "Confirmed";
            if ($rootScope.account.companyId){
                if(!vm.wmsIn.companyId){
                    vm.wmsIn.companyId = $rootScope.account.companyId; 
                }             
                vm.wmsIn.registerId = $rootScope.account.id;
                vm.wmsIn.registerBy = $rootScope.account.firstName + " " + $rootScope.account.lastName;         
            }                
            WmsIn.update(vm.wmsIn, onSaveSuccess, onSaveError);
        }

        function decline () {
            if(vm.wmsIn.cargoList.length > 0){
                layer.msg($translate.instant('autopsApp.wmsIn.releaseLockAlert'));
                return;
            }
            vm.isSaving = true;
            // vm.wmsIn.warehousingNumber = null;
            vm.wmsIn.updateTime = null;        
            vm.wmsIn.status = "Declined";
            if ($rootScope.account.companyId){
                if(!vm.wmsIn.companyId){
                    vm.wmsIn.companyId = $rootScope.account.companyId; 
                } 
                vm.wmsIn.registerId = $rootScope.account.id;
                vm.wmsIn.registerBy = $rootScope.account.firstName + " " + $rootScope.account.lastName;         
            }                
            WmsIn.update(vm.wmsIn, onSaveSuccess, onSaveError);
        }
        function getWarehouses () {
            if (vm.warehouses && vm.warehouses.length > 0){
                return;
            }
            if(!$rootScope.account.companyId){
                WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: true,
                    includeGroup: true
                }, function (data) {
                    vm.warehouses = data;
                });
            }
            else if (vm.wmsIn.isVirtual && vm.wmsIn.medCompanyId == $rootScope.account.companyId){
                WmsWarehouse.getAll({
                    includeOwn: false,
                    includeVirtual: true,
                    includeGroup: false
                }, function (data) {
                    vm.warehouses = data;
                });
            }
            else {
                WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false
                }, function (data) {
                    vm.warehouses = data;
                });
            } 
        }

        function clientSelected (client) {
            vm.wmsIn.venderId = client.id;
            vm.wmsIn.salesId = client.createdBy;
            vm.wmsIn.salesName = client.creater;
            if(client.cusId && client.cusName){
                vm.wmsIn.cusId = client.cusId;
                vm.wmsIn.cusName = client.cusName;
            }                 
            checkChoosedList();
        }

        function warehouseSelected (warehouse) {
            vm.wmsIn.warehouseName = warehouse.name;
            vm.wmsIn.warehouseId = warehouse.id;
            vm.wmsIn.companyId = warehouse.companyId;
            vm.wmsIn.medCompanyId = warehouse.medCompanyId;
            vm.wmsIn.isVirtual = warehouse.isVirtual;
            vm.wmsIn.medCompany = warehouse.medCompany;
            vm.wmsIn.medVenderId = warehouse.medVenderId;
            checkChoosedList();
        }

        function checkChoosedList () {
            for (var i = vm.wmsIn.cargoList.length -1; i >=0; i--){
                if (vm.wmsIn.cargoList[i].clientId != vm.wmsIn.venderId){
                    delFromChoosed(vm.wmsIn.cargoList[i]);
                    continue;
                }
                if (vm.wmsIn.cargoList[i].warehouseId != vm.wmsIn.warehouseId){
                    delFromChoosed(vm.wmsIn.cargoList[i]);
                    continue;
                }
            }
            vm.totalItems = vm.wmsIn.cargoList.length;
        }

        function amendation () {
            vm.isEdit = true;
            $translate.refresh();
        }
        
        function printOut(wmsIn){
            OpsFiles.generateWmsInPaper({},wmsIn,function(result){
                exportExcelOrPdf(result);
            },function(error){
                layer.msg($translate.instant('autopsApp.wmsIn.viewWmsInPaperFailed'));
            });
        }
        function printPl(reportType, fileType){
            var wmsIn = angular.copy(vm.wmsIn);
            wmsIn.reportType = reportType;
            wmsIn.fileType = fileType;
            wmsIn.wmsWorkLogs = vm.wmsWorkLogs;
            OpsFiles.generateWmsInPaper({},wmsIn,function(result){
                $rootScope.PDFPRINTEXCELDOWNLOAD(result, fileType, 'packingList');                
            },function(error){
                layer.msg($translate.instant('autopsApp.wmsIn.viewWmsInPaperFailed'));
            });
        }

        function exportExcelOrPdf(result) {
            var file = new Blob([result.data], {
                type: 'application/pdf'
            });
            var fileURL = URL.createObjectURL(file);
            var previewDialog = layer.open({
                type: 2,
                area: ['1200px', '800px'],
                maxmin: true,
                title: $translate.instant("autopsApp.wmsIn.viewWmsInPaper"),
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: fileURL
            });
        }
        function delFromChoosed (cargo) {
            if(vm.isSaving || cargo.allOut){
                return;
            }
            vm.isSaving = true;
            if (!angular.equals(vm.wmsIn, wmsInCopy)){
                layer.msg($translate.instant('global.saveAlert'));
                vm.isSaving = false;
                return;
            }         
            WmsCargo.delFromOut(cargo, function(result) {
                vm.isSaving = false;
                var index = vm.wmsIn.cargoList.indexOf(cargo);
                if (index > -1) {
                    vm.wmsIn.cargoList.splice(index, 1);
                };   
                vm.wmsIn = result;
                updateList();
                angular.copy(vm.wmsIn, wmsInCopy);                    
            }, function(error){
                vm.isSaving = false;
                if(error.status == 409){
                    layer.msg($translate.instant('global.refreshAlert'));                        
                }
                else{
                    layer.msg($translate.instant('global.delete_fail'));       
                }
            });
        }

        function updateList () {
            var choosedGW = 0;
            var choosedVol = 0;
            var choosedPkgs = 0;
            angular.forEach(vm.wmsIn.cargoList, function(data) {
                if (data.pkgs){
                    choosedPkgs = choosedPkgs + data.pkgs;
                }
                if (data.pkgs && data.gw){
                    choosedGW = choosedGW + data.pkgs * data.gw;
                }
                if (data.pkgs && data.vol){
                    choosedVol = choosedVol + data.pkgs * data.vol;
                }
            });
            vm.total.choosedPkgs = choosedPkgs + "PKG";
            vm.total.choosedGW = choosedGW.toFixed(2) + "KGS";
            vm.total.choosedVol = choosedVol.toFixed(3) + "CBM";  

            var checkNum = choosedGW.toFixed(2) - 0;
            if(checkNum != choosedGW){
                vm.wmsIn.gw = choosedGW.toFixed(2) - 0;
            }
            checkNum = choosedVol.toFixed(2) - 0;
            if(checkNum != choosedVol){
                vm.wmsIn.vol = choosedVol.toFixed(3) - 0;
            }
            vm.wmsIn.applicationNum = choosedPkgs;
            vm.totalItems = vm.wmsIn.cargoList.length;
        }

        function generateGuide () {
            if (vm.isSaving){
                return;
            }
            vm.isSaving = true;
            WmsIn.generateGuide(vm.wmsIn, function (result) {
                vm.wmsIn = result;
                if (vm.wmsIn.guideId){
                    vm.wmsIn.encodeId = WinAES.encode(vm.wmsIn.guideId.toString());
                }     
                vm.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));                    
            });            
        }
        function generateCustoms () {
            vm.isSaving = true;
            WmsIn.generateCustoms(vm.wmsIn, onGenerateSuccess, onGenerateError);
        }

        function onGenerateSuccess(result) {
            vm.wmsIn = result;
            if (vm.wmsIn.customDeclarationId){
                vm.wmsIn.encodeIdCus = WinAES.encode(vm.wmsIn.customDeclarationId.toString());
            }                
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submitted'));
        }

        function onGenerateError() {
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submittedfailed'));
            
        }

        $scope.wmsLabels = function(reportType) {
            $scope.format = 1;
            WmsIn.wmsLabels({
                reportType: reportType,
                fileType: $scope.format
            }, vm.wmsIn, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }
        $scope.wmsPltLabels = function(reportType) {
            if(!vm.wmsIn.palletizeJson){
                layer.msg($translate.instant('autopsApp.wmsIn.palletizeAlert'));
                $scope.palletizePop();
            }
            $scope.format = 1;
            WmsIn.wmsPltLabels({
                reportType: reportType,
                fileType: $scope.format
            }, vm.wmsIn, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }     

        function setAudited (audied) {
            if (!$rootScope.account.authorities || $rootScope.account.authorities.indexOf("AU_WMS_AUDIT_OUT") == -1) {
                vm.wmsIn.audited = !audied;
                $rootScope.FORBIDDEN_BY_ALERT('AU_WMS_AUDIT_OUT');    
                return;
            }
            if(audied){
                vm.wmsIn.auditor = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            }
            else {
                vm.wmsIn.auditor = null;
            }
            save();
        }

        $scope.palletizePop = function() {     
            $uibModal.open({
                templateUrl: 'app/entities/crm-price/crm-price-dialog.html',
                controller: 'CrmPriceDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return angular.copy(vm.wmsIn);
                    }]
                }
            }).result.then(function(result) {
                vm.wmsIn.pallets = result.pltNum;
                vm.wmsIn.palletizeJson = angular.toJson(result);
            }, function() {
            });
        }

        $scope.setIsClosed = function() {
            vm.wmsIn.isClosed = !vm.wmsIn.isClosed;
            if (!vm.wmsIn.isClosed && $rootScope.account.authorities.indexOf("AU_BILL_CLOSE") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_CLOSE');   
                return;
            }
            if (vm.wmsIn.isClosed && $rootScope.account.authorities.indexOf("AU_BILL_OPEN") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_BILL_OPEN'); 
                return;
            }
            if(!vm.wmsIn.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if (!angular.equals(wmsInCopy, vm.wmsIn)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            WmsIn.setIsClosed({id:vm.wmsIn.id}, onSaveSuccess, onSaveError);
        }


    }
})();
