(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsBillController', OpsBillController);

    OpsBillController.$inject = ['$scope', '$state', '$rootScope', '$uibModal', '$translate',
    'OpsBill', 'OpsBillSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Guide', 'WmsIn', 'ExpressParcel'];

    function OpsBillController ($scope, $state, $rootScope, $uibModal, $translate,
        OpsBill, OpsBillSearch, ParseLinks, AlertService, pagingParams, paginationConstants, Guide, WmsIn, ExpressParcel) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        $scope.jobType = null;
        $scope.setJobType = function(type) {
            $scope.jobType = type;
        }

        loadAll();
        function loadAll () {
            if (vm.searchQuery) {
               if($scope.jobType){                
                    OpsBill.searchByNums({
                        type: $scope.jobType
                    }, vm.searchQuery, function(data, headers) {
                        $rootScope.ALERT_RESULT_MSG(headers);
                        vm.totalItems = vm.queryCount = data.length;
                        vm.opsBills = data;
                    }, onError);
                    return;
                }
                OpsBillSearch.query({
                    query: vm.searchQuery,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                OpsBill.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.opsBills = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
           if($scope.jobType){                
                loadAll();
                return;
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        $scope.dnMakePop = function(opsBill) {
            if (opsBill.guideId) {
                Guide.getGuideAndRates({
                    id: opsBill.guideId
                }, function(result) {
                    dnMakeDialog(result, opsBill, 'guide');
                })
            }
            else if (opsBill.wmsInId) {
                WmsIn.getGuideRates({
                    id: opsBill.wmsInId
                }, function(result) {
                    dnMakeDialog(result, opsBill, 'wms');
                })
            }
            else {
                ExpressParcel.getGuideAndRates({
                    id: opsBill.expressParcelId
                }, function(result) {
                    dnMakeDialog(result, opsBill, 'expressParcel');
                })
            }
        }


        function dnMakeDialog (guide, opsBill, shipmentType) {
            var dnEntity = angular.copy(guide);
            dnEntity.isDn = opsBill.idDn;
            dnEntity.shpmtType = shipmentType;
            dnEntity.billNum = opsBill.billNo;

            $uibModal.open({
                templateUrl: 'app/entities/guide/dn-dialog.html',
                controller: 'DnDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return dnEntity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsBill');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {

            }, function(result) {

            });
        }

        $scope.delBill = function(opsBill) {
            if (opsBill.createdBy == $rootScope.account.id
                    && $rootScope.account.authorities.indexOf("AU_DEL_OWN_BILL") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_DEL_OWN_BILL');
                return;
            }
            if (opsBill.createdBy != $rootScope.account.id
                    && $rootScope.account.authorities.indexOf("AU_DEL_OTHER_BILL") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_DEL_OTHER_BILL');
                return;
            }

            layer.confirm($translate.instant('autopsApp.opsBill.delete.question', {id: opsBill.billNo}), {
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')], icon: 3 //按钮
            }, function(index) {
                var opsBillDTO = {};
                opsBillDTO.opsBill = angular.copy(opsBill);
                OpsBill.delBill(opsBillDTO, function(result){
                    loadAll();
                    layer.msg($translate.instant('global.messages.submitted'));
                }, $rootScope.ALERT_ERROR);
                layer.close(index);
            }, function() {
                return;
            });
        }
        $scope.createNew = function() {
            $rootScope.OPS_ALERT($translate.instant('autopsApp.opsBill.createAlert'));
        }

        $scope.ratesSubmit = function(opsBill) {
            var entity = angular.copy(opsBill);
            entity.isOpsBill = true;
            $uibModal.open({
                templateUrl: 'app/entities/guideRates/batchRatesSubmit.html',
                controller: 'BatchRatesSubmitController',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
            }, function(result) {
            });
        }

        $scope.batchDcnPrint = function(opsBills, reportType, fileType) {
            if(!opsBills || opsBills.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            layer.load(1, { shade: 0.3 });
            OpsBill.batchDcnPrint({
                reportType: reportType,
                fileType: fileType,
                companyId: $rootScope.account.companyId
            }, opsBills, function(result) {
                layer.closeAll();
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.closeAll();
            });
        }

    }
})();
