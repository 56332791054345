(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('JobPalletMovementController',JobPalletMovementController);

        JobPalletMovementController.$inject = ['$rootScope', '$uibModalInstance', '$translate', '$timeout', 'entity', 'JobPallet', 'WmsWarehouse', 'WmsIn'];

    function JobPalletMovementController($rootScope, $uibModalInstance, $translate, $timeout, entity, JobPallet, WmsWarehouse, WmsIn) {
        var vm = this;

        vm.jobPallets = entity.jobPallets;
        vm.locations = entity.locations;
        vm.warehouses = entity.warehouses;
        vm.submitMovment = submitMovment;
        vm.getAllLocations = getAllLocations;
        vm.getAllWarehouses = getAllWarehouses;
        vm._onLocationSelected = _onLocationSelected;

        function _onLocationSelected (location, cargo) {
            cargo.locationNew = location.code;
            cargo.locationIdNew = location.locationId;
            cargo.warehouseIdNew = location.warehouseId;
            angular.forEach(vm.warehouses, function(warehouseItem){
                if (warehouseItem.id == location.warehouseId) {
                    cargo.warehouseNameNew = warehouseItem.name;
                }
            })
        }
        function getAllLocations () {
            getAllWarehouses();
            if (vm.locations && vm.locations.length > 0){
                return;
            }
            WmsWarehouse.getAllLocations({companyId: $rootScope.account.companyId}, function (data) {
                vm.locations = data;
            });
        }

        function getAllWarehouses () {
            if (vm.warehouses && vm.warehouses.length > 0){
                return;
            }
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: true,
                    includeGroup: false
                }, function (result) {
                if(!result || result.length == 0){
                    layer.msg($translate.instant('global.messages.resultNull'));
                }
                vm.warehouses = result;
            });
        }

        function submitMovment () {
            JobPallet.movement(vm.jobPallets, function(result) {
                $uibModalInstance.close(result);
                layer.msg($translate.instant('global.messages.submitted'));
                $translate.refresh();  
            }); 
        }
    }
})();
