(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('EdiInsurance', EdiInsurance);

    EdiInsurance.$inject = ['$resource', 'DateUtils'];

    function EdiInsurance ($resource, DateUtils) {

        function initResponse(data){
            if (data) {
                data = angular.fromJson(data);
                data.createTime = DateUtils.convertDateTimeFromServer(data.createTime);
                data.startDate = DateUtils.convertLocalDateFromServer(data.startDate);
                data.comCode += "";   
                data.transportId += ""; 
                data.goodsType += ""; 
                data.bigPack += ""; 
                if (data.insuranceType != 'WYB') {
                    data.smallPack += ""; 
                }
                data.jzx += ""; 
                data.startPortBig += ""; 
                data.startPortSmall += ""; 
                data.terminiPortBig += ""; 
                data.terminiPortSmall += ""; 
                data.jcl += ""; 
                data.currencyCode += ""; 
                data.hasCredit += ""; 
                data.status += "";                 
                if(data.planInfoList){
                    data.planInfoListObj = angular.fromJson(data.planInfoList);
                }
                if(data.auditTime){
                    data.isAudit = true;
                }else{
                    data.isAudit = false;
                }
                data.freightCurrencyCode += "";
            }
            return data;
        }

        function initRequest (data) {
            data = angular.copy(data);
            data.startDate = DateUtils.convertLocalDateToServer(data.startDate);
            if(angular.isObject(data.planInfoList)){
                data.planInfoList = angular.toJson(data.planInfoList, true);
            }
           return angular.toJson(data);
        }

        var resourceUrl =  'api/edi-insurances/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'getByGuideId': {
                url:'api/edi-insurances/getByGuideId',
                method: 'GET',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'getLinghangDataMap': {
                url:'api/edi-insurances/getLinghangDataMap',
                method: 'GET'
            },
            'getByJobNum': {
                url:'api/edi-insurances/getByJobNum',
                method: 'GET',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'apiToubao': {
                url:'api/edi-insurances/apiToubao',
                method: 'POST',
                transformResponse: function (data) {
                    return initResponse(data);
                },
                transformRequest: function (data) {
                    return initRequest(data);
                }
            },
            'getToubaoBody': {
                url:'api/edi-insurances/getToubaoBody',
                method: 'POST',
                transformRequest: function (data) {
                    return initRequest(data);
                }
            },
            'apiXianzhong': {
                url:'api/edi-insurances/apiXianzhong',
                method: 'POST'
            },
            'apiKancha': {
                url:'api/edi-insurances/apiKancha',
                method: 'POST',
                isArray: true
            },
            'apiDuizhang': {
                url:'api/edi-insurances/apiDuizhang',
                method: 'POST'
            },
            'apiChedan': {
                url:'api/edi-insurances/apiChedan',
                method: 'POST',
                transformRequest: function (data) {
                    return initRequest(data);
                },
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'apiBaofei': {
                url:'api/edi-insurances/apiBaofei',
                method: 'POST'
            },
            'apiPigai': {
                url:'api/edi-insurances/apiPigai',
                method: 'POST'
            },
            'apiOrderinfo': {
                url:'api/edi-insurances/apiOrderinfo',
                method: 'POST',
                transformResponse: function (data) {
                    return initResponse(data);
                },
                transformRequest: function (data) {
                    return initRequest(data);
                }
            },
            'update': { 
                method:'PUT',
                transformRequest: function (data) {
                    return initRequest(data);
                },
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'save': { 
                method:'POST',
                transformRequest: function (data) {
                    return initRequest(data);
                },
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'batchSaveInsurance': {
                method: 'POST',
                url: 'api/edi-insurances/batchSaveInsurance'
            },
            'getFilterPage': {
                method: 'POST',
                url: 'api/edi-insurances/getFilterPage',
                isArray: true
            },
            'batchInsurance': {
                method: 'POST',
                url: 'api/edi-insurances/batchInsurance',
                isArray: true
            },
            'batchAuditInsurance': {
                method: 'POST',
                url: 'api/edi-insurances/batchAuditInsurance',
                isArray: true
            },
            'audit': {
                method: 'POST',
                url: 'api/edi-insurances/audit',
                transformResponse: function (data) {
                    return initResponse(data);
                }
            },
            'getWybDataMap': {
                url:'api/edi-insurances/getWybDataMap',
                method: 'GET'
            },
            'getPacificDataMap': {
                url:'api/edi-insurances/getPacificDataMap',
                method: 'GET'
            },
            'batchCreateInsurance': {
                method: 'POST',
                url: 'api/edi-insurances/batchCreateInsurance',
                isArray: true
            }
        });
    }
})();
