(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('TemplateRatesController', TemplateRatesController);

    TemplateRatesController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$translate', '$timeout', 'entity', 'OpsTemplate'];
    
    function TemplateRatesController($scope, $rootScope, $uibModalInstance, $translate, $timeout, entity, OpsTemplate) {
               
        var ratesCopy = entity;       
        $scope.isSaving = false; 
        $scope.opsTemplate = {};
        $scope.opsTemplate.rates = [];

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $timeout(function (){
            angular.forEach(ratesCopy, function(data){
                data.id = null;
                data.venderName = data.payer;
                $scope.opsTemplate.rates.push(data);
            })  
        })

        $scope.save = function() {
            if (!$scope.opsTemplate.code || !$scope.opsTemplate.remarks) {
                layer.msg($translate.instant('global.messages.fillBasicInfo'));
                return;
            }
            $scope.isSaving = true;
            if ($scope.opsTemplate.id != null) {
                OpsTemplate.update($scope.opsTemplate, onTempalteSaveSuccess, onTempalteSaveError);
            } else {
                OpsTemplate.save($scope.opsTemplate, onTempalteSaveSuccess, onTempalteSaveError);
            }   
        }

        var onTempalteSaveSuccess = function(result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('global.save_success'));
            $scope.clear();
        }
        var onTempalteSaveError = function(result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('global.save_fail'));
        }

        $scope.upperTemplateCode = function(code) {
            $scope.opsTemplate.code = angular.uppercase(code);
        };











}
})();
