(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsUserDetailController', OpsUserDetailController);

    OpsUserDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'OpsUser'];
    
    function OpsUserDetailController($scope, $rootScope, $stateParams, entity, OpsUser) {
        $scope.opsUser = entity;
        $scope.load = function (id) {
            OpsUser.get({id: id}, function(result) {
                $scope.opsUser = result;
            });
        };
        var unsubscribe = $rootScope.$on('autopsApp:opsUserUpdate', function(event, result) {
            $scope.opsUser = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
