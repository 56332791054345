(function() {
    'use strict';
    angular
        .module('autopsApp')
        .controller('WoBodyController', WoBodyController);

    WoBodyController.$inject = ['$scope', '$state', '$rootScope', '$uibModal', '$translate', '$timeout',
    'WoHead', 'WoHeadSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'WinAES', 'GridState', 'uiGridConstants', 'uiGridGroupingConstants'];

    function WoBodyController ($scope, $state, $rootScope, $uibModal, $translate, $timeout,
        WoHead, WoHeadSearch, ParseLinks, AlertService, paginationConstants, WinAES, GridState, uiGridConstants, uiGridGroupingConstants) {
        var vm = this;

        vm.predicate = 'id';
        vm.reverse = true;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = 1;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery;
        vm.currentSearch;
        vm.isAdvanceSearch = false;

        vm.loadNotRepliedWo = false;
        vm.getOnePendingWo = getOnePendingWo;

        vm.technicalSupport = technicalSupport;
        vm.knowledgeBase = knowledgeBase;
        vm.urgentTag = urgentTag;
        vm.auditRequired = auditRequired;
        vm.endWo = endWo;
        vm.closeWo = closeWo;

        function getOnePendingWo (getNew){
            WoHead.getOnePendingWo({
                getNew: getNew
            }, function(result){
                if(result && result.id){
                    woHeadPop(result);
                }
                else {
                    layer.msg("没有待处理工单！");
                }
            });
        }
        $scope.woHeadPopById= function(id) {
            WoHead.get({id : id}, function(result){
                woHeadPop(result);
            });
        }

        function woHeadPop(woHead) {
            $uibModal.open({
                templateUrl: 'app/entities/wo-head/wo-head-detail.html',
                controller: 'WoHeadDetailController',
                controllerAs: 'vm',
                backdrop: false,
                keyboard: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return woHead;
                    }]
                }
            }).result.then(function() {
                loadAll();
            }, function() {
                loadAll();
            });
        }
        loadAll();
        var pageLoaded = false;
        var filterTimer;
        function loadAll () {
            if (vm.searchQuery) {
                WoHeadSearch.query({
                    loadFirstLastBody: true,
                    query: vm.searchQuery,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                var filter = {};
                WoHead.getPendingWo({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, filter, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                if(headers('X-Total-Count')){
                    vm.totalItems = headers('X-Total-Count');
                }
                else {
                    vm.totalItems = data.length;
                }
                vm.woHeads = data;
                initData();
                vm.isAdvanceSearch = false;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        function initData() {
            angular.forEach(vm.woHeads, function(data){
                data.encodeId = WinAES.encode(data.id.toString());
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = true;
            vm.currentSearch = searchQuery;
            loadAll();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = vm.currentSearch = null;
            loadAll();
        }
        var unsubscribe = $rootScope.$on('autopsApp:woHeadUpdate', function(event, result) {
            var isNew = true;
            angular.forEach(vm.woHeads, function(data){
                if(data.id && data.id == result.id){
                    angular.copy(result, data);
                    data.encodeId = WinAES.encode(data.id.toString());
                    isNew = false;
                }
            })
            if(isNew){
                result.encodeId = WinAES.encode(result.id.toString());
                vm.woHeads.splice(0, 0, result);
            }
        });
        $scope.$on('$destroy', unsubscribe);


        var onUpdateSuccess = function(result){
            angular.forEach(vm.woHeads, function(data){
                if(data.id && data.id == result.id){
                    angular.copy(result, data);
                    data.encodeId = WinAES.encode(data.id.toString());
                    $translate.refresh();
                }
            })
            layer.msg($translate.instant('global.messages.submitted'));
        }

        var woTypes = [
            { value: 'bug', label: $translate.instant('autopsApp.woHead.woType.bug')},
            { value: 'advice', label: $translate.instant('autopsApp.woHead.woType.advice')},
            { value: 'customized', label: $translate.instant('autopsApp.woHead.woType.customized')}];
        var woStatus = [
            { value: 'new', label: $translate.instant('autopsApp.woHead.sts.new')},
            { value: 'replied', label: $translate.instant('autopsApp.woHead.sts.replied')},
            { value: 'added', label: $translate.instant('autopsApp.woHead.sts.added')},
            { value: 'closed', label: $translate.instant('autopsApp.woHead.sts.closed')},
            { value: 'newAdded', label: $translate.instant('autopsApp.woHead.sts.newAdded')}];


        $scope.setSubmitterRating= function(id, submitterRating) {
            if(!id){
                return;
            }
            WoHead.setSubmitterRating({
                id: id,
                submitterRating: submitterRating
            }, function(){
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(){
                layer.msg($translate.instant('global.messages.submittedfailed'));
            });
        }

        function technicalSupport (woHead) {
            if($rootScope.account.id == 1987){
                layer.msg($translate.instant('global.forbidden'));
                return;
            }
            WoHead.technicalSupport({
                id: woHead.id
            }, function(result){
                woHead.technicalSupport = result.technicalSupport;
                woHead.ldSubmitter = result.ldSubmitter;
                $translate.refresh();
                layer.msg($translate.instant('global.messages.submitted'));
            });
        }
        function auditRequired (woHead) {        
            if($rootScope.account.id != 9339 && $rootScope.account.id != 5){
                return;
            }
            WoHead.auditRequired({
                id: woHead.id
            }, function(result){
                woHead.auditRequired = result.auditRequired;
                $translate.refresh();
                layer.msg($translate.instant('global.messages.submitted'));
            });
        }

        function knowledgeBase (woHead) {
            if($rootScope.account.id != 5){
                return;
            }
            WoHead.knowledgeBase({
                id: woHead.id
            }, function(result){
                woHead.knowledgeBase = result.knowledgeBase;
                $translate.refresh();
                layer.msg($translate.instant('global.messages.submitted'));
            });
        }

        function urgentTag (woHead) {
            if($rootScope.account.companyId != 1){
                return;
            }
            WoHead.urgentTag({
                id: woHead.id
            }, function(result){
                woHead.urgentTag = result.urgentTag;
                $translate.refresh();
                layer.msg($translate.instant('global.messages.submitted'));
            });
        }
        function endWo (woHead) {
            if(woHead.endTag){
                woHead.endTagUser = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            }
            WoHead.update(woHead, function(result){
                woHead.endTag = result.endTag;
                woHead.endTagUser = result.endTagUser;
                $translate.refresh();
                layer.msg($translate.instant('global.messages.submitted'));
            });
        }


        function closeWo (woHead) {
            if(woHead.endTime){
                $rootScope.OPS_ALERT("工单已关闭");
                return;
            }
            WoHead.closeWoByCus({
                id: woHead.id
            }, function(result){
                woHead.endTime = result.endTime;
                woHead.status = result.status;
                $translate.refresh();
                layer.msg($translate.instant('global.messages.submitted'));
            });
        }

    }
})();
