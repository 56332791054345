(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WriteoffInvoicePopController', WriteoffInvoicePopController);
    
    WriteoffInvoicePopController.$inject = ['$rootScope', '$scope', '$timeout', '$translate', '$uibModalInstance', 'entity', 'GuideRates', 'DataUtils'];
    
    function WriteoffInvoicePopController($rootScope, $scope, $timeout, $translate, $uibModalInstance, entity, GuideRates, DataUtils) {
 
        $scope.invoiceRequest = entity;
        $timeout(function (){
            if (!$scope.invoiceRequest.writeOffRates || $scope.invoiceRequest.writeOffRates.length == 0){
                GuideRates.getWrieteOffRatesByDnNum({
                    companyId: $rootScope.account.companyId,
                    dnNum: $scope.invoiceRequest.dnNum
                }, function(result) {
                    $scope.invoiceRequest.writeOffRates = result;
                    $scope.invoiceRequest.writeOffRates.sort(function(a,b){
                        return a.jobNum > b.jobNum?1:-1;       
                    });
                    $scope.invoiceAutoInput();
                    invalidCheck();
                });
            }
            else {
                // $scope.invoiceAutoInput();
                invalidCheck();
            }
        });
        $scope.filterFd = {};
        $scope.filterFdChange = function(fd) {
            $scope.invoiceRequest.writeOffRates.sort(function(a, b) {
                if(a[fd] && angular.lowercase(a[fd]).indexOf(angular.lowercase($scope.filterFd[fd])) != -1){
                    return -1;
                }
                if(b[fd] && angular.lowercase(b[fd]).indexOf(angular.lowercase($scope.filterFd[fd])) != -1){
                    return 1;
                }
            });
        }
        var invalidCheck = function() {
            if($scope.invoiceRequest.manual){
                return;
            }
            var ttlAmt = 0;
            $scope.invoiceRequest.checkBal = 0;
            angular.forEach($scope.invoiceRequest.writeOffRates, function(data){                
                if (data.isPay) {
                    $scope.invoiceRequest.checkBal += data.diffAmount;
                    ttlAmt -= data.totalAmount;
                }
                else {
                    ttlAmt += data.totalAmount;
                    $scope.invoiceRequest.checkBal -= data.diffAmount;
                }
            })
            if(DataUtils.round(ttlAmt, 2) != DataUtils.round($scope.invoiceRequest.invoiceAmount, 2)){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.accountInvoice.invalidDnAlert', {param: $scope.invoiceRequest.dnNum}));
            }
        }
        $scope.invoiceAutoInput = function() {
            if (!$scope.invoiceRequest.actualAmount){
                $scope.invoiceRequest.actualAmount = 0;
            }
            if($scope.invoiceRequest.checkBal > $scope.invoiceRequest.actualAmount){
                $scope.invoiceRequest.actualAmount = $scope.invoiceRequest.checkBal;
                layer.msg("Min: " + $scope.invoiceRequest.checkBal);
            }
            if($scope.invoiceRequest.balance < $scope.invoiceRequest.actualAmount){
                $scope.invoiceRequest.actualAmount = $scope.invoiceRequest.balance;
                layer.msg("Max: " + $scope.invoiceRequest.balance);
            }
            var thisAmount = $scope.invoiceRequest.actualAmount.toFixed(2) - 0;
            //清空记录
            angular.forEach($scope.invoiceRequest.writeOffRates, function(data){
                data.thisAmount = 0;
            });

            //更新费用列表
            angular.forEach($scope.invoiceRequest.writeOffRates, function(data){
                if (!data.isPay && data.totalAmount < 0) {
                    data.thisAmount = data.diffAmount.toFixed(2) - 0;
                    thisAmount = thisAmount - data.thisAmount;
                }
                else if (data.isPay && data.totalAmount > 0) {
                    data.thisAmount = data.diffAmount.toFixed(2) - 0;
                    thisAmount = thisAmount + data.thisAmount;
                }          
            });
            angular.forEach($scope.invoiceRequest.writeOffRates, function(data){
                if (!data.isPay && data.totalAmount >= 0) {
                    if (thisAmount >= data.diffAmount) {
                        data.thisAmount = data.diffAmount.toFixed(2) - 0;
                        thisAmount = thisAmount - data.diffAmount;
                    } 
                    else {
                        data.thisAmount = thisAmount.toFixed(2) - 0;
                        thisAmount = 0;
                    }
                }
                else if (data.isPay && data.totalAmount <= 0) {
                    if (thisAmount >= -data.diffAmount) {
                        data.thisAmount = data.diffAmount.toFixed(2) - 0;
                        thisAmount = thisAmount + data.diffAmount;
                    }
                    else {
                        data.thisAmount = -(thisAmount.toFixed(2) - 0);
                        thisAmount = 0;
                    }
                }  
            });
        }
        
        $scope.choosedInvoiceChange = function(rate) {
            if(!rate.diffAmount){return};
            rate.choosed = !rate.choosed;
            rate.thisAmount = rate.diffAmount;
            $scope.thisInvoiceAmountChange(rate);
            updateChoosedTtl();
        }
        $scope.choosedTtlAmt = 0;
        var updateChoosedTtl = function() {
            $scope.choosedTtlAmt = 0;
            angular.forEach($scope.invoiceRequest.writeOffRates, function(data){
                if (data.choosed) {    
                    if(data.isPay) {
                        $scope.choosedTtlAmt -= data.thisAmount;
                    }
                    else {
                        $scope.choosedTtlAmt += data.thisAmount;
                    }
                }
            });
        }

        $scope.chooseByJob = function(rate) {
            var choosed = !rate.choosed;
            angular.forEach($scope.invoiceRequest.writeOffRates, function(data){
                if (data.jobNum == rate.jobNum) {     
                    data.choosed = choosed;
                }
            });
            updateChoosedTtl();
        }

        $scope.thisInvoiceAmountChange = function(rate) {
            if(!rate.thisAmount && rate.thisAmount != 0){
                rate.thisAmount = rate.diffAmount;
            }
            if(rate.diffAmount >= 0 && (rate.thisAmount < 0 || rate.thisAmount > rate.diffAmount)){
                rate.thisAmount = rate.diffAmount;
            }
            if(rate.diffAmount <= 0 && (rate.thisAmount > 0 || rate.thisAmount < rate.diffAmount)){
                rate.thisAmount = rate.diffAmount;
            } 
            //更新合计数
            var totalPaid = 0;
            angular.forEach($scope.invoiceRequest.writeOffRates, function(data){
                if(data.isPay){
                    totalPaid = totalPaid - data.thisAmount;  
                }
                else {
                    totalPaid = totalPaid + data.thisAmount;  
                }
                      
            });
            //更新开票申请
            $scope.invoiceRequest.actualAmount = totalPaid.toFixed(2) - 0;
        }

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();