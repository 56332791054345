(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('BookingCustom', BookingCustom);

    BookingCustom.$inject = ['$resource','DateUtils'];
    
    function BookingCustom($resource, DateUtils) {
        return $resource('api/bookingCustoms/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if(data){
                        data = angular.fromJson(data);
                        data.declareDate = DateUtils.convertDateTimeFromServer(data.declareDate);
                    } 
                    return data;
                }
            },
            'getPackingList': {
                method:'GET',
                url:'api/bookingCustoms/getPackingList',
                isArray: true
            },
            'testFileCrawler': {
                method:'POST',
                url:'api/bookingCustoms/testFileCrawler',
                transformRequest: function (data) {
                    data.declareDate = DateUtils.convertLocalDateToServer(data.declareDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if(data){
                        data = angular.fromJson(data);
                        data.declareDate = DateUtils.convertDateTimeFromServer(data.declareDate);
                    } 
                    return data;
                }
            },
            'savePackingList': {
                method:'POST',
                url:'api/bookingCustoms/savePackingList',
                isArray: true
            },
            'update': { 
                method:'PUT',
                transformRequest: function (data) {
                    data.declareDate = DateUtils.convertLocalDateToServer(data.declareDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if(data){
                        data = angular.fromJson(data);
                        data.declareDate = DateUtils.convertDateTimeFromServer(data.declareDate);
                    } 
                    return data;
                }
            },
            'save': { 
                method:'POST',
                transformRequest: function (data) {
                    data.declareDate = DateUtils.convertLocalDateToServer(data.declareDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if(data){
                        data = angular.fromJson(data);
                        data.declareDate = DateUtils.convertDateTimeFromServer(data.declareDate);
                    } 
                    return data;
                }
            },
            'sendFileService': {
                method:'GET',
                url: 'api/bookingCustoms/sendFileService'
            },
            'customsImport': {
                method:'GET',
                url: 'api/bookingCustoms/customsImport'
            },
            'undoCustoms': {
                method:'GET',
                url: 'api/bookingCustoms/undoCustoms'
            }
        });
    }
})();
