(function() {
	'use strict';

	angular
		.module('autopsApp')
		.controller('StatBookingNewController', StatBookingNewController);

	StatBookingNewController.$inject = ['$scope', '$rootScope', '$translate', '$state', '$uibModal', '$timeout', '$location', 'Principal', 
	'OpsFiles', 'uiGridConstants', 'uiGridGroupingConstants', 'GridState', 'DateUtils', 'DataUtils', 'AsyncTask', 'OpsCompany'];

	function StatBookingNewController($scope, $rootScope, $translate, $state, $uibModal, $timeout, $location, Principal,
		OpsFiles, uiGridConstants, uiGridGroupingConstants, GridState, DateUtils, DataUtils, AsyncTask, OpsCompany) {		

		$scope.statParams = {isShipper: false, showOrgCur: false};
        $scope.statParams.statType = "month";
        $scope.statParams.month = new Date();
        $scope.statParams.filterRequired = true;

		$scope.datePickerOpenStatus = {};
		$scope.yearFormat = "yyyy";
		$scope.monthFormat = "yyyy-MM";
		$scope.dayFormat = "yyyy-MM-dd";
		$scope.yearDatepickerOptions = {
			minMode: 'year'
		}
		$scope.monthDatepickerOptions = {
			minMode: 'month'
		}
		$scope.dayDatepickerOptions = {
			minMode: 'day'
		}
		$scope.openCalendar = function(date) {
			$scope.datePickerOpenStatus[date] = true;
		}
		
		$scope.isSaving = false;
		$scope.reportavailalbe = false;
		$scope.getContents = function() {           
			DataUtils.updateStatParams($scope.statParams);
			if(!$scope.statParams.etdFM || !$scope.statParams.etdTO){
				layer.msg($translate.instant('stats.stat.dateNullAlert'));				
				return;
			}
            $scope.isSaving = true;
            var loadIndex = layer.load(1, {shade: 0.3});  
            $scope.statParams.timezoneOffset = new Date().getTimezoneOffset()/60;
            $scope.reportavailalbe = false;
			OpsFiles.comStats($scope.statParams, function(result) {
				$scope.isSaving = false;
                layer.close(loadIndex);  
				if (!result || result.length == 0) {
					layer.msg($translate.instant('global.messages.resultNull'));
				}
                $scope.gridOptions.data = result;   
                resize();           
                initContent();
			}, function(response) {
				$scope.isSaving = false;
                $rootScope.ALERT_ERROR(response);
			});
		}

        window.onresize = function(){
            resize();
        }

        function resize() {
            var tabHeight = $scope.gridOptions.data.length * 30 + 190;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 680){
                tabHeight = 680;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }
        var colExist = function(field) {
            var colExist = false;
            angular.forEach($scope.gridOptions.columnDefs, function(col){
                if(field == col.field){
                    colExist = true;
                }
            })
            return colExist;
        }
        
        var initContent = function() {
            var curList;
            var profit = 0;
            var rev = 0;
            angular.forEach($scope.gridOptions.data, function(data){
				data.gw = data.gw?(data.gw.toFixed(2) -0):null;
				data.vol = data.vol?(data.vol.toFixed(3) -0):null;
				data.chargeWeight = data.chargeWeight?(data.chargeWeight.toFixed(2) -0):null;
                data.salesName = data.salesName?data.salesName.replace(/[ ]/g,""):"NULL";
                data.opName = data.opName?data.opName.replace(/[ ]/g,""):"NULL";
                data.docName = data.docName?data.docName.replace(/[ ]/g,""):"NULL";
                data.finName = data.finName?data.finName.replace(/[ ]/g,""):"NULL";
                data.checkByName = data.checkByName?data.checkByName.replace(/[ ]/g,""):"NULL";
                data.bookingBy = data.bookingBy?data.bookingBy.replace(/[ ]/g,""):"NULL";
                data.customBy = data.customBy?data.customBy.replace(/[ ]/g,""):"NULL";
                data.cusName = data.cusName?data.cusName.replace(/[ ]/g,""):"NULL";
                data.truckBy = data.truckBy?data.truckBy.replace(/[ ]/g,""):"NULL";
                if(data.polRegion){
                    data.polRegion = $translate.instant('global.lanes.' + data.polRegion);
                }
                if(data.podRegion){
                    data.podRegion = $translate.instant('global.lanes.' + data.podRegion);
                }           
                if(!curList){
                    curList = data.currencys;
                }     
                if(data.profitRMB){
                    profit += data.profitRMB;
                }
                if(data.totalRMB){
                    rev += data.totalRMB;
                }
                if(data.transferOutTime){
                    data.transferOutTime = data.transferOutTime.substring(0, 10);
                }
                if(data.deliveryAppointmentTime){
                    data.deliveryAppointmentTime = data.deliveryAppointmentTime.substring(0, 10);
                }
                if(data.deliveryTime){
                    data.deliveryTime = data.deliveryTime.substring(0, 10);
                }
                if(data.ctnReturnTime){
                    data.ctnReturnTime = data.ctnReturnTime.substring(0, 10);
                }
                
                if(data.totalRMB){
                    if(!data.uncollectedRMB){
                        data.paymentStatus = "paymentPaid";
                    }
                    else if(data.uncollectedRMB < data.totalRMB){
                        data.paymentStatus = "paymentPartlyPaid";
                    }
                    else {
                        data.paymentStatus = "paymentNotPaid";
                    }
                }
                else {
                    data.paymentStatus = "paymentNotPaid";
                }
            })
            if(rev){
                $scope.avgGp = DataUtils.round(profit/rev*100, 2) + "%";
            }
            else {
                $scope.avgGp = "N/A";
            }
            if($scope.statParams.showOrgCur){
                $scope.gridOptions.columnDefs = angular.copy(columnDefsCopy);
                angular.forEach(curList, function(cur){
                    if(!colExist("rev" + cur)){
                        $scope.gridOptions.columnDefs.push({
                            field: "rev" + cur, 
                            minWidth:120,
                            type:'number',
                            treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                            aggregationType: uiGridConstants.aggregationTypes.sum,
                            filters: [
                                {
                                  condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                                  placeholder: ' >= '
                                },
                                {
                                  condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                                  placeholder: ' <= '
                                }
                            ],
                            displayName: $translate.instant('stats.stat.freightOrgAmt', {currency: cur})
                        })
                    }
                })
                angular.forEach($scope.gridOptions.data, function(data){
                    for(var i =0; i < curList.length; i++){
                        data["rev" + curList[i]] = data.currencyRevList[i].totalB;
                    }                
                })
            }

            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
            removeGridCol();
            setTimeout(function(){
                getCtnTypeStr();
            }, 1000);
        }

        $scope.ctnTypeStr = "";
        var getCtnTypeStr = function() {
            $scope.ctnTypeStr = "";
            var ctnTypes = new Set();
            var lclCount = 0;
            var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
            angular.forEach(allvisiblerows, function(data){
                if(!data.entity.isVoid && !data.entity.localTimeTwo && (!data.entity.ctnTypeNum || data.entity.ctnTypeNum.indexOf('X') == -1)){
                    lclCount++;
                }
                if(!data.entity.isVoid && !data.entity.localTimeTwo && data.entity.ctnTypeNum){
                    var volumes = data.entity.ctnTypeNum.split(' ');
                    angular.forEach(volumes, function(volume){
                        if (volume){
                            var teus = volume.split('X');
                            if(teus.length == 2){
                                ctnTypes.add(teus[1]);
                            }
                        }
                    })
                }
            })
            var amt = 0;
            ctnTypes.forEach(function(ctnType){
                amt = 0;
                angular.forEach(allvisiblerows, function(data){
                    if(!data.entity.isVoid && !data.entity.localTimeTwo && data.entity.ctnTypeNum){
                        var volumes = data.entity.ctnTypeNum.split(' ');
                        angular.forEach(volumes, function(volume){
                            if (volume){
                                var teus = volume.split('X');
                                if(teus.length == 2 && teus[1] == ctnType){
                                    amt += parseInt(teus[0]);
                                }
                            }
                        })
                    }
                })
                $scope.ctnTypeStr += amt + "X" + ctnType + " ";
            })
            if(lclCount){
                $scope.ctnTypeStr += lclCount + "XLCL";
            }
            $translate.refresh();
        }
        function getTitleStr() {
            var outStr = $translate.instant('global.menu.admin.statBooking');
            outStr += "(" + DateUtils.convertLocalDateToServer($scope.statParams.etdFM) 
                + " - " 
                + DateUtils.convertLocalDateToServer($scope.statParams.etdTO)
                + ")";
            return outStr;
        }
        var baseCur = $rootScope.account.baseCurrency?$rootScope.account.baseCurrency:"RMB";
        var statsPageSize = 5000;
        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: false,   

            enableGridMenu : true,//表格 菜单  
            showGridFooter: true,//表格的footer  
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤            

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            enableRowHeaderSelection : true,  //行选中
            enableRowSelection: true, //行选中
            enableSelectAll: true, //全部选中
            enableFullRowSelection : false, //行任意位置选中
            multiSelect: true,//多行选中

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: $translate.instant('global.menu.admin.profitStats') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],       

            paginationPageSizes: [1000, 5000, 10000, 15000, 20000],
            paginationPageSize: statsPageSize,
            useExternalPagination: false,

            minimumColumnSize: 70,
            columnDefs: [
                { field: 'jobNum', 
                    width: 180,
                    displayName: $translate.instant('stats.stat.jobNum'),
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<span class="ml-5"><a href=""ng-click="grid.appScope.VIEWGE(row.entity)">{{row.entity.jobNum}}&nbsp;</a><span class="glyphicon glyphicon-ok-circle"ng-if="row.entity.tkState == \'3\'"></span></span>'},
                { field: 'poNum', 
                    displayName: $translate.instant('stats.stat.poNum'), 
                    minWidth:120},
                { field: 'clientName', 
                    displayName: $translate.instant('stats.stat.filterByClient'), 
                    minWidth:120},
                { field: 'shipper', 
                    displayName: $translate.instant('stats.stat.filterByShiperCnee'), 
                    minWidth:120},                
                { field: 'shipmentType', 
                    displayName: $translate.instant('stats.stat.shipmentType'), 
                    minWidth:120},
                { field: 'bizType', 
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.bizType')},
                { field: 'deliveryToName',
                    displayName: $translate.instant('stats.stat.deliveryto'), 
                    minWidth:120},
                { field: 'ctnNumStr', 
                    displayName: $translate.instant('stats.stat.ctnNumsStr'), 
                    minWidth:120},    
                { field: 'ctnTypeNum',
                    visible: false,
                    displayName: $translate.instant('stats.stat.ctnQuantityStr'), 
                    minWidth:120,
                    footerCellTemplate: '<div >{{grid.appScope.ctnTypeStr}}</div>'},
                { field: 'atd', 
                    displayName: $translate.instant('stats.stat.atd'), 
                    minWidth:120},
                { field: 'ata', 
                    displayName: $translate.instant('stats.stat.ata'), 
                    minWidth:120},
                { field: 'transferOutTime', 
                    displayName: $translate.instant('stats.stat.transferOutTime'), 
                    minWidth:120},
                { field: 'deliveryAppointmentTime', 
                    displayName: $translate.instant('stats.stat.deliveryAppointmentTime'), 
                    minWidth:120},
                { field: 'deliveryTime', 
                    displayName: $translate.instant('stats.stat.deliveryTime'), 
                    minWidth:120},                
                { field: 'ctnReturnTime', 
                    displayName: $translate.instant('stats.stat.ctnReturnTime'), 
                    minWidth:120},
                { field: 'ageingAtdDelivery', 
                    displayName: $translate.instant('stats.stat.ageingAtdDelivery'), 
                    cellTemplate: '<div class="ui-grid-cell-contents" ng-if="row.entity.ageingAtdDelivery">{{row.entity.ageingAtdDelivery}} DAYS</div>',
                    minWidth:120},
                { field: 'ageingAtdReturn', 
                    displayName: $translate.instant('stats.stat.ageingAtdReturn'), 
                    cellTemplate: '<div class="ui-grid-cell-contents" ng-if="row.entity.ageingAtdReturn">{{row.entity.ageingAtdReturn}} DAYS</div>',
                    minWidth:120},
                { field: 'teus', 
                    type:'number',
                    visible: false,
                    displayName: $translate.instant('stats.stat.teu'), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'pkgs', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.pkgs'), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'gw', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.gw'), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'vol', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.vol'), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'chargeWeightKg', 
                    type:'number',
                    visible: false,
                    displayName: $translate.instant('stats.stat.chargeWeight') + "(KG)", 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'chargeWeightCbm', 
                    type:'number',
                    visible: false,
                    displayName: $translate.instant('stats.stat.chargeWeight') + "(CBM)", 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:100},
                { field: 'porName', 
                    minWidth:120,
                    visible: false,
                    displayName: $translate.instant('stats.stat.porName')},
                { field: 'polName', 
                    displayName: $translate.instant('stats.stat.polName'), 
                    minWidth:120},
                { field: 'podName', 
                    displayName: $translate.instant('stats.stat.podName'), 
                    visible: false,
                    minWidth:120},
                { field: 'destName',
                    displayName: $translate.instant('stats.stat.destName'), 
                    minWidth:120},
                { field: 'destCountryCode',
                    visible: false,
                    displayName: $translate.instant('stats.stat.destCountryCode'), 
                    minWidth:120},      
                { field: 'shiptoPostcode', 
                    minWidth:120,
                    visible: false,
                    displayName: $translate.instant('stats.stat.shiptoPostcode')},
                { field: 'cargoReadyDate', 
                    displayName: $translate.instant('stats.stat.cargoReadyDate'), 
                    minWidth:120},
                { field: 'bizDate', 
                    minWidth:120, 
                    displayName: $translate.instant('stats.stat.bizDate')}, 
                { field: 'etd', 
                    displayName: $translate.instant('stats.stat.guideRates.etd'), 
                    minWidth:120},
                { field: 'eta', 
                    displayName: $translate.instant('stats.stat.guideRates.eta'), 
                    minWidth:120},
                // { field: 'vsl', 
                //     displayName: $translate.instant('stats.stat.vsl'), 
                //     minWidth:120},
                // { field: 'voy', 
                //     displayName: $translate.instant('stats.stat.voy'), 
                //     minWidth:120},
                // { field: 'carrier', 
                //     displayName: $translate.instant('stats.stat.bcarrier'), 
                //     minWidth:120},
                { field: 'deliveryChannel', 
                    displayName: $translate.instant('stats.stat.deliveryChannel'), 
                    visible: false,
                    minWidth:120},
                { field: 'productName', 
                    displayName: $translate.instant('stats.stat.productName'), 
                    minWidth:120},               
                { field: 'shipmentId', 
                    minWidth:120,
                    visible: false,
                    displayName: "Shipment ID"},
                { field: 'mblNum', 
                    minWidth:120,
                    displayName: $translate.instant('stats.stat.mblNum')},
                { field: 'fbaRef', 
                    minWidth:120,
                    visible: false,
                    displayName: "Reference ID"},
                { field: 'createTime', 
                    displayName: $translate.instant('stats.stat.createTime'), 
                    visible: false,
                    minWidth:120},
                { field: 'paymentStatus', 
                    displayName: $translate.instant('stats.stat.paymentPaidDate'), 
                    cellTemplate: '<div class="ui-grid-cell-contents" ng-show="row.entity.paymentStatus"><span ng-class="row.entity.paymentStatus == \'paymentPaid\'?\'label label-primary\':\'label label-info\'" translate="stats.stat.{{row.entity.paymentStatus}}"></span></div>',
                    minWidth:120},
                { field: 'totalRMB', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.freightBaseAmt', {currency: baseCur}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:150},
                { field: 'uncollectedRMB', 
                    type:'number',
                    displayName: $translate.instant('stats.stat.balBaseAmt', {currency: baseCur}), 
                    treeAggregationType: uiGridGroupingConstants.aggregation.SUM,
                    treeAggregationLabel: "Sub: ",
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    minWidth:150}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 163;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }     
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);                   
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    $scope.gridOptions.exporterExcelFilename = getTitleStr();
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "comStats";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);                   
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    $scope.gridOptions.exporterExcelFilename = getTitleStr();
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "comStats";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }, {
                title: $translate.instant('entity.action.exportSelExcel'),
                action: function($event) {
                    var jsonObj = {};
                    $scope.gridOptions.exporterExcelFilename = getTitleStr();
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = $scope.gridApi.selection.getSelectedRows();
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "comStats";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 5
            }, {
                title: $translate.instant('entity.action.delSelected'),
                action: function($event) {
                    for(var i = $scope.gridOptions.data.length - 1; i >= 0; i--){
                        var row = $scope.gridApi.grid.getRow($scope.gridOptions.data[i]);
                        if(row.isSelected){
                            $scope.gridOptions.data.splice(i, 1);
                        }
                    }
                    $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );          
                },
                order: 6
            }, {
                title: $translate.instant('entity.action.delNotSelected'),
                action: function($event) {
                    for(var i = $scope.gridOptions.data.length - 1; i >= 0; i--){
                        var row = $scope.gridApi.grid.getRow($scope.gridOptions.data[i]);
                        if(!row.isSelected){
                            $scope.gridOptions.data.splice(i, 1);
                        }
                    }
                    $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );                    
                },
                order: 7
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi; 
                $scope.gridApi.treeBase.on.rowExpanded($scope,function(row) {
                	resize();
                });
                gridApi.core.on.filterChanged($scope, function() {
                    clearTimeout(filterTimer);
                    filterTimer = setTimeout(function(){
                        getCtnTypeStr();
                    }, 1000);//查询延时时间，单位ms
                });
            }
        }
        var gridStateObj = {};
        var gridStateCopy = null;
        var locationPath = $location.path();
        $scope.isHostStats = locationPath.indexOf("shipment-tracking") !== -1;
        $timeout(function (){     
            loadHostCompanyId();
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 163
            }, function(result){
                gridStateObj = result;
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }   
                if(gridStateObj.gridState){
                    $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                }                
            })

        });
        var removeGridCol = function() {
            if(!$rootScope.HOST_COMPANY_ID){
                return;
            }
            if(!$rootScope.account.companyId){
                for(var i = $scope.gridOptions.columnDefs.length - 1; i >= 0; i--){                
                    if($scope.gridOptions.columnDefs[i].field == 'clientName'
                        || $scope.gridOptions.columnDefs[i].field == 'shipper'){
                        $scope.gridOptions.columnDefs.splice(i, 1);
                    }
                    else if($scope.gridOptions.columnDefs[i].field == 'mblNum' 
                        && $rootScope.HAVENOAUTH('SS_VIEW_MBL')){
                        $scope.gridOptions.columnDefs.splice(i, 1);
                    }
                    else if($scope.gridOptions.columnDefs[i].field == 'ctnNumStr' 
                        && $rootScope.HAVENOAUTH('SS_VIEW_CTNR')){
                        $scope.gridOptions.columnDefs.splice(i, 1);
                    }
                }
            }
            if($rootScope.HOST_COMPANY_ID == 326){
                for(var i = $scope.gridOptions.columnDefs.length - 1; i >= 0; i--){                
                    if($scope.gridOptions.columnDefs[i].field == 'deliveryChannel'
                        || $scope.gridOptions.columnDefs[i].field == 'productName'
                        || $scope.gridOptions.columnDefs[i].field == 'totalRMB'){
                        $scope.gridOptions.columnDefs.splice(i, 1);
                    }
                }
            }
            else {
                for(var i = $scope.gridOptions.columnDefs.length - 1; i >= 0; i--){                
                    if($scope.gridOptions.columnDefs[i].field == 'bizType'){
                        $scope.gridOptions.columnDefs.splice(i, 1);
                    }
                }
            }
        }

        var loadHostCompanyId = function() {
            if(!$rootScope.HOST_COMPANY_ID && $rootScope.account.id){
                OpsCompany.getCompanyByUserId({userId: $rootScope.account.id}, function(result){
                    $rootScope.HOST_COMPANY_ID = result.companyId;
                    removeGridCol();
                })
            }
            else {
                removeGridCol();
            }
        }


        var filterTimer;
        var removeCurCol = function(field) {
            if(!columnDefsCopy || !columnDefsCopy.length){
                return;
            }
            for(var i = columnDefsCopy.length - 1; i >= 0; i--){
                if(columnDefsCopy[i].field && columnDefsCopy[i].field.indexOf('rev') == 0 && columnDefsCopy[i].field.length == 6){
                    columnDefsCopy.splice(i, 1);
                }
            }
        }

        var columnDefsCopy = angular.copy($scope.gridOptions.columnDefs);
        removeCurCol();
        $scope.setIsShipper = function(isShipper) {
            $scope.statParams.isShipper = isShipper;
        };

        $scope._onClientSelected = function(data) {
            $scope.statParams.venderId = data.id;
            $scope.statParams.clientName = data.companyName;
        }

	}
})();