(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsUnclaimedCargoDialogController', WmsUnclaimedCargoDialogController);

    WmsUnclaimedCargoDialogController.$inject = ['$timeout', '$rootScope', '$scope', '$uibModalInstance', '$translate', '$uibModal', 
    'WmsCargo', 'uiGridConstants'];

    function WmsUnclaimedCargoDialogController ($timeout, $rootScope, $scope, $uibModalInstance, $translate, $uibModal,
        WmsCargo, uiGridConstants) {
        var vm = this;

        vm.clear = clear;
        $scope.isSaving = false;

        $timeout(function (){
            WmsCargo.unclaimedCargo(function(results){
                $scope.gridOptions.data = results;
                $scope.gridOptions.totalItems = results.length;
                $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            })
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        $scope.claim = function (wmsCargo) {
            $uibModal.open({
                templateUrl: 'app/entities/wms-cargo/wms-claim-cargo-dialog.html', 
                controller: 'WmsClaimCargoDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        return wmsCargo;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsCargo');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {                
                var index = $scope.gridOptions.data.indexOf(wmsCargo);
                $scope.gridOptions.data.splice(index, 1);
                $scope.gridOptions.totalItems = $scope.gridOptions.data.length;
                $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            }, function() {
            });
        }

        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: true,      

            enableGridMenu : true,//表格 菜单  
            showGridFooter: false,//表格的footer  
            showColumnFooter: true,//每列footer

            enableFiltering: true,//高级过滤            

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: 'unclaimed-cargo.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],         

            paginationPageSizes: [20, 35, 50],
            paginationPageSize: 20,
            useExternalPagination: false,

            minimumColumnSize: 70,
            columnDefs: [
                { field: 'id', 
                    width: 70,
                    displayName: "", 
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<button type="button"ng-click="grid.appScope.claim(row.entity)"class="btn btn-danger btn-grid ml-5"><span class="glyphicon glyphicon-ok"></span>&nbsp;<span translate="autopsApp.wmsIn.claim">claim</span></button>'},
                { field: 'returnTracking', 
                    displayName: $translate.instant('autopsApp.wmsIn.returnTracking'),
                    cellTemplate: '<span class="ml-5">{{row.entity.returnTracking}} <span class="glyphicon glyphicon-link"ng-if="grid.appScope.account.companyId && grid.appScope.account.companyId != row.entity.companyId" data-toggle="tooltip" data-placement="top" title="Virtual Warehouse Cargo"></span></span>'},
                { field: 'pkgs', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.wmsIn.pkgs'), 
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    cellTemplate: '<span class="ml-5">{{row.entity.pkgs}} {{row.entity.packageType}}</span>'},
                { field: 'gw', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.wmsIn.gw'), 
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'vol', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.wmsIn.vol'), 
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    cellTemplate: '<span class="label label-info text-center ml-5">{{row.entity.length}} X {{row.entity.width}} X {{row.entity.height}} = {{row.entity.vol}}</span>'}
            ],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
            }
        }


    }
})();
