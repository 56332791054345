(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('WoHead', WoHead);

    WoHead.$inject = ['$resource', 'DateUtils'];

    function WoHead ($resource, DateUtils) {
        var resourceUrl =  'api/wo-heads/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.submitTime = DateUtils.convertDateTimeFromServer(data.submitTime);
                        data.endTime = DateUtils.convertDateTimeFromServer(data.endTime);
                        data.completionDate = DateUtils.convertLocalDateFromServer(data.completionDate);
                    }
                    return data;
                }
            },
            'addWoBody': {
                method: 'PUT',
                url:'api/wo-heads/addWoBody',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.submitTime = DateUtils.convertDateTimeFromServer(data.submitTime);
                        data.endTime = DateUtils.convertDateTimeFromServer(data.endTime);
                        data.completionDate = DateUtils.convertLocalDateFromServer(data.completionDate);
                    }
                    return data;
                }
            },
            'kpiExport': {
                method: 'GET',
                url:'api/wo-heads/kpiExport'
            },     
            'getWoHeadByKey': {
                method: 'GET',
                url:'api/wo-heads/getWoHeadByKey'
            },
            'changeType': {
                method: 'GET',
                url:'api/wo-heads/changeType'
            },
            'closeWoByCus': {
                method: 'GET',
                url:'api/wo-heads/closeWoByCus'
            },
            'openWo': {
                method: 'GET',
                url:'api/wo-heads/openWo'
            },
            'getWorkload': {
                method: 'GET',
                url:'api/wo-heads/getWorkload'
            },
            'technicalSupport': {
                method: 'GET',
                url:'api/wo-heads/technicalSupport'
            },
            'auditRequired': {
                method: 'GET',
                url:'api/wo-heads/auditRequired'
            },    
            'workLoad': {
                method: 'GET',
                url:'api/wo-heads/workLoad'
            },  
            'setServiceRating': {
                method: 'GET',
                url:'api/wo-heads/setServiceRating'
            },
            'setSubmitterRating': {
                method: 'GET',
                url:'api/wo-heads/setSubmitterRating'
            },
            'updateInternalRemarks': {
                method: 'GET',
                url:'api/wo-heads/updateInternalRemarks'
            },
            'updateWoTitle': {
                method: 'GET',
                url:'api/wo-heads/updateWoTitle'
            },             
            'knowledgeBase': {
                method: 'GET',
                url:'api/wo-heads/knowledgeBase'
            },       
            'urgentTag': {
                method: 'GET',
                url:'api/wo-heads/urgentTag'
            },     
            'fastReply': {
                method: 'GET',
                url:'api/wo-heads/fastReply',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.submitTime = DateUtils.convertDateTimeFromServer(data.submitTime);
                        data.endTime = DateUtils.convertDateTimeFromServer(data.endTime);
                        data.completionDate = DateUtils.convertLocalDateFromServer(data.completionDate);
                    }
                    return data;
                }
            },                      
            'getFilterPage': {
                method: 'POST',
                url:'api/wo-heads/getFilterPage',
                isArray: true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.completionDateFM = DateUtils.convertLocalDateToServer(data.completionDateFM);
                    data.completionDateTO = DateUtils.convertLocalDateToServer(data.completionDateTO);
                    return angular.toJson(data);
                }
            },
            'getPendingWo': {
                method: 'POST',
                url:'api/wo-heads/getPendingWo',
                isArray: true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.completionDateFM = DateUtils.convertLocalDateToServer(data.completionDateFM);
                    data.completionDateTO = DateUtils.convertLocalDateToServer(data.completionDateTO);
                    return angular.toJson(data);
                }
            },
            'getPendingWoAmt': {
                method: 'GET',
                url:'api/wo-heads/getPendingWoAmt'
            },
            'getOnePendingWo': {
                method: 'GET',
                url:'api/wo-heads/getOnePendingWo'
            },
            'closeWo': {
                method: 'PUT',
                url:'api/wo-heads/closeWo'
            },            
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.completionDate = DateUtils.convertLocalDateToServer(data.completionDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    data.completionDate = DateUtils.convertLocalDateFromServer(data.completionDate);       
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.completionDate = DateUtils.convertLocalDateToServer(data.completionDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    data.completionDate = DateUtils.convertLocalDateFromServer(data.completionDate);       
                    return data;
                }
            }
        });
    }
})();
