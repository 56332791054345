(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BookingCtnsDeleteController', BookingCtnsDeleteController);

    BookingCtnsDeleteController.$inject = ['$rootScope', '$scope', '$uibModalInstance', '$timeout', '$translate', 'entity', 'GuideCtns', 'DateUtils'];
    
	function BookingCtnsDeleteController($rootScope, $scope, $uibModalInstance, $timeout, $translate, entity, GuideCtns, DateUtils) {

        var guide = entity.guide;
        $scope.ctn = entity.ctn;

        $scope.sms = {};

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $timeout(function (){
            $scope.sms.forwarder = $rootScope.account.companyName;
            var truckCopy = null;
            if(guide && guide.booking && guide.booking.bookingTrucks && guide.booking.bookingTrucks.length > 0){
                for (var i = 0; i < guide.booking.bookingTrucks.length; i++) {
                    if (guide.booking.bookingTrucks[i].truckerVid == $scope.ctn.truckerId) {
                        truckCopy = angular.copy(guide.booking.bookingTrucks[i]);
                        break;
                    }
                }
            }
            if (truckCopy) {
                $scope.sms.mob = truckCopy.attnMob;
                if (truckCopy.soNum) {
                    $scope.sms.soNum = truckCopy.soNum;
                }
            } else {
                if (guide.shipperMob) {
                    $scope.sms.mob = guide.shipperMob;
                }
                else if (guide.booking.bookingTrucks && guide.booking.bookingTrucks[0] && guide.booking.bookingTrucks[0].attnMob){
                    $scope.sms.mob = guide.booking.bookingTrucks[0].attnMob;
                }
                else {
                    $scope.sms.mob = "";
                }
                $scope.sms.soNum = guide.soNum;
            }
        });

        $scope.smSend = function() {
            if (!$scope.sms.mob) {
                layer.msg($translate.instant('entity.action.smNull'));
                return;
            }
            var myreg = /^1\d{10}$/;
            $scope.sms.mob = $scope.sms.mob.replace(/\s+/g, "");
            if (!myreg.test($scope.sms.mob)) {
                var alertContent = $scope.sms.mob + ": " + $translate.instant('global.messages.validate.email.mobInvalid');
                layer.msg(alertContent);
                return;
            }

            //TODO 发送短信
            $scope.ctn.smsSent = true;
            GuideCtns.updateOne({
                mob: $scope.sms.mob,
                soNum: $scope.sms.soNum,
                forwarder: $scope.sms.forwarder,
                isExport: guide.isExport
            }, $scope.ctn, function(result) {
                layer.msg($translate.instant('global.send_success'));
                $uibModalInstance.dismiss('cancel');
            }, function(error) {
                layer.msg($translate.instant('global.send_fail'));
            });
        };

    }
})();
