(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('WmsSubPackingListSearch', WmsSubPackingListSearch);

    WmsSubPackingListSearch.$inject = ['$resource'];

    function WmsSubPackingListSearch($resource) {
        var resourceUrl =  'api/_search/wms-sub-packing-lists/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
