
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('JsExpressionDetailController', JsExpressionDetailController);

    JsExpressionDetailController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', '$translate', '$uibModal', 
    'entity', 'JsExpression'];

    function JsExpressionDetailController ($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, $translate, $uibModal, 
        entity, JsExpression) {
        var vm = this;

        vm.jsExpression = entity;
        vm.clear = clear;
        vm.save = save;
        vm.jsBeautify = jsBeautify;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();     
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            if(!vm.jsExpression.itemName){
                layer.msg($translate.instant('autopsApp.jsExpression.itemRequired'));
                return;
            }
            vm.isSaving = true;
            if (vm.jsExpression.id) {
                vm.jsExpression.editor = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                JsExpression.update(vm.jsExpression, function (result) {
                    vm.jsExpression = result;
                    layer.msg($translate.instant('global.save_success'));
                    vm.isSaving = false;
                }, onSaveError);
            } else {
                vm.jsExpression.companyId = $rootScope.account.companyId;
                vm.jsExpression.editor = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                JsExpression.save(vm.jsExpression, function (result) {
                    angular.copy(result, vm.jsExpression);
                    layer.msg($translate.instant('global.save_success'));
                    vm.isSaving = false;
                }, onSaveError);
            }
        }        

        function onSaveError () {
            vm.isSaving = false;
            layer.msg($translate.instant('global.save_fail'));
        }

        $scope._onItemSelected = function(data) {
            vm.jsExpression.itemId = data.id;
        }
        function jsBeautify (obj, fd) {
            obj[fd] = js_beautify(obj[fd]);
        }

        

    }
})();
