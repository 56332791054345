(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('GuideTemplate', GuideTemplate);

    GuideTemplate.$inject = ['$resource','DateUtils'];
    
    function GuideTemplate($resource, DateUtils) {
        return $resource('api/guideTemplates/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getAll': {
                method:'GET',
                url:'api/guideTemplatesAll',
                isArray:true
            },
            'update': { method:'PUT' }
        });
    }
})();
