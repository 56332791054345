(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CustomDeclarationGoodsController', CustomDeclarationGoodsController);

    CustomDeclarationGoodsController.$inject = ['$rootScope', '$scope', '$state', 'WinAES', 'CustomDeclarationGoods', 'CustomDeclarationGoodsSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'WmsWarehouse'];

    function CustomDeclarationGoodsController ($rootScope, $scope, $state, WinAES, CustomDeclarationGoods, CustomDeclarationGoodsSearch, ParseLinks, AlertService, pagingParams, paginationConstants, WmsWarehouse) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        vm.warehouseSelected = warehouseSelected;
        vm.hsCodeSelected = hsCodeSelected;

        function warehouseSelected (warehouse) {
            $rootScope.warehouseId = warehouse.id;
            $rootScope.warehouseName = warehouse.name;
            if ($rootScope.warehouseId){
                CustomDeclarationGoods.getAllHsCode({warehouseId: $rootScope.warehouseId}, function(result) {
                    $rootScope.hsCodes = result;
                });
            }
            loadAll();
        }

        function hsCodeSelected (hsCode) {
            $rootScope.hsCode = hsCode.key;
            loadAll();
        }
        loadAll();
        function loadAll () {
            if (!$rootScope.warehouseId || !$rootScope.hsCode){
                return;
            }
            if (pagingParams.search) {
                CustomDeclarationGoodsSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                CustomDeclarationGoods.query({
                    warehouseId: $rootScope.warehouseId,
                    hsCode: $rootScope.hsCode,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.customDeclarationGoods = data;
                angular.forEach(vm.customDeclarationGoods, function(data){
                    data.encodeId = WinAES.encode(data.declareId.toString())
                })
                vm.page = pagingParams.page;
            }
            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function getAllWareHouse() {
            if($rootScope.warehouses && $rootScope.warehouses.length > 0){
                return;
            }
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false
                }, function (data) {
                $rootScope.warehouses = data;
            });
        }

        getAllWareHouse();



    }
})();
