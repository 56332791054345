(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsCompanyFunctionSettingsDialogController', OpsCompanyFunctionSettingsDialogController);

        OpsCompanyFunctionSettingsDialogController.$inject = ['$timeout', '$rootScope', '$scope', '$translate', '$uibModalInstance',
    'entity', 'OpsCompany'];

    function OpsCompanyFunctionSettingsDialogController ($timeout, $rootScope, $scope, $translate, $uibModalInstance,
        entity, OpsCompany) {
        var vm = this;
        vm.clear = clear;
        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
        function loadData(){
            // 对应公司已选选项
            OpsCompany.getCustomizedFunction({
                companyId: entity.id
            }, function(result) {
                vm.companyFunctionList = result;
            })

            OpsCompany.getAllCustomizedFunction(function(result) {
                vm.allFunctionList = result;
            })
        }
        loadData();

        $scope.ChangeSwitch = function(item){
            OpsCompany.changeCustomizedFunction({
                companyId: entity.id,
                customizedFunction: item
            },function(result){
                vm.companyFunctionList = result;
                $translate.refresh();
                layer.msg($translate.instant('global.save_success'));
            }, $rootScope.ALERT_ERROR)
        }
    }
})();