(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsGroupDeleteController',OpsGroupDeleteController);

    OpsGroupDeleteController.$inject = ['$uibModalInstance', 'entity', 'OpsGroup'];

    function OpsGroupDeleteController($uibModalInstance, entity, OpsGroup) {
        var vm = this;

        vm.opsGroup = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            OpsGroup.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
