(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CarrierController', CarrierController);
    CarrierController.$inject = ['$rootScope', '$scope', '$state','OpsFiles','$translate'];

    function CarrierController ($rootScope, $scope, $state,OpsFiles,$translate) {
       
        var vm = this;
        $scope.scroll = function (id) {  
        $("html,body").animate({scrollTop:$('#' + id).offset().top},1000)
        }
        $scope.goIntroduction = function () {  
            $state.go('introduction');
        }
        $scope.goTariff = function () {  
            $state.go('tariff');
        }
        $scope.goFeatures = function () {  
            $state.go('features');
        }
        $scope.goFaq = function () {  
            $state.go('faq');
        }
        $scope.goChangeLog = function () {  
            $state.go('changelog');
        }
        $scope.goCarrier = function () {  
            $state.go('carrier');
        }
        $scope.goFeatureswms = function () {  
            $state.go('features-wms');
        }
        $scope.goProjectCase = function () {  
            $state.go('project-case');
        }
        $scope.carrierChoosed = false;
        $scope.carrierChoosedLabel = "";
        $scope.carrierLoading = false;
        $scope.carriers = new Array(
            {"name":"OOCL 东方海外","code":1014,"logo":"content/images/oocl.png"},
            {"name":"NYK 日本邮船","code":1015,"logo":"content/images/nyk.png"},
            {"name":"CMA 法国达飞","code":1016,"logo":"content/images/cma.png"},
            {"name":"HPL 赫伯罗特","code":1017,"logo":"content/images/hpl.png"},
            {"name":"COSCON 中远集运","code":1018,"logo":"content/images/coscon.png"},
            {"name":"MSC 地中海","code":1019,"logo":"content/images/msc.png"},
            {"name":"HMM 现代商船","code":1020,"logo":"content/images/hmm.png"},
            {"name":"APL 美国总统","code":1021,"logo":"content/images/apl.png"},
            {"name":"EMC 长荣海运","code":1022,"logo":"content/images/emc.png"}
        );
        
        $scope.search = function(){
            
            if(angular.isUndefined($scope.searchQuery) || $scope.searchQuery== ''){
                layer.msg($translate.instant('global.ctnWarnMiss'));
                return;
            }
            $scope.carrierLoading = true;
            OpsFiles.goGetTrackingInfo({queryCtnNum: $scope.searchQuery,carrierCode:$scope.carrierCode},function(result){
                if(result.success){
                    $scope.trackingCtnResult  = result.moveList;
                    var carrierTag = "";
                    var carrierTitle = "";
                    switch ($scope.carrierCode) {
                    case 1014:
                        carrierTitle = "OOCL " +  $scope.searchQuery;
                        break;
                    case 1015:
                        carrierTitle = "NYK " +  $scope.searchQuery;
                        break;
                    case 1016:
                        carrierTitle = "CMA " +  $scope.searchQuery;
                        break;
                    case 1017:
                        carrierTitle = "HPL " +  $scope.searchQuery;
                        break;
                    case 1018:
                        carrierTitle = "COSCON " +  $scope.searchQuery;
                        break;
                    case 1019:
                        carrierTitle = "MSC " +  $scope.searchQuery;
                        break;
                    case 1020:
                        carrierTitle = "HMM " +  $scope.searchQuery;
                        break;
                    case 1021:
                        carrierTitle = "APL " +  $scope.searchQuery;
                        break;
                    case 1022:
                        carrierTitle = "EMC " +  $scope.searchQuery;
                        break;
                    default:
                        break;
                    }
                    
                   layer.open({
                          type: 1,
                          area: ['880px', '600px'],
                          title: carrierTitle,
                          maxmin: true,
                          shade:0,
                          content: $("#searchResult")
                      });
                }else{
                    if(result.cause == 1003){
                        $rootScope.OPS_ALERT($translate.instant('global.trackingTimeOut'));
                    }
                    if(result.cause == 1004){
                        $rootScope.OPS_ALERT($translate.instant('global.noRecord'));
                    }
                    if(result.cause == 1006){
                        $rootScope.OPS_ALERT($translate.instant('global.carrierUnsupported'));
                    }
                }
                $scope.carrierLoading = false;
           },function(error){
             $scope.carrierLoading = false;
             $rootScope.OPS_ALERT($translate.instant('global.queryFailed'));
           });
        }        
        $scope.chooseThisCarrier = function(carrier){            
            $scope.carrierChoosedLabel = carrier.name;
            $scope.carrierCode = carrier.code;
            $scope.carrierChoosed = true;
        }
        
    }
})();
