(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('opsRates', {
                parent: 'entity',
                url: '/opsRatess',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'autopsApp.opsRates.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/opsRates/opsRatess.html',
                        controller: 'OpsRatesController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsRates');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('opsRates.detail', {
                parent: 'entity',
                url: '/opsRates/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'autopsApp.opsRates.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/opsRates/opsRates-detail.html',
                        controller: 'OpsRatesDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsRates');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'OpsRates', function($stateParams, OpsRates) {
                        return OpsRates.get({id : $stateParams.id});
                    }]
                }
            })
            .state('opsRates.new', {
                parent: 'opsRates',
                url: '/new',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/opsRates/opsRates-dialog.html',
                        controller: 'OpsRatesDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    currency: null,
                                    price: null,
                                    item: null,
                                    count: null,
                                    payer: null,
                                    payerId: null,
                                    paymentTerm: null,
                                    remarks: null,
                                    unit: null,
                                    itemId: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('opsRates', null, { reload: true });
                    }, function() {
                        $state.go('opsRates');
                    })
                }]
            })
            .state('opsRates.edit', {
                parent: 'opsRates',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/opsRates/opsRates-dialog.html',
                        controller: 'OpsRatesDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['OpsRates', function(OpsRates) {
                                return OpsRates.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('opsRates', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('opsRates.delete', {
                parent: 'opsRates',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/opsRates/opsRates-delete-dialog.html',
                        controller: 'OpsRatesDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['OpsRates', function(OpsRates) {
                                return OpsRates.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('opsRates', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }
})();
