(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GuideTemplateDeleteController', GuideTemplateDeleteController);

    GuideTemplateDeleteController.$inject = ['$rootScope', '$scope', '$uibModalInstance', 'entity', 'GuideTemplate'];
    
	function GuideTemplateDeleteController($rootScope, $scope, $uibModalInstance, entity, GuideTemplate) {

        $scope.guideTemplate = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        setTimeout(function () {   
            if (!$rootScope.id){
                $uibModalInstance.dismiss('cancel');
            }
        }, 500);  
        $scope.confirmDelete = function (id) {
            GuideTemplate.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }
})();
