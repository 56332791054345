(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('js-expression', {
            parent: 'entity',
            url: '/js-expression?page&sort&search',
            data: {
                authorities: ['FUN_CARRIER'],
                pageTitle: 'autopsApp.jsExpression.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/js-expression/js-expressions.html',
                    controller: 'JsExpressionController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('jsExpression');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('js-expression.new', {
            parent: 'js-expression',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/js-expression/js-expression-detail.html',
                    controller: 'JsExpressionDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('js-expression', null, { reload: 'js-expression' });
                }, function() {
                    $state.go('js-expression');
                });
            }]
        })
        .state('js-expression.edit', {
            parent: 'js-expression',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/js-expression/js-expression-detail.html',
                    controller: 'JsExpressionDetailController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['JsExpression', 'WinAES', function(JsExpression, WinAES) {
                            return JsExpression.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('js-expression', null, { reload: 'js-expression' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('js-expression.delete', {
            parent: 'js-expression',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/js-expression/js-expression-delete-dialog.html',
                    controller: 'JsExpressionDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['JsExpression', 'WinAES', function(JsExpression, WinAES) {
                            return JsExpression.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('js-expression', null, { reload: 'js-expression' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
