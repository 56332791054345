(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsCarrierController', OpsCarrierController);

    OpsCarrierController.$inject = ['$timeout', '$rootScope', '$scope', '$state', '$translate',
    'WinAES', 'OpsCarrier', 'Principal', 'OpsCarrierSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function OpsCarrierController ($timeout, $rootScope, $scope, $state, $translate,
        WinAES, OpsCarrier, Principal, OpsCarrierSearch, ParseLinks, AlertService, pagingParams, paginationConstants) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})){
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                OpsCarrierSearch.query({
                    query: pagingParams.search,
                    type: 1,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                OpsCarrier.query({
                    type: 1,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.opsCarriers = data;
                angular.forEach(vm.opsCarriers, function(data){
                    data.encodeId = WinAES.encode(data.id.toString());
                })
                vm.page = pagingParams.page;
            }
            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = true;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
        $timeout(function (){
            $rootScope.RESIZE();
        });
        $scope.groupShare = function(){
            if($rootScope.HAVENOAUTH('GROUP_SHARE')){
                $rootScope.FORBIDDEN_BY_ALERT('GROUP_SHARE');
                return;
            }
            layer.confirm($translate.instant('global.groupShareAlert'), {
                icon: 3,
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                layer.close(index);
                layer.load(1, {shade: 0.3});
                OpsCarrier.groupShare(function(){
                    layer.closeAll();
                    $rootScope.OPS_ALERT($translate.instant('global.shareSuccess'));
                }, function(){
                    layer.closeAll();
                    $rootScope.OPS_ALERT($translate.instant('global.save_fail'));
                });
            }, function() {
            });
        }
    }
})();
