(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('Booking', Booking);

    Booking.$inject = ['$resource','DateUtils'];
    
    function Booking($resource, DateUtils) {
        return $resource('api/bookings/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'lockBooking': {
                method:'GET',
                url:'api/bookings/lock'
            },
            'getFilterPage': {
                method:'PUT',
                url:'api/bookings/getFilterPage', 
                isArray: true
            },
            'getHblsCount': {
                method:'GET',
                url:'api/booking/getHblsCount'
            },
            'plConfirm': {
                method:'GET',
                url:'api/bookings/plConfirm',
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    data.lastModifiedTime = DateUtils.convertDateTimeFromServer(data.lastModifiedTime);        
                    return data;
                }
            },            
            'delHbl': {
                method:'DELETE',
                url:'api/bookings/delHbl'
            },
            'checkCargoPic': {
                method:'PUT',
                url:'api/bookings/checkCargoPic'
            }, 
            'getWorkLoad': {
                method:'GET',
                url:'api/bookings/getWorkLoad'
            },
            'getBookingTerm': {
                method:'GET',
                url:'api/bookings/getBookingTerm'
            },            
            'getTrucksAndBroker': {
                method:'GET',
                url:'api/bookings/getTrucksAndBroker'
            },
            'getHbls': {
                method:'GET',
                url:'api/bookings/getHbls', 
                isArray: true,
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    angular.forEach(data, function(data) {
                        data.etd = DateUtils.convertLocalDateFromServer(data.etd);
                        data.issueDate = DateUtils.convertLocalDateFromServer(data.issueDate);
                    });
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.etd = DateUtils.convertLocalDateToServer(data.etd);
                    data.atd = DateUtils.convertLocalDateToServer(data.atd);
                    data.issueDate = DateUtils.convertLocalDateToServer(data.issueDate);
                    data.cargoReadyDate = DateUtils.convertLocalDateToServer(data.cargoReadyDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    data.lastModifiedTime = DateUtils.convertDateTimeFromServer(data.lastModifiedTime);
                    data.etd = DateUtils.convertLocalDateFromServer(data.etd);
                    data.atd = DateUtils.convertLocalDateFromServer(data.atd);
                    data.issueDate = DateUtils.convertLocalDateFromServer(data.issueDate);
                    data.cargoReadyDate = DateUtils.convertLocalDateFromServer(data.cargoReadyDate);
                    if (data.bookingTrucks){
                        for(var i=0; i<data.bookingTrucks.length; i++){
                            data.bookingTrucks[i].loadTime = DateUtils.convertDateTimeFromServer(data.bookingTrucks[i].loadTime);
                        }
                    }
                    return data;
                }
            }
        });
    }
})();
