(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsSubPackingListDetailController', WmsSubPackingListDetailController);

    WmsSubPackingListDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'WmsSubPackingList'];

    function WmsSubPackingListDetailController($scope, $rootScope, $stateParams, previousState, entity, WmsSubPackingList) {
        var vm = this;

        vm.wmsSubPackingList = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('autopsApp:wmsSubPackingListUpdate', function(event, result) {
            vm.wmsSubPackingList = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
