(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('bookingTruck', {
                parent: 'ops',
                url: '/bookingTrucks',
                data: {
                    authorities: ['ROLE_TRUCKER', 'ROLE_OPERATOR'],
                    pageTitle: 'autopsApp.bookingTruck.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/bookingTruck/bookingTrucks.html',
                        controller: 'BookingTruckController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bookingTruck');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('bookingTruck.detail', {
                parent: 'app',
                url: '/bookingTruck',
                data: {
                    authorities: ['ROLE_TRUCKER', 'ROLE_OPERATOR'],
                    pageTitle: 'autopsApp.bookingTruck.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/bookingTruck/bookingTruck-detail.html',
                        controller: 'BookingTruckDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bookingTruck');
                        return $translate.refresh();
                    }]
                }
            })
            .state('bookingTruck.new', {
                parent: 'bookingTruck',
                url: '/new',
                data: {
                    authorities: ['ROLE_TRUCKER', 'ROLE_OPERATOR'],
                },
                onEnter: ['$rootScope', '$state', '$uibModal', function($rootScope, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/bookingTruck/bookingTruck-dialog.html',
                        controller: 'BookingTruckDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function() {
                                return {
                                    bookingId: null,
                                    loadTime: null,
                                    loadAddress: null,
                                    attn: null,
                                    attnMob: null,
                                    attnTel: null,
                                    attnFax: null,
                                    attnEmail: null,
                                    remarks: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('bookingTruck', null, {
                            reload: true
                        });
                    }, function() {
                        $state.go('bookingTruck');
                    })
                }]
            })
            .state('bookingTruck.edit', {
                parent: 'bookingTruck',
                url: '/edit',
                data: {
                    authorities: ['ROLE_TRUCKER', 'ROLE_OPERATOR'],
                },
                onEnter: ['$rootScope', '$state', '$uibModal', function($rootScope, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/bookingTruck/bookingTruck-dialog.html',
                        controller: 'BookingTruckDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['BookingTruck', function(BookingTruck) {
                                if ($rootScope.id) {
                                    return BookingTruck.getOne({
                                        id: $rootScope.id
                                    });
                                }
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('bookingTruck', null, {
                            reload: true
                        });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }
})();