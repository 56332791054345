(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('OpsPorts', OpsPorts);

    OpsPorts.$inject = ['$resource'];

    function OpsPorts ($resource) {
        var resourceUrl =  'api/ops-ports/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getAll': {
                method:'GET',
                url:'api/ops-ports/getAll',
                isArray:true
            },
            'deleteAllPorts': {
                method:'DELETE',
                url:'api/ops-ports/deleteAllPorts'
            },
            'getFBAPorts': {
                method:'GET',
                url:'api/ops-ports/getFBAPorts',
                isArray:true
            },
            'submitSearch': {
                method:'POST',
                url:'api/ops-ports/submitSearch', 
                isArray: true
            },             
            'groupShare': {
                method:'GET',
                url:'api/ops-ports/groupShare'
            },
            'groupShareOne': {
                method:'PUT',
                url:'api/ops-ports/groupShareOne'
            },
            'refreshExisting': {
                method:'PUT',
                url:'api/ops-ports/refreshExisting'
            },                    
            'update': { method:'PUT' }
        });
    }
})();
