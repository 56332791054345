(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WriteOffRecordsDeleteController',WriteOffRecordsDeleteController);

    WriteOffRecordsDeleteController.$inject = ['$uibModalInstance', '$uibModal', 'entity', 'WriteOffRecords'];

    function WriteOffRecordsDeleteController($uibModalInstance, $uibModal, entity, WriteOffRecords) {
        var vm = this;

        vm.writeOffRecords = entity.writeOffRecords;
        vm.dnNum = entity.dnNum;
        vm.clear = clear;
        vm.viewWriteOff = viewWriteOff;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function viewWriteOff (record) {
            $uibModal.open({
                templateUrl: 'app/entities/write-off/write-off-dialog.html',
                controller: 'WriteOffDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: ['WriteOff', function(WriteOff) {
                        return WriteOff.get({id : record.writeOffId}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('writeOff');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {
                // $state.go('^', {}, { reload: false });
            }, function() {
                // $state.go('^');
            });
        }


    }
})();
