(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountCashierDetailController', AccountCashierDetailController);

    AccountCashierDetailController.$inject = [];

    function AccountCashierDetailController() {
        var vm = this;
    }
})();
