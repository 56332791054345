(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('OpsBill', OpsBill);

    OpsBill.$inject = ['$resource', 'DateUtils'];

    function OpsBill ($resource, DateUtils) {
        var resourceUrl =  'api/ops-bills/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.issueDate = DateUtils.convertLocalDateFromServer(data.issueDate);
                        data.deadlineDate = DateUtils.convertLocalDateFromServer(data.deadlineDate);
                    }
                    return data;
                }
            },
            'getAll': { 
                method: 'GET', 
                url:'api/ops-bills/getAll',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        angular.forEach(data, function(data){
                            data.issueDate = DateUtils.convertLocalDateFromServer(data.issueDate);
                            data.deadlineDate = DateUtils.convertLocalDateFromServer(data.deadlineDate);
                        })                        
                    }
                    return data;
                }
            },      
            'getBillNo': { 
                method: 'PUT', 
                url:'api/ops-bills/getBillNo',
                transformRequest: function (data) {
                    if (!data){return;}
                    data = angular.copy(data);
                    data.issueDate = DateUtils.convertLocalDateToServer(data.issueDate);
                    data.deadlineDate = DateUtils.convertLocalDateToServer(data.deadlineDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data){
                        data = angular.fromJson(data);
                        data.issueDate = DateUtils.convertLocalDateFromServer(data.issueDate);    
                        data.deadlineDate = DateUtils.convertLocalDateFromServer(data.deadlineDate);                    
                    }
                    return data;
                }
            },    
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    if (!data){return;}
                    data = angular.copy(data);
                    data.issueDate = DateUtils.convertLocalDateToServer(data.issueDate);
                    data.deadlineDate = DateUtils.convertLocalDateToServer(data.deadlineDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data){
                        data = angular.fromJson(data);
                        data.issueDate = DateUtils.convertLocalDateFromServer(data.issueDate);        
                        data.deadlineDate = DateUtils.convertLocalDateFromServer(data.deadlineDate);                
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    if (!data){return;}
                    data.issueDate = DateUtils.convertLocalDateToServer(data.issueDate);
                    data.deadlineDate = DateUtils.convertLocalDateToServer(data.deadlineDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data){
                        data = angular.fromJson(data);
                        data.issueDate = DateUtils.convertLocalDateFromServer(data.issueDate);      
                        data.deadlineDate = DateUtils.convertLocalDateFromServer(data.deadlineDate);                  
                    }
                    return data;
                }
            },
            'saveBill': {
                method: 'POST',
                url:'api/ops-bills/saveBill',
                transformRequest: function (data) {
                    if (!data){return;}
                    data.opsBill.issueDate = DateUtils.convertLocalDateToServer(data.opsBill.issueDate);
                    data.opsBill.deadlineDate = DateUtils.convertLocalDateToServer(data.opsBill.deadlineDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data){
                        data = angular.fromJson(data);
                        data.issueDate = DateUtils.convertLocalDateFromServer(data.issueDate);      
                        data.deadlineDate = DateUtils.convertLocalDateFromServer(data.deadlineDate);                  
                    }
                    return data;
                }
            },
            'delBill': {
                method: 'POST',
                url:'api/ops-bills/delBill'
            },
            'checkBillAu': {
                method: 'PUT',
                url:'api/ops-bills/checkBillAu'
            },
            'checkBillNum': {
                method: 'POST',
                url:'api/ops-bills/checkBillNum'
            },
            'searchByNums': {
                method: 'PUT',
                url:'api/ops-bills/searchByNums',
                isArray: true
            },      
            'batchDcnPrint':{
                method:'POST',
                url:'api/ops-bills/batchDcnPrint',
                responseType: 'arraybuffer',
                isArray:false,
                transformResponse: function (data) {
                    var result = new Object();                    
                    result['data'] = data;
                    return result;
                }
            },
        });
    }
})();
