(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsBillDialogController', OpsBillDialogController);

    OpsBillDialogController.$inject = ['$translate', '$scope', '$rootScope', '$uibModalInstance', '$http', 
    'entity', 'OpsFiles'];

    function OpsBillDialogController ($translate, $scope, $rootScope, $uibModalInstance, $http, 
        entity, OpsFiles) {
        var vm = this;

        vm.tempNews = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.confirmAddNews = confirmAddNews;
        vm.timingReleaseChange = timingReleaseChange;

        $scope.files = [];  

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {  
            OpsFiles.saveNews({},vm.tempNews,function(result){            
            },function(error){
            });
        }

        function confirmAddNews(){
            if ($rootScope.HAVENOAUTH('FUN_ANNOUNCEMENT')) {
                $rootScope.FORBIDDEN_BY_ALERT('FUN_ANNOUNCEMENT');   
                return;
            }
            if(vm.tempNews && vm.tempNews.subject && vm.tempNews.content){
                vm.tempNews.type = 1;
                if (!vm.tempNews.announcementTime){
                    vm.tempNews.announcementTime = new Date();
                }
                vm.isSaving = true;
                OpsFiles.saveNews({},vm.tempNews,function(result){
                    vm.tempNews = result;
                    vm.isSaving = false;
                    layer.msg($translate.instant('global.save_success'));
                },function(error){
                    vm.isSaving = false;
                    layer.msg($translate.instant('global.save_fail'));
                });
            }else{
                layer.msg($translate.instant('home.fillForm'));
            }
        }

        function timingReleaseChange(){
            if(vm.tempNews.timingRelease){
                vm.tempNews.announcementTime = new Date();
            }
            else {
                vm.tempNews.announcementTime = null;
            }
        }       

        vm.datePickerForAnnouncementTime = {};
        vm.datePickerForAnnouncementTime.status = {
            opened: false
        };
        vm.datePickerForAnnouncementTimeOpen = function($event) {
            vm.datePickerForAnnouncementTime.status.opened = true;
        };


    }
})();
