(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsPortsController', OpsPortsController);

    OpsPortsController.$inject = ['$timeout', '$rootScope', '$scope', '$state', '$translate', '$uibModal', '$http',
    'WinAES', 'Principal', 'OpsPorts', 'OpsPortsSearch', 'ParseLinks', 'AlertService', 'OpsFiles',
    'pagingParams', 'paginationConstants'];

    function OpsPortsController ($timeout, $rootScope, $scope, $state, $translate, $uibModal, $http,
        WinAES, Principal, OpsPorts, OpsPortsSearch, ParseLinks, AlertService, OpsFiles,
        pagingParams, paginationConstants) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})){
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        loadAll();
        vm.isAdvanceSearch = false;

        function loadAll () {
            if (vm.searchQuery) {
                OpsPortsSearch.query({
                    query: vm.searchQuery,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                OpsPorts.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.opsPorts = data;
                angular.forEach(vm.opsPorts, function(data){
                    data.encodeId = WinAES.encode(data.id.toString())
                })
                vm.page = pagingParams.page;
                vm.isAdvanceSearch = false;
            }
            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = true;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.isAdvanceSearch = false;
            vm.searchQuery = vm.currentSearch = null;
            vm.transition();
            // loadAll();
        }

        $timeout(function (){
            $rootScope.RESIZE();
        });

        $scope.groupShare = function(){
            if($rootScope.HAVENOAUTH('GROUP_SHARE')){
                $rootScope.FORBIDDEN_BY_ALERT('GROUP_SHARE');
                return;
            }
            layer.confirm($translate.instant('global.groupShareAlert'), {
                icon: 3,
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                layer.close(index);
                layer.load(1, {shade: 0.3});
                OpsPorts.groupShare(function(){
                    layer.closeAll();
                    $rootScope.OPS_ALERT($translate.instant('global.shareSuccess'));
                }, function(){
                    layer.closeAll();
                    $rootScope.OPS_ALERT($translate.instant('global.save_fail'));
                });
            }, function() {
            });
        }

        $scope.groupShareOne = function(opsPort){
            if($rootScope.HAVENOAUTH('GROUP_SHARE')){
                $rootScope.FORBIDDEN_BY_ALERT('GROUP_SHARE');
                return;
            }
            layer.confirm($translate.instant('global.groupShareAlert'), {
                icon: 3,
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                layer.close(index);
                layer.load(1, {shade: 0.3});
                OpsPorts.groupShareOne(opsPort, function(){
                    layer.closeAll();
                    $rootScope.OPS_ALERT($translate.instant('global.shareSuccess'));
                }, function(){
                    layer.msg($translate.instant('global.save_fail'));
                });
            }, function() {
            });
        }

        $scope.searchPop = function(){
            $uibModal.open({
                templateUrl: 'app/entities/ops-ports/ops-ports-detail.html',
                controller: 'OpsPortsDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                    }]
                }
            }).result.then(function(results) {
                vm.isAdvanceSearch = vm.currentSearch = true;
                vm.totalItems = vm.queryCount = results.length;
                vm.opsPorts = results;
                angular.forEach(vm.opsPorts, function(data){
                    data.encodeId = WinAES.encode(data.id.toString())
                })
            }, function() {
            });
        }
        var unsubscribe = $rootScope.$on('autopsApp:opsPortsUpdate', function(event, result) {
            var isNew = true;
            angular.forEach(vm.opsPorts, function(data){
                if(data.id == result.id){
                    angular.copy(result, data);
                    data.encodeId = WinAES.encode(data.id.toString())
                    isNew = false;
                }
            })
            if(isNew){
                loadAll();
            }
        });

        $scope.importByExcel = function  (file, type) {
            if (!file) {return;}
            var fd = new FormData();
            fd.append('file', file);
            fd.append('type', type);
            var url = "api/ops-ports/importByExcel";
            layer.msg("Importing...", {
                shade: 0.3,
                time: 200000
            });
            $http.post(url, fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    layer.closeAll();
                    loadAll();
                    if(!data){
                        layer.msg($translate.instant('global.importSuccess'));
                        return;
                    }
                    data = angular.fromJson(data);
                    $rootScope.OPS_ALERT($translate.instant('global.importExcelAlert',
                        {updated: data.updated, created: data.created, invalid: data.invalid, invalidStr: data.invalidStr}));
                }).error(function(error) {
                    layer.closeAll();
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                });
        };
        $scope.excelExport = function() {
            layer.load(1, { shade: 0.3 });
            var fileName = "ops_ports.xlsx";
            OpsFiles.exportOpsPorts({
                companyId: $rootScope.account.companyId
            }, function(result) {
                layer.closeAll();
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }
    }
})();
