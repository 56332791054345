(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsUserDialogController', OpsUserDialogController);

    OpsUserDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'OpsUser'];
    
    function OpsUserDialogController($scope, $stateParams, $uibModalInstance, entity, OpsUser) {

        $scope.opsUser = entity;
        $scope.load = function(id) {
            OpsUser.get({id : id}, function(result) {
                $scope.opsUser = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('autopsApp:opsUserUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.opsUser.id != null) {
                OpsUser.update($scope.opsUser, onSaveSuccess, onSaveError);
            } else {
                OpsUser.save($scope.opsUser, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.datePickerForCreatedTime = {};

        $scope.datePickerForCreatedTime.status = {
            opened: false
        };

        $scope.datePickerForCreatedTimeOpen = function($event) {
            $scope.datePickerForCreatedTime.status.opened = true;
        };
}
})();
