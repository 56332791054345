(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CrmQuotationDialogController', CrmQuotationDialogController);

    CrmQuotationDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$translate', '$uibModalInstance', '$uibModal', '$http',
    'JhiLanguageService', 'Dictionary', 'Principal', 'entity', 'CrmQuotation', 'OpsVender', 'CrmPrice', 'OpsPorts', 'OpsTemplate'];

    function CrmQuotationDialogController ($timeout, $scope, $rootScope, $translate, $uibModalInstance, $uibModal, $http, 
        JhiLanguageService, Dictionary, Principal, entity, CrmQuotation, OpsVender, CrmPrice, OpsPorts, OpsTemplate) {
        
        var vm = this;

        vm.crmQuotation = entity;
        var quotationCopy = angular.copy(vm.crmQuotation);
        vm.clear = clear;
        vm.save = save;

        vm.getUserClients = getUserClients;
        vm.clientSelected = clientSelected;
        vm.allCients;

        vm.addPrice = addPrice;
        vm.delPrice = delPrice;

        vm.printQuotation = printQuotation;
        vm.emailQuotation = emailQuotation;
        vm.loadOpsTemplate = loadOpsTemplate;
        vm.opstemplates = [];

        $scope.files = [];

        function getUserClients (price) {
            if(vm.allCients && vm.allCients.length > 0){
                return;
            }
            vm.allCients = OpsVender.getUserClients();
        }

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });
        function loadOpsTemplate (price) {
            if(vm.opstemplates.length > 0){
                return;
            }
            vm.opstemplates = OpsTemplate.query();
        }
        var lang = "";
        JhiLanguageService.getCurrent().then(function(language) {
            lang = language;
        });


        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        }

        function delPrice (price) {
            if (price.id) {
                CrmPrice.delete({
                    id: price.id
                });
            };
            var index = vm.crmQuotation.crmPrices.indexOf(price);
            if (index > -1) {
                vm.crmQuotation.crmPrices.splice(index, 1);
            };
        }

        function addPrice () {
            if(!vm.crmQuotation.crmPrices){
                vm.crmQuotation.crmPrices = [];
            }
            vm.crmQuotation.crmPrices.push({});
        }

        function clientSelected (client) {
            vm.crmQuotation.venderId = client.id;
            vm.crmQuotation.inquirerId = client.userId;            
            vm.crmQuotation.inquirerCompany = client.companyName;
            OpsVender.getDTO({
                id: client.id
            }, function(result) {
                if(result.contactList && result.contactList.length > 0){
                    var contact = result.contactList[0];
                    angular.forEach(result.contactList, function(data){
                        if(data.defaultPic){
                            contact = data;
                        }
                    })
                    vm.crmQuotation.inquirerName = contact.attn;
                    vm.crmQuotation.emailTo = contact.email;
                    vm.crmQuotation.emailCc = contact.cc;
                    vm.crmQuotation.inquirerTel = contact.tel;
                    vm.crmQuotation.inquirerFax = contact.fax;
                    vm.crmQuotation.inquireMob = contact.mob;                    
                }
            })
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        } 
        function getVolume () {
            if(!vm.crmQuotation.volume && (vm.crmQuotation.gw || vm.crmQuotation.vol)){
                vm.crmQuotation.volume = (vm.crmQuotation.gw?vm.crmQuotation.gw + "KGS ":"") + (vm.crmQuotation.vol?vm.crmQuotation.vol + "CBM":"");
            }
            vm.crmQuotation.companyId = $rootScope.account.companyId;
            vm.crmQuotation.quoterId = $rootScope.account.id;
            vm.crmQuotation.quoterName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
        }
        function save () {
            getVolume ();
            vm.crmQuotation.quotationNum = getQuotationNum();
            vm.isSaving = true;
            if (vm.crmQuotation.id !== null) {
                CrmQuotation.update(vm.crmQuotation, onSaveSuccess, onSaveError);
            } else {
                CrmQuotation.save(vm.crmQuotation, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('autopsApp:crmQuotationUpdate', result);
            vm.crmQuotation = result;
            quotationCopy = angular.copy(vm.crmQuotation);
            // $uibModalInstance.close(result);
            layer.msg($translate.instant('global.save_success'));
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submittedfailed'));
        }

        var getQuotationNum = function() {
            var now = new Date();
            var year = now.getFullYear();
            var month = (now.getMonth() + 1).toString();
            var day = (now.getDate()).toString();
            var hours = (now.getHours()).toString();
            var min = (now.getMinutes()).toString();
            var sec = (now.getSeconds()).toString();
            if (month.length == 1) {
                month = "0" + month;
            }
            if (day.length == 1) {
                day = "0" + day;
            }
            if (hours.length == 1) {
                hours = "0" + hours;
            }
            if (min.length == 1) {
                min = "0" + min;
            }
            var qNum = "Q" + year + month + day + hours + min;
            return qNum;
        }
        $scope.shipmentsTypes = [{
            id: 'FCL',
            name: $translate.instant('global.FCL')
        }, {
            id: 'AIR',
            name: $translate.instant('global.AIR')
        }, {
            id: 'LCL',
            name: $translate.instant('global.LCL')
        }, {
            id: 'EXPRESS',
            name: $translate.instant('global.EXPRESS')
        }, {
            id: 'FBASEA',
            name: $translate.instant('global.FBASEA')
        }, {
            id: 'FBAAIR',
            name: $translate.instant('global.FBAAIR')
        }, {
            id: 'FBARAIL',
            name: $translate.instant('global.FBARAIL')
        }];

        $scope.weekDays = [{
            id: 1,
            name: $translate.instant('global.weekDay.Mon')
        }, {
            id: 2,
            name: $translate.instant('global.weekDay.Tue')
        }, {
            id: 3,
            name: $translate.instant('global.weekDay.Wed')
        }, {
            id: 4,
            name: $translate.instant('global.weekDay.Thu')
        }, {
            id: 5,
            name: $translate.instant('global.weekDay.Fri')
        }, {
            id: 6,
            name: $translate.instant('global.weekDay.Sat')
        }, {
            id: 7,
            name: $translate.instant('global.weekDay.Sun')
        }];

        var loadPorts = function(type) {
            if ($rootScope.OPSPORTS.airports && $rootScope.OPSPORTS.airports.length > 0){
                return;
            }
            OpsPorts.getAll(function(result){
                $rootScope.OPSPORTS.airports = [];
                $rootScope.OPSPORTS.seaports = [];
                angular.forEach(result, function(data){
                    if(data.type == 1){
                        $rootScope.OPSPORTS.seaports.push(data);
                    }
                    else {
                        $rootScope.OPSPORTS.airports.push(data);
                    }
                })
                $scope.shipmentTypeChange(type);
            })
        };

        $scope.shipmentTypeChange = function (type){
            if ($rootScope.OPSPORTS.airports && $rootScope.OPSPORTS.airports.length > 0){
                if(type == "AIR" || type == "FBAAIR" || type == "EXPRESS" ){
                    $scope.portList = $rootScope.OPSPORTS.airports;
                }
                else{
                    $scope.portList = $rootScope.OPSPORTS.seaports;
                }
            }
            else {
                loadPorts(type);
            }
        }
        $scope.getQuotation = function(crmPrice) {
            if(!vm.crmQuotation.venderId){
                layer.msg($translate.instant('autopsApp.crmQuotation.clientNullAlert'));
                return;
            }

            var booking = {};
            if(crmPrice){
                booking.shipmentType = crmPrice.type;
                booking.polName = crmPrice.pol;
                booking.destName = crmPrice.pod;    
            }          
            booking.fromCrmQuotation = true;
            booking.isNew = true;
            booking.vol = vm.crmQuotation.vol;
            booking.gw = vm.crmQuotation.gw;
            booking.isEn = vm.crmQuotation.isEn;
            booking.crmClient = {id: vm.crmQuotation.venderId};

            $uibModal.open({
                templateUrl: 'app/entities/ops-cost/ops-cost-detail.html',
                controller: 'OpsCostDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return booking;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsCost');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                if(!vm.crmQuotation.crmPrices){
                    vm.crmQuotation.crmPrices = [];
                }
                angular.forEach(result.crmPrices, function(data){
                    vm.crmQuotation.crmPrices.push(data);
                })
            }, function() {
            });
        }

        $scope.textPop = function(crmPrice, fd) {
            $rootScope.notBlInfo = true;
            $uibModal.open({
                templateUrl: 'app/entities/ops-change-log/ops-change-log-delete-dialog.html',
                controller: 'OpsChangeLogDeleteController',
                controllerAs: 'vm',
                // backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return crmPrice[fd];
                    }]
                }
            }).result.then(function(result) {
                crmPrice[fd] = result;
            }, function() {});
        }

        function printQuotation(reportCode){
            vm.isSaving= true;
            getVolume ()
            vm.crmQuotation.reportCode = reportCode;
            CrmQuotation.printQuotation({},vm.crmQuotation,function(result){
                vm.isSaving= false;
                $rootScope.PRINTBYTES(result.data);
            },function(error){
                vm.isSaving= false;
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        function emailQuotation(reportCode){
            if (!angular.equals(quotationCopy, vm.crmQuotation)) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };
            if(!vm.crmQuotation.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if(!vm.crmQuotation.venderId){
                layer.msg($translate.instant('autopsApp.crmQuotation.clientNullAlert'));
                return;
            }
            vm.isSaving= true;
            getVolume ()
            vm.crmQuotation.reportCode = reportCode;

            var email = {};
            email.attachments = [];
            email.head = vm.crmQuotation.inquirerCompany;
            email.subject = vm.crmQuotation.emailSubject;

            email.to = vm.crmQuotation.emailTo + "; " + $rootScope.account.email + "; ";
            email.cc = vm.crmQuotation.emailCc;
            email.content = "Dear " + vm.crmQuotation.inquirerName + ",\n\n" + $translate.instant('entity.email.crmQuotation') + "\n\n";
            email.content += (vm.crmQuotation.isEn?"Volume: ":"货量: ") + vm.crmQuotation.volume + "\n\n";
            angular.forEach(vm.crmQuotation.crmPrices, function(price){
                if(price.type == "FCL"){
                    email.content += (vm.crmQuotation.isEn?"FCL Service":"海运整柜") + "\n";
                }
                else if(price.type == "LCL"){
                    email.content += (vm.crmQuotation.isEn?"LCL Service":"海运拼箱") + "\n";
                }
                else if(price.type == "AIR"){
                    email.content += (vm.crmQuotation.isEn?"AIR Service":"航空货运") + "\n";
                }
                else if(price.type == "EXPRESS"){
                    email.content += (vm.crmQuotation.isEn?"EXPRESS":"快递/专线") + "\n";
                }
                else if(price.type == "FBASEA"){
                    email.content += (vm.crmQuotation.isEn?"FBA SEA":"FBA海运") + "\n";
                }
                else if(price.type == "FBARAIL"){
                    email.content += (vm.crmQuotation.isEn?"FBA RAIL":"FBA铁路") + "\n";
                }
                else{
                    email.content += (vm.crmQuotation.isEn?"FBA AIR":"FBA空运") + "\n";
                }   
                email.content += price.content + "\n\n";
            })
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.files = $scope.files;
            entity.email = email;
            entity.emailType = "crmQuotation";
            // entity.code = reportCode;
            CrmQuotation.getAttachment({},vm.crmQuotation,function(result){
                entity.fileResult = result[0];
                sendEmail(entity);
                vm.isSaving= false;
            },function(error){
                vm.isSaving= false;
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        function sendEmail(entity){            
            $uibModal.open({
                templateUrl: 'app/entities/email/email-dialog.html',
                controller: 'EmailDialogController',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {

            }, function(result) {});            
        }

        $scope.showContactList = function() {
            $uibModal.open({
                templateUrl: 'app/entities/guide/contact-list.html',
                controller: 'ContactListController',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return OpsVender.getContactsList({id: vm.crmQuotation.venderId}).$promise;
                    }]
                }
            }).result.then(function(contact) {
                vm.crmQuotation.inquirerId = contact.userId;
                vm.crmQuotation.inquirerName = contact.attn;
                vm.crmQuotation.emailTo = contact.email;
                vm.crmQuotation.emailCc = contact.cc;
                vm.crmQuotation.inquirerTel = contact.tel;
                vm.crmQuotation.inquirerFax = contact.fax;
                vm.crmQuotation.inquireMob = contact.mob;
                save();
            }, function() {});
        }
    }
})();
