(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BookingTemplateDialogController', BookingTemplateDialogController);

    BookingTemplateDialogController.$inject = ['$translate', '$scope', '$uibModalInstance', 'entity', 'BookingTemplate'];

    function BookingTemplateDialogController($translate, $scope, $uibModalInstance, entity, BookingTemplate) {

        $scope.bookingTemplate = entity;
        // $translatePartialLoader.addPart('bookingTemplate');
        // $translate.refresh();

        var onSaveSuccess = function (result) {
            // $scope.$emit('autopsApp:bookingTemplateUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
            layer.msg($translate.instant('global.save_success'));
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('global.save_fail'));
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.bookingTemplate.id != null) {
                BookingTemplate.update($scope.bookingTemplate, onSaveSuccess, onSaveError);
            } else {
                BookingTemplate.save($scope.bookingTemplate, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
