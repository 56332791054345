(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ReportTemplateDeleteController', ReportTemplateDeleteController);

    ReportTemplateDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'ReportTemplate'];
    
	function ReportTemplateDeleteController($scope, $uibModalInstance, entity, ReportTemplate) {

        $scope.reportTemplate = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            ReportTemplate.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }
})();
