(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('ScanBoxService', ScanBoxService);

    ScanBoxService.$inject = ['$q'];
    
    function ScanBoxService($q) {
        var initMsg = $q.defer();
        var resultMsg = $q.defer();
        var newMsg = $q.defer();
        
        return {
            //######################## 初始化 #############################
        	startScan: function() {
        		Quagga.init({
            		inputStream: {
                        type : "LiveStream",
                        constraints: {
                            width: {min: 640},
                            height: {min: 480},
                            facingMode: "environment",
                            aspectRatio: {min: 1, max: 2}
                        }
                    },
                    locator: {
                        patchSize: "medium",
                        halfSample: true
                    },
                    numOfWorkers: 4,
                    decoder: {
                        readers : [{
                            format: "code_128_reader",
                            config: {}
                        }]
                    },
                    locate: true
            	  }, function(err) {
            	      if (err) {
            	    	  resultMsg.reject(err);
            	      }else{
            	    	  Quagga.start();
                	      //扫描中
                	      Quagga.onProcessed(function(result){
                	    	  //画框
                	    	  var drawingCtx = Quagga.canvas.ctx.overlay,
            	              drawingCanvas = Quagga.canvas.dom.overlay;
                	    	  if (result) {
        	        	            if (result.boxes) {
        	        	                drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
        	        	                result.boxes.filter(function (box) {
        	        	                    return box !== result.box;
        	        	                }).forEach(function (box) {
        	        	                    Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {color: "green", lineWidth: 2});
        	        	                });
        	        	            }
        	        	            if (result.box) {
        	        	                Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: "#00F", lineWidth: 2});
        	        	            }
        	
        	        	            if (result.codeResult && result.codeResult.code) {
        	        	                Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {color: 'red', lineWidth: 3});
        	        	            }
        	        	       }
                	      });
                	      //识别出结果
                	      Quagga.onDetected(function(data){
                             var result = new Object();
              	    	     result.success = true;
              	    	     result.code = data.codeResult.code;
              	    	     resultMsg.notify(result);
                    	  });
            	      }
            	  });
        		return resultMsg.promise;
            },
            endAnalysis:function(){
            	Quagga.offDetected();
            },
            restartScan:function(){
                Quagga.start();
            },
            endScan:function(){
                Quagga.stop();
            }
            
        }


    }
})();