(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CustomConditionDialogController', CustomConditionDialogController);

    CustomConditionDialogController.$inject = ['$timeout', '$scope', '$translate', '$uibModalInstance', '$rootScope', 
        'entity', 'CustomNode', 'Dictionary', 'DataUtils'];

    function CustomConditionDialogController ($timeout, $scope, $translate, $uibModalInstance, $rootScope,
        entity, CustomNode, Dictionary, DataUtils) {
        var vm = this;

        vm.fbArray = entity.fbArray;
        vm.role = entity.role;
        vm.shipmentType = entity.shipmentType;
        vm.clear = clear;
        vm.save = save;

        var comNodes = ["createdTime", "deliveryGuideTime", "bookingTime", "soReleaseTime", "truckArrangeTime", "otherTimeOne", "otherTimeTwo", "customArrangeTime",
            "customReleaseTime", "atd", "hblReleasedTime",  "mblReleasedTime", "ataTime", "customsInspectionExp", "customsInspection", "customClearTime", "ctnAvailableTime", "otherTimeThree", "deliveryAppointmentTime",
            "deliveryTime", "closeTime", "dnSentTime", "invoiceIssuedTime", "invoiceReceivedTime", "paymentPaidTime", "paymentReceivedTime", "localTimeTwo", "isApplied", "isClosed"];

        var fmsNodes = ["ctnsLoadedTime", "vgmTime", "BKsiReceivedTime", "siSubmitedTime", "hblConfirmedTime", "mblConfirmedTime",
            "saSentTime", "localTimeOne"];

        var airNodes = ["BKsiReceivedTime", "plConfirmTime", "siSubmitedTime", "hblConfirmedTime", "mblConfirmedTime",
            "saSentTime"];

        var fbaNodes = ["plConfirmTime", "ctnsLoadedTime"];

        var afExNodes = ["dnSentTime", "invoiceIssuedTime", "invoiceReceivedTime", "paymentPaidTime", "paymentReceivedTime"];

        var headBtnNodes = ["ediCenterBtn", "cloudFilesBtn", "labelBtn", "othersBtn", "ratesBtn",
            "refNumTitle", "productChannelTitle", "cargoVolumeTitle", "vslScheduleTitle", "contactInfoTitle",
            "cargoDetailsTitle", "intracompanyTitle", "routeBilTitle", "fastLabelPanel", "packingListPanel", "tcPanel", "ctnsPanel", "nodesPanel"];

        function addComNodes(outFds) {
            angular.forEach(comNodes, function(data){
                if(outFds.indexOf(data) == -1){
                    outFds.push(data);
                }
            })
        }

        function getAllFdsByShipmentType(shipmentType){
            if(shipmentType == "AIR") {
                var outFds = angular.copy(Dictionary.airRqs);
                addComNodes(outFds);
                angular.forEach(airNodes, function(data){
                    if(outFds.indexOf(data) == -1){
                        outFds.push(data);
                    }
                })
                return outFds;
            }
            else if($rootScope.ISFBA(shipmentType) || $rootScope.ISWMS(shipmentType) || shipmentType == "FBAALL" || shipmentType == "WMSALL") {
                var outFds = angular.copy(Dictionary.fbaRqs);
                addComNodes(outFds);
                angular.forEach(fbaNodes, function(data){
                    if(outFds.indexOf(data) == -1){
                        outFds.push(data);
                    }
                })
                return outFds;
            }
            else {
                var outFds = angular.copy(Dictionary.fmsRqs);
                addComNodes(outFds);
                angular.forEach(fmsNodes, function(data){
                    if(outFds.indexOf(data) == -1){
                        outFds.push(data);
                    }
                })
                return outFds;
            }
        }

        
        function getFeilds(shipmentType) {
            if(!shipmentType){
                clear();
                return;
            }
            vm.fbArray = [];
            var allFds = getAllFdsByShipmentType(shipmentType);
            angular.forEach(allFds, function(data){
                addToBindFdList(data);
            })
            // console.log(shipmentType)
            //头部节点增加
            if(shipmentType == "AIR") {
                angular.forEach(Dictionary.airFds, function(data){
                    if(allFds.indexOf(data) == -1){
                        allFds.push(data);
                        var fd = {name: data};
                        DataUtils.getLangNameFba(fd);
                        vm.fbArray.push(fd);
                    }
                })
            }
            else if($rootScope.ISFBA(shipmentType) || $rootScope.ISWMS(shipmentType) 
                || shipmentType == "FBAALL" || shipmentType == "WMSALL") {
                angular.forEach(Dictionary.fbaFds, function(data){
                    if(allFds.indexOf(data) == -1){
                        allFds.push(data);
                        var fd = {name: data};
                        DataUtils.getLangNameFba(fd);
                        vm.fbArray.push(fd);
                    }
                })
            }
            else {
                angular.forEach(Dictionary.fmsFds, function(data){
                    if(allFds.indexOf(data) == -1){
                        allFds.push(data);
                        var fd = {name: data};
                        DataUtils.getLangNameFba(fd);
                        vm.fbArray.push(fd);
                    }
                })
            }
            angular.forEach(headBtnNodes, function(data){
                if(allFds.indexOf(data) == -1){
                    allFds.push(data);
                    var fd = {name: data};
                    DataUtils.getLangNameFba(fd);
                    vm.fbArray.splice(0, 0, fd);
                }
            })
        }

        function addToBindFdList(data) {
            var fd = {name: data};
            DataUtils.getLangNameFba(fd);
            vm.fbArray.push(fd);
        }


        $timeout(function (){
            CustomNode.getWfCustomNodes({
                shipmentType: entity.shipmentType,
                role: entity.role
            }, onGetSuccess, onSaveError);
            if(!vm.fbArray){
                getFeilds(entity.shipmentType);
            }
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            CustomNode.autoWfCustomNodes({
                shipmentType: entity.shipmentType,
                role: entity.role
            }, getWfCustomNodes(), onSaveSuccess, onSaveError);
        }

        function getWfCustomNodes () {
            var keyValueDtos = [];
            angular.forEach(vm.fbArray, function(data){
                if(data.checked){
                    keyValueDtos.push({
                        key: data.name,
                        value: data.langName
                    });
                }
            })
            return keyValueDtos;
        }
        function onGetSuccess (result) {
            var bdFileds = new Set();
            angular.forEach(result, function(data){
                bdFileds.add(data.bindFd);
            })
            angular.forEach(vm.fbArray, function(data){
                data.checked = bdFileds.has(data.name);
            })
            vm.isSaving = false;
        }

        function onSaveSuccess (result) {
            onGetSuccess(result);
            layer.msg($translate.instant('global.save_success'));
        }

        function onSaveError () {
            vm.isSaving = false;
            layer.msg($translate.instant('global.save_fail'));
        }


    }
})();
