(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AuthorityController', AuthorityController);
    AuthorityController.$inject = ['$scope'];

    function AuthorityController ($scope) {
    	var vm = this;
		$scope.scroll = function (id) {  
		$("html,body").animate({scrollTop:$('#' + id).offset().top},1000)
		}

    }
})();