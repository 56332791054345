
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ContentsPopController', ContentsPopController);

    ContentsPopController.$inject = ['$rootScope', '$scope', '$translate', '$uibModalInstance', '$uibModal', '$translatePartialLoader', 
    'entity', 'OpsFiles'];
    
    function ContentsPopController($rootScope, $scope, $translate, $uibModalInstance, $uibModal, $translatePartialLoader,
        entity, OpsFiles) {

        $scope.showList = entity;
        $translatePartialLoader.addPart('accountTitle');
        $translate.refresh();

        $scope.ttl = {};
        $scope.excelExport = function(vContents) {
            if(!vContents || vContents.length == 0){
                layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                return;
            }
            var fileName = "unmatchedAccountingEntry.xlsx";
            var ledgerEntries = [{}];
            ledgerEntries[0].voucherContents = angular.copy(vContents);
            OpsFiles.excelVoucherContent({
                singleSheet: true,
                title: "Unmatched Accounting Entry",
                enCn: $translate.instant('global.enCn')
            }, ledgerEntries, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }    

        // $timeout(function (){

        // });

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.viewVoucher = function(voucherId) {
            if(!voucherId){
                return;
            }
            $rootScope.VIEWVOUCHER(voucherId);
        }
    }
})();


(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('RatesPopController', RatesPopController);

    RatesPopController.$inject = ['$rootScope', '$scope', '$translate', '$uibModalInstance', '$timeout', '$uibModal', 
    'entity', 'DataUtils', 'AccountInvoice', 'AccountPayment', 'OpsFiles', 'GuideRates'];
    
    function RatesPopController($rootScope, $scope, $translate, $uibModalInstance, $timeout, $uibModal,
        entity, DataUtils, AccountInvoice, AccountPayment, OpsFiles, GuideRates) {

        $scope.guideRates = null;

        $timeout(function (){
            layer.load(1, {shade: 0.3});
            GuideRates.getGuideRatesDtoList(entity, function (resultList) {
                layer.closeAll(); 
                if (resultList.length > 5000) {
                    layer.confirm($translate.instant('global.dataSizeAlert', {amt: resultList.length}), {
                        btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                    }, function(index) {
                        $scope.excelExport(resultList);
                        layer.close(index);
                    }, function() {
                        $scope.guideRates = resultList;
                        getTotal();
                        initList();
                        $translate.refresh();
                    });
                }
                else {
                    $scope.guideRates = resultList;
                    getTotal();
                    initList();
                    $translate.refresh();
                } 
            })
        });

        var initList = function() {
            if ($scope.guideRates && $scope.guideRates.length > 0) {
                $scope.guideRates.sort(function(a, b) {
                    return a.venderId < b.venderId ? 1 : -1;
                });
                $scope.guideRates.sort(function(a, b) {
                    return a.jobNum < b.jobNum ? 1 : -1;
                });
            }
            var jobNumCopy = null;
            angular.forEach($scope.guideRates, function(data) {
                if(null == jobNumCopy){
                    jobNumCopy = data.jobNum;
                }
                else {
                    if(data.jobNum == jobNumCopy){
                        data.jobNum = null;
                    }
                    else {
                        jobNumCopy = data.jobNum;
                    }
                }
            })     
        }

        $scope.ttlList = [];
        var ttl = 0;
        var getTotal = function() {
            var currencys = [];
            angular.forEach($scope.guideRates, function(data) {
                if (data.payCurrency && currencys.indexOf(data.payCurrency) === -1) {
                    currencys.push(data.payCurrency);
                }
            })            
            angular.forEach(currencys, function(currency) {
                ttl = 0;
                angular.forEach($scope.guideRates, function(rate) {
                    if (rate.payCurrency == currency && rate.payAmount) {
                        if (rate.isPay) {
                            ttl = ttl - rate.payAmount;
                        } else {
                            ttl = ttl + rate.payAmount;
                        }
                    }
                })
                $scope.ttlList.push({
                    currency: currency,
                    total: DataUtils.round(ttl, 2)
                });
            })
        }

        $scope.excelExport = function(guideRates) {
            if(!guideRates || guideRates.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var fileName = $translate.instant('autopsApp.guide.viewRates') + ".xlsx";           
            OpsFiles.excelSynGuideRatesDTO({
                enCn: $translate.instant('global.enCn')
            }, guideRates, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }    

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };          
    }
})();

(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('SynPopController', SynPopController);

    SynPopController.$inject = ['$scope', '$uibModal', '$uibModalInstance', '$rootScope',
    'entity', 'AccountInvoice', 'AccountPayment'];
    
    function SynPopController($scope, $uibModal, $uibModalInstance, $rootScope,
        entity, AccountInvoice, AccountPayment) {

        $scope.guideRates = entity;

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.ratesPop = function(ratesList) {
            $uibModal.open({
                templateUrl: 'app/entities/pop-list/rates-pop.html',
                controller: 'RatesPopController',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return ratesList;
                    }]
                }
            }).result.then(function(result) {

            }, function(result) {

            });     
        }
        $scope.viewVoucher = function(voucherId) {
            $rootScope.VIEWVOUCHER(voucherId);
        }
    }
})();


(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('InvoicesPopController', InvoicesPopController);

    InvoicesPopController.$inject = ['$rootScope', '$scope', '$translate', '$uibModalInstance', '$timeout', '$translatePartialLoader', 
    'entity', 'DataUtils'];
    
    function InvoicesPopController($rootScope, $scope, $translate, $uibModalInstance, $timeout, $translatePartialLoader,
        entity, DataUtils) {

        $scope.showList = entity;
        $translatePartialLoader.addPart('accountInvoice');
        $translate.refresh();

        $scope.ttl = {};
        var getTotal = function() {
            var invoiceAmount = 0;
            var paidAmount = 0;
            angular.forEach($scope.showList, function(data){
                if (data.invoiceAmount) {
                    $scope.ttl.currency = data.currency;
                    invoiceAmount = invoiceAmount + data.invoiceAmount;
                }
                if(data.paidAmount){
                    paidAmount = paidAmount + data.paidAmount;
                }
            })
            $scope.ttl.invoiceAmount = DataUtils.round(invoiceAmount, 2);
            $scope.ttl.paidAmount = DataUtils.round(paidAmount, 2);
            $scope.ttl.balance = DataUtils.round(invoiceAmount - paidAmount, 2);
        }

        $timeout(function (){
            getTotal();
        });

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();

(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('PaymentsPopController', PaymentsPopController);

    PaymentsPopController.$inject = ['$rootScope', '$scope', '$translate', '$uibModalInstance', '$timeout', '$translatePartialLoader', 
    'entity', 'DataUtils'];
    
    function PaymentsPopController($rootScope, $scope, $translate, $uibModalInstance, $timeout, $translatePartialLoader,
        entity, DataUtils) {

        $scope.showList = entity;
        $translatePartialLoader.addPart('accountPayment');
        $translate.refresh();

        $scope.ttl = {};
        var getTotal = function() {
            var invoiceAmount = 0;
            var paidAmount = 0;
            angular.forEach($scope.showList, function(data){
                if (data.invoiceAmount) {
                    $scope.ttl.currency = data.currency;
                    invoiceAmount = invoiceAmount + data.invoiceAmount;
                }
                if(data.paidAmount){
                    paidAmount = paidAmount + data.paidAmount;
                }
            })
            $scope.ttl.invoiceAmount = DataUtils.round(invoiceAmount, 2);
            $scope.ttl.paidAmount = DataUtils.round(paidAmount, 2);
            $scope.ttl.balance = DataUtils.round(invoiceAmount - paidAmount, 2);
        }

        $timeout(function (){
            getTotal();
        });

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();

(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ShaPortPopController', ShaPortPopController);

    ShaPortPopController.$inject = ['$scope', '$uibModalInstance',  
    'entity'];
    
    function ShaPortPopController($scope, $uibModalInstance, 
        entity) {

        $scope.data = entity;        

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

    }
})();

(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ShaPortListController', ShaPortListController);

    ShaPortListController.$inject = ['$scope', '$uibModalInstance', '$uibModal', 
    'entity'];
    
    function ShaPortListController($scope, $uibModalInstance, $uibModal,
        entity) {

        $scope.dataList = entity;

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.viewDetails = function(data) {
            $uibModal.open({
                templateUrl: 'app/entities/pop-list/sha-port-pop.html',
                controller: 'ShaPortPopController',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return data;
                    }]
                }
            }).result.then(function(result) {
            }, function(result) {
            });
        };

    }
})();