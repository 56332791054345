(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountPaymentDialogController', AccountPaymentDialogController);

    AccountPaymentDialogController.$inject = ['$scope', '$uibModalInstance', '$rootScope', 'entity', 'AccountPayment', 'OpsVender'];

    function AccountPaymentDialogController ($scope, $uibModalInstance, $rootScope, entity, AccountPayment, OpsVender) {
       
        $scope.searchParams = entity;
        var vm = this;
         vm.isSaving = false;
        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope._onClientSelected = function(data) {
            $scope.searchParams.venderId = data.id;
        }

        $scope._onClerkSelected = function(clerk, data) {
            $scope.searchParams[clerk] = data.id;
        }

        $scope.searchRequests = function() {
            vm.isSaving = true;
            AccountPayment.advanceSearchRequests($scope.searchParams, function(result) {
                vm.isSaving = false;
                $uibModalInstance.close(result);
            }, function(response) {
                vm.isSaving = false;

            });  
        };
        $scope.setStatus= function(fd, value) {
            $scope.searchParams[fd] = value;
        }       

    }
})();
