(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BatchInvoiceController', BatchInvoiceController);
    
    BatchInvoiceController.$inject = ['$rootScope', '$scope', '$state', '$uibModal', '$translate', '$window', '$timeout',
    'Principal', 'AccountTitle', 'OpsVender','JhiLanguageService','GuideRates','AccountInvoice', 'AccountPayment', 'AutoCheck', 'uiGridConstants',
    'GridState'];
    
    function BatchInvoiceController($rootScope, $scope, $state, $uibModal, $translate, $window, $timeout,
        Principal, AccountTitle, OpsVender, JhiLanguageService, GuideRates, AccountInvoice, AccountPayment, AutoCheck, uiGridConstants,
        GridState) {

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                $rootScope.loadCurrencys();
            });
        }
        else {
            $rootScope.loadCurrencys();
        }

        $scope.invoiceBatchDTO = {invoiceTime: new Date()};
        $scope.accountInvoices = [];
        $scope.guideRates = [];
        $scope.datePickerOpenStatus = {};

        var autoCheck;

        $timeout(function (){
            autoCheck = angular.copy($rootScope.AUTOCHECK);
            $rootScope.AUTOCHECK = null;
            if(autoCheck){
                $scope.invoiceBatchDTO.isPay = (autoCheck.isPay?true:false);
            }            
            $scope.registerTypeChange(true);
        });

        var gridStateObj = {};
        var gridStateCopy = null;
        var choosedGridStateObj = {};
        var choosedGridStateCopy = null;
        function loadGridState(){
            initColumns($scope.invoiceBatchDTO.byDn);
            $scope.choosedGridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            $scope.choosedGridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );

            gridStateCopy = $scope.gridApi.saveState.save();
            choosedGridStateCopy = $scope.choosedGridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: getGridId($scope.invoiceBatchDTO.byDn, false)
            }, function (result) {
                gridStateObj = result;
                if (!gridStateObj || !gridStateObj.id) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
                    $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
                $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
            })

            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: getGridId($scope.invoiceBatchDTO.byDn, true)
            }, function (result) {
                choosedGridStateObj = result;
                if (!choosedGridStateObj || !choosedGridStateObj.id) {
                    $scope.choosedGridApi.saveState.restore($scope, choosedGridStateCopy);
                    $scope.choosedGridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
                    $scope.choosedGridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
                    return;
                }
                $scope.choosedGridApi.saveState.restore($scope, angular.fromJson(choosedGridStateObj.gridState));
                $scope.choosedGridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
                $scope.choosedGridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );

            })
        }

        function getGridId(isDn, isChoosed){
            if(isDn){
                return isChoosed?138:137;
            }
            else {
                return isChoosed?140:139;
            }
        }

        function initColumns(byDn){
            chooseColumnDefs.splice(0,chooseColumnDefs.length);
            choosedColumnDefs.splice(0,choosedColumnDefs.length);

            if(byDn){
                angular.forEach(defaultColumnDefs, function(col){
                    chooseColumnDefs.push(col);
                    if(col.field == 'id'){
                        var colCopy = angular.copy(col);
                        colCopy.width = 35;
                        colCopy.cellTemplate = '<div align="center"><span ng-click="grid.appScope.delFromChoosed(row.entity)" class="text-danger"><span class="glyphicon glyphicon-remove"></span></span></div>'
                        choosedColumnDefs.push(colCopy);
                    }
                    else {
                        choosedColumnDefs.push(col);
                    }                    
                })
            }
            else{
                angular.forEach(rateColumnDefs, function(col){
                    chooseColumnDefs.push(col);
                    if(col.field == 'id'){
                        var colCopy = angular.copy(col);
                        colCopy.width = 35;
                        colCopy.cellTemplate = '<div align="center"><span ng-click="grid.appScope.delFromChoosed(row.entity)" class="text-danger"><span class="glyphicon glyphicon-remove"></span></span></div>'
                        choosedColumnDefs.push(colCopy);
                    }
                    else {
                        choosedColumnDefs.push(col);
                    }                    
                })
            }
            $translate.refresh();
        }


        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }
        $scope.currencyChange = function() {
            initAllList();
        }

        $scope.paymentTypeChange = function(isPay) {
            $scope.invoiceBatchDTO.isPay = isPay;
            initAllList();
        }

        $scope.registerTypeChange = function(byDn) {
            $scope.invoiceBatchDTO.byDn = byDn;
            if(autoCheck && autoCheck.idSet){  
                initAllList();
            }
            else {
                loadGridState();
                initAllList();
            }
        }
        var initAllList = function() {
            $scope.invoiceBatchDTO.accountInvoices = [];
            $scope.invoiceBatchDTO.accountPayments = [];
            $scope.invoiceBatchDTO.guideRates = [];
            $scope.accountInvoices = [];
            $scope.guideRates = [];
            $scope.chooseDnGridOptions.data = [];
            $scope.choosedDnGridOptions.data = [];
            $scope.invoiceBatchDTO.payer = null;
            $scope.invoiceBatchDTO.total = null;
            $scope.invoiceBatchDTO.invoiceAmt = null;
            loadByAutoCheck();
        }
        var loadByAutoCheck = function() {
            if(autoCheck && autoCheck.idSet){                
                autoCheck.currency = $scope.invoiceBatchDTO.currency;
                $scope.isSaving = true;
                AutoCheck.getGuideRatesForInvoiceBatch(autoCheck, function(result) {
                    $scope.isSaving = false;
                    if(!result || result.length == 0){
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.autoCheck.ratesNotLockedAlert'));    
                        return;
                    }
                    $scope.invoiceBatchDTO.currency = result[0].payCurrency;
                    for(var i = result.length - 1; i >= 0; i--){
                        if(result[i].payCurrency != $scope.invoiceBatchDTO.currency){
                            result.splice(i, 1);
                        }
                    }
                    angular.forEach(result, function(data){
                        if($scope.invoiceBatchDTO.isPay != data.isPay){
                            data.payAmount = -data.payAmount;
                        }
                    })
                    $scope.invoiceBatchDTO.byDn = false;   
                    $scope.guideRates = result;
                    $scope.chooseDnGridOptions.totalItems = $scope.guideRates.length;
                    $scope.chooseDnGridOptions.data = $scope.guideRates;
                    $scope.invoiceBatchDTO.guideRates = [];
                    updateList();
                    loadGridState();    
                }, function(response) {
                    $scope.isSaving = false;
                });                     
            }
        }

        $rootScope.LOADALLVENDERS();

        $scope._onPayerSelected = function(data) {
            if ($scope.invoiceBatchDTO.byDn){
                if ($scope.invoiceBatchDTO.isPay){
                    AccountPayment.getForInvoiceBatch({
                        companyId: $rootScope.account.companyId,
                        venderId: data.id
                    }, function(result, headers) {                
                        if (result.length == 0){
                            layer.msg($translate.instant('global.messages.resultNull'));
                            $scope.invoiceBatchDTO.payer = "";
                            return;
                        }
                        $scope.accountInvoices = result;
                        if (headers('X-Total-Count')) {
                            $scope.chooseDnGridOptions.totalItems = headers('X-Total-Count');
                        } else {
                            $scope.chooseDnGridOptions.totalItems = $scope.accountInvoices.length;
                        }
                        $scope.chooseDnGridOptions.data = $scope.accountInvoices;
                        $scope.invoiceBatchDTO.accountPayments = [];
                        updateList();
                    });

                }
                else {
                    AccountInvoice.getForInvoiceBatch({
                        companyId: $rootScope.account.companyId,
                        venderId: data.id
                    }, function(result, headers) {                
                        if (result.length == 0){
                            layer.msg($translate.instant('global.messages.resultNull'));
                            $scope.invoiceBatchDTO.payer = "";
                            return;
                        }
                        $scope.accountInvoices = result;
                        if (headers('X-Total-Count')) {
                            $scope.chooseDnGridOptions.totalItems = headers('X-Total-Count');
                        } else {
                            $scope.chooseDnGridOptions.totalItems = $scope.accountInvoices.length;
                        }
                        $scope.chooseDnGridOptions.data = $scope.accountInvoices;
                        $scope.invoiceBatchDTO.accountInvoices = [];
                        updateList();
                    });
                }
            }
            else {
                GuideRates.getForInvoiceBatch({
                    companyId: $rootScope.account.companyId,
                    venderId: data.id,
                    isPay: $scope.invoiceBatchDTO.isPay
                }, function(result, headers) {                
                    if (result.length == 0){
                        layer.msg($translate.instant('global.messages.resultNull'));
                        $scope.invoiceBatchDTO.payer = "";
                        return;
                    }
                    $scope.guideRates = result;
                    if (headers('X-Total-Count')) {
                        $scope.chooseDnGridOptions.totalItems = headers('X-Total-Count');
                    } else {
                        $scope.chooseDnGridOptions.totalItems = $scope.guideRates.length;
                    }
                    $scope.chooseDnGridOptions.data = $scope.guideRates;
                    $scope.invoiceBatchDTO.guideRates = [];
                    updateList();
                });
            }
        }
        
        $scope.searchDnNum = "";
        var updateList = function(data) {
            //未选择合计和过滤
            $scope.invoiceBatchDTO.total = 0;
            if ($scope.invoiceBatchDTO.byDn){
                for(var i = $scope.accountInvoices.length -1; i >=0; i--){
                    if($scope.accountInvoices[i].currency != $scope.invoiceBatchDTO.currency){
                        $scope.accountInvoices.splice(i, 1);                        
                    }
                    else {
                        $scope.invoiceBatchDTO.total = $scope.invoiceBatchDTO.total + $scope.accountInvoices[i].invoiceAmount; 
                    }
                }
            }
            else {
                for(var i = $scope.guideRates.length -1; i >=0; i--){
                    if($scope.guideRates[i].payCurrency != $scope.invoiceBatchDTO.currency){
                        $scope.guideRates.splice(i, 1);                        
                    }
                    else {
                        $scope.invoiceBatchDTO.total = $scope.invoiceBatchDTO.total + $scope.guideRates[i].payAmount; 
                    }
                }
            }
            $scope.invoiceBatchDTO.total = $scope.invoiceBatchDTO.total.toFixed(2) -0; 

            //以选择合计
            $scope.invoiceBatchDTO.invoiceAmt = 0;
            if ($scope.invoiceBatchDTO.byDn){
                if ($scope.invoiceBatchDTO.isPay){
                    angular.forEach($scope.invoiceBatchDTO.accountPayments, function(data){
                        $scope.invoiceBatchDTO.invoiceAmt = $scope.invoiceBatchDTO.invoiceAmt + data.invoiceAmount;
                    });
                }
                else {
                    angular.forEach($scope.invoiceBatchDTO.accountInvoices, function(data){
                        $scope.invoiceBatchDTO.invoiceAmt = $scope.invoiceBatchDTO.invoiceAmt + data.invoiceAmount;
                    });
                }
            }
            else {
                angular.forEach($scope.invoiceBatchDTO.guideRates, function(data){
                    $scope.invoiceBatchDTO.invoiceAmt = $scope.invoiceBatchDTO.invoiceAmt + data.payAmount;
                });
            }

            $scope.invoiceBatchDTO.invoiceAmt = $scope.invoiceBatchDTO.invoiceAmt.toFixed(2) -0;
            $scope.searchDnNum = "";
            resize();
        }
        
        $scope.delFromList = function (item) {
            if ($scope.invoiceBatchDTO.byDn){
                var index = $scope.accountInvoices.indexOf(item);
                if (index > -1) {
                    $scope.accountInvoices.splice(index, 1);
                    updateList();
                };     
            }
            else {
                var index = $scope.guideRates.indexOf(invoice);
                if (index > -1) {
                    $scope.guideRates.splice(index, 1);
                    updateList();
                };    
            }       
        }

        $scope.choosedList =[];
        $scope.addToList = function (item) {
            if ($scope.invoiceBatchDTO.byDn){
                if ($scope.invoiceBatchDTO.isPay){
                    var isRepeated = false;
                    for(var j = $scope.invoiceBatchDTO.accountPayments.length -1; j >=0; j--){
                        if (item.id ==$scope.invoiceBatchDTO.accountPayments[j].id){
                            isRepeated = true;
                            break;
                        }
                    }
                    if (isRepeated){
                        layer.msg($translate.instant('global.messages.repeated'));
                    }
                    else {
                        $scope.invoiceBatchDTO.accountPayments.push(item);
                        $scope.choosedDnGridOptions.data = $scope.invoiceBatchDTO.accountPayments;
                    }

                    var index = $scope.accountInvoices.indexOf(item);
                    if (index > -1) {
                        $scope.accountInvoices.splice(index, 1);
                        updateList();
                    };    
                }
                else {
                    var isRepeated = false;
                    for(var j = $scope.invoiceBatchDTO.accountInvoices.length -1; j >=0; j--){
                        if (item.id ==$scope.invoiceBatchDTO.accountInvoices[j].id){
                            isRepeated = true;
                            break;
                        }
                    }
                    if (isRepeated){
                        layer.msg($translate.instant('global.messages.repeated'));
                    }
                    else {
                        $scope.invoiceBatchDTO.accountInvoices.push(item);
                        $scope.choosedDnGridOptions.data = $scope.invoiceBatchDTO.accountInvoices;
                    }

                    var index = $scope.accountInvoices.indexOf(item);
                    if (index > -1) {
                        $scope.accountInvoices.splice(index, 1);
                        updateList();
                    };    
                }
  
            }      
            else {
                var isRepeated = false;
                for(var j = $scope.invoiceBatchDTO.guideRates.length -1; j >=0; j--){
                    if (item.id ==$scope.invoiceBatchDTO.guideRates[j].id){
                        isRepeated = true;
                        break;
                    }
                }
                if (isRepeated){
                    layer.msg($translate.instant('global.messages.repeated'));
                }
                else {
                    $scope.invoiceBatchDTO.guideRates.push(item);
                    $scope.choosedDnGridOptions.data = $scope.invoiceBatchDTO.guideRates;
                }

                var index = $scope.guideRates.indexOf(item);
                if (index > -1) {
                    $scope.guideRates.splice(index, 1);
                    updateList();
                };      
            }
        }

        $scope.delFromChoosed = function (item) {
            if ($scope.invoiceBatchDTO.byDn){
                if ($scope.invoiceBatchDTO.isPay){
                    $scope.accountInvoices.push(item);
                    var index = $scope.invoiceBatchDTO.accountPayments.indexOf(item);
                    if (index > -1) {
                        $scope.invoiceBatchDTO.accountPayments.splice(index, 1);
                        updateList();
                    };   
                }
                else {
                    $scope.accountInvoices.push(item);
                    var index = $scope.invoiceBatchDTO.accountInvoices.indexOf(item);
                    if (index > -1) {
                        $scope.invoiceBatchDTO.accountInvoices.splice(index, 1);
                        updateList();
                    };   
                }
            }
            else {
                $scope.guideRates.push(item);
                var index = $scope.invoiceBatchDTO.guideRates.indexOf(item);
                if (index > -1) {
                    $scope.invoiceBatchDTO.guideRates.splice(index, 1);
                    updateList();
                };   
            }         
        }

        $scope.addAll = function () {
            if ($scope.invoiceBatchDTO.byDn){
                for(var i = $scope.accountInvoices.length -1; i >=0; i--){      
                    $scope.addToList($scope.accountInvoices[i]);
                }
            }
            else {
                for(var i = $scope.guideRates.length -1; i >=0; i--){      
                    $scope.addToList($scope.guideRates[i]);
                }
            }
        }

        $scope.submitInvoiceNum = function () {
            $scope.isSaving = true;
            if (!$scope.invoiceBatchDTO.invoiceAmt) {
                layer.msg($translate.instant('autopsApp.accountInvoice.home.noChoosed'));
                $scope.isSaving = false;
                return;
            }
            $scope.invoiceBatchDTO.companyId = $rootScope.account.companyId;
            GuideRates.batchInvoice($scope.invoiceBatchDTO, onSaveSuccess, onSaveError);
        }

        var onSaveSuccess = function(result) {
            $scope.isSaving = false;
            initAllList();       
            layer.msg($translate.instant('global.messages.submitted'));
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('global.save_fail'));            
        };

        function resize() {
            if ($("#chooseDnGridId") && $("#chooseDnGridId").offset()) {
                var tabHeight = $scope.chooseDnGridOptions.data.length * 30 + 180;
                var winHeight = $(window).height() - $("#chooseDnGridId").offset().top - 3;
                if (tabHeight > winHeight) {
                    tabHeight = winHeight;
                }
                if (tabHeight < 300) {
                    tabHeight = 300;
                }                
                angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
            }
            if ($("#choosedDnGrid") && $("#choosedDnGrid").offset()) {
                var tabHeight = $scope.choosedDnGridOptions.data.length * 30 + 180;
                var winHeight = $(window).height() - $("#choosedDnGrid").offset().top - 3;
                if (tabHeight > winHeight) {
                    tabHeight = winHeight;
                }
                if (tabHeight < 300) {
                    tabHeight = 300;
                }                
                angular.element(document.getElementsByClassName('gridStyle')[1]).css('height', tabHeight + 'px');
            }
        }


        // 根据账单时的列
        var defaultColumnDefs = [{
            field: 'id',
            width: 60,
            displayName: "",
            pinnedLeft: true,
            enableColumnMenu: false,
            enableFiltering: false,
            aggregationType: uiGridConstants.aggregationTypes.count,
            cellTemplate: '<div align="center"><span ng-click="grid.appScope.addToList(row.entity)" class="text-primary"><span class="glyphicon glyphicon-plus"></span></span><span ng-click="grid.appScope.delFromList(row.entity)" class="text-danger" style="margin-left: 5px;"><span class="glyphicon glyphicon-remove"></span></span></div>'
        }, {
            field: 'dnNum',
            minWidth: 120,
            displayName: $translate.instant('autopsApp.accountInvoice.dnNum'),
            cellTemplate: '<div class="ui-grid-cell-contents"><a href="" ng-click="grid.appScope.EDIT_DCN(row.entity)">{{row.entity.dnNum}}</a></div>'
        }, {
            field: 'invoiceTitle',
            minWidth: 150,
            displayName: $translate.instant('autopsApp.accountInvoice.invoiceTitle')
        }, {
            field: 'invoiceAmount',
            minWidth: 100,
            displayName: $translate.instant('autopsApp.accountInvoice.invoiceAmount'),
            cellTemplate: '<span class="grid-text"><span>{{row.entity.currency}}&nbsp;</span>{{row.entity.invoiceAmount}}</span>'
        }, {
            field: 'paidAmount', 
            minWidth:100, 
            type:'number',   
            displayName: $translate.instant('autopsApp.accountInvoice.paidAmount'), 
            cellTemplate: '<span class="grid-text text-danger"><span ng-if="row.entity.paidAmount"><span>{{row.entity.currency}}&nbsp;{{row.entity.paidAmount}}</span></span>'
        }, {
            field: 'billNum',
            minWidth: 140,
            displayName: $translate.instant('autopsApp.accountInvoice.billNum')
        }, {
            field: 'jobNums',
            minWidth: 140,
            displayName: $translate.instant('autopsApp.accountInvoice.jobNums')
        }, {
            field: 'blNums',
            minWidth: 140,
            displayName: $translate.instant('autopsApp.accountInvoice.blNums')
        }, {
            field: 'sales',
            minWidth: 120,
            displayName: $translate.instant('autopsApp.accountInvoice.salesName')
        }, {
            field: 'creater',
            minWidth: 120,
            displayName: $translate.instant('autopsApp.accountInvoice.creater')
        }, {
            field: 'economicDate',
            minWidth: 150,
            displayName: $translate.instant('autopsApp.accountInvoice.economicDate'),
            cellTemplate: '<span>{{row.entity.economicDate | date:\'mediumDate\'}}</span>'
        }];


        // 根据费用时的列
        var rateColumnDefs = [{
            field: 'id',
            width: 60,
            displayName: "",
            pinnedLeft: true,
            enableColumnMenu: false,
            enableFiltering: false,
            aggregationType: uiGridConstants.aggregationTypes.count,
            cellTemplate: '<div align="center"><span ng-click="grid.appScope.addToList(row.entity)" class="text-primary"><span class="glyphicon glyphicon-plus"></span></span><span ng-click="grid.appScope.delFromList(row.entity)" class="text-danger" style="margin-left: 5px;"><span class="glyphicon glyphicon-remove"></span></span></div>'
        }, {
            field: 'dnNum',
            minWidth: 120,
            displayName: $translate.instant('autopsApp.accountInvoice.dnNum'),
            cellTemplate: '<div class="ui-grid-cell-contents"><a href="" ng-click="grid.appScope.EDIT_DCN(row.entity)">{{row.entity.dnNum}}</a></div>'
        }, {
            field: 'payer',
            minWidth: 150,
            displayName: $translate.instant('autopsApp.accountInvoice.invoiceTitle')
        }, {
            field: 'payAmount',
            minWidth: 100,
            displayName: $translate.instant('autopsApp.accountInvoice.invoiceAmount'),
            cellTemplate: '<span><span>{{row.entity.payCurrency}}&nbsp;</span>{{row.entity.payAmount}}</span>'
        }, {
            field: 'actual', 
            minWidth:100, 
            type:'number',   
            displayName: $translate.instant('autopsApp.accountInvoice.paidAmount'), 
            cellTemplate: '<span class="grid-text text-danger"><span ng-if="row.entity.actual"><span>{{row.entity.payCurrency}}&nbsp;{{row.entity.actual}}</span></span>'
        }, {
            field: 'billNum',
            minWidth: 140,
            displayName: $translate.instant('autopsApp.accountInvoice.billNum')
        }, {
            field: 'jobNum',
            minWidth: 140,
            displayName: $translate.instant('autopsApp.accountInvoice.jobNums')
        }, {
            field: 'mblNum',
            minWidth: 140,
            displayName: $translate.instant('autopsApp.accountInvoice.blNums')
        }, {
            field: 'item',
            minWidth: 120,
            displayName: $translate.instant('autopsApp.accountInvoice.chargeItem')
        }, {
            field: 'salesName',
            minWidth: 120,
            displayName: $translate.instant('autopsApp.accountInvoice.salesName')
        }, {
            field: 'remarks',
            minWidth: 120,
            displayName: $translate.instant('autopsApp.accountInvoice.remarks')
        }]

        var chooseColumnDefs = [];
        var choosedColumnDefs = [];
        initColumns(true);

        $scope.chooseDnGridOptions = {
            enableSorting: true, //排序
            useExternalSorting: false,

            enableGridMenu: true, //表格 菜单
            showGridFooter: false, //表格的footer
            showColumnFooter: false, //每列footer

            enableFiltering: true, //高级过滤
            useExternalFiltering: false,

            enableHorizontalScrollbar: 1, //表格的水平滚动条
            enableVerticalScrollbar: 1, //表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            exporterMenuCsv: false,
            exporterMenuPdf: false,
            exporterMenuExcel: false,

            minimumColumnSize: 70,
            exporterExcelFilename: "invoice",
            columnDefs: chooseColumnDefs,
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function ($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = getGridId($scope.invoiceBatchDTO.byDn, false);
                    GridState.update(gridStateObj, function (result) {
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function (error) {
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function ($event) {
                    if (gridStateObj.gridState) {
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function ($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function ($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.chooseDnGridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })

                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "accountPayment";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.chooseDnGridOptions.exporterExcelFilename);
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function ($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.chooseDnGridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "accountPayment";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.chooseDnGridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function (gridApi) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function (grid, sortColumns) {
                    if (!$scope.chooseDnGridOptions.useExternalSorting) {
                        if (sortColumns[sortColumns.length - 1]) {
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.chooseDnGridOptions.data.sort(function (a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                });
            }
        }

        $scope.choosedDnGridOptions = {
            enableSorting: true, //排序
            useExternalSorting: false,

            enableGridMenu: true, //表格 菜单
            showGridFooter: false, //表格的footer
            showColumnFooter: false, //每列footer

            enableFiltering: true, //高级过滤
            useExternalFiltering: false,

            enableHorizontalScrollbar: 1, //表格的水平滚动条
            enableVerticalScrollbar: 1, //表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            exporterMenuCsv: false,
            exporterMenuPdf: false,
            exporterMenuExcel: false,

            minimumColumnSize: 70,
            exporterExcelFilename: "charges",
            columnDefs: choosedColumnDefs,
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function ($event) {
                    choosedGridStateObj.gridState = angular.toJson($scope.choosedGridApi.saveState.save());
                    choosedGridStateObj.userId = $rootScope.account.id;
                    choosedGridStateObj.gridId = getGridId($scope.invoiceBatchDTO.byDn, true);
                    GridState.update(choosedGridStateObj, function (result) {
                        choosedGridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function (error) {
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function ($event) {
                    if (choosedGridStateObj.gridState) {
                        $scope.choosedGridApi.saveState.restore($scope, angular.fromJson(choosedGridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function ($event) {
                    $scope.choosedGridApi.saveState.restore($scope, choosedGridStateCopy);
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function ($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.choosedDnGridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.choosedGridApi.core.getVisibleRows($scope.choosedGridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.choosedGridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "accountPayment";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.choosedDnGridOptions.exporterExcelFilename);
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function ($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.choosedDnGridOptions;
                    jsonObj.saveState = $scope.choosedGridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "accountPayment";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.choosedDnGridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function (gridApi) {
                $scope.choosedGridApi = gridApi;
                $scope.choosedGridApi.core.on.sortChanged($scope, function (grid, sortColumns) {
                    if (!$scope.choosedDnGridOptions.useExternalSorting) {
                        if (sortColumns[sortColumns.length - 1]) {
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.choosedDnGridOptions.data.sort(function (a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                });
            }
        }

    }
})();