(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('VoucherContentController', VoucherContentController);

    VoucherContentController.$inject = ['$scope', '$state', 'VoucherContent', 'VoucherContentSearch'];
    
    function VoucherContentController($scope, $state, VoucherContent, VoucherContentSearch) {

        $scope.voucherContents = [];
        $scope.loadAll = function() {
            VoucherContent.query(function(result) {
               $scope.voucherContents = result;
            });
        };
        $scope.loadAll();


        $scope.search = function () {
            VoucherContentSearch.query({query: $scope.searchQuery}, function(result) {
                $scope.voucherContents = result;
            }, function(response) {
                if(response.status === 404) {
                    $scope.loadAll();
                }
            });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.voucherContent = {
                ref: null,
                ledger: null,
                subLedger: null,
                dn: null,
                cn: null,
                id: null
            };
        };
    }
})();
