(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ExpressCargoDialogController', ExpressCargoDialogController);

    ExpressCargoDialogController.$inject = ['$rootScope', '$translate', '$uibModalInstance', '$timeout', '$uibModal', 'entity', 'WmsCargo', 'DataUtils'];

    function ExpressCargoDialogController ($rootScope, $translate, $uibModalInstance, $timeout, $uibModal, entity, WmsCargo, DataUtils) {
        var vm = this;

        var wmsIn = entity.wmsIn;
        var expressParcel = entity.expressParcel;
        var guide = entity.guide;

        vm.clear = clear;
        vm.choose = choose;
        vm.chooseAll = chooseAll;
        vm.unchoose = unchoose;
        vm.unchooseAll = unchooseAll;
        vm.fastPicking = fastPicking;
        vm.wmsCargos = [];
        vm.choosedCargos = [];
        vm.submit = submit;
        vm.totalLine = {};
        vm.filterFdChange = filterFdChange;
        vm.inventoryFilter = inventoryFilter;
        vm.excelPicking = excelPicking;
        vm.filter = {};

        var venderId = null;
        var warehouseId = null;
        var jobNum = null;
        var wmsInId = null;
        var expressId = null;
        var guideId = null;
        var locationType = null;

        $timeout(function() {
            if(wmsIn){
                venderId = wmsIn.venderId;
                warehouseId = wmsIn.warehouseId;
                wmsInId = wmsIn.id;
            }
            else if (expressParcel){
                venderId = expressParcel.shipperId;
                warehouseId = expressParcel.warehouseId;
                expressId = expressParcel.id;
                locationType = $rootScope.tmsTypes.indexOf(expressParcel.shipmentType) !== -1?"TK":"KD";
            }
            else if (guide){
                venderId = guide.customerId;
                warehouseId = guide.whId;
                guideId = guide.id;
                locationType = "FB";
            }
            if(!warehouseId || !venderId){
                layer.msg($translate.instant('autopsApp.wmsIn.chooseClientAndWarehouseTips'));
                $uibModalInstance.dismiss('cancel');
            }
            loadDefault();            
        })
        function initCargos () {
            angular.forEach(vm.wmsCargos, function(data){
                data.palletAmt = DataUtils.getPalletAmt(data.availableAmt, data.palletCapacity);;
                data.spliteNum = 1;
            })
        }
        function loadDefault () {
            WmsCargo.getAll({
                clientId:venderId, 
                locationType:locationType,
                warehouseId:warehouseId
            }, function(results){
                vm.wmsCargos = results;
                layer.msg($translate.instant('autopsApp.wmsCargo.resultSize', {param : vm.wmsCargos.length}));  
                initCargos();
            });
        }

        function inventoryFilter () {
            vm.filter.companyId = $rootScope.account.companyId;      
            vm.filter.warehouseId = warehouseId;
            vm.filter.clientId = venderId;
            vm.filter.forPickup = true;
            vm.filter.locationType = locationType;
            
            // vm.filter.status = "OK";
            if(!vm.filter.cargoName 
                && !vm.filter.mark 
                && !vm.filter.code 
                && !vm.filter.sku 
                && !vm.filter.wmsInNum 
                && !vm.filter.poNum
                && !vm.filter.batchOne
                && !vm.filter.batchTwo
                && !vm.filter.batchThree
                && !vm.filter.batchFour
                && !vm.filter.truckNum
                && !vm.filter.ctnNum){
                loadDefault();
                return;
            }
            WmsCargo.inventoryQuery(vm.filter, function(result){
                vm.wmsCargos = result;
                layer.msg($translate.instant('autopsApp.wmsCargo.resultSize', {param : vm.wmsCargos.length}));  
                var isNew = true;                 
                angular.forEach(vm.choosedCargos, function(choosedData){                
                    isNew = true;
                    angular.forEach(vm.wmsCargos, function(data){
                        if(choosedData.id == data.id){
                            isNew = false;
                        }
                    })
                    if(isNew){
                        vm.wmsCargos.push(choosedData)
                    }
                })
                initCargos();
            });
        }

        vm.filterFd = {};
        function filterFdChange (fd) {
            vm.wmsCargos.sort(function(a, b) {
                if(a[fd] && angular.lowercase(a[fd]).indexOf(angular.lowercase(vm.filter[fd])) != -1){
                    return -1;
                }
                if(b[fd] && angular.lowercase(b[fd]).indexOf(angular.lowercase(vm.filter[fd])) != -1){
                    return 1;
                }
            });
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function choose (cargo) { 
            if(cargo.status != "OK"){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsCargo.notAvailableInventory'));  
                return;
            }
            var idx = vm.wmsCargos.indexOf(cargo);
            vm.wmsCargos.splice(idx, 1);

            var isNew = true;
            cargo.pkgs = cargo.availableAmt;
            angular.forEach(vm.choosedCargos, function(data){
                if(data.id == cargo.id){
                    isNew = false;
                }
            })
            if(isNew){
                cargo.palletAmt = DataUtils.getPalletAmt(cargo.pkgs, cargo.palletCapacity);
                vm.choosedCargos.push(cargo);
            }
            else {
                layer.msg($translate.instant('global.messages.repeated'));
            }
            updateTotal();   
        }

        function chooseAll () { 
            angular.forEach(vm.wmsCargos, function(cargo){
                var isNew = true;
                cargo.pkgs = cargo.availableAmt;
                angular.forEach(vm.choosedCargos, function(data){
                    if(data.id == cargo.id){
                        isNew = false;
                    }
                })
                if(isNew){
                    cargo.palletAmt = DataUtils.getPalletAmt(cargo.pkgs, cargo.palletCapacity);
                    vm.choosedCargos.push(cargo);
                }
                else {
                    layer.msg($translate.instant('global.messages.repeated'));
                }
            })
            vm.wmsCargos = [];
            updateTotal();   
        }
        function unchooseAll () { 
            angular.forEach(vm.choosedCargos, function(cargo){
                var isNew = true;
                angular.forEach(vm.wmsCargos, function(data){
                    if(data.id == cargo.id){
                        isNew = false;
                    }
                })
                if(isNew){
                    cargo.palletAmt = DataUtils.getPalletAmt(cargo.availableAmt, cargo.palletCapacity);
                    vm.wmsCargos.push(cargo);
                }
                else {
                    layer.msg($translate.instant('global.messages.repeated'));
                }
            });
            vm.choosedCargos = [];
            updateTotal();   
        }

        function unchoose (cargo) {
            var idx = vm.choosedCargos.indexOf(cargo);
            vm.choosedCargos.splice(idx, 1);

            var isNew = true;
            angular.forEach(vm.wmsCargos, function(data){
                if(data.id == cargo.id){
                    isNew = false;
                }
            })
            if(isNew){
                cargo.palletAmt = DataUtils.getPalletAmt(cargo.availableAmt, cargo.palletCapacity);
                vm.wmsCargos.push(cargo);
            }
            else {
                layer.msg($translate.instant('global.messages.repeated'));
            }
            updateTotal();   
        }
        vm.isSaving = false;
        function submit () {
            vm.isSaving = true;    
            if(wmsIn){
                WmsCargo.lockWmsInCargos({
                    wmsInId: wmsInId
                }, vm.choosedCargos, onSaveSuccess, onSaveError)
            }      
            else if(expressParcel){
                WmsCargo.lockOutCargos({
                    expressId: expressId
                }, vm.choosedCargos, onSaveSuccess, onSaveError)
            }  
            else {
                WmsCargo.lockFmsOutCargos({
                    guideId: guideId
                }, vm.choosedCargos, onSaveSuccess, onSaveError)
            }    
        }
        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError (result) {
            vm.isSaving = false;
            if (result.status == 409) {
                vm.wmsCargos = [];
                layer.msg($translate.instant('global.refreshAlert'));
            } 
            else {
                layer.msg($translate.instant('global.save_fail'));
            }
        }

        var updateTotal = function() {
            var ttlPkgs = 0, ttlPlt = 0, ttlNw = 0, ttlGw = 0, ttlVol = 0, ttlCapacity = 0, ttlPicecs = 0;
            angular.forEach(vm.choosedCargos, function(data){
                if(data.pkgs){
                    ttlPkgs += data.pkgs;
                    if(data.nw){
                        data.nwTtl = DataUtils.round(data.nw * data.pkgs, 2);
                        ttlNw += data.nwTtl;
                    }
                    if(data.gw){
                        data.gwTtl = DataUtils.round(data.gw * data.pkgs, 2);
                        ttlGw += data.gwTtl;
                    }
                    if(data.vol){
                        data.volTtl = DataUtils.round(data.vol * data.pkgs, 3);
                        ttlVol += data.volTtl;
                    }
                    if(data.capacity){
                        data.capacityTtl = DataUtils.round(data.capacity * data.pkgs, 2);
                        ttlCapacity += data.capacityTtl;
                    }
                    if(data.piece){
                        data.pieceTtl = DataUtils.round(data.piece * data.pkgs, 2);
                        ttlPicecs += data.pieceTtl;
                    }
                }
                if(data.palletAmt){
                    ttlPlt += data.palletAmt;
                }
            })
            vm.totalLine = {
                status: "Total",
                pkgs: DataUtils.round(ttlPkgs, 0),
                nwTtl: DataUtils.round(ttlNw, 2),
                gwTtl: DataUtils.round(ttlGw, 2),
                volTtl: DataUtils.round(ttlVol, 3),
                capacityTtl: DataUtils.round(ttlCapacity, 2),
                pieceTtl: DataUtils.round(ttlPicecs, 2),
                palletAmt: DataUtils.round(ttlPlt, 0)
            };
        }

        function fastPicking (cargo) {
            if(cargo.status != "OK"){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsCargo.notAvailableInventory'));  
                return;
            }
            if (isNaN(cargo.spliteNum) || parseInt(cargo.spliteNum) != cargo.spliteNum){
                layer.msg($translate.instant('autopsApp.wmsIn.notNumAlert'));
                return;
            }
            var spliteNum = cargo.spliteNum - 0;
            if(spliteNum < 0 || spliteNum > cargo.availableAmt){
                layer.msg($translate.instant('autopsApp.wmsIn.notNumAlert'));
                return;
            }
            var idx = vm.wmsCargos.indexOf(cargo);
            vm.wmsCargos.splice(idx, 1);

            var isNew = true;
            cargo.pkgs = spliteNum;
            angular.forEach(vm.choosedCargos, function(data){
                if(data.id == cargo.id){
                    isNew = false;
                }
            })
            if(isNew){
                cargo.palletAmt = DataUtils.getPalletAmt(cargo.pkgs, cargo.palletCapacity);
                vm.choosedCargos.push(cargo);
            }
            else {
                cargo.pkgs = spliteNum;
                layer.msg($translate.instant('global.messages.repeated'));
            }
            updateTotal();   
        }

        function excelPicking () {
            var entity = {
                venderId: venderId,
                warehouseId: warehouseId,
                wmsInId: wmsInId,
                expressId: expressId,
                guideId: guideId,
                locationType: locationType
            };

            $uibModal.open({
                templateUrl: 'app/entities/wms-area/wms-areas.html',
                controller: 'WmsAreaController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                $uibModalInstance.close(result);
            }, function() {});
        }


    }
})();
