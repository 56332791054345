(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('VgmHeadDialogController', VgmHeadDialogController);

    VgmHeadDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$translate', '$stateParams', '$uibModalInstance', 'entity', 'VgmHead', 'VgmCtn'];

    function VgmHeadDialogController($timeout, $scope, $rootScope, $translate, $stateParams, $uibModalInstance, entity, VgmHead, VgmCtn) {
        var vm = this;

        vm.vgmHead = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.sendVgm = sendVgm;
        vm.addVgmCtn = addVgmCtn;
        vm.delVgmCtn = delVgmCtn;
        vm.onCarrierChoose = onCarrierChoose;
        vm.onPortChoose = onPortChoose;
        vm.onClientSelected = onClientSelected;
        vm.onPierChoose = onPierChoose;
        vm.updateStatus = updateStatus;

        var vgmHeadCopy;

        function onCarrierChoose(carrierCode) {
            for (var i = 0; i < $rootScope.carrierList.length; i++) {
                if ($scope.carrierList[i].value == carrierCode) {
                    vm.vgmHead.carrierName = $scope.carrierList[i].label;
                }
            }
        }

        function onPortChoose(polId) {
            for (var i = 0; i < $rootScope.vgmPortList.length; i++) {
                if ($scope.vgmPortList[i].value == polId) {
                    vm.vgmHead.polName = $scope.vgmPortList[i].label;
                }
            }
        }

        function onPierChoose(pierCode) {
            for (var i = 0; i < $rootScope.pierList.length; i++) {
                if ($scope.pierList[i].value == pierCode) {
                    vm.vgmHead.pierName = $scope.pierList[i].label;
                }
            }
        }

        function onClientSelected(item) {
            vm.vgmHead.clientId = item.id;
        }

        $timeout(function() {
            if ($rootScope.HAVENOAUTH('VGM')) {
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                clear();
            }
            vm.isDisabled = vm.vgmHead.status != null && vm.vgmHead.status != '-1';
            vgmHeadCopy = angular.copy(vm.vgmHead);
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            if ($rootScope.HAVENOAUTH('AU_VGM_SAVE')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_VGM_SAVE');
                return;
            }
            vm.isSaving = true;
            // 从海运出口页面传入数据到vgm弹窗的客户数据只有carrierCode，所以保存时要得到客户名称
            if(vm.vgmHead.carrierCode){
                angular.forEach($rootScope.carrierList,function(carrier){
                        if(carrier.value == vm.vgmHead.carrierCode){
                            vm.vgmHead.carrierName = carrier.label;
                        }
                    })
            }
            if(!vm.vgmHead.carrierName){
                vm.vgmHead.carrierCode = null;
            }

            if (vm.vgmHead.id !== null) {
                VgmHead.update(vm.vgmHead, onSaveSuccess, onSaveError);
            } else {
                VgmHead.save(vm.vgmHead, onSaveSuccess, onSaveError);
            }
        }

        function sendVgm(){
            if ($rootScope.HAVENOAUTH('AU_VGM_SEND')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_VGM_SEND');
                return;
            }
            if (!angular.equals(vm.vgmHead, vgmHeadCopy) || vm.vgmHead.id == null){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            layer.confirm($translate.instant('global.confirmAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                VgmHead.sendVgm({id: vm.vgmHead.id}, onSendSuccess, onSendError);
            }, function() {
            });
        }
        function onSendSuccess(result){
            if (result.returnCode == '200') {
                layer.msg($translate.instant('global.messages.submittedPleaseWait'));
                $uibModalInstance.close(result);
                angular.copy(vm.vgmHead, vgmHeadCopy);
            }else{
                $rootScope.OPS_ALERT(result.message);
            }
        }

        function onSendError(error) {
            vm.isSaving = false;
            $rootScope.ALERT_ERROR(error);
        }

        function onSaveSuccess(result) {
            vm.vgmHead = result;
            vgmHeadCopy = angular.copy(vm.vgmHead);
            $scope.$emit('autopsApp:vgmHeadUpdate', vgmHeadCopy);
            // $uibModalInstance.close(result);
            vm.isSaving = false;
            layer.msg($translate.instant('global.save_success'));
        }

        function onSaveError(result) {
            vm.isSaving = false;
            $rootScope.ALERT_ERROR(result);
        }

        vm.datePickerOpenStatus.lastModifiedTime = false;
        vm.datePickerOpenStatus.vgmDeadline = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }


        function addVgmCtn() {
            if (!vm.vgmHead.vgmCtns) {
                vm.vgmHead.vgmCtns = [];
            }
            var enName;
            var username;
            if($rootScope.account.enName){
                enName = $rootScope.account.enName.replace(/[^a-zA-Z]/g,'').toUpperCase();
            }
            if($rootScope.account.userName){
                username = $rootScope.account.userName.replace(/[^a-zA-Z]/g,'').toUpperCase();
            }
            vm.vgmHead.vgmCtns.push({
                weighingParty: enName,
                signature: username
            });
            
        }

        function delVgmCtn(vgmCtn) {
            if (vgmCtn.id) {
                VgmCtn.delete({
                    id: vgmCtn.id
                });
            };
            var index = vm.vgmHead.vgmCtns.indexOf(vgmCtn);
            if (index > -1) {
                vm.vgmHead.vgmCtns.splice(index, 1);
            };
        }

        function updateStatus(){
            if (!angular.equals(vm.vgmHead, vgmHeadCopy) || vm.vgmHead.id == null){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            VgmHead.updateStatus({id: vm.vgmHead.id}, function(result){
                layer.msg($translate.instant('global.messages.updateStatusSuccess'));
                vm.vgmHead = result;
                vgmHeadCopy = angular.copy(vm.vgmHead);
                vm.isDisabled = vm.vgmHead.status != null && vm.vgmHead.status != '-1';
            }, $rootScope.ALERT_ERROR);
        }

    }
})();
