(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('WmsInSearch', WmsInSearch);

    WmsInSearch.$inject = ['$resource'];

    function WmsInSearch($resource) {
        var resourceUrl =  'api/_search/wms-ins/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'queryVirtual': { 
                method: 'GET', 
                url:'api/_search/wms-ins-virtual',
                isArray: true
            }
        });
    }
})();
