(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AgingAnalysisController', AgingAnalysisController);

    AgingAnalysisController.$inject = ['$scope', '$rootScope', '$translate', 
    'OpsFiles', 'Principal', 'AccountPayment', 'AccountRef', 'DateUtils','AccountVoucher', 'DataUtils',
    'AccountTitle','VoucherContent', 'GuideRates', 'OpsCompany'];
    
    function AgingAnalysisController($scope, $rootScope, $translate, 
        OpsFiles, Principal, AccountPayment, AccountRef, DateUtils, AccountVoucher, DataUtils,
        AccountTitle, VoucherContent, GuideRates, OpsCompany) {

        $scope.companys = [];
        $scope.filterDTO = {};
        $scope.filterDTO.cutDate = new Date();
        $scope.filterDTO.agingDateBy = "billDate";
        $scope.filterDTO.type = "receivable";
        $scope.filterDTO.ledgerCode = "client";
        $scope.showCurContents = true;

        function loadCompanys () {
            OpsCompany.getGroupCompanys(function(result){
                $scope.companys = result;
                $scope.filterDTO.scope = $rootScope.account.companyId;
            })
        }

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                if($rootScope.account.isSuper){
                    loadCompanys();
                }          
                if ($rootScope.account.authorities.indexOf("REPORT_AGING") !== -1){
                    $scope.filterDTO.isSales = false;
                }
                else {
                    $scope.filterDTO.isSales = true;
                }                
            });
        }
        else {
            if($rootScope.account.isSuper){
                loadCompanys();
            }   
            if ($rootScope.account.authorities.indexOf("REPORT_AGING") !== -1){
                $scope.filterDTO.isSales = false;
            }
            else {
                $scope.filterDTO.isSales = true;
            }        
        }   

        $scope.reportavailalbe = false;
        $scope.isSaving = false;

        var refrestCDC = function() {
            if ($scope.agingAnalysis.balanceUSD != null) {
                $scope.agingAnalysis.balanceUSD = DataUtils.formatTwoDigits($scope.agingAnalysis.balanceUSD);
            }
            if ($scope.agingAnalysis.thirtyDaysUSD != null) {
                $scope.agingAnalysis.thirtyDaysUSD = DataUtils.formatTwoDigits($scope.agingAnalysis.thirtyDaysUSD);
            }
            if ($scope.agingAnalysis.sixtyDaysUSD != null) {
                $scope.agingAnalysis.sixtyDaysUSD = DataUtils.formatTwoDigits($scope.agingAnalysis.sixtyDaysUSD);
            }
            if ($scope.agingAnalysis.ninetyDaysUSD != null) {
                $scope.agingAnalysis.ninetyDaysUSD = DataUtils.formatTwoDigits($scope.agingAnalysis.ninetyDaysUSD);
            }
            if ($scope.agingAnalysis.aboveNinetyDaysUSD != null) {
                $scope.agingAnalysis.aboveNinetyDaysUSD = DataUtils.formatTwoDigits($scope.agingAnalysis.aboveNinetyDaysUSD);
            }

            if ($scope.agingAnalysis.balanceRMB != null) {
                $scope.agingAnalysis.balanceRMB = DataUtils.formatTwoDigits($scope.agingAnalysis.balanceRMB);
            }
            if ($scope.agingAnalysis.thirtyDaysRMB != null) {
                $scope.agingAnalysis.thirtyDaysRMB = DataUtils.formatTwoDigits($scope.agingAnalysis.thirtyDaysRMB);
            }
            if ($scope.agingAnalysis.sixtyDaysRMB != null) {
                $scope.agingAnalysis.sixtyDaysRMB = DataUtils.formatTwoDigits($scope.agingAnalysis.sixtyDaysRMB);
            }
            if ($scope.agingAnalysis.ninetyDaysRMB != null) {
                $scope.agingAnalysis.ninetyDaysRMB = DataUtils.formatTwoDigits($scope.agingAnalysis.ninetyDaysRMB);
            }
            if ($scope.agingAnalysis.aboveNinetyDaysRMB != null) {
                $scope.agingAnalysis.aboveNinetyDaysRMB = DataUtils.formatTwoDigits($scope.agingAnalysis.aboveNinetyDaysRMB);
            }
            if ($scope.agingAnalysis.contents) {
                for (var i = 0; i < $scope.agingAnalysis.contents.length; i++) {
                    if ($scope.agingAnalysis.contents[i].balance != null) {
                        $scope.agingAnalysis.contents[i].balance = DataUtils.formatTwoDigits($scope.agingAnalysis.contents[i].balance);
                    }
                    if ($scope.agingAnalysis.contents[i].thirtyDays != null) {
                        $scope.agingAnalysis.contents[i].thirtyDays = DataUtils.formatTwoDigits($scope.agingAnalysis.contents[i].thirtyDays);
                    }
                    if ($scope.agingAnalysis.contents[i].sixtyDays != null) {
                        $scope.agingAnalysis.contents[i].sixtyDays = DataUtils.formatTwoDigits($scope.agingAnalysis.contents[i].sixtyDays);
                    }
                    if ($scope.agingAnalysis.contents[i].ninetyDays != null) {
                        $scope.agingAnalysis.contents[i].ninetyDays = DataUtils.formatTwoDigits($scope.agingAnalysis.contents[i].ninetyDays);
                    }
                    if ($scope.agingAnalysis.contents[i].aboveNinetyDays != null) {
                        $scope.agingAnalysis.contents[i].aboveNinetyDays = DataUtils.formatTwoDigits($scope.agingAnalysis.contents[i].aboveNinetyDays);
                    }
                }
            }

        }
        var initCContent = function() {
            if ($scope.agingAnalysis && $scope.agingAnalysis.contents && $scope.agingAnalysis.contents.length > 0) {
                $scope.reportavailalbe = true;
            } else {
                $scope.agingAnalysis = {};
                $scope.agingAnalysis.contents = [];
                $translate.refresh();
            }
            refrestCDC();

        }
        $scope.agingAnalysis = {};
        initCContent();

        $scope.getContetns = function() {
            if (!$scope.filterDTO.type) {
                layer.msg($translate.instant('autopsApp.accountTitle.agingNullFilterAlert'));
                return;
            }
            $scope.filterDTO.companyId = $rootScope.account.companyId;
            $scope.isSaving = true;
            $scope.agingAnalysis = {};

            if($scope.salesList && $scope.salesList.length > 0){
                $scope.filterDTO.salesIds = [];
                angular.forEach($scope.salesList, function(data){
                    $scope.filterDTO.salesIds.push(data.id);
                })
            }
            else {
                $scope.filterDTO.salesIds = null;
            }

            AccountPayment.agingAnalysis($scope.filterDTO, function(result) {
                $scope.isSaving = false;
                $scope.agingAnalysis = result;
                if (!$scope.agingAnalysis || !$scope.agingAnalysis.contents || $scope.agingAnalysis.contents.length == 0) {
                    layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                    $scope.reportavailalbe = false;
                    return;
                }
                initCContent();
            }, $rootScope.ALERT_ERROR);
        }
        $scope.datePickerOpenStatus = {};
        $scope.dayFormat = "yyyy-MM-dd";
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        $scope.uppaidList = [];
        $scope.showTips = function(unpaidDays, content) {
            $scope.companyName = content.companyName;
            $scope.unpaidDays = unpaidDays;
            var dnNums;

            if ("Total" == unpaidDays) {
                $scope.totalAmount = content.currency + content.balance;
                dnNums = content.balanceText;
            } else if ("0-30 Days" == unpaidDays) {
                $scope.totalAmount = content.currency + content.thirtyDays;
                dnNums = content.thirtyDaysText;

            } else if ("31-60 Days" == unpaidDays) {
                $scope.totalAmount = content.currency + content.sixtyDays;
                dnNums = content.sixtyDaysText;

            } else if ("61-90 Days" == unpaidDays) {
                $scope.totalAmount = content.currency + content.ninetyDays;
                dnNums = content.ninetyDaysText;

            } else if (">90 Days" == unpaidDays) {
                $scope.totalAmount = content.currency + content.aboveNinetyDays;
                dnNums = content.aboveNinetyDaysText;
            }
            if(!dnNums || dnNums.length == 0){
                return;
            }
            var popTile = "";
            if ($scope.filterDTO.type == "payable") {
                popTile = $translate.instant('autopsApp.accountTitle.home.payableList');
            } else {
                popTile = $translate.instant('autopsApp.accountTitle.home.receivableList');
            }
            var companyId = $scope.filterDTO.scope;
            if(!companyId){
                companyId = $rootScope.account.companyId;
            }
            AccountPayment.getUnpaidList({
                companyId: companyId,
                dnNums: dnNums,
                type: $scope.filterDTO.type
            }, function(result) {
                $scope.uppaidList = result;
                initContent();
                layer.open({
                    type: 1,
                    area: ['1080px', '530px'],
                    maxmin: true,
                    title: popTile,
                    shade: 0.3,
                    moveType: 0,
                    shift: 0,
                    content: $("#paymentUnpaidPop"),
                    btn: [$translate.instant('entity.action.back')],
                    yes: function(index, layero) {
                        layer.close(index);
                    },
                    end: function() {
                    }
                });
            });

        }
        var refrestDC = function() {
            for (var j = $scope.uppaidList.length - 1; j >= 0; j--) {
                if ($scope.uppaidList[j].invoiceAmount != null) {
                    $scope.uppaidList[j].invoiceAmount = DataUtils.formatTwoDigits($scope.uppaidList[j].invoiceAmount);
                }
                if ($scope.uppaidList[j].paidAmount != null) {
                    $scope.uppaidList[j].paidAmount = DataUtils.formatTwoDigits($scope.uppaidList[j].paidAmount);
                }
            }
        }
        var initContent = function() {
            if ($scope.uppaidList) {
                for (var j = $scope.uppaidList.length; j <= 8; j++) {
                    $scope.uppaidList.push({});
                }

            } else {
                $scope.uppaidList = [];
                for (var j = 0; j <= 8; j++) {
                    $scope.uppaidList.push({});
                }
            }
            refrestDC();
        }

        $scope.generateAgingAnalysisReport = function(format) {
            var reportEntity = $scope.agingAnalysis;
            reportEntity.type = $scope.filterDTO.type;
            reportEntity.ledgerCode = $scope.filterDTO.ledgerCode;

            OpsFiles.generateAgingAnalysisReport({
                fileType: $scope.fileType
            }, reportEntity, function(result) {
                $rootScope.PDFPRINTEXCELDOWNLOAD(result, format, 'Aging-Analysis');   
                $scope.exportExcelOrPdf(result);
            }, function(error) {
                layer.msg($translate.instant('stats.stat.noData'));
            });
        }

        var isReverse = false;
        $scope.sortByTitle = function(title) {
            isReverse = !isReverse;
            $scope.agingAnalysis.contents.sort(function(a,b){
                if(!isNaN(a[title])){
                    a[title] = a[title] - 0;
                    b[title] = b[title] - 0;
                }
                if (isReverse){
                    return a[title]<b[title]?1:-1;
                }
                else {
                    return a[title]>b[title]?1:-1;
                }                   
            });
        }

        $scope.choosedRates = [];
        $scope.viewRates = function(request) {
            var companyId = $scope.filterDTO.scope;
            if(!companyId){
                companyId = $rootScope.account.companyId;
            }
            GuideRates.getWrieteOffRatesByDnNum({
                companyId: companyId,
                dnNum: request.dnNum
            }, function(result) {
                $scope.choosedRates = result;
            });

            layer.open({
                type: 1,
                area: ['980px', '580px'],
                moveOut: false,
                title: request.dnNum,
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: $("#allRatesPop"),
                end: function() {
                    $scope.choosedRates=[];
                }
            });
        }
        $scope.setAgingDateBy = function(typeValue) {
            $scope.filterDTO.agingDateBy = typeValue;
        }

        $scope.salesList = [];        
        $scope._onClerkSelected = function(fd, item) {
            if(fd == "salesId"){
                $scope.salesList.push(item);
            }
            $scope.statParams[fd] = "";
        };
        $scope.delSales = function(index) {
            $scope.salesList.splice(index, 1);
        };


    }
})();