(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('JhiSignature', JhiSignature);

    JhiSignature.$inject = ['$resource','DateUtils'];
    
    function JhiSignature($resource, DateUtils) {
        return $resource('api/jhiSignatures/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'queryRatesRemarks': {
                method: 'GET',
                url:'api/jhiSignatures/queryRatesRemarks',
                isArray: true
            },
            'findRateRemarks': {
                method: 'GET',
                url:'api/jhiSignatures/findRateRemarks'
            },
            'getPortRemarks': {
                method: 'GET',
                url:'api/jhiSignatures/getPortRemarks'
            },       
            'getFbaGuideRemarks': {
                method: 'GET',
                url:'api/jhiSignatures/getFbaGuideRemarks'
            },         
            'save': { method: 'POST', isArray: true},
            'update': { method:'PUT' }
        });
    }
})();
