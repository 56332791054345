(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('VoucherContentSearch', VoucherContentSearch);

    VoucherContentSearch.$inject = ['$resource'];
    
    function VoucherContentSearch($resource) {
        return $resource('api/_search/voucherContents/:query', {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
