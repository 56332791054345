(function () {
    'use strict';
    angular
        .module('autopsApp')
        .factory('CustomNode', CustomNode);

    CustomNode.$inject = ['$resource', 'DateUtils'];

    function CustomNode($resource, DateUtils) {
        var resourceUrl = 'api/custom-nodes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    }
                    return data;
                }
            },
            'update': { method: 'PUT' },
            'shareNode': {
                method: 'GET',
                url: 'api/custom-nodes/shareNode'
            },
            'getConditions': {
                method: 'GET',
                url: 'api/custom-nodes/getConditions', 
                isArray: true
            },
            'autoWfCustomNodes': {
                method: 'POST',
                url: 'api/custom-nodes/autoWfCustomNodes', 
                isArray: true
            },
            'getWfCustomNodes': {
                method: 'GET',
                url: 'api/custom-nodes/getWfCustomNodes', 
                isArray: true
            },
            'getCustomNodesByType': {
                method: 'GET',
                url: 'api/custom-nodes/getCustomNodesByType',
                isArray: true
            },
            'getCustomTasks': {
                method: 'GET',
                url: 'api/custom-nodes/getCustomTasks', 
                isArray: true
            },
            'getAfCustomNodes': {
                method: 'GET',
                url: 'api/custom-nodes/getAfCustomNodes', 
                isArray: true
            },
            'changeDisabled': {
                method: 'GET',
                url: 'api/custom-nodes/changeDisabled'
            }            

        });
    }
})();
