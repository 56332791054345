(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsWarehouseController', WmsWarehouseController);

    WmsWarehouseController.$inject = ['$scope', '$rootScope', '$state', '$uibModal', 'Principal', 'WmsWarehouse', 'WmsWarehouseSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function WmsWarehouseController ($scope, $rootScope, $state, $uibModal, Principal, WmsWarehouse, WmsWarehouseSearch, ParseLinks, AlertService, pagingParams, paginationConstants) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };

        var vm = this;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.edit = edit;
        vm.checkWareHouseProccess =checkWareHouseProccess;
        vm.wmsAuth = wmsAuth;
        loadAll();
        function loadAll () {
            if (pagingParams.search) {
                WmsWarehouseSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                WmsWarehouse.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.wmsWarehouses = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = true;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
        function edit (id) {
            $rootScope.id = id;
            $state.go('wms-warehouse.detail');
        }
        function checkWareHouseProccess(id){
            $rootScope.id = id;
            $state.go('wms-warehouse.inventory');
        }

        function wmsAuth (wmsWarehouse) {
            $uibModal.open({
                templateUrl: 'app/entities/virtual-warehouse/virtual-warehouse-dialog.html',
                controller: 'VirtualWarehouseDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function(){
                        return wmsWarehouse;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('virtualWarehouse');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {

            }, function() {

            });
        }




    }
})();
