(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsAreaDialogController', WmsAreaDialogController);

    WmsAreaDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', '$translate', 'entity', 'WmsWarehouse'];

    function WmsAreaDialogController ($timeout, $scope, $uibModalInstance, $translate, entity, WmsWarehouse) {
        var vm = this;
        vm.wmsArea = entity;
        vm.clear = clear;
        vm.save = save;
        vm.save = save;
        vm.refreshInventoryLocationType = refreshInventoryLocationType;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            $uibModalInstance.close();
        }

        function refreshInventoryLocationType () {      
            if(!vm.wmsArea.id){
                layer.msg($translate.instant('global.messages.submitted'));
                return;
            }
            layer.confirm($translate.instant('autopsApp.wmsWarehouse.refreshLocationTypeAlert'), {
                icon: 0,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                layer.load(1, {shade: 0.3});
                WmsWarehouse.refreshInventoryLocationType({
                    locationType: vm.wmsArea.locationType,
                    wmsAreaId: vm.wmsArea.id,
                    warehouseId: vm.wmsArea.warehouseId
                }, function(result){
                    layer.closeAll();
                    layer.msg($translate.instant('global.messages.submitted'));
                }, function(error){        
                    layer.closeAll();        
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                });              
            }, function() {
            });
        }
    }
})();
