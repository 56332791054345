(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider        
        .state('write-off-rates-detail', {
            parent: 'entity',
            url: '/write-off-rates/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'autopsApp.writeOffRates.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/write-off-rates/write-off-rates-detail.html',
                    controller: 'WriteOffRatesDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('writeOffRates');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'WriteOffRates', function($stateParams, WriteOffRates) {
                    return WriteOffRates.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'write-off-rates',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('write-off-rates-detail.edit', {
            parent: 'write-off-rates-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/write-off-rates/write-off-rates-dialog.html',
                    controller: 'WriteOffRatesDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: ['WriteOffRates', function(WriteOffRates) {
                            return WriteOffRates.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('write-off-rates.new', {
            parent: 'write-off-rates',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/write-off-rates/write-off-rates-dialog.html',
                    controller: 'WriteOffRatesDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                jobNum: null,
                                mblNum: null,
                                vslVoy: null,
                                payer: null,
                                payerVid: null,
                                etd: null,
                                item: null,
                                thisAmount: null,
                                currency: null,
                                totalAmount: null,
                                refId: null,
                                diffAmount: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('write-off-rates', null, { reload: 'write-off-rates' });
                }, function() {
                    $state.go('write-off-rates');
                });
            }]
        })
        .state('write-off-rates.edit', {
            parent: 'write-off-rates',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/write-off-rates/write-off-rates-dialog.html',
                    controller: 'WriteOffRatesDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: ['WriteOffRates', function(WriteOffRates) {
                            return WriteOffRates.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('write-off-rates', null, { reload: 'write-off-rates' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('write-off-rates.delete', {
            parent: 'write-off-rates',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/write-off-rates/write-off-rates-delete-dialog.html',
                    controller: 'WriteOffRatesDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['WriteOffRates', function(WriteOffRates) {
                            return WriteOffRates.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('write-off-rates', null, { reload: 'write-off-rates' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
