(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CrmPriceDeleteController',CrmPriceDeleteController);

    CrmPriceDeleteController.$inject = ['$uibModalInstance', 'entity', 'CrmEnquiry'];

    function CrmPriceDeleteController($uibModalInstance, entity, CrmEnquiry) {
        var vm = this;

        vm.clear = clear;
        vm.confirm = confirm;
        vm.quoterChoosed = quoterChoosed;
        vm.quoter = null;
        function quoterChoosed (clerk) {
            vm.quoter = clerk;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirm (id) {
            entity.id = null;
            CrmEnquiry.transferEnquiry({quoterId: vm.quoter.id}, entity, function () {                
                $uibModalInstance.close(true);
            });
        }
    }
})();
