(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('GridStateSearch', GridStateSearch);

    GridStateSearch.$inject = ['$resource'];

    function GridStateSearch($resource) {
        var resourceUrl =  'api/_search/grid-states/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'queryByCustomExcelImport': {
                url: 'api/_search/grid-states/queryByCustomExcelImport',
                method: 'GET',
                isArray: true
            }
        });
    }
})();
