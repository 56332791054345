(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('OpsReport', OpsReport);

    OpsReport.$inject = ['$resource','DateUtils'];

    function OpsReport($resource, DateUtils) {
        function initGuideRequest (data) {
            data = angular.copy(data)
            data.issueDate = DateUtils.convertLocalDateToServer(data.issueDate); 
            data.cargoReadyDate = DateUtils.convertLocalDateToServer(data.cargoReadyDate);
            data.eta = DateUtils.convertLocalDateToServer(data.eta);
            data.etd = DateUtils.convertLocalDateToServer(data.etd);
            data.atd = DateUtils.convertLocalDateToServer(data.atd);
            data.billDate = DateUtils.convertLocalDateToServer(data.billDate);
            data.opDate = DateUtils.convertLocalDateToServer(data.opDate);
            data.feederEtd = DateUtils.convertLocalDateToServer(data.feederEtd);
            data.deliveryDate = DateUtils.convertLocalDateToServer(data.deliveryDate);
            data.accountDate = DateUtils.convertLocalDateToServer(data.accountDate);
            data.economicDate = DateUtils.convertLocalDateToServer(data.economicDate);
            if (data.booking){
                data.booking.issueDate = DateUtils.convertLocalDateToServer(data.booking.issueDate);
                data.booking.etd = DateUtils.convertLocalDateToServer(data.booking.etd);
            }
            angular.forEach(data.bookingCustoms, function(data){
                data.declareDate = DateUtils.convertLocalDateToServer(data.declareDate);
            })
            angular.forEach(data.guideCtns, function(ctn) {
                ctn.gwDec = ctn.gw;
            })
            angular.forEach(data.guideRates, function(rate) {
                rate.priceDec = rate.price;
                rate.totalDec = rate.total;
                rate.countDec = rate.count;
            })
            return angular.toJson(data);
        }


        return $resource('', {}, {
            'getMultiAttachments':{
                method:'POST',
                url:'api/guide/getMultiAttachments',
                isArray:true,
                transformRequest: function (data) {
                    return initGuideRequest(data);
                }
            },
            'getWmsAttachments':{
                method:'POST',
                url:'api/wms-ins/getWmsAttachments',
                isArray:true,
            },
            'generateGuide':{
                method:'POST',
                url:'api/report/guide',
                responseType: 'arraybuffer',
                isArray:false,
                transformRequest: function (data) {
                    return initGuideRequest(data);
                },
                transformResponse: function (data) {
                    var result = new Object();                    
                    result['data'] = data;
                    return result;
                }
            },
            'combineAndPrint':{
                method:'POST',
                url:'api/report/guide/combineAndPrint',
                responseType: 'arraybuffer',
                isArray:false,
                transformResponse: function (data) {
                    var result = new Object();                    
                    result['data'] = data;
                    return result;
                }
            },
            'guidesPlExport':{
                method:'GET',
                url:'api/report/guidesPlExport',
                responseType: 'arraybuffer',
                isArray:false,
                transformResponse: function (data) {
                    var result = new Object();                    
                    result['data'] = data;
                    return result;
                }
            },
            'generateCustomDeclaration':{
                method:'POST',
                url:'api/report/customDeclaration',
                responseType: 'arraybuffer',
                isArray:false,
                transformRequest: function (data) {
                    var tz = jstz.determine();
                    tz.name();
                    data.zoneId = tz.name();
                    data = angular.copy(data)
                    data.eta = DateUtils.convertLocalDateToServer(data.eta);
                   return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var tz = jstz.determine();
                    tz.name();
                    data.zoneId = tz.name();
                    var result = new Object();                    
                    result['data'] = data;
                    return result;
                }
            }
        });
    }
})();
