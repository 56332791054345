(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('Account', Account);

    Account.$inject = ['$resource'];

    function Account ($resource) {
        var service = $resource('api/account', {}, {
            'get': { method: 'GET', params: {}, isArray: false,
                interceptor: {
                    response: function(response) {
                        // expose response
                        return response;
                    }
                }
            },
            'checkDeposite':{
                method:'GET',
                url:'api/account/checkDeposite'
            },
            'getQq':{
                method:'GET',
                url:'api/account/getQq'
            },
            'changeUserPassword':{
                method:'POST',
                url:'api/account/changeUserPassword'
            }
        });

        return service;
    }
})();

(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('Clerks', Clerks);

    Clerks.$inject = ['$resource'];
    
    function Clerks($resource) {
	    return $resource('api/account/clerks', {}, {
	    });
    }
})();

(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('ValidateCode', ValidateCode);

    ValidateCode.$inject = ['$resource'];
    
    function ValidateCode($resource) {
		return $resource('', {}, {
		    'sendVerCode':{
		        method:'GET',
		        url:'api/sendValidateCode'
		    },
		    'validateCode':{
		        method:'GET',
		        url:'api/validateCode'
		    }
		});
    }
})();
