(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountVoucherController', AccountVoucherController);

    AccountVoucherController.$inject = ['$timeout', '$rootScope', '$scope', '$state', '$uibModal', '$translate', 'paginationConstants', 'JhiLanguageService', 'Principal', 'Clerks', 'AccountTitle', 'AccountVoucher', 'AccountVoucherSearch', 'DateUtils', 'ParseLinks', 'AccountRef', 'OpsFiles', 'CashFlowSubject', 'VoucherContent', 'OpsExchangeRate'];

    function AccountVoucherController($timeout, $rootScope, $scope, $state, $uibModal, $translate, paginationConstants, JhiLanguageService, Principal, Clerks, AccountTitle, AccountVoucher, AccountVoucherSearch, DateUtils, ParseLinks, AccountRef, OpsFiles, CashFlowSubject, VoucherContent, OpsExchangeRate) {
        var vm = this;
        var exchangeRates = null;
        var getExchangeRatesMap = function() {
            $rootScope.loadCurrencys();
            if (exchangeRates) {
                return;
            }
            OpsExchangeRate.getExchangeRatesMap(function(result) {
                exchangeRates = result;
            })
        }

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                getExchangeRatesMap();
            });
        } else {
            getExchangeRatesMap();
        }

        var lang = "";
        JhiLanguageService.getCurrent().then(function(language) {
            lang = language;
        });
        if (!$rootScope.voucherFilter) {
            $rootScope.voucherFilter = "artificial";
        }
        $scope.filterSet = function(status) {
            $rootScope.voucherFilter = status;
            loadAll();
        }

        $scope.accountVouchers = [];
        $scope.cfAllotRequired = false;


        vm.loadPage = loadPage;
        vm.predicate = 'id';
        vm.reverse = false;

        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = 1;

        vm.transition = transition;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.isAdvanceSearch = false;

        loadAll();

        function loadAll() {
            if (vm.searchQuery) {
                AccountVoucherSearch.query({
                    originator: $rootScope.voucherFilter,
                    query: vm.searchQuery,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                AccountVoucher.query({
                    originator: $rootScope.voucherFilter,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                $scope.accountVouchers = data;
                initVouchers();
                vm.isAdvanceSearch = false;
                setTimeout(function() {frzTable("divTable", "basicTable", "footPager");}, 100);
                setTimeout(function() {frzTable("divTable", "basicTable", "footPager");}, 300);
            }

            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }
        function initVouchers() {
            angular.forEach($scope.accountVouchers, function(voucher){
                angular.forEach(voucher.voucherContents, function(content){
                    content.showContent = "";
                    if(content.code){
                        content.showContent += content.code;
                    }
                    if(content.subLedger){
                        content.showContent += " " + content.subLedger;
                    }
                    if(content.clerkName){
                        content.showContent += " _ " + content.clerkName;
                    }
                    if(content.team){
                        content.showContent += " _ " + content.team;
                    }
                    if(content.branch){
                        content.showContent += " _ " + content.branch;
                    }
                    if(content.vender){
                        content.showContent += " _ " + content.vender;
                    }
                })
            })
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function transition() {
            loadAll();
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            loadAll();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = null;
            vm.searchQuery = null;
            if (vm.isAdvanceSearch) {
                vm.itemsPerPage = paginationConstants.itemsPerPage;
                vm.isAdvanceSearch = false;
                loadAll();
            } else {
                loadAll();
            }
        }

        $scope.viewCurrencyChange = function() {
            setTimeout(function() {frzTable("divTable", "basicTable", "footPager");}, 50);
            setTimeout(function() {frzTable("divTable", "basicTable", "footPager");}, 300);
        }

        $scope.invalid = function(accountVoucher) {
            if ((accountVoucher.isAudited && !accountVoucher.isVoided) || accountVoucher.isLocked) {
                layer.msg($translate.instant('autopsApp.accountVoucher.auditedAlert'));
                return;
            }
            var isVoided = true;
            if (accountVoucher.isVoided) {
                isVoided = false;
            }
            $scope.isSaving = true;
            AccountVoucher.setVoidAudit({
                id: accountVoucher.id,
                isVoided: isVoided
            },
            function(result) {
                $scope.isSaving = false;
                accountVoucher.isVoided = result.isVoided;
                layer.msg($translate.instant('global.save_success'));
                setTimeout(function() {frzTable("divTable", "basicTable", "footPager");}, 100);
            },
            function(result) {
                $scope.isSaving = false;
                $rootScope.ALERT_ERROR(result);
            });
        }
        window.onresize = function(){
            setTimeout(function() {frzTable("divTable", "basicTable", "footPager");}, 100);
        }

        $scope.delete = function(accountVoucher) {
            if (!accountVoucher.isVoided || accountVoucher.isLocked || accountVoucher.isAudited) {
                return;
            }
            if (accountVoucher.antiId) {
                layer.confirm($translate.instant('autopsApp.accountVoucher.delete.questionBoth'), {
                    title: $translate.instant('global.alert_title'),
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    layer.close(index);
                    $scope.isSaving = true;
                    AccountVoucher.delBoth(accountVoucher, onDelSuccess, $rootScope.ALERT_ERROR);
                }, function() {});
            } else {
                var delContent = $translate.instant('autopsApp.accountVoucher.delete.question', {
                    id: accountVoucher.voucherNum
                });
                layer.confirm(delContent, {
                    title: $translate.instant('global.alert_title'),
                    btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
                }, function(index) {
                    layer.close(index);
                    $scope.isSaving = true;
                    AccountVoucher.delete({
                        id: accountVoucher.id
                    }, onDelSuccess, $rootScope.ALERT_ERROR);
                }, function() {});
            }
        }

        $scope.delAntiVouchers = function() {
            var delContent = $translate.instant('autopsApp.accountVoucher.delete.questionAll', {
                num: $scope.accountVouchers.length
            });
            layer.confirm(delContent, {
                title: $translate.instant('global.alert_title'),
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                layer.close(index);
                $scope.isSaving = true;
                AccountVoucher.delAll($scope.accountVouchers, onDelSuccess, $rootScope.ALERT_ERROR);
            }, function() {});
        }

        $scope.delVoidVouchers = function() {
            var vouchers = angular.copy($scope.accountVouchers);
            for(var i = vouchers.length; i >= 0; i--){
                if(!vouchers[i] || vouchers[i].antiId || !vouchers[i].isVoided || vouchers[i].isAudited){
                    vouchers.splice(i, 1);
                }
            }
            var delContent = $translate.instant('autopsApp.accountVoucher.delete.questionAll', {
                num: vouchers.length
            });
            layer.confirm(delContent, {
                title: $translate.instant('global.alert_title'),
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                layer.close(index);
                $scope.isSaving = true;
                AccountVoucher.delAll(vouchers, onDelSuccess, $rootScope.ALERT_ERROR);
            }, function() {});
        }

        var onDelSuccess = function(result) {
            layer.msg($translate.instant('global.delete_success'));
            loadAll();
            $scope.isSaving = false;
        };

        $scope.customFilter = function() {
            var customFilterDTO = {};
            customFilterDTO.isVoided = false;
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountVoucher/accountVoucher-detail.html',
                controller: 'AccountVoucherDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return customFilterDTO;
                    }]
                }
            }).result.then(function(results) {
                customFilterResult(results);
            }, function() {
            });
        }

        function customFilterResult (results) {
            if (!results || results.length == 0) {
                layer.msg($translate.instant('autopsApp.accountVoucher.filter.resultNull'));
                return;
            }
            if(results.length > 2000){
                layer.confirm($translate.instant('global.dataSizeAlert', { amt: $scope.accountVouchers.length }), {
                    icon: 3,
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    OpsFiles.excelAccountVoucher({
                        oldFormat: false,
                        title: $translate.instant('autopsApp.accountVoucher.home.title'),
                        enCn: $translate.instant('global.enCn')
                    }, results, function(result) {
                        $rootScope.DOWNLOADEXCEL(result, $translate.instant('autopsApp.accountVoucher.home.title') + ".xlsx");
                    })
                    layer.close(index);
                    return;
                }, function() {
                    loadFilterResult(results);
                });
            }
            else {
                loadFilterResult(results);
            }
        }
        var loadFilterResult = function(results) {
            $scope.accountVouchers = results;
            initVouchers();
            vm.isAdvanceSearch = true;
            vm.currentSearch = true;
            $scope.isVouchersNumTrim = false;
            $scope.accountVouchers.sort(function(a,b){
                return a.makeDate > b.makeDate?1:-1;
            });
            vm.totalItems = $scope.accountVouchers.length;
            vm.itemsPerPage = $scope.accountVouchers.length;
            vm.queryCount = vm.totalItems;
            vm.page = 1;
            $translate.refresh();
            setTimeout(function() {frzTable("divTable", "basicTable", "footPager");}, 100);
            setTimeout(function() {frzTable("divTable", "basicTable", "footPager");}, 500);
            setTimeout(function() {frzTable("divTable", "basicTable", "footPager");}, 1500);
        }


        var onSaveVouchersSuccess = function(result) {
            $scope.isSaving = false;
            layer.closeAll();
            $scope.accountVouchers = result;
            initVouchers();
            $translate.refresh();
            layer.msg($translate.instant('global.messages.submitted'));
        };

        var onSaveVouchersError = function(result) {
            $scope.isSaving = false;
            layer.closeAll();
            layer.msg($translate.instant('global.messages.submittedfailed'));
        };


        $scope.auditAll = function() {
            if ($rootScope.account.authorities.indexOf("FUN_AUDIT_VOUCHER") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('FUN_AUDIT_VOUCHER');
                return;
            }
            var isLocked = false;
            for (var i = 0; i < $scope.accountVouchers.length; i++) {
                if ($scope.accountVouchers[i].isLocked) {
                    isLocked = true;
                    break;
                }
            }
            if (isLocked) {
                layer.msg($translate.instant('autopsApp.accountVoucher.endClosedAlert'));
                return;
            }
            for (var i = 0; i < $scope.accountVouchers.length; i++) {
                if (!$scope.accountVouchers[i].isVoided) {
                    $scope.accountVouchers[i].isAudited = true;
                    $scope.accountVouchers[i].audit = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                    $scope.accountVouchers[i].auditId = $rootScope.account.id;
                }
            }
            $scope.isSaving = true;
            layer.load(1, {shade: 0.3});
            AccountVoucher.updateList($scope.accountVouchers, onSaveVouchersSuccess, onSaveVouchersError);
        }

        $scope.unauditAll = function() {
            if ($rootScope.account.authorities.indexOf("FUN_AUDIT_VOUCHER") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('FUN_AUDIT_VOUCHER');
                return;
            }
            var isLocked = false;
            for (var i = 0; i < $scope.accountVouchers.length; i++) {
                if ($scope.accountVouchers[i].isLocked) {
                    isLocked = true;
                    break;
                }
            }
            if (isLocked) {
                layer.msg($translate.instant('autopsApp.accountVoucher.endClosedAlert'));
                return;
            }
            for (var i = 0; i < $scope.accountVouchers.length; i++) {
                // if (!$scope.accountVouchers[i].isVoided ) {
                $scope.accountVouchers[i].isAudited = false;
                $scope.accountVouchers[i].audit = null;
                $scope.accountVouchers[i].auditId = null;
                // }
            }
            $scope.isSaving = true;
            layer.load(1, {shade: 0.3});
            AccountVoucher.updateList($scope.accountVouchers, onSaveVouchersSuccess, onSaveVouchersError);
        }

        $scope.removeOne = function(index) {
            $scope.accountVouchers.splice(index, 1);
        }


        $scope.previewAccountVouchReport = function(accountVoucher) {
            OpsFiles.previewAccountVoucherReport({
                fileType: 'pdf'
            }, $scope.accountVouchers, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant("autopsApp.accountVoucher.viewAccountVoucherFail "));
            });
        }

        $scope.datePickerForMonth = {};
        $scope.datePickerForMonth.status = {
            opened: false
        };
        $scope.monthFormat = "yyyy-MM";
        $scope.datepickerOptions = {
            minMode: 'month'
        }

        $scope.datePickerForMonthOpen = function($event) {
            $scope.datePickerForMonth.status.opened = true;
        };

        $scope.voucherNumTrimMonth = null;
        $scope.voucherNumTrim = function() {
            $scope.voucherNumTrimMonth = null;
            layer.open({
                type: 1,
                area: ['280px', '350px'],
                title: $translate.instant('autopsApp.accountVoucher.chooseMonth'),
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: $("#voucherNumTrimMonthPop"),
                btn: [$translate.instant('entity.action.confirm')],
                yes: function(index, layero) {
                    layer.load(1, {shade: 0.3});
                    AccountVoucher.voucherNumTrim({
                        companyId: $rootScope.account.companyId,
                        accountDate: DateUtils.convertLocalDateToServer($scope.voucherNumTrimMonth)
                    }, function(result) {
                        layer.closeAll();
                        if (!result || result.length == 0) {
                            $scope.accountVouchers = [];
                            layer.msg($translate.instant('autopsApp.accountVoucher.filter.resultNull'));
                            return;
                        }
                        if (result.length > 1000) {
                            layer.confirm($translate.instant('global.dataSizeAlert', {amt: result.length}), {
                                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                            }, function(index) {
                                $scope.excelExport(result);
                                layer.close(index);
                            }, function() {
                                customFilterResult(result);
                            });
                        }
                        else {
                            customFilterResult(result);
                        }
                    }, function(error){
                        layer.closeAll();
                        $rootScope.ALERT_ERROR(error);
                    });
                },
                end: function() {}
            });
        }

        $timeout(function() {
        });

        /*new voucher pop*/
        $scope.edidVoucher = function(voucher) {
            var vouchersEntity = {};
            if (!voucher) {
                voucher = {
                    voucherNum: null,
                    makeDate: DateUtils.convertLocalDateFromServer($rootScope.account.accountDate),
                    attachmentCount: null,
                    dnTotal: null,
                    cnTotal: null,
                    accounting: null,
                    accountingId: null,
                    audit: null,
                    auditId: null,
                    cashier: null,
                    cashierId: null,
                    originator: $rootScope.account.firstName + " " + $rootScope.account.lastName,
                    originatorId: $rootScope.account.id,
                    isAudited: false,
                    isLocked: false,
                    isVoided: false,
                    isDn: null,
                    companyId: $rootScope.account.companyId,
                    id: null
                };
            }
            vouchersEntity.voucherId = voucher.id;
            vouchersEntity.accountVoucher = voucher;
            vouchersEntity.accountVouchers = $scope.accountVouchers;
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountVoucher/accountVoucher-dialog.html',
                controller: 'AccountVoucherDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return vouchersEntity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountVoucher');
                        $translatePartialLoader.addPart('writeOff');
                        $translatePartialLoader.addPart('accountInvoice');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                if (vm.currentSearch) {
                    udpateVouchers(result);
                } else {
                    loadAll();
                }
            }, function() {});
        }

        var udpateVouchers = function(result) {
            angular.forEach($scope.accountVouchers, function(voucher) {
                if (voucher.id == result.id) {
                    voucher.voucherNum = result.voucherNum;
                    voucher.makeDate = result.makeDate;
                    voucher.dnTotal = result.dnTotal;
                    voucher.cnTotal = result.cnTotal;
                    voucher.accounting = result.accounting;
                    voucher.audit = result.audit;
                    voucher.originator = result.originator;
                    voucher.remarks = result.remarks;
                }
            })
        }

        $scope.copyVoucher = function(voucher, chargeOff) {
            AccountVoucher.get({
                id: voucher.id
            }, function(voucher) {
                voucher.id = null;
                voucher.voucherNum = null;
                voucher.makeDate = new Date();
                voucher.attachmentCount = null;
                voucher.accounting = null;
                voucher.accountingId = null;
                voucher.audit = null;
                voucher.auditId = null;
                voucher.cashier = null;
                voucher.cashierId = null;
                voucher.originator = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                voucher.originatorId = $rootScope.account.id;
                voucher.isAudited = false;
                voucher.isDn = null;
                voucher.companyId = $rootScope.account.companyId;
                voucher.remarks = null;
                voucher.isLocked = false;
                voucher.isVoided = false;
                voucher.refId = null;
                voucher.antiId = null;
                voucher.createdTime = null;
                angular.forEach(voucher.voucherContents, function(content) {
                    content.id = null;
                    content.balance = null;
                    content.currencyBalance = null;
                    content.isDn = null;
                    content.generalOne = null;
                    content.generalTwo = null;
                    content.generalThree = null;
                    content.generalFour = null;
                    content.economicDate = null;
                    content.isWritedOff = null;
                    if(chargeOff){
                        if(content.dn){
                            content.dn = -content.dn;
                        }
                        if(content.cn){
                            content.cn = -content.cn;
                        }
                    }
                })
                var vouchersEntity = {};
                vouchersEntity.accountVoucher = voucher;
                $uibModal.open({
                    templateUrl: 'app/entities/accountant/accountVoucher/accountVoucher-dialog.html',
                    controller: 'AccountVoucherDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxl',
                    resolve: {
                        entity: [function() {
                            return vouchersEntity;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('accountVoucher');
                            $translatePartialLoader.addPart('writeOff');
                            $translatePartialLoader.addPart('accountInvoice');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function(results) {
                    loadAll();
                }, function() {
                    loadAll();
                });
            });
        }


        $scope.setAudited = function(accountVoucher, isAudited) {
            if ($rootScope.account.authorities.indexOf("FUN_AUDIT_VOUCHER") === -1) {
                accountVoucher.isAudited = !isAudited;
                $rootScope.FORBIDDEN_BY_ALERT('FUN_AUDIT_VOUCHER');
                return;
            }
            if (accountVoucher.isVoided || accountVoucher.isLocked) {
                accountVoucher.isAudited = !isAudited;
                return;
            }
            $scope.isSaving = true;
            AccountVoucher.setVoidAudit({
                id: accountVoucher.id,
                isAudited: isAudited
            }, function(result) {
                $scope.isSaving = false;
                for (var i = 0; i < $scope.accountVouchers.length; i++) {
                    if ($scope.accountVouchers[i].id == result.id) {
                        $scope.accountVouchers[i] = result;
                        initVouchers();
                    }
                }
                layer.msg($translate.instant('global.save_success'));
            }, function(result) {
                $scope.isSaving = false;
                $rootScope.ALERT_ERROR(result);
            });
        };

        var isReverse = true;
        $scope.sortVouchers = function(title) {
            isReverse = !isReverse;
            $scope.accountVouchers.sort(function(a, b) {
                if (isReverse) {
                    return a[title] < b[title] ? 1 : -1;
                } else {
                    return a[title] > b[title] ? 1 : -1;
                }
            });
        }

        $scope.excelExport = function(oldFormat) {
            if (!$scope.accountVouchers || $scope.accountVouchers.length == 0) {
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            OpsFiles.excelAccountVoucher({
                oldFormat: oldFormat,
                title: $translate.instant('autopsApp.accountVoucher.home.title'),
                enCn: $translate.instant('global.enCn')
            }, $scope.accountVouchers, function(result) {
                $rootScope.DOWNLOADEXCEL(result, $translate.instant('autopsApp.accountVoucher.home.title') + ".xlsx");
            })
        }

        $scope.exportVouchers = function() {
            OpsFiles.exportVouchers({
            }, $scope.accountVouchers, $rootScope.exportDbfFile);
        }

        $scope.exportVouchersPop = function() {
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountRef/accountRef-detail.html',
                controller: 'AccountRefDetailController',
                size: 'xxl',
                backdrop: false,
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountInvoice');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
            }, function() {
            });
        }

    }
})();
