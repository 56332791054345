(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('WmsWorkLog', WmsWorkLog);

    WmsWorkLog.$inject = ['$resource', 'DateUtils'];

    function WmsWorkLog ($resource, DateUtils) {
        var resourceUrl =  'api/wms-work-logs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.workTime = DateUtils.convertDateTimeFromServer(data.workTime);
                        data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    }
                    return data;
                }
            },
            'getAllWorks': {
                method:'GET',
                url:'api/wms-work-logs/getAllWorks',
                isArray: true
            },     
            'refreshCharge': {
                method:'PUT',
                url:'api/wms-work-logs/refreshCharge'
            },            
            'getOpRequirment': {
                method:'GET',
                url:'api/wms-work-logs/getOpRequirment',
                isArray:true
            },
            'getWmsWorkLogs': {
                method:'PUT',
                url:'api/wms-work-logs/getWmsWorkLogs',
                isArray:true
            },
            'saveBilledWorkLogs': {
                method:'PUT',
                url:'api/wms-work-logs/saveBilledWorkLogs'
            },
            'jobNumBindCheck': {
                method:'PUT',
                url:'api/wms-work-logs/jobNumBindCheck'
            },            
            'save': { 
                method:'POST',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.workTime = DateUtils.convertDateTimeFromServer(data.workTime);
                        data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    }
                    return data;
                }
            },
            'update': { 
                method:'PUT',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.workTime = DateUtils.convertDateTimeFromServer(data.workTime);
                        data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    }
                    return data;
                }
            }
        });
    }
})();
