(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('accountInvoice', {
                parent: 'finance',
                url: '/invoice-request',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'autopsApp.accountInvoice.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountInvoice/accountInvoices.html',
                        controller: 'AccountInvoiceController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountInvoice');
                        $translatePartialLoader.addPart('accountPayment');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('autoCheck'); 
                        return $translate.refresh();
                    }]
                }
            })
            .state('dn-management', {
                parent: 'finance',
                url: '/dn-management',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'global.menu.entities.dn-management'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountInvoice/accountInvoices.html',
                        controller: 'AccountInvoiceController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountInvoice');
                        $translatePartialLoader.addPart('accountPayment');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('autoCheck'); 
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
