(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('DateUtils', DateUtils);

    DateUtils.$inject = ['$filter', '$rootScope'];

    function DateUtils ($filter, $rootScope) {

        var service = {
            convertDateTimeFromServer : convertDateTimeFromServer,
            convertLocalDateFromServer : convertLocalDateFromServer,
            convertLocalDateToServer : convertLocalDateToServer,
            convertDateTimeToString : convertDateTimeToString,
            getTimeDiffHour : getTimeDiffHour,
            getDaysDiff : getDaysDiff,
            isSameMonth : isSameMonth,
            getNewDay : getNewDay,
            compareDate: compareDate,
            getPreMonth: getPreMonth,
            getNewMonth: getNewMonth,
            getNewMonthFirstDay: getNewMonthFirstDay,
            getAccountDate: getAccountDate,
            isValidDate: isValidDate,
            dateformat : dateformat,
            convertFdDateAndTimeFromServer : convertFdDateAndTimeFromServer,
            convertObjDateAndTimeFromServer : convertObjDateAndTimeFromServer,
            convertObjDateToServer : convertObjDateToServer
        };

        return service;
        function isValidDate(date) {
            return date instanceof Date && !isNaN(date.getTime())
        }

        function getDaysDiff (dateFrom, dateTo) {
            if(!dateFrom || !dateTo){
                return null;
            }
            var date1, date2, aDate, oDate1, oDate2;
            date1 = this.convertLocalDateToServer(dateFrom);
            date2 = this.convertLocalDateToServer(dateTo);
            if (date1 > date2) {
                return -1;
            }
            aDate = date1.split("-");
            oDate1 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0]);
            aDate = date2.split("-");
            oDate2 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0]);
            return parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24);
        }


        /**
         * 获取几个月后的新月份
         *
         * @date 格式为yyyy-mm-dd的日期，如：2014-01-25
         */
        function getNewMonth(date, months) {
            var arr = date.split('-');
            var year = arr[0]; //获取当前日期的年份
            var month = arr[1]; //获取当前日期的月份
            var day = arr[2]; //获取当前日期的日
            var days = new Date(year, month, 0);
            days = days.getDate(); //获取当前日期中的月的天数
            var year2 = year;
            var month2 = parseInt(month) + months;
            if (month2 > 12) {
                year2 = parseInt(year2) + 1;
                month2 = month2 -12;
            }
            var day2 = day;
            var days2 = new Date(year2, month2, 0);
            days2 = days2.getDate();
            if (day2 > days2) {
                day2 = days2;
            }
            if (month2 < 10) {
                month2 = '0' + month2;
            }

            var t2 = year2 + '-' + month2 + '-' + day2;
            return new Date(year2, month2 - 1, day2);
        }
        function getNewMonthFirstDay(date, months) {
            if(!date){
                return;
            }
            var dateTemp;
            if(typeof(date) != "string"){
                dateTemp = convertLocalDateToServer(date);
            }
            else {
                dateTemp = date;
            }
            var arr = dateTemp.split('-');
            var year = arr[0]; //获取当前日期的年份
            var month = arr[1]; //获取当前日期的月份
            var day = arr[2]; //获取当前日期的日
            var days = new Date(year, month, 0);
            days = days.getDate(); //获取当前日期中的月的天数
            var year2 = year;
            var month2 = parseInt(month) + months;
            if (month2 > 12) {
                year2 = parseInt(year2) + 1;
                month2 = 1;
            }
            var day2 = day;
            var days2 = new Date(year2, month2, 0);
            days2 = days2.getDate();
            if (day2 > days2) {
                day2 = days2;
            }
            if (month2 < 10) {
                month2 = '0' + month2;
            }
            return new Date(year2, month2 - 1, 1);
        }

        /**
         * 获取上一个月
         *
         * @date 格式为yyyy-mm-dd的日期，如：2014-01-25
         */

        function getPreMonth(date) {
            var arr = date.split('-');
            var year = arr[0]; //获取当前日期的年份
            var month = arr[1]; //获取当前日期的月份
            var day = arr[2]; //获取当前日期的日
            var days = new Date(year, month, 0);
            days = days.getDate(); //获取当前日期中月的天数
            var year2 = year;
            var month2 = parseInt(month) - 1;
            if (month2 == 0) {
                year2 = parseInt(year2) - 1;
                month2 = 12;
            }
            var day2 = day;
            var days2 = new Date(year2, month2, 0);
            days2 = days2.getDate();
            if (day2 > days2) {
                day2 = days2;
            }
            if (month2 < 10) {
                month2 = '0' + month2;
            }
            var t2 = year2 + '-' + month2 + '-' + day2;
            return t2;
        }

        function compareDate (date1, date2) {
            if(!date1 && !date2){
                return 0;
            }
            else if(!date1){
                return -1;
            }
            else if(!date2){
                return 1;
            }

            var oDate1 = new Date(date1);
            var oDate2 = new Date(date2);
            if(oDate1.getDate() == oDate2.getDate()
                && oDate1.getMonth() == oDate2.getMonth()
                && oDate1.getFullYear() == oDate2.getFullYear()){
                return 0;
            }
            else if(oDate1.getFullYear() > oDate2.getFullYear()){
                return 1;
            }
            else if(oDate1.getFullYear() == oDate2.getFullYear()
                && oDate1.getMonth() > oDate2.getMonth()){
                return 1;
            }
            else if(oDate1.getFullYear() == oDate2.getFullYear()
                && oDate1.getMonth() == oDate2.getMonth()
                && oDate1.getDate() > oDate2.getDate()){
                return 1;
            }
            else {
                return -1
            }
        }

        /**
        * 获取多少天后的新日期
        */
        function getNewDay (date, days) {
            if(!date){
                return;
            }
            if(typeof(date) != "string"){
                date = convertLocalDateToServer(date);
            }
            if(date.length > 10){
                date = date.substring(0, 10);
            }
            var dateTemp = date.split("-");
            var nDate = new Date(dateTemp[1] + '-' + dateTemp[2] + '-' + dateTemp[0]); //转换为MM-DD-YYYY格式

            var millSeconds = Math.abs(nDate) + (days * 24 * 60 * 60 * 1000);
            return new Date(millSeconds);
        }

        function getTimeDiffHour (d1, d2) {
            if(!d1){
                return "NA";
            }
            d1 = new Date(d1);
            d2 = new Date(d2);
            var result = parseInt(d2 - d1)/ 1000 / 60 / 60;
            return result.toFixed(0);
        }

        function isSameMonth (d1, d2) {
            var date1, date2, aDate, bDate;
            date1 = convertLocalDateToServer(d1);
            date2 = convertLocalDateToServer(d2);
            aDate = date1.split("-");
            bDate = date2.split("-");
            if (aDate[1] != bDate[1] || aDate[0] != bDate[0]) {
                return false;
            }
            return true;
        }

        function convertDateTimeFromServer (date) {
            if (date) {
                return new Date(date);
            } else {
                return null;
            }
        }

        function convertLocalDateFromServer (date) {
            if (date) {
                date = date+"";
                var dateString = date.split('-');
                return new Date(dateString[0], dateString[1] - 1, dateString[2]);
            }
            return null;
        }

        function convertLocalDateToServer (date) {
            if (date) {
                return $filter('date')(date, 'yyyy-MM-dd');
            } else {
                return null;
            }
        }

        /*
        *获取时间字符串
        */
        function convertDateTimeToString (DateIn){
            if (!DateIn){
                return null;
            }
            DateIn= new Date(DateIn);
            var Year=0;
            var Month=0;
            var Day=0;
            var Hour = 0;
            var Minute = 0;
            var CurrentDate="";
            Year      = DateIn.getFullYear();
            Month     = DateIn.getMonth()+1;
            Day       = DateIn.getDate();
            Hour      = DateIn.getHours();
            Minute    = DateIn.getMinutes();
            CurrentDate = Year + "-";
            if (Month >= 10 )
            {
                CurrentDate = CurrentDate + Month + "-";
            }
            else
            {
                CurrentDate = CurrentDate + "0" + Month + "-";
            }
            if (Day >= 10 )
            {
                CurrentDate = CurrentDate + Day ;
            }
            else
            {
                CurrentDate = CurrentDate + "0" + Day ;
            }

             if(Hour >=10)
            {
                CurrentDate = CurrentDate + " " + Hour ;
            }
            else
            {
                CurrentDate = CurrentDate + " 0" + Hour ;
            }
            if(Minute >=10)
            {
                CurrentDate = CurrentDate + ":" + Minute ;
            }
            else
            {
                CurrentDate = CurrentDate + ":0" + Minute ;
            }
            return CurrentDate;
        }

        function dateformat () {
            return 'yyyy-MM-dd';
        }

        function getAccountDate (companyId, inDate, podArea, shipmentType){
            if ($rootScope.isZb(companyId)) {
                inDate = getNewMonth(convertLocalDateToServer(inDate), 2);
            }
            else if ($rootScope.isYuyue(companyId)) {
                if(shipmentType == "AIR"){
                    inDate = getNewDay(convertLocalDateToServer(inDate), 15);
                }
                else if(!podArea){
                    inDate = getNewMonth(convertLocalDateToServer(inDate), 2);
                }
                else if(podArea == "AMW" || podArea == "ANZ"){
                    inDate = getNewDay(convertLocalDateToServer(inDate), 45);
                }
                else if(podArea == "ASL"){
                    inDate = getNewDay(convertLocalDateToServer(inDate), 30);
                }
                else{
                    inDate = getNewMonth(convertLocalDateToServer(inDate), 2);
                }
            }
            return inDate;
        }

        function convertObjDateAndTimeFromServer (obj, dateFds, timeFds){
            angular.forEach(dateFds, function(fd){
                if(!angular.isDate(obj[fd])){
                    obj[fd] = convertLocalDateFromServer(obj[fd]);
                }
            })
            angular.forEach(timeFds, function(fd){
                if(!angular.isDate(obj[fd])){
                    obj[fd] = convertDateTimeFromServer(obj[fd]);
                }
            })
        }
        function convertObjDateToServer (obj, dateFds){
            angular.forEach(dateFds, function(fd){
                obj[fd] = convertLocalDateToServer(obj[fd]);
            })
        }
        function convertFdDateAndTimeFromServer (dt){
            if(!dt || !isNaN(dt) || !angular.isString(dt) || angular.isObject(dt) || dt.indexOf("-") == -1){
                return dt;
            }
            if(dt.length != 10 && dt.length != 24){
                return dt;
            }
            var dateTime = new Date(dt);
            if(isValidDate(dateTime)){
                if(dt.length == 10){
                    convertLocalDateFromServer(dt);
                }
                else {
                    return dateTime;
                }
            }
            return dt;
        }





    }

})();
