(function () {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CustomNodeDetailController', CustomNodeDetailController);

    CustomNodeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CustomNode'];

    function CustomNodeDetailController($scope, $rootScope, $stateParams, previousState, entity, CustomNode) {
        var vm = this;

        vm.customNode = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('autopsApp:customNodeUpdate', function (event, result) {
            vm.customNode = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
