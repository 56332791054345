(function () {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CustomNodeController', CustomNodeController);

    CustomNodeController.$inject = ['$scope', '$rootScope', '$state', '$uibModal', '$translate', 'Principal', 'entity',
        'CustomNode', 'CustomNodeSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'WinAES', 'Clerks'];

    function CustomNodeController($scope, $rootScope, $state, $uibModal, $translate, Principal, entity,
        CustomNode, CustomNodeSearch, ParseLinks, AlertService, pagingParams, paginationConstants, WinAES, Clerks) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.copyNode = copyNode;
        vm.loadClerks = loadClerks;
        vm.shareNode = shareNode;
        vm.autoWfFields = autoWfFields;
        vm.changeDisabled = changeDisabled;

        if(entity.type == 'RQ' || entity.type == 'HD' || entity.type == 'AF' || entity.type == 'AM' || entity.type == 'WF'){
            vm.type = entity.type;
        }
        else{
            vm.type = "ND";
        }

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                loadAll();
            });
        }
        else if ($rootScope.account && $rootScope.account.id) {
            loadAll();
        }

        function loadAll() {
            if(entity.type == 'RQ' || entity.type == 'HD' || entity.type == 'AF' || entity.type == 'WF'){
                if ($rootScope.HAVENOAUTH('AU_COMPANY_CONFIGURATION')) {
                    $rootScope.FORBIDDEN_BY_ALERT('AU_COMPANY_CONFIGURATION');
                    return;
                }
            }
            if(entity.type == 'AM'){
                if ($rootScope.HAVENOAUTH('AU_AM_MGMT')) {
                    $rootScope.FORBIDDEN_BY_ALERT('AU_AM_MGMT');
                    return;
                }
            }
            if (pagingParams.search) {
                CustomNodeSearch.query({
                    type: vm.type,
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                CustomNode.query({
                    type: vm.type,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.customNodes = data;
                vm.page = pagingParams.page;
                angular.forEach(vm.customNodes, function (data) {
                    data.encodeId = WinAES.encode(data.id.toString())
                })
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch,
                type: vm.type
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = true;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function copyNode(customNode) {
            CustomNode.get({id: customNode.id}, function(result){
                result.id = null;
                result.userId = null;
                result.companyId = null;
                result.createdTime = null;
                if(customNode.type != 'AM'){
                    result.ndName = null;
                }
                result.disabled = false;
                if(result.customConditions != null && result.customConditions.length > 0){
                    result.customConditions.forEach(function(data){
                        data.id = null;
                        data.customNodeId = null;
                    })
                }
                if(customNode.type == 'ND' || customNode.type == 'AM'){
                    var templateUrlStr = 'app/entities/custom-node/custom-node-dialog.html';
                    var controllerStr = 'CustomNodeDialogController';
                }else{
                    var templateUrlStr = 'app/entities/custom-node/custom-node-dialog-field.html';
                    var controllerStr = 'CustomNodeDialogFieldController';
                }
                $uibModal.open({
                    templateUrl: templateUrlStr,
                    controller: controllerStr,
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xl',
                    resolve: {
                        entity: result,
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('customNode');
                            $translatePartialLoader.addPart('global');
                            $translatePartialLoader.addPart('customCondition');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function () {
                    loadAll();
                }, function () { });
            }, $rootScope.ALERT_ERROR)
        }

        function shareNode(customNode, user) {
            CustomNode.shareNode({
                userId: user.id,
                cusomNodeId: customNode.id
            }, function(result){
                layer.msg($translate.instant('global.shareSuccess'));
            }, $rootScope.ALERT_ERROR);
        }

        function onSaveSuccess(result) {
            loadAll();
        }

        vm.clerks;
        function loadClerks() {
            if(vm.clerks && vm.clerks.length > 0){
                return;
            }
            Clerks.query({ companyId: $rootScope.account.companyId }, function(result) {
                vm.clerks = result;
            });
        }
        var unsubscribe = $rootScope.$on('autopsApp:customNodeUpdate', function(event, result) {
            var matched = false;
            angular.forEach(vm.customNodes, function(node){
                if(node.id == result.id){
                    angular.copy(result, node);
                    matched = true;
                    node.encodeId = WinAES.encode(node.id.toString());
                }
            })
            if(!matched) {
                loadAll();
            }
        });
        $scope.$on('$destroy', unsubscribe);

        vm.customNode = {};
        function autoWfFields() {
            if(!vm.customNode.role || !vm.customNode.shipmentType){
                layer.msg($translate.instant('autopsApp.customNode.autoWfFieldsAlert'));
                return;
            }
            var entity = {
                role: vm.customNode.role,
                shipmentType: vm.customNode.shipmentType,
                fbArray: vm.bindFdList
            }
            $uibModal.open({
                templateUrl: 'app/entities/custom-condition/custom-condition-dialog.html',
                controller: 'CustomConditionDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }

        function changeDisabled(customNode) {
            CustomNode.changeDisabled({
                id: customNode.id
            }, function(result){
                customNode.disabled = result.disabled;
                layer.msg($translate.instant('global.messages.submitted'));
            }, $rootScope.ALERT_ERROR);
        }


    }
})();
