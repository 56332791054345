(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsShipperDetailController', OpsShipperDetailController);

    OpsShipperDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'OpsShipper'];
    
    function OpsShipperDetailController($scope, $rootScope, $stateParams, entity, OpsShipper) {
        $scope.opsShipper = entity;
        $scope.load = function (id) {
            OpsShipper.get({id: id}, function(result) {
                $scope.opsShipper = result;
            });
        };
        var unsubscribe = $rootScope.$on('autopsApp:opsShipperUpdate', function(event, result) {
            $scope.opsShipper = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
    