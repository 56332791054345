(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsTemplateDetailController', OpsTemplateDetailController);

    OpsTemplateDetailController.$inject = ['$scope', '$location', '$rootScope', '$stateParams', '$state', '$translate', 'Principal', 'OpsItem', 'entity', 'OpsTemplate', 'OpsRates', 'OpsVender'];

    function OpsTemplateDetailController($scope, $location, $rootScope, $stateParams, $state, $translate, Principal, OpsItem, entity, OpsTemplate, OpsRates, OpsVender) {
        $scope.opsTemplate = entity;
        $scope.load = function(id) {
            OpsTemplate.get({
                id: id
            }, function(result) {
                $scope.opsTemplate = result;
            });
        };
        $scope.upperCode = function(code) {
            $scope.opsTemplate.code = angular.uppercase(code);
        };
        var path = $location.path();
        if (!$rootScope.id && path.indexOf("new") <= 0) {
            $state.go('opsTemplate', null, {
                reload: true
            });
        }
        
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                $rootScope.loadCurrencys();
            });
        }
        else {
            $rootScope.loadCurrencys();
        }


        $scope.addRate = function() {
            $scope.opsTemplate.rates.push({
                isPay: false
            });
        };
        $scope.delRate = function(rate) {
            if (rate.id != null) {
                OpsRates.delete({
                    id: rate.id
                });
            };
            var index = $scope.opsTemplate.rates.indexOf(rate);
            if (index > -1) {
                $scope.opsTemplate.rates.splice(index, 1);
            };
        };


        var onSaveSuccess = function(result) {
            $scope.isSaving = false;
            $state.go('opsTemplate', null, {
                reload: true
            });
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
        };

        $scope.save = function() {
            $scope.isSaving = true;
            if ($scope.opsTemplate.id != null) {
                OpsTemplate.update($scope.opsTemplate, onSaveSuccess, onSaveError);
            } else {
                OpsTemplate.save($scope.opsTemplate, onSaveSuccess, onSaveError);
            }
        };

        $scope._onItemSelected = function(data, rate) {
            if ($translate.instant("autopsApp.booking.opsItem.cnen") == "nameEn") {
                rate.item = data.nameEn;
            } else {
                rate.item = data.nameCn;
            };
            rate.itemId = data.id;
        }

        $scope.setIsPayTrue = function($index) {
            $scope.opsTemplate.rates[$index].isPay = true;
        };

        $scope.setIsPayFalse = function($index) {
            $scope.opsTemplate.rates[$index].isPay = false;
        };

        var addAutoMatchVenders = function(data, rate) {
            $scope.opsVenders = angular.copy($rootScope.OPSVENDERS);
            $scope.opsVenders.splice(0, 0, {
                id: -1,//"客户"
                code: "TYPE",
                companyName: $translate.instant('autopsApp.opsTemplate.customer')
            }, {
                id: -2,//"发/收货人"
                code: "TYPE",
                companyName: $translate.instant('autopsApp.opsTemplate.shipper')
            }, {
                id: -3,//"订舱代理"
                code: "TYPE",
                companyName: $translate.instant('autopsApp.opsTemplate.bookingAgent')
            }, {
                id: -4,//"海外代理"
                code: "TYPE",
                companyName: $translate.instant('autopsApp.opsTemplate.overseasAgent')
            }, {
                id: -11,//"清关代理"
                code: "TYPE",
                companyName: $translate.instant('autopsApp.opsTemplate.clearAgent')
            }, {
                id: -5,//"拖车行"
                code: "TYPE",
                companyName: $translate.instant('autopsApp.opsTemplate.trucker')
            }, {
                id: -6,//"报关行"
                code: "TYPE",
                companyName: $translate.instant('autopsApp.opsTemplate.broker')
            }, {
                id: -7,//"仓储公司"
                code: "TYPE",
                companyName: $translate.instant('autopsApp.opsTemplate.warehouse')
            }, {
                id: -8,//"贸易公司"
                code: "TYPE",
                companyName: $translate.instant('autopsApp.opsTemplate.trader')
            }, {
                id: -9,//"保险公司"
                code: "TYPE",
                companyName: $translate.instant('autopsApp.opsTemplate.insurance')
            }, {
                id: -10,//"熏蒸公司"
                code: "TYPE",
                companyName: $translate.instant('autopsApp.opsTemplate.fumigation')
            });  
        }

        $scope.opsVenders = [];
        var loadAllVenders = function() {
            if (!$rootScope.OPSVENDERS || $rootScope.OPSVENDERS.length == 0){
                OpsVender.query(function(result) {
                    $rootScope.OPSVENDERS = result;
                    angular.forEach($rootScope.OPSVENDERS, function(data) {
                        data.branch = null;
                        data.team = null;
                        data.branch = null;
                        data.team = null;
                    })
                    addAutoMatchVenders();
                });   
            }
            else {
                addAutoMatchVenders();
            }
        }
        loadAllVenders();

        $scope._onVenderSelected = function(data, rate) {
            rate.venderName = data.companyName;
            rate.venderId = data.id;
        }
        $scope.checkCodeRepeat = function(data, rate) {
            if (!$scope.opsTemplate.code) {
                return;
            } else {
                OpsTemplate.checkCodeRepeat({
                    companyId: $rootScope.account.companyId,
                    tempId: $scope.opsTemplate.id,
                    code: $scope.opsTemplate.code
                }, function(result) {
                    if (result.isRepeated) {
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.opsTemplate.codeRepeated'));
                        $scope.opsTemplate.code = null;
                        return;
                    }
                });
            }
        }

    }
})();