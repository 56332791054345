(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GuideCtnsDialogController', GuideCtnsDialogController);

    GuideCtnsDialogController.$inject = ['$scope', '$rootScope', '$stateParams', '$uibModalInstance', '$translate', 
    'entity', 'GuideCtns'];
   
    function GuideCtnsDialogController($scope, $rootScope, $stateParams, $uibModalInstance, $translate, 
        entity, GuideCtns) {

        $scope.guideCtns = entity;
        $scope.load = function(id) {
            GuideCtns.get({id : id}, function(result) {
                $scope.guideCtns = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('autopsApp:guideCtnsUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($rootScope.account.companyId){
                $scope.guideCtns.companyId = $rootScope.account.companyId;
            }
            else {
                $scope.guideCtns.createdBy = $rootScope.account.id;
            }
            $scope.guideCtns.isTemplate = true;
            GuideCtns.saveAsTemplate($scope.guideCtns, onSaveSuccess, onSaveError);
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        $scope.checkTruckNum = function() {
            if (!$scope.guideCtns.truckNum) {
                return;
            } else {
                GuideCtns.checkTruckNumRepeat({
                    companyId: $rootScope.account.companyId,
                    id: $scope.guideCtns.id,
                    truckNum: $scope.guideCtns.truckNum
                }, function(result) {
                    if (result.isRepeated) {
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.guideCtns.truckNumRepeat'));
                        return;
                    }
                });
            }
        }
        
}
})();
