(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('bookingTemplate', {
                parent: 'entity',
                url: '/bookingTemplates',
                data: {
                    authorities: ['ROLE_CLIENT'],
                    pageTitle: 'autopsApp.bookingTemplate.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/bookingTemplate/bookingTemplates.html',
                        controller: 'BookingTemplateController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bookingTemplate');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('bookingTemplate.detail', {
                parent: 'entity',
                url: '/bookingTemplate',
                data: {
                    authorities: ['ROLE_CLIENT'],
                    pageTitle: 'autopsApp.bookingTemplate.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/bookingTemplate/bookingTemplate-detail.html',
                        controller: 'BookingTemplateDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bookingTemplate');
                        return $translate.refresh();
                    }],
                    entity: ['$rootScope', 'BookingTemplate', function($rootScope, BookingTemplate) {
                        if ($rootScope.id){
                        return BookingTemplate.get({id : $rootScope.id});
                    }
                    }]
                }
            })
            .state('bookingTemplate.new', {
                parent: 'bookingTemplate',
                url: '/new',
                data: {
                    authorities: ['ROLE_CLIENT'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/bookingTemplate/bookingTemplate-dialog.html',
                        controller: 'BookingTemplateDialogController',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    createdBy: null,
                                    code: null,
                                    remarks: null,
                                    bookingId: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('bookingTemplate', null, { reload: true });
                    }, function() {
                        $state.go('bookingTemplate');
                    })
                }]
            })
            .state('bookingTemplate.edit', {
                parent: 'bookingTemplate',
                url: '/edit',
                data: {
                    authorities: ['ROLE_CLIENT'],
                },
                onEnter: ['$rootScope', '$state', '$uibModal', function($rootScope, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/bookingTemplate/bookingTemplate-dialog.html',
                        controller: 'BookingTemplateDialogController',
                        size: 'md',
                        resolve: {
                            entity: ['BookingTemplate', function(BookingTemplate) {
                                if ($rootScope.id){
                                return BookingTemplate.get({id : $rootScope.id});
                            }
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('bookingTemplate', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('bookingTemplate.delete', {
                parent: 'bookingTemplate',
                url: '/delete',
                data: {
                    authorities: ['ROLE_CLIENT'],
                },
                onEnter: ['$rootScope', '$state', '$uibModal', function($rootScope, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/bookingTemplate/bookingTemplate-delete-dialog.html',
                        controller: 'BookingTemplateDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['BookingTemplate', function(BookingTemplate) {
                                if ($rootScope.id){
                                    return {    
                                        code: $rootScope.num, 
                                        id: $rootScope.id
                                        };
                                }
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('bookingTemplate', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }
})();
