(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CommodityDatabaseDeleteController',CommodityDatabaseDeleteController);

    CommodityDatabaseDeleteController.$inject = ['$uibModalInstance', 'entity', 'CommodityDatabase'];

    function CommodityDatabaseDeleteController($uibModalInstance, entity, CommodityDatabase) {
        var vm = this;

        vm.commodityDatabase = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CommodityDatabase.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
