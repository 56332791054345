(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OaApplicationDeleteController',OaApplicationDeleteController);

    OaApplicationDeleteController.$inject = ['$uibModalInstance', '$rootScope', 'entity', 'OaApplication'];

    function OaApplicationDeleteController($uibModalInstance, $rootScope, entity, OaApplication) {
        var vm = this;

        vm.oaApplication = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            OaApplication.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                }, function(){
                    $rootScope.FORBIDDEN_BY_ALERT("AU_DEL_OA")
                });
        }
    }
})();
