(function() {
	'use strict';

	angular
		.module('autopsApp')
		.controller('PerformanceController', PerformanceController);

	PerformanceController.$inject = ['$scope', '$rootScope', '$translate', '$state', '$filter', '$uibModal', '$timeout',
	'Principal', 'Auth', 'DateUtils', 'OpsFiles', 'OpsCompany', 'uiGridConstants', 'GridState', 'User', 'DataUtils', 'AsyncTask'];

	function PerformanceController($scope, $rootScope, $translate, $state, $filter, $uibModal, $timeout,
		Principal, Auth, DateUtils, OpsFiles, OpsCompany, uiGridConstants, GridState, User, DataUtils, AsyncTask) {

        $scope.companys = [];
        $scope.statParams = {};
        $scope.statParams.dateType = "etd";
        $scope.statParams.statType = "month";
        $scope.statParams.month = new Date();

		$scope.loadCompanys = function () {
			if($scope.companys && $scope.companys.length > 0){
                return;
            }
			if($rootScope.account.isSuper && $rootScope.HAVEAUTH('FUN_VIEW_GROUP_BIZ')){
				OpsCompany.getGroupCompanys(function(result){
					$scope.companys = result;
				})
			}
		}

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;      
            });
        }
		//----------统计报表

		$scope.datePickerOpenStatus = {};

		$scope.yearFormat = "yyyy";
		$scope.monthFormat = "yyyy-MM";
		$scope.dayFormat = "yyyy-MM-dd";
		$scope.yearDatepickerOptions = {
			minMode: 'year'
		}
		$scope.monthDatepickerOptions = {
			minMode: 'month'
		}
		$scope.dayDatepickerOptions = {
			minMode: 'day'
		}
		$scope.datePickerOpenStatus.yearpicker = false;
		$scope.datePickerOpenStatus.monthpicker = false;
		$scope.datePickerOpenStatus.daypicker = false;
		$scope.datePickerOpenStatus.dateFromPicker = false;
		$scope.datePickerOpenStatus.dateToPicker = false;

		$scope.openCalendar = function(date) {
			$scope.datePickerOpenStatus[date] = true;
		}

		$scope.isSaving = false;
		var getStatParams = function() {
			$scope.statParams.etdFM = "";
			$scope.statParams.etdTO = "";
			$scope.statParams.fileName = "";
			$scope.statParams.statBy = "sales";
			//统计区间
			if ($scope.statParams.statType == "irregular") {
				$scope.statParams.etdFM = $scope.statParams.etdFM_;
				$scope.statParams.etdTO = $scope.statParams.etdTO_;
			}
			if ($scope.statParams.statType == "year" && $scope.statParams.year) {
				var year = $scope.statParams.year.getFullYear(); //取当前的年份          
				$scope.statParams.etdFM = new Date(year, 0, 1);
				$scope.statParams.etdTO = new Date(year, 11, 31);
			}
			if ($scope.statParams.statType == "month" && $scope.statParams.month) {
				var year = $scope.statParams.month.getFullYear(); //取当前的年份          
				var month = $scope.statParams.month.getMonth();
				if (month == 11) {
					month = month -12;
					year++;
				}
				$scope.statParams.etdFM = new Date(year, month, 1);
				$scope.statParams.etdTO = new Date(year, month+1, 0);

			}
			if ($scope.statParams.statType == "day" && $scope.statParams.day) {
				$scope.statParams.etdFM = $scope.statParams.day;
				$scope.statParams.etdTO = $scope.statParams.day;
			}
			if(!$scope.statParams.etdFM && !$scope.statParams.etdTO){
				layer.msg($translate.instant('stats.stat.dateNullAlert'));				
				return;
			}
			if($scope.salesList && $scope.salesList.length > 0){
				$scope.statParams.salesIds = [];
				angular.forEach($scope.salesList, function(data){
					$scope.statParams.salesIds.push(data.id);
				})
				$scope.statParams.filterRequired = true;
			}
			else {
				$scope.statParams.salesIds = null;
				$scope.statParams.filterRequired = false;
			}		
			if($scope.companyList && $scope.companyList.length > 0){
                $scope.statParams.companyIds = [];
                angular.forEach($scope.companyList, function(data){
                    if($scope.statParams.companyIds.indexOf(data.id) == -1){
                        $scope.statParams.companyIds.push(data.id);
                    }                   
                })
            }
            else {
                $scope.statParams.companyIds = null;
            }	
            if($rootScope.HAVEAUTH('WMS_VIRTUAL')){
                $scope.statParams.haveVirtualWms = true;
            }
            if($scope.statParams.team || $scope.statParams.branch){
            	$scope.statParams.filterRequired = true;
            }
		}

		$scope.reportavailalbe = false;
		$scope.statsResult = {};
		$scope.getContents = function() {
			$scope.isSaving = true;
			getStatParams();
			$scope.reportavailalbe = false;
			OpsFiles.statsPerformance($scope.statParams, function(result) {
				$scope.isSaving = false;
				$scope.statsResult = result;
				if (!$scope.statsResult.profitStatByUserDTOs || $scope.statsResult.profitStatByUserDTOs.length == 0) {
					initContent();
					layer.msg($translate.instant('stats.stat.noData'));
					$translate.refresh();
					return;
				}
				$scope.gridOptions.data = $scope.statsResult.profitStatByUserDTOs; 
				resize();
				$scope.reportavailalbe = true;
			}, function(response) {
				$scope.isSaving = false;
				$rootScope.ALERT_ERROR(response);
			});
		}

        window.onresize = function(){
            resize();
        }

        function resize() {
            var profit = 0;
            var rev = 0;
            angular.forEach($scope.gridOptions.data, function(data){
                if(data.profitRMB){
                    profit += data.profitRMB;
                }
                if(data.totalRMB){
                    rev += data.totalRMB;
                }
            })
            if(rev){
                $scope.avgGp = DataUtils.round(profit/rev*100, 2) + "%";
            }
            else {
                $scope.avgGp = "N/A";
            }

            var tabHeight = $scope.gridOptions.data.length * 30 + 180;
            var winHeight = $(window).height() - $("#gridId").offset().top -3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 300){
                tabHeight = 300;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }


		$scope.gridOptions = {
		    enableSorting: true,//排序
            enableRowHeaderSelection : true,  //行选中
            enableGridMenu : true,//表格 菜单  
            showGridFooter: true,//表格的footer  
            enableFiltering: true,//高级过滤
            showColumnFooter: true,//每列footer

            minRowsToShow: 20,
            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

		    enableRowSelection: true, //行选中
		    enableSelectAll: true, //全部选中
		    enableFullRowSelection : false, //行任意位置选中

            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
		    exporterExcelFilename: $translate.instant('global.menu.admin.performance') + '.xlsx',
		    exporterExcelSheetName: 'Sheet1',
		    exporterSuppressColumns : ['id'], 
		    columnDefs: [
                { field: 'branch', 
                    displayName: $translate.instant('stats.stat.bbranch') + "(" + $translate.instant('stats.stat.salesName') + ")", 
                    minWidth:120},
                { field: 'department', 
                    displayName: $translate.instant('stats.stat.bteam') + "(" + $translate.instant('stats.stat.salesName') + ")", 
                    minWidth:120},
		      	{ field: 'userName',
			      	minWidth:100, 
		      		displayName: $translate.instant('stats.stat.salesName'), 
		      		cellTemplate: '<a href="" ng-click="grid.appScope.showAllGuides(row.entity)" ng-if="row.entity.guides">{{row.entity.userName}}</a>'},
		      	{ field: 'bills', 
		      		aggregationType: uiGridConstants.aggregationTypes.sum, 
		      		minWidth:100, 
		      		displayName: $translate.instant('stats.stat.orderQuantity')},
		      	{ field: 'teus', 
		      		aggregationType: uiGridConstants.aggregationTypes.sum, 
		      		minWidth:100, 
		      		displayName: $translate.instant('stats.stat.teu')},
		      	{ field: 'currency', 
		      		minWidth:100, 
		      		displayName: $translate.instant('stats.stat.currency')},
		      	{ field: 'totalRMB', 
		      		aggregationType: uiGridConstants.aggregationTypes.sum, 
		      		minWidth:100, 
		      		displayName: $translate.instant('stats.stat.receivableTtl')},
		      	{ field: 'costRmbTtl', 
		      		aggregationType: uiGridConstants.aggregationTypes.sum, 
		      		minWidth:100, 
		      		displayName: $translate.instant('stats.stat.payableTtl')},
		      	{ field: 'uncollectedRMB', 
		      		aggregationType: uiGridConstants.aggregationTypes.sum, 
		      		minWidth:100, 
		      		displayName: $translate.instant('stats.stat.balanceTtl')},
		      	{ field: 'unPaidRMB', 
		      		aggregationType: uiGridConstants.aggregationTypes.sum, 
		      		minWidth:100, 
		      		displayName: $translate.instant('stats.stat.unPaidRMB')},  
		      	{ field: 'profitRMB', 
		      		aggregationType: uiGridConstants.aggregationTypes.sum, 
		      		minWidth:100, 
		      		displayName: $translate.instant('stats.stat.profit')},
		      	{ field: 'gp', 
		      		type:'number',
		      		// aggregationType: uiGridConstants.aggregationTypes.avg, 
		      		minWidth:100, 
		      		footerCellTemplate: '<div class="grid-text" ng-show="grid.appScope.avgGp">Avg: {{grid.appScope.avgGp}}</div>',
		      		displayName: $translate.instant('stats.stat.gp')}
  			],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 83;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }     
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);                   
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
            }
		}

        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 83
            }, function(result){
                gridStateObj = result;
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }   
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
            })
        });



		var initContent = function() {
			$scope.statsResult = {};
			$scope.guides = [];
			for (var j = 0; j <= 10; j++) {
				$scope.guides.push({});
			}

			$scope.statsResult.profitStatByUserDTOs = [];
			for (var j = 0; j <= 12; j++) {
				$scope.statsResult.profitStatByUserDTOs.push({});
			}
			$scope.gridOptions.minRowsToShow = $scope.statsResult.profitStatByUserDTOs.length;
			$scope.gridOptions.data = $scope.statsResult.profitStatByUserDTOs; 
		}

		initContent();
		$scope.exportReport = function(format) {
			// layer.msg($translate.instant('global.developingAlert'));
			// return;
			$scope.statParams.fileType = format;
			$scope.statParams.fileName = "";
			$scope.fileName_btn = layer.open({
				type: 1,
				area: ['300px', '180px'],
				btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')],
				title: $translate.instant('global.fileName'),
				shade: 0.3,
				moveType: 0,
				shift: 0,
				content: $("#templateReportStatName"),
				success: function(layero, index) {
					$("#statFileName").focus();
				},
				yes: function(index, layero) {
					if ($scope.statParams.fileName == '' || angular.isUndefined($scope.statParams.fileName)) {
						$scope.statParams.fileName = 'Performance';
					}
					getStatFile();
					layer.close($scope.fileName_btn);
				},
				no: function(index, layero) {
					layer.close(index);
				}
			});


		}

		$("#statFileName").keydown(function(event) {
			$scope.keycode = window.event ? event.keyCode : event.which;
			if ($scope.keycode == 13) {
				if ($scope.statParams.fileName == '' || angular.isUndefined($scope.statParams.fileName)) {
					$scope.statParams.fileName = 'Performance';
				}
				getStatFile();
				layer.close($scope.fileName_btn);
			}
		})
		var getStatFile = function() {
			$scope.statParams.opType = false;
			OpsFiles.generatePerformanceReport({
					fileType:$scope.statParams.fileType,
					dateScope:$filter('date')($scope.statParams.month, 'yyyy-MM')
			},$scope.statsResult, function(result) {
				exportExcelOrPdf(result);
			}, function(error) {
				layer.msg($translate.instant('stats.stat.noData'));
			});
		}

		var exportExcelOrPdf = function(result) {
			if (angular.isDefined($scope.statParams.fileType) && $scope.statParams.fileType == 2) {
				var file = new Blob([result.data], {
					type: 'application/vnd.ms-excel'
				});
				var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
				var aTag = document.createElement("a");
				aTag.download = $scope.statParams.fileName + ".xlsx";
				aTag.href = blobURL;
				aTag.click();
			} else {
                var file = new Blob([result.data], {
                    type: 'application/pdf'
                });
                var fileURL = URL.createObjectURL(file);
                var previewDialog = layer.open({
                    type: 2,
                    area: ['1200px', '800px'],
                    maxmin: true,
                    title: $translate.instant("stats.stat.viewPerformance"),
                    shade: 0.3,
                    moveType: 0,
                    shift: 0,
                    content: fileURL
                })
            }
		}
		$scope.showAllGuides = function(content) {
        	var entity = {};
        	entity.content = content;
        	entity.statParams = $scope.statParams;
            $uibModal.open({
                templateUrl: 'app/entities/stats/profit/job-list-dialog.html',
                controller: 'JobListDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }

        $scope.salesList = [];
        $scope._onClerkSelected = function(fd, item) {
            if(fd == "salesId"){
            	$scope.salesList.push(item);
            }
            $scope.statParams[fd] = "";
        };
        $scope.delSales = function(index) {
	        $scope.salesList.splice(index, 1);
        };

        $scope.companyList = [];        
        $scope._onCompanySelected = function(company) {
        	if(company.id == 0){
        		$scope.companyList = [];
        		angular.forEach($scope.companys, function(data){
        			if(data.id != 0){
        				$scope.companyList.push(data); 
        			}	        		
	        	})  
	        	return;
        	}
        	angular.forEach($scope.companys, function(data){
        		if(data.id == company.id){
        			var isNew = true;
        			angular.forEach($scope.companyList, function(data1){
        				if(data1.id == data.id){
        					isNew = false;
        				}
        			})
        			if(isNew){
        				$scope.companyList.push(data);
        			}        			
        		}
        	})       
        	$scope.statParams.company = "";     
        };
        $scope.delCompany = function(index) {
	        $scope.companyList.splice(index, 1);
        };
		$scope.salesFilter = function(item) {
            if (item.authorities.indexOf("ROLE_SALES") !== -1 
            	&& $rootScope.account.authorities.indexOf("AU_STAT_ALL") !== -1) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_SALES") !== -1 
            	&& $rootScope.account.authorities.indexOf("AU_STAT_BRANCH") !== -1
            	&& item.branch == $rootScope.account.branch) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_SALES") !== -1 
            	&& $rootScope.account.authorities.indexOf("AU_STAT_TEAM") !== -1
            	&& item.team == $rootScope.account.team) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_SALES") !== -1 
            	&& item.id == $rootScope.account.id) {
                return true;
            }
            return false;
		};
        $scope.asyncTasks = function(allCols) {
            getStatParams();
            var gridState = $scope.gridApi.saveState.save();
            DataUtils.fillGridDisplayName(gridState, $scope.gridOptions.columnDefs, allCols);            

            var body = {};
            body.statParams = $scope.statParams;
            body.gridState = gridState;
            body.taskType = "PERFORMANCE";
            $scope.isSaving = true;        
            var loadIndex = layer.load(1, {shade: 0.3});
            AsyncTask.submitAsyncTask(body, function(result){
                $scope.isSaving = false;
                layer.close(loadIndex);
                $rootScope.OPS_ALERT($translate.instant('autopsApp.asyncTask.tips.taskSubmitSuccess'));
            }, $rootScope.ALERT_ERROR);
        }

	}
})();