(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ChangeSalesController', ChangeSalesController);

    ChangeSalesController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$translate', '$timeout',
    'entity', 'OpsVender'];
    
    function ChangeSalesController($scope, $rootScope, $uibModalInstance, $translate, $timeout, 
        entity, OpsVender) {

        $scope.opsClient = entity;        
        $scope.isSaving = false;

        $timeout(function (){            
            if ($rootScope.account.companyId !=  $scope.opsClient.companyId && $scope.opsClient.companyId){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.opsClient.groupClientAlert'));
                $uibModalInstance.dismiss('cancel');
                return;
            }
            else if($scope.opsClient.createdBy 
                && $rootScope.account.id != $scope.opsClient.createdBy
                && $rootScope.HAVENOAUTH('FUN_VIEW_ALL_CLIENTS')
                && ($rootScope.HAVENOAUTH('ROLE_BRANCH') || $rootScope.account.branch != $scope.opsClient.branch)
                && ($rootScope.HAVENOAUTH('ROLE_DEPARTMENT') || $rootScope.account.branch != $scope.opsClient.branch
                    || $rootScope.account.team != $scope.opsClient.team)){
                $rootScope.OPS_ALERT($translate.instant('global.forbidden'));
                $uibModalInstance.dismiss('cancel');
                return;
            }
            else {
                $rootScope.loadClerks();
            }
        });

        
        $scope.newSalesId = 0;
        $scope.updateSales = function(item) {
            $scope.newSalesId = item.id;
        };

        $scope.salesFilter = function(item) {
            var authorities = ["ROLE_SALES", "ROLE_CS", "ROLE_BOSS"];            
            for (var i = 0; i < authorities.length; i++) {
                if (item.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }
            return false;
        };

        
        $scope.save = function() {
            $scope.isSaving = true;
            OpsVender.shiftSales({newSalesId:$scope.newSalesId}, $scope.opsClient, onShiftSuccess, onShiftError);
        };


        var onShiftSuccess = function (result) {            
            $scope.isSaving = false;
            $uibModalInstance.close(result);
            layer.msg($translate.instant('global.save_success'));
        };

        var onShiftError = function (result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('global.save_fail'));            
        };


        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };








}
})();
