(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountCashierSearchController', AccountCashierSearchController);

    AccountCashierSearchController.$inject = ['$rootScope', '$scope', '$uibModalInstance', '$http', 'entity', 'OpsVender', 'AccountCashier'];

    function AccountCashierSearchController ($rootScope, $scope, $uibModalInstance, $http, entity, OpsVender, AccountCashier) {
       
        $scope.searchParams = entity;
        var vm = this;
        vm.isSaving = false;
        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        $scope._onClerkSelected = function(clerk, data) {
            $scope.searchParams[clerk] = data.id;
        }

        $scope.setIsLocked= function(locked) {
            $scope.searchParams.isLocked = locked;
        }

        $scope.setIsDn= function(isDn) {
            $scope.searchParams.isDn = isDn;
        }   

        $scope.advanceSearch = function() {
            vm.isSaving = true;
            AccountCashier.advanceSearch($scope.searchParams, function(result) {
                vm.isSaving = false;
                $uibModalInstance.close(result);
            }, $rootScope.ALERT_ERROR);  
        };

    }
})();
