(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('opsItem', {
                parent: 'entity',
                url: '/opsItems',
                data: {
                    authorities: ['FUN_ITEM_MGMT', 'ROLE_CLIENT'],
                    pageTitle: 'autopsApp.opsItem.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/opsItem/opsItems.html',
                        controller: 'OpsItemController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsItem');
                        $translatePartialLoader.addPart('opsTemplate');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('opsItem.detail', {
                parent: 'entity',
                url: '/opsItem',
                data: {
                    authorities: ['FUN_ITEM_MGMT', 'ROLE_CLIENT'],
                    pageTitle: 'autopsApp.opsItem.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/opsItem/opsItem-detail.html',
                        controller: 'OpsItemDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsItem');
                        return $translate.refresh();
                    }],
                    entity: ['$rootScope', 'OpsItem', function($rootScope, OpsItem) {
                        if ($rootScope.id){
                        return OpsItem.get({id : $rootScope.id});
                        }
                    }]
                }
            })
            .state('opsItem.new', {
                parent: 'opsItem',
                url: '/new',
                data: {
                    authorities: ['FUN_ITEM_MGMT', 'ROLE_CLIENT'],
                },
                onEnter: ['$rootScope', '$state', '$uibModal', function($rootScope, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/opsItem/opsItem-dialog.html',
                        controller: 'OpsItemDialogController',
                        size: 'md',
                        backdrop: false,
                        resolve: {
                            entity: function () {
                                return {
                                    companyId: null,
                                    createdBy: null,
                                    code: null,
                                    nameCn: null,
                                    nameEn: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('opsItem', null, { reload: true });
                    }, function() {
                        $state.go('opsItem');
                    })
                }]
            })
            .state('opsItem.edit', {
                parent: 'opsItem',
                url: '/edit',
                data: {
                    authorities: ['FUN_ITEM_MGMT', 'ROLE_CLIENT'],
                },
                onEnter: ['$rootScope', '$state', '$uibModal', function($rootScope, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/opsItem/opsItem-dialog.html',
                        controller: 'OpsItemDialogController',
                        size: 'md',
                        backdrop: false,
                        resolve: {
                            entity: ['OpsItem', function(OpsItem) {
                                if ($rootScope.id){
                                return OpsItem.get({id : $rootScope.id});
                            }
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('opsItem', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }
})();
