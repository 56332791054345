(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('AccountCashier', AccountCashier);

    AccountCashier.$inject = ['$resource', 'DateUtils'];

    function AccountCashier ($resource, DateUtils) {
        var resourceUrl =  'api/account-cashiers/:id';

        return $resource(resourceUrl, {}, {
            'query': { 
                method: 'GET', 
                isArray: true, 
                transformResponse: function (data) {
                    if (!data){return;}   
                    data = angular.fromJson(data);
                    if (data && data.length >0) {
                        for(var i=0; i<data.length; i++){
                            data[i].transactionDate = DateUtils.convertLocalDateFromServer(data[i].transactionDate);
                        }   
                    }
                    return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.transactionDate = DateUtils.convertLocalDateFromServer(data.transactionDate);
                    }
                    return data;
                }
            },
            'createAccountVouchers': {
                method:'POST',
                url:'api/account-cashiers/createAccountVouchers',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    angular.forEach(data, function(data){
                        data.transactionDate = DateUtils.convertLocalDateToServer(data.transactionDate);
                    })                    
                    return angular.toJson(data);
                }
            },
            'getOneAccountVoucher': {
                method:'POST',
                url:'api/account-cashiers/getOneAccountVoucher',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.transactionDate = DateUtils.convertLocalDateToServer(data.transactionDate);                 
                    return angular.toJson(data);
                }
            },
            'setLocked':{
                method:'GET',
                url:'api/account-cashiers/setLocked/:id',
                isArray:false
            },
            'bindVoucher':{
                method:'GET',
                url:'api/account-cashiers/bindVoucher'
            },
            'refreshBalance':{
                method:'GET',
                url:'api/account-cashiers/refreshBalance'
            },            
            'getFilterPage': {
                method: 'POST',
                url:'api/account-cashiers/getFilterPage',
                isArray: true, 
                transformResponse: function (data) {
                    if (!data){return;}   
                    data = angular.fromJson(data);
                    if (data && data.length >0) {
                        for(var i=0; i<data.length; i++){
                            data[i].transactionDate = DateUtils.convertLocalDateFromServer(data[i].transactionDate);
                        }   
                    }
                    return data;
                }
            },     
            'getAllTransaction': {
                method: 'GET',
                url:'api/account-cashiers/getAllTransaction',  
                isArray: true, 
                transformResponse: function (data) {
                    if (!data){return;}   
                    data = angular.fromJson(data);
                    if (data && data.length >0) {
                        for(var i=0; i<data.length; i++){
                            data[i].transactionDate = DateUtils.convertLocalDateFromServer(data[i].transactionDate);
                        }   
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.transactionDate = DateUtils.convertLocalDateToServer(data.transactionDate);
                    return angular.toJson(data);
                }
            },
            'generateReport':{
                method:'POST',
                url:'api/account-cashiers/generateReport/:fileType',
                responseType: 'arraybuffer',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    angular.forEach(data, function(data){
                        data.transactionDate = DateUtils.convertLocalDateToServer(data.transactionDate);
                    })                    
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },
            'advanceSearch': {
                method:'PUT',
                url:'api/account-cashiers/advanceSearch',
                isArray:true,
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.transactionDateFM = DateUtils.convertLocalDateToServer(data.transactionDateFM);
                    data.transactionDateTO = DateUtils.convertLocalDateToServer(data.transactionDateTO);
                    return angular.toJson(data);
                }, 
                transformResponse: function (data) {
                    if (!data){return;}   
                    data = angular.fromJson(data);
                    if (data && data.length >0) {
                        for(var i=0; i<data.length; i++){
                            data[i].transactionDate = DateUtils.convertLocalDateFromServer(data[i].transactionDate);
                        }   
                    }
                    return data;
                }
            },  
            'comWriteOff': {
                method:'POST',
                url:'api/account/comWriteOff',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.accountCashier.transactionDate = DateUtils.convertLocalDateToServer(data.accountCashier.transactionDate);
                    angular.forEach(data.accountPaymentDTOs, function(request){
                        angular.forEach(request.writeOffRates, function(rate){
                            rate.etd = DateUtils.convertLocalDateToServer(rate.etd);
                        })
                    })
                    angular.forEach(data.accountInvoiceDTOs, function(request){
                        angular.forEach(request.writeRates, function(rate){
                            rate.etd = DateUtils.convertLocalDateToServer(rate.etd);
                        })
                    })
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    data.makeDate = DateUtils.convertLocalDateFromServer(data.makeDate);
                    return data;
                }
            },  
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.transactionDate = DateUtils.convertLocalDateToServer(data.transactionDate);
                    return angular.toJson(data);
                }
            }
        });
    }
})();
