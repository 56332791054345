(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GuideDocDialogController', GuideDocDialogController);

    GuideDocDialogController.$inject = ['$scope', '$rootScope', '$stateParams', '$uibModal', '$uibModalInstance', '$translate', '$timeout',
    'OpsCarrier', 'entity', 'GuideDoc', 'ExpressTracking'];
    
    function GuideDocDialogController($scope, $rootScope, $stateParams, $uibModal, $uibModalInstance, $translate, $timeout,
        OpsCarrier, entity, GuideDoc, ExpressTracking) {

        $scope.guides = entity;
        var choosedGuide;

        $scope.choosedGuide = {};
        $scope.chooseOne = function(guide) {      
            $scope.choosedGuide = guide;
            getDocs();
        }

        $timeout(function() {
            $scope.choosedGuide = $scope.guides[0];
            getDocs();
        })

        $scope.guideDocs = [];
        function getDocs(){
            if(!$scope.choosedGuide.id){
                return;
            }
            GuideDoc.get({id : $scope.choosedGuide.id}, function(result){
                $scope.guideDocs = result;
            })
        }      

        $scope.docTypes = [{
            C: "mbl",
            N: $translate.instant('autopsApp.guide.docType.mbl')
        }, {
            C: 'hbl',
            N: $translate.instant('autopsApp.guide.docType.hbl')
        }, {
            C: 'invoice',
            N: $translate.instant('autopsApp.guide.docType.invoice')
        }, {
            C: 'custom',
            N: $translate.instant('autopsApp.guide.docType.custom')
        }, {
            C: 'insurance',
            N: $translate.instant('autopsApp.guide.docType.insurance')
        }, {
            C: 'fumigation',
            N: $translate.instant('autopsApp.guide.docType.fumigation')
        }, {
            C: 'co',
            N: $translate.instant('autopsApp.guide.docType.co')
        }, {
            C: 'ciq',
            N: $translate.instant('autopsApp.guide.docType.ciq')
        }];

        
        $scope.onExpressSelected = function(carrier, doc) {       
            doc.carrierCode = carrier.code;
        }


        var onSaveSuccess = function (result) {
            $scope.guideDocs = result;
            layer.msg($translate.instant('global.messages.submitted'));
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            layer.msg($translate.instant('global.messages.submittedfailed'));
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            GuideDoc.update($scope.guideDocs, onSaveSuccess, onSaveError);
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.addDoc = function() {
            $scope.guideDocs.push({
                guideId: $scope.choosedGuide.id,
                createdByName: $rootScope.account.firstName + " " + $rootScope.account.lastName
            });
        };

        $scope.deleteDoc = function(doc) {
            if (doc.id != null) {
                GuideDoc.delete({
                    id: doc.id
                });
            };
            var index = $scope.guideDocs.indexOf(doc);
            if (index > -1) {
                $scope.guideDocs.splice(index, 1);
            };
        };

        $scope.docTracking = function(doc) {
            $uibModal.open({
                templateUrl: 'app/entities/express-tracking/express-tracking-delete-dialog.html',
                controller: 'ExpressTrackingDeleteController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return ExpressTracking.kd100Tracking({
                            carrier : doc.expressCompany,
                            carrierCode : doc.carrierCode, 
                            trackingNum : doc.expressNum
                        }).$promise;
                    }]
                }
            }).result.then(function(result) {

            }, function() {
            });
        }
        $scope.onDocTypeSelected = function(data, doc) { 
            if(data.C == "mbl"){
                doc.docNum = $scope.choosedGuide.mblNum;
            }
            else if (data.C == "hbl"){
                doc.docNum = $scope.choosedGuide.hblNum;
            }
        }
        $scope.signDoc = function(doc) { 
            if(!doc.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            GuideDoc.save(doc, function (result) {
                doc.signed = result.signed;
                doc.signedBy = result.signedBy;
                doc.id = result.id;
                doc.signTime = result.signTime;                
                layer.msg($translate.instant('global.messages.submitted'));
            }, onSaveError);
        }
        
    }       
})();
