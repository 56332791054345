(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GuideSearchDialogController', GuideSearchDialogController);

    GuideSearchDialogController.$inject = ['$scope', '$rootScope', '$timeout', '$translate', '$uibModalInstance', '$location', '$http', 
    'OpsPorts', 'entity', 'Guide', 'OpsVender', 'OpsCarrier', 'WmsWarehouse', 'GridState', 'DateUtils'];

    function GuideSearchDialogController ($scope, $rootScope, $timeout, $translate, $uibModalInstance, $location, $http,
        OpsPorts, entity, Guide, OpsVender, OpsCarrier, WmsWarehouse, GridState, DateUtils) {
       
        $scope.searchParams = entity;
        var vm = this;
        vm.isSaving = false;
        vm.clear = clear;
        vm.getWarehouses = getWarehouses;
        vm.warehouseSelected = warehouseSelected;
        vm.save = save;
        vm.clearCondition = clearCondition;
        
        vm.etdPlaceHolder = "";
        vm.etaPlaceHolder = "";
        var path = $location.path();
        vm.searchType = "all";
        var gridStateObj = {};
        var gridStateCopy = {};
        $timeout(function (){
            getPlaceHolder();  
            if($scope.searchParams.searchType){
                vm.searchType = $scope.searchParams.searchType;
            }
            else if(path.indexOf("dialog") >= 0 ){
                vm.searchType = "all";
            }
            else if(path.indexOf("sea") >= 0 ){
                vm.searchType = "sea";
            }
            else if(path.indexOf("air") >= 0 ){
                vm.searchType = "air";
            }
            else if(path.indexOf("fba") >= 0 ){
                vm.searchType = "fba";
            }
            else if(path.indexOf("wms") >= 0 ){
                vm.searchType = "wms";
            } 
            // if($scope.searchParams.forConsol)
            getGridId();
            if(!vm.gridId){
                return;
            }
            gridStateCopy = angular.copy($scope.searchParams);
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: vm.gridId
            }, function(result) {
                gridStateObj = result;
                if (!gridStateObj || !gridStateObj.id) {
                    return;
                }

                $scope.searchParams = angular.fromJson(gridStateObj.gridState);
                for(var param in $scope.searchParams){
                    if(param.substring(param.length - 2) == "FM" || param.substring(param.length - 2) == "TO"){
                        $scope.searchParams[param] = DateUtils.convertDateTimeFromServer($scope.searchParams[param]);
                    }
                    if(param == "choosedStatus"){
                        $scope.choosedStatus = $scope.searchParams[param];
                    }
                }
                Object.assign($scope.searchParams, gridStateCopy);
            })
        })
        
        function getPlaceHolder () {
            if($scope.searchParams.isExport == true || $scope.searchParams.expImp == "exp"){
                $scope.searchParams.expImp = "exp";
                vm.etdPlaceHolder = "ETD ";
                vm.etaPlaceHolder = "ETA ";
            }              
            else if ($scope.searchParams.isExport == false || $scope.searchParams.expImp == "imp"){
                $scope.searchParams.expImp = "imp";
                vm.etdPlaceHolder = "ETA ";
                vm.etaPlaceHolder = "ETD ";
            }
            else {
                vm.etdPlaceHolder = "ETD(ETA for Imp) ";
                vm.etaPlaceHolder = "ETA(ETD for Imp) ";
            }
            $translate.refresh();
        }
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }        
        
        $scope._onVenderSelected = function(fd, data) {
            $scope.searchParams[fd] = data.id;
        }

        $scope._onClerkSelected = function(clerk, data) {
            $scope.searchParams[clerk] = data.id;
        }

        $scope.choosedStatus = [];
        $scope._onStatusSelected = function(data) {
            if($scope.choosedStatus.indexOf(data) == -1){
                $scope.choosedStatus.push(data);
            }            
            $scope.searchParams.statusName = "";
        }
        $scope.removeStatus = function(index) {
            $scope.choosedStatus.splice(index, 1)
        }        

        $scope.contracts = [];
        $scope.getallcontract= function() {
            if ($scope.contracts && $scope.contracts.length > 0) {
                return;
            };
            Guide.getallcontract(function(result) {
                $scope.contracts = result;
            });
        };

        $scope.setStatus= function(fd, value) {
            if($scope.searchParams[fd]  == value){
                $scope.searchParams[fd] = null;
            }
            else {
                $scope.searchParams[fd] = value;
            }     
            getPlaceHolder();
        }        

        $scope.searchGuides = function() {
            var searchParams = angular.copy($scope.searchParams);
            if(searchParams.isExport == null && searchParams.shipmentType != 'fba' 
                && !searchParams.autoCheck && !searchParams.forComStats){
                if(searchParams.expImp == "exp" || !searchParams.expImp){
                    searchParams.isExport = true;
                }
                else {
                    searchParams.isExport = false;
                }
            }
            if($scope.choosedStatus.length > 0){
                searchParams.statusSet = [];
                angular.forEach($scope.choosedStatus, function(data){
                    searchParams.statusSet.push(data.value);
                })
            }

            searchParams.searchType = vm.searchType;
            searchParams.shipmentType = searchParams.shpmType;
            layer.load(1, {shade: 0.3});
            console.log(searchParams.BKimporterName)
            console.log(searchParams.BKsoNum)
            Guide.advanceSearchGuides(searchParams, function(result) {
                layer.closeAll();
                $uibModalInstance.close(result);
            }, $rootScope.ALERT_ERROR);  
        };
        $scope.excelImport = function(file, type) {
            if (!file) {return;}            

            var path = $location.path();
            var shipmentType = "all";
            if(path.indexOf("fba") >= 0){
                shipmentType = "fba";
            }
            else if(path.indexOf("sea") >= 0){
                shipmentType = "sea";
            }
            else if(path.indexOf("air") >= 0){
                shipmentType = "air";
            }
            else if(path.indexOf("local") >= 0){
                shipmentType = "wms";
            }

            var fd = new FormData();
            fd.append('file', file);
            fd.append('type', type);
            fd.append('forCombine', $scope.searchParams.forCombine?true:false);            
            fd.append('shipmentType', shipmentType);
            $http.post(
                'api/guides/updateStatusByExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {    
                    data = angular.fromJson(data);
                    if (data.result.length == 0){
                        layer.msg($translate.instant('global.messages.resultNull'));  
                        return;
                    }
                    $uibModalInstance.close(data.result);
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));                           
                });
        };

        vm.wmswarehouses = [];
        function getWarehouses () {
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false
                }, function (data) {
                vm.wmswarehouses = data;
            });
        }
        function warehouseSelected (data) {
            $scope.searchParams.whId = data.id;
        }
        $scope._onShipmentSelected = function(data) {
            if(!data){
                return;
            }
            if(!$scope.searchParams.shipmentTypes){
                $scope.searchParams.shipmentTypes = [];
            }
            if($scope.searchParams.shipmentTypes.indexOf(data) == -1){
                $scope.searchParams.shipmentTypes.push(data);
            }
            $scope.searchParams.shpmType = null;
        }
        $scope.removeShipmentType = function(index) {
            $scope.searchParams.shipmentTypes.splice(index, 1)
        }
    
        function save (){
            if(!vm.gridId){
                return;
            }
            var searchParams = angular.copy($scope.searchParams);
            if($scope.choosedStatus.length > 0){
                searchParams.choosedStatus = $scope.choosedStatus;
            }
            
            // 去除空的参数。第二个条件判空要3个等号不是2个！
            for(var param in searchParams){
                if(searchParams[param] == null || searchParams[param] === ""){
                    delete searchParams[param]
                }
            }
            gridStateObj.gridState = angular.toJson(searchParams);
            gridStateObj.userId = $rootScope.account.id;
            gridStateObj.gridId = vm.gridId;
            GridState.update(gridStateObj, function(result) {
                gridStateObj = result;
                layer.msg($translate.instant('global.save_success'));
            }, function() {
                layer.msg($translate.instant('global.save_fail'));
            });
        }

        function clearCondition (){
            $scope.searchParams = gridStateCopy;
            $scope.choosedStatus = [];
            // if(gridStateObj.id){
            //     GridState.delete({id: gridStateObj.id}, function() {}, function() {});
            // }
        }

        vm.gridId;
        function getGridId(){
            if(path.indexOf("all") >= 0 ){
                if($scope.searchParams.isExport){
                    vm.gridId = 125;
                }else{
                    vm.gridId = 126;
                }
            }
            else if(path.indexOf("sea") >= 0 ){
                if($scope.searchParams.isExport){
                    vm.gridId = 127;
                }else{
                    vm.gridId = 128;
                }
            }
            else if(path.indexOf("air") >= 0 ){
                if($scope.searchParams.isExport){
                    vm.gridId = 129;
                }else{
                    vm.gridId = 130;
                }
            }
            else if(path.indexOf("fba") >= 0 ){
                vm.gridId = 131;
            }
            else if(path.indexOf("wms") >= 0 ){
                vm.gridId = 132;
            }
            else if(path.indexOf("releaseManagement") >= 0 || path.indexOf("shipment-release") >= 0){
                vm.gridId = 154;
            }
            else if(path.indexOf("loading-distribution") >= 0 ){
                vm.gridId = 155;
            }           

        }

    }
})();
