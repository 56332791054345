(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('ExpressPackage', ExpressPackage);

    ExpressPackage.$inject = ['$resource'];

    function ExpressPackage ($resource) {
        var resourceUrl =  'api/express-packages/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getFilterPage': {
                method: 'POST',
                url: 'api/express-packages/getFilterPage',
                isArray: true},
            'advancedSearch': {
                method: 'POST',
                url: 'api/express-packages/advancedSearch',
                isArray: true },

            'exportExpressPackages': {
                method: 'POST',
                url: 'api/excel/exportExpressPackage',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            }
        });
    }
})();
