(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OaApplicationController', OaApplicationController);

    OaApplicationController.$inject = ['$rootScope', '$scope', '$state', '$timeout', '$uibModal', '$translate',
    'OaApplication', 'OaApplicationSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'WinAES',
    'uiGridConstants', 'DataUtils', 'GridState'];

    function OaApplicationController ($rootScope, $scope, $state, $timeout, $uibModal, $translate,
        OaApplication, OaApplicationSearch, ParseLinks, AlertService, paginationConstants, WinAES,
        uiGridConstants, DataUtils, GridState) {
        var vm = this;

        vm.page = 1;
        vm.predicate = 'id';
        vm.direction = 'desc';
        vm.itemsPerPage = 20;

        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = null;
        vm.currentSearch = null;

        if(!$rootScope.viewType){
            $rootScope.viewAll = false;
            $rootScope.viewType = 'app';
            $rootScope.exClosed = true;
        }

        var gridCopy;
        var pageLoaded = false;
        var filterTimer;

        function loadAll () {
            if (vm.searchQuery) {
                OaApplicationSearch.query({
                    query: vm.searchQuery,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    viewType: $rootScope.viewType,
                    viewAll: $rootScope.viewAll,
                    exClosed: $rootScope.exClosed,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                var filter = {
                    viewType: $rootScope.viewType,
                    viewAll: $rootScope.viewAll
                };
                if($rootScope.exClosed){
                    filter.closed = false;
                }
                if(gridCopy){
                    angular.forEach(gridCopy.columns, function(column){
                        angular.forEach(column.filters, function(cf){
                            if(cf.term != null && angular.isDefined(cf.term)){
                                if(cf.condition){
                                    var fd = column.field;
                                    if(fd.endWith("Str")){
                                        fd = fd.replace("Str", "");
                                    }
                                    if(cf.condition == 128 || cf.condition == 256){ //" < ",
                                        if(column.field == "checkDate"){
                                            filter[fd + "TO"] = DateUtils.convertLocalDateToServer(cf.term);
                                        }
                                        else {
                                            filter[fd + "TO"] = cf.term;
                                        }
                                    }
                                    else if(cf.condition == 32 || cf.condition == 64){ //" > ",
                                        if(column.field == "checkDate"){
                                            filter[fd + "FM"] = DateUtils.convertLocalDateToServer(cf.term);
                                        }
                                        else{
                                            filter[fd + "FM"] = cf.term;
                                        }
                                    }
                                }
                                else {
                                    filter[column.field] = cf.term;
                                }
                            }
                        })
                    })
                }
                OaApplication.getFilterPage({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, filter, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + vm.direction];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));

                if(headers('X-Total-Count')){
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                }
                else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                vm.isAdvanceSearch = false;
                initData();


                resize();
                $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS);
                $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN);

            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        loadAll();

        function initData() {
            $scope.gridOptions.useExternalSorting = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.useExternalFiltering = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.showColumnFooter = vm.isAdvanceSearch;

            angular.forEach($scope.gridOptions.data, function(data){
                data.encodeId = WinAES.encode(data.id.toString());
                angular.forEach($rootScope.OATYPES, function(opt) {
                    if (opt.value == data.type) {
                        data.typeLabel = opt.label;
                    }
                })
            })
        }
        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.direction = 'desc';
            vm.currentSearch = searchQuery;
            loadAll();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.direction =  'desc';
            vm.searchQuery = vm.currentSearch = null;
            loadAll();
        }


        $scope.setViewType = function (type) {
            $rootScope.viewType = type;
            // vm.exClosed = true;
            loadAll();
        }

        $scope.viewAllChange = function () {
            $rootScope.viewAll = !$rootScope.viewAll;
            loadAll();
        }
        $scope.exClosedChange = function () {
            $rootScope.exClosed = !$rootScope.exClosed;
            loadAll();
        }


        var rateUnsubscribe = $rootScope.$on('autopsApp:oaApplicationUpdate', function(event, result) {
            if(result && result.id){
                angular.forEach($scope.gridOptions.data, function(oldOne){
                    if(oldOne.id == result.id){
                        angular.copy(result, oldOne);
                        oldOne.encodeId = WinAES.encode(oldOne.id.toString());
                        angular.forEach($rootScope.OATYPES, function(opt) {
                            if (opt.value == oldOne.type) {
                                oldOne.typeLabel = opt.label;
                            }
                        })
                    }
                })
            }
        });
        $scope.$on('$destroy', rateUnsubscribe);
        window.onresize = function(){
            resize();
        }

        function resize() {
            if(!$("#gridId") || !$("#gridId").offset()){
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 180;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 600){
                tabHeight = 600;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: true,

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: false,//每列footer

            enableFiltering: true,//高级过滤
            useExternalFiltering: true,

            enableHorizontalScrollbar : 1,//表格的水平滚动条
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            exporterMenuCsv : false,
            exporterMenuPdf : false,
            exporterMenuExcel : false,

            paginationPageSizes: [20, 35, 50],
            paginationPageSize: 20,
            useExternalPagination: true,

            minimumColumnSize: 70,
            exporterExcelFilename: $translate.instant('autopsApp.oaApplication.home.title'),
            columnDefs: [
                { field: 'id',
                    width: 130,
                    displayName: "",
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<div class="btn-group flex-btn-group-container ml-5"><button type="button" ui-sref="oa-application.edit({id:row.entity.encodeId})" class="btn btn-primary btn-grid"><span class="glyphicon glyphicon-modal-window"></span><span class="hidden-xs hidden-sm" translate="entity.action.edit"></span></button><button type="button" ui-sref="oa-application.delete({id:row.entity.encodeId})" class="btn btn-danger btn-grid" ><span class="glyphicon glyphicon-remove-circle"></span><span class="hidden-xs hidden-sm" translate="entity.action.delete"></span></button></div>'},
                { field: 'type',
                    displayName: $translate.instant('autopsApp.oaApplication.type'),
                    minWidth:100,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.OATYPES
                    },
                    cellTemplate: '<span class="ml-5"><span class="label label-success" ng-if="row.entity.type">{{row.entity.typeLabel}}</span></span>'},
                { field: 'cloudFiles',
                    displayName: $translate.instant('global.cloudFiles'),
                    width:80,
                    enableSorting: false,
                    cellTemplate: '<div id="btnsId" class="btn-group flex-btn-group-container" ng-if="row.entity.id"><div class="btn-group" uib-dropdown dropdown-append-to-body><button id="btn-append-to-body" type="button" class="btn btn-link btn-xs" uib-dropdown-toggle ng-click="row.entity.files=[];grid.appScope.LOADFILES(row.entity.files, \'/oaApplication/\' + row.entity.id + \'/\', row.entity.companyId)"><span class="glyphicon glyphicon-cloud"></span><span translate="global.cloudFiles">cloudFiles</span><span class="caret"></span></button><ul class="dropdown-menu" uib-dropdown-menu role="menu" aria-labelledby="btn-append-to-body"><li><a href="" ngf-multiple="true" ngf-select ngf-change="grid.appScope.UPLOADFILES($files, \'/oaApplication/\' + row.entity.id + \'/\', row.entity.files, row.entity.companyId)"><span class="glyphicon glyphicon-cloud-upload"></span><span class="ml-4" translate="global.uploadFiles">uploadFiles</span></a></li><li role="separator" class="divider" ng-if="row.entity.files"></li><li ng-repeat="file in row.entity.files"><a href="" ng-click="grid.appScope.PDFPOP(file.fileurl,file.filename)"><span class="glyphicon glyphicon-cloud-download"></span><span class="ml-4">{{file.filename}}</span></a></li></ul></div></div>'},
                { field: 'subject',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.oaApplication.subject')},
                { field: 'refNum',
                    minWidth:150,
                    cellTemplate: '<div class="ui-grid-cell-contents"><span>{{row.entity.refNum}}</span><a href="" class="ml-5 glyphicon glyphicon-eye-open" ng-if="row.entity.refNum && row.entity.refNum.length == 12 && row.entity.refNum.indexOf(\'MC\') == 0" ng-click="grid.appScope.goAutoCheck(row.entity)"></a></div>',
                    displayName: $translate.instant('autopsApp.oaApplication.refNum')},
                { field: 'content',
                    minWidth:250,
                    displayName: $translate.instant('autopsApp.oaApplication.content'),
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.content}}">{{row.entity.content}}</span>'},
                { field: 'amount',
                    type:'number',
                    minWidth:100,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.oaApplication.amount')},
                { field: 'currency',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.oaApplication.currency')},
                { field: 'vender',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.oaApplication.vender')},
                { field: 'instruction',
                    minWidth:250,
                    displayName: $translate.instant('autopsApp.oaApplication.instruction'),
                    cellTemplate: '<span class="grid-text" data-toggle="tooltip" data-placement="top" title="{{row.entity.instruction}}">{{row.entity.instruction}}</span>'},
                { field: 'approved',
                    displayName: $translate.instant('autopsApp.oaApplication.approved'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span class="ml-5"><span translate="global.no_answer"class="label label-primary"ng-hide="row.entity.approved||!row.entity.id">no_answer</span><span translate="global.yes_answer"class="label label-danger"ng-if="row.entity.approved&&row.entity.id">yes_answer</span></span>'},
                { field: 'closed',
                    displayName: $translate.instant('autopsApp.oaApplication.closed'),
                    minWidth:60,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span class="ml-5"><span translate="global.no_answer"class="label label-primary"ng-hide="row.entity.closed||!row.entity.id">no_answer</span><span translate="global.yes_answer"class="label label-danger"ng-if="row.entity.closed&&row.entity.id">yes_answer</span></span>'},
                { field: 'creater',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.oaApplication.creater')},
                { field: 'superior',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.oaApplication.superior')},
                { field: 'executor',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.oaApplication.executor')},
                { field: 'createdTime',
                    minWidth:200,
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.oaApplication.createdTime'),
                    cellTemplate: '<span>{{row.entity.createdTime | date:\'medium\'}}</span>'},
                { field: 'approvalTime',
                    minWidth:200,
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.oaApplication.approvalTime'),
                    cellTemplate: '<span>{{row.entity.approvalTime | date:\'medium\'}}</span>'},
                { field: 'closedTime',
                    minWidth:200,
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.oaApplication.closedTime'),
                    cellTemplate: '<span>{{row.entity.closedTime | date:\'medium\'}}</span>'}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 119;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "oaApplication";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "oaApplication";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                    if(!$scope.gridOptions.useExternalSorting){
                        if(sortColumns[sortColumns.length - 1]){
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function(a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    vm.predicate = sortColumns[sortColumns.length - 1].field;
                    vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    loadAll();
                });
                gridApi.core.on.filterChanged($scope, function() {
                    gridCopy = this.grid;
                    if(pageLoaded && $scope.gridOptions.useExternalFiltering){
                        clearTimeout(filterTimer);
                        filterTimer = setTimeout(function(){
                            loadAll();
                        }, 1000);//查询延时时间，单位ms
                    }
                });
                gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                    vm.page = newPage;
                    vm.itemsPerPage = pageSize;
                    if(pageLoaded){
                        loadAll();
                    }
                });
            }
        }
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 119
            }, function(result){
                gridStateObj = result;
                if(!gridStateObj || !gridStateObj.id){
                    pageLoaded = true;
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                pageLoaded = true;
                if(gridCopy){
                    var filterRequired = false;
                    angular.forEach(gridCopy.columns, function(column){
                        angular.forEach(column.filters, function(cf){
                            if(cf.term || cf.term == false){
                                filterRequired = true;
                            }
                        })
                    })
                    if(filterRequired){
                        layer.msg($translate.instant('global.filterDetectedAlert'));
                        loadAll();
                    }
                }
            })
        });
        $scope.goAutoCheck = function  (oaApplication) {
            OaApplication.getAutoCheck(oaApplication, function(result){
                $timeout(function (){
                    $state.go('auto-check-detail',{id: WinAES.encode(result.id.toString())});
                })
            }, $rootScope.ALERT_ERROR)
        }

    }
})();
