(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CrmPriceController', CrmPriceController);

    CrmPriceController.$inject = ['$scope', '$state', '$rootScope', '$translate', '$uibModal', 'WinAES', 'CrmEnquiry',
    'Principal', 'CrmQuote', 'CrmQuotationSearch', 'ParseLinks', 'pagingParams', 'paginationConstants'];

    function CrmPriceController ($scope, $state, $rootScope, $translate, $uibModal, WinAES, CrmEnquiry,
        Principal, CrmQuote, CrmQuotationSearch, ParseLinks, pagingParams, paginationConstants) {

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.editCrmEnquiry = editCrmEnquiry;
        vm.markAsQuoted = markAsQuoted;
        vm.printQuotation = printQuotation;
        vm.generateGuide = generateGuide;
        vm.transferEnquiry = transferEnquiry;

        loadAll();
        $scope.viewAllChange = function() {
            if ($rootScope.HAVENOAUTH('AU_VIEW_ALL_QUOTATION')) {
                vm.viewAll = !vm.viewAll;
                $rootScope.FORBIDDEN_BY_ALERT('AU_VIEW_ALL_QUOTATION');
                return;
            }
            loadAll();
        }
        function loadAll () {
            if (pagingParams.search) {
                CrmQuote.searchQuote({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                var filter = {enquiryIdTAG: true};
                filter.searchType = vm.viewAll?"all":"self";
                layer.load(1, { shade: 0.3 });
                CrmQuote.getFilterPage({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, filter, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                layer.closeAll();
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.crmQuotes = data;
                vm.page = pagingParams.page;
                angular.forEach(vm.crmQuotes, function(data){
                    data.encodeId = WinAES.encode(data.id.toString());
                    if (data.crmEnquiry && data.crmEnquiry.volumeJson) {
                        data.crmEnquiry.volumeJsonObj = angular.fromJson(data.crmEnquiry.volumeJson);
                    }
                    getChargesTtl(data);
                })
                setTimeout(function() {frzTable("divTable", "basicTable", "footPager");}, 1000);
            }
            function onError(error) {
                layer.closeAll();
                layer.msg($translate.instant('global.loadFailed'));
            }
        }
        function getChargesTtl(crmQuote) {
            crmQuote.ttlList = [];
            var currencies = new Set();
            var freightTypes = new Set();
            angular.forEach(crmQuote.crmQuotePrices, function(data) {
                freightTypes.add(data.type);
            })
            angular.forEach(crmQuote.crmQuotePrices, function(data) {
                currencies.add(data.currency);
            })
            currencies.forEach(function(cur) {
                var curTtl = 0;
                freightTypes.forEach(function(freightType) {
                    var ttl = 0;
                    angular.forEach(crmQuote.crmQuotePrices, function(data) {
                        if (data.total && cur == data.currency && freightType == data.type) {
                            ttl += data.total;
                            curTtl += data.total;
                        }
                    })
                })
                if (curTtl) {
                    crmQuote.ttlList.push({
                        currency: cur,
                        type: "TTL",
                        total: curTtl
                    });
                }
            })
            $translate.refresh();
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }


        var unsubscribe = $rootScope.$on('autopsApp:crmQuoteUpdate', function(event, result) {
            var isNew = true;
            angular.forEach(vm.crmQuotes, function(crmQuote){
                if(crmQuote.id == result.id){
                    angular.copy(result, crmQuote);
                    crmQuote.encodeId = WinAES.encode(crmQuote.id.toString());
                    isNew = false;
                }
            })
            if(isNew) {
                loadAll();
            }
        });
        $scope.$on('$destroy', unsubscribe);

        function editCrmEnquiry(crmQuote) {
            if(!crmQuote.crmEnquiry.id){
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/crm-enquiry/crm-enquiry-dialog.html',
                controller: 'CrmEnquiryDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return CrmEnquiry.get({id : crmQuote.crmEnquiry.id}).$promise;
                    }]
                }
            }).result.then(function(result) {
                crmQuote.crmEnquiry = result;
                if (crmQuote.crmEnquiry.volumeJson) {
                    crmQuote.crmEnquiry.volumeJsonObj = angular.fromJson(crmQuote.crmEnquiry.volumeJson);
                }
                getChargesTtl(crmQuote);
            }, function(result) {
            });
        }

        function markAsQuoted(crmQuote) {
            if(crmQuote.status == 'booked'){
                layer.msg($translate.instant('autopsApp.crmEnquiry.bookedAlert'));
                return;
            }
            var crmQuoteCopy = angular.copy(crmQuote)
            if(crmQuoteCopy.status == 'new'){
                crmQuoteCopy.status = 'replied';
                crmQuoteCopy.quotationTime = new Date();
            }
            else if(crmQuoteCopy.status == 'replied'){
                crmQuoteCopy.status = 'new';
                crmQuoteCopy.quotationTime = null;
            }
            CrmQuote.save(crmQuoteCopy, function(result){
                crmQuote.status = crmQuoteCopy.status;
                crmQuote.quotationTime = crmQuoteCopy.quotationTime;
                setTimeout(function() {frzTable("divTable", "basicTable", "footPager");}, 100);
                layer.msg($translate.instant('global.update_success'));
            }, $rootScope.ALERT_ERROR);
        }
        function printQuotation(crmQuote){
            vm.isSaving= true;
            var crmQuoteDTO = angular.copy(crmQuote);
            crmQuoteDTO.reportCode = 1190;
            CrmQuote.printQuotation({},crmQuoteDTO,function(result){
                vm.isSaving= false;
                $rootScope.PRINTBYTES(result.data);
            },function(error){
                vm.isSaving= false;
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        function generateGuide(crmQuote){
            if(crmQuote.jobNum){
                layer.msg($translate.instant('autopsApp.crmEnquiry.doubleCreateGuideAlert'));
                return;
            }
            layer.confirm($translate.instant('autopsApp.crmEnquiry.generateGuideAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                CrmQuote.generateGuide({}, crmQuote, function(result){
                    vm.isSaving= false;
                    if(result.jobNum){
                        crmQuote.jobNum = result.jobNum;
                    }
                    $rootScope.OPS_ALERT($translate.instant('global.createdSuccessWithRef', { "ref": result.jobNum }));
                }, $rootScope.ALERT_ERROR);
            }, function() {});
        }

        function transferEnquiry(crmQuote) {
            $uibModal.open({
                templateUrl: 'app/entities/crm-price/crm-price-delete-dialog.html',
                controller: 'CrmPriceDeleteController',
                controllerAs: 'vm',
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return crmQuote.crmEnquiry;
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }


    }
})();
