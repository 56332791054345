(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('CustomDeclarationCtnsSearch', CustomDeclarationCtnsSearch);

    CustomDeclarationCtnsSearch.$inject = ['$resource'];

    function CustomDeclarationCtnsSearch($resource) {
        var resourceUrl =  'api/_search/custom-declaration-ctns/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
