(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountInvoiceBatchDialogController', AccountInvoiceBatchDialogController);

        AccountInvoiceBatchDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$translate',
    'entity', 'AccountInvoice', 'DateUtils'];

    function AccountInvoiceBatchDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $translate,
        entity, AccountInvoice, DateUtils) {
        var vm = this;

        vm.accountInvoice = entity;
        initData();
        vm.clear = clear;
        vm.save = save;
        vm.addOne = addOne;
        vm.deleteOne = deleteOne;
        vm.invoiceAmountChange = invoiceAmountChange;
        vm.issusedAmt = 0;
        vm.balAmt = 0;
        function invoiceAmountChange () {
            vm.issusedAmt = 0;
            vm.accountInvoice.invoiceBatch.forEach (function(item) {
                if (item.invoiceAmount) {
                    vm.issusedAmt += item.invoiceAmount;
                }
            })
            vm.balAmt = vm.accountInvoice.invoiceAmount - vm.issusedAmt;
            $translate.refresh();
        }
        $timeout(function (){
            invoiceAmountChange();
        })
        function initData () {
            if (!vm.accountInvoice.invoiceBatch) {
                vm.accountInvoice.invoiceBatch = [];
            } else {
                vm.accountInvoice.invoiceBatch = angular.fromJson(vm.accountInvoice.invoiceBatch);
                vm.accountInvoice.invoiceBatch.forEach (function(item) {
                    if (item.invoiceTime) {
                        item.invoiceTime = DateUtils.convertDateTimeFromServer(item.invoiceTime);
                    }
                })
            }
            
        }
        

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function addOne () {
            if(!vm.accountInvoice.invoiceBatch){
                vm.accountInvoice.invoiceBatch = [];
            }
            vm.accountInvoice.invoiceBatch.push({
                invoiceTime: null,
                invoiceAmount: null,
                invoiceNum: ""
            });
        }

        function deleteOne (index) {
            vm.accountInvoice.invoiceBatch.splice(index, 1);
        }

        function save () {
            vm.isSaving = true;
            var accountInvoice = angular.copy(vm.accountInvoice);
            if(accountInvoice.invoiceBatch){
                var invoiceAmoutTotal = 0;
                angular.forEach(accountInvoice.invoiceBatch, function(item) {
                    if (item.invoiceAmount) {
                        invoiceAmoutTotal = item.invoiceAmount + invoiceAmoutTotal;
                    }
                })
                if (invoiceAmoutTotal > vm.accountInvoice.invoiceAmount) {
                    layer.msg($translate.instant('autopsApp.accountInvoice.batchAmoutExcessive'));
                    return;
                }
                accountInvoice.invoiceBatch = angular.toJson(accountInvoice.invoiceBatch);
            }
            AccountInvoice.save(accountInvoice, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess () {
            layer.msg($translate.instant('global.save_success'));
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
    }
})();
