
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BookingCustomDetailController', BookingCustomDetailController);

    BookingCustomDetailController.$inject = ['$scope', '$rootScope', '$uibModal', '$uibModalInstance', '$translate', '$timeout', '$http', 
    'entity', 'GuideFk', 'OpsReport', 'OpsVender', 'DataUtils', 'BookingCustom'];
    
    function BookingCustomDetailController($scope, $rootScope, $uibModal, $uibModalInstance, $translate, $timeout, $http, 
        entity, GuideFk, OpsReport, OpsVender, DataUtils, BookingCustom) {
        $scope.guide = entity;
        $scope.guideFk = {id: entity.id};
        $scope.guideFkCopy;
        $scope.files = [];   

        $timeout(function() {
            GuideFk.get({
                id: $scope.guide.id,
                type: "customs"
            }, function(result) {
                $scope.guideFk = result;
                if(!result.bookingCustoms || result.bookingCustoms.length == 0){
                    $scope.addOne();
                }
                else {                    
                    initFilesFolder();
                }
                getPackingList();
            });
        })
        $scope.packingList = [];
        var getPackingList = function() {
            BookingCustom.getPackingList({
                id: $scope.guide.id 
            }, function(result) {
                $scope.packingList = result;
                angular.forEach($scope.packingList, function(pl){
                    if(pl.bookingCustomId){
                        var idOk = false;
                        angular.forEach($scope.guideFk.bookingCustoms, function(bkc){
                            if(bkc.id == pl.bookingCustomId){
                                idOk = true;
                            }
                        })
                        if(!idOk){
                            pl.bookingCustomId = null;
                        }
                    }
                })
            });
        }

        $scope.addOne = function() {
            var newOne = {
                exportType: null,
                needFileService: false,
                fileServiceIsSend: false,
                status: "Uncommitted",
                files:[]
            };
            if(!$scope.guideFk.bookingCustoms){
                $scope.guideFk.bookingCustoms = [];
            }
            $scope.guideFk.bookingCustoms.push(newOne);
        }
        function initFilesFolder(){
            angular.forEach($scope.guideFk.bookingCustoms, function(data){
                data.files = [];
            })
            $scope.guideFkCopy = angular.copy($scope.guideFk);
        }

        $scope.delOne = function(custom, idx) {
            if ($scope.guide.isClosed) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.lockedAlert'));
                return;
            }
            if (custom.id != null) {
                $scope.isSaving = true;
                BookingCustom.delete({
                    id: custom.id
                }, function (argument) {
                    $scope.isSaving = false;
                    layer.msg($translate.instant('global.delete_success'));
                    $scope.guideFk.bookingCustoms.splice(idx, 1);
                    if($scope.guideFk.bookingCustoms.length == 0){
                        $scope.clear();
                    }
                });
            };
            $scope.guideFk.bookingCustoms.splice(idx, 1);
            if($scope.guideFk.bookingCustoms.length == 0){
                $scope.clear();
            }
        };       


        var onSaveSuccess = function (result) {            
            if($.isArray(result)){
            }    
            else {
                $scope.guideFk = result;
                initFilesFolder();
                $scope.$emit('autopsApp:loadGuide', result);
            }        
            layer.msg($translate.instant('global.messages.submitted'));
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            layer.msg($translate.instant('global.messages.submittedfailed'));
            $scope.isSaving = false;
        };

        $scope.save = function () {
            if ($scope.guide.isClosed) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.lockedAlert'));
                return;
            }
            
            $scope.isSaving = true;
            GuideFk.update($scope.guideFk, onSaveSuccess, onSaveError);
            
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        function amassSynCustoms(custom) {
            GuideFk.amassSynCustoms({
                bookingCustomId: custom.id,
                toGuideId: $scope.guideFk.branchGuideId
            }, function (argument) {
                layer.msg("推送成功(定制功能：报关委托推送到受理方)");
            })
        }

        $scope.guideCustomSend = function(type, custom, reportType) {
            if(!custom.id || !angular.equals($scope.guideFkCopy,$scope.guideFk)){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if($rootScope.isYuyue($rootScope.account.companyId) && $scope.guideFk.branchGuideId && $scope.guideFk.branchGuideId > $scope.guideFk.id){
                amassSynCustoms(custom)
            }
            if (!custom || !custom.brokerName) {
                layer.msg($translate.instant('global.messages.customInvalid'));
                return;
            }
            var email = {};
            email.attachments = [];
            email.head = custom.brokerName;
            if (custom.tradeAgentName) {
                email.subject = $translate.instant('entity.email.customSubject') + "--" + custom.tradeAgentName + "--" + $rootScope.account.companyName;
            } else {
                email.subject = $translate.instant('entity.email.customSubject') + "--" + $scope.guide.shipperName + "--" + $rootScope.account.companyName;
            }
            email.content = $translate.instant('entity.email.customContent') + "\n";
            if ($rootScope.account.signatures && $rootScope.account.signatures.length > 0) {
                email.content = email.content + $rootScope.account.signatures[0].signature;
            }
            var entity = {};
            entity.email = email;
            entity.guide = angular.copy($scope.guide);
            if(!entity.guide.booking){
                entity.guide.booking = {};
            }
            entity.guide.booking.bookingCustom = custom;
            entity.emailType = type;
            entity.venderId = custom.brokerVid;
            entity.contactId = custom.brokerContactId;
            entity.code = reportType;
            entity.files = $scope.files;
            entity.bookingTruck = null;
            sendEmailPop(entity);
        }

        var sendEmailPop = function(entity) {
            $uibModal.open({
                templateUrl: 'app/entities/email/email-dialog.html',
                controller: 'EmailDialogController',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                $scope.guide = result;
            }, function(result) {});
        }
        $scope.exportCustom = function(format, custom, reportType) {
            if (!custom || !custom.brokerName) {
                layer.msg($translate.instant('global.messages.customInvalid'));
                return;
            }
            exportExcelPdf(format, reportType, $scope.guide.jobNum + "-Custom", custom);
        }

        function exportExcelPdf(format, code, fileName, custom) {
            var guideReportDTO = angular.copy($scope.guide);
            if(!guideReportDTO.booking){
                guideReportDTO.booking = {};
            }
            guideReportDTO.booking.bookingCustom = custom;
            guideReportDTO.reportType = code;
            guideReportDTO.opType = 1;
            guideReportDTO.fileType = format;
            guideReportDTO.fileName = fileName;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                $rootScope.EXPORTDOWNLOAD(result, format, fileName);
            });
        }
        $scope.previewReport = function(code, bookingCustom) {
            var guideReportDTO = angular.copy($scope.guide);
            if(!guideReportDTO.booking){
                guideReportDTO.booking = {};
            }
            guideReportDTO.booking.bookingCustom = bookingCustom;
            guideReportDTO.reportType = code;
            guideReportDTO.opType = 1;
            guideReportDTO.fileType = 1;
            OpsReport.generateGuide({}, guideReportDTO, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {

            });
        }

        $scope._onInspectionSelected = function(data, bookingCustom) {
            OpsVender.getDTO({
                id: data.id
            }, function(result) {
                if (!result.audited && result.truckerMustAudit) {
                    guide.booking.bookingCustom.inspectionCompany = null;
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.notAuditedAlert'));
                    return;
                }
                bookingCustom.inspectionVid = result.venderId;                
                bookingCustom.inspectionPic = result.contactList[0].attn;
                bookingCustom.inspectionTel = result.contactList[0].tel;
            });
        }
        $scope.declPorts = [];
        $scope.getDeclPorts = function(mGuideId) {
            if ($scope.declPorts.length > 0) {
                return;
            }
            $http.get('i18n/customsData.json').success(function(result) {
                $scope.declPorts = result.customCodes;
            });
        }
        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }
        $scope.setCustomTime = function(timeCode, bookingCustom) {
            if ($scope.guide.isClosed) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.lockedAlert'));
                return;
            }
            if(!bookingCustom[timeCode]){
                bookingCustom[timeCode] = new Date();
                return;
            }            
            $uibModal.open({
                templateUrl: 'app/entities/grid-state/grid-state-dialog.html',
                controller: 'GridStateDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return bookingCustom[timeCode];
                    }]
                }
            }).result.then(function(result) {
                bookingCustom[timeCode] = result;
            }, function() {
            });
        }

        $scope.savePackingList = function () {
            if ($scope.guide.isClosed) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.lockedAlert'));
                return;
            }
            
            $scope.isSaving = true;
            BookingCustom.savePackingList($scope.packingList, onSaveSuccess, onSaveError);            
        };

        $scope.removePl = function (pl) {
            pl.bookingCustomId = null;            
        };   
        $scope.choosePl = function (pl, customs) {
            pl.bookingCustomId = customs.id;            
        };   
        $scope.chooseAll = function (customs) {
            angular.forEach($scope.packingList, function(data){
                if(!data.bookingCustomId){
                    data.bookingCustomId = customs.id;
                }                        
            })      
        };   

        $scope.testFileCrawler = function (bookingCustom) {
            BookingCustom.testFileCrawler(bookingCustom, function(result){
                angular.copy(result, bookingCustom);
                layer.msg($translate.instant('global.messages.submitted'));
            }, $rootScope.ALERT_ERROR);
        };
        
        $scope.sendFileService = function (bookingCustomId){
            if(!angular.equals($scope.guideFkCopy,$scope.guideFk)){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            BookingCustom.sendFileService({bookingCustomId:bookingCustomId},function(result){
                $scope.guideFk = result;
                initFilesFolder();
                layer.msg($translate.instant('global.send_success'));
            },$rootScope.ALERT_ERROR);
        };

        $scope.customsImport = function (){
            if(!angular.equals($scope.guideFkCopy,$scope.guideFk)){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            BookingCustom.customsImport({masterGuideId:$scope.guide.id},function(result){
                $scope.guideFk = result;
                initFilesFolder();
                layer.msg($translate.instant('global.send_success'));
            },$rootScope.ALERT_ERROR);
        };

        $scope.cancelFileService = function (bookingCustomId){
            BookingCustom.undoCustoms({bookingCustomId:bookingCustomId,serviceType:1},function(result){
                $scope.guideFk = result;
                initFilesFolder();
                layer.msg($translate.instant('global.cancel_success'));
            },$rootScope.ALERT_ERROR);
        };

        $scope.cancelCustomsImport = function (bookingCustomId){
            BookingCustom.undoCustoms({bookingCustomId:bookingCustomId,serviceType:""},function(result){
                $scope.guideFk = result;
                initFilesFolder();
                layer.msg($translate.instant('global.cancel_success'));
            },$rootScope.ALERT_ERROR);
        };
    }       
})();
