(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OaApplicationDialogController', OaApplicationDialogController);

    OaApplicationDialogController.$inject = ['$timeout', '$rootScope', '$state', '$scope', '$stateParams', '$uibModalInstance', '$uibModal', '$translate', '$http',
    'entity', 'OaApplication', 'AccountInvoice', 'AccountPayment', 'WinAES'];

    function OaApplicationDialogController ($timeout, $rootScope, $state, $scope, $stateParams, $uibModalInstance, $uibModal, $translate, $http,
        entity, OaApplication, AccountInvoice, AccountPayment, WinAES) {
        var vm = this;

        vm.oaApplication = entity;
        vm.clear = clear;
        vm.save = save;
        vm.update = update;
        vm.typeChange = typeChange;
        vm.editDisabled = editDisabled;

        $scope.files = [];

        $timeout(function (){
            if(!vm.oaApplication.id){
                if(!vm.oaApplication.type){
                    vm.oaApplication.type = "blRelease";
                }
                if(vm.oaApplication.type == "blRelease"){
                    vm.oaApplication.content = blRlsContent;
                }
            }
            // angular.element('.form-group:eq(1)>input').focus();
        });
        var blRlsContent = "JOB No.：\nETD：\nETA:\n客户或代理名称：\n协议付款方式：\n本单收款情况：\n"
                + "已欠款金额：\n已放单情况：\n注：保证客户会在  年  月  日前将款项付清，由此产生的责任由本人承担，谢谢! 后压单（如果是到付请注明）\n"
                + "压单情况：\n工作单号：  柜型*数量  起运港   目的港  品名 货值  开船时间  是否到付  ";
        var invoiceContent = "工作号：\n开票抬头：\n税号：\n公司地址：\n联系电话：\n接收邮箱：\n"
                + "开户行：\n银行账号：\n开票金额：\n";
        function typeChange () {
            if(vm.oaApplication.type == "blRelease"){
                vm.oaApplication.content = blRlsContent;
            }
            else if(vm.oaApplication.type == "invoice"){
                vm.oaApplication.content = invoiceContent;
            }
            else {
                vm.oaApplication.content = "";
            }
            vm.oaApplication.superior = null;
            vm.oaApplication.superiorId = null;
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            OaApplication.save(vm.oaApplication, onSaveSuccess, onSaveError);
        }


        function onSaveSuccess (result) {
            $scope.$emit('autopsApp:oaApplicationUpdate', result);
            vm.oaApplication = result;
            // $uibModalInstance.close(result);
            layer.msg($translate.instant('global.messages.submitted'));
            vm.isSaving = false;
        }

        function onSaveError (error) {
            vm.isSaving = false;
            if(error.status == 409){
                layer.msg($translate.instant('global.refreshAlert'));
            }
            else{
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        }

        $scope._onDataSelected = function(data, fd) {
            vm.oaApplication[fd] = data.id;
        }
        vm.insType = null;
        $scope.setInsType = function (type) {
            vm.insType = type;
            if(type == 'executed' || type == 'notApproved'){
                update();
            }
            else if(type == 'approved' || type == 'nextExecutor'){
                vm.insPlacehd = $translate.instant('autopsApp.oaApplication.executor');
                layer.msg($translate.instant('autopsApp.oaApplication.chooseOneTips'));
            }
            else {
                vm.insPlacehd = $translate.instant('autopsApp.oaApplication.superior');
                layer.msg($translate.instant('autopsApp.oaApplication.chooseOneTips'));
            }
        }
        function update () {
            vm.isSaving = true;
            var oaApplication = angular.copy(vm.oaApplication);
            oaApplication.instruction = oaApplication.tempInstruction;
            if(vm.insType == 'approved' || vm.insType == 'nextExecutor'){
                oaApplication.executor = oaApplication.tempClerk;
                oaApplication.executorId = oaApplication.tempClerkId;
            }
            else if(vm.insType == 'nextSuperior' || vm.insType == 'rejected'){
                oaApplication.superior = oaApplication.tempClerk;
                oaApplication.superiorId = oaApplication.tempClerkId;
            }
            OaApplication.update({type: vm.insType}, oaApplication, onSaveSuccess, onSaveError);
        }

        $scope.superiorFilter = function(item) {
            if(vm.oaApplication.type == "constraint" && item.authorities.indexOf("ROLE_ACCOUNT") == -1){
                return false;
            }
            return true;
        };

        $scope.refPop = function(refNum) {
            if(!refNum){
                return;
            }
            //W24051121422
            if(vm.oaApplication.id && vm.oaApplication.refNum 
                && (vm.oaApplication.type == "constraint" || vm.oaApplication.type == "blRelease")){
                clear();
                $timeout(function (){
                    $state.go('all-export', {oaRefNum: vm.oaApplication.refNum}, { reload: true });
                })
                return;
            }

            if(vm.oaApplication.refNum
                && vm.oaApplication.refNum.length == 12 && vm.oaApplication.refNum.indexOf("MC") == 0){
                goAutoCheck();
                return;
            }
            if(!vm.oaApplication.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if(refNum.indexOf("DN") == 0 || refNum.indexOf("CN") == 0){
                var rateEntity = {dnNum: refNum};
                $rootScope.PREVIEW_DCN(rateEntity);
            }
            else {
                var entity = {};
                entity.guide = {
                    companyId: vm.oaApplication.companyId,
                    jobNum: refNum
                };
                entity.isFin = true;
                $rootScope.VENDER_AUDIT_POP(entity);
            }
        }

        $rootScope.venderPop = function(id) {
            if (!id) {
                return;
            }
            var contact = {};
            contact.venderId = id;
            $uibModal.open({
                templateUrl: 'app/entities/ops-change-log/ops-change-log-detail.html',
                controller: 'OpsChangeLogDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        return contact;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsClient');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
            }, function() {
            });
        }

        function editDisabled () {
            return (vm.oaApplication.id && vm.oaApplication.createrId != $rootScope.account.id) || vm.oaApplication.approved || vm.oaApplication.closed || vm.oaApplication.approver;
        }

        function goAutoCheck () {
            OaApplication.getAutoCheck(vm.oaApplication, function(result){
                clear();
                $timeout(function (){
                    $state.go('auto-check-detail',{id: WinAES.encode(result.id.toString())});
                })
            }, $rootScope.ALERT_ERROR)
        }





    }
})();
