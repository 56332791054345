(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WriteOffRecordsDetailController', WriteOffRecordsDetailController);

    WriteOffRecordsDetailController.$inject = ['$scope', '$rootScope', '$timeout', '$uibModalInstance', '$translate', 'entity', 'GuideRates', 'DataUtils'];

    function WriteOffRecordsDetailController($scope, $rootScope, $timeout, $uibModalInstance, $translate, entity, GuideRates, DataUtils) {
        var vm = this;
        $scope.shipmentType = entity.type;


        $scope.clear = function() {
            $uibModalInstance.dismiss();
        }
        $scope.guide = null;

        $timeout(function (){
            layer.load(1, {shade: 0.3});
            GuideRates.getShipperRates({
                enCn: $translate.instant("global.enCn"),
                id: entity.id,
                type: entity.type
            }, function(result){
                layer.closeAll(); 
                $scope.guide = result;
                updateTotal();
            }, function (argument) {
                layer.closeAll(); 
                layer.msg($translate.instant('global.noRecord'));
                $scope.clear();
            })
        });

        $scope.sellingTotalList = [];
        var updateTotal = function() {
            $scope.sellingTotalList = [];
            var currencys = [];
            angular.forEach($scope.guide.guideRates, function(data) {
                if (data.payCurrency && currencys.indexOf(data.payCurrency) === -1) {
                    currencys.push(data.payCurrency);
                }
            })
            var selling = 0;
            angular.forEach(currencys, function(currency) {
                selling = 0;
                angular.forEach($scope.guide.guideRates, function(rate) {
                    if (rate.payCurrency == currency && rate.payAmount) {
                        if (!rate.isPay) {
                            selling = selling + rate.payAmount;
                        }
                    }
                })
                $scope.sellingTotalList.push({
                    currency: currency,
                    total: DataUtils.round(selling, 2)
                });
            })
        }
        // $scope.defualtReportCode = 200;
        // $scope.getDefualtReportCode = function() {
        //     if(shipmentType == 'expressParcel'){
        //         $scope.defualtReportCode = 730;
        //     }
        //     else if(shipmentType == 'wms'){
        //         $scope.defualtReportCode = 850;
        //     }
        //     else if ($scope.guide.shipmentType == 'AIR' || $scope.guide.shipmentType == 'FBAAIR'){
        //         $scope.defualtReportCode = 410;
        //     }
        //     else {
        //         $scope.defualtReportCode = 200;
        //     }
        // }
        // $rootScope.LOAD_REPORT_TEMPLATES();
        // $scope.dnPreviewDisabled = function() {
        //     if(!$scope.guide || !$scope.guide.guideRates || $scope.guide.guideRates.length == 0){
        //         return true;
        //     }
        //     if($scope.shipmentType == "fms"){
        //         return !$scope.guide.booking.isLocked;
        //     }
        //     return false;
        // }
        // $scope.dnPreview = function(reportCode) {
        //     var guideReportDTO = angular.copy($scope.guide);
        //     guideReportDTO.opType = 1;
        //     guideReportDTO.reportType = reportCode;
        //     guideReportDTO.fileType = 1;
        //     OpsReport.generateGuide({}, guideReportDTO, function(result) {
        //         $rootScope.PRINTBYTES(result.data);
        //     });
        // }

    }
})();
