(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ExpressPackingDialogController', ExpressPackingDialogController);

    ExpressPackingDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', '$location', 
    'entity', 'ExpressPacking', 'OpsVender'];

    function ExpressPackingDialogController ($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, $location,
        entity, ExpressPacking, OpsVender) {
        var vm = this;

        vm.expressPacking = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.expressParcels = null;

        $timeout(function() {
            angular.element('.form-group:eq(1)>input').focus();
            var path = $location.path();
            if (path.indexOf("copy") >= 0) {
                vm.expressPacking.id = null;
                vm.expressPacking.guideId = null;
                vm.expressPacking.jobNum = null;
                vm.expressPacking.etd = null;
                vm.expressPacking.consolidated = false;                
            }   
            getExpressParcels();    
        });
        function getExpressParcels () {
            if(!vm.expressPacking.id){
                return;
            }
            ExpressPacking.getExpressParcels({
                expressPackingId: vm.expressPacking.id
            }, function(results){
                vm.expressParcels = results;
            })            
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.expressPacking.id !== null) {
                ExpressPacking.update(vm.expressPacking, onSaveSuccess, onSaveError);
            } else {
                ExpressPacking.save(vm.expressPacking, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('autopsApp:expressPackingUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $scope._onColoaderSelected = function(data) {
            vm.expressPacking.bookingToId = data.id;
        }


    }
})();
