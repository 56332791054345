(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('OpsCompany', OpsCompany);

    OpsCompany.$inject = ['$resource','DateUtils'];
    
    function OpsCompany($resource, DateUtils) {
        return $resource('api/opsCompanys/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.billDate = DateUtils.convertLocalDateFromServer(data.billDate);
                    return data;
                }
            },
            'getSetDTO': {
                method: 'GET',
                url:'api/opsCompanys/getSetDTO'
            },
            'updateSetDTO': { 
                method:'PUT' ,
                url:'api/opsCompanys/updateSetDTO'
            },
            'changeCompany': {
                method: 'GET',
                url:'api/opsCompanys/changeCompany'
            },
            'deleteCompanyPic': {
                method: 'GET',
                url:'api/opsCompanys/deleteCompanyPic'
            },            
            'getByEmail': {
                method: 'GET',
                url:'api/opsCompanys/getByEmail'
            },
            'getOpsCompanyJson': {
                method: 'GET',
                url:'api/ops-company-json'
            },
            'saveOpsCompanyJson': {
                method: 'POST',
                url:'api/ops-company-json'
            },
            'amendNum': {
                method: 'GET',
                url:'api/opsCompanys/amendNum'
            },    
            'getCompanyAbb': {
                method: 'GET',
                url:'api/opsCompanys/getCompanyAbb'
            },
            'getCompanyByUserId': {
                method: 'GET',
                url:'api/opsCompanys/getCompanyByUserId'
            },            
            'goLiveCheck': {
                method: 'GET',
                url:'api/opsCompanys/goLiveCheck'
            },
            'jhfAuthPushChange': {
                method: 'GET',
                url:'api/opsCompanys/jhfAuthPushChange'
            },            
            'getZoneIds': {
                method: 'GET',
                url:'api/opsCompanys/getZoneIds',
                isArray: true
            },            
            'getVenderIdCompanyIdByEmail': {
                method: 'GET',
                url:'api/opsCompanys/getVenderIdCompanyIdByEmail'
            },            
            'getAccountLocked': {
                method: 'GET',
                url:'api/opsCompanys/getAccountLocked'
            },
            'setAccountLocked': {
                method: 'GET',
                url:'api/opsCompanys/setAccountLocked'
            },
            'update': { 
                method:'PUT' ,
                transformRequest: function (data) {
                    data.billDate = DateUtils.convertLocalDateToServer(data.billDate);
                    return angular.toJson(data);
                }
            },
            'getGroupCompanys': {
                method:'GET',
                url:'api/opsCompanys/getGroupCompanys',
                isArray: true
            },
            'getBindCompanys': {
                method:'GET',
                url:'api/opsCompanys/getBindCompanys',
                isArray: true
            },
            'getHblNum': {
                method:'GET',
                url:'api/opsCompanys/getHblNum/:id',
                params:{id:'@id'},
                transformResponse: function (data) {
                    data = angular.fromJson(data);      
                    return data;
                }
            },
            'getCustomizedFunction': {
                method: 'GET',
                url: 'api/opsCompanys/getCustomizedFunction',
                isArray: true
            },
            'changeCustomizedFunction': {
                method: 'GET',
                url: 'api/opsCompanys/changeCustomizedFunction',
                isArray: true
            },
            'getAllCustomizedFunction': {
                method: 'GET',
                url: 'api/opsCompanys/getAllCustomizedFunction',
                isArray: true
            }
        });
    }
})();
