(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WriteoffPaymentPopController', WriteoffPaymentPopController);
    
    WriteoffPaymentPopController.$inject = ['$rootScope', '$scope', '$timeout', '$translate', '$uibModalInstance', 'entity', 'GuideRates', 'DataUtils'];
    
    function WriteoffPaymentPopController($rootScope, $scope, $timeout, $translate, $uibModalInstance, entity, GuideRates, DataUtils) {

        $scope.paymentRequest = entity
        $timeout(function (){
            if (!$scope.paymentRequest.writeOffRates || $scope.paymentRequest.writeOffRates.length == 0){
                GuideRates.getWrieteOffRatesByDnNum({
                    companyId: $rootScope.account.companyId,
                    dnNum: $scope.paymentRequest.dnNum
                }, function(result) {
                    $scope.paymentRequest.writeOffRates = result;
                    $scope.paymentRequest.writeOffRates.sort(function(a,b){
                        return a.jobNum > b.jobNum?1:-1;       
                    });
                    $scope.paymentAutoInput();
                    invalidCheck();
                });
            }
            else {
                // $scope.paymentAutoInput();
                invalidCheck();
            }
        });
        $scope.filterFd = {};
        $scope.filterFdChange = function(fd) {
            $scope.paymentRequest.writeOffRates.sort(function(a, b) {
                if(a[fd] && angular.lowercase(a[fd]).indexOf(angular.lowercase($scope.filterFd[fd])) != -1){
                    return -1;
                }
                if(b[fd] && angular.lowercase(b[fd]).indexOf(angular.lowercase($scope.filterFd[fd])) != -1){
                    return 1;
                }
            });
        }

        var invalidCheck = function() {
            if($scope.paymentRequest.manual){
                return;
            }
            var ttlAmt = 0;
            $scope.paymentRequest.checkBal = 0;
            angular.forEach($scope.paymentRequest.writeOffRates, function(data){                
                if (!data.isPay) {
                    ttlAmt -= data.totalAmount;
                    $scope.paymentRequest.checkBal += data.diffAmount;
                }
                else {
                    ttlAmt += data.totalAmount;
                    $scope.paymentRequest.checkBal -= data.diffAmount;
                }
            })
            if(DataUtils.round(ttlAmt, 2) != DataUtils.round($scope.paymentRequest.invoiceAmount, 2)){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.accountInvoice.invalidDnAlert', {param: $scope.paymentRequest.dnNum}));
            }
        }

        $scope.paymentAutoInput = function() {
            if (null == $scope.paymentRequest.actualAmount){
                $scope.paymentRequest.actualAmount = 0;
            }
            if($scope.paymentRequest.checkBal > $scope.paymentRequest.actualAmount){
                $scope.paymentRequest.actualAmount = $scope.paymentRequest.checkBal;
                layer.msg("Min: " + $scope.paymentRequest.checkBal);
            }
            if($scope.paymentRequest.balance < $scope.paymentRequest.actualAmount){
                $scope.paymentRequest.actualAmount = $scope.paymentRequest.balance;
                layer.msg("Max: " + $scope.paymentRequest.balance);
            }

            var thisAmount = $scope.paymentRequest.actualAmount.toFixed(2) - 0;
            //清空记录
            angular.forEach($scope.paymentRequest.writeOffRates, function(data){
                data.thisAmount = 0;
            });
            //更新费用列表
            angular.forEach($scope.paymentRequest.writeOffRates, function(data){
                if (!data.isPay && data.totalAmount > 0) {
                    data.thisAmount = data.diffAmount.toFixed(2) - 0;
                    thisAmount = thisAmount + data.thisAmount;
                }
                else if (data.isPay && data.totalAmount < 0) {
                    data.thisAmount = data.diffAmount.toFixed(2) - 0;
                    thisAmount = thisAmount - data.thisAmount;
                }

            });
            angular.forEach($scope.paymentRequest.writeOffRates, function(data){
                if (!data.isPay && data.totalAmount <= 0) {
                    if (thisAmount >= -data.diffAmount) {
                        data.thisAmount = data.diffAmount.toFixed(2) - 0;
                        thisAmount = thisAmount + data.diffAmount;
                    }
                    else {
                        data.thisAmount = -(thisAmount.toFixed(2) - 0);
                        thisAmount = 0;
                    }
                }
                else if (data.isPay && data.totalAmount >= 0) {
                    if (thisAmount >= data.diffAmount) {
                        data.thisAmount = data.diffAmount.toFixed(2) - 0;
                        thisAmount = thisAmount - data.diffAmount;
                    }
                    else {
                        data.thisAmount = thisAmount.toFixed(2) - 0;
                        thisAmount = 0;
                    }
                }


                if (data.isPay && data.totalAmount >= 0) {
  
                }
            });
        }
        
        $scope.choosedChange = function(rate) {
            if(!rate.diffAmount){return};
            rate.choosed = !rate.choosed;
            rate.thisAmount = rate.diffAmount;
            $scope.thisAmountChange(rate);
            updateChoosedTtl();
        }

        $scope.choosedTtlAmt = 0;
        var updateChoosedTtl = function() {
            $scope.choosedTtlAmt = 0;
            angular.forEach($scope.paymentRequest.writeOffRates, function(data){
                if (data.choosed) {     
                    if(data.isPay) {
                        $scope.choosedTtlAmt -= data.thisAmount;
                    }
                    else {
                        $scope.choosedTtlAmt += data.thisAmount;
                    }
                }
            });
        }

        $scope.chooseByJob = function(rate) {
            var choosed = !rate.choosed;
            angular.forEach($scope.paymentRequest.writeOffRates, function(data){
                if (data.jobNum == rate.jobNum) {     
                    data.choosed = choosed;
                }
            });
            updateChoosedTtl();
        }        

        $scope.thisAmountChange = function(rate) {
            if(!rate.thisAmount && rate.thisAmount != 0){
                rate.thisAmount = rate.diffAmount;
            }
            if(rate.diffAmount >= 0 && (rate.thisAmount < 0 || rate.thisAmount > rate.diffAmount)){
                rate.thisAmount = rate.diffAmount;
            }
            if(rate.diffAmount <= 0 && (rate.thisAmount > 0 || rate.thisAmount < rate.diffAmount)){
                rate.thisAmount = rate.diffAmount;
            } 

            //更新合计数
            var totalPaid = 0;
            angular.forEach($scope.paymentRequest.writeOffRates, function(data){
                if(data.isPay){
                    totalPaid = totalPaid + data.thisAmount;  
                }
                else {
                    totalPaid = totalPaid - data.thisAmount;  
                }
            });
            //更新开票申请
            $scope.paymentRequest.actualAmount = totalPaid.toFixed(2) - 0;
        }
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();