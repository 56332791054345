(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('SkuBindController', SkuBindController);

    SkuBindController.$inject = ['$scope', '$state', '$translate', '$http', '$uibModal', '$rootScope', '$timeout',
    'SkuBind', 'SkuBindSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'WinAES'];

    function SkuBindController ($scope, $state, $translate, $http, $uibModal, $rootScope, $timeout,
        SkuBind, SkuBindSearch, ParseLinks, AlertService, pagingParams, paginationConstants, WinAES) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.copy = copy;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.searchPop = searchPop;
        vm.deleteAll = deleteAll;
        vm.skuBindExport = skuBindExport;

        vm.importByExcel = importByExcel;
        function importByExcel (file) {
            if (!file) {return;}
            var fd = new FormData();
            fd.append('file', file);
            $http.post(
                'api/sku-binds/importByExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    data = angular.fromJson(data);
                    loadAll();
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.skuBind.uploadResult', {
                        imported: data.imported,
                        invalid: data.invalid,
                        repeated: data.repeated}));
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                });
        };
        if(!$rootScope.jobType){
            $rootScope.jobType = null;
        }

        $scope.setJobType = function(type) {
            $rootScope.jobType = type;
        }

        function loadAll () {
            if (vm.currentSearch) {
                if($rootScope.jobType){
                    $scope.isAdvanceSearch = true;
                    SkuBind.searchByNums({
                        type: $rootScope.jobType,
                        numStr: vm.currentSearch,
                        companyId: $rootScope.account.companyId
                    }, onSuccess, onError);
                }
                else {
                    SkuBindSearch.query({
                        query: vm.currentSearch,
                        page: pagingParams.page - 1,
                        size: vm.itemsPerPage,
                        sort: sort()
                    }, onSuccess, onError);
                }

            } else {
                SkuBind.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                // vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                if(!vm.totalItems){
                    vm.totalItems = data.length;
                }
                vm.queryCount = vm.totalItems;
                vm.skuBinds = data;
                vm.page = pagingParams.page;
                angular.forEach(vm.skuBinds, function(data){
                    data.encodeId = WinAES.encode(data.id.toString())
                })
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        loadAll();
        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            // vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = true;
            vm.currentSearch = searchQuery;
            loadAll();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.searchQuery = vm.currentSearch = null;
            $scope.isAdvanceSearch = false;
            loadAll();
        }
        function copy (skuBind) {
            SkuBind.get({id : skuBind.id}, function(result){
                result.id = null;
                $uibModal.open({
                    templateUrl: 'app/entities/sku-bind/sku-bind-dialog.html',
                    controller: 'SkuBindDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'md',
                    resolve: {
                        entity: ['SkuBind', 'WinAES', function(SkuBind, WinAES) {
                            return result;
                        }]
                    }
                }).result.then(function() {
                    loadAll();
                }, function() {
                });
            });
        }


        function searchPop () {
            var searchParams = {
                companyId: $rootScope.account.companyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/sku-bind/sku-bind-detail.html',
                controller: 'SkuBindDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(results) {
                $scope.isAdvanceSearch = vm.currentSearch = true;
                vm.totalItems = results.length;
                vm.queryCount = vm.totalItems;
                vm.skuBinds = results;
                vm.page = 1;
                angular.forEach(vm.skuBinds, function(data){
                    data.encodeId = WinAES.encode(data.id.toString())
                })
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }
        function deleteAll () {
            SkuBind.deleteAll(vm.skuBinds, function(){
                vm.skuBinds = [];
                layer.msg($translate.instant('global.delete_success'));
            }, function(){
                layer.msg($translate.instant('global.delete_fail'));
            });
        }
        $timeout(function (){
            $rootScope.RESIZE();
        });
        function skuBindExport () {
            if (vm.skuBinds == null || vm.skuBinds.length == 0) {
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            layer.load(1, { shade: 0.3 });
            SkuBind.skuBindExport(vm.skuBinds, function (result) {
                layer.closeAll();
                var filename = "sku_binds.xlsx";
                $rootScope.DOWNLOADEXCEL(result, filename);
            }, function () {
                layer.msg($translate.instant('global.loadFileFailed'));
                layer.closeAll();
            })
        }




    }
})();
