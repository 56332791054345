(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('GuideCtns', GuideCtns);

    GuideCtns.$inject = ['$resource','DateUtils'];
    
    function GuideCtns($resource, DateUtils) {
        return $resource('api/guideCtnss/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    for(var i=0; i<data.length; i++){
                        if (data[i].returnTime){
                            data[i].returnTime = DateUtils.convertDateTimeFromServer(data[i].returnTime);
                        }    
                    }    
                    return data;
                }
            },
            'truckerGet': {
                method:'GET',
                url:'api/guideCtnsForTrucker/:truckId',
                params:{truckId:'@truckId'},
                isArray:true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    for(var i=0; i<data.length; i++){
                        if (data[i].returnTime){
                            data[i].returnTime = DateUtils.convertDateTimeFromServer(data[i].returnTime);
                        }    
                    }          
                    return data;
                }
            },
            'update': { 
                method:'PUT', 
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    for(var i=0; i<data.length; i++){
                        if (data[i].returnTime){
                            data[i].returnTime = DateUtils.convertDateTimeFromServer(data[i].returnTime);
                        }                        
                    }          
                    return data;
                }
            },
            'updateOne': {
                method:'POST',
                url:'api/guideCtn',
                transformResponse: function (data) {
                    data = angular.fromJson(data);  
                    if(data.returnTime){
                        data.returnTime = DateUtils.convertDateTimeFromServer(data.returnTime);
                    }
                    return data;
                }
            },
            'saveAsTemplate': {
                method:'PUT',
                url:'api/setTruckTemplate',
                transformResponse: function (data) {
                    if (!data){
                        return;
                    }
                    data = angular.fromJson(data);  
                    if(data.returnTime){
                        data.returnTime = DateUtils.convertDateTimeFromServer(data.returnTime);
                    }
                    return data;
                }
            },
            'getTempalte': {
                method:'GET',
                url:'api/getTruckTemplate/:id',
                transformResponse: function (data) {
                    if (!data){
                        return;
                    }
                    data = angular.fromJson(data);  
                    if(data.returnTime){
                        data.returnTime = DateUtils.convertDateTimeFromServer(data.returnTime);
                    }
                    return data;
                }
            },
            'getTempaltes': {
                method:'GET',
                url:'api/guideCtnss/getTempaltes',
                isArray:true
            },
            'checkTruckNumRepeat': {
                method:'GET',
                url:'api/guideCtnss/checkTruckNumRepeat'
            },
            'save': { 
                method:'POST', 
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    for(var i=0; i<data.length; i++){
                        if (data[i].returnTime){
                            data[i].returnTime = DateUtils.convertDateTimeFromServer(data[i].returnTime);
                        }    
                    }          
                    return data;
                }
            }
        });
    }
})();
