(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GuideTemplateDetailController', GuideTemplateDetailController);

    GuideTemplateDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'GuideTemplate'];
    
    function GuideTemplateDetailController($scope, $rootScope, $stateParams, entity, GuideTemplate) {
        $scope.guideTemplate = entity;
        $scope.load = function (id) {
            GuideTemplate.get({id: id}, function(result) {
                $scope.guideTemplate = result;
            });
        };
        var unsubscribe = $rootScope.$on('autopsApp:guideTemplateUpdate', function(event, result) {
            $scope.guideTemplate = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
