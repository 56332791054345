(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GuideTemplateController', GuideTemplateController);

    GuideTemplateController.$inject = ['$timeout', '$scope', '$translate', '$rootScope', '$state', 'GuideTemplate', 'GuideTemplateSearch', 'ParseLinks'];

    function GuideTemplateController($timeout, $scope, $translate, $rootScope, $state, GuideTemplate, GuideTemplateSearch,ParseLinks) {

        $scope.guideTemplates = [];
        $scope.predicate = 'id';
        $scope.reverse = false;
        $scope.page = 1;
        $scope.itemsPerPage = 20;
        $scope.loadAll = function() {
            GuideTemplate.query({page: $scope.page-1, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']},function(result,headers) {
            	$scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
            	$scope.guideTemplates = result;
            });
        };
        $scope.loadAll();


        $scope.search = function () {
            GuideTemplateSearch.query({query: $scope.searchQuery}, function(result) {
                $scope.guideTemplates = result;
            }, function(response) {
                if(response.status === 404) {
                    $scope.loadAll();
                }
            });
        };

        $scope.create = function () {
            layer.msg($translate.instant('autopsApp.guideTemplate.createTips'));
        }
        $scope.edit = function (id) {
            $rootScope.id = id;
            $state.go('guideTemplate.edit');

        }
        $scope.delete = function (id, num) {
            $rootScope.num = num;
            $rootScope.id = id;
            $state.go('guideTemplate.delete');

        }

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.guideTemplate = {
                createdBy: null,
                code: null,
                remarks: null,
                guideId: null,
                id: null
            };
        };
        $timeout(function (){
            $rootScope.RESIZE();
        });


    }
})();
