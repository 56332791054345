(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('BookingTemplate', BookingTemplate);

    BookingTemplate.$inject = ['$resource','DateUtils'];
    
    function BookingTemplate($resource, DateUtils) {
        return $resource('api/bookingTemplates/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
