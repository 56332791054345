(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('Email', Email);

    Email.$inject = ['$resource','DateUtils'];
    
    function Email($resource, DateUtils) {
        return $resource('', {}, {
            'sendEmail': {
            	method:'POST',
    		    url:'api/sendEmail',
            },
            'roleReset': {
                method:'GET',
                url:'api/users/addAuthRole',
            },
            'initSearch': {
                method:'GET',
                url:'api/users/initSearch',
            },
            'clearAccounting': {
                method:'GET',
                url:'api/users/clearAccounting',
            },
            'resetBalance': {
                method:'GET',
                url:'api/users/resetBalance',
            },
            'delOneCompanyData': {
                method:'GET',
                url:'api/init/delOneCompanyData',
            },
            'sendChangeLogEmail': {
                method:'POST',
                url:'api/sendChangeLogEmail',
            }
        });
    }
})();
