(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('CrmEnquiry', CrmEnquiry);

    CrmEnquiry.$inject = ['$resource', 'DateUtils'];

    function CrmEnquiry ($resource, DateUtils) {
        var resourceUrl =  'api/crm-enquiries/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.enquiryTime = DateUtils.convertDateTimeFromServer(data.enquiryTime);
                    }
                    return data;
                }
            },
            'getFilterPage':{
                method: 'POST',
                url:'api/crm-enquiries/getFilterPage',
                isArray: true
            },
            'transferEnquiry':{
                method: 'POST',
                url:'api/crm-enquiries/transferEnquiry'
            },
            'update': { method:'PUT' }
        });
    }
})();
