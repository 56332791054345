(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('commodity-database', {
            parent: 'entity',
            url: '/commodity-database?page&sort&search',
            data: {
                authorities: ['EDIT_COMM_DATABASE'],
                pageTitle: 'autopsApp.commodityDatabase.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/commodity-database/commodity-databases.html',
                    controller: 'CommodityDatabaseController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('commodityDatabase');
                    $translatePartialLoader.addPart('commodityCountry');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('commodity-database-detail', {
            parent: 'entity',
            url: '/commodity-database/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'autopsApp.commodityDatabase.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/commodity-database/commodity-database-detail.html',
                    controller: 'CommodityDatabaseDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('commodityDatabase');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'CommodityDatabase', function($stateParams, CommodityDatabase) {
                    return CommodityDatabase.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'commodity-database',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('commodity-database.new', {
            parent: 'commodity-database',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/commodity-database/commodity-database-dialog.html',
                    controller: 'CommodityDatabaseDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xl',
                    resolve: {
                        entity: function () {
                            return {
                                commodityInspectionRequired: false,
                                modelRequired: false
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('commodity-database', null, { reload: 'commodity-database' });
                }, function() {
                    $state.go('commodity-database');
                });
            }]
        })
        .state('commodity-database.edit', {
            parent: 'commodity-database',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/commodity-database/commodity-database-dialog.html',
                    controller: 'CommodityDatabaseDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xxl',
                    resolve: {
                        entity: ['CommodityDatabase', 'WinAES', function(CommodityDatabase, WinAES) {
                            return CommodityDatabase.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^');
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('commodity-database.delete', {
            parent: 'commodity-database',
            url: '/{id}/delete',
            data: {
                authorities: ['EDIT_COMM_DATABASE']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/commodity-database/commodity-database-delete-dialog.html',
                    controller: 'CommodityDatabaseDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['CommodityDatabase', 'WinAES', function(CommodityDatabase, WinAES) {
                            return CommodityDatabase.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('commodity-database', null, { reload: 'commodity-database' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
