(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('VgmCtnDetailController', VgmCtnDetailController);

    VgmCtnDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'VgmCtn', 'VgmHead'];

    function VgmCtnDetailController($scope, $rootScope, $stateParams, previousState, entity, VgmCtn, VgmHead) {
        var vm = this;

        vm.vgmCtn = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('autopsApp:vgmCtnUpdate', function(event, result) {
            vm.vgmCtn = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
