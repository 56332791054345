(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AgentController', AgentController);

    AgentController.$inject = ['$rootScope', '$scope', '$state', '$translate', '$uibModal', 'Principal', 'Guide', 'GuideSearch','ParseLinks','OpsFiles'];

    function AgentController($rootScope, $scope, $state, $translate, $uibModal, Principal, Guide, GuideSearch, ParseLinks, OpsFiles) {

        $scope.guides = [];
        $scope.predicate = 'id';
        $scope.reverse = false;
        $scope.page = 1;
        $scope.currentSearch = false;

        $scope.loadAll = function() {
            $scope.currentSearch = false;
            Guide.getAImports({
                page: $scope.page - 1,
                size: 20,
                sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']
            }, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.guides = result;
            });
        };

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        }
        $scope.reset = function() {
            $scope.currentSearch = false;
            $scope.page = 0;
            $scope.guides = [];
            $scope.loadAll();
        };

        $scope.loadAll();
        $scope.search = function() {
            $scope.currentSearch = true;
            GuideSearch.aImportsQuery({
                query: $scope.searchQuery
            }, function(result) {
                $scope.guides = result;
            }, function(response) {
                if (response.status === 404) {
                    $scope.loadAll();
                }
            });
        };

        $scope.viewGuide = function(guide) {
            $rootScope.id = "view";
            $uibModal.open({
                templateUrl: 'app/entities/agent/agent-detail.html',
                controller: 'AgentDetailController',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return Guide.agentImport({id : guide.id}).$promise;
                    }]
                }
            }).result.then(function(result) {
            }, function(result) {
            });
        }

        $scope.searchStr = "";
        var inputPopIndex = 0;
        $scope.searchGuidePop = function() {
            $scope.searchStr = "";
            inputPopIndex = layer.open({
                type: 1,
                area: ['380px', '170px'],
                title: $translate.instant('autopsApp.guide.home.advancedSearch'),
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: $("#advancedSearchPop")
            });
            document.getElementById("searchStr_field").focus();
        }

        $scope.searchGuide = function() {
            layer.close(inputPopIndex);
            $scope.currentSearch = true;
            $scope.searchStr = $scope.searchStr.replace(/\s+/g,"");
            Guide.searchAGuide({
                inputStr: $scope.searchStr
            }, function(result) {
                $scope.guides = result;
            }, function(response) {
                if (response.status === 404) {
                    $scope.loadAll();
                }
            });
        };
    }

})();
