(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('AccountTitle', AccountTitle);

    AccountTitle.$inject = ['$resource','DateUtils'];
    
    function AccountTitle($resource, DateUtils) {
        return $resource('api/accountTitles/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getAll': {
                method:'GET',
                url:'api/accountTitless',
                isArray:true
            },
            'resetSubsidiary': {
                method:'GET',
                url:'api/accountTitles/resetSubsidiary'
            },
            'generateVenderTitle': {
                method:'GET',
                url:'api/autoAccountVoucher/generateVenderTitle'
            },
            'shareOne': {
                method:'GET',
                url:'api/accountTitles/shareOne'
            },
            'getAllByPrefix': {
                method:'GET',
                url:'api/getAccountTitlessByPrefix',
                isArray:true
            },
            'initialize': {
                method:'POST',
                url:'api/accountTitles/initialize',
                isArray:true,
                transformRequest: function (data) {
                    data.startMonth = DateUtils.convertLocalDateToServer(data.startMonth);
                    return angular.toJson(data);
                },
            },
            'update': { method:'PUT' }
        });
    }
})();
