(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('reportTemplate', {
                parent: 'entity',
                url: '/reportTemplates',
                data: {
                    authorities: ['FUN_REPORT_TEMP'],
                    pageTitle: 'autopsApp.reportTemplate.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/reportTemplate/reportTemplates.html',
                        controller: 'ReportTemplateController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('reportTemplate');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('reportTemplate.new', {
                parent: 'reportTemplate',
                url: '/new',
                data: {
                    authorities: ['FUN_REPORT_TEMP'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/reportTemplate/reportTemplate-dialog.html',
                        controller: 'ReportTemplateDialogController',
                        size: 'md',
                        resolve: {
                            entity: function () {
                                return {
                                    companyId: null,
                                    companyName: null,
                                    type: null,
                                    template: null,
                                    templateContentType: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('reportTemplate', null, { reload: true });
                    }, function() {
                        $state.go('reportTemplate');
                    })
                }]
            })
            .state('reportTemplate.edit', {
                parent: 'reportTemplate',
                url: '/{id}/edit',
                data: {
                    authorities: ['FUN_REPORT_TEMP'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/reportTemplate/reportTemplate-dialog.html',
                        controller: 'ReportTemplateDialogController',
                        size: 'md',
                        resolve: {
                            entity: ['ReportTemplate', 'WinAES', function(ReportTemplate, WinAES) {
                                return ReportTemplate.get({id : WinAES.decode($stateParams.id)});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('reportTemplate', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('reportTemplate.delete', {
                parent: 'reportTemplate',
                url: '/{id}/delete',
                data: {
                    authorities: ['FUN_REPORT_TEMP'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/reportTemplate/reportTemplate-delete-dialog.html',
                        controller: 'ReportTemplateDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['ReportTemplate', 'WinAES', function(ReportTemplate, WinAES) {
                                return ReportTemplate.get({id : WinAES.decode($stateParams.id)});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('reportTemplate', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }
})();
