(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CrmEnquiryDetailController', CrmEnquiryDetailController);

    CrmEnquiryDetailController.$inject = ['$timeout', '$rootScope', '$scope', '$translate', '$uibModalInstance', '$uibModal',
        'entity', 'CrmEnquiry', 'OpsCost', 'DataUtils', 'CrmQuote', 'CrmPrice'
    ];

    function CrmEnquiryDetailController($timeout, $rootScope, $scope, $translate, $uibModalInstance, $uibModal,
        entity, CrmEnquiry, OpsCost, DataUtils, CrmQuote, CrmPrice) {
        var vm = this;

        vm.crmQuote = entity;
        vm.clear = clear;
        vm.save = save;
        vm.getRates = getRates;
        vm.chooseService = chooseService;
        vm.addPrice = addPrice;
        vm.delPrice = delPrice;
        vm.setShowIdx = setShowIdx;
        vm.curChange = getChargesTtl;
        vm.rateChange = rateChange;
        vm.unitChange = unitChange;
        vm.editCrmEnquiry = editCrmEnquiry;
        vm.printQuotation = printQuotation;
        vm.emailQuotation = emailQuotation;
        vm.markAsQuoted = markAsQuoted;
        vm.generateGuide = generateGuide;
        vm.quoteSelected = quoteSelected;
        vm.searchQuote = searchQuote;
        vm.notQuoter = notQuoter;
        vm.files = [];

        vm.chargeTypes= ["OF", "POL", "POD", "CUS", "OTH"];
        var crmEnquiryCopy;
        var priceDefault;

        $timeout(function() {
            intiCrmQuote();
        });

        function intiCrmQuote() {
            if(!vm.crmQuote || !vm.crmQuote.crmEnquiry || !vm.crmQuote.crmEnquiry.id){
                editCrmEnquiry({});
            }
            else {
                if (vm.crmQuote.crmEnquiry.volumeJson) {
                    vm.crmQuote.crmEnquiry.volumeJsonObj = angular.fromJson(vm.crmQuote.crmEnquiry.volumeJson);
                }
                crmEnquiryCopy = angular.copy(vm.crmQuote.crmEnquiry);
                initRates();
                getChargesTtl();

            }
        }
        function initNewPrice() {
            priceDefault = {
                currency: $rootScope.account.baseCurrency,
                unit: "KG",
                count: crmEnquiryCopy.gw
            }
        }

        function editCrmEnquiry(crmEnquiry) {
            $uibModal.open({
                templateUrl: 'app/entities/crm-enquiry/crm-enquiry-dialog.html',
                controller: 'CrmEnquiryDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'xl',
                resolve: {
                    entity: [function() {
                        if(crmEnquiry.id){
                            return CrmEnquiry.get({id : crmEnquiry.id}).$promise;
                        }
                        else {
                            return crmEnquiry;
                        }
                    }]
                }
            }).result.then(function(result) {
                vm.crmQuote.crmEnquiry = result;
                crmEnquiryCopy = angular.copy(vm.crmQuote.crmEnquiry);
                initRates();
                getChargesTtl();
            }, function(result) {
            });
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
        function markAsQuoted() {
            if(vm.crmQuote.status == 'booked'){
                layer.msg($translate.instant('autopsApp.crmEnquiry.bookedAlert'));
                return;
            }
            if(vm.crmQuote.status == 'new'){
                vm.crmQuote.status = 'replied';
                vm.crmQuote.quotationTime = new Date();
            }
            else if(vm.crmQuote.status == 'replied'){
                vm.crmQuote.status = 'new';
                vm.crmQuote.quotationTime = null;
            }
            save();
        }


        function save() {
            vm.isSaving = true;
            vm.crmQuote.enquiryId = crmEnquiryCopy.id;
            vm.crmQuote.inquirerId = crmEnquiryCopy.createrId;
            CrmQuote.save(vm.crmQuote, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(result) {
            vm.crmQuote = result;
            getChargesTtl();
            vm.crmQuote.crmEnquiry = angular.copy(crmEnquiryCopy);
            layer.msg($translate.instant('global.save_success'));
            vm.isSaving = false;
            $translate.refresh();
            $scope.$emit('autopsApp:crmQuoteUpdate', angular.copy(vm.crmQuote));
        }

        function onSaveError() {
            vm.isSaving = false;
        }
        $scope.opsSellings = [];
        $scope.findPrices = function() {
            if ($scope.opsSellings.length > 0) { return; }
            var shipmentType = vm.crmQuote.shipmentType;
            if (!shipmentType) {
                shipmentType = "FBA";
            }
            OpsCost.findPrices({
                isSelling: true,
                shipmentType: shipmentType
            }, function(result) {
                $scope.opsSellings = result;
            })
        }

        function getCheapestPrice() {
            vm.cheapestPrice = null;
            angular.forEach(vm.ratesList, function(rate) {
                if (!vm.cheapestPrice || rate.freight < vm.cheapestPrice) {
                    vm.cheapestPrice = rate.freight;
                }
            })
            vm.ratesList.sort(function(a, b) {
                return Math.abs(a.freight) > Math.abs(b.freight) ? 1 : -1;
            });
        }
        vm.ratesList = [];


        function getRates(freightType, fd) {
            vm.isSaving = true;
            var crmQuoteDTO = {};
            if (fd) {
                crmQuoteDTO[fd] = vm.crmQuote[fd];
            }
            crmQuoteDTO.freightType = freightType;
            crmQuoteDTO.crmEnquiry = angular.copy(crmEnquiryCopy);

            if (!crmQuoteDTO.shipmentType) {
                crmQuoteDTO.shipmentType = "FBA";
            }
            OpsCost.crmEnquiryTrial(crmQuoteDTO, function(ratesList) {
                vm.isSaving = false;
                vm.ratesList = ratesList;
                if (!vm.ratesList || vm.ratesList.length == 0) {
                    layer.msg($translate.instant('global.noRecord'));
                } else {
                    getCheapestPrice();
                }
            }, function(error) {
                vm.isSaving = false;
                if (error.status == 404) {
                    layer.msg($translate.instant('global.noRecord'));
                } else {
                    layer.msg($translate.instant('global.requestFail'));
                }
            })
        }

        function chooseService(rate) {
            if (rate.carrier) {
                vm.crmQuote.carrier = rate.carrier;
            }
            if (rate.shipmentType) {
                vm.crmQuote.transportationMode = rate.shipmentType;
            }
            if (rate.shipmentType) {
                vm.crmQuote.transportationMode = rate.shipmentType;
            }
            if (rate.polChannel) {
                vm.crmQuote.polChannel = rate.polChannel;
            }
            if (rate.channelName) {
                vm.crmQuote.deliveryChannel = rate.channelName;
            }
            if (rate.productName) {
                vm.crmQuote.productName = rate.productName;
            }
            if (rate.sellingMath) {
                vm.crmQuote.sellingMath = rate.sellingMath;
            }
            if ((!vm.crmQuote.expireDate && rate.expireDate) || DateUtils.compareDate(vm.crmQuote.expireDate, rate.expireDatee) > 0) {
                vm.crmQuote.expireDate = rate.expireDate;
            }
            addQuotePrices(rate.freightRates);
            getChargesTtl();
            $translate.refresh();
        }

        function existCheck(rate) {
            var isExist = false;
            angular.forEach(vm.crmQuote.crmQuotePrices, function(data) {
                if (rate.type == data.type && rate.item == data.item &&
                    rate.price == data.price && rate.count == data.count && rate.unit == data.unit &&
                    rate.currency == data.currency && rate.total == data.total)
                    isExist = true;
            })
            return isExist;

        }

        function haveRates(type) {
            var haveRate = false;
            angular.forEach(vm.crmQuote.crmQuotePrices, function(data) {
                if (type == data.type) {
                    haveRate = true;
                }
            })
            return haveRate;
        }


        function getPriceByType(type) {
            var newPrice = angular.copy(priceDefault);
            newPrice.type = type;
            return newPrice;
        }


        function initRates() {
            initNewPrice()
            if (!vm.crmQuote.crmQuotePrices) {
                vm.crmQuote.crmQuotePrices = [];
            }
            if (!haveRates("OF")) {
                vm.crmQuote.crmQuotePrices.push(getPriceByType("OF"));
            }
            // if(!haveRates("POL")){
            //     vm.crmQuote.crmQuotePrices.push(getPriceByType("POL"));
            // }
            if(crmEnquiryCopy.insuranceRequired){
                if(null == vm.crmQuote.insuranceIncluded){
                    vm.crmQuote.insuranceIncluded = true;
                }
                if(!haveRates("OTH")){
                    var insRate = {
                        item: "保险费",
                        type: "OTH",
                        currency: $rootScope.account.baseCurrency,
                        unit: "SHIPMENT",
                        count: 1,
                        remark: "0.2% of 保险金险"
                    }
                    vm.crmQuote.crmQuotePrices.push(insRate);
                }
            }
            if(crmEnquiryCopy.dutyRequired){
                if(null == vm.crmQuote.customsIncluded){
                    vm.crmQuote.customsIncluded = true;
                }
                if(!haveRates("CUS")){
                    vm.crmQuote.crmQuotePrices.push({
                        item: "报关费",
                        type: "CUS",
                        currency: $rootScope.account.baseCurrency,
                        unit: "SHIPMENT",
                        count: 1
                    });
                    vm.crmQuote.crmQuotePrices.push({
                        item: "进口清关费",
                        type: "CUS",
                        currency: $rootScope.account.baseCurrency,
                        unit: "SHIPMENT",
                        count: 1
                    });
                    vm.crmQuote.crmQuotePrices.push({
                        item: "关税",
                        type: "CUS",
                        currency: $rootScope.account.baseCurrency,
                        unit: "SHIPMENT",
                        count: 1,
                        remark: "HS 代码和发票金额决定"
                    });
                    if(angular.equals(angular.uppercase(crmEnquiryCopy.shiptoCountryCode), "US")){
                        vm.crmQuote.crmQuotePrices.push({
                            item: "进口安全申报",
                            type: "CUS",
                            currency: $rootScope.account.baseCurrency,
                            unit: "SHIPMENT",
                            count: 1
                        });
                    }
                }
            }
            if(crmEnquiryCopy.pickupRequired){
                if(!haveRates("POL")){
                    var newRate = getPriceByType("POL");
                    newRate.item = "本地提货费";
                    vm.crmQuote.crmQuotePrices.push(newRate);
                }
            }
            if(crmEnquiryCopy.deliveryRequired){
                if(!haveRates("POD")){
                    var newRate = getPriceByType("POD");
                    newRate.item = "派送费";
                    vm.crmQuote.crmQuotePrices.push(newRate);
                }
            }
        }

        function addQuotePrices(newRates) {
            angular.forEach(newRates, function(rate) {
                if (!existCheck(rate)) {
                    vm.crmQuote.crmQuotePrices.push(rate);
                }
            })
        }

        function getChargesTtl() {
            vm.crmQuote.ttlList = [];
            var currencies = new Set();
            var freightTypes = new Set();
            angular.forEach(vm.crmQuote.crmQuotePrices, function(data) {
                freightTypes.add(data.type);
            })
            angular.forEach(vm.crmQuote.crmQuotePrices, function(data) {
                currencies.add(data.currency);
            })
            currencies.forEach(function(cur) {
                var curTtl = 0;
                freightTypes.forEach(function(freightType) {
                    var ttl = 0;
                    angular.forEach(vm.crmQuote.crmQuotePrices, function(data) {
                        if (data.total && cur == data.currency && freightType == data.type) {
                            ttl += data.total;
                            curTtl += data.total;
                        }
                    })
                    if (ttl) {
                        vm.crmQuote.ttlList.push({
                            currency: cur,
                            type: freightType,
                            total: ttl
                        });
                    }
                    if (freightType == "OF" && crmEnquiryCopy.gw) {
                        vm.crmQuote.ttlList.push({
                            currency: cur,
                            type: "FPK",
                            total: DataUtils.round(ttl / crmEnquiryCopy.gw, 2)
                        });
                    }
                })
                if (curTtl) {
                    vm.crmQuote.ttlList.push({
                        currency: cur,
                        type: "TTL",
                        total: curTtl
                    });
                    if (crmEnquiryCopy.gw) {
                        vm.crmQuote.ttlList.push({
                            currency: cur,
                            type: "TPK",
                            total: DataUtils.round(curTtl/crmEnquiryCopy.gw, 2)
                        });
                    }
                }
            })
            $translate.refresh();
        }

        function addPrice(type) {
            if(notQuoter()){
                return;
            }
            vm.crmQuote.crmQuotePrices.push(getPriceByType(type));
        }

        function notQuoter() {
            return vm.crmQuote.quoterId && vm.crmQuote.quoterId != $rootScope.account.id;
        }


        function delPrice(price) {
            if(notQuoter()){
                return;
            }
            if (!price.id) {
                removePrice(price);
            }
            else {
                CrmPrice.delete({
                    id: price.id
                }, function(){
                    removePrice(price);
                });
            }
        }

        function removePrice(price) {
            var index = vm.crmQuote.crmQuotePrices.indexOf(price);
            if (index > -1) {
                vm.crmQuote.crmQuotePrices.splice(index, 1);
            };
            getChargesTtl();
        }

        vm.showIdx;
        function setShowIdx(idx) {
            if(notQuoter()){
                return;
            }
            vm.showIdx = idx;
            $timeout(function() {
                $("#" + idx + "_field").focus();
                $("#" + idx + "_field").select();
            });
        }

        function rateChange(rate) {
            if (rate.price && rate.count) {
                rate.total = rate.price * rate.count;
                rate.total = rate.total.toFixed(2) - 0;
            }
            else {
                rate.total = null;
            }
            getChargesTtl();
        }

        function unitChange(rate) {
            if ($rootScope.priceUnits.indexOf(rate.unit) === -1) {
                return;
            }
            var guieEntity = angular.copy(crmEnquiryCopy);
            guieEntity.pkgNum = guieEntity.pkgs;
            rate.count = DataUtils.round(DataUtils.getUnitCount(rate, 'guide', guieEntity), 4);
            rateChange(rate);
        }

        function printQuotation(reportCode){
            vm.isSaving= true;
            var crmQuoteDTO = angular.copy(vm.crmQuote);
            crmQuoteDTO.reportCode = reportCode;
            CrmQuote.printQuotation({},crmQuoteDTO,function(result){
                vm.isSaving= false;
                $rootScope.PRINTBYTES(result.data);
            },function(error){
                vm.isSaving= false;
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        function emailQuotation(reportCode){
            if(!vm.crmQuote.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if(!vm.crmQuote.crmEnquiry.venderId){
                layer.msg($translate.instant('autopsApp.crmQuotation.clientNullAlert'));
                return;
            }
            vm.isSaving= true;

            var email = {};
            email.attachments = [];
            email.head = vm.crmQuote.inquirerCompany;
            email.subject = vm.crmQuote.crmEnquiry.shipmentName + "-" + vm.crmQuote.quoteNum;

            email.to = vm.crmQuote.crmEnquiry.inquirerEmail + "; " + $rootScope.account.email + "; ";
            email.content = "Dear " + vm.crmQuote.crmEnquiry.inquirerName + ",\n\n" + $translate.instant('entity.email.crmQuotation') + "\n\n";

            var entity = {};
            entity.files = [];
            entity.email = email;
            entity.emailType = "crmQuote";

            var crmQuoteDTO = angular.copy(vm.crmQuote);
            crmQuoteDTO.reportCode = reportCode;
            CrmQuote.getAttachment({},crmQuoteDTO,function(result){
                entity.fileResult = result[0];
                sendEmail(entity);
                vm.isSaving= false;
            },function(error){
                vm.isSaving= false;
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        function sendEmail(entity){
            $uibModal.open({
                templateUrl: 'app/entities/email/email-dialog.html',
                controller: 'EmailDialogController',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
            }, function(result) {});
        }

        function generateGuide(){
            if(vm.crmQuote.jobNum){
                layer.msg($translate.instant('autopsApp.crmEnquiry.doubleCreateGuideAlert'));
                return;
            }

            layer.confirm($translate.instant('autopsApp.crmEnquiry.generateGuideAlert'), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                CrmQuote.generateGuide({}, vm.crmQuote, function(result){
                    vm.isSaving= false;
                    if(result.jobNum){
                        vm.crmQuote.jobNum = result.jobNum;
                    }
                    $rootScope.OPS_ALERT($translate.instant('global.createdSuccessWithRef', { "ref": result.jobNum }));
                }, $rootScope.ALERT_ERROR);
            }, function() {});
        }

        function searchQuote(searchStr) {
            return CrmQuote.searchQuote({
                query: searchStr,
                page: 0,
                size: 30,
                sort: sort()
            }).$promise;
            function sort() {
                var result = ['_score,desc'];
                return result;
            }
        }
        function quoteSelected(item) {
            vm.crmQuote.carrier = item.carrier;
            vm.crmQuote.transportationMode = item.transportationMode;
            vm.crmQuote.freightService = item.freightService;
            vm.crmQuote.subjectTo = item.subjectTo;
            vm.crmQuote.productName = item.productName;
            vm.crmQuote.deliveryChannel = item.deliveryChannel;
            vm.crmQuote.polChannel = item.polChannel;
            vm.crmQuote.sellingMath = item.sellingMath;
            vm.crmQuote.customsIncluded = item.customsIncluded;
            vm.crmQuote.insuranceIncluded = item.insuranceIncluded;
            vm.crmQuote.origin = item.origin;
            vm.crmQuote.originDwell = item.originDwell;
            vm.crmQuote.portToPort = item.portToPort;
            vm.crmQuote.destinationDwell = item.destinationDwell;
            vm.crmQuote.destination = item.destination;
            angular.forEach(item.crmQuotePrices, function(price){
                price.id = null;
                price.quotationId = null;
                price.crmQuote = null;
                vm.crmQuote.crmQuotePrices.push(price);
            })
            getChargesTtl();
        }

    }
})();
