(function() {
	'use strict';

	angular.module('autopsApp').directive('customFileUpload', customFileUpload);

	customFileUpload.$inject = [ '$parse', '$http', '$translate' ];

	function customFileUpload($parse, $http, $translate) {

		return {
			restrict : 'AE',
			scope : {
				customDeclarationId : '=',
				refreshFiles : '&'
			},
			link : function(scope, element, attrs, ngModel) {

				scope.hasFile = false;
				var model = $parse(attrs.customFileUpload);
				var modelSetter = model.assign;

				element.bind('change', function(event) {

					if (element[0].files[0] == undefined) {
						return;
					}
					scope.$apply(function() {
						modelSetter(scope, element[0].files[0]);
					});

					var fd = new FormData();
					fd.append('file', element[0].files[0]);
					$http.post(
							'api/uploadCustomDeclarationFile/' + scope.customDeclarationId, fd, {
								transformRequest : angular.identity,
								headers : {
									'Content-Type' : undefined
								},
							}).success(
							function(data) {
								if (angular.isDefined(data.filepath)) {
									scope.refreshFiles({
										file : data
									});
									layer.msg($translate
											.instant('global.uploadSuccess'));
								}
							}).error(function(error) {
						layer.msg($translate.instant('global.uploadFail'));
						console.log("上传文件失败");
					});

				});
			},
		};
	}
})();