(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsShipperDeleteController', OpsShipperDeleteController);

    OpsShipperDeleteController.$inject = ['$rootScope', '$scope', '$uibModalInstance', '$translate', 'entity', 'OpsShipper'];
    
	function OpsShipperDeleteController($rootScope, $scope, $uibModalInstance, $translate, entity, OpsShipper) {

        $scope.opsShipper = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        var onSaveSuccess = function (result) {
            $uibModalInstance.close(true);
            layer.msg($translate.instant('global.delete_success'), {time: 2000});
        };
        setTimeout(function () {   
            if (!$rootScope.id){
                $uibModalInstance.dismiss('cancel');
            }
        }, 500);  

        var onSaveError = function (result) {            
            if (result.status === 403) {
                $uibModalInstance.close(true);
                $rootScope.OPS_ALERT($translate.instant('global.forbidden'), {
                    icon: 0, 
                    title :$translate.instant('global.alert_title'),
                    btn: [$translate.instant('global.ok_answer')]
                })
            }            
        };

        $scope.confirmDelete = function (id) {
            OpsShipper.delete({id: id}, onSaveSuccess, onSaveError);
        };


    }
})();
