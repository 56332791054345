(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsOutDialogController', WmsOutDialogController);

    WmsOutDialogController.$inject = ['$timeout', '$scope', '$translate', '$uibModalInstance', 'entity', 'WmsIn', 'ScanBoxService'];

    function WmsOutDialogController ($timeout, $scope, $translate, $uibModalInstance, entity, WmsIn, ScanBoxService) {
        var vm = this;

        vm.wmsIn = angular.copy(entity);
        vm.clear = clear;
        vm.save = save;
        vm.scanedCode = null;
        vm.scanedLocation = "扫码获取";

        $timeout(function (){
            $("#field_manualBarCode").focus();
            for (var i = vm.wmsIn.cargoList.length - 1; i>=0; i--){
                if (!vm.wmsIn.cargoList[i].allOut){
                    vm.wmsIn.cargoList.splice(i, 1);
                }
                else {
                    vm.wmsIn.cargoList[i].pkgs = 0;
                }
            }
        });


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            WmsIn.saveCount(vm.wmsIn, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        function getLocation () {
            var isCorrect = false;
            angular.forEach(vm.wmsIn.cargoList, function(data){
                if (vm.scanedCode == data.code){
                    vm.scanedLocation = data.location;
                    data.pkgs++;
                    isCorrect = true;
                }
                alert("scan again");
            });
            if (!isCorrect){                
                $("#field_manualBarCode").focus();
            }
        }
        $(document).keydown(function(event){ 
            if(event.keyCode == 13){
                getLocation();
            }
        });

        // function startScan () {            
        //     ScanBoxService.startScan().then(function(resolve){                                
        //     },function(reason){
        //         layer.msg($translate.instant('autopsApp.wmsIn.notSupported'), {
        //           zIndex: 10000
        //         });
                
        //         // 
        //     },function(notify){
        //         // ScanBoxService.endAnalysis();
        //         // Quagga.stop();
        //         ScanBoxService.endScan();
        //         vm.scanedCode = notify.code;
        //         // alert(notify.code);
        //         getLocation();
        //         ScanBoxService.restartScan();
        //         // ScanBoxService.endScan();
                
                
        //         // $timeout(function(){
        //         //     // 
        //         //     Quagga.start();
        //         // },5000);
        //         // $uibModalInstance.close(notify.code);          
        //     });            
        // }


        // $uibModalInstance.opened.then(function(){
        // });

        // $uibModalInstance.closed.then(function(){
        //     ScanBoxService.endScan();
        // });


    }
})();
