(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OrderRestrictionDetailController', OrderRestrictionDetailController);

    OrderRestrictionDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'OrderRestriction'];

    function OrderRestrictionDetailController($scope, $rootScope, $stateParams, previousState, entity, OrderRestriction) {
        var vm = this;

        vm.orderRestriction = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('autopsApp:orderRestrictionUpdate', function(event, result) {
            vm.orderRestriction = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
