(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountPaymentDetailController', AccountPaymentDetailController);

    AccountPaymentDetailController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$uibModal', 'entity', 'DataUtils'];
    
    function AccountPaymentDetailController($scope, $rootScope, $uibModalInstance, $uibModal, entity, DataUtils) {

        $scope.guides = entity;
        if($scope.guides){
            $scope.guides.sort(function(a, b) {
               return a.shipperName<b.shipperName?1:-1;
            });
            angular.forEach($scope.guides, function(data) {
                DataUtils.fillFdLabel(data);
            })
        }

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.viewUnpaidList = function(guide) {
            var entity = {};
            entity.guide = angular.copy(guide);
            entity.isFin = false;
            $rootScope.VENDER_AUDIT_POP(entity);
        }
    }
})();