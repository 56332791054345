
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('InvoiceInfoDetailController', InvoiceInfoDetailController);

    InvoiceInfoDetailController.$inject = ['$scope', '$timeout', '$rootScope', '$uibModalInstance', '$translate',
        'entity', 'OpsVender', 'InvoiceInfo'
    ];

    function InvoiceInfoDetailController($scope, $timeout, $rootScope, $uibModalInstance, $translate,
        entity, OpsVender, InvoiceInfo) {

        $scope.opsClient = entity;

        $timeout(function() {
            if (!$scope.opsClient.invoiceInfos) {
                $scope.opsClient.invoiceInfos = [];
            }
        });

        var onSaveSuccess = function(result) {
            layer.msg($translate.instant('global.save_success'));
            $scope.isSaving = false;
            $scope.opsClient = result; 
        };

        $scope.clear = function() {
            $uibModalInstance.close();
        }
        $scope.save = function() {
            $scope.isSaving = true;
            OpsVender.update($scope.opsClient, onSaveSuccess, $rootScope.ALERT_ERROR);
        };

        $scope.addInvoiceInfo = function() {
            $scope.opsClient.invoiceInfos.push({});
        };

        $scope.delInvoiceInfo = function(invoiceInfo) {
            if (invoiceInfo.id != null) {
                InvoiceInfo.delete({
                    id: invoiceInfo.id
                });
            };
            var index = $scope.opsClient.invoiceInfos.indexOf(invoiceInfo);
            if (index > -1) {
                $scope.opsClient.invoiceInfos.splice(index, 1);
            };
        };

    }
})();