(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ExpressTrackingCtnController',ExpressTrackingCtnController);

    ExpressTrackingCtnController.$inject = ['$uibModalInstance', '$translate', 'entity'];

    function ExpressTrackingCtnController($uibModalInstance, $translate, entity) {
        var vm = this;

        vm.result = entity;
        vm.clear = clear;
        $translate.refresh();
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }

})();
