(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CustomDeclarationCtnsDialogController', CustomDeclarationCtnsDialogController);

    CustomDeclarationCtnsDialogController.$inject = ['$timeout', '$scope', '$translate', '$uibModalInstance', 'entity', 'Guide', 'WinAES'];

    function CustomDeclarationCtnsDialogController ($timeout, $scope, $translate, $uibModalInstance, entity, Guide, WinAES) {
        var vm = this;

        vm.clear = clear;
        vm.shipperName = entity.shipperName;

        $timeout(function (){

        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

    }
})();
