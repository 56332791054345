
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('PushOrdersController',PushOrdersController);

    PushOrdersController.$inject = ['$uibModalInstance', '$rootScope', '$scope', '$translate', '$uibModal', '$timeout',
    'entity', 'ExpressParcel', 'DateUtils'];

    function PushOrdersController($uibModalInstance, $rootScope, $scope, $translate, $uibModal, $timeout,
        entity, ExpressParcel, DateUtils) {
        var vm = this;

        vm.accountBind = angular.copy(entity);
        vm.orders = [];

        vm.clear = clear;
        vm.getOrders = getOrders;
        vm.delOne = delOne;
        vm.pushOrders = pushOrders;
        vm.pushLabel = pushLabel;
        vm.updatePushOrdersStatus = updatePushOrdersStatus;
        vm.delPush = delPush;
        vm.getPushApiRequestBody = getPushApiRequestBody;

        $timeout(function() {            
            layer.msg("初始化授权...", {
                shade: 0.3,
                time: 2000000
            });
            ExpressParcel.checkPushEdiAvailable(vm.accountBind, function(result){
                layer.closeAll();
                vm.accountBind = result;
                layer.msg($translate.instant('global.init_success'));
            }, function(error){
                layer.closeAll();
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                }   
                else {
                    $rootScope.OPS_ALERT($translate.instant('global.init_fail'));
                }
            }); 
        })

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function delOne (index) {
            vm.orders.splice(index, 1);
        }
        function delPush (order) {
            layer.confirm($translate.instant('autopsApp.expressParcel.delPushAlert'), {
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                var pushOrdersDTO = {
                    accountBind: vm.accountBind,
                    expressParcelDTOs: vm.orders
                }
                if(order){
                    pushOrdersDTO.expressParcelDTOs = [order];
                }
                ExpressParcel.delPush(pushOrdersDTO, function(){
                    if(order){
                        order.orderPushed = false;
                    }
                    else {
                        angular.forEach(vm.orders, function(data){
                            data.orderPushed = false;
                        })
                    }
                }, function (error){
                    if(error && error.data && error.data.description){
                        $rootScope.OPS_ALERT(error.data.description);
                    }
                    else {
                        layer.msg($translate.instant('global.requestFail'));
                    }
                });
                layer.close(index);
            }, function() {
            });
        }

        function updatePushOrdersStatus () {
            layer.msg("开始核对订单状态...", {
              shade: 0.3,
              time: 200000000
            });   
            var pushOrdersDTO = {
                accountBind: vm.accountBind,
                expressParcelDTOs: vm.orders
            }
            ExpressParcel.updatePushOrdersStatus(pushOrdersDTO, function(resultList){
                updateExpressParcels(resultList);
                layer.closeAll();  
                layer.msg($translate.instant('global.update_success'));
            }, function (){
                layer.closeAll();  
                layer.msg($translate.instant('global.update_fail'));
            });
        }

        function pushOrders () {
            var pushOrdersDTO = {
                accountBind: vm.accountBind,
                expressParcelDTOs: vm.orders
            }
            if((vm.accountBind.type == 'chukou1' || vm.accountBind.type == 'ecang' || vm.accountBind.type == 'westernpost')
                && (vm.accountBind.thirdPartCode.indexOf(";") != -1 || vm.accountBind.payeeCode.indexOf(";") != -1)){
                $uibModal.open({
                    templateUrl: 'app/entities/sub-tracking/sub-tracking-delete-dialog.html',
                    controller: 'SubTrackingDeleteController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                        entity: [function() {
                            return pushOrdersDTO;
                        }]
                    }
                }).result.then(function(resultList) {
                    updateExpressParcels(resultList);
                }, function() {
                });
                return;
            }
            else {
                layer.load(1, {shade: 0.3});
                ExpressParcel.pushOrders(pushOrdersDTO, function(resultList){
                    layer.closeAll();  
                    updateExpressParcels(resultList);
                }, function (){
                    layer.closeAll();  
                });
            }
        }

        function pushLabel () {
            var pushOrdersDTO = {
                accountBind: vm.accountBind,
                expressParcelDTOs: vm.orders
            }
            layer.load(1, {shade: 0.3});
            ExpressParcel.pushLabel(pushOrdersDTO, function(resultList){
                layer.closeAll();  
                updateExpressParcels(resultList);
            }, function (){
                layer.closeAll();  
            });
        }
        
        function getOrders (bizType) {
            var searchParams = {
                submited : true,
                plRequired: true,
                shipmentType: bizType,
                companyId: $rootScope.account.companyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/express-parcel/express-parcel-detail.html',
                controller: 'ExpressParcelDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(results) {
                vm.orders = results;
                updatePushOrdersStatus();
            }, function() {
            });
        }
        function updateExpressParcels (resultList) {
            angular.forEach(vm.orders, function(data){
                angular.forEach(resultList, function(newOne){
                    if(data.id == newOne.id){
                        angular.copy(newOne, data);
                    }
                })
            })
            $translate.refresh();
        }
        function getPushApiRequestBody(order){
            var pushOrdersDTO = {
                accountBind: vm.accountBind,
                expressParcelDTO: order
            }
            if((vm.accountBind.type == 'chukou1' || vm.accountBind.type == 'ecang' || vm.accountBind.type == 'westernpost')
                && (vm.accountBind.thirdPartCode.indexOf(";") != -1 || vm.accountBind.payeeCode.indexOf(";") != -1)){
                pushOrdersDTO.getRequestBody = true;
                $uibModal.open({
                    templateUrl: 'app/entities/sub-tracking/sub-tracking-delete-dialog.html',
                    controller: 'SubTrackingDeleteController',
                    controllerAs: 'vm',
                    size: 'sm',
                    resolve: {
                        entity: [function() {
                            return pushOrdersDTO;
                        }]
                    }
                }).result.then(function(resultList) {
                }, function() {
                });
                return;
            }
            ExpressParcel.getPushApiRequestBody(pushOrdersDTO, function(result){
                layer.closeAll();
                $rootScope.DOWNLOAD_TXT(result.value, order.jobNum + "_request_body.txt");                
            }, function(error){
                layer.closeAll();
            });
        }



    }
})();