(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('OpsRatesSearch', OpsRatesSearch);

    OpsRatesSearch.$inject = ['$resource'];

    function OpsRatesSearch($resource) {
        return $resource('api/_search/opsRatess/:query', {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
