(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AutoCheckCurrentDialogController', AutoCheckCurrentDialogController);

    AutoCheckCurrentDialogController.$inject = ['$scope', '$rootScope', '$translate', '$uibModalInstance', '$uibModal', '$timeout', 
    'AutoCheck', 'entity', 'DataUtils', 'GuideRates'];

    function AutoCheckCurrentDialogController($scope, $rootScope, $translate, $uibModalInstance, $uibModal, $timeout, 
        AutoCheck, entity, DataUtils, GuideRates){

        var vm = this;
        var autoCheck = entity;

        vm.groupCurrentObj = {};
        vm.clear = clear;
        vm.chooseOne = chooseOne;
        vm.ratesSubmit = ratesSubmit;
        vm.currentConfirm = currentConfirm;
        vm.chooseCur = chooseCur;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.choosedCompanyId = null;
        vm.choosedCur = null;
        vm.guideRates = [];
        vm.totalList = [];
        vm.currencys = [];
        vm.allSubmited = null;

        $timeout(function (){
            getGroupCurrentObj();
        });

        function getGroupCurrentObj (opsCompany) {
            AutoCheck.getGroupCurrentObj(autoCheck, function(result){
                vm.groupCurrentObj = result;
                if(!vm.groupCurrentObj.opsCompanys || vm.groupCurrentObj.opsCompanys.length == 0){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.autoCheck.currentObjNullAlert'));
                    clear();
                    return;
                }
                filterRates();
            });
        }   
        function chooseCur (cur) {
            vm.choosedCur = cur;
            filterRates();
        } 

        function chooseOne (opsCompany) {
            vm.choosedCompanyId = opsCompany.id;
            filterRates();
        }

        function filterRates () {
            if(!vm.choosedCompanyId){
                layer.msg($translate.instant('global.messages.choosePayerFirstly'));
                return;
            }
            if(!vm.choosedCur){
                layer.msg($translate.instant('global.messages.chooseCurFirstly'));
                return;
            }
            //过滤费用
            vm.guideRates = [];
            angular.forEach(vm.groupCurrentObj.guideRates, function(rate) {
                if (rate.companyId == vm.choosedCompanyId 
                    && rate.payCurrency == vm.choosedCur) {
                    vm.guideRates.push(rate);
                }
            })           
            //获取列表
            vm.totalList = [];
            vm.currencys = [];
            vm.allSubmited = true;
            angular.forEach(vm.guideRates, function(rate) {
                if (rate.payCurrency && vm.currencys.indexOf(rate.payCurrency) === -1) {
                    vm.currencys.push(rate.payCurrency);
                }
                if(!rate.locked){
                    vm.allSubmited = false;
                }
            })
            var ttlAmt = 0;
            angular.forEach(vm.currencys, function(currency) {
                ttlAmt = 0;
                angular.forEach(vm.guideRates, function(rate) {
                    if (rate.payCurrency == currency && rate.payAmount) {
                        if (!rate.isPay) {
                            ttlAmt = ttlAmt + rate.payAmount;
                        }
                        else {
                            ttlAmt = ttlAmt - rate.payAmount;
                        }
                    }
                })
                vm.totalList.push({
                    currency: currency,
                    total: DataUtils.round(ttlAmt, 2)
                });
            })
        }


        function ratesSubmit () {
            layer.confirm($translate.instant('autopsApp.autoCheck.submitRatesQuestion'), {
                icon: 3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    doRatesSubmit();
                    layer.close(index);
                }, function() {
            });
        };      
        function doRatesSubmit () {
            var ratesCopy = angular.copy(vm.guideRates);
            for(var i = ratesCopy.length -1; i >= 0; i--){
                if(ratesCopy[i].locked){
                    ratesCopy.splice(i, 1);
                }
            }
            if(!ratesCopy || ratesCopy.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            $scope.isSaving = true;
            GuideRates.ratesSubmit({isDiff: false}, ratesCopy, function(data){
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
                getGroupCurrentObj();
            }, $rootScope.ALERT_ERROR)
        };

        function currentConfirm () {
            if(!vm.allSubmited){
                layer.msg($translate.instant('autopsApp.autoCheck.notSubmitedAlert'));
                return;
            }
            var ratesCopy = angular.copy(vm.guideRates);
            for(var i = ratesCopy.length -1; i >= 0; i--){
                if(!ratesCopy[i].payAmount 
                    || ratesCopy[i].actual >= ratesCopy[i].payAmount 
                    || ratesCopy[i].isHidden
                    || !ratesCopy[i].locked){
                    ratesCopy.splice(i, 1);
                }
            }
            if(!ratesCopy || ratesCopy.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }

            //获取账单金额
            var amtTtl = 0;
            angular.forEach(ratesCopy, function(rate){
                if(rate.isPay){
                    amtTtl -= rate.payAmount;
                    if(rate.actual){
                        amtTtl += rate.actual;
                    }
                }
                else {
                    amtTtl += rate.payAmount;
                    if(rate.actual){
                        amtTtl -= rate.actual;
                    }
                }
            })
            $rootScope.loadCurrencys();
            if(amtTtl < 0){
                $uibModal.open({
                    templateUrl: 'app/entities/accountant/accountInvoice/createDn-dialog.html',
                    controller: 'CreateDnDialogController',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                guideRates: ratesCopy,
                                branchCompanyId: vm.choosedCompanyId,
                                currency: vm.choosedCur,
                                dnOnly: true,
                                advancedFunds: true,
                                invoiceAmount: -DataUtils.round(amtTtl, 2),
                                invoiceType:"noInvoice",
                                accountDate: new Date()
                            };
                        },
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('accountInvoice'); 
                            $translatePartialLoader.addPart('autoCheck'); 
                            return $translate.refresh();
                        }],
                    }
                }).result.then(function(result) {
                    getGroupCurrentObj();
                }, function() {
                })
            }
            else {
                $uibModal.open({
                    templateUrl: 'app/entities/accountant/accountPayment/createCn-dialog.html',
                    controller: 'CreateCnDialogController',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                guideRates: ratesCopy,
                                currency: vm.choosedCur,
                                branchCompanyId: vm.choosedCompanyId,
                                invoiceAmount: DataUtils.round(amtTtl, 2),
                                dnOnly:true,
                                noInvoice: true,
                                advancedFunds: true,
                                invoiceType:"withoutInvoice",
                                accountDate: new Date()
                            };
                        },
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('accountPayment'); 
                            $translatePartialLoader.addPart('autoCheck'); 
                            return $translate.refresh();
                        }],
                    }
                }).result.then(function(result) {
                    getGroupCurrentObj();
                }, function() {
                })
            }            
        }

    }
})();