(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountCashierFinController', AccountCashierFinController);

    AccountCashierFinController.$inject = ['$rootScope', '$scope', '$translate', '$uibModal', '$timeout', 
    'Principal', 'JhiLanguageService', 'AccountCashier', 'AlertService', 'AccountTitle', 'DateUtils', 'DataUtils', 'uiGridConstants', 'GridState'];

    function AccountCashierFinController($rootScope, $scope, $translate, $uibModal, $timeout,
        Principal, JhiLanguageService, AccountCashier, AlertService, AccountTitle, DateUtils, DataUtils, uiGridConstants, GridState) {

        var lang = "";
        JhiLanguageService.getCurrent().then(function(language) {
            lang = language;
        });

        var vm = this;
        vm.requestDTO = {};
        vm.cashLedgers = [];
        $scope.ledgers = [];

        vm.onLedgerChoosed = onLedgerChoosed;
        vm.getContents = getContents;
        vm.generateReport = generateReport;
        // vm.loadAllLedgers = loadAllLedgers;
        vm.createAccountVouchers = createAccountVouchers;

        $scope.bindVoucher = function (accountCashier) {
            layer.prompt({
              formType: 0,
              value: null,
              title: $translate.instant('autopsApp.accountCashier.voucherNum'),
              area: ['280px', '160px'] 
            }, function(value, index, elem){
                value = value -0;
                AccountCashier.bindVoucher({
                    accountCashierId: accountCashier.id,
                    voucherNum: value
                }, function(result){
                    layer.msg($translate.instant('global.save_success'));
                    angular.copy(result, accountCashier);
                }, function(result){
                    if(result.status == 404){
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.accountCashier.voucherNotExist'), {icon:0});
                    }
                    else {
                        layer.msg($translate.instant('global.refreshAlert'));
                    }                    
                })  
                layer.close(index);
            });
        }
        $scope.writeOff = function(accountCashier) {
            if(accountCashier.companyId == 152){
                oldWriteOff(accountCashier);
            }
            else {
                newWriteOff(accountCashier);
            }
        }

        function newWriteOff(accountCashier) {
            var entty = {byDn: true};
            entty.accountCashier = accountCashier;
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountant/fast-write-off.html',
                controller: 'FastWriteOffController',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entty;
                    }],                    
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountInvoice');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('stats');
                        $translatePartialLoader.addPart('autoCheck');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {
                getContents();
            }, function() {
                getContents();
            });
        }

        function oldWriteOff(accountCashier) {
            var entty = {};
            entty.accountCashier = accountCashier;
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountant/com-write-off.html',
                controller: 'ComWriteOffController',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: [function() {
                        return entty;
                    }],                    
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountInvoice');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {
                getContents();
            }, function() {
                getContents();
            });
        }

        function loadLedger () {      
            AccountTitle.getAll({
                type: "part"
            }, function(result) {
                $scope.ledgers = result;
                $scope.ledgers = DataUtils.generateShowLedger($scope.ledgers, true, lang);
                if ($scope.ledgers.length == 0 && $rootScope.HAVEAUTH('FMIS')) {
                    layer.msg($translate.instant('autopsApp.accountCashier.noSubledgerAvailable'));
                }
                angular.forEach($scope.ledgers, function(data) {
                    if (data.code.substring(0,4) == "1001" || data.code.substring(0,4) == "1002"){
                        vm.cashLedgers.push(data);
                    }                
                })
            });
        }
        $scope.initLedgers = function () {
            // $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            // $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
            // var sdfsdf = angular.copy($scope.ledgers);
            // $scope.ledgers = [];
            // $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.ALL );
            // $scope.ledgers = DataUtils.generateShowLedger(sdfsdf, true, lang);
            // $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.ALL );

            
            // loadLedger();
            // $translate.refresh();
        }

        // function loadAllLedgers () {
        //     AccountTitle.getAll({
        //         type: "all"
        //     }, function(result) {
        //         $scope.ledgers = result;
        //         $scope.ledgers = DataUtils.generateShowLedger($scope.ledgers, true, lang);
        //         if ($scope.ledgers.length == 0 && $rootScope.HAVEAUTH('FMIS')) {
        //             layer.msg($translate.instant('autopsApp.accountCashier.noSubledgerAvailable'));
        //         }
        //         angular.forEach($scope.ledgers, function(data) {
        //             if (data.code.substring(0,4) == "1001" || data.code.substring(0,4) == "1002"){
        //                 vm.cashLedgers.push(data);
        //             }                
        //         })
        //     });
        // }

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                loadLedger();
            });
        }
        else {
            loadLedger();
        }

        $scope.deleteOne = function (accountCashier) {
            var index = $scope.gridOptions.data.indexOf(accountCashier);
            if (index > -1) {
                $scope.gridOptions.data.splice(index, 1);
            };
        }

        $scope.setFdValue= function(fd, val) {
            vm.requestDTO[fd] = val;
        }

        function getContents() {
            vm.isSaving = true;
            var accountCasherFilter = angular.copy(vm.requestDTO);
            accountCasherFilter.accountName = null;
            accountCasherFilter.companyId = $rootScope.account.companyId;
            $scope.gridOptions.data = [];
            AccountCashier.advanceSearch(accountCasherFilter, function(result) {
                vm.isSaving = false;
                $scope.gridOptions.data = result;
                $timeout(function() {
                    $("#datefrom_field").focus();
                });
                resize();
            }, $rootScope.ALERT_ERROR);
        }

        function onLedgerChoosed(data) {
            vm.requestDTO.accountId = data.id;
        }

        function generateReport () {
            if (!$scope.gridOptions.data || $scope.gridOptions.data.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            AccountCashier.generateReport({
                fileType: 2
            }, $scope.gridOptions.data, function(result) {
                var file = new Blob([result.data], {
                    type: 'application/vnd.ms-excel'
                });
                var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
                var aTag = document.createElement("a");
                aTag.download = "cashJournal" + ".xlsx";
                aTag.href = blobURL;
                aTag.click();
            }, function(error) {
                layer.msg($translate.instant('global.requestFail'));
            });
        }

        $scope.createVoucher = function(accountCashier) {
            if(accountCashier.dnNums || accountCashier.isLocked || accountCashier.voucherId){
                return;
            }            
            AccountCashier.getOneAccountVoucher(accountCashier, function(result) {
                vm.isSaving = false;
                var vouchersEntity = {};
                vouchersEntity.accountVoucher = result;
                $uibModal.open({
                    templateUrl: 'app/entities/accountant/accountVoucher/accountVoucher-dialog.html',
                    controller: 'AccountVoucherDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxl',
                    resolve: {
                        entity: [function() {
                            return vouchersEntity;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('accountVoucher');
                            $translatePartialLoader.addPart('writeOff');      
                            $translatePartialLoader.addPart('accountInvoice');                    
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function(results) {
                    getContents();
                }, function() {
                    getContents();
                });            
            }, function(response) {
                vm.isSaving = false;
                layer.msg($translate.instant('autopsApp.accountCashier.createVouchersFailed'));
            });  
        }

        function createAccountVouchers () {
            var refTemp = null;
            angular.forEach($scope.gridOptions.data, function (data) {
                if (data.dnNums && data.diffAmt && !data.oppositeName && !data.voucherId){
                    refTemp = data.transactionRef;
                }
                if(!data.oppositeName){
                    data.oppositeId = null;
                    data.oppositeName = null;
                    data.oppositeCode = null;
                }
            })
            if (refTemp){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.accountCashier.diffAccountNullAlert', {ref: refTemp}));
                return;
            }
            vm.isSaving = true;
            AccountCashier.createAccountVouchers($scope.gridOptions.data, function(result) {
                vm.isSaving = false;
                getContents();
                if(!result || result.length ==0){
                    layer.msg($translate.instant('autopsApp.accountCashier.noVouchersCreated'));
                }
                else {
                    $rootScope.VIEWVOUCHER(result[0].id, result);
                }                
            }, function(response) {
                vm.isSaving = false;
                getContents();
                layer.msg($translate.instant('autopsApp.accountCashier.createVouchersFailed'));
            });  
        }

        $scope.onOppositeLedgerChoosed = function (data, accountCasher) {
            if((data.code.substring(0,4) == "2205" || data.code.substring(0,4) == "1123") &&
                (data.code.length == 4 || (data.currency == null && accountCasher.originalCurrency != $rootScope.account.baseCurrency) || (data.currency != null && data.currency != accountCasher.originalCurrency))){
                layer.msg($translate.instant('autopsApp.accountCashier.advanceAccountCurrencyAlert'));
                accountCasher.oppositeName = null;
                return;
            }
            if((data.code.substring(0,4) == "2205" && (accountCasher.originalAmt < 0 || accountCasher.diffAmt < 0))
                || (data.code.substring(0,4) == "1123" && (accountCasher.originalAmt > 0 || accountCasher.diffAmt < 0))){
                layer.msg($translate.instant('autopsApp.accountCashier.advanceAccountAmtAlert'));
                accountCasher.oppositeName = null;
                return;
            }

            if(!data.currency){
                data.currency = $rootScope.account.baseCurrency;
            }
            if (data.id && data.currency != accountCasher.originalCurrency){
                layer.confirm($translate.instant('autopsApp.accountCashier.currencyNotMatchAlert'), {
                    btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
                }, function(index) {
                    accountCasher.oppositeId = data.id;
                    accountCasher.oppositeName = data.showLedger;
                    accountCasher.oppositeCode = data.code;
                    saveCashier(accountCasher);
                    layer.close(index);
                }, function() {
                    accountCasher.oppositeName = null;
                    $translate.refresh();
                    return;
                });
            }
            else {
                accountCasher.oppositeId = data.id;
                accountCasher.oppositeName = data.showLedger;
                accountCasher.oppositeCode = data.code;
                saveCashier(accountCasher);
            }
        }

        $scope.clearOposite = function (accountCashier) {            
            if(!accountCashier.oppositeName && accountCashier.oppositeCode){
                vm.isSaving = true;
                accountCashier.oppositeId = null;
                accountCashier.oppositeName = null;
                accountCashier.oppositeCode = null;
                AccountCashier.update(accountCashier, function(result){
                    accountCashier = result;
                    vm.isSaving = false;
                    layer.msg($translate.instant('global.save_success'));
                }, function(result){
                    vm.isSaving = false;
                    $rootScope.ALERT_ERROR(result);
                });
            }
        }

        function saveCashier (accountCashier) {
            vm.isSaving = true;
            AccountCashier.update(accountCashier, function(result){
                accountCashier = result;
                vm.isSaving = false;
                layer.msg($translate.instant('global.save_success'));
            }, function(result){
                vm.isSaving = false;
                $rootScope.ALERT_ERROR(result);
            });
        }

        window.onresize = function(){
            resize();
        }

        function resize() {
            if(!$("#gridId") || !$("#gridId").offset()){
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 180;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 600){
                tabHeight = 600;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: false,      

            enableGridMenu : true,//表格 菜单  
            showGridFooter: false,//表格的footer  
            showColumnFooter: false,//每列footer

            enableFiltering: true,//高级过滤            
            useExternalFiltering: false,

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  
            exporterMenuCsv : false,  
            exporterMenuPdf : false, 
            exporterMenuLabel : "Export",  
            exporterExcelFilename: ($rootScope.ISTMS?$translate.instant('autopsApp.expressParcel.home.tmstitle'):$translate.instant('autopsApp.expressParcel.home.title')) + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],         

            paginationPageSizes: [20, 35, 50, 100, 200],
            paginationPageSize: 20,
            useExternalPagination: true,

            minimumColumnSize: 70,
            columnDefs: [
                { field: 'id', 
                    width: 45,
                    displayName: "", 
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<div class="ml-4"><button type="button" ng-click="grid.appScope.deleteOne(row.entity)"class="btn btn-danger btn-grid"><span class="glyphicon glyphicon-remove-circle"></span></button></div>'},
                { field: 'transactionRef', 
                    displayName: $translate.instant('autopsApp.accountCashier.transactionRef'), 
                    minWidth:120},
                { field: 'isLocked', 
                    displayName: $translate.instant('autopsApp.accountCashier.isLocked'),
                    minWidth:80, 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span ng-class="row.entity.isLocked?\'label-primary\':\' label-danger\'"translate="{{row.entity.isLocked?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'dnNums', 
                    displayName: $translate.instant('autopsApp.accountCashier.dnNums'), 
                    cellTemplate: '<input type="text" class="grid-input" ng-model="row.entity.dnNums" ng-dblclick="grid.appScope.writeOff(row.entity)" readonly ng-disabled="row.entity.voucherId || row.entity.isLocked"  placeholder="{{\'autopsApp.accountCashier.dbClickTips\' | translate}}"/>',
                    minWidth:150},
                { field: 'description', 
                    displayName: $translate.instant('autopsApp.accountCashier.description'), 
                    minWidth:150},
                { field: 'originalCurrency', 
                    displayName: $translate.instant('autopsApp.accountCashier.originalCurrency'), 
                    minWidth: 60},
                { field: 'originalAmt', 
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],          
                    cellTemplate: '<strong class="ml-5 text-primary">{{row.entity.originalAmt}}</strong>',
                    displayName: $translate.instant('autopsApp.accountCashier.originalAmt'), 
                    minWidth:100},
                { field: 'balance', 
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],          
                    cellTemplate: '<strong class="ml-5 text-success">{{row.entity.balance}}</strong>',
                    displayName: $translate.instant('autopsApp.accountCashier.balance'), 
                    minWidth:100},
                { field: 'baseCurrency', 
                    displayName: $translate.instant('autopsApp.accountCashier.baseCurrency'), 
                    minWidth: 60},
                { field: 'invoiceAmt', 
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],          
                    displayName: $translate.instant('autopsApp.accountCashier.invoiceAmt'), 
                    minWidth:80},
                { field: 'diffAmt', 
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],          
                    displayName: $translate.instant('autopsApp.accountCashier.diffAmt'), 
                    minWidth:80},
                { field: 'oppositeName', 
                    displayName: $translate.instant('autopsApp.accountCashier.oppositeName'), 
                    cellTemplate: '<input dropdown-append-to-body type="text"class="grid-input"ng-model="row.entity.oppositeName"uib-typeahead="ledger.showLedger as ledger for ledger in grid.appScope.ledgers | filter: $viewValue | limitTo:100"typeahead-template-url="ledgerTemplate.html"typeahead-on-select="grid.appScope.onOppositeLedgerChoosed($item, row.entity)"typeahead-editable="true"typeahead-min-length="1"ng-disabled="row.entity.voucherId"ng-dblclick="grid.appScope.createVoucher(row.entity)"ng-blur="grid.appScope.clearOposite(row.entity)"ng-focus="grid.appScope.initLedgers()"/>',
                    minWidth:150},
                { field: 'voucherNum', 
                    displayName: $translate.instant('autopsApp.accountCashier.voucherNum'), 
                    minWidth:120,
                    cellTemplate: '<a class="ml-5" href="" ng-click="grid.appScope.bindVoucher(row.entity)" ng-hide="row.entity.voucherNum" translate="autopsApp.accountCashier.bindVoucher" class="label label-primary">bindVoucher</a><a class="ml-5" href="" ng-click="grid.appScope.VIEWVOUCHER(row.entity.voucherId)" ng-if="row.entity.voucherNum">{{row.entity.voucherNum}}</a>'},
                { field: 'payerName', 
                    displayName: $translate.instant('autopsApp.accountCashier.payerName'), 
                    minWidth:180},
                { field: 'transactionDate', 
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.accountCashier.transactionDate'), 
                    minWidth:120,
                    cellTemplate: '<span class="grid-text">{{row.entity.transactionDate | date:\'mediumDate\'}}</span>'},
                { field: 'accountCode', 
                    displayName: $translate.instant('autopsApp.accountCashier.accountCode'), 
                    minWidth:100},
                { field: 'accountName', 
                    displayName: $translate.instant('autopsApp.accountCashier.accountName'), 
                    minWidth:150},
                { field: 'invoiceNums', 
                    displayName: $translate.instant('autopsApp.accountCashier.invoiceNums'), 
                    cellTemplate: '<span class="grid-text" ng-dblclick="grid.appScope.COPYALL(row.entity.invoiceNums)">{{row.entity.invoiceNums}}</span>',
                    minWidth:150},
                { field: 'blNums', 
                    displayName: $translate.instant('autopsApp.accountCashier.blNums'), 
                    cellTemplate: '<span class="grid-text" ng-dblclick="grid.appScope.COPYALL(row.entity.blNums)">{{row.entity.blNums}}</span>',
                    minWidth:150},
                { field: 'jobNums', 
                    displayName: $translate.instant('autopsApp.accountCashier.jobNums'), 
                    cellTemplate: '<span class="grid-text" ng-dblclick="grid.appScope.COPYALL(row.entity.jobNums)">{{row.entity.jobNums}}</span>',
                    minWidth:150},
                { field: 'exchangeRate', 
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],          
                    displayName: $translate.instant('autopsApp.accountCashier.exchangeRate'), 
                    minWidth:80},
                { field: 'casherName', 
                    displayName: $translate.instant('autopsApp.accountCashier.casherName'), 
                    minWidth:120},
                { field: 'remarks', 
                    displayName: $translate.instant('autopsApp.accountCashier.remarks'), 
                    cellTemplate: '<span class="grid-text" ng-dblclick="grid.appScope.COPYALL(row.entity.remarks)">{{row.entity.remarks}}</span>',
                    minWidth:180}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 113;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }     
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);                   
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
            }
        }
        var gridCopy;
        var pageLoaded = false;
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            for(var i = $scope.gridOptions.columnDefs.length - 1; i >= 0; i--){
                if($scope.gridOptions.columnDefs[i].field == 'company' && $rootScope.account.companyId != 1){
                    $scope.gridOptions.columnDefs.splice(i, 1);
                }
                else if($scope.gridOptions.columnDefs[i].field == 'knowledgeBase' && $rootScope.account.companyId != 1){
                    $scope.gridOptions.columnDefs.splice(i, 1);
                }
            }
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 113
            }, function(result){
                gridStateObj = result;
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    pageLoaded = true;
                    return;
                }   
                if(gridStateObj.gridState){
                    $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                }                
                pageLoaded = true;
                if(gridCopy){
                    var filterRequired = false;
                    angular.forEach(gridCopy.columns, function(column){                    
                        angular.forEach(column.filters, function(cf){
                            if(cf.term || cf.term == false){
                                filterRequired = true;                         
                            }
                        })
                    })
                    if(filterRequired){
                        layer.msg($translate.instant('global.filterDetectedAlert'));
                        loadAll();
                    }
                }
            })
        });
    }

})();