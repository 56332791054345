(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsGoodsDeleteController',WmsGoodsDeleteController);

    WmsGoodsDeleteController.$inject = ['$uibModalInstance', '$scope', '$timeout', 'entity', 'WmsGoods', 'WmsWarehouse'];

    function WmsGoodsDeleteController($uibModalInstance, $scope, $timeout, entity, WmsGoods, WmsWarehouse) {
        var vm = this;

        vm.orderHandleFees = entity;
        vm.clear = clear;
        vm.confirm = confirm;
        vm.onWmsSelected = onWmsSelected;

        function onWmsSelected (ohf, wms) {
            ohf.id = wms.id;
        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $timeout(function (){
            if(!vm.orderHandleFees){
                vm.orderHandleFees = [{}];
            }
            getWarehouses();
        });
        
        function confirm () {
            $uibModalInstance.close(vm.orderHandleFees);
        }
        $scope.addOne = function() {
            vm.orderHandleFees.splice(0, 0, {});
        }

        $scope.delOne = function (index) {
            vm.orderHandleFees.splice(index, 1);
        }

        vm.wmswarehouses = [];
        function getWarehouses () {
            WmsWarehouse.getAll({
                includeOwn: true,
                includeVirtual: true,
                includeGroup: true
                }, function (data) {
                vm.wmswarehouses = data;
            });
        }


    }
})();
