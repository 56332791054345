(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('CrmQuotationSearch', CrmQuotationSearch);

    CrmQuotationSearch.$inject = ['$resource'];

    function CrmQuotationSearch($resource) {
        var resourceUrl =  'api/_search/crm-quotations/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
