
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AmsEdiDataDialogController', AmsEdiDataDialogController);

    AmsEdiDataDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$translate', '$uibModalInstance', '$http',
    'OpsCarrier', 'AmsEdiData', 'OpsPorts', 'entity', 'BookingCtns', 'Dictionary', 
    'Guide', 'GuideCtns', 'DataUtils'];

    function AmsEdiDataDialogController($timeout, $scope, $rootScope, $translate, $uibModalInstance, $http,
        OpsCarrier, AmsEdiData, OpsPorts, entity, BookingCtns, Dictionary, 
       Guide, GuideCtns, DataUtils) {
        var vm = this;
        $scope.isSaving = false;
        $scope.manifestType = entity.manifestType;

        $scope.guide = {};
        $scope.ediData = {};
        $scope.amsCarries = Dictionary.amsCarries;
        $scope.countryCodes = Dictionary.countryCodes;
        $scope.usStateList = Dictionary.usStateList;
        $scope.cnStateList = Dictionary.cnStateList;
        $scope.shipAgentList = Dictionary.shipAgentList;
        $scope.elineManifestCarrierList = Dictionary.elineManifestCarrierList;
        $scope.elinePackageUnitList = Dictionary.elinePackageUnitList;
        $scope.elineCtnTypeList = Dictionary.elineCtnTypeList;
        $scope.stateMap = {};
        var guideCopy;
        var ediDataCopy;
        var refreshRequired = false;
        $scope.elineShowStatusList = ["CCAM_NEW_WAITING", "CCAM_NEW_SUCCESS", "CCAM_NEW_FAILURE", "CCAM_UPDATE_WAITING", "CCAM_UPDATE_SUCCESS", "CCAM_UPDATE_FAILURE", "CCAM_DELETE_WAITING", "CCAM_DELETE_SUCCESS", "CCAM_DELETE_FAILURE"];

        vm.isf05 = false;
        function checkIsf05(){
            vm.isf05 = false;
            if($scope.manifestType != "ISF" || !$scope.guide.booking || !$scope.guide.booking.podId){
                return;
            }
            if($scope.guide.booking.podId.indexOf("US") !== 0){
                vm.isf05 = true;
            }
            if($scope.guide.booking.destId.indexOf("CA") === 0){
                vm.isf05 = true;
            }
        }

        $timeout(function (){
            if(entity.guideId){
                AmsEdiData.getByGuideId({
                    guideId: entity.guideId,
                    manifestType: entity.manifestType
                }, onGetSuccess);
            }
            angular.forEach($scope.usStateList, function(data){
                $scope.stateMap[data.code] = data.name;
            })
            angular.forEach($scope.cnStateList, function(data){
                $scope.stateMap[data.code] = data.name;
            })            
        });

        $scope.clearData = function() {
            $scope.ediData = {};
            $scope.guide = {};
        }

        $scope.getByJobNum = function() {
            AmsEdiData.getByJobNum({
                companyId: $rootScope.account.companyId,
                jobNum: $scope.guide.jobNum,
                manifestType: entity.manifestType
            }, onGetSuccess, function(error){
                if(error.status == 404){
                    $rootScope.OPS_ALERT($translate.instant("global.numNotExist"));
                }
                else {
                    layer.msg($translate.instant("global.loadFailed")); 
                }
            })
        }

        $scope.getAmsStatus = function() {
            if(!$scope.ediData.declareTime){                
                return;
            }
            AmsEdiData.getAmsStatus($scope.ediData, function(result){
                if(result.stsJson){
                    $scope.ediData.stsJson = result.stsJson;
                    $scope.ediData.stsObj = angular.fromJson(result.stsJson);
                }
            }, function(error){
            })
        }
        $scope.fillZipCodeAndState = function() {

            layer.msg("Trying to update Post-Code/ Sub-Division-Code/ Region...", {
                shade: 0.3,
                time: 2000000
            });
            AmsEdiData.fillZipCodeAndState($scope.ediData, function(result){
                $scope.ediData = result;
                if(result.stsJson){
                    $scope.ediData.stsJson = result.stsJson;
                    $scope.ediData.stsObj = angular.fromJson(result.stsJson);
                }
                layer.closeAll();
                $rootScope.OPS_ALERT($translate.instant("autopsApp.amsEdiData.fillZipCodeAndStateAlert"))
                $translate.refresh();
            }, function(error){
                layer.closeAll();
            })
        }


        var onGetSuccess = function(result) {
            $scope.ediData = result.amsEdiData;
            $scope.guide = result.guide;
            if (!$scope.ediData){
                $scope.ediData = {};
            }
            if (!$scope.guide.cargoType){
                $scope.guide.cargoType = "generalCargo";
            }
            if (!$scope.guide.taxIdType){
                $scope.guide.taxIdType = "ANI";
            }
            if (!$scope.ediData.carrierCode && $scope.guide.booking.carrierCode){
                angular.forEach($scope.amsCarries, function(data){
                    if($scope.guide.booking.carrierCode == data.code){
                        $scope.ediData.carrierCode = data.code;
                        $scope.ediData.carrier = data.name;
                    }
                })
            }
            
            if (!$scope.ediData.carrier && $scope.ediData.carrierCode){
                angular.forEach($scope.amsCarries, function(data){
                    if($scope.ediData.carrierCode == data.code){
                        $scope.ediData.carrier = data.name;
                    }
                })
            }

            if($scope.manifestType == "AFR"){
                if($scope.guide.booking.hsCode && $scope.guide.booking.hsCode.length > 6){
                    $scope.guide.booking.hsCode = $scope.guide.booking.hsCode.replace(/[^\d]/g,'');
                    if($scope.guide.booking.hsCode.length > 6){
                        $scope.guide.booking.hsCode = $scope.guide.booking.hsCode.substring(0, 6);
                    }
                }
            }

            $scope.generateMark();
            $scope.generateGoodsDiscription();
            afterGetEdiData();
            guideCopy = angular.copy($scope.guide);
            ediDataCopy = angular.copy($scope.ediData);            
            updateGwVol();
            $scope.uppperCase();
            if (!angular.equals(guideCopy, $scope.guide)) {
                layer.msg("Data updated according EDI criterion, pls check carefully before save.");
            };
            $scope.getAmsStatus();
            $scope.getVesselInfo();
            if($scope.manifestType == "ISF"){
                if(!$scope.ediData.id){
                    $scope.fillZipCodeAndState();
                }
                checkIsf05();
            }
        }

        $scope.getVesselInfo = function() {
            if($scope.manifestType == "ISF" || $scope.manifestType == "AFR" || !$scope.guide.vsl){
                return;
            }
            if(($scope.manifestType == "ACI" || $scope.manifestType == "AMS") && $scope.ediData.imo){
                return;
            }
            if($scope.manifestType == "AFR" && $scope.ediData.callSign){
                return;
            }
            layer.msg("Trying to get IMO/CallSign by vessel name...", {
                shade: 0.3,
                time: 2000000
            });
            AmsEdiData.getVesselInfo({
                vesselName: $scope.guide.vsl
            }, $scope.ediData, function(results){
                layer.closeAll();
                if(results && results.length > 1){
                    var imoStr = "";
                    angular.forEach(results, function(vsl){
                        if(vsl.imo){
                            imoStr = imoStr + vsl.imo + " ";
                        }
                    })
                    $rootScope.OPS_ALERT("Got " + results.length + " records: " + imoStr);
                    return;
                }
                if(results && results.length == 1 && results[0].imo){
                    $scope.ediData.imo = results[0].imo;
                }
                if($scope.manifestType == "ACI" || $scope.manifestType == "AMS"){
                    if(!$scope.ediData.imo){
                        layer.msg("Got IMO failed");
                    }
                    else {
                        layer.msg("Matched IMO: " + $scope.ediData.imo);
                    }                    
                }
                else if($scope.manifestType == "AFR"){
                    if(!$scope.ediData.callSign){
                        layer.msg("Got CallSign failed");
                    }
                    else {
                        layer.msg("Matched CallSign: " + $scope.ediData.callSign);
                    }
                }
            }, function(error){
                layer.closeAll();
            })
        }
        
        $scope.generateMark = function() {
            if ($scope.guide.mark && !$scope.ediData.mark) {
                $scope.ediData.mark = angular.uppercase($scope.guide.mark);
            }
            if ($scope.guide.booking.mark && !$scope.ediData.mark) {
                $scope.ediData.mark = angular.uppercase($scope.guide.booking.mark);
            }
            var maxLen = $scope.manifestType == "AFR"?140:450;
            if($scope.ediData.mark && $scope.ediData.mark.length > maxLen){
                $scope.ediData.mark = $scope.ediData.mark.substring(0, maxLen);
            }
        }
        $scope.generateGoodsDiscription = function() {
            if ($scope.guide.goodsDiscription && !$scope.ediData.goodsDiscription) {
                $scope.ediData.goodsDiscription = angular.uppercase($scope.guide.goodsDiscription);
            }
            if ($scope.guide.booking.goodsDiscription && !$scope.ediData.goodsDiscription) {
                $scope.ediData.goodsDiscription = angular.uppercase($scope.guide.booking.goodsDiscription);
            }
            var maxLen = $scope.manifestType == "AFR"?350:450;
            if($scope.ediData.goodsDiscription && $scope.ediData.goodsDiscription.length > maxLen){
                $scope.ediData.goodsDiscription = $scope.ediData.goodsDiscription.substring(0, maxLen);
            }
        }

        $scope.uppperCase = function() {           
            $scope.ediData.shAddress = angular.uppercase(DataUtils.checkStrLen($scope.ediData.shAddress, 50));
            $scope.ediData.cnAddress = angular.uppercase(DataUtils.checkStrLen($scope.ediData.cnAddress, 50));
        };

        $scope.onCarrierSelected = function(carrier) {       
            $scope.ediData.carrierCode = carrier.code;
            if(carrier.code && $scope.ediData.mblNum && $scope.guide.mblNum
                && $scope.ediData.mblNum.indexOf(carrier.code) !== 0){
                if($scope.guide.mblNum.indexOf(carrier.code) !== 0){
                    $scope.ediData.mblNum = carrier.code + $scope.guide.mblNum;
                }
                else {
                    $scope.ediData.mblNum = $scope.guide.mblNum;
                }
            }
        }

        $scope.clear = function () {
            $uibModalInstance.close(refreshRequired);
        }
        
        $scope.sendAms = function(actionType) {
            if (!angular.equals(guideCopy, $scope.guide) || !$scope.ediData.id) {
                layer.msg($translate.instant('global.saveAlert'));
                return;
            };     
            $scope.isSaving = true;
            var guideEdi = {};
            guideEdi.guide = angular.copy($scope.guide);
            guideEdi.amsEdiData = $scope.ediData;
            AmsEdiData.sendAms({actionCode: actionType, manifestType: $scope.manifestType},guideEdi, onSendSuccess, onSendError);     
        }

        var onSendSuccess = function(result) {
            $scope.isSaving = false;
            $uibModalInstance.close(refreshRequired);
            layer.msg($translate.instant('global.send_success'));
        };
        var onSendError = function(error) {
            $scope.isSaving = false;
            $scope.ediData.amsStatus = ediDataCopy.amsStatus;
            $scope.ediData.isfStatus = ediDataCopy.isfStatus;

            if(error && error.data && error.data.description){
                $rootScope.OPS_ALERT(error.data.description);
            }
            else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        };

        $scope.save = function(booking) {
            $scope.isSaving = true;
            $scope.ediData.guideId = $scope.guide.id;
            if(!$scope.ediData.carrier){
                $scope.ediData.carrierCode = null;
            }
            if (!angular.equals($scope.ediData, ediDataCopy)){
                if ($scope.ediData.id !== null) {
                    AmsEdiData.update($scope.ediData, onSaveEdiSuccess, onSaveError);
                } else {
                    AmsEdiData.save($scope.ediData, onSaveEdiSuccess, onSaveError);
                }
            }
            else {
                if (angular.equals($scope.guide, guideCopy)) {
                    $scope.isSaving = false;
                    layer.msg($translate.instant('global.messages.submitted'));
                    return;
                };
                Guide.update($scope.guide, onSaveGuideSuccess, onSaveError); 
            }
        };

        var afterGetEdiData = function(){
            if($scope.ediData.stsJson){
                $scope.ediData.stsObj = angular.fromJson($scope.ediData.stsJson);
            }
            ediDataCopy = angular.copy($scope.ediData); 
        }

        var onSaveEdiSuccess = function(result) {
            $scope.ediData = result;
            afterGetEdiData();
            if (angular.equals($scope.guide, guideCopy)) {
                $scope.isSaving = false;
                layer.msg($translate.instant('global.messages.submitted'));
                return;
            };
            Guide.update($scope.guide, onSaveGuideSuccess, onSaveError);  
        };

        var onSaveGuideSuccess = function(result) {
            refreshRequired = true;
            $scope.isSaving = false;
            $scope.guide = result;     
            guideCopy = angular.copy($scope.guide);       
            layer.msg($translate.instant('global.messages.submitted'));
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
            if (result.status == 403){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.lockedAlert'));
            }
            else {
                $rootScope.ALERT_ERROR(result);
            }
        };

        $scope._selected = function($item, which) {
            switch (which) {
                case "porChoose":
                    $scope.guide.booking.porId = $item.code;
                    break;
                case "polChoose":
                    $scope.guide.booking.polId = $item.code;
                    $scope.ediData.shCountryCode = $item.code.substring(0, 2);
                    break;
                case "podChoose":
                    $scope.guide.booking.podId = $item.code;
                    $scope.ediData.cnCountryCode = $item.code.substring(0, 2);
                    break;
                case "destChoose":
                    $scope.guide.booking.destId = $item.code;
                    break;
                case "deliverytoChoose":
                    $scope.guide.booking.deliverytoId = $item.code;
                    break;
                case "consolidatorCity":
                    $scope.ediData.consolidatorPostalCode = $item.code;
                    break;
                case "carrier":
                    $scope.ediData.carrierCode = $item.value;
                    break;
                case "packageUnit":
                    $scope.ediData.packageUnitCode = $item.value;
                    break;
            }
        }


        $scope.updatePkgsAndType = function() {
            if ($scope.guide.pkgNum && $scope.guide.booking.pkgType) {
                $scope.guide.booking.pkgsAndType = $scope.guide.pkgNum.toString() + " " + $scope.guide.booking.pkgType;
                $scope.guide.pkgsAndType = $scope.guide.pkgNum.toString() + " " + $scope.guide.booking.pkgType;
            }
        }
        var updateGwVol = function() {
            if ($scope.guide.gw && $scope.guide.vol) {
                $scope.guide.gwVol = $scope.guide.gw.toFixed(2) + "KGS" + "\n" + $scope.guide.vol.toFixed(3) + "CBM";
                $scope.guide.booking.gwVol = $scope.guide.gw.toFixed(2) + "KGS" + "\n" + $scope.guide.vol.toFixed(3) + "CBM";
            } else if ($scope.guide.vol) {
                $scope.guide.gwVol = $scope.guide.vol.toFixed(3) + "CBM";
                $scope.guide.booking.gwVol = $scope.guide.vol.toFixed(3) + "CBM";

            } else if ($scope.guide.gw) {
                $scope.guide.gwVol = $scope.guide.gw.toFixed(2) + "KGS";
                $scope.guide.booking.gwVol = $scope.guide.gw.toFixed(2) + "KGS";
            }
            if ($scope.guide.booking.divVolBy){
                $scope.guide.chargeWeight = $scope.guide.vol * 1000000 / $scope.guide.booking.divVolBy;
            }
            if($scope.guide.chargeWeight < $scope.guide.gw){
                $scope.guide.chargeWeight = $scope.guide.gw;
            }
            if ($scope.guide.chargeWeight){
                $scope.guide.chargeWeight = $scope.guide.chargeWeight.toFixed(2) - 0;
                $scope.guide.awbWeight = $scope.guide.chargeWeight.toFixed(2) - 0;
            }   
        };
        $scope.uppercaseCtnNum = function(ctn) {
            ctn.ctnNum = angular.uppercase(ctn.ctnNum);
        }
        $scope.uppercaseCtnSealNum = function(ctn) {
            ctn.sealNum = angular.uppercase(ctn.sealNum);
        }
        $scope.checkCtnRepeat = function(ctn) {
            if(!ctn.ctnNum){
                return;
            }
            angular.forEach($scope.guide.guideCtns, function(data){
                if (data != ctn && data.ctnNum == ctn.ctnNum){
                    $rootScope.OPS_ALERT(data.ctnNum + ": " + $translate.instant('autopsApp.guide.home.ctnRepeatedAlert'));
                }
            })
        }
        $scope.guideCtnPkgsChange = function() {
            if ($scope.guide.guideCtns && $scope.guide.guideCtns.length > 0) {
                var newPkgsTotal = 0;
                for (var i = 0; i < $scope.guide.guideCtns.length; i++) {
                    if ($scope.guide.guideCtns[i].pkgs) {
                        newPkgsTotal = newPkgsTotal + $scope.guide.guideCtns[i].pkgs;
                    }
                }
                $scope.guide.pkgNum = newPkgsTotal;
                $scope.updatePkgsAndType();
            }
            updateBookingCtnsGwVol();
        }
        $scope.guideCtnPkgTypeChange = function(pkgType) {
            var pkgsCopy = null;
            var isSamePkg = true;
            angular.forEach($scope.guide.guideCtns, function (data) {        
                if (data.pkgType && pkgType != data.pkgType){
                    isSamePkg = false;
                }
            });
            if (isSamePkg){
                $scope.guide.booking.pkgType = pkgType;
            }
            else {
                $scope.guide.booking.pkgType = "PKGS";
            } 
            $scope.updatePkgsAndType();
            updateBookingCtnsGwVol();
        }
        $scope.guideCtnGwChange = function() {
            if ($scope.guide.guideCtns && $scope.guide.guideCtns.length > 0) {
                var newGwTotal = 0;
                for (var i = 0; i < $scope.guide.guideCtns.length; i++) {
                    if ($scope.guide.guideCtns[i].gw) {
                        newGwTotal = newGwTotal + $scope.guide.guideCtns[i].gw;
                    }
                }
                $scope.guide.gw = newGwTotal.toFixed(2) - 0;
                updateGwVol();
            }
            updateBookingCtnsGwVol();
        }
        $scope.guideCtnVolChange = function() {
            if ($scope.guide.guideCtns && $scope.guide.guideCtns.length > 0) {
                var newVolTotal = 0;
                for (var i = 0; i < $scope.guide.guideCtns.length; i++) {
                    if ($scope.guide.guideCtns[i].vol) {
                        newVolTotal = newVolTotal + $scope.guide.guideCtns[i].vol;
                    }
                }
                $scope.guide.vol = newVolTotal.toFixed(3) - 0;
                updateGwVol();
            }
            updateBookingCtnsGwVol();
        }
        var updateBookingCtnsGwVol = function() {
            if ($scope.guide.guideFclCtns && $scope.guide.guideFclCtns.length > 0) {                
                angular.forEach($scope.guide.guideFclCtns, function(bctn){
                    if (bctn.ctnType) {
                        var gwTtl=0, volTtl=0, pkgTtl=0, pkgType;
                        angular.forEach($scope.guide.guideCtns, function(gctn){
                            if(gctn.ctnType == bctn.ctnType){
                                if(gctn.gw){
                                    gwTtl = gwTtl + gctn.gw;
                                }
                                if(gctn.vol){
                                    volTtl = volTtl + gctn.vol;
                                }      
                                if(gctn.pkgs){
                                    pkgTtl = pkgTtl + gctn.pkgs;
                                }      
                                if(pkgType){
                                    if(gctn.pkgType && pkgType != gctn.pkgType){
                                        pkgType =  "PKGS";      
                                    }
                                }
                                else {
                                    pkgType = gctn.pkgType;
                                }                                              
                            }
                        })
                        bctn.gw = gwTtl.toFixed(2) - 0;
                        bctn.vol = volTtl.toFixed(3) - 0;
                        bctn.pkgNum = pkgTtl.toFixed(2) - 0;
                        bctn.pkgType = pkgType;
                    }
                });
            }
        }

        $scope.addGCtn = function() {
            $scope.guide.guideCtns.push({
                smsSent: false
            });
        };

        
        $scope.deleteGCtn = function(ctn) {
            if (ctn.id != null) {
                GuideCtns.delete({
                    id: ctn.id
                });
            };
            var index = $scope.guide.guideCtns.indexOf(ctn);
            if (index > -1) {
                $scope.guide.guideCtns.splice(index, 1);
            };
            var ctnAmt = 0;
            angular.forEach($scope.guide.guideCtns, function(gctn){
                if(gctn.ctnType == ctn.ctnType){
                    ctnAmt++;
                }                
            })
            angular.forEach($scope.guide.guideFclCtns, function(bctn){
                if(ctnAmt && bctn.ctnType == ctn.ctnType){
                    bctn.num = ctnAmt;
                    ctnAmt = null;
                }         
            })
            updateBookingCtnsGwVol();            
        };
        $scope.checkHtsCode = function(ctn) {
            if(!$scope.guide.booking.hsCode){
                return;
            }
            var wordList = $scope.guide.booking.hsCode.split(/\s+/g);
            angular.forEach(wordList, function(data){
                if(data){
                    if(isNaN(data) || !(/^\d+$/.test(data))){
                        $rootScope.OPS_ALERT(data + ": is invalid. Valid HTSCode is number string without '.' ");
                    }
                    else if(data.length != 6 && data.length != 8 && data.length != 10){
                        $rootScope.OPS_ALERT(data + ": is invalid. Valid HTSCode length is 6 8 10. 6 is recommended.");
                    }
                }
            })
        }
    }
})();
