(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('OpsTemplate', OpsTemplate);

    OpsTemplate.$inject = ['$resource','DateUtils'];

    function OpsTemplate($resource, DateUtils) {
        return $resource('api/opsTemplates/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.createdTime = DateUtils.convertDateTimeFromServer(data.createdTime);
                    return data;
                }
            },
            'getAll': {
                method:'GET',
                url:'api/opsTemplatesAll',
                isArray:true
            },
            
            'checkCodeRepeat': {
                method:'GET',
                url:'api/opsTemplates/checkCodeRepeat'
            },
            'getLocalCharges': {
                method:'GET',
                url:'api/opsTemplates/getLocalCharges'
            },
            'update': { method:'PUT' }
        });
    }
})();
