(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ShareCargoCostDialogController', ShareCargoCostDialogController);

    ShareCargoCostDialogController.$inject = ['$rootScope', '$uibModalInstance', '$translate', '$timeout', '$uibModal', 'entity', 'GuideRates'];

    function ShareCargoCostDialogController ($rootScope, $uibModalInstance, $translate, $timeout, $uibModal, entity, GuideRates) {
               
        var vm = this;
        var isPay = entity.isPay;
        vm.shareMCostDTO = entity;
        vm.clear = clear;
        vm.delCargo = delCargo;
        vm.delRate = delRate;
        vm.submitShare = submitShare;
        vm.setShareType = setShareType;
        vm.isSaving = false;
        vm.exSharedRates = false;
        vm.exSharedRatesChange = exSharedRatesChange;

        $timeout(function (){
            vm.shareMCostDTO.wmsCargos = [];
            vm.shareMCostDTO.shareRates = [];
            vm.shareMCostDTO.type = "vol";

            angular.forEach(vm.shareMCostDTO.guideRates, function(data){
                if (data.exchangeRate && !data.isHidden && data.payAmount && data.payAmount > 0 && isPay == data.isPay){
                    vm.shareMCostDTO.shareRates.push(data);                   
                }
            })

            GuideRates.getShareCargos(vm.shareMCostDTO, function(result){
                angular.forEach(result, function(data){
                    vm.shareMCostDTO.wmsCargos.push(data);
                })
                updateVWtotal();
            })
            updateTotal();
        })
        vm.costTotalList = [];
        function updateTotal () {
            vm.costTotalList = [];
            var currencys = [];
            angular.forEach(vm.shareMCostDTO.shareRates, function(data){
                if (data.payCurrency && currencys.indexOf(data.payCurrency) === -1){
                    currencys.push(data.payCurrency);
                }
            })

            var cost = 0;
            angular.forEach(currencys, function(currency){
                cost = 0;
                angular.forEach(vm.shareMCostDTO.shareRates, function(rate){
                    if (rate.payCurrency == currency && rate.payAmount){
                        if (rate.isPay == isPay) {
                            cost = cost + rate.payAmount;
                        }
                    }
                })
                vm.costTotalList.push({currency: currency, total: cost.toFixed(2) -0});
            })
        }

        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function setShareType (type) {
            vm.shareMCostDTO.type = type;
        }
        function delCargo (index) {
            vm.shareMCostDTO.wmsCargos.splice(index, 1);
            updateVWtotal();
        }

        function delRate (rate) {
            var index = vm.shareMCostDTO.shareRates.indexOf(rate);
            if (index > -1) {
                vm.shareMCostDTO.shareRates.splice(index, 1);
            };
            updateTotal();
        }
        function exSharedRatesChange () {
            vm.shareMCostDTO.shareRates = [];
            angular.forEach(vm.shareMCostDTO.guideRates, function(data){
                if (data.exchangeRate && !data.isHidden && data.isPay == isPay && data.payAmount && data.payAmount > 0){
                    if (vm.exSharedRates){
                        if (!data.sharedOn){
                            vm.shareMCostDTO.shareRates.push(data);
                        }                        
                    }
                    else {
                        vm.shareMCostDTO.shareRates.push(data);
                    }                    
                }
            })
            updateTotal();
        }

        var onShareError = function(result) {
            vm.isSaving = false;
            if (result.status == 409){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.expiredAlert'));
            }
            else if (result.status == 403){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.shareCostError'));
            }
            else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        };
        
        var onShareSuccess = function(result) {
            vm.isSaving = false;            
            $uibModalInstance.close(result);
        };

        function submitShare() {  
            if(!vm.shareMCostDTO.shareRates || vm.shareMCostDTO.shareRates.length == 0){
                layer.msg($translate.instant('global.noSelectedCharges'));                
                return;
            }
            if (!vm.shareMCostDTO.type || vm.shareMCostDTO.wmsCargos.length == 0 || vm.shareMCostDTO.wmsCargos.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));       
                return;
            }
            vm.isSaving = true;
            var entity = angular.copy(vm.shareMCostDTO);
            GuideRates.shareToCargo(entity, onShareSuccess, onShareError);
        }

        vm.vwTtl = {};
        function updateVWtotal () {
            vm.vwStr = "";
            var gw=0,vol=0,vw=0,rt=0,mt=0,value=0,duty=0;
            angular.forEach(vm.shareMCostDTO.wmsCargos, function(data){
                gw += data.gwTtl;
                vol += data.volTtl;
                vw += data.vwTtl;
                rt += data.rtTtl;
                mt += data.mtTtl;
                value += data.goodsValueTtl;
                duty += data.dutyTtl;                
            })
            vm.vwTtl.gwTtl = gw.toFixed(2) -0;
            vm.vwTtl.volTtl = vol.toFixed(4) -0;
            vm.vwTtl.rtTtl = rt.toFixed(4) -0;
            vm.vwTtl.mtTtl = mt.toFixed(4) -0;
            vm.vwTtl.vwTtl = vw.toFixed(2) -0;
            vm.vwTtl.dutyTtl = duty.toFixed(2) -0;
            vm.vwTtl.goodsValueTtl = value.toFixed(2) -0;            
        }



    }
})();
