(function() {
    'use strict';
    angular
        .module('autopsApp')
        .controller('BookingCtnsDetailController', BookingCtnsDetailController);
    BookingCtnsDetailController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$timeout', '$translate', '$uibModal', 
        'entity', 'JhiSignature', 'OpsCost'
    ];
    function BookingCtnsDetailController($scope, $rootScope, $uibModalInstance, $timeout, $translate, $uibModal, 
        entity, JhiSignature, OpsCost) {
        $scope.opsCost = entity.opsCost;
        $scope.remarks = entity.remarks;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $timeout(function (){
            getRemarks($scope.opsCost);
        });

        function getRemarks(opsCost) {
            if(!opsCost || !opsCost.remarks || !opsCost.companyId){
                return;
            }
            JhiSignature.findRateRemarks({
                companyId: opsCost.companyId,
                code: opsCost.remarks
            }, function(result){
                if(result){
                    $scope.remarks=result.signature;
                }  
                else {
                    $scope.remarks = opsCost.remarks;
                    // layer.msg($translate.instant('global.noRecord'));   
                }              
            }, function(result){
                $scope.remarks = opsCost.remarks;
            })
        }
        $scope.termsConfirmed = false;
        $scope.bookSpacePop = function  () {
            if(!$scope.termsConfirmed){
                layer.msg($translate.instant('autopsApp.opsCost.bookingAlertContent'));  
                return;
            }
            if(!$scope.opsCost){
                $uibModalInstance.close();
                return;
            }
            OpsCost.getBookingAgent({
                gw: entity.gw,
                vol: entity.vol
            }, $scope.opsCost, function(result){
                if($scope.opsCost.type == 16 || $scope.opsCost.type == 30 || $scope.opsCost.type == 32){
                    $rootScope.bookingType = "fms";
                }
                else{
                    $rootScope.bookingType = "fba";
                }
                result.IS_FROM_POP = true;
                $rootScope.BOOKINGACTIVETAB = 0;
                $rootScope.id = "new";
                $uibModal.open({
                    templateUrl: 'app/entities/booking/booking-detail.html',
                    controller: 'BookingDetailController',
                    backdrop: false,
                    size: 'xxxl',
                    resolve: {
                        entity: [function() {
                            return result;
                        }]
                    }
                }).result.then(function(result) {   
                }, function(result) {
                });       
                $uibModalInstance.dismiss('cancel');
            })     
        }

    }
})();
