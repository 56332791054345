(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('GuideCtnsSearch', GuideCtnsSearch);

    GuideCtnsSearch.$inject = ['$resource'];
    
    function GuideCtnsSearch($resource) {
        return $resource('api/_search/guideCtnss/:query', {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
