(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsUserController', OpsUserController);

    OpsUserController.$inject = ['$scope', '$state', 'OpsUser', 'OpsUserSearch', 'ParseLinks'];

    function OpsUserController($scope, $state, OpsUser, OpsUserSearch, ParseLinks) {

        $scope.opsUsers = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 1;
        $scope.loadAll = function() {
            OpsUser.query({page: $scope.page - 1, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.opsUsers = result;
            });
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();


        $scope.search = function () {
            OpsUserSearch.query({query: $scope.searchQuery}, function(result) {
                $scope.opsUsers = result;
            }, function(response) {
                if(response.status === 404) {
                    $scope.loadAll();
                }
            });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.opsUser = {
                companyName: null,
                address: null,
                login: null,
                name: null,
                mob: null,
                tel: null,
                email: null,
                createdTime: null,
                isClient: null,
                remarks: null,
                id: null
            };
        };
    }
})();
