(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ExpressPackingDeleteController',ExpressPackingDeleteController);

    ExpressPackingDeleteController.$inject = ['$uibModalInstance', 'entity', 'ExpressPacking'];

    function ExpressPackingDeleteController($uibModalInstance, entity, ExpressPacking) {
        var vm = this;

        vm.expressPacking = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            ExpressPacking.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
