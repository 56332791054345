
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ExpenseDetailsController', ExpenseDetailsController);

    ExpenseDetailsController.$inject = ['$scope', '$rootScope', '$window', '$translate', 
    'Principal', 'OpsFiles', 'DateUtils', 'DataUtils', 'AccountGeneral'];

    function ExpenseDetailsController($scope, $rootScope, $window, $translate, 
        Principal, OpsFiles, DateUtils, DataUtils, AccountGeneral) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            })
        }


        $scope.isSaving = false;
        $scope.profitSheet = {};

        $scope.filterDTO = {subsidiaryType: "team"};

        $scope.getContetns = function() {
            $scope.isSaving = true;
            OpsFiles.expenseDetails($scope.filterDTO, function(result) {
                $scope.isSaving = false;
                $scope.profitSheet = result;
                $scope.filterDTO.makeDate = new Date();
            }, function(error) {
                $scope.isSaving = false;
                layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
            });
        }
        $scope.exportReport = function(format) {
            OpsFiles.excelExpenseDetails($scope.profitSheet, function(result) {
                $rootScope.EXPORTDOWNLOAD(result, 2, "ExpenseDetails");
            }, function(error) {
                layer.msg($translate.instant('global.exportFailed'));
            });
        }
        $scope.monthFormat = "yyyy-MM";
        $scope.datepickerOptions = {
           minMode:'month'
        }
    }
})();