(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CrmPriceDialogController', CrmPriceDialogController);

    CrmPriceDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', '$http', '$translate', '$rootScope',
    'entity', 'WmsIn', 'DataUtils'];

    function CrmPriceDialogController ($timeout, $scope, $uibModalInstance, $http, $translate, $rootScope,
        entity, WmsIn, DataUtils) {
        var vm = this;

        vm.wmsIn = entity;
        if(vm.wmsIn.palletizeJson){
            vm.palletize = angular.fromJson(vm.wmsIn.palletizeJson);
        }
        else {
            vm.palletize = {};
        }

        vm.clear = clear;
        vm.save = save;
        vm.autoPalletize = autoPalletize;
        vm.importPallets = importPallets;
        vm.allotPallets = allotPallets;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }


        $timeout(function (){
            reAllotPallets();
        });

        function clear () {
            $uibModalInstance.close(vm.palletize);
            // $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            // alert(angular.toJson(vm.palletize));
            WmsIn.savePalletize({}, {
                id: vm.wmsIn.id,
                palletizeJson: angular.toJson(vm.palletize)
            }, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            // vm.palletize = result;
            layer.msg($translate.instant('global.save_success'));

            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function isSingle (palletNum) {
            var count = 0;
            angular.forEach(vm.palletize.rawData, function(pallet){
                if(pallet.palletNum == palletNum){
                    count++;
                }

            })
            if(count > 1){
                return false;
            }
            return true;
        }

        function getLevelData () {
            angular.forEach(vm.palletize.rawData, function(pallet){
                if(isSingle(pallet.palletNum) && !pallet.levelHeight){
                    pallet.levelHeight = DataUtils.round(pallet.height/pallet.levels, 2);
                }
                if(isSingle(pallet.palletNum) && !pallet.levelGw){
                    pallet.levelGw = DataUtils.round(pallet.gw/pallet.levels, 2);
                }
            })
        }

        function noLevelDataStr () {
            var str = "";
            angular.forEach(vm.palletize.rawData, function(pallet){
                if(!pallet.levelGw || !pallet.levelHeight || !pallet.levelPkgs){
                    str = str + pallet.palletNum + "(SKU:" + pallet.sku + "); ";
                }
            })
            return str;
        }
        function autoPalletize () {
            getLevelData ();
            var noDataStr = noLevelDataStr();
            if(noDataStr.length > 0){
                $rootScope.OPS_ALERT("层规格缺失(层高/件数/重量): " + noDataStr);
                return;
            }

            // layer.msg($translate.instant("global.developingAlert"));
            // return;
            if(!vm.palletize.weight || !vm.palletize.length || !vm.palletize.width || !vm.palletize.height){
                $rootScope.OPS_ALERT("托盘限重等未填写！")
                return;
            }
            var mw = vm.palletize.weight-0;
            var l = vm.palletize.length-0;
            var w = vm.palletize.width-0;
            var h = vm.palletize.height-0;
            var mv = l * w * h;

            var diffHeight, diffGw, diffMw, delLevels, tempPltNum;

            //超高拆托
            var pallets = [];
            angular.forEach(vm.palletize.rawData, function(pallet){
                if(pallet.palletNum && pallet.height > h){
                    diffHeight = pallet.height - h;
                    delLevels = Math.ceil(diffHeight/pallet.levelHeight);
                    if(delLevels < pallet.levels){//部分移除的情况
                        var splitLevels = angular.copy(pallet);
                        splitLevels.oldPltNum = pallet.palletNum;
                        splitLevels.palletNum = null;
                        splitLevels.pkgs = DataUtils.round(delLevels*pallet.levelPkgs, 0);
                        splitLevels.gw = null;
                        splitLevels.height = null;
                        splitLevels.cft = null;
                        splitLevels.vw = null;
                        splitLevels.levels = delLevels;
                        pallets.push(splitLevels);

                        var newPallet = angular.copy(pallet);
                        newPallet.pkgs = DataUtils.round(pallet.pkgs - delLevels * pallet.levelPkgs, 0);
                        newPallet.gw = DataUtils.round(pallet.gw - delLevels * pallet.levelGw, 2);
                        newPallet.height = DataUtils.round(pallet.height - delLevels * pallet.levelHeight, 0);
                        newPallet.cft = DataUtils.round(newPallet.height * 40 * 48, 2);
                        newPallet.vw = DataUtils.round(newPallet.cft/166.66666, 2);
                        newPallet.levels = pallet.levels - delLevels;
                        pallets.push(newPallet);

                        //更新该托盘其它记录的重量 高度 材积
                        tempPltNum = pallet.palletNum;
                        pallet.palletNum = null
                        angular.forEach(vm.palletize.rawData, function(pallet2){
                            if(pallet2.palletNum == tempPltNum){
                                pallet2.gw = DataUtils.round(pallet.gw - delLevels * pallet.levelGw, 2);
                                pallet2.height = DataUtils.round(pallet.height - delLevels * pallet.levelHeight, 0);
                                pallet2.cft = DataUtils.round(pallet2.height * 40 * 48, 2);
                                pallet2.vw = DataUtils.round(pallet2.cft/166.66666, 2);
                            }
                        })
                    }
                    else {//全部移除都不够的情况
                        tempPltNum = pallet.palletNum;
                        pallet.palletNum = null
                        var splitLevels = angular.copy(pallet);
                        splitLevels.oldPltNum = tempPltNum;
                        splitLevels.gw = null;
                        splitLevels.height = null;
                        splitLevels.cft = null;
                        splitLevels.vw = null;
                        pallets.push(splitLevels);

                        //更新该托盘其它记录的重量 高度 材积
                        angular.forEach(vm.palletize.rawData, function(pallet2){
                            if(pallet2.palletNum == tempPltNum){
                                pallet2.gw = DataUtils.round(pallet.gw - pallet.levels * pallet.levelGw, 2);
                                pallet2.height = DataUtils.round(pallet.height - pallet.levels * pallet.levelHeight, 0);
                                pallet2.cft = DataUtils.round(pallet2.height * 40 * 48, 2);
                                pallet2.vw = DataUtils.round(pallet2.cft/166.66666, 2);
                            }
                        })
                    }
                }
                else {
                    pallets.push(pallet);
                }
            })
            allotPallets(pallets);
            //超重拆托
            pallets = [];
            angular.forEach(vm.palletize.rawData, function(pallet){
                if(pallet.palletNum && pallet.gw > mw){
                    diffGw = pallet.gw - mw;
                    delLevels = Math.ceil(diffGw/pallet.levelGw);
                    if(delLevels < pallet.levels){//部分移除的情况
                        var splitLevels = angular.copy(pallet);
                        splitLevels.oldPltNum = pallet.palletNum;
                        splitLevels.palletNum = null;
                        splitLevels.pkgs = DataUtils.round(delLevels * pallet.levelPkgs, 0);
                        splitLevels.gw = null;
                        splitLevels.height = null;
                        splitLevels.cft = null;
                        splitLevels.vw = null;
                        splitLevels.levels = delLevels;
                        pallets.push(splitLevels);

                        var newPallet = angular.copy(pallet);
                        newPallet.pkgs = DataUtils.round(pallet.pkgs - delLevels * pallet.levelPkgs, 0);
                        newPallet.gw = DataUtils.round(pallet.gw - delLevels * pallet.levelGw, 2);
                        newPallet.height = DataUtils.round(pallet.height - delLevels * pallet.levelHeight, 0);
                        newPallet.cft = DataUtils.round(newPallet.height * 40 * 48, 2);
                        newPallet.vw = DataUtils.round(newPallet.cft/166.66666, 2);
                        newPallet.levels = pallet.levels - delLevels;
                        pallets.push(newPallet);

                        //更新该托盘其它记录的重量 高度 材积
                        tempPltNum = pallet.palletNum;
                        pallet.palletNum = null
                        angular.forEach(vm.palletize.rawData, function(pallet2){
                            if(pallet2.palletNum == tempPltNum){
                                pallet2.gw = DataUtils.round(pallet.gw - delLevels * pallet.levelGw, 2);
                                pallet2.height = DataUtils.round(pallet.height - delLevels * pallet.levelHeight, 0);
                                pallet2.cft = DataUtils.round(pallet2.height * 40 * 48, 2);
                                pallet2.vw = DataUtils.round(pallet2.cft/166.66666, 2);
                            }
                        })
                    }
                    else {//全部移除都不够的情况
                        tempPltNum = pallet.palletNum;
                        pallet.palletNum = null
                        var splitLevels = angular.copy(pallet);
                        splitLevels.oldPltNum = tempPltNum;
                        splitLevels.gw = null;
                        splitLevels.height = null;
                        splitLevels.cft = null;
                        splitLevels.vw = null;
                        pallets.push(splitLevels);

                        //更新该托盘其它记录的重量 高度 材积
                        angular.forEach(vm.palletize.rawData, function(pallet2){
                            if(pallet2.palletNum == tempPltNum){
                                pallet2.gw = DataUtils.round(pallet.gw - pallet.levels * pallet.levelGw, 2);
                                pallet2.height = DataUtils.round(pallet.height - pallet.levels * pallet.levelHeight, 0);
                                pallet2.cft = DataUtils.round(pallet2.height * 40 * 48, 2);
                                pallet2.vw = DataUtils.round(pallet2.cft/166.66666, 2);
                            }
                        })
                    }
                }
                else {
                    pallets.push(pallet);
                }
            })
            allotPallets(pallets);

            //超体积重拆托
            pallets = [];
            angular.forEach(vm.palletize.rawData, function(pallet){
                if(pallet.palletNum && pallet.cft > mv){
                    diffMw = pallet.cft - mv;
                    delLevels = Math.ceil(diffMw/(pallet.levelHeight * 40 * 48));
                    if(delLevels < pallet.levels){//部分移除的情况
                        var splitLevels = angular.copy(pallet);
                        splitLevels.oldPltNum = pallet.palletNum;
                        splitLevels.palletNum = null;
                        splitLevels.pkgs = DataUtils.round(delLevels * pallet.levelPkgs, 0);
                        splitLevels.gw = null;
                        splitLevels.height = null;
                        splitLevels.cft = null;
                        splitLevels.vw = null;
                        splitLevels.levels = delLevels;

                        pallets.push(splitLevels);

                        var newPallet = angular.copy(pallet);
                        newPallet.pkgs = DataUtils.round(pallet.pkgs - delLevels * pallet.levelPkgs, 0);
                        newPallet.gw = DataUtils.round(pallet.gw - delLevels * pallet.levelGw, 2);
                        newPallet.height = DataUtils.round(pallet.height - delLevels * pallet.levelHeight, 0);
                        newPallet.cft = DataUtils.round(newPallet.height * 40 * 48, 2);
                        newPallet.vw = DataUtils.round(newPallet.cft/166.66666, 2);
                        newPallet.levels = pallet.levels - delLevels;
                        pallets.push(newPallet);

                        //更新该托盘其它记录的重量 高度 材积
                        tempPltNum = pallet.palletNum;
                        pallet.palletNum = null
                        angular.forEach(vm.palletize.rawData, function(pallet2){
                            if(pallet2.palletNum == tempPltNum){
                                pallet2.gw = DataUtils.round(pallet.gw - delLevels * pallet.levelGw, 2);
                                pallet2.height = DataUtils.round(pallet.height - delLevels * pallet.levelHeight, 0);
                                pallet2.cft = DataUtils.round(pallet2.height * 40 * 48, 2);
                                pallet2.vw = DataUtils.round(pallet2.cft/166.66666, 2);
                            }
                        })
                    }
                    else {//全部移除都不够的情况
                        tempPltNum = pallet.palletNum;
                        pallet.palletNum = null
                        var splitLevels = angular.copy(pallet);
                        splitLevels.oldPltNum = tempPltNum;
                        splitLevels.gw = null;
                        splitLevels.height = null;
                        splitLevels.cft = null;
                        splitLevels.vw = null;
                        pallets.push(splitLevels);

                        //更新该托盘其它记录的重量 高度 材积
                        angular.forEach(vm.palletize.rawData, function(pallet2){
                            if(pallet2.palletNum == tempPltNum){
                                pallet2.gw = DataUtils.round(pallet.gw - pallet.levels * pallet.levelGw, 2);
                                pallet2.height = DataUtils.round(pallet.height - pallet.levels * pallet.levelHeight, 0);
                                pallet2.cft = DataUtils.round(pallet2.height * 40 * 48, 2);
                                pallet2.vw = DataUtils.round(pallet2.cft/166.66666, 2);
                            }
                        })
                    }
                }
                else {
                    pallets.push(pallet);
                }
            })
            allotPallets(pallets);

            //拆出来的货 装进新的托盘
            var pltedList = [];
            angular.forEach(pallets, function(data){
                if(data.palletNum){
                    pltedList.push(data);
                }
            })
            var newPltNum = 1;
            while (haveUnPlted(pallets)){
                var newPallet = {
                    pkgs: 0,
                    height: 0,
                    gw: 0,
                    cft: 0
                };
                var newPltNumStr = "newPlt" + newPltNum;

                angular.forEach(pallets, function(pallet){
                    if(!pallet.palletNum && newPallet.height < h && newPallet.gw < mw && newPallet.cft < mv){
                        pallet.palletNum = newPltNumStr;

                        // newPallet.pkgs += pallet.pkgs;
                        newPallet.gw = DataUtils.round(newPallet.gw + pallet.levels * pallet.levelGw, 2);
                        newPallet.height = DataUtils.round(newPallet.height + pallet.levels * pallet.levelHeight, 0);
                        newPallet.cft = DataUtils.round(newPallet.height * 40 * 48, 2);
                        newPallet.vw = DataUtils.round(newPallet.cft/166.66666, 2);

                        pltedList.push(pallet);
                    }
                })
                angular.forEach(pltedList, function(pallet){
                    if(newPltNumStr == pallet.palletNum){
                        pallet.gw = newPallet.gw;
                        pallet.height = newPallet.height;
                        pallet.cft = newPallet.cft;
                        pallet.vw = newPallet.vw;
                    }
                })
                newPltNum++;
            }
            pallets = pltedList;

            //合并托盘
            var palletsNumSet = new Set();//所有的托盘号
            angular.forEach(pallets, function(data){
                palletsNumSet.add(data.palletNum);
            })
            var pltNumsList = Array.from(palletsNumSet);

            pltedList = [];
            var fp,tp;
            var cmGw, cmHeight, cmCft;//合并后的重量


            for(var i = 0; i < pltNumsList.length; i++){
                fp = getPltByNum(pallets, pltNumsList[i]);//获取托盘1
                for(var j = i+1; j < pltNumsList.length; j++){
                    tp = getPltByNum(pallets, pltNumsList[j]);//获取托盘2
                    cmGw = fp.gw + tp.gw;
                    cmHeight = fp.height + tp.height;
                    cmCft = fp.cft + tp.cft;
                    if(cmGw <= mw && cmHeight <= h && cmCft <= mv){
                        angular.forEach(pallets, function(data){
                            //两个托盘合并
                            if(data.palletNum == tp.palletNum){
                                data.gw += fp.gw;
                                data.height += fp.height;
                                data.cft += fp.cft;
                                data.vw += fp.vw;
                            }

                            //被合并托盘清零
                            if(data.palletNum == fp.palletNum){
                                data.palletNum = tp.palletNum;
                                data.oldPltNum = fp.palletNum;
                                data.gw += tp.gw;
                                data.height += tp.height;
                                data.cft += tp.cft;
                                data.vw += tp.vw;
                            }
                        })
                    }
                }
            }
            allotPallets(pallets);
        }

        //根据托盘号 获取托盘
        function getPltByNum (pallets, palletNum) {
            for(var i = 0; i < pallets.length; i++){
                if(palletNum == pallets[i].palletNum){
                    return angular.copy(pallets[i]);
                }
            }
        }
        //还有未打托的托盘
        function haveUnPlted (pallets) {
            for(var i = 0; i < pallets.length; i++){
                if(!pallets[i].palletNum){
                    return true;
                }
            }
            return false;
        }

        //根据导入数据 和最新出仓单 重新拣货一次
        vm.unAllotedCargos = [];
        function reAllotPallets () {
            if(!vm.palletize){
                vm.unAllotedCargos = angular.copy(vm.wmsIn.cargoList);
            }
            var palletsNumSet = new Set();//所有的托盘号
            angular.forEach(vm.palletize.pallets, function(data){
                palletsNumSet.add(data.palletNum);
            })
            //所有货物
            var cargosCopy = angular.copy(vm.wmsIn.cargoList);

            var thisCgPkgs;
            palletsNumSet.forEach(function(palletNum){
                var pallet = null;
                angular.forEach(vm.palletize.pallets, function(plt){
                    if(plt.palletNum == palletNum){
                        //重新拣货
                        angular.forEach(plt.cargos, function (cg) {
                            thisCgPkgs = cg.pkgs;//托盘的箱数
                            angular.forEach(cargosCopy, function (cargo) {
                                if(thisCgPkgs && cargo.pkgs && cg.sku == cargo.sku){
                                    if(cargo.pkgs >= thisCgPkgs){
                                        cargo.pkgs = cargo.pkgs - thisCgPkgs;
                                        thisCgPkgs = 0;
                                    }
                                    else {
                                        thisCgPkgs = thisCgPkgs - cargo.pkgs;
                                        cargo.pkgs = 0;
                                    }
                                }
                            })
                        })
                    }
                })
            })
            combineSku ();
            getUnallotedCargos(cargosCopy);
            sortPallets ();
        }

        //按导入数据 和出仓单 分配托盘
        function allotPallets (pallets) {
            vm.palletize.rawData = angular.copy(pallets);//导入数据留存
            var palletsNumSet = new Set();//所有的托盘号
            vm.palletize.pkgs = 0;
            angular.forEach(pallets, function(data){
                if(data.palletNum){
                    palletsNumSet.add(data.palletNum);
                }
                if(data.pkgs){
                    vm.palletize.pkgs += data.pkgs;
                }
            })
            vm.palletize.pltNum = Array.from(palletsNumSet).length;
            //所有货物
            var cargosCopy = angular.copy(vm.wmsIn.cargoList);

            //创建托盘并把货物加载进去
            vm.palletize.pallets = [];//打包好的托盘

            var ttlPltPkgs, ttlPltLevels, thisPltPkgs, actPkgs;

            palletsNumSet.forEach(function(palletNum){
                var pallet = null;
                ttlPltPkgs = ttlPltLevels = actPkgs = 0;
                angular.forEach(pallets, function(data){
                    if(data.palletNum && data.palletNum == palletNum){
                        if(!pallet){//第一次匹配
                            pallet = angular.copy(data);
                            pallet.cargos = [];
                            pallet.skuList = [];
                            pallet.sku = null;
                            pallet.levelPkgs = null;
                        }
                        if(data.pkgs){//托盘总件数
                            ttlPltPkgs += data.pkgs;
                        }
                        if(data.levels){//托盘总层数
                            ttlPltLevels += data.levels;
                        }
                        //保存托盘sku
                        var newSku = {};
                        newSku.sku = data.sku;
                        newSku.pkgs = data.pkgs;
                        newSku.cargoName = data.cargoName;
                        newSku.levels = data.levels;
                        newSku.oldPltNum = data.oldPltNum;
                        pallet.skuList.push(newSku);

                        thisPltPkgs = data.pkgs;//托盘的箱数
                        angular.forEach(cargosCopy, function (cargo) {
                            if(thisPltPkgs && cargo.pkgs && data.sku == cargo.sku){
                                if(cargo.pkgs >= thisPltPkgs){
                                    var newCargo = {};
                                    newCargo.pkgs = thisPltPkgs;
                                    newCargo.sku = cargo.sku;
                                    newCargo.cargoName = cargo.cargoName;
                                    newCargo.vol = cargo.vol;
                                    newCargo.gw = cargo.gw;
                                    newCargo.height = cargo.height;

                                    actPkgs += newCargo.pkgs;
                                    pallet.cargos.push(newCargo);

                                    cargo.pkgs = cargo.pkgs - thisPltPkgs;
                                    thisPltPkgs = 0;
                                }
                                else {
                                    var newCargo = {};
                                    newCargo.pkgs = cargo.pkgs;
                                    newCargo.sku = cargo.sku;
                                    newCargo.cargoName = cargo.cargoName;
                                    newCargo.vol = cargo.vol;
                                    newCargo.gw = cargo.gw;
                                    newCargo.height = cargo.height;

                                    actPkgs += newCargo.pkgs;
                                    pallet.cargos.push(newCargo);

                                    thisPltPkgs = thisPltPkgs - cargo.pkgs;
                                    cargo.pkgs = 0;
                                }
                            }
                        })
                    }
                })
                if(ttlPltPkgs){
                    pallet.pkgs = ttlPltPkgs;
                    pallet.levels = ttlPltLevels;
                    pallet.actPkgs = actPkgs;
                    vm.palletize.pallets.push(pallet);
                }
            })

            angular.forEach(pallets, function(data){
                if(!data.palletNum){
                    var pallet = angular.copy(data);
                    pallet.skuList = [];
                    var newSku = {};
                    newSku.sku = data.sku;
                    newSku.pkgs = data.pkgs;
                    newSku.cargoName = data.cargoName;
                    newSku.levels = data.levels;
                    newSku.oldPltNum = data.oldPltNum;
                    pallet.skuList.push(newSku);
                    vm.palletize.pallets.push(pallet);
                }
            })

            combineSku();
            getUnallotedCargos(cargosCopy);
            sortPallets ();
        }
        function sortPallets () {
            var pallets = [];
            angular.forEach(vm.palletize.pallets, function(pallet){
                if(!pallet.palletNum || pallet.palletNum.indexOf("newPlt") != -1){
                    pallets.push(pallet);
                }
            })
            angular.forEach(vm.palletize.pallets, function(pallet){
                if(pallet.actPkgs != pallet.pkgs && pallet.palletNum && pallet.palletNum.indexOf("newPlt") == -1){
                    pallets.push(pallet);
                }
            })
            angular.forEach(vm.palletize.pallets, function(pallet){
                if(pallet.actPkgs == pallet.pkgs && pallet.palletNum && pallet.palletNum.indexOf("newPlt") == -1){
                    pallets.push(pallet);
                }
            })
            vm.palletize.pallets = pallets;
        }



        //获取未匹配库存
        function getUnallotedCargos (cargosCopy) {
            vm.palletize.unAllotedCargos = [];
            var actGw, actVol;
            angular.forEach(cargosCopy, function (cargo) {
                if(cargo.pkgs){
                    actGw = actVol = 0;
                    if(cargo.vol){
                        actVol += cargo.pkgs * cargo.vol;
                    }
                    if(cargo.gw){
                        actGw += cargo.pkgs * cargo.gw;
                    }
                    var newCargo = {};
                    newCargo.sku = cargo.sku;
                    newCargo.gw = DataUtils.round(actGw, 2);
                    newCargo.lbs = DataUtils.round(actGw * 2.20462262, 2);
                    newCargo.vol = DataUtils.round(actVol, 2);
                    newCargo.vw = DataUtils.round(actVol * 166.66666, 2);
                    newCargo.pkgs = cargo.pkgs;
                    newCargo.length = cargo.length;
                    newCargo.width = cargo.width;
                    newCargo.height = cargo.height;
                    vm.palletize.unAllotedCargos.push(newCargo);
                }
            })
        }

        //合并相同SKU
        function combineSku () {
            var ttlPkgs;
            var updated = false;
            angular.forEach(vm.palletize.pallets, function(pallet){
                var skuSet = new Set();//每个托盘的所有SKU号
                angular.forEach(pallet.cargos, function(cargo){
                    skuSet.add(cargo.sku);
                })
                //按SKU合并
                skuSet.forEach(function(sku){
                    ttlPkgs = 0;
                    angular.forEach(pallet.cargos, function(cargo){
                        if(sku == cargo.sku){
                            ttlPkgs += cargo.pkgs;
                        }
                    })
                    updated = false;
                    for(var i = pallet.cargos.length -1; i >=0; i--){
                        if(pallet.cargos[i].sku == sku){
                            if(updated){
                                pallet.cargos.splice(i, 1);
                            }
                            else {
                                pallet.cargos[i].pkgs = ttlPkgs;
                                updated = true;
                            }
                        }
                    }
                })
            })

            //获取实际重量 体积
            var actGw, actVol;
            angular.forEach(vm.palletize.pallets, function(pallet){
                actGw = actVol = 0;
                angular.forEach(pallet.cargos, function(cargo){
                    if(cargo.pkgs){
                        if(cargo.vol){
                            actVol += cargo.pkgs * cargo.vol;
                        }
                        if(cargo.gw){
                            actGw += cargo.pkgs * cargo.gw;
                        }
                    }
                })
                pallet.actGw = DataUtils.round(actGw, 2);
                pallet.actLbs = DataUtils.round(actGw * 2.20462262, 2);
                pallet.actVol = DataUtils.round(actVol, 2);
                pallet.actVw = DataUtils.round(actVol * 166.66666, 2);
            })


        }

        function importPallets (file) {
            if (!file) {return;}
            var fd = new FormData();
            fd.append('file', file);
            $http.post(
                'api/wms-ins/importPallets', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    layer.msg($translate.instant("global.importSuccess"));
                    vm.palletize.importDataCopy = angular.fromJson(data);
                    allotPallets(angular.fromJson(data));
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                });
        };

        $scope.wmsPltLabels = function(reportType) {
            var wmsInEntity = angular.copy(vm.wmsIn);
            wmsInEntity.palletizeJson = angular.toJson(vm.palletize);
            $scope.format = 1;
            WmsIn.wmsPltLabels({
                reportType: reportType,
                fileType: $scope.format
            }, wmsInEntity, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        $scope.amendPltNum = function(pallet) {
            layer.prompt({
                formType: 0,
                value: "",
                title: "Pallet#"
            }, function(value, index, elem) {
                layer.close(index);
                angular.forEach(vm.palletize.rawData, function(data){
                    if(data.palletNum == pallet.palletNum){
                        data.palletNum = value;
                    }
                })
                pallet.palletNum = value;
                $translate.refresh();
            });
        }

    }
})();
