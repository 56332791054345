(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CustomDeclarationGoodsDetailController', CustomDeclarationGoodsDetailController);

    CustomDeclarationGoodsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'CustomDeclarationGoods'];

    function CustomDeclarationGoodsDetailController($scope, $rootScope, $stateParams, previousState, entity, CustomDeclarationGoods) {
        var vm = this;

        vm.customDeclarationGoods = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('autopsApp:customDeclarationGoodsUpdate', function(event, result) {
            vm.customDeclarationGoods = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
