(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('PoDialogController', PoDialogController);

    PoDialogController.$inject = ['$scope', '$state', '$rootScope', '$http', '$translate', '$location', '$timeout', '$uibModalInstance', '$uibModal', 
    'entity', 'WinAES', 'WmsIn', 'WmsCargo', 'Po', 'ManageEnterprise', 'DataUtils', 'ReportTemplate'];

    function PoDialogController($scope, $state, $rootScope, $http, $translate, $location, $timeout, $uibModalInstance, $uibModal,
        entity, WinAES, WmsIn, WmsCargo, Po, ManageEnterprise, DataUtils, ReportTemplate) {
        var vm = this;
        vm.wmsIn = entity;
        vm.addCargo = addCargo;
        vm.delCargo = delCargo;
        vm.save = save;
        vm.updateTtl = updateTtl;    
        vm.updateVol = updateVol;              

        vm.poVenderSelected = poVenderSelected;
        vm._onGoodsSelected = _onGoodsSelected;
        vm.getAllGoods = getAllGoods;
        vm.importCargo = importCargo;
        vm.getBarcode = getBarcode;
        vm.copyCargo = copyCargo;
        vm.updateShipmentPkgAmt = updateShipmentPkgAmt;   
        vm.deleteOne = deleteOne;
        vm.generateWmsIn = generateWmsIn;
        vm.clearWmsGoods = clearWmsGoods;

        vm.files = [];

        function clearWmsGoods (cargo) {     
            cargo.code = null;
            cargo.wmsGoodsId = null;
            cargo.cargoName = null;
            cargo.packageType = null;
            cargo.gw = null;
            cargo.vol = null;
            cargo.length = null;
            cargo.width = null;
            cargo.height = null;
            cargo.isPallet = null;
            cargo.palletCapacity = null;
            cargo.pkgs = null;
            cargo.palletAmt = null;
        }
        
        function updateShipmentPkgAmt(cargo) {
            if (!cargo.pkgs || !cargo.palletCapacity){
                cargo.palletAmt = null;
                return;
            }
            var palletAmtFloat =  cargo.pkgs / cargo.palletCapacity;
            cargo.palletAmt = parseInt(palletAmtFloat);
            if (palletAmtFloat != cargo.palletAmt){
                cargo.palletAmt++;
            }
        }
        function generateCustoms () {
            vm.isSaving = true;
            WmsIn.generateCustoms(vm.wmsIn, onGenerateSuccess, onGenerateError);
        }

        function onGenerateSuccess(result) {
            vm.wmsIn = result;
            updateGwVolTtl();
            if (vm.wmsIn.customDeclarationId){
                vm.wmsIn.encodeIdCus = WinAES.encode(vm.wmsIn.customDeclarationId.toString());
            }              
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submitted'));
        }

        function updateGwVolTtl () {
            angular.forEach(vm.wmsIn.cargoList, function(data){
                if(data.pkgs){
                    if(data.nw){
                        data.nwTtl = DataUtils.round(data.nw * data.pkgs, 4);
                    }
                    if(data.gw){
                        data.gwTtl = DataUtils.round(data.gw * data.pkgs, 4);
                    }
                    if(data.vol){
                        data.volTtl = DataUtils.round(data.vol * data.pkgs, 4);
                    }
                    if(data.capacity){
                        data.capacityTtl = DataUtils.round(data.capacity * data.pkgs, 2);
                    }
                    if(data.piece){
                        data.pieceTtl = DataUtils.round(data.piece * data.pkgs, 2);
                    }
                    if(data.goodsValue){
                        data.goodsValueTtl = DataUtils.round(data.goodsValue * data.pkgs, 2);
                    }
                }
            })
        }

        function copyCargo (cargo) {
            var cargoCopy = angular.copy(cargo);
            cargoCopy.id = null;
            cargoCopy.inTime = null;
            cargoCopy.createTime = null;
            cargoCopy.location = null;
            cargoCopy.locationId = null;
            cargoCopy.allOut = false;
            cargoCopy.pkgs = null;
            cargoCopy.warehouseId = null;
            cargoCopy.areaId = null;
            cargoCopy.palletAmt = null;
            cargoCopy.gateInTime = null;
            cargoCopy.remarks = null;
            cargoCopy.status = null;
            cargoCopy.billDate = null;
            cargoCopy.lastCargoId = null;
            cargoCopy.returnHandled = null;
            vm.wmsIn.cargo
            vm.wmsIn.cargoList.push(cargoCopy);
        }


        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        $scope.clear = function () {
            $uibModalInstance.dismiss();                       
        }

        var path = $location.path();
        var wmsInCopy = {};
        $timeout(function() {
            if (path.indexOf("copy") >= 0) {
                vm.wmsIn.id = null;
                vm.wmsIn.status = "new";
                vm.wmsIn.updateTime = null;
                vm.wmsIn.warehousingNumber = null;
                vm.wmsIn.actualNum = null;
                vm.wmsIn.registerBy = null;
                vm.wmsIn.registerId = null;
                vm.wmsIn.createTime = null;
                vm.wmsIn.inTime = null;
                vm.wmsIn.allOut = false;
                vm.wmsIn.outNum = null;
                vm.wmsIn.applicationNum = null;
                vm.wmsIn.gw = null;    
                vm.wmsIn.vol = null;     
                vm.wmsIn.vol = null;   
                vm.wmsIn.guideId = null; 
                vm.wmsIn.poNum = null;   
                vm.wmsIn.ctnNum = null;   
                vm.wmsIn.truckNum = null;   
                vm.wmsIn.wmsInNum = null;   
                vm.wmsIn.wmsOutNum = null;   
                vm.wmsIn.remarks = null;   
                vm.wmsIn.audited = null;  
                vm.wmsIn.returnTracking = null; 
                vm.wmsIn.poDate = null; 
                vm.wmsIn.jobNum = null;   
                vm.wmsIn.isOut = false;
                vm.wmsIn.customDeclarationId = null;
                vm.wmsIn.customReleased = false;
                vm.wmsIn.customNum = null;
                if (vm.wmsIn.cargoList && vm.wmsIn.cargoList.length >0){
                    for (var i = 0; i < vm.wmsIn.cargoList.length; i ++){
                        vm.wmsIn.cargoList[i].id = null;
                        vm.wmsIn.cargoList[i].inTime = null;
                        vm.wmsIn.cargoList[i].outTime = null;
                        vm.wmsIn.cargoList[i].location = null;
                        vm.wmsIn.cargoList[i].locationId = null;
                        vm.wmsIn.cargoList[i].allOut = false;
                        vm.wmsIn.cargoList[i].pkgs = null;
                        vm.wmsIn.cargoList[i].warehouseId = null;
                        vm.wmsIn.cargoList[i].areaId = null;
                        vm.wmsIn.cargoList[i].palletAmt = null;
                        vm.wmsIn.cargoList[i].gateInTime = null;
                        vm.wmsIn.cargoList[i].remarks = null;
                        vm.wmsIn.cargoList[i].status = null;
                        vm.wmsIn.cargoList[i].poNum = null;
                        vm.wmsIn.cargoList[i].ctnNum = null;
                        vm.wmsIn.cargoList[i].truckNum = null;
                        vm.wmsIn.cargoList[i].wmsInNum = null;
                        vm.wmsIn.cargoList[i].wmsOutNum = null;
                        vm.wmsIn.cargoList[i].billDate = null;
                        vm.wmsIn.cargoList[i].palletAmt = null;
                        vm.wmsIn.cargoList[i].lastCargoId = null;
                        vm.wmsIn.cargoList[i].oldLabel = null;
                        vm.wmsIn.cargoList[i].newLabel = null;
                        vm.wmsIn.cargoList[i].returnHandled = null;
                    }
                }
                angular.copy(vm.wmsIn, wmsInCopy);
            }
            else if (path.indexOf("new") >= 0){
                vm.wmsIn = {
                    cargoList: [{
                        allOut:false, 
                        action:"appIn", 
                        returnHandle: null, 
                        returnHandled: null}],
                    type: "WMS",
                    handleType: "wmsService",
                    isOut: false,
                    freeDays: $rootScope.account.freeDays,
                    status: "new",
                    allOut: false
                }
                angular.copy(vm.wmsIn, wmsInCopy);
            }
            else {
                angular.copy(vm.wmsIn, wmsInCopy);
            }
            updateGwVolTtl();
        });
        var rateUnsubscribe = $rootScope.$on('autopsApp:wmsWorkLogUpdate', function(event, result) {
            if(result && result.id){
                var isNew = true;
                angular.forEach(vm.wmsWorkLogs, function(data){
                    if(data.id == result.id){
                        data.count = result.count;
                        data.work = result.work;
                        isNew = false;
                    }
                })
                if(isNew){
                    vm.wmsWorkLogs.push(result)
                }
            }
        });
        $scope.$on('$destroy', rateUnsubscribe);


        function getBarcode (cargo, type) {
            if (cargo.allOut){return;}
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-dialog.html',
                controller: 'WmsInDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md'
            }).result.then(function(code) {
                cargo[type] = code;                
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        function addCargo () {
            if (!vm.wmsIn.cargoList){
                vm.wmsIn.cargoList = [];
            }
            vm.wmsIn.cargoList.push({
                allOut:false,
                action:"appIn"
            });
        }
        function delCargo (cargo) {
            if (cargo.id != null) {
                WmsCargo.delete({
                    id: cargo.id
                }, function(data){
                    var index = vm.wmsIn.cargoList.indexOf(cargo);
                    if (index > -1) {
                        vm.wmsIn.cargoList.splice(index, 1);
                    };
                    updateTtl();
                    save();
                }, $rootScope.ALERT_ERROR);
            } else {
                var index = vm.wmsIn.cargoList.indexOf(cargo);
                if (index > -1) {
                    vm.wmsIn.cargoList.splice(index, 1);
                };
                updateTtl();
            }
        };

        function onSaveSuccess (result) {
            vm.wmsIn = result;
            updateGwVolTtl();
            wmsInCopy = angular.copy(vm.wmsIn);
            vm.isSaving = false;
            $scope.$emit('autopsApp:poUpdate', result);
            layer.msg($translate.instant('global.save_success'));
        }

        function onSaveError (result) {
            vm.isSaving = false;
            if (result.status == 409) {
                layer.msg($translate.instant('global.refreshAlert'));
            } 
            else if (result.status == 423) {
                layer.msg($translate.instant('autopsApp.wmsIn.auditedAlert'));
            }
            else if (result.status == 403) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.cargoInAlert'));
            } 
            else {
                layer.msg($translate.instant('global.save_fail'));
            }
        }

        function updateVol () {
            vm.wmsIn.vol = 0;
            for (var i = 0; i < vm.wmsIn.cargoList.length; i ++){                
                if (vm.wmsIn.cargoList[i].length && vm.wmsIn.cargoList[i].width && vm.wmsIn.cargoList[i].height){
                    vm.wmsIn.cargoList[i].vol = vm.wmsIn.cargoList[i].length/100 * vm.wmsIn.cargoList[i].width/100 * vm.wmsIn.cargoList[i].height/100;
                    vm.wmsIn.cargoList[i].vol = vm.wmsIn.cargoList[i].vol.toFixed(4) -0;
                }
                if (vm.wmsIn.cargoList[i].vol && vm.wmsIn.cargoList[i].pkgs){
                    vm.wmsIn.vol = vm.wmsIn.vol + vm.wmsIn.cargoList[i].vol * vm.wmsIn.cargoList[i].pkgs;
                }
            }
            if (vm.wmsIn.vol){
                vm.wmsIn.vol = vm.wmsIn.vol.toFixed(3) -0;
            }
            else {
                vm.wmsIn.vol = null;
            }
            updateTtl();
        }
        function updateTtl () {
            if (!vm.wmsIn.cargoList || vm.wmsIn.cargoList.length == 0){
                return;
            }
            vm.wmsIn.gw = 0;
            vm.wmsIn.vol = 0;
            vm.wmsIn.applicationNum = 0;
            vm.wmsIn.poValue = 0;

            for (var i = 0; i < vm.wmsIn.cargoList.length; i ++){
                if(!vm.wmsIn.cargoList[i].vol){
                    if (vm.wmsIn.cargoList[i].length && vm.wmsIn.cargoList[i].width && vm.wmsIn.cargoList[i].height){
                        vm.wmsIn.cargoList[i].vol = vm.wmsIn.cargoList[i].length/100 * vm.wmsIn.cargoList[i].width/100 * vm.wmsIn.cargoList[i].height/100;
                        vm.wmsIn.cargoList[i].vol = vm.wmsIn.cargoList[i].vol.toFixed(4) -0;
                    }
                }
                if (vm.wmsIn.cargoList[i].pkgs){
                    vm.wmsIn.applicationNum = vm.wmsIn.applicationNum + vm.wmsIn.cargoList[i].pkgs;
                    if(vm.wmsIn.cargoList[i].vol){
                        vm.wmsIn.cargoList[i].volTtl = (vm.wmsIn.cargoList[i].vol * vm.wmsIn.cargoList[i].pkgs).toFixed(4) -0;
                        vm.wmsIn.vol += vm.wmsIn.cargoList[i].volTtl;
                    }
                    if(vm.wmsIn.cargoList[i].gw){
                        vm.wmsIn.cargoList[i].gwTtl = (vm.wmsIn.cargoList[i].gw * vm.wmsIn.cargoList[i].pkgs).toFixed(2) -0;
                        vm.wmsIn.gw += vm.wmsIn.cargoList[i].gwTtl;
                    }
                    if(vm.wmsIn.cargoList[i].nw){
                        vm.wmsIn.cargoList[i].nwTtl = (vm.wmsIn.cargoList[i].nw * vm.wmsIn.cargoList[i].pkgs).toFixed(2) -0;
                    }
                    if(vm.wmsIn.cargoList[i].capacity){
                        vm.wmsIn.cargoList[i].capacityTtl = (vm.wmsIn.cargoList[i].capacity * vm.wmsIn.cargoList[i].pkgs).toFixed(2) -0;
                    }
                    if(vm.wmsIn.cargoList[i].piece){
                        vm.wmsIn.cargoList[i].pieceTtl = (vm.wmsIn.cargoList[i].piece * vm.wmsIn.cargoList[i].pkgs).toFixed(2) -0;
                    }
                    if(vm.wmsIn.cargoList[i].goodsValue){
                        vm.wmsIn.cargoList[i].goodsValueTtl = (vm.wmsIn.cargoList[i].goodsValue * vm.wmsIn.cargoList[i].pkgs).toFixed(2) -0;
                        vm.wmsIn.poValue += vm.wmsIn.cargoList[i].goodsValueTtl;
                    }
                }
                updateShipmentPkgAmt(vm.wmsIn.cargoList[i]);
            }
            if (vm.wmsIn.gw){
                vm.wmsIn.gw = vm.wmsIn.gw.toFixed(2) -0;
            }
            else {
                vm.wmsIn.gw = null;
            }

            if (vm.wmsIn.vol){
                vm.wmsIn.vol = vm.wmsIn.vol.toFixed(3) -0;
            }
            else {
                vm.wmsIn.vol = null;
            }

            if (vm.wmsIn.applicationNum){
                vm.wmsIn.applicationNum = vm.wmsIn.applicationNum.toFixed(0) -0;
            }
            else {
                vm.wmsIn.applicationNum = null;
            } 
            vm.wmsIn.poValue = vm.wmsIn.poValue.toFixed(2) -0;
        }

        $scope.salesFilter = function(item) {
            return item.authorities.indexOf("ROLE_SALES") !== -1;
        };
        $scope.updateSales = function(item) {
            vm.wmsIn.salesId = item.id;
        };

        function generateWmsIn () {
            vm.isSaving = true;
            Po.generateWmsIn({id: vm.wmsIn.id}, onSaveSuccess, onSaveError);
        }
        function save () {
            vm.isSaving = true;
            Po.save(vm.wmsIn, onSaveSuccess, onSaveError);
        }

        function getAllGoods () {
            if(!vm.wmsIn.poVenderId && $rootScope.account.companyId){
                return;
            }
            if (!$rootScope.WMSGOODS || $rootScope.WMSGOODS.length == 0){
                ManageEnterprise.getAllGoods({venderId:vm.wmsIn.poVenderId}, function(result) {
                    $rootScope.WMSGOODS = result;
                })
            }
        }

        function poVenderSelected (client) {
            vm.wmsIn.poVenderId = client.id;
            vm.wmsIn.poVender = client.companyName;     
            $rootScope.WMSGOODS = [];
        }

        function _onGoodsSelected (data, cargo) {
            cargo.wmsGoodsId = data.wmsGoodsId;//batchid
            goodsSelected (data, cargo);
        }

        function goodsSelected (data, cargo) {
            cargo.code = data.code;
            cargo.sku = data.sku;
            cargo.cargoName = data.cargoName;
            cargo.packageType = data.packageType;
            cargo.gw = data.gw;
            cargo.pkgs = null;
            cargo.palletAmt = null;
            cargo.vol = data.vol;
            cargo.length = data.length;
            cargo.width = data.width;
            cargo.height = data.height;
            cargo.isPallet = data.isPallet;
            cargo.palletCapacity = data.palletCapacity;
            cargo.chargeType = data.chargeType;
            cargo.chargePrice = data.chargePrice;
            cargo.piece = data.piece;
            cargo.pieceUnit = data.pieceUnit;
            cargo.nw = data.nw;
            cargo.capacity = data.capacity;
            cargo.capacityUnit = data.capacityUnit;
            cargo.goodsValue = data.goodsValue;
            cargo.declareValue = data.declareValue;
            cargo.dutyRate = data.dutyRate;
            if(cargo.cargoName){
                if(!vm.wmsIn.cargoDescription){
                    vm.wmsIn.cargoDescription = cargo.cargoName;
                }
            }
        }

        function deleteOne (wmsIn) {
            if ($rootScope.HAVENOAUTH('AU_DEL_BIZ')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_DEL_BIZ');
                return;
            }      
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-delete-dialog.html',
                controller: 'WmsInDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return wmsIn;
                    }]
                }
            }).result.then(function() {
                $uibModalInstance.close(vm.wmsIn);  
            }, function() {
            });
            
        }
        function importCargo (file) {
            if (!file) {return;}
            var fd = new FormData();
            fd.append('file', file);
            if(vm.wmsIn.venderId){
                fd.append('customerId', vm.wmsIn.venderId);
            }            
            $http.post(
                'api/wms-cargos/importByExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    if (!data || data.length == 0){
                        layer.msg($translate.instant('global.messages.resultNull'));  
                        return;
                    }
                    data = angular.fromJson(data);
                    if (!data || data.length == 0){
                        layer.msg($translate.instant('global.messages.resultNull'));  
                        return;
                    }
                    if(vm.wmsIn.cargoList 
                        && vm.wmsIn.cargoList.length == 1 
                        && !vm.wmsIn.cargoList[0].id 
                        && !vm.wmsIn.cargoList[0].pkgs){
                        vm.wmsIn.cargoList  = [];
                    }
                    angular.forEach(data, function (cargo) {
                        vm.wmsIn.cargoList.push(cargo);
                    })           
                    updateTtl();
                    layer.msg($translate.instant('global.importSuccess'));
                    if(vm.wmsIn.id && vm.wmsIn.companyId){
                        $rootScope.UPLOAD_FILE_WITH_NAME(file, '/wmsIn/' + vm.wmsIn.id + '/', vm.files, vm.wmsIn.companyId, null);
                    }
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));                           
                });
        };

    }
})();
