(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('VgmHead', VgmHead);

    VgmHead.$inject = ['$resource', 'DateUtils'];

    function VgmHead ($resource, DateUtils) {
        var resourceUrl =  'api/vgm-heads/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.lastModifiedTime = DateUtils.convertDateTimeFromServer(data.lastModifiedTime);
                        data.sendTime = DateUtils.convertDateTimeFromServer(data.sendTime);
                        data.vgmDeadline = DateUtils.convertDateTimeFromServer(data.vgmDeadline);
                    }
                    return data;
                }
            },
            'update': { 
                method:'PUT',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.lastModifiedTime = DateUtils.convertDateTimeFromServer(data.lastModifiedTime);
                        data.sendTime = DateUtils.convertDateTimeFromServer(data.sendTime);
                        data.vgmDeadline = DateUtils.convertDateTimeFromServer(data.vgmDeadline);
                    }
                    return data;
                }
            },
            'save': {
                method:'POST',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.lastModifiedTime = DateUtils.convertDateTimeFromServer(data.lastModifiedTime);
                        data.sendTime = DateUtils.convertDateTimeFromServer(data.sendTime);
                        data.vgmDeadline = DateUtils.convertDateTimeFromServer(data.vgmDeadline);
                    }
                    return data;
                }
            },
            'getFilterPage':{
                method: 'POST',
                url:'api/vgm-heads/getFilterPage',
                isArray: true
            },
            'sendVgm':{
                method: 'GET',
                url: 'api/vgm-heads/sendVgm/:id'
            },
            'getVgmHeadByGuideId':{
                method: 'GET',
                url: 'api/vgm-heads/byGuideId',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.lastModifiedTime = DateUtils.convertDateTimeFromServer(data.lastModifiedTime);
                        data.sendTime = DateUtils.convertDateTimeFromServer(data.sendTime);
                        data.vgmDeadline = DateUtils.convertDateTimeFromServer(data.vgmDeadline);
                    }
                    return data;
                }
            },
            'updateStatus':{
                method: 'GET',
                url: 'api/vgm-heads/updateStatus/:id',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.lastModifiedTime = DateUtils.convertDateTimeFromServer(data.lastModifiedTime);
                        data.sendTime = DateUtils.convertDateTimeFromServer(data.sendTime);
                        data.vgmDeadline = DateUtils.convertDateTimeFromServer(data.vgmDeadline);
                    }
                    return data;
                }
            },

        });
    }
})();
