(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('FbaWmsInDialogController', FbaWmsInDialogController);

    FbaWmsInDialogController.$inject = ['$scope', '$rootScope', '$timeout', '$http', '$uibModal', '$translate', '$uibModalInstance',
        'entity', 'ManageEnterprise', 'WmsIn', 'DataUtils', 'OpsVender', 'OpsCarrier', 'WmsWarehouse', 
        'Guide', 'AddressBook', 'ExpressParcel', 'BookingCtns', 'OpsFiles', 'OpsCost'
    ];

    function FbaWmsInDialogController($scope, $rootScope, $timeout, $http, $uibModal, $translate, $uibModalInstance, 
        entity, ManageEnterprise, WmsIn, DataUtils, OpsVender, OpsCarrier, WmsWarehouse, 
        Guide, AddressBook, ExpressParcel, BookingCtns, OpsFiles, OpsCost) {
        var vm = this;
        vm.bizType = entity.type;
        vm.addCargo = addCargo;
        vm.delCargo = delCargo;
        vm.submitIn = submitIn;
        vm.updateTtl = updateTtl;
        vm.updateVol = updateVol;
        vm.updatePltVol = updatePltVol;
        vm.focusCargo = focusCargo;
        vm.getWarehouses = getWarehouses;
        vm.warehouseSelected = warehouseSelected;
        vm._onLocationSelected = _onLocationSelected;
        vm.countrySelected = countrySelected;
        vm.getLocations = getLocations;
        vm.getBarcode = getBarcode;
        vm.getShipment = getShipment;
        vm.receivingNote = receivingNote;
        vm.autoRemoteCheck = autoRemoteCheck;
        vm.clear = clear;

        vm.locations = [];
        $scope.guide = {};
        vm.thisCargo = {};
        vm.isSaving = false;
        vm.splitCargo = splitCargo;
        vm.initRapidIn = initRapidIn;

        var initGuidePl = function() {
            if (!$scope.guide.booking.bookingCtns || $scope.guide.booking.bookingCtns.length == 0) {
                $scope.guide.booking.bookingCtns = [];
                vm.thisCargo = angular.copy(newCargo);
                vm.thisCargo.pkgNum = $scope.guide.pkgNum;
                vm.thisCargo.gw = $scope.guide.gw;
                vm.thisCargo.vol = $scope.guide.vol;
                if(vm.locations && vm.locations.length > 0){
                    _onLocationSelected (vm.locations[0], vm.thisCargo);
                }    
                $scope.guide.booking.bookingCtns.push(vm.thisCargo);
            }
            angular.forEach($scope.guide.booking.bookingCtns, function(data){
                if (data.pkgNum) {
                    if (data.gw) {
                        data.gwTtl = DataUtils.round(data.pkgNum * data.gw, 2);
                    }
                    if (data.nw) {
                        data.nwTtl = DataUtils.round(data.pkgNum * data.nw, 2);
                    }
                    if (data.vol) {
                        data.volTtl = DataUtils.round(data.pkgNum * data.vol, 5);
                    }
                }
                if($scope.guide.destCountryCode){
                    if(!data.importCountryCode){
                        data.importCountryCode = $scope.guide.destCountryCode;
                    }
                    if(data.mixedPls && data.mixedPls.length > 0){
                        angular.forEach(data.mixedPls, function(pl){
                            if(!pl.importCountryCode){
                                pl.importCountryCode = $scope.guide.destCountryCode;
                            }
                        })
                    }
                }   
                if(vm.locations && vm.locations.length > 0){
                     _onLocationSelected (vm.locations[0], data);    
                }                         
            })        

            if(!$scope.guide.cargoType){
                $scope.guide.cargoType = "generalCargo";
            }
            if(!$scope.guide.destCountryCode){
                $scope.guide.destCountryCode = "US";
                $scope.guide.destCountryName = "美国";
            }
            else{
                $scope.guide.destCountryName = $rootScope.getCountryName($scope.guide.destCountryCode);
            }
            if(!$scope.guide.shipmentType){
                $scope.guide.shipmentType = vm.bizType == "WMS"?"WMS":"FBASEA";
            }    
            vm.pkgsTtl = $scope.guide.pkgNum;
            getWarehouses();
        };
        function getShipment() {
            if (!$scope.guide.jobNum) {
                return;
            }
            vm.isSaving = true;
            Guide.getRapidInGuide({
                jobNum: $scope.guide.jobNum
            }, function(result) {
                $scope.guide = result;
                initGuidePl();
                vm.isSaving = false;
                layer.msg($translate.instant('entity.action.loadSuccess'));
            }, function(result){
                vm.isSaving = false;
                if(result.status == 406){
                    layer.msg("It's FCL shipment!");
                }
                else if(result.status == 403){
                    layer.msg($translate.instant('autopsApp.guide.home.guideNotDeliveriedAlert'));                    
                }
                else if(result.status == 404){
                    layer.msg($translate.instant('global.noRecord'));
                }
                else {
                    layer.msg($translate.instant('global.loadFailed'));
                }
            })
        }

        $timeout(function() {
            if($rootScope.account.authorities.indexOf('FBA') === -1 && $rootScope.account.authorities.indexOf('WMS') === -1){
                layer.msg($translate.instant('global.forbidden'));
                clear();
            }            
            if(entity && entity.guideId){
                Guide.getRapidInGuide({
                    id: entity.guideId
                }, function(result) {
                    $scope.guide = result;
                    initGuidePl();
                });
            }         
        });


        function clear () {
            $uibModalInstance.close();                     
        }
        function getBarcode (obj, fd) {
            if(obj.locked){
                return;
            }
            getLocations();
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-dialog.html',
                controller: 'WmsInDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md'
            }).result.then(function(code) {
                if (fd == "code"){ 
                    angular.forEach(vm.locations, function(data){
                        if(data.code == code){
                            _onLocationSelected(data, obj);
                        }
                    });
                }
                else {
                    obj[fd] = code;
                }
                if(fd =="jobNum"){
                    getShipment();
                }
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        function _onLocationSelected (item, cargo) {
            cargo.location = item.code;
            angular.forEach($scope.guide.booking.bookingCtns, function (data) {
                if(!data.location){
                    data.location = item.code;
                }
            })
        }
        function countrySelected (item) {
            $scope.guide.destCountryCode = item.code;
        }

        function getLocations () {
            if(!$scope.guide.whId){
                layer.msg($translate.instant('autopsApp.wmsIn.chooseWarehouseAlert'));
                return;
            }
            if(vm.isSaving){
                return;
            }
            if (!vm.locations || vm.locations.length == 0){
                vm.isSaving = true;
                WmsWarehouse.getLocations({warehouseId: $scope.guide.whId}, function (data) {
                    vm.isSaving = false;
                    vm.locations = data;   
                    chooseLocation();             
                });
            }
        }

        var chooseLocation = function(){
            if(vm.locations 
                && vm.locations.length > 0 
                && $scope.guide.booking.bookingCtns 
                && $scope.guide.booking.bookingCtns.length > 0){                        
                angular.forEach($scope.guide.booking.bookingCtns, function(cargo){
                    _onLocationSelected (vm.locations[0], cargo);
                })
            }    
        }

        var newCargo = {pkgType:"CTNS", pkgNum:1};
        function initRapidIn() {
            $scope.guide = {
                createdBy: $rootScope.account.id,
                pkgNum: 1,
                shipmentType: vm.bizType == "WMS"?"WMS":"FBASEA",
                cargoType: "generalCargo",
                destCountryCode: "US",
                destCountryName: $rootScope.getCountryName("US"),
                deliveryWay: "express",
                status: "wmsIn",
                chargeByCbm: false,
                booking: {
                    divVolBy: 6000,
                    bookingCtns: []
                }
            }
            vm.thisCargo= angular.copy(newCargo);
            if(vm.locations && vm.locations.length > 0){
                _onLocationSelected (vm.locations[0], vm.thisCargo);
            }           
            $scope.guide.booking.bookingCtns.push(vm.thisCargo);
            getWarehouses();
        }
        initRapidIn();       

        function addCargo() {
            if (!$scope.guide.booking.bookingCtns) {
                $scope.guide.booking.bookingCtns = [];
            }
            updateTtl();
            if(vm.pkgsTtl && $scope.guide.pkgNum >= vm.pkgsTtl){
                $rootScope.ALERT_ALARM();
                $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.amtAlert', {actAmt: $scope.guide.pkgNum, limit: vm.pkgsTtl}), {icon: 0, shade: 0.3})
                // return;
            }

            vm.thisCargo= angular.copy(newCargo);
            if(vm.locations && vm.locations.length > 0){
                _onLocationSelected (vm.locations[0], vm.thisCargo);
            }            
            $scope.guide.booking.bookingCtns.splice(0, 0, vm.thisCargo);   
            $timeout(function() {
                $("#gw_field").focus();
            });
        }

        function focusCargo(cargo) {
            vm.thisCargo = cargo;
        }
        function delCargo(cargo) {
            if(cargo.id){
                return;
            }
            var index = $scope.guide.booking.bookingCtns.indexOf(cargo);
            if (index > -1) {
                $scope.guide.booking.bookingCtns.splice(index, 1);
            };
        }


        function getWarehouses () {
            if($rootScope.account.authorities.indexOf('WMS') === -1){
                return;
            }
            if (vm.warehouses && vm.warehouses.length > 0){
                if(!$scope.guide.whId){
                    warehouseSelected(vm.warehouses[0]);
                }    
                return;
            }
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false,
                    shipmentType: $scope.guide.shipmentType
                }, function (data) {
                vm.warehouses = data;
                if(vm.warehouses.length > 0 && !$scope.guide.whId){
                    warehouseSelected(vm.warehouses[0]);
                    getLocations();
                }                
            });
        }
        getWarehouses();

        function warehouseSelected (warehouse) {
            $scope.guide.whName = warehouse.name;            
            $scope.guide.companyId = warehouse.companyId;

            if($scope.guide.whId != warehouse.id){
                $scope.guide.whId = warehouse.id;
                vm.locations = [];
                getLocations();
            }            
        }

        function submitIn() {
            // if(!$scope.guide.shipperId){
            //     layer.msg($translate.instant('autopsApp.wmsIn.clientNullAlert'));                
            //     return;
            // }
            for(var i = $scope.guide.booking.bookingCtns.length  -1; i >= 0; i--){
                if(!$scope.guide.booking.bookingCtns[i].id){
                    if(!$scope.guide.booking.bookingCtns[i].pkgNum){
                        $scope.guide.booking.bookingCtns.splice(i, 1);
                    }
                    else if (!$scope.guide.booking.bookingCtns[i].sku 
                        && !$scope.guide.booking.bookingCtns[i].cargoName 
                        && !$scope.guide.booking.bookingCtns[i].gw
                        && !$scope.guide.booking.bookingCtns[i].length
                        && !$scope.guide.booking.bookingCtns[i].width
                        && !$scope.guide.booking.bookingCtns[i].height
                        && !$scope.guide.booking.bookingCtns[i].vol){
                        $scope.guide.booking.bookingCtns.splice(i, 1);
                    }     
                }
            }
            angular.forEach($scope.guide.booking.bookingCtns, function(data){
                if(data.pallets){
                    data.ctnType = (data.pltlength?data.pltlength:"") + " X " + (data.pltwidth?data.pltwidth:"") + " X " + (data.pltheight?data.pltheight:"");
                }
                else{
                    data.ctnType = null;
                }
            })
            if($rootScope.HAVEAUTH('WMS')){
                if(!$scope.guide.whId){
                    layer.msg($translate.instant('autopsApp.wmsIn.chooseWarehouseAlert'));                
                    return;
                }
                // for(var i = 0; i < $scope.guide.booking.bookingCtns.length; i++){
                //     if ($scope.guide.booking.bookingCtns[i].pkgNum 
                //         && !$scope.guide.booking.bookingCtns[i].location){
                //         var msg = $scope.guide.booking.bookingCtns[i].cargoName + ": " + $translate.instant('global.choose_location_alert');
                //         $rootScope.OPS_ALERT(msg);
                //         return;
                //     }     
                // }
            } 
            vm.isSaving = true;
            var wmsRapidInDTO = {};
            wmsRapidInDTO.guide = $scope.guide;
            wmsRapidInDTO.wmsLocations = vm.locations;            
            Guide.fbaRegistIn(wmsRapidInDTO, onSaveSuccess, onSaveError);          
        }
        function onSaveSuccess (result) {
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submitted'));
            $scope.guide = result;
            getShipment()
            // initRapidIn();
        }

        function onSaveError (result) {
            vm.isSaving = false;
            if (result.status == 409) {
                layer.msg($translate.instant('autopsApp.wmsIn.jobNumConflictAlert'));
            }
            else if (result.status == 406) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.home.overdue'));
            } 
            else {
                layer.msg($translate.instant('global.save_fail'));
            }
        }


        function updateTtl (isPkg) {
            $scope.guide.gw = 0;
            $scope.guide.vol = 0;
            $scope.guide.pkgNum = 0;

            angular.forEach($scope.guide.booking.bookingCtns, function(data){
                if(!data.pkgNum){
                    data.pkgNum = 0;
                }
                $scope.guide.pkgNum += data.pkgNum;
                if(data.gw){
                    data.gwTtl = DataUtils.round(data.gw * data.pkgNum, 2);  
                    $scope.guide.gw += data.gw * data.pkgNum;
                }
                if(data.vol){
                    data.volTtl = DataUtils.round(data.vol * data.pkgNum, 4);  
                    $scope.guide.vol += data.vol * data.pkgNum;
                }
            })
            $scope.guide.gw = DataUtils.round($scope.guide.gw, 2);
            $scope.guide.vol = DataUtils.round($scope.guide.vol, 4);

            if(isPkg && vm.pkgsTtl && $scope.guide.pkgNum >= vm.pkgsTtl){
                $rootScope.ALERT_ALARM();
                $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.amtAlert', {actAmt: $scope.guide.pkgNum, limit: vm.pkgsTtl}), {icon: 0, shade: 0.3})
            }
        }
        
        function updateVol (cargo) {
            if (cargo.length && cargo.width && cargo.height){
                cargo.vol = cargo.length * cargo.width * cargo.height/1000000;
                cargo.vol = DataUtils.round(cargo.vol, 4);
                updateTtl();
            }
        }
        function updatePltVol (cargo) {
            if (cargo.pltlength && cargo.pltwidth && cargo.pltheight){
                if(cargo.pallets){
                    if(cargo.pkgNum){
                        cargo.vol = cargo.pltlength * cargo.pltwidth * cargo.pltheight/1000000 * cargo.pallets/cargo.pkgNum;
                        cargo.vol = DataUtils.round(cargo.vol, 4);
                        updateTtl();
                    }
                }
            }
        }

        $(document).keydown(function(event) {            
            if (event.keyCode == 13) {
                if ("gw_field" == document.activeElement.id) {
                    $("#field_length").focus();
                }
                else if ("field_length" == document.activeElement.id) {
                    $("#field_width").focus();
                }
                else if ("field_width" == document.activeElement.id) {
                    $("#field_height").focus();
                }                
                else if ("field_height" == document.activeElement.id) {
                    $timeout(function (){
                        $("#button_addCargo").focus();
                    });
                }
            } 
        });
        $(document).keydown(function(event) {            
            if (event.keyCode == 83 && event.ctrlKey) {
                event.preventDefault();
                submitIn();              
            } 
            else if (event.ctrlKey && (event.keyCode == 13 || event.keyCode == 107)) {
                event.preventDefault();
                addCargo();  
            }                    
        });

        vm.pkgsTtl = null;
        $scope.wmsLabels = function(reportType) {
            if(!$scope.guide.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            var guide = angular.copy($scope.guide);
            guide.pkgNum = vm.pkgsTtl;
            WmsIn.wmsRapidLabels({
                reportType: reportType,
                fileType: 1
            }, guide, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        $scope._selected = function($item) {
            $scope.guide.booking.deliverytoId = $item.code;
            $scope.guide.deliverytoId = $scope.guide.booking.deliverytoId;

            $scope.guide.deliveryTo = $scope.guide.booking.deliveryTo;

            $scope.guide.shiptoPostcode = $item.postCode;
            $scope.guide.destCountryCode = $item.countryCode;
            $scope.guide.shiptoPostcode = $item.postCode;   
            $scope.guide.destCountryName = $rootScope.getCountryName($scope.guide.destCountryCode);
            AddressBook.getFbaAddress({code: $item.code, countryCode: $item.countryCode}, function(result){
                if(result){
                    DataUtils.onGuideAddressChoosed($scope.guide, result);
                    $scope.guide.destCountryName = $rootScope.getCountryName($scope.guide.destCountryCode);

                }                                    
            }); 
            autoRemoteCheck();
            
        }

        $scope.deliveryWayChange = function() {
            // if($scope.guide.deliveryWay == "express" || $scope.guide.shipmentType == "FBAAIR" || $scope.guide.shipmentType == "EXPRESS"){
            //     $scope.guide.chargeByCbm = false;
            //     $scope.guide.booking.divVolBy = 6000;
            // }
            // else{
            //     $scope.guide.chargeByCbm = true;
            //     $scope.guide.booking.divVolBy = 1000;  
            // }
        };

        function autoRemoteCheck(){
            if(!$scope.guide.destCountryCode 
                || !$scope.guide.shiptoPostcode || $scope.guide.shiptoPostcode.length < 5
                || !$scope.guide.booking.carrierFullName){
                return;
            }
            ExpressParcel.remoteCheck({
                country: $scope.guide.destCountryCode,
                postcode: $scope.guide.shiptoPostcode,
                carrier: $scope.guide.booking.carrierFullName
            }, function(results){
                if(results && results.length > 0){
                    $scope.guide.isRemote = true;
                    $rootScope.OPS_ALERT($scope.guide.booking.carrierFullName + ": is Remote!");
                }
                else {
                    $scope.guide.isRemote = false;
                    layer.msg($scope.guide.booking.carrierFullName + ": Not Remote!");
                }
            }, function () {
            });
        }

        
        $scope.gwTtlChange = function(data) {
            data.gw = DataUtils.round(data.gwTtl / data.pkgNum, 2);
            $scope.guide.gw = 0;
            angular.forEach($scope.guide.booking.bookingCtns, function(data){
                if(data.gwTtl){
                    $scope.guide.gw += data.gwTtl;
                }
            })
            $scope.guide.gw = DataUtils.round($scope.guide.gw, 2);
        };

        $scope.volTtlChange = function(data) {
            data.vol = DataUtils.round(data.volTtl / data.pkgNum, 2);
            $scope.guide.vol = 0;
            angular.forEach($scope.guide.booking.bookingCtns, function(data){
                if(data.volTtl){
                    $scope.guide.vol += data.volTtl;
                }
            })
            $scope.guide.vol = DataUtils.round($scope.guide.vol, 2);
        };

        function splitCargo (cargo) {
            var pkgNum = cargo.pkgNum;
            layer.prompt({
              formType: 0,
              value: pkgNum,
              title: $translate.instant('autopsApp.wmsIn.splitCargoTitle'),
              area: ['280px', '160px'] 
            }, function(value, index, elem){
                value = value -0;
                if (!value || value == cargo.pkgNum){
                    layer.close(index);
                    return;
                }                          
                if (isNaN(value) || parseInt(value) != value || value > cargo.pkgNum){
                    layer.msg($translate.instant('autopsApp.wmsIn.notNumAlert'));
                    return;
                }
                cargo.pkgNum = value-0;

                BookingCtns.splitCargoCom(cargo, function(result) {
                    $scope.guide.booking.bookingCtns.push(result);
                    if(cargo.pallets){
                        cargo.pallets -= result.pallets;
                    }
                    $translate.refresh();  
                });          
                layer.close(index);
                $translate.refresh();
            });
        }

        function receivingNote(reportType){
            var guideCopy = angular.copy($scope.guide);
            // angular.forEach(guideCopy.booking.bookingCtns, function (data) {
            //     if (!data.wmsCargoId && data.pkgsCount){
            //         data.pkgs = data.pkgsCount;
            //         data.location = data.locationCopy;
            //         data.locationId = data.locationIdCopy;
            //         data.areaId = data.areaIdCopy;
            //         data.warehouseId = data.warehouseIdCopy;
            //         data.keeper = data.keeperCopy;
            //         data.keeperId = data.keeperIdCopy;
            //         data.allOut = true;
            //     }         
            // })
            OpsFiles.receivingNoteGuide({reportType: reportType}, guideCopy, function(result){
                $rootScope.PRINTBYTES(result.data);
            },function(error){
                layer.msg($translate.instant('autopsApp.wmsIn.viewWmsInPaperFailed'));
            });
        }
        $scope.customRequiredChange = function() {
            $scope.guide.customRequired = !$scope.guide.customRequired;
        }
        $scope.isRemoteChange = function() {
            $scope.guide.isRemote = !$scope.guide.isRemote;
        }
        $scope.onChannelSelected = function(carrier) {
            $scope.guide.booking.carrierCode = carrier.code;
            $scope.guide.booking.carrierFullName = carrier.name;
            if(carrier.volCoe || carrier.volumeShare){
                if(carrier.volCoe){
                    $scope.guide.booking.divVolBy = carrier.volCoe;
                }
                if(carrier.volumeShare){
                    $scope.guide.booking.volumeShare = carrier.volumeShare;
                }
            }
            autoRemoteCheck();
        }

    }
})();