(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('ParcelGoodsSearch', ParcelGoodsSearch);

    ParcelGoodsSearch.$inject = ['$resource'];

    function ParcelGoodsSearch($resource) {
        var resourceUrl =  'api/_search/parcel-goods/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
