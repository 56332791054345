(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('DataSubscriptionDeleteController',DataSubscriptionDeleteController);

    DataSubscriptionDeleteController.$inject = ['$uibModalInstance', '$rootScope', 'entity', 'DataSubscription'];

    function DataSubscriptionDeleteController($uibModalInstance, $rootScope, entity, DataSubscription) {
        var vm = this;

        vm.dataSubscription = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            DataSubscription.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                }, $rootScope.ALERT_ERROR);
        }
    }
})();
