(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountRefController', AccountRefController);

    AccountRefController.$inject = ['$scope', '$state', '$rootScope', '$translate', '$uibModal', '$timeout',
        'AccountRef', 'AccountRefSearch'];

    function AccountRefController($scope, $state, $rootScope, $translate, $uibModal, $timeout,
        AccountRef, AccountRefSearch) {

        $scope.accountRefs = [];
        $scope.accountRef = {};

        $scope.loadAll = function() {
            AccountRef.query(function(result) {
               $scope.accountRefs = result;
            });
        };

        $scope.loadAll();
        $scope.search = function () {
            AccountRefSearch.query({query: $scope.searchQuery}, function(result) {
                $scope.accountRefs = result;
            }, function(response) {
                if(response.status === 404) {
                    $scope.loadAll();
                }
            });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.accountRef = {
                companyId: null,
                code: null,
                ref: null,
                id: null
            };
        };



        $scope.edit = function(accountRef) {
            var entity = accountRef;
            if (!accountRef){
                entity = {
                    code: null,
                    ref: null,
                    companyId: $rootScope.account.companyId,
                    id: null
                };
            }
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountRef/accountRef-dialog.html',
                controller: 'AccountRefDialogController',
                size: 'md',
                resolve: {
                    entity: function () {
                        return entity;
                    }
                }
            }).result.then(function(result) {
                $scope.loadAll();
            }, function() {
            })
        };

        $scope.delete = function (ref) {
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountRef/accountRef-delete-dialog.html',
                controller: 'AccountRefDeleteController',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return ref;
                    }]
                }
            }).result.then(function(result) {
                $scope.loadAll();
            }, function() {
            })
        }
        $timeout(function (){
            $rootScope.RESIZE();
        });


    }
})();
