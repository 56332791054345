(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('EndingClosingController', EndingClosingController);

    EndingClosingController.$inject = ['$scope', '$rootScope', '$translate', '$window', '$uibModal',
    'JhiLanguageService', 'Principal', 'AccountRef', 'DateUtils','AccountVoucher','AccountTitle','VoucherContent'];

    function EndingClosingController($scope, $rootScope, $translate, $window, $uibModal,
        JhiLanguageService, Principal, AccountRef, DateUtils, AccountVoucher, AccountTitle, VoucherContent) {
        
        $scope.filterDTO = {};
        var checkAuth = function(){
            if ($rootScope.HAVENOAUTH('ROLE_DEPARTMENT')){            
                $window.history.back();
                layer.msg($translate.instant('global.forbiddenTeam'));
            }
            $scope.filterDTO.dateFrom = DateUtils.convertLocalDateFromServer($rootScope.account.accountDate);
        }
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                checkAuth();
            });
        }
        else {
            checkAuth();
        }
        
        

        $scope.isSaving = false;
        $scope.checkedOk = false;
        $scope.showCheckResult = false;

        $scope.voucherReult = [];
        $scope.checkBeforeClosing = function () {
            if (!$scope.filterDTO.dateFrom) {
                layer.msg($translate.instant('autopsApp.accountTitle.monthNullAlert'));
                return;
            }
            $scope.isSaving = true;
            $scope.voucherReult = {};
            $scope.filterDTO.companyId = $rootScope.account.companyId;
            layer.load(1, { shade: 0.3 });
            AccountVoucher.getAccountClosing($scope.filterDTO, function(result) {
                layer.closeAll();
                $scope.voucherReult = result;
                $scope.isSaving = false;
                if (!$scope.voucherReult || $scope.voucherReult.length ==0){
                    $scope.checkedOk = true;
                }
                else {
                    $scope.checkedOk = false;
                    $scope.showCheckResult = true;
                }               
                $translate.refresh();
            }, function(response) {
                $scope.isSaving = false;
                $rootScope.ALERT_ERROR(response);
           });

        }
        $scope.isSaving = false;


        var onAccountingSuccess = function(result) {
            $scope.checkedOk = false;
            $rootScope.account.accountDate = getNextMonth($rootScope.account.accountDate);
            $rootScope.OPS_ALERT($translate.instant('autopsApp.accountTitle.onClosingSuccess'));
        };

        var onAccountingError = function(result) {
            $scope.checkedOk = false;
            $rootScope.OPS_ALERT($translate.instant('autopsApp.accountTitle.onClosingError'));
        };
        
        $scope.doClosing = function () {    
            if($rootScope.ISBETA()){
                layer.msg($translate.instant('global.betaAlert'));
                return;
            }
            if (!$scope.filterDTO.dateFrom) {
                layer.msg($translate.instant('autopsApp.accountTitle.monthNullAlert'));
                return;
            }
            var date = DateUtils.convertLocalDateToServer($scope.filterDTO.dateFrom);
            var aDate = date.split("-");
            var oDate1 = aDate[1];
            if (oDate1 == "12") {
                var title = $translate.instant('global.confirm');
                var content = $translate.instant('autopsApp.accountTitle.yearClosingAlert', {year: aDate[0]});
                layer.confirm(content, {
                    title: title,
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function() {
                    layer.closeAll();
                    $scope.filterDTO.companyId = $rootScope.account.companyId;
                    $scope.isSaving = true;
                    AccountVoucher.doAccountClosing($scope.filterDTO, onAccountingSuccess, onAccountingError);
                    return;
                }, function() {
                    return;
                });
                return;
            }

            $scope.filterDTO.companyId = $rootScope.account.companyId;
            $scope.isSaving = true;
            AccountVoucher.doAccountClosing($scope.filterDTO, onAccountingSuccess, onAccountingError);
        }

        $scope.getVoucher = function(voucher) {
            if (!voucher || !voucher.id) {
                return;
            }
            $rootScope.VIEWVOUCHER(voucher.id, $scope.voucherReult);
        };

        /**
         * 获取下一个月
         *
         * @date 格式为yyyy-mm-dd的日期，如：2014-01-25
         */        
        function getNextMonth(date) {
            var arr = date.split('-');
            var year = arr[0]; //获取当前日期的年份
            var month = arr[1]; //获取当前日期的月份
            var day = arr[2]; //获取当前日期的日
            var days = new Date(year, month, 0);
            days = days.getDate(); //获取当前日期中的月的天数
            var year2 = year;
            var month2 = parseInt(month) + 1;
            if (month2 == 13) {
                year2 = parseInt(year2) + 1;
                month2 = 1;
            }
            var day2 = day;
            var days2 = new Date(year2, month2, 0);
            days2 = days2.getDate();
            if (day2 > days2) {
                day2 = days2;
            }
            if (month2 < 10) {
                month2 = '0' + month2;
            }
        
            var t2 = year2 + '-' + month2 + '-' + day2;
            return t2;
        }


    }
})();
