(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('RequestResetController', RequestResetController);

    RequestResetController.$inject = ['$translate', '$rootScope', '$timeout', 'Auth'];

    function RequestResetController ($translate, $rootScope, $timeout, Auth) {
        var vm = this;

        vm.error = null;
        vm.errorEmailNotExists = null;
        vm.requestReset = requestReset;
        vm.resetAccount = {};
        vm.success = null;

        $timeout(function (){angular.element('#email').focus();});

        function requestReset () {
            vm.error = null;
            vm.errorEmailNotExists = null;

            Auth.resetPasswordInit(vm.resetAccount.email).then(function () {
                vm.success = 'OK';
            }).catch(function (response) {
                vm.success = null;
                if (response.status === 400 && response.data === 'e-mail address not registered') {
                    vm.errorEmailNotExists = 'ERROR';
                } else {
                    if (response.status === 403 && response.data === 'Is Demo') {
                        layer.msg($translate.instant('global.demoAlert'));
                    }
                    vm.error = 'ERROR';
                }
            });
        }
    }
})();
