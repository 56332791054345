(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('AccountVoucher', AccountVoucher);

    AccountVoucher.$inject = ['$resource','DateUtils'];
    
    function AccountVoucher($resource, DateUtils) {
        return $resource('api/accountVouchers/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    data.makeDate = DateUtils.convertLocalDateFromServer(data.makeDate);
                    return data;
                }
            },
            'setVoidAudit': {
                method: 'GET',
                url:'api/accountVouchers/setVoidAudit',
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    data.makeDate = DateUtils.convertLocalDateFromServer(data.makeDate);
                    return data;
                }
            },
            'getRatesList': {
                method: 'GET',
                isArray: true,
                url:'api/accountVouchers/getRatesList/:id',
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    data.makeDate = DateUtils.convertLocalDateFromServer(data.makeDate);
                    return data;
                }
            },            
            'getVoucherByDnCN': {
                url:'api/autoVoucher/getVoucherByDnCN',
                method: 'POST',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    if(data.accountPayment){
                        data.accountPayment.deadLine = DateUtils.convertLocalDateToServer(data.accountPayment.deadLine);
                        data.accountPayment.economicDate = DateUtils.convertLocalDateToServer(data.accountPayment.economicDate);
                        data.accountPayment.accountDate = DateUtils.convertLocalDateToServer(data.accountPayment.accountDate);
                    }
                    if(data.accountInvoice){
                        data.accountInvoice.deadLine = DateUtils.convertLocalDateToServer(data.accountInvoice.deadLine);
                        data.accountInvoice.economicDate = DateUtils.convertLocalDateToServer(data.accountInvoice.economicDate);
                        data.accountInvoice.accountDate = DateUtils.convertLocalDateToServer(data.accountInvoice.accountDate);
                    }
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    data.makeDate = DateUtils.convertLocalDateFromServer(data.makeDate);
                    return data;
                }
            },
            'getAccountTransfer': {
                url:'api/getAccountTransfer',
                method: 'PUT',
                transformRequest: function (data) {
                    if (!data){return;} 
                    var data2 = angular.copy(data);
                    data2.dateFrom = DateUtils.convertLocalDateToServer(data2.dateFrom);
                    data2.dateTo = DateUtils.convertLocalDateToServer(data2.dateTo);
                    return angular.toJson(data2);
                }
            },
            'checkAssist': {
                url:'api/accountVouchers/checkAssist',
                method: 'PUT',
                isArray:true
            }, 
            'accountTransferPrevew': {
                url:'api/accountTransfer',
                method: 'POST',
                transformRequest: function (data) {
                    var data2 = angular.copy(data);
                    data2.dateFrom = DateUtils.convertLocalDateToServer(data2.dateFrom);
                    data2.dateTo = DateUtils.convertLocalDateToServer(data2.dateTo);
                    return angular.toJson(data2);
                },
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    data.makeDate = DateUtils.convertLocalDateFromServer(data.makeDate);
                    return data;
                }
            },
            'getAdjustVoucher': {
                url:'api/exchangeAdjust/getAdjustVoucher',
                method: 'POST',
                transformRequest: function (data) {
                    var data2 = angular.copy(data);
                    data2.dateFrom = DateUtils.convertLocalDateToServer(data2.dateFrom);
                    data2.dateTo = DateUtils.convertLocalDateToServer(data2.dateTo);
                    data2.cutDate = DateUtils.convertLocalDateToServer(data2.cutDate);
                    return angular.toJson(data2);
                },
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    data.makeDate = DateUtils.convertLocalDateFromServer(data.makeDate);
                    return data;
                }
            },  
            'doAccountTransfer': {
                url:'api/accountTransfer',
                method: 'PUT',
                transformRequest: function (data) {
                    data.makeDate = DateUtils.convertLocalDateToServer(data.makeDate);
                    return angular.toJson(data);
                }
            },
            'antiTransfer': {
                url:'api/unaccountTransfer',
                method: 'POST',
                transformRequest: function (data) {
                    var data2 = angular.copy(data);
                    data2.dateFrom = DateUtils.convertLocalDateToServer(data2.dateFrom);
                    data2.dateTo = DateUtils.convertLocalDateToServer(data2.dateTo);
                    return angular.toJson(data2);
                }
            },
            'getAccountClosing': {
                url:'api/accountClosing',
                method: 'PUT',
                isArray: true,
                transformRequest: function (data) {
                    var data2 = angular.copy(data);
                    data2.dateFrom = DateUtils.convertLocalDateToServer(data2.dateFrom);
                    data2.dateTo = DateUtils.convertLocalDateToServer(data2.dateTo);
                    return angular.toJson(data2);
                }
            },
            'delBoth': {
                url:'api/accountVouchers/delBoth',
                method: 'POST'
            },
            'delAll': {
                url:'api/accountVouchers/delAll',
                method: 'POST'
            },
            'doAccountClosing': {
                url:'api/accountClosing',
                method: 'POST',
                transformRequest: function (data) {
                    var data2 = angular.copy(data);
                    data2.dateFrom = DateUtils.convertLocalDateToServer(data2.dateFrom);
                    data2.dateTo = DateUtils.convertLocalDateToServer(data2.dateTo);
                    return angular.toJson(data2);
                }
            },
            'antiAccountClosing': {
                url:'api/unaccountClosing',
                method: 'PUT',
                transformRequest: function (data) {
                    var data2 = angular.copy(data);
                    data2.dateFrom = DateUtils.convertLocalDateToServer(data2.dateFrom);
                    data2.dateTo = DateUtils.convertLocalDateToServer(data2.dateTo);
                    return angular.toJson(data2);
                }
            },
            'getAccountingResult': {
                url:'api/accountingVouchers',
                method: 'PUT',
                transformRequest: function (data) {
                    var data2 = angular.copy(data);
                    data2.dateFrom = DateUtils.convertLocalDateToServer(data2.dateFrom);
                    data2.dateTo = DateUtils.convertLocalDateToServer(data2.dateTo);
                    return angular.toJson(data2);
                }
            },
            'doAccounting': {
                url:'api/accountingVouchers',
                method: 'POST',
                transformRequest: function (data) {
                    for (var i = 0; i <data.length; i++){
                        data[i].makeDate = DateUtils.convertLocalDateToServer(data[i].makeDate);
                    }                    
                    return angular.toJson(data);
                }
            },
            'antiBookingkeeping': {
                url:'api/unaccountingVouchers',
                method: 'POST',
                transformRequest: function (data) {
                    var data2 = angular.copy(data);
                    data2.dateFrom = DateUtils.convertLocalDateToServer(data2.dateFrom);
                    data2.dateTo = DateUtils.convertLocalDateToServer(data2.dateTo);
                    return angular.toJson(data2);
                }
            },
            'updateList': {
                method: 'PUT',
                url:'api/updateAccountVouchers',
                isArray:true,
                transformRequest: function (data) {
                    for(var i=0; i<data.length; i++){
                        data[i].makeDate = DateUtils.convertLocalDateToServer(data[i].makeDate);
                    }   
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    for(var i=0; i<data.length; i++){
                        data[i].makeDate = DateUtils.convertLocalDateFromServer(data[i].makeDate);
                    }          
                    return data;
                }
            },            
            'getAllByFiler': {
                method: 'PUT',
                url:'api/accountVouchersByFilter',
                isArray:true,
                transformRequest: function (data) {
                    var data2 = angular.copy(data);
                    data2.makeDate = DateUtils.convertLocalDateToServer(data2.makeDate);
                    data2.makeDateFM = DateUtils.convertLocalDateToServer(data2.makeDateFM);
                    data2.makeDateTO = DateUtils.convertLocalDateToServer(data2.makeDateTO);
                    data2.economicDate = DateUtils.convertLocalDateToServer(data2.economicDate);
                    data2.economicDateFM = DateUtils.convertLocalDateToServer(data2.economicDateFM);
                    data2.economicDateTO = DateUtils.convertLocalDateToServer(data2.economicDateTO);
                    return angular.toJson(data2);
                },
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    for(var i=0; i<data.length; i++){
                        data[i].makeDate = DateUtils.convertLocalDateFromServer(data[i].makeDate);
                    }          
                    return data;
                }
            },
            'voucherNumTrim': {
                method: 'GET',
                url:'api/accountVouchers/voucherNumTrim',
                isArray:true,
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    for(var i=0; i<data.length; i++){
                        data[i].makeDate = DateUtils.convertLocalDateFromServer(data[i].makeDate);
                    }          
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.makeDate = DateUtils.convertLocalDateToServer(data.makeDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    data.makeDate = DateUtils.convertLocalDateFromServer(data.makeDate);       
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.makeDate = DateUtils.convertLocalDateToServer(data.makeDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (!data){return;} 
                    data = angular.fromJson(data);
                    data.makeDate = DateUtils.convertLocalDateFromServer(data.makeDate);       
                    return data;
                }
            }
        });
    }
})();
