(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsTemplateDialogController', OpsTemplateDialogController);

    OpsTemplateDialogController.$inject = ['$scope', '$uibModalInstance', 'entity'];
    
    function OpsTemplateDialogController($scope, $uibModalInstance, entity) {

        $scope.opsTemplate = entity;

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

}
})();
