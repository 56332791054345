(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsFactoryDialogController', OpsFactoryDialogController);

    OpsFactoryDialogController.$inject = ['$scope', '$rootScope', '$translate', '$timeout', '$uibModalInstance', '$uibModal', 
    'entity', 'OpsFactory', 'OpsVender'];

    function OpsFactoryDialogController($scope, $rootScope, $translate, $timeout, $uibModalInstance, $uibModal, 
        entity, OpsFactory, OpsVender) {

        $scope.guide = entity;
        $scope.opsFactories = [];

        $timeout(function() {
            OpsFactory.getAll({
                guideId: $scope.guide.id
            }, function(result) {
                $scope.opsFactories = result;
            });
        })
        var onSaveSuccess = function(result) {
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
        };

        $scope.saveList = function() {
            $scope.isSaving = true;
            OpsFactory.save($scope.opsFactories, onSaveSuccess, onSaveError);
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.shipperName = "";
        $scope._onClientSelected = function(client) {
            var isRepeat = false;
            angular.forEach($scope.opsFactories, function(data) {
                if (data.vId == client.id) {
                    isRepeat = true;
                }
            })
            if (isRepeat) {
                layer.msg($translate.instant('global.messages.repeated'));
                return;
            }
            OpsVender.getDTO({
                id: client.id
            }, function(result) {
                var newFactory = {};
                newFactory.vId = result.venderId;
                newFactory.companyName = result.companyName;
                newFactory.address = result.address;
                newFactory.guideId = $scope.guide.id;

                if(result.contactList.length == 1){
                    newFactory.attn = result.contactList[0].attn;
                    newFactory.tel = result.contactList[0].tel;
                    newFactory.fax = result.contactList[0].fax;
                    newFactory.mob = result.contactList[0].mob;                
                    newFactory.cc = result.contactList[0].cc;
                    newFactory.email = result.contactList[0].email;
                    $scope.opsFactories.push(newFactory);
                }
                else {
                    $rootScope.chooseContactVenderType = "client";
                    $uibModal.open({
                        templateUrl: 'app/entities/guide/contact-list.html',
                        controller: 'ContactListController',
                        size: 'lg',
                        resolve: {
                            entity: [function() {
                                return result.contactList;
                            }]
                        }
                    }).result.then(function(contact) {
                        newFactory.attn = contact.attn;
                        newFactory.tel = contact.tel;
                        newFactory.fax = contact.fax;
                        newFactory.mob = contact.mob;                
                        newFactory.cc = contact.cc;
                        newFactory.email = contact.email;
                        $scope.opsFactories.push(newFactory);
                        $translate.refresh();
                    }, function() {});
                }
            });
            $scope.shipperName = "";
        }

        $scope.delFactory = function(factory) {
            if (factory.id) {
                OpsFactory.delete({
                    id: factory.id
                }, function() {
                    var index = $scope.opsFactories.indexOf(factory);
                    if (index > -1) {
                        $scope.opsFactories.splice(index, 1);
                    };
                });
            } else {
                var index = $scope.opsFactories.indexOf(factory);
                if (index > -1) {
                    $scope.opsFactories.splice(index, 1);
                };
            }

        };
        $scope.showContactList = function(factory) {
            if ($rootScope.HAVENOAUTH('FUN_VIEW_SHIPPER')) {
                $rootScope.FORBIDDEN_BY_ALERT('FUN_VIEW_SHIPPER');   
                return;
            }
            if (!factory.vId) {
                return;
            }
            var contact = {};
            contact.attn = factory.attn;
            contact.tel = factory.tel;
            contact.fax = factory.fax;
            contact.mob = factory.mob;
            contact.email = factory.email;
            contact.venderId = factory.vId;
            $rootScope.chooseContactVenderType = "client";
           
            $uibModal.open({
                templateUrl: 'app/entities/ops-change-log/ops-change-log-detail.html',
                controller: 'OpsChangeLogDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        return contact;
                    }]
                }
            }).result.then(function(result) {
                factory.attn = result.attn;
                factory.tel = result.tel;
                factory.fax = result.fax;
                factory.mob = result.mob;                
                factory.cc = result.cc;
                factory.email = result.email;
            }, function() {});
        }



    }
})();