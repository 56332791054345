(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ManageEnterpriseDialogController', ManageEnterpriseDialogController);

    ManageEnterpriseDialogController.$inject = ['$timeout', '$translate', '$scope', '$rootScope','$stateParams', '$uibModalInstance', 
    'entity', 'ManageEnterprise'];

    function ManageEnterpriseDialogController ($timeout, $translate, $scope, $rootScope, $stateParams, $uibModalInstance, 
        entity, ManageEnterprise) {
    	var vm = this;

        vm.manageEnterprise = entity;
        vm.clear = clear;
        vm.save = save;
        vm.clientSelected = clientSelected;
        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        var unsubscribe = $rootScope.$on('autopsApp:manageEnterpriseUpdate', function(event, result) {
        	vm.manageEnterprise = result;
        });
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            if(!vm.manageEnterprise.name){
                vm.manageEnterprise.venderId = null;
            }
            if (vm.manageEnterprise.id !== null) {
            	ManageEnterprise.update(vm.manageEnterprise, onSaveSuccess, onSaveError);
            } else {
            	vm.manageEnterprise.goodsList = [];
            	ManageEnterprise.save(vm.manageEnterprise, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('autopsApp:manageEnterpriseUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError (result) {
            if (result.status == 409){
                layer.msg($translate.instant('autopsApp.manageEnterprise.clientRepeat'));
            }
            vm.isSaving = false;
        }

        $rootScope.LOADALLVENDERS();
        
        function clientSelected (client) {
            vm.manageEnterprise.venderId = client.id;
            vm.manageEnterprise.name = client.companyName;
        }


    }
})();
