(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsInListController', WmsInListController);

    WmsInListController.$inject = ['$timeout', '$rootScope', '$scope', '$uibModalInstance', '$translate', 
    'entity', 'WmsIn', 'Guide', 'WinAES'];

    function WmsInListController ($timeout, $rootScope, $scope, $uibModalInstance, $translate, 
        entity, WmsIn, Guide, WinAES) {
        var vm = this;
        vm.clear = clear;
        vm.wmsIn = entity.wmsIn;
        vm.guide = entity.guide;
        vm.wmsIns = [];
        vm.ttlLine = {};
        vm.unbindGuide = unbindGuide;
        vm.bindGuide = bindGuide
        vm.getGuide = getGuide;
        vm.jobNum = "";
        vm.warehousingNumber = "";

        vm.isOut = null;
        $timeout(function (){
            if(vm.wmsIn.id){
                if(vm.wmsIn.isOut){
                   vm.isOut = true; 
                }
                else {
                    vm.isOut = false; 
                }
            } 
            WmsIn.getListById({
                guideId: vm.guide.id,
                wmsId: vm.wmsIn.id
            }, function(ressult){
                vm.wmsIns = ressult;
                angular.forEach(vm.wmsIns, function(data){
                    data.codeId = WinAES.encode(data.id.toString());
                    if (data.guideId){
                        data.encodeId = WinAES.encode(data.guideId.toString());
                    }                    
                })
                updateTtl();
            })
            if(vm.guide.id){
                vm.guide.encodeId = WinAES.encode(vm.guide.id.toString());
            }            
        });

        function updateTtl () {           
            vm.ttlLine.gw = 0; 
            vm.ttlLine.vol = 0; 
            vm.ttlLine.pkgs = 0;
            angular.forEach(vm.wmsIns, function(data){
                if (data.applicationNum){
                    vm.ttlLine.pkgs += data.applicationNum;
                }    
                if (data.gw){
                    vm.ttlLine.gw += data.gw;
                }   
                if (data.vol){
                    vm.ttlLine.vol += data.vol;
                }                   
            })
            vm.ttlLine.gw = vm.ttlLine.gw.toFixed(2) - 0; 
            vm.ttlLine.vol = vm.ttlLine.vol.toFixed(3) - 0;  
            vm.ttlLine.pkgs = vm.ttlLine.pkgs.toFixed(0) - 0; 
        }

        function getGuide () {
            Guide.getGuideByJobNum({
                companyId: $rootScope.account.companyId,
                jobNum: vm.guide.jobNum,
                wmsInId: vm.wmsIn.id
            }, function(result){
                vm.guide = result;
                vm.guide.encodeId = WinAES.encode(vm.guide.id.toString());
                WmsIn.getListById({
                    guideId: result.id
                }, function(ressult){
                    vm.wmsIns = ressult;
                    angular.forEach(vm.wmsIns, function(data){
                        data.codeId = WinAES.encode(data.id.toString());
                        if (data.guideId){
                            data.encodeId = WinAES.encode(data.guideId.toString());
                        }                    
                    })
                    updateTtl();
                })
            }, function(error){
                if (error.status == 406) {
                    layer.msg($translate.instant('autopsApp.wmsIn.bizClosedAlert'));
                }
            })
        }

        function unbindGuide (wmsIn) {
            WmsIn.unbindGuide({
                wmsInId: wmsIn.id
            }, function(result){
                var index = vm.wmsIns.indexOf(wmsIn);
                if (index > -1) {
                    vm.wmsIns.splice(index, 1);
                };
                updateTtl();
            }, function(error){
                if (error.status == 406) {
                    layer.msg($translate.instant('autopsApp.wmsIn.bizClosedAlert'));
                }
            })
        }
        
        function bindGuide () {
            if(!vm.guide.id || !vm.warehousingNumber){
                return;
            }
            WmsIn.bindGuide({
                companyId: $rootScope.account.companyId,
                guideId: vm.guide.id,
                jobNum: vm.guide.jobNum,
                warehousingNumber: vm.warehousingNumber
            }, function(result){
                if(vm.isOut == null || result.isOut == vm.isOut){
                    vm.wmsIns.push(result);
                    vm.isOut = result.isOut;
                    updateTtl();
                }
                else {                    
                    layer.msg($translate.instant('autopsApp.wmsIn.inOutConflict'));
                }
            }, function(error){
                if (error.status == 400) {
                    layer.msg($translate.instant('global.messages.resultNull'));
                }
                else if (error.status == 406) {
                    layer.msg($translate.instant('autopsApp.wmsIn.bizClosedAlert'));
                }
                else if (error.status == 409) {
                    layer.msg($translate.instant('autopsApp.wmsIn.bindedAlert'));
                }
                else {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));        
                }
            })
        }
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
    }
})();
