(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountCashierController', AccountCashierController);

    AccountCashierController.$inject = ['$rootScope', '$scope', '$state', '$http', '$translate', '$uibModal', '$timeout',
    'Principal', 'JhiLanguageService', 'AccountCashier', 'AccountCashierSearch', 'ParseLinks', 'AlertService', 'pagingParams',
    'AccountTitle', 'paginationConstants', 'DateUtils', 'DataUtils', 'GridState', 'uiGridConstants'];

    function AccountCashierController($rootScope, $scope, $state, $http, $translate, $uibModal, $timeout,
        Principal, JhiLanguageService, AccountCashier, AccountCashierSearch, ParseLinks, AlertService, pagingParams,
        AccountTitle, paginationConstants, DateUtils, DataUtils, GridState, uiGridConstants) {

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                loadLedger();
            });
        } else {
            loadLedger();
        }

        $timeout(function (){
            if($rootScope.HAVENOAUTH('CMIS')){
                $state.go('home');
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                return;
            }
        })

        var lang = "";
        JhiLanguageService.getCurrent().then(function(language) {
            lang = language;
        });

        var vm = this;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.onLedgerChoosed = onLedgerChoosed;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;

        vm.ledgers = [];
        vm.payers = [];
        vm.uploadDepositFile = uploadDepositFile;
        vm.searchPop = searchPop;
        vm.isAdvanceSearch = false;

        vm.generateReport = generateReport;
        vm.choosedAccount = {};


        loadAll();
        var gridCopy;
        var pageLoaded = false;
        var filterTimer;
        function loadAll() {
            if (!vm.choosedAccount || !vm.choosedAccount.id || !$rootScope.account.companyId) {
                return;
            }
            if (pagingParams.search) {
                AccountCashierSearch.query({
                    companyId: $rootScope.account.companyId,
                    accountId: vm.choosedAccount.id,
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                var filter = {};
                filter.accountId = vm.choosedAccount.id;
                filter.companyId = $rootScope.account.companyId;
                if(gridCopy){
                    angular.forEach(gridCopy.columns, function(column){
                        angular.forEach(column.filters, function(cf){
                            if(cf.term != null && angular.isDefined(cf.term)){
                                if(cf.condition){
                                    var fd = column.field;
                                    if(fd.endWith("Str")){
                                        fd = fd.replace("Str", "");
                                    }
                                    if(cf.condition == 128 || cf.condition == 256){
                                        filter[fd + "TO"] = cf.term;
                                    }
                                    else if(cf.condition == 32 || cf.condition == 64){
                                        filter[fd + "FM"] = cf.term;
                                    }
                                }
                                else {
                                    filter[column.field] = cf.term;
                                }
                            }
                        })
                    })
                }
                // layer.load(1, {shade: 0.3});
                AccountCashier.getFilterPage({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, filter, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                if(headers('X-Total-Count')){
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                }
                else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                if (!pagingParams.search && $scope.gridOptions.data.length == 0) {
                    $scope.addCashier();
                }
                vm.isAdvanceSearch = false;
                resize();
                updateByIsAdvanceSearch();
                // layer.closeAll();
            }

            function onError(error) {
                layer.closeAll();
                layer.msg($translate.instant('global.loadFailed'));
            }
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = true;
            vm.currentSearch = searchQuery;
            vm.transition();
        }


        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            if (vm.isAdvanceSearch){
                vm.itemsPerPage = paginationConstants.itemsPerPage;
                vm.isAdvanceSearch = false;
                loadAll();
            }
            else {
                vm.transition();
            }
        }


        function loadLedger() {
            AccountTitle.getAllByPrefix({
                code: "1001"
            }, function(result) {
                angular.forEach(result, function(data) {
                    vm.ledgers.push(data);
                })
                AccountTitle.getAllByPrefix({
                    code: "1002"
                }, function(result) {
                    angular.forEach(result, function(data) {
                        vm.ledgers.push(data);
                    })
                    vm.ledgers = DataUtils.generateShowLedger(vm.ledgers, true, lang);
                    if (vm.ledgers.length == 0 && $rootScope.HAVEAUTH('FMIS')) {
                        layer.msg($translate.instant('autopsApp.accountCashier.noSubledgerAvailable'));
                    }
                });
            });
        }

        function onLedgerChoosed(data) {
            vm.choosedAccount = data;
            loadAll();
        }

        $scope.editCashier = function(cashier) {
            openEditPop(cashier);
        };

        $scope.addCashier = function(cashier) {
            if (!vm.choosedAccount.id){
                return;
            }
            if(cashier && cashier.accountId != vm.choosedAccount.id){
                layer.msg($translate.instant('global.refreshAlert'));
                return;
            }
            var accountCashier = {};
            accountCashier.accountName = vm.choosedAccount.showLedger;
            accountCashier.accountId = vm.choosedAccount.id;
            accountCashier.accountCode = vm.choosedAccount.code;
            if (!vm.choosedAccount.currency) {
                accountCashier.originalCurrency = $rootScope.account.baseCurrency;
            } else {
                accountCashier.originalCurrency = vm.choosedAccount.currency;
            }
            accountCashier.cahserId = $rootScope.account.id;
            accountCashier.casherName = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            accountCashier.companyId = $rootScope.account.companyId;
            accountCashier.transactionDate = new Date();
            if (cashier && cashier.id) {
                accountCashier.transactionRef = DataUtils.getNextCode(cashier.transactionRef);
            }
            openEditPop(accountCashier);
        }


        function openEditPop(cashier) {
            var cashierDTO = {};
            cashierDTO.accountCashier = cashier;
            cashierDTO.payers = vm.payers;

            $uibModal.open({
                templateUrl: 'app/entities/account-cashier/account-cashier-dialog.html',
                controller: 'AccountCashierDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return cashierDTO;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountInvoice');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                vm.payers = result;
                loadAll();
            }, function(result) {

            });
        }

        $scope.setLocked = function(accountCashier) {
            if (accountCashier.voucherNum){
                // if (accountCashier.dnNums){
                //     $rootScope.OPS_ALERT($translate.instant('autopsApp.accountCashier.'));
                //     return;
                // }
                // else {
                // }

                layer.confirm($translate.instant('autopsApp.accountCashier.voidExpenseAlert'), {
                    title: accountCashier.voucherNum,
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                }, function(index) {
                    layer.close(index);
                    saveSetLocked(accountCashier);
                }, function() {
                    return;
                });
            }
            else {
                saveSetLocked(accountCashier);
            }
        }
        function saveSetLocked (accountCashier) {
            AccountCashier.setLocked({id : accountCashier.id}, function(result) {
                angular.copy(result, accountCashier);
                layer.msg($translate.instant('global.messages.submitted'));
                $translate.refresh();
            }, function(result) {
                if (result.status === 409){
                    layer.msg($translate.instant('autopsApp.accountCashier.lockedAlert'));
                }
                else if (result.status === 403){
                    layer.msg($translate.instant('autopsApp.accountCashier.voidVoucherAlert'));
                }
                else {
                    layer.msg($translate.instant('global.save_fail'));
                }
            });
        }

        function uploadDepositFile () {
            if (!vm.choosedAccount || !vm.choosedAccount.code){
                layer.msg($translate.instant('autopsApp.accountCashier.chooseLedgerAlert'));
                return;
            }
            $("input[name=depositFile]").trigger('click');
        }

        $("#depositFile").change(function() {
            var file = $('#depositFile').get(0).files[0];
            if (!file) {
                return;
            }
            var fd = new FormData();
            fd.append('file', file);
            fd.append('accountCode', vm.choosedAccount.code);
            fd.append('accountName', vm.choosedAccount.showLedger);
            fd.append('accountId', vm.choosedAccount.id);
            $http.post(
                'api/uploadDepositFile', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(
                function(data) {
                    if (!data.success) {
                        $rootScope.OPS_ALERT(data.msg);
                    }
                    loadAll();
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.accountCashier.uploadResult', {
                        invalidCount: data.invalidCount,
                        repeatCount: data.repeatCount,
                        newCount: data.newCount}));
                    $('#depositFile').val(null);
                }).error(function(error) {
                    if (error.msg == "initCashierAlert") {
                        layer.msg($translate.instant('autopsApp.accountCashier.initCashierAlert'));
                    }
                    else if (error.msg == "invalidBE") {
                        layer.msg($translate.instant('autopsApp.accountCashier.invalidBE'));
                    }
                    else if (error.msg == "numConflict") {
                        layer.msg($translate.instant('autopsApp.accountCashier.numConflict'));
                    }
                    else if (error.msg == "payerConflict") {
                        layer.msg($translate.instant('autopsApp.accountCashier.payerConflict'));
                    }
                    else if (error.msg == "amtNull") {
                        layer.msg($translate.instant('autopsApp.accountCashier.amtNull'));
                    }
                    else if (error.msg == "dateNull") {
                        layer.msg($translate.instant('autopsApp.accountCashier.dateNull'));
                    }
                    else if (error.msg) {
                        $rootScope.OPS_ALERT(error.msg);
                    }
                    else {
                        $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                    }
                $('#depositFile').val(null);
            });
        });

        function searchPop () {
            if (!vm.choosedAccount || !vm.choosedAccount.id){
                layer.msg($translate.instant('autopsApp.accountCashier.chooseLedgerAlert'));
                return;
            }
            var searchParams = {
                accountId: vm.choosedAccount.id,
                companyId: $rootScope.account.companyId
            };

            $uibModal.open({
                templateUrl: 'app/entities/account-cashier/account-cashier-search-dialog.html',
                controller: 'AccountCashierSearchController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(results) {
                vm.currentSearch = true;
                $scope.gridOptions.data = results;
                $scope.gridOptions.totalItems = $scope.gridOptions.data.length;
                vm.page = 1;
                vm.isAdvanceSearch = true;
                resize();
                updateByIsAdvanceSearch();
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }

        function updateByIsAdvanceSearch(searchQuery) {
            $scope.gridOptions.useExternalSorting = !$scope.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.useExternalFiltering = !$scope.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.showColumnFooter = $scope.isAdvanceSearch;
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
            $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
        }



        window.onresize = function(){
            resize();
        }

        function resize() {
            if(!$("#gridId") || !$("#gridId").offset()){
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 180;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 600){
                tabHeight = 600;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        function generateReport () {
            if (!$scope.gridOptions.data || $scope.gridOptions.data.length == 0){
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            AccountCashier.generateReport({
                fileType: 2
            }, $scope.gridOptions.data, function(result) {
                var file = new Blob([result.data], {
                    type: 'application/vnd.ms-excel'
                });
                var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
                var aTag = document.createElement("a");
                aTag.download = "cashJournal" + ".xlsx";
                aTag.href = blobURL;
                aTag.click();
            }, function(error) {
                layer.msg($translate.instant('global.requestFail'));
            });
        }

        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: true,

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: false,//每列footer

            enableFiltering: true,//高级过滤
            useExternalFiltering: true,

            enableHorizontalScrollbar : 1,//表格的水平滚动条
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)
            // enableRowHeaderSelection : true,  //行选中
            // enableRowSelection: true, //行选中
            // enableSelectAll: true, //全部选中
            // enableFullRowSelection : false, //行任意位置选中
            // multiSelect: true,//多行选中
            exporterMenuCsv : false,
            exporterMenuPdf : false,
            exporterMenuLabel : "Export",
            exporterExcelFilename: ($rootScope.ISTMS?$translate.instant('autopsApp.expressParcel.home.tmstitle'):$translate.instant('autopsApp.expressParcel.home.title')) + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],

            paginationPageSizes: [20, 35, 50, 100, 200],
            paginationPageSize: 20,
            useExternalPagination: true,

            minimumColumnSize: 70,
            columnDefs: [
                { field: 'id',
                    width: 150,
                    displayName: "",
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<div class="btn-group flex-btn-group-container"><button type="button" ng-disabled="row.entity.isLocked || row.entity.voucherId"ng-click="grid.appScope.editCashier(row.entity)"class="btn btn-primary btn-grid"><span class="glyphicon glyphicon-pencil"></span><span class="hidden-xs hidden-sm" translate="entity.action.edit"></span></button><button type="button"ng-click="grid.appScope.addCashier(row.entity)"ng-disabled="!row.entity.isLast"class="btn btn-danger btn-grid"><span class="glyphicon glyphicon-plus"></span><span class="hidden-xs hidden-sm" translate="entity.action.add"></span></button></div>'},
                { field: 'transactionRef',
                    displayName: $translate.instant('autopsApp.accountCashier.transactionRef'),
                    minWidth:120,
                    cellTemplate: '<a class="ml-5" href="" ng-click="grid.appScope.editCashier(row.entity)">{{row.entity.transactionRef}}</a>'},
                { field: 'isLocked',
                    displayName: $translate.instant('autopsApp.accountCashier.isLocked'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span ng-dblclick="grid.appScope.setLocked(row.entity)" ng-class="row.entity.isLocked?\'label-primary\':\' label-danger\'"translate="{{row.entity.isLocked?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'},
                { field: 'voucherNum',
                    displayName: $translate.instant('autopsApp.accountCashier.voucherNum'),
                    minWidth:120,
                    cellTemplate: '<a class="ml-5" href="" ng-click="grid.appScope.VIEWVOUCHER(row.entity.voucherId)">{{row.entity.voucherNum}}</a><span class="glyphicon glyphicon-ok-circle" ng-if="row.entity.voucherExported"data-toggle="tooltip" data-placement="top" title="Exported"></span>'},
                { field: 'payerName',
                    displayName: $translate.instant('autopsApp.accountCashier.payerName'),
                    minWidth:180},
                { field: 'transactionDate',
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"uib-datepicker-popup="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.accountCashier.transactionDate'),
                    minWidth:120,
                    cellTemplate: '<span class="grid-text">{{row.entity.transactionDate | date:\'mediumDate\'}}</span>'},
                { field: 'originalCurrency',
                    displayName: $translate.instant('autopsApp.accountCashier.originalCurrency'),
                    minWidth: 60},
                { field: 'originalAmt',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<strong class="ml-5 text-primary">{{row.entity.originalAmt}}</strong>',
                    displayName: $translate.instant('autopsApp.accountCashier.originalAmt'),
                    minWidth:100},
                { field: 'balance',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    cellTemplate: '<strong class="ml-5 text-success">{{row.entity.balance}}</strong><span class="text-primary ml-4 glyphicon glyphicon-refresh" ng-click="grid.appScope.refreshBalance(row.entity)"></span>',
                    displayName: $translate.instant('autopsApp.accountCashier.balance'),
                    minWidth:100},
                { field: 'accountCode',
                    displayName: $translate.instant('autopsApp.accountCashier.accountCode'),
                    minWidth:100},
                { field: 'accountName',
                    displayName: $translate.instant('autopsApp.accountCashier.accountName'),
                    minWidth:150},
                { field: 'invoiceNums',
                    displayName: $translate.instant('autopsApp.accountCashier.invoiceNums'),
                    cellTemplate: '<span class="grid-text" ng-dblclick="grid.appScope.COPYALL(row.entity.invoiceNums)">{{row.entity.invoiceNums}}</span>',
                    minWidth:150},
                { field: 'dnNums',
                    displayName: $translate.instant('autopsApp.accountCashier.dnNums'),
                    cellTemplate: '<span class="grid-text" ng-dblclick="grid.appScope.COPYALL(row.entity.dnNums)">{{row.entity.dnNums}}</span>',
                    minWidth:150},
                { field: 'blNums',
                    displayName: $translate.instant('autopsApp.accountCashier.blNums'),
                    cellTemplate: '<span class="grid-text" ng-dblclick="grid.appScope.COPYALL(row.entity.blNums)">{{row.entity.blNums}}</span>',
                    minWidth:150},
                { field: 'jobNums',
                    displayName: $translate.instant('autopsApp.accountCashier.jobNums'),
                    cellTemplate: '<span class="grid-text" ng-dblclick="grid.appScope.COPYALL(row.entity.jobNums)">{{row.entity.jobNums}}</span>',
                    minWidth:150},
                { field: 'oppositeName',
                    displayName: $translate.instant('autopsApp.accountCashier.oppositeName'),
                    minWidth:180},
                { field: 'description',
                    displayName: $translate.instant('autopsApp.accountCashier.description'),
                    minWidth:150},
                { field: 'exchangeRate',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.accountCashier.exchangeRate'),
                    minWidth:80},
                { field: 'baseCurrency',
                    displayName: $translate.instant('autopsApp.accountCashier.baseCurrency'),
                    minWidth: 60},
                { field: 'invoiceAmt',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.accountCashier.invoiceAmt'),
                    minWidth:80},
                { field: 'diffAmt',
                    type:'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.accountCashier.diffAmt'),
                    minWidth:80},
                { field: 'casherName',
                    displayName: $translate.instant('autopsApp.accountCashier.casherName'),
                    minWidth:120},
                { field: 'remarks',
                    displayName: $translate.instant('autopsApp.accountCashier.remarks'),
                    cellTemplate: '<span class="grid-text" ng-dblclick="grid.appScope.COPYALL(row.entity.remarks)">{{row.entity.remarks}}</span>',
                    minWidth:180},
                { field: 'identification',
                    displayName: $translate.instant('autopsApp.accountCashier.identification'),
                    minWidth:150}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 112;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                    if(!$scope.gridOptions.useExternalSorting){
                        if(sortColumns[sortColumns.length - 1]){
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function(a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    if(sortColumns[sortColumns.length - 1]){
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.reverse = 'asc' == sortColumns[sortColumns.length - 1].sort.direction;
                    }
                    if(pageLoaded){
                        loadAll();
                    }
                });
                gridApi.core.on.filterChanged($scope, function() {
                    gridCopy = this.grid;
                    if(pageLoaded && $scope.gridOptions.useExternalFiltering){
                        clearTimeout(filterTimer);
                        filterTimer = setTimeout(function(){
                            loadAll();
                        }, 1000);//查询延时时间，单位ms
                    }
                });
                gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                    vm.page = newPage;
                    vm.itemsPerPage = pageSize;
                    if(pageLoaded){
                        loadAll();
                    }
                });
            }
        }

        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            for(var i = $scope.gridOptions.columnDefs.length - 1; i >= 0; i--){
                if($scope.gridOptions.columnDefs[i].field == 'company' && $rootScope.account.companyId != 1){
                    $scope.gridOptions.columnDefs.splice(i, 1);
                }
                else if($scope.gridOptions.columnDefs[i].field == 'knowledgeBase' && $rootScope.account.companyId != 1){
                    $scope.gridOptions.columnDefs.splice(i, 1);
                }
            }
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 112
            }, function(result){
                gridStateObj = result;
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    pageLoaded = true;
                    return;
                }
                if(gridStateObj.gridState){
                    $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                }
                pageLoaded = true;
                if(gridCopy){
                    var filterRequired = false;
                    angular.forEach(gridCopy.columns, function(column){
                        angular.forEach(column.filters, function(cf){
                            if(cf.term || cf.term == false){
                                filterRequired = true;
                            }
                        })
                    })
                    if(filterRequired){
                        layer.msg($translate.instant('global.filterDetectedAlert'));
                        loadAll();
                    }
                }
            })
        });



        $scope.refreshBalance = function(accountCashier) {
            if (!accountCashier.id) {
                return;
            }
            layer.confirm($translate.instant('autopsApp.accountCashier.refreshBalanceAlert'), {
                icon: 0,
                title: accountCashier.transactionRef,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                AccountCashier.refreshBalance({id: accountCashier.id}, function(){
                    loadAll();
                })
            }, function() {
                return;
            });


        }


    }
})();
