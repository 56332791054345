(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CreateDnDialogController', CreateDnDialogController);

    CreateDnDialogController.$inject = ['$scope', '$rootScope', '$translate', '$uibModalInstance', '$timeout', '$uibModal',
    'entity', 'AccountInvoice', 'OpsVender'];

    function CreateDnDialogController($scope, $rootScope, $translate, $uibModalInstance, $timeout, $uibModal,
        entity, AccountInvoice, OpsVender) {

        $scope.invoiceRequest = entity;
        var ratesCopy = entity.guideRates;


        $timeout(function (){
            if((!$scope.invoiceRequest.id || $scope.invoiceRequest.manual) && $rootScope.HAVENOAUTH("ROLE_ACCOUNT")){
                $rootScope.FORBIDDEN_BY_ALERT('ROLE_ACCOUNT');
                $scope.clear();
            }
            if(entity.branchCompanyId){
                OpsVender.getBranchVender({
                    companyId: $rootScope.account.companyId,
                    bindedCompanyId: entity.branchCompanyId
                }, function(result){
                    updateWithObj(result);
                });
            }
            else if (entity.id && entity.venderId){
                $scope.updateSettlementObj(entity.venderId);
            }
            $scope.invoiceRequest.files = [];
            $rootScope.loadCurrencys();
        });

        var onSaveSuccess = function (result) {
            $uibModalInstance.close(result);
            $scope.isSaving = false;
            layer.msg($translate.instant('global.messages.submitted'));
        };

        $scope.vatInoiceChange = function () {
            if(!$scope.invoiceRequest.vatInoice){
                $scope.invoiceRequest.invoiceTotalTax = null;
            }
        }

        $scope.isInvalid = function () {
            if(!$scope.invoiceRequest.isTtl && (!$scope.invoiceRequest.invoiceType || !$scope.invoiceRequest.accountDate)){
                return true;
            }
            return false;
        }
        $scope.save = function () {
            if(!$scope.invoiceRequest.isTtl && (!$scope.invoiceRequest.invoiceType || !$scope.invoiceRequest.accountDate)){
                return;
            }
            $scope.invoiceRequest.isUsd = true;
            if($scope.invoiceRequest.currency == $rootScope.account.baseCurrency){
                $scope.invoiceRequest.isUsd = false;
            }
            if(!$scope.invoiceRequest.currency){
                $scope.invoiceRequest.currency = $rootScope.account.baseCurrency;
            }
            $scope.isSaving = true;

            if(!$scope.invoiceRequest.id){
                var accountInvoiceDTO = {};
                accountInvoiceDTO.accountInvoice = $scope.invoiceRequest;
                accountInvoiceDTO.guideRates = ratesCopy;
                AccountInvoice.createAccountInvoice(accountInvoiceDTO, onSaveSuccess, $rootScope.ALERT_ERROR);
            }
            else {
                AccountInvoice.update($scope.invoiceRequest, onSaveSuccess, $rootScope.ALERT_ERROR);
            }
        };

        $scope.invoiceTypeChange = function(type) {
            if(type == 'noInvoice'){
                // $scope.invoiceRequest.isIssued = true;
            }
            else if (type == 'usdOnly'){
                // $scope.invoiceRequest.isIssued = false;
                $scope.invoiceRequest.currency = "USD";
            }
            else{
                // $scope.invoiceRequest.isIssued = false;
                $scope.invoiceRequest.currency = $rootScope.account.baseCurrency;
            }
        }
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };


        $scope.updateSettlementObj = function(vId) {
            OpsVender.getInvoiceVenderDTO({id: vId}, function(result) {
                updateWithObj(result);
            }, function(result){
                $scope.invoiceRequest.venderId = null;
                layer.msg($translate.instant('autopsApp.guide.venderDeletedAlert'));
            });
        };

        var venderCopy;
        var updateWithObj = function (result) {
            venderCopy = result;
            if($scope.invoiceRequest.id){
                if(!$scope.invoiceRequest.invoiceTitle && result.invoiceInfos && result.invoiceInfos.length > 0 && result.invoiceInfos[0].invoiceTitle){
                    $scope.invoiceRequest.invoiceTitle = result.invoiceInfos[0].invoiceTitle;
                    $scope.invoiceRequest.taxNum = result.invoiceInfos[0].taxNum;
                    if($scope.invoiceRequest.invoiceType == 'rmbOnly'){
                        $scope.invoiceRequest.bankInfo = result.invoiceInfos[0].bankInfo;
                    }
                    else {
                        $scope.invoiceRequest.bankInfo = result.invoiceInfos[0].bankInfoUsd;
                    }
                    $scope.invoiceRequest.addressTel = result.invoiceInfos[0].addressTel;
                    $scope.invoiceRequest.mailAddress = result.invoiceInfos[0].mailAddress;
                    $scope.invoiceRequest.mailMob = result.invoiceInfos[0].mailMob;
                    $scope.invoiceRequest.mailAttn = result.invoiceInfos[0].mailAttn;
                    $scope.invoiceRequest.eInvoiceEmail = result.invoiceInfos[0].einvoiceEmail;
                }
                return;
            }
            $scope.invoiceRequest.venderId = result.id;
            $scope.invoiceRequest.payer = result.companyName;
            $scope.invoiceRequest.invoiceTitle = result.companyName;
            $scope.invoiceRequest.mailAddress = result.address;
            $scope.invoiceRequest.mailMob = result.mob;
            $scope.invoiceRequest.mailAttn = result.attn;
            $scope.invoiceRequest.addressTel = result.tel;
            $scope.invoiceRequest.bankInfo = null;
            $scope.invoiceRequest.taxNum = null;
            if(!entity.branchCompanyId){
                $scope.invoiceRequest.dnOnly = (result.paymentTerm == "credit"?true:false);
            }
            if(result.invoiceInfos && result.invoiceInfos.length > 0 && result.invoiceInfos[0].invoiceTitle){
                $scope.invoiceRequest.invoiceTitle = result.invoiceInfos[0].invoiceTitle;
                $scope.invoiceRequest.taxNum = result.invoiceInfos[0].taxNum;
                if($scope.invoiceRequest.invoiceType == 'rmbOnly'){
                    $scope.invoiceRequest.bankInfo = result.invoiceInfos[0].bankInfo;
                }
                else {
                    $scope.invoiceRequest.bankInfo = result.invoiceInfos[0].bankInfoUsd;
                }
                $scope.invoiceRequest.addressTel = result.invoiceInfos[0].addressTel;
                $scope.invoiceRequest.mailAddress = result.invoiceInfos[0].mailAddress;
                $scope.invoiceRequest.mailMob = result.invoiceInfos[0].mailMob;
                $scope.invoiceRequest.mailAttn = result.invoiceInfos[0].mailAttn;
                $scope.invoiceRequest.eInvoiceEmail = result.invoiceInfos[0].einvoiceEmail;
            }
        };


        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        $scope.updateSales = function(item) {
            $scope.invoiceRequest.salesId = item.id;
        };
        $scope.changeInvoiceTitle = function() {
            if(!venderCopy){return;}
            if(!venderCopy || !venderCopy.invoiceInfos || venderCopy.invoiceInfos.length == 0){
                layer.msg($translate.instant('global.messages.resultNull'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/invoice-info/invoice-info-dialog.html',
                controller: 'InvoiceInfoDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return venderCopy.invoiceInfos;
                    }]
               }
           }).result.then(function(result) {
                $scope.invoiceRequest.invoiceTitle = result.invoiceTitle;
                $scope.invoiceRequest.taxNum = result.taxNum;
                if($scope.invoiceRequest.invoiceType == 'rmbOnly' || $rootScope.account.baseCurrency == $scope.invoiceRequest.currency){
                    $scope.invoiceRequest.bankInfo = result.bankInfo;
                }
                else {
                    $scope.invoiceRequest.bankInfo = result.bankInfoUsd;
                }
                $scope.invoiceRequest.addressTel = result.addressTel;
                if(result.mailAddress){
                     $scope.invoiceRequest.mailAddress = result.mailAddress;
                }
                if(result.mailMob){
                     $scope.invoiceRequest.mailMob = result.mailMob;
                }
                if(result.mailAttn){
                     $scope.invoiceRequest.mailAttn = result.mailAttn;
                }
                if(result.einvoiceEmail){
                     $scope.invoiceRequest.eInvoiceEmail = result.einvoiceEmail;
                }
                $translate.refresh();
           }, function() {

           });
        };
    }
})();
