(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountGeneralDetailController', AccountGeneralDetailController);

    AccountGeneralDetailController.$inject = ['$scope', '$rootScope', '$translate', 'JhiLanguageService', 'Principal', 'AccountRef', 'DateUtils', 'AccountVoucher', 'AccountTitle', 'VoucherContent', 'OpsFiles', 'AccountGeneral', '$filter', '$timeout'];

    function AccountGeneralDetailController($scope, $rootScope, $translate, JhiLanguageService, Principal, AccountRef, DateUtils, AccountVoucher, AccountTitle, VoucherContent, OpsFiles, AccountGeneral, $filter, $timeout) {
        $scope.ledgerDataForFilter = [{
                choosedId: 0,
                level: 0,
                code: " ",
                abb: " ",
                showLedger: $translate.instant('autopsApp.accountGeneral.allLedgers')
            }];


        var ledgers = [];
        // var showLedger = "";
        var ledgersCopyForCheckParent = [];

        $scope.format = "pdf";
        $scope.monthFormat = "yyyy-MM";
        $scope.datepickerOptions = {
           minMode:'month'
        }
        $scope.reportavailalbe = false;
        $scope.fileName = "";
        var params = [];

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                AccountTitle.getAll({
                    type: "all"
                }, function(result) {
                    ledgers = angular.copy(result);
                    ledgersCopyForCheckParent = angular.copy(result);
                    generateShowLedgerForFilter();
                    for (var i = 0; i < ledgers.length; i++) {
                        $scope.ledgerDataForFilter.push({
                            "choosedId": ledgers[i].id,
                            "level": ledgers[i].level,
                            "code": ledgers[i].code,
                            "isForeign": ledgers[i].isForeign,
                            "currency": ledgers[i].currency,
                            "abb": ledgers[i].abb,
                            "showLedger": ledgers[i].showLedger
                        });
                    }
                });
            });
        } else {
            AccountTitle.getAll({
                type: "all"
            }, function(result) {
                ledgers = angular.copy(result);
                ledgersCopyForCheckParent = angular.copy(result);
                generateShowLedgerForFilter();
                for (var i = 0; i < ledgers.length; i++) {
                    $scope.ledgerDataForFilter.push({
                        "choosedId": ledgers[i].id,
                        "level": ledgers[i].level,
                        "code": ledgers[i].code,
                        "isForeign": ledgers[i].isForeign,
                        "currency": ledgers[i].currency,
                        "abb": ledgers[i].abb,
                        "showLedger": ledgers[i].showLedger
                    });
                }
            });
        }

        var lang = "";
        JhiLanguageService.getCurrent().then(function(language) {
            lang = language;
        });
        var zTreeObj;
        var categorySetting = {
            check: {
                enable: false
            },
            callback: {
                onClick: onClick
            },
        };
        function onClick(event, treeId, treeNode) {
            var data = treeNode.obj;
            $scope.filterDTO.ledgerId = data.id;
            $scope.filterDTO.ledgerCode = data.code;
            var showLedgerArr = [];
            angular.forEach(ledgers, function(result, index) {
                if (data.code && data.code.indexOf(result.code) > (-1)) {
                    showLedgerArr.push(lang == 'en' ? result.enName : result.cnName);
                } else if (data.code == result.code) {
                    showLedgerArr.push(lang == 'en' ? result.enName : result.cnName);
                    return
                }
            })
            // showLedger = showLedgerArr.join('/ ');
            $scope.filterDTO.ledger = showLedgerArr.join('/ ');
            $scope.reportavailalbe = false;
            // if (data.isForeign) {
            //     $scope.isForeign = true;
            //     $scope.currencyName = data.currency;
            // } else {
            //     $scope.isForeign = false;
            //     $scope.currencyName = null;
            // }
            // initCContent();
            // $scope.filterDTO.level = "";
            $scope.getContetns();
            $translate.refresh();
        }
        var generateShowLedgerForFilter = function() {
            for (var i = 0; i < ledgers.length; i++) {
                var titleTemp = "";
                if (ledgers[i].level > 1) {
                    var subLen = 0;
                    for (var j = 1; j < ledgers[i].level; j++) {
                        if (j == 2) {
                            subLen = subLen + 2;
                        } else {
                            subLen = subLen + 4;
                        }
                        var ledger = ledgers[i].code.substr(0, subLen);

                        for (var ii = 0; ii < ledgers.length; ii++) {
                            if (ledgers[ii].code == ledger) {
                                if (lang == "en") {
                                    titleTemp = titleTemp + ledgers[ii].enName + "/ ";
                                } else {
                                    titleTemp = titleTemp + ledgers[ii].cnName + "/ ";
                                }
                            }
                        }
                    }
                }
                if (lang == "en") {
                    ledgers[i].showLedger = titleTemp + ledgers[i].enName;
                } else {
                    ledgers[i].showLedger = titleTemp + ledgers[i].cnName;
                }
            }

            // for (var j = ledgers.length - 1; j >= 0; j--) {
            //     if (ledgers[j].isLast) {
            //         ledgers.splice(j, 1);
            //     }
            // }
        }

        $scope.datePickerForEtd = {};

        $scope.datePickerForEtd.status = {
            opened: false
        };

        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };
        $scope.datePickerForCargoReadyDate = {};

        $scope.datePickerForCargoReadyDate.status = {
            opened: false
        };

        $scope.datePickerForCargoReadyDateOpen = function($event) {
            $scope.datePickerForCargoReadyDate.status.opened = true;
        };


        $scope.load = function(id) {
            AccountTitle.get({
                id: id
            }, function(result) {
                $scope.accountTitle = result;
            });
        };

        $scope.filterDTO = {};

        $scope.onLedgerChoosed = function(data) {
            $scope.filterDTO.ledgerId = data.choosedId;
            $scope.filterDTO.ledger = data.showLedger;
            $scope.filterDTO.ledgerCode = data.code;
            $scope.reportavailalbe = false;
        }

        $scope.isSaving = false;
        $scope.accountGeneralDTOs = [{}];

        $scope.getContetns = function() {
            if ($scope.filterDTO.ledgerId == null || !$scope.filterDTO.dateTo || !$scope.filterDTO.dateFrom) {
                layer.msg($translate.instant('autopsApp.accountGeneral.nullFilterAlert'));
                return;
            }
            if ($scope.filterDTO.isAudited == null) {
                $scope.filterDTO.isAudited = false;
            }
            $scope.filterDTO.companyId = $rootScope.account.companyId;

            var yearFrom = $scope.filterDTO.dateFrom.getFullYear();         
            var monthFrom = $scope.filterDTO.dateFrom.getMonth(); 
            var year = $scope.filterDTO.dateTo.getFullYear();        
            var month = $scope.filterDTO.dateTo.getMonth();
            // if (yearFrom != year){
            //     layer.msg($translate.instant('global.messages.twoYearPeriodAlert'));
            //     return;
            // }

            if (month == 11) {
                month = month-12;
                year++;
            }            

            $scope.filterDTO.dateFrom = new Date(yearFrom, monthFrom, 1);
            $scope.filterDTO.dateTo = new Date(year, month+1, 0);

            $scope.isSaving = true;
            $scope.accountGeneralDTOs = [];
            AccountGeneral.getAccountGenerals($scope.filterDTO, function(result) {
                $scope.isSaving = false;
                $scope.accountGeneralDTOs = result;
                if (!$scope.accountGeneralDTOs || $scope.accountGeneralDTOs.length == 0) {
                    layer.msg($translate.instant('autopsApp.accountGeneral.filter.resultNull'));
                    return;
                }
                $scope.reportavailalbe = true;
            }, function(response) {
                if (response.status == 406){
                    layer.msg($translate.instant('autopsApp.accountGeneral.lastYearNotAccountedAlert'));
                }   
                else {
                    layer.msg($translate.instant('global.requestFail'));
                }                
                $scope.accountGeneralDTOs = [{}];             
                $scope.isSaving = false;
            });
        }

        $scope.exportAccountGeneral = function(format) {
            $scope.fileType = format;
            $scope.fileName = '';
            $scope.fileName_btn = layer.open({
                type: 1,
                area: ['350px', '180px'],
                btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')],
                title: $translate.instant('global.fileName'),
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: $("#templateReportName"),
                success: function(layero, index) {
                    $("#fileName").focus();
                },
                yes: function(index, layero) {
                    if ($scope.fileName == '') {
                        $scope.fileName = 'AccountGeneral';
                    }
                    $scope.generateAccountGeneral();
                    layer.close($scope.fileName_btn);
                },
                no: function(index, layero) {
                    layer.close(index);
                }
            });
        }

        $("#fileName").keydown(function(event) {
            $scope.keycode = window.event ? event.keyCode : event.which;
            if ($scope.keycode == 13) {
                if ($scope.fileName == '') {
                    $scope.fileName = 'AccountGeneral';
                }
                $scope.generateAccountGeneral();
                layer.close($scope.fileName_btn);
            }
        })

        $scope.params = {};
        $scope.generateAccountGeneral = function() {
            OpsFiles.generateAccountGeneralReport({
                fileType: $scope.fileType,
                ledgerName: $scope.filterDTO.ledger,
                dateFrom: $filter('date')(DateUtils.convertLocalDateToServer($scope.filterDTO.dateFrom), 'yyyy-MM'),
                dateTo:$filter('date')(DateUtils.convertLocalDateToServer($scope.filterDTO.dateTo),'yyyy-MM')
            }, $scope.accountGeneralDTOs, function(result) {
                $scope.exportExcelOrPdf(result);
            }, function(error) {
                layer.msg($translate.instant('global.accountGeneral.viewAccountGeneralsFail'));
            });
        }

        $scope.exportExcelOrPdf = function(result) {
            if (angular.isDefined($scope.fileType) && $scope.fileType == 'excel') {
                var file = new Blob([result.data], {
                    type: 'application/vnd.ms-excel'
                });
                var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
                var aTag = document.createElement("a");
                aTag.download = $scope.fileName + ".xlsx";
                aTag.href = blobURL;
                aTag.click();
            } else {
                var file = new Blob([result.data], {
                    type: 'application/pdf'
                });
                var fileURL = URL.createObjectURL(file);
                var previewDialog = layer.open({
                    type: 2,
                    area: ['1200px', '800px'],
                    maxmin: true,
                    title: $translate.instant("autopsApp.accountGeneral.viewAccountGenerals"),
                    shade: 0.3,
                    moveType: 0,
                    shift: 0,
                    content: fileURL
                })
            }
        }
        $scope.firstShow = true;
        $scope.isOpTree = true;
        $scope.isOpenTree = function() {
            $scope.firstShow = false;
            $scope.isOpTree = !$scope.isOpTree;
        }
        $scope.TreeDivHeight = null;
        $scope.isAcitve = false;
        $timeout(function() {
            getTableHeight()
        });
        window.addEventListener('resize', getTableHeight);

        function getTableHeight() {
            var footerHeight = 0;
            if (document.getElementById("footPager")) {
                document.getElementById("footPager").style.cssText = "margin: 0px;";
                footerHeight = $("#" + "footPager").height();
            }
            var heightPx = $(window).height() - $("#generalTable").offset().top - footerHeight - 1;
            $scope.TableHeight = {
                "max-height": heightPx + "px"
            }
            $scope.TreeDivHeight = {
                "max-height": heightPx - 10 + "px"
            }
            $scope.TreeHeightStyle = {
                "max-height": heightPx - 140 + "px"
            }
            $translate.refresh();
        }

        $scope.getPeriodAccountCodes = function() {
            if (!$scope.filterDTO.dateTo || !$scope.filterDTO.dateFrom) {
                return;
            }
            var filterLedger = {};
            filterLedger.companyId = $rootScope.account.companyId;
            filterLedger.makeDateTO = $scope.filterDTO.dateTo;
            filterLedger.makeDateFM = $scope.filterDTO.dateFrom;
            filterLedger.includeBalaceNotZero = $scope.filterDTO.isShowAmount; //无发生额且余额为0不显示
            layer.load(1, { shade: 0.3 });
            AccountGeneral.getPeriodAccountCodes(filterLedger, function(result) {
                layer.closeAll();
                ledgers = result;
                $scope.filterChange();
            }, function() {
                layer.closeAll();
                layer.msg($translate.instant('global.loadFailed'));
            });
        }
        var filterResults;
        $scope.filterChange = function() {
            filterResults = getFilterResult(ledgers);
            caegoryNodes = [];
            getNodes(filterResults);
            zTreeObj = $.fn.zTree.init($("#accountTitlesTree"), categorySetting, caegoryNodes);
            fuzzySearch('accountTitlesTree', '#field_search', null, true);
        }
        function getFilterResult() {
            if (!ledgers || ledgers.length == 0) {
                return;
            }
            var results = angular.copy(ledgers);
            var newResult = angular.copy(results);
            for (var i = results.length - 1; i >= 0; i--) {
                if (codeCompare($scope.filterDTO.ledgerCodeFrom, results[i].code) == 1 ) {
                    newResult.splice(i, 1);
                }
                if (codeCompare($scope.filterDTO.ledgerCodeTo, results[i].code) == -1 ) {
                    newResult.splice(i, 1);
                }
                if ($scope.filterDTO.levelFrom && parseInt($scope.filterDTO.levelFrom) > results[i].level) {
                    newResult.splice(i, 1);
                }
                if ($scope.filterDTO.levelTo && parseInt($scope.filterDTO.levelTo) < results[i].level) {
                    newResult.splice(i, 1);
                }
            }
            return newResult
        }
        function codeCompare(code1, code2) {
            if(!code1 || !code2){
                return 0;
            }
            if(code2.length > code1.length){
                code2 = code2.substring(0, code1.length);
            }
            if(code1 > code2){
                return 1;
            }
            if(code1 < code2){
                return -1;
            }
            return 0;
        }
        // zTree 的数据属性，深入使用请参考 API 文档（zTreeNode 节点数据详解）
        var caegoryNodes = [];
        function getNodes(dataList) {
            var num = 1;
            if ($scope.filterDTO.levelFrom) {
                num = $scope.filterDTO.levelFrom - 0;
            }
            angular.forEach(dataList, function(data) {
                if (data.level == num) {
                    var node = {
                        name: lang == 'en' ? data.code + ' ' + data.enName : data.code + ' ' + data.cnName,
                        open: true,
                        id: data.code,
                        obj: data,
                        children: []
                    };
                    addSubNodes(node, data.level + 1, dataList)
                    if (node.children.length == 0) {
                        node.children = null;
                    }
                    caegoryNodes.push(node)
                }
            })
        };
        function addSubNodes(parentNode, level, dataList) {
            angular.forEach(dataList, function(data) {
                if (data.level == level && data.code.indexOf(parentNode.id) == 0 && data.code != parentNode.id) {
                    var node = {
                        name: lang == 'en' ? data.code + ' ' + data.enName : data.code + ' ' + data.cnName,
                        open: true,
                        id: data.code,
                        obj: data,
                        children: []
                    };
                    addSubNodes(node, data.level + 1, dataList);
                    if (node.children.length == 0) {
                        node.children = null;
                    }
                    parentNode.children.push(node)
                }
            })
        }
    }
})();