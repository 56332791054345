(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ExpressCargoOrganizeDialogController', ExpressCargoOrganizeDialogController);

    ExpressCargoOrganizeDialogController.$inject = ['$rootScope', '$translate', '$uibModalInstance', '$timeout', '$uibModal', 
    'entity', 'DataUtils', 'ExpressParcel'];

    function ExpressCargoOrganizeDialogController ($rootScope, $translate, $uibModalInstance, $timeout, $uibModal, 
        entity, DataUtils, ExpressParcel) {
        var vm = this;
        vm.isVMed = false;

        vm.expressParcel  = entity;
        vm.addCargo = addCargo;
        vm.delCargo = delCargo;
        vm.clear = clear;
        vm.updateCount = updateCount;
        vm.updateGwTtl = updateGwTtl;
        vm.updateVolTtl = updateVolTtl;
        vm.updateTotal = updateTotal;
        vm.updateVol = updateVol;
        vm.getLabel = getLabel;
        vm.packOne = packOne;

        function packOne () {
            vm.expressParcel.labelCargos = [];
            var allCargoName = null;
            var allSku = "";
            angular.forEach(vm.expressParcel.expressCargos, function(data){
                if(!allCargoName){
                    allCargoName = data.cargoName;
                }
                if(allSku.length < 35 && data.sku){
                    allSku += data.sku + " ";
                }
            })
            var newCargo = {
                refGuideId: -1,
                cargoName: allCargoName,
                sku: allSku,
                pkgs: 1,
                packageType: "CTNS",
                gw: vm.totalLineOld.gwTtl,
                gwTtl: vm.totalLineOld.gwTtl,
                vol: vm.totalLineOld.volTtl,
                volTtl: vm.totalLineOld.volTtl
            }

            vm.expressParcel.labelCargos.push(newCargo);
            updateTotal();
        }


        
        function updateVol () {
            for (var i = 0; i < vm.expressParcel.labelCargos.length; i ++){
                if (vm.expressParcel.labelCargos[i].length && vm.expressParcel.labelCargos[i].width && vm.expressParcel.labelCargos[i].height){
                    vm.expressParcel.labelCargos[i].vol = vm.expressParcel.labelCargos[i].length/100 * vm.expressParcel.labelCargos[i].width/100 * vm.expressParcel.labelCargos[i].height/100;
                    vm.expressParcel.labelCargos[i].vol = vm.expressParcel.labelCargos[i].vol.toFixed(6) -0;
                }
                if (vm.expressParcel.labelCargos[i].vol && vm.expressParcel.labelCargos[i].pkgs){
                    vm.expressParcel.labelCargos[i].volTtl = vm.expressParcel.labelCargos[i].vol * vm.expressParcel.labelCargos[i].pkgs;
                    vm.expressParcel.labelCargos[i].volTtl = vm.expressParcel.labelCargos[i].volTtl.toFixed(3) -0;
                }
            }
            updateTotal();
        }

        function updateGwTtl (cargo) {
            if(cargo.gw && cargo.pkgs){
                cargo.gwTtl = cargo.gw * cargo.pkgs
            }
            else {
                cargo.gwTtl = 0;
            }
            cargo.gwTtl = cargo.gwTtl.toFixed(2) -0;
            updateTotal();
        }
        function updateVolTtl (cargo) {
            cargo.volTtl = 0;
            if (cargo.vol && cargo.pkgs){
                cargo.volTtl = cargo.vol * cargo.pkgs;
            }
            cargo.volTtl = cargo.volTtl.toFixed(2) -0;
            updateTotal();
        }

        function updateCount () {
            if (!vm.expressParcel.labelCargos || vm.expressParcel.labelCargos.length == 0){
                return;
            }
            for (var i = 0; i < vm.expressParcel.labelCargos.length; i ++){
                var pkgs = vm.expressParcel.labelCargos[i].pkgs;
                if(!pkgs){pkgs = 0;}                

                if (vm.expressParcel.labelCargos[i].vol){
                    vm.expressParcel.labelCargos[i].volTtl = vm.expressParcel.labelCargos[i].vol * pkgs;
                }
                if(!vm.expressParcel.labelCargos[i].volTtl){
                    vm.expressParcel.labelCargos[i].volTtl = 0;
                }
                vm.expressParcel.labelCargos[i].volTtl = vm.expressParcel.labelCargos[i].volTtl.toFixed(3) -0;

                if(vm.expressParcel.labelCargos[i].gw){
                    vm.expressParcel.labelCargos[i].gwTtl = vm.expressParcel.labelCargos[i].gw * pkgs
                }
                if(!vm.expressParcel.labelCargos[i].gwTtl){
                    vm.expressParcel.labelCargos[i].gwTtl = 0;
                }               
                vm.expressParcel.labelCargos[i].gwTtl = vm.expressParcel.labelCargos[i].gwTtl.toFixed(2) -0;       
            }
            updateTotal();
        }


        $timeout(function() {
            vm.expressParcel.labelCargos = angular.copy(vm.expressParcel.expressCargos);
            if(vm.expressParcel.medCompanyId == $rootScope.account.companyId){
                vm.isVMed = true;
            }
            vm.expressParcel.inchOz = false;
            updateTotal();
        })
        function addCargo () {
            vm.expressParcel.labelCargos.push({});
        }
        function delCargo (index) {
            vm.expressParcel.labelCargos.splice(index, 1);  
        };

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        var updateTotal = function() {
            var ttlPkgs = 0, ttlGw = 0, ttlVol = 0;
            angular.forEach(vm.expressParcel.labelCargos, function(data){
                if(data.pkgs){
                    ttlPkgs += data.pkgs;
                    if(data.gw){
                        data.gwTtl = DataUtils.round(data.gw * data.pkgs, 2);
                        ttlGw += data.gwTtl;
                    }
                    if(data.vol){
                        data.volTtl = DataUtils.round(data.vol * data.pkgs, 3);
                        ttlVol += data.volTtl;
                    }
                }
            })
            vm.expressParcel.pkgNum = ttlPkgs;
            vm.totalLine = {
                status: "Total",
                pkgs: DataUtils.round(ttlPkgs, 0),
                gwTtl: DataUtils.round(ttlGw, 2),
                volTtl: DataUtils.round(ttlVol, 3)
            };

            ttlPkgs = 0; ttlGw = 0; ttlVol = 0; 
            angular.forEach(vm.expressParcel.expressCargos, function(data){
                if(data.pkgs){
                    ttlPkgs += data.pkgs;
                    if(data.gw){
                        data.gwTtl = DataUtils.round(data.gw * data.pkgs, 2);
                        ttlGw += data.gwTtl;
                    }
                    if(data.vol){
                        data.volTtl = DataUtils.round(data.vol * data.pkgs, 3);
                        ttlVol += data.volTtl;
                    }                    
                }
            })

            vm.totalLineOld = {
                status: "Total",
                pkgs: DataUtils.round(ttlPkgs, 0),
                gwTtl: DataUtils.round(ttlGw, 2),
                volTtl: DataUtils.round(ttlVol, 3)
            };
        }
        vm.isSaving = false;
        function getLabel(){
            if(vm.expressParcel.shiptoProvinceCode == "HI" || vm.expressParcel.shiptoProvinceCode == "PR" || vm.expressParcel.shiptoProvinceCode == "AK"){
                layer.confirm($translate.instant('autopsApp.expressParcel.outUsAlert'), {
                    icon: 3,
                    title: "Alert",
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                }, function(index) {
                    layer.close(index);
                    doGetLabel();
                }, function() {
                    return;
                });
            }
            else{
                doGetLabel();
            }
        }
        function doGetLabel(){
            vm.isSaving = true;
            layer.msg("Generating label...", {
              shade: 0.3,
              time: 200000
            });
            vm.isSaving = true;
            var labelEntity = angular.copy(vm.expressParcel);
            labelEntity.expressCargos = angular.copy(labelEntity.labelCargos);

            if(vm.expressParcel.freightLtl){
                ExpressParcel.getLtlLabel(labelEntity, function(result) {
                    layer.closeAll();
                    $rootScope.PDFPOP(result.labelUrl, "label");
                    $uibModalInstance.close(result);
                    vm.isSaving = false;
                }, function(error){
                    layer.closeAll();
                    vm.isSaving = false;
                    if(error && error.data && error.data.description){
                        $rootScope.OPS_ALERT(error.data.description);
                    }  
                });
            }
            else {
                ExpressParcel.getLabel({isCombine: true}, labelEntity, function(result) {
                    layer.closeAll();
                    vm.isSaving = false;
                    $uibModalInstance.close(result);
                    $rootScope.PDFPOP(result.labelUrl, "label");
                }, function(error){
                    layer.closeAll();
                    vm.isSaving = false;
                    if(error && error.data && error.data.description){
                        $rootScope.OPS_ALERT(error.data.description);
                    }  
                    else{
                        layer.msg($translate.instant('global.messages.submittedfailed'));
                    }
                });
            }       
        }
    }
})();
