(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GenerateHblNumController', GenerateHblNumController);

    GenerateHblNumController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$translate', '$timeout', 'entity', 'OpsCompany', 'Guide'];
    
    function GenerateHblNumController($scope, $rootScope, $uibModalInstance, $translate, $timeout, entity, OpsCompany, Guide) {
        $scope.guide = entity;        
        $scope.hblPrefixs = [];
        $scope.hblPrefix = "";
        $scope.hblNum = "";
        var hblNumCopy = "";

        $timeout(function (){
            $scope.hblPrefixs = $rootScope.account.blPrefix.split(";");
            if($scope.hblPrefixs.length > 0){
                $scope.hblPrefix = $scope.hblPrefixs[0];
            }
            OpsCompany.getHblNum({
                id: $rootScope.account.companyId
            }, function(result) {
                $scope.hblNum = result.hblNum;
                hblNumCopy = result.hblNum;
            });
        })

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };      
        
        $scope.save = function() {
            $scope.isSaving = true;
            var newHBLNum = $scope.hblPrefix + $scope.hblNum;
            if (hblNumCopy == $scope.hblNum) {
                $uibModalInstance.close(newHBLNum);
            } else {
                Guide.checkHbl({
                    companyId: $rootScope.account.companyId,
                    guideId: $scope.guide.id,
                    hblNum: newHBLNum
                }, function(result1) {
                    $scope.isSaving = false;
                    if (result1.isRepeated) {
                        layer.msg($translate.instant('autopsApp.guide.home.hblNumRepeated'));
                        return;
                    } else {
                        $uibModalInstance.close(newHBLNum);
                    }
                });
            }    
        }

}
})();
