(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsConcernLaneController',OpsConcernLaneController);

    OpsConcernLaneController.$inject = ['$rootScope', '$scope', '$uibModalInstance', '$uibModal', '$translate', '$http', 
    'entity', 'Principal', 'OpsCost', 'OpsTemplate', 'JhiSignature', 'AddressBook', 'DataUtils', 'Guide', 'OpsColoader', 
    'BookingCtns', 'OpsFiles', 'WmsIn', 'Booking', 'WmsWarehouse', 'OpsPorts', 'OpsVender', 'OpsCompany', 'OpsReport'];

    function OpsConcernLaneController($rootScope, $scope, $uibModalInstance, $uibModal, $translate, $http, 
        entity, Principal, OpsCost, OpsTemplate, JhiSignature, AddressBook, DataUtils, Guide, OpsColoader, 
        BookingCtns, OpsFiles, WmsIn, Booking, WmsWarehouse, OpsPorts, OpsVender, OpsCompany, OpsReport) {
        var vm = this;

        vm.clear = clear;

        vm.findRates = findRates;        

        vm.choosedOne = null;
        vm.choosePrice = choosePrice;
        vm.getLocalCharges = getLocalCharges;
        vm.getRemarks = getRemarks;
        vm.step = 1;
        vm.setStep = setStep;
        vm.sendOrder = sendOrder;
        vm.updateCargoType = updateCargoType;

        $scope.guide = null;


        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                if (!$rootScope.HOST_COMPANY_ID && $rootScope.account){
                    OpsCompany.getCompanyByUserId({userId: $rootScope.account.id}, function(result){
                        $rootScope.HOST_COMPANY_ID = result.companyId;
                    })
                }
            });
        }
        else {
            if (!$rootScope.HOST_COMPANY_ID){
                OpsCompany.getCompanyByUserId({userId: $rootScope.account.id}, function(result){
                    $rootScope.HOST_COMPANY_ID = result.companyId;
                })
            }
        }

        var chooseFirstColoader = function() {
            if(!$scope.guide.booking.bookingToName){
                $scope.onColoaderSelected($scope.opsColoaders[0]);
            }  
        }
        $scope.opsColoaders = [];
        $scope.loadOpsColoader = function() {
            if ($scope.opsColoaders && $scope.opsColoaders.length > 0) {
                chooseFirstColoader();
                return;
            };
            OpsColoader.getAll(function(result) {
                $scope.opsColoaders = result;
                for (var i = $scope.opsColoaders.length -1; i >=0; i--) {
                    if (!$scope.opsColoaders[i].companyName){
                        $scope.opsColoaders.splice(i, 1);
                    }
                }
                if($scope.opsColoaders.length > 0){
                    chooseFirstColoader();
                }                
            });
        };
        
        function updateCargoType(){
            DataUtils.fillFdLabel($scope.guide);
        }

        function initGuide(){
            $scope.guide = {
                shipmentType: "FBASEA",
                cargoType: "generalCargo",
                dontSynTtl: false,
                createdTime: new Date(),
                bookingCustoms: [{}],
                ownImporter: false,
                valueCur: "USD",
                booking: {
                    shipperBl: "TO ORDER",
                    cneeBl: "TO ORDER",
                    notifyBl: "SAME AS CONSIGNEE",                    
                    warehouseRequired: true,
                    bookingCtns: [{}],
                    bookingPrices: [],
                    bookingTrucks: [{}],
                    status: "new"
                }
            };
            vm.step = 1;
            updateCargoType();
        };
        initGuide();

        function onCheckError(error){
            vm.step--;
            if(error && error.data && error.data.description){
                $rootScope.OPS_ALERT(error.data.description);
            }
            else {
                layer.msg($translate.instant('global.requestFail'));
            }
        }

        var isLocal = function() {
            if(!$scope.guide || !$scope.guide.booking){
                return false;
            }
            if($scope.guide.shipmentType == 'LOCALKG'
                || $scope.guide.shipmentType == 'LOCAL'){
                return true;
            }
            return false;
        }

        var plOk = function(num) {
            if (!$scope.guide.cargoReadyDate) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.CRD_invalid'));
                return false;
            };
            if($rootScope.isZb($rootScope.HOST_COMPANY_ID)){
                if ((!$scope.guide.booking.bookingCtns || $scope.guide.booking.bookingCtns.length == 0) && !isLocal()) {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.pl_required'));
                    return false;
                };
                if (!$scope.guide.fbaRef) {
                    for(var i = $scope.guide.booking.bookingCtns.length - 1; i >= 0; i--){
                        if(!$scope.guide.booking.bookingCtns[i].fbaRef){
                            $rootScope.OPS_ALERT("Reference Id is required!");
                            return false;
                        }
                    }
                };
                if (!$scope.guide.shipmentId) {
                    for(var i = $scope.guide.booking.bookingCtns.length - 1; i >= 0; i--){
                        if(!$scope.guide.booking.bookingCtns[i].shipmentId){
                            $rootScope.OPS_ALERT("Shipment Id is required!");
                            return false;
                        }
                    }
                };
            }
            if(!isLocal() && $rootScope.isZb($rootScope.HOST_COMPANY_ID)){
                Booking.checkCargoPic($scope.guide.booking, function(result){
                    if(result.cargoStr){
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.cargoPicAlert', {cargoStr: result.cargoStr}),{icon: 0});
                        return false;
                    }
                    else {
                        vm.step += num;
                    }                    
                })
            }
            else {
                vm.step += num;            
            }            
        }

        var bookingOk = function() {
            if (!$scope.guide.whName && $rootScope.isZb($rootScope.HOST_COMPANY_ID) && !isLocal()) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.por_invalid'));
                return false;
            };
            if (!$scope.guide.booking.deliveryTo
                && (!$scope.guide.shiptoPostcode || !$scope.guide.destCountryCode)
                && !isLocal()) {
                $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.deliveryTo_invalid'));
                return false;
            };
            if ($scope.guide.ownImporter && !isLocal()) {
                if(!$scope.guide.booking.importerName || !$scope.guide.booking.importerAddress || !$scope.guide.booking.importerTaxId){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.importerInfo_invalid'));
                    return false;
                }
            };

            if ($scope.guide.truckRequired) {
                if (!$scope.guide.booking.bookingTrucks[0] || !$scope.guide.booking.bookingTrucks[0].loadTime) {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.loadTime_null'));
                    return false;
                };
                if (!$scope.guide.booking.bookingTrucks[0].loadAddress) {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.loadAddress_null'));
                    return false;
                };
                if (!$scope.guide.booking.bookingTrucks[0].attn) {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.attn_null'));
                    return false;
                };
            };

            if ($scope.guide.customRequired && !isLocal()) {
                if (!$scope.guide.bookingCustoms || $scope.guide.bookingCustoms.length == 0 
                    || !$scope.guide.bookingCustoms[0].exportType) {
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.exportType_null'));
                    return false;
                };
            };
            // if($rootScope.isZb($rootScope.HOST_COMPANY_ID)){
            //     var cargoNames = new Set();
            //     angular.forEach($scope.guide.booking.bookingCtns, function(ctn){
            //         if(ctn.cargoName){
            //             if(cargoNames.has(ctn.cargoName)){
            //                 $rootScope.OPS_ALERT($translate.instant('autopsApp.booking.cargoNameRepeat'));
            //                 return false;
            //             }
            //             else {
            //                 cargoNames.add(ctn.cargoName);
            //             }
            //         }
            //     })
            // }
            return true;
        };


        function setStep(num){
            if(num > 0){
                if(vm.step + num == 2){
                    if(!$scope.guide.productName && !$scope.guide.deliveryChannel){
                        
                        layer.confirm($translate.instant('global.skipStepAlert'), {
                            icon: 3,
                            title: "Msg",
                            btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                        }, function(index) {
                            layer.close(index);
                            vm.step += num;  
                            $translate.refresh();
                        }, function() {
                            // layer.msg($translate.instant('autopsApp.booking.channelNullAlert')); 
                        });    
                        return; 
                    }
                    vm.step += num;
                }
                else if (vm.step + num == 3){
                    plOk(num);
                }
                else if (vm.step + num == 4){
                    if(bookingOk()){                        
                        vm.step += num;
                    }
                }
                else if (vm.step + num == 5){
                    vm.step += num;
                }
                else {
                    vm.step += num;
                }
            }
            else {
                vm.step += num;
            }            
        }
        function getRemarks(opsCost) {
            if(!opsCost.remarks || !opsCost.companyId){
                return;
            }

            JhiSignature.findRateRemarks({
                companyId: opsCost.companyId,
                code: opsCost.remarks
            }, function(result){
                if(result){
                    vm.remarks=result.signature;
                }  
                else {
                    vm.remarks = opsCost.remarks;
                    // layer.msg($translate.instant('global.noRecord'));   
                }              
            }, function(result){
                vm.remarks = opsCost.remarks;
            })
        }

        function getLocalCharges(opsCost, code) {
            if(!code){
                return;
            }
            OpsTemplate.getLocalCharges({
                code: code,
                companyId: opsCost.companyId
            }, function(result){
                if(!result || !result.rates || result.rates.length == 0){
                    vm.remarks = code;  
                    return;
                }
                vm.remarks = null;
                $uibModal.open({
                    templateUrl: 'app/entities/opsTemplate/opsTemplate-dialog.html',
                    controller: 'OpsTemplateDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'md',
                    resolve: {
                        entity: [function() {
                            return result;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('opsTemplate');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function(results) {}, function() {});
            })
        }

        function choosePrice (opsCost) {
            if(vm.choosedOne && vm.choosedOne.opsCostId == opsCost.opsCostId){
                vm.choosedOne = null;
                vm.remarks = null;
            }
            else {
                vm.choosedOne = opsCost;
                getRemarks(vm.choosedOne);
                vm.step = 2;
            }
            DataUtils.updateGuideByProduct($scope.guide, opsCost, true);
            $scope.saveGuide();
        }

        $scope.ratesList = [];
        function findRates() {
            if($rootScope.HAVEAUTH('ORDER_BY_CHANNEL') && !$scope.guide.deliveryChannel){
                layer.msg($translate.instant('autopsApp.booking.channelNullAlert')); 
                return; 
            }            
            var loadIndex = layer.load(1, {shade: 0.3});  
            OpsCost.guideTryCost($scope.guide, function(result){
                $scope.ratesList = result;
                if($scope.ratesList && $scope.ratesList.length == 1){
                    vm.choosedOne = $scope.ratesList[0];
                }
                layer.close(loadIndex);
            }, function(error){
                layer.close(loadIndex);
                $scope.ratesList = [];
                $rootScope.ALERT_ERROR(error);
            })
        }

        function clear () {
            $uibModalInstance.dismiss($scope.guide);
        }

        $scope.addressBooks = [];
        $scope.loadAddressBooks = function() {
            if ($scope.addressBooks.length == 0 && !$scope.isSaving) {
                $scope.isSaving = true;
                AddressBook.getAll(function(result) {
                    $scope.addressBooks = result;
                    $scope.isSaving = false;
                });

            };
        };
        $scope.datePickerOpenStatus = {};
        $scope.openCalendar  = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        $scope.dutyPayPartyChange = function(payParty) {
            if(payParty != "thirdparty"){
                $scope.guide.dutyPayPartyCode = null;
                $scope.guide.dutyPayPartyId = null;
            }
        }

        $scope.allProducts = [];
        $scope.getProducts = function() {
            if(!$scope.guide.productName){
                $scope.guide.productName = "";
            }
            if($scope.allProducts.length > 0){
                return;
            }
            OpsCost.getProducts({companyId: $rootScope.HOST_COMPANY_ID}, function(results){
                $scope.allProducts = results;
            })
        }

        $scope.onCarrierSelected = function(carrier) {
            $scope.guide.pickupCarrierCode = carrier.code;
        }

        $scope.addCargo = function  () {
            if (!$scope.guide.expressCargos){
                $scope.guide.expressCargos = [];
            }
            var newCargo = {};
            if($rootScope.ISTMS){
                newCargo.length = 40;
                newCargo.width = 48;
                newCargo.height = 74;
            }
            $scope.guide.expressCargos.push(newCargo);
        }

        $scope.comCargoName = function(cargoName) {
            if(!$scope.guide.goodsDiscription){
                $scope.guide.goodsDiscription = cargoName;
            }
            else if ($scope.guide.goodsDiscription.indexOf(cargoName) == -1) {
                $scope.guide.goodsDiscription = $scope.guide.goodsDiscription + " " + cargoName;
            }
        };

        $scope.addCtn = function() {
            if(!$scope.guide.booking.bookingCtns){
                $scope.guide.booking.bookingCtns = [];
            }
            $scope.guide.booking.bookingCtns.push({});
        };

        $scope.deleteCtn = function(bookingCtn) {
            if (bookingCtn.id && $scope.guide.id) {
                BookingCtns.delete({
                    id: bookingCtn.id
                }, function(){
                    layer.msg($translate.instant('global.delete_success'));
                    var index = $scope.guide.booking.bookingCtns.indexOf(bookingCtn);
                    if (index > -1) {
                        $scope.guide.booking.bookingCtns.splice(index, 1);
                    };
                }, function(result){
                    if(result.status == 409){
                        $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.cargoIsOutAlert'), {icon:0});                        
                    }
                    else {
                        layer.msg($translate.instant('global.delete_fail'));
                    }
                });
            }
            else {
                var index = $scope.guide.booking.bookingCtns.indexOf(bookingCtn);
                if (index > -1) {
                    $scope.guide.booking.bookingCtns.splice(index, 1);
                };
            }

        };
        $scope.bookingCtnPop = function() {
            if (!$scope.guide.id){
                $scope.guide.shipperUserId = $rootScope.account.id;
                $scope.guide.customerUserId = $rootScope.account.id;
                $scope.guide.booking.createdTime = new Date();    
                $scope.guide.booking.lastModifiedTime = new Date();
                Guide.updateGuideBooking($scope.guide, function(result){
                    $scope.guide = result;
                    $scope.$emit('autopsApp:guideUpdate', result);
                    $rootScope.plPop($scope.guide);
                }, function(){                    
                });
            }
            else {
                $rootScope.plPop($scope.guide);
            }            
        }    
        $scope.updateDuty = function(bookingCtn) {
            bookingCtn.dutyAmt = DataUtils.getDuty(bookingCtn).dutyAmt;
            $scope.mixPlChange(bookingCtn);
        };

        $scope.updatePrice = function(bookingCtn) {
            if (bookingCtn.pieces && bookingCtn.unitPrice) {
                bookingCtn.totalPrice = DataUtils.round(bookingCtn.pieces * bookingCtn.unitPrice, 2);
                $scope.updateDuty(bookingCtn);
            }
            var pieces = 0;
            angular.forEach($scope.guide.booking.bookingCtns, function(bcn){
                if(bcn.pieces){
                    pieces+=bcn.pieces;
                }
            })
            $scope.guide.pieces = pieces;
        };

        $scope.saveGuide = function() {
            if (!$scope.guide.id){
                $scope.guide.shipperUserId = $rootScope.account.id;
                $scope.guide.customerUserId = $rootScope.account.id;
                $scope.guide.booking.createdTime = new Date(); 
                $scope.guide.booking.lastModifiedTime = new Date();
            }    
            Guide.updateGuideBooking($scope.guide, function(result){
                $scope.guide = result;
                $scope.$emit('autopsApp:guideUpdate', result);
            }, function(){                    
            }); 
        }
        var loadGuide = function(refreshPWV) {
            if(!$scope.guide.id){
                return;
            }
            $scope.isSaving = true;
            Guide.getGuideBookingDTO({id : $scope.guide.id}, function(result){
                $scope.isSaving = false;
                $scope.guide = result;
                layer.msg($translate.instant('entity.action.loadSuccess'));
            });
        }

        $scope.importPlByExcel = function (file) {
            if (!file) {return;}
            if(!$scope.guide.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if($scope.guide.isClosed){
                layer.msg($translate.instant('global.lockedAlert'));
                return;
            }            
            $scope.saveGuide();
            var fd = new FormData();
            fd.append('file', file);
            fd.append('guideId', $scope.guide.id);
            $http.post(
                'api/bookingCtnss/importByExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {    
                    loadGuide(true);  
                    layer.msg($translate.instant('global.importSuccess'));
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));                                            
                });
        };



        $scope.printIn = function(reportType){
            if(!$scope.guide.id){
                return;
            }
            var wmsIn = {isOut:false};
            wmsIn.guideId = $scope.guide.id;

            OpsFiles.generateWmsInPaper({reportType: reportType},wmsIn,function(result){
                $rootScope.PRINTBYTES(result.data);
            },function(error){
                layer.msg($translate.instant('autopsApp.wmsIn.viewWmsInPaperFailed'));
            });
        }
        $scope.wmsLabels = function(reportType) {
            if(!$scope.guide.id){
                return;
            }
            var wmsIn = {};
            wmsIn.guideId = $scope.guide.id;
            wmsIn.companyId = $scope.guide.companyId;
            $scope.format = 1;
            WmsIn.wmsLabels({
                reportType: reportType,
                fileType: $scope.format
            }, wmsIn, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.loadFileFailed'));
            });
        }

        $scope.wmsAndPols = null;
        $scope.getWmsAndPols = function () {
            if ($scope.wmsAndPols){
                return;
            }
            OpsCost.getWmsAndPols(function (data) {
                $scope.wmsAndPols = data;
            });
        }
        $scope.wmsPolSelected = function(warehouse) {
            $scope.guide.booking.porName = warehouse.name;
            $scope.wmsWarehouseSelected(warehouse);
        }

        $scope.wmsWarehouseSelected = function(warehouse) {
            if(!warehouse.id){
                return;
            }
            if($scope.guide.jobNum && warehouse.companyId && $scope.guide.companyId != warehouse.companyId){
                $scope.guide.whName = guideCopy.whName;
                layer.msg($translate.instant('autopsApp.guide.warehouseInvalidAlert'));
                return;
            }
            $scope.guide.whName = warehouse.name;
            $scope.guide.whId = warehouse.id;
            if(warehouse.companyId){
                $scope.guide.companyId = warehouse.companyId;
                // $scope.guide.medCompanyId = warehouse.medCompanyId;
                // $scope.guide.isVirtual = warehouse.isVirtual;
                // $scope.guide.medCompany = warehouse.medCompany;
                // $scope.guide.medVenderId = warehouse.medVenderId;
            }
        }






        $scope._selected = function($item, which){
            switch(which){
                case "porChoose":                    
                    $scope.guide.booking.porId = $item.code;               
                    break;
                case "polChoose":                    
                    $scope.guide.booking.polId = $item.code;               
                    $scope.guide.polRegion = $item.region;
                    break;
                case "podChoose":
                    $scope.guide.booking.podId = $item.code; 
                    if(!$scope.guide.podRegion) {
                        $scope.guide.podRegion = $item.region;
                    }                     
                    break;
                case "destChoose":
                    $scope.guide.booking.destId = $item.code;   
                    $scope.guide.podRegion = $item.region;
                    $scope.guide.destCountryCode = $item.countryCode;                    
                    break;
                case "deliveryChoose":
                    $scope.guide.booking.deliverytoId = $item.code;  
                    AddressBook.getFbaAddress({code: $item.code, countryCode: $item.countryCode}, function(result){
                        DataUtils.onGuideAddressChoosed($scope.guide, result);
                        $rootScope.getCountryName($scope.guide.destCountryCode);
                    });                
                    break;
            }
        }
        $scope.portList = new Array();

        $scope.loadFbaPorts = function() {
            if ($rootScope.OPSPORTS.fbaPorts && $rootScope.OPSPORTS.fbaPorts.length > 0){
                return;
            }
            OpsPorts.getFBAPorts(function(result){
                $rootScope.OPSPORTS.fbaPorts = [];
                angular.forEach(result, function(data){
                    $rootScope.OPSPORTS.fbaPorts.push(data);
                })
            })
        };
        $scope.setFdValue = function(fd, value) {
            $scope.guide[fd] = value;
            if(fd == 'truckRequired' && !$scope.guide.booking.bookingTrucks){
                $scope.guide.booking.bookingTrucks = [{}];
            }
            else if(fd == 'customRequired' && !$scope.guide.bookingCustoms){
                $scope.guide.bookingCustoms = [{}];
            }
        }
        $scope.opsFactorys = [];
        $scope.loadOpsFactory = function() {
            if ($scope.opsFactorys.length == 0 && !$scope.isSaving) {
                $scope.isSaving = true;
                OpsVender.getOpsFactorys(function(result) {
                    $scope.isSaving = false;
                    $scope.opsFactorys = result;             
                });
            };
        };
        $scope._factoryOnSelected = function(data, truck) {
            truck.factoryName = data.companyName;
            truck.loadAddress = data.address;
            truck.attn = data.attn;
            truck.attnTel = data.tel;
            truck.attnFax = data.fax;
            truck.attnMob = data.mob;
            truck.attnEmail = data.email;
        }

        var doSend = function() {
            if ($scope.guide.booking.bookingTrucks) {
                if ($scope.guide.booking.bookingTrucks.length > 0 && !$scope.guide.booking.bookingTrucks[0].status) {
                    $scope.guide.booking.bookingTrucks[0].status = "new";
                    $scope.guide.booking.bookingTrucks[0].isExport = true;
                }
            }
            if(vm.choosedOne){
                var currency = vm.choosedOne.currency;
                if(currency == "￥"){
                    currency = "RMB";
                }
                else if(currency == "$"){
                    currency = "USD";
                }
                var price = {
                    item: "运费",
                    currency: currency,
                    unit: "SHIPMENT",
                    count: 1,
                    paymentTerm: "PPD",
                    price: vm.choosedOne.freight,
                    remarks: vm.choosedOne.msg,
                    total: vm.choosedOne.freight
                }
                $scope.guide.booking.bookingPrices = [price];
            }
            
            $scope.isSaving = true;
            $scope.guide.booking.lastModifiedTime = new Date();
            $scope.guide.booking.status = "Requested";
            Guide.save($scope.guide, firstSendSuccess, firstSendError);
        }

        var firstSendSuccess = function(result) {
            $scope.isSaving = false;
            $scope.guide = result;
            vm.step++;
            var guideReportDTO = angular.copy($scope.guide);
            guideReportDTO.opType = 2;
            guideReportDTO.reportType = 10;
            guideReportDTO.fileType = 1;
            OpsReport.generateGuide({}, guideReportDTO);
            layer.msg($translate.instant('global.messages.submitted'));
        };
        
        var firstSendError = function(result) {
            $scope.isSaving = false;
            $scope.guide.booking.status = "new";
            if (result.status === 404) {
                layer.msg($translate.instant('global.email_not_found'));
            } else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }
        };

        function sendOrder() {
            if($rootScope.isZb($rootScope.HOST_COMPANY_ID)){
                var entity = {};
                entity.remarks = vm.bkRemarks;
                $uibModal.open({
                    templateUrl: 'app/entities/bookingCtns/bookingCtns-detail.html',
                    controller: 'BookingCtnsDetailController',
                    size: 'xl',
                    resolve: {
                        entity: function () {
                            return entity;
                        },
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('opsCost');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function(result) {
                    doSend();
                }, function() {
                })
            }
            else {
                doSend();
            }            
        }

        $scope.bkRemarks = $translate.instant('autopsApp.booking.home.tips_content');
        $scope.bkRemarks = $scope.bkRemarks + "\n请仔细核对提交装箱单发票件重尺、货值（注意不能低申报漏报瞒报）、品名 、HS CODE 、税率等信息；\n";
        if($rootScope.isZb($rootScope.HOST_COMPANY_ID)){
            $scope.bkRemarks = "请仔细核对提交装箱单发票件重尺、货值（注意不能低申报漏报瞒报）、品名 、HS CODE 、税率等信息；\n我司预收税率，为使用我司税号清关，由于外贸商业机密，我司不提供关税清单和税费截图；\n如货物到港清关时，遇到反倾销或海关大幅调整关税的情况，我司将提供凭证，收取关税差额；\n如客户要求清完关后凭税单及发票付关税，我司将收取关税代缴手续费（代缴关税总金额的5%）;";           
        }
        $scope.updateVolGw = function() {
            DataUtils.updateGuidePl($scope.guide);
        }

    }
})();
