(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('VirtualWarehouse', VirtualWarehouse);

    VirtualWarehouse.$inject = ['$resource'];

    function VirtualWarehouse ($resource) {
        var resourceUrl =  'api/virtual-warehouses/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'getByWarehouseId': {
                method:'GET',
                url:'api/virtual-warehouses/getByWarehouseId',
                isArray:true
            },  
            'bindVirtualWarehouse': {
                method:'PUT',
                url:'api/virtual-warehouses/bindVirtualWarehouse'
            },
            'update': { method:'PUT' }
        });
    }
})();
