(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('DnCnCombineController', DnCnCombineController);
    
    DnCnCombineController.$inject = ['$rootScope', '$scope', '$translate', '$uibModal', '$timeout', 
    'Principal', 'OpsVender','AccountInvoice', 'InvoiceInfo', 'AutoCheck', 'DateUtils', 'DataUtils', 'uiGridConstants', 'GridState'];
    
    function DnCnCombineController($rootScope, $scope, $translate, $uibModal, $timeout,
        Principal, OpsVender, AccountInvoice, InvoiceInfo, AutoCheck, DateUtils, DataUtils, uiGridConstants, GridState) {
       
        var autoCheck;
        var gridStateObj = {};
        var gridStateCopy = null;
        var choosedGridStateObj = {};
        var choosedGridStateCopy = null;
        $timeout(function (){
            if($rootScope.AUTOCHECK && $rootScope.AUTOCHECK.idSet){
                $scope.accountCashier.venderId = $rootScope.AUTOCHECK.payerVid;
                $scope.accountCashier.payer = $rootScope.AUTOCHECK.payer
                autoCheck = angular.copy($rootScope.AUTOCHECK);
                if(autoCheck.currency){
                    $scope.getListByCurrency(autoCheck.currency);
                }
                else {
                    $scope.isSaving = true;
                    AutoCheck.getAllPayCurrencys(autoCheck, function(result) {
                        $scope.isSaving = false;
                        if(!result || result.length == 0){
                            return;
                        }
                        $scope.getListByCurrency(result[0].key);
                        $scope.currencyMaps = result;                       
                    }, function(response) {
                        $scope.isSaving = false;
                    });
                }
                $rootScope.AUTOCHECK=null;           
            }
            gridStateCopy = $scope.gridApi.saveState.save();
            choosedGridStateCopy = $scope.choosedGridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 158
            }, function (result) {
                gridStateObj = result;
                if (!gridStateObj || !gridStateObj.id) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
            })

            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 159
            }, function (result) {
                choosedGridStateObj = result;
                if (!choosedGridStateObj || !choosedGridStateObj.id) {
                    $scope.choosedGridApi.saveState.restore($scope, choosedGridStateCopy);
                    return;
                }
                $scope.choosedGridApi.saveState.restore($scope, angular.fromJson(choosedGridStateObj.gridState));
            })
            resize();
        });

        $scope.getListByCurrency = function(currency) {
            $scope.choosedDnList =[];
            $scope.choosedCnList =[];
            autoCheck.currency = currency;
            $scope.currency = currency;
            $scope.isSaving = true;
            AutoCheck.getDnCnsForCom(autoCheck, function(result) {
                $scope.isSaving = false;
                $scope.accountPayments = result.accountPayments;    
                $scope.accountInvoices = result.accountInvoices;   
                if ($scope.accountPayments.length == 0 && $scope.accountInvoices.length == 0){
                    layer.msg($translate.instant('global.messages.resultNull'));
                    $scope.accountCashier.payer = "";
                    return;
                }   
                updateList();
            }, function(response) {
                $scope.isSaving = false;
            });     
        }


        $scope.currency = null;
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                $scope.currency = $rootScope.account.baseCurrency;
            });
        }
        else {
            $scope.currency = $rootScope.account.baseCurrency;
        }
        $rootScope.LOADALLVENDERS();
        $rootScope.loadCurrencys();

        $scope.isSaving = false;
        $scope.exPaid = true;

        $scope.accountCashier = {};
        $scope.accountPayments = [];
        $scope.accountInvoices = [];
        var venderCopy;
        $scope._onPayerSelected = function(data) {
            $scope.accountCashier.venderId = data.id;
            AccountInvoice.getForCombine({
                companyId: $rootScope.account.companyId,
                currency: $scope.currency,
                venderId: data.id,
                exPaid: $scope.exPaid
            }, function(result) {     
                $scope.accountInvoice = {};
                $scope.accountPayments = result.accountPayments;    
                $scope.accountInvoices = result.accountInvoices;    
                if ($scope.accountInvoices.length == 0 && $scope.accountPayments.length == 0){
                    layer.msg($translate.instant('global.messages.resultNull'));
                    $scope.accountCashier.payer = "";
                    return;
                }      
                updateList();       
                if(venderCopy && venderCopy.invoiceInfos && venderCopy.invoiceInfos.length > 0){
                    updateSettlementObj(venderCopy.invoiceInfos[0]);
                    updateInvoiceTitle(venderCopy.invoiceInfos[0]);
                }   
            });
            OpsVender.getInvoiceVenderDTO({id: data.id}, function(result) {
                venderCopy = result;
                if(venderCopy && venderCopy.invoiceInfos && venderCopy.invoiceInfos.length > 0){
                    updateSettlementObj(venderCopy.invoiceInfos[0]);
                    updateInvoiceTitle(venderCopy.invoiceInfos[0]);
                }
            });
        }
        
        $scope.searchDnNum = "";
        $scope.searchCnNum = "";
        $scope.etdEndDate = null
        var updateList = function() {
            //不在区间
            if($scope.accountPayments && $scope.accountPayments.length > 0){
                for(var i = $scope.accountPayments.length - 1; i >= 0; i--){
                    if($scope.etdEndDate && DateUtils.compareDate($scope.etdEndDate, $scope.accountPayments[i].economicDate) < 0){
                        $scope.accountPayments.splice(i, 1);
                    }
                    else if($scope.accountPayments[i].isTtl){
                        $scope.accountPayments.splice(i, 1);
                    }
                }
            }
            if($scope.accountInvoices && $scope.accountInvoices.length > 0){
                for(var i = $scope.accountInvoices.length - 1; i >= 0; i--){
                    if($scope.etdEndDate && DateUtils.compareDate($scope.etdEndDate, $scope.accountInvoices[i].economicDate) < 0){
                        $scope.accountInvoices.splice(i, 1);
                    }
                    else if($scope.accountInvoices[i].isTtl){
                        $scope.accountInvoices.splice(i, 1);
                    }
                }
            }
            //已付款
            if($scope.accountPayments && $scope.accountPayments.length > 0){
                for(var i = $scope.accountPayments.length - 1; i >= 0; i--){
                    if($scope.accountPayments[i].paidAmount){
                        $scope.accountPayments.splice(i, 1);
                    }
                }
            }

            // 因为应收、应付数据放在同一个表格中，故使用isDn字段来区分某条数据是应收还是应付
            angular.forEach($scope.accountInvoices, function(data){
                data.isDn = true;
            });
            angular.forEach($scope.accountPayments, function(data){
                data.isDn = false;
            });
            $scope.chooseGridOptions.data = [];
            $scope.chooseGridOptions.data = $scope.chooseGridOptions.data.concat($scope.accountInvoices);
            $scope.chooseGridOptions.data = $scope.chooseGridOptions.data.concat($scope.accountPayments);

            $scope.accountCashier.total = 0;
            angular.forEach($scope.accountInvoices, function(data){
                $scope.accountCashier.total += data.invoiceAmount;
            });
            angular.forEach($scope.accountPayments, function(data){
                $scope.accountCashier.total -= data.invoiceAmount;
            });
            $scope.accountCashier.total = DataUtils.round($scope.accountCashier.total, 2);

            //已选中
            $scope.accountCashier.invoiceAmt = 0;
            angular.forEach($scope.choosedDnList, function(data){
                $scope.accountCashier.invoiceAmt += data.invoiceAmount;
            });
            angular.forEach($scope.choosedCnList, function(data){
                $scope.accountCashier.invoiceAmt -= data.invoiceAmount;
            });
            $scope.accountCashier.invoiceAmt = DataUtils.round($scope.accountCashier.invoiceAmt, 2);
            resize();
        }

        $scope.delFromDnList = function (invoice) {
            var index = $scope.accountInvoices.indexOf(invoice);
            if (index > -1) {
                $scope.accountInvoices.splice(index, 1);
                updateList();
            };            
        }

        $scope.delFromCnList = function (invoice) {
            var index = $scope.accountPayments.indexOf(invoice);
            if (index > -1) {
                $scope.accountPayments.splice(index, 1);
                updateList();
            };            
        }

        $scope.choosedDnList =[];
        $scope.choosedCnList =[];
        $scope.addToDnList = function (invoice) {
            if(!invoice.dnOnly || invoice.comId || invoice.isVoided){
                layer.msg($translate.instant('global.invalidRecord'));
                return;
            }
            var isRepeated = false;
            for(var j = $scope.choosedDnList.length -1; j >=0; j--){
                if (invoice.id ==$scope.choosedDnList[j].id){
                    isRepeated = true;
                    break;
                }
            }
            if (isRepeated){
                layer.msg($translate.instant('global.messages.repeated'));
            }
            else {
                $scope.choosedDnList.push(invoice);
                $scope.choosedGridOptions.data.push(invoice);
            }

            var index = $scope.accountInvoices.indexOf(invoice);
            if (index > -1) {
                $scope.accountInvoices.splice(index, 1);                
            };        
            updateList();    
            $scope.searchDnNum = "";
        }
        $scope.addToCnList = function (invoice) {
            if(!invoice.dnOnly || invoice.comId || invoice.isVoided || invoice.isPaid){
                layer.msg($translate.instant('global.invalidRecord'));
                return;
            }
            var isRepeated = false;
            for(var j = $scope.choosedCnList.length -1; j >=0; j--){
                if (invoice.id ==$scope.choosedCnList[j].id){
                    isRepeated = true;
                    break;
                }
            }
            if (isRepeated){
                layer.msg($translate.instant('global.messages.repeated'));
            }
            else {
                $scope.choosedCnList.push(invoice);
                $scope.choosedGridOptions.data.push(invoice);
            }

            var index = $scope.accountPayments.indexOf(invoice);
            if (index > -1) {
                $scope.accountPayments.splice(index, 1);                
            };        
            updateList();    
            $scope.searchCnNum = "";
        }

        $scope.delDnFromChoosed = function (invoice) {
            var isRepeated = false;
            for(var j = $scope.accountInvoices.length -1; j >=0; j--){
                if (invoice.id ==$scope.accountInvoices[j].id){
                    isRepeated = true;
                    break;
                }
            }
            if (!isRepeated){
                $scope.accountInvoices.push(invoice);
            }
            var index = $scope.choosedDnList.indexOf(invoice);
            if (index > -1) {
                $scope.choosedDnList.splice(index, 1);                
            };        
            var index = $scope.choosedGridOptions.data.indexOf(invoice);
            if (index > -1) {
                $scope.choosedGridOptions.data.splice(index, 1);
            }; 
            updateList();    
        }
        $scope.delCnFromChoosed = function (invoice) {
            var isRepeated = false;
            for(var j = $scope.accountPayments.length -1; j >=0; j--){
                if (invoice.id ==$scope.accountPayments[j].id){
                    isRepeated = true;
                    break;
                }
            }
            if (!isRepeated){
                $scope.accountPayments.push(invoice);
            }
            var index = $scope.choosedCnList.indexOf(invoice);
            if (index > -1) {
                $scope.choosedCnList.splice(index, 1);                
            };      
            var index = $scope.choosedGridOptions.data.indexOf(invoice);
            if (index > -1) {
                $scope.choosedGridOptions.data.splice(index, 1);                
            };    
            updateList();    
        }

        $scope.addAll = function () {
            for(var i = $scope.accountPayments.length -1; i >=0; i--){      
                $scope.addToCnList($scope.accountPayments[i]);
            }
            for(var i = $scope.accountInvoices.length -1; i >=0; i--){      
                $scope.addToDnList($scope.accountInvoices[i]);
            }
        }

        $scope.deadlineDate = null;
        $scope.accountInvoice = {};
        $scope.electronicInvoice = false;
        $scope.doCom = function () {
            if($scope.accountCashier.invoiceAmt > 0 && !$scope.accountInvoice.invoiceTitle){
                $scope.changeInvoiceTitle($scope.accountCashier.venderId);
                layer.msg($translate.instant('autopsApp.accountInvoice.invoiceTitleNullAlert'));
                return;
            }
            $scope.isSaving = true;
            if ($scope.choosedCnList.length == 0 && $scope.choosedDnList.length == 0) {
                layer.msg($translate.instant('autopsApp.accountInvoice.home.nothingChoosed'));
                $scope.isSaving = false;
                return;
            }
            var combineRequestDTO = {};
            combineRequestDTO.accountInvoices = $scope.choosedDnList;
            combineRequestDTO.accountPayments = $scope.choosedCnList;
            
            $scope.accountInvoice.deadLine = $scope.deadlineDate;
            combineRequestDTO.accountInvoice = $scope.accountInvoice;
            combineRequestDTO.electronicInvoice = $scope.electronicInvoice;
            combineRequestDTO.isDn = true;
            if ($scope.accountCashier.invoiceAmt == 0){
                if($scope.choosedDnList.length == 0){
                    combineRequestDTO.isDn = false;
                }
            }
            else if ($scope.accountCashier.invoiceAmt < 0){
                combineRequestDTO.isDn = false;
            }
            AccountInvoice.doCom(combineRequestDTO, onSaveSuccess, onSaveError);   
        }

        var onSaveSuccess = function(result) {
            $scope.isSaving = false;
            $scope.choosedDnList = [];
            $scope.choosedCnList = [];
            $scope.accountPayments = [];
            $scope.accountInvoices = [];
            $scope.choosedGridOptions.data = [];
            $scope.chooseGridOptions.data = [];
            $scope.accountCashier.venderId = null;
            $scope.accountCashier.payer = null;            
            $rootScope.PREVIEW_DCN(result);      
            updateList();        
            layer.msg($translate.instant('global.messages.submitted'));
        };

        var onSaveError = function(result) {
            $scope.isSaving = false;
            $scope.choosedDnList = [];
            $scope.choosedCnList = [];
            $scope.accountPayments = [];   
            $scope.accountInvoices = []; 
            layer.msg($translate.instant('global.save_fail'));
        };

        $scope.clearAccountInvoices = function() {
            $scope.accountCashier.payer = null;
            $scope.accountInvoices = [];
            $scope.accountPayments = [];
            updateList();
        }

        $scope.clearChoosedList = function() {
            for(var i = $scope.choosedDnList.length -1; i >=0; i--){
                $scope.delDnFromChoosed($scope.choosedDnList[i]);
            }
            for(var i = $scope.choosedCnList.length -1; i >=0; i--){
                $scope.delCnFromChoosed($scope.choosedCnList[i]);
            }            
            updateList();
        }


        $scope.datePickerForDeadLine = {};
        $scope.datePickerForDeadLine.status = {
            opened: false
        };
        $scope.datePickerForDeadLineOpen = function($event) {
            $scope.datePickerForDeadLine.status.opened = true;
        };
        $scope.datePickerForEta = {};
        $scope.datePickerForEta.status = {
            opened: false
        };
        $scope.datePickerForEtaOpen = function($event) {
            $scope.datePickerForEta.status.opened = true;
        };
        $scope.changeInvoiceTitle = function(vId) {
            if(!vId && (!venderCopy || !venderCopy.invoiceInfos || venderCopy.invoiceInfos.length == 0)){
                layer.msg($translate.instant('global.messages.resultNull'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/invoice-info/invoice-info-dialog.html',
                controller: 'InvoiceInfoDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        if(venderCopy && venderCopy.invoiceInfos && venderCopy.invoiceInfos.length){
                            return venderCopy.invoiceInfos;
                        }
                        return InvoiceInfo.getVenderInvoiceInfos({venderId:vId}).$promise;                        
                    }]
               }
           }).result.then(function(result) {
                updateInvoiceTitle(result);
           }, function() {
           });   
        };

        var updateInvoiceTitle = function(result){
            $scope.accountInvoice.invoiceTitle = result.invoiceTitle;
            $scope.accountInvoice.taxNum = result.taxNum;
            if($scope.currency == $rootScope.account.baseCurrency){
                $scope.accountInvoice.bankInfo = result.bankInfo;
            }
            else {
                $scope.accountInvoice.bankInfo = result.bankInfoUsd;
            }                        
            $scope.accountInvoice.addressTel = result.addressTel;
            $scope.accountInvoice.mailAddress = result.mailAddress;
            $scope.accountInvoice.mailMob = result.mailMob;
            $scope.accountInvoice.mailAttn = result.mailAttn;
            $scope.accountInvoice.eInvoiceEmail = result.einvoiceEmail;
        }

        $scope.dnCnNum = null;
        $scope.getDnCn = function(vId) {
            if(!$scope.currency || !$scope.dnCnNum){
                return;
            }
            AccountInvoice.getDnCn({
                companyId: $rootScope.account.companyId,
                currency: $scope.currency,
                dnNum: $scope.dnCnNum
            }, function(result) {     
                addDnCnList(result);                       
            });
        }

        function addDnCnList(result) {
            if (result.invoicesList || result.invoicesList.length > 0) {
                angular.forEach(result.invoicesList, function(data){
                    $scope.addToDnList(data);                       
                })
            }
            if (result.paymentsList || result.paymentsList.length > 0) {
                angular.forEach(result.paymentsList, function(data){
                    $scope.addToCnList(data);                       
                })
            }
            updateList(); 
        }

        $scope.statementCode = null;
        $scope.getDnCnByStatementCode = function() {
            if(!$scope.statementCode){
                return;
            }
            $scope.isSaving = true;
            AutoCheck.getDnCnByStatementCode({
                companyId: $rootScope.account.companyId,
                currency: $scope.currency,
                code: $scope.statementCode
            }, function(result) {
                $scope.isSaving = false;
                addDnCnList(result);
            }, function(response) {
                layer.msg($translate.instant('global.messages.resultNull'));
                $scope.isSaving = false;
            });
        }
        $scope.changeBankInfo = function(vId) {
            if(!vId && (!venderCopy || !venderCopy.invoiceInfos || venderCopy.invoiceInfos.length == 0)){
                layer.msg($translate.instant('global.messages.resultNull'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/invoice-info/invoice-info-dialog.html',
                controller: 'InvoiceInfoDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        if(venderCopy && venderCopy.invoiceInfos && venderCopy.invoiceInfos.length){
                            return venderCopy.invoiceInfos;
                        }
                        return InvoiceInfo.getVenderInvoiceInfos({venderId:vId}).$promise;     
                    }]
               }
           }).result.then(function(result) {
                $scope.accountInvoice.remarks="";
                updateSettlementObj(result);
           }, function() {

           });   
        };
        var updateSettlementObj = function(invoiceInfo) {
            if(invoiceInfo.invoiceTitle){
                $scope.accountInvoice.remarks = invoiceInfo.invoiceTitle + "\n";
            }            
            if($scope.currency == 'USD'){
                if(invoiceInfo.bankInfoUsd){
                    $scope.accountInvoice.remarks += invoiceInfo.bankInfoUsd;
                }                
            }
            else {
                if(invoiceInfo.bankInfo){
                    $scope.accountInvoice.remarks += invoiceInfo.bankInfo;
                }
            }
            if($scope.accountInvoice.remarks){
                $scope.accountInvoice.remarks += "\n";
            }
        };

        // 根据账单时的列
        var defaultColumnDefs = [{
            field: 'id',
            width: 60,
            displayName: "",
            pinnedLeft: true,
            enableColumnMenu: false,
            enableFiltering: false,
            aggregationType: uiGridConstants.aggregationTypes.count,
            cellTemplate: '<div align="center"><span class=text-primary ng-click="row.entity.isDn ? grid.appScope.addToDnList(row.entity) : grid.appScope.addToCnList(row.entity)"><span class="glyphicon glyphicon-plus"></span> </span><span class=text-danger ng-click="row.entity.isDn ? grid.appScope.delFromDnList(row.entity) : grid.appScope.delFromCnList(row.entity)" style=margin-left:5px><span class="glyphicon glyphicon-remove"></span></span></div>'
        }, {
            field: 'dnNum',
            minWidth: 120,
            displayName: $translate.instant('autopsApp.accountInvoice.dnNum'),
            cellTemplate: '<span class="grid-text"><a href="" ng-click="grid.appScope.PREVIEW_DCN(row.entity)">{{row.entity.dnNum}}</a></span>'
        }, {
            field: 'invoiceAmount',
            minWidth: 150,
            displayName: $translate.instant('autopsApp.accountInvoice.invoiceAmount'),
            cellTemplate: '<div class="ui-grid-cell-contents text-right"><span ng-show="row.entity.isDn"><span>{{row.entity.currency}}</span><span class="ml-3">{{grid.appScope.FORMAT_NUM(row.entity.invoiceAmount)}}</span></span><span ng-hide="row.entity.isDn" class="text-danger">(<span>{{row.entity.currency}}</span><span class="ml-3">{{grid.appScope.FORMAT_NUM(row.entity.invoiceAmount)}}</span>)</span></div>'
        }, {
            field: 'payer',
            minWidth: 140,
            displayName: $translate.instant('autopsApp.accountInvoice.payer'),
            cellTemplate: '<div class="td-150 ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{row.entity.isDn ? row.entity.payer : row.entity.invoiceTitle}}">{{row.entity.isDn ? row.entity.payer : row.entity.invoiceTitle}}</div>'
        }, {
            field: 'blNums',
            minWidth: 140,
            displayName: $translate.instant('autopsApp.accountInvoice.blNums')
        }, {
            field: 'jobNums',
            minWidth: 140,
            displayName: $translate.instant('autopsApp.accountInvoice.jobNums')
        }, {
            field: 'sales',
            minWidth: 120,
            displayName: $translate.instant('autopsApp.accountInvoice.salesName')
        }, {
            field: 'creater',
            minWidth: 120,
            displayName: $translate.instant('autopsApp.accountInvoice.creater')
        }, {
            field: 'economicDate',
            minWidth: 150,
            displayName: $translate.instant('autopsApp.accountInvoice.economicDate'),
            cellTemplate: '<span class="grid-text">{{row.entity.economicDate | date:"mediumDate"}}</span>'
        }, {
            field: 'invoiceNum',
            minWidth: 120,
            displayName: $translate.instant('autopsApp.accountInvoice.invoiceNum')
        },{ field: 'invoiceTime',
            displayName: $translate.instant('autopsApp.accountInvoice.invoiceTime'),
            filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
            filters: [
                {
                    condition: uiGridConstants.filter.GREATER_THAN,
                    placeholder: ' > '
                },
                {
                    condition: uiGridConstants.filter.LESS_THAN,
                    placeholder: ' < '
                }
            ],
            minWidth:160,
            cellTemplate: '<span class="grid-text">{{row.entity.invoiceTime | date:\'medium\'}}</span>'
        },{ field: 'sendTime', 
            minWidth:200, 
            type:'date',
            filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
            filters: [
                {
                  condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                  placeholder: ' >= '
                },
                {
                  condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                  placeholder: ' <= '
                }
            ],
            displayName: $translate.instant('autopsApp.accountInvoice.sendTime'), 
            cellTemplate: '<span class="grid-text">{{row.entity.sendTime | date:\'medium\'}}</span>'}];

        if ($rootScope.isYuyue($rootScope.account.companyId)) {
            defaultColumnDefs.push({ field: 'ataTime',
                displayName: "ATA",
                filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                filters: [
                    {
                        condition: uiGridConstants.filter.GREATER_THAN,
                        placeholder: ' > '
                    },
                    {
                        condition: uiGridConstants.filter.LESS_THAN,
                        placeholder: ' < '
                    }
                ],
                minWidth:160,
                cellTemplate: '<span class="grid-text">{{row.entity.ataTime | date:\'medium\'}}</span>'
            });
        }
        

        var chooseColumnDefs = angular.copy(defaultColumnDefs);
        var choosedColumnDefs = [];
        angular.forEach(defaultColumnDefs, function(col){
            if(col.field == 'id'){
                var colCopy = angular.copy(col);
                colCopy.cellTemplate = '<div align="center"><span ng-click="row.entity.isDn ? grid.appScope.delDnFromChoosed(row.entity) : grid.appScope.delCnFromChoosed(row.entity)" class="text-danger" style="margin-left: 5px;"><span class="glyphicon glyphicon-remove"></span></span></div>';
                choosedColumnDefs.push(colCopy);
                return;
            }
            choosedColumnDefs.push(col);
        })

        $scope.chooseGridOptions = {
            enableSorting: true, //排序
            useExternalSorting: false,

            enableGridMenu: true, //表格 菜单
            showGridFooter: false, //表格的footer
            showColumnFooter: false, //每列footer

            enableFiltering: true, //高级过滤
            useExternalFiltering: false,

            enableHorizontalScrollbar: 1, //表格的水平滚动条
            enableVerticalScrollbar: 1, //表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            exporterMenuCsv: false,
            exporterMenuPdf: false,
            exporterMenuExcel: false,

            paginationPageSizes: [20, 35, 50],
            paginationPageSize: 20,
            useExternalPagination: true,

            minimumColumnSize: 70,
            exporterExcelFilename: "invoice",
            columnDefs: chooseColumnDefs,
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function ($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 158;
                    GridState.update(gridStateObj, function (result) {
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function (error) {
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function ($event) {
                    if (gridStateObj.gridState) {
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function ($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function ($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.chooseGridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "accountInvoice";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.chooseGridOptions.exporterExcelFilename);
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function ($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.chooseGridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "accountInvoice";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.chooseGridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function (gridApi) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function (grid, sortColumns) {
                    if (!$scope.chooseGridOptions.useExternalSorting) {
                        if (sortColumns[sortColumns.length - 1]) {
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.chooseGridOptions.data.sort(function (a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    if (sortColumns[sortColumns.length - 1]) {
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    }
                });
            }
        }

        $scope.choosedGridOptions = {
            enableSorting: true, //排序
            useExternalSorting: false,

            enableGridMenu: true, //表格 菜单
            showGridFooter: false, //表格的footer
            showColumnFooter: false, //每列footer

            enableFiltering: true, //高级过滤
            useExternalFiltering: false,

            enableHorizontalScrollbar: 1, //表格的水平滚动条
            enableVerticalScrollbar: 1, //表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            exporterMenuCsv: false,
            exporterMenuPdf: false,
            exporterMenuExcel: false,

            paginationPageSizes: [20, 35, 50],
            paginationPageSize: 20,
            useExternalPagination: true,

            minimumColumnSize: 70,
            exporterExcelFilename: "Invoice",
            columnDefs: choosedColumnDefs,
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function ($event) {
                    choosedGridStateObj.gridState = angular.toJson($scope.choosedGridApi.saveState.save());
                    choosedGridStateObj.userId = $rootScope.account.id;
                    choosedGridStateObj.gridId = 159;
                    GridState.update(choosedGridStateObj, function (result) {
                        choosedGridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function (error) {
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function ($event) {
                    if (choosedGridStateObj.gridState) {
                        $scope.choosedGridApi.saveState.restore($scope, angular.fromJson(choosedGridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function ($event) {
                    $scope.choosedGridApi.saveState.restore($scope, choosedGridStateCopy);
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function ($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.choosedGridOptions;
                    jsonObj.saveState = $scope.choosedGridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "accountPayment";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.choosedGridOptions.exporterExcelFilename);
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function ($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.choosedGridOptions;
                    jsonObj.saveState = $scope.choosedGridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "accountPayment";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.choosedGridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function (gridApi) {
                $scope.choosedGridApi = gridApi;
                $scope.choosedGridApi.core.on.sortChanged($scope, function (grid, sortColumns) {
                    if (!$scope.choosedGridOptions.useExternalSorting) {
                        if (sortColumns[sortColumns.length - 1]) {
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.choosedGridOptions.data.sort(function (a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    if (sortColumns[sortColumns.length - 1]) {
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    }
                });
            }
        }
        window.onresize = function(){
            resize();
        }
        function resize() {
            var winHeight = $(window).height() - $("#chooseGridId").offset().top - 50;
            var elements = document.getElementsByClassName('gridStyle');
            angular.forEach(elements, function(data){
                angular.element(data).css('height', winHeight + 'px');
            })
        }
    }
})();