(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('HandbookController', HandbookController);
    HandbookController.$inject = ['$rootScope', '$timeout', '$scope', '$translate', 'OpsFiles'];

    function HandbookController ($rootScope, $timeout, $scope, $translate, OpsFiles) {
    	var vm = this;
        $scope.companyVideos = [];
        if(!$rootScope.choosedVideo){
            $rootScope.choosedVideo = {};
        }
        $timeout(function (){
            return OpsFiles.getFilesList({
                filepath: "/videos/",
                companyId: $rootScope.account.companyId
            }, function(result){
                $scope.companyVideos = result;
                if($scope.companyVideos && $scope.companyVideos.length > 0){
                    myPlayer.reset();
                    myPlayer.src({type: "video/mp4", src: $scope.companyVideos[0].fileurl});
                    $rootScope.choosedVideo = $scope.companyVideos[0];
                }                
            });            
        });
        var myPlayer = videojs('own_video');

        $scope.changeVideo = function(video) {
            $rootScope.choosedVideo = video;
            myPlayer.reset();
            myPlayer.src({type: "video/mp4", src:video.fileurl});
            myPlayer.load(video.fileurl);
            myPlayer.play();
        }

    }
})();
