(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('LoginLogDetailController', LoginLogDetailController);

    LoginLogDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'LoginLog'];

    function LoginLogDetailController($scope, $rootScope, $stateParams, previousState, entity, LoginLog) {
        var vm = this;

        vm.loginLog = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('autopsApp:loginLogUpdate', function(event, result) {
            vm.loginLog = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
