(function() {
	'use strict';

	angular.module('autopsApp').directive('manageEnterpriseGoodsFile',
			manageEnterpriseGoodsFile);

	manageEnterpriseGoodsFile.$inject = [ '$parse', '$http', '$translate' ];

	function manageEnterpriseGoodsFile($parse, $http, $translate) {

		return {
			restrict : 'AE',
			scope : {
				triggerReload : '&',
				manageEnterpriseId : '=',
				goodsId : '='
			},
			link : function(scope, element, attrs, ngModel) {

				var model = $parse(attrs.manageEnterpriseGoodsFile);
				var modelSetter = model.assign;

				scope.ridFile = function() {
					angular.forEach(angular
							.element("input[name='wmsGoodsFile']"), function(
							inputElem) {
						angular.element(inputElem).val(null);
					});
				}

				element.bind('change', function(event) {

					if (element[0].files[0] == undefined) {
						return;
					}
					scope.$apply(function() {
						modelSetter(scope, element[0].files[0]);
					});

					var fd = new FormData();
					fd.append('file', element[0].files[0]);
					$http.post(
							'api/uploadGoodsFile/' + scope.manageEnterpriseId
									+ '/' + scope.goodsId, fd, {
								transformRequest : angular.identity,
								headers : {
									'Content-Type' : undefined
								},
							}).success(
							function(data) {
								if (angular.isDefined(data.filepath)) {
									scope.triggerReload(({file:data}));
									layer.msg($translate
											.instant('global.uploadSuccess'));
								}
								scope.ridFile();
							}).error(function(error) {
						layer.msg($translate.instant('global.uploadFail'));
						scope.ridFile();
						console.log("上传文件失败");
					});

				});
			},
		};
	}
})();