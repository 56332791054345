
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountRefDetailController', AccountRefDetailController);
    
    AccountRefDetailController.$inject = ['$rootScope', '$scope', '$translate', '$uibModal', '$uibModalInstance', '$timeout', 'AccountTitle', 
    'AccountInvoice','AccountCashier', 'AccountPayment', 'OpsVender', 'DataUtils', 'OpsFiles'];
    
    function AccountRefDetailController($rootScope, $scope, $translate, $uibModal, $uibModalInstance, $timeout, AccountTitle, 
        AccountInvoice, AccountCashier, AccountPayment, OpsVender, DataUtils, OpsFiles) {

        $scope.exportParams = {};
        $scope.exportParams.type = "exportByDn";

        $scope.accountInvoices = [];
        $scope.accountPayments = [];
        $scope.accountVouchers = [];

        $scope.choosedDnList =[];
        $scope.choosedCnList =[];
        $scope.choosedVouchersList =[];

        $scope.ttlList = [];
        $scope.choosedTtlList = [];
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.setType = function(type) {
            $scope.exportParams.type = type;
        };
        $scope.exportTypeChange = function() {
            $scope.accountInvoices = [];
            $scope.accountPayments = [];
            $scope.accountVouchers = [];

            $scope.choosedDnList =[];
            $scope.choosedCnList =[];   
            $scope.choosedVouchersList =[];
            
            $scope.ttlList = [];
            $scope.choosedTtlList = [];
        };

        $scope.searchDn = function() {
            var searchParams = {
                isVoided: false,
                isTtl: false,
                companyId: $rootScope.account.companyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountInvoice/accountInvoice-dialog.html',
                controller: 'AccountInvoiceDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }]
                }
            }).result.then(function(results) {
                $scope.accountInvoices = results;
                updateList(); 
            }, function() {
            });
        }

        $scope.searchCn = function() {
            var searchParams = {
                isVoided: false,
                isTtl: false,
                companyId: $rootScope.account.companyId
            };
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountPayment/accountPayment-dialog.html',
                controller: 'AccountPaymentDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return searchParams;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountPayment');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(results) {
                $scope.accountPayments = results;
                updateList(); 
            }, function() {
            });
        }

        $scope.searchVouchers = function() {
            var customFilterDTO = {};
            customFilterDTO.isVoided = false;
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountVoucher/accountVoucher-detail.html',
                controller: 'AccountVoucherDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',
                resolve: {
                    entity: [function() {
                        return customFilterDTO;
                    }]
                }
            }).result.then(function(results) {
                $scope.accountVouchers = results;
                updateList(); 
            }, function() {
            });
        }

        $scope.delFromDnList = function (index) {
            $scope.accountInvoices.splice(index, 1);
            updateList();
        }

        $scope.delFromCnList = function (index) {
            $scope.accountPayments.splice(index, 1);
            updateList();
        }
        $scope.delFromVouchersList = function (index) {
            $scope.accountVouchers.splice(index, 1);
            updateList();
        }

        function addOneDn(invoice) {
            var isRepeated = false;
            for(var j = $scope.choosedDnList.length -1; j >=0; j--){
                if (invoice.id ==$scope.choosedDnList[j].id){
                    isRepeated = true;
                    break;
                }
            }
            if (isRepeated){
                layer.msg($translate.instant('global.messages.repeated'));
            }
            else {
                $scope.choosedDnList.push(invoice);
            }
        }

        $scope.addToDnList = function (invoice) {
            addOneDn(invoice);
            var index = $scope.accountInvoices.indexOf(invoice);
            if (index > -1) {
                $scope.accountInvoices.splice(index, 1);                
            };        
            updateList();    
            $scope.searchDnNum = "";
        }
        function addOneCn(invoice) {
            var isRepeated = false;
            for(var j = $scope.choosedCnList.length -1; j >=0; j--){
                if (invoice.id ==$scope.choosedCnList[j].id){
                    isRepeated = true;
                    break;
                }
            }
            if (isRepeated){
                layer.msg($translate.instant('global.messages.repeated'));
            }
            else {
                $scope.choosedCnList.push(invoice);
            }
        }
        $scope.addToCnList = function (invoice) {
            addOneCn(invoice);
            var index = $scope.accountPayments.indexOf(invoice);
            if (index > -1) {
                $scope.accountPayments.splice(index, 1);                
            };        
            updateList();    
            $scope.searchCnNum = "";
        }
        function addOneVoucher(voucher) {
            var isRepeated = false;
            for(var j = $scope.choosedVouchersList.length -1; j >=0; j--){
                if (voucher.id ==$scope.choosedVouchersList[j].id){
                    isRepeated = true;
                    break;
                }
            }
            if (isRepeated){
                layer.msg($translate.instant('global.messages.repeated'));
            }
            else {
                $scope.choosedVouchersList.push(voucher);
            }
        }
        $scope.addToVouchersList = function (voucher) {
            addOneVoucher(voucher);
            var index = $scope.accountVouchers.indexOf(voucher);
            if (index > -1) {
                $scope.accountVouchers.splice(index, 1);                
            };        
            updateList();    
            $scope.searchVoucherNum = "";
        }

        function delOneDn(invoice) {
            var isRepeated = false;
            for(var j = $scope.accountInvoices.length -1; j >=0; j--){
                if (invoice.id ==$scope.accountInvoices[j].id){
                    isRepeated = true;
                    break;
                }
            }
            if (!isRepeated){
                $scope.accountInvoices.push(invoice);
            }
        }
        $scope.delDnFromChoosed = function (invoice) {
            delOneDn(invoice);

            var index = $scope.choosedDnList.indexOf(invoice);
            if (index > -1) {
                $scope.choosedDnList.splice(index, 1);                
            };        
            updateList();    
        }
        function delOneCn(invoice) {
            var isRepeated = false;
            for(var j = $scope.accountPayments.length -1; j >=0; j--){
                if (invoice.id ==$scope.accountPayments[j].id){
                    isRepeated = true;
                    break;
                }
            }
            if (!isRepeated){
                $scope.accountPayments.push(invoice);
            }
        }
        $scope.delCnFromChoosed = function (invoice) {
            delOneCn(invoice);
            var index = $scope.choosedCnList.indexOf(invoice);
            if (index > -1) {
                $scope.choosedCnList.splice(index, 1);                
            };        
            updateList();    
        }
        function delOneVoucher(voucher) {
            var isRepeated = false;
            for(var j = $scope.accountVouchers.length -1; j >=0; j--){
                if (voucher.id ==$scope.accountVouchers[j].id){
                    isRepeated = true;
                    break;
                }
            }
            if (!isRepeated){
                $scope.accountVouchers.push(voucher);
            }
        }
        $scope.delVoucherFromChoosed = function (voucher) {
            delOneVoucher(voucher);
            var index = $scope.choosedVouchersList.indexOf(voucher);
            if (index > -1) {
                $scope.choosedVouchersList.splice(index, 1);                
            };        
            updateList();    
        }

        $scope.edidVoucher = function(voucher) {
            var vouchersEntity = {};
            vouchersEntity.voucherId = voucher.id;
            vouchersEntity.accountVoucher = voucher;
            vouchersEntity.accountVouchers = $scope.accountVouchers;
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountVoucher/accountVoucher-dialog.html',
                controller: 'AccountVoucherDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return vouchersEntity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountVoucher');
                        $translatePartialLoader.addPart('writeOff');
                        $translatePartialLoader.addPart('accountInvoice');    
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
            }, function() {});
        }


    

        var updateList = function() {
            var currencies = new Set();
            var ttlAmt;
            $scope.ttlList = [];
            $scope.choosedTtlList = [];
            if($scope.exportParams.type == 'exportByDn'){
                angular.forEach($scope.accountInvoices, function(data){
                    data.invoiceAmount = data.invoiceAmount + "";
                    currencies.add(data.currency);
                })
                currencies.forEach(function(currency){
                    ttlAmt = 0;
                    angular.forEach($scope.accountInvoices, function(data){
                        if(currency == data.currency){
                            ttlAmt += data.invoiceAmount-0;
                        }
                    })
                    if(ttlAmt){
                        var newTtl = {};
                        newTtl.currency = currency;
                        newTtl.ttlAmt = ttlAmt.toFixed(2) -0;
                        $scope.ttlList.push(newTtl);
                    }
                })

                currencies = new Set();
                angular.forEach($scope.choosedDnList, function(data){
                    currencies.add(data.currency);
                })
                currencies.forEach(function(currency){
                    ttlAmt = 0;
                    angular.forEach($scope.choosedDnList, function(data){
                        if(currency == data.currency){
                            ttlAmt += data.invoiceAmount-0;
                        }
                    })
                    if(ttlAmt){
                        var newTtl = {};
                        newTtl.currency = currency;
                        newTtl.ttlAmt = ttlAmt.toFixed(2) -0;
                        $scope.choosedTtlList.push(newTtl);
                    }
                })
            }
            else if($scope.exportParams.type == 'exportByCn'){
                angular.forEach($scope.accountPayments, function(data){
                    data.invoiceAmount = data.invoiceAmount + "";
                    currencies.add(data.currency);
                })
                currencies.forEach(function(currency){
                    ttlAmt = 0;
                    angular.forEach($scope.accountPayments, function(data){
                        if(currency == data.currency){
                            ttlAmt += data.invoiceAmount-0;
                        }
                    })
                    if(ttlAmt){
                        var newTtl = {};
                        newTtl.currency = currency;
                        newTtl.ttlAmt = ttlAmt.toFixed(2) -0;
                        $scope.ttlList.push(newTtl);
                    }
                })
                
                currencies = new Set();
                angular.forEach($scope.choosedCnList, function(data){
                    currencies.add(data.currency);
                })
                currencies.forEach(function(currency){
                    ttlAmt = 0;
                    angular.forEach($scope.choosedCnList, function(data){
                        if(currency == data.currency){
                            ttlAmt += data.invoiceAmount-0;
                        }
                    })
                    if(ttlAmt){
                        var newTtl = {};
                        newTtl.currency = currency;
                        newTtl.ttlAmt = ttlAmt.toFixed(2) -0;
                        $scope.choosedTtlList.push(newTtl);
                    }
                })
            } 
            else if($scope.exportParams.type == 'exportByVoucher'){
                angular.forEach($scope.accountVouchers, function(data){
                    data.dnTotal = data.dnTotal + "";
                    data.voucherNum = data.voucherNum + "";                    
                })
                currencies.add($rootScope.account.baseCurrency);
                currencies.forEach(function(currency){
                    ttlAmt = 0;
                    angular.forEach($scope.accountVouchers, function(data){
                        ttlAmt += data.dnTotal-0;
                    })
                    if(ttlAmt){
                        var newTtl = {};
                        newTtl.currency = currency;
                        newTtl.ttlAmt = ttlAmt.toFixed(2) -0;
                        $scope.ttlList.push(newTtl);
                    }
                })
                currencies.forEach(function(currency){
                    ttlAmt = 0;
                    angular.forEach($scope.choosedVouchersList, function(data){
                        ttlAmt += data.dnTotal-0;
                    })
                    if(ttlAmt){
                        var newTtl = {};
                        newTtl.currency = currency;
                        newTtl.ttlAmt = ttlAmt.toFixed(2) -0;
                        $scope.choosedTtlList.push(newTtl);
                    }
                })
            } 
        }
        $scope.addAll = function() {
            var isRepeated = false;
            if($scope.exportParams.type == 'exportByDn'){
                angular.forEach($scope.accountInvoices, function(data){
                    addOneDn(data);
                })
                $scope.accountInvoices = [];
            }
            else if($scope.exportParams.type == 'exportByCn'){
                angular.forEach($scope.accountPayments, function(data){
                    addOneCn(data);
                })
                $scope.accountPayments = [];                 
            }
            else if($scope.exportParams.type == 'exportByVoucher'){
                angular.forEach($scope.accountVouchers, function(data){
                    addOneVoucher(data);
                })
                $scope.accountVouchers = [];
            }
            updateList();   
        }
        $scope.removeAll = function() {
            var isRepeated = false;
            if($scope.exportParams.type == 'exportByDn'){
                angular.forEach($scope.choosedDnList, function(data){
                    delOneDn(data);
                })
                $scope.choosedDnList = [];
            }
            else if($scope.exportParams.type == 'exportByCn'){
                angular.forEach($scope.choosedCnList, function(data){
                    delOneCn(data);
                })
                $scope.choosedCnList = [];
            }
            else if($scope.exportParams.type == 'exportByVoucher'){
                angular.forEach($scope.choosedVouchersList, function(data){
                    delOneVoucher(data);
                })
                $scope.choosedVouchersList = [];
            }
            updateList();  
        }
        $scope.exportVouchers = function(combineRequired) {
            if($scope.exportParams.type == 'exportByDn'){
                if($scope.choosedDnList.length == 0){
                    return;
                }
                OpsFiles.exportDnVouchers({
                    combineRequired: combineRequired
                }, $scope.choosedDnList, $rootScope.exportDbfFile);
            }
            else if($scope.exportParams.type == 'exportByCn'){
                if($scope.choosedCnList.length == 0){
                    return;
                }
                OpsFiles.exportCnVouchers({
                    combineRequired: combineRequired
                }, $scope.choosedCnList, $rootScope.exportDbfFile);
            }
            else if($scope.exportParams.type == 'exportByVoucher'){
                if($scope.choosedVouchersList.length == 0){
                    return;
                }
                OpsFiles.exportVouchers({}, $scope.choosedVouchersList, $rootScope.exportDbfFile);
            }
        }

        $scope.scVoucerExport = function() {
            var voucherList = [];
            if($scope.exportParams.type == 'exportByDn'){
                angular.forEach($scope.choosedDnList, function(data){
                    if(data.voucherId){
                        voucherList.push({id: data.voucherId});
                    }                    
                })
            }
            else if($scope.exportParams.type == 'exportByCn'){
                angular.forEach($scope.choosedCnList, function(data){
                    if(data.voucherId){
                        voucherList.push({id: data.voucherId});
                    }                    
                })
            }
            else if($scope.exportParams.type == 'exportByVoucher'){
                voucherList = $scope.choosedVouchersList;
            }

            if (voucherList.length == 0) {
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            OpsFiles.scVoucerExport({}, voucherList, function(result) {
                $rootScope.DOWNLOADEXCEL(result, "sapVouchers.xlsx");
            })
        }

    }
})();