(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CoscoTrackingController', CoscoTrackingController);

        CoscoTrackingController.$inject = ['$scope', 'ExpressTracking', 'entity', '$rootScope', '$timeout', '$uibModalInstance'];

    function CoscoTrackingController ($scope, ExpressTracking, entity, $rootScope, $timeout, $uibModalInstance) {
        var vm = this;
        $scope.orderNum = entity.orderNum;

        $scope.goBack = function() {
            $uibModalInstance.close();
        }

        $timeout(function (){
            ExpressTracking.getCoscoTrackingToken({}, function(result) {
                var iframe= document.getElementById("child");
                iframe.src = "https://cct.elines.coscoshipping.com/scct/eb/external?type=bookingNumber&tenant=LD&lang=zh&userId=ledai&token=" + result.token + "&number=" + $scope.orderNum;
            }, $rootScope.ALERT_ERROR);
        });
    }
})();
