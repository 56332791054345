(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('JsExpression', JsExpression);

    JsExpression.$inject = ['$resource', 'DateUtils'];

    function JsExpression ($resource, DateUtils) {
        var resourceUrl =  'api/js-expressions/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.effectiveDate = DateUtils.convertLocalDateFromServer(data.effectiveDate);
                    }
                    return data;
                }
            },
            'getJsExpressionsByTypeId': {
                method: 'GET',
                url:'api/js-expressions/byTypeId',
                isArray: true
            },
            'getJsTemplates': {
                method: 'GET',
                url:'api/js-expressions/getJsTemplates',
                isArray: true
            },            
            'clearBillingFd': {
                method: 'GET',
                url:'api/js-expressions/clearBillingFd'
            },       
            'getByAddressBookId': {
                method: 'GET',
                url:'api/js-expressions/getByAddressBookId'
            },   
            'update': { 
                method:'PUT',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.effectiveDate = DateUtils.convertLocalDateToServer(data.effectiveDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.effectiveDate = DateUtils.convertLocalDateFromServer(data.effectiveDate);
                    }
                    return data;
                }
            },            
            'save': { 
                method:'POST',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.effectiveDate = DateUtils.convertLocalDateToServer(data.effectiveDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.effectiveDate = DateUtils.convertLocalDateFromServer(data.effectiveDate);
                    }
                    return data;
                }
            }
        });
    }
})();
