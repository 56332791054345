(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BookingTruckDialogController', BookingTruckDialogController);

    BookingTruckDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', '$location', '$translate', '$rootScope', 'entity', 'BookingTruck','Principal'];
    
    function BookingTruckDialogController($scope, $stateParams, $uibModalInstance, $location, $translate, $rootScope, entity, BookingTruck, Principal) {
        $scope.isTrucker = true;
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                if ($rootScope.account.authorities.indexOf("ROLE_TRUCKER") !== -1){
                    $scope.isTrucker = true;                
                }
                else {
                    $scope.isTrucker = false;   
                }
            });
        }
        else {
            if ($rootScope.account.authorities.indexOf("ROLE_TRUCKER") !== -1){
                $scope.isTrucker = true;        
            }
            else {
                $scope.isTrucker = false;   
            }
        }
        $scope.bookingTruck = entity;
        $scope.load = function(id) {
            BookingTruck.getOne({id : id}, function(result) {
                $scope.bookingTruck = result;
            });
        };
        setTimeout(function () {   
            var path = $location.path();
            if (!$rootScope.id && path.indexOf("new") <= 0){
                $uibModalInstance.dismiss('cancel');
            }
        }, 500);  
        var onSaveSuccess = function (result) {
            layer.closeAll('dialog');
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            layer.closeAll('dialog');
            $scope.isSaving = false;
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.declineTruckOrder = function() {
            var title = $translate.instant('autopsApp.bookingTruck.declineTitle');
            var content = $translate.instant('autopsApp.bookingTruck.declineContent');
            layer.confirm(content, {
                title: title,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function() {
                $scope.bookingTruck.status = "Declined";
                $scope.bookingTruck.orderTime =  new Date();
                var trucks = [];
                trucks.push($scope.bookingTruck);
                BookingTruck.update(trucks, onSaveSuccess, onSaveError); 
            }, function() {
                return;
            });               
        };

        $scope.acceptTruckOrder = function() {
            $scope.bookingTruck.status = "Confirmed";
            $scope.bookingTruck.orderTime =  new Date();
            var trucks = [];
            trucks.push($scope.bookingTruck);
            BookingTruck.update(trucks, onSaveSuccess, onSaveError); 
        }

        $scope.cancelOrder = function(truck) {
            var title = $translate.instant('autopsApp.bookingTruck.cancelOrder');
            var content = $translate.instant('autopsApp.bookingTruck.cancelOrderAlert');
            layer.confirm(content, {
                title: title,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                truck.status = "Cancelled";
                truck.orderTime = new Date();
                var trucks = [];
                trucks.push(truck);
                BookingTruck.update(trucks, onSaveSuccess, onSaveError); 
            }, function() {
                return;
            });
        };
}
})();
