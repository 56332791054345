(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ShareClientDeleteController',ShareClientDeleteController);

    ShareClientDeleteController.$inject = ['$uibModalInstance', '$rootScope', '$scope', '$translate', 'entity', 'OpsVender'];

    function ShareClientDeleteController($uibModalInstance, $rootScope, $scope, $translate, entity, OpsVender) {
        var vm = this;

        vm.shareClient = entity;
        vm.clear = clear;
        vm.confirm = confirm;
        vm.salesFilter = salesFilter;
        $rootScope.loadClerks();
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        $scope.isSaving = false;
        $scope.oldSId = 0;
        $scope.newSId = 0;
        function confirm (id) {
            $scope.isSaving = true;
            OpsVender.shiftAll({
                oldSalesId:$scope.oldSId,
                newSalesId:$scope.newSId
            },  function(result){
                $scope.isSaving = false;
                layer.msg($translate.instant('global.save_success'));
                $uibModalInstance.close();
            }, function(error){
                $scope.isSaving = false;
                layer.msg($translate.instant('global.save_fail'));   
            });
        }

        $scope.updateOldSales = function(item) {
            $scope.oldSId = item.id;
        };
        $scope.updateNewSales = function(item) {
            $scope.newSId = item.id;
        };       

        function salesFilter (item) {
            var authorities = ["ROLE_SALES", "ROLE_CS", "ROLE_BOSS"];            
            for (var i = 0; i < authorities.length; i++) {
                if (item.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }
            return false;
        };


    }
})();
