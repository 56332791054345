(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AmsEdiDataDeleteController',AmsEdiDataDeleteController);

    AmsEdiDataDeleteController.$inject = ['$uibModalInstance', '$rootScope', '$timeout', 'entity', 'AmsEdiData'];

    function AmsEdiDataDeleteController($uibModalInstance, $rootScope, $timeout, entity, AmsEdiData) {
        var vm = this;

        vm.amsEdiData = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            AmsEdiData.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
        $timeout(function (){       
            if(vm.amsEdiData.manifestType != "SINOLINES" && $rootScope.HAVENOAUTH("AU_EDI_" + vm.amsEdiData.manifestType)){
                $rootScope.FORBIDDEN_BY_ALERT("AU_EDI_" + vm.amsEdiData.manifestType);
                $uibModalInstance.dismiss();
            }
        })

    }
})();
