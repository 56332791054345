(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('OpsItem', OpsItem);

    OpsItem.$inject = ['$resource','DateUtils'];

    function OpsItem($resource, DateUtils) {
        return $resource('api/opsItems/:id', {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'getAll': {
                method:'GET',
                url:'api/opsItemsAll',
                isArray:true
            },      
            'deleteAllItems': {
                method:'DELETE',
                url:'api/opsItems/deleteAllItems'
            },
            'getAllWmsService': {
                method:'GET',
                url:'api/ops-item/getAllWmsService',
                isArray:true
            },
            'groupShareOne': {
                method:'PUT',
                url:'api/ops-item/groupShareOne'
            },
            'groupShare': {
                method:'GET',
                url:'api/opsItems/groupShare'
            },
            'checkCodeRepeat': {
                method:'GET',
                url:'api/opsItems/checkCodeRepeat'
            },
            'update': { method:'PUT' }
        });
    }
})();
