
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OrderNodeDetailController', OrderNodeDetailController);

    OrderNodeDetailController.$inject = ['$http', '$scope', '$rootScope', '$uibModalInstance', '$translate', 
    'entity', 'Guide'];

    function OrderNodeDetailController ($http, $scope, $rootScope, $uibModalInstance, $translate, 
        entity, Guide) {
        var vm = this;

        vm.clear = clear;
        vm.updateTrackingNum = updateTrackingNum;
        vm.parsingExcelStr = parsingExcelStr;
        $scope.isSaving = false;
        vm.excelTxt = "";

        vm.keyValueDtos = [];
        function updateTrackingNum () {
            Guide.updateTrackingNum({
                companyId: $rootScope.account.companyId,
                isFms: entity.isFms
            }, vm.keyValueDtos, function(result){
                if(result.notUpdatedJob){
                    $rootScope.OPS_ALERT($translate.instant('global.update_fail') + ": " + result.notUpdatedJob);  
                }
                else {
                    $rootScope.OPS_ALERT( $translate.instant('global.update_success'));  
                }                
            }, $rootScope.ALERT_ERROR);            
        }

        function clear () {
            $uibModalInstance.close();
        }
        function parsingExcelStr() {
            vm.keyValueDtos = [];
            if (!vm.excelTxt) {
                return;
            }
            var rows = vm.excelTxt.split("\n");            
            for (var i = 0; i < rows.length; i++) {
                if (!rows[i]) {
                    continue;
                }
                var cols = rows[i].split("\t");
                if(cols.length <= 1){
                    continue;
                }
                var keyValueDto = {
                    key: cols[0],
                    value: rows[i].replace(cols[0], "")
                }
                vm.keyValueDtos.push(keyValueDto);
            }

        }


    }
})();

