(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('grid-state', {
            parent: 'entity',
            url: '/grid-state?page&sort&search',
            data: {
                authorities: ['AU_EXCEL_SC'],
                pageTitle: 'autopsApp.gridState.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/grid-state/grid-states.html',
                    controller: 'GridStateController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('gridState');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('expressParcel');
                    $translatePartialLoader.addPart('booking');
                    $translatePartialLoader.addPart('wmsIn');
                    return $translate.refresh();
                }]
            }
        })
        .state('grid-state.new', {
            parent: 'grid-state',
            url: '/new',
            data: {
                authorities: ['AU_EXCEL_SC']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/grid-state/grid-state-dialog-excel.html',
                    controller: 'GridStateDialogExcelController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xl',
                    resolve: {
                        entity: function () {
                            return {
                                id: null,
                                userId: null,
                                gridId: null,
                                gridState: null,
                                stateName: null,
                                companyId: null,
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('grid-state', null, { reload: 'grid-state' });
                }, function() {
                    $state.go('grid-state', null, { reload: 'grid-state' });
                });
            }]
        })
        .state('grid-state.edit', {
            parent: 'grid-state',
            url: '/{id}/edit',
            data: {
                authorities: ['AU_EXCEL_SC']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/grid-state/grid-state-dialog-excel.html',
                    controller: 'GridStateDialogExcelController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xl',
                    resolve: {
                        entity: ['GridState', 'WinAES', function(GridState, WinAES) {
                            return GridState.getOneCustomExcelImport({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('grid-state', null, { reload: 'grid-state' });
                }, function() {
                    $state.go('grid-state', null, { reload: 'grid-state' });
                });
            }]
        })
        .state('grid-state.delete', {
            parent: 'grid-state',
            url: '/{id}/delete',
            data: {
                authorities: ['AU_EXCEL_SC']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/grid-state/grid-state-delete-dialog.html',
                    controller: 'GridStateDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['GridState', 'WinAES', function(GridState, WinAES) {
                            return GridState.getOneCustomExcelImport({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('grid-state', null, { reload: 'grid-state' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('customize-export', {
            parent: 'entity',
            url: '/customize-export?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'autopsApp.gridState.home.exportTitle'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/grid-state/customize-export.html',
                    controller: 'CustomizeExportController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('gridState');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('expressParcel');
                    $translatePartialLoader.addPart('booking');
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('warehousingData');
                    return $translate.refresh();
                }]
            }
        })
        .state('customize-export.new', {
            parent: 'customize-export',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/grid-state/customize-export-dialog.html',
                    controller: 'CustomizeExportDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                id: null,
                                userId: null,
                                gridId: null,
                                gridState: null,
                                stateName: null,
                                companyId: null,
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('customize-export', null, { reload: 'customize-export' });
                }, function() {
                    $state.go('customize-export', null, { reload: 'customize-export' });
                });
            }]
        })
        .state('customize-export.edit', {
            parent: 'customize-export',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/grid-state/customize-export-dialog.html',
                    controller: 'CustomizeExportDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: ['GridState', 'WinAES', function(GridState, WinAES) {
                            return GridState.getOneCustomExcelImport({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('customize-export', null, { reload: 'customize-export' });
                }, function() {
                    $state.go('customize-export', null, { reload: 'customize-export' });
                });
            }]
        })
        .state('customize-export.delete', {
            parent: 'customize-export',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/grid-state/customize-export-delete-dialog.html',
                    controller: 'CustomizeExportDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['GridState', 'WinAES', function(GridState, WinAES) {
                            return GridState.getOneCustomExcelImport({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('customize-export', null, { reload: 'customize-export' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('syn-order', {
            parent: 'entity',
            url: '/syn-order?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'autopsApp.gridState.home.synOrderTitle'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/grid-state/syn-order.html',
                    controller: 'SynOrderController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('gridState');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('expressParcel');
                    $translatePartialLoader.addPart('booking');
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('warehousingData');
                    return $translate.refresh();
                }]
            }
        })
        .state('syn-order.new', {
            parent: 'syn-order',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/grid-state/syn-order-dialog.html',
                    controller: 'SynOrderDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                id: null,
                                userId: null,
                                gridId: "41",
                                gridState: null,
                                stateName: null,
                                companyId: null,
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('syn-order', null, { reload: 'syn-order' });
                }, function() {
                    $state.go('syn-order', null, { reload: 'syn-order' });
                });
            }]
        })
        .state('syn-order.edit', {
            parent: 'syn-order',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/grid-state/syn-order-dialog.html',
                    controller: 'SynOrderDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: ['GridState', 'WinAES', function(GridState, WinAES) {
                            return GridState.getOneSynBranch({configurationId : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('syn-order', null, { reload: 'syn-order' });
                }, function() {
                    $state.go('syn-order', null, { reload: 'syn-order' });
                });
            }]
        })
        .state('syn-order.delete', {
            parent: 'syn-order',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/grid-state/syn-order-delete-dialog.html',
                    controller: 'SynOrderDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['GridState', 'WinAES', function(GridState, WinAES) {
                            return GridState.getOneSynBranch({configurationId : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('syn-order', null, { reload: 'syn-order' });
                }, function() {
                    $state.go('^');
                });
            }]
        }).state('customize-email', {
            parent: 'entity',
            url: '/customize-email?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'autopsApp.gridState.home.emailTemplateTitle'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/grid-state/customize-email.html',
                    controller: 'CustomizeEmailController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('gridState');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('customize-email.new', {
            parent: 'customize-email',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/grid-state/customize-email-dialog.html',
                    controller: 'CustomizeEmailDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                id: null,
                                userId: null,
                                gridId: null,
                                gridState: null,
                                stateName: null,
                                companyId: null,
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('customize-email', null, { reload: 'customize-email' });
                }, function() {
                    $state.go('customize-email', null, { reload: 'customize-email' });
                });
            }]
        })
        .state('customize-email.edit', {
            parent: 'customize-email',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/grid-state/customize-email-dialog.html',
                    controller: 'CustomizeEmailDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'lg',
                    resolve: {
                        entity: ['GridState', 'WinAES', function(GridState, WinAES) {
                            return GridState.getOneCustomExcelImport({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('customize-email', null, { reload: 'customize-email' });
                }, function() {
                    $state.go('customize-email', null, { reload: 'customize-email' });
                });
            }]
        })
        .state('customize-email.delete', {
            parent: 'customize-email',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/grid-state/customize-email-delete-dialog.html',
                    controller: 'CustomizeEmailDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['GridState', 'WinAES', function(GridState, WinAES) {
                            return GridState.getOneCustomExcelImport({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('customize-email', null, { reload: 'customize-email' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
    }

})();
