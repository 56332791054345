(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('balanceSheet', {
                parent: 'finSettlement',
                url: '/balanceSheet',
                data: {
                    authorities: ['REPORT_BALANCE_STATEMENT'],
                    pageTitle: 'global.menu.entities.balance_sheet'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountStatements/balance-sheet.html',
                        controller: 'BalanceSheetController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('cashStatement', {
                parent: 'finSettlement',
                url: '/cashStatement',
                data: {
                    authorities: ['REPORT_CASHFLOW_STATEMENT'],
                    pageTitle: 'global.menu.entities.cash_flow_statement'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountStatements/cash-statement.html',
                        controller: 'CashStatementController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        return $translate.refresh();
                    }]
                }
            })
            .state('agingAnalysis', {
                parent: 'stats',
                url: '/agingAnalysis',
                data: {
                    authorities: ['REPORT_AGING', 'ROLE_SALES'],
                    pageTitle: 'global.menu.entities.agingAnalysis'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountStatements/aging-anlysis.html',
                        controller: 'AgingAnalysisController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        $translatePartialLoader.addPart('stats');
                        $translatePartialLoader.addPart('asyncTask');
                        return $translate.refresh();
                    }]
                }
            })
            .state('aging-analysis', {
                parent: 'stats',
                url: '/aging-analysis',
                data: {
                    authorities: ['REPORT_AGING', 'ROLE_SALES'],
                    pageTitle: 'global.menu.entities.agingAnalysis'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountStatements/aging-anlysis-new.html',
                        controller: 'AgingAnalysisNewController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        $translatePartialLoader.addPart('stats');
                        $translatePartialLoader.addPart('asyncTask');
                        return $translate.refresh();
                    }]
                }
            })
            .state('agingAnalysisJob', {
                parent: 'stats',
                url: '/agingAnalysisJob',
                data: {
                    authorities: ['REPORT_AGING', 'ROLE_SALES'],
                    pageTitle: 'global.menu.entities.agingAnalysisJob'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountStatements/aging-anlysis-job.html',
                        controller: 'AgingAnalysisJobController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('stats');
                        $translatePartialLoader.addPart('accountTitle');
                        $translatePartialLoader.addPart('asyncTask');
                        return $translate.refresh();
                    }]
                }
            })
            .state('income-statement', {
                parent: 'finSettlement',
                url: '/income-statement',
                data: {
                    authorities: ['REPORT_PROFIT_STATEMENT'],
                    pageTitle: 'global.menu.entities.profit_statement'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountStatements/income-statement.html',
                        controller: 'IncomeStatementController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        return $translate.refresh();
                    }]
                }
            })
            .state('profit-statement', {
                parent: 'finSettlement',
                url: '/profit-statement',
                data: {
                    authorities: ['REPORT_PROFIT_STATEMENT'],
                    pageTitle: 'global.menu.entities.profitStatement'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountStatements/profit-statement.html',
                        controller: 'ProfitStatementController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        $translatePartialLoader.addPart('stats');                        
                        return $translate.refresh();
                    }]
                }
            })
            .state('ownershipStatement', {
                parent: 'stats',
                url: '/ownershipStatement',
                data: {
                    authorities: ['ROLE_ACCOUNT'],
                    pageTitle: 'global.menu.entities.ownership_statement'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountStatements/ownership-statement.html',
                        controller: 'OwnershipStatementController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        return $translate.refresh();
                    }]
                }
            })            
            .state('cashflowMatch', {
                parent: 'finSettlement',
                url: '/cash-flow-allot',
                data: {
                    authorities: ['REPORT_CASHFLOW_STATEMENT'],
                    pageTitle: 'global.menu.entities.cash_flow_distribution'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountStatements/cash-flow-match.html',
                        controller: 'CashFlowMatchController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        return $translate.refresh();
                    }]
                }
            })
            .state('project-profit-statement', {
                parent: 'finSettlement',
                url: '/project-profit-statement',
                data: {
                    authorities: ['REPORT_PROFIT_STATEMENT'],
                    pageTitle: 'global.menu.entities.projectProfitStatement'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountStatements/project-profit-statement.html',
                        controller: 'ProjectProfitStatementController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        $translatePartialLoader.addPart('accountVoucher');
                        return $translate.refresh();
                    }]
                }
            })
            .state('expense-details', {
                parent: 'finSettlement',
                url: '/expense-details',
                data: {
                    authorities: ['REPORT_PROFIT_STATEMENT'],
                    pageTitle: 'global.menu.entities.expenseDetails'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/accountant/accountStatements/expense-details.html',
                        controller: 'ExpenseDetailsController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountTitle');
                        return $translate.refresh();
                    }]
                }
            });
        }

})();