(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('CommodityDatabase', CommodityDatabase);

    CommodityDatabase.$inject = ['$resource', 'DateUtils'];

    function CommodityDatabase ($resource, DateUtils) {
        var resourceUrl =  'api/commodity-databases/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.createTime = DateUtils.convertDateTimeFromServer(data.createTime);
                        data.updateTime = DateUtils.convertDateTimeFromServer(data.updateTime);
                    }
                    return data;
                }
            },
            'getFilterPage': {
                method: 'POST',
                url:'api/commodity-databases/getFilterPage',
                isArray: true
            },        
            'searchCommodityDatabase':{
                method:'GET',
                url:'api/commodity-databases/searchCommodityDatabase',
                isArray:true
            },
            'searchForPackingList':{
                method:'GET',
                url:'api/commodity-databases/searchForPackingList',
                isArray:true
            },
            'hscodeFinder':{
                method:'GET',
                url:'api/commodity-databases/hscodeFinder',
                isArray:true
            },
            'findOneByHscode':{
                method:'GET',
                url:'api/commodity-databases/findOneByHscode'
            },
            'findOneByVenderIdAndIdCode':{
                method:'GET',
                url:'api/commodity-databases/findOneByVenderIdAndIdCode'
            },
            'update': { method:'PUT' },
            'advancedSearch': {
                method: 'POST',
                url: 'api/commodity-databases/advancedSearch',
                isArray: true
            },
            'batchDelete': {
                method: 'POST',
                url: 'api/commodity-databases/batchDelete'
            },
            'batchAudit': {
                method: 'POST',
                url: 'api/commodity-databases/batchAudit'
            },
            'exportExcel': {
                method: 'POST',
                url:'api/commodity-databases/exportExcel',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            }
        });
    }
})();
