(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsOutRegisterController', WmsOutRegisterController);

    WmsOutRegisterController.$inject = ['$scope', '$state', '$timeout', '$rootScope', '$http', '$translate', '$uibModal', '$uibModalInstance', 
    'entity', 'WmsIn', 'Principal', 'WmsWarehouse', 'WmsCargo', 'OpsFiles', 'DataUtils', 'OpsItem', 'WmsWorkLog'];

    function WmsOutRegisterController($scope, $state, $timeout, $rootScope, $http, $translate, $uibModal, $uibModalInstance,
        entity, WmsIn, Principal, WmsWarehouse, WmsCargo, OpsFiles, DataUtils, OpsItem, WmsWorkLog) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };
        var vm = this;
        vm.wmsIn = entity;
        vm.save = save;
        vm.splitCargo = splitCargo;
        vm.getLocations = getLocations;
        vm.locations = [];
        vm._onLocationSelected = _onLocationSelected;
        var wmsInCopy = {};

        vm.getBarcode = getBarcode;
        vm.scanCount = scanCount;
        vm.pkgsCountChange = pkgsCountChange;
        vm.getPkgs = getPkgs;
        vm.scanedCode = "";
        vm.removeCargo = removeCargo;

        vm.allOutSelect = allOutSelect;
        vm.onGateInTimeSelected = onGateInTimeSelected;
        vm.markOutCargoStatus = markOutCargoStatus;

        vm.getAllWmsService = getAllWmsService;
        vm.opsItems = [];
        vm.wmsWorkLogs = [];   
        vm.files = [];  

        vm.page = 1;
        vm.itemsPerPage = 20;
        vm.totalItems = 0;
        $scope.filter = null;
        $scope.pageFiler = function(cargo, idx){
            if($scope.filter){
                return true;
            }
            return idx >= (vm.itemsPerPage * (vm.page - 1)) && idx < (vm.page * vm.itemsPerPage) ;
        }

        $scope.clear = function () {
            $uibModalInstance.dismiss();           
        }

        function getAllWmsService(cargo){
            if(cargo && !cargo.wmsService){
                cargo.wmsService = "";
            }
            if(vm.opsItems && vm.opsItems.length > 0){
                return;
            }
            OpsItem.getAllWmsService({
                whId: vm.wmsIn.warehouseId,
                companyId: $rootScope.account.companyId
            }, function(result) {
                vm.opsItems = result;
                if(!vm.opsItems || vm.opsItems.length == 0){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsIn.wmsServiceTips'));
                }
            }, function(error) {
                layer.msg($translate.instant('global.noRecord'));
            });
        }
        function onGateInTimeSelected (cargo) {
            if(!cargo.tempTime){return;}
            angular.forEach(vm.wmsIn.cargoList, function (data) {
                if (!data.tempTime && !data.outTime){
                    data.tempTime = cargo.tempTime;
                }
            })
        }

        function removeCargo (cargo) {
            if(vm.wmsIn.id){
                var index = vm.wmsIn.cargoList.indexOf(cargo);
                if (index > -1) {
                    vm.wmsIn.cargoList.splice(index, 1);
                };
                allOutSelect();     
            }
            else {
                WmsCargo.delFromOut(cargo, function(result) {
                    var index = vm.wmsIn.cargoList.indexOf(cargo);
                    if (index > -1) {
                        vm.wmsIn.cargoList.splice(index, 1);
                    };   
                    allOutSelect();                  
                }, function(error){
                    if(error.status == 409){
                        layer.msg($translate.instant('global.refreshAlert'));                        
                    }
                    else{
                        layer.msg($translate.instant('global.delete_fail'));       
                    }
                });   
            }                   
        }       


        function allOutSelect () {
            angular.forEach(vm.wmsIn.cargoList, function (data) {
                if (!data.outTime && data.status == 'OK'){
                    data.pkgsCount = data.pkgs;
                    pkgsCountChange(data);
                }
            })
        }

        function getBarcode (cargo, type) {
            if (vm.isSaving || cargo.outTime
                || (type == "palletizedAt" && (!cargo.isPallet || cargo.palletAmt != 1))){
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-dialog.html',
                controller: 'WmsInDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md'
            }).result.then(function(code) {
                if (type == "code"){ 
                    angular.forEach(vm.locations, function(data){
                        if(data.code == code){
                            cargo.locationCopy = data.code;
                            cargo.locationIdCopy = data.locationId;
                            cargo.locationTypeCopy = data.locationType;                            
                            cargo.areaIdCopy = data.areaId;
                            cargo.warehouseIdCopy = data.warehouseId;
                            cargo.keeperCopy = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                            cargo.keeperIdCopy = $rootScope.account.id;
                        }
                    });
                }
                else {
                    cargo.palletizedAt = code;
                }

            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }
        $timeout(function() {
            if(!vm.wmsIn.id){
                allOutSelect();
                return;
            }
            angular.forEach(vm.wmsIn.cargoList, function (data) {
                if(!data.location){
                    data.location = "";
                }
                if (!data.outTime){
                    data.pkgsCount = 0;
                }
            })
            vm.totalItems = vm.wmsIn.cargoList.length;
            WmsWarehouse.getLocations({warehouseId: vm.wmsIn.warehouseId}, function (data) {
                vm.locations = data;
                for (var i = vm.locations.length - 1; i >= 0; i --){
                    if (vm.locations[i].isVirtual == "isNot"){
                        vm.locations.splice(i, 1);
                    }
                }
            });
            angular.copy(vm.wmsIn, wmsInCopy);

            if(vm.wmsIn.id){
                WmsWorkLog.getOpRequirment({
                    type: "wms",
                    id: vm.wmsIn.id
                }, function(result){
                    vm.wmsWorkLogs = result;
                });
            }
        })
        var rateUnsubscribe = $rootScope.$on('autopsApp:wmsWorkLogUpdate', function(event, result) {
            if(result && result.id){
                var isNew = true;
                angular.forEach(vm.wmsWorkLogs, function(data){
                    if(data.id == result.id){
                        data.count = result.count;
                        data.work = result.work;
                        isNew = false;
                    }
                })
                if(isNew){
                    vm.wmsWorkLogs.push(result)
                }
            }
        });
        $scope.$on('$destroy', rateUnsubscribe);   

        function save () {
            vm.isSaving = true;
            if (angular.equals(vm.wmsIn, wmsInCopy)){
                layer.msg($translate.instant('global.no_amendmend'));
                vm.isSaving = false;
                return;
            }                 

            angular.forEach(vm.wmsIn.cargoList, function (data) {
                if (!data.outTime && data.pkgsCount){
                    data.pkgs = data.pkgsCount;
                    data.location = data.locationCopy;
                    data.locationId = data.locationIdCopy;
                    data.locationType = data.locationTypeCopy;
                    data.areaId = data.areaIdCopy;
                    data.warehouseId = data.warehouseIdCopy;
                    data.keeper = data.keeperCopy;
                    data.keeperId = data.keeperIdCopy;
                    data.allOut = true;
                }         
            })
            if (vm.wmsIn.actualNum){
                if(vm.wmsIn.actualNum >= vm.wmsIn.applicationNum){
                    vm.wmsIn.status = "allIn";
                    vm.wmsIn.allOut = true;

                    angular.forEach(vm.wmsIn.cargoList, function (data) {
                        if (!data.outTime && data.allOut && data.tempTime){
                            vm.wmsIn.outTime = data.tempTime;
                        }         
                    })
                    if(!vm.wmsIn.outTime){
                        vm.wmsIn.outTime = new Date();
                    }   
                }
                else {
                    vm.wmsIn.status = "partIn";
                }
            }
            else {
                vm.wmsIn.status = "Confirmed";
            }
            vm.wmsIn.registerId = $rootScope.account.id;
            vm.wmsIn.registerBy = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            if(vm.wmsIn.id){
                WmsIn.saveRegistOut(vm.wmsIn, onSaveSuccess, onSaveError);
            }
            else {
                WmsIn.saveTempRegistOut(vm.wmsIn, onSaveSuccess, onSaveError);
            }             
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submitted'));            
            $scope.$emit('autopsApp:wmsInUpdate', result);
            $uibModalInstance.close(result);  
        }

        function onSaveError (result) {
            vm.isSaving = false;
            if (result.status == 409) {
                layer.msg($translate.instant('global.refreshAlert'));
            } 
        }

        //拆分货物
        function splitCargo (cargo) {
            var pkgs = cargo.pkgs;
            layer.prompt({
              formType: 0,
              value: pkgs,
              title: $translate.instant('autopsApp.wmsIn.splitCargoTitle'),
              area: ['280px', '160px'] 
            }, function(value, index, elem){
                value = value -0;
                if (!value || value == cargo.pkgs){
                    layer.close(index);
                    return;
                }                          
                if (isNaN(value) || parseInt(value) != value || value > cargo.pkgs){
                    layer.msg($translate.instant('autopsApp.wmsIn.notNumAlert'));
                    return;
                }
                cargo.pkgs = value-0;
                cargo.palletAmt = DataUtils.getPalletAmt(cargo.pkgs, cargo.palletCapacity);
                WmsCargo.splitCargoCom(cargo, function(result) {
                    if(vm.wmsIn.cargoList){
                        result.pkgsCount = 0;
                        vm.wmsIn.cargoList.push(result);
                    }
                    $translate.refresh();  
                });          
                layer.close(index);               
            });
        }

        var locationCopy = null;
        function _onLocationSelected (item, cargo) {
            cargo.locationCopy = item.code;
            cargo.locationIdCopy = item.locationId;
            cargo.locationTypeCopy = item.locationType;
            cargo.areaIdCopy = item.areaId;
            cargo.warehouseIdCopy = item.warehouseId;
            cargo.keeperCopy = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            cargo.keeperIdCopy = $rootScope.account.id;

            locationCopy = {};
            locationCopy.id = item.locationId;
            locationCopy.locationType = item.locationType;
            locationCopy.code = item.code;
            locationCopy.areaId = item.areaId;
            locationCopy.warehouseId = item.warehouseId;
        }

        function getLocations (cargo) {
            if (locationCopy && !cargo.location){
                cargo.location = locationCopy.code;
                cargo.locationType = locationCopy.locationType;
                cargo.locationId = locationCopy.id;
                cargo.areaId = locationCopy.areaId;
                cargo.warehouseId = locationCopy.warehouseId;
            }
        }
        
        var isFistCount = true;
        function scanCount () {
            if(vm.isSaving){
                return;           
            }
            else {
                vm.scanedCode = null;
                vm.scanedLocation = $translate.instant('autopsApp.wmsIn.scanAndCount');
                vm.isSaving = true;
                if(isFistCount){
                    angular.forEach(vm.wmsIn.cargoList, function(data){
                        if(!data.outTime){
                            data.pkgsCount = 0;
                        }                    
                    });
                }
                $timeout(function (){
                    $("#field_manualBarCode").focus();
                });
                isFistCount = false;
            }
        };
        function pkgsCountChange (cargo) {
            if (cargo.pkgsCount < 0 || cargo.pkgsCount > cargo.pkgs){
                cargo.pkgsCount = cargo.pkgs;
            }
            var actualNum = 0;       
            for (var i = 0; i < vm.wmsIn.cargoList.length; i ++){
                if (vm.wmsIn.cargoList[i].outTime){
                    actualNum = actualNum + vm.wmsIn.cargoList[i].pkgs;
                }
                else if (vm.wmsIn.cargoList[i].pkgsCount){
                    actualNum = actualNum + vm.wmsIn.cargoList[i].pkgsCount;
                }
            }
            vm.wmsIn.actualNum = actualNum.toFixed(0) -0;
        }

        function countNums () {
            var isCorrect = false;
            for(var i = 0; i < vm.wmsIn.cargoList.length; i++){
                if ((vm.scanedCode == vm.wmsIn.cargoList[i].code || vm.scanedCode == vm.wmsIn.cargoList[i].sku) && !vm.wmsIn.cargoList[i].outTime){
                    vm.scanedLocation = vm.wmsIn.cargoList[i].location;//匹配的库位
                    if(vm.wmsIn.cargoList[i].pkgsCount < vm.wmsIn.cargoList[i].pkgs){
                        vm.wmsIn.cargoList[i].pkgsCount++;
                        pkgsCountChange(vm.wmsIn.cargoList[i]);
                        isCorrect = true;
                        break;
                    } 
                }
            }
            if (!isCorrect){    
                layer.msg($translate.instant('autopsApp.wmsIn.noMatchCode'));  
                vm.scanedLocation = $translate.instant('autopsApp.wmsIn.noMatchCode');                        
                $("#field_manualBarCode").focus();
            }
            vm.scanedCode = null;
            $("#field_manualBarCode").focus();
            $translate.refresh();
        }

        $(document).keydown(function(event){ 
            if (vm.isSaving){
                // $("#field_manualBarCode").focus();
                if(event.keyCode == 13){
                    countNums();
                }
            }
        });

        $("#field_manualBarCode").blur(function(){ 
            // alert("s");
            vm.isSaving = false;
            vm.scanedLocation = null;
            vm.scanedCode = null;   
            $translate.refresh();
        }); 


        $timeout(function (){
            $("#field_manualBarCode").focus();
        });

        function getPkgs (cargo) {
            if (!cargo.pkgsCount){
                cargo.pkgsCount = cargo.pkgs;
            }
            pkgsCountChange(cargo);
        }
        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function (date) {
            $scope.datePickerOpenStatus[date] = true;
        }

        $scope.textPop = function(cargo, fd) {
            $uibModal.open({
                templateUrl: 'app/entities/ops-change-log/ops-change-log-delete-dialog.html',
                controller: 'OpsChangeLogDeleteController',
                controllerAs: 'vm',
                // backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return cargo[fd];
                    }]
                }
            }).result.then(function(result) {
                cargo[fd] = result;
            }, function() {});
        }

        function markOutCargoStatus (cargo) {
            if(vm.wmsIn.companyId != 173 && vm.wmsIn.companyId != 54){
                return;
            }
            var newStatus = cargo.status == "OK"?"NO":"OK";
            layer.confirm($translate.instant('autopsApp.wmsIn.markOutCargoStatusAlert', {param: newStatus}), {
                icon: 3,
                title: "Msg",
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
            }, function(index) {
                layer.close(index);
                WmsCargo.markOutCargoStatus({
                    outCargoId: cargo.id,
                    status: newStatus
                }, function(result) {
                    cargo.status = result.status;    
                    cargo.lastCargoId = result.lastCargoId;
                    layer.msg($translate.instant('global.messages.submitted'));            
                }, $rootScope.ALERT_ERROR);   
            }, function() {
            });                
        }    

    }

})();
