(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('InvoiceComListController', InvoiceComListController);

    InvoiceComListController.$inject = ['$rootScope', '$timeout', '$scope', '$translate', '$uibModal', '$uibModalInstance', 'entity', 'AccountInvoice', 'AccountPayment'];

    function InvoiceComListController ($rootScope, $timeout, $scope, $translate, $uibModal, $uibModalInstance, entity, AccountInvoice, AccountPayment) {
        var vm = this;

        vm.accountInvoice = entity;
        var isDn = entity.isDn;
        vm.clear = clear;
        vm.remove = remove;
        vm.accountInvoices = [];
        vm.accountPayments = [];

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function remove (accountInvoice) {
            if(accountInvoice.isIssued){
                layer.msg($translate.instant('autopsApp.accountInvoice.invoiceIssued'));
                return;
            }
            accountInvoice.comId = null;
            AccountInvoice.update(accountInvoice, function(result) {
                var index = vm.accountInvoices.indexOf(accountInvoice);
                if (index > -1) {
                    vm.accountInvoices.splice(index, 1);
                };
                layer.msg($translate.instant('global.delete_success'));
            }, function () {
                layer.msg($translate.instant('global.delete_fail'));
            });
        }


        $timeout(function() {
            AccountInvoice.getComList({
                isDn: isDn,
                id:vm.accountInvoice.id
            }, function(result){
                vm.accountInvoices = result.accountInvoices;
                vm.accountPayments = result.accountPayments;
            })
        });
        var onDismissSuccess = function(result) {
            $scope.isSaving = false;
            $uibModalInstance.close();
            layer.msg($translate.instant('global.messages.submitted'));
        }

        $scope.dismissCom = function() {
            if ($rootScope.HAVENOAUTH('AU_DCN_DISMISS')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_DCN_DISMISS');
                return;
            }
            var title = $translate.instant('autopsApp.accountInvoice.home.hedgingTitle');
            var content = vm.accountInvoice.dnNum + $translate.instant('autopsApp.accountInvoice.home.hedgingContent');
            layer.confirm(content, {
                title: title,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
            }, function(index) {
                layer.close(index);
                $scope.isSaving = true;
                AccountInvoice.dismissCom({
                    isDn: isDn,
                    id: vm.accountInvoice.id
                }, onDismissSuccess, $rootScope.ALERT_ERROR);

            });
        }

    }
})();
