(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('JobListDialogController', JobListDialogController);

    JobListDialogController.$inject = ['$scope', '$rootScope', '$translate', '$uibModalInstance', '$uibModal', 'entity', 'OpsFiles', 'DateUtils'];
    
    function JobListDialogController($scope, $rootScope, $translate, $uibModalInstance, $uibModal, entity, OpsFiles, DateUtils) {

        $scope.userResultDto = entity.content;
        $scope.statParams = entity.statParams;

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        var isReverse = false;
        $scope.sortPopByTitle = function(title) {
            isReverse = !isReverse;
            $scope.userResultDto.guides.sort(function(a,b){
                if (isReverse){
                    return a[title]<b[title]?1:-1;
                }
                else {
                    return a[title]>b[title]?1:-1;
                }                   
            });
            // setTimeout(function() {frzTable("divTable", "showTable", null);}, 100);  
        }

        $scope.excelExport = function() {
            if(!$scope.userResultDto || !$scope.userResultDto.guides || $scope.userResultDto.guides.length == 0){
                layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                return;
            }
            
            var excelDTO = angular.copy($scope.userResultDto);
            var title = $translate.instant('global.menu.admin.profitStats');
            var fileName = title + ".xlsx";
            if($scope.statParams){
                title = title + "(From " + DateUtils.convertLocalDateToServer($scope.statParams.dateFrom) + " To " + DateUtils.convertLocalDateToServer($scope.statParams.dateTo) + ")";
            }            
            OpsFiles.excelProfitStatByUserDTO({
                title: title,
                enCn: $translate.instant('global.enCn')
            }, excelDTO, function(result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }    

    }
})();
