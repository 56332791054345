
(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('SubTrackingDetailController', SubTrackingDetailController);

    SubTrackingDetailController.$inject = ['$timeout', '$scope', '$rootScope', '$uibModalInstance', '$translate', 
    'entity', 'SubTracking'];

    function SubTrackingDetailController ($timeout, $scope, $rootScope, $uibModalInstance, $translate, 
        entity, SubTracking) {
        var vm = this;

        vm.clear = clear;
        vm.save = save;
        vm.splitStr = splitStr;

        vm.trackingStr = "";
        vm.trackingList = [];
        vm.consoleList = [];
        vm.choosedShipment;
        vm.chooseOne = chooseOne;
        vm.trackingShipment = trackingShipment;

        vm.masterJobNum;
        $timeout(function (){          
            if(entity.isMaster){
                vm.masterJobNum = entity.jobNum;
                layer.load(1, {shade: 0.3});
                SubTracking.getConsolList(entity, function(results){               
                    vm.consoleList = results;   
                    vm.choosedShipment = results[0];
                    getSubtrackingList();
                    layer.closeAll();
                }, function(result){
                    layer.closeAll();
                });
            }
            else{
                vm.choosedShipment = entity;
                getSubtrackingList();
            }
        });
        function chooseOne (choosedShipment) {
            vm.choosedShipment = choosedShipment;
            getSubtrackingList();
        }
        function getSubtrackingList () {
            vm.trackingList = [];
            vm.trackingStr = "";
            SubTracking.getList({
                guideId: vm.choosedShipment.guideId,
                parcelId: vm.choosedShipment.parcelId
            }, function(results){
                angular.forEach(results, function(data){
                    if(data && data.tracking && vm.trackingList.indexOf(data.tracking) == -1){
                        vm.trackingStr += data.tracking + " ";
                        vm.trackingList.push(data.tracking);
                    }                    
                })
            });
        }

        function splitStr () {
            vm.trackingList = [];
            var wordList = vm.trackingStr.split(/\s+/g);
            angular.forEach(wordList, function(data){
                if(data && vm.trackingList.indexOf(data) == -1){
                    vm.trackingList.push(data);
                }
            })
            vm.trackingStr = "";
            angular.forEach(vm.trackingList, function(data){
                if(data){
                    vm.trackingStr += data + " ";
                }                    
            })
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            var keyValue = {};
            keyValue.value = vm.trackingStr;
            SubTracking.saveList({
                guideId: vm.choosedShipment.guideId,
                parcelId: vm.choosedShipment.parcelId
            }, keyValue, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (results) {
            SubTracking.getConsolList(entity, function(results){               
                vm.consoleList = results;   
            }, function(result){
            });
            layer.msg($translate.instant('global.messages.submitted'));
            vm.isSaving = false;
        }

        function onSaveError () {
            layer.msg($translate.instant('global.messages.submittedfailed'));
            vm.isSaving = false;
        }

        function trackingShipment (consoleShipment) {
            var guide = {
                carrier: consoleShipment.carrier,
                mblNum: consoleShipment.mblNum,
                shipmentType: consoleShipment.shipmentType,
                isMaster: consoleShipment.isMaster
            };
            var isExpress = false;
            if(consoleShipment.guideId){
                guide.id = consoleShipment.guideId;
            }
            else {
                guide.id = consoleShipment.parcelId;
                isExpress = true;
            }
            $rootScope.viewTracking(guide, null, isExpress); 
        }
    }
})();
