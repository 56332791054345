(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('customs-audit', {
                parent: 'entity',
                url: '/customs-audit',
                data: {
                    authorities: ['AUDI_COMM_DATABASE'],
                    pageTitle: 'global.menu.entities.customsAudit'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/bookingCtns/bookingCtnss.html',
                        controller: 'BookingCtnsController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('booking');
                        $translatePartialLoader.addPart('commodityDatabase');
                        $translatePartialLoader.addPart('guide');                        
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('bookingCtns.detail', {
                parent: 'entity',
                url: '/bookingCtns/{id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'autopsApp.bookingCtns.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/bookingCtns/bookingCtns-detail.html',
                        controller: 'BookingCtnsDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('bookingCtns');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'BookingCtns', function($stateParams, BookingCtns) {
                        return BookingCtns.get({id : $stateParams.id});
                    }]
                }
            });
    }
})();
