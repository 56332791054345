(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountCashierDialogController', AccountCashierDialogController);

    AccountCashierDialogController.$inject = ['$timeout', '$scope', '$uibModal', '$rootScope', '$translate', '$stateParams', '$uibModalInstance', 
    'JhiLanguageService', 'entity', 'AccountCashier', 'OpsVender', 'AccountInvoice', 'AccountTitle', 'WinAES', 'DataUtils'];

    function AccountCashierDialogController ($timeout, $scope, $uibModal, $rootScope, $translate, $stateParams, $uibModalInstance, 
        JhiLanguageService, entity, AccountCashier, OpsVender, AccountInvoice, AccountTitle, WinAES, DataUtils) {
        
        var lang = "";
        JhiLanguageService.getCurrent().then(function(language) {
            lang = language;
        });
     
        var vm = this;
        vm.accountCashier = entity.accountCashier;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.onPayerSelected = onPayerSelected;
        vm.viewChargesList = viewChargesList;
        vm.save = save;    
        vm.unLock = unLock;
        vm.getSubLedgers = getSubLedgers; 
        vm._onLedgerChoosed = _onLedgerChoosed;
        vm.deleteOne = deleteOne;

        vm.transactionRefLocked = true;
        $scope.files = [];

        $timeout(function (){
            // angular.element('.form-group:eq(1)>input').focus();
            if (vm.accountCashier.transactionRef){
                vm.transactionRefLocked = true;
            }
            else {
                vm.transactionRefLocked = false;
            }
            if(vm.accountCashier.id){
                vm.accountCashier.encodeId = WinAES.encode(vm.accountCashier.id.toString());
            }
            
            // if (vm.accountCashier.dnNums && vm.accountCashier.dnNums.length > 0){
            //     vm.dnNums = vm.accountCashier.dnNums.split(' ');
            // }
        });

        function unLock () {
            vm.transactionRefLocked = !vm.transactionRefLocked;
        }


        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            if (vm.accountCashier.isLocked || vm.accountCashier.voucherId) {
                layer.msg($translate.instant('autopsApp.accountCashier.voucherIdNotNull'));
                return;
            }      

            vm.isSaving = true;
            // vm.accountCashier.dnNums = vm.dnNums.join(' ');
            if (vm.accountCashier.id !== null) {
                AccountCashier.update(vm.accountCashier, onSaveSuccess, onSaveError);
            } else {
                AccountCashier.save(vm.accountCashier, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $scope.$emit('autopsApp:accountCashierUpdate', result);
            $uibModalInstance.close($rootScope.OPSVENDERS);            
        }

        function onSaveError (result) {
            vm.isSaving = false;
            $rootScope.ALERT_ERROR(result);
        }

        vm.datePickerOpenStatus.transactionDate = false;
        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function onPayerSelected (data) {
            vm.accountCashier.payerVid = data.id;
        }


        function viewChargesList (invoice) {
            $uibModal.open({
                templateUrl: 'app/entities/write-off-rates/write-off-rates-dialog.html',
                controller: 'WriteOffRatesDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: ['GuideRates', function(GuideRates) {
                        return GuideRates.getWrieteOffRatesByDnNum({
                            companyId: $rootScope.account.companyId,
                            dnNum: invoice.dnNum
                        }).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountInvoice');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {

            }, function(result) {
                
            });
        }

        
        function getSubLedgers () {
            if ($rootScope.SUBLEDGERS && $rootScope.SUBLEDGERS.length > 0) {
                return;
            }            
            AccountTitle.getAll({
                type: "part"
            }, function(result) {
                $rootScope.SUBLEDGERS = DataUtils.generateShowLedger(result, true, lang);
            });
        }

        function _onLedgerChoosed (data) {
            if((data.code.substring(0,4) == "2205" || data.code.substring(0,4) == "1123") &&
                ((data.currency == null && vm.accountCasher.originalCurrency != $rootScope.account.baseCurrency) || (data.currency != null && data.currency != vm.accountCasher.originalCurrency))){
                layer.msg($translate.instant('autopsApp.accountCashier.advanceAccountCurrencyAlert'));
                vm.accountCasher.oppositeName = null;
                return;
            }
            if((data.code.substring(0,4) == "2205" && (vm.accountCasher.originalAmt < 0 || vm.accountCasher.diffAmt < 0))
                || (data.code.substring(0,4) == "1123" && (vm.accountCasher.originalAmt > 0 || vm.accountCasher.diffAmt < 0))){
                layer.msg($translate.instant('autopsApp.accountCashier.advanceAccountAmtAlert'));
                vm.accountCasher.oppositeName = null;
                return;
            }
            vm.accountCashier.oppositeName = data.showLedger;
            vm.accountCashier.oppositeId = data.id;
            vm.accountCashier.oppositeCode = data.code;
        }

        function deleteOne () {
            $uibModal.open({
                templateUrl: 'app/entities/account-cashier/account-cashier-delete-dialog.html',
                controller: 'AccountCashierDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: ['AccountCashier', function(AccountCashier) {
                        return AccountCashier.get({
                            id:vm.accountCashier.id
                        }).$promise;
                    }]
                }
            }).result.then(function(result) {
                $uibModalInstance.close($rootScope.OPSVENDERS);   
            }, function(result) {
                
            })
        };
        
    }
})();
