(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('OpsExchangeRate', OpsExchangeRate);

    OpsExchangeRate.$inject = ['$resource', 'DateUtils'];

    function OpsExchangeRate ($resource, DateUtils) {
        var resourceUrl =  'api/ops-exchange-rates/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.effectiveDate = DateUtils.convertLocalDateFromServer(data.effectiveDate);
                    }
                    return data;
                }
            },
            'getAllCurrencys': {
                method:'GET',
                url:'api/ops-exchange-rates/getAllCurrencys',
                isArray: true
            },  
            'getEffectiveExchangeRates': {
                method:'GET',
                url:'api/ops-exchange-rates/getEffectiveExchangeRates',
                isArray: true
            },  
            'groupShareOne': {
                method:'PUT',
                url:'api/ops-exchange-rates/groupShareOne'
            },  
            'refreshExchangeRates': {
                method:'PUT',
                url:'api/ops-exchange-rates/refreshExchangeRates'
            },              
            'getExchangeRatesMap': {
                method:'GET',
                url:'api/ops-exchange-rates/getExchangeRatesMap',
                isArray: false
            },  
            'getCurExchangeRate': {
                method:'GET',
                url:'api/ops-exchange-rates/getCurExchangeRate',
                isArray: false
            },              
            'getGuidingRate': {
                method:'GET',
                url:'api/ops-exchange-rates/getGuidingRate',
                isArray: false
            },  
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.effectiveDate = DateUtils.convertLocalDateToServer(data.effectiveDate);
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    data.effectiveDate = DateUtils.convertLocalDateToServer(data.effectiveDate);
                    return angular.toJson(data);
                }
            },
            'advancedSearch': {
                method:'POST',
                url:'api/ops-exchange-rates/advancedSearch',
                isArray: true
            },
            'exportOpsExchangeRate': {
                method: 'POST',
                url: 'api/excel/exportOpsExchangeRate',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            }
        });
    }
})();
