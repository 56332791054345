(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AmendEtdController', AmendEtdController);

    AmendEtdController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$translate', 
    'entity', 'Guide', 'ExpressParcel', 'WmsIn'];
    
    function AmendEtdController($scope, $rootScope, $uibModalInstance, $translate, 
        entity, Guide, ExpressParcel, WmsIn) {

        $scope.guideDto = entity;
        
        $scope.lockEDate = entity.lockEDate;
        var shipmentType = entity.shipmentType;
        $scope.amendEDate = true;
        $scope.isSaving = false;
        $scope.lockEDate = true;
        $scope.amendBillDate = entity.amendBillDate?true:false;

        $scope.datePickerForEtdAmend = {};
        $scope.datePickerForEtdAmend.status = {
            opened: false
        };
        $scope.datePickerForEtdAmendOpen = function($event) {
            $scope.datePickerForEtdAmend.status.opened = true;
        };
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
      
        var etd = angular.copy($scope.guideDto.etd);
        $scope.save = function() {
            $scope.isSaving = true;
            if(shipmentType == "expressParcel"){
                ExpressParcel.amendEtd({
                    amendEDate: $scope.amendEDate
                }, $scope.guideDto, onSaveSuccess, onSaveError);      
            }
            else if(shipmentType == "wms"){
                WmsIn.amendEtd({
                    amendEDate: $scope.amendEDate
                }, $scope.guideDto, onSaveSuccess, onSaveError);      
            }
            else {
                Guide.amendEtd({
                    amendEDate: $scope.amendEDate,
                    amendBillDate: $scope.amendBillDate
                }, $scope.guideDto, onSaveSuccess, onSaveError);      
            }
        }

        var onSaveSuccess = function(result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('global.messages.submitted'));
            $uibModalInstance.close($scope.guideDto);        
        };
        
        var onSaveError = function(result) {
            if (result.status == 403){//合并付款跨月
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.combineDnAlert'));
            }
            else if (result.status == 423){ //已结单
                $rootScope.OPS_ALERT($translate.instant('global.lockedAlert'));
            }
            else if (result.status == 409){ //月份已结账
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.etdClosedAlert'));
            }
            else if (result.status == 400){ //跨月 超过7天
                $rootScope.OPS_ALERT($translate.instant('autopsApp.guide.etdLockedAlert'));
            }
            else {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            }                        
            $scope.isSaving = false;   
        };









}
})();
