(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsCarrierDeleteController',OpsCarrierDeleteController);

    OpsCarrierDeleteController.$inject = ['$uibModalInstance', 'entity', 'OpsCarrier'];

    function OpsCarrierDeleteController($uibModalInstance, entity, OpsCarrier) {
        var vm = this;

        vm.opsCarrier = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.delName = vm.opsCarrier.type == 0?vm.opsCarrier.channel:vm.opsCarrier.name;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            layer.load(1, {shade: 0.3}); 
            OpsCarrier.delete({id: id},
                function () {
                    layer.closeAll(); 
                    $uibModalInstance.close(true);
                }, function(){
                    layer.closeAll(); 
                });
        }
    }
})();
