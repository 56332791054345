(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('PaymentInvoiceDialogController', PaymentInvoiceDialogController);

    PaymentInvoiceDialogController.$inject = ['$scope', '$rootScope', '$translate', '$uibModalInstance', '$timeout',
    'entity', 'AccountPayment', 'AccountInvoice'];

    function PaymentInvoiceDialogController($scope, $rootScope, $translate, $uibModalInstance, $timeout,
        entity, AccountPayment, AccountInvoice) {

        $scope.accountPayment = entity.accountPayment;
        $scope.accountInvoice = entity.accountInvoice;
        $scope.isUpdate = entity.isUpdate;
        if($scope.accountPayment && !$scope.accountPayment.receiveTime){
            $scope.accountPayment.receiveTime = new Date();
        }
        if($scope.accountInvoice && !$scope.accountInvoice.sendTime){
            $scope.accountInvoice.sendTime = new Date();
        }
        if($scope.accountInvoice && !$scope.accountInvoice.invoiceTime){
            $scope.accountInvoice.invoiceTime = new Date();
        }
        if($scope.accountPayment && !$scope.accountPayment.invoiceTime){
            $scope.accountPayment.invoiceTime = new Date();
        }

        $scope.clear = function () {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.datePickerOpenStatus = {};
        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }
   
        var onSaveInvoiceNumSuccess = function(result) { 
            layer.closeAll();   
            $scope.isSaving = false;        
            layer.msg($translate.instant('global.save_success'));
            $uibModalInstance.close(result);
        };

        var onSaveInvoiceNumError = function(result) {
            layer.closeAll(); 
            $scope.isSaving = false;
            if (result.status === 400) {
                layer.msg($translate.instant('global.refreshAlert'));
            }
            else if (result.status === 409) {
                layer.msg($translate.instant('autopsApp.accountPayment.invoiceNumRepeat'));
            } else {
                layer.msg($translate.instant('global.save_fail'));
            }            
        };
        
        $scope.save = function() {
            if($scope.accountPayment){
                if (!$scope.accountPayment.invoiceNum) {
                    layer.msg($translate.instant('autopsApp.accountPayment.invoiceNumNullAlert'));
                    return;
                }
                $scope.accountPayment.isIssued = true;
                $scope.accountPayment.lastModifiedBy = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                layer.msg("Saving...", {
                  shade: 0.3,
                  time: 2000000
                });
                $scope.isSaving = true;
                if($scope.isUpdate){
                    AccountPayment.update($scope.accountPayment, onSaveInvoiceNumSuccess, onSaveInvoiceNumError);
                }
                else {
                    AccountPayment.updateWithoutRates($scope.accountPayment, onSaveInvoiceNumSuccess, onSaveInvoiceNumError);
                }                
            }
            else{
                if (!$scope.accountInvoice.invoiceNum) {
                    layer.msg($translate.instant('autopsApp.accountPayment.invoiceNumNullAlert'));
                    return;
                }
                $scope.accountInvoice.isIssued = true;
                $scope.accountInvoice.lastModifiedBy = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                layer.msg("Saving...", {
                  shade: 0.3,
                  time: 2000000
                });
                $scope.isSaving = true;
                if($scope.isUpdate){
                    AccountInvoice.update($scope.accountInvoice, onSaveInvoiceNumSuccess, onSaveInvoiceNumError);
                }
                else {
                    AccountInvoice.updateWithoutRates($scope.accountInvoice, onSaveInvoiceNumSuccess, onSaveInvoiceNumError);
                }                
            }
        }

        $scope.getNextInvoiceNum = function() {
            AccountInvoice.getNextInvoiceNum({
                companyId: $scope.accountInvoice.companyId
            }, function(result){
                $scope.accountInvoice.invoiceNum = result.invoiceNum;
            })
        }


    }
})();
