(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ClerkManagementDetailController', ClerkManagementDetailController);

    ClerkManagementDetailController.$inject = ['$scope', '$uibModalInstance', '$translate', 'Account', 'entity'];
    
    function ClerkManagementDetailController($scope, $uibModalInstance, $translate, Account, entity) {
        var vm = this;
        var pswEntity = entity;
        vm.pswStrength = 0;
        vm.password = "";

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.save = function() {    
            vm.password = vm.password.replace(/\s+/g, "");
            if(vm.password.length < 6){
                layer.msg($translate.instant('global.messages.validate.newpassword.minlength'));   
                return;
            }
            else if (vm.password.length > 50){
                layer.msg($translate.instant('global.messages.validate.newpassword.maxlength'));   
                return;
            }
            pswEntity.newPassword = vm.password;
            Account.changeUserPassword(pswEntity, function(result){
                layer.msg($translate.instant('global.save_success'));   
                $uibModalInstance.close(result);
            }, function(error){
                layer.msg($translate.instant('global.save_fail'));  
            })
        };



    }
})();
