(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('EdiData', EdiData);

    EdiData.$inject = ['$resource', 'DateUtils'];

    function EdiData ($resource, DateUtils) {
        var resourceUrl =  'api/edi-data/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},            
            'getByGuideId': {
                method: 'GET', 
                url:'api/edi-data/guideId',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.brUpdatedTime = DateUtils.convertDateTimeFromServer(data.brUpdatedTime);
                        data.siUpdatedTime = DateUtils.convertDateTimeFromServer(data.siUpdatedTime);
                    }
                    return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.brUpdatedTime = DateUtils.convertDateTimeFromServer(data.brUpdatedTime);
                        data.siUpdatedTime = DateUtils.convertDateTimeFromServer(data.siUpdatedTime);
                    }
                    return data;
                }
            },
            'cargoSmartBr': { 
                method: 'POST', 
                url:'api/cargoSmartBr',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    if (data.guide.booking){
                       data.guide.etd = DateUtils.convertLocalDateToServer(data.guide.etd);
                    }
                   return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.brUpdatedTime = DateUtils.convertDateTimeFromServer(data.brUpdatedTime);
                        data.siUpdatedTime = DateUtils.convertDateTimeFromServer(data.siUpdatedTime);
                    }
                    return data;
                }
            },
            'cargoSmartSi': { 
                method: 'POST', 
                url:'api/cargoSmartSi',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    if (data.guide.booking){
                       data.guide.etd = DateUtils.convertLocalDateToServer(data.guide.etd);
                    }
                   return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.brUpdatedTime = DateUtils.convertDateTimeFromServer(data.brUpdatedTime);
                        data.siUpdatedTime = DateUtils.convertDateTimeFromServer(data.siUpdatedTime);
                    }
                    return data;
                }
            },
            'save': { 
                method:'POST',
                transformResponse: function (data) {
                    if (data){
                        data = angular.fromJson(data);
                        data.brUpdatedTime = DateUtils.convertDateTimeFromServer(data.brUpdatedTime);
                        data.siUpdatedTime = DateUtils.convertDateTimeFromServer(data.siUpdatedTime);  
                    }       
                    return data;
                } 
            },
            'update': { 
                method:'PUT',
                transformResponse: function (data) {
                    if (data){
                        data = angular.fromJson(data);
                        data.brUpdatedTime = DateUtils.convertDateTimeFromServer(data.brUpdatedTime);
                        data.siUpdatedTime = DateUtils.convertDateTimeFromServer(data.siUpdatedTime);  
                    }       
                    return data;
                } 
            }
        });
    }
})();
