(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('express-packing', {
            parent: 'entity',
            url: '/express-packing?page&sort&search',
            data: {
                authorities: ['ROLE_OPERATOR'],
                pageTitle: 'autopsApp.expressPacking.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/express-packing/express-packings.html',
                    controller: 'ExpressPackingController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('expressPacking');
                    $translatePartialLoader.addPart('global');
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('expressParcel');
                    return $translate.refresh();
                }]
            }
        })
        .state('express-packing.new', {
            parent: 'express-packing',
            url: '/new',
            data: {
                authorities: ['ROLE_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/express-packing/express-packing-dialog.html',
                    controller: 'ExpressPackingDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxl',
                    resolve: {
                        entity: function () {
                            return {};
                        }
                    }
                }).result.then(function() {
                    $state.go('express-packing', null, { reload: 'express-packing' });
                }, function() {
                    $state.go('express-packing');
                });
            }]
        })
        .state('express-packing.edit', {
            parent: 'express-packing',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/express-packing/express-packing-dialog.html',
                    controller: 'ExpressPackingDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxl',
                    resolve: {
                        entity: ['ExpressPacking', 'WinAES', function(ExpressPacking, WinAES) {
                            return ExpressPacking.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('express-packing', null, { reload: 'express-packing' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('express-packing.copy', {
            parent: 'express-packing',
            url: '/{id}/copy',
            data: {
                authorities: ['ROLE_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/express-packing/express-packing-dialog.html',
                    controller: 'ExpressPackingDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'xxl',
                    resolve: {
                        entity: ['ExpressPacking', 'WinAES', function(ExpressPacking, WinAES) {
                            return ExpressPacking.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('express-packing', null, { reload: 'express-packing' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('express-packing.delete', {
            parent: 'express-packing',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_OPERATOR']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/express-packing/express-packing-delete-dialog.html',
                    controller: 'ExpressPackingDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ExpressPacking', 'WinAES', function(ExpressPacking, WinAES) {
                            return ExpressPacking.get({id : WinAES.decode($stateParams.id)}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('express-packing', null, { reload: 'express-packing' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
