(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountVoucherDialogController', AccountVoucherDialogController);

    AccountVoucherDialogController.$inject = ['$rootScope', '$scope', '$http', '$timeout', '$uibModal', '$translate', '$uibModalInstance', 'JhiLanguageService', 
    'entity', 'AccountVoucher', 'AccountTitle', 'DataUtils', 'AccountRef', 'VoucherContent', 'OpsFiles', 'CashFlowSubject', 
    'OpsVender', 'User', 'OpsExchangeRate', 'DateUtils'];

    function AccountVoucherDialogController($rootScope, $scope, $http, $timeout, $uibModal, $translate, $uibModalInstance, JhiLanguageService, 
        entity, AccountVoucher, AccountTitle, DataUtils, AccountRef, VoucherContent, OpsFiles, CashFlowSubject, 
        OpsVender, User, OpsExchangeRate, DateUtils) {

        $scope.accountVouchers = entity.accountVouchers;
        $scope.voucherContents = entity.voucherContents;
        var contentIdTemp = entity.contentIdTemp;
        $scope.accountVoucher = {};
        $scope.files = [];
        $scope.AddRemoveIndex=null;
        $scope.DivWidth=null;
        $scope.isTdDivShow=null;
        $scope.TdDivShowIndex=null;
        $scope.isShowParams=null;
        $scope.cashFlowSubjects = [];
        var cashFlowSubjects = [];

        $timeout(function (){
            if (entity.voucherId) {
                AccountVoucher.get({
                    id: entity.voucherId
                }, function(result) {
                    $scope.accountVoucher = result;
                    checkVoucher();
                }, function(result) {
                    layer.msg($translate.instant('global.noRecord'));    
                    $uibModalInstance.dismiss('cancel');                
                });
            }
            else if (entity.voucherId) {
                AccountVoucher.get({
                    id: entity.voucherId
                }, function(result) {
                    $scope.accountVoucher = result;
                    checkVoucher();
                }, function(result) {
                    layer.msg($translate.instant('global.noRecord'));    
                    $uibModalInstance.dismiss('cancel');        
                });
            }
            else if (entity.accountVoucher) {
                $scope.accountVoucher = entity.accountVoucher;
                checkVoucher();
            }
            else if (!$scope.accountVouchers){
                $scope.accountVoucher = entity;
                checkVoucher();
            }
            else {
                $scope.accountVoucher = {};
                initContent();
            }
            CashFlowSubject.query(function(result){
                cashFlowSubjects = result;
                checkAndUpdateCashFlow();
            },function(error){
                layer.msg('GET CASH FLOW FAILED!');
            });
        })
        var checkVoucher = function() {
            AccountVoucher.checkAssist($scope.accountVoucher, function(accountTitles){
                angular.forEach(accountTitles, function(data){
                    angular.forEach($scope.accountVoucher.voucherContents, function(content){
                        if(content.code == data.code){
                            content.venderRequired = data.venderRequired;
                            content.clerkRequired = data.clerkRequired;
                            content.teamRequired = data.teamRequired;
                            content.branchRequired = data.branchRequired;
                            if (data.isCash){
                                if(!content.cashFlowId){
                                    content.cashFlowId = 0; 
                                }
                            }
                            else {
                                content.cashFlowId = null; 
                            }
                            
                            if (subsidiariesRequired()){
                                if(!content.vender && content.venderRequired){
                                    $rootScope.OPS_ALERT($translate.instant('global.requiredNull') + ": " 
                                        + $translate.instant('autopsApp.accountVoucher.subsidiaries') + " - " 
                                        + $translate.instant('autopsApp.accountVoucher.vender'));
                                }
                                else if(!content.clerkName && content.clerkRequired){
                                    $rootScope.OPS_ALERT($translate.instant('global.requiredNull') + ": " 
                                        + $translate.instant('autopsApp.accountVoucher.subsidiaries') + " - " 
                                        + $translate.instant('autopsApp.accountVoucher.clerkName'));
                                }
                                else if(!content.team && content.teamRequired){
                                    $rootScope.OPS_ALERT($translate.instant('global.requiredNull') + ": " 
                                        + $translate.instant('autopsApp.accountVoucher.subsidiaries') + " - " 
                                        + $translate.instant('autopsApp.accountVoucher.team'));
                                }
                                else if(!content.branch && content.branchRequired){
                                    $rootScope.OPS_ALERT($translate.instant('global.requiredNull') + ": " 
                                        + $translate.instant('autopsApp.accountVoucher.subsidiaries') + " - " 
                                        + $translate.instant('autopsApp.accountVoucher.branch'));
                                }                                
                            }
                        }
                    })
                })
                initContent();

                var cashContentCount = 0;
                angular.forEach($scope.accountVoucher.voucherContents, function(data){
                    if (data.cashFlowId != null){
                        cashContentCount++;
                    }
                });
                if (cashContentCount >1){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.accountVoucher.falseCashFlow'));
                }
            })
        }

        $scope.datePickerForEta = {};
        $scope.datePickerForEta.status = {
            opened: false
        };
        $scope.datePickerForEtaOpen = function($event) {
            $scope.datePickerForEta.status.opened = true;
        };
        var lang = "";
        JhiLanguageService.getCurrent().then(function(language) {
            lang = language;
        });        // 刷新借贷总额

        $scope.curTtlObj = {};
        var refrestDtCt = function() {
            var dt = 0;
            var ct = 0;
            var currencies = new Set();
            angular.forEach($scope.accountVoucher.voucherContents, function(data){
                if (data.dn) {
                    dt += data.dn;
                }
                if (data.cn) {
                    ct += data.cn;
                }
                if(data.currency){
                    currencies.add(data.currency);
                }
            })
            $scope.accountVoucher.dnTotal = DataUtils.round(dt, 2);
            $scope.accountVoucher.cnTotal = DataUtils.round(ct, 2);

            $scope.curTtlObj = {dn:"",cn:""};
            currencies.forEach(function(cur){
                var curTtl = 0;
                angular.forEach($scope.accountVoucher.voucherContents, function(data){
                    if(data.dn && data.currency == cur && data.currencyCount){
                        curTtl += data.currencyCount;
                    }
                })
                $scope.curTtlObj.dn += cur + $rootScope.FORMAT_NUM(DataUtils.round(curTtl, 2)) + " ";
            });

            $scope.curTtlStr += "\nCN: ";
            currencies.forEach(function(cur){
                var curTtl = 0;
                angular.forEach($scope.accountVoucher.voucherContents, function(data){
                    if(data.cn && data.currency == cur && data.currencyCount){
                        curTtl += data.currencyCount;
                    }
                })
                $scope.curTtlObj.cn += cur + $rootScope.FORMAT_NUM(DataUtils.round(curTtl, 2)) + " ";
            });
            $translate.refresh();
        }

        // 初始化借贷明细
        var accountVoucherCopy;
        var initContent = function(isDelContent) {
            if ($scope.accountVoucher.voucherContents) {
                for (var j = $scope.accountVoucher.voucherContents.length; j <= 3; j++) {
                    $scope.accountVoucher.voucherContents.push({});
                }

            } else {
                $scope.accountVoucher.voucherContents = [];
                for (var j = 0; j <= 3; j++) {
                    $scope.accountVoucher.voucherContents.push({});
                }
            }
            $scope.accountVoucher.isUsd = false;
            angular.forEach($scope.accountVoucher.voucherContents, function(content) {
                if (content.currency || content.exchangeRate || content.currencyCount){
                    $scope.accountVoucher.isUsd = true;
                }
            })
            checkAndUpdateCashFlow();
            refrestDtCt();
            if ($scope.accountVoucher.isUsd) {
                $scope.colNumRemarks = 25;
            } else {
                $scope.colNumRemarks = 24;
            }
            $scope.writeOffRates = null;
            if($scope.accountVoucher.id){
                AccountVoucher.getRatesList({
                    id: $scope.accountVoucher.id
                }, function(result) {
                    $scope.writeOffRates = result;
                    updateTtl();
                })
            }
            if(!isDelContent){
                accountVoucherCopy = angular.copy($scope.accountVoucher);
            }
            $translate.refresh();
        }

        var checkAndUpdateCashFlow = function(){
            if(!$scope.accountVoucher.voucherContents || $scope.accountVoucher.voucherContents.length == 0){
                return;
            }
            if(!cashFlowSubjects || cashFlowSubjects.length == 0){
                return;
            }
            angular.forEach($scope.accountVoucher.voucherContents, function(content) {
                checkAndUpdateCashFlowOne(content);
            })
        }
        var checkAndUpdateCashFlowOne = function(content){
            if (null != content.cashFlowId){
                if(!content.dn && !content.cn){
                    content.cashFlowId = 0;
                }
                else {
                    angular.forEach(cashFlowSubjects, function(val) {
                        if(content.cashFlowId == val.id){
                            if(content.dn){
                                if(val.isDn){
                                    content.cashFlowName = val.subject;
                                }
                                else {
                                    content.cashFlowId = 0;
                                    content.cashFlowName = "";
                                }                                    
                            }
                            if(content.cn){
                                if(val.isDn){
                                    content.cashFlowId = 0;
                                    content.cashFlowName = "";
                                }
                                else {
                                    content.cashFlowName = val.subject;
                                }                                    
                            }                                
                        }
                    })
                }
                $translate.refresh();
            }            
        }

        $scope.addparams = function(name,index){
            $scope.isShowParams = name +'_'+ index
            $timeout(function (){
                document.getElementById(name+index).focus();
            })
        }

        $scope.removeParams = function(){
            $scope.isShowParams=null;
        }

        $scope.ttlList = [];
        var updateTtl = function() {
            $scope.ttlList = [];
            var dnNums = new Set();
            angular.forEach($scope.writeOffRates, function(rate) {
                if (rate.dnNum) {
                    dnNums.add(rate.dnNum);
                }
            })
            var ttl = 0;
            var currency;
            dnNums.forEach(function(dnNum) {
                ttl = 0;
                currency = null;
                angular.forEach($scope.writeOffRates, function(rate) {
                    if (rate.dnNum == dnNum) {
                        if(rate.currency && !currency){
                            currency = rate.currency;
                        }
                        if(rate.thisAmount){
                            ttl += rate.thisAmount;
                        }
                        else if(rate.totalAmount){
                            ttl += rate.totalAmount;
                        }                        
                    }
                })
                if(ttl){
                    $scope.ttlList.push({
                        dnNum: dnNum,
                        currency: currency,
                        ttl: DataUtils.round(ttl, 2)
                    });   
                }
            })
        };

        $scope.accountTitleDialog = function(content, index, $event) {
            var entity = {};
            entity.accountTitle = null;
            entity.isAdd = null;
            entity.fmVoucher = true;
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountTitle/accountTitle-dialog.html',
                controller: 'AccountTitleDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xl',//sm md lg xl xxl xxxl
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
                $scope._onLedgerChoosed(content, result, index, $event)
                // loadAll();
            }, function(result) {
                $scope.subLedgers = [];
                $scope.loadDefultLedger();
            });
        }

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.nextVoucher = function() {
            if (!$scope.accountVouchers || $scope.accountVouchers.length == 0) {
                layer.msg($translate.instant('global.last_record_alert'));
                return;
            }
            var index = 0;
            for (var i = 0; i < $scope.accountVouchers.length; i++) {
                if ($scope.accountVouchers[i].id == $scope.accountVoucher.id) {
                    index = i;
                    break;
                }
            }
            if (!$scope.accountVouchers[index + 1]) {
                layer.msg($translate.instant('global.last_record_alert'));
                return;
            }
            AccountVoucher.get({
                id: $scope.accountVouchers[index + 1].id
            }, function(result) {
                $scope.accountVoucher = result;
                checkVoucher();
            });
        }

        $scope.previousVoucher = function() {
            if (!$scope.accountVouchers || $scope.accountVouchers.length == 0) {
                layer.msg($translate.instant('global.first_record_alert'));
                return;
            }
            var index = 0;
            for (var i = 0; i < $scope.accountVouchers.length; i++) {
                if ($scope.accountVouchers[i].id == $scope.accountVoucher.id) {
                    index = i;
                    break;
                }
            }
            if (!$scope.accountVouchers[index - 1]) {
                layer.msg($translate.instant('global.first_record_alert'));
                return;
            }
            AccountVoucher.get({
                id: $scope.accountVouchers[index - 1].id
            }, function(result) {
                $scope.accountVoucher = result;
                checkVoucher();
            });
        }

        $scope.printAccountVoucher = function(){            
            var accountVouchers = [];
            accountVouchers.push($scope.accountVoucher);        
            OpsFiles.previewAccountVoucherReport({fileType:'pdf'}, accountVouchers, function(result){
                $rootScope.PRINTBYTES(result.data);
            },function(error){
                layer.msg($translate.instant("autopsApp.accountVoucher.viewAccountVoucherFail "));
            });
        }

        $scope.refs = [];
        $scope.loadRefs = function(content) {
            if(!content.ref){
                angular.forEach($scope.accountVoucher.voucherContents, function(data){
                    if(data.ref){
                        content.ref = data.ref;
                    }
                })
            }
            if ($scope.refs && $scope.refs.length > 0) {
                return;
            }
            AccountRef.query(function(result) {
                $scope.refs = result;
            });
        }

        var onSaveSuccess = function(result) {
            if($scope.accountVoucher.saveAndCreate){
                $scope.accountVoucher = {
                    voucherNum: null,
                    makeDate: DateUtils.convertLocalDateFromServer($rootScope.account.accountDate),
                    attachmentCount: null,
                    dnTotal: null,
                    cnTotal: null,
                    accounting: null,
                    accountingId: null,
                    audit: null,
                    auditId: null,
                    cashier: null,
                    cashierId: null,
                    originator: $rootScope.account.firstName + " " + $rootScope.account.lastName,
                    originatorId: $rootScope.account.id,
                    isAudited: false,
                    isLocked: false,
                    isVoided: false,
                    isDn: null,
                    companyId: $rootScope.account.companyId,
                    id: null
                };
            }
            else{
                $scope.accountVoucher = result;
            }
            $scope.isSaving = false;
            checkVoucher();
            layer.msg($translate.instant('global.save_success'));
        };

        var subsidiariesRequired = function() {
            for (var i = $scope.accountVoucher.voucherContents.length - 1; i >= 0; i--) {
                if ($scope.accountVoucher.voucherContents[i].ref 
                    && ($scope.accountVoucher.voucherContents[i].ref.indexOf("汇兑损益调整") !== -1 
                    || $scope.accountVoucher.voucherContents[i].ref.indexOf("Adjustment of exchange gains and losses") !== -1 )) {
                    return false;
                }
            }
            return true;
        }

        $scope.save = function(saveAndCreate) {
            if (accountVoucherCopy.id && angular.equals(accountVoucherCopy, $scope.accountVoucher)) {
                if(saveAndCreate){
                    $scope.accountVoucher.saveAndCreate = saveAndCreate;
                    onSaveSuccess();
                    return;
                }
                layer.msg($translate.instant('global.no_amendmend'));                
                return;
            }
            if (accountVoucherCopy.isAudited && $scope.accountVoucher.isAudited) {
                $scope.accountVoucher = angular.copy(accountVoucherCopy);
                layer.msg($translate.instant('autopsApp.accountVoucher.auditedAlert'));
                return;
            }
            if (accountVoucherCopy.isLocked) {
                $scope.accountVoucher = angular.copy(accountVoucherCopy);
                layer.msg($translate.instant('autopsApp.accountVoucher.lockedAlert'));
                return;
            }
            refrestDtCt();
            if ($scope.accountVoucher.dnTotal != $scope.accountVoucher.cnTotal) {
                layer.msg($translate.instant('autopsApp.accountVoucher.invalidVoucher'));
                return;
            }
            for (var i = $scope.accountVoucher.voucherContents.length - 1; i >= 0; i--) {
                if (!$scope.accountVoucher.voucherContents[i].dn && !$scope.accountVoucher.voucherContents[i].cn) {
                    $scope.accountVoucher.voucherContents.splice(i, 1);
                }
                else if(!$scope.accountVoucher.voucherContents[i].subLedger || !$scope.accountVoucher.voucherContents[i].subLedgerId || !$scope.accountVoucher.voucherContents[i].code){
                    layer.msg($translate.instant('autopsApp.accountVoucher.accountInvalidAlert'), {param: $scope.accountVoucher.voucherContents[i].code});
                    initContent();
                    return;
                }
                else if (subsidiariesRequired()){
                    if(!$scope.accountVoucher.voucherContents[i].vender && $scope.accountVoucher.voucherContents[i].venderRequired){
                        $rootScope.OPS_ALERT($translate.instant('global.requiredNull') + ": " 
                            + $translate.instant('autopsApp.accountVoucher.subsidiaries') + " - " 
                            + $translate.instant('autopsApp.accountVoucher.vender'));
                        $scope.showAssistInput(i);
                        initContent();
                        return;
                    }
                    else if(!$scope.accountVoucher.voucherContents[i].clerkName && $scope.accountVoucher.voucherContents[i].clerkRequired){
                        $rootScope.OPS_ALERT($translate.instant('global.requiredNull') + ": " 
                            + $translate.instant('autopsApp.accountVoucher.subsidiaries') + " - " 
                            + $translate.instant('autopsApp.accountVoucher.clerkName'));
                        $scope.showAssistInput(i);
                        initContent();
                        return;
                    }
                    else if(!$scope.accountVoucher.voucherContents[i].team && $scope.accountVoucher.voucherContents[i].teamRequired){
                        $rootScope.OPS_ALERT($translate.instant('global.requiredNull') + ": " 
                            + $translate.instant('autopsApp.accountVoucher.subsidiaries') + " - " 
                            + $translate.instant('autopsApp.accountVoucher.team'));
                        $scope.showAssistInput(i);
                        initContent();
                        return;
                    }
                    else if(!$scope.accountVoucher.voucherContents[i].branch && $scope.accountVoucher.voucherContents[i].branchRequired){
                        $rootScope.OPS_ALERT($translate.instant('global.requiredNull') + ": " 
                            + $translate.instant('autopsApp.accountVoucher.subsidiaries') + " - " 
                            + $translate.instant('autopsApp.accountVoucher.branch'));
                        $scope.showAssistInput(i);
                        initContent();
                        return;
                    }
                }
            }
            $scope.accountVoucher.saveAndCreate = saveAndCreate;
            $scope.isSaving = true;
            if ($scope.accountVoucher.id != null) {
                AccountVoucher.update($scope.accountVoucher, onSaveSuccess, onSaveError);
            } else {
                AccountVoucher.save({dnNum: entity.dnNum}, $scope.accountVoucher, onSaveSuccess, onSaveError);
            }
        };


        var onSaveError = function(result) {
            $scope.isSaving = false;
            initContent();
            $rootScope.ALERT_ERROR(result);
        };

        $scope._onLedgerChoosed = function(content, data, index, $event) {
            content.cashFlowId = data.cashFlowId;
            content.subLedger = data.showLedger;
            content.code = data.code;
            if (data.isCash){
                if(!content.cashFlowId){
                    content.cashFlowId = 0; 
                }
            }
            else {
                content.cashFlowId = null; 
            }
            content.venderRequired = data.venderRequired;
            content.clerkRequired = data.clerkRequired;
            content.teamRequired = data.teamRequired;
            content.branchRequired = data.branchRequired;

            angular.forEach($scope.accountVoucher.voucherContents, function(content) {
                if(!content.venderRequired){
                    content.vender = null;
                    content.venderId = null;
                }
                if(!content.clerkRequired){
                    content.clerkName = null;
                    content.clerkId = null;
                }
                if(!content.teamRequired){
                    content.team = null;
                }
                if(!content.branchRequired){
                    content.branch = null;
                }
            });
            if (data.code.length <=4 ){
                content.levelOne = data.code;
                content.levelTwo = null;
                content.levelThree = null;
                content.levelFour = null;
            }
            else if (data.code.length <=6 ){
                content.levelOne = data.code.substr(0, 4);
                content.levelTwo = data.code;
                content.levelThree = null;
                content.levelFour = null;
            }
            else if (data.code.length <=10 ){
                content.levelOne = data.code.substr(0, 4);
                content.levelTwo = data.code.substr(0, 6);
                content.levelThree = data.code;
                content.levelFour = null;
            }
            else if (data.code.length <=14 ){
                content.levelOne = data.code.substr(0, 4);
                content.levelTwo = data.code.substr(0, 6);
                content.levelThree = data.code.substr(0, 10);
                content.levelFour = data.code;
            }

            content.subLedgerId = data.id;
            if (data.isForeign || data.format == "currencyAmount") {
                content.dn = null;
                content.cn = null;
                $scope.accountVoucher.isUsd = true;
                $scope.colNumRemarks = 25;
                if (data.currency) {
                    content.currency = data.currency;
                    OpsExchangeRate.getCurExchangeRate({
                        date: DateUtils.convertLocalDateToServer($scope.accountVoucher.makeDate),
                        currency: content.currency,
                        companyId: $rootScope.account.companyId
                    }, function(result) {
                        if(!result){
                            return;
                        }
                        content.exchangeRate = result.exchangeRate;
                    })
                    content.currencyCount = 0;
                }
                refrestDtCt();
            }
            else {
                content.currency = null;
                content.exchangeRate = null;
                content.currencyCount = null;
                var haveUsd = null;

                for (var i = $scope.accountVoucher.voucherContents.length - 1; i >= 0; i--) {
                    if ($scope.accountVoucher.voucherContents[i].currency || 
                    $scope.accountVoucher.voucherContents[i].exchangeRate ||
                    $scope.accountVoucher.voucherContents[i].currencyCount){
                        haveUsd = true;
                    }
                }
                if (haveUsd){
                    $scope.accountVoucher.isUsd = true;
                    $scope.colNumRemarks = 25;
                }
                else {
                    $scope.accountVoucher.isUsd = false;
                    $scope.colNumRemarks = 24;
                }
            }
            $scope.showAssistInput(index);
            $scope.stopProp($event);
            
        }
        $scope.showAssistInput =function (index) {
            $timeout(function (){
                $scope.DivWidth={
                    'margin-left': "-1px",
                    'width': angular.element(document.getElementById("TdDiv").clientWidth)[0] + 2 + "px",
                }
                $scope.isTdDivShow=true;
                $scope.TdDivShowIndex=index;
                initCashFlowSubjectsByIdx(index);                      
                $translate.refresh();
            })
        }

        var initCashFlowSubjectsByIdx =function (index) {
            if($scope.accountVoucher.voucherContents && $scope.accountVoucher.voucherContents.length > index){
                initCashFlowSubjectByContent($scope.accountVoucher.voucherContents[index]);
            }
        }

        var initCashFlowSubjectByContent =function (content) {            
            if(content.dn){
                cashFlowSubjectsChange(true);
            }
            else if (content.cn){
                cashFlowSubjectsChange(false);
            }
            checkAndUpdateCashFlowOne(content);
        }

        function cashFlowSubjectsChange(isDn) {
            $scope.cashFlowSubjects = [];
            angular.forEach(cashFlowSubjects, function(data){
                if(isDn == true && (data.isDn == true || data.isDn == null)){
                    $scope.cashFlowSubjects.push(data)
                }else if(isDn == false && (data.isDn == false || data.isDn == null)){
                    $scope.cashFlowSubjects.push(data)
                }
            })
        }

        $scope.stopProp =function ($event) {
            if(!$event){
                return;
            }
            $event.stopPropagation() ?  $event.stopPropagation() :  $event.cancelBubble = true;
        }

        $scope.showTdDiv =function (content, index) {
            return content.subLedger && $scope.isTdDivShow && $scope.TdDivShowIndex == index
                && (content.venderRequired || content.clerkRequired || content.teamRequired || content.branchRequired
                    || content.vender || content.clerkName || content.team || content.branch || 
                    (content.cashFlowId !== null && ((content.dn !== undefined && content.dn !== null)  || (content.cn !== undefined && content.cn !== null) )));
        }       

        $scope.CloseTdDiv = function(){
            $scope.isTdDivShow=false;
            $scope.TdDivShowIndex=null;
            $scope.cashFlowSubjects = [];
            $translate.refresh();
        }

        $scope.subLedgers = [];
        $scope.loadDefultLedger = function() {
            if ($scope.subLedgers && $scope.subLedgers.length > 0) {
                return;
            }     
            AccountTitle.getAll({
                type: "part"
            }, function(result) {
                $scope.subLedgers = result;
                $scope.subLedgers = DataUtils.generateShowLedger($scope.subLedgers, true, lang);
            });
        }

        $scope.loadAllLedger = function() {
            AccountTitle.getAll({
                type: "all"
            }, function(result) {
                $scope.subLedgers = result;
                $scope.subLedgers = DataUtils.generateShowLedger($scope.subLedgers, true, lang);
            });
        }
        function isDnAccount(code){
            if(!code || code.length < 2){
                return true;
            }
            var num = code.substring(0, 2) - 0;
            if(num < 20 || (num > 50 && num < 60) || (num > 63 && num < 70)){
                return true;
            }
            return false;
        }
        $scope.amountChange = function(content) {
            var rmbTotal = content.currencyCount * content.exchangeRate;
            if(content.dn){
                content.dn = DataUtils.round(rmbTotal, 2);
            }
            else if (content.cn){
                content.cn = DataUtils.round(rmbTotal, 2);
            }
            else if(isDnAccount(content.code)){
                content.dn = DataUtils.round(rmbTotal, 2);
            }
            else {
                content.cn = DataUtils.round(rmbTotal, 2);
            }
            refrestDtCt();
        }
        $scope.balanceInput = function(content, isDn) {
            if(isDn && content.cn){
                content.dn = content.cn;
                content.cn = null;    
                checkAndUpdateCashFlowOne(content);  
                refrestDtCt();
                return;
            }
            if(!isDn && content.dn){
                content.cn = content.dn;
                content.dn = null;
                checkAndUpdateCashFlowOne(content);
                refrestDtCt();
                return;
            }
            var index = $scope.accountVoucher.voucherContents.indexOf(content);
            var inputedValue = null;
            if (isDn) {
                content.dn = inputedValue = $scope.accountVoucher.cnTotal - $scope.accountVoucher.dnTotal + (content.dn==null?-content.cn:content.dn);
                content.dn = DataUtils.round(content.dn, 2);
                content.cn = null;
            } else {
                content.cn = inputedValue = $scope.accountVoucher.dnTotal - $scope.accountVoucher.cnTotal + (content.cn==null?-content.dn:content.cn);
                content.cn = DataUtils.round(content.cn, 2);
                content.dn = null;
            }
            if (content.currency) {
                if (!inputedValue) {
                    content.currencyCount = 0;
                }
                else if(content.currencyCount){
                    content.exchangeRate = inputedValue / content.currencyCount;
                    content.exchangeRate = Math.abs(DataUtils.round(content.exchangeRate, 5));
                    if((inputedValue > 0 && content.currencyCount < 0) || (inputedValue < 0 && content.currencyCount > 0)){
                        content.currencyCount = -content.currencyCount;
                    }
                }
                else if (content.exchangeRate){
                    content.currencyCount = inputedValue / content.exchangeRate;
                    content.currencyCount = DataUtils.round(content.currencyCount, 2);
                }
            } else {
                content.currencyCount = null;
                content.exchangeRate = null;
            }
            checkAndUpdateCashFlowOne(content);
            refrestDtCt();
        };

        $scope.amtChange = function(content, isDn) {
            if(isDn){
                if(!content.dn){
                    return;
                }
                content.cn = null;
                if(content.exchangeRate){
                    content.currencyCount = content.dn/ content.exchangeRate;
                    content.currencyCount = DataUtils.round(content.currencyCount, 2);
                }
            }
            else {
                if(!content.cn){
                    return;
                }
                content.dn = null;
                if(content.exchangeRate){
                    content.currencyCount = content.cn/ content.exchangeRate;
                    content.currencyCount = DataUtils.round(content.currencyCount, 2);
                }
            }
            checkAndUpdateCashFlowOne(content);
            refrestDtCt();
        }
        $scope.deleteContent = function(content) {
            if ($scope.accountVoucher.isAudited) {
                layer.msg($translate.instant('autopsApp.accountVoucher.auditedAlert'));
                return;
            }
            if ($scope.accountVoucher.isLocked) {
                layer.msg($translate.instant('autopsApp.accountVoucher.lockedAlert'));
                return;
            }
            var index = $scope.accountVoucher.voucherContents.indexOf(content);
            $scope.accountVoucher.voucherContents.splice(index, 1);
            initContent(true);      
        }

        $scope.addContent = function(index) {
            if ($scope.accountVoucher.isAudited) {
                layer.msg($translate.instant('autopsApp.accountVoucher.auditedAlert'));
                return;
            }
            if ($scope.accountVoucher.isLocked) {
                layer.msg($translate.instant('autopsApp.accountVoucher.lockedAlert'));
                return;
            }            
            if(index == $scope.accountVoucher.voucherContents.length -1){
                $scope.accountVoucher.voucherContents.push({});
            }
            else {
                $scope.accountVoucher.voucherContents.splice(index, 0, {});
            }
        }
        $scope.setAddRemove = function(isMouse,index){
            if(isMouse == 'enter'){
                $scope.AddRemoveIndex = index;
            }else{
                $scope.AddRemoveIndex = null;
            }
        }
        $scope.updateSettlementObj = function(content, item) {
            content.venderId = item.id;
        }

        $scope.clearVenders = function() {
            $rootScope.OPSVENDERS = [];
        }

        $scope.onClerkSelected = function(content, item) {
            content.clerkId = item.id;
            if(item.team){
                content.team = item.team;
            }
            if(item.branch){
                content.branch = item.branch;
            }
            $translate.refresh();
        };


        $scope.nextContent = function() {
            var index = null;
            var matched = false;
            for (var i = 0; i < $scope.voucherContents.length; i++) {
                if (!matched && $scope.voucherContents[i].id == contentIdTemp) {
                    matched = true;
                }
                if(matched && i < $scope.voucherContents.length -1 && $scope.voucherContents[i + 1].id){
                    index = i;
                    break;
                }
            }
            if (null == index) {
                layer.msg($translate.instant('global.last_record_alert'));
                return;
            }
            contentIdTemp = $scope.voucherContents[index + 1].id;
            VoucherContent.getVoucher({
                id: contentIdTemp
            }, function(result) {
                $scope.accountVoucher = result;
                checkVoucher();
            });
        }
        $scope.previousContent = function() {
            var index = null;
            var matched = false;
            for (var i = $scope.voucherContents.length - 1; i >= 0 ; i--) {
                if (!matched && $scope.voucherContents[i].id == contentIdTemp) {
                    matched = true;
                }
                if(matched && i >0 && $scope.voucherContents[i - 1].id){
                    index = i;
                    break;
                }
            }
            if (null == index) {
                layer.msg($translate.instant('global.first_record_alert'));
                return;
            }
            contentIdTemp = $scope.voucherContents[index - 1].id;
            VoucherContent.getVoucher({
                id: contentIdTemp
            }, function(result) {
                $scope.accountVoucher = result;
                checkVoucher();
            });
        }

        $scope.setAudited = function() {
            if ($rootScope.account.authorities.indexOf("FUN_AUDIT_VOUCHER") === -1) {                
                $scope.accountVoucher.isAudited = !$scope.accountVoucher.isAudited;
                $rootScope.FORBIDDEN_BY_ALERT('FUN_AUDIT_VOUCHER');
                return;
            }
            if ($scope.accountVoucher.isVoided || $scope.accountVoucher.isLocked){
                $scope.accountVoucher.isAudited = !$scope.accountVoucher.isAudited;
                return;
            }
            $scope.isSaving = true;
            AccountVoucher.setVoidAudit({
                id: $scope.accountVoucher.id,
                isAudited: $scope.accountVoucher.isAudited
            }, function(result) {
                $scope.isSaving = false;
                $scope.accountVoucher = result;
                checkVoucher();
                layer.msg($translate.instant('global.save_success'));
            }, function(result) {
                $scope.isSaving = false;
                $rootScope.ALERT_ERROR(result);        
            });
        };
        $scope.addRef = function(content) {
            var entity = {
                code: null,
                ref: null,                
                companyId: $rootScope.account.companyId,
                id: null
            };
            $uibModal.open({
                templateUrl: 'app/entities/accountant/accountRef/accountRef-dialog.html',
                controller: 'AccountRefDialogController',
                size: 'md',
                resolve: {
                    entity: [function () {
                        return entity;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('accountRef');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function(result) {
                content.ref = result.ref;
            }, function() {
            })
        };

        
        var enCnChange = function() {
            angular.forEach($scope.accountVoucher.voucherContents, function(content){
                angular.forEach($scope.subLedgers, function(data){
                    if(content.code == data.code){
                        content.subLedger = data.showLedger;
                    }
                })
            })
        }

        $scope.enCn = false;
        $scope.enCnChange = function() {      
            if($scope.enCn){
                lang = 'en';
            }
            else {
                lang = 'zh_cn';
            }

            AccountTitle.getAll({
                type: "all"
            }, function(result) {
                $scope.subLedgers = result;
                $scope.subLedgers = DataUtils.generateShowLedger($scope.subLedgers, true, lang);
                enCnChange();
            });
        }
        $scope._cashFlowChoosed = function(content, data, index, $event){
            content.cashFlowId = data.id;
        }
    }
})();