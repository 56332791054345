(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BookingCustomController', BookingCustomController);

    BookingCustomController.$inject = ['$scope', '$state', 'BookingCustom', 'BookingCustomSearch'];
    
    function BookingCustomController($scope, $state, BookingCustom, BookingCustomSearch) {

        $scope.bookingCustoms = [];
        $scope.loadAll = function() {
            BookingCustom.query(function(result) {
               $scope.bookingCustoms = result;
            });
        };
        $scope.loadAll();


        $scope.search = function () {
            // BookingCustomSearch.query({query: $scope.searchQuery}, function(result) {
            //     $scope.bookingCustoms = result;
            // }, function(response) {
            //     if(response.status === 404) {
            //         $scope.loadAll();
            //     }
            // });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.bookingCustom = {
                bookingId: null,
                exportType: null,
                bookingPartyName: null,
                tradeAgentName: null,
                transferRequired: null,
                customDocsRequired: null,
                remarks: null,
                id: null,
                needFileService: false,
                fileServiceIsSend: false
            };
        };
    }
})();
