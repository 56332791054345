(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('VgmHeadController', VgmHeadController);

    VgmHeadController.$inject = ['$scope', '$state', '$rootScope', '$translate', '$uibModal', '$location', '$http', '$timeout',
        'VgmHead', 'VgmHeadSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'uiGridConstants', 'GridState', 'DataUtils',
        'DateUtils','WinAES'
    ];

    function VgmHeadController($scope, $state, $rootScope, $translate, $uibModal, $location, $http, $timeout,
        VgmHead, VgmHeadSearch, ParseLinks, AlertService, pagingParams, paginationConstants, uiGridConstants, GridState, DataUtils, DateUtils,
        WinAES) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = 'id';
        vm.direction = 'desc';
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.page = 1;
        loadAll();
        vm.isAdvanceSearch = false;
        var gridCopy;
        var pageLoaded = true;
        var filterTimer;

        function loadAll() {
            if (vm.searchQuery) {
                VgmHeadSearch.query({
                    venderType: $rootScope.venderType,
                    query: vm.searchQuery,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                var filter = {};
                // filter.venderType = $rootScope.venderType;
                // filter.filterType = $rootScope.filterType;
                if (gridCopy) {
                    angular.forEach(gridCopy.columns, function(column) {
                        angular.forEach(column.filters, function(cf) {
                            if (cf.term != null && angular.isDefined(cf.term)) {
                                if (cf.condition) {
                                    var fd = column.field;
                                    if (cf.condition == 128 || cf.condition == 256) {
                                        filter[fd + "TO"] = cf.term;
                                    } else if (cf.condition == 32 || cf.condition == 64) {
                                        filter[fd + "FM"] = cf.term;
                                    }
                                } else {
                                    filter[column.field] = cf.term;
                                }
                            }
                        })
                    })
                }
                VgmHead.getFilterPage({
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, filter, onSuccess, onError);
            }

            function sort() {
                var result = [vm.predicate + ',' + vm.direction];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                if (headers('X-Total-Count')) {
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                } else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                angular.forEach($scope.gridOptions.data, function(data){
                    data.encodeId = WinAES.encode(data.id.toString())
                })
                vm.isAdvanceSearch = false;
                initData();

                resize();
                $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.OPTIONS);
                $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
            }

            function onError(error) {
                console.log("onError");
                layer.msg($translate.instant('global.loadFailed'));
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + vm.direction,
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.direction = 'desc';
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.direction =  'desc';
            vm.currentSearch = null;
            vm.transition();
        }

        window.onresize = function() {
            resize();
        }

        function resize() {
            if (!$("#gridId") || !$("#gridId").offset()) {
                return;
            }
            var tabHeight = $scope.gridOptions.data.length * 30 + 180;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if (tabHeight > winHeight) {
                tabHeight = winHeight;
            }
            if (tabHeight < 600) {
                tabHeight = 600;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        function initData() {
            $scope.gridOptions.useExternalSorting = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.useExternalFiltering = !vm.isAdvanceSearch && !vm.currentSearch;
            $scope.gridOptions.showColumnFooter = vm.isAdvanceSearch;
            DataUtils.addVenderTag($scope.gridOptions.data);
        }

        $scope.gridOptions = {
            enableSorting: true, //排序
            useExternalSorting: true,

            enableGridMenu: true, //表格 菜单
            showGridFooter: false, //表格的footer
            showColumnFooter: false, //每列footer

            enableFiltering: true, //高级过滤
            useExternalFiltering: true,

            enableHorizontalScrollbar: 1, //表格的水平滚动条
            enableVerticalScrollbar: 1, //表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            exporterMenuCsv: false,
            exporterMenuPdf: false,
            exporterMenuExcel: false,

            paginationPageSizes: [20, 35, 50],
            paginationPageSize: 20,
            useExternalPagination: true,

            minimumColumnSize: 70,
            exporterExcelFilename: "settlement_object",
            columnDefs: [{
                    field: 'id',
                    width: 140,
                    displayName: "",
                    pinnedLeft: true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<div class="btn-group flex-btn-group-container ml-5"><button type="submit"ui-sref="vgm-head.edit({id:row.entity.encodeId})"class="btn btn-primary btn-grid"><span class="glyphicon glyphicon-modal-window"></span><span class="hidden-xs hidden-sm"translate="entity.action.edit"></span></button><div class="btn-group"uib-dropdown dropdown-append-to-body><button type="button"class="btn btn-info btn-grid"ng-disabled="isSaving"uib-dropdown-toggle><span class="glyphicon glyphicon-option-horizontal"></span><span translate="entity.action.others">others</span><span class="caret"></span></button><ul uib-dropdown-menu><li><a href=""ng-click="grid.appScope.copy(row.entity.id)"><span class="glyphicon glyphicon-copy"></span>&nbsp;<span translate="entity.action.copy"></span></a></li><li role="separator"class="divider"></li><li><a ui-sref="vgm-head-detail({id:row.entity.encodeId})"><span class="glyphicon glyphicon-eye-open"></span>&nbsp;<span class="hidden-xs hidden-sm"translate="entity.action.view"></span></a></li><li role="separator"class="divider"></li><li ng-if="row.entity.status == \'-1\' || row.entity.status == \'102\'"><a ui-sref="vgm-head.delete({id:row.entity.encodeId})"><span class="glyphicon glyphicon-remove-circle"></span>&nbsp;<span translate="entity.action.delete"></span></a></li></ul></div></div>'
                },
                {
                    field: 'jobNum',
                    minWidth: 140,
                    displayName: $translate.instant('autopsApp.vgmHead.jobNum')
                }, {
                    field: 'mblNum',
                    minWidth: 140,
                    displayName: $translate.instant('autopsApp.vgmHead.mblNum')
                }, {
                    field: 'soNum',
                    minWidth: 140,
                    displayName: $translate.instant('autopsApp.vgmHead.soNum')
                }, {
                    field: 'polId',
                    minWidth: 70,
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.vgmPortList
                    },
                    displayName: $translate.instant('autopsApp.vgmHead.polId'),
                    cellTemplate: '<span class="ml-5" ng-if="row.entity.polName">{{row.entity.polName}}</span>'
                }, {
                    field: 'carrierCode',
                    minWidth: 170,
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.carrierList
                    },
                    displayName: $translate.instant('autopsApp.vgmHead.carrierCode'),
                    cellTemplate: '<span class="ml-5" ng-if="row.entity.carrierName">{{row.entity.carrierName}}</span>'
                }, {
                    field: 'pierCode',
                    minWidth: 160,
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.pierList
                    },
                    displayName: $translate.instant('autopsApp.vgmHead.pierCode'),
                    cellTemplate: '<div class="ui-grid-cell-contents" title="{{row.entity.pierName}}"><span class="ml-5" ng-if="row.entity.pierName">{{row.entity.pierName}}</span></div>'
                }, {
                    field: 'vsl',
                    minWidth: 120,
                    displayName: $translate.instant('autopsApp.vgmHead.vsl')
                }, {
                    field: 'voy',
                    minWidth: 100,
                    displayName: $translate.instant('autopsApp.vgmHead.voy')
                }, {
                    field: 'isSoc',
                    minWidth: 120,
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    displayName: $translate.instant('autopsApp.vgmHead.isSoc'),
                    cellTemplate: '<span ng-class="row.entity.isSoc?\'label-primary\':\' label-danger\'"translate="{{row.entity.isSoc?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'
                }, {
                    field: 'vgmDeadline',
                    minWidth: 200,
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [{
                        condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                        placeholder: ' >= '
                    }, {
                        condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                        placeholder: ' <= '
                    }],
                    displayName: $translate.instant('autopsApp.vgmHead.vgmDeadline'),
                    cellTemplate: '<span>{{row.entity.vgmDeadline | date:\'medium\'}}</span>'
                },
                // {
                //     field: 'ctnVerified',
                //     minWidth: 120,
                //     filter: {
                //         type: uiGridConstants.filter.SELECT,
                //         selectOptions: $rootScope.YES_NO_OPTIONS
                //     },
                //     displayName: $translate.instant('autopsApp.vgmHead.ctnVerified'),
                //     cellTemplate: '<span ng-class="row.entity.ctnVerified?\'label-primary\':\' label-danger\'"translate="{{row.entity.ctnVerified?\'global.yes_answer\':\'global.no_answer\'}}"class="label ml-5"ng-if="row.entity.id"></span>'
                // },
                {
                    field: 'status',
                    minWidth: 120,
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.vgmStatusList
                    },
                    displayName: $translate.instant('autopsApp.vgmHead.status'),
                    cellTemplate: '<span class="ml-5" ng-if="row.entity.status" translate="autopsApp.vgmHead.statusName.{{row.entity.status}}"></span>'
                }, {
                    field: 'sendTime',
                    minWidth: 200,
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [{
                        condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                        placeholder: ' >= '
                    }, {
                        condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                        placeholder: ' <= '
                    }],
                    displayName: $translate.instant('autopsApp.vgmHead.sendTime'),
                    cellTemplate: '<span>{{row.entity.sendTime | date:\'medium\'}}</span>'
                }, {
                    field: 'clientName',
                    minWidth: 180,
                    displayName: $translate.instant('autopsApp.vgmHead.clientName')
                }, {
                    field: 'submitter',
                    minWidth: 150,
                    displayName: $translate.instant('autopsApp.vgmHead.submitter')
                }, {
                    field: 'lastModifiedTime',
                    minWidth: 200,
                    type:'date',
                    filterHeaderTemplate: '<div ui-grid-filter=""><div class="ui-grid-filter-container"ng-style="col.extraStyle"ng-repeat="colFilter in col.filters track by $index"ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"><div><input type="text"class="ui-grid-filter-input ui-grid-filter-input-0 ng-empty ng-touched"datepicker-append-to-body="true"datetime-picker="{{dateformat}}"is-open="grid.appScope.datePickerOpenStatus[$index+col.field]"ng-click="grid.appScope.openCalendar($index+col.field)"ng-model="colFilter.term"ng-attr-placeholder="{{colFilter.placeholder || \'\'}}"aria-label=""aria-invalid="false"><div role="button"class="ui-grid-filter-button ng-hide"ng-click="removeFilter(colFilter, $index)"ng-if="!colFilter.disableCancelFilterButton"ng-disabled="!colFilter.term"ng-show="colFilter.term"tabindex="0"aria-hidden="true"aria-disabled="true"disabled="disabled"><i class="ui-grid-icon-cancel"ui-grid-one-bind-aria-label="aria.removeFilter"aria-label="">&nbsp;</i></div></div></div></div>',
                    filters: [{
                        condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                        placeholder: ' >= '
                    }, {
                        condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                        placeholder: ' <= '
                    }],
                    displayName: $translate.instant('autopsApp.vgmHead.lastModifiedTime'),
                    cellTemplate: '<span>{{row.entity.lastModifiedTime | date:\'medium\'}}</span>'
                }
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = 120;
                    GridState.update(gridStateObj, function(result) {
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error) {
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if (gridStateObj.gridState) {
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }, {
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "vgmHead";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 3
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "vgmHead";
                    jsonObj.sumBy = "NA";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 4
            }],
            onRegisterApi: function(gridApi) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                    if (!$scope.gridOptions.useExternalSorting) {
                        if (sortColumns[sortColumns.length - 1]) {
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function(a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    if(sortColumns[sortColumns.length - 1]){
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    }
                    loadAll();
                });
                gridApi.core.on.filterChanged($scope, function() {
                    gridCopy = this.grid;
                    if (pageLoaded && $scope.gridOptions.useExternalFiltering) {
                        clearTimeout(filterTimer);
                        filterTimer = setTimeout(function() {
                            loadAll();
                        }, 1000); //查询延时时间，单位ms
                    }
                });
                gridApi.pagination.on.paginationChanged($scope, function(newPage, pageSize) {
                    vm.page = newPage;
                    vm.itemsPerPage = pageSize;
                    if (pageLoaded) {
                        loadAll();
                    }
                });
            }
        }
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function() {
            gridStateCopy = $scope.gridApi.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 120
            }, function(result) {
                gridStateObj = result;
                if (!gridStateObj || !gridStateObj.id) {
                    pageLoaded = true;
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                pageLoaded = true;
                if (gridCopy) {
                    var filterRequired = false;
                    angular.forEach(gridCopy.columns, function(column) {
                        angular.forEach(column.filters, function(cf) {
                            if (cf.term) {
                                filterRequired = true;
                            }
                        })
                    })
                    if (filterRequired) {
                        layer.msg($translate.instant('global.filterDetectedAlert'));
                        loadAll();
                    }
                }
            })
        });

        $scope.copy = function(id){
            VgmHead.get({id: id},function(result){
                var vgmHead = result;
                var vgmCtns = [];
                if(vgmHead.vgmCtns != null && vgmHead.vgmCtns.length > 0){
                    vgmHead.vgmCtns.forEach(function(data){
                        vgmCtns.push({
                            ctnNum: data.ctnNum,
                            id: null,
                            message: null,
                            signature: data.signature,
                            status: null,
                            vgmId: null,
                            vgmWeight: data.vgmWeight,
                            weighingParty: data.weighingParty
                        });
                    })
                }
                $uibModal.open({
                    templateUrl: 'app/entities/vgm-head/vgm-head-dialog.html',
                    controller: 'VgmHeadDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'xl',
                    resolve: {
                        entity: function () {
                            return {
                                carrierCode: vgmHead.carrierCode,
                                carrierName: vgmHead.carrierName,
                                clientId: vgmHead.clientId,
                                clientName: vgmHead.clientName,
                                companyId: null,
                                ctnVerified: false,
                                guideId: vgmHead.guideId,
                                id: null,
                                isSoc: vgmHead.isSoc,
                                jobNum: vgmHead.jobNum,
                                lastModifiedTime: null,
                                mblNum: vgmHead.mblNum,
                                message: null,
                                pierCode: vgmHead.pierCode,
                                pierName: vgmHead.pierName,
                                polId: vgmHead.polId,
                                polName: vgmHead.polName,
                                sendTime: null,
                                soNum: vgmHead.soNum,
                                status: -1,
                                submitter: null,
                                submitterId: null,
                                vgmCtns: vgmCtns,
                                vgmDeadline: DateUtils.convertDateTimeFromServer(vgmHead.vgmDeadline),
                                vgmSn: null,
                                voy: vgmHead.voy,
                                vsl: vgmHead.vsl,
                            };
                        }
                    }
                }).result.then(function () {
                    loadAll();
                }, function () { });
            },$rootScope.ALERT_ERROR);
        }

    }
})();
