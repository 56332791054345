(function () {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsGoodsBatchCreateController', WmsGoodsBatchCreateController);

    WmsGoodsBatchCreateController.$inject = ['$scope', '$state', '$rootScope', '$timeout', '$translate',
        'WmsIn', 'DataUtils', 'WmsWarehouse'];

    function WmsGoodsBatchCreateController($scope, $state, $rootScope, $timeout, $translate,
        WmsIn, DataUtils, WmsWarehouse) {

        $timeout(function () {
            $("#field_location").focus();
            getLocations();
        });

        var vm = this;
        vm.wmsCargo = {
            pkgs: 1,
            packageType: "PACKAGES"
        };
        
        vm.save = save;
        vm.fucusInput = fucusInput;
        vm.updateVol = updateVol;
        vm.locationSelected = locationSelected;


        function save() {
            if (vm.isSaving) {
                return;
            }
            if(vm.location.id){
                vm.wmsCargo.areaId = vm.location.areaId;
                vm.wmsCargo.locationId = vm.location.id;
                vm.wmsCargo.location = vm.location.code;
                vm.wmsCargo.locationType = vm.location.locationType;
                vm.wmsCargo.warehouseId = vm.location.warehouseId;
            }
            else {
                layer.msg($translate.instant('autopsApp.wmsIn.locationNullAlert'));
                return;
            }
            vm.isSaving = true;
            WmsIn.createWmsCargo(vm.wmsCargo, onSaveSuccess, $rootScope.ALERT_ERROR);
            vm.isSaving = false;
        }
        function onSaveSuccess(result) {
            vm.wmsCargo = {
                pkgs: 1,
                packageType: "PACKAGES"
            };
            fucusInput("field_returnTracking");
            layer.msg($translate.instant('global.messages.submitted'));
        }

        function fucusInput (fdId) {
            $timeout(function() {
                $("#" + fdId).focus();   
            });
        }
        function updateVol () {
            if ( vm.wmsCargo.length &&  vm.wmsCargo.width &&  vm.wmsCargo.height){
                 vm.wmsCargo.vol =  vm.wmsCargo.length/100 *  vm.wmsCargo.width/100 *  vm.wmsCargo.height/100;
                 vm.wmsCargo.vol =  vm.wmsCargo.vol.toFixed(4) -0;
            }
        }
        function getLocations () {
            WmsWarehouse.getAllLocations({companyId: $rootScope.account.companyId}, function (data) {
                vm.locations = data;
            });
        }

        function locationSelected(item) {
            vm.location = item;
            fucusInput("field_returnTracking");
        }

        vm._onLocationSelected = _onLocationSelected;
        function _onLocationSelected () {
            vm.location = {};
            angular.forEach(vm.locations, function(item){
                if(item.code == vm.location.code){
                    vm.location = item;
                }                
            })
            if(!vm.location.id){                
                layer.msg("Location is not exist");
                fucusInput("field_location");
            }
            else {
                layer.msg("Matched");
                fucusInput("field_returnTracking");
            } 
        }

    }
})();
