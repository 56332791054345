(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CustomDeclarationDocsDeleteController',CustomDeclarationDocsDeleteController);

    CustomDeclarationDocsDeleteController.$inject = ['$uibModalInstance', 'entity', 'CustomDeclarationDocs'];

    function CustomDeclarationDocsDeleteController($uibModalInstance, entity, CustomDeclarationDocs) {
        var vm = this;

        vm.customDeclarationDocs = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CustomDeclarationDocs.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
