(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsRatesController', OpsRatesController);

    OpsRatesController.$inject = ['$scope', '$state', 'OpsRates', 'OpsRatesSearch', 'ParseLinks'];
    
    function OpsRatesController($scope, $state, OpsRates, OpsRatesSearch,ParseLinks) {

        $scope.opsRatess = [];
        $scope.predicate = 'id';
        $scope.reverse = false;
        $scope.page = 1;
        $scope.loadAll = function() {
            OpsRates.query({page: $scope.page-1, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'asc' : 'desc'), 'id']},function(result,headers) {
            	$scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
            	$scope.opsRatess = result;
            });
        };
        $scope.loadAll();


        $scope.search = function () {
            OpsRatesSearch.query({query: $scope.searchQuery}, function(result) {
                $scope.opsRatess = result;
            }, function(response) {
                if(response.status === 404) {
                    $scope.loadAll();
                }
            });
        };

        $scope.refresh = function () {
            $scope.loadAll();
            $scope.clear();
        };

        $scope.clear = function () {
            $scope.opsRates = {
                currency: null,
                price: null,
                item: null,
                count: null,
                payer: null,
                payerId: null,
                paymentTerm: null,
                remarks: null,
                unit: null,
                itemId: null,
                id: null
            };
        };
    }
})();
