(function() {
	'use strict';

	angular.module('autopsApp').factory('GuideSearch', GuideSearch);

	GuideSearch.$inject = [ '$resource' ];

	function GuideSearch($resource) {
		return $resource('api/_search/guides/:query', {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'aImportsQuery' : {
				method : 'GET',
				url:'api/_search/agentGuides/:query',
				isArray : true
			},
			'pubSearch' : {
				method : 'GET',
				url:'api/_search/pubSearch/:query',
				isArray : true
			},
			'tableQuery' : {
				method : 'GET',
				url:'api/_search/table/:query',
				isArray : true
			},
			'billsQuery' : {
				method : 'GET',
				url:'api/_search/bills/:query',
				isArray : true
			},
            'queryVirtual': { 
                method: 'GET', 
                url:'api/_search/guides/queryVirtual',
                isArray: true
            }
		});
	}
})();
