(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('JobPallet', JobPallet);

    JobPallet.$inject = ['$resource', 'DateUtils'];

    function JobPallet ($resource, DateUtils) {
        var resourceUrl =  'api/job-pallets/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.inTime = DateUtils.convertDateTimeFromServer(data.inTime);
                        data.outTime = DateUtils.convertDateTimeFromServer(data.outTime);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'advancedSearch': { 
                method: 'POST', 
                url:'api/job-pallets/advancedSearch',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        angular.forEach(data, function(dataItem) {
                            dataItem.inTime = DateUtils.convertDateTimeFromServer(dataItem.inTime);
                            dataItem.outTime = DateUtils.convertDateTimeFromServer(dataItem.outTime);
                        })
                    }
                    return data;
                }
            },
            'outCargo': {
                method: 'POST',
                url:'api/job-pallets/outCargo'
            }, 
            'antiOutCargo': {
                method: 'POST',
                url:'api/job-pallets/antiOutCargo'
            }, 
            'movement': { 
                method:'POST',
                url:'api/job-pallets/movement',            
                isArray: true
            },
            'jobPalletBarcode':{
                url:'api/job-pallets/jobPalletBarcode',
                method:'POST',
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            }, 
            'delAll': { 
                method:'POST',
                url:'api/job-pallets/delAll'
            }
        });
    }
})();
