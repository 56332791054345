(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CommodityCountryDeleteController',CommodityCountryDeleteController);

    CommodityCountryDeleteController.$inject = ['$uibModalInstance', 'entity', 'CommodityCountry'];

    function CommodityCountryDeleteController($uibModalInstance, entity, CommodityCountry) {
        var vm = this;

        vm.commodityCountry = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            CommodityCountry.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
