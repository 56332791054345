(function () {
    'use strict';

    angular
        .module('autopsApp')
        .controller('JobPalletDialogController', JobPalletDialogController);

    JobPalletDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'JobPallet', 'WmsWarehouse', '$rootScope',
        '$uibModal'];

    function JobPalletDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, JobPallet, WmsWarehouse, $rootScope,
        $uibModal) {
        var vm = this;

        vm.jobPallet = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.getAllWarehouses = getAllWarehouses;
        vm.getAllLocations = getAllLocations;
        vm.warehouses = [];
        vm.locations = [];
        vm.warehouseSelected = warehouseSelected;
        vm.locationSelected = locationSelected;
        vm.getBarcode = getBarcode;

        $timeout(function () {
            angular.element('.form-group:eq(0)>input').focus();
            getAllWarehouses();
            getAllLocations();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.jobPallet.id !== null) {
                JobPallet.update(vm.jobPallet, onSaveSuccess, onSaveError);
            } else {
                JobPallet.save(vm.jobPallet, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $scope.$emit('autopsApp:jobPalletUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.inTime = false;
        vm.datePickerOpenStatus.outTime = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function getAllLocations() {
            if ($scope.isSaving || (vm.locations && vm.locations.length > 0)) {
                return;
            }
            WmsWarehouse.getAllLocations({ companyId: $rootScope.account.companyId }, function (data) {
                vm.locations = data;
            });
        }

        function getAllWarehouses() {
            if (vm.warehouses && vm.warehouses.length > 0) {
                return;
            }
            WmsWarehouse.getAll({
                includeOwn: true,
                includeVirtual: true,
                includeGroup: false
            }, function (result) {
                if (!result || result.length == 0) {
                    layer.msg($translate.instant('global.messages.resultNull'));
                }
                vm.warehouses = result;
            });
        }

        function warehouseSelected(item) {
            vm.jobPallet.warehouseId = item.id;
        }

        function locationSelected(item) {
            vm.jobPallet.locationId = item.locationId;
            if (item.warehouseId) {
                for (var i in vm.warehouses) {
                    var warehouseItem = vm.warehouses[i];
                    if (warehouseItem.id && warehouseItem.id == item.warehouseId) {
                        vm.jobPallet.warehouseId = warehouseItem.id;
                        vm.jobPallet.warehouseName = warehouseItem.name;
                        break;
                    }
                }
            }
        }

        function getBarcode(fd) {
            if ("location" == fd) {
                getAllLocations();
            }
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-dialog.html',
                controller: 'WmsInDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function (code) {
                if ("location" == fd) {
                    angular.forEach(vm.locations, function (data) {
                        if (data.code == code) {
                            vm.jobPallet.location = data.code;
                            vm.jobPallet.locationId = data.locationId;
                        }
                    });
                }
                else {
                    vm.jobPallet[fd] = code;
                }
            }, function () {
            });
        }
    }
})();
