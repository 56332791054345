(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('express-tracking-detail', {
            parent: 'ops',
            url: '/express-tracking/delivery',
            data: {
                authorities: ['ROLE_COURIER', 'ROLE_OPERATOR'],
                pageTitle: 'global.menu.entities.courier'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/express-tracking/express-tracking-detail.html',
                    controller: 'ExpressTrackingDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('expressTracking');
                    $translatePartialLoader.addPart('wmsIn');
                    $translatePartialLoader.addPart('opsTrackStatus');
                    $translatePartialLoader.addPart('guide');       
                    $translatePartialLoader.addPart('activate');                                    
                    return $translate.refresh();
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'express-tracking',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        });
    }

})();
