(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('PubTrackingController', PubTrackingController);

    PubTrackingController.$inject = ['$timeout', '$scope', '$location', '$rootScope', '$translate', '$uibModal', 
        'ExpressTracking', 'OpsFiles', 'tmhDynamicLocale'];

    function PubTrackingController ($timeout, $scope, $location, $rootScope, $translate, $uibModal,
        ExpressTracking, OpsFiles, tmhDynamicLocale) {
        var vm = this;
        $rootScope.ISPUBTRACKING = true;

        vm.trackingResults = [];   
        vm.tracking = tracking;
        vm.getReceipt = getReceipt;
        
        vm.isElt = false;
        vm.isOntask = false;
        vm.isBlue = false;
        vm.isQqfm = false;

        $rootScope.isPublicTracking = false;

        var locationPath = $location.path();
        $timeout(function() {
            if (locationPath.indexOf("elt") >= 0 || locationPath.indexOf("yuyue") >= 0) {
                $translate.use('en');
                tmhDynamicLocale.set('en');
                vm.isElt = true;
            }         
            else if (locationPath.indexOf("blue") !== -1){
                document.title = "蓝色国际物流跟踪查询网站   ";
                vm.isBlue = true;
            }
            else if (locationPath.indexOf("sharkso") !== -1){
                document.title = "景林供应链跟踪查询网站   ";
            }
            else if (locationPath.indexOf("ontask") !== -1){
                vm.isOntask = true;
            }
            else if (locationPath.indexOf("globalflyinghorse") !== -1){
                vm.isQqfm = true;
            }
            else if (locationPath.indexOf("btskd") !== -1){
                $rootScope.PUBTKSHOWLOGIN = true;
            }   
            else if (locationPath.indexOf("public-tracking") !== -1){
                $rootScope.isPublicTracking = true;
                $rootScope.LOAD_PIC_LOGO(true);
            }
        });

        function tracking (){
            ExpressTracking.pubTracking({
                soNum: vm.soNum,
                url: $location.path(),
                host: $location.host(),
                noSubTracking: true
            }, function(result){
                vm.trackingResults = result;
                loadAllTracking();
            }, function(result){
                if (result.status == 404){
                    layer.msg($translate.instant('activate.notFound'));
                }
                else if (result.status == 403){
                    layer.msg($translate.instant('activate.overMaxAlert'));
                }
            })
        }

        function loadAllTracking () {   
            var trackingNum = "";
            angular.forEach(vm.trackingResults, function(trackingResult){
                angular.forEach(trackingResult.subTrackings, function(tracking){
                    if(tracking.trackingNum && !tracking.context){
                        trackingNum += tracking.trackingNum + " ";
                    }
                })
            })
            if(trackingNum){
                layer.msg($translate.instant('global.messages.loadingSubTrackingAlert') + trackingNum);
            }
            angular.forEach(vm.trackingResults, function(trackingResult){
                angular.forEach(trackingResult.subTrackings, function(tracking){
                    if(tracking.trackingNum && !tracking.context){                        
                        ExpressTracking.pubSubTracking({
                            carrier : tracking.carrier, 
                            carrierCode : tracking.carrierCode, 
                            trackingNum : tracking.trackingNum
                        }, function(result){
                            tracking.trackings = result.trackings;
                            tracking.state = result.state;
                            if(result.trackings && result.trackings.length > 0){
                                tracking.context = result.trackings[result.trackings.length - 1].context;
                                tracking.time = result.trackings[result.trackings.length - 1].time;
                            }
                        })
                    }
                })
            })
        }
        $scope.getOneTracking = function(subTracking) {
            if(subTracking.trackings && subTracking.trackings.length > 0){
                subTracking.trackings = subTracking.trackings;
                subTracking.nu = subTracking.trackingNum;
                fullTrackingPop(subTracking);
            }
        }

        function fullTrackingPop (result) {
            $uibModal.open({
                templateUrl: 'app/entities/express-tracking/express-tracking-delete-dialog.html',
                controller: 'ExpressTrackingDeleteController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: [function() {
                        return result;
                    }]
                }
            }).result.then(function(result) {
            }, function() {
            });
        }

        $scope.go17track = function(trackNum) {
            if(!trackNum){
                return;
            }
            var absUrl = "https://t.17track.net/zh-cn?v=2#nums=" + trackNum;
            window.open(absUrl);       
        };

        function getReceipt (trackingResult){
            if (!trackingResult.guideId || trackingResult.files) {                
                return;
            }
            ExpressTracking.getPodFile({
                id: trackingResult.guideId
            },function (results){
                trackingResult.files = results;
                $translate.refresh();
            });
        }
        

        // $scope.CalHeight = function(index){
        //     if(!vm.trackingResults || vm.trackingResults.length <= index 
        //         || !vm.trackingResults[index].trackings || vm.trackingResults[index].trackings.length == 0){
        //         return;
        //     }
        //     var divHeight = document.getElementById("dashedLine"+index).offsetHeight;
        //     var LastUl = document.getElementById("SubUl"+vm.trackingResults[index].trackings[vm.trackingResults[index].trackings.length-1].id);
        //     if(LastUl){
        //         var LastUlHeight = LastUl.offsetHeight;
        //         var dashedLineHeight = divHeight - LastUlHeight;
        //         angular.element(document.getElementsByClassName('dashed-line')[index]).css('height', dashedLineHeight + 'px');
        //     }
        // }

    }
})();
