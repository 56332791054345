(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsCargoDetailController', WmsCargoDetailController);

    WmsCargoDetailController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$uibModal', '$translate', '$timeout',
    'entity', 'WmsCargo', 'WmsWarehouse', 'DateUtils'];

    function WmsCargoDetailController($scope, $rootScope, $uibModalInstance, $uibModal, $translate, $timeout,
        entity, WmsCargo, WmsWarehouse, DateUtils) {
        var vm = this;
        vm.wmsCargo = entity;
        vm.clear = clear;
        vm.updateVol = updateVol;
        vm.getBarcode = getBarcode;
        vm.updateShipmentPkgAmt = updateShipmentPkgAmt;

        vm.save = save;
        function save () {
            if ($scope.isSaving){
                return;
            }
            $scope.isSaving = true;
            vm.wmsCargo.keeper = $rootScope.account.firstName + " " + $rootScope.account.lastName;
            vm.wmsCargo.keeperId = $rootScope.account.id;      
            vm.wmsCargo.action = "amendment";          
            WmsCargo.update(vm.wmsCargo, function (result) {
                $scope.isSaving = false;
                $uibModalInstance.close(result);
                layer.msg($translate.instant('global.save_success'));
            }, function (result){
                $scope.isSaving = false;                
                if(result.status == 423){
                    layer.msg($translate.instant('autopsApp.wmsCargo.lockedAlert'));   
                }
                else {
                    layer.msg($translate.instant('global.save_fail'));   
                }    
                $uibModalInstance.dismiss();                         
            });
        }


        function updateShipmentPkgAmt() {
            if (!vm.wmsCargo.pkgs || !vm.wmsCargo.palletCapacity){
                vm.wmsCargo.palletAmt = null;
                return;
            }
            var palletAmtFloat =  vm.wmsCargo.pkgs / vm.wmsCargo.palletCapacity;
            vm.wmsCargo.palletAmt = parseInt(palletAmtFloat);
            if (palletAmtFloat != vm.wmsCargo.palletAmt){
                vm.wmsCargo.palletAmt++;
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function getBarcode (cargo, type) {
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-dialog.html',
                controller: 'WmsInDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md'
            }).result.then(function(code) {
                cargo[type] = code;                
            }, function() {
                // $log.info('Modal dismissed at: ' + new Date());
            });
        }
        
        function updateVol () {
            if ( vm.wmsCargo.length &&  vm.wmsCargo.width &&  vm.wmsCargo.height){
                 vm.wmsCargo.vol =  vm.wmsCargo.length/100 *  vm.wmsCargo.width/100 *  vm.wmsCargo.height/100;
                 vm.wmsCargo.vol =  vm.wmsCargo.vol.toFixed(4) -0;
            }
        }

        $timeout(function() {
            if(vm.wmsCargo.billDate){
                vm.wmsCargo.billDate = DateUtils.convertLocalDateFromServer(vm.wmsCargo.billDate);
            }
        });

    }
})();
