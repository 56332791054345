(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('JsExpressionDialogController', JsExpressionDialogController);

    JsExpressionDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', '$translate', '$uibModal', 
    'entity', 'JsExpression'];

    function JsExpressionDialogController ($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, $translate, $uibModal, 
        entity, JsExpression) {
        var vm = this;

        vm.jsExpressionBase = entity;
        vm.clear = clear;
        vm.save = save;
        vm.addOne = addOne;
        vm.delOne = delOne;
        vm.clearBillingFd = clearBillingFd;
        vm.getJsTemplates = getJsTemplates;

        vm.jsExpressions = [];
        $timeout(function (){
            JsExpression.getJsExpressionsByTypeId({
                id: vm.jsExpressionBase.id,
                type: vm.jsExpressionBase.type
            }, function(resultList){
                vm.jsExpressions = resultList;
                checkList();
            })            
        });

        vm.jsTemplates = [];
        function getJsTemplates () {
            if(vm.jsTemplates && vm.jsTemplates.length > 0){
                return;
            }
            JsExpression.getJsTemplates({
                companyId: $rootScope.account.companyId,
                jsType: vm.jsExpressionBase.type == "channel"?"surchargeJs":"wmsChargeJs"//manageEnterprise channel wmsGoods
            }, function(resultList){
                vm.jsTemplates = resultList;
            })    
        }

        function checkList () {
            if(vm.jsExpressions.length == 0){
                addOne();
            }
        }
        function addOne () {
            var newJsExpression = {};
            if(vm.jsExpressionBase.type == "manageEnterprise"){
                newJsExpression.manageEnterpriseId = vm.jsExpressionBase.id;
            }
            else if(vm.jsExpressionBase.type == "wmsGoods"){
                newJsExpression.wmsGoodsId = vm.jsExpressionBase.id;
            }
            else if(vm.jsExpressionBase.type == "channel"){
                newJsExpression.channelId = vm.jsExpressionBase.id;
            }
            vm.jsExpressions.push(newJsExpression);
        }
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save (jsExpression) {
            if(!jsExpression.itemName && !jsExpression.jsName){
                layer.msg($translate.instant('autopsApp.jsExpression.itemRequired'));
                return;
            }
            if(jsExpression.jsName){
                jsExpression.jsExpression = null;
                jsExpression.itemId = null;
                jsExpression.itemName = null;
            }
            vm.isSaving = true;
            if (jsExpression.id) {
                JsExpression.update(jsExpression, function (result) {
                    jsExpression = result;
                    layer.msg($translate.instant('global.save_success'));
                    vm.isSaving = false;
                }, onSaveError);
            } else {
                JsExpression.save(jsExpression, function (result) {
                    angular.copy(result, jsExpression);
                    layer.msg($translate.instant('global.save_success'));
                    vm.isSaving = false;
                }, onSaveError);
            }
        }        

        function onSaveError () {
            vm.isSaving = false;
            layer.msg($translate.instant('global.save_fail'));
        }

        $scope._onItemSelected = function(data, jsExpression) {
            jsExpression.itemId = data.id;
        }
        function delOne(jsExpression) {
            if(!jsExpression.id){
                var idx = vm.jsExpressions.indexOf(jsExpression);
                vm.jsExpressions.splice(idx, 1);
                checkList();
            }
            else {
                $uibModal.open({
                    templateUrl: 'app/entities/js-expression/js-expression-delete-dialog.html',
                    controller: 'JsExpressionDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['JsExpression', function(JsExpression) {
                            return JsExpression.get({id : jsExpression.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    var idx = vm.jsExpressions.indexOf(jsExpression);
                    vm.jsExpressions.splice(idx, 1);
                    checkList();
                }, function() {

                });
            }
        }
        function clearBillingFd(fd) {
            layer.load(1, {shade: 0.3});
            JsExpression.clearBillingFd({
                id: vm.jsExpressionBase.type == "wmsGoods"?vm.jsExpressionBase.id:null,
                manageEnterpriseId: (vm.jsExpressionBase.type == "manageEnterprise" || vm.jsExpressionBase.type == "chargePriceInventory" || vm.jsExpressionBase.type == "dayChargeTrue" || vm.jsExpressionBase.type == "dayChargeFalse")? vm.jsExpressionBase.id:null, 
                fd: fd
            }, function (result) {
                layer.closeAll(); 
                layer.msg($translate.instant('global.messages.submitted'));
            });
        }

    }
})();
