(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsItemDialogController', OpsItemDialogController);

    OpsItemDialogController.$inject = ['$rootScope', '$scope', '$location', '$uibModalInstance', '$translate', 'entity', 'OpsItem', 'WmsWarehouse'];
    
    function OpsItemDialogController($rootScope, $scope, $location, $uibModalInstance, $translate, entity, OpsItem, WmsWarehouse) {

        $scope.opsItem = entity;
        $scope.load = function(id) {
            OpsItem.get({id : id}, function(result) {
                $scope.opsItem = result;
            });
        };

        $scope.upperCode = function(code) {
            $scope.opsItem.code = angular.uppercase(code);
        };
        setTimeout(function () {   
            $rootScope.loadCurrencys();
        }, 500);  
        var onSaveSuccess = function (result) {
            $scope.$emit('autopsApp:opsItemUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
            if (result.status === 403) {
                $uibModalInstance.close(true);
                $rootScope.OPS_ALERT($translate.instant('global.forbidden'), {
                    icon: 0, 
                    title :$translate.instant('global.alert_title'),
                    btn: [$translate.instant('global.ok_answer')]
                })
            };    
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.opsItem.id != null) {
                OpsItem.update($scope.opsItem, onSaveSuccess, onSaveError);
            } else {
                OpsItem.save($scope.opsItem, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };


        $scope.opsVenders = [{
            id: null,
            code: "",
            companyName: ""
        }, {
            id: -1,//"客户"
            code: "TYPE",
            companyName: $translate.instant('autopsApp.opsTemplate.customer')
        }, {
            id: -2,//"发/收货人"
            code: "TYPE",
            companyName: $translate.instant('autopsApp.opsTemplate.shipper')
        }, {
            id: -3,//"订舱代理"
            code: "TYPE",
            companyName: $translate.instant('autopsApp.opsTemplate.bookingAgent')
        }, {
            id: -4,//"海外代理"
            code: "TYPE",
            companyName: $translate.instant('autopsApp.opsTemplate.overseasAgent')
        }, {
            id: -11,//"清关代理"
            code: "TYPE",
            companyName: $translate.instant('autopsApp.opsTemplate.clearAgent')
        }, {
            id: -5,//"拖车行"
            code: "TYPE",
            companyName: $translate.instant('autopsApp.opsTemplate.trucker')
        }, {
            id: -6,//"报关行"
            code: "TYPE",
            companyName: $translate.instant('autopsApp.opsTemplate.broker')
        }, {
            id: -7,//"仓储公司"
            code: "TYPE",
            companyName: $translate.instant('autopsApp.opsTemplate.warehouse')
        }, {
            id: -8,//"贸易公司"
            code: "TYPE",
            companyName: $translate.instant('autopsApp.opsTemplate.trader')
        }, {
            id: -9,//"保险公司"
            code: "TYPE",
            companyName: $translate.instant('autopsApp.opsTemplate.insurance')
        }, {
            id: -10,//"熏蒸公司"
            code: "TYPE",
            companyName: $translate.instant('autopsApp.opsTemplate.fumigation')
        }];  

        $scope.warehouses = [];
        $scope.getWarehouses = function() {
            if ($scope.warehouses && $scope.warehouses.length > 0){
                return;
            }
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false
                }, function (data) {
                $scope.warehouses = data;
            });
        }
        $scope.warehouseSelected = function(warehouse) {
            $scope.opsItem.whName = warehouse.name;
            $scope.opsItem.whId = warehouse.id;
        }
        $scope.checkCodeRepeat = function() {
            if (!$scope.opsItem.code) {
                return;
            }
            OpsItem.checkCodeRepeat({
                companyId: $rootScope.account.companyId,
                opsItemId: $scope.opsItem.id,
                code: $scope.opsItem.code
            }, function(result) {
                if (result.isRepeated) {
                    $rootScope.OPS_ALERT($translate.instant('global.codeRepeat'));
                }
            });
        }


}
})();
