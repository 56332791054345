(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WriteOffDeleteController',WriteOffDeleteController);

    WriteOffDeleteController.$inject = ['$uibModalInstance', '$rootScope', '$translate', 'entity', 'WriteOff'];

    function WriteOffDeleteController($uibModalInstance, $rootScope, $translate, entity, WriteOff) {
        var vm = this;

        vm.writeOff = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.isSaving = false;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        if ($rootScope.HAVENOAUTH('AU_ANTI_WRITEOFF')) {
            clear();
            $rootScope.FORBIDDEN_BY_ALERT('AU_ANTI_WRITEOFF');
            
        }
        function confirmDelete (id) {
            if(vm.isSaving){return;}
            vm.isSaving = true;
            layer.load(1, {shade: 0.3});
            WriteOff.delete({id: id},function () {
                layer.closeAll(); 
                vm.isSaving = false;
                $uibModalInstance.close(true);
            },function (result) {
                layer.closeAll(); 
                vm.isSaving = false;
                $rootScope.ALERT_ERROR(result);
            });
        }
    }
})();
