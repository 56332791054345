(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('incomeList', {
                parent: 'stats',
                url: '/incomeList',
                data: {
                    authorities: ['REPORT_INCOME_LIST'],
                    pageTitle: 'global.menu.admin.incomeList'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/stats/incomeList/incomeList.html',
                        controller: 'IncomeListController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('stats');
                        $translatePartialLoader.addPart('guide');
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
