(function () {
    'use strict';

    angular
        .module('autopsApp')
        .factory('Register', Register);

    Register.$inject = ['$resource'];

    function Register ($resource) {
        return $resource('api/register', {}, {});
    }
})();

(function () {
    'use strict';

    angular
        .module('autopsApp')
        .factory('Apply', Apply);

    Apply.$inject = ['$resource'];

    function Apply ($resource) {
        return $resource('api/trialApply', {}, {});
    }
})();

