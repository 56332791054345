(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('WarehousingData', WarehousingData);

    WarehousingData.$inject = ['$resource'];

    function WarehousingData ($resource) {
        var resourceUrl =  'api/warehousing-data/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },            
            'websockSubscribe': { 
                method: 'GET', 
                url:'api/warehousing-data/websockSubscribe'
            },
            'getPackingList': { 
                method: 'GET', 
                url:'api/warehousing-data/getPackingList', 
                isArray: true
            },            
            'deleteByGuideId': { 
                method: 'DELETE', 
                url:'api/warehousing-data/deleteByGuideId'
            },
            'update': { method:'PUT' },
            'getFilterPage': {
                method: 'POST',
                url: 'api/warehousing-data/getFilterPage',
                isArray: true
            },
            'advancedSearch': {
                method: 'POST',
                url: 'api/warehousing-data/advancedSearch',
                isArray: true
            },
            'exportWarehousingData': {
                method: 'POST',
                url: 'api/excel/exportWarehousingData',
                isArray:false,
                responseType: 'arraybuffer',
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            }
        });
    }
})();
