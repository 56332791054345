(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('WmsGoodsBatch', WmsGoodsBatch);

    WmsGoodsBatch.$inject = ['$resource', 'DateUtils'];

    function WmsGoodsBatch ($resource, DateUtils) {
        var resourceUrl =  'api/wms-goods-batches/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.expirationDate = DateUtils.convertLocalDateFromServer(data.expirationDate);
                        data.productionDate = DateUtils.convertLocalDateFromServer(data.productionDate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.expirationDate = DateUtils.convertLocalDateToServer(data.expirationDate);
                    data.productionDate = DateUtils.convertLocalDateToServer(data.productionDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.expirationDate = DateUtils.convertLocalDateFromServer(data.expirationDate);
                        data.productionDate = DateUtils.convertLocalDateFromServer(data.productionDate);
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.expirationDate = DateUtils.convertLocalDateToServer(data.expirationDate);
                    data.productionDate = DateUtils.convertLocalDateToServer(data.productionDate);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.expirationDate = DateUtils.convertLocalDateFromServer(data.expirationDate);
                        data.productionDate = DateUtils.convertLocalDateFromServer(data.productionDate);
                    }
                    return data;
                }
            },
            'del':{
            	method:'DELETE'
            }
            
        });
    }
})();
