(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountTitleDeleteController', AccountTitleDeleteController);

    AccountTitleDeleteController.$inject = ['$scope', '$uibModalInstance', 'entity', 'AccountTitle'];

	function AccountTitleDeleteController($scope, $uibModalInstance, entity, AccountTitle) {

        $scope.accountTitle = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            AccountTitle.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }
})();
