(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('AutoCheckSearch', AutoCheckSearch);

    AutoCheckSearch.$inject = ['$resource'];

    function AutoCheckSearch($resource) {
        var resourceUrl =  'api/_search/auto-checks/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
