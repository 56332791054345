(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CashStatementController', CashStatementController);

    CashStatementController.$inject = ['$scope', '$rootScope', '$translate', '$filter', '$uibModal',
    'JhiLanguageService', 'Principal', 'AccountTitle', 'OpsFiles','DateUtils', 'DataUtils'];
    
    function CashStatementController($scope, $rootScope, $translate, $filter, $uibModal,
        JhiLanguageService, Principal, AccountTitle, OpsFiles, DateUtils, DataUtils) {
         if (!$rootScope.account || angular.equals($rootScope.account, {})) {
             Principal.identity().then(function(account) {
                 $rootScope.account = account;
             });
         }

         $scope.isSaving = false;
         $scope.cashFlowsStatement = {};
         $scope.cashFlowsStatement.cashFlowsStatementList = [];
         $scope.reportavailalbe = false;
         
         $scope.getContetns = function() {
             $scope.isSaving = true;
            if (!$scope.filterDTO.dateFrom || !$scope.filterDTO.dateTo){
                layer.msg($translate.instant('autopsApp.accountTitle.monthNullAlert'));
                return;
            }

            $scope.isSaving = true;
            $scope.filterDTO.companyId = $rootScope.account.companyId;
            var yearFrom = $scope.filterDTO.dateFrom.getFullYear();
            var monthFrom = $scope.filterDTO.dateFrom.getMonth();
            var year = $scope.filterDTO.dateTo.getFullYear();
            var month = $scope.filterDTO.dateTo.getMonth();
            if (month == 11) {
                month = month - 12;
                year++;
            }
            var filterDTO = angular.copy($scope.filterDTO);
            filterDTO.dateFrom = new Date(yearFrom, monthFrom, 1);
            filterDTO.dateTo = new Date(year, month + 1, 0);
            OpsFiles.getCashFlowsStatement(filterDTO, function(result) {
                 $scope.isSaving = false;
                 $scope.cashFlowsStatement = result;
                 if (!$scope.cashFlowsStatement || $scope.cashFlowsStatement.cashFlowsStatementList.length == 0) {
                     layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                     return;
                 }
                 for(var i = 0 ; i < $scope.cashFlowsStatement.cashFlowsStatementList.length; i ++){
                     $scope.cashFlowsStatement.cashFlowsStatementList[i].currentBalance = DataUtils.formatTwoDigits($scope.cashFlowsStatement.cashFlowsStatementList[i].currentBalance);
                     $scope.cashFlowsStatement.cashFlowsStatementList[i].previousBalance = DataUtils.formatTwoDigits($scope.cashFlowsStatement.cashFlowsStatementList[i].previousBalance);
                 }
                 $scope.reportavailalbe = true;
             });
         }

         var initContent = function() {
             for (var j = 0; j <= 34; j++) {
                 $scope.cashFlowsStatement.cashFlowsStatementList.push({});
             }
         }

         initContent();
         $scope.exportReport = function(format) {
             OpsFiles.generateReportCashFlowsStatement({fileType: format}, $scope.cashFlowsStatement, function(result) {
                 if (format == 2) {
                     var file = new Blob([result.data], {
                         type: 'application/vnd.ms-excel'
                     });
                     var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
                     var aTag = document.createElement("a");
                     aTag.download = "Cash Flow" + ".xlsx";
                     aTag.href = blobURL;
                     aTag.click();
                 } else {
                     var file = new Blob([result.data], {
                         type: 'application/pdf'
                     });
                     var fileURL = URL.createObjectURL(file);
                     var previewDialog = layer.open({
                         type: 2,
                         area: ['1200px', '800px'],
                         maxmin: true,
                         title: $translate.instant("autopsApp.accountTitle.viewReport"),
                         shade: 0.3,
                         moveType: 0,
                         shift: 0,
                         content: fileURL
                     })
                 }
             }, function(error) {
                 layer.msg($translate.instant('autopsApp.accountTitle.viewAccountTitleFail'));
             });
         }


     $scope.dateformat = "yyyy-MM";
         
     $scope.dateOption = {
             minMode:'month'
     }
     
     $scope.datePickerForDateFrom = {};

     $scope.datePickerForDateFrom.status = {
         opened: false
     };

     $scope.datePickerForDateFromOpen = function($event) {
         $scope.datePickerForDateFrom.status.opened = true;
     };
     $scope.datePickerForDateTo = {};

     $scope.datePickerForDateTo.status = {
         opened: false
     };

     $scope.datePickerForDateToOpen = function($event) {
         $scope.datePickerForDateTo.status.opened = true;
     };
     
     $scope.showDetails = function(cashFlow, isCurrent) {
        if(!cashFlow.cashFlowId){
            return;
        }
        var contents = {};
        if(isCurrent){
            contents = angular.copy($scope.cashFlowsStatement.thisContentList);
        }
        else {
            contents = angular.copy($scope.cashFlowsStatement.lastContentList);
        }
        for(var i = contents.length -1; i >= 0; i--){
            if(contents[i].cashFlowId != cashFlow.cashFlowId){
                contents.splice(i, 1);
            }
        }
        var entity = {};
        entity.contents = contents;
        entity.cashFlow = cashFlow;
        $uibModal.open({
            templateUrl: 'app/entities/voucherContent/voucherContent-detail.html',
            controller: 'VoucherContentDetailController',
            size: 'xxxl',
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('accountVoucher');
                    return $translate.refresh();
                }],
                entity: [function() {
                    return entity;
                }]
            }
        }).result.then(function(result) {
        }, function() {
        })
     };
     

    }
})();
