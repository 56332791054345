(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsRatesDialogController', OpsRatesDialogController);

    OpsRatesDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'OpsRates', 'OpsTemplate'];
    
    function OpsRatesDialogController($scope, $stateParams, $uibModalInstance, entity, OpsRates, OpsTemplate) {

        $scope.opsRates = entity;
        $scope.opstemplates = OpsTemplate.query();
        $scope.load = function(id) {
            OpsRates.get({id : id}, function(result) {
                $scope.opsRates = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('autopsApp:opsRatesUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.opsRates.id != null) {
                OpsRates.update($scope.opsRates, onSaveSuccess, onSaveError);
            } else {
                OpsRates.save($scope.opsRates, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
}
})();
