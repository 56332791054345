(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('clerk-management', {
                parent: 'company',
                url: '/clerk-management',
                data: {
                    authorities: ['ROLE_BOSS', 'FUN_CLERKS_MGMT'],
                    pageTitle: 'user-management.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/company/user-management/user-management.html',
                        controller: 'ClerkManagementController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('user.management');
                        return $translate.refresh();
                    }]
                }
            })
            .state('clerk-management.new', {
                parent: 'clerk-management',
                url: '/new',
                data: {
                    authorities: ['ROLE_BOSS', 'FUN_CLERKS_MGMT'],
                },
                onEnter: ['$rootScope', '$state', '$uibModal', function($rootScope, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/company/user-management/user-management-dialog.html',
                        controller: 'ClerkManagementDialogController',
                        size: 'lg',
                        backdrop: false,
                        resolve: {
                            entity: function () {
                                return {
                                    id: null, login: null, firstName: null, lastName: null, email: null,
                                    activated: true, langKey: null, createdBy: null, createdDate: null,
                                    lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                                    resetKey: null, authorities: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('clerk-management', null, { reload: true });
                    }, function() {
                        $state.go('clerk-management');
                    })
                }]
            })
            .state('clerk-management.edit', {
                parent: 'clerk-management',
                url: '/edit',
                data: {
                    authorities: ['ROLE_BOSS', 'FUN_CLERKS_MGMT'],
                },
                onEnter: ['$rootScope', '$state', '$uibModal', function($rootScope, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/company/user-management/user-management-dialog.html',
                        controller: 'ClerkManagementDialogController',
                        size: 'lg',
                        backdrop: false,
                        resolve: {
                            entity: ['User', function(User) {
                                if ($rootScope.num){
                                return User.get({login : $rootScope.num});
                                }
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('clerk-management', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('clerk-management.authorities', {
                parent: 'clerk-management',
                url: '/authorities',
                data: {
                    authorities: ['ROLE_BOSS', 'FUN_CLERKS_MGMT'],
                },
                onEnter: ['$rootScope', '$state', '$uibModal', function($rootScope, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/company/user-management/user-authorities-dialog.html',
                        controller: 'ClerkAuthoritiesController',
                        size: 'xxxl',
                        backdrop: false,
                        resolve: {
                            entity: ['User', function(User) {
                                return User.getCompanyclerks({
                                    companyId: $rootScope.account.companyId
                                }).$promise;
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('clerk-management', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('clerk-management.delete', {
                parent: 'clerk-management',
                url: '/delete',
                data: {
                    authorities: ['ROLE_BOSS', 'FUN_CLERKS_MGMT'],
                },
                onEnter: ['$rootScope', '$state', '$uibModal', function($rootScope, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/company/user-management/user-management-delete-dialog.html',
                        controller: 'ClerkManagementDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['User', function(User) {
                                if ($rootScope.num){
                                    return {    
                                        login: $rootScope.num
                                    };
                                }
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('clerk-management', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }
})();
