(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('LoginLogDialogController', LoginLogDialogController);

    LoginLogDialogController.$inject = ['$timeout', '$rootScope', '$scope', '$stateParams', '$uibModalInstance', '$translate',
        'entity', 'ExpressParcel', 'GridState', 'uiGridConstants'];

    function LoginLogDialogController ($timeout, $rootScope, $scope, $stateParams, $uibModalInstance, $translate,
        entity, ExpressParcel, GridState, uiGridConstants) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.jobNum;
        vm.jobCunt = entity.guides.length;
        $timeout(function (){
            ExpressParcel.getUnconsolidatedMshipment({isFms: entity.isFms}, function(result) {
                $scope.gridOptions_1.data = result;
            }, function(response) {
            });  
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function save (job) {
            var isFms = entity.isFms;
            var id = null;
            var jobNum = vm.jobNum;
            if(job){
                jobNum = job.jobNum;
                if(job.parcelId){
                    id = job.parcelId;
                    isFms = false;
                }
                else {
                    id = job.guideId;
                    isFms = true;
                }
            }            
            layer.load(1, { shade: 0.3 });
            ExpressParcel.addGuidesToMaster({
                isFms: isFms, 
                id: id,
                jobNum: jobNum
            }, entity.guides, function (results) {
                layer.closeAll();
                layer.msg($translate.instant('global.messages.submitted'));
                angular.forEach(entity.guides, function(guide){
                    angular.forEach(results, function(result){
                        if(guide.id == result.id){
                            guide.masterId = result.masterId;
                            guide.parcelId = result.parcelId;
                        }
                    })
                })
                $translate.refresh();
                clear();                
            }, $rootScope.ALERT_ERROR);
        }

        $scope.gridOptions_1 = {
            enableSorting: true,//排序
            useExternalSorting: false,      

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: false,//每列footer

            enableFiltering: true,//高级过滤            

            enableHorizontalScrollbar : 1,//表格的水平滚动条  
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            minimumColumnSize: 80,
            columnDefs: [
                { field: 'id', 
                    width: 100,
                    displayName: "", 
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    cellTemplate: '<button type="button"ng-click="grid.appScope.vm.save(row.entity)"class="btn btn-danger btn-grid ml-5"><span class="glyphicon glyphicon-plus"></span><span translate="autopsApp.guide.fastConsole">fastConsole</span></button>'},
                { field: 'jobNum', 
                    displayName: 'Job#',
                    width: 150,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<span class="grid-text"><a href=""ng-click="grid.appScope.BIZPOP(row.entity.guideId)" ng-if="row.entity.guideId">{{row.entity.jobNum}}</a><a href=""ng-click="grid.appScope.VIEWEXPRESS(row.entity.parcelId)"class="ml-5"ng-if="row.entity.parcelId">{{row.entity.jobNum}}</a><span class="glyphicon glyphicon-ok-circle ml-5 text-info"ng-if="row.entity.checkStatus == \'approved\'"data-toggle="tooltip"data-placement="top"title="{{\'autopsApp.guide.sts.approved\' | translate}}"></span><span class="glyphicon glyphicon-lock ml-5 text-danger"ng-if="row.entity.isClosed || row.entity.consolidated"data-toggle="tooltip"data-placement="top"title="{{row.entity.isClosed?\'Locked\':\'\'}} {{row.entity.consolidated?\'Consolidated\':\'\'}}"></span></span>'},
                { field: 'checkStatus', 
                    displayName: $translate.instant('autopsApp.guide.checkStatus'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CHECK_STATUS
                    },
                    cellTemplate: '<span class="grid-text" ng-if="row.entity.checkStatus"><span class="label label-info" ng-if="row.entity.checkStatus == \'notChecked\'" translate="autopsApp.guide.sts.notChecked">status</span><span class="label label-success" ng-if="row.entity.checkStatus == \'approved\'" translate="autopsApp.guide.sts.approved">status</span><span class="label label-danger" ng-if="row.entity.checkStatus == \'unapproved\'" translate="autopsApp.guide.sts.unapproved">status</span></span>'},
                { field: 'onHold', 
                    displayName: $translate.instant('autopsApp.guide.onHold'), 
                    width: 50,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5" ng-click="grid.appScope.consoleOnHoldShipmentPop(row.entity)"><span ng-if="row.entity.onHold"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.onHold"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'soNum', 
                    displayName: 'SO#',
                    visible: false},
                { field: 'poNum', 
                    displayName: 'PO#',
                    visible: false},
                { field: 'mblNum',
                    visible: false,
                    cellTemplate: '<span class="ml-5">{{row.entity.mblNum}}<span class="glyphicon glyphicon-ok-circle ml-5 text-primary"data-toggle="tooltip"data-placement="top"title="MBL released: {{row.entity.mblReleasedTime | date:\'medium\'}}"ng-if="row.entity.mblReleasedTime"></span></span>',
                    displayName: 'MBL/Mawb#'},
                { field: 'hblNum',
                    visible: false,
                    cellTemplate: '<span class="ml-5">{{row.entity.hblNum}}<span class="glyphicon glyphicon-ok-circle ml-5 text-primary"data-toggle="tooltip"data-placement="top"title="HBL released: {{row.entity.hblReleasedTime | date:\'medium\'}}"ng-if="row.entity.hblReleasedTime"></span></span>',
                    displayName: 'HBL/Hawb#'},
                { field: 'refNum', 
                    displayName: 'EDI Ref#',
                    visible: false},
                { field: 'thirdpartyPlatformRef', 
                    displayName: $translate.instant('autopsApp.guide.thirdpartyPlatformRef'),
                    visible: false},
                { field: 'clientSoNum', 
                    displayName: $translate.instant('autopsApp.guide.clientWmsInNum'),
                    visible: false},
                { field: 'gw', 
                    width: 70,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'GW', 
                    aggregationHideLabel: true,
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'vol', 
                    width: 70,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'VOL', 
                    aggregationHideLabel: true,
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'pkgNum', 
                    width: 70,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'PKG', 
                    aggregationHideLabel: true,
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'gwForecast', 
                    width: 70,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'GW(E)', 
                    aggregationHideLabel: true,
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'volForecast', 
                    width: 70,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'VOL(E)', 
                    aggregationHideLabel: true,
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'pkgNumForecast', 
                    width: 70,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'PKG(E)', 
                    aggregationHideLabel: true,
                    aggregationType: uiGridConstants.aggregationTypes.sum},
                { field: 'pallets', 
                    width: 100,
                    type: 'number', 
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: 'PLT', 
                    aggregationHideLabel: true,
                    aggregationType: uiGridConstants.aggregationTypes.sum}, 
                { field: 'shipmentType', 
                    displayName: 'TYPE',
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.FMS_TYPES
                    },  
                    cellTemplate: '<span ng-if="row.entity.shipmentType" class="label label-info ml-5" translate="global.{{row.entity.shipmentType}}"></span>'},
                { field: 'deliveryTo', 
                    displayName: $translate.instant('autopsApp.guide.deliveryTo')},
                { field: 'shiptoCode', 
                    displayName: $translate.instant('autopsApp.guide.shipToParty')},                    
                { field: 'destName', 
                    displayName: $translate.instant('autopsApp.guide.destName')},
                { field: 'shiptoCountryCode', 
                    displayName: $translate.instant('autopsApp.guide.destCountryCode')}, 
                { field: 'shiptoZipCode', 
                    displayName: $translate.instant('autopsApp.guide.destPostCode')}, 
                { field: 'polName', 
                    displayName: $translate.instant('autopsApp.guide.polName')}, 
                { field: 'warehouseName', 
                    displayName: $translate.instant('autopsApp.guide.warehouse')}, 
                { field: 'cargoType', 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.CARGOTYPES
                    },  
                    displayName: $translate.instant('autopsApp.guide.cargoType'),
                    cellTemplate: '<span class="label label-info ml-5">{{row.entity.cargoTypeLabel}}</span>'},
                { field: 'createdTime', 
                    displayName: $translate.instant('autopsApp.guide.home.createdTime'),
                    cellTemplate: '<span data-toggle="tooltip" data-placement="top" title="{{row.entity.createdTime | date:\'medium\'}}" class="ml-5">{{row.entity.createdTime | date:\'mediumDate\'}}</span>'},
                { field: 'cargoReadyDate', 
                    displayName: $translate.instant('autopsApp.guide.cargoReadyDate')},
                { field: 'bookingEtd', 
                    displayName: $translate.instant('autopsApp.guide.bookingEtd')},
                { field: 'wmsInTime', 
                    displayName: $translate.instant('autopsApp.guide.wmsInTime'),
                    cellTemplate: '<span data-toggle="tooltip" data-placement="top" title="{{row.entity.wmsInTime | date:\'medium\'}}" class="ml-5">{{row.entity.wmsInTime | date:\'mediumDate\'}}</span>'},
                { field: 'etd', 
                    displayName: 'ETD'},
                { field: 'customerName', 
                    displayName: $translate.instant('autopsApp.guide.bookingPartyName')},
                { field: 'shipperName', 
                    displayName: $translate.instant('autopsApp.guide.shipperName')},
                { field: 'productName', 
                    displayName: $translate.instant('autopsApp.guide.productName')},
                { field: 'deliveryChannel', 
                    displayName: $translate.instant('autopsApp.guide.deliveryChannel')},
                { field: 'salesName', 
                    displayName: $translate.instant('autopsApp.guide.salesName')},
                { field: 'opName', 
                    displayName: $translate.instant('autopsApp.guide.opName')},
                { field: 'docName', 
                    displayName: $translate.instant('autopsApp.guide.docName')},
                { field: 'cusName', 
                    displayName: $translate.instant('autopsApp.guide.cusName')},
                { field: 'vsl', 
                    displayName: 'Vessel'},
                { field: 'voy', 
                    displayName: 'Voy/Flight'},
                { field: 'porName', 
                    displayName: $translate.instant('autopsApp.guide.porName')},
                { field: 'memo', 
                    displayName: $translate.instant('autopsApp.guide.table.memo')},
                { field: 'cargoName', 
                    displayName: $translate.instant('autopsApp.guide.cargoName')},
                { field: 'innerRemark', 
                    displayName: $translate.instant('autopsApp.guide.remarks')},
                { field: 'bookingRemark', 
                    displayName: $translate.instant('autopsApp.booking.remarks')},
                { field: 'ownImporter', 
                    displayName: $translate.instant('autopsApp.guide.ownImporter'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.ownImporter"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.ownImporter"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'plConfirmed', 
                    displayName: $translate.instant('autopsApp.booking.plConfirmed'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    type: "boolean",
                    cellTemplate: '<span class="ml-5"><span ng-if="row.entity.plConfirmed"class="label label-primary"><span translate="global.yes_answer">yes_answer</span></span><span ng-hide="row.entity.plConfirmed"class="label label-success"><span translate="global.no_answer">no_answer</span></span></span>'},
                { field: 'customsType', 
                    displayName: $translate.instant('autopsApp.guide.customsType')},
                { field: 'bizType',
                    displayName: $translate.instant('autopsApp.guide.bizType'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.BIZTYPES
                    },
                    cellTemplate: '<span class="grid-text">{{row.entity.bizTypeLabel}}</span>'},
                { field: 'internalType',
                    displayName: $translate.instant('autopsApp.guide.bizInternalType'),
                    minWidth:80,
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.INTERNALTYPES
                    },
                    cellTemplate: '<span class="grid-text">{{row.entity.internalTypeLabel}}</span>'},
                { field: 'deliveryWay', 
                    displayName: $translate.instant('autopsApp.booking.deliveryWay'), 
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.DELIVERYTYPES
                    },       
                    minWidth:100,
                    cellTemplate: '<span class="ml-5" translate="autopsApp.booking.deliveryType.{{row.entity.deliveryWay}}" ng-if="row.entity.deliveryWay" class="label-info label"></span>'}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    gridStateObj_1.gridState = angular.toJson($scope.gridApi_1.saveState.save());
                    gridStateObj_1.userId = $rootScope.account.id;
                    gridStateObj_1.gridId = 168;
                    GridState.update(gridStateObj_1, function(result){
                        gridStateObj_1 = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj_1.gridState){
                        $scope.gridApi_1.saveState.restore($scope, angular.fromJson(gridStateObj_1.gridState));
                    }       
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi_1.saveState.restore($scope, gridStateCopy_1);                   
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi_1 = gridApi;
            }
        }


        var gridStateObj_1 = {};
        var gridStateCopy_1 = null;
        $timeout(function (){
            gridStateCopy_1 = $scope.gridApi_1.saveState.save();
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: 168
            }, function(result){
                gridStateObj_1 = result;
                if(!gridStateObj_1 || !gridStateObj_1.id){
                    $scope.gridApi_1.saveState.restore($scope, gridStateCopy_1);
                    return;
                }   
                $scope.gridApi_1.saveState.restore($scope, angular.fromJson(gridStateObj_1.gridState));
            })            
        });


    }
})();
