(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('PublishRateDialogController', PublishRateDialogController);

    PublishRateDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', '$translate',
        'entity', 'PublishRate'];

    function PublishRateDialogController ($timeout, $scope, $stateParams, $uibModalInstance, $translate,
        entity, PublishRate) {
        var vm = this;

        vm.publishRate = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.save = save;
        vm.bindChange = bindChange;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
            $scope.fillPublishedRateGrid();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.publishRate.id !== null) {
                PublishRate.update(vm.publishRate, onSaveSuccess, onSaveError);
            } else {
                PublishRate.save(vm.publishRate, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('autopsApp:publishRateUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        function bindChange (date) {
            if(!vm.publishRate.venderName){
                vm.publishRate.venderId = null;
            }
        }
        $scope.fillPublishedRateGrid = function() {
            if (!vm.publishRate.publishedPrice) {
                return;
            }
            var rows = vm.publishRate.publishedPrice.split("\n");
            $scope.gridOptions1.columnDefs = [];
            $scope.gridOptions1.data = [];
            for (var i = 0; i < rows.length; i++) {
                if (!rows[i]) {
                    continue;
                }
                var cols = rows[i].split("\t");
                if (i == 0) {
                    for (var ii = 0; ii < cols.length; ii++) {
                        $scope.gridOptions1.columnDefs.push({
                            field: ii + "",
                            displayName: cols[ii]
                        });
                    }
                } else {
                    var data = {};
                    for (var ii = 0; ii < cols.length; ii++) {
                        data[ii + ""] = cols[ii];
                    }
                    $scope.gridOptions1.data.push(data);
                }
            }
        }
        $scope.gridOptions1 = {
            data: [],
            enableSorting: false, //排序
            useExternalSorting: false,

            enableGridMenu: true, //表格 菜单  
            showGridFooter: false, //表格的footer  
            showColumnFooter: false, //每列footer

            enableFiltering: false, //高级过滤            

            enableHorizontalScrollbar: 1, //表格的水平滚动条  
            enableVerticalScrollbar: 1, //表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            exporterMenuCsv: false,
            exporterMenuPdf: false,
            exporterMenuLabel: "Export",
            exporterExcelFilename: $translate.instant('global.menu.entities.zoneMgmt') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns: ['id'],

            paginationPageSizes: [20, 35, 50],
            paginationPageSize: 20,
            useExternalPagination: false,

            minimumColumnSize: 70,
            columnDefs: [],
            onRegisterApi: function(gridApi) {
                $scope.gridApi = gridApi;
            }
        }
    }
})();
