(function() {
    'use strict';

    angular
        .module('autopsApp')
        .directive('baModel', baModel);
    
    baModel.$inject = ['$parse','$http'];
    
function baModel($parse,$http) {
	function fileRule(file) {
	    
	}
	
  return {
    restrict: 'AE',
    scope:{
//    	   hasFile:'=',
//    	   setFile:'&',
    	   baId:'=',
    	   triggerReload:'&'
    },
    link: function(scope, element, attrs, ngModel) {
    	
      scope.hasFile = false;
      var model = $parse(attrs.baModel);
      var modelSetter = model.assign;
      
      scope.ridFile = function (){
    	  angular.forEach(angular.element("input[name='baFile']"),function (inputElem) {
			  angular.element(inputElem).val(null);
    	  });
      }
      
      element.bind('change', function(event){
    	  
    	if(element[0].files[0]==undefined){
    		return;
    	}
        scope.$apply(function(){
          modelSetter(scope, element[0].files[0]);
        });
        
        var fd = new FormData();
        fd.append('BA_File', element[0].files[0]);
        $http.post('api/opsBAFile/'+scope.baId, fd, {
        	transformRequest: angular.identity,
            headers: {'Content-Type': undefined},
        }).success(function(data){
        	  scope.ridFile();
        	  scope.triggerReload();
        }).error(function(error){
        	scope.ridFile();
            console.log("上传EXCEL失败");
        });
        
        
//        $("#setbaFileBtn").trigger('click');
      });
    },
//    template:'<input type="button" id="setbaFileBtn" ng-click="setFile({file:baFile})" style="display:none"/>',
  };
}
})();