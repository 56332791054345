(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('OpsColoaderSearch', OpsColoaderSearch);

    OpsColoaderSearch.$inject = ['$resource'];
    
    function OpsColoaderSearch($resource) {
        return $resource('api/_search/opsColoaders/:query', {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
