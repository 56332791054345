(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('AccountRefSearch', AccountRefSearch);

    AccountRefSearch.$inject = ['$resource'];
    
    function AccountRefSearch($resource) {
        return $resource('api/_search/accountRefs/:query', {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
