(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ExpressCargoDeleteController',ExpressCargoDeleteController);

    ExpressCargoDeleteController.$inject = ['$rootScope', '$uibModalInstance', '$timeout',
    'entity', 'ExpressParcel', 'AddressBook'];

    function ExpressCargoDeleteController($rootScope, $uibModalInstance, $timeout,
        entity, ExpressParcel, AddressBook) {
        var vm = this;

        vm.rateResponse = entity.rateResult;

        vm.clear = clear;
        vm.chooseService = chooseService;
        vm.getEnabledAccounts = getEnabledAccounts;
        vm.getMoreRates = getMoreRates;

        vm.allAccounts = [];
        
        vm.cheapestPrice = null;
        vm.cheapestOne = null;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function chooseService (account) {
            if(!account){
                $uibModalInstance.close(vm.cheapestOne);     
            }
            $uibModalInstance.close(account);            
        }

        $timeout(function() {
            getCheapestPrice();
        })

        function isSameRate(newOne, oldOne){
            return newOne.negotiatedRate == oldOne.negotiatedRate && newOne.channel == oldOne.channel && newOne.service == oldOne.service && newOne.accountNum == oldOne.accountNum;
        }
        function addNewRates(result){
            var isNew;
            if(!vm.rateResponse.expressRates){
                vm.rateResponse.expressRates = [];
            }
            angular.forEach(result.expressRates, function(newOne){   
                if(!newOne.negotiatedRate){
                    newOne.negotiatedRate = newOne.totalFreight;
                }             
                isNew = true;
                angular.forEach(vm.rateResponse.expressRates, function(oldOne){
                    if(isSameRate(newOne, oldOne)){
                        isNew = false;
                    }
                })
                if(isNew){
                    vm.rateResponse.expressRates.push(newOne);
                }  
            })
            if(!vm.rateResponse.alerts){
                vm.rateResponse.alerts = [];
            }
            angular.forEach(result.alerts, function(newOne){
                isNew = true;
                angular.forEach(vm.rateResponse.alerts, function(oldOne){
                    if(angular.equals(newOne, oldOne)){
                        isNew = false;
                    }
                })
                if(isNew){
                    vm.rateResponse.alerts.push(newOne);
                }                
            })
            getCheapestPrice();
        }
        function getCheapestPrice(){
            vm.cheapestPrice = vm.cheapestOne = null;
            angular.forEach(vm.rateResponse.expressRates, function(rate){
                if(!rate.negotiatedRate){
                    rate.negotiatedRate = rate.totalFreight;
                }     
                if(rate.negotiatedRate && rate.negotiatedRate.length > 3){
                    rate.rateAmt = rate.negotiatedRate.substr(3) -0;
                }
            })    
            vm.rateResponse.expressRates.sort(function(a,b){
                return a.rateAmt > b.rateAmt?1:-1;                  
            });
            angular.forEach(vm.rateResponse.expressRates, function(rate){ 
                if(rate.rateAmt){
                    if(!vm.cheapestPrice || rate.rateAmt< vm.cheapestOne.rateAmt){                    
                        vm.cheapestPrice = rate.negotiatedRate;
                        vm.cheapestOne = rate;
                    }
                }
            })             
        }

        vm.enabledAccounts = [];
        function getEnabledAccounts () {
            if(vm.enabledAccounts.length > 0){
                return;
            }
            AddressBook.getEnabledAccounts(function(results){
                vm.enabledAccounts = results;
            })            
        }

        function getMoreRates(account){
            if(account){
                entity.addressBooks = [];
                entity.addressBooks.push(account);
            }
            else {
                entity.addressBooks = vm.enabledAccounts;
            }            
            var loadIndex = layer.load(1, {shade: 0.3});
            ExpressParcel.getAllLabelRates(entity, function(result){
                layer.close(loadIndex);
                addNewRates(result);
            }, function(error){
                layer.close(loadIndex);
                if(error && error.data && error.data.description){
                    $rootScope.OPS_ALERT(error.data.description);
                }  
            });
        }
        
    }
})();
