(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AccountVoucherDetailController', AccountVoucherDetailController);

    AccountVoucherDetailController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$translate', 'JhiLanguageService',
    'DateUtils', 'entity', 'AccountVoucher', 'Clerks', 'DataUtils', 'AccountTitle', 'CashFlowSubject', 'User'];

    function AccountVoucherDetailController($scope, $rootScope, $uibModalInstance, $translate, JhiLanguageService,
        DateUtils, entity, AccountVoucher, Clerks, DataUtils, AccountTitle, CashFlowSubject, User) {
        $scope.customFilterDTO = entity;

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };        

        $scope.isVouchersNumTrim = false;
        $scope.submitFilter = function() {
            $scope.isSaving = true;
            layer.load(1, {shade: 0.3});
            AccountVoucher.getAllByFiler($scope.customFilterDTO, function(result) {
                layer.closeAll();  
                $scope.isSaving = false;
                $uibModalInstance.close(result);
            }, function(){
                layer.closeAll();  
                $scope.isSaving = false;
                layer.msg($translate.instant('global.loadFailed'));
            });
        };

        $scope.clerks = [];
        $scope.loadClerks = function() {
            if ($scope.clerks && $scope.clerks.length > 0) {
                return;
            };
            Clerks.query(function(result) {
                $scope.clerks = result;
                $scope.clerks.splice(0, 0, {
                    id: 0,
                    name: $translate.instant('autopsApp.accountVoucher.filter.system'),
                    role: "account"
                });
                $scope.clerks.splice(0, 0, {
                    id: -1,
                    name: $translate.instant('autopsApp.accountVoucher.filter.artificial'),
                    role: "account"
                });
            });
        }
        $scope.setStatus= function(fd, value) {
            $scope.customFilterDTO[fd] = value;
        }        
        $scope.finFilter = function(item) {
            return item.authorities.indexOf("ROLE_ACCOUNT") !== -1;
        };

        $scope.finFilter1 = function(item) {
            if(item.id == -1){
                return false;
            }
            return item.authorities.indexOf("ROLE_ACCOUNT") !== -1;
        };

        $scope.clerkFilter = function(item) {
            if (item.id > 0) {
                return true;
            };
            return false;
        };

        $scope._onClerkSelected = function(clerk, data) {
            $scope.customFilterDTO[clerk] = data.id;
        }

        $scope.baseFilter = function(item) {
            if (item == $rootScope.account.baseCurrency) {
                return false;
            }
            return true;
        };
        var lang = "";
        JhiLanguageService.getCurrent().then(function(language) {
            lang = language;
        });
        $scope.subLedgers = [];
        $scope.loadAllLedger = function() {
            if($scope.subLedgers && $scope.subLedgers.length > 0){
                return;
            }
            AccountTitle.getAll({
                type: "all"
            }, function(result) {
                $scope.subLedgers = result;
                $scope.subLedgers = DataUtils.generateShowLedger($scope.subLedgers, false, lang);
            });
        }

        $scope._onLedgerChoosed = function(data) {
            if(!$scope.customFilterDTO.ledgers){
                $scope.customFilterDTO.ledgers = [];
            }
            if($scope.customFilterDTO.ledgers.indexOf(data.code) != -1){
                layer.msg($translate.instant('global.messages.repeated'));
            }
            else {
                $scope.customFilterDTO.ledgers.push(data.code);
            }
            
            $scope.customFilterDTO.subLedger = "";
        }

        $scope.delLedger = function(index) {
            $scope.customFilterDTO.ledgers.splice(index, 1);
        };

        $scope._onExLedgerChoosed = function(data) {
            if(!$scope.customFilterDTO.exLedgers){
                $scope.customFilterDTO.exLedgers = [];
            }
            if($scope.customFilterDTO.exLedgers.indexOf(data.code) != -1){
                layer.msg($translate.instant('global.messages.repeated'));
            }
            else {
                $scope.customFilterDTO.exLedgers.push(data.code);
            }
            
            $scope.customFilterDTO.exLedger = "";
        }
        $scope.delExLedger = function(index) {
            $scope.customFilterDTO.exLedgers.splice(index, 1);
        };
        $scope.cashFlowSubjects = [];
        $scope.getCashFlowSubjects = function() {
            CashFlowSubject.query(function(result) {
                $scope.cashFlowSubjects = result;
            }, function(error) {
                layer.msg('GET CASH FLOW FAILED!');
            });
        }

        $scope.chooseCashFlowSuject = function(casheFlow){
            $scope.customFilterDTO.cashFlowId = casheFlow.id;
        }
        $scope.updateSettlementObj = function(item) {
            $scope.customFilterDTO.venderId = item.id;
        }

    }
})();
