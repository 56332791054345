(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ReportTemplateDialogController', ReportTemplateDialogController);

    ReportTemplateDialogController.$inject = ['$scope', '$rootScope', '$stateParams', '$uibModalInstance', '$translate', 
    'DataUtils', 'entity', 'ReportTemplate'];
    
    function ReportTemplateDialogController($scope, $rootScope, $stateParams, $uibModalInstance, $translate, 
        DataUtils, entity, ReportTemplate) {

        $scope.reportTemplate = entity;
        $scope.load = function(id) {
            ReportTemplate.get({id : id}, function(result) {
                $scope.reportTemplate = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('autopsApp:reportTemplateUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.reportTemplate.id != null) {
                ReportTemplate.update($scope.reportTemplate, onSaveSuccess, onSaveError);
            } else {
                ReportTemplate.save($scope.reportTemplate, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.abbreviate = DataUtils.abbreviate;
        $scope.byteSize = DataUtils.byteSize;

        $scope.isOpsCs = function() {
            //海外独立服务器
            if($rootScope.HAVEAUTH("OS_SERVER") || $rootScope.absUrl.indexOf("127.0.0.1") !== -1){
                return true;
            }
            //指定客服用服务器
            if(($rootScope.account.companyId == 54 || $rootScope.account.companyId == 19)
                && $rootScope.absUrl.indexOf("47.106.228.122") !== -1){
                return true;
            }
            return false;
        };

        $scope.setTemplate = function ($file, reportTemplate) {
            if(!$scope.isOpsCs()){
                $rootScope.OPS_ALERT($translate.instant('global.reportUploadAlert'));
                return;
            }            
            if ($file) {
                var fileReader = new FileReader();
                fileReader.readAsDataURL($file);
                fileReader.onload = function (e) {
                    var base64Data = e.target.result.substr(e.target.result.indexOf('base64,') + 'base64,'.length);
                    $scope.$apply(function() {
                        reportTemplate.template = base64Data;
                        reportTemplate.templateContentType = $file.type;
                    });
                };
            }
        };

        $scope.downTemplate = function () {
            ReportTemplate.getBob({id : $scope.reportTemplate.id}, function(result){
                var file = new Blob([result.data], {
                        type: 'application/jasper'
                    });
                if (file.size == 0) {
                    layer.msg($translate.instant('global.loadFileFailed'));
                    return;
                }
                var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
                var aTag = document.createElement("a");
                aTag.download = $scope.reportTemplate.templateName + ".jasper";
                aTag.href = blobURL;
                aTag.click();     
            }); 
        }
        
}
})();
