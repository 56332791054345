(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('booking', {
                parent: 'ops',
                url: '/shipments',
                data: {
                    authorities: ['ROLE_CLIENT', 'FUN_COMPANY_SETUP'],
                    pageTitle: 'autopsApp.booking.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/booking/bookings.html',
                        controller: 'BookingController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('booking');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('expressParcel');                        
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('opsCost');
                        $translatePartialLoader.addPart('stats');                        
                        return $translate.refresh();
                    }]
                }
            }).state('booking-all', {
                parent: 'ops',
                url: '/all-shipments',
                data: {
                    authorities: ['ROLE_CLIENT', 'FUN_COMPANY_SETUP'],
                    pageTitle: 'autopsApp.booking.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/booking/bookings.html',
                        controller: 'BookingController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('booking');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('expressParcel');    
                        $translatePartialLoader.addPart('opsCost');
                        $translatePartialLoader.addPart('stats');     
                        return $translate.refresh();
                    }]
                }
            }).state('booking-fms', {
                parent: 'ops',
                url: '/fms-shipments',
                data: {
                    authorities: ['ROLE_CLIENT', 'FUN_COMPANY_SETUP'],
                    pageTitle: 'autopsApp.booking.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/booking/bookings.html',
                        controller: 'BookingController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('booking');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('expressParcel');    
                        $translatePartialLoader.addPart('opsCost');
                        $translatePartialLoader.addPart('stats');     
                        return $translate.refresh();
                    }]
                }
            }).state('booking-wms', {
                parent: 'ops',
                url: '/wms-shipments',
                data: {
                    authorities: ['ROLE_CLIENT', 'FUN_COMPANY_SETUP'],
                    pageTitle: 'autopsApp.booking.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/booking/bookings.html',
                        controller: 'BookingController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('booking');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('expressParcel');    
                        $translatePartialLoader.addPart('opsCost');
                        $translatePartialLoader.addPart('stats');     
                        return $translate.refresh();
                    }]
                }
            }).state('booking-fba', {
                parent: 'ops',
                url: '/fba-shipments',
                data: {
                    authorities: ['ROLE_CLIENT', 'FUN_COMPANY_SETUP'],
                    pageTitle: 'autopsApp.booking.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/booking/bookings.html',
                        controller: 'BookingController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('booking');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('wmsIn');
                        $translatePartialLoader.addPart('expressParcel');    
                        $translatePartialLoader.addPart('opsCost');
                        $translatePartialLoader.addPart('stats');     
                        return $translate.refresh();
                    }]
                }
            });
    }
})();
