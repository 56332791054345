(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsLogDialogController', WmsLogDialogController);

    WmsLogDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', '$translate',
        'entity', 'WmsLog', 'uiGridConstants', 'WmsWarehouse', 'WmsCargo'
    ];

    function WmsLogDialogController($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, $translate,
        entity, WmsLog, uiGridConstants, WmsWarehouse, WmsCargo) {
        var vm = this;
        vm.searchEntity = entity;
        vm.clear = clear;
        vm.getWmsLogs = getWmsLogs;
        vm.briefWmsInOutLogs = briefWmsInOutLogs;
        vm.correctInventory = correctInventory;
        vm.forcedAdjustment = forcedAdjustment;

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }
        $scope.gridOptions = {
            enableSorting: true, //排序
            useExternalSorting: false,

            enableGridMenu: true, //表格 菜单  
            showGridFooter: false, //表格的footer  
            showColumnFooter: true, //每列footer

            enableFiltering: true, //高级过滤            

            enableHorizontalScrollbar: 1, //表格的水平滚动条  
            enableVerticalScrollbar: 1, //表格的垂直滚动条 (两个都是 1-显示,0-不显示)  

            exporterMenuCsv: false,
            exporterMenuPdf: false,
            exporterMenuLabel: "Export",
            exporterExcelFilename: $translate.instant('autopsApp.wmsCargo.detail.logs') + '.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns: ['id'],
            minimumColumnSize: 70,
            columnDefs: [{
                    field: 'wmsInNum',
                    displayName: $translate.instant('autopsApp.wmsCargo.wmsInNum'),
                    minWidth: 200
                },
                {
                    field: 'availableChange',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsCargo.availableChange'),
                    cellTemplate: '<strong class="grid-text text-primary">{{row.entity.availableChange}}</strong>',
                    minWidth: 100
                },
                {
                    field: 'inventoryChange',
                    aggregationType: uiGridConstants.aggregationTypes.sum,
                    displayName: $translate.instant('autopsApp.wmsCargo.inventoryChange'),
                    cellTemplate: '<strong class="grid-text text-primary">{{row.entity.inventoryChange}}</strong>',
                    minWidth: 100
                },
                {
                    field: 'sku',
                    displayName: "SKU",
                    minWidth: 200
                },
                {
                    field: 'actTime',
                    displayName: $translate.instant('autopsApp.wmsCargo.actTime'),
                    minWidth: 200,
                    cellTemplate: '<span class="grid-text">{{row.entity.actTime | date:\'medium\'}}</span>'
                },
                {
                    field: 'actType',
                    filter: {
                        type: uiGridConstants.filter.SELECT,
                        selectOptions: $rootScope.WMS_ACTION_TYPES
                    },
                    displayName: $translate.instant('autopsApp.wmsCargo.actionType'),
                    cellTemplate: '<span class="grid-text"><span translate="autopsApp.wmsCargo.action.{{row.entity.actType}}" ng-if="row.entity.actType"></span></span>'
                },
                {
                    field: 'location',
                    displayName: $translate.instant('autopsApp.wmsCargo.location'),
                    minWidth: 100
                },
                {
                    field: 'wmsOutNum',
                    displayName: $translate.instant('autopsApp.wmsCargo.refNum'),
                    minWidth: 100
                },
                {
                    field: 'cargoCode',
                    displayName: $translate.instant('autopsApp.wmsCargo.code'),
                    aggregationType: uiGridConstants.aggregationTypes.count
                },
                {
                    field: 'register',
                    displayName: $translate.instant('autopsApp.wmsCargo.register'),
                    minWidth: 100
                }
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('entity.action.exportVisExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = angular.copy($scope.gridOptions);
                    jsonObj.gridOptions.data = [];
                    var allvisiblerows = $scope.gridApi.core.getVisibleRows($scope.gridApi.grid);
                    angular.forEach(allvisiblerows, function(row){
                        jsonObj.gridOptions.data.push(row.entity);
                    })
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = true;
                    jsonObj.dataType = "wmsLog";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 0
            }, {
                title: $translate.instant('entity.action.exportAllExcel'),
                action: function($event) {
                    var jsonObj = {};
                    jsonObj.gridOptions = $scope.gridOptions;
                    jsonObj.saveState = $scope.gridApi.saveState.save();
                    jsonObj.visibleOnly = false;
                    jsonObj.dataType = "wmsLog";
                    $rootScope.EXPORT_GRID_DATA(jsonObj, $scope.gridOptions.exporterExcelFilename);
                },
                order: 1
            }],
            onRegisterApi: function(gridApi) {
                $scope.gridApi = gridApi;
            }
        }
        vm.isBrief = false;

        function getWmsLogs() {
            vm.isBrief = false;
            if (!vm.searchEntity.wmsCargoId && (!vm.searchEntity[vm.searchEntity.filterType] || !vm.searchEntity.actTimeFrom)) {
                layer.msg($translate.instant('autopsApp.wmsCargo.filterNullAlert'));
                return;
            }
            var loadIndex = layer.load(1, { shade: 0.3 });
            WmsLog.getWmsLogs(vm.searchEntity, function(data) {
                $scope.gridOptions.data = data;
                layer.close(loadIndex);
            }, function() {
                layer.close(loadIndex);
            });
        }

        function briefWmsInOutLogs() {
            vm.isBrief = true;
            var loadIndex = layer.load(1, { shade: 0.3 });
            WmsLog.briefWmsInOutLogs({ wmsCargoId: vm.searchEntity.wmsCargoId }, function(data, headers) {
                $scope.gridOptions.data = data;
                layer.close(loadIndex);
                if (headers('X-autopsApp-i18n')) {
                    $rootScope.OPS_ALERT($translate.instant(headers('X-autopsApp-i18n')));
                }
            }, function() {
                layer.close(loadIndex);
            });
        }

        function correctInventory() {
            vm.isBrief = true;
            var loadIndex = layer.load(1, { shade: 0.3 });
            WmsLog.correctInventory({ wmsCargoId: vm.searchEntity.wmsCargoId }, function(data) {
                briefWmsInOutLogs();
                layer.close(loadIndex);
            }, function() {
                layer.close(loadIndex);
            });
        }

        vm.gateInTime = null;
        $timeout(function() {
            if (vm.searchEntity.wmsCargoId) {
                briefWmsInOutLogs();
                WmsCargo.get({ id: vm.searchEntity.wmsCargoId }, function(result) {
                    vm.gateInTime = result.gateInTime;
                })
            } else {
                vm.searchEntity.filterType = "sku";
            }
        });

        function forcedAdjustment() {
            if ($rootScope.account.authorities.indexOf("AU_WMS_AMENDMENT") === -1) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_WMS_AMENDMENT');    
                return;
            }
            layer.prompt({
                formType: 0,
                value: null,
                title: $translate.instant('autopsApp.wmsIn.forcedAdjustmentTips'),
                area: ['280px', '160px']
            }, function(value, index, elem) {
                value = value - 0;
                if (isNaN(value) || parseInt(value) != value || !value) {
                    layer.msg($translate.instant('autopsApp.wmsIn.notNumAlert'));
                    return;
                }
                var loadIndex = layer.load(1, { shade: 0.3 });
                WmsCargo.forcedAdjustment({
                    moveAmt: value,
                    wmsCargoId: vm.searchEntity.wmsCargoId
                }, function(result) {
                    layer.close(loadIndex);
                    briefWmsInOutLogs();
                });
                layer.close(index);
            });
        }
    }
})();
