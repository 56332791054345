(function () {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CustomizeEmailDialogController', CustomizeEmailDialogController);

    CustomizeEmailDialogController.$inject = ['$rootScope', '$scope', '$uibModalInstance', 'entity', 'GridState', '$timeout', '$translate'];

    function CustomizeEmailDialogController($rootScope, $scope, $uibModalInstance, entity, GridState, $timeout, $translate) {
        var vm = this;

        $scope.gridState = entity;
        vm.clear = clear;
        vm.save = save;
        vm.reloadGridState = reloadGridState;
        vm.insertAtCursor = insertAtCursor;
        vm.changeTemplateTitleFocus = changeTemplateTitleFocus;
        vm.changeTemplateFocus = changeTemplateFocus;
        vm.templateTitleFocus = false;
        vm.templateFocus = false;
        vm.templateTxt = "";
        vm.templateTitleTxt = "";
        // 变量列表，在此处添加后，需要在后端DataUtil.parseEmailTemplate()方法添加取值。（不用反射机制是防止用户自己填未开放的变量）
        vm.varList = ["time", "jobNum", "porId", "por", "polId", "pol", "podId", "pod", "destId", "dest", "deliveryToId", "deliveryTo",
        "HBLporId", "HBLpor", "HBLpolId", "HBLpol", "HBLpodId", "HBLpod", "HBLdestId", "HBLdest", "HBLdeliveryToId", "HBLdeliveryTo","vsl", "voy", "volume", "atd", "ctn", "ata", "etd", "eta",
         "deliveryAppointmentTime", "ctnAvailableTime", "deliveryTime", "transferTime", "expressTracking",
         "carrier", "bookingEtd", "cargoReadyDate", "bookingRemarks", "mblNum", "hblNum", "poNum", "dock",
         "cls", "siCutTime", "serviceTerm", "freightTerm", "HBLfreightTerm", "feederVsl", "feederVoy", "feederEtd", "soNum", "customerName", "deliveryBlCompanyName", "shipperBlCompanyName",
         "cneeBlCompanyName", "hblRlsWay", "releaseWay", "goodsDiscription", "HBLGoodsDiscription", "guideFclCtns", "cntrNo", "cntrNum", "SOC", "HBLJZT"];

        $timeout(function (){
            try {
                var jsonObject = JSON.parse($scope.gridState.gridState);
                vm.templateTxt = jsonObject.templete;
                vm.templateTitleTxt = jsonObject.templeteTitle;
            }
            //旧模版内容
            catch (e){
                vm.templateTxt = $scope.gridState.gridState;
            }
        })
        function reloadGridState(gridStateId) {
            if (!gridStateId) return;
            GridState.get({ id: gridStateId }, function (result) {
                $scope.gridState = result;
            }, $rootScope.ALERT_ERROR)
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            $scope.gridState.companyId = $rootScope.account.companyId;
            $scope.gridState.gridState = JSON.stringify({
                "templeteTitle": vm.templateTitleTxt,
                "templete": vm.templateTxt
            })
            GridState.saveCustomRecord($scope.gridState, function (result) {
                layer.msg($translate.instant('global.save_success'));
                reloadGridState(result.id);
            }, $rootScope.ALERT_ERROR)
        }

        function insertAtCursor(myValue) {
            var myField;
            if (vm.templateTitleFocus){
                myField = document.getElementById('field_templateTitle');
            }else {
                myField = document.getElementById('field_template');
            }
            //IE support
            if (document.selection) {
                myField.focus();
                sel = document.selection.createRange();
                sel.text = myValue;
                sel.select();
            }
            //MOZILLA/NETSCAPE support
            else if (myField.selectionStart || myField.selectionStart == '0') {
                var startPos = myField.selectionStart;
                var endPos = myField.selectionEnd;
                // save scrollTop before insert
                var restoreTop = myField.scrollTop;
                myField.value = myField.value.substring(0, startPos) + myValue + myField.value.substring(endPos, myField.value.length);
                if (restoreTop > 0) {
                    // restore previous scrollTop
                    myField.scrollTop = restoreTop;
                }
                myField.focus();
                myField.selectionStart = startPos + myValue.length;
                myField.selectionEnd = startPos + myValue.length;
            } else {
                myField.value += myValue;
                myField.focus();
            }
            if (vm.templateTitleFocus){
                vm.templateTitleTxt  = document.getElementById("field_templateTitle").value;
            }
            else {
                vm.templateTxt = document.getElementById("field_template").value;
            }
            // $scope.gridState.gridState = document.getElementById("field_template").value;
        }

        function changeTemplateTitleFocus(){
            vm.templateTitleFocus = true;
            vm.templateFocus = false;
        }

        function changeTemplateFocus(){
            vm.templateTitleFocus = false;
            vm.templateFocus = true;
        }
    }
})();
