(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ClerkManagementController', ClerkManagementController);

    ClerkManagementController.$inject = ['$rootScope', '$state', '$scope', '$translate', '$timeout',
    'Principal', 'User', 'ParseLinks', 'JhiLanguageService', 'Account', 'OpsCompany'];

    function ClerkManagementController($rootScope, $state, $scope, $translate, $timeout,
        Principal, User, ParseLinks, JhiLanguageService, Account, OpsCompany) {
        $scope.users = [];
        JhiLanguageService.getAll().then(function (languages) {
            $scope.languages = languages;
        });
        $scope.currentSearch = false;
		var filterType = "actived";
        $scope.filterSet = function(type) {
            filterType = type;
            $scope.loadAll();
        };

        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };
        $scope.page = 1;
        $scope.pageSize = 20;
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.loadAll = function () {
            $scope.currentSearch = false;
            User.query({
                type: filterType,
                page: $scope.page - 1,
                size: 20,
                sort: [$scope.predicate + ',' + ($scope.reverse ? 'desc' : 'asc'), 'id']
            }, function (result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.users = result;
            });
        };
        $scope.search = function () {
            $scope.currentSearch = true;
            User.search({query: $scope.searchQuery}, function(result) {
                $scope.users = result;
            }, function(response) {
                if(response.status === 404) {
                    $scope.loadAll();
                }
            });
        };
        $scope.loadPage = function (page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();

        $scope.setActive = function (user, isActivated) {
            if (user.role == "boss"){
                return;
            }
            user.activated = isActivated;
            User.update(user, function () {
                $scope.loadAll();
            }, function(error){
                $scope.loadAll();
                $rootScope.ALERT_ERROR(error);
            });
        };
        $scope.setIsSuper = function (user, isSuper) {
            if ($rootScope.HAVENOAUTH('AU_GROUP_AUTHORIY')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_GROUP_AUTHORIY');
                return;
            }
            user.isSuper = isSuper;
            User.update(user, function () {
                $scope.loadAll();
            }, function(error){
                $scope.loadAll();
                $rootScope.ALERT_ERROR(error);
            });
        };
        // $scope.clear = function () {
        //     $scope.user = {
        //         id: null, login: null, firstName: null, lastName: null, email: null,
        //         activated: null, langKey: null, createdBy: null, createdDate: null,
        //         lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
        //         resetKey: null, authorities: null
        //     };
        //     $scope.editForm.$setPristine();
        //     $scope.editForm.$setUntouched();
        // };

        $scope.edit = function (user) {
            $rootScope.num = user.login;;
            $state.go('clerk-management.edit');

        }

        $scope.delete = function (user) {
            if (user.role == "boss"){
                return;
            }
            $rootScope.num = user.login;;
            $state.go('clerk-management.delete');
        }
        $scope.tableHeightStyle = {};
        $timeout(function (){
            var footerHeight = 0;
            if(document.getElementById("footPager")){
                document.getElementById("footPager").style.cssText="margin: 0px;";
                footerHeight = $("#"+"footPager").height();
            }
            var heightPx = $(window).height() - $("#basicTable").offset().top -footerHeight -1;
            $scope.tableHeightStyle = {
              "max-height" : heightPx + "px"
            }
            OpsCompany.get({
                id: $rootScope.account.companyId
            }, function(result) {
                $scope.isGroup = result.groupId;
            });

        });

        $scope.exportUsers = function(){
            var fileName = $translate.instant('user-management.userFileName') + ".xlsx";
            layer.load(1, { shade: 0.3 });
            User.getAuthStrs(function(result){
                var authorities = result;
                angular.forEach(authorities, function(data){
                    if(!data.value){
                        if(data.key.indexOf("SS_") == 0){
                            data.value = $translate.instant('user-management.subSys.' + data.key);
                        }
                        else {
                            data.value = $translate.instant('user-management.' + data.key);
                        }
                    }
                })
                User.exportUsers(authorities, function(result){
                    layer.closeAll();
                    $rootScope.DOWNLOADEXCEL(result, fileName);
                }, $rootScope.ALERT_ERROR)
            })
        }
    }
})();
