(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsItemDeleteController', OpsItemDeleteController);

    OpsItemDeleteController.$inject = ['$rootScope', '$scope', '$uibModalInstance', '$translate', 'entity', 'OpsItem'];
    
	function OpsItemDeleteController($rootScope, $scope, $uibModalInstance, $translate, entity, OpsItem) {

        $scope.opsItem = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        var onSaveSuccess = function (result) {
            $uibModalInstance.close(true);
            layer.msg($translate.instant('global.delete_success'));
        };

        var onSaveError = function (result) {            
            if (result.status === 403) {
                $uibModalInstance.dismiss();
                $rootScope.OPS_ALERT($translate.instant('global.forbidden'), {
                    icon: 0, 
                    title :$translate.instant('global.alert_title'),
                    btn: [$translate.instant('global.ok_answer')]
                })
            }            
        };
        $scope.confirmDelete = function (id) {
            OpsItem.delete({id: id}, onSaveSuccess, onSaveError);
        };

    }
})();
