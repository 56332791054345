(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsInDialogController', WmsInDialogController);

    WmsInDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'AlertService', '$translate', 'ScanBoxService'];

    function WmsInDialogController ($timeout, $scope, $uibModalInstance, AlertService, $translate, ScanBoxService) {
        var vm = this;

        vm.code = '';
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){angular.element('#field_manualBarCode').focus();});

        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            if(vm.code){
                $uibModalInstance.close(vm.code);
            }
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        $uibModalInstance.opened.then(function(){
            $timeout(function(){
                ScanBoxService.startScan().then(function(resolve){
                },function(reason){
                    layer.msg($translate.instant('autopsApp.wmsIn.notSupported'), {
                        zIndex: 10000
                    });
                    ScanBoxService.endScan();
                    // ScanBoxService.endAnalysis();
                },function(notify){
                    $uibModalInstance.close(notify.code);                  
                });
            },200);
        });
        
        $uibModalInstance.closed.then(function(){
            ScanBoxService.endScan();
        });
        
        $(document).keydown(function(event){ 
            if(event.keyCode == 13 && vm.code){
                $uibModalInstance.close(vm.code); 
            }
        });
        
    }
})();
