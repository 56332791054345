(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsLocationDialogController', WmsLocationDialogController);

    WmsLocationDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', 'entity', 'Principal', 'WmsLocation'];

    function WmsLocationDialogController ($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, entity, Principal, WmsLocation) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };
        var vm = this;

        vm.wmsLocation = entity;
        vm.clear = clear;
        vm.save = save;
        vm.updateCode = updateCode;
        vm.areas = [];

        function getAreas () {
            WmsLocation.getAllAreas({}, onSuccess, onError);
            function onSuccess(data) {
                vm.areas = data;
            }
            function onError(error) {

            }
        }
        getAreas();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            vm.wmsLocation.companyId = $rootScope.account.companyId;
            if (vm.wmsLocation.id !== null) {
                WmsLocation.update(vm.wmsLocation, onSaveSuccess, onSaveError);
            } else {
                WmsLocation.save(vm.wmsLocation, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('autopsApp:wmsLocationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        function updateCode (areaId) {
            for (var i = 0; i < vm.areas.length; i++){
                if (areaId == vm.areas[i].id){
                    vm.wmsLocation.warehouse = vm.areas[i].warehouse;
                    vm.wmsLocation.areaName = vm.areas[i].code;                    
                    return;
                }
            }
        }

    }
})();
