(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('UnAccountingController', UnAccountingController);

    UnAccountingController.$inject = ['$scope', '$rootScope', '$translate', '$window', 'Principal', 'DateUtils','AccountVoucher'];
    
    function UnAccountingController($scope, $rootScope, $translate, $window, Principal, DateUtils, AccountVoucher) {
        $scope.filterDTO = {};
        var checkAuth = function(){
            if ($rootScope.HAVENOAUTH('ROLE_DEPARTMENT')){            
                $window.history.back();
                layer.msg($translate.instant('global.forbiddenTeam'));
            }
            $scope.filterDTO.dateFrom = DateUtils.convertLocalDateFromServer($rootScope.account.accountDate);
        }
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;                
                checkAuth();
            })
        }
        else {
            checkAuth();
        }

        $scope.datePickerForEtd = {};
        $scope.datePickerForEtd.status = {
            opened: false
        };
        $scope.datePickerForEtdOpen = function($event) {
            $scope.datePickerForEtd.status.opened = true;
        };
        
        $scope.isSaving = false;

        var onSuccess = function(result) {
            layer.closeAll(); 
            $rootScope.OPS_ALERT($translate.instant('autopsApp.accountTitle.antiAccountingSuccess'));
        };

        var onError = function(result) {
            layer.closeAll(); 
            // $rootScope.OPS_ALERT($translate.instant('autopsApp.accountTitle.onAccountingError'));
        };
        
        $scope.antiAccounting = function () {    
            if($rootScope.ISBETA()){
                layer.msg($translate.instant('global.betaAlert'));
                return;
            }
            layer.load(1, {shade: 0.3});
            $scope.isSaving = true;
            $scope.filterDTO.companyId = $rootScope.account.companyId;
            AccountVoucher.antiBookingkeeping($scope.filterDTO, onSuccess, onError);
        }




    }
})();