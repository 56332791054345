(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('EdiInsuranceDeleteController',EdiInsuranceDeleteController);

    EdiInsuranceDeleteController.$inject = ['$uibModalInstance', '$translate', '$timeout', 'entity', 'EdiInsurance'];

    function EdiInsuranceDeleteController($uibModalInstance, $translate, $timeout,entity, EdiInsurance) {
        var vm = this;

        vm.ediInsurance = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        $timeout(function (){            
            if(vm.ediInsurance.status != '0'){
                $rootScope.OPS_ALERT($translate.instant("autopsApp.ediInsurance.dedeteAlert"));
                $uibModalInstance.dismiss('cancel');
            }
        });

        function confirmDelete (id) {
            EdiInsurance.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
