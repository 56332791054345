(function() {
    'use strict';

    angular
        .module('autopsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('opsUser', {
                parent: 'admin',
                url: '/opsUsers',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'autopsApp.opsUser.home.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/opsUser/opsUsers.html',
                        controller: 'OpsUserController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsUser');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('opsUser.detail', {
                parent: 'admin',
                url: '/opsUser/{id}',
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'autopsApp.opsUser.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/opsUser/opsUser-detail.html',
                        controller: 'OpsUserDetailController'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('opsUser');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'OpsUser', function($stateParams, OpsUser) {
                        return OpsUser.get({id : $stateParams.id});
                    }]
                }
            })
            .state('opsUser.new', {
                parent: 'opsUser',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/opsUser/opsUser-dialog.html',
                        controller: 'OpsUserDialogController',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    companyName: null,
                                    address: null,
                                    login: null,
                                    name: null,
                                    mob: null,
                                    tel: null,
                                    email: null,
                                    createdTime: null,
                                    isClient: null,
                                    remarks: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function(result) {
                        $state.go('opsUser', null, { reload: true });
                    }, function() {
                        $state.go('opsUser');
                    })
                }]
            })
            .state('opsUser.edit', {
                parent: 'opsUser',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/opsUser/opsUser-dialog.html',
                        controller: 'OpsUserDialogController',
                        size: 'lg',
                        resolve: {
                            entity: ['OpsUser', function(OpsUser) {
                                return OpsUser.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('opsUser', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            })
            .state('opsUser.delete', {
                parent: 'opsUser',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_ADMIN'],
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/opsUser/opsUser-delete-dialog.html',
                        controller: 'OpsUserDeleteController',
                        size: 'md',
                        resolve: {
                            entity: ['OpsUser', function(OpsUser) {
                                return OpsUser.get({id : $stateParams.id});
                            }]
                        }
                    }).result.then(function(result) {
                        $state.go('opsUser', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    })
                }]
            });
    }
})();
