(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('VgmCtnController', VgmCtnController);

    VgmCtnController.$inject = ['$scope', '$state', 'VgmCtn', 'VgmCtnSearch'];

    function VgmCtnController ($scope, $state, VgmCtn, VgmCtnSearch) {
        var vm = this;

        vm.vgmCtns = [];
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            VgmCtn.query(function(result) {
                vm.vgmCtns = result;
                vm.searchQuery = null;
            });
        }

        function search() {
            if (!vm.searchQuery) {
                return vm.loadAll();
            }
            VgmCtnSearch.query({query: vm.searchQuery}, function(result) {
                vm.vgmCtns = result;
                vm.currentSearch = vm.searchQuery;
            });
        }

        function clear() {
            vm.searchQuery = null;
            loadAll();
        }
    }
})();
