    (function() {
        'use strict';

        angular
            .module('autopsApp')
            .controller('BalanceSheetController', BalanceSheetController);

        BalanceSheetController.$inject = ['$scope', '$rootScope', '$window', '$translate', 'Principal', 'OpsFiles', 'DateUtils', 'DataUtils'];

        function BalanceSheetController($scope, $rootScope, $window, $translate, Principal, OpsFiles, DateUtils, DataUtils) {

            if (!$rootScope.account || angular.equals($rootScope.account, {})) {
                Principal.identity().then(function(account) {
                    $rootScope.account = account;
                })
            }

            $scope.isSaving = false;
            $scope.balanceSheet = {};
            $scope.balanceSheet.balanceSheetList = [];
            $scope.accountMonth = null;
            var reportavailalbe = false;
            $scope.getContetns = function() {
                if (!$scope.accountMonth) {
                    layer.msg($translate.instant('global.messages.invalidDate'));
                    return;
                }
                $scope.isSaving = true;
                reportavailalbe = false;
                OpsFiles.getBalanceSheet({
                    dateFrom: DateUtils.convertLocalDateToServer($scope.accountMonth),
                    beginningDate: DateUtils.convertLocalDateToServer($scope.beginningDate)
                }, function(result) {
                    $scope.isSaving = false;
                    $scope.balanceSheet = result;
                    if (!$scope.balanceSheet || $scope.balanceSheet.balanceSheetList.length == 0) {
                        layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                        return;
                    }
                    for (var i = 0; i < $scope.balanceSheet.balanceSheetList.length; i++) {
                        $scope.balanceSheet.balanceSheetList[i].assetsEndingBalance = DataUtils.formatTwoDigits($scope.balanceSheet.balanceSheetList[i].assetsEndingBalance);
                        $scope.balanceSheet.balanceSheetList[i].assetsYearBegining = DataUtils.formatTwoDigits($scope.balanceSheet.balanceSheetList[i].assetsYearBegining);
                        $scope.balanceSheet.balanceSheetList[i].liabilitiesEndingBalance = DataUtils.formatTwoDigits($scope.balanceSheet.balanceSheetList[i].liabilitiesEndingBalance);
                        $scope.balanceSheet.balanceSheetList[i].liabilitiesYearBegining = DataUtils.formatTwoDigits($scope.balanceSheet.balanceSheetList[i].liabilitiesYearBegining);
                    }
                    reportavailalbe = true;
                }, function(result) {
                    $scope.isSaving = false;
                });
            }

            var initContent = function() {
                for (var j = 0; j <= 20; j++) {
                    $scope.balanceSheet.balanceSheetList.push({});
                }
            }

            initContent();
            $scope.exportReport = function(format) {
                if(!reportavailalbe){
                    layer.msg($translate.instant('global.noRecord'));
                    return;
                }
                OpsFiles.generateReportBalanceSheet({
                    fileType: format,
                    reportType: 380,
                }, $scope.balanceSheet, function(result) {
                    $rootScope.PDFPRINTEXCELDOWNLOAD(result, format, 'balance_sheet');     
                }, function(error) {
                    layer.msg($translate.instant('autopsApp.accountTitle.viewAccountTitleFail'));
                });
            }
            $scope.monthFormat = "yyyy-MM";
            $scope.datepickerOptions = {
                minMode: 'month'
            }

            $scope.datePickerForEtd = {};
            $scope.datePickerForEtd.status = {
                opened: false
            };
            $scope.datePickerForEtdOpen = function($event) {
                $scope.datePickerForEtd.status.opened = true;
            };

            $scope.datePickerForBg = {};
            $scope.datePickerForBg.status = {
                opened: false
            };
            $scope.datePickerForBgOpen = function($event) {
                $scope.datePickerForBg.status.opened = true;
            };

            $scope.pgsBalanceSheet = function(type) {
                if (!$scope.accountMonth) {
                    layer.msg($translate.instant('global.messages.invalidDate'));
                    return;
                }
                layer.confirm("科目项金额为 0 的不显示", {
                    icon: 3,
                    btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')]
                }, function(index) {
                    layer.close(index);                      
                    pgsBalanceSheetExport(type, true);
                }, function() {
                    pgsBalanceSheetExport(type, false);
                });

            }           
            var pgsBalanceSheetExport = function(type, delZero) {
                layer.load(1, {shade: 0.3});
                OpsFiles.pgsBalanceSheet({
                    delZero: delZero,
                    type: type,
                    companyId: $rootScope.account.companyId,
                    accountMonth: DateUtils.convertLocalDateToServer($scope.accountMonth)
                }, function(result) {
                    layer.closeAll(); 
                    $rootScope.DOWNLOADEXCEL(result, "balance_sheet.xlsx");
                }, function(result) {
                    layer.closeAll(); 
                })
            }
        }
    })();
