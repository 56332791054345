(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsCostDialogController', OpsCostDialogController);

    OpsCostDialogController.$inject = ['$rootScope', '$scope', '$stateParams', '$uibModalInstance', 'OpsPorts', 'entity', 'OpsCost'];

    function OpsCostDialogController ($rootScope, $scope, $stateParams, $uibModalInstance, OpsPorts, entity, OpsCost) {
        var vm = this;

        vm.costFilter = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.ports = {};
        vm.openCalendar = openCalendar;
        vm.advanceSearch = advanceSearch;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function getPorts () {
            OpsCost.getAllPorts({
                type: vm.costFilter.type,
                companyId: vm.costFilter.companyId
            }, function(result){
                vm.ports = result;
            })
        }
        getPorts();
        function advanceSearch () {
            if(vm.costFilter.showAll){
                vm.costFilter.uploadedBy = null;
            }    
            else {
                vm.costFilter.uploadedBy = $rootScope.account.id;
            }                    
            vm.isSaving = true;
            OpsCost.advanceSearch(vm.costFilter, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.effectiveDateFrom = false;
        vm.datePickerOpenStatus.effectiveDateTo = false;
        vm.datePickerOpenStatus.expireDateFrom = false;
        vm.datePickerOpenStatus.expireDateTo = false;
        vm.datePickerOpenStatus.createdTimeFrom = false;
        vm.datePickerOpenStatus.createdTimeTo = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        

    }
})();
