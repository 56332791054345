(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsConcernLaneDetailController', OpsConcernLaneDetailController);

    OpsConcernLaneDetailController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$translate', '$timeout', 
    'entity', 'OpsCost'];

    function OpsConcernLaneDetailController($scope, $rootScope, $uibModalInstance, $translate, $timeout, 
        entity, OpsCost) {
        var vm = this;

        vm.jsEntity = entity;
        vm.clear = clear;
        vm.doDebug = doDebug;
        vm.isSaving = false;
        $timeout(function (){
            vm.bizTypeTip = "";
            angular.forEach($rootScope.BIZTYPES, function(item) {
                if(item.value){
                    vm.bizTypeTip += (item.value + "=" + item.label + " ");
                }
            })
            vm.clientTypeTip = "";
            angular.forEach($rootScope.CLIENT_TYPES, function(item) {
                if(item.value){
                    vm.clientTypeTip += (item.value + "=" + item.label + " ");
                }
            })
            vm.clientSourceTip = "";
            angular.forEach($rootScope.CLIENT_SOURCES, function(item) {
                if(item.value){
                    vm.clientSourceTip += (item.value + "=" + item.label + " ");
                }
            })
        })
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function doDebug () {   
            vm.isSaving = true;  
            OpsCost.jsDebug({
                type: vm.jsEntity.jsType
            }, vm.jsEntity, onSaveSuccess, onSaveError);
        }
        vm.debugOutput = null;
        function onSaveSuccess (result) {
            vm.isSaving = false;
            vm.debugOutput = result.debugOutput;     
            $translate.refresh();       
        }
        function onSaveError (result) {
            vm.isSaving = false;
            layer.msg($translate.instant('global.requestFail'));            
        }
    }
})();
