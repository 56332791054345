(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsAreaController', WmsAreaController);

    WmsAreaController.$inject = ['$timeout', '$scope', '$rootScope', '$stateParams', '$uibModalInstance', 
    'entity', 'WmsCargo'];

    function WmsAreaController ($timeout, $scope, $rootScope, $stateParams, $uibModalInstance, 
        entity, WmsCargo) {
        var vm = this;

        vm.clear = clear;
        vm.save = save;
        vm.dataParse = dataParse;

        $timeout(function (){
            angular.element('#excelTxt_field').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            if(!vm.pickingList || vm.pickingList.length == 0){
                return;
            }
            layer.load(1, {shade: 0.3});
            WmsCargo.excelPickingCargo({
                venderId: entity.venderId,
                warehouseId: entity.warehouseId,
                wmsInId: entity.wmsInId,
                expressId: entity.expressId,
                guideId: entity.guideId,
                locationType: entity.locationType
            }, vm.pickingList, onSaveSuccess, $rootScope.ALERT_ERROR);
        }

        function onSaveSuccess (result) {            
            layer.closeAll();             
            $uibModalInstance.close(result);
            layer.msg($translate.instant('autopsApp.wmsIn.excelPickingSuccess'));
        }

        vm.pickingList = [];
        function dataParse () {
            if(!vm.excelTxt){
                return;
            }
            vm.pickingList = [];
            var rows = vm.excelTxt.split("\n");
            for(var i = 0; i < rows.length; i++){
                if(!rows[i]){
                    continue;
                }
                var cols = rows[i].split("\t");
                if(cols.length >= 2){
                    var newPicking = {
                        sku: cols[0],
                        count: cols[1]
                    }
                    vm.pickingList.push(newPicking);
                }

            }

        }

    }
})();
