(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WriteOffRecordsDialogController', WriteOffRecordsDialogController);

    WriteOffRecordsDialogController.$inject = ['$timeout', '$rootScope', '$scope', '$stateParams', '$uibModalInstance', 
    'Principal', 'JhiLanguageService', 'AccountTitle', 'entity', 'WriteOff', 'OpsVender', 'DataUtils'];

    function WriteOffRecordsDialogController ($timeout, $rootScope, $scope, $stateParams, $uibModalInstance, 
        Principal, JhiLanguageService, AccountTitle, entity, WriteOff, OpsVender, DataUtils) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };
        $scope.searchParams = entity;
        var vm = this;
        vm.clear = clear;
        var lang = "";
        JhiLanguageService.getCurrent().then(function(language) {
            lang = language;
        });
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        $scope.datePickerOpenStatus = {};
        $scope.dayFormat = "yyyy-MM-dd";
        $scope.dayDatepickerOptions = {
            minMode: 'day'
        }
        $scope.datePickerOpenStatus.dateFromPicker = false;
        $scope.datePickerOpenStatus.dateToPicker = false;

        $scope.openCalendar = function(date) {
            $scope.datePickerOpenStatus[date] = true;
        }        

        $scope._onClientSelected = function(data) {
            $scope.searchParams.payerVid = data.id;
        }

        $scope.setDnCn = function(data) {
            $scope.searchParams.isDn = data;
        }
        $scope.setAudited = function(data) {
            $scope.searchParams.audited = data;
        }
        $scope.subLedgers = [];
        $scope.loadDefultLedger = function() {
            if ($scope.subLedgers && $scope.subLedgers.length > 0) {
                return;
            }            
            AccountTitle.getAll({
                type: "part"
            }, function(result) {
                $scope.subLedgers = result;
                $scope.subLedgers = DataUtils.generateShowLedger($scope.subLedgers, true, lang);
            });
        }

        $scope._onLedgerChoosed = function(data) {
            $scope.searchParams.accountTitleId = data.id;
        }

        $scope.searchRequests = function() {
            layer.load(1, { shade: 0.3 });
            WriteOff.advanceSearch($scope.searchParams, function(result) {
                layer.closeAll();
                $uibModalInstance.close(result);
            }, $rootScope.ALERT_ERROR);  
        };
    }
})();
