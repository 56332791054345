(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsOutDeleteController',WmsOutDeleteController);

    WmsOutDeleteController.$inject = ['$uibModalInstance', '$translate', 'entity', 'WmsWarehouse'];

    function WmsOutDeleteController($uibModalInstance, $translate, entity, WmsWarehouse) {
        var vm = this;

        vm.expressZone = entity.expressZone;
        vm.clear = clear;
        vm.saveExpressZone = saveExpressZone;
        vm.getExpressZones = getExpressZones;
        
        function clear () {
            $uibModalInstance.close(vm.expressZone);
        }

        function saveExpressZone (id) {
            var saveEntity = {
                id: entity.id,
                expressZone: vm.expressZone
            };
            WmsWarehouse.saveExpressZone(saveEntity, function(){
                layer.msg($translate.instant('global.save_success'));
            })
        }
        vm.expressZones = {};
        function getExpressZones () {
            vm.expressZones = [];
            if(vm.expressZone){
                var rows = vm.expressZone.split("\n");                
                for(var i = 0; i < rows.length; i++){
                    if(!rows[i]){
                        continue;
                    }
                    var cols = rows[i].split("\t");
                    var newZone = {};
                    if(cols.length == 2){
                        newZone.zip = cols[0];
                        newZone.zone = cols[1];
                    }
                    vm.expressZones.push(newZone);
                }
            }
        }
        getExpressZones();
    }
})();
