(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('AutoCheckParcelController', AutoCheckParcelController);

    AutoCheckParcelController.$inject = ['$scope', '$rootScope', '$translate', '$http', '$timeout', '$state', 'Principal', 
    'AutoCheck', 'DateUtils', 'OpsVender', 'entity', 'AutoCheckItem', 'DataUtils'];

    function AutoCheckParcelController($scope, $rootScope, $translate, $http, $timeout, $state, Principal, 
        AutoCheck, DateUtils, OpsVender, entity, AutoCheckItem, DataUtils) {
        
        var vm = this;

        $scope.autoCheck = entity;
        vm.save = save;
        vm.goBack = goBack;
        vm.delImportedOne = delImportedOne;
        vm.delFaillOne = delFaillOne;
        vm.ratesCopy = [];
        $scope.ratesList = [];        
        $scope.files = [];  
        $scope.delOne = function (rate) {
            delImportedOne (rate);     
        }

        function delImportedOne (checkItem, idx, autoCheckItem) {
            if(checkItem.id){
                AutoCheckItem.delete({id: checkItem.id}, function(result){
                    afterDelImportedOne(checkItem, idx, autoCheckItem);
                }, function(error){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.autoCheck.rateLocked'));                    
                });
            }
            else {
                afterDelImportedOne(checkItem, idx, autoCheckItem);
            } 
        }
        function afterDelImportedOne (checkItem, idx, autoCheckItem) {
            autoCheckItem.autoCheckItems.splice(idx, 1);
            autoCheckItem.balance = DataUtils.round(autoCheckItem.balance + checkItem.amount, 2);
            $scope.autoCheckParcelDTO.balance = DataUtils.round($scope.autoCheckParcelDTO.balance + checkItem.amount, 2);

            autoCheckItem.itemI = DataUtils.round(autoCheckItem.itemI - checkItem.amount, 2);
            $scope.autoCheckParcelDTO.totalI = DataUtils.round($scope.autoCheckParcelDTO.totalI - checkItem.amount, 2);
            initCheckResult();            
        }


        $timeout(function (){
            $scope.refreshParcelCheck();
        });


        $scope._onClientSelected = function(data) {
            $scope.autoCheck.payerVid = data.id;
        }

        $scope.clearImports = function () {
            if($scope.autoCheck.id){
                AutoCheck.deleteAutoCheckItems({id: $scope.autoCheck.id}, function(){
                    $scope.refreshParcelCheck();
                });
            }
            else{
                $scope.autoCheckParcelDTO = {}; 
            }
        }

        function onSaveSuccess (result) {
            $scope.autoCheck = result;
            layer.msg($translate.instant('global.save_success'));
            vm.isSaving = false;
        }

        function onSaveError () {
            layer.msg($translate.instant('global.save_fail'));    
            vm.isSaving = false;
        }

        function goBack () {
            $state.go('parcel-check');          
        }

        function save () { 
            if (!$scope.autoCheck.code) {
                layer.msg($translate.instant('autopsApp.autoCheck.nameNullAlert'));
                return;
            }
            if (!$scope.autoCheck.payer) {
                layer.msg($translate.instant('autopsApp.autoCheck.payerNullAlert'));
                return;
            }
            if ($scope.autoCheck.submited) {
                layer.msg($translate.instant('autopsApp.autoCheck.submitedAlert'));
                return;
            }
            vm.isSaving = true;
            $scope.autoCheck.autoCheckItems = null;
            var idSet = "";
            if($scope.autoCheckParcelDTO){
                angular.forEach($scope.autoCheckParcelDTO.autoCheckItems, function(autoCheckItem){
                    angular.forEach(autoCheckItem.guideRates, function(rate){
                        if(rate.id && rate.id > 0){
                            idSet = idSet + rate.id + ";";
                        }
                    })        
                })
                $scope.autoCheck.checkAmt = $scope.autoCheckParcelDTO.totalB + "(Bal:" + $scope.autoCheckParcelDTO.balance + ") ";
            }
            $scope.autoCheck.idSet = idSet;


            $scope.autoCheck.companyId = $rootScope.account.companyId;
            if ($scope.autoCheck.id !== null) {
                AutoCheck.update($scope.autoCheck, onSaveSuccess, onSaveError);
            } else {
                AutoCheck.save($scope.autoCheck, onSaveSuccess, onSaveError);
            }
        }
        $scope.importBills = function (file) {
            if (!file) {return;}
            if(!file.name || angular.lowercase(file.name).indexOf(".xlsx") == -1){
                $rootScope.OPS_ALERT($translate.instant('global.xlsAlert'));                   
                return;
            }
            layer.load(1, {shade: 0.3});
            var fd = new FormData();
            fd.append('file', file);
            fd.append('autoCheckId', $scope.autoCheck.id);
            $http.post(
                'api/auto-checks/importParcelBills', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {   
                    layer.closeAll(); 
                    $scope.autoCheckParcelDTO = data; 
                    initCheckResult();
                }).error(function(error) {
                    layer.closeAll(); 
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));                           
                });
        };


        $scope.fillGuideRates = function (autoCheckItem, isPay) {
            var autoCheckParcelDTO;
            if(autoCheckItem){
                autoCheckParcelDTO = {};                
                autoCheckParcelDTO.autoCheckItems = [];
                autoCheckParcelDTO.autoCheckItems.push(angular.copy(autoCheckItem));
            }
            else {
                autoCheckParcelDTO = angular.copy($scope.autoCheckParcelDTO);
            }
            autoCheckParcelDTO.isPay = isPay;
            autoCheckParcelDTO.autoCheck = angular.copy($scope.autoCheck);
            layer.load(1, {shade: 0.3});
            AutoCheck.fillGuideRates(autoCheckParcelDTO, function(result){
                layer.closeAll();
                if(result && autoCheckItem){
                    var matched = false;
                    angular.forEach(autoCheckItem.guideRates, function(rate){
                        if(rate.id == result.id){
                            angular.copy(result, rate);
                            matched = true;
                        }
                    })
                    if(!matched){
                        autoCheckItem.guideRates=[];
                        autoCheckItem.guideRates.push(result);
                    }
                    autoCheckItem.itemB = autoCheckItem.itemI;
                    autoCheckItem.balance = 0;
                    initCheckResult();
                }
                else {
                    $scope.refreshParcelCheck();
                }                
            }, function(){
                layer.closeAll(); 
                layer.msg($translate.instant('global.update_fail')); 
            });
        }

        $scope.refreshParcelCheck = function () {
            if(!$scope.autoCheck.id){
                return;
            }
            var autoCheckParcelDTO;
            if($scope.autoCheckParcelDTO){
                autoCheckParcelDTO = angular.copy($scope.autoCheckParcelDTO);
            } 
            else{
                autoCheckParcelDTO = {};
            }
            autoCheckParcelDTO.autoCheck = $scope.autoCheck;
            layer.load(1, {shade: 0.3});
            AutoCheck.refreshParcelCheck(autoCheckParcelDTO, function(result){
                layer.closeAll(); 
                $scope.autoCheckParcelDTO = result; 
                initCheckResult();
            }, function(){
                layer.closeAll(); 
                layer.msg($translate.instant('global.update_fail')); 
            });
        }

        vm.billCurrency = null;
        function initCheckResult(){
            if(!$scope.autoCheckParcelDTO){
                return;
            }
            for(var i = $scope.autoCheckParcelDTO.autoCheckItems.length - 1; i >= 0; i--){
                if(!$scope.autoCheckParcelDTO.autoCheckItems[i].autoCheckItems || $scope.autoCheckParcelDTO.autoCheckItems[i].autoCheckItems.length == 0){
                    $scope.autoCheckParcelDTO.autoCheckItems.splice(i, 1);
                }
                else if(!vm.billCurrency) {
                    vm.billCurrency = $scope.autoCheckParcelDTO.autoCheckItems[i].autoCheckItems[0].currency;
                }
            }
            angular.forEach($scope.autoCheckParcelDTO.autoCheckItems, function(job){
                if(job.autoCheckItems.length < job.guideRates.length){
                    for(var i = job.autoCheckItems.length; i < job.guideRates.length; i++){
                        job.autoCheckItems.push({});
                    }
                }
                if(job.guideRates && job.guideRates.length > 0){
                    angular.forEach(job.guideRates, function(rate){
                        rate.jobNum = job.autoCheckItems[0].ref;
                    })
                }
                else {
                    job.guideRates = [{
                        jobNum: job.autoCheckItems[0].ref,
                        guideId: job.autoCheckItems[0].guideId,
                        parcelId: job.autoCheckItems[0].parcelId
                    }];
                }
            })
            $scope.autoCheckParcelDTO.autoCheckItems.sort(function(a,b){
                return Math.abs(a.balance)<Math.abs(b.balance)?1:-1;                  
            });
        }

        function delFaillOne (idx, failItems) {
            if(failItems[idx].id){
                AutoCheckItem.delete({id: failItems[idx].id});
            }
            failItems.splice(idx, 1);   

        }



    }
})();