(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsVenderDeleteController', OpsVenderDeleteController);

    OpsVenderDeleteController.$inject = ['$scope', '$rootScope', '$uibModalInstance', 'entity', 'OpsVender'];
    
	function OpsVenderDeleteController($scope, $rootScope, $uibModalInstance, entity, OpsVender) {

        $scope.opsVender = entity;
        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.confirmDelete = function (id) {
            if ($rootScope.HAVENOAUTH('DELETE_STMT_OBJ')){
                $rootScope.FORBIDDEN_BY_ALERT('DELETE_STMT_OBJ');   
                return;
            }
            OpsVender.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };

    }
})();
