(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('PubSearchController', PubSearchController);

    PubSearchController.$inject = ['$scope', '$rootScope', '$uibModalInstance', '$translate', 'entity', 'GuideSearch'];

    function PubSearchController($scope, $rootScope, $uibModalInstance, $translate, entity, GuideSearch) {

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.searchQuery = "";
        $scope.pubSearch = function () {
            if ($scope.searchQuery) {
                GuideSearch.pubSearch({
                    query: $scope.searchQuery,
                    page: 0,
                    size: 20,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = ['_score' + ',' + ('desc')];
                return result;
            }
            function onSuccess(data, headers) {
                $scope.guides = data;
            }
            function onError(error) {
            }
        }











}
})();
