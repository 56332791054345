(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsPortsDialogController', OpsPortsDialogController);

    OpsPortsDialogController.$inject = ['$timeout', '$scope', '$rootScope', '$translate', '$stateParams', '$uibModalInstance', 
    'entity', 'OpsPorts'];

    function OpsPortsDialogController ($timeout, $scope, $rootScope, $translate, $stateParams, $uibModalInstance, 
        entity, OpsPorts) {
        var vm = this;

        vm.opsPorts = entity;
        vm.clear = clear;
        vm.save = save;
        vm.typeChange = typeChange;

        $scope.lanes = [];
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();            
            loadTypes();
        });

        function loadTypes () {
            $scope.lanes = [];
            var typeStr = vm.opsPorts.type = vm.opsPorts.type.toString();
            if (typeStr == "1"){
                angular.forEach($rootScope.sealanes, function(data){
                    $scope.lanes.push(data);
                })
                angular.forEach($rootScope.airlanes, function(data){
                    $scope.lanes.push(data);
                })
            }
            else {
                angular.forEach($rootScope.airlanes, function(data){
                    $scope.lanes.push(data);
                })
                angular.forEach($rootScope.sealanes, function(data){
                    $scope.lanes.push(data);
                })
            }
            $translate.refresh();
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            if (vm.opsPorts.type == "1" && vm.opsPorts.code.length != 5 && vm.opsPorts.code.length != 4 && vm.opsPorts.code.length != 6){
                layer.msg($translate.instant('autopsApp.opsPorts.codeAlertSea'));
                return;
            }
            if (vm.opsPorts.type == "3" && (vm.opsPorts.code.length > 5 || vm.opsPorts.code.length < 3)){
                layer.msg($translate.instant('autopsApp.opsPorts.codeAlertAir'));
                return;                
            }

            vm.isSaving = true;
            if (vm.opsPorts.id !== null) {
                OpsPorts.update(vm.opsPorts, onSaveSuccess, onSaveError);
            } else {
                OpsPorts.save(vm.opsPorts, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('autopsApp:opsPortsUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        
        function typeChange (type) {
            if (!type){
                return;
            }
            vm.opsPorts.region = null;
            loadTypes();
        }

        $scope.uppercaseName = function(str) { 
            vm.opsPorts.name = angular.uppercase(str);
        }
        $scope.uppercaseCode = function(str) { 
            vm.opsPorts.code = angular.uppercase(str);
        }

        $scope.refreshExisting = function(str) { 
            if (!vm.opsPorts.code || !vm.opsPorts.region || vm.isSaving){
                layer.msg($translate.instant('global.blueNullAlert'));
                return;                
            }

            layer.confirm($translate.instant('autopsApp.opsPorts.refreshExistingAlert'), {
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    vm.isSaving = true;
                    layer.msg("Data synchronization...", {
                      shade: 0.3,
                      time: 2000000
                    }); 
                    OpsPorts.refreshExisting(vm.opsPorts, function(){
                        layer.closeAll(); 
                        layer.msg($translate.instant('global.syn_success'));
                        vm.isSaving = false;
                    }, function(){
                        layer.closeAll(); 
                        layer.msg($translate.instant('global.syn_fail'));
                        vm.isSaving = false;                
                    });
                    layer.close(index);
                }, function() {
            });
        }

    }
})();
