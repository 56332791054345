(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('ExpressParcelSearch', ExpressParcelSearch);

    ExpressParcelSearch.$inject = ['$resource'];

    function ExpressParcelSearch($resource) {
        var resourceUrl =  'api/_search/express-parcels/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'queryVirtual': { 
                method: 'GET', 
                url:'api/_search/express-parcels/queryVirtual',
                isArray: true
            }
        });
    }
})();
