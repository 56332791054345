(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('VoucherSynController', VoucherSynController);

    VoucherSynController.$inject = ['$scope', '$rootScope', '$translate', 
    'JhiLanguageService', 'Principal', 'AccountRef', 'DateUtils','OpsFiles', 'DataUtils'];

    function VoucherSynController($scope, $rootScope, $translate, 
        JhiLanguageService, Principal, AccountRef, DateUtils, OpsFiles, DataUtils) {

        $scope.ledgerTitle = $translate.instant('global.menu.entities.vouchersSyn');
      
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        }

        $scope.dateFrom = "";
        $scope.dateTo = "";
        $scope.datePickerForDateFrom = {};

        $scope.datePickerForDateFrom.status = {
            opened: false
        };

        $scope.datePickerForDateTo = {};

        $scope.datePickerForDateTo.status = {
            opened: false
        };
        
        $scope.datePickerOpen = function($event,fromOrTo) {
        	if(fromOrTo){
        		$scope.datePickerForDateFrom.status.opened = true;
        	}else{
        		$scope.datePickerForDateTo.status.opened = true;
        	}
        };

        $scope.datepickerOptions = {
        	   minMode:'month'
        }
        $scope.monthFormat = "yyyy-MM";
        $scope.filterDTO = {};
        $scope.isSaving = false;
        $scope.reportavailalbe = false;
        $scope.vouchers = [];
        $scope.tempVouchers = [];
        var refrestCDC = function() {
            if ($scope.vouchers && $scope.vouchers.length > 0) {
                for (var i = 0; i < $scope.vouchers.length; i++) {
                    if ($scope.vouchers[i].baseAmt){
                    	$scope.vouchers[i].baseAmt = DataUtils.formatTwoDigits($scope.vouchers[i].baseAmt);
                    }
                    if ($scope.vouchers[i].payAmount){
                    	$scope.vouchers[i].payAmount = DataUtils.formatTwoDigits($scope.vouchers[i].payAmount);
                    }
                    if ($scope.vouchers[i].exchangeRate){
                    	$scope.vouchers[i].exchangeRate = DataUtils.formatTwoDigits($scope.vouchers[i].exchangeRate);
                    }
                }
                $scope.tempVouchers = [];
                if($scope.vouchersSynAR && !$scope.vouchersSynAP){//AR
            		for(var i = 0 ; i < $scope.vouchers.length; i++){
            			if($scope.vouchers[i].iscredit){
            				$scope.tempVouchers.push($scope.vouchers[i]);
            			}
            		}
            	}
            	if($scope.vouchersSynAP && !$scope.vouchersSynAR){//AP
            		for(var i = 0 ; i < $scope.vouchers.length; i++){
            			if(!$scope.vouchers[i].iscredit){
            				$scope.tempVouchers.push($scope.vouchers[i]);
            			}
            		}
            	}
            	if(($scope.vouchersSynAP && $scope.vouchersSynAR) || (!$scope.vouchersSynAP && !$scope.vouchersSynAR)){
            		for(var i = 0 ; i < $scope.vouchers.length; i++){
            			$scope.tempVouchers.push($scope.vouchers[i]);
            		}
            	}
            }
            else {
                $scope.tempVouchers = [];
                for (var j = 0; j <= 20; j++) {
                    $scope.tempVouchers.push({});
                }
            }
        }
        refrestCDC();
        
        $scope.getContetns = function () {
            var year = $scope.dateFrom.getFullYear();         
            var month = $scope.dateFrom.getMonth();
            $scope.filterDTO.dateFrom = new Date(year, month, 1);
            year = $scope.dateTo.getFullYear();        
            month = $scope.dateTo.getMonth();
            if (month == 11) {
                month = month-12;
                year++;
            }            
            $scope.filterDTO.dateTo = new Date(year, month+1, 0);

            var date1 = DateUtils.convertLocalDateToServer($scope.filterDTO.dateFrom);
            var date2 = DateUtils.convertLocalDateToServer($scope.filterDTO.dateTo);
            if (date1 > date2) {
                layer.msg($translate.instant('global.messages.beginGreater'));
                return;
            }

            $scope.isSaving = true;
            $scope.vouchers = [];
            OpsFiles.getVouchersSyn({},$scope.filterDTO,function(result){
                $scope.vouchers = result;
                $scope.reportavailalbe = true;
                if (!$scope.vouchers || $scope.vouchers.length == 0) {
                	$scope.reportavailalbe = false;
                    layer.msg($translate.instant('autopsApp.accountTitle.filter.resultNull'));
                    return;
                }
                refrestCDC();
            });
        }

        $scope.vouchersSynAR = false;
        $scope.vouchersSynAP = false;
        $scope.chooseArOrAp = function(){
        	if($scope.vouchersSynAR && !$scope.vouchersSynAP){//AR
        		$scope.ledgerTitle = $translate.instant('autopsApp.accountTitle.vouchersSynARTitle');
        		if($scope.vouchers){
        			$scope.tempVouchers = [];
        			for(var i = 0 ; i < $scope.vouchers.length; i++){
            			if($scope.vouchers[i].iscredit){
            				$scope.tempVouchers.push($scope.vouchers[i]);
            			}
            		}
        		}
        		
        	}
        	if($scope.vouchersSynAP && !$scope.vouchersSynAR){//AP
        		$scope.ledgerTitle = $translate.instant('autopsApp.accountTitle.vouchersSynAPTitle');
        		if($scope.vouchers){
        			$scope.tempVouchers = [];
	        		for(var i = 0 ; i < $scope.vouchers.length; i++){
	        			if(!$scope.vouchers[i].iscredit){
	        				$scope.tempVouchers.push($scope.vouchers[i]);
	        			}
	        		}
        		}
        	}
        	if(($scope.vouchersSynAP && $scope.vouchersSynAR) || (!$scope.vouchersSynAP && !$scope.vouchersSynAR)){
        		$scope.ledgerTitle = $translate.instant('global.menu.entities.vouchersSyn');
        		if($scope.vouchers){
        			$scope.tempVouchers = [];
	        		for(var i = 0 ; i < $scope.vouchers.length; i++){
	        			$scope.tempVouchers.push($scope.vouchers[i]);
	        		}
        		}
        	}	
        }
        
        $scope.exportReport = function(format) {
            $scope.format = format;
            $scope.fileName = '';
            $scope.fileName_btn = layer.open({
                type: 1,
                area: ['300px', '180px'],
                btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')],
                title: $translate.instant('global.fileName'),
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: $("#templateReportName"),
                success: function(layero, index) {
                    $("#fileName").focus();
                },
                yes: function(index, layero) {
                    if ($scope.fileName == '') {
                        $scope.fileName = '凭证同步统计表';
                    }
                    generateReport();
                    layer.close($scope.fileName_btn);
                },
                no: function(index, layero) {
                    layer.close(index);
                }
            });
        }

        $("#fileName").keydown(function(event) {
            $scope.keycode = window.event ? event.keyCode : event.which;
            if ($scope.keycode == 13) {
                if ($scope.fileName == '') {
                    $scope.fileName = '凭证同步统计表';
                }
                generateReport();
                layer.close($scope.fileName_btn);
            }
        })


        var generateReport = function() {
            OpsFiles.generateVouchersSyn({dateFrom:DateUtils.convertLocalDateToServer($scope.filterDTO.dateFrom), dateTo: DateUtils.convertLocalDateToServer($scope.filterDTO.dateTo), fileType: $scope.format},$scope.tempVouchers, function(result) {
                exportExcelOrPdf(result);
            }, function(error) {
                layer.msg($translate.instant('autopsApp.accountTitle.viewVoucherSynFailed'));
            });
        }

	    var exportExcelOrPdf = function(result) {
	        if (angular.isDefined($scope.format) && $scope.format == 'excel') {
	            var file = new Blob([result.data], {
	                type: 'application/vnd.ms-excel'
	            });
	            var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
	            var aTag = document.createElement("a");
	            aTag.download = $scope.fileName + ".xlsx";
	            aTag.href = blobURL;
	            aTag.click();
	        } else {
	            var file = new Blob([result.data], {
	                type: 'application/pdf'
	            });
	            var fileURL = URL.createObjectURL(file);
	            var previewDialog = layer.open({
	                type: 2,
	                area: ['1200px', '800px'],
	                maxmin: true,
	                title: $translate.instant("autopsApp.accountTitle.viewVoucherSyn"),
	                shade: 0.3,
	                moveType: 0,
	                shift: 0,
	                content: fileURL
	            })
	        }
	    }
    
    
    }
})();
