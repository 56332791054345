(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ShareCargoListDialogController', ShareCargoListDialogController);

    ShareCargoListDialogController.$inject = ['$rootScope', '$uibModalInstance', '$translate', '$timeout', 'entity', 'GuideRates'];

    function ShareCargoListDialogController ($rootScope, $uibModalInstance, $translate, $timeout, entity, GuideRates) {
               
        var vm = this;
        vm.rateCopy = entity;
        vm.clear = clear;
        vm.delRate = delRate;  
        vm.delAllShareCost = delAllShareCost;      
        vm.isSaving = false;

        vm.shareRates = [];
        vm.mRate = {};
        vm.type = "vol";

        var refresh = false;
        $timeout(function (){
            if (vm.rateCopy.dnNum.indexOf("CSV") === 0){
                vm.type = "vol";
            }
            else if (vm.rateCopy.dnNum.indexOf("CSW") === 0){
                vm.type = "gw";
            }
            else if (vm.rateCopy.dnNum.indexOf("CSC") === 0){
                vm.type = "vw";
            }
            else if (vm.rateCopy.dnNum.indexOf("CST") === 0){
                vm.type = "mt";
            }
            else if (vm.rateCopy.dnNum.indexOf("CSR") === 0){
                vm.type = "rt";
            }
            else if (vm.rateCopy.dnNum.indexOf("CSV") === 0){
                vm.type = "value";
            }
            else if (vm.rateCopy.dnNum.indexOf("CSD") === 0){
                vm.type = "duty";
            }
            getShareRates();
        })
        
        function getShareRates () {
            vm.shareRates = [];
            GuideRates.getCargoShareRates({
                sharedOn : vm.rateCopy.sharedOn,
                id: vm.rateCopy.id
            }, function(result){
                if(result.length == 0){
                    refresh = true;
                    $uibModalInstance.close(refresh);
                    return;
                }
                angular.forEach(result, function(data){
                    if (data.payAmount > 0){
                        vm.mRate = data;
                    }
                    else {
                        vm.shareRates.push(data);
                    }
                })
            })
        }

        function clear () {
            $uibModalInstance.close(refresh);
        }
        function delRate (rate) {
            if ($rootScope.HAVENOAUTH('AU_CHARGES_SHARE')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_CHARGES_SHARE');
                return;
            }
            vm.isSaving = true;
            if (vm.shareRates.length == 1){
                delAllShareCost();
            }
            else {
                GuideRates.reShareCost(rate, function(result){
                    vm.isSaving = false;                    
                    layer.msg($translate.instant('global.messages.submitted'));
                    getShareRates();
                }, function(){
                    vm.isSaving = false;
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                })
            }
        }
        function delAllShareCost () {
            if ($rootScope.HAVENOAUTH('AU_CHARGES_SHARE')) {
                $rootScope.FORBIDDEN_BY_ALERT('AU_CHARGES_SHARE');
                return;
            }
            GuideRates.delAllShareCost({sharedOn:vm.mRate.sharedOn}, function(){
                vm.isSaving = false;
                refresh = true;
                clear();
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(result){
                vm.isSaving = false;
                if(result.status == 403){
                    layer.msg($translate.instant('global.lockedAlert'));
                }
                else {
                    layer.msg($translate.instant('global.messages.submittedfailed'));
                }                
            })
        }

    }
})();
