(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('WmsWarehouseDetailController', WmsWarehouseDetailController);

    WmsWarehouseDetailController.$inject = ['$scope', '$http', '$timeout', '$rootScope', '$location', '$state', '$stateParams', '$translate', '$uibModal', 
    'previousState', 'entity', 'WmsWarehouse', 'Principal', 'WmsLocation', 'WmsArea', 'AccountBind', 'OpsFiles', 'AddressBook'];

    function WmsWarehouseDetailController($scope, $http, $timeout, $rootScope, $location, $state, $stateParams, $translate, $uibModal, 
        previousState, entity, WmsWarehouse, Principal, WmsLocation, WmsArea, AccountBind, OpsFiles, AddressBook) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };

        var vm = this;

        vm.wmsWarehouse = entity;
        vm.previousState = previousState.name;
        vm.addArea = addArea;
        vm.save = save;
        vm.delArea = delArea;
        vm.editArea = editArea;        
        vm.addLocation = addLocation;
        vm.delLocation = delLocation;
        vm.delWarehouse = delWarehouse;
        vm.types = [];
        vm.checkTypes = checkTypes;
        vm.volumeChange = volumeChange;
        vm.areaChange = areaChange;
        vm.importLocation = importLocation;
        vm.getLocationByArea = getLocationByArea;
        vm.printLocationBarcode = printLocationBarcode;
        vm.getAccountBinds = getAccountBinds;
        vm.accountBindSelected = accountBindSelected;
        vm.autoPushChange = autoPushChange;
        vm.loadAddressBooks = loadAddressBooks;
        vm.expressZonePop = expressZonePop;
        vm.setDisabled = setDisabled;
        vm.refreshNameOfOrders = refreshNameOfOrders;
        vm.refreshEnNameOfOrders = refreshEnNameOfOrders;
        var warehouseCopy = {};
        angular.copy(entity, warehouseCopy);

        var unsubscribe = $rootScope.$on('autopsApp:wmsWarehouseUpdate', function(event, result) {
            vm.wmsWarehouse = result;
        });
        $scope.$on('$destroy', unsubscribe);

        vm.addressBooks = [];
        function loadAddressBooks () {
            if (vm.addressBooks.length == 0 && !vm.isSaving) {
                vm.isSaving = true;
                AddressBook.getAll(function(result) {
                    vm.addressBooks = result;
                    vm.isSaving = false;
                });
            };
        };

        function getLocationByArea(wmsArea) {
            if(wmsArea.wmsLocations && wmsArea.wmsLocations.length > 0){
                return;
            }
            WmsWarehouse.getLocationByArea({
                areaId: wmsArea.id
            }, function(result){
                wmsArea.wmsLocations = result;
                $translate.refresh();
            }, function(result){                          
            });
        }

        function addArea() {
            if (!vm.wmsWarehouse.wmsAreas){
                vm.wmsWarehouse.wmsAreas = [];
            }
            vm.wmsArea = {};
            vm.wmsWarehouse.wmsAreas.push(vm.wmsArea);
            areaPop(vm.wmsArea);
        }
        function editArea(area) {
            areaPop(area);
        }
        function areaPop(area) {
            $uibModal.open({
                templateUrl: 'app/entities/wms-area/wms-area-dialog.html',
                controller: 'WmsAreaDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'md',
                resolve: {
                    entity: function () {
                        return area;
                    }
                }
            }).result.then(function() {
            }, function() {
            });
        }

        function save () {
            vm.isSaving = true;
            if (angular.equals(vm.wmsWarehouse, warehouseCopy)){
                vm.isSaving = false;
                return;
            }   
            if (!vm.wmsWarehouse.venderName){
                vm.wmsWarehouse.venderId = null;
            } 
            if (vm.wmsWarehouse.id) {
                WmsWarehouse.update(vm.wmsWarehouse, onSaveSuccess, onSaveError);
            } else {
                if($rootScope.HAVEAUTH("WMS_VIRTUAL_ONLY")){
                    $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
                    return;
                }
                WmsWarehouse.save(vm.wmsWarehouse, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            vm.wmsWarehouse = result;
            angular.copy(vm.wmsWarehouse, warehouseCopy);
            vm.isSaving = false;
            layer.msg($translate.instant('global.save_success'));
        }

        function onSaveError (result) {
            vm.isSaving = false;
            if(result.status == 403){
                $rootScope.OPS_ALERT($translate.instant('global.moduleNotLoad'));
            }
            else{
                layer.msg($translate.instant('global.save_fail'));
            }            
        }

        function delArea (area) {     
            if (area.id) {
                WmsArea.delete({
                    id: area.id
                }, function(result){
                    var index = vm.wmsWarehouse.wmsAreas.indexOf(area);
                    if (index > -1) {
                        vm.wmsWarehouse.wmsAreas.splice(index, 1);
                    };
                }, function(result){
                    if (result.status == 403){
                        layer.msg($translate.instant('autopsApp.wmsWarehouse.locationUsed'));
                    }                               
                });
            }
            else {
                var index = vm.wmsWarehouse.wmsAreas.indexOf(area);
                if (index > -1) {
                    vm.wmsWarehouse.wmsAreas.splice(index, 1);
                };
            }
        }
        function delWarehouse (warehouse) {
            if(warehouse.id){
                WmsWarehouse.delete({
                    id: warehouse.id
                }, function(result){
                    $state.go('wms-warehouse', null, {
                        reload: true
                    });
                }, function(result){
                    if (result.status == 403){
                        layer.msg($translate.instant('autopsApp.wmsWarehouse.locationUsed'));
                    }                               
                });
            }
            else {
                $state.go('wms-warehouse', null, {
                    reload: true
                });
            }
        }

        function addLocation (area) {
            if (!area.wmsLocations){
                area.wmsLocations = [];
            }
            area.wmsLocations.push({});

        }
        function delLocation (area, location) {
            if (location.id) {
                WmsLocation.delete({
                    id: location.id
                }, function(result){
                    var index = area.wmsLocations.indexOf(location);
                    if (index > -1) {
                        area.wmsLocations.splice(index, 1);
                    };
                    volumeChange();
                    areaChange();
                }, function(result){
                    if (result.status == 403){
                        layer.msg($translate.instant('autopsApp.wmsWarehouse.locationUsed'));
                    }                               
                });
            }
            else {
                var index = area.wmsLocations.indexOf(location);
                if (index > -1) {
                    area.wmsLocations.splice(index, 1);
                };
                volumeChange();
                areaChange();
            }

        }
        var path = $location.path();
        if (!$rootScope.id && path.indexOf("new") < 0) {
            $state.go('wms-warehouse', null, {
                reload: true
            });
        }

        function loadTypes () {
            WmsWarehouse.getTypes(function (data) {
                vm.types = data;
            });
        }
        loadTypes();

        function checkTypes (tye) {
            if (!tye){
                return;
            }
            var isExist = false;
            for (var i=0; i <vm.types.length; i++){
                if (vm.types[i].type == tye ){
                    isExist =true;
                    break;
                }
            }
            if (!isExist){
                vm.types.push({
                    id: null,
                    code: null,
                    max: null,
                    min: null,
                    volume: null,
                    area: null,
                    remarks: null,
                    type: tye,
                    isLocked: null
                });
            }
        }
        function volumeChange () {
            var warehouseVol = 0;
            angular.forEach(vm.wmsWarehouse.wmsAreas, function (area) {
                var areaVol = 0;
                angular.forEach(area.wmsLocations, function (data) {
                    if (data.volume){
                        areaVol = areaVol + data.volume;
                    } 
                });
                area.volume = areaVol.toFixed(2)- 0;
                warehouseVol = warehouseVol + areaVol;
            })
            vm.wmsWarehouse.volume = warehouseVol.toFixed(2)- 0;
        }
        function areaChange () {
            var warehouseArea = 0;
            angular.forEach(vm.wmsWarehouse.wmsAreas, function (area) {
                var areaArea = 0;
                angular.forEach(area.wmsLocations, function (data) {
                    if (data.area){
                        areaArea = areaArea + data.area;
                    } 
                });
                area.area = areaArea;
                warehouseArea = warehouseArea + areaArea;
            })
            vm.wmsWarehouse.area = warehouseArea;
        }

        function importLocation (file,wmsArea ) {
            if (!file) {return;}
            var fd = new FormData();
            fd.append('file', file);
            $http.post(
                'api/wms-locations/importByExcel', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {    
                    data = angular.fromJson(data);
                    if (!data || data.length == 0){
                        layer.msg($translate.instant('global.messages.resultNull'));  
                        return;
                    }
                    if(!wmsArea.wmsLocations){
                        wmsArea.wmsLocations = [];
                    }
                    angular.forEach(data, function (location) {
                        wmsArea.wmsLocations.push(location);
                    })           
                    layer.msg($translate.instant('global.importSuccess'));
                }).error(function(error) {
                    $rootScope.OPS_ALERT($translate.instant('global.importFailed'));                           
                });
        };

        function printLocationBarcode (wmsAreaId, wmsLocationId) {
            WmsLocation.printLocationBarcode({
                areaId: wmsAreaId,
                locationId: wmsLocationId,
                companyId: $rootScope.account.companyId
            }, function(result) {
                $rootScope.PRINTBYTES(result.data);
            }, function(error) {
                layer.msg($translate.instant('global.noRecord'));
            });
        }

        vm.accountBinds = [];
        function getAccountBinds (){
            if(!$rootScope.account.companyId || vm.accountBinds.length > 0){
                return;
            }
            AccountBind.getEdiAccounts({
                companyId: $rootScope.account.companyId,
                type: "orders"
            }, function(result){
                vm.accountBinds = result;
            })
        }
        function accountBindSelected (data){
            vm.wmsWarehouse.accountBindId = data.id;
        }
        function autoPushChange (){
            vm.wmsWarehouse.autoPush = !vm.wmsWarehouse.autoPush;
            if(!vm.wmsWarehouse.autoPush){
                vm.wmsWarehouse.accountBindId = null;
                vm.wmsWarehouse.accountBindName = null;
                vm.wmsWarehouse.labeledOnly = null;
            }
        }


        $scope.downLoadPic = function(bookingCtn) {
            var aTag = document.createElement("a");
            aTag.href = vm.wmsWarehouse.mapPic.fileurl;
            aTag.click();
        }
        $scope.deleOssFile = function(bookingCtn) {
            OpsFiles.delOssFile({
                filepath: vm.wmsWarehouse.mapPic.filepath
            }, function(result) {
                vm.wmsWarehouse.mapPic = null;
                layer.msg($translate.instant('global.delete_success'));
            }, function(reject) {
                layer.msg($translate.instant('global.delete_fail'));
            }, function(error) {
                console.log(error);
            });
        }

        function contains(file) {
            file = angular.lowercase(file);
            var regx = new Array('jpg', 'png', 'jpeg', 'bmp', 'tiff');
            for (var m = 0; m < regx.length; m++) {
                if (file == regx[m]) {
                    return true;
                }
            }
            return false;
        }

        $scope.uploadPic = function(file, bookingCtn, isMix) {
            if(!vm.wmsWarehouse.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            if (!file) {
                return;
            }
            if (file.size > 2040960) {
                layer.msg($translate.instant('global.logo_warn2m'));
                return;
            }            
            var fileSplit = file.name.split(".");
            if (!contains(fileSplit[fileSplit.length - 1])) {
                layer.msg($translate.instant('global.logo_warn1'));
                return;
            }
            var fd = new FormData();
            var filePath = "/" + "warehouse/" + vm.wmsWarehouse.id + "/map/";

            fd.append('filepath', filePath);
            fd.append('file', file);
            fd.append('companyId', $rootScope.account.companyId);
            $http.post(
                'api/ops-files/uploadFile', fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                vm.wmsWarehouse.mapPic = data;
                layer.msg($translate.instant('global.uploadSuccess'));
            }).error(function(error) {
                layer.msg($translate.instant('global.uploadFail'));
            });
        };

        function getWarehouseMap() {
            if(!vm.wmsWarehouse || !vm.wmsWarehouse.id){
                return;
            }
            var filePath = "/" + "warehouse/" + vm.wmsWarehouse.id + "/map/";
            OpsFiles.getFilesList({
                filepath: filePath,
                companyId: $rootScope.account.companyId
            }, function(data) {
                if (data && data.length > 0) {
                    vm.wmsWarehouse.mapPic = data[0];
                }
            });
        }
        $timeout(function() {
            getWarehouseMap();            
        });

        function expressZonePop() {
            if(!vm.wmsWarehouse.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            $uibModal.open({
                templateUrl: 'app/entities/wms-out/wms-out-delete-dialog.html',
                controller: 'WmsOutDeleteController',
                backdrop: false,
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return angular.copy(vm.wmsWarehouse) ;
                    }]
                }
            }).result.then(function(result) {
                vm.wmsWarehouse.expressZone = result;
            }, function() {

            });
        }
        function setDisabled (){
            WmsWarehouse.setDisabled({
                id: vm.wmsWarehouse.id
            }, function(result){
                vm.wmsWarehouse.disabled = result.disabled;
            }, function(error){
                if(error.status == 403){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.wmsWarehouse.virtualWarehousesEnabled'));
                }
                else{
                    layer.msg($translate.instant('global.save_fail'));
                }  
            })
        }
        
        function refreshNameOfOrders (){
            layer.confirm($translate.instant('autopsApp.wmsWarehouse.refreshNameOfOrdersAlert'), {
                icon: 3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    WmsWarehouse.refreshNameOfOrders({}, vm.wmsWarehouse, function(result){
                        layer.msg($translate.instant('global.update_success'));
                    }, function(result){
                        layer.msg($translate.instant('global.update_fail'));
                    })
                    layer.close(index);
                }, function() {
            });
        }

        function refreshEnNameOfOrders (){
            layer.confirm($translate.instant('autopsApp.wmsWarehouse.refreshNameOfOrdersAlert'), {
                icon: 3,
                btn: [$translate.instant('global.yes_answer'), $translate.instant('global.no_answer')] //按钮
                }, function(index) {
                    WmsWarehouse.refreshEnNameOfOrders({}, vm.wmsWarehouse, function(result){
                        layer.msg($translate.instant('global.update_success'));
                    }, function(result){
                        layer.msg($translate.instant('global.update_fail'));
                    })
                    layer.close(index);
                }, function() {
            });
        }
        
    }
})();
