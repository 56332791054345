(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ReportTemplateController', ReportTemplateController);

    ReportTemplateController.$inject = ['$timeout', '$scope', '$state', '$uibModal', '$rootScope',
    'WinAES', 'DataUtils', 'ReportTemplate', 'ReportTemplateSearch', 'ParseLinks'];

    function ReportTemplateController($timeout, $scope, $state, $uibModal, $rootScope,
        WinAES, DataUtils, ReportTemplate, ReportTemplateSearch, ParseLinks) {

        $scope.reportTemplates = [];
        $scope.predicate = 'id';
        $scope.reverse = true;
        $scope.page = 1;
        $scope.itemsPerPage = 20;

        $scope.loadAll = function() {
            ReportTemplate.query({page: $scope.page - 1, size: 20, sort: [$scope.predicate + ',' + ($scope.reverse ? 'desc' : 'asc'), 'id']}, function(result, headers) {
                $scope.links = ParseLinks.parse(headers('link'));
                $scope.totalItems = headers('X-Total-Count');
                $scope.reportTemplates = result;
                angular.forEach($scope.reportTemplates, function(data){
                    data.encodeId = WinAES.encode(data.id.toString())
                })
            });
        };
        $scope.loadPage = function(page) {
            $scope.page = page;
            $scope.loadAll();
        };
        $scope.loadAll();

        $scope.currentSearch = false;
        $scope.clear = function() {
            $scope.currentSearch = false;
            $scope.loadAll();
        }

        $scope.search = function () {
            ReportTemplateSearch.query({query: $scope.searchQuery}, function(result) {
                $scope.reportTemplates = result;
                $scope.currentSearch = true;
                angular.forEach($scope.reportTemplates, function(data){
                    data.encodeId = WinAES.encode(data.id.toString())
                })
            }, function(response) {
                if(response.status === 404) {
                    $scope.loadAll();
                }
            });
        };

        $scope.abbreviate = DataUtils.abbreviate;
        $scope.byteSize = DataUtils.byteSize;

        $timeout(function (){
            $rootScope.RESIZE();
        });

        $scope.uploadTemplate = function(id, name){
            var reportTemplate = {};
            reportTemplate.companyId = id;
            reportTemplate.companyName = name;
            reportTemplate.type = null;
            $uibModal.open({
                templateUrl: 'app/entities/opsFactory/opsFactory-detail.html',
                controller: 'OpsFactoryDetailController',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return reportTemplate;
                    }]
                }
            }).result.then(function(results) {
            }, function() {
            });
        }

    }
})();
