(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('CustomDeclarationDetailController', CustomDeclarationDetailController);

    CustomDeclarationDetailController.$inject = ['$timeout', '$scope', '$rootScope', '$state', '$stateParams', '$http', '$uibModal', 'previousState', 'CustomDeclaration', '$translate',
        'OpsFiles', 'CustomDeclarationGoods', 'entity', 'OpsReport', 'ManageEnterprise', 'WmsWarehouse', 'CustomDeclarationCtns', 
        'CustomDeclarationDocs', 'DataUtils'
    ];

    function CustomDeclarationDetailController($timeout, $scope, $rootScope, $state, $stateParams, $http, $uibModal, previousState, CustomDeclaration, $translate,
        OpsFiles, CustomDeclarationGoods, entity, OpsReport, ManageEnterprise, WmsWarehouse, CustomDeclarationCtns, 
        CustomDeclarationDocs, DataUtils) {

        var vm = this;

        vm.customDeclaration = entity;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        vm.previousState = previousState.name;
        vm.addGoods = addGoods;
        vm.tempGoods = '';
        vm.deleteGoods = deleteGoods;
        vm.inputCustomsCode = inputCustomsCode;
        vm.declare = declare;
        vm.generateWmsIn = generateWmsIn;
        vm.viewWmsIn = viewWmsIn;
        vm.viewWmsOut = viewWmsOut;
        vm.priceChange = priceChange;
        vm.clearGoods = clearGoods;
        vm.addCtns = addCtns;
        vm.addDocs = addDocs;
        vm.deleteCtn = deleteCtn;
        vm.deleteDoc = deleteDoc;
        vm.setAudited = setAudited;
        vm.getAllGoods = getAllGoods;
        vm.getAllManageEnterprises = getAllManageEnterprises;

        //for TypeHead
        vm._goodSelected = _goodSelected;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus.iEDate = false;
        vm.datePickerOpenStatus.declareDate = false;

        vm.updateCustomsGoods = updateCustomsGoods;

        vm.manageEnterprises = [];
        vm._traderSelected = _traderSelected;
        vm._ownerSelected = _ownerSelected;
        vm._agentSelected = _agentSelected;
        vm._tradeAreaSelected = _tradeAreaSelected;
        vm._tradeCountrySelected = _tradeCountrySelected;
        vm._destinationPortSelected = _destinationPortSelected;
        vm._destinationCodeSelected = _destinationCodeSelected;     
        vm._customsFieldSelected = _customsFieldSelected;   
        vm._ctnTypeSelected = _ctnTypeSelected;

        vm.customCodes = [];
        vm.conveyCodes = [];
        vm.tradeModes = [];
        vm.cutModes = [];
        vm.wrapTypes = [];
        vm.trafModes = [];
        vm.dutyModes = [];
        vm.countries = [];
        vm.warehouses = [];
        vm.inlandPorts = [];
        vm.transModes = [];
        vm.ports = [];
        vm.entryTypes = [];
        vm.customsFields = [];
        vm.tradeCurrs = [];
        vm.units = []; 
        vm.ctnTypes = []; 

        vm._iEPortSelected = _iEPortSelected;
        vm._declPortSelected = _declPortSelected;
        vm._conveySelected = _conveySelected;
        vm._tradeSelected = _tradeSelected;
        vm._cutSelected = _cutSelected;
        vm._wrapTypeSelected = _wrapTypeSelected;
        vm._customsModeSelected = _customsModeSelected;
        vm._countriesSelected = _countriesSelected;
        vm._transModeSelected = _transModeSelected;
        vm._entryTypeSelected = _entryTypeSelected;
        vm.getAllManualNo = getAllManualNo;
        vm.warehouseSelected = warehouseSelected;
        vm.getAllWarehouses = getAllWarehouses;
        vm.setWmsType = setWmsType;
        vm._currencySelected = _currencySelected;
        vm._unit1Selected = _unit1Selected;
        vm._docSelected = _docSelected;
        vm.totalChange = totalChange;
        vm.manualNos = [];

        var customCopy;
        function warehouseSelected (warehouse) {
            vm.customDeclaration.warehouseName = warehouse.name;
            vm.customDeclaration.warehouseId = warehouse.id;
        }
        function getAllWarehouses () {
            if (vm.warehouses && vm.warehouses.length > 0){
                return;
            }
            WmsWarehouse.getAll({
                    includeOwn: true,
                    includeVirtual: false,
                    includeGroup: false
                }, function (data) {
                vm.warehouses = data;
            });
        }

        function getAllManualNo () {
            CustomDeclaration.getAllManualNo(function(result) {
                vm.manualNos = result;
            });
        };
        function clearGoods (goods) {
            goods.code = null;
            goods.hsCode = null;
            goods.goodsCn = null;
            goods.packageType = null;
            goods.pkgs = null;
            goods.qty1 = null;     
            goods.amount = null;
            goods.goodsModel = null;
            goods.unit1 = null;
            goods.qty = null;
            goods.price = null;
            goods.tradeCurr = null;
            goods.tradeCurrName = null;
            goods.wmsGoodsId = null;
            goods.weight = null;
            goods.unit1Code = null;
            goods.dutyMode = null;
            goods.dutyModeName = null;           
            goods.originCountry = null;
            goods.originCountryName = null;;
            $translate.refresh();
        };

        function inputCustomsCode() {     
            if (!vm.customDeclaration.id || vm.customDeclaration.audited){return;}
            var pkgsNull = false;
            angular.forEach(vm.customDeclaration.goodsList, function(data){
                if (!data.pkgs || !data.qty1){                    
                    pkgsNull = true;
                }
            })
            if (pkgsNull){
                layer.msg($translate.instant('autopsApp.customDeclaration.pkgsNullAlert'));
                return;
            }

            layer.prompt({
                formType: 0,
                value: vm.customDeclaration.code,
                title: $translate.instant('autopsApp.customDeclaration.code')
            }, function(value, index, elem) {
                layer.close(index);
                vm.customDeclaration.code = value;
                $translate.refresh();
                save();
            });
        }

        $timeout(function() {
            if (null == vm.customDeclaration.ioFlag) {
                vm.customDeclaration.ioFlag = true;
            }
            if (!vm.customDeclaration.type) {
                vm.customDeclaration.type = 1;
            }
            if (previousState.isCopy){
                vm.customDeclaration.id = null;
                vm.customDeclaration.code = null;
                vm.customDeclaration.invNo = null;
                vm.customDeclaration.invPassword = null;
                vm.customDeclaration.iEDate = null;
                vm.customDeclaration.declareDate = new Date();
                vm.customDeclaration.contaNo = null;
                vm.customDeclaration.declarationTime = null;
                vm.customDeclaration.declarateStatus = false;
                vm.customDeclaration.declarerId = null;
                vm.customDeclaration.declarerName = null;
                vm.customDeclaration.wmsInId = null;
                vm.customDeclaration.updatedTime = null;
                vm.customDeclaration.wmsInId = null;
                vm.customDeclaration.billNo = null;
                angular.forEach(vm.customDeclaration.goodsList, function(data){
                    data.id = null;
                    data.balance = null;
                    data.time = null;
                    data.declarationTime = null;
                })
                angular.forEach(vm.customDeclaration.ctnsList, function(data){
                    data.id = null;
                    data.containerId = null;
                    data.ciqContainerMd = null;
                    data.customsContainerMd = null;
                    data.containerWt = null;
                    data.lclFlag = null;
                    data.ctnType = null;
                })
                angular.forEach(vm.customDeclaration.docsList, function(data){
                    data.id = null;
                    data.docuCode = null;
                    data.certCode = null;
                    data.docName = null;
                })
            }
            customCopy = angular.copy(vm.customDeclaration);
        });

        $http.get('i18n/customsData.json').success(function(result) {
            vm.customCodes = result.customCodes;
            vm.conveyCodes = result.conveyCodes;
            vm.tradeModes = result.tradeModes;
            vm.cutModes = result.cutModes;
            vm.wrapTypes = result.wrapTypes;
            vm.trafModes = result.trafModes;
            vm.dutyModes = result.dutyModes;
            vm.countries = result.countries;
            vm.ports = result.ports;
            vm.transModes = result.transModes;
            vm.inlandPorts = result.inlandPorts;
            vm.entryTypes = result.entryTypes;
            vm.customsFields = result.customsFields;
            vm.tradeCurrs = result.tradeCurrs;
            vm.units = result.units;
            vm.ctnTypes = result.ctnTypes;
            vm.docs = result.docs;
        });
                        
        function _docSelected(data, doc) {
            doc.docName = data.N;
            doc.docuCode = data.C;
        }

        function _ctnTypeSelected(data, ctn) {
            ctn.ctnType = data.N;
            ctn.customsContainerMd = data.T;
            ctn.ciqContainerMd = data.C;
        }

        function _countriesSelected(data, goods) {
            goods.originCountryName = data.N;
            goods.originCountry = data.C;
        }
        function _currencySelected(data, goods) {
            goods.tradeCurrName = data.N;
            goods.tradeCurr = data.C;
        }
        function _customsModeSelected(data, goods) {
            goods.dutyModeName = data.N;
            goods.dutyMode = data.C;
        }
        function _entryTypeSelected(data) {
            vm.customDeclaration.entryTypeName = data.N;
            vm.customDeclaration.entryType = data.C;
        }
        function _transModeSelected(data) {
            vm.customDeclaration.transModeName = data.N;
            vm.customDeclaration.transMode = data.C;
        }
        function _unit1Selected(data, goods) {
            goods.unit1 = data.N;
            goods.unit1Code = data.C;
        }
        function _customsFieldSelected(data) {
            vm.customDeclaration.customsFieldName = data.N;
            vm.customDeclaration.customsField = data.C;
        }
        function _iEPortSelected(data) {
            vm.customDeclaration.iEPortName = data.N;
            vm.customDeclaration.iEPort = data.C;
        }
        function _tradeAreaSelected(data) {
            vm.customDeclaration.tradeAreaName = data.N;
            vm.customDeclaration.tradeAreaCode = data.C;
        }
        function _tradeCountrySelected(data) {
            vm.customDeclaration.tradeCountryName = data.N;
            vm.customDeclaration.tradeCountry = data.C;
        }

        function _destinationPortSelected(data) {
            vm.customDeclaration.destinationPortName = data.N;
            vm.customDeclaration.destinationPort = data.C;
        }
        function _destinationCodeSelected(data) {
            vm.customDeclaration.destinationCodeName = data.N;
            vm.customDeclaration.destinationCode = data.C;
        }

        function _declPortSelected(data) {
            vm.customDeclaration.declPortName = data.N;
            vm.customDeclaration.declPort = data.C;
        }

        function _conveySelected(data) {
            vm.customDeclaration.conveyName = data.N;
            vm.customDeclaration.conveyCode = data.C;
        }

        function _tradeSelected(data) {
            vm.customDeclaration.tradeModeName = data.N;
            vm.customDeclaration.tradeMode = data.C;
        }

        function _cutSelected(data) {
            vm.customDeclaration.cutModeName = data.N;
            vm.customDeclaration.cutMode = data.C;
        }

        function _wrapTypeSelected(data) {
            vm.customDeclaration.wrapName = data.N;
            vm.customDeclaration.wrapType = data.C;
        }


        function getAllManageEnterprises() {
            if(vm.isSaving){
                return;
            }
            if(vm.manageEnterprises && vm.manageEnterprises.length > 0){
                return;
            }
            vm.isSaving = true;
            ManageEnterprise.getAll(function(result) {
                vm.isSaving = false;
                vm.manageEnterprises = result;
            })
        }
        function _traderSelected(data) {
            vm.customDeclaration.tradeName = data.name;
            vm.customDeclaration.tradeCode = data.recordNum;
        }

        function _ownerSelected(data) {
            vm.customDeclaration.ownerName = data.name;
            vm.customDeclaration.ownerCode = data.recordNum;
        }

        function _agentSelected(data) {
            vm.customDeclaration.agentName = data.name;
            vm.customDeclaration.agentCode = data.recordNum;
        }

        function save() {
            if (vm.customDeclaration.audited){
                return;
            }
            if (vm.customDeclaration.type > 2){
                vm.customDeclaration.warehouseName = null;
                vm.customDeclaration.warehouseId = null;
            }
            else {
                var goodsIdNullAlert = false;
                angular.forEach(vm.customDeclaration.goodsList, function(data){
                    if(!data.wmsGoodsId || !data.code){
                        goodsIdNullAlert = true;
                    }
                })
                if(goodsIdNullAlert){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.customDeclaration.goodsIdNullAlert'));
                    return;
                }
            }
            if(angular.equals(vm.customDeclaration, customCopy)){
                layer.msg($translate.instant('global.no_amendmend'));
                return;
            }
            vm.isSaving = true;
            if (vm.customDeclaration.id) {
                CustomDeclaration.update(vm.customDeclaration, onSaveSuccess, onSaveError);
            } else {
                CustomDeclaration.save(vm.customDeclaration, onSaveSuccess, onSaveError);                
            }
        }

        function onSaveSuccess(result) {
            vm.customDeclaration = result;
            customCopy = angular.copy(vm.customDeclaration);
            vm.isSaving = false;
            layer.msg($translate.instant('global.save_success'));
        }

        function onSaveError(result) {
            if (result.status == 409){
                layer.msg($translate.instant('autopsApp.customDeclaration.refreshAlert'));
            }
            else if (result.status == 403){
                layer.msg($translate.instant('autopsApp.customDeclaration.chooseWarehouseAlert'));
            }
            else {
                layer.msg($translate.instant('global.save_fail'));
            }
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.iEDate = false;

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        function addGoods() {
            if (!vm.customDeclaration.goodsList) {
                vm.customDeclaration.goodsList = [];
            }
            vm.customDeclaration.goodsList.push({});
        }
        function addCtns() {
            if (!vm.customDeclaration.ctnsList) {
                vm.customDeclaration.ctnsList = [];
            }
            vm.customDeclaration.ctnsList.push({});
        }
        function addDocs() {
            if (!vm.customDeclaration.docsList) {
                vm.customDeclaration.docsList = [];
            }
            vm.customDeclaration.docsList.push({});
        }


        function getAllGoods () {
            if(vm.isSaving){
                return;
            }
            if (!$rootScope.WMSGOODS || $rootScope.WMSGOODS.length == 0){
                vm.isSaving = true;
                ManageEnterprise.getAllGoods(function(result) {
                    $rootScope.WMSGOODS = result;
                    vm.isSaving = false;
                })
            }
        }

        function deleteCtn(ctn) {
            if (ctn.id) {
                CustomDeclarationCtns.delete({
                    id: ctn.id
                });
            }
            var index = vm.customDeclaration.ctnsList.indexOf(ctn);
            if (index > -1) {
                vm.customDeclaration.ctnsList.splice(index, 1);
            };
        };

        function deleteDoc(doc) {
            if (doc.id) {
                CustomDeclarationDocs.delete({
                    id: doc.id
                });
            }
            var index = vm.customDeclaration.docsList.indexOf(doc);
            if (index > -1) {
                vm.customDeclaration.docsList.splice(index, 1);
            };
        };

        function deleteGoods(goods) {
            if (goods.id) {
                CustomDeclarationGoods.delete({
                    id: goods.id
                });
            }
            var index = vm.customDeclaration.goodsList.indexOf(goods);
            if (index > -1) {
                vm.customDeclaration.goodsList.splice(index, 1);
            };
        };
        function _goodSelected(item, goods) {
            goods.code = item.code;
            goods.hsCode = item.hsCode;
            goods.goodsCn = item.goodsCn;
            goods.packageType = item.packageType;
            goods.pkgs = 1;
            goods.qty1 = 0;      
            goods.amount = 0;     

            goods.goodsModel = item.goodsModel;
            goods.unit1 = item.unit1;
            goods.qty = item.qty1;

            goods.price = DataUtils.round(item.price, 4);
            goods.tradeCurr = item.tradeCurr;
            goods.tradeCurrName = item.tradeCurrName;
            goods.wmsGoodsId = item.wmsGoodsId;
            goods.weight = item.gw;

            goods.unit1Code = item.unit1Code;
            goods.dutyMode = item.dutyMode;
            goods.dutyModeName = item.dutyModeName;            
            goods.originCountry = item.originCountry;
            goods.originCountryName = item.originCountryName;

            updateCustomsGoods(goods);
            // amount originCountry

        }
        function updateCustomsGoods(goods) {
            if (goods.pkgs && goods.pkgs < 1){
                goods.pkgs = 1;
                return;
            }

            if (goods.pkgs && goods.qty){
                goods.qty1 = goods.pkgs * goods.qty;
                goods.qty1 = DataUtils.round(goods.qty1, 2);
            }
            if (goods.qty1 && goods.price){
                goods.amount = goods.qty1 * goods.price;
                goods.amount = DataUtils.round(goods.amount, 2);
            }
            var totalPkgs = 0;
            var totalWeight = 0;
            angular.forEach(vm.customDeclaration.goodsList, function(data){
                if (data.pkgs && data.weight){
                    totalWeight = totalWeight + data.pkgs * data.weight;
                }
                if (data.pkgs){
                    totalPkgs = totalPkgs + data.pkgs;
                }
            })
            vm.customDeclaration.grossWt = totalWeight;
            vm.customDeclaration.packNo = totalPkgs;

        }


        //click upload button
        $scope.uploadCustomDeclarationFile = function() {
            $("input[name=customDeclarationFile]").trigger('click');
        }

        //load file
        $scope.files = new Array();
        $scope.loadFiles = function() {
            if (vm.customDeclaration.id) {
                OpsFiles.getCustomDeclarationFiles({
                    customDeclarationId: vm.customDeclaration.id
                }, function(data) {
                    if (data.length > 0) {
                        $scope.files = new Array();
                        for (var i = 0; i < data.length; i++) {
                            var temp = new Object();
                            temp['filepath'] = data[i]['filepath'];
                            temp['filename'] = data[i]['filename'];
                            temp['fileurl'] = data[i]['fileurl'];
                            temp['fileist'] = true;
                            $scope.files.push(temp);
                        }
                    }
                });
            }
        }

        $scope.reloadFiles = function(file) {
                if (!angular.isDefined(file.filepath)) {
                    return;
                }
                var exist = false;
                if ($scope.files.length > 0) {
                    for (var i = 0; i < $scope.files.length; i++) {
                        if ($scope.files[i]['filename'] == file['filename']) {
                            $scope.files[i]['filepath'] = file['filepath'];
                            $scope.files[i]['filename'] = file['filename'];
                            $scope.files[i]['fileurl'] = file['fileurl'];
                            exist = true;
                            break;
                        }
                    }
                }
                if (!exist) {
                    var temp = new Object();
                    temp['filepath'] = file['filepath'];
                    temp['filename'] = file['filename'];
                    temp['fileurl'] = file['fileurl'];
                    temp['fileist'] = true;
                    $scope.files.push(temp);
                }
            }
            //------------------------------------------------ generate Custom Declaration Report----------------------------------------------
        $scope.exportCustomDeclaration = function(format) {
            var customDeclarationReportDTO = angular.copy(vm.customDeclaration);
            customDeclarationReportDTO.opType = 1;
            customDeclarationReportDTO.reportType = 460;
            if (!customDeclarationReportDTO.ioFlag){
                customDeclarationReportDTO.reportType = 600;
            }            
            customDeclarationReportDTO.fileType = format;
            customDeclarationReportDTO.fileName = 'customDeclaration';            
            OpsReport.generateCustomDeclaration({}, customDeclarationReportDTO, function(result) {
                if (format == 2) {
                    exportExcelOrPdf(result);
                } else {
                    customDeclarationPreview(result);
                }
            });
        }


        var customDeclarationPreview = function(result) {
            var file = new Blob([result.data], {
                type: 'application/pdf'
            });
            if (file.size == 0) {
                layer.msg($translate.instant('global.loadFileFailed'));
                return;
            }
            var fileURL = URL.createObjectURL(file);
            var previewDialog = layer.open({
                type: 2,
                area: ['1000px', '680px'],
                maxmin: true,
                title: $translate.instant("entity.action.siPreview"),
                shade: 0.3,
                moveType: 0,
                shift: 0,
                content: fileURL
            })
        }
        var exportExcelOrPdf = function(result) {
            var file = new Blob([result.data], {
                type: 'application/vnd.ms-excel'
            });
            var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
            var aTag = document.createElement("a");
            aTag.download = vm.fileName + ".xlsx";
            aTag.href = blobURL;
            aTag.click();
        }

        function onDeclSuccess(result) {
            vm.isSaving = false;
            if(result.id){
                vm.customDeclaration = result;
                customCopy = angular.copy(vm.customDeclaration);
                layer.msg($translate.instant('autopsApp.customDeclaration.home.declSuccess'));
            }
            else {
                $rootScope.OPS_ALERT(result.marks);
            }
        }

        function onDeclError(result) {
            vm.isSaving = false;
            if(result.status == 406){
                $rootScope.OPS_ALERT($translate.instant('autopsApp.customDeclaration.home.serviceNotOpen'));
            }
            else {
                layer.msg($translate.instant('autopsApp.customDeclaration.home.declError'));
            }
        }

        var result = '';
        function declare () {
            if ($rootScope.account.wmsMustAudit && !vm.customDeclaration.audited) {
                layer.msg($translate.instant('global.notAuditedAlert'));
                return;
            }
            if (!vm.customDeclaration.id){
                layer.msg($translate.instant('global.saveAlert'));
                return;
            }
            vm.isSaving = true;
            CustomDeclaration.sWindow(vm.customDeclaration, onDeclSuccess, onDeclError)
        }
        
        function generateWmsIn () {
            if(!vm.customDeclaration.warehouseId || !vm.customDeclaration.warehouseName){
                layer.msg($translate.instant('autopsApp.customDeclaration.warehouseNullAlert'));
                return;
            }
            vm.isSaving = true;
            CustomDeclaration.generateWmsIn(vm.customDeclaration, onGenerateSuccess, onGenerateError);
        }

        function viewWmsIn () {
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-detail.html',
                controller: 'WmsInDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: ['WmsIn', function(WmsIn) {
                        return WmsIn.get({id : vm.customDeclaration.wmsInId}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }

        function viewWmsOut () {
            $uibModal.open({
                templateUrl: 'app/entities/wms-in/wms-in-out-detail.html',
                controller: 'WmsInOutDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxxl',
                resolve: {
                    entity: ['WmsIn', function(WmsIn) {
                        return WmsIn.get({id : vm.customDeclaration.wmsInId}).$promise;
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('wmsIn');
                        return $translate.refresh();
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }

        function onGenerateSuccess(result) {
            vm.customDeclaration = result;
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submitted'));
        }

        function onGenerateError() {
            vm.isSaving = false;
            layer.msg($translate.instant('global.messages.submittedfailed'));
            
        }
        function setWmsType(type) {
            if (vm.customDeclaration.audited){
                return;
            }
            vm.customDeclaration.type = type;
        }
        function totalChange(type) {
            if (vm.customDeclaration.audited){
                return;
            }
            vm.customDeclaration.type = type;
        }
        function priceChange(goods) {
            if(goods.price && goods.qty1){
                goods.amount = goods.price * goods.qty1;
                goods.amount = DataUtils.round(goods.amount, 2);
            }
        }
        function totalChange(goods) {
            if(goods.amount && goods.qty1){
                goods.price = goods.amount / goods.qty1;
                goods.price = DataUtils.round(goods.price, 4);
            }
        }
        function setAudited () {
            CustomDeclaration.setAudited(vm.customDeclaration, onSaveSuccess, onSaveError);
        }
    }
})();