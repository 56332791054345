(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ExpressChargesController', ExpressChargesController);

    ExpressChargesController.$inject = ['$scope', '$rootScope', '$state', '$translate', '$uibModal', '$http', '$timeout', '$location', '$interval',
    'DateUtils', 'Principal', 'OpsCost', 'OpsCostSearch', 'ParseLinks', 'AlertService', 'OpsFiles', 'OpsPorts',
    'uiGridConstants', 'OpsTemplate', 'GridState', 'JhiSignature'];

    function ExpressChargesController($scope, $rootScope, $state, $translate, $uibModal, $http, $timeout, $location, $interval,
        DateUtils, Principal, OpsCost, OpsCostSearch, ParseLinks, AlertService, OpsFiles, OpsPorts,
        uiGridConstants, OpsTemplate, GridState, JhiSignature) {
        var vm = this;
        vm.filterValue = null;
        var filter = "self";

        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;

        vm.uploadFile = uploadFile;
        vm.files = new Array();
        vm.loadFiles = loadFiles;
        vm.isFclType = isFclType;
        vm.setFilter = setFilter;
        vm.openCalendar = openCalendar;
        vm.datePickerOpenStatus = {};
        vm._selected = _selected;
        vm.filterSet = filterSet;
        vm.delAll = delAll;
        vm.saveAll = saveAll;
        vm.delExpired = delExpired;
        vm.deleteFile = deleteFile;
        vm.advancedSearch = advancedSearch;
        vm.deleteAll = deleteAll;
        vm.isAdvanceSearch = false;
        vm.getCount = getCount;
        vm.loadPorts = loadPorts;
        vm.rateRemarks = rateRemarks;
        vm.generateSelling = generateSelling;

        vm.units = [ "", "KG", "CBM", "TON", "PALLET"];

        if ($location.path().indexOf("selling") >= 0) {
            $scope.isCostMgmt = false;
        }
        else {
            $scope.isCostMgmt = true;
        }

        vm.predicate = 'id';
        vm.direction = 'desc';
        vm.page = 1;
        vm.itemsPerPage = 20;

        vm.clear = clear;
        vm.search = search;
        vm.searchQuery = null;
        vm.currentSearch = null;

        vm.isAdvanceSearch = false;

        function loadAll() {
            if(!vm.filterValue){
                layer.msg($translate.instant('autopsApp.opsCost.chooseTypsAlert'));
                return;
            }
            if (vm.searchQuery) {
                OpsCostSearch.query({
                    type: vm.filterValue,
                    filter: filter,
                    query: vm.searchQuery,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                if(vm.predicate == '_score'){
                    vm.predicate = 'id';
                }
                OpsCost.query({
                    type: vm.filterValue,
                    filter: filter,
                    page: vm.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [(vm.predicate.endWith('TAG')?vm.predicate.replace('TAG', ''):vm.predicate) + ',' + vm.direction];
                return result;
            }
            function onSuccess(data, headers) {
                if(headers('X-Total-Count')){
                    $scope.gridOptions.totalItems = headers('X-Total-Count');
                }
                else {
                    $scope.gridOptions.totalItems = data.length;
                }
                $scope.gridOptions.data = data;
                resize();
                initData();
                $scope.gridOptions.useExternalSorting = true;
                $scope.gridOptions.showColumnFooter = false;

                $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
                $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
                vm.isAdvanceSearch = false;
                $scope.gridApi.grid.refresh();
            }
            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
            }
        }
        function search(searchQuery) {
            if (!searchQuery) {
                return vm.clear();
            }
            vm.page = 1;
            vm.predicate = '_score';
            vm.direction = 'desc';
            vm.currentSearch = searchQuery;
            loadAll();
        }
        function clear() {
            vm.page = 1;
            vm.predicate = 'id';
            vm.direction =  'desc';
            vm.searchQuery = vm.currentSearch = null;
            vm.isAdvanceSearch = false;
            loadAll();
        }
        // loadAll();


        window.onresize = function(){
            resize();
        }

        function resize() {
            var tabHeight = $scope.gridOptions.data.length * 30 + 200;
            var winHeight = $(window).height() - $("#gridId").offset().top - 3;
            if(tabHeight > winHeight){
                tabHeight = winHeight;
            }
            if(tabHeight < 300){
                tabHeight = 300;
            }
            angular.element(document.getElementsByClassName('gridStyle')[0]).css('height', tabHeight + 'px');
        }

        var btnColWidth = null;
        $interval( function() {
            if(!btnColWidth){
                var btnDom = document.getElementById("btnsId");
                if(btnDom){
                    btnColWidth = btnDom.offsetWidth + 10;
                    $scope.gridOptions.columnDefs[0].width = btnColWidth;
                    $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.COLUMN );
                }
            }
        }, 2000, 10);

        function advancedSearch(opsCost, code) {
            if(!vm.filterValue){
                layer.msg($translate.instant('autopsApp.opsCost.chooseTypsAlert'));
                return;
            }
            var filter = {};
            filter.type = vm.filterValue;
            filter.companyId = $rootScope.account.companyId;
            $uibModal.open({
                templateUrl: 'app/entities/ops-cost/ops-cost-dialog.html',
                controller: 'OpsCostDialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return filter;
                    }]
                }
            }).result.then(function(results) {
                $scope.gridOptions.data = results;
                initData();
                vm.isAdvanceSearch = vm.currentSearch = true;
                $scope.gridOptions.totalItems = results.length;
                $scope.gridOptions.useExternalSorting = false;
                $scope.gridOptions.showColumnFooter = true;
                $scope.gridApi.core.notifyDataChange( uiGridConstants.dataChange.OPTIONS );
                resize();
            }, function() {});
        }

        var polName = $translate.instant('autopsApp.opsCost.pol');
        var tsName = $translate.instant('autopsApp.opsCost.transferPort');
        var destName = $translate.instant('autopsApp.opsCost.dest');
        var carrierName = $translate.instant('autopsApp.opsCost.carrier');
        function initData() {
            if(vm.page != 1){
                return;
            }
            angular.forEach($scope.gridOptions.columnDefs, function(col, index){
                /********  表头更改  ********/
                //pol pod dest
                if(col.field == 'pol'){
                    if(vm.filterValue >= 24){
                        col.displayName = $translate.instant('autopsApp.opsCost.expressPol');
                    }
                    else if (vm.filterValue == 6){
                        col.displayName = $translate.instant('autopsApp.opsCost.fclTruckPol');
                    }
                    else if (vm.filterValue == 5){
                        col.displayName = $translate.instant('autopsApp.opsCost.lclTruckPol');
                    }
                    else {
                        col.displayName = $translate.instant('autopsApp.opsCost.pol');
                    }
                }
                else if(col.field == 'dest'){
                    if(vm.filterValue >= 24){
                        col.displayName = $translate.instant('autopsApp.opsCost.expressPod');
                    }
                    else if (vm.filterValue == 6){
                       col.displayName = $translate.instant('autopsApp.opsCost.fclTruckPod');
                    }
                    else if (vm.filterValue == 5){
                        col.displayName = $translate.instant('autopsApp.opsCost.lclTruckPod');
                    }
                    else {
                        col.displayName = $translate.instant('autopsApp.opsCost.dest');
                    }
                }
                else if(col.field == 'transferPort'){
                    if(vm.filterValue >= 24){
                        col.displayName = $translate.instant('autopsApp.opsCost.transferPort');
                    }
                    else if (vm.filterValue == 6){
                    }
                    else if (vm.filterValue == 5){
                    }
                    else {
                        col.displayName = $translate.instant('autopsApp.opsCost.transferPort');
                    }
                }
                //carrier chennal
                else if(col.field == 'carrier'){
                    if (vm.filterValue == 32 || vm.filterValue == 33 || vm.filterValue == 1 || vm.filterValue == 16){
                        col.displayName = $translate.instant('autopsApp.opsCost.carrier');
                    }
                    else if (vm.filterValue == 30 || vm.filterValue == 31){
                        col.displayName = $translate.instant('autopsApp.opsCost.airCarrier');
                    }
                    else if (vm.filterValue == 6){
                        col.displayName = $translate.instant('autopsApp.opsCost.fclTruckCarrier');
                    }
                    else if (vm.filterValue == 5){
                        col.displayName = $translate.instant('autopsApp.opsCost.lclTruckCarrier');
                    }
                }
                else if(col.field == 'cls'){
                    if (vm.filterValue == 1 || vm.filterValue == 16 || vm.filterValue == 32 || vm.filterValue == 33){
                        col.displayName = $translate.instant('autopsApp.opsCost.cls');
                    }
                    else if(vm.filterValue < 32 || vm.filterValue == 2 || vm.filterValue == 3){
                        col.displayName = $translate.instant('autopsApp.opsCost.expressCls');
                    }
                }
                //etd
                else if(col.field == 'etd'){
                    if (vm.filterValue == 1 || vm.filterValue == 16 || vm.filterValue == 32 || vm.filterValue == 33){
                        col.displayName = $translate.instant('autopsApp.opsCost.etd');
                    }
                    else if(vm.filterValue < 30 || vm.filterValue == 31){
                        col.displayName = $translate.instant('autopsApp.opsCost.airETD');
                    }
                }
                //tt
                else if(col.field == 'tt'){
                    if(vm.filterValue == 30 || vm.filterValue == 31){
                        col.displayName = $translate.instant('autopsApp.opsCost.airTT');
                    }
                    else if (vm.filterValue == 1 || vm.filterValue == 16 || vm.filterValue == 32 || vm.filterValue == 33){
                        col.displayName = $translate.instant('autopsApp.opsCost.tt');
                    }
                }

                /********  显示 隐藏  ********/
                //carrier
                if(col.field == 'carrier'){
                    if(vm.filterValue != 2 && vm.filterValue != 3){
                        col.visible = true;
                    }
                    else {
                        col.visible = false;
                    }
                }
                //FBA TMS AIR LCL
                else if((index >= 11 && index <=20) || index == 22){
                    if(vm.filterValue >= 24 || vm.filterValue == 2 || vm.filterValue == 3){
                        col.visible = true;
                    }
                    else {
                        col.visible = false;
                    }
                }
                else if(index == 21 || index ==23){
                    if(vm.filterValue >= 24){
                        col.visible = true;
                    }
                    else {
                        col.visible = false;
                    }
                }
                //FCL
                else if(index >= 24 && index <=27){
                    if(vm.filterValue == 1 || vm.filterValue == 16){
                        col.visible = true;
                    }
                    else {
                        col.visible = false;
                    }
                }
                //FCL truck
                else if(index >= 28 && index <= 30){
                    if(vm.filterValue == 6){
                        col.visible = true;
                    }
                    else {
                        col.visible = false;
                    }
                }
                //lCL truck
                else if(index >= 31 && index <= 38){
                    if(vm.filterValue == 5){
                        col.visible = true;
                    }
                    else {
                        col.visible = false;
                    }
                }
                //volCoe
                else if(col.field == 'volCoe'){
                    if(vm.filterValue < 24){
                        col.visible = false;
                    }
                    else {
                        col.visible = true;
                    }
                }
                //serviceTerm
                else if(col.field == 'serviceTerm'){
                    if(vm.filterValue == 2 || vm.filterValue == 3 || vm.filterValue == 1 || vm.filterValue == 16){
                        col.visible = true;
                    }
                    else {
                        col.visible = false;
                    }
                }
                //tms
                else if(index == 41 || index == 42){
                    if(vm.filterValue == 2 || vm.filterValue == 3){
                        col.visible = true;
                    }
                    else {
                        col.visible = false;
                    }
                }
                //max min
                else if(col.field == 'weightSeven' || col.field == 'weightEight'){
                    if(vm.filterValue == 2 || vm.filterValue == 3 || vm.filterValue >= 24 ){
                        col.visible = true;
                    }
                    else {
                        col.visible = false;
                    }
                }
                //commodity
                else if(col.field == 'commodity'){
                    if(vm.filterValue == 5 || vm.filterValue == 6 || vm.filterValue == 2 || vm.filterValue == 3){
                        col.visible = false;
                    }
                    else {
                        col.visible = true;
                    }
                }
                //cls
                else if(col.field == 'cls'){
                    if(vm.filterValue >= 24 || vm.filterValue == 1 || vm.filterValue == 16 || vm.filterValue == 2 || vm.filterValue == 3){
                        col.visible = true;
                    }
                    else {
                        col.visible = false;
                    }
                }
                //etd
                else if(col.field == 'etd'){
                    if(vm.filterValue >= 30 || vm.filterValue == 1 || vm.filterValue == 16){
                        col.visible = true;
                    }
                    else {
                        col.visible = false;
                    }
                }
                //tt
                else if(col.field == 'tt'){
                    if(vm.filterValue >= 30 || vm.filterValue == 1 || vm.filterValue == 16){
                        col.visible = true;
                    }
                    else {
                        col.visible = false;
                    }
                }
                //tt
                else if(col.field == 'nameAccount'){
                    if(vm.filterValue == 1 || vm.filterValue == 16){
                        col.visible = true;
                    }
                    else {
                        col.visible = false;
                    }
                }
            })
        }

        $scope.gridOptions = {
            enableSorting: true,//排序
            useExternalSorting: true,

            enableGridMenu : true,//表格 菜单
            showGridFooter: false,//表格的footer
            showColumnFooter: false,//每列footer

            enableRowHeaderSelection : true,  //行选中
            enableRowSelection: true, //行选中
            enableSelectAll: true, //全部选中
            enableFullRowSelection : false, //行任意位置选中
            multiSelect: true,//多行选中

            enableFiltering: true,//高级过滤

            enableHorizontalScrollbar : 1,//表格的水平滚动条
            enableVerticalScrollbar : 1,//表格的垂直滚动条 (两个都是 1-显示,0-不显示)

            paginationPageSizes: [20, 35, 50],
            paginationPageSize: 20,
            useExternalPagination: true,

            minimumColumnSize: 70,

            exporterMenuCsv : false,
            exporterMenuPdf : false,
            exporterMenuLabel : "Export",
            exporterExcelFilename: 'rateMgmt.xlsx',
            exporterExcelSheetName: 'Sheet1',
            exporterSuppressColumns : ['id'],

            enableCellEditOnFocus: true,
            columnDefs: [
                { field: 'id',
                    width: 182,
                    displayName: "",
                    pinnedLeft:true,
                    enableColumnMenu: false,
                    enableFiltering: false,
                    enableCellEdit: false,
                    aggregationType: uiGridConstants.aggregationTypes.count,
                    cellTemplate: '<div class="btn-group flex-btn-group-container"><button type="button"ng-click="grid.appScope.addOne(row.entity)"class="btn btn-info btn-grid"><span class="glyphicon glyphicon-plus"></span><span class="hidden-xs hidden-sm"translate="entity.action.add"></span></button><button type="button"ng-click="grid.appScope.save(row.entity)"class="btn btn-primary btn-grid"><span class="glyphicon glyphicon-save"></span><span class="hidden-xs hidden-sm"translate="entity.action.save"></span></button><div class="btn-group"uib-dropdown dropdown-append-to-body><button id="btn-append-to-body"type="button"class="btn btn-info btn-grid"uib-dropdown-toggle ng-disabled="grid.appScope.isSaving || !row.entity.id"><span class="glyphicon glyphicon-option-horizontal"></span>&nbsp;<span class="caret"></span></button><ul class="dropdown-menu"uib-dropdown-menu role="menu"aria-labelledby="btn-append-to-body"><li><a href=""ng-click="grid.appScope.JS_DEBUG(\'freightJs\', row.entity.freightJs)"><span class="glyphicon glyphicon-cog"></span>&nbsp;<span translate="autopsApp.opsCost.jsExpression">jsExpression</span></a></li><li role="separator"class="divider"></li><li><a href=""ng-click="grid.appScope.deleteCost(row.entity)"><span class="glyphicon glyphicon-remove-circle"></span>&nbsp;<span class="hidden-xs hidden-sm"translate="entity.action.delete"></span></a></li></ul></div></div>'},
                { field: 'level',
                    displayName: $translate.instant('autopsApp.opsCost.level'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: [
                        { value: 1, label: "1 " + $translate.instant('autopsApp.opsCost.home.level')},
                        { value: 2, label: "2 " + $translate.instant('autopsApp.opsCost.home.level')},
                        { value: 3, label: "3 " + $translate.instant('autopsApp.opsCost.home.level')},
                        { value: 4, label: "4 " + $translate.instant('autopsApp.opsCost.home.level')},
                        { value: 5, label: "5 " + $translate.instant('autopsApp.opsCost.home.level')}]
                    },
                    width: 100,
                    cellTemplate: '<div class="ml-5 mt-5"><uib-rating max="5" ng-model="row.entity.level" state-on="\'glyphicon glyphicon-star\'" state-off="\'glyphicon glyphicon-star-empty\'"></uib-rating></div>'},
                { field: 'identification',
                    displayName: $translate.instant('autopsApp.opsCost.price.code'),
                    minWidth:110},
                { field: 'productName',
                    displayName: $translate.instant('autopsApp.opsCost.productName'),
                    minWidth:110},
                { field: 'pol',
                    displayName: polName,
                    minWidth:110},
                { field: 'dest',
                    displayName: destName,
                    minWidth:110},
                { field: 'transferPort', //6
                    minWidth:110,
                    visible: true,
                    displayName: tsName},
                { field: 'carrier',
                    minWidth:110,
                    visible: true,
                    displayName: carrierName},
                { field: 'discountOne',
                    type: 'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    visible: !$scope.isCostMgmt,
                    displayName: $translate.instant('autopsApp.opsCost.discountOne')},
                { field: 'discountTwo',
                    type: 'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    visible: !$scope.isCostMgmt,
                    displayName: $translate.instant('autopsApp.opsCost.discountTwo')},
                { field: 'discountThree',
                    type: 'number',
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    visible: !$scope.isCostMgmt,
                    displayName: $translate.instant('autopsApp.opsCost.discountThree')},

                /**************FBA TMS AIR LCL*************/
                { field: 'weightOne', //index 11
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.price.firstWeight')},
                { field: 'firstWeight',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.price.price')},
                { field: 'weightTwo',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.price.addWeight') + "1"},
                { field: 'twenty',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.price.price') + "1"},
                { field: 'weightThree',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.price.addWeight') + "2"},
                { field: 'forty',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.price.price') + "2"},
                { field: 'weightFour',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.price.addWeight') + "3"},
                { field: 'fortyHq',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.price.price') + "3"},
                { field: 'weightFive',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.price.addWeight') + "4"},
                { field: 'fortyFive',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.price.price') + "4"},
                { field: 'weightSix',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.price.addWeight') + "5"},
                { field: 'fifth',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.price.price') + "5"},
                { field: 'sixth',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.price.price') + "6"},

                /**************FCL*************/
                { field: 'twenty',
                    type: 'number',
                    visible: true,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.twenty')},
                { field: 'forty',
                    type: 'number',
                    visible: true,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.forty')},
                { field: 'fortyHq',
                    type: 'number',
                    visible: true,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.fortyHq')},
                { field: 'fortyFive',
                    type: 'number',
                    visible: true,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.fortyFive')},
                /**************FCL truck 6*************/
                { field: 'twenty',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.fclTruck20GP')},
                { field: 'forty',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.fclTruck40GP')},
                { field: 'fortyHq',
                    type: 'number',
                    visible: false,
                    displayName: $translate.instant('autopsApp.opsCost.twinDrag')},
                /**************LCL truck 5*************/
                { field: 'twenty',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.threeT')},
                { field: 'forty',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.fiveT')},
                { field: 'fortyHq',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.eightT')},
                { field: 'fortyFive',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.tenT')},
                { field: 'fifth',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.twelveT')},
                { field: 'sixth',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.fifthT')},
                { field: 'seventh',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.twentyT')},
                { field: 'eighth',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.twentyFiveT')},
                { field: 'volCoe',
                    type: 'number',
                    visible: false,
                    displayName: $translate.instant('autopsApp.opsCost.volCoe')},
                { field: 'serviceTerm',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.opsCost.serviceTerm')},
                { field: 'sixth',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: "FTL 26FT"},
                { field: 'seventh',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: "FTL 53FT"},
                { field: 'weightSeven',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: "MIN"},
                { field: 'weightEight',
                    type: 'number',
                    visible: false,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: "MAX"},
                { field: 'polCharges',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.opsCost.polCharges')},
                { field: 'podCharges',
                    minWidth:150,
                    cellTemplate: '',
                    displayName: $translate.instant('autopsApp.opsCost.podCharges')},
                { field: 'commodity',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.opsCost.price.commodity')},
                { field: 'cls',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.opsCost.cls')},
                { field: 'etd',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.opsCost.etd')},
                { field: 'tt',
                    type: 'number',
                    minWidth:100,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.tt')},
                { field: 'nameAccount',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.opsCost.nameAccount')},
                { field: 'contractNum',
                    minWidth:150,
                    displayName: $translate.instant('autopsApp.opsCost.contractNum')},
                { field: 'effectiveDate',
                    minWidth:150,
                    type: 'date',
                    cellFilter: 'date:"yyyy-MM-dd"',
                    // enableFiltering: false,
                    displayName: $translate.instant('autopsApp.opsCost.effectiveDate')},
                { field: 'expireDate',
                    minWidth:150,
                    type: 'date',
                    cellFilter: 'date:"yyyy-MM-dd"',
                    // enableFiltering: false,
                    displayName: $translate.instant('autopsApp.opsCost.expireDate')},
                { field: 'remarks',
                    minWidth:200,
                    displayName: $translate.instant('autopsApp.opsCost.remarks')},
                { field: 'createdTime',
                    minWidth:200,
                    cellFilter: 'date:"yyyy-MM-dd HH:mm:ss"',
                    enableFiltering: false,
                    enableCellEdit: false,
                    displayName: $translate.instant('autopsApp.opsCost.createdTime')},
                { field: 'currency',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.opsCost.currency')},
                { field: 'porName',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.opsCost.porName')},
                { field: 'deliveryWay',
                    displayName: $translate.instant('autopsApp.booking.deliveryWay'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.DELIVERYTYPES
                    },
                    minWidth:100},
                    // cellTemplate: '<span class="ml-5" translate="autopsApp.booking.deliveryType.{{row.entity.deliveryWay}}" ng-if="row.entity.deliveryWay" class="label-info label"></span>'},
                { field: 'deliveryChannel',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.opsCost.deliveryChannel')},
                { field: 'freightJs',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.opsCost.freightJs')},
                { field: 'priceUnit',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.opsCost.unit')},
                { field: 'isDiscount',
                    enableCellEdit: false,
                    displayName: $translate.instant('autopsApp.opsCost.isDiscount'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span ng-if="row.entity.isDiscount"ng-click="grid.appScope.setIsDiscount(row.entity)"class="ml-5"><span translate="global.yes_answer"class="label label-success">yes</span></span><span ng-if="!row.entity.isDiscount"ng-click="grid.appScope.setIsDiscount(row.entity)"class="ml-5"><span translate="global.no_answer"class="label label-primary">no</span></span>'},
                { field: 'unitPriceFreight',
                    enableCellEdit: false,
                    displayName: $translate.instant('autopsApp.opsCost.unitPriceFreight'),
                    filter: {
                      type: uiGridConstants.filter.SELECT,
                      selectOptions: $rootScope.YES_NO_OPTIONS
                    },
                    cellTemplate: '<span ng-if="row.entity.unitPriceFreight"ng-click="grid.appScope.setUnitPriceFreight(row.entity)"class="ml-5"><span translate="global.yes_answer"class="label label-success">yes</span></span><span ng-if="!row.entity.unitPriceFreight"ng-click="grid.appScope.setUnitPriceFreight(row.entity)"class="ml-5"><span translate="global.no_answer"class="label label-primary">no</span></span>'},
                { field: 'item',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.opsCost.item')},
                { field: 'shipmentType',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.opsCost.shipmentType')},
                { field: 'purchasedBy',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.opsCost.purchasedBy')},
                { field: 'volumeShare',
                    type: 'number',
                    minWidth:100,
                    filters: [
                        {
                          condition: uiGridConstants.filter.GREATER_THAN_OR_EQUAL,
                          placeholder: ' >= '
                        },
                        {
                          condition: uiGridConstants.filter.LESS_THAN_OR_EQUAL,
                          placeholder: ' <= '
                        }
                    ],
                    displayName: $translate.instant('autopsApp.opsCost.volumeShare')},
                { field: 'polChannel',
                    minWidth:100,
                    displayName: $translate.instant('autopsApp.opsCost.polChannel')}
            ],
            gridMenuCustomItems: [{
                title: $translate.instant('global.saveGrid'),
                action: function($event) {
                    if(!vm.filterValue){
                        return;
                    }
                    gridStateObj.gridState = angular.toJson($scope.gridApi.saveState.save());
                    gridStateObj.userId = $rootScope.account.id;
                    gridStateObj.gridId = vm.filterValue;
                    GridState.update(gridStateObj, function(result){
                        gridStateObj = result;
                        layer.msg($translate.instant('global.save_success'));
                    }, function(error){
                        layer.msg($translate.instant('global.save_fail'));
                    });
                },
                order: 0
            }, {
                title: $translate.instant('global.restoreGrid'),
                action: function($event) {
                    if(gridStateObj.gridState){
                        $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
                    }
                },
                order: 1
            }, {
                title: $translate.instant('global.restoreGridDefaults'),
                action: function($event) {
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                },
                order: 2
            }],
            onRegisterApi: function( gridApi ) {
                $scope.gridApi = gridApi;
                $scope.gridApi.core.on.sortChanged($scope, function(grid, sortColumns) {
                    if(!$scope.gridOptions.useExternalSorting){
                        if(sortColumns[sortColumns.length - 1]){
                            var fd = sortColumns[sortColumns.length - 1].field;
                            var dir = sortColumns[sortColumns.length - 1].sort.direction;
                            $scope.gridOptions.data.sort(function(a, b) {
                                return DataUtils.gridSort(a, b, fd, dir);
                            });
                        }
                        return;
                    }
                    if(sortColumns[sortColumns.length - 1]){
                        vm.predicate = sortColumns[sortColumns.length - 1].field;
                        vm.direction = sortColumns[sortColumns.length - 1].sort.direction;
                    }
                    loadAll();
                });
                $scope.gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                    vm.page = newPage;
                    vm.itemsPerPage = pageSize;
                    loadAll();
                });
                $scope.gridApi.edit.on.afterCellEdit($scope,function(rowEntity){
                    var rowCol = $scope.gridApi.cellNav.getFocusedCell();
                    if(rowCol.col.colDef.name && rowCol.col.colDef.name == "polCharges" || rowCol.col.colDef.name == "polCharges" && rowEntity[rowCol.col.colDef.name]){
                        getLocalCharges(rowEntity[rowCol.col.colDef.name]);
                    }
                });
            }
        }
        var gridStateObj = {};
        var gridStateCopy = null;
        $timeout(function (){
            gridStateCopy = $scope.gridApi.saveState.save();
            getUserGridState();
        });

        function getUserGridState() {
            if(!vm.filterValue){
                return;
            }
            GridState.getUserGridState({
                userId: $rootScope.account.id,
                gridId: vm.filterValue
            }, function(result){
                gridStateObj = result;
                if(!gridStateObj || !gridStateObj.id){
                    $scope.gridApi.saveState.restore($scope, gridStateCopy);
                    return;
                }
                $scope.gridApi.saveState.restore($scope, angular.fromJson(gridStateObj.gridState));
            })
        }

        vm.noAuth = true;
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
                if ($rootScope.account.authorities.indexOf("ROLE_MARKET") !== -1) {
                    vm.noAuth = false;
                }
            });
        } else {
            if ($rootScope.account.authorities.indexOf("ROLE_MARKET") !== -1) {
                vm.noAuth = false;
            }
        }
        function getCount (){
            OpsCost.getPriceCount({
                isCost: $scope.isCostMgmt,
                filter: filter
            }, function(result){
                vm.costCount = result;
                $translate.refresh();
            });
        }
        getCount();

        function saveAll() {
            OpsCost.saveAll($scope.gridOptions.data, function(result) {
                $scope.gridOptions.data = result;
                layer.msg($translate.instant('autopsApp.opsCost.deleteAll'));
            }, onSaveError);
        }

        function getLocalCharges(code) {
            if(!code){
                return;
            }
            OpsTemplate.getLocalCharges({
                code: code,
                companyId: $rootScope.account.companyId
            }, function(result){
                if(!result || !result.rates || result.rates.length == 0){
                    layer.msg($translate.instant('autopsApp.opsCost.notTempMsg'));
                    return;
                }
                $uibModal.open({
                    templateUrl: 'app/entities/opsTemplate/opsTemplate-dialog.html',
                    controller: 'OpsTemplateDialogController',
                    controllerAs: 'vm',
                    backdrop: false,
                    size: 'md',
                    resolve: {
                        entity: [function() {
                            return result;
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('opsTemplate');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function(results) {}, function() {});
            })
        }

        function filterSet(filterv) {
            filter = filterv;
            loadAll();
            getCount();
        }

        function delAll() {
            if(!vm.filterValue){
                layer.msg($translate.instant('autopsApp.opsCost.chooseTypsAlert'));
                return;
            }
            OpsCost.deleteAllByType({
                type: vm.filterValue,
                companyId: $rootScope.account.companyId,
                userId: $rootScope.account.id
            }, function(){
                layer.msg($translate.instant('global.delete_success'));
                loadAll();
            }, function(){
                layer.msg($translate.instant('global.delete_fail'));
            });
        }


        function loadFiles() {
            if(!vm.filterValue){return;}
            if (vm.files && vm.files.length > 0) {
                return;
            }
            // alert('load');
            OpsCost.loadFiles({
                type: vm.filterValue
            }, function(data) {
                if (data.length > 0) {
                    vm.files = new Array();
                    for (var i = 0; i < data.length; i++) {
                        var temp = new Object();
                        temp['filepath'] = data[i]['filepath'];
                        temp['filename'] = data[i]['filename'];
                        temp['fileurl'] = data[i]['fileurl'];
                        temp['fileist'] = true;
                        vm.files.push(temp);
                    }
                }
            });
        }

        function isFclType() {
            return vm.filterValue == 1 || vm.filterValue == 16;
        }

        function setFilter(type) {
            vm.filterValue = type;
            getUserGridState();
            vm.searchQuery = '';
            vm.currentSearch = '';
            vm.files = new Array();
            $scope.gridOptions.data = [];
            loadAll();

        }

        //pol Id  & pod Id
        function _selected($item, which, opsCost) {
            if (which == 'pol') {
                opsCost.polId = $item.code;
            }
            if (which == 'pod') {
                opsCost.podId = $item.code;
            }
        }

        function openCalendar(date) {
            vm.datePickerOpenStatus[date] = true;
        }

        $scope.save = function(cost) {
            if (cost.id !== null) {
                OpsCost.update(cost, function(result) {
                    cost.id = result.id;
                    cost.effectiveDate = result.effectiveDate;
                    cost.expireDate = result.expireDate;
                    layer.msg($translate.instant('global.save_success'));
                }, onSaveError);
            } else {
                cost.createdTime = new Date();
                OpsCost.save(cost, function(result) {
                    cost.id = result.id;
                    cost.effectiveDate = result.effectiveDate;
                    cost.expireDate = result.expireDate;
                    layer.msg($translate.instant('global.save_success'));
                }, onSaveError);
            }
        }

        function onSaveError() {
            layer.msg($translate.instant('global.save_fail'));
        }

        function onDeleteSuccess(result) {
            loadAll();
            layer.msg($translate.instant('global.delete_success'));
        }

        function onDeleteError() {
            layer.msg($translate.instant('global.delete_fail'));
        }

        $scope.setIsDiscount = function(cost) {
            cost.isDiscount = !cost.isDiscount;
        }

        $scope.setUnitPriceFreight = function(cost) {
            cost.unitPriceFreight = !cost.unitPriceFreight;
        }

        $scope.deleteCost = function(cost) {
            if (!cost.id) {
                var index = $scope.gridOptions.data.indexOf(cost);
                if (index > -1) {
                    $scope.gridOptions.data.splice(index, 1);
                };
                return;
            }
            if ($rootScope.account
                && $rootScope.HAVENOAUTH('ROLE_BRANCH')
                && $rootScope.account.id != cost.uploadedBy) {
                layer.msg($translate.instant('global.forbiddenBranch'));
                return;
            }
            layer.confirm($translate.instant('global.confirmToDelete'), {
                btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')]
            }, function() {
                OpsCost.delete({
                    id: cost.id
                }, onDeleteSuccess, onDeleteError)
            });
        }

        $scope.addOne = function (opsCost) {
            if(!vm.filterValue){
                layer.msg($translate.instant('autopsApp.opsCost.chooseTypsAlert'));
                return;
            }
            var newCost = {
                uploadedBy: $rootScope.account.id,
                purchasedBy: $rootScope.account.firstName + " "+ $rootScope.account.lastName,
                companyId: $rootScope.account.companyId,
                type: vm.filterValue
            };
            if(!$scope.gridOptions.data || $scope.gridOptions.data.length == 0 || !opsCost){
                if(!$scope.gridOptions.data){
                    $scope.gridOptions.data = [];
                }
                if($scope.gridOptions.data.length > 0 && !opsCost){
                    $scope.gridOptions.data.splice(0, 0, newCost);
                }
                else {
                    $scope.gridOptions.data.push(newCost);
                }
                return;
            }
            var index = $scope.gridOptions.data.indexOf(opsCost);
            $scope.gridOptions.data.splice(index, 0, newCost);
        }
        function loadPorts() {
            if ($rootScope.OPSPORTS.airports && $rootScope.OPSPORTS.airports.length > 0) {
                return;
            }
            OpsPorts.getAll(function(result) {
                $rootScope.OPSPORTS.airports = [];
                $rootScope.OPSPORTS.seaports = [];
                angular.forEach(result, function(data) {
                    if (data.type == 1) {
                        $rootScope.OPSPORTS.seaports.push(data);
                    } else {
                        $rootScope.OPSPORTS.airports.push(data);
                    }
                })
            })
        };
        $scope.textPop = function(text, cost, fd) {
            $uibModal.open({
                templateUrl: 'app/entities/ops-change-log/ops-change-log-delete-dialog.html',
                controller: 'OpsChangeLogDeleteController',
                controllerAs: 'vm',
                // backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return text;
                    }]
                }
            }).result.then(function(result) {
                cost[fd] = result;
            }, function() {
            });
        }
        function uploadFile(file) {
            if (!file) {
                return;
            }
            if (!vm.filterValue) {
                layer.msg($translate.instant('autopsApp.opsCost.chooseTypsAlert'));
                return;
            }
            layer.msg("Parsing...", {
              shade: 0.3,
              time: 2000000
            });
            var fd = new FormData();
            fd.append('file', file);
            fd.append('type', vm.filterValue);
            var urlStr = 'api/ops-costs/excelForParcel';
            if(vm.filterValue < 24 && vm.filterValue != 2 && vm.filterValue != 3){
                urlStr = 'api/ops-costs/importByExcel';
            }
            $http.post(urlStr, fd, {
                    transformRequest: angular.identity,
                    headers: {
                        'Content-Type': undefined
                    },
                }).success(function(data) {
                    layer.closeAll();
                    if(!data){
                        return;
                    }
                    data = angular.fromJson(data);
                    vm.files.push(data.file);
                    if (data.newCount == 0 && data.repeatCount == 0){
                        layer.msg($translate.instant('global.messages.resultNull'));
                        return;
                    }
                    if(data.newCount>0){
                        filter = "self";
                        loadAll();
                    }
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.opsCost.uploadResult', {
                        repeatCount: data.repeatCount,
                        newCount: data.newCount}));
                }).error(function(error) {
                    layer.closeAll();
                    if(error && "xlsAlert" == error.msg){
                        $rootScope.OPS_ALERT($translate.instant('global.xlsAlert'));
                    }
                    else {
                        $rootScope.OPS_ALERT($translate.instant('global.importFailed'));
                    }

                });
        };
        function delExpired() {
            if(!vm.filterValue){
                layer.msg($translate.instant('autopsApp.opsCost.chooseTypsAlert'));
                return;
            }
            OpsCost.deleteAllExpired({
                type: vm.filterValue,
                companyId: $rootScope.account.companyId,
                userId: $rootScope.account.id
            }, function(){
                layer.msg($translate.instant('global.delete_success'));
                loadAll();
            }, function(){
                layer.msg($translate.instant('global.delete_fail'));
            });
        }
        function deleteFile(file) {
            OpsFiles.delOssFile({
                filepath: file.filepath
            }, function(result) {
                layer.msg($translate.instant('global.delete_success'));
                vm.files = new Array();
                loadFiles();
            }, function(reject) {
                layer.msg($translate.instant('global.delete_fail'));
            }, function(error) {
                console.log(error);
            });
        }


        function deleteAll() {
            if(!$scope.gridOptions.data || $scope.gridOptions.data.length == 0){
                return;
            }
            OpsCost.deleteAll($scope.gridOptions.data, function(){
                layer.msg($translate.instant('global.delete_success'));
                $scope.gridOptions.data = [];
            }, function(){
                layer.msg($translate.instant('global.delete_fail'));
            });
        }

        function rateRemarks () {
            $uibModal.open({
                templateUrl: 'app/entities/jhiSignature/jhiSignature-detail.html',
                controller: 'JhiSignatureDetailController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function() {
                        return JhiSignature.queryRatesRemarks({companyId: $rootScope.account.companyId}).$promise
                    }]
                }
            }).result.then(function(results) {

            }, function() {

            });
        }

        var unsubscribe = $rootScope.$on('autopsApp:opsCostUpdate', function(event, result) {
            angular.forEach(obj, function(data){
                if(data.id == result.id){
                    angular.copy(result, data);
                }
            })
        });
        $scope.$on('$destroy', unsubscribe);
        $scope.popRequired = function(cost) {
            if(cost.type >= 24 || cost.type == 2 || cost.type == 3){
                return true;
            }
            return false;
        }
        function generateSelling (selectedRows){
            if(!selectedRows || selectedRows.length == 0){
                layer.msg($translate.instant('global.noSelectedRecords'));
                return;
            }
            for(var i = 0; i < selectedRows.length; i++){
                if(!selectedRows[i].identification){
                    $rootScope.OPS_ALERT($translate.instant('autopsApp.opsCost.identificationNullAlert'));
                    return;
                }
            }
            layer.prompt({
                formType: 0,
                value: "",
                title: $translate.instant('autopsApp.opsCost.margin')
            }, function(value, index, elem) {                
                if (isNaN(value) || parseInt(value) < 0) {
                    layer.msg($translate.instant('global.inputNumOnlyAlert'));
                    return;
                }
                OpsCost.generateSelling({
                    margin: value
                }, selectedRows, function(result){
                    layer.msg($translate.instant('global.messages.submitted'));
                }, $rootScope.ALERT_ERROR);
            });            
        }

    }
})();
