(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('ManageEnterpriseDeleteController',ManageEnterpriseDeleteController);

    ManageEnterpriseDeleteController.$inject = ['$uibModalInstance', 'entity', 'ManageEnterprise'];

    function ManageEnterpriseDeleteController($uibModalInstance, entity, ManageEnterprise) {
        var vm = this;

        vm.manageEnterprise = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
                
        function confirmDelete (id) {
            ManageEnterprise.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                }, function(error){
                    if(error.status == 409){
                        $rootScope.OPS_ALERT("Pls delete SKU/Product firstly!");
                    }
                    else {
                        layer.msg($translate.instant('global.delete_fail'));
                    }                    
                });
        }
    }
})();
