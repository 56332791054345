(function() {
    'use strict';

    angular
        .module('autopsApp')
        .factory('BookingPriceSearch', BookingPriceSearch);

    BookingPriceSearch.$inject = ['$resource'];
    
    function BookingPriceSearch($resource) {
        return $resource('api/_search/bookingPrices/:query', {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
