(function() {
    'use strict';
    angular
        .module('autopsApp')
        .factory('CrmQuotation', CrmQuotation);

    CrmQuotation.$inject = ['$resource', 'DateUtils'];

    function CrmQuotation ($resource, DateUtils) {
        var resourceUrl =  'api/crm-quotations/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.quotationTime = DateUtils.convertDateTimeFromServer(data.quotationTime);
                        data.enquiryTime = DateUtils.convertDateTimeFromServer(data.enquiryTime);
                        data.validate = DateUtils.convertLocalDateFromServer(data.validate);
                    }
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.validate = DateUtils.convertLocalDateToServer(data.validate);
                    return angular.toJson(data);
                }
            },
            'printQuotation':{
                method:'POST',
                responseType: 'arraybuffer',
                url:'api/crm-quotations/printQuotation',
                transformRequest: function (data) {
                    data = angular.copy(data);
                    return angular.toJson(data);
                },
                transformResponse: function (data) {
                    var result = new Object();
                    result['data'] = data;
                    return result;
                }
            },            
            'getAttachment':{
                method:'POST',
                url:'api/crm-quotations/getAttachment',
                isArray:true
            },
            'getQuotation': {
                method: 'PUT',
                url:'api/crm-quotations/getQuotation',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.quotationTime = DateUtils.convertDateTimeFromServer(data.quotationTime);
                        data.enquiryTime = DateUtils.convertDateTimeFromServer(data.enquiryTime);
                        data.validate = DateUtils.convertLocalDateFromServer(data.validate);
                    }
                    return data;
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.validate = DateUtils.convertLocalDateToServer(data.validate);
                    return angular.toJson(data);
                }
            }
        });
    }
})();
