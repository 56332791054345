(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('BookingTruckDetailController', BookingTruckDetailController);

    BookingTruckDetailController.$inject = ['$scope', '$state', '$rootScope', '$timeout', '$translate', '$location', '$uibModal', 
    'GuideCtns', 'DateUtils', 'GuideFk'];
    
    function BookingTruckDetailController($scope, $state, $rootScope, $timeout, $translate, $location, $uibModal, 
        GuideCtns, DateUtils, GuideFk) {
       
        var path = $location.path();

        if (!$rootScope.BKTK && path.indexOf("new") <= 0) {
            $state.go('bookingTruck', null, {
                reload: true
            });
        }   
        $scope.guideId = null;
        $timeout(function() {
            GuideFk.get({
                tkId: $rootScope.BKTK.id,
                type: "guideCtns"
            }, function(result) {
                $scope.guideId = result.id;
            });
            loadCtns();
        })
        var loadCtns = function () {
            if ($rootScope.BKTK){
                GuideCtns.truckerGet({truckId : $rootScope.BKTK.id}, function(result) {
                    $scope.guideCtns = result;
                    for (var i =0; i <$scope.guideCtns.length; i++){
                        if (!$scope.guideCtns[i].truckNum && !$scope.guideCtns[i].driverName && !$scope.guideCtns[i].driverMob){
                            $scope.guideCtns[i].truckerId = $rootScope.BKTK.truckerVid;
                        }
                    }

                    guideCtnsCopy = angular.copy($scope.guideCtns);
                });
            }
        };     

        $scope.datePickerOpenStatus = {};       
        $scope.openCalendar = function (date) {
        	$scope.datePickerOpenStatus[date] = true;
        }

        $scope.guideCtns = [];
        var guideCtnsCopy = [];
        guideCtnsCopy = angular.copy($scope.guideCtns);   


        $scope.addGCtn = function() {
            $scope.guideCtns.push({
                truckerId: $rootScope.BKTK.truckerVid,
                smsSent:false
            });
        };

        $scope.isSameCtns = function() {
            return angular.equals($scope.guideCtns, guideCtnsCopy);
        }

      var onCtnsSaveSuccess = function (result) {
            layer.msg($translate.instant('global.messages.submitted'));
            $scope.isSaving = false;
            $state.go('bookingTruck', null, {
                reload: true
            });
        };
        var onSaveError = function(result) {
            $scope.isSaving = false;
            layer.msg($translate.instant('global.messages.submittedfailed'));
        };

        $scope.saveGCtns = function () {
            if (angular.equals($scope.guideCtns, guideCtnsCopy)){
                layer.msg($translate.instant('global.no_amendmend'));
                return;
            };
            for (var i =0; i <$scope.guideCtns.length; i++){
                if (!$scope.guideCtns[i].truckNum && !$scope.guideCtns[i].driverName && !$scope.guideCtns[i].driverMob){
                    $scope.guideCtns[i].truckerId = null;
                }
            }
            $scope.isSaving = true;
            GuideCtns.update({id:$rootScope.BKTK.id}, $scope.guideCtns, onCtnsSaveSuccess, onSaveError);
        };

        $scope.deleteGCtn = function(ctn) {
            if (ctn.id != null) {
                GuideCtns.delete({
                    id: ctn.id
                });
            };
            var index = $scope.guideCtns.indexOf(ctn);
            if (index > -1) {
                $scope.guideCtns.splice(index, 1);
            };
        };
        
        $scope.returnTimeOpen = function($index) {
            var id = '#field_returnTime' + $index;
            $(id).datetimepicker({
                startView: 2,
                todayHighlight: false,
                autoclose: true,
                todayBtn: true,
                minView: 0
            });
            $(id).datetimepicker('show');
        };

        $scope.files = [];

        $scope.uppercaseCtnNum = function(ctn) {
            ctn.ctnNum = angular.uppercase(ctn.ctnNum);
        }
        $scope.uppercaseCtnSealNum = function(ctn) {
            ctn.sealNum = angular.uppercase(ctn.sealNum);
        }
        $scope.ctnFilter = function(ctn) {
            if (ctn.truckerId == $rootScope.BKTK.truckerVid) {
                return true;
            };
        }
        $scope.sms = {};

        $scope.smSend = function(ctn) {
            var entity = {};
            entity.ctn = ctn;
            var guide = {};
            guide.booking = {};
            guide.booking.bookingTrucks = [];
            guide.booking.bookingTrucks.push($rootScope.BKTK);
            entity.guide = guide;
            $uibModal.open({
                templateUrl: 'app/entities/bookingCtns/bookingCtns-delete-dialog.html',
                controller: 'BookingCtnsDeleteController',
                backdrop: false,
                size: 'sm',
                resolve: {
                    entity: [function() {
                        return entity;
                    }]
                }
            }).result.then(function(result) {
            }, function(result) {
            });            
        }


        $scope.saveAsTruckTempalte = function(ctn) {
            if (ctn.isTemplate){
                ctn.createdBy = null;
                ctn.isTemplate = false;
            }
            else {
                ctn.createdBy = $rootScope.account.id;
                ctn.isTemplate = true;
            }
            
            GuideCtns.saveAsTemplate({}, ctn, function(result) {
                ctn = result;
                layer.msg($translate.instant('global.messages.submitted'));
            }, function(error) {
                layer.msg($translate.instant('global.messages.submittedfailed'));
            });
        }

        $scope.templates=[];
        var loadTempaltes = function(ctn) {           
            GuideCtns.getTempaltes({}, function(result) {
                $scope.templates = result;
            }, function(error) {
                // layer.msg($translate.instant('global.messages.submittedfailed'));
            });
        }
        loadTempaltes();
        $scope._selected = function ($item, ctn){
            ctn.truckNum = $item.truckNum;
            ctn.driverMob = $item.driverMob;
            ctn.driverName = $item.driverName;    
        }
        
    }
})();
