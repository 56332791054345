(function() {
	'use strict';

	angular
		.module('autopsApp')
		.controller('receiptPaymentController', receiptPaymentController);

	receiptPaymentController.$inject = ['$scope', '$rootScope', '$translate', '$state', '$uibModal', 'Principal', 'User', 
	'Guide', 'DateUtils', 'OpsFiles', 'OpsVender', 'OpsCompany', 'OpsCarrier', 'DataUtils', 'AsyncTask'];

	function receiptPaymentController($scope, $rootScope, $translate, $state, $uibModal, Principal, User, 
		Guide, DateUtils, OpsFiles, OpsVender, OpsCompany, OpsCarrier, DataUtils, AsyncTask) {
		
		$scope.companys = [];
		$scope.statParams = {};
		function loadCompanys () {
			OpsCompany.getGroupCompanys(function(result){
				$scope.companys = result;
				$scope.statParams.scope = $rootScope.account.companyId;
			})
		}


		if (!$rootScope.account || angular.equals($rootScope.account, {})) {
			Principal.identity().then(function(account) {
				$rootScope.account = account;
				if($rootScope.account.isSuper){
					loadCompanys();
				}				
			});
		}
		else {
			if($rootScope.account.isSuper){
				loadCompanys();
			}	
		}	


		//----------统计报表
		$scope.datePickerOpenStatus = {};

		$scope.yearFormat = "yyyy";
		$scope.monthFormat = "yyyy-MM";
		$scope.dayFormat = "yyyy-MM-dd";
		$scope.yearDatepickerOptions = {
			minMode: 'year'
		}
		$scope.monthDatepickerOptions = {
			minMode: 'month'
		}
		$scope.dayDatepickerOptions = {
			minMode: 'day'
		}
		$scope.datePickerOpenStatus.yearpicker = false;
		$scope.datePickerOpenStatus.monthpicker = false;
		$scope.datePickerOpenStatus.daypicker = false;
		$scope.datePickerOpenStatus.dateFromPicker = false;
		$scope.datePickerOpenStatus.dateToPicker = false;

		$scope.openCalendar = function(date) {
			$scope.datePickerOpenStatus[date] = true;
		}

		$scope.statParams = {};
		$scope.statParams.isPay = false;
		$scope.isSaving = false;
		var getStatParams = function() {
			$scope.statParams.dateFrom = "";
			$scope.statParams.dateTo = "";
			$scope.statParams.fileName = "";
			if ($scope.statParams.statType == "irregular") {
				$scope.statParams.etdFM = $scope.statParams.dateFrom_;
				$scope.statParams.etdTO = $scope.statParams.dateTo_;
				$scope.statParams.fileName = $scope.statParams.dateFrom_ + "_" + $scope.statParams.dateTo_ + "利润统计";
			}
			if ($scope.statParams.statType == "year") {
				var year = $scope.statParams.year.getFullYear(); //取当前的年份          
				$scope.statParams.etdFM = new Date(year, 0, 1);
				$scope.statParams.etdTO = new Date(year, 11, 31);
				$scope.statParams.fileName = $scope.statParams.year + "年利润统计";
			}
			if ($scope.statParams.statType == "month") {
				var year = $scope.statParams.month.getFullYear(); //取当前的年份          
				var month = $scope.statParams.month.getMonth();
				if (month == 11) {
					month = month -12;
					year++;
				}
				$scope.statParams.etdFM = new Date(year, month, 1);
				$scope.statParams.etdTO = new Date(year, month+1, 0);
				$scope.statParams.fileName = $scope.statParams.month + "利润统计";
			}
			if ($scope.statParams.statType == "day") {
				$scope.statParams.etdFM = $scope.statParams.day;
				$scope.statParams.etdTO = $scope.statParams.day;
				$scope.statParams.fileName = $scope.statParams.day + "利润统计";
			}
			if (!$scope.statParams.isPay){
				$scope.statParams.isPay = false;
			}
			if (!$scope.statParams.agentName){
				$scope.statParams.deliveryId = null;
			}
			if (!$scope.statParams.coloaderName){
				$scope.statParams.bookingToId = null;	
			}
			if (!$scope.statParams.payerName){
				$scope.statParams.venderId = null;	
			}
			if (!$scope.statParams.clientName){
				$scope.statParams.shipperId = null;
			}
			if (!$scope.statParams.customerName){
				$scope.statParams.customerId = null;
			}
			if($scope.marketList && $scope.marketList.length > 0){
				$scope.statParams.marketIds = [];
				angular.forEach($scope.marketList, function(data){
					$scope.statParams.marketIds.push(data.id);
				})
			}
			else {
				$scope.statParams.marketIds = null;
			}
			
			if($scope.finList && $scope.finList.length > 0){
				$scope.statParams.finIds = [];
				angular.forEach($scope.finList, function(data){
					$scope.statParams.finIds.push(data.id);
				})
			}
			else {
				$scope.statParams.finIds = null;
			}

			if($scope.docList && $scope.docList.length > 0){
				$scope.statParams.docIds = [];
				angular.forEach($scope.docList, function(data){
					$scope.statParams.docIds.push(data.id);
				})
			}
			else {
				$scope.statParams.docIds = null;
			}

			if($scope.opList && $scope.opList.length > 0){
				$scope.statParams.opIds = [];
				angular.forEach($scope.opList, function(data){
					$scope.statParams.opIds.push(data.id);
				})
			}
			else {
				$scope.statParams.opIds = null;
			}

			if($scope.csList && $scope.csList.length > 0){
				$scope.statParams.cusIds = [];
				angular.forEach($scope.csList, function(data){
					$scope.statParams.cusIds.push(data.id);
				})
			}
			else {
				$scope.statParams.cusIds = null;
			}

			if($scope.salesList && $scope.salesList.length > 0){
				$scope.statParams.salesIds = [];
				angular.forEach($scope.salesList, function(data){
					$scope.statParams.salesIds.push(data.id);
				})
			}
			else {
				$scope.statParams.salesIds = null;
			}
		}
		
		$scope.reportavailalbe = false;
		$scope.statsResult = {};
		$scope.getContents = function() {
			$scope.isSaving = true;
			getStatParams();
			OpsFiles.receiptPayment($scope.statParams, function(result) {
				$scope.isSaving = false;
				$scope.statsResult = result;
				if (!$scope.statsResult.receiptPaymentContents || $scope.statsResult.receiptPaymentContents.length == 0) {
					initContent();
					layer.msg($translate.instant('stats.stat.noData'));
					return;
				}
				$scope.reportavailalbe = true;
			}, function(response) {
				$scope.isSaving = false;
				$scope.reportavailalbe = false;
				if(response.status == 404){
					layer.msg($translate.instant('stats.stat.noData'));
				}
				else {
					$rootScope.ALERT_ERROR(response);
				}
				initContent();
			});
		}
		var initContent = function() {
			$scope.statsResult.receiptPaymentContents = [];
			for (var j = 0; j <= 20; j++) {
				$scope.statsResult.receiptPaymentContents.push({});
			}

			$scope.statsResult.receiptPaymentContents = [];
			for (var j = 0; j <= 20; j++) {
				$scope.statsResult.receiptPaymentContents.push({});
			}
		}
		initContent();
		$scope.exportReport = function(format) {
			// layer.msg($translate.instant('global.developingAlert'));
			// return;
			$scope.statParams.fileType = format;
			$scope.statParams.fileName = "";
			$scope.fileName_btn = layer.open({
				type: 1,
				area: ['300px', '180px'],
				btn: [$translate.instant('global.confirm'), $translate.instant('global.cancel')],
				title: $translate.instant('global.fileName'),
				shade: 0.3,
				moveType: 0,
				shift: 0,
				content: $("#templateReportStatName"),
				success: function(layero, index) {
					$("#statFileName").focus();
				},
				yes: function(index, layero) {
					if ($scope.statParams.fileName == '' || angular.isUndefined($scope.statParams.fileName)) {
						$scope.statParams.fileName = 'Summary';
					}
					$scope.getStatFile();
					layer.close($scope.fileName_btn);
				},
				no: function(index, layero) {
					layer.close(index);
				}
			});


		}

		$("#statFileName").keydown(function(event) {
			$scope.keycode = window.event ? event.keyCode : event.which;
			if ($scope.keycode == 13) {
				if ($scope.statParams.fileName == '' || angular.isUndefined($scope.statParams.fileName)) {
					$scope.statParams.fileName = 'Summary';
				}
				$scope.getStatFile();
				layer.close($scope.fileName_btn);
			}
		})
		$scope.getStatFile = function() {
			OpsFiles.generateSummaryReport({
				fileType:$scope.statParams.fileType
			}, $scope.statsResult, function(result) {
				$scope.exportExcelOrPdf(result);
			}, function(error) {
				layer.msg($translate.instant('stats.stat.noData'));
			});
		}

		$scope.exportExcelOrPdf = function(result) {
			if (angular.isDefined($scope.statParams.fileType) && $scope.statParams.fileType == 2) {
				var file = new Blob([result.data], {
					type: 'application/vnd.ms-excel'
				});
				var blobURL = (window.URL || window.webkitURL).createObjectURL(file);
				var aTag = document.createElement("a");
				aTag.download = $scope.statParams.fileName + ".xlsx";
				aTag.href = blobURL;
				aTag.click();
			} else {
                var file = new Blob([result.data], {
                    type: 'application/pdf'
                });
                var fileURL = URL.createObjectURL(file);
                var previewDialog = layer.open({
                    type: 2,
                    area: ['980px', '680px'],
                    maxmin: true,
                    title: $translate.instant("stats.stat.viewMonthlyStatement"),
                    shade: 0.3,
                    moveType: 0,
                    shift: 0,
                    content: fileURL
                })
            }
		}

		$scope.statParams.filterRequired = false;
		$scope.filterCheckBoxControl = function() {
			if (!$scope.statParams.filterRequired) {
				$scope.statParams.filterRequired = false;
				$scope.statParams.salesId = null;
				$scope.statParams.opId = null;
				$scope.statParams.docId = null;
				$scope.statParams.finId = null;
				$scope.statParams.marketId = null;	
				$scope.statParams.shipperId = null;
				$scope.statParams.clientName = null;
				$scope.statParams.deliveryId = null;
				$scope.statParams.agentName = null;
				$scope.statParams.bookingToId = null;	
				$scope.statParams.coloaderName = null;					
				$scope.statParams.contract = "";
				$scope.statParams.carrier = "";	
				$scope.statParams.shipmentType = null;
				$scope.statParams.polRegion = null;
				$scope.statParams.podRegion = null;
		        $scope.salesList = [];
		        $scope.marketList = [];
		        $scope.finList = [];
		        $scope.docList = [];
		        $scope.opList = [];
		        $scope.csList = [];
			}
		}

        $scope.opFilter = function(item) {
            if (item.authorities.indexOf("ROLE_OPERATOR") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_ALL") !== -1) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_OPERATOR") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_BRANCH") !== -1
                && item.branch == $rootScope.account.branch) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_OPERATOR") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_TEAM") !== -1
                && item.team == $rootScope.account.team) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_OPERATOR") !== -1 
                && item.id == $rootScope.account.id) {
                return true;
            }
            return false;
        };

        $scope.docFilter = function(item) {
            if (item.authorities.indexOf("ROLE_DOCUMENT") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_ALL") !== -1) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_DOCUMENT") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_BRANCH") !== -1
                && item.branch == $rootScope.account.branch) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_DOCUMENT") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_TEAM") !== -1
                && item.team == $rootScope.account.team) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_DOCUMENT") !== -1 
                && item.id == $rootScope.account.id) {
                return true;
            }
            return false;
        };
        
        $scope.salesFilter = function(item) {
            if (item.authorities.indexOf("ROLE_SALES") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_ALL") !== -1) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_SALES") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_BRANCH") !== -1
                && item.branch == $rootScope.account.branch) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_SALES") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_TEAM") !== -1
                && item.team == $rootScope.account.team) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_SALES") !== -1 
                && item.id == $rootScope.account.id) {
                return true;
            }
            return false;
        };

        $scope.csFilter = function(item) {
            if (item.authorities.indexOf("ROLE_CS") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_ALL") !== -1) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_CS") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_BRANCH") !== -1
                && item.branch == $rootScope.account.branch) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_CS") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_TEAM") !== -1
                && item.team == $rootScope.account.team) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_CS") !== -1 
                && item.id == $rootScope.account.id) {
                return true;
            }
            return false;
        };

        $scope.marketFilter = function(item) {      
            if (item.authorities.indexOf("ROLE_MARKET") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_ALL") !== -1) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_MARKET") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_BRANCH") !== -1
                && item.branch == $rootScope.account.branch) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_MARKET") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_TEAM") !== -1
                && item.team == $rootScope.account.team) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_MARKET") !== -1 
                && item.id == $rootScope.account.id) {
                return true;
            }
            return false;
        };

        $scope.finFilter = function(item) {
            if (item.authorities.indexOf("ROLE_ACCOUNT") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_ALL") !== -1) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_ACCOUNT") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_BRANCH") !== -1
                && item.branch == $rootScope.account.branch) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_ACCOUNT") !== -1 
                && $rootScope.account.authorities.indexOf("AU_STAT_TEAM") !== -1
                && item.team == $rootScope.account.team) {
                return true;
            }
            if (item.authorities.indexOf("ROLE_ACCOUNT") !== -1 
                && item.id == $rootScope.account.id) {
                return true;
            }
            return false;
        };

		$scope.contracts = [];
		$scope.getallcontract= function() {
			if ($scope.contracts && $scope.contracts.length > 0) {
				return;
			};
            Guide.getallcontract(function(result) {
				$scope.contracts = result;
			});
		};

		$scope.lanes = [];
		$scope.shipmentTypeChange = function(type) {
			if (type == "AIR") {
				$scope.lanes = $rootScope.airlanes;
			} else {
				$scope.lanes = $rootScope.sealanes;
			}
		};
        $scope._onCustomerSelected = function(data) {
        	$scope.statParams.customerId = data.id;
        }
        $scope._onVenderSelected = function(data) {
        	$scope.statParams.venderId = data.id;
        }

        $scope._onClientSelected = function(data) {
        	$scope.statParams.shipperId = data.id;
        }

        $scope._onColoaderSelected = function(data) {
        	$scope.statParams.bookingToId = data.id;
        }

        $scope._onAgentSelected = function(data) {
        	$scope.statParams.deliveryId = data.id;
        }

        var isReverse = false;        
        $scope.sortNameByTitle = function(title) {
        	isReverse = !isReverse;
    		$scope.statsResult.receiptPaymentContents.sort(function(a,b){
    			if (isReverse){
    				return a[title]<b[title]?1:-1;
    			}
    			else {
    				return a[title]>b[title]?1:-1;
    			}        			
    		});
        }

        $scope.setIspay = function(isPayValue) {
        	$scope.statParams.isPay = isPayValue;
        	$scope.statsResult = {};
        }

        $scope.showAllGuides = function(content) {
            $uibModal.open({
                templateUrl: 'app/entities/guideRates/guideRatesDTO-dialog.html',
                controller: 'GuideRatesDTODialogController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'xxl',
                resolve: {
                    entity: [function() {
                        return content;
                    }]
                }
            }).result.then(function() {
            }, function() {
            });
        }

        $scope.salesList = [];
        $scope.marketList = [];
        $scope.finList = [];
        $scope.docList = [];
        $scope.opList = [];
        $scope.csList = [];
        
        $scope._onClerkSelected = function(fd, item) {
            if(fd == "salesId"){
            	$scope.salesList.push(item);
            }
            else if(fd == "marketId"){
            	$scope.marketList.push(item);
            }
            else if(fd == "finId"){
            	$scope.finList.push(item);
            }
            else if(fd == "docId"){
            	$scope.docList.push(item);
            }
            else if(fd == "opId"){
            	$scope.opList.push(item);
            }
            else if(fd == "cusId"){
            	$scope.csList.push(item);
            }
            $scope.statParams[fd] = "";
        };
        $scope.delSales = function(index) {
	        $scope.salesList.splice(index, 1);
        };
        $scope.delMarket = function(index) {
	        $scope.marketList.splice(index, 1);
        };
        $scope.delFin = function(index) {
	        $scope.finList.splice(index, 1);
        };
        $scope.delDoc = function(index) {
	        $scope.docList.splice(index, 1);
        };
        $scope.delOp = function(index) {
	        $scope.opList.splice(index, 1);
        };
        $scope.delCs = function(index) {
	        $scope.csList.splice(index, 1);
        };


		$scope.asyncTasks = function() {
            getStatParams();
			if(!$scope.statParams.etdFM || !$scope.statParams.etdTO){
				layer.msg($translate.instant('stats.stat.dateNullAlert'));				
				return;
			}
            $scope.isSaving = true;
            var loadIndex = layer.load(1, {shade: 0.3});
            var body = {};
			$scope.statParams.companyId = $rootScope.account.companyId;
            body.statParams = $scope.statParams;
            body.gridState = {
				"columns": [
					{
						"name": "objectName",
						"visible": true,
						"width": 250,
						"displayName": "对象名称"
					},
					{
						"name": "salesName",
						"visible": true,
						"width": 100,
						"displayName": "业务员"
					},
					{
						"name": "paymentRMB",
						"visible": true,
						"width": 100,
						"displayName": "应收(RMB)"
					},
					{
						"name": "paidRMB",
						"visible": true,
						"width": 100,
						"displayName": "已收(RMB)"
					},
					{
						"name": "notPaidRMB",
						"visible": true,
						"width": 100,
						"displayName": "未收(RMB)"
					},
					{
						"name": "noInvoiceRMB",
						"visible": true,
						"width": 100,
						"displayName": "应收未开票(RMB)"
					},
					{
						"name": "paymentUSD",
						"visible": true,
						"width": 100,
						"displayName": "应收(USD)"
					},
					{
						"name": "paidUSD",
						"visible": true,
						"width": 100,
						"displayName": "已收(USD)"
					},
					{
						"name": "notPaidUSD",
						"visible": true,
						"width": 100,
						"displayName": "未收(USD)"
					},
					{
						"name": "noInvoiceUSD",
						"visible": true,
						"width": 100,
						"displayName": "应收未开票(USD)"
					},
					{
						"name": "paymentALL",
						"visible": true,
						"width": 100,
						"displayName": "合计应收(RMB)"
					},
					{
						"name": "paidALL",
						"visible": true,
						"width": 100,
						"displayName": "合计实收(RMB)"
					},
					{
						"name": "notPaidALL",
						"visible": true,
						"width": 100,
						"displayName": "合计未收(RMB)"
					}
				]
			}
            body.taskType = 'RECEIPT_PAYMENT';
			AsyncTask.submitAsyncTask(body, function(result) {
				$scope.isSaving = false;
                layer.close(loadIndex);
				layer.msg($translate.instant('autopsApp.asyncTask.tips.taskSubmitSuccess'));
			}, $rootScope.ALERT_ERROR);
            $scope.isSaving = false;
        }
	}
})();