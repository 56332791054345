(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('GuideTemplateDialogController', GuideTemplateDialogController);

    GuideTemplateDialogController.$inject = ['$rootScope', '$scope', '$location', '$uibModalInstance', 'entity', 'GuideTemplate'];
    
    function GuideTemplateDialogController($rootScope, $scope, $location, $uibModalInstance, entity, GuideTemplate) {

        $scope.guideTemplate = entity;
        $scope.load = function(id) {
            GuideTemplate.get({id : id}, function(result) {
                $scope.guideTemplate = result;
            });
        };
        setTimeout(function () {   
            var path = $location.path();
            if (!$rootScope.id && path.indexOf("new") <= 0){
                $uibModalInstance.dismiss('cancel');
            }
        }, 500);  
        var onSaveSuccess = function (result) {
            // $scope.$emit('autopsApp:guideTemplateUpdate', result);
            $uibModalInstance.close(result);
            $scope.isSaving = false;
        };

        var onSaveError = function (result) {
            $scope.isSaving = false;
        };

        $scope.save = function () {
            $scope.isSaving = true;
            if ($scope.guideTemplate.id != null) {
                GuideTemplate.update($scope.guideTemplate, onSaveSuccess, onSaveError);
            } else {
                GuideTemplate.save($scope.guideTemplate, onSaveSuccess, onSaveError);
            }
        };

        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
}
})();
