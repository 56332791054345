(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsExchangeRateController', OpsExchangeRateController);

    OpsExchangeRateController.$inject = ['$rootScope', '$timeout', '$translate', '$scope', '$state', '$uibModal',
        'OpsExchangeRate', 'OpsExchangeRateSearch', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'Principal'];

    function OpsExchangeRateController ($rootScope, $timeout, $translate, $scope, $state, $uibModal,
        OpsExchangeRate, OpsExchangeRateSearch, ParseLinks, AlertService, pagingParams, paginationConstants, Principal) {
        if (!$rootScope.account || angular.equals($rootScope.account, {})) {
            Principal.identity().then(function(account) {
                $rootScope.account = account;
            });
        };

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.edit = edit;
        vm.delOne = delOne;
        vm.exportExcel = exportExcel;
        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                OpsExchangeRateSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                OpsExchangeRate.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.opsExchangeRates = data;
                vm.page = pagingParams.page;
                vm.isAdvanceSearch = false;
            }
            function onError(error) {
                layer.msg($translate.instant('global.loadFailed'));
                vm.isAdvanceSearch = false;
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc'),
                search: vm.currentSearch
            });
        }

        function search (searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
            loadAll();
        }

        function edit (exchangeRate) {
            $rootScope.id = exchangeRate.id;
            $state.go('ops-exchange-rate.edit');
        }

        function delOne (exchangeRate) {
            layer.confirm($translate.instant('autopsApp.opsExchangeRate.voidAlert'), {
                icon: 3,
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                layer.close(index);
                OpsExchangeRate.delete({id: exchangeRate.id}, function (result) {
                    angular.copy(result, exchangeRate);
                    layer.msg($translate.instant('global.update_success'));
                });
            }, function() {
            });
        }

        $scope.groupShareOne = function(exchangeRate){
            layer.confirm($translate.instant('autopsApp.opsExchangeRate.groupShareOneAlert'), {
                icon: 3,
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                layer.close(index);
                OpsExchangeRate.groupShareOne(exchangeRate, function(){
                    layer.msg($translate.instant('global.syn_success'));
                }, $rootScope.ALERT_ERROR);
            }, function() {
            });
        }
        $scope.refreshExchangeRates = function(exchangeRate){
            layer.load(1, { shade: 0.3 });
            layer.confirm($translate.instant('autopsApp.opsExchangeRate.refreshExchangeRatesAlert'), {
                icon: 3,
                btn: [$translate.instant('entity.action.confirm'), $translate.instant('entity.action.back')] //按钮
            }, function(index) {
                layer.close(index);
                OpsExchangeRate.refreshExchangeRates(exchangeRate, function(){
                    layer.closeAll();
                    layer.msg($translate.instant('global.update_success'));
                }, $rootScope.ALERT_ERROR);
            }, function() {
                layer.closeAll();
            });
        }

        $scope.searchRequestPop = function () {
            var searchParams = {};
            $uibModal.open({
                templateUrl: 'app/entities/ops-exchange-rate/ops-exchange-search.html',
                controller: 'OpsExchangeSearchController',
                controllerAs: 'vm',
                backdrop: false,
                size: 'lg',
                resolve: {
                    entity: [function () {
                        return searchParams;
                    }]
                }
            }).result.then(function (results) {
                vm.opsExchangeRates = results;
                vm.isAdvanceSearch = true;
                vm.queryCount = results.length;
            }, function () {
            });
        }

        function exportExcel() {
            if (!vm.opsExchangeRates || vm.opsExchangeRates.length == 0) {
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            var fileName = "exchange rate.xlsx";
            OpsExchangeRate.exportOpsExchangeRate(vm.opsExchangeRates, function (result) {
                $rootScope.DOWNLOADEXCEL(result, fileName);
            })
        }
        $timeout(function (){
            $rootScope.RESIZE();
        });
    }
})();
