(function() {
    'use strict';

    angular
        .module('autopsApp')
        .controller('OpsTemplateChooseController', OpsTemplateChooseController);

    OpsTemplateChooseController.$inject = ['$scope', '$rootScope', '$timeout', '$uibModalInstance', '$translate',
        'entity', 'OpsTemplate', 'OpsRates', 'DataUtils', 'Guide'
    ];

    function OpsTemplateChooseController($scope, $rootScope, $timeout, $uibModalInstance, $translate,
        entity, OpsTemplate, OpsRates, DataUtils, Guide) {

        $scope.guide = entity.guide;
        $scope.opsTemplates = [];
        $scope.guides = [];

        $scope.shipmentType = entity.shipmentType;

        $timeout(function() {
            OpsTemplate.getAll(function(result) {
                $scope.opsTemplates = result;
            })
            var shipperId = $scope.shipmentType == "wms" ? $scope.guide.venderId : $scope.guide.shipperId;
            var shipmentType = $scope.shipmentType == "wms" ? "WMS" : $scope.guide.shipmentType;
            if ($rootScope.account.companyId && shipperId && shipmentType) {
                Guide.getRecentShipments({
                    type: $scope.shipmentType,
                    companyId: $rootScope.account.companyId,
                    shipmentType: shipmentType,
                    isOut: $scope.guide.isOut,
                    isExport: $scope.guide.isExport,
                    shipperId: shipperId
                }, function(results) {
                    $scope.guides = results;
                    for (var i = $scope.guides.length - 1; i >= 0; i--) {
                        if ($scope.guides[i].id == $scope.guide.id) {
                            $scope.guides.splice(i, 1);
                            break;
                        }
                    }
                })
            }
        })

        $scope.searchQuery = null;
        $scope.getGuideRatesByJobNum = function(copyId) {
            Guide.getGuideRatesByJobNum({
                companyId: $rootScope.account.companyId,
                type: $scope.shipmentType,
                jobNum: $scope.searchQuery
            }, function(guideRates) {
                copyGuideRatesPop(guideRates);
            }, function(result) {
                copyGuideRatesPop(guideRates);
            })
        }

        $scope.copyRates = function(copyId) {
            Guide.getGuideRates({
                type: $scope.shipmentType,
                id: copyId
            }, function(guideRates) {
                copyGuideRatesPop(guideRates);
            })
        };

        var copyGuideRatesPop = function(guideRates) {
            if (!guideRates) {
                guideRates = [];
            }
            for (var i = guideRates.length - 1; i >= 0; i--) {
                if (guideRates[i].isHidden || guideRates[i].sharedOn) {
                    guideRates.splice(i, 1);
                }
            }
            if (guideRates.length == 0) {
                layer.msg($translate.instant('global.noRecord'));
                return;
            }
            layer.open({
                content: $translate.instant('autopsApp.booking.copyChoose', {
                    amt: guideRates.length
                }),
                title: $translate.instant('global.messageInfo'),
                btn: [$translate.instant('autopsApp.booking.copyAll'), 
                    $translate.instant('autopsApp.booking.copyRev'), 
                    $translate.instant('autopsApp.booking.copyExp'), 
                    $translate.instant('autopsApp.booking.copyNone')],
                yes: function(index, layero) {
                    angular.forEach(guideRates, function(rate) {
                        copyGuideRate(rate);
                    })
                    $uibModalInstance.dismiss('cancel');
                    layer.close(index);
                },
                btn2: function(index, layero) {
                    angular.forEach(guideRates, function(rate) {
                        if (!rate.isPay) {
                            copyGuideRate(rate);
                        }
                    })
                    $uibModalInstance.dismiss('cancel');
                    layer.close(index);
                },
                btn3: function(index, layero) {
                    angular.forEach(guideRates, function(rate) {
                        if (rate.isPay) {
                            copyGuideRate(rate);
                        }
                    })
                    $uibModalInstance.dismiss('cancel');
                    layer.close(index);
                },
                btn4: function(index, layero) {
                    $uibModalInstance.dismiss('cancel');
                    layer.close(index);
                },
                cancel: function() {
                    //return false 开启该代码可禁止点击该按钮关闭
                }
            });
        }

        var copyGuideRate = function(rate) {
            if (!rate.isHidden && !rate.sharedOn && !rate.hiddenRate) {
                DataUtils.clearCopyRate(rate);
                rate.inputBy = $rootScope.account.firstName + " " + $rootScope.account.lastName;
                rate.companyId = $rootScope.account.companyId;
                rate.count = DataUtils.round(DataUtils.getUnitCount(rate, $scope.shipmentType, $scope.guide), 4);
                if (rate.count && rate.price) {
                    rate.total = DataUtils.round(rate.price * rate.count, 2);
                    if (!rate.payExchange) {
                        rate.payExchange = 1.00;
                    }
                    rate.payAmount = DataUtils.round(rate.total * rate.payExchange, 2);
                }
                $scope.guide.guideRates.push(rate);
            }
        };


        $scope.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.onRateTempChoosed = function(data) {
            OpsRates.get({
                id: data.id
            }, function(result) {
                if ($scope.guide.guideRates) {
                    for (var i = 0; i < result.length; i++) {
                        if ($scope.guide.hidenCost && result[i].isPay &&
                            $rootScope.account.authorities.indexOf("FUN_VIEWCOST") === -1) {
                            continue;
                        }
                        if ($scope.guide.hidenSelling && !result[i].isPay &&
                            $rootScope.account.authorities.indexOf("FUN_VIEWSELLING") === -1) {
                            continue;
                        }
                        result[i].id = null;
                        if (result[i].venderId < 0) {
                            result[i] = getRatePayer(result[i]);
                        } else {
                            if ($rootScope.account.companyId != data.companyId) {
                                result[i].venderId = null;
                            } else {
                                result[i].payer = result[i].venderName;
                            }
                        }
                        result[i].payCurrency = result[i].currency;
                        if ($rootScope.account.companyId != data.companyId && $rootScope.OPSCURRENCYS.indexOf(result[i].payCurrency) == -1) {
                            result[i].payCurrency = result[i].currency = null;
                        }
                        result[i].payExchange = 1.00;
                        result[i].count = DataUtils.getUnitCount(result[i], $scope.shipmentType, $scope.guide);
                        if (null == result[i].count) {
                            if ($scope.guide.companyId == 388 || $scope.guide.companyId == 407) {
                                result[i].count = 0;
                            } else {
                                continue;
                            }
                        }
                        DataUtils.rateAmountChange(result[i], $scope.guide);
                        if (result[i].min && result[i].payAmount < result[i].min) {
                            result[i].price = result[i].total = result[i].payAmount = result[i].min;
                            result[i].unit = "SHIPMENT";
                            result[i].count = 1;
                            result[i].remarks = "Minimum Charge";
                        } else if (result[i].max && result[i].payAmount > result[i].max) {
                            result[i].price = result[i].total = result[i].payAmount = result[i].max;
                            result[i].unit = "SHIPMENT";
                            result[i].count = 1;
                            result[i].remarks = "Maximum Charge";
                        }
                        $scope.guide.guideRates.push(result[i]);
                    }
                }
                $uibModalInstance.close(result);
            });
        }

        var getRatePayer = function(rate) {
            if (rate.venderId == -1) {
                rate.venderId = $scope.shipmentType == "wms" ? $scope.guide.venderId : $scope.guide.customerId;
                rate.payer = $scope.shipmentType == "wms" ? $scope.guide.clientName : $scope.guide.customerName;
            } else if (rate.venderId == -2) {
                rate.venderId = $scope.shipmentType == "wms" ? $scope.guide.venderId : $scope.guide.shipperId;
                rate.payer = $scope.shipmentType == "wms" ? $scope.guide.clientName : $scope.guide.shipperName;
            } else if (rate.venderId == -3) {
                rate.venderId = $scope.guide.bookingToId;
                rate.payer = $scope.guide.bookingToName;
            } else if (rate.venderId == -4) {
                rate.venderId = $scope.guide.deliveryId;
                rate.payer = $scope.guide.deliveryName;
            } else if (rate.venderId == -5) {
                if ($scope.guide.booking && $scope.guide.booking.bookingTrucks && $scope.guide.booking.bookingTrucks.length > 0) {
                    rate.venderId = $scope.guide.booking.bookingTrucks[0].truckerVid;
                    rate.payer = $scope.guide.booking.bookingTrucks[0].truckerName;
                }

            } else if (rate.venderId == -6) {
                if ($scope.guide.bookingCustoms && $scope.guide.bookingCustoms.length > 0) {
                    rate.venderId = $scope.guide.bookingCustoms[0].brokerVid;
                    rate.payer = $scope.guide.bookingCustoms[0].brokerName;
                }
            } else if (rate.venderId == -7) {
                rate.venderId = $scope.guide.warehouseVid;
                rate.payer = $scope.guide.warehouseName;
            } else if (rate.venderId == -8) {
                rate.venderId = $scope.guide.traderVid;
                rate.payer = $scope.guide.traderName;
            } else if (rate.venderId == -9) {
                rate.venderId = $scope.guide.insuranceVid;
                rate.payer = $scope.guide.insuranceName;
            } else if (rate.venderId == -10) {
                rate.venderId = $scope.guide.fumigationVid;
                rate.payer = $scope.guide.fumigationName;
            } else if (rate.venderId == -11) {
                rate.venderId = $scope.guide.clearanceAgentId;
                rate.payer = $scope.guide.clearanceAgent;
            }
            return rate;
        }

        var units = ["20GP", "40GP", "40HQ", "45HQ", "20RF", "20RH", "40RF", "40RH", "40DRH", "45RH",
            "20OT", "40OT", "20FR", "40FR", "20HT", "40HT", "20TK", "20HC", "20OH",
            "40TK", "20HZ", "40HZ", "40NOR"
        ];

        var getCtnNum = function(type) {
            var outNum = 0;
            if (!$scope.guide.guideFclCtns) {
                return outNum;
            }
            angular.forEach($scope.guide.guideFclCtns, function(data) {
                if (data.ctnType == type && data.num) {
                    outNum = outNum + data.num;
                }
            })
            return outNum;
        }

        var getCtnNumTtl = function() {
            var outNum = 0;
            if (!$scope.guide.guideFclCtns) {
                return outNum;
            }
            angular.forEach($scope.guide.guideFclCtns, function(data) {
                if (data.ctnType && data.num) {
                    outNum = outNum + data.num;
                }
            })
            return outNum;
        }

    }
})();
